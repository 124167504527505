import { Default } from "./InitialState";
import {
  TOGGLE_BULK_UPLOAD_MODAL,
  HANDLE_BULK_UPLOAD_FILE_DROP,
  ADMIN_BULK_UPLOAD_START,
  ADD_JOB_STARTED,
  ADD_JOB_FAILED,
  ADD_JOB_SUCCESS,
  ADMIN_BULK_UPLOAD_END,
} from "../../constants";

// eslint-disable-next-line max-lines-per-function
const UserUploadReducer = (state = Default(), action) => {
  switch (action.type) {
    case TOGGLE_BULK_UPLOAD_MODAL:
      return {
        ...state,
        isBulkAddModalVisible: !state.isBulkAddModalVisible,
      };
    case ADMIN_BULK_UPLOAD_START:
      return {
        ...state,
        isBulkLoading: true,
      };
    case ADMIN_BULK_UPLOAD_END:
      return {
        ...state,
        isBulkLoading: false,
      };
    case HANDLE_BULK_UPLOAD_FILE_DROP:
      return {
        ...state,
        fileToUpload: action.payload,
      };
    default:
      return jobDetails(state, action);
  }
};

// eslint-disable-next-line max-lines-per-function
const jobDetails = (state, action) => {
  switch (action.type) {
    default:
      return addJobDetails(state, action);
  }
};
// eslint-disable-next-line max-lines-per-function
const addJobDetails = (state, action) => {
  switch (action.type) {
    case ADD_JOB_STARTED:
      return {
        ...state,
        isBulkLoading: true,
      };
    case ADD_JOB_SUCCESS:
      return {
        ...state,
        isBulkLoading: false,
        isBulkAddModalVisible: false,
        fileToUpload: null,
        bulkUploadType: null,
      };
    case ADD_JOB_FAILED:
      return {
        ...state,
        isBulkLoading: false,
      };
    default:
      return state;
  }
};
export default UserUploadReducer;
