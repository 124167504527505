export function IniialState() {
  return {
    fetchStatusReportApi: {
      isProcessing: false,
      data: null,
      error: false,
      message: ''
    },

  };
}