import { notification } from "antd";
import { parseAsync } from "json2csv";

export function parseAndDownloadCSV({ columns, data, fileName, downloadName }) {
  parseAsync(data, { fields: columns })
    .then((csv) => {
      const elem = document.createElement("a");
      elem.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      elem.target = "_blank";
      elem.download = fileName + ".csv";
      elem.click();
    })
    .catch((err) => {
      notification.error({ message: 'Failed to generate csv' })
    }
    );

}