import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const renamePeopleStarted = (state) => {
    return {
        ...state,
        renamePeopleApi: getApiStartState()
    }

}

export const renamePeopleSuccess = (state, payload) => {
    return {
        ...state,
        renamePeopleApi: getSuccessResponse(payload)
    }

}

export const renamePeopleFailed = (state, payload) => {
    return {
        ...state,
        renamePeopleApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}