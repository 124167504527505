import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const Toggler = styled(({ state, ...props }) => (
    <a {...props}>
        {(state === 'closed') &&
            <FontAwesomeIcon icon={faChevronRight} />
        }
        {(state === 'opened') &&
            <FontAwesomeIcon icon={faChevronDown} />
        }
    </a>
))`
    color: #333;
    display: inline-block;
    text-align: center;
    margin-right: 2px;
`;

export default Toggler;