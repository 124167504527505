import { getSuccessResponse, handleApiResponseError, handleNetworkError } from "../../../../utils/reducerOperations";

export function submitSurveyResponseStart(state) {
    return {
        ...state,
        submitSurveyResponseApi: {
            data: null,
            isProcessing: true,
            message: null,
            error: false
        }
    };
};

export function submitSurveyResponseSuccess(state, action) {
    const { data } = action.response;
    return {
        ...state,
        surveyCompleted: true,
        submitSurveyResponseApi: getSuccessResponse(data),
    };
};

export function submitSurveyResponseFail(state, action) {
    return {
        ...state,
        submitSurveyResponseApi:
            (action.response
                && action.response.response
                && action.response.response.data
                ? handleApiResponseError(action.response.response.data)
                : handleNetworkError(action.response))

    };
};