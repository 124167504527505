import { UPDATE_DEMOGRAPHIC_REDUCER } from '../../constants';

export * from './fetchDemographicsData';
export * from './fetchDimensions';
export * from './fetchOutliersData';

export const updateDemographicReducer = (params) => {
  return {
    type: UPDATE_DEMOGRAPHIC_REDUCER,
    payload: { ...params },
  };
};
