import React, { Component } from 'react'
import DownloadLinks from '../DownloadLinks'

class SimpleSurveyRightSection extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }



    render() {
        return (
            <>
                <DownloadLinks
                    description={"Simple survey"}
                />
            </>
        )
    }
}

export default SimpleSurveyRightSection