export const exploreParticipationDataStart = (state) => {
  return {
    ...state,
    exploreParticipationDataApi: {
      data: null,
      isProcessing: true,
      error: null,
      message: null,
      isInitialApiCalled: false,
    },
  };
};

export const exploreParticipationDataSuccess = (state, payload) => {
  return {
    ...state,
    exploreParticipationDataApi: {
      isProcessing: false,
      data: payload.data.data,
      error: !payload.status,
      message: payload.message,
      isInitialApiCalled: true,
    },
    exploreParticipationSelectedColumns: Object.keys(
      payload.data.data.aggregatedDemographicsResponse.result || {}
    ),
    filters: payload.filters || {},
    selectedUser: payload.selectedUser,
    directReporteesOnly: payload.directReporteesOnly,
  };
};

export const exploreParticipationDataFailed = (state, payload) => {
  return {
    ...state,
    exploreParticipationDataApi: {
      isProcessing: false,
      data: null,
      error: payload.error,
      isInitialApiCalled: false,
    },
  };
};

export const resetParticipationValues = (state) => {
  return {
    ...state,
    exploreParticipationDataApi: {
      isProcessing: false,
      data: null,
      error: null,
      isInitialApiCalled: false,
    },
  };
};
