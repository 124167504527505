import React from "react";
import { Component } from "react";
import { AsterikLabel } from "../AsterikLabel";
import TextArea from "../../FormElements/TextArea";
import helpers from "./helpers";
import { SelectElement } from "../../FormElements/SelectElement/";
import { TextInput } from "../../FormElements/TextInput/";
import Datepicker from "../../FormElements/Datepicker";
import Timepicker from "../../FormElements/Timepicker";
import './style.scss';
import SVGIcons from '../../Components/SVGIcons';
class FormField extends Component {
  state = {
    orgText: this.props.value ? true : false,
  };
  constructor(props) {
    super(props);
    this.makeClassName = this.makeClassName.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onEnterPressed = this.onEnterPressed.bind(this);
  }

  makeClassName() {
    let classList = [""];
    if (this.props.classNameForEmail) {
      classList.push(this.props.classNameForEmail)
    }
    if (this.props.field.isValid === false) classList.push("has-error");
    if (this.props.field.required === true) classList.push("required-field");
    return classList.join(" ");
  }

  onInputChange(event) {
    if (this.props.isEmailField && event.target.value) {
      event.target.value = event.target.value.trim()
    }
    this.setState({
      orgText: false,
    });
    if (this.props.loginFormChange)
      this.props.loginFormChange({
        index: this.props.index,
        event: event,
      });
    if (this.props.onInputChange)
      this.props.onInputChange({
        index: this.props.index,
        event: event,
        name: event.target.name,
      });
  }
  onChangeDateTime = (event, valueString) => {
    this.props.onInputChange({
      index: this.props.index,
      event: valueString,
      name: event,
    });
  };
  onSelectChange = (event, value) => {
    this.props.loginFormChange({
      index: this.props.index,
      event: event ? event : "",
      name: value ? value.props.children : "",
    });
    if (this.props.onInputChange) this.props.onInputChange(event);
  };

  onEnterPressed(event) {
    if (event.keyCode === 13) {
      if (this.props.onEnter) {
        this.props.onEnter(event);
      }
    }
  }

  renderAsterik = () => {
    if (this.props.required === true) {
      return <AsterikLabel />;
    }
  };
  getRequiredClass = () => {
    if (this.props.required === true) return " required-field";
    else return "";
  };


  renderLabel = () => {
    if (this.props.labelRequired === true) {
      return (
        <label>
          <span className='label-name'>{this.props.field.label}</span>
          {this.props.required && (
            <span title='required' className='required-icon'>
              <SVGIcons type={'SVG-rating'} />
            </span>
          )}
        </label>
      )
    }
  };

  renderInput = () => (

    <TextInput
      test-name="input-field"
      className={this.makeClassName()}
      id={this.props.id}
      disabled={this.props.field.disabled}
      name={this.props.field.name}
      htmlType={this.props.field.type}
      placeholder={this.props.field.placeholder}
      value={this.state.orgText ? "" : this.props.field.value}
      handleChange={this.onInputChange}
      onPressEnter={this.onEnterPressed}
      onBlur={this.props.onBlur}
    />
  );
  renderDatePicker = () => (
    <Datepicker
      className="date-input"
      placeholder={this.props.field.placeholder}
      onChange={this.props.onChangeDateTime}
    />
  );
  renderTimePicker = () => (
    <Timepicker
      placeholder={this.props.field.placeholder}
      className="date-input"
      onChange={this.onChangeDateTime}
    />
  );
  renderTextarea = () => (
    <TextArea
      textAreaAutosize = {this.props.textAreaAutosize?true:false}
      test-name="input-field"
      disabled={this.props.field.disabled}
      name={this.props.field.name}
      className={`${this.makeClassName()} ${this.props.textAreaClassName ? this.props.textAreaClassName : ""
        }`}
      type={this.props.field.type}
      value={this.props.field.value}
      handleChange={({ event }) => this.onInputChange(event)}
      placeHolder={this.props.field.placeholder}
      onKeyUp={this.onEnterPressed}
    />
  );
  renderSelect = () => (
    <SelectElement
      test-name="input-field"
      allowClear={this.props.allowClear}
      placeholder={this.props.field.placeholder}
      className={this.makeClassName()}
      name={this.props.field.name}
      type={this.props.field.type}
      value={this.props.field.value ? this.props.field.value : undefined}
      disabled={this.props.field.disabled}
      onChange={this.onSelectChange}
      onKeyUp={this.onEnterPressed}
    >
      {helpers.renderOption(this.props)}
    </SelectElement>
  );

  renderHint = () => {
    if (this.props.field.hint) {
      return (
        <div className='form-hints'>
          <p>
            Hint : {this.props.field.hint}
          </p>
        </div>
      )
    }
  }

  renderField = () => {
    if (this.props.field.component === "textarea") return this.renderTextarea();
    else if (this.props.field.component === "select")
      return this.renderSelect();
    return this.renderInput();
  };

  getErrorClassList = (data) => {
    const { name } = this.props.field;
    if (
      name !== "search" &&
      name !== "existingUserTemplate" &&
      name !== "newUserTemplate"
    )
      return data.length ? ' invalid-item' : '';
    return "";
  };
  render() {
    return (
      <div className={
        "input-wrapper" +
        this.getRequiredClass() +
        this.getErrorClassList(this.props.field.message)
      } test-name="input-wrapper">
        {this.renderLabel()}
        {this.renderField()}
        {this.props.field.message && !this.props.loginPage &&
          <div className="error-message" test-name="error-message"> <span children={this.props.field.message} />
          </div>}
        {this.renderHint()}
      </div >
    );
  }
}

export default FormField;
