import {
  apiSuccessState,
  apiFailureState,
} from "../../../../utils/apiStateUtils";

export function fetchTemplatesForAdminSuccess(state, payload) {
  return {
    ...state,
    fetchTemplatesForAdminApi: apiSuccessState(
      state.fetchTemplatesForAdminApi,
      payload.payload
    ),
  };
}

export function fetchTemplatesForAdminStarted(state, payload) {
  return {
    ...state,
    fetchTemplatesForAdminApi: {
      ...state.fetchTemplatesForAdminApi,
      loading: true,
      error: false,
    },
  };
}

export function fetchTemplatesForAdminFailure(state, payload) {
  return {
    ...state,
    fetchTemplatesForAdminApi: apiFailureState(
      state.fetchTemplatesForAdminApi,
      payload
    ),
  };
}
