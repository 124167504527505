import { deepCopy } from "../../../../../utils/deepCopy"

export const addMultipleSelectionAnswerOption = (state, payload) => {
    let goals = addMultipleSelectionAnswerOptionToGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const addMultipleSelectionAnswerOptionToGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(goal => {
        if (goal.goalId === "multipleOption") {
            goal.questions.forEach(question => {
                if (question.staticQuestionId === payload.staticQuestionId) {
                    question.options.splice(question.options.length, 0, payload.data)
                }
            })
        }
    })
    return goalsCopy
}

