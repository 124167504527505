export function fetchDemographicsDataStart(state) {
    return {
        ...state,
        fetchDemographicsApi: {
            data: null,
            isProcessing: true,
            message: null,
            error: false
        }

    };
};

export function fetchDemographicsDataSuccess(state, action) {
    return {
        ...state,
        fetchDemographicsApi: {
            data: action.response.data.data.response,
            isProcessing: false,
            message: null,
            error: false
        }

    };
};

export function fetchDemographicsDataFailed(state, action) {
    return {
        ...state,
        fetchDemographicsApi: {
            data: null,
            isProcessing: false,
            message: null,
            error: true
        }

    };
}