export function IniialState() {
  return {
    trendPairingDataApi: {
      isProcessing: false,
      data: null,
      error: false,
      message: '',
      unmappedSurvey: [],
      currentSurvey: []
    },

    listComparisonSurveyApi: {
      isProcessing: false,
      data: null,
      error: false,
      message: '',
      dummyData: []
    },

    updateTrendDataApi: {
      isProcessing: false,
      data: null,
      error: false,
      message: ''
    },

    isTrendPairingDataAlreadyFetched: false,
    pairingTableDropDownValue: undefined,
    trendToggle: false
  };
}