export const leadershipTemplate = [
    {
        questionDimension: 'Senior Leaders',
        type: 'Matrix',
        questionLabel: 'Senior Leaders',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: 'Senior leaders are moving the organization in the right direction.', value: '', isSelected: true
        },
        {
            text: `Senior leaders demonstrate that people are critical to the organization's success.`, value: '', isSelected: true

        },
        {
            text: `Senior leaders are good role models of our organization's core values.`, value: '', isSelected: true

        },
        {
            text: 'Senior leaders are making the right decisions to drive business performance.', value: '', isSelected: false

        }]
    }
]



