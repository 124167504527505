export const initialState = {
  outlierApi: {
    isProcessing: false,
    error: false,
    reason: '',
    outlierData: {},
  },
  isOutlierApiCalled: false,
  rightSectionData: {
    positiveData: {
      header: '',
      outlierScore: '',
      outlierScoreText: '',
      overallScore: '',
      overallScoreText: 'All respondents',
    },
    negativeData: {
      header: '',
      outlierScore: '',
      outlierScoreText: '',
      overallScore: '',
      overallScoreText: 'All respondents',
    }

  },
  leftSectionData: {
    positiveData: {
      title: '',
    mainText: '',
    details: '',
    },
    negativeData: {
      title: '',
      mainText: '',
      details: '',
    }

  },
  neumericDemographicsApi:{
    isProcessing: false,
    error: false,
    reason: '',
    data: {},
  },
};
