export const futureWorkTemplate = [
    {
        questionDimension: 'Future of work',
        type: 'Matrix',
        questionLabel: 'Future of work',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: 'I am at least as productive when working from home as when working in an office.', value: '', isSelected: true
        },
        {
            text: 'I have the technology I need to work remotely.', value: '', isSelected: true

        },
        {
            text: 'My organization can succeed if workers like me rarely go to a physical office.', value: '', isSelected: true

        },
        {
            text: 'We have a clear work-from-home policy.', value: '', isSelected: false

        }]
    }
]
