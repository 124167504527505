export const fetchSurveyWizardSurveyListStart = (state, payload) => {
  return {
    ...state,
    surveyListApi: {
      isProcessing: true,
      error: false, reason: '',
      surveyList: []
    },
  };
};

export const fetchSurveyWizardSurveyListSuccess = (state, payload) => {
  let data = (payload.data && payload.data.hits) || [];
  let total = (payload.data && payload.data.total) || [];
  return {
    ...state,
    surveyListApi: {
      isProcessing: false,
      error: false,
      reason: '',
      surveyList: data,
      total: total
    },
  };
};

export const fetchSurveyWizardSurveyListFail = (state, payload) => {
  return {
    ...state,
    surveyListApi: {
      isProcessing: false,
      error: true,
      reason: payload.error,
      surveyList: [],
    },
  };
};

export const fetchCohortChildListSuccess = (state,data) =>{
  let updatedList = (data.data && data.data.hits) || [];
  let total = (data.data && data.data.total) || [];
  return {
      ...state, 
      surveyListApi: {
        ...state.surveyListApi,
        surveyList: updatedList,
        total: total
      }
    };
}