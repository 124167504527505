import { FEEDBACK_360_SURVEY } from "../../../../../../../config/questionTypeConstants"
import { KEY_CONSTANTS } from "../../constants"

export const getQuestionText = (data) => {
    
    if (data.relation === KEY_CONSTANTS.SELF && data.questionDetails.questions[0].HTMLSelfQuestion) {
        return data.questionDetails.questions[0].HTMLSelfQuestion ? KEY_CONSTANTS.HTML_QUESTION_SELF : KEY_CONSTANTS.SELF_QUESTION

    }
    else{
        return data.questionDetails.questions[0].HTMLQuestion ? KEY_CONSTANTS.HTML_QUESTION : KEY_CONSTANTS.QUESTION
    }
}

