import {
    GET_NORM_BENCHMARK_VALUE_SUCCESS,
    GET_NORM_BENCHMARK_VALUE_FAILED,
    GET_NORM_BENCHMARK_VALUE_STARTED,
} from '../../../constants';
import { GET_PROJECT_CONFIGURATION } from '../../../../../config/constants';
import { withType } from '../../../../utils/actionOperations';
import { ApiBase } from '../../../../utils/apiBase';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const getBenchmarkDetails = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
        dispatch(withType(GET_NORM_BENCHMARK_VALUE_STARTED));
        apiBaseInstance.instance
            .post(GET_PROJECT_CONFIGURATION, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then((response) => {
                dispatch(
                    withType(GET_NORM_BENCHMARK_VALUE_SUCCESS, response.data && response.data.data && response.data.data)
                );
                cb && cb(true, response.data && response.data.data && response.data.data);
            })
            .catch((err) => {
                cb && cb(false);
                dispatch(
                    withType(GET_NORM_BENCHMARK_VALUE_FAILED, {
                        error: err,
                    })
                );
            });
    };
};