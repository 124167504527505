import {
    CHANGE_ACTIVE_FL_NAME_FORM_CHANGE,
    CHANGE_ACTIVE_FL_NAME_STARTED,
    CHANGE_ACTIVE_FL_NAME_SUCCESS,
    CHANGE_ACTIVE_FL_NAME_FAILED,
    CHANGE_ACTIVE_FL_NAME_CLEAR
} from '../../constants';
import {UPDATE_USER_URL} from '../../../../config/constants';
import {ApiBase} from '../../../utils/apiBase';

export const onCancelNameChange = () => {
    return {
      type: CHANGE_ACTIVE_FL_NAME_CLEAR,
    };
  };

export const changeFirstLastNameFormChange = (event) => {
    return {
      type: CHANGE_ACTIVE_FL_NAME_FORM_CHANGE,
      payload: event,
    };
  };

export const changeFirstLastNameAction = (params,next) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch({
            type: CHANGE_ACTIVE_FL_NAME_STARTED
        });
        apiBaseInstance.instance
            .post(UPDATE_USER_URL, params)
            .then(res =>
                changeFirstLastNameSuccessAction(dispatch, {res,params,next})
            )

            .catch(err =>
                changeFirstLastNameFailedAction(dispatch, err)
            );
    };
};

function changeFirstLastNameSuccessAction(dispatch, {res,params,next}) {
    localStorage.setItem('firstName', params.firstName);
    localStorage.setItem('lastName', params.lastName);
    localStorage.setItem('employeeName',params.employeeName);
    next();
    return dispatch({
        type: CHANGE_ACTIVE_FL_NAME_SUCCESS,
        payload: res.data
    });
}
function changeFirstLastNameFailedAction(dispatch, err) {
    return dispatch({
        type: CHANGE_ACTIVE_FL_NAME_FAILED,
        payload: err
    });
}