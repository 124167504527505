export const mockData = [
  {
    topics: [
      'Engagement',
      'Change & Communication',
      'Diversity, Equity &Inclusion',
    ],
    title: 'Culture',
  },
  {
    topics: [
      'Innovation/Risk',
      'Org Direction',
      'Senior Leadership',
      'Management',
    ],
    title: 'Leadership',
  },
  {
    topics: ['Safety'],
    title: 'Physical',
  },
];

export const barChartProps = {
  favScore: 11,
  neutralScore: 12,
  unFavScore: 77,
};

export const questionChartProps = [
  {
    favScore: 50,
    neutralScore: 30,
    unFavScore: 20,
  },
];

export const Questions = [
  'I would recommend Acme Corp to friends or family as...',
  'This organization inspired me to do my best work...',
  'I would take a lot to get me to leave Acme Corp for...',
];

export const sortOptions = [
  { label: 'Score', value: 'score' },
  { label: 'Topic', value: 'topic' },
];

export const topicSortOptions = [
  { label: 'Ascending', value: 'ascending' },
  { label: 'Descending', value: 'descending' },
];

export const constants = {
  TOPIC: 'topic',
  CATEGORY: 'category',
  SCORE: 'score',
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  NONE: 'none',
  GENERATING_TOPICS: 'Generating Topics',
  COMPLETED: 'completed',
  PROCESSING: 'processing',
  TOPIC_FAILED:
    'Failed to generate topics. Check out the Storyboard section under Manage tab to reprocess survey topics.',
  EMPTY_MESSAGE:'No data found'  
};
