import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const deletePeopleStarted = (state) => {
    return {
        ...state,
        deletePeopleApi: getApiStartState()
    }

}

export const deletePeopleSuccess = (state, payload) => {
    return {
        ...state,
        deletePeopleApi: getSuccessResponse(payload)
    }

}

export const deletePeopleFailed = (state, payload) => {
    return {
        ...state,
        deletePeopleApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}