import {
  SET_THRESHOLD_VALUE_SUCCESS,
  SET_THRESHOLD_VALUE_FAILED,
  SET_THRESHOLD_VALUE_STARTED
} from '../../constants';
import { MODIFY_PROJECT_CONFIGURATIONS } from '../../../../config/constants';
import { withType } from '../../../utils/actionOperations';
import { ApiBase } from '../../../utils/apiBase';
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const setThresholdValue = (payload, cb, presetData) => {
  !payload.isSurveyEdited ? payload.isSurveyEdited = false : payload.isSurveyEdited = true;
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(SET_THRESHOLD_VALUE_STARTED, presetData));
    apiBaseInstance.instance
      .post(MODIFY_PROJECT_CONFIGURATIONS, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        dispatch(
          withType(SET_THRESHOLD_VALUE_SUCCESS, response.data)
        );
        cb && cb(true);
      })
      .catch((err) => {
        cb && cb(false);
        dispatch(
          withType(SET_THRESHOLD_VALUE_FAILED, {
            error: err,
          })
        );
      });
  };
};