import * as am4core from "@amcharts/amcharts4/core";
import React, { Component } from "react";
import * as am4pluginsWordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import { wordCloudData } from "./constants";
import { wordCloudSeries } from "../../../../../../../../utils/wordCloudChartConfig";
import { EmptyState } from "../../../../../../../../Packages/EmptyState";
import GraphLoader from "../../../../../../../../Components/ContentLoaders/GraphLoader";

export default class WordCloud extends Component {
  componentDidMount() {
    this.setData()
  }

  componentWillUnmount() {
    if (this.wordCloud) {
      this.wordCloud.dispose();
    }
  }

  componentDidUpdate(oldProps) {
    this.setData()
    // this.wordCloud.data = this.props.wordCloudData;
  }

  setData = () => {
    let wordCloudFields = wordCloudData.wordCloudFields;
    let wordCloud = am4core.create(
      wordCloudData.wordCloudDivId,
      am4pluginsWordCloud.WordCloud
    );
    wordCloud.responsive.enabled = true;
    wordCloud.responsive.rules.push({
      relevant: function (target) {
        if (target.pixelWidth <= 600) {
          return true;
        }
        return false;
      },
      state: function (target, stateId) {
        return true;
      },
    });
    wordCloud.data = this.props.wordCloudData;
    wordCloud.fontFamily = wordCloudFields.fontFamily;
    let series = wordCloudSeries(wordCloud, wordCloudFields);
    let hoverState = series.labels.template.states.create(
      wordCloudFields.hoverField
    );
    hoverState.properties.fill = am4core.color(wordCloudFields.hoverFill);
    wordCloud.exporting.menu = new am4core.ExportMenu();
    wordCloud.exporting.menu.items = wordCloudData.amchartmenu;
    wordCloud.exporting.timeoutDelay = 8000;
    wordCloud.exporting.filePrefix = wordCloudFields.filePrefix;
    wordCloud.logo.disabled = true;
    this.wordCloud = wordCloud;
  }

  render() {
    return (
      <>
        {this.props.props[this.props.props.question].exploreWordCloudDataApi.isProcessing ?
          <GraphLoader height={300} width={600} />
          : <>
            <h2 class="head-barchart">Word cloud</h2>
            {this.props.wordCloudData.length === 0 ? (
              <EmptyState emptyStateMessage={"No data Found"} />
            ) : (
              <div
                id={wordCloudData.wordCloudDivId}
                style={{
                  height: "500px",
                }}
              ></div>
            )}
          </>
        }
      </>
    );
  }
}
