import * as actionTypes from "../../constants";
import { ApiBase } from "../../../utils/apiBase";
import { COPY_PROJECT_URL } from "../../../../config/constants";
import { notification } from "antd";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export function copyProject(payload, callback) {
  return (dispatch) => {
    dispatch({ type: actionTypes.COPY_SURVEY_START });
    const axios = new ApiBase().instance;
    axios
      .post(COPY_PROJECT_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((res) => {
        notification.success({
          message: "Project " + payload.currentProjectName + " copied successfully.",
        });
        copyProjectSuccess.bind(null, dispatch, payload);
        callback && setTimeout(callback(true), 0);
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.COPY_SURVEY_FAIL,
          response: err,
        });
        callback&&callback(false)
      });
  };
}

function copyProjectSuccess(dispatch, payload, response) {

  return dispatch({
    type: actionTypes.COPY_SURVEY_SUCCESS,
    response,
    requestPayload: payload,
  });
}
