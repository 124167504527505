import React from "react";
import ContentLoader from "react-content-loader";

const SingleHomeLoader = () => (
  <ContentLoader
    height={100}
    width={600}
    speed={2}
    animation={true}
    primaryColor="#D3D3D3"
    secondaryColor="#ecebeb"
  >
    <rect x="20" y="0" rx="3" ry="3" width="108" height="73" />
    <rect x="135" y="0" rx="3" ry="3" width="108" height="73" />
    <rect x="249" y="0" rx="3" ry="3" width="108" height="73" />
    <rect x="363" y="0" rx="3" ry="3" width="108" height="73" />
    <rect x="478" y="0" rx="3" ry="3" width="108" height="73" />
  </ContentLoader>
);

const HomeLoader = (props) =>
  Array(3)
    .fill(null)
    .map((_, index) => <SingleHomeLoader key={index} />);

export default HomeLoader;
