export const fetchSurveyDetailsStart = (state, payload) => {
  return {
    ...state,
    surveyDetailsApi: {
      isProcessing: true,
      error: false,
      reason: '',
      surveyDetails: [],
    },
  };
};

export const fetchSurveyDetailsSuccess = (state, payload) => {
  let data =
    (payload.data &&
      payload.data.hits &&
      payload.data.hits.length &&
      payload.data.hits[0]) ||
    [];
  return {
    ...state,
    surveyDetailsApi: {
      isProcessing: false,
      error: false,
      reason: '',
      surveyDetails: data,
    },
  };
};

export const fetchSurveyDetailsFail = (state, payload) => {
  return {
    ...state,
    surveyDetailsApi: {
      isProcessing: false,
      error: true,
      reason: payload.error,
      surveyDetails: [],
    },
  };
};
