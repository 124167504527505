import { ApiBase } from "../../../utils/apiBase";
import { FETCH_HRIS_URL } from "../../../../config/constants";
import {
  FETCH_HRIS_LIST_STARTED,
  FETCH_HRIS_LIST_SUCCESS,
  FETCH_HRIS_LIST_FAILED,
} from "../../constants";
import {
  fetchHrisHierarchyFailure,
} from "./fetchHrisHierarchy";
import { fetchUsersFailure } from "./fetchUsers";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchHRIS = (params, callBack) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    //dispatch(fetchUsersStart());
    dispatch(fetchHRISStart());
    //dispatch(fetchHrisHierarchyStart());
    apiBaseInstance.instance
      .post(FETCH_HRIS_URL, { ...getHrisParams(params), ...getProjectIdAndOrgId(params) })
      .then((res) => {
        dispatch(fetchHRISSuccess(res));
        callBack && callBack(true, res);
        return res;
      })
      // .then((res) => {
      //   if (getDefaultHrisValue()) {
      //     dispatch(
      //       fetchHrisHierarchy(getParamsForFetchHierarchy(params, hrisFileId))
      //     );
      //     dispatch(fetchUsers(getParamsForFetchUser(params, hrisFileId)));
      //   } else {
      //     dispatch(fetchHrisHierarchyFailure());
      //     fetchUsersFailure(dispatch);
      //   }
      // })
      .catch((err) => {
        dispatch(fetchHRISFailure(err));
        fetchUsersFailure(dispatch);
        dispatch(fetchHrisHierarchyFailure());
        callBack && callBack(false);
      });
  };
};

export const fetchHRISOnSearch = (params) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(fetchHRISStart());
    apiBaseInstance.instance
      .post(FETCH_HRIS_URL, { ...getHrisParams(params), ...getProjectIdAndOrgId(params) })
      .then((res) => {
        dispatch(fetchHRISSuccess(res));
      })
      .catch((err) => {
        dispatch(fetchHRISFailure(err));
      });
  };
};

const fetchHRISStart = () => {
  return {
    type: FETCH_HRIS_LIST_STARTED,
  };
};

const fetchHRISSuccess = (res) => {
  return {
    type: FETCH_HRIS_LIST_SUCCESS,
    payload: res.data,
  };
  
};
const fetchHRISFailure = (error) => {
  return {
    type: FETCH_HRIS_LIST_FAILED,
    payload: error,
  };
};

const getHrisParams = (params) => {
  return {
    ...params,
    organizationId: params.organizationId,
    searchText: params.searchText,
  };
};
