export const remoteDemographicsTemplate = [
    {
        type: 'Multiple Choice',
        questionDimension: 'Demographics',
        isSelected: false,
        options: [
            {
                label: "Every day",
                value: "1"
            },
            {
                label: "1-2 days",
                value: "2"
            },
            {
                label: "3-4 days",
                value: "3"
            },
            {
                label: "Never",
                value: "4"
            }
        ],
        question: 'Remote Days/Week.',
        questionLabel: 'Remote Days/Week',
        isDemographic: true
    }
]