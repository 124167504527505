import {
  MANAGE_RESPONSES_FETCH_RESPONSES_STARTED,
  MANAGE_RESPONSES_FETCH_RESPONSES_SUCCESS,
  MANAGE_RESPONSES_FETCH_RESPONSES_FAILED,
} from "../../constants";
import { fetchPayload } from "../../../utils/reducerOperations";
import { InitialState } from "./InitialState";
import {
  fetchResponsesStart,
  fetchResponsesSuccess,
  fetchResponsesFailed,
} from "./Helper";

const NewdashboardFetchResponsesReducer = (state = InitialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case MANAGE_RESPONSES_FETCH_RESPONSES_STARTED:
      return fetchResponsesStart(state, payload);
    case MANAGE_RESPONSES_FETCH_RESPONSES_SUCCESS:
      return fetchResponsesSuccess(state, payload);
    case MANAGE_RESPONSES_FETCH_RESPONSES_FAILED:
      return fetchResponsesFailed(state, payload);
    default:
        return { ...state };
  }
};


export default NewdashboardFetchResponsesReducer;
