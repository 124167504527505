import { deepCopy } from "../../../../../../utils/deepCopy"

export const onSaveConditionalDisplayLikert = (state, payload) => {
    let addedCondition = addTopicConditionForDisplay(state, payload)
    return {
        ...state,
        goals: addedCondition
    }
}

const addTopicConditionForDisplay = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(goal => {
        if (goal.blockId === payload.blockId) {
            if(payload.save){
                goal.isConditionalTopicSection = true
            }else{
                goal.isConditionalTopicSection = false
                delete goal.hrisBasedCondition
            }
        }
    })
    return goalsCopy
}

export const onDeleteDisplayConditionLikert = (state, payload) => {
    let deletedDisplayTopic = deleteDisplayCondition(state, payload)
    return {
        ...state,
        goals: deletedDisplayTopic
    }
}

const deleteDisplayCondition = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(goal => {
        if (goal.blockId === payload.blockId) {
            goal.isConditionalTopicSection = false
            delete goal.hrisBasedCondition
        }
    })
    return goalsCopy
}


export const onChangeDisplayConditionLikert = (state, payload) => {
    let addedCondition = addTopicConditionForDisplayLikert(state, payload)
    return {
        ...state,
        goals: addedCondition
    }
}

const addTopicConditionForDisplayLikert = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(goal => {
        if (goal.blockId === payload.blockId) {

            let getCondition = generateCondition(goal, payload)
            goal.isConditionalTopicSection = true
            goal.hrisBasedCondition = getCondition
        }
    })
    return goalsCopy
}

const generateCondition = (goal, payload) => {
    if (payload.dropDownType === "SHOW_QUESTION_DROP_DOWN") {
        if (goal.hrisBasedCondition && goal.hrisBasedCondition.length > 0) {
            return [{ ...goal.hrisBasedCondition[0], showQuestion: payload.value }]
        }
        else {
            return [
                {
                    showQuestion: payload.value
                }
            ]
        }

    }
    if (payload.dropDownType === "DEMOGRAPHICS_OPTIONS_DROPDOWN") {
        if (goal.hrisBasedCondition && goal.hrisBasedCondition.length > 0) {
            return [{ ...goal.hrisBasedCondition[0], selectedDemographics: payload.value }]
        }
        else {
            return [
                {
                    selectedDemographics: payload.value
                }
            ]
        }

    }
    if (payload.dropDownType === "DEMOGRAPHICS_VALUE_DROPDOWN") {
        if (goal.hrisBasedCondition && goal.hrisBasedCondition.length > 0) {
            return [{ ...goal.hrisBasedCondition[0], [goal.hrisBasedCondition[0].selectedDemographics]: payload.value }]
        }
        return [
            {
                [goal.hrisBasedCondition[0].selectedDemographics]: payload.value
            }
        ]
    }
}