import {
    DELETE_ORGANIZATION_STARTED,
    DELETE_ORGANIZATION_SUCCESS,
    DELETE_ORGANIZATION_FAILED
} from '../../constants';
import { ApiBase } from '../../../utils/apiBase';
import { DELETE_ORGANIZATION_URL } from '../../../../config/constants';
import { withType } from '../../../utils/actionOperations';
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';
export const deleteOrganization = (params, callback) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(DELETE_ORGANIZATION_STARTED, params));
        apiBaseInstance.instance
            .post(DELETE_ORGANIZATION_URL, { ...params, ...getProjectIdAndOrgId(params) })
            .then(res => {
                deleteORganizationSuccessAction(
                    dispatch, { ...res.data, ...params });
                callback && callback();
            })
            .catch(err => {
                deleteOrganizationFailedAction(
                    dispatch, { err: err, params: params });
            });
    };
};

function deleteORganizationSuccessAction(dispatch, response) {
    return dispatch(
        withType(DELETE_ORGANIZATION_SUCCESS, response)
    );
}
function deleteOrganizationFailedAction(dispatch, error) {
    return dispatch(
        withType(DELETE_ORGANIZATION_FAILED, {
            ...error.err,
            ...error.params
        })
    );
}
