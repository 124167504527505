import {
  FETCH_USERS_ADMIN_LIST_STARTED,
  FETCH_USERS_ADMIN_LIST_FAILED,
  FETCH_USERS_ADMIN_LIST_SUCCESS,
} from "../../constants";
import { SEARCH_USERS_URL } from "../../../../config/constants";
import { getOrgId } from "../../../utils/localStorgeOperations";
import { ApiBase } from "../../../utils/apiBase"
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const searchUsersForAdmin = (params) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(searchUsersForAdminStart());
    apiBaseInstance.instance
      .post(SEARCH_USERS_URL, { ...getParamsForSearch(params), ...getProjectIdAndOrgId(getParamsForSearch(params)) })
      .then((res) => searchUsersForAdminSuccess(dispatch, res))
      .catch((err) => searchUsersForAdminFailure(dispatch, err));
  };
};

function searchUsersForAdminSuccess(dispatch, res) {
  return dispatch({
    payload: res.data,
    type: FETCH_USERS_ADMIN_LIST_SUCCESS,
  });
}
export const searchUsersForAdminStart = () => {
  return {
    type: FETCH_USERS_ADMIN_LIST_STARTED,
  };
};
export function searchUsersForAdminFailure(dispatch, err) {
  return dispatch({
    payload: err,
    type: FETCH_USERS_ADMIN_LIST_FAILED,
  });
}
const getParamsForSearch = (params) => {
  return {
    ...params,
    hrisFileId: params.hrisFileId,
    organizationId: getOrgId(),
    size: 12,
    from: params.from
  };
};
