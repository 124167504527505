export const addTrendDataToTable = (tableData, showActualScore) => {
    let updatedTableData = []
    tableData.forEach((item, key) => {
      if (item.trend && item.trend.length > 0) {
        let trendDetails = {}
        item.trend.forEach((trendData, index) => {
          let score = 0
          let negative = false
          if (trendData.trendScore < 0) {
            score = showActualScore ? Math.abs(item.favScore - trendData.trendScore) : trendData.trendScore
            negative = true
          } else {
            score = showActualScore ? Math.abs(item.favScore - trendData.trendScore) : trendData.trendScore
          }
          trendDetails = { ...trendDetails, [trendData.projectId]: {score,negative} }
        })
        updatedTableData.push({ ...item, ...trendDetails })
      }
      else {
        updatedTableData.push(item)
      }
    })
  return updatedTableData
}

export const addTrendDataToTableDownload = (tableData, showActualScore) => {
  let updatedTableData = []
  let trendAddedProject = []
  tableData.forEach((item, key) => {
    if (item.trend && item.trend.length > 0) {
      let trendDetails = {}
      item.trend.forEach((trendData, index) => {
        let score = 0
        let negative = false
        if (trendData.trendScore < 0) {
          score = showActualScore ? Math.abs(item.favScore - trendData.trendScore) : trendData.trendScore
          negative = true
        } else {
          score = showActualScore ? Math.abs(item.favScore - trendData.trendScore) : trendData.trendScore
        }
        if (!trendAddedProject.includes(trendData.projectId)) {
          trendAddedProject.push(trendData.projectId)
        }
        trendDetails = { ...trendDetails, [trendData.projectId]: score, benchMark: item.benchmarkScore ? item.benchmarkScore : "-" }
      })
      updatedTableData.push({ ...item, ...trendDetails })
    }
    else {
      updatedTableData.push({ ...item, benchMark: item.benchmarkScore ? item.benchmarkScore : "-" })
    }
  })
  if (trendAddedProject.length > 0) {
    let trendUpdatedtableData = []
    updatedTableData.forEach((tableData => {
      let generatedObject = {}
      trendAddedProject.forEach((trendProject) => {
        if (!(Object.keys(tableData).includes(trendProject))) {
          generatedObject[trendProject] = "-"
        }
      })
      trendUpdatedtableData.push({ ...generatedObject, ...tableData })
    }))
    return trendUpdatedtableData
  }
  return updatedTableData
}