export const fetchThemeTopicDataStart = (state, payload) => {
  return {
    ...state,
    [payload.questionId]: {
      ...state[payload.questionId],
      themeTopicDataApi: {
        isProcessing: true,
        data: [],
        columns: [],
        selectedColumns: [],
        error: false,
        message: "",
      },
    }
  };
};

export const fetchThemeTopicDataSuccess = (state, payload) => {
  const columns = payload.data.columns || [];
  const selectedColumns =
    payload.data.selectedColumns && payload.data.selectedColumns.length
      ? payload.data.selectedColumns
      : Object.keys(payload.data.data || {});
  const data = formatResponse(payload.data.data);
  return {
    ...state,
    [payload.questionId]: {
      ...state[payload.questionId],
      themeTopicDataApi: {
        isProcessing: false,
        data,
        columns,
        selectedColumns,
        error: false,
        message: "",
        isInitialApiCalled: true,
      },
      isDataAlreadyFetched: true,
      filters: payload.filters,
      byScore: payload.byScore,
      multipleSelect: payload.multipleSelect,
      selectedUser: payload.selectedUser,
      directReporteesOnly: payload.directReporteesOnly,
    },
  };
};

export const fetchThemeTopicDataFailed = (state, payload) => {
  return {
    ...state,
    [payload.questionId]: {
      ...state[payload.questionId],
      themeTopicDataApi: {
        isProcessing: false,
        data: [],
        columns: [],
        selectedColumns: [],
        error: true,
        message: payload.error,
      },
    }
  };
};

const formatResponse = (data) => {
  let formattedResponse = [];
  let dataWithScore = calculateScore(data);
  if (dataWithScore.length) {
    let highestScore = getHighestCount(dataWithScore);
    formattedResponse = getThemeGraphData(dataWithScore, highestScore);
  }
  return formattedResponse;
};

const calculateScore = (data) => {
  if (data && Object.keys(data).length) {
    let formattedData = [];
    Object.keys(data).forEach((key) => {
      const totalCommentsCount = data[key].filteredCommentsCount;
      const histogramData = data[key].histogramData;
      const formattedGraphData = histogramData.map((item) => {
        let graphData = {
          label: item.node,
          subLabel: `${item.count} of ${key === "Average Score" ? totalCommentsCount : item.secondaryCount
            } Respondents`,
          value:
            key === "Average Score"
              ? Math.round((item.count / totalCommentsCount) * 100)
              : item.percentage,
          threshold: item.count,
        };
        return graphData;
      });
      key === "Average Score"
        ? formattedData.unshift({ key: key, value: formattedGraphData })
        : formattedData.push({ key: key, value: formattedGraphData });
    });
    return formattedData;
  }
  return [];
};

const getHighestCount = (data) => {
  let scores = data.map((item) => {
    return (item.value.length && item.value[0].value) || 0;
  });
  const highestScore = scores.reduce((a, b) => (a > b ? a : b));
  return highestScore;
};

const getThemeGraphData = (data, highestScore) => {
  let multiplier = 1
  if (highestScore && highestScore > 80) {
    multiplier = 100
  } else {
    multiplier = 75
  }
  let graphData = data.map((item) => {
    let value = item.value.map((element) => {
      return {
        ...element,
        width: Math.round((element.value / highestScore) * multiplier),
      };
    });
    return { ...item, value };
  });
  return graphData;
};
