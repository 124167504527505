import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const csvDownloadStarted = (state) => {
    return {
        ...state,
        csvDownloadApi: getApiStartState()
    }

}

export const csvDownloadSuccess = (state, payload) => {
    return {
        ...state,
        csvDownloadApi: getSuccessResponse(payload)
    }

}

export const csvDownloadFailed = (state, payload) => {
    return {
        ...state,
        csvDownloadApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}