import {
  update,
  makeKey,
  handleApiResponseError,
  handleNetworkError,
} from "../../../../utils/reducerOperations";
import { appendUserRoles } from "../../UsersPageReducer/Helpers/appendUserRole";

export function fetchUsersForAdminStarted(state, payload) {
  return makeKey(
    "fetchUsersForAdminApi",
    update(state.fetchUsersForAdminApi, { isProcessing: true, loading: true })
  );
}

export function fetchUsersForAdminSuccess(state, payload) {
  let pageKeys = [];
  let nextPageEnabled = false;
  if (payload.data.LastEvaluatedKey) {
    pageKeys[0] = payload.data.LastEvaluatedKey;
    nextPageEnabled = true;
  }
  return makeKey(
    "fetchUsersForAdminApi",
    update(state.fetchUsersForAdminApi, {
      message: payload.message,
      isProcessing: false,
      loading: false,
      previousPageEnabled: false,
      count: payload.data.count,
      nextPageEnabled: nextPageEnabled,
      error: !payload.status,
      data: appendUserRoles(payload.data.paginatedUserData),
      page: 0,
      pageKeys: pageKeys,
    })
  );
}

export function fetchUsersForAdminFailure(state, payload) {
  return makeKey(
    "fetchUsersForAdminApi",
    update(
      state.fetchUsersForAdminApi,
      payload.response && payload.response.data
        ? handleApiResponseError(payload.response.data)
        : handleNetworkError(payload),
      { loading: false }
    )
  );
}

export const hrisFileChange = (state, payload) => {
  return {
    ...state,
    hrisFileValue: payload.value,
    hrisFileName: payload.name,
  };
};
