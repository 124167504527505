
export const fetchProjectsTabConfigurationStart = (state, payload) => {
    return {
        ...state,
        projectsPageTabConfigurationApi: {
            isProcessing: true,
            error: false,
            data: false
        }
    };
}

export const fetchProjectsTabConfigurationSuccess = (state, payload, searchText) => {
    let projectCount = payload.total
    if (searchText !== '' && payload.total === 0) {
        projectCount = 1
    }
    return {
        ...state,
        projectCount: projectCount,
        projectsPageTabConfigurationApi: {
            isProcessing: false,
            data: true,
            tabConfig: payload,
            count: payload.total
        },
    };
}

export const fetchProjectsTabConfigurationFail = (state, payload) => {
    return {
        ...state,
        projectsPageTabConfigurationApi: {
            isProcessing: false,
            error: true,
            data: false
        },
    };
};