import React from "react";
import { TextInput } from "../../FormElements/TextInput";
import "./index.scss";
import SVGIcons from '../../Components/SVGIcons';
class SearchInput extends React.Component {

  render() {
    return (
      <div className="seach-box">
        <a href={false} onClick={this.props.mousePress} className="seach-box__icon">
          <span >
          <SVGIcons type={'SVG-lens'} />
          </span>
        </a>
        <TextInput
          className='seach-input'
          placeholder={this.props.placeholder?this.props.placeholder:"Search"}
          value={this.props.value}
          handleChange={this.props.onChange}
          onKeyDown={this.props.keyPress}
          onPressEnter={this.props.onPressEnter}
          htmlType="text"
          name="search-input-box"
        />
        {this.props.clearSearch ?
          <a href={false} className="seach-box__clear" onClick={this.props.clearSearch}>
            <span>
            <SVGIcons type={'SVG-circle-cross'}/>
            </span>
          </a> : ""
        }
      </div>
    );
  }
}
export default SearchInput;
