import React, { Component } from 'react'
import { ButtonElement } from "../../../../../../FormElements/ButtonElement";
import { Input } from 'antd';
import parse from "html-react-parser";
import { getQuestionText } from './helpers';
import { getQuestionTranslation, getTextForTranslation } from '../../../../../../utils/newAttendSurveyUtils';
import { KEY_CONSTANTS } from '../../constants';
import "./index.scss";
import ErrorBlock from '../ErrorBlock';
import { AsterikLabel } from '../../../../../../Components/AsterikLabel';
import { getClassNameForMatrix } from '../../helper';
const { TextArea } = Input;
export default class OpenEndedQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textAnswer: ""
    }
  }

  setAnswer = (e) => {
    this.props.updateAnswer({
      value: e.target.value,
      question: this.props.questionDetails.questions[0],
      blockId: this.props.questionDetails.blockId
    })

    setTimeout(() => {
      this.props.updateCurrentIndex()
    }, 200)

  }

  onSubmitAnswer = () => {
    this.props.handleGoPrevAndNext(false, false)
  }

  render() {
        return (
      <>
        <div className="surveyopenend-block">
          <div className='surveyopenend-block__header'>
                <h3 className={this.props.questionDetails.isRequired ? 'heading-asterisk' : ""}>{this.props.selectedLanguage === KEY_CONSTANTS.DEFAULT_LANGUAGE ? parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: this.props.questionDetails.questions[0], key: getQuestionText({ questionDetails: this.props.questionDetails, relation: this.props.relation, projectType: this.props.surveyQuestionSetData.projectType }) })) : <p>{parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: this.props.questionDetails.questions[0], key: getQuestionText({ questionDetails: this.props.questionDetails, relation: this.props.relation, projectType: this.props.surveyQuestionSetData.projectType }) }))}</p>}</h3>
          </div>
          <div className='surveyopenend-block__textinput'>
            <TextArea
              key = {this.props.questionDetails.questions && this.props.questionDetails.questions[0].qid}
              className='openend-textarea'
              test-name="text-entry"
              autoSize={{ minRows: 2, maxRows: 3000 }}
              onChange={(e) => { this.setAnswer(e) }}
              value={this.props.currentQuestionSet.answer}
                  placeholder={"Enter response in any language"
                    //   getTextForTranslation({
                    //   language: this.props.selectedLanguage,
                    //   defaultTexts: this.props.defaultTexts,
                    //   translatedTexts: this.props.translatedTexts,
                    //   key: KEY_CONSTANTS.PLACEHOLDER_LABEL
                    // })
                  }
            />
            <div className='surveyopenend-block__button'>
              {/* <ButtonElement type={'primary'} className='openended-btn' onClick={() => this.onSubmitAnswer()}>
                {getTextForTranslation({
                  language: this.props.selectedLanguage,
                  defaultTexts: this.props.defaultTexts,
                  translatedTexts: this.props.translatedTexts,
                  key: KEY_CONSTANTS.CONTINUE_BUTTON
                })}
              </ButtonElement> */}
            </div>
            <ErrorBlock 
            manageScroller={this.props.manageScroller} />
          </div >
        </div>
      </>
    )
  }
}
