import {
  FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_START,
  FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_SUCCESS,
  FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_FAIL,
} from "../../../constants";
import { NLP_DEMOGRAPHIC_TOPICS_DATA_URL } from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
import { getProjectIdAndOrgId } from "../../../../utils/apiUtils";

export const fetchDemographicTopicData = (params = {}) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch({
      type: FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_START,
      payload:{questionId : params.selectedQuestionId}
    });
    apiBaseInstance
      .post(NLP_DEMOGRAPHIC_TOPICS_DATA_URL, {
        // ...NLPCommonReqBodies(),
        // ...getUserDataFromTree(),
        ...getProjectIdAndOrgId(params),
        ...params,
      })
      .then((res) =>
        fetchComparisonTopicsDataSuccess({ dispatch, res, params })
      )
      .catch((err) => fetchComparisonTopicsDataFailed(dispatch, err,params));
  };
};

function fetchComparisonTopicsDataSuccess({ dispatch, res, params }) {
  const data = (res.data && res.data.data) || {};
  return dispatch({
    type: FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_SUCCESS,
    payload: {
      data: data,
      filters:params.filters,
      byScore: params.byScore,
      multipleSelect: params.multipleSelect,
      selectedUser: params.selectedUser,
      directReporteesOnly: params.directReporteesOnly,
      questionId : params.selectedQuestionId
    },
  });
}

function fetchComparisonTopicsDataFailed(dispatch, err,params) {
  return dispatch({
    type: FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_FAIL,
    payload: { error: err.message ,questionId : params.selectedQuestionId},
  });
}
