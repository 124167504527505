import * as actionTypes from '../../constants';
import { ApiBase } from '../../../utils/apiBase';
import { SAVE_PROJECT_URL } from '../../../../config/constants';
import { mapKeysForApi } from '../../../utils/projectsMapKeys';
import { deepCopy } from '../../../utils/deepCopy'
import { getValueFromQueryString } from '../../../utils/getValueFromUrl';
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export function saveProject(payload = {}, preview, callback) {
    const initialPayload = deepCopy(payload);
    const isWizard = payload.isEditor ? false : true;
    payload = formatdata(payload, payload.isEditor);
    const axios = new ApiBase().instance;
    return dispatch => {
        dispatch({ type: actionTypes.SAVE_PROJECT_START });
        axios.post(SAVE_PROJECT_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(res => saveProjectSuccess({ res, dispatch, preview, callback, initialPayload, isWizard }))
            .catch(e => saveProjectFailed(e, dispatch));
    };
}

function formatdata(payload, isEditor) {
    payload.projectStatus = checkProjectStatus(payload);//'draft';
    payload.questionCount = getQuestionCount(payload.questions)
    payload.blockCount = payload.questions ? payload.questions.length : 0;
    if (isEditor) {
        payload.isEditor = undefined;
        payload = mapQuestionSetToGoals(payload)
    }
    payload = mapKeysForApi(payload);
    return payload;
}
function checkProjectStatus(payload) {
    if (payload.projectStatus === "published" || payload.projectStatus === "scheduled") {

        return payload.projectStatus;
    }
    else {
        return "draft";
    }
}
function getQuestionCount(questionSet) {
    let questions = [];
    let matrixCount = 0;
    if (questionSet && questionSet.length) {
        questionSet.forEach(item => {
            if (item.isDefault) {
                matrixCount = matrixCount + item.questions[0].matrixQuestions.length
            } else {
                questions = questions.concat(item.questions)
            }
        })
    }
    questions = questions.filter(item => item.type !== "Page Break")
    const count = questions.length + matrixCount;
    return count
}


function saveProjectSuccess({ res, dispatch, preview, callback, initialPayload, isWizard }) {
    const isEdit = getValueFromQueryString("edit") || initialPayload.isSurveyEdited;
    if (!isEdit) {
        initialPayload.projectStatus = 'draft'
    }
    callback && callback(initialPayload)
    if (preview) {
        let path = `${window.location.origin}/preview-project?projectId=${initialPayload.projectId}`;
        window.open(path)
    }
    if (isWizard)
        dispatch({
            type: actionTypes.SET_WIZARD_CONFIG,
            payload: { config: initialPayload }
        })
    return dispatch({
        type: actionTypes.SAVE_PROJECT_SUCCESS,
        response: res.data
    });
};

function saveProjectFailed(error, dispatch) {
    return dispatch({
        type: actionTypes.SAVE_PROJECT_FAIL,
        response: error
    });
};


const mapQuestionSetToGoals = (config) => {
    const goals = config.goals;
    const questions = config.questions;
    questions.forEach(block => {
        let selectedGoal = goals.find(goal => goal.goalId === block.goalId)
        if (selectedGoal) {
            selectedGoal = mapChangesToGoal(selectedGoal, block)
        }
    })
    console.log('Goals', goals)
    console.log('Config', config.goals)
    return config;
}

const mapChangesToGoal = (goal, editedBlock) => {
    goal.dimension = editedBlock.dimension;
    if (goal.isDefault) { //matrix question templates
        goal = mapChangesToMatrixQuestions(goal, editedBlock)
    } else { // non-matrix questions
        goal = mapChangesToNonMatrixQuestions(goal, editedBlock)
    }
    return goal
}

const mapChangesToMatrixQuestions = (goal, editedBlock) => {
    goal.questions[0].questionDimension = editedBlock.dimension;
    goal.questions[0].questionLabel = editedBlock.dimension;
    goal.questions[0].question = editedBlock.questions[0].question;
    goal.questions[0].options = editedBlock.questions[0].options;
    editedBlock.questions[0].matrixQuestions.forEach(item => {
        let matrixQuestion = goal.questions[0].matrixQuestions.find(
            question => question.value === item.value);
        if (matrixQuestion) {
            matrixQuestion.isSelected = true;
            matrixQuestion.text = item.text;
        } else {
            item.isSelected = true;
            goal.questions[0].matrixQuestions.push(item);
        }
    })
    return goal
}

const mapChangesToNonMatrixQuestions = (goal, editedBlock) => {
    editedBlock.questions.forEach((item, index) => {
        let question = item.qid ? goal.questions.find(
            question => question.qid === item.qid) : undefined;
        if (question) {
            if (goal.isTextEntryOnly) {
                item.options = []
            }
            item.questionDimension = editedBlock.dimension;
            goal.questions[goal.questions.indexOf(question)] = item;
        }

    })
    return goal
}