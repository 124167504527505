import { setInitialState } from "../../../utils/reducerOperations";
export function Default() {
    return {
        storyboardParticipationApi: setInitialState(),
        overallParticipationRate: {
            isDataFetched: false,
            projectId: undefined
        },
    };
}
