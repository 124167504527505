import { Default } from './InitialState';
import {
    update,
    fetchPayload
} from '../../../utils/reducerOperations';
import * as helper from './helper';
import {
    FETCH_SCORES_FAIL,FETCH_SCORES_STARTED,FETCH_SCORES_SUCCESS
} from '../../constants';

const ScoresPageReducer = (state=Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
    case FETCH_SCORES_STARTED:
        return update(state,
            helper.fetchScoresTableStarted(state, payload)
        );
    case FETCH_SCORES_SUCCESS:
        return update(state,
            helper.fetchScoresTableSuccess(state, payload)
        );
    case FETCH_SCORES_FAIL:
        return update(state,
            helper.fetchScoresTableFailed(state, payload)
        );
    default: return {...state};
    }
};

export default ScoresPageReducer;