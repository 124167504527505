import { Excel } from 'antd-table-saveas-excel';

export const downloadAsExcel = ({ data, coloumns, name }) => {
    const excel = new Excel();
    excel.defaultTheadCellStyle = { ...excel.defaultTheadCellStyle, background: "ffffff", v: "center", h: "center", fontName: "Inter" }
    excel.defaultTbodyCellStyle = { ...excel.defaultTbodyCellStyle, border: true, v: "center", h: "center", fontName: "Inter" }
    excel.addSheet('test')
    excel.addColumns(coloumns)
    excel.addDataSource(data)
    excel.sheet = handleAlignLeftMostColoumn(excel.sheet)
    excel.saveAs(`${name}.xlsx`);
}

const handleAlignLeftMostColoumn = (sheet) => {
    let sheetCopy = sheet
    sheetCopy.rows.forEach((rowElement, rowIndex) => {
    })
    return sheetCopy
}