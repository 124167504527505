export default {
  contactForm_heading: 'Let\'s Chat',
  contactForm_para: "Contact us to discuss your need and get a \n    demo for your business.Simplyfill\n     out the form and we will be in touch as soon as possible.",
  sendMailFailed: "Sorry, Something went wrong. Please try \n    again later.",
  sendMailSuccess: "Thank you ! \n    We appreciate your interest. Our team will contact you shortly.",
  contactForm_left_heading: 'Contact Us',
  contactForm_right_para: "If you are a current customer looking \n    for support, please contact ",
  contactForm_mail_link: 'mailto:support@culturebie.com',
  contactForm_mail: 'support@culturebie.com',
  contactForm_button: 'Contact us',
  contactForm_checkbox_para: "Please select the product or service \n    you would like to talk about",
  checkboxData: ['Talent KPI Dashboards', 'HR Data Quality Audits', 'Talent Analytics Software', 'Text Data Analytics', 'Building Talent Analytics Function', 'Trainings and Workshop', 'Other'],
  contact_toAddresses: ['support@culturebie.com'],
  contact_mailSubject: 'Culturebie Demonstration Enquiry'
};