import {
    SURVEY_WIZARD_MODIFY_PROJECT_DATA_START,
    SURVEY_WIZARD_MODIFY_PROJECT_DATA_SUCCESS,
    SURVEY_WIZARD_MODIFY_PROJECT_DATA_FAIL,
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { MODIFY_PROJECT_DATA } from "../../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const surveyWizardModifyProjectData = (payload, cb, presetData) => {
    !payload.isSurveyEdited ? payload.isSurveyEdited=false:payload.isSurveyEdited=true;
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
        dispatch(withType(SURVEY_WIZARD_MODIFY_PROJECT_DATA_START, presetData));
        apiBaseInstance.instance
            .post(MODIFY_PROJECT_DATA, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then((response) => {
                dispatch(
                    withType(SURVEY_WIZARD_MODIFY_PROJECT_DATA_SUCCESS, response.data)
                );
                cb && cb(true);
            })
            .catch((err) => {
                cb && cb(false);
                dispatch(
                    withType(SURVEY_WIZARD_MODIFY_PROJECT_DATA_FAIL, {
                        error: err,
                    })
                );
            });
    };
};