import { deepCopy } from "../../../../../utils/deepCopy"
import { toast } from '../../../../../utils/messageUtils';

export const deleteTopic = (state, payload) => {
    let goals = deleteTopicFromGoals(state.goals, payload)
    return {
        ...state,
        goals: goals
    }
}

const deleteTopicFromGoals = (goals,payload)=>{
    let goalsCopy = deepCopy(goals)
    let topicDeletedGoals = goalsCopy.filter(item=>item.goalId!==payload.goalId)
    let message = payload.isDescriptive?"Descriptive text":"Topic"
    if(payload.projectType === "Very_Simple_Survey"){
        message = "Question group"
    }
        toast.success(`${message} deleted successfully`)
    return topicDeletedGoals
}