export const empowermentTemplate = [
    {
        questionDimension: 'Empowerment',
        type: 'Matrix',
        questionLabel: 'Empowerment',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: 'I can make an impact at my organization.', value: '', isSelected: true
        },
        {
            text: 'I am involved in making decisions that affect me and my work.', value: '', isSelected: true

        },
        {
            text: 'I have everything I need to do my job effectively.', value: '', isSelected: true

        },
        {
            text: `I know how my work contributes to my organization's goals.`, value: '', isSelected: false

        }
        ]
    }
]
