import { apiInitialState } from '../../../utils/apiStateUtils';

export default {
    sentimentApi: apiInitialState(),
    sentimentTopicApi: apiInitialState(),
    hasMore: true,
    batchIdsApi:apiInitialState(),
    filtersApi:{
        ...apiInitialState(),
        data:[]
    },
    activeFacet:''
};