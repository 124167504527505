import React, { Component } from 'react';
import './index.scss'
import ErrorBlock from '../ErrorBlock';
import parse from "html-react-parser";

import { AsterikLabel } from '../../../../../../Components/AsterikLabel';
import { getQuestionTranslation } from '../../../../../../utils/newAttendSurveyUtils';
import { KEY_CONSTANTS } from '../../constants';

class NetPromoterScore extends Component {

    handleClick = (index) => {
        this.props.handleChangeNetPromoter({ index: index, blockId: this.props.questionDetails.blockId })
        setTimeout(() => {
            this.props.updateCurrentIndex()
        }, 400)
    }


    getQuestionBox = (question) => {
        let answer = this.props.currentQuestionSet && this.props.currentQuestionSet.answer !== undefined ? this.props.currentQuestionSet.answer : undefined
        let total = question.totalPromotingScore
        let renderComponent = []
        for (let index = 0; index < total; index++) {
            renderComponent.push(<div className={`promo-block__area--box ${index === answer ? "netScoreBoxColoured" : ""}`}
                onClick={() => this.handleClick(index)}
                key={index} >
                <span>{index}</span>
            </div>
            );
        }

        return renderComponent

    }

    getOptionsSection = (question) => {
        return (<div className='promo-textsurvey'>
            {question.options && question.options.map((item) =>
                <div>
                    {parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: this.props.selectedLanguage }))}
                </div>)}
        </div>)

    }

    render() {
        let currentBlock = this.props.questionDetails
        return (
            <>
                {this.props.questionDetails.questions.map((item, index) =>
                    <div> <h3 className='answer-rating-head'>{parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: item.HTMLQuestion ? KEY_CONSTANTS.HTML_QUESTION : KEY_CONSTANTS.QUESTION }))} {this.props.questionDetails.isRequired && < AsterikLabel className="required-icon" />}</h3>
                        <div className={` ${currentBlock && currentBlock.blockImage ? `${this.props.currentClassname}promo-block-img` : `${this.props.currentClassname}promo-block`}`}>
                            <div className={`${this.props.currentClassname}promo-block__area`}> {this.getQuestionBox(item)}</div>
                            <div className={`${this.props.currentClassname}promo-block__bottom`}>{this.getOptionsSection(item)}</div>
                    </div>
                    </div> )}
                <ErrorBlock manageScroller={this.props.manageScroller}/>
</>
        );
    }
}

export default NetPromoterScore;
