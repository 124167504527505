import {

    SURVEY_EDITOR_DESCRIPTIVE_SECTION_CHANGE
} from "../../../../../constants";


export const handleChangeDescriptiveText = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_DESCRIPTIVE_SECTION_CHANGE, payload });
    }
};