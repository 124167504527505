import React from "react";
import { CustomTable } from "../../../../../../../../Components/CustomTable";
import { EmptyState } from "../../../../../../../../Packages/EmptyState";
import { checkIfAnyFilterIsApplied } from "../../../../../../../../utils/filterUtils";
import constants, { ADDED_COLOUMN_DOWNLOAD, addTrendColomn } from "./constants";
import { addTrendDataToTable, addTrendDataToTableDownload } from "./helpers";
import { checkTrendProjects } from "../../../../../../../../utils/trendUtils";
import { getMatchedSurveyDetails } from "../../../../helper";
import { Switch } from "antd";
import { ButtonElement } from "../../../../../../../../FormElements/ButtonElement";
import { getDataFromUrlToken } from "../../../../../../../../utils/urlDataStore";
import { URL_DATA } from "../../../../../../../../../config/urlDataConstants";
import { downloadAsExcel } from "../../../../../../../../utils/parseAndDownloadExcel";
import SVGIcons from "../../../../../../../../Components/SVGIcons";

class AdminBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showActualTrendScore: false,
    };
  }

  getTableProps = () => {
    return {
      dontShowtrend: checkIfAnyFilterIsApplied(),
      showActualScore: this.state.showActualTrendScore,
      threshold:
        this.props.thresholdData && this.props.thresholdData.filterThreshold
          ? this.props.thresholdData.filterThreshold
          : 0,
      benchmarkTooltip:
        this.props.thresholdApi.data &&
        this.props.thresholdApi.data.benchmarkTooltip
          ? this.props.thresholdApi.data.benchmarkTooltip
          : "",
    };
  };

  getData = () => {
    let updatedDataWithTrend = addTrendDataToTable(
      this.props.adminBarchartApi.data.dimensionTable,
      this.state.showActualTrendScore
    );
    return updatedDataWithTrend;
  };

  getTrendColumn = () => {
    let trendColomns = getMatchedSurveyDetails(
      this.props.getTrendProjectsApi.data
    );
    return trendColomns;
  };

  getColumn = (data) => {
    if (this.getTrendColumn().length > 0) {
      let addedColomnForTrend = addTrendColomn(
        this.getTrendColumn(),
        this.getTableProps()
      );
      data.splice(2, 0, ...addedColomnForTrend);

      return data;
    }
    return [...data];
  };

  handleDownload = () => {
    let coloumnData = this.getColumn(
      constants.tableColumns(this.getTableProps())
    );
    let excelData = addTrendDataToTableDownload(
      this.props.adminBarchartApi.data.dimensionTable,
      this.state.showActualTrendScore
    );
    let formattedColoumns = [];
    coloumnData.forEach((columns) => {
      if (columns.key === "chart") {
        formattedColoumns.push(...ADDED_COLOUMN_DOWNLOAD);
      } else if (columns.key === "benchmark") {
        columns.title = "Benchmark";
        delete columns.render;
        delete columns.width;
        formattedColoumns.push(columns);
      } else {
        delete columns.render;
        delete columns.width;
        columns.title = columns.title ? columns.title : "Topic";
        if (columns.title) {
          formattedColoumns.push(columns);
        }
      }
    });
    const projectDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS);
    let fileName = projectDetails.projectName
      ? `${projectDetails.projectName}-topics-${new Date().toISOString()}`
      : "topics";
    downloadAsExcel({
      data: excelData,
      coloumns: formattedColoumns,
      name: fileName,
    });
  };

  render() {
    return this.props.adminBarchartApi.error ? (
      <EmptyState
        emptyStateMessage={constants.ERROR_MESSAGE}
        customHeight="400"
      />
    ) : (
      <>
        <div className="question-table-right ">
          {this.getTrendColumn().length > 0 && (
            <span className="trend-actual-score content-end">
              <p className="trend-actual-score__label">
                Show trend actual score
              </p>
              <Switch
                checked={this.state.showActualTrendScore}
                onChange={(e) => {
                  this.setState({ showActualTrendScore: e });
                }}
              />
            </span>
          )}
          <ButtonElement
            className="download-heatmap"
            onClick={this.handleDownload}
          >
            <SVGIcons type={"SVG-download"} /> Download As Excel
          </ButtonElement>
        </div>

        <CustomTable
          scroll={{ x: 1000 }}
          className="no-space"
          dataSource={this.getData()}
          columns={this.getColumn(constants.tableColumns(this.getTableProps()))}
          onChange={this.props.onChange}
          pagination={false}
          loading={this.props.adminBarchartApi.isProcessing}
        />
        <div className="topics-block__bottom">
          <p>
            <div className="color-block favourable"></div>Favorable
          </p>
          <p>
            <div className="color-block neutral"></div>Neutral
          </p>
          <p>
            <div className="color-block unfavourable"></div>Unfavorable
          </p>
        </div>
      </>
    );
  }
}
export default AdminBarChart;
