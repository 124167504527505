import * as helper from "./helper";
import * as actionTypes from "../../constants";
import initialState from "./intialState";

const NLPComparisonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NLP_TOPICSDATA_START:
      return helper.fetchTopicsDataStart(state, action);
    case actionTypes.FETCH_NLP_TOPICSDATA_SUCCESS:
      return helper.fetchTopicsDataSuccess(state, action);
    case actionTypes.FETCH_NLP_TOPICSDATA_FAIL:
      return helper.fetchTopicsDataFail(state, action);
    case actionTypes.FETCH_DEMOGRAPHIC_SNAPSHOT_START:
      return helper.fetchSnashotDataStart(state, action);
    case actionTypes.FETCH_DEMOGRAPHIC_SNAPSHOT_SUCCESS:
      return helper.fetchSnashotDataSuccess(state, action);
    case actionTypes.FETCH_DEMOGRAPHIC_SNAPSHOT_FAIL:
      return helper.fetchSnashotDataFail(state, action);
    case actionTypes.COMPARISONTAB_CHANGE_TOPICS_DROPDOWN:
      return helper.changeTopicsDropdown(state, action);
    case actionTypes.COMPARISONTAB_UPDATE_COLUMNS_LIST:
      return helper.updateSelectedColumns(state, action);
    case actionTypes.COMPARISONTAB_UPDATE_NUMERIC_COLUMNS_LIST:
      return helper.updateNumericSelectedColumns(state, action);
    case actionTypes.FETCH_NLPHEATMAP_DATA_START:
      return helper.fetchHeatmapStarted(state, action);
    case actionTypes.FETCH_NLPHEATMAP_DATA_SUCCESS:
      return helper.fetchHeatmapSuccess(state, action);
    case actionTypes.FETCH_NLPHEATMAP_DATA_FAIL:
      return helper.fetchHeatmapFailed(state, action);
    case actionTypes.FETCH_NLP_HEATMAP_DATA_START:
      return helper.fetchNLPHeatmapStarted(state, action);
    case actionTypes.FETCH_NLP_HEATMAP_DATA_SUCCESS:
      return helper.fetchNLPHeatmapSuccess(state, action);
    case actionTypes.FETCH_NLP_HEATMAP_DATA_FAIL:
      return helper.fetchNLPHeatmapFailed(state, action);
    case actionTypes.FETCH_NLP_HEATMAP_QUESTION_DATA_START:
      return helper.fetchNLPHeatmapQuestionStarted(state, action);
    case actionTypes.FETCH_NLP_HEATMAP_QUESTION_DATA_SUCCESS:
      return helper.fetchNLPHeatmapQuestionSuccess(state, action);
    case actionTypes.FETCH_NLP_HEATMAP_QUESTION_DATA_FAIL:
      return helper.fetchNLPHeatmapQuestionFailed(state, action);
    case actionTypes.FETCH_NUMERIC_DEMOGRAPHICS_START:
      return helper.fetchNumericDemographicsStart(state);
    case actionTypes.FETCH_NUMERIC_DEMOGRAPHICS_SUCCESS:
      return helper.fetchNumericDemographicsSuccess(state, action);
    case actionTypes.FETCH_NUMERIC_DEMOGRAPHICS_FAIL:
      return helper.fetchNumericDemographicsFail(state, action);
    case actionTypes.COMPARISONTAB_DESELECT_NUMERIC_COLUMNS_LIST:
      return helper.deselectNumericColumns(state);
    case actionTypes.COMPARISONTAB_DESELECT_QUESTIONS_COLUMNS_LIST:
      return helper.deselectQuestionsColumn(state);
    case actionTypes.FETCH_COMPARAE_QUESTION_ID:
        return helper.fetchQuestionId(state, action);
    case actionTypes.CLEAR_COMPARAE_QUESTION_ID:
      return helper.clearQuestionId(state);
    default:
      return state;
  }
};

export default NLPComparisonReducer;
