import { getDataFromUrlToken } from "../../../../../../utils/urlDataStore";
import { URL_DATA } from "../../../../../../../config/urlDataConstants";
import { getProjectDataForApiCall } from "../../../../subroutes/Heatmap/constants";
import { getSelectedUserData } from "../../helper";
import { getURLFilterAndByScore } from "../../../../../../utils/byScoreFilterUtils";

export const getApiPayload = (props) => {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER)||{};
    let filterData = getURLFilterAndByScore(urlFilter)
    const selectedUserData = getSelectedUserData();
    let survryDetails =
      props.surveyDetails && props.surveyDetails.length
        ? props.surveyDetails
        : getDataFromUrlToken(URL_DATA.PROJECT_DETAILS);
    let payload = getProjectDataForApiCall(survryDetails);
    if (survryDetails) {
      return {
        ...payload,
        directReporteesOnly: selectedUserData.directReporteesOnly,
        ...filterData,
        selectedUser: selectedUserData.selectedUser,
  
      }
    }
  }