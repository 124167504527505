export const fetchOutlierStart = (state, payload) => {
  return {
    ...state,
    outlierApi: {
      isProcessing: true,
      error: false,
      reason: '',
      outlierData: {},
    },
  };
};

export const fetchOutlierSuccess = (state, payload) => {
  return {
    ...state,
    outlierApi: {
      isProcessing: false,
      error: false,
      reason: '',
      outlierData: payload.data,
    },
    isOutlierApiCalled: true,
  };
};

export const fetchOutlierFail = (state, payload) => {
  return {
    ...state,
    outlierApi: {
      isProcessing: false,
      error: true,
      reason: payload.error,
      outlierData: {},
      isOutlierApiCalled: true,
    },
  };
};
