import { deepCopy } from "../../../../../utils/deepCopy"

export const markAsOutCome = (state, payload) => {
    let goals = markAsOutComeTopic(state.goals, payload)
    return {
        ...state,
        goals: goals
    }
}

const markAsOutComeTopic = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(item => {
        if (item.isDefault && item.isSelected) {
            if (item.goalId === payload.goalId) {
                item.isOutcomeVariable = true
            } else {
                item.isOutcomeVariable = false
            }
        }

    })
    return unshiftOutcomeTopicOnFirstPosition(goalsCopy)
}

const unshiftOutcomeTopicOnFirstPosition = (goals) => {
    let goalsCopy = deepCopy(goals)
    const outComeTopic = goalsCopy.find(item => item.isOutcomeVariable)
    if (outComeTopic) {
        goalsCopy.splice(goalsCopy.indexOf(outComeTopic), 1)
        goalsCopy.unshift(outComeTopic)
    }
    return goalsCopy
}