import { apiInitialState } from "../../../utils/apiStateUtils";
import { fetchFormConfigurations } from "../../inputFormConfigurations";
export function Default() {
  return {
    fetchTemplatesApi: apiInitialState(),
    addTemplateApi: apiInitialState(),
    isFormValid: false,
    emailTemplateBoxConfiguration: fetchFormConfigurations([
      "templateName",
      "template",
    ]),
    emailTemplatePagination: 1,
  };
}
