import React from "react";
import DriverDataView from "../DriverDataView";
import "../../index.scss";
export default class StrengthAndWeaknessDataView extends React.Component {
  render() {
    return (
      <div className="drivers-content drivers-area">
        <div className="drivers-content__item">
          <div className="drivers-content__item--right">
            <ul>
              <DriverDataView
                data={this.props.strengths == null ? [] : this.props.strengths}
                title={"Drivers with High Scores"}
              />
            </ul>
            <ul>
              <DriverDataView
                data={
                  this.props.weaknesses == null ? [] : this.props.weaknesses}
                title={"Drivers with Low Scores"}
              />
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
