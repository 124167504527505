import { deepCopy } from "../../../../../../utils/deepCopy"

export const handleNaOptionSelect = (state, payload) => {
    let updatedGoals = updateMatrixBlock(state.goals, payload)
    return {
        ...state,
        goals: updatedGoals
    }
}

const updateMatrixBlock = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            element.allowNAOption = payload.value
            if (payload.value) {
                element.questions[0].options = [...element.questions[0].options, { label: "Can't answer", value: "0" }]
            }
            else {
                let updatedOptions = element.questions[0].options.filter((item => item.value !== "0"))
                element.questions[0].options = updatedOptions
            }
        }
    });
    return goalsCopy
}