import {
  getApiStartState,
  getSuccessResponse,
} from "../../../../utils/reducerOperations";

export function fetchCommentStatisticsStart(state, payload) {
  return {
    ...state,
    [payload.questionId]: {
      ...state[payload.questionId],
      explorecommentStatisticApi: getApiStartState(),
    }
  };
}

export function fetchCommentStatisticsSuccess(state, payload) {
  return {
    ...state,
    [payload.questionId]: {
      ...state[payload.questionId],
      explorecommentStatisticApi: getSuccessResponse(payload && payload.data),
      isDataAlreadyFetched: true,
      filters: payload.filters,
      byScore: payload.byScore,
      multipleSelect: payload.multipleSelect,
      selectedUser: payload.selectedUser,
      directReporteesOnly: payload.directReporteesOnly,
    },
  };
}

export function fetchCommentStatisticsFailed(state, payload) {
  return {
    ...state,
    [payload.questionId]: {
      ...state[payload.questionId],
      explorecommentStatisticApi: {
        isProcessing: false,
        data: null,
        error: payload.error,
      },
    }
  };
}
