import { Default } from './InitialState';
import {
    update,
    fetchPayload
} from '../../../utils/reducerOperations';
import * as helper from './helper';
import {
    FETCH_SPREAD_ANALYSIS_FAIL,
    FETCH_SPREAD_ANALYSIS_STARTED,
    FETCH_SPREAD_ANALYSIS_SUCCESS
} from '../../constants';

const SpreadAnalysisPageReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
    case FETCH_SPREAD_ANALYSIS_STARTED:
        return update(state,
            helper.fetchSpreadAnalysisStarted(state, payload)
        );
    case FETCH_SPREAD_ANALYSIS_SUCCESS:
        return update(state,
            helper.fetchSpreadAnalysisSuccess(state, payload)
        );
    case FETCH_SPREAD_ANALYSIS_FAIL:
        return update(state,
            helper.fetchSpreadAnalysisFailed(state, payload)
        );
    default: return { ...state };
    }
};

export default SpreadAnalysisPageReducer;