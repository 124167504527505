import * as actionTypes from "../../constants";
import * as helper from "./helper/";
import initialState from "./intialState";

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_FIELDS:
      return helper.updateFields(state, action);
    case actionTypes.UPLOAD_COMMENTS_START:
      return helper.uploadFileStart(state, action);
    case actionTypes.UPLOAD_COMMENTS_SUCCESS:
      return helper.uploadFileSuccess(state, action);
    case actionTypes.UPLOAD_COMMENTS_FAIL:
      return helper.uploadFileFail(state, action);
    case actionTypes.UPLOAD_TOPIC_FILE_START:
      return helper.uploadTopicFileStart(state, action);
    case actionTypes.UPLOAD_TOPIC_FILE_SUCCESS:
      return helper.uploadTopicFileSuccess(state, action);
    case actionTypes.UPLOAD_TOPIC_FILE_FAIL:
      return helper.uploadTopicFileFail(state, action);
    case actionTypes.UPLOAD_HRIS_FILE_START:
      return helper.uploadHrisFileStart(state, action);
    case actionTypes.UPLOAD_HRIS_FILE_SUCCESS:
      return helper.uploadHrisFileSuccess(state, action);
    case actionTypes.UPLOAD_HRIS_FILE_FAIL:
      return helper.uploadHrisFileFail(state, action);
    case actionTypes.BATCH_ID_FOR_UPLOAD:
      return helper.setBatchId(state, action);
    case actionTypes.CLEAR_TOPIC_FILE:
      return helper.clearTopicFileName(state);
    default:
      return uploadReprocess(state, action);
  }
};

function uploadReprocess(state, action) {
  switch (action.type) {
    case actionTypes.UPLOAD_REPROCESS_START:
      return helper.reprocessUploadStart(state, action);
    case actionTypes.UPLOAD_REPROCESS_SUCCESS:
      return helper.reprocessFileUploadSuccess(state, action);
    case actionTypes.UPLOAD_REPROCESS_FAIL:
      return helper.reprocessUploadFail(state, action);
    case actionTypes.GET_CSV_COLUMN_START:
      return helper.getCsvColumnsStart(state, action);
    case actionTypes.GET_CSV_COLUMN_SUCCESS:
      return helper.getCsvColumnsSuccess(state, action);
    case actionTypes.GET_CSV_COLUMN_FAIL:
      return helper.getCsvColumnsFail(state, action);
    default:
      return processFiles(state, action);
  }
}

function processFiles(state, action) {
  switch (action.type) {
    case actionTypes.PROCESS_FILES_START:
      return helper.processFilesStart(state, action);
    case actionTypes.PROCESS_FILES_SUCCESS:
      return helper.processFilesSuccess(state, action);
    case actionTypes.PROCESS_FILES_FAIL:
      return helper.processFilesFail(state, action);
    case actionTypes.REPROCESS_FILES_START:
      return helper.reprocessFilesStart(state, action);
    case actionTypes.REPROCESS_FILES_SUCCESS:
      return helper.reprocessFilesSuccess(state, action);
    case actionTypes.REPROCESS_FILES_FAIL:
      return helper.reprocessFilesFail(state, action);
    case actionTypes.CLEAR_FILES_DATA:
      return helper.clearData(state, action);
    case actionTypes.DOWNLOAD_DEFAULT_TOPICS_START:
      return helper.downloadLibraryStart(state, action);
    case actionTypes.DOWNLOAD_DEFAULT_TOPICS_SUCCESS:
      return helper.downloadLibrarySuccess(state, action);
    case actionTypes.DOWNLOAD_DEFAULT_TOPICS_FAIL:
      return helper.downloadLibraryFail(state, action);
    default:
      return getBatchIds(state, action);
  }
}

function getBatchIds(state, action) {
  switch (action.type) {
    case actionTypes.GET_BATCH_IDS_STARTED:
      return helper.getBatchIdsStart(state, action);
    case actionTypes.GET_BATCH_IDS_SUCCESS:
      return helper.getBatchIdsSuccess(state, action);
    case actionTypes.GET_BATCH_IDS_FAIL:
      return helper.getBatchIdsFail(state, action);
    case actionTypes.DOWNLOAD_SAMPLE_COMMENT_START:
      return helper.downloadCommentStart(state, action);
    case actionTypes.DOWNLOAD_SAMPLE_COMMENT_SUCCESS:
      return helper.downloadCommentSuccess(state, action);
    case actionTypes.DOWNLOAD_SAMPLE_COMMENT_FAIL:
      return helper.downloadCommentFail(state, action);
    default:
      return jobRequest(state, action);
  }
}

function jobRequest(state, action) {
  switch (action.type) {
    case actionTypes.APPROVE_JOB_REQUEST_START:
      return helper.jobApprovalRequestStart(state, action);
    case actionTypes.APPROVE_JOB_REQUEST_SUCCESS:
      return helper.jobApprovalRequestSuccess(state, action);
    case actionTypes.APPROVE_JOB_REQUEST_FAIL:
      return helper.jobApprovalRequestFail(state, action);
    case actionTypes.DOWNLOAD_USER_COMMENT_START:
      return helper.downloadUserCommentStart(state, action);
    case actionTypes.DOWNLOAD_USER_COMMENT_SUCCESS:
      return helper.downloadUserCommentSuccess(state, action);
    case actionTypes.DOWNLOAD_USER_COMMENT_FAIL:
      return helper.downloadUserCommentFail(state, action);
    default:
      return batchProcess(state, action);
  }
}

function batchProcess(state, action) {
  switch (action.type) {
    case actionTypes.BATCH_APPROVE_JOBS_START:
      return helper.batchApprovalStart(state, action);
    case actionTypes.BATCH_APPROVE_JOBS_SUCCESS:
      return helper.batchApprovalSuccess(state, action);
    case actionTypes.BATCH_APPROVE_JOBS_FAIL:
      return helper.batchApprovalFail(state, action);
    case actionTypes.BATCH_DELETE_JOBS_START:
      return helper.batchDeleteStart(state, action);
    case actionTypes.BATCH_DELETE_JOBS_SUCCESS:
      return helper.batchDeleteSuccess(state, action);
    case actionTypes.BATCH_DELETE_JOBS_FAIL:
      return helper.batchDeleteFail(state, action);
    default:
      return fetchHrisFIle(state, action);
  }
}
function fetchHrisFIle(state, action) {
  switch (action.type) {
    case actionTypes.FETCH_HRIS_ADMIN_LIST_STARTED:
      return helper.fetchHrisFileStart(state, action);
    case actionTypes.FETCH_HRIS_ADMIN_LIST_SUCCESS:
      return helper.fetchHrisFileSuccess(state, action);
    case actionTypes.FETCH_HRIS_ADMIN_LIST_FAILED:
      return helper.fetchHrisFileFail(state, action);
    default:
      return { ...state };
  }
}
export default AdminReducer;
