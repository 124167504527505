import {
  MANAGE_VIEWERS_FETCH_LIST_VIEWERS_STARTED,
  MANAGE_VIEWERS_FETCH_LIST_VIEWERS_SUCCESS,
  MANAGE_VIEWERS_FETCH_LIST_VIEWERS_FAILED,
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { LISTVIEWERUSERS } from "../../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const NewdashboarViewersListViewers = (payload, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(MANAGE_VIEWERS_FETCH_LIST_VIEWERS_STARTED, {}));
    apiBaseInstance.instance
      .post(LISTVIEWERUSERS, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        dispatch(
          withType(MANAGE_VIEWERS_FETCH_LIST_VIEWERS_SUCCESS, {
            data: response.data,
            message: response.message,
            searchText: payload.searchText
          })
        );
        cb && cb();
      })
      .catch((err) => {
        dispatch(
          withType(MANAGE_VIEWERS_FETCH_LIST_VIEWERS_FAILED, {
            error: err,
          })
        );
      });
  };
};
