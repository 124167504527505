import { connect } from 'react-redux';
import { CommentQuestions } from './CommentQuestions';
import { withRouter } from 'react-router-dom';
import {
  fetchExploreWordcloudData,
  fetchExploreCommentStatisticsData,
  fetchExploreQuestionComments,
  fetchExploreRelationData,
  fetchExploreTopicComments,
  fetchAllThemeData,
  fetchDemographicTopicData,
  updateThemeStateValues,
  fetchDashBoardFilters,
  setQuestionTopic
} from '../../../../../../redux/actions';

const mapStateToProps = (state) => {
  return {
    ...state.DashBoardReducer,
    ...state.FetchExploreCommentQustionReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchExploreWordcloudData: (params) =>
      dispatch(fetchExploreWordcloudData(params)),
    fetchExploreCommentStatisticsData: (params,cb) =>
      dispatch(fetchExploreCommentStatisticsData(params,cb)),
    fetchExploreQuestionComments: (params, callBack) =>
      dispatch(fetchExploreQuestionComments(params, callBack)),
    fetchExploreRelationData: (params) =>
      dispatch(fetchExploreRelationData(params)),
    fetchExploreTopicComments: (params, cb) =>
      dispatch(fetchExploreTopicComments(params, cb)),
    fetchAllThemeData: (params) => dispatch(fetchAllThemeData(params)),
    fetchDemographicTopicData: (params) =>
      dispatch(fetchDemographicTopicData(params)),
    updateThemeStateValues: (params) =>
      dispatch(updateThemeStateValues(params)),
    fetchDashBoardFilters: (params) => dispatch(fetchDashBoardFilters(params)),
    setQuestionTopic :(params)=>dispatch(setQuestionTopic(params)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentQuestions));
