export const setTopAndBottomTopics = (state, payload) => {
  const itemTable = payload.surveyreporttabledata.itemTable;
  const excludeOutcomeVariableData =
    payload.surveyreporttabledata.dimensionTable.filter(
      (data) => data.item !== payload.outComeVariable
    );
  const dimensionsInAscendingOrder = listInAscendingOrderBasisOfFavScore([
    ...excludeOutcomeVariableData,
  ]);
  const dimensionsInDescendingOrder = listInDescendingOrderBasisOfFavScore([
    ...excludeOutcomeVariableData,
  ]);
  const topGraphData = getTopGraphData(
    [...dimensionsInDescendingOrder],
    [...itemTable]
  );
  const bottomGraphData = getBottomGraphData(
    [...dimensionsInAscendingOrder],
    [...itemTable]
  );
  const lengthofDimensionTable = excludeOutcomeVariableData.length;
  const topDimensions = getTopDimensionsFromData(
    [...excludeOutcomeVariableData],
    lengthofDimensionTable
  );
  const bottomDimensions = getBottomDimensionsFromData(
    [...excludeOutcomeVariableData],
    lengthofDimensionTable
  );
  const topQuestions = getTopQuestionsFromData(
    [...topDimensions],
    [...itemTable]
  );
  const bottomQueestions = getBottomQuestionsFromData(
    [...bottomDimensions],
    [...itemTable]
  );
  const highgestScoreInTopData = getHighestScore([...topGraphData]);
  const highestScoreInBottomData = getHighestScore([...bottomGraphData]);
  return {
    ...state,
    fetchStoryboardTopAndBottomTopicsApi: {
      topDimensions: topDimensions,
      bottomDimensions: bottomDimensions,
      topQuestions: topQuestions,
      bottomQuestions: bottomQueestions,
      isDataAlreadySet: true,
      projectId: payload.projectId,
      topGraphData: topGraphData,
      bottomGraphData: bottomGraphData,
      highestScoreInTopData: highgestScoreInTopData,
      highestScoreInBottomData: highestScoreInBottomData,
    },
  };
};

export const clearAllData = (state) => {
  return {
    ...state,
    fetchStoryboardTopAndBottomTopicsApi: {
      topDimensions: [],
      bottomDimensions: [],
      topQuestions: [],
      bottomQuestions: [],
      isDataAlreadySet: false,
      projectId: "",
      topGraphData: [],
      bottomGraphData: [],
      highestScoreInTopData: 0,
      highestScoreInBottomData: 0,
    },
  };
};

const getTopDimensionsFromData = (dimensionList, dimensionListLength) => {
  let fetchSize = dimensionListLength >= 6 ? 3 : 1;
  let getOrderedData = listInDescendingOrderBasisOfFavScore(dimensionList);
  let trimmedDimensionList = getOrderedData.slice(0, fetchSize);
  return trimmedDimensionList;
};

const getBottomDimensionsFromData = (dimensionList, dimensionListLength) => {
  let fetchSize = dimensionListLength >= 6 ? 3 : 1;
  let getOrderedData = listInAscendingOrderBasisOfFavScore(dimensionList);
  let trimmedDimensionList = getOrderedData.slice(0, fetchSize);
  return trimmedDimensionList;
};

const getTopQuestionsFromData = (dimensionData, itemTable) => {
  const length = dimensionData.length;
  let topQuestions = [];
  for (const element of dimensionData) {
    let filteredQuestions = itemTable.filter(
      (data) => data.dimension === element.item
    );
    let descendingFilterQuestion =
      listInDescendingOrderBasisOfFavScore(filteredQuestions);
    if (length === 3) {
      topQuestions.push(descendingFilterQuestion[0]);
    } else if (length === 1) {
      topQuestions.push(descendingFilterQuestion[0]);
      topQuestions.push(descendingFilterQuestion[1]);
    }
  }
  return topQuestions;
};

const getBottomQuestionsFromData = (dimensionData, itemTable) => {
  const length = dimensionData.length;
  let bottomQuestions = [];
  for (const element of dimensionData) {
    let filteredQuestions = itemTable.filter(
      (data) => data.dimension === element.item
    );
    let ascendingFilterQuestion =
      listInAscendingOrderBasisOfFavScore(filteredQuestions);
    if (length === 3) {
      bottomQuestions.push(ascendingFilterQuestion[0]);
    } else if (length === 1) {
      ascendingFilterQuestion.map((item) => bottomQuestions.push(item));
    }
  }
  return bottomQuestions;
};

const listInDescendingOrderBasisOfFavScore = (list) => {
  const listInDescendingOrder = list.sort(function (firstData, secondData) {
    return parseFloat(secondData.favScore) - parseFloat(firstData.favScore);
  });
  return listInDescendingOrder;
};

const listInAscendingOrderBasisOfFavScore = (list) => {
  const listInAscendingOrder = list.sort(function (firstData, secondData) {
    return parseFloat(firstData.favScore) - parseFloat(secondData.favScore);
  });
  return listInAscendingOrder;
};

const getTopGraphData = (descendingOrderDimensionList, itemTable) => {
  const length = descendingOrderDimensionList.length;
  let topGraphData = [];
  let topDimensionData =
    length > 5
      ? descendingOrderDimensionList.slice(0, 5)
      : descendingOrderDimensionList;
  for (const element of topDimensionData) {
    let filteredQuestions = itemTable.filter(
      (data) => data.dimension === element.item
    );
    let questionsInDescendingOrder =
      listInDescendingOrderBasisOfFavScore(filteredQuestions);
    topGraphData.push(questionsInDescendingOrder[0]);
  }
  let graphData = getGraphData([...topDimensionData], [...topGraphData]);
  const topGraphDataInDescendingOrder = listInDescendingOrderBasisOfFavScore([
    ...graphData,
  ]);
  return topGraphDataInDescendingOrder;
};

const getGraphData = (dimensionData, questionsData) => {
  let graphData = [];
  let questionAddedGraphData =[]
  graphData = dimensionData.map((item) => {
    return { favScore: item.favScore, dimension: item.item };
  });
  for (const element of questionsData) {
    graphData.map((item) =>
    element.dimension  === item.dimension
        ? questionAddedGraphData.push({ ...item, item: element.item })
        : null
    );
  }
  return questionAddedGraphData;
};

const getBottomGraphData = (ascendingOrderDimensionList, itemTable) => {
  const length = ascendingOrderDimensionList.length;
  let bottomGraphData = [];
  let bottomDimensionData =
    length > 5
      ? ascendingOrderDimensionList.slice(0, 5)
      : ascendingOrderDimensionList;
  for (const element of bottomDimensionData) {
    let filteredQuestions = itemTable.filter(
      (data) => data.dimension === element.item
    );
    let questionsInAscendingOrder =
      listInAscendingOrderBasisOfFavScore(filteredQuestions);
    bottomGraphData.push(questionsInAscendingOrder[0]);
  }
  let graphData = getGraphData([...bottomDimensionData], [...bottomGraphData]);
  const topGraphDataInAscendingOrder = listInAscendingOrderBasisOfFavScore([
    ...graphData,
  ]);
  return topGraphDataInAscendingOrder;
};

const getHighestScore = (list) => {
  let listInDescendingOrder = listInDescendingOrderBasisOfFavScore(list);
  const highestScore = listInDescendingOrder[0].favScore.toFixed();
  return highestScore;
};
