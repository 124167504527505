export const surveyParticipationDataStart = (state) => {
    return {
      ...state,
      surveyParticipationDataApi: {
        data: null,
        isProcessing: true,
        error: null,
        message: null,
        isInitialApiCalled: false,
      },
    };
  };
  
  export const surveyParticipationDataSuccess = (state, payload) => {
    return {
      ...state,
      surveyParticipationDataApi: {
        isProcessing: false,
        data: payload.data.data,
        error: !payload.status,
        message: payload.message,
        isInitialApiCalled: true,
      },
      surveyParticipationSelectedColumns: Object.keys(
        payload.data.data.aggregatedDemographicsResponse.result || {}
      ),
      filters: payload.filters || {},
      selectedUser: payload.selectedUser,
      directReporteesOnly: payload.directReporteesOnly,
    };
  };
  
  export const surveyParticipationDataFailed = (state, payload) => {
    return {
      ...state,
      surveyParticipationDataApi: {
        isProcessing: false,
        data: null,
        error: payload.error,
        isInitialApiCalled: false,
      },
    };
  };
  
  export const resetParticipationValues = (state) => {
    return {
      ...state,
      surveyParticipationDataApi: {
        isProcessing: false,
        data: null,
        error: null,
        isInitialApiCalled: false,
      },
    };
  };
  
 