import { ApiBase } from "../../../utils/apiBase";
import { FETCH_HRIS_URL } from "../../../../config/constants";
import {
  FETCH_HRIS_ADMIN_LIST_STARTED,
  FETCH_HRIS_ADMIN_LIST_SUCCESS,
  FETCH_HRIS_ADMIN_LIST_FAILED,
} from "../../constants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchHRISFile = (params) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(fetchHRISFileStart());
    apiBaseInstance.instance
      .post(FETCH_HRIS_URL, { ...params, ...getProjectIdAndOrgId(params) })
      .then((res) => {
        dispatch(fetchHRISFileSuccess(res));
      })
      .catch((err) => {
        dispatch(fetchHRISFileFailure(err));
      });
  };
};

const fetchHRISFileStart = () => {
  return {
    type: FETCH_HRIS_ADMIN_LIST_STARTED,
  };
};

const fetchHRISFileSuccess = (res) => {
  return {
    type: FETCH_HRIS_ADMIN_LIST_SUCCESS,
    payload: res.data,
  };
};
const fetchHRISFileFailure = (error) => {
  return {
    type: FETCH_HRIS_ADMIN_LIST_FAILED,
    payload: error,
  };
};
