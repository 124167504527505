import { setInitialState } from "../../../../utils/reducerOperations";

export const InitialState = () => ({
  exploreParticipationDataApi: setInitialState(),
  exploreParticipationSelectedColumns: [],
  isInitialApiCalled: false,
  exploreDistributionDataApi: setInitialState(),
  selectedUser: "",
  directReporteesOnly: false,
  filters : {},
});
