import {
  DELETE_PEOPLE_START,
  DELETE_PEOPLE_SUCCESS,
  DELETE_PEOPLE_FAILED,
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { DELETE_PEOPLE } from "../../../../../config/constants";
import { getProjectIdAndOrgId } from "../../../../utils/apiUtils";

export const deletePeople = (payload, callback) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(DELETE_PEOPLE_START, {}));
    apiBaseInstance.instance
      .post(DELETE_PEOPLE, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        dispatch(
          withType(DELETE_PEOPLE_SUCCESS, {
            data: response.data,
            message: response.message,
          })
        );
        callback && callback();
      })
      .catch((err) => {
        dispatch(
          withType(DELETE_PEOPLE_FAILED, {
            error: err,
          })
        );
      });
  };
};
