import { connect } from "react-redux";
import NetPromoterScore from "./NetPromoterScore";
import { handleChangeNetPromoter, updateCurrentIndex } from "../../../../../../redux/actions";


const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handleChangeNetPromoter: (payload) => dispatch((handleChangeNetPromoter(payload))),
        updateCurrentIndex: (payload) => dispatch((updateCurrentIndex(payload)))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NetPromoterScore);