import {
  FETCH_SURVEY_REPORT_START,
  FETCH_SURVEY_REPORT_SUCCESS,
  FETCH_SURVEY_REPORT_FAILED,
} from '../../constants';
import { FETCH_SCORES_URL } from '../../../../config/constants';
import { ApiBase } from '../../../utils/apiBase';
import { withType } from '../../../utils/actionOperations';
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export function fetchSurveyReportData(payload) {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(FETCH_SURVEY_REPORT_START, {}));
    apiBaseInstance
      .post(FETCH_SCORES_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => fetchSurveyReportSuccess(dispatch, res))
      .catch((err) => fetchSurveyReportFailure(dispatch, err));
  };
}

function fetchSurveyReportSuccess(dispatch, res) {
  const data = (res.data && res.data.data && res.data.data.response) || {
    dimensionTable: [],
    itemTable: [],
  };
  return dispatch(withType(FETCH_SURVEY_REPORT_SUCCESS, { data }));
}

function fetchSurveyReportFailure(dispatch, err) {
  return dispatch(withType(FETCH_SURVEY_REPORT_FAILED, { error: err }));
}
