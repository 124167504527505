import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const unPublishSurveyStarted = (state) => {
    return {
        ...state,
        unPublishApi: getApiStartState()
    }

}

export const unPublishSurveySuccess = (state, payload) => {
    return {
        ...state,
        unPublishApi: getSuccessResponse(payload)
    }

}

export const unPublishSurveyFailed = (state, payload) => {
    return {
        ...state,
        unPublishApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}