import { deepCopy } from '../../../../utils/deepCopy';
import { setInitialState } from '../../../../utils/reducerOperations';
import { defaultMultipleChoice, defaultMultipleOptions, defaultTextEntry, dummyGoals, dynamic180Feedback, dynamic360Feedback, dynamic360FeedbackAppliedMaterials, dynamic360FeedbackAppliedMaterialsDirector, employeeExperienceCheck, goals, quickPulseGoals } from './defaultGoals'
import { automaticChoices } from './automaticChoices'

export const Default = () => ({
    fetchBenchmarkDetails: {
        isProcessing: false,
        data: null,
        error: false,
        message: '',
    },
    setBenchmarkDetails: {
        isProcessing: false,
        data: null,
        error: false,
        message: '',
    },
    fetchResponsesApi: {
        isProcessing: false,
        data: null,
        error: false,
        message: '',
    },
    surveywizardParticipationDataApi: setInitialState(),
    surveywizardParticipationSelectedColumns: [],
    publishSurveyApi: {
        isProcessing: false,
        data: null,
        message: null,
        error: false,
    },
    goals: [],
    defaultGoals: deepCopy(goals),
    automaticChoices: deepCopy(automaticChoices),
    surveyListApi: {
        isProcessing: false,
        error: false,
        reason: '',
        surveyList: [],
    },
    surveyWizardProjectDetailsApi: {
        isProcessing: false,
        data: {},
        error: false,
        reminderData: [],
        message: '',
        hrisData: {}
    },
    surveyWizardHrisFIleApi: {
        isProcessing: null,
        data: {
            response: {
                hrisFilesList: []
            }
        },
        message: null,
        error: null,
    },
    modifyProjectDataApi: setInitialState(),
    localStateData: {
        closeSurveyAutomatically: false
    },
    sendPreviewMailApi: setInitialState(),
    newWizardUploadTranslatedQuestionsApi: {
        isProcessing: false,
        error: false
    },
    newWizardCheckTranslatedQuestionsApi: {
        isProcessing: false,
        error: false
    },
    logoData: {
        logoUrl: null
    },
    HRISFilterData: {
        isProcessing: false,
        data: {},
        error: false,
        message: '',
    },
    HRISUploadStatus:{
      isProcessing: false,
      data:{},
      error:false
    },
    employeeExperienceCheckGoals: employeeExperienceCheck,
    quickPulseGoals: quickPulseGoals,
    defaultMultipleChoice: defaultMultipleChoice,
    defaultMultipleOptions: defaultMultipleOptions,
    defaultTextEntry: defaultTextEntry,
    defaultTopics: dummyGoals,
    feedBack360Dynamic: dynamic360Feedback,
    feedBack180Dynamic: dynamic180Feedback,
    feedback360AppliedMaterials: dynamic360FeedbackAppliedMaterials,
    feedback360AppliedMaterialsDirector: dynamic360FeedbackAppliedMaterialsDirector,
    subcategoryDetails: {}
});