import {
  apiFailureState,
  apiSuccessState,
  apiStartState,
} from "../../../utils/apiStateUtils";
import { deepCopy } from "../../../utils/deepCopy";
export function fetchBatchIdsStart(state) {
  return {
    ...state,
    batchIdsApi: apiStartState(state.batchIdsApi),
  };
}
export function fetchBatchIdsSuccess(state, action) {
  return {
    ...state,
    batchIdsApi: apiSuccessState(state.batchIdsApi, action),
  };
}
export function fetchBatchIdsFail(state, action) {
  return {
    ...state,
    batchIdsApi: apiFailureState(state.batchIdsApi, action),
  };
}
export function fetchFilterStart(state) {
  return {
    ...state,
    filtersApi: { loading: true, error: false },
  };
}
export function fetchFilterSuccess(state, action) {
  return {
    ...state,
    filtersApi: { loading: false, error: false },
    facetData: { ...action.data },
  };
}
export function fetchFilterFail(state, action) {
  return {
    ...state,
    filtersApi: { loading: true, error: true },
  };
}
export function updateNLPFacetGroup(state, action) {
  let { facetData, activeFacet } = state;
  facetData.activeFilters.forEach((facet) => {
    if (facet.label === action.item) {
      if (facet.selected && activeFacet === action.item) activeFacet = null;
      else activeFacet = action.item;
      facet.selected = !!!facet.selected;
    } else facet.selected = false;
  });
  return {
    ...state,
    facetData: { ...facetData },
    activeFacet,
  };
}

export function updateNLPFacetGroupItem(state, action) {
  let { activeFilters } = state.facetData;
  if (action.clear)
    activeFilters.forEach((group) => {
      group.options.forEach((item) => {
        item.selected = false;
      });
      group.searchKey = "";
    });
  else {
    const group = activeFilters.find((item) => item.label === action.label);
    if (group)
      (group.options || []).forEach((item) => {
        if (item.value.split(":")[0] === action.item) {
          item.selected = !!!item.selected;
        }
      });
  }
  return {
    ...state,
    facetData: {
      ...state.facetData,
      activeFilters: [...activeFilters],
    },
  };
}
export function fetchCommentsCountStart(state) {
  return {
    ...state,
    commentsCountApi: { loading: true, error: false },
  };
}
export function fetchCommentsCountSuccess(state, action) {
  return {
    ...state,
    commentsCountApi: { loading: false, error: false, data: action.data },
  };
}
export function fetchCommentsCountFail(state, action) {
  return {
    ...state,
    commentsCountApi: { loading: true, error: true },
  };
}
export function fetchCommentStatisticsStart(state) {
  return {
    ...state,
    commentStatistic: { loading: true, error: false, data: {} },
  };
}

export function fetchCommentStatisticsSuccess(state, action) {
  return {
    ...state,
    commentStatistic: { loading: false, error: false, data: action.data },
  };
}

export function fetchCommentStatisticsFail(state, action) {
  return {
    ...state,
    commentStatistic: { loading: true, error: TextTrackCue, data: {} },
  };
}

export function addAdditionalFilter(state, action) {
  let { activeFilters } = state.facetData;
  if (!activeFilters.find((item) => item.label === action.filter.label)) {
    activeFilters.push(deepCopy(action.filter));
  } else {
    activeFilters = activeFilters.filter(
      (item) => item.label !== action.filter.label
    );
  }
  return {
    ...state,
    facetData: {
      ...state.facetData,
      activeFilters: [...activeFilters],
    },
  };
}

export function addFilterSearchKey(state, action) {
  let { activeFilters } = state.facetData;
  const group = activeFilters.find((item) => item.label === action.group);
  if (group) group.searchKey = action.value;
  return {
    ...state,
    facetData: {
      ...state.facetData,
      activeFilters: [...activeFilters],
    },
  };
}
export function fetchBatchQuestionsStart(state) {
  return {
    ...state,
    questionsApi: apiStartState(state.questionsApi),
  };
}

export function fetchBatchQuestionsSuccess(state, action) {
  return {
    ...state,
    questionsApi: apiSuccessState(state.questionsApi, action),
  };
}

export function fetchBatchQuestionsFail(state, action) {
  return {
    ...state,
    questionsApi: apiFailureState(state.questionsApi, action),
  };
}

export const fetchTreeDataStart = (state, action) => {
  return {
    ...state,
    treeDataApi: apiStartState(state.treeDataApi, action),
  };
};

export const fetchTreeDataSuccess = (state, action) => {
  return {
    ...state,
    treeDataApi: apiSuccessState(state.treeDataApi, action),
  };
};

export const fetchTreeDataFail = (state, action) => {
  return {
    ...state,
    treeDataApi: apiFailureState(state.treeDataApi, action),
  };
};

export const changeProject = (state, action) => {
  return{
    ...state,
    changeProjectValue: action.item,
  }
}

