import { setInitialState } from "../../../utils/reducerOperations";
export function Default() {
  return {
    myProjectsApi: setInitialState(),
    createProjectApi: setInitialState(),
    saveQuestionSetApi: setInitialState(),
    projectsCount: 0,
    copySurveyApi: setInitialState(),
    deleteProjectApi: setInitialState(),
  };
}
