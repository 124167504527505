import React from "react";
import { Input } from "antd";
const TextArea = (props) => {
  const { TextArea } = Input;
  const onChange = (eve) => {
    props.handleChange &&
      props.handleChange({
        value: eve.target.value,
        name: eve.target.name,
        index: props.index,
        formObject: props.formObject,
        event: eve,
      });
  };
  return (
    <TextArea
      as="textarea"
      onChange={onChange}
      disabled={props.disabled}
      placeholder={props.placeHolder}
      onBlur={props.onBlur}
      value={props.value}
      type={props.htmlType}
      name={props.name}
      size={props.size}
      className={`${props.className || ""} ${!props.isValid ? " error-field" : ""
        }`}
      autoSize = {props.textAreaAutosize ? {minRows:4} : false}
    />
  );
};

export default TextArea;
