import * as actionTypes from "../../constants";
import { NUMERIC_INSIGHTS_URL } from "../../../../config/constants";
import {
    NLPCommonReqBodies,
    getUserDataFromTree,
} from "../../../utils/actionOperations";
import { ApiBase } from "../../../utils/apiBase";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchNumericInsights = (payload) => {
    return (dispatch) => {
        const axios = new ApiBase().instance;
        if (!payload) {
            payload = {
                ...NLPCommonReqBodies(),
                ...getUserDataFromTree(),
                projectOrigin: localStorage.getItem("projectOrigin"),
            }
        }
        dispatch({ type: actionTypes.FETCH_NUMERIC_INSIGHTS_START });
        axios
            .post(NUMERIC_INSIGHTS_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then((res) => fetchNumericInsightSuccess({ dispatch, res }))
            .catch((err) => fetchNumericInsightFailed(dispatch, err));
    };
};

function fetchNumericInsightSuccess({ dispatch, res }) {
    return dispatch({
        type: actionTypes.FETCH_NUMERIC_INSIGHTS_SUCCESS,
        data: res.data,
    });
}

function fetchNumericInsightFailed(dispatch, err) {
    return dispatch({
        type: actionTypes.FETCH_NUMERIC_INSIGHTS_FAIL,
        error: err.message,
    });
}
