export default {
  inputBoxes: [
    {
      label: "Name",
      message: "",
      name: "name",
      placeholder: "Your Name",
      type: "text",
      value: "",
      required: true,
    },
    {
      label: "Email",
      message: "",
      name: "email",
      placeholder: "Email",
      type: "email",
      value: "",
      required: true,
    },
    {
      component: "textarea",
      label: "Message",
      message: "",
      name: "message",
      placeholder: "Message",
      type: "text",
      value: "",
      required: true,
    },
  ],
  subject : "New Message from Culturebie.com website footer support ."
};
