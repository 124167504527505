import { ROLES, ROLES_US_SPELLING } from "./constants"

export const Capitalize = (stringItem = '') => {
    if (typeof stringItem === 'string')
        return (
            stringItem.charAt(0).toUpperCase() +
            stringItem.substr(1).toLowerCase()
        );
    else
        return stringItem;
};

export function snakeCaseToPascalCase(string = '') {
    if (!(typeof string === 'string')) return;
    const stringArray = string.split('_');
    return stringArray.map(item => Capitalize(item)).join(' ');
}

export function rolesToUsSpelling(role) {
    switch (role) {
        case ROLES.ORG_ADMIN:
            return ROLES_US_SPELLING.ORG_ADMIN;
        case ROLES.ORG_MANAGER:
            return ROLES_US_SPELLING.ORG_MANAGER;
        default:
            return role
    }

}