export const getApiErrorMessage = (type, message) => {
  switch (type) {
    case "Network Error":
      return "Application went down. Try again later!";
    case "addOrganization":
      return "Add a new organization";
    default:
      return message;
  }
};
