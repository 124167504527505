import React, { Component } from 'react';
import DemographicsTopics from './children/DemographicsTopics';
import { createDropDownData, replaceCurrentSurveyWithName } from '../../helpers';
import { GRAPH_NAMES_TREND, TOAST_ERROR } from './constants';
import { filterDemographicsData, getSelectedFilters } from './helper';
import GraphLoader from '../../../../../../../../Components/ContentLoaders/GraphLoader';
import { Spin } from 'antd';
import { EmptyState } from '../../../../../../../../Packages/EmptyState';
import { deepCopy } from '../../../../../../../../utils/deepCopy';
import { toast } from '../../../../../../../../utils/messageUtils';


class DemographicsTrend extends Component {
    constructor(props) {
        super(props);
        let { topicsData, questionsData } = createDropDownData(this.props.projectDetails.questionSet, this.props.projectDetails.isFileUpload, true)
        this.props.setDemographicsTopicQuestions({ topics: this.props.demographicsSelectedTopics.length > 0 ? this.props.demographicsSelectedTopics : topicsData && topicsData[0] && topicsData[0].value ? topicsData[0].value : "", questions: this.props.demographicsSelectedQuestions > 0 ? this.props.demographicsSelectedQuestions : questionsData && questionsData[0] && questionsData[0].value ? questionsData[0].value : "" })
        let treeSelectedQuestions = this.props.selectedDemographicsQuestionTree.length > 0 ? this.props.selectedDemographicsQuestionTree : this.props.demographicsData[0] && this.props.demographicsData[0].value ? [this.props.demographicsData[0].value] : []
        let treeSelectedTopics = this.props.selectedDemographicsTopicTree.length > 0 ? this.props.selectedDemographicsTopicTree : this.props.demographicsData[0] && this.props.demographicsData[0].value ? [this.props.demographicsData[0].value] : []

        this.props.setDemographicsTreeDataSelect({ treeSelectTopic: treeSelectedTopics, treeSelectQuestion: treeSelectedQuestions })
        this.state = {
            topicsData: topicsData,
            questionsData: questionsData,
            topicProjectGroup: false,
            questionProjectGroup: false,
            topicGraph: "vertical-bar",
            questionGraph: "vertical-bar",
            spinLoader: false
        };
    }

    onChangeSelection = (value, isFromTopic) => {
        if (isFromTopic) {
            let demographicsFilers = getSelectedFilters(this.props.selectedDemographicsTopicTree, this.props.demographicsData)
            this.props.setDemographicsTopicQuestions({ topics: value, questions: this.props.demographicsSelectedQuestions })
            this.props.callDemographicsTrendApi([value], [this.props.demographicsSelectedQuestions], demographicsFilers)
        }
        else {
            this.props.setDemographicsTopicQuestions({ topics: this.props.demographicsSelectedTopics, questions: value })
            let demographicsFilers = getSelectedFilters(this.props.selectedDemographicsQuestionTree, this.props.demographicsData)
            this.props.callDemographicsTrendQuestionsApi([this.props.demographicsSelectedTopics], [value], demographicsFilers)
        }

    }


    handleGraphChange = (value, topics) => {
        if (topics) {
            this.setState({
                topicGraph: value
            })
        } else {
            this.setState({
                questionGraph: value
            })
        }
    }

    handleChangeToggle = (value) => {
        this.setState(value)
    }
    selectOnlySingleOption = (currentValue, selectedValues) => {
        let filteredData = []
        let currentValueLastItem = currentValue && currentValue.split(":")[0] ? currentValue.split(":")[0] : currentValue
        filteredData = selectedValues.filter((item) => item.startsWith(currentValueLastItem));
        if (!selectedValues.length === 0 && filteredData.length === 0) {
            filteredData.push(currentValueLastItem)
        }
        else if (currentValue && !currentValue.split(":")[1] && selectedValues.length > 1) {
            filteredData = [currentValue]

        }
        return filteredData

    }
    handleChangeTree = (value, isFromTopic, w, s, r, ere, ewr) => {
        let answer = []
        // let splittedValue = value.split(":")
        // let currentValueLastItem = value[value.length - 1].split(":")[0] ? value[value.length - 1].split(":")[0] : value[value.length - 1]
        if (isFromTopic) {
            answer = this.selectOnlySingleOption(value[value.length - 1], value)
            if (answer.length !== 0) {
                let previousAnswerCopyTopic = deepCopy(this.props.selectedDemographicsTopicTree)
                let previousMainDemographicsTopics = previousAnswerCopyTopic[0] && previousAnswerCopyTopic[0].split(":")[1] ? previousAnswerCopyTopic[0].split(":")[0] : previousAnswerCopyTopic[0]
                this.props.setDemographicsTreeDataSelect({ treeSelectTopic: answer, treeSelectQuestion: this.props.selectedDemographicsQuestionTree })
                let demographicsFilers = getSelectedFilters(answer, this.props.demographicsData)
                if (this.apiCallNeeded(answer, previousMainDemographicsTopics)) {
                    this.props.callDemographicsTrendApi([this.props.demographicsSelectedTopics], [this.props.demographicsSelectedQuestions], demographicsFilers)

                }
            }
            else {
                toast.error(TOAST_ERROR)
            }


        }
        else {
            let previousAnswerCopy = deepCopy(this.props.selectedDemographicsQuestionTree)
            let previousMainDemographics = previousAnswerCopy[0] && previousAnswerCopy[0].split(":")[1] ? previousAnswerCopy[0].split(":")[0] : previousAnswerCopy[0]
            answer = this.selectOnlySingleOption(value[value.length - 1], value)
            if (answer.length !== 0) {
                this.props.setDemographicsTreeDataSelect({ treeSelectTopic: this.props.selectedDemographicsTopicTree, treeSelectQuestion: answer })
                let demographicsFilers = getSelectedFilters(answer, this.props.demographicsData)
                if (this.apiCallNeeded(answer, previousMainDemographics)) {
                    this.props.callDemographicsTrendQuestionsApi([this.props.demographicsSelectedTopics], [this.props.demographicsSelectedQuestions], demographicsFilers)

                }
            }
            else {
                toast.error(TOAST_ERROR)
            }


        }
    }

    apiCallNeeded = (answer, previousMainDemographics) => {
        if (answer && answer.length == 1 && !answer[0].split(":")[1] && !answer[0] === previousMainDemographics) {
            return true
        }
        else {
            let changedAnswer = answer.filter(item => item.startsWith(previousMainDemographics))
            if (changedAnswer.length == 0) {
                return true
            }

        }
        return false
    }

    handleBlur = () => {

    }

    getLoader = () => {
        if (this.props.trendDemographicsVisualisationData.isProcessing && !this.props.spinLoading && !this.props.trendDemographicsVisualisationData.error) {
            return true
        }
        else if (this.props.trendVisualisationDataQuestions.isProcessing && !this.props.spinLoading && !this.props.trendVisualisationDataQuestions.error) {
            return true
        }
        return false
    }



    render() {
        return (
            <>
                <Spin spinning={this.props.spinLoading}>
                    {this.props.trendDemographicsVisualisationData.isProcessing && !this.props.spinLoading && !this.props.trendDemographicsVisualisationData.error
                        ? <GraphLoader height={300} width={600} />
                        : <>
                            {this.props.trendDemographicsVisualisationData.error ? <EmptyState
                                component="table"
                                emptyStateMessage={"Fetch demographics details failed"}
                            /> : this.state.topicsData && this.state.topicsData.length > 0 &&
                            <>
                                <DemographicsTopics
                                    handleDownloadExcel={this.props.handleDownloadExcel}
                                    loading={this.props.trendDemographicsVisualisationData.isProcessing}
                                    selectedTree={this.props.selectedDemographicsTopicTree}
                                    handleChangeTree={this.handleChangeTree}
                                    demographicsData={filterDemographicsData(this.props.demographicsData)}
                                    handleChangeToggle={this.handleChangeToggle}
                                    handleGraphChange={this.handleGraphChange}
                                    onChangeSelection={this.onChangeSelection}
                                    graphName={GRAPH_NAMES_TREND.DEMOGRAPHICS_TOPICS}
                                    selectedDropDown={this.props.demographicsSelectedTopics}
                                    dropdownData={this.state.topicsData}
                                    graphType={this.state.topicGraph}
                                    showProjectGroup={this.state.topicProjectGroup}
                                    graphData={replaceCurrentSurveyWithName(this.props.trendDemographicsVisualisationData.data.trendData, this.props.projectDetails.projectName, this.props.selectedDemographicsTopicTree)}
                                />
                                <div className='trend-border'></div>
                            </>
                            }
                            {this.state.questionsData && this.state.questionsData.length > 0 &&
                                <DemographicsTopics
                                    handleDownloadExcel={this.props.handleDownloadExcel}
                                    loading={this.props.trendVisualisationDataQuestions.isProcessing}
                                    selectedTree={this.props.selectedDemographicsQuestionTree}
                                    handleChangeTree={this.handleChangeTree}
                                    demographicsData={filterDemographicsData(this.props.demographicsData)}
                                    handleChangeToggle={this.handleChangeToggle}
                                    handleGraphChange={this.handleGraphChange}
                                    onChangeSelection={this.onChangeSelection}
                                    graphName={GRAPH_NAMES_TREND.DEMOGRAPHICS_QUESTIONS}
                                    selectedDropDown={this.props.demographicsSelectedQuestions}
                                    dropdownData={this.state.questionsData}
                                    graphType={this.state.questionGraph}
                                    showProjectGroup={this.state.questionProjectGroup}
                                    graphData={replaceCurrentSurveyWithName(this.props.trendVisualisationDataQuestions.data.trendData, this.props.projectDetails.projectName, this.props.selectedDemographicsQuestionTree)}
                                />
                            }
                        </>
                    }
                </Spin>
            </>
        );
    }
}

export default DemographicsTrend;
