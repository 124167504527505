import React, { Component } from 'react';
import { Collapse } from 'antd';
const { Panel } = Collapse;
export class Collapsible extends Component {
	constructor(props) {
		super(props);
		this.state={}
	}
	callback(key) {
		console.log(key);
		// this.props.callback()
	}

	onClick = (topic) => {
		if (this.props.onClick) {
			this.props.onClick(topic)
		}
	}

	preventExpand = () =>{
		if(this.props.preventExpand){
			return {activeKey : -1}
		}else{
			return {}
		}
	}

	render() {
		return (
			<Collapse
				{...this.preventExpand()}
				expandIconPosition={this.props.expandIconPosition}
				expandIcon={this.props.expandIcon}
				className={this.props.className}
				defaultActiveKey={this.props.defaultActiveKey}
				onChange={this.callback}>
				{this.props.panels.map((item, index) => (
					<Panel
						header={<div onClick={() => this.onClick(item.header)}>{item.header}</div>}
						key={index}
						showArrow = {this.props.showArrow}
					>
						{item.children}
					</Panel>
				))}
			</Collapse>
		);
	}
}

// 
