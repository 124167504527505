export const fetchStoryboardFilterStart = (state, payload) => {
    return {
      ...state,
      storyboardFilterApi: {
        isProcessing: true,
        error: false,
        reason: '',
        facetData: state.storyboardFilterApi.facetData,
      },
    };
  };
  
  export const fetchStoryboardFilterSucess = (state, payload) => {
    const data = payload.data || {};
    return {
      ...state,
      storyboardFilterApi: {
        isProcessing: false,
        error: false,
        reason: '',
        facetData: data,
      },
    };
  };
  
  export const fetchStoryboardFilterFail = (state, payload) => {
    return {
      ...state,
      storyboardFilterApi: {
        isProcessing: false,
        error: true,
        reason: payload.error,
        facetData: [],
      },
    };
  };
  