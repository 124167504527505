import {
    SET_DEMOGRAPHICS_TOPICS_QUESTIONS_TREND, SET_DEMOGRAPHICS_TREE_DATA_SELECT
} from "../../../constants";


export const setDemographicsTopicQuestions = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SET_DEMOGRAPHICS_TOPICS_QUESTIONS_TREND, payload });
    }
};

export const setDemographicsTreeDataSelect = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SET_DEMOGRAPHICS_TREE_DATA_SELECT, payload });
    }
};