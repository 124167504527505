export const fetchPayload = (action) => {
  return (action && action.payload) || {};
};

export function getApiStartState() {
  return {
    data: null,
    isProcessing: true,
    error: null,
    message: null,
  };
}

export const fetchTarget = (payload) => {
  return (payload && payload.event && payload.event.target) || "";
};
export const setInitialState = (inputObject = null) => {
  return inputObject
    ? { ...inputObject, ...getInitialVariables() }
    : getInitialVariables();
};

export const handleApiResponseError = (response) => {
  return {
    isProcessing: false,
    data: response,
    message: response.message || response.reason,
    reason: response.reason || response.message,
    error: !response.status,
  };
};

export const handleNetworkError = (response) => {
  return {
    isProcessing: false,
    loading: false,
    data: response,
    message: response && response.message,
    error: true,
  };
};

function getInitialVariables() {
  return {
    isProcessing: null,
    data: null,
    message: null,
    error: null,
    page: -1,
    nextPageEnabled: false,
    previousPageEnabled: false,
    pageKeys: [],
  };
}

export const validateField = (inputConfiguration) => {
  let isValid = false;
  if (inputConfiguration.notRequired) {
    isValid = true;
  } else if (inputConfiguration.value.length <= 0)
    inputConfiguration.message = inputConfiguration.rule.emptyMessage;
  else if (inputConfiguration.rule.condition.test &&
    !inputConfiguration.rule.condition.test(inputConfiguration.value))
    inputConfiguration.message = inputConfiguration.rule.errorMessage;
  else {
    inputConfiguration.message = "";
    isValid = true;
  }
  return isValid;
};

export function setInputFormChange(inputBoxConfiguration, payload) {
  let resp = inputBoxConfiguration.map((inputItem, index) => {
    if (ConfirmPassword({ inputItem, index }, payload.index)) {
      return updateConfirmPasswordItem(
        { ...inputItem },
        {
          payload,
          password: inputBoxConfiguration[index - 1],
        }
      );
    }
    if (newPassword({ inputItem, index }, payload.index)) {
      return updateNewPasswordItem(
        { ...inputItem },
        {
          payload,
          password: inputBoxConfiguration[index + 1],
        }
      );
    } else if (payload.index === index && payload.hasOwnProperty("name"))
      return updateSelectFieldItem({ ...inputItem }, payload);
    else if (payload.index === index)
      return updateInputFieldItem({ ...inputItem }, payload);
    else return inputItem;
  });
  return resp;
}
function ConfirmPassword({ inputItem, index }, payloadIndex) {
  return inputItem.name === "confirm" && index === payloadIndex;
}
function newPassword({ inputItem, index }, payloadIndex) {
  return inputItem.name === "newPassword" && index === payloadIndex;
}

function updateInputFieldItem(field, payload) {
  field.value = fetchTarget(payload).value;
  field.isValid = validateField(field);
  return field;
}
function updateSelectFieldItem(field, payload) {
  field.value = fetchTarget(payload).value;
  field.isValid = validateField(field);
  field.keyName = payload.name;

  return field;
}

export function checkIsFormValid(inputBoxConfiguration) {
  let invalidFields = inputBoxConfiguration
    .filter((field) => !field.isValid || !field.value.length)
    .filter((field) => !field.notRequired);
  return invalidFields.length ? false : true;
}

export function update(prevState, newState) {
  return Object.assign({}, prevState, newState);
}

export function makeKey(keyName, value) {
  return { [keyName]: value };
}

export function formChangeSuccess(state, payload) {
  let configuration = setInputFormChange(state.inputBoxConfiguration, payload);
  return {
    inputBoxConfiguration: configuration,
    isFormValid: checkIsFormValid(configuration),
  };
}

export function customFormChangeSuccess({ state, key }, payload) {
  let configuration = setInputFormChange(state[key], payload);
  configuration.forEach(item => {
    if (item.label === "Current password" && item.value && item.value.length > 0) {
      item.isValid = true
      item.message = ""
    }
  })
  return {
    [key]: configuration,
    isFormValid: checkIsFormValid(configuration),
  };
}

export function selectChangeSuccess(state, payload) {
  let configuration = setInputFormChange(state.selectBoxConfiguration, payload);
  return {
    selectBoxConfiguration: configuration,
    isInviteFormValid: checkIsInviteFormValid(configuration),
    keyName: payload.name,
  };
}

export function checkIsInviteFormValid(inputBoxConfiguration) {
  let invalidFields = inputBoxConfiguration.filter(
    (field) => !field.isValid || !field.value.length
  );
  return invalidFields.length > 1 ? false : true;
}

function updateConfirmPasswordItem(fieldItem, { payload, password }) {
  fieldItem.value = fetchTarget(payload).value;
  fieldItem.isValid = password.value === fieldItem.value;
  if (!fieldItem.value.length) fieldItem.message = fieldItem.rule.emptyMessage;
  else if (!fieldItem.isValid) fieldItem.message = fieldItem.rule.errorMessage;
  else {
    fieldItem.message = "";
    // password.isValid = password.value.length ? true : false;
  }
  return fieldItem;
}

function updateNewPasswordItem(fieldItem, { payload, password }) {
  fieldItem.value = fetchTarget(payload).value;
  fieldItem.isValid = fieldItem.rule.condition.test(fieldItem.value);
  if (password.value.length && password.value !== fieldItem.value) {
    password.isValid = false;
    password.message = fieldItem.rule.confirmMessage;
  } else if (password.value.length && password.value === fieldItem.value) {
    password.isValid = true;
    password.message = "";
  } else if (!fieldItem.value.length)
    fieldItem.message = fieldItem.rule.emptyMessage;
  if (!fieldItem.isValid) fieldItem.message = fieldItem.rule.errorMessage;
  else {
    fieldItem.message = "";
  }
  return fieldItem;
}

export function getSuccessResponse(payload) {
  return {
    isProcessing: false,
    data: payload.data,
    error: !payload.status,
    message: payload.message,
  };
}

export const storeDefaultHRISValue = (payload) => {
  if (payload.response) {
    if (payload.response.hrisFilesList[0]) {
      const defaultHrisValue = payload.response.hrisFilesList[0].hrisFileId;
      const defaultHrisName = payload.response.hrisFilesList[0].hrisFileName;
      localStorage.setItem("defaultHrisValue", defaultHrisValue);
      localStorage.setItem("defaultHrisName", defaultHrisName);
    }
  }
};

export const sortHrisFileList = (payload) => {
  if (payload.data.response) {
    if (payload.data.response.hrisFilesList.length) {
      payload.data.response.hrisFilesList =
        payload.data.response.hrisFilesList.sort(
          (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
        );
      return payload;
    }
  }
  return payload;
};

export const filterSurveys = ({ searchText, surveyList }) => {
  if (searchText) {
    if (Array.isArray(surveyList) && surveyList.length) {
      const newList = surveyList.filter((survey) => {
        if (
          survey.projectName &&
          survey.projectName.toUpperCase().includes(searchText.toUpperCase())
        )
          return true;
        return false
      });
      return newList;
    }
    return [];
  } else {
    return surveyList;
  }
};
