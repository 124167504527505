import { DASHBOARD_TAB_PERMISSION } from "../../../../config/userPermissionConstants";

export const CUSTOM_THRESHOLD = "customThreshold"
export const DASHBOARD_TABS = [
    { tab: "Storyboard", accessKey: DASHBOARD_TAB_PERMISSION.STORY },
    { tab: "Explore", accessKey: DASHBOARD_TAB_PERMISSION.EXPLORE },
    { tab: "Heatmap", accessKey: DASHBOARD_TAB_PERMISSION.HEATMAP },
    { tab: "Comments", accessKey: DASHBOARD_TAB_PERMISSION.COMMENTS },
    { tab: "Insights", accessKey: DASHBOARD_TAB_PERMISSION.INSIGHTS },
    { tab: "Manage", accessKey: DASHBOARD_TAB_PERMISSION.MANAGE },
];