import { 
    GET_PARTICIPATON_RATE_FAIL,
    GET_PARTICIPATON_RATE_SUCCESS,
    GET_PARTICIPATON_RATE_START,
    COMPARISONTAB_UPDATE_PARTICIPATION_COLUMNS_LIST,
    COMPARISONTAB_DESELECT_PARTICIPATION_COLUMNS_LIST
} from '../../constants';
import { initialState } from './IntialState';
import {  
    getParticipationRateStart,
    getParticipationRateSuccess,
    getParticipationRateFail,
    updateParticipationSelectedColumns,
    deselectParticipationDemographicColumns,
} from './helper';


const participationsPageReducer = (state=initialState, action) => {
    switch (action.type) {
    case GET_PARTICIPATON_RATE_START:
        return getParticipationRateStart(state,action);
    case GET_PARTICIPATON_RATE_SUCCESS:
        return getParticipationRateSuccess(state,action);
    case GET_PARTICIPATON_RATE_FAIL:
        return getParticipationRateFail(state,action);
    case COMPARISONTAB_UPDATE_PARTICIPATION_COLUMNS_LIST:
        return updateParticipationSelectedColumns(state, action);
    case COMPARISONTAB_DESELECT_PARTICIPATION_COLUMNS_LIST:
        return deselectParticipationDemographicColumns(state)
    default: return {...state};
    }
};

export default participationsPageReducer;