import {
    update,
    fetchPayload
} from '../../../utils/reducerOperations';
import * as helper from './helper';
import {
    FETCH_DRIVERS_DATA_FAIL,
    FETCH_DRIVERS_DATA_START,
    FETCH_DRIVERS_DATA_SUCCESS,
    DRIVERS_SCATTER_CHANGE_OPACITY
} from '../../constants';
import { Default } from './InitialState';

const DriversPageReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
    case FETCH_DRIVERS_DATA_START:
        return update(state,
            helper.fetchDriversDataStarted(state, payload));
    case FETCH_DRIVERS_DATA_SUCCESS:
        return update(state,
            helper.fetchDriversDataSuccess(state, payload));
    case FETCH_DRIVERS_DATA_FAIL:
        return update(state,
            helper.fetchDriversDataFailed(state, payload));
    case DRIVERS_SCATTER_CHANGE_OPACITY:
        return update(state,
            helper.updateScatterOpacity(state,payload));
    default: return { ...state };
    }
};

export default DriversPageReducer;