import {
  handleNetworkError,
  update,
  makeKey,
} from "../../../../utils/reducerOperations";
function failureResponse(state, payload) {
  if (payload.response && payload.response.data)
    return makeKey(
      "verifyPasswordApi",
      update(state.verifyPasswordApi, {
        sessionExpired: true,
        isProcessing: false,
        data: payload.response.data,
        message: payload.response.data.message || payload.response.data.reason,
        error: !payload.response.data.status,
      })
    );
  else
    return makeKey(
      "verifyPasswordApi",
      update(state.verifyPasswordApi, handleNetworkError(payload))
    );
}

export function verifyProcessFailed(state, payload) {
  let res = failureResponse(state, payload);
  // toast.error(getErrorMessage(res));
  return res;
}

export const clearSessionExpired = (state) => {
  return makeKey(
    "verifyPasswordApi",
    update(state.verifyPasswordApi, {
      sessionExpired: false,
    })
  );
};
// const getErrorMessage = (res) => {
//   const { verifyPasswordApi } = res;
//   if (verifyPasswordApi) {
//     return (
//       <>
//         <h3>{verifyPasswordApi.message}</h3>
//         <p>{verifyPasswordApi.data ? verifyPasswordApi.data.reason : null}</p>
//       </>
//     );
//   }
//   return "Registration failed";
// };
