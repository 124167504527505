import {
    FETCH_HRIS_FILTERS_START,
    FETCH_HRIS_FILTERS_SUCCESS,
    FETCH_HRIS_FILTERS_FAIL,
} from '../../../../constants';
import { GET_HRIS_DEMOGRAPHIC_DATA } from '../../../../../../config/constants';
import { ApiBase } from '../../../../../utils/apiBase';
import { getProjectIdAndOrgId } from '../../../../../utils/apiUtils';

export const fetchHRISData = (payload) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
        dispatch({ type: FETCH_HRIS_FILTERS_START });
        apiBaseInstance.instance
            .post(GET_HRIS_DEMOGRAPHIC_DATA, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then((res) => fetchFilterSuccess(dispatch, { res }))
            .catch((err) => fetchFilterFail(dispatch, err));
    };
};

function fetchFilterSuccess(dispatch, { res }) {
    const data = res && res.data && res.data
    return dispatch({
        type: FETCH_HRIS_FILTERS_SUCCESS,
        payload: { data: data },
    });
}

function fetchFilterFail(dispatch, err) {
    return dispatch({
        type: FETCH_HRIS_FILTERS_FAIL,
        payload: { error: err.message },
    });
}
