import {
  update,
  makeKey,
  getSuccessResponse,
} from "../../../../utils/reducerOperations";

export function verifyProcessSuccess(state, payload) {
  // SetLocalStorage({...payload.data});
  return makeKey(
    "verifyPasswordApi",
    update(state.verifyPasswordApi, getSuccessResponse(payload))
  );
}
