import {
    MANAGE_EXPORT_GENERATE_REPORT_MAIL_STARTED,
    MANAGE_EXPORT_GENERATE_REPORT_MAIL_SUCCESS,
    MANAGE_EXPORT_GENERATE_REPORT_MAIL_FAILED
} from '../../../constants';
import { ApiBase } from '../../../../utils/apiBase';
import { notification } from 'antd';
import { GET_RESPONSES_AS_CSV } from '../../../../../config/constants';
import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const NewdashboardGenerateReportMail = (payload, cb) => {
  const apiBaseInstance = new ApiBase().instance;
    return (dispatch) => {
      dispatch(withType(MANAGE_EXPORT_GENERATE_REPORT_MAIL_STARTED, {}));
      return apiBaseInstance
        .post(GET_RESPONSES_AS_CSV, {
          ...payload, ...getProjectIdAndOrgId(payload)
        })
        .then((res) => {
          notification.success({ message: 'The report will be mailed to you shortly' });
          cb && cb();
          dispatch(
            withType(MANAGE_EXPORT_GENERATE_REPORT_MAIL_SUCCESS, {
              data: res,
            })
          );
        })
        .catch((err) => {
          notification.error({ message: 'Failed to generate report' });
          dispatch(withType(MANAGE_EXPORT_GENERATE_REPORT_MAIL_FAILED, err));
        });
    };
  };