import {
  FETCH_NEWDASHBOARD_BATCH_QUESTIONS_START,
  FETCH_NEWDASHBOARD_BATCH_QUESTIONS_SUCCESS,
  FETCH_NEWDASHBOARD_BATCH_QUESTIONS_FAILED,
  SET_NEWDAHSBOARD_COMMENT_QUESTION_INITIAL_STATE,
  SET_NEWDAHSBOARD_COMMENT_QUESTION_INITIAL_STATE_SUCCESS
} from "../../../constants";
import { BATCH_QUESTIONS_URL } from "../../../../../config/constants";
import { withType } from "../../../../utils/actionOperations";
import { ApiBase } from "../../../../utils/apiBase";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const getBatchQuestionsId = (payload,cb) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(FETCH_NEWDASHBOARD_BATCH_QUESTIONS_START, {}));
    return apiBaseInstance
      .post(BATCH_QUESTIONS_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        dispatch(
          withType(FETCH_NEWDASHBOARD_BATCH_QUESTIONS_SUCCESS, {
            data: {
              data: res.data.data.currentBatchQuestions,
            },
          }),
        );
        dispatch(
          withType(SET_NEWDAHSBOARD_COMMENT_QUESTION_INITIAL_STATE,{
            data: {
              data: res.data.data.currentBatchQuestions,
            },
          })
        )
        cb && setTimeout(() => { cb(); }, 0);
      })
      .catch((err) => {
        dispatch(withType(FETCH_NEWDASHBOARD_BATCH_QUESTIONS_FAILED, err));
      });
  };
};

export const InitialStateSetKeyChange =(data)=>{
  return(dispatch)=>{
    dispatch(
      withType(SET_NEWDAHSBOARD_COMMENT_QUESTION_INITIAL_STATE_SUCCESS,{
        key:data
      })
    )
  }
  
}