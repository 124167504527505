import { selectChangeSuccess } from "../../../../utils/reducerOperations";
import { fetchFormConfigurations } from "../../../inputFormConfigurations";
export function inviteUserFormChange(state, payload) {
  return selectChangeSuccess(state, payload);
}
export function clearInviteUserForm(state, payload) {
  return {
    ...state,
    isInviteFormValid: false,
    selectBoxConfiguration: fetchFormConfigurations([
      "existingUserTemplate",
      "newUserTemplate",
    ]),
  };
}

export const managersPaginationChange = (state, payload) => {
  return {
    ...state,
    managersPagination: payload,
  };
};
