import React, { useState } from "react";
import { Select } from "antd";
import SVGIcons from "../../Components/SVGIcons";
export const SelectElement = (props) => {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [valueState, setValueState] = useState("");
  const [pageCounter, setPageCounter] = useState(1);
  let SVG_IconData = {
    "ascending": "SVG-uparrow",
    "descending": "SVG-downarrow",
    "none": ""
  }
  function onBlur() { }
  function onFocus() { }
  function onSearch(val) {
    setValueState(val);
  }
  function onPopupScroll(event) {
    if (props.onScroll) {
      var target = event.target;
      if (
        !loading &&
        target.scrollTop + target.offsetHeight === target.scrollHeight
      ) {
        setLoading(true);
        target.scrollTo(0, target.scrollHeight);
        props.onScroll(pageCounter);
        let incrimentPageCounter = pageCounter + 1;
        setPageCounter(incrimentPageCounter);
        if (pageCounter !== 1)
          if (props.optionList.length > 0) {
            // setOptionList(optionList.concat(props.optionList));
            setLoading(false);
          }
      }
    }
  }

  const getSuffixIcon = () => {
    return props.suffixIcon ? { suffixIcon: props.suffixIcon } : {}
  }

  return (
    <Select
      {...getSuffixIcon()}
      getPopupContainer={props.getPopupContainer}
      showSearch={!props.disableSearch}
      disabled={props.disabled}
      loading={props.loading}
      mode={props.mode}
      placeholder={props.placeholder ? props.placeholder : "Select Option"}
      optionFilterProp="children"
      onChange={props.onChange !== undefined ? (value, event) => props.onChange(value, event) : ''}
      onFocus={onFocus}
      bordered={!props.dontShowBorder}
      showArrow={!props.showArrow}
      onBlur={onBlur}
      onSearch={onSearch}
      onSelect={props.onSelect !== undefined ? (value, key) => props.onSelect(value) : ''}
      allowClear={props.allowClear}
      defaultValue={props.defaultValue}
      value={props.value}
      searchValue={valueState}
      className={`${props.className || ""} ${!props.isValid ? " error-field" : ""
        }`}
      onPopupScroll={onPopupScroll}
      filterOption={(input, option) => {
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      }
    >
      {(props.optionList || []).map((item, index) => {
        return (
          <Option key={index} value={typeof item === 'string' ? item : item.value} label={item.label} disabled={item.disableOption ? item.disableOption : false}>
            {props.showCustomIcon !== undefined ? <div className="topics-sort">{item.value === props.selectedSortOption ?
              <SVGIcons type={SVG_IconData[props.selectedTopicSortOption]} /> : ''}</div> : ''}
            <span>{typeof item === 'string' ? item : item.label}</span>
            <span className="filter-count">{item.count}</span>
          </Option>
        );
      })}
    </Select>
  );
};
