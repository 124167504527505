import {
  NEW_DASHBOARD_SEND_FEEDBACK_START,
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { SEND_FEEDBACK_URL } from "../../../../../config/constants";
import { toast } from "../../../../utils/messageUtils";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const NewdashboarStoryboardSendFeedback = (payload, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(NEW_DASHBOARD_SEND_FEEDBACK_START, {}));
    apiBaseInstance.instance
      .post(SEND_FEEDBACK_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        toast.success("Feedback send Successfully");
        cb && cb();
      })
      .catch((err) => {
        toast.error("Failed to send Feedback");
      });
  };
};
