import { 
    apiStartState,
    apiFailureState,
    apiSuccessState
} from '../../../../utils/apiStateUtils';


export function batchApprovalStart(state) {
    return {
        ...state,
        batchApprovalApi: apiStartState(state.batchApprovalApi)
    };
};

export function batchApprovalSuccess(state, action) {
    return {
        ...state,
        batchApprovalApi: apiSuccessState(state.batchApprovalApi,action)
    };
}

export function batchApprovalFail(state, action) {
    return {
        ...state,
        batchApprovalApi: apiFailureState(state.batchApprovalApi,action)
    };
};