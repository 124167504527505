import { deepCopy } from '../../../../utils/deepCopy';

export const getExploreTopicListStarted = (state) => {
  return {
    ...state,
    exploreTopicListApi: {
      isProcessing: true,
      error: false,
      reason: '',
      isApiCalled:false,
      exploreTopicList: state.exploreTopicListApi.exploreTopicList,
      sortOrder: state.sortOrder,
      sortOption: state.sortOption,
    },
  };
};

export const getExploreTopicListSuccess = (state, payload) => {
  const data = (payload.data && payload.data.hits) || [];
  // let sortedTopicData = getSortedTopicScore(data, 'descending', state);

  return {
    ...state,
    exploreTopicListApi: {
      isProcessing: false,
      error: false,
      reason: '',
      exploreTopicList: data,
      isApiCalled: true,
      sortOrder: state.sortOrder,
      sortOption: state.sortOption,
    },
    filters: payload.filters,
    byScore: payload.byScore,
    multipleSelect: payload.multipleSelect,
    selectedUser: payload.selectedUser,
    directReporteesOnly: payload.directReporteesOnly,
  };
};

export const setFilterSuccess = (state, payload) => {
  return {
    ...state,
    filters: payload.filters,
    byScore: payload.byScore,
    multipleSelect: payload.multipleSelect,
    selectedUser: payload.selectedUser,
    directReporteesOnly: payload.directReporteesOnly,
  };
};

export const getExploreTopicListFailed = (state, payload) => {
  return {
    ...state,
    exploreTopicListApi: {
      isProcessing: false,
      error: true,
      isApiCalled:false,
      reason: payload.error || '',
      exploreTopicList: [],
      sortOrder: state.sortOrder,
      sortOption: state.sortOption,
    },
  };
};

export const resetValues = (state) => {
  return {
    ...state,
    exploreTopicListApi: {
      isProcessing: false,
      error: false,
      reason: '',
      exploreTopicList: [],
      isApiCalled: false,
      sortOrder: state.sortOrder,
      sortOption: state.sortOption,
    },
    quoteModifying: false,
  };
};

export const setQuoteModifyingLoading = (state, payload) => {
  return { ...state, quoteModifying: payload.loading };
};

const getSortedTopicScore = (dimensionTable, order, state) => {
  if (order === 'descending')
    return dimensionTable.sort((a, b) => {
      return b.favScore - a.favScore;
    });
  else
    return dimensionTable.sort((a, b) => {
      return a.favScore - b.favScore;
    });
};

const getSortedTopic = (data, order) => {
  if (order === 'descending')
    return data.sort((a, b) => {
      a = a.item.trim();
      b = b.item.trim();
      return b.localeCompare(a);
    });
  else
    return data.sort((a, b) => {
      a = a.item.trim();
      b = b.item.trim();
      return a.localeCompare(b);
    });
};

export const sortExploreTopicList = (state, action) => {
  let surveyReport = deepCopy(state.topicsSurveyReportApi.surveyReport);
  // eslint-disable-next-line
  let sortedTopicData;
  let dimensionTable = surveyReport.dimensionTable;
  if (action.payload.option === 'score')
    sortedTopicData = getSortedTopicScore(
      dimensionTable,
      action.payload.sortOption,
      state
    );
  else if (action.payload.option === 'topic')
    sortedTopicData = getSortedTopic(dimensionTable, action.payload.sortOption);
  return {
    ...state,
    exploreTopicListApi: {
      ...state.exploreTopicListApi,
      isProcessing: false,
      error: false,
      reason: '',
      // exploreTopicList: sortedTopicData,
      isApiCalled: true,
      sortOrder: action.payload.sortOption,
      sortOption: action.payload.option,
    },
    topicsSurveyReportApi: {
      isProcessing: state.topicsSurveyReportApi.isProcessing ? state.topicsSurveyReportApi.isProcessing : false,
      error: false,
      reason: '',
      surveyReport: surveyReport,
      isApiCalled: true,
    },
  };
};

export const fetchTopicSurveyReportStart = (state, payload) => {
  return {
    ...state,
    topicsSurveyReportApi: {
      isProcessing: true,
      error: false,
      reason: '',
      surveyReport: { dimensionTable: [], itemTable: [], ...state.topicsSurveyReportApi.surveyReport },
      isApiCalled: false,
    },
  };
};

export const fetchTopicSurveyReportSuccess = (state, payload) => {
  let data = payload.data && payload.data.dimensionTable;
  data.sort((a, b) => {
    return b.favScore - a.favScore;
  });
  return {
    ...state,
    topicsSurveyReportApi: {
      isProcessing: false,
      error: false,
      reason: '',
      surveyReport: payload.data,
      isApiCalled: true,
    },
    filters: payload.filters,
    byScore: payload.byScore,
    multipleSelect: payload.multipleSelect,
    selectedUser: payload.selectedUser,
    directReporteesOnly: payload.directReporteesOnly,
  };
};

export const fetchTopicSurveyReportFail = (state, payload) => {
  return {
    ...state,
    topicsSurveyReportApi: {
      isProcessing: false,
      error: true,
      reason: payload.error,
      surveyReport: { dimensionTable: [], itemTable: [] },
      isApiCalled: false,
    },
  };
};

export const disableTopicLoading = (state, payload) => {
  return {
    ...state,
    disableTopicLoader: payload.disableTopicLoading,
  };
};

export const fetchExploreTopicFilterStatusStart = (state, payload) => {
  return {
    ...state,
    exploreTopicFilterStatus: {
      isProcessing: true,
      error: false,
      reason: '',
      data: state.exploreTopicFilterStatus.data,
    },
  };
};

export const fetchExploreTopicFilterStatusSuccess = (state, payload) => {
  let data = (payload.data &&
    payload.data.storyboardStatusData &&
    payload.data.storyboardStatusData._source) || {
    topicStoriesFilterStatus: '',
    filters: {},
    byScore: [],
    multipleSelect: []
  };
  return {
    ...state,
    exploreTopicFilterStatus: {
      isProcessing: false,
      error: false,
      reason: '',
      data: data,
    },
  };
};

export const fetchExploreTopicFilterStatusFail = (state, payload) => {
  return {
    ...state,
    exploreTopicFilterStatus: {
      isProcessing: false,
      error: true,
      reason: payload.error,
      data: state.exploreTopicFilterStatus.data,
    },
  };
};

export const clearExploreTopicFilterStatus = (state) => {
  return {
    ...state,
    exploreTopicFilterStatus: {
      isProcessing: false,
      error: false,
      reason: '',
      data: { topicStoriesFilterStatus: '', filters: {}, byScore: [], multipleSelect: [] },
    },
  };
};

export const adminBarchartDataStart = (state, payload) => {
  return {
    ...state,
    adminBarchartApi: {
      isProcessing: true,
      error: false,
      reason: '',
      data: { dimensionTable: [], itemTable: [] },
      isApiCalled: false,
    },
  };
};

export const adminBarchartDataSuccess = (state, payload) => {
  const response = (payload.data && payload.data.response) || [];
  const { outcome } = payload;
  const data = sortAdminBarchartData(response, outcome);
  return {
    ...state,
    adminBarchartApi: {
      isProcessing: false,
      error: false,
      reason: '',
      data,
      isApiCalled: true,
    },
  };
};

export const adminBarchartDataFail = (state, payload) => {
  return {
    ...state,
    adminBarchartApi: {
      isProcessing: false,
      error: true,
      reason: '',
      data: { dimensionTable: [], itemTable: [] },
      isApiCalled: false,
    },
  };
};

const sortAdminBarchartData = (data, outcome) => {
  let dimensionTableWithoutOutcome = data.dimensionTable.filter(
    (item) => item.item !== outcome
  );
  let sortedDimensionTableWithoutOutcome = dimensionTableWithoutOutcome.sort(
    (a, b) => {
      return a.item.localeCompare(b.item);
    }
  );
  let outComeVariable = data.dimensionTable.find(
    (item) => item.item === outcome
  );
  let dimensionTable;
  if (outComeVariable)
    dimensionTable = [outComeVariable, ...sortedDimensionTableWithoutOutcome];
  else dimensionTable = sortedDimensionTableWithoutOutcome;
  return { dimensionTable, itemTable: data.itemTable };
};

export const setApiCalledKey = (state, payload) => {
  return {
    ...state,
    topicsSurveyReportApi: {
      ...state.topicsSurveyReportApi,
      isApiCalled: false
    },
    exploreTopicListApi: {
      ...state.exploreTopicListApi,
      isApiCalled: false
    }

  }

}