import {
  VERIFY_PROCESS_STARTED,
  VERIFY_PROCESS_SUCCESS,
  VERIFY_PROCESS_FAILED,
} from "../../constants";

import { VERIFY_URL } from "../../../../config/constants";
import { ApiBase } from "../../../utils/apiBase";
import { toast } from "../../../utils/messageUtils";

const SUCCESS_MESSAGE =
  "Created new password successfully. Please login again with the new password";
export const verifyAction = (params) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch({
      type: VERIFY_PROCESS_STARTED,
    });
    apiBaseInstance.instance
      .post(VERIFY_URL, params)
      .then((res) => verifyProcessSuccess(dispatch, res))
      .catch((err) => verifyProcessFailure(dispatch, err));
  };
};

const verifyProcessSuccess = async (dispatch, res) => {
  await toast.success(SUCCESS_MESSAGE);
  return dispatch({
    type: VERIFY_PROCESS_SUCCESS,
    payload: res.data,
  });
};
function verifyProcessFailure(dispatch, err) {
  return dispatch({
    type: VERIFY_PROCESS_FAILED,
    payload: err,
  });
}
