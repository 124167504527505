import { initialState } from './initialState';
import {
  fetchOutlierStart,
  fetchOutlierSuccess,
  fetchOutlierFail,
  setOutlierData,
  fetchNeumericDemographicsStart,
  fetchNeumericDemographicsSuccess,
  fetchNeumericDemographicsFail
} from './helper';
import {
  GET_OUTLIERS_DATA_FAIL,
  GET_OUTLIERS_DATA_START,
  GET_OUTLIERS_DATA_SUCCESS,
  SET_STORYBOARD_OUTLIER_DATA,
  FETCH_STORYBOARD_DEMOGRAPHICS_START,
  FETCH_STORYBOARD_DEMOGRAPHICS_SUCCESS,
  FETCH_STORYBOARD_DEMOGRAPHICS_FAILED
} from '../../constants';

const storyBoardOutlier = (state = initialState, action) => {
  switch (action.type) {
    case GET_OUTLIERS_DATA_START:
      return fetchOutlierStart(state, action.payload);
    case GET_OUTLIERS_DATA_SUCCESS:
      return fetchOutlierSuccess(state, action.payload);
    case GET_OUTLIERS_DATA_FAIL:
      return fetchOutlierFail(state, action.payload);
    case SET_STORYBOARD_OUTLIER_DATA:
      return setOutlierData(state, action.payload);
    case FETCH_STORYBOARD_DEMOGRAPHICS_START:
      return fetchNeumericDemographicsStart(state, action.payload);
    case FETCH_STORYBOARD_DEMOGRAPHICS_SUCCESS:
      return fetchNeumericDemographicsSuccess(state, action.payload);
    case FETCH_STORYBOARD_DEMOGRAPHICS_FAILED:
      return fetchNeumericDemographicsFail(state, action.payload);
    default:
      return state;
  }
};

export default storyBoardOutlier;
