import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const editReminderStarted = (state) => {
    return {
        ...state,
        editReminderApi: getApiStartState()
    }

}

export const editReminderSuccess = (state, payload) => {
    return {
        ...state,
        editReminderApi: getSuccessResponse(payload)
    }

}

export const editReminderFailed = (state, payload) => {
    return {
        ...state,
        editReminderApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}