/*eslint-disable*/
import React from "react";
import { snakeCaseToPascalCase } from "../../../../../../utils/textManipulations";
import { compareByAlpha } from "../../../../../../utils/compareByAlpha";
import { comapreDates } from "../../../../../../utils/comapreDates";
import moment from "moment-timezone";
const jstz = require("jstimezonedetect");
const timeZone = jstz.determine().name();

export default {
  defaultState: {
    searchText: "",
    approveId: "",
    downloadId: "",
    activeBatchOperation: null,
    selectedRows: [],
    showReprocessUpload: false,
    selectedDocument: undefined,
    isProcess: false,
  },
  approvedTooltip: (processing) =>
    processing
      ? "Action not permitted while a survey is being processed"
      : "Approve this survey",
  approvedTooltipCompleted: (completed) =>
    completed
      ? "Action not permitted already survey is completed"
      : "Approve this survey",
  downloadTooltip: (processing) =>
    processing
      ? "Action not permitted while a survey is being processed"
      : "Download processed comment data from this survey",
  deleteTooltip: (processing) =>
    processing
      ? "Action not permitted while a survey is being processed"
      : "Delete this survey and its comments",
  reprocessTooltip: (processing) =>
    processing
      ? "Action not permitted while a survey is being processed"
      : "Reprocess this survey with a new topic library file",
  reprocessTooltipCompoleted: (completed) =>
    completed
      ? "Action not permitted already survey is completed"
      : "Reprocess this survey with a new topic library file",
  defaultLoader: [
    {
      x: 64,
      y: 13,
      width: 180,
    },
    {
      x: 300,
      y: 13,
      width: 180,
    },
    {
      x: 582,
      y: 13,
      width: 180,
    },
    {
      x: 968,
      y: 13,
      width: 83,
    },
  ],
  columns: ({ searchProp, getTimeZonePicker, isAdmin }) => [
    {
      title: "Report Name",
      dataIndex: "projectName",
      key: "projectName",
      ...searchProp("projectName"),
      sorter: (a, b) => compareByAlpha(a.projectName, b.projectName),
      render: (str) => <b className="break-word" children={str} />,
    },
    {
      title: "Date",
      dataIndex: "projectCreationDate",
      key: "projectCreationDate",
      sorter: (a, b) => comapreDates(a.projectCreationDate, b.projectCreationDate),
      ...getTimeZonePicker("jobStartDte"),
      render: (date) => {
        let updatedDate = moment(date).tz(timeZone).format("llll")
        return updatedDate;
      }
    },
    {
      title: "Status",
      dataIndex: "projectStatus",
      key: "projectStatus",
      sorter: (a, b) => compareByAlpha(a.projectStatus, b.projectStatus),
      render: (string) => {
        if (string === "processed") return "Completed";
        return isAdmin ? snakeCaseToPascalCase(string) : "Processing";
      },
    },
  ],
  emptyMessage: "no data found",
  errorMessage: "data fetching failed",
  size: "small",
  popup: {
    title: "Are you sure want to delete this project?",
    okText: "Yes",
    cancelText: "No",
    btnText: "Delete",
    btnType: "danger",
  },
  user: "user",
  owner: "owner",
  userTitle: "Report owner",
  approve: "Approve",
  approved: "Approved",
  delete: "Delete",
  reprocess: "Reprocess",
  uploadTitle: "Reprocess ",
  description: "Choose and upload a new topic library to re-process the batch",
  tableTitle: "Your text analytics reports",
  deleteStyle: { color: "red", fontSize: "14px" },
  approval: "Approval",
  searchInTable: {
    style: { width: 188, marginBottom: 8, display: "block" },
    search: {
      btnType: "primary",
      size: "small",
      text: "Search",
    },
    reset: {
      size: "small",
      text: "Reset",
    },
  },
  download: "Download",
  emptyString: "",
  operations: [
    {
      name: "delete",
      label: "Delete",
      btnType: "danger",
    },
    {
      name: "approve",
      label: "Approve",
      btnType: "primary",
      adminOnly: true,
    },
  ],
  danger: "danger",
  primary: "primary",
  batchOperationIcon: {
    title: "Perform Batch Operation",
    titlePlacement: "left",
    icon: "setting",
    closeIcon: "close",
  },
  close: "close",
  batchApproveConfirmationMessage: "Do you Want to approve these jobs?",
  batchDeleteConfirmationMessage: "Do you Want to delete these jobs?",
};
