export const commitmentTemplate = [
    {
        questionDimension: 'Commitment',
        type: 'Matrix',
        questionLabel: 'Commitment',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: 'My organization values me as an individual.', value: '', isSelected: true
        },
        {
            text: `I see my organization's success as my success.`, value: '', isSelected: true

        },
        {
            text: `I haven't looked for other jobs in the last six months.`, value: '', isSelected: false

        }]
    }
]

