import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
export default {
  ORG_ADMIN: "Organization Admin",
  defaultTitle: "Organization",
  button: {
    variant: "outline-primary",
    size: "md",
  },
  iconButton: {
    iconName:  faTrashAlt,
    iconDelete:  faTrashAlt,
    classes: ["icon"],
  },
  VIEW_SURVEY: "View Surveys",
  MANAGE_USER: "Manage Users",
  path: {
    organizations: "/admin/home/organizations/",
    survey: "/home/",
    users: "/admin/home/organizations/",
  },
  DELETE: "Delete",
  OK_BUTTON_PROP: { type: "danger" },
  DELETE_WARNING: "Deleting this organization cannot be undone.",
  DELETE_QUESTION: "Do you Want to delete this organization?",
  WARNING: "warning",
};
