import {
  NEW_FORGOT_PASSWORD_PROCESS_STARTED,
  NEW_FORGOT_PASSWORD_PROCESS_SUCCESS,
  NEW_FORGOT_PASSWORD_PROCESS_FAILED,
  NEW_FORGOT_PASSWORD_CLEAR_STATE

} from '../../constants';
import { initialState } from './initialState';
import {
  newForgotPasswordStart,
  newForgotPasswordSuccess,
  newForgotPasswordFailed,
  newForgotPasswordClearState

} from './helper';
import { fetchPayload } from '../../../utils/reducerOperations';


const NewForgotPasswordReducer = (state = initialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case NEW_FORGOT_PASSWORD_PROCESS_STARTED:
      return newForgotPasswordStart(state, payload);
    case NEW_FORGOT_PASSWORD_PROCESS_SUCCESS:
      return newForgotPasswordSuccess(state, payload);
    case NEW_FORGOT_PASSWORD_PROCESS_FAILED:
      return newForgotPasswordFailed(state, payload);
    case NEW_FORGOT_PASSWORD_CLEAR_STATE:
      return newForgotPasswordClearState(state, payload);
    default:
      return { ...state }
  }
};

export default NewForgotPasswordReducer;
