import React from "react";
import { EmptyState } from "../../Packages/EmptyState";
import { Spin } from "antd";
const NO_SURVEY = "No projects found";

const RenderComponent = (props) => {
  if (
    (props.api.isProcessing === undefined && props.api.loading) ||
    (!(props.api.isProcessing === undefined) &&
      props.api.isProcessing !== false) ||
    (props.demographicsApi && props.demographicsApi.isProcessing === true) ||
    (props.api.loading)
  )
    return props.loader || <Spin />;
  else if (props.tableData && props.tableData.length) {
    return props.children();
  } else if (props.api.data && props.api.data.length) {
    return props.children();
  } else if (checkCustomComponents(props.component) && props.api.data) {
    return props.children();
  } else return emptyState(props.api, props.demographicsApi);
};

const emptyState = (api, demographicsApi) => {
  var type = "";
  if ((api && api.error) || (demographicsApi && demographicsApi.error)) type = "Network Error";
  return (
    <EmptyState
      customHeight='400'
      type={type}
      component="table"
      emptyStateMessage={NO_SURVEY} />
  );
};

const checkCustomComponents = (component) => {
  switch (component) {
    case "dashboard":
    case "preview":
    case "attendSurvey":
      return true;
    default:
      return false;
  }
};

export default RenderComponent;
