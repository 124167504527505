
import React, { Component } from 'react';
import { getRankingShape } from './helper';
import ErrorBlock from '../ErrorBlock';
import { AsterikLabel } from '../../../../../../Components/AsterikLabel';
import parse from "html-react-parser";
import ScrollBar from '../../../../../../Packages/ScrollBar';
import { getQuestionTranslation } from '../../../../../../utils/newAttendSurveyUtils';
import { KEY_CONSTANTS } from '../../constants';

class RatingScaleQuestion extends Component {


    handleClick = (index) => {
        this.props.handleChangeRating({ index: index, blockId: this.props.questionDetails.blockId })
        setTimeout(() => {
            this.props.updateCurrentIndex()
        }, 400)
    }

    renderIcons = (options) => {
        let ratingShape = getRankingShape(options.shape)
        let headers = []
        let total = options.totalRatingValue
        let answer = this.props.currentQuestionSet && this.props.currentQuestionSet.answer ? this.props.currentQuestionSet.answer : 0
        for (let index = 0; index < total; index++) {
            if (index < answer) {
                headers.push(<div className='answer-rating__rating' key={index} onClick={() => this.handleClick(index + 1)}>{ratingShape.filled}<span>{index + 1}</span></div>);
            }
            else {
                headers.push(<div className='answer-rating__rating' key={index} onClick={() => this.handleClick(index + 1)}>{ratingShape.notFilled}<span>{index + 1}</span></div>);
            }

        }
        return headers
    }

    render() {
        return (
            <div>
                    {this.props.questionDetails.questions.map((item, index) =>
                        <div> <h3 className={this.props.questionDetails.isRequired ? 'heading-asterisk' : ""}>
                            {this.props.selectedLanguage === KEY_CONSTANTS.DEFAULT_LANGUAGE ? parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: item.HTMLQuestion ? KEY_CONSTANTS.HTML_QUESTION : KEY_CONSTANTS.QUESTION })) : <p>{parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: item.HTMLQuestion ? KEY_CONSTANTS.HTML_QUESTION : KEY_CONSTANTS.QUESTION }))}</p>}</h3> <div className='rating-answer'>
                            <div className={`${this.props.questionDetails.blockImage ? `${this.props.currentClassname}answer-rating answer-rating-img` : `${this.props.currentClassname}answer-rating`}`}>{this.renderIcons(item)}</div>
                        </div>
                        </div>)}
                <ErrorBlock manageScroller={this.props.manageScroller} />
            </div>
        );
    }
}

export default RatingScaleQuestion;
