import {
  FETCH_OVERALL_EXPLORE_PARTICIPATION_FAILED,
  FETCH_OVERALL_EXPLORE_PARTICIPATION_SUCCESS,
  FETCH_OVERALL_EXPLORE_PARTICIPATION_START,
} from "../../constants";
import { GET_PARTICIPATON_RATE_URL } from "../../../../config/constants";
import { ApiBase } from "../../../utils/apiBase";
import { withType } from "../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchOverAllExploreParticipationData = (payload, cb) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(FETCH_OVERALL_EXPLORE_PARTICIPATION_START, {}));
    return apiBaseInstance
      .post(GET_PARTICIPATON_RATE_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        dispatch(
          withType(FETCH_OVERALL_EXPLORE_PARTICIPATION_SUCCESS, {
            data: res.data,
          })
        );
        setTimeout(() => {
          cb && cb();
        }, 200);
      })
      .catch((err) => {
        dispatch(withType(FETCH_OVERALL_EXPLORE_PARTICIPATION_FAILED, err));
      });
  };
};
