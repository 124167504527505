export const generateAnswerForMultipleSelection = (currentEvents, optionList,answeredOption) => {   
    let answerArray = []
    if(answeredOption) answeredOption= JSON.parse(answeredOption)
    currentEvents.forEach((answer) => {
        let findedAnswerOption = optionList.find((item => item.value === answer))
        if(findedAnswerOption.isOtherOption){
            let textAnswer = "";
            if (answeredOption && answeredOption.length > 0) {
                let answeredTextOption = answeredOption.find((item) => item.answer === findedAnswerOption.value);
                if (answeredTextOption) {
                    textAnswer = answeredTextOption.textAnswer || "";
                }
            }
            answerArray.push({
                answer: findedAnswerOption.value,
                answerLabel: findedAnswerOption.label,
                isOtherOption:findedAnswerOption.isOtherOption,
                textAnswer:textAnswer
            })
        }
        else{
        answerArray.push({
            answer: findedAnswerOption.value,
            answerLabel: findedAnswerOption.label
        })
    }
    });
    return JSON.stringify(answerArray)
}

export const getAnswerValue = (valueArray) => {
    let valueArrayAnswers = []
    valueArray && valueArray.forEach((currentAnswer) => {
        valueArrayAnswers.push(currentAnswer.answer)

    })
    return valueArrayAnswers
}