import { setInitialState } from '../../../../utils/reducerOperations';

export const InitialState = () => ({
    fetchExploreDriversDataApi: {
        ...setInitialState(),
        strengthAndWeakness: {
            strengths: [],
            weaknesses: []
        }
    },
    driverDimensionsApi: {
        isProcessing: false,
        error: false,
        errorMessage: "",
        isInitialApiCalled: false,
        dimensions: [],
    },
    isDataAlreadyFetched: false,
    filters: {},
    selectedUser: "",
    directReporteesOnly: false,
    byScore: [],
    multipleSelect: [],
    selectedTopic:null,
    selectedQuestionId:null
});