export function createRoleSuccess(state, payload) {
  return {
    ...state,
    createRoleApi:{
      loading:false,
      error:false
    }
  }
}

export function createRoleStarted(state, payload) {
  return {
    ...state,
    createRoleApi:{
      loading:true,
      error:false
    }
  }
}

export function createRoleFail(state, payload) {
  return {
    ...state,
    createRoleApi:{
      loading:false,
      error:true
    }
  }
}