import { deepCopy } from "../../../../utils/deepCopy";
import { getFilteredMenuData } from "../../../../utils/newAttendSurveyUtils";
import { generateGoalsForSurveyEditor } from "../../../../utils/newEditSurveyUtils";

export const fetchSurveyWizardProjectDetailsStart = (state, payload) => {
    if (payload) {
        if (payload.hrisFileId) {
            return {
                ...state,
                surveyWizardProjectDetailsApi: {
                    ...state.surveyWizardProjectDetailsApi,
                    hrisData: { ...payload },
                    isProcessing: true,
                }
            };
        } else {
            return {
                ...state,
                surveyWizardProjectDetailsApi: {
                    ...state.surveyWizardProjectDetailsApi,
                    data: {
                        ...state.surveyWizardProjectDetailsApi.data,
                        ...payload
                    },
                    isProcessing: true,
                }
            };
        }
    } else {
        return {
            ...state,
            surveyWizardProjectDetailsApi: {
                ...state.surveyWizardProjectDetailsApi,
                isProcessing: true,
            }
        };
    }
}

export const fetchSurveyWizardProjectDetailsSuccess = (state, payload) => {
    let data = payload.data.response.projectDetails.jobData[0]._source
    let automaticallyCloseSurvey = deepCopy(data).surveyEndDate ?
        { closeSurveyAutomatically: true } : { closeSurveyAutomatically: false }
    let goals = getGoals(data, state)
    let aboutSurvey = data.aboutSurvey?data.aboutSurvey:""
    let updatedGoals = data.questionSetUpdatedDate ? data.questionSet : generateGoalsForSurveyEditor(deepCopy(data))
    return {
        ...state,
        surveyWizardProjectDetailsApi: {
            isProcessing: false,
            data: deepCopy(data),
            reminderData: payload.data.response.projectDetails.remainderData,
            hrisData: payload.data.response.projectDetails.hrisData ? payload.data.response.projectDetails.hrisData : {},
            error: false,
            message: payload.message
        },
        logoData: {
            logoUrl: data && data.logoUrl ? data.logoUrl : null
        },
        goals: updatedGoals,
        localStateData: {
            ...state.localStateData,
            ...automaticallyCloseSurvey,
            projectName : data.projectName,
            aboutSurvey: aboutSurvey,
            questionSetUpdatedDate: data.questionSetUpdatedDate ? data.questionSetUpdatedDate : new Date().toISOString(),
            endMessage: data.endMessage ? data.endMessage : { message: "You have reached the end of the survey", buttonName: "Submit" }
        }
    };
}

export const fetchSurveyWizardProjectDetailsFail = (state, payload) => {
    return {
        ...state,
        surveyWizardProjectDetailsApi: {
            isProcessing: false,
            error: true,
            data: false,
            message: payload.message,
            reminderData: [],
            hrisData: {}
        },
    };
};

const getGoals = (details, state) => {
    let data = deepCopy(details)
    if (data.questionSet && data.questionSet.length !== 0) {
        return removePageBreakGoals(data.questionSet)
    } else if (data.goals && data.goals.length !== 0) {
        return removePageBreakGoals(data.goals)
    }else{
        return deepCopy(state.defaultGoals)
    }
}


const removePageBreakGoals = (goal) => {
    let goalsCopy = deepCopy(goal);
    var removedPageBreakGoals = [];
    goalsCopy.forEach((item, firstIndex) => {
        (item.goalId === "demographics") &&
            item.questions.forEach((question, index) => {
                if (question.question !== 'Page Break') {
                    removedPageBreakGoals.push(question);
                }
                goalsCopy[firstIndex].questions = removedPageBreakGoals;
            })
    })
    let multipleOptionPageBreakRemoved = removePageBreakGoalsMultipleOption(goalsCopy)
    let pageBreakRemovedGoals = removePageBreakGoalsCommentSection(multipleOptionPageBreakRemoved)
    return pageBreakRemovedGoals
}

const removePageBreakGoalsCommentSection = (goal) => {
    let goalsCopy = deepCopy(goal);
    var removedPageBreakGoals = [];
    goalsCopy.forEach((item, firstIndex) => {
        item.goalId === "comments" &&
            item.questions.forEach((question, index) => {
                if (question.question !== 'Page Break') {
                    removedPageBreakGoals.push(question);
                }
                goalsCopy[firstIndex].questions = removedPageBreakGoals;
            })
    })
    return goalsCopy
}

const addMultipleOptionObjIfNotPresent = (goals) => {
    let reArrangedGoals = []
    let isMultipleOptionPresent = false
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach((item) => {
        if (item.goalId === "multipleOption") {
            isMultipleOptionPresent = true
        }
    })
    if (!isMultipleOptionPresent) {
        goalsCopy.forEach((item) => {
            reArrangedGoals.push(item)
            if (item.goalId === "demographics") {
                reArrangedGoals.push(multipleOptionObject)
            }
        })
        return reArrangedGoals
    }
    return goalsCopy
}

const multipleOptionObject = {
    dimension: 'multipleOption',
    questions: [],
    isTemplate: true,
    anonymousOnly: true,
    isMultipleOption: true,
    isSelected: true,
    goalId: 'multipleOption'
}

const removePageBreakGoalsMultipleOption = (goal) => {
    let goalsCopy = deepCopy(goal);
    var removedPageBreakGoals = [];
    goalsCopy.forEach((item, firstIndex) => {
        (item.goalId === "multipleOption") &&
            item.questions.forEach((question, index) => {
                if (question.question !== 'Page Break') {
                    removedPageBreakGoals.push(question);
                }
                goalsCopy[firstIndex].questions = removedPageBreakGoals;
            })
    })
    let pageBreakRemovedGoals = removePageBreakGoalsCommentSection(goalsCopy)
    return pageBreakRemovedGoals
}

