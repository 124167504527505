export const setTrendDemographicsTopicsQuestions = (state, payload) => {
    return {
        ...state,
        demographicsSelectedTopics: payload.topics,
        demographicsSelectedQuestions: payload.questions
    };
};

export const setTrendTreeSelectTopicQuestions = (state, payload) => {
    return {
        ...state,
        selectedDemographicsTopicTree: payload.treeSelectTopic,
        selectedDemographicsQuestionTree: payload.treeSelectQuestion
    }
}