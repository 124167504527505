export function Default() {
    return {

        fetchResultsPageApi: {
            isProcessing: false,
            error: false,
            data: {},

        },
    }
}