import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";
export const communicationTemplate = [
    {
        questionDimension: 'Communication',
        type: 'Matrix',
        questionLabel: 'Communication',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',

        qid: uuidv4(),
        matrixQuestions: [
            {
                text: 'This organization listens to what I have to say.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('This organization listens to what I have to say.')

            },
            {
                text: 'There is honest and open two-way communication at my organization.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('There is honest and open two-way communication at my organization.')

            },
            {
                text: 'My organization is effective communicating about changes.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My organization is effective communicating about changes.')

            },
            {
                text: 'My organization freely shares information about topics that affect our business.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My organization freely shares information about topics that affect our business.')
            }]
    }
]

