import { deepCopy } from "../../../../../../utils/deepCopy"
import { toast } from "../../../../../../utils/messageUtils"

export const handleAddFromLibrary = (state, payload) => {
    const updatedGoalsWithNewQuestion = getUpdatedGoalsWithNewQuestion(state.goals, payload)
    toast.success(`${payload.selectedQuestion.dimension} added successfully`)
    return (
        {
            ...state,
            goals: updatedGoalsWithNewQuestion
        }
    )
}

const getUpdatedGoalsWithNewQuestion = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    let selectedIndex = goalsCopy.findIndex(item => item.blockId === payload.blockId)
    if (selectedIndex !== -1) {
        goalsCopy[selectedIndex] = { blockId: goalsCopy[selectedIndex].blockId, ...payload.selectedQuestion }
    }
    return goalsCopy

}