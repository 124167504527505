import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const growthAndDevelopment = {
    "blockId": uuidv4(),
    "description": "<p>In this section, please consider the following behaviors focused on areas such as: Goal-Orientation, Self-Awareness, and Continuous Learning.</p>",
    "goalId": "dynamic180Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Rarely  ",
                    "value": "1"
                },
                {
                    "label": "Not Often Enough",
                    "value": "2"
                },
                {
                    "label": "Often ",
                    "value": "3"
                },
                {
                    "label": "Almost Always  ",
                    "value": "4"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "staticQuestionId": generateStaticQuestionId("Sets stretch-oriented personal and professional goals"),
                    "HTMLText": "<p>Sets stretch-oriented personal and professional goals.</p>",
                    "isSelected": true,
                    "text": "Sets stretch-oriented personal and professional goals.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Focuses both on the process and the end-goals to achieve greater impact"),
                    "HTMLText": "<p>Focuses both on the process and the end-goals to achieve greater impact.</p>",
                    "isSelected": true,
                    "text": "Focuses both on the process and the end-goals to achieve greater impact.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Stays motivated to achieve outcomes even when setbacks occur"),
                    "HTMLText": "<p>Stays motivated to achieve outcomes even when setbacks occur.</p>",
                    "isSelected": true,
                    "text": "Stays motivated to achieve outcomes even when setbacks occur.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Maintains clarity and focuses on improving key strengths, opportunities for improvement, and blind spots."),
                    "HTMLText": "<p>Maintains clarity and focuses on improving key strengths, opportunities for improvement, and blind spots.</p>",
                    "isSelected": true,
                    "text": "Maintains clarity and focuses on improving key strengths, opportunities for improvement, and blind spots.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Seeks and integrates ongoing coaching and feedback"),
                    "HTMLText": "<p>Seeks and integrates ongoing coaching and feedback.</p>",
                    "isSelected": true,
                    "text": "Seeks and integrates ongoing coaching and feedback.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Balances time and energy related to performance, development, and well-being"),
                    "HTMLText": "<p>Balances time and energy related to performance, development, and well-being.</p>",
                    "isSelected": true,
                    "text": "Balances time and energy related to performance, development, and well-being.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Maintains clarity and confidence in working towards long-term aspirations"),
                    "HTMLText": "<p>Maintains clarity and confidence in working towards long-term aspirations.</p>",
                    "isSelected": true,
                    "text": "Maintains clarity and confidence in working towards long-term aspirations.",
                    "value": uuidv4()
                },
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Growth & Development"
}