import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const getTextQuestionListStarted = (state) => {
    return {
        ...state,
        getTextQuestionListApi: getApiStartState()
    }

}

export const getTextQuestionListSuccess = (state, payload) => {
    return {
        ...state,
        getTextQuestionListApi: getSuccessResponse(payload)
    }

}

export const getTextQuestionListFailed = (state, payload) => {
    return {
        ...state,
        getTextQuestionListApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}