import { toast } from "../../../../utils/messageUtils";

export function addAdminSuccess(state, payload) {
  toast.success(
    "Admin created successfully and an email invitation has been sent to the admin"
  );
  return {
    ...state,
    addAdminApi: {
      loading: false,
      error: false,
    },
  };
}

export function addAdminStarted(state, payload) {
  return {
    ...state,
    addAdminApi: {
      loading: true,
      error: false,
    },
  };
}

export function addAdminFail(state, payload) {
  getErrorMessage(payload);
  return {
    ...state,
    addAdminApi: {
      loading: false,
      error: true,
    },
  };
}

const getErrorMessage = (payload) => {
  if (payload.payload) {
    if (payload.payload.response) {
      if (payload.payload.response.data) {
        const { reason } = payload.payload.response.data;
        toast.error(reason);
      }
    }
  } else toast.error("Failed to add new admins");
};
