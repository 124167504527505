export const diversityTemplate = [
    {
        questionDimension: 'Diversity',
        type: 'Matrix',
        questionLabel: 'Diversity',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: 'My organization values diverse perspectives.', value: '', isSelected: true
        },
        {
            text: 'All employees, regardless of their backgrounds, are treated fairly.', value: '', isSelected: true

        },
        {
            text: 'Diversity and inclusion are important priorities for my organization.', value: '', isSelected: true

        },
        {
            text: 'Senior leaders demonstrate that they are committed to diversity and inclusion.', value: '', isSelected: false

        }]
    }
]
