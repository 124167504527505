import { 
    apiStartState,
    apiFailureState,
    apiSuccessState
} from '../../../../utils/apiStateUtils';

export function reprocessUploadStart(state,action) {
    return {
        ...state,
        reprocessUploadApi: apiStartState(state.reprocessUploadApi)
    };
};
export function reprocessFileUploadSuccess(state, action) {
    const api=apiSuccessState(state.reprocessUploadApi, action);
    return {
        ...state,
        reprocessUploadApi: {
            ...api,
            name:action.fileName
        }
    };
}
export function reprocessUploadFail(state, action) {
    return {
        ...state,
        reprocessUploadApi: apiFailureState(state.reprocessUploadApi,action)
    };
};