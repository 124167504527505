import React from "react";
import ScoreElement from "../ScoreElement";
export default class StrengthAndWeaknessDataView extends React.Component {
  render() {
    return (
      <div className="drivers-content drivers-area">
        <div className="drivers-content__item">
          <div className="drivers-content__item--right">
            <ul>
              <ScoreElement data={this.props.topScores} title={"Top scores"} />
            </ul>
            <ul>
              <ScoreElement data={this.props.bottomScores} title={"Lowest scores"} />
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
