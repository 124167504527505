import { fetchPayload, update } from "../../../utils/reducerOperations";
import {
  GET_USER_PROFILE_STARTED,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILED,
} from "../../constants";
import { Default } from "./InitialState";
import {
  getUserProfileStarted,
  getUserProfileFailed,
  getUserProfileSuccess,
} from "./Helpers";
const getProfilePageReducer = (state = Default(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case GET_USER_PROFILE_STARTED:
      return update(state, getUserProfileStarted(state, payload));
    case GET_USER_PROFILE_SUCCESS:
      return update(state, getUserProfileSuccess(state, payload));
    default:
      return getProfileFailed(state, action);
  }
};
const getProfileFailed = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case GET_USER_PROFILE_FAILED:
      return update(state, getUserProfileFailed(state, payload));
    default:
      return { ...state };
  }
};

export default getProfilePageReducer;
