export const fetchCohortSurveyChildListSurveyWizardStart = (state, payload) => {
    return {
        ...state,
        fetchChildCohortListingInSurveyWizard: {
            ...state.fetchChildCohortListingInSurveyWizard,
            isProcessing: true,
            error: false, reason: '',
        },
    };
};

export const fetchCohortSurveyChildListSurveyWizardSuccess = (state, payload) => {
    let formattedDropDownData = getFormattedDropDownData(payload)
    return {
        ...state,
        fetchChildCohortListingInSurveyWizard: {
            ...state.fetchChildCohortListingInSurveyWizard,
            isProcessing: false,
            data: payload.data.data.hits,
            error: false, reason: '',
            dropdownData: formattedDropDownData
        },
    };
};

export const fetchCohortSurveyChildListSurveyWizardFail = (state, payload) => {
    return {
        ...state,
        fetchChildCohortListingInSurveyWizard: {
            ...state.fetchChildCohortListingInSurveyWizard,
            isProcessing: false,
            error: true,
            reason: payload.error,
        },
    };
};

const getFormattedDropDownData = (response) => {
    let dropdownData = []
    if (response.data.data.hits && response.data.data.hits.length > 0) {
        dropdownData = [{
            title: "All",
            value: "all",
            children: response.data.data.hits.map((currentSurvey) => {
                return {
                    title: currentSurvey.projectName,
                    value: currentSurvey.projectId
                }
            })
        }

        ]
    }
    return dropdownData
}