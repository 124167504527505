import { DYNAMIC_360_GOAL_IDS, FEEDBACK_360_SURVEY, QUESTION_TYPE } from "../../../../../config/questionTypeConstants"
import { CANT_ANSWER, NON_MAPPING_KEYS } from "../constants"

export const generateSummaryRatings = (questionSetCopy) => {
    let summaryScale = {}
    let totalQuestionsCount = 0
    let optionsInitialized = false
    let findedDragIndex = questionSetCopy.findIndex((item) => item.goalId === DYNAMIC_360_GOAL_IDS.STRENGTH_DRAG || item.goalId === DYNAMIC_360_GOAL_IDS.STRENGTH_DRAG_180)
    let findedImprovementDragIndex = questionSetCopy.findIndex((item) => item.goalId === DYNAMIC_360_GOAL_IDS.IMPROVEMENT_DRAG || item.goalId === DYNAMIC_360_GOAL_IDS.IMPROVEMENT_DRAG_180)
    if (isQuestionCountLessThanThreshold(questionSetCopy[findedDragIndex].questions[0]) || isQuestionCountLessThanThreshold(questionSetCopy[findedImprovementDragIndex].questions[0])) {
        questionSetCopy.forEach((block) => {
            if (block.type === QUESTION_TYPE.MATRIX) {
                if (!optionsInitialized && block.questions && block.questions.length > 0 && block.questions[0].options) {
                    block.questions[0].options.forEach(option => {
                        if (option.value !== "0") {
                            summaryScale[option.value] = { answerLabel: option.label, scaleCount: 0, ...option };

                        }
                    });
                    optionsInitialized = true;
                }
                totalQuestionsCount = totalQuestionsCount + block.questions[0].matrixQuestions.length
                if (block.questions[0].answer && block.questions[0].answer.length > 0) {
                    let currentAnswer = JSON.parse(block.questions[0].answer)
                    currentAnswer.forEach((answer) => {
                        if (answer.columnId !== CANT_ANSWER && summaryScale[answer.columnId]) {
                            summaryScale[answer.columnId].scaleCount += 1
                        }
                    })
                }
            }
        })
        return { summaryScale, totalQuestionsCount }
    }
    return { isCurrentIndexChangeNeeded: true }

}

const isQuestionCountLessThanThreshold = (answeredQuestionDetails) => {
    let questionCount = 0
    if (answeredQuestionDetails) {
        for (const key in answeredQuestionDetails) {
            if (answeredQuestionDetails[key].questions && Array.isArray(answeredQuestionDetails[key].questions)) {
                questionCount += answeredQuestionDetails[key].questions.length;
                if (questionCount > 3) {
                    break
                }

            }
        }
    }
    if (questionCount < 3) {
        return true
    }
    return false
}

export const handleChangePageIndex = (state, payload) => {
    return { ...state, currentIndex: 1, progressIndex: 1 }
}

export const handleChangeClickProceed = (state, payload) => {
    let findedIndex = nextIndexToDisplay(state.pageBreakAddedQuestionSet, state.currentIndex)
    return { ...state, currentIndex: findedIndex, progressIndex: findedIndex }
}

const nextIndexToDisplay = (pageBreakAddedQuestionSetDetails, currentIndex) => {
    let updatedIndex = currentIndex + 1
    let findedStrengthItem = pageBreakAddedQuestionSetDetails.find((item) => item.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG)
    let strengthCount = findQuestionsCountInDragPage(findedStrengthItem.questions[0])
    if (strengthCount < 3) {
        updatedIndex = updatedIndex + 2
        let findedImprovementItem = pageBreakAddedQuestionSetDetails.find((item) => item.goalId === FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG)
        let improvementCount = findQuestionsCountInDragPage(findedImprovementItem.questions[0])
        if (improvementCount < 3) {
            updatedIndex = updatedIndex + 2
        }
    }
    return updatedIndex
}

const findQuestionsCountInDragPage = (answeredQuestionDetails) => {
    let questionCount = 0
    if (answeredQuestionDetails) {
        for (const key in answeredQuestionDetails) {
            if (answeredQuestionDetails[key].questions && Array.isArray(answeredQuestionDetails[key].questions)) {
                questionCount += answeredQuestionDetails[key].questions.length;
            }
        }

    }
    return questionCount
}