import { nlpGetURLParams } from "../../../../utils/nlpURLUtils";

export const fetchExploreDashBoardFilterStart = (state, payload) => {
  return {
    ...state,
    [payload.selectedQuestionId]: {
      ...state[payload.selectedQuestionId],
      dashBoardFilterData:
        state[payload.selectedQuestionId] && state[payload.selectedQuestionId].dashBoardFilterData != null
          ? state[payload.selectedQuestionId].dashBoardFilterData
          : null,
      exploreDashboardFilter: state[payload.selectedQuestionId] && state[payload.selectedQuestionId].dashBoardFilterData != null
        ? state[payload.selectedQuestionId].dashBoardFilterData
        : null,
      error: null,
      exploreFilterSearchText: "",
      dashboardFilterProcessing: true,
      exploreDashboardAlreayFetched: state[payload.selectedQuestionId] && state[payload.selectedQuestionId].exploreDashboardAlreayFetched
        ? state[payload.selectedQuestionId].exploreDashboardAlreayFetched
        : false
    },
  };
};

export const fetchExploreDashBoardFilterSuccess = (state, payload) => {
  const data = formatResponse(payload.data);
  return {
    ...state,
    [payload.selectedQuestionId]: {
      ...state[payload.selectedQuestionId],
      dashBoardFilterData: data,
      exploreDashboardFilter: data,
      exploreDashboardAlreayFetched: true,
      exploreFilterSearchText: "",
      dashboardFilterProcessing: false,
    },
  };
};

export const fetchExploreDashBoardFilterFail = (state, payload) => {
  return {
    ...state,
    [payload.selectedQuestionId]: {
      ...state[payload.selectedQuestionId],
      dashBoardFilterData: [],
      exploreDashboardFilter: [],
      exploreFilterSearchText: "",
      dashboardFilterProcessing: false,
    },
  };
};

function formatResponse(data = {}) {
  const { filter } = nlpGetURLParams();
  const filters = []
  let question = []
  let options = {}
  Object.keys(data).forEach((label) => {
    let datas = [];
    let subOption = []
    let topicScoreLevelData = []
    if (label === "byScore") {
      for (const [key, value] of Object.entries(data[label])) {
        subOption.push(getSubOption(value, key))
        topicScoreLevelData.push(getTopicFavUnFavNeuMenuData(key, data))
        datas.push(`${key}:${value.count}`)
      }
    }
    else if (label === "multipleSelect") {
      for (const [key, value] of Object.entries(data[label])) {
        let questionObj = {
          text: `${key}:${value.count}`,
          questionId: value.questionId
        }
        question.push(questionObj)
        options[key] = value.options.map((item) => (
          `${item.label}:${item.count}`
        ))

      }
    }

    else {
      for (const [key, value] of Object.entries(data[label])) {
        datas.push(`${key}:${value}`);
      }
    }
    let rec
    if (label === "multipleSelect") {
      rec = {
        label, question, options
      }
    }
    else {
      rec = {
      label,
      selected: false,
      options: (datas || []).map((item) => ({
        value: item,
        selected: (filter[label] || []).includes(item.split(':')[0]),
      })),
      subOption,
      topicScoreLevelData
    };
    }
    filters.push(rec);
  });
  return filters.sort((firstValue, secondValue) => firstValue.label.localeCompare(secondValue.label));;
}

const getSubOption = (value, key) => {
  let datas = []
  value.questions.forEach(item => {
    datas.push({ text: `${item.question}:${item.count}`, questionId: item.questionId })
  })
  return { [key]: datas }
}

const getTopicFavUnFavNeuMenuData = (topic, data) => {
  let topicData = data.byScore[topic]
  return {
    [topic]: [
      ` Favorable: ${topicData.favourable}`,
      ` Unfavorable: ${topicData.unFavourable}`,
      `Neutral: ${topicData.neutral}`
    ]
  }
}


export const changeExploreDashboardSearchFilter = (state, payload) => {
  let data = searchFilterData(payload.searchText, state[payload.selectedQuestionId].exploreDashboardFilter)
  return {
    ...state,
    [payload.selectedQuestionId]: {
      ...state[payload.selectedQuestionId],
      dashBoardFilterData: data,
      exploreFilterSearchText: payload.searchText
    },
  };
};

function searchFilterData(searchText, data) {
  let filterArr = data.filter(
    (item) => item.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1
  );
  return filterArr;
}
