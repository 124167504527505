import {
  update,
  makeKey,
  handleApiResponseError,
  handleNetworkError,
  storeDefaultHRISValue,
} from "../../../../utils/reducerOperations";

export const fetchHRISStarted = (state) => {
  return makeKey(
    "fetchHRISApi",
    update(state.fetchHRISApi, { isProcessing: true })
  );
};
export const fetchHRISSuccess = (state, payload) => {
  //const newPayload = sortHrisFileList(payload);
  storeDefaultHRISValue(payload);
  return makeKey(
    "fetchHRISApi",
    update(state.fetchHRISApi, {
      message: payload.message,
      isProcessing: false,
      data: payload.data.response,
      error: !payload.status,
      page: 0,
    })
  );
};
export const fetchHRISFailed = (state, payload) => {
  return makeKey(
    "fetctHRISApi",
    update(
      state.fetchHRISApi,
      payload.response && payload.response.data
        ? handleApiResponseError(payload.response.data)
        : handleNetworkError(payload)
    )
  );
};
