import { toast } from '../../../../utils/messageUtils';
import {
    makeKey,
    update,
    getSuccessResponse,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';

const fetchOutlierApi = 'fetchOutlierApi';
export const fetchOutlierSuccess = (state, payload) => {
    return makeKey(
        fetchOutlierApi,
        update(state.fetchOutlierApi,
            getSuccessResponse(payload))
    );
};

export const fetchOutlierStarted = (state, payload) => {
    return makeKey(
        fetchOutlierApi,
        update(state.fetchOutlierApi, {
            isProcessing: true,
            error: false,
            data: null
        })
    );
};

export const fetchOutlierFailed = (state, payload) => {
    let response = makeKey(
        fetchOutlierApi,
        update(state.fetchOutlierApi, {
            ...(payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload))
        })
    );
    toast.error(response.fetchOutlierApi.message);
    return response;
};

export const updateFiltersVisibility = (state, payload) => {
    let filters=Object.assign([],state.filters);
    if(payload.action==='hide'){
        filters[payload.index].visible=false;
    }
    if(payload.action==='toggle')
        filters[payload.index].checked=
        !filters[payload.index].checked;
    if(!payload.action)
        filters=filters.map(item =>({
            ...item,
            visible : true,
            checked : true
        }));
    return {...state,filters};
};