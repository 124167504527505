import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const multipleOptionTemplateGender = [
    {
        type: 'multipleOption',
        questionLabel: 'Gender',
        questionDimension: 'multipleOption',
        isSelected: true,
        options: [
            {
                label: "Male",
                value: "1",
                id: "gender-1"
            },
            {
                label: "Female",
                value: "2",
                id: "gender-2"
            },
            {
                label: "Other",
                value: "3",
                id: "gender-3"
            }
        ],
        qid: uuidv4(),
        question: 'What is your gender?',
        staticQuestionId: generateStaticQuestionId('What is your gender?'),
        isDemographic: true
    },
]

export const multipleOptionTemplateGeneration = [
    {
        type: 'multipleOption',
        questionDimension: 'multipleOption',
        isSelected: true,
        options: [
            {
                label: "Generation  Z (Born 1997-2012)",
                value: "1",
                id: "Age-1"
            },
            {
                label: "Millennial (Born 1981-1996)",
                value: "2",
                id: "Age-2"
            },
            {
                label: "Generation X (Born 1965-1980)",
                value: "3",
                id: "Age-3"
            },
            {
                label: "Baby Boomer (Born 1946-1964)",
                value: "4",
                id: "Age-4"
            },
            {
                label: "Silent (Born 1928-1945)",
                value: "5",
                id: "Age-5"
            }
        ],
        qid: uuidv4(),
        question: 'What generation are you a part of?',
        questionLabel: 'Age',
        isDemographic: true,
        staticQuestionId: generateStaticQuestionId('What generation are you a part of?'),
    }
]

export const multipleOptionTemplateJobTenure = [{
    type: 'multipleOption',
    questionDimension: 'multipleOption',
    isSelected: true,
    options: [
        {
            label: "Less than 1 year",
            value: "1",
            id: "job tenure-1"
        },
        {
            label: "1-3 years",
            value: "2",
            id: "job tenure-2"
        },
        {
            label: "4-6 years",
            value: "3",
            id: "job tenure-3"
        },
        {
            label: "6-8 years",
            value: "4",
            id: "job tenure-4"
        },
        {
            label: "More than 8 years",
            value: "5",
            id: "job tenure-5"
        }
    ],
    qid: uuidv4(),
    question: 'How long have you worked in your current job?',
    questionLabel: 'Job Tenure',
    isDemographic: true,
    staticQuestionId: generateStaticQuestionId('How long have you worked in your current job?'),
}]


export const multipleOptionTemplateRace = [
    {
        type: 'multipleOption',
        questionDimension: 'multipleOption',
        isSelected: true,
        options: [
            {
                label: "Asian",
                value: "1",
                id: "race/ethnicity-1"
            },
            {
                label: "Black",
                value: "2",
                id: "race/ethnicity-2"
            },
            {
                label: "Hispanic/Latino",
                value: "3",
                id: "race/ethnicity-3"
            },
            {
                label: "White",
                value: "4",
                id: "race/ethnicity-4"
            },
            {
                label: "Other",
                value: "5",
                id: "race/ethnicity-5"
            }
        ],
        qid: uuidv4(),
        question: 'What is your race/ethnicity?',
        questionLabel: 'Race/Ethnicity',
        isDemographic: true,
        staticQuestionId: generateStaticQuestionId('What is your race/ethnicity?'),
    },
]

export const multipleOptionTemplateWork = [{
    type: 'multipleOption',
    questionDimension: 'multipleOption',
    isSelected: true,
    options: [
        {
            label: "Every day",
            value: "1",
            id: "remote days/week-1"
        },
        {
            label: "1-2 days",
            value: "2",
            id: "remote days/week-2"
        },
        {
            label: "3-4 days",
            value: "3",
            id: "remote days/week-3"
        },
        {
            label: "Never",
            value: "4",
            id: "remote days/week-4"
        }
    ],
    qid: uuidv4(),
    question: 'How often do you work remotely each week, if at all?',
    questionLabel: 'Remote Days/Week',
    isDemographic: true,
    staticQuestionId: generateStaticQuestionId('How often do you work remotely each week, if at all?'),
}]