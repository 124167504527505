import { deepCopy } from "../../../../utils/deepCopy";

export const fetchHeatmapTableStarted = (state, payload) => {
    return {
        ...state,
        heatmapTableApi: {
            isProcessing: true,
            error: false
        },
        selectedHeaders: payload.data || [],
        filters: payload.filters,
        byScore: payload.byScore,
        multipleSelect: payload.multipleSelect,
        selectedUserData: payload.selectedUserData
    }
}

export const fetchHeatmapTableSuccess = (state, payload) => {
    let heatmapColumns = [];
    let heatmapData = [];
    let heatmapColumnList = []
    let initialLoad = payload.request.initialLoad
    if (payload.data && payload.data.data) {
        heatmapColumns = payload.data.data.columnData;
        heatmapColumnList = payload.data.data.columnList
        heatmapData = payload.data.data.dataMap
        if (payload.request.projectType && payload.request.projectType === "Very_Simple_Survey") {
        } else {
            heatmapData.forEach((item) => {
                if (item.name !== "Responses") {
                    item.children = [];
                    item.key = item.name
                }
            })
        }

    }
    return {
        ...state,
        heatmapTableApi: {
            isProcessing: false,
            error: false
        },
        heatmapColumns: deepCopy(heatmapColumns),
        heatmapData: deepCopy(heatmapData),
        heatmapChildrenList: deepCopy(heatmapColumns),
        heatmapColumnList: deepCopy(heatmapColumnList),
        isDataFetched: initialLoad ? true : state.isDataFetched
    }

}

export const fetchHeatmapTableFailed = (state, payload) => {
    return {
        ...state,
        heatmapTableApi: {
            isProcessing: false,
            error: true
        },
        heatmapColumns: [],
        heatmapData: [],
        heatmapChildrenList: [],
        heatmapColumnList: [],
        isDataFetched: false
    }

}

export const updateScoreLevel = (state, payload) => {
    return { ...state, selectedScoreLevel: payload.data };
};

export const updateComparisonLevel = (state, payload) => {
    return { ...state, selectedComparisonLevel: payload.data };
};