
import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const improvementComments180 = {
    "blockId": uuidv4(),
    "goalId": "commentsOnImprovement",
    "isHiddenFromEditSurvey": true,
    "questions": [],
    "type": "priorityComments",
    "dimension": "commentsOnImprovement"
}