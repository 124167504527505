export function IniialState() {
    return {

        projectsPageTabConfigurationApi: {
            isProcessing: false,
            data: false,
            error: false,
            message: '',
            tabConfig: []
        },



        stopResponseCollectionApi:
        {
            isProcessing: false,
            data: null,
            message: null,
            error: false,
        },
        NewSurveyPageFetchOrganizationApi: {
            isProcessing: false,
            data: false,
            error: false,
            message: '',
        },
        projectDetailsApi: {
            isProcessing: false,
            data: false,
            error: false,
            reminderData: [],
            message: '',
            hrisData: {}
        },
        surveyListApi: {
            isProcessing: false,
            data: false,
            error: false,
            message: '',
            surveyList: []
        },
        createProjectApi: {
            isProcessing: false,
            data: null,
            error: null,
            message: null,
        },
        publishSurveyApi: {
            isProcessing: false,
            data: null,
            message: null,
            error: true,
        },
        searchText: "",
        pageNumber: 1,
        sortField: "projectCreationDate",
        sortOrder: "descend",
        feedbacksurveyListApi: {
            isProcessing: false,
            data: false,
            error: false,
            message: '',
            surveyList: []
        },

        searchTextFeedback: "",
        pageNumberFeedback: 1,
        sortFieldFeedback: "projectCreationDate",
        sortOrderFeedback: "descend",
    }
};
