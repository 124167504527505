import { URL_DATA } from "../../../../../config/urlDataConstants";
import { getOpenSurveyCondition } from "../../../../Routes/NewDashboard/subroutes/Explore/ExploreHelper";
import { checkAccessStatus, checkIfOrgAdmin, checkIfSuperAdmin, checkManager, getViewerCorrespondManagerId } from "../../../../utils/rolePermissionUtils";
import { getDataFromUrlToken } from "../../../../utils/urlDataStore";
import { getOrgIdFromUrl } from "../../../../utils/urlProcessor";
import { DASHBOARD_TABS } from "../constants";

export const setTabsInDashboard = (state, payload) => {
    const tabs = getTabs(payload)
    return {
        ...state,
        dashboardTabs: tabs
    };
};

const getTabs = (payload) => {
    let storyThresahold = payload.thresholdData && payload.thresholdData.response && payload.thresholdData.response.storyboardThreshold ? payload.thresholdData.response.storyboardThreshold : 1
    let urlData = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS)
    const surveyDetails =
        payload.surveyDetails && payload.surveyDetails.hits[0];
    let organizationId = getOrgIdFromUrl();
    let viewerData = getViewerCorrespondManagerId(organizationId, surveyDetails.projectId)
    let ifManager = viewerData.managerIdFound || checkManager(organizationId, surveyDetails.projectId)
    let tabs = DASHBOARD_TABS.filter((item) =>
        checkAccessStatus(organizationId, item.accessKey, urlData && urlData.projectId)
    );
    if (surveyDetails.projectOrigin) {
        if (surveyDetails.projectOrigin === 'Comment Analysis') {
            tabs = tabs.filter((item) => item.tab !== 'Heatmap');
        }
        // eslint-disable-next-line
        if (surveyDetails.projectType && surveyDetails.projectType === "Simple_Survey" || surveyDetails.projectType === "Very_Simple_Survey") {
            tabs = tabs.filter((item) => item.tab !== 'Storyboard');

        }
        if (ifManager && urlData && urlData.completedParticipantsCount !== undefined && urlData.completedParticipantsCount < storyThresahold) {
            tabs = tabs.filter((item) => item.tab !== 'Storyboard')
        }
        if (ifManager && urlData && urlData.completedParticipantsCount !== undefined && urlData.completedParticipantsCount < 20) {
            tabs = tabs.filter((item) => item.tab !== 'Storyboard')
        }
        if (surveyDetails.projectOrigin === 'Comment Analysis' || getOpenSurveyCondition(surveyDetails)) {
            tabs = tabs.filter((item) => item.tab !== 'Storyboard');
        }
        if (surveyDetails.demographics && surveyDetails.demographics.length) {
        } else {
            tabs = tabs.filter((item) => item.tab !== 'Heatmap');
        }
        if (checkIfSuperAdmin(organizationId) || checkIfOrgAdmin(organizationId)) {
            tabs = tabs.filter((item) => item.tab !== 'Manage');
        }

    }
    return tabs;
};