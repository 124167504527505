import {
    HANDLE_CHANGE_PAIRING_SURVEY_DROPDOWN
} from "../../../constants";


export const handleChangePairingSurveyDropDown = (event) => {
    return {
        type: HANDLE_CHANGE_PAIRING_SURVEY_DROPDOWN,
        payload: event,
    };
};