import {
  ADMIN_FORM_CHANGE,
  CLEAR_ADMIN_FORM,
  ADD_ADMIN_PAGINATION_CHANGE,
} from "../../constants";
export const adminFormChange = (event) => {
  return {
    type: ADMIN_FORM_CHANGE,
    payload: event,
  };
};

export const clearAdminForm = () => {
  return {
    type: CLEAR_ADMIN_FORM,
  };
};

export const addAdminPaginationChange = (page) => {
  return {
    type: ADD_ADMIN_PAGINATION_CHANGE,
    payload: page,
  };
};
