import { deepCopy } from "../../../../../utils/deepCopy"

export const addDemographicAnswerOption = (state, payload) => {
    let goals = addDemographicAnswerOptionToGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const addDemographicAnswerOptionToGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(goal => {
        if (goal.goalId === "demographics") {
            goal.questions.forEach(question=>{
                if(question.staticQuestionId===payload.staticQuestionId){
                    question.options.splice(question.options.length, 0, payload.data)
                }
            })
        }
    })
    return goalsCopy
}

