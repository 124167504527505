export function fetchTopResultsSuccess(state, action) {
  return {
      ...state,
      topResultsApi:{error:false,loading:false,data:action.data},
  };
}

export function fetchTopResultsStart(state, action) {

  return {
      ...state,
      topResultsApi:{loading:true,error:false}
  };
}

export function fetchTopResultsFail(state, action) {
  return {
      ...state,
      topResultsApi:{loading:false,error:true}
  };
};
