import { getApiStartState } from "../../../../utils/reducerOperations"

export const storyboardFetchDriversStarted = (state) => {
    return {
        ...state,
        storyboardFetchDriversDataApi: getApiStartState()
    }

}

export const storyboardFetchDriversSuccess = (state, payload) => {
    return {
        ...state,
        storyboardFetchDriversDataApi: {
            isProcessing: false,
            data: payload,
            }
    }

}

export const storyboardFetchDriversFailed = (state, payload) => {
    return {
        ...state,
        storyboardFetchDriversDataApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}


