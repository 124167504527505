import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const collaborationDirector = {
    "blockId": uuidv4(),
    "description": "<p>Grow Connectivity: Collaborate in a complex, matrixed, and dynamic environment within and across team boundaries with an enterprise-wide mindset to drive business value.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategoryId": generateStaticQuestionId("Adopts Enterprise-wide Mindset"),
                    "subCategory": "Adopts Enterprise-wide Mindset",
                    "staticQuestionId": generateStaticQuestionId("Prioritizes what's good for Applied (enterprise) rather than only focusing on own team's interests"),
                    "HTMLText": "<p>Prioritizes what's good for Applied (enterprise) rather than only focusing on own team's interests</p>",
                    "isSelected": true,
                    "text": "Prioritizes what's good for Applied (enterprise) rather than only focusing on own team's interests",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Adopts Enterprise-wide Mindset"),
                    "subCategory": "Adopts Enterprise-wide Mindset",
                    "staticQuestionId": generateStaticQuestionId("Clarifies key dependencies and expectations between own and other teams in the organization"),
                    "HTMLText": "<p>Clarifies key dependencies and expectations between own and other teams in the organization</p>",
                    "isSelected": true,
                    "text": "Clarifies key dependencies and expectations between own and other teams in the organization",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Adopts Enterprise-wide Mindset"),
                    "subCategory": "Adopts Enterprise-wide Mindset",
                    "staticQuestionId": generateStaticQuestionId("Demonstrates personal courage and convinction to advocate for trade-offs across teams/ organizations to deliver enterprise goals"),
                    "HTMLText": "<p>Demonstrates personal courage and convinction to advocate for trade-offs across teams/ organizations to deliver enterprise goals</p>",
                    "isSelected": true,
                    "text": "Demonstrates personal courage and convinction to advocate for trade-offs across teams/ organizations to deliver enterprise goals",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Enables Connectivity across Teams"),
                    "subCategory": "Enables Connectivity across Teams",
                    "staticQuestionId": generateStaticQuestionId("Encourages a partnership mindset and drives joint problem solving for challenges faced"),
                    "HTMLText": "<p>Encourages a partnership mindset and drives joint problem solving for challenges faced</p>",
                    "isSelected": true,
                    "text": "Encourages a partnership mindset and drives joint problem solving for challenges faced",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Enables Connectivity across Teams"),
                    "subCategory": "Enables Connectivity across Teams",
                    "staticQuestionId": generateStaticQuestionId("Intentionally builds relationships & partnerships (outside own team) to drive smooth deliveries for the team"),
                    "HTMLText": "<p>Intentionally builds relationships & partnerships (outside own team) to drive smooth deliveries for the team</p>",
                    "isSelected": true,
                    "text": "Intentionally builds relationships & partnerships (outside own team) to drive smooth deliveries for the team",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Enables Connectivity across Teams"),
                    "subCategory": "Enables Connectivity across Teams",
                    "staticQuestionId": generateStaticQuestionId("Maintains constructive relationships, even when tensions are high"),
                    "HTMLText": "<p>Maintains constructive relationships, even when tensions are high</p>",
                    "isSelected": true,
                    "text": "Maintains constructive relationships, even when tensions are high",
                    "value": uuidv4()
                },
             
                {
                    "subCategoryId": generateStaticQuestionId("Influences Impactful Outcomes"),
                    "subCategory": "Influences Impactful Outcomes",
                    "staticQuestionId": generateStaticQuestionId("Proactively demonstrates influence across organizations, functions and location"),
                    "HTMLText": "<p>Proactively demonstrates influence across organizations, functions and location</p>",
                    "isSelected": true,
                    "text": "Proactively demonstrates influence across organizations, functions and location",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Influences Impactful Outcomes"),
                    "subCategory": "Influences Impactful Outcomes",
                    "staticQuestionId": generateStaticQuestionId("Is comfortable influencing a wide-range of stakeholders without relying on formal authority"),
                    "HTMLText": "<p>Is comfortable influencing a wide-range of stakeholders without relying on formal authority</p>",
                    "isSelected": true,
                    "text": "Is comfortable influencing a wide-range of stakeholders without relying on formal authority",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Influences Impactful Outcomes"),
                    "subCategory": "Influences Impactful Outcomes",
                    "staticQuestionId": generateStaticQuestionId("Is a fair negotiator in high conflict situations"),
                    "HTMLText": "<p>Is a fair negotiator in high conflict situations</p>",
                    "isSelected": true,
                    "text": "Is a fair negotiator in high conflict situations",
                    "value": uuidv4()
                },
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Collaboration"
}