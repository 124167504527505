import React from "react";
import { EmptyState } from "../../../../../../Packages/EmptyState";
import {  getDataFromUrlToken } from "../../../../../../utils/urlDataStore";
import { URL_DATA } from "../../../../../../../config/urlDataConstants";
import { addTrendColomn, addTrendDataToTable, getMatchedSurveyDetails, getSelectedUserData } from "../../helper";
import { checkIfObjectsAreEqual } from "../../../../../../utils/customFunctions";
import {
  checkConnectedProjects,
  checkTrendProjects,
} from "../../../../../../utils/trendUtils";
import GraphLoader from "../../../../../../Components/ContentLoaders/GraphLoader";
import TableLoader from "../../../../../../Components/ContentLoaders/TableLoader";
import { getURLFilterAndByScore } from "../../../../../../utils/byScoreFilterUtils";
import { DASHBOARD_TABS } from '../../../../../../../config/tabConstants'
import InsightsTable from "../../../../../../Components/InsightsTable";
import { GAINS, GAIN_FETCH_KEY, LOSSESS, LOSS_FETCH_KEY, VALID_COUNT_KEY } from "./constants";

export class TrendInsights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const selectedUserData = getSelectedUserData();
    let filterData = getURLFilterAndByScore(urlFilter)
    if (this.checkIfApiCallRequiredOnMount(filterData.filters, selectedUserData)) {
      this.callApi(filterData.filters, selectedUserData);
    }
  }

  callApi = (filters, selectedUserData) => {
    const projectId =
      this.props.surveyDetailsApi &&
      this.props.surveyDetailsApi.surveyDetails &&
      this.props.surveyDetailsApi.surveyDetails.projectId;
    const hrisFileId =
      this.props.surveyDetailsApi &&
      this.props.surveyDetailsApi.surveyDetails &&
      this.props.surveyDetailsApi.surveyDetails.hrisFileId;
    let payload = {
      projectId: projectId,
      filters: filters,
      directReporteesOnly: selectedUserData.directReporteesOnly,
      selectedUser: selectedUserData.selectedUser,
      hrisFileId: hrisFileId,
      currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TREND_INSIGHTS,
      isGainLoss:true
    };
    if(!this.props.fetchExploreTrendInsightsDataApi.isProcessing){
      this.props.fetchNewDashboardTrendInsightsData(payload);
    }
    if(!this.props.isDataFetched || !this.props.isTrendComparisonRecallNeeded){
      let projectDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS);

      this.props.NewDashboardGetQuestionsTableTrend(
        {
          currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.QUESTIONS,
          projectId: projectDetails.projectId,
          organizationId: projectDetails.organizationId,
          projectOrigin: projectDetails.projectOrigin,
          projectType: projectDetails.projectType,
          isAnonymous: projectDetails.isAnonymous,
  
        }
      )
    }
  };

  checkIfApiCallRequiredOnMount = (urlFilter, selectedUserData) => {
    if (
      !this.props.isTrendInsightsAlreadyFetched ||
      !this.props.isTrendComparisonRecallNeeded ||
      !checkIfObjectsAreEqual(urlFilter, this.props.filters) ||
      !checkIfObjectsAreEqual(selectedUserData.directReporteesOnly, this.props.directReporteesOnly) ||
      !checkIfObjectsAreEqual(selectedUserData.selectedUser, this.props.selectedUser)
    ) {
      return true;
    }
    return false;
  };
  componentDidUpdate() {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const selectedUserData = getSelectedUserData();
    let filterData = getURLFilterAndByScore(urlFilter)
    if (
      checkIfObjectsAreEqual(filterData.filters, this.props.filters) &&
      checkIfObjectsAreEqual(selectedUserData.directReporteesOnly, this.props.directReporteesOnly) &&
      checkIfObjectsAreEqual(selectedUserData.selectedUser, this.props.selectedUser)
    ) {
    } else {
      if (!this.props.fetchExploreTrendInsightsDataApi.isProcessing) {
        this.callApi(filterData.filters, selectedUserData);
      }
    }
  }

  getTrendAddedTableData = () => {
    let gainList = []
    let lossList = []
    if (this.props.fetchExploreTrendInsightsDataApi.data &&
      this.props.fetchExploreTrendInsightsDataApi.data.gain) {
      gainList = addTrendDataToTable(this.props.fetchExploreTrendInsightsDataApi.data.gain, this.state.showActualScore)
    }
    if (this.props.fetchExploreTrendInsightsDataApi.data &&
      this.props.fetchExploreTrendInsightsDataApi.data.loss) {
      lossList = addTrendDataToTable(this.props.fetchExploreTrendInsightsDataApi.data.loss, this.state.showActualScore)
    }
    let combinedData = {...this.props.fetchExploreTrendInsightsDataApi, data:{gain: gainList, loss: lossList} }
    return combinedData
  }

  tableProps = () => {
    return {
      threshold: this.props.threshold.filterThreshold,
      showActualScore:false ,
    };
  };

  makeTrendColomnHeaders=()=>{
    let trendHeaders=getMatchedSurveyDetails(this.props.getTrendProjectsApi.data,true)
    let addedColomnForTrend =[]
    if(trendHeaders.length > 0){
      addedColomnForTrend = addTrendColomn(
        [trendHeaders[0]],
        this.tableProps(),
        VALID_COUNT_KEY
      );
    }
    return addedColomnForTrend
  }

  render() {
    return (
      <>
        {this.props.fetchExploreTrendInsightsDataApi.isProcessing ||  this.props.getTrendProjectsApi.isProcessing ?
          <>
            <GraphLoader height={300} width={600} />
            <TableLoader />
          </> :
          <>
            {this.props.fetchExploreTrendInsightsDataApi.error ? (
              <EmptyState
                type={"Network Error"}
                emptyStateMessage={this.props.fetchExploreTrendInsightsDataApi.message}
              />
            ) : (

                <InsightsTable
                negativeFetchKey={LOSS_FETCH_KEY}
                positiveFetchKey={GAIN_FETCH_KEY}
                insightsTrendColomns={this.makeTrendColomnHeaders()}
                trendData={checkTrendProjects(
                  this.props.surveyDetailsApi &&
                  this.props.surveyDetailsApi.surveyDetails
                )}
                threshold={this.props.threshold}
                apiData={this.getTrendAddedTableData()}
                positiveHeading={GAINS}
                negativeHeading={LOSSESS}
                />
            )}
          </>}
      </>
    );
  }
}
