export const fetchManagerHeatmapStatusStart = (state, payload) => {
    return {
        ...state,
        managerStatusApi: {
            ...state.managerStatusApi,
            isProcessing: true,
            error: false

        },
    }

}

export const fetchManagerHeatmapStatusSuccess = (state, payload) => {
    return {
        ...state,
        managerStatusApi: {
            isProcessing: false,
            error: false,
            data: []

        },
    }
}

export const fetchManagerHeatmapStatusFailed = (state, payload) => {
    return {
        ...state,
        managerStatusApi: {
            isProcessing: false,
            error: false,
            data: null

        },
    }
}