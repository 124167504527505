import { connect } from 'react-redux';
import Trend from './Trend';
import { withRouter } from 'react-router-dom';
import { getDemographicsQuestionsTrendData, getDemographicsTrendData, getTrendData, setTopoicsAndQuestions } from '../../../../../../redux/actions';

const mapStateToProps = (state) => {
  return {
    projectDetails: state.DashBoardReducer.surveyDetailsApi.surveyDetails,
    ...state.TrendVisualisationReducer,
    demographicsData: state.DashBoardReducer.duplicateFilterData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrendData: (params, cb) => dispatch(getTrendData(params, cb)),
    setTopoicsAndQuestions: (params, cb) => dispatch(setTopoicsAndQuestions(params, cb)),
    getDemographicsTrendData: (params, cb) => dispatch(getDemographicsTrendData(params, cb)),
    getDemographicsQuestionsTrendData: (params, cb) => dispatch(getDemographicsQuestionsTrendData(params, cb))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Trend));
