import * as actionTypes from '../../constants';
import Axios from 'axios';
import { GET_COLUMN_URL } from '../../../../config/constants';

export const getCsvColumns = (payload) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_CSV_COLUMN_START });
    Axios.post(GET_COLUMN_URL, payload)
      .then((res) => getCsvColumnsSuccess(dispatch, res))
      .catch((err) => getCsvColumnsFailed(dispatch, err));
  };
};

function getCsvColumnsSuccess(dispatch, res) {
  return dispatch({
    type: actionTypes.GET_CSV_COLUMN_SUCCESS,
    data: res.data.data,
  });
}

function getCsvColumnsFailed(dispatch, err) {
  return dispatch({
    type: actionTypes.GET_CSV_COLUMN_SUCCESS,
    error: err.message,
  });
}
