import {
    SAVE_HEATMAP_CONFIGURATION_SUCCESS,
    SAVE_HEATMAP_CONFIGURATION_FAIL,
    SAVE_HEATMAP_CONFIGURATION_START
  } from '../../constants';
  import { MODIFY_PROJECT_CONFIGURATIONS } from '../../../../config/constants';
  import { withType } from '../../../utils/actionOperations';
  import { ApiBase } from '../../../utils/apiBase';
  import { getProjectIdAndOrgId } from '../../../utils/apiUtils';
  
  export const saveHeatmapConfiguration = (configuration, cb, presetData) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
      dispatch(withType(SAVE_HEATMAP_CONFIGURATION_START, presetData));
      apiBaseInstance.instance
        .post(MODIFY_PROJECT_CONFIGURATIONS, { ...configuration, ...getProjectIdAndOrgId(configuration) })
        .then((response) => {
          dispatch(
            withType(SAVE_HEATMAP_CONFIGURATION_SUCCESS, response.data)
          );
          cb && cb(true);
        })
        .catch((err) => {
          cb && cb(false);
          dispatch(
            withType(SAVE_HEATMAP_CONFIGURATION_FAIL, {
              error: err,
            })
          );
        });
    };
  };
  