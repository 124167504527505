import { Default } from "./InitialState";
import { fetchPayload, update } from "../../../utils/reducerOperations";
import {
  FETCH_ADMIN_LIST_STARTED,
  FETCH_ADMIN_LIST_SUCCESS,
  FETCH_ADMIN_LIST_FAILED,
  ADMIN_FORM_CHANGE,
  ADD_ADMIN_STARTED,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAILED,
  FETCH_USERS_ADMIN_LIST_STARTED,
  FETCH_USERS_ADMIN_LIST_SUCCESS,
  FETCH_USERS_ADMIN_LIST_FAILED,
  FETCH_TEMPLATE_ADMIN_LIST_STARTED,
  FETCH_TEMPLATE_ADMIN_LIST_SUCCESS,
  FETCH_TEMPLATE_ADMIN_LIST_FAILED,
  CLEAR_ADMIN_FORM,
  CLEAR_INVITE_FORM,
  INVITE_FORM_CHANGE,
  USERS_HRIS_FILE_CHANGE,
  ADD_ADMIN_PAGINATION_CHANGE,
  MANAGERS_PAGINATION_CHANGE,
} from "../../constants";
import {
  fetchAdminsFail,
  fetchAdminsStarted,
  fetchAdminsSuccess,
  addAdminStarted,
  addAdminSuccess,
  addAdminFail,
  fetchUsersForAdminStarted,
  fetchUsersForAdminSuccess,
  fetchUsersForAdminFailure,
  fetchTemplatesForAdminStarted,
  fetchTemplatesForAdminSuccess,
  fetchTemplatesForAdminFailure,
  adminFormChange,
  clearAdminForm,
  clearInviteUserForm,
  inviteUserFormChange,
  hrisFileChange,
  addAdminPaginationChange,
  managersPaginationChange,
} from "./Helpers";



const addAdminsPageReducer = (state = Default(), action) => {
  let addAdminPayload = fetchPayload(action);
  switch (action.type) {
    case ADMIN_FORM_CHANGE:
      return update(state, adminFormChange(state, addAdminPayload));
    case CLEAR_ADMIN_FORM:
      return clearAdminForm(state, addAdminPayload);
    case FETCH_ADMIN_LIST_STARTED:
      return fetchAdminsStarted(state, action);
    case FETCH_ADMIN_LIST_SUCCESS:
      return fetchAdminsSuccess(state, action);
    case FETCH_ADMIN_LIST_FAILED:
      return fetchAdminsFail(state, action);
    case ADD_ADMIN_STARTED:
      return addAdminStarted(state, action);
    case ADD_ADMIN_SUCCESS:
      return addAdminSuccess(state, action);
    case ADD_ADMIN_FAILED:
      return addAdminFail(state, action);
    default:
      return fetchUsersForAdmin(state, action);
  }
};

const fetchUsersForAdmin = (state, action) => {
  let adminPagePayload = fetchPayload(action);
  switch (action.type) {
    case INVITE_FORM_CHANGE:
      return update(state, inviteUserFormChange(state, adminPagePayload));
    case CLEAR_INVITE_FORM:
      return clearInviteUserForm(state, adminPagePayload);
    case USERS_HRIS_FILE_CHANGE:
      return update(state, hrisFileChange(state, adminPagePayload));
    case FETCH_USERS_ADMIN_LIST_STARTED:
      return update(state, fetchUsersForAdminStarted(state, adminPagePayload));
    case FETCH_USERS_ADMIN_LIST_SUCCESS:
      return update(state, fetchUsersForAdminSuccess(state, adminPagePayload));
    case FETCH_USERS_ADMIN_LIST_FAILED:
      return update(state, fetchUsersForAdminFailure(state, adminPagePayload));
    case FETCH_TEMPLATE_ADMIN_LIST_STARTED:
      return fetchTemplatesForAdminStarted(state, action);
    case FETCH_TEMPLATE_ADMIN_LIST_SUCCESS:
      return fetchTemplatesForAdminSuccess(state, action);
    case FETCH_TEMPLATE_ADMIN_LIST_FAILED:
      return fetchTemplatesForAdminFailure(state, action);
    case ADD_ADMIN_PAGINATION_CHANGE:
      return addAdminPaginationChange(state, adminPagePayload);
    case MANAGERS_PAGINATION_CHANGE:
      return managersPaginationChange(state, adminPagePayload);
    default:
      return { ...state };
  }
};

export default addAdminsPageReducer;
