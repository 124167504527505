import {
    SURVEY_EDITOR_OPTIONS_DRAG_END,
    SURVEY_EDITOR_OPTIONS_DELETE,
    SURVEY_EDITOR_OPTIONS_ADD,
    SURVEY_EDITOR_HANDLE_CHANGE_OPTIONS,
    SURVEY_EDITOR_HANDLE_MULTIOPTION_TOGGLE,
    SURVEY_EDITOR_HANDLE_CHANGE_QUESTION,
    SURVEY_EDITOR_HANDLE_TOGGLE_WRITE_IN_OPTION,
    SURVEY_EDITOR_HANDLE_ADD_FOLLOWUP_QUESTION,
    SURVEY_EDITOR_HANDLE_ADD_CONDITIONAL_FOLLOWUP,
    SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP_LABEL,
    SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP,
    SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP_CHANGE,
    HANDLE_UPDATE_REDIRECT_VALUE_QUESTION
} from "../../../../../constants";


export const handleMultipleOptionsDragEnd = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_OPTIONS_DRAG_END, payload });
    }
};

export const handleDeleteMultipleOptions = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_OPTIONS_DELETE, payload });
    }

}

export const handleOptionsAdd = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_OPTIONS_ADD, payload });
    }

}

export const handleChangeOptionLabel = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_HANDLE_CHANGE_OPTIONS, payload });
    }

}

export const handleChangeTypeToggle = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_HANDLE_MULTIOPTION_TOGGLE, payload });
    }
}

export const handleChangeMultiChoiceQuestion = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_HANDLE_CHANGE_QUESTION, payload });
    }
};

export const handleChangeWriteInOption = (payload) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_HANDLE_TOGGLE_WRITE_IN_OPTION, payload });
    }
}

export const multipleChoiceQuestionAddFollowUp = (payload) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_HANDLE_ADD_FOLLOWUP_QUESTION, payload });
    }
}

export const multipleChoiceConditionalFollowup = (payload) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_HANDLE_ADD_CONDITIONAL_FOLLOWUP, payload });
    }
}

export const multipleChoiceQuestionChangeFollowUpLabel = (payload) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP_LABEL, payload });
    }
}

export const multipleChoiceQuestionDeleteFollowUp = (payload) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP, payload });
    }
}

export const multipleChoiceQuestionChangeFollowUpCondition = (payload) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP_CHANGE, payload });
    }
}
export const handleUpdateRedirectValue = (payload) => {
    return (dispatch) => {
        dispatch({ type: HANDLE_UPDATE_REDIRECT_VALUE_QUESTION, payload });
    }
}