import {
  makeKey,
  update,
  handleApiResponseError,
  handleNetworkError,
  getSuccessResponse,
} from "../../../../utils/reducerOperations";
import { toast } from "../../../../utils/messageUtils";

export function submitContactFormStarted(state, payload) {
  return makeKey(
    "contactProcessApi",
    update(state.contactProcessApi, { isProcessing: true })
  );
}

export function submitContactFormFailed(state, payload) {
  toast.error("Message not sent. Try again later");
  if (payload.response && payload.response.data) {
    return makeKey(
      "contactProcessApi",
      update(
        state.contactProcessApi,
        handleApiResponseError(payload.response.data)
      )
    );
  } else {
    return makeKey(
      "contactProcessApi",
      update(state.contactProcessApi, handleNetworkError(payload))
    );
  }
}

export function submitContactFormSuccess(state, payload) {
  toast.success("Your message has been successfully delivered");
  return makeKey(
    "contactProcessApi",
    update(state.contactProcessApi, getSuccessResponse(payload))
  );
}

export const updateContactFormCheckBox = (state, payload) => {
  let i = 0;
  const inputBoxConfiguration = state.inputBoxConfiguration;
  let options = inputBoxConfiguration.filter(
    (field) => field.name === payload.field
  )[0] || { name: payload.field, value: [] };
  options = pushOrPop(options, payload.event);

  inputBoxConfiguration.map((field) => {
    if (field.name === payload.field) {
      field = options;
      i = 1;
    }
    return 0;
  });
  if (options.value.length) options.isValid = true;
  else options.isValid = false;
  if (i === 0) inputBoxConfiguration.push(options);
  return {
    ...state,
    inputBoxConfiguration,
  };
};

const pushOrPop = (options, event) => {
  if (!event.target.checked) {
    options.value.splice(options.value.indexOf(event.target.value), 1);
  } else options.value.push(event.target.value);
  return options;
};
