import {
    getSuccessResponse
} from '../../../../utils/reducerOperations';
import {toast} from '../../../../utils/messageUtils';
import {
    fetchFormConfigurations
} from '../../../inputFormConfigurations';
export function fileUploadSuccess(state, payload) {
    toast.success(payload.message);
    return {
        uploadSurveyApi: getSuccessResponse(payload),
        inputBoxConfiguration: fetchFormConfigurations(['surveyFile'])
    };
}
