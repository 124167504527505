
export const fetchFeedbackProjectDetailsStart = (state, payload) => {
    return {
        ...state,
        feedbacksurveyListApi: {
            isProcessing: true,
            error: false,
            data: false
        }
    };
}

export const fetchFeedbackProjectDetailsSuccess = (state, payload, searchText) => {
    let projectCount = payload.total
    if (searchText !== '' && payload.total === 0) {
        projectCount = 1
    }
    return {
        ...state,
        projectCount: projectCount,
        feedbacksurveyListApi: {
            lastsearchText: searchText,
            isProcessing: false,
            data: true,
            surveyList: payload.hits,
            count: payload.total
        },
    };
}

export const fetchFeedbackProjectDetailsFail = (state, payload) => {
    return {
        ...state,
        feedbacksurveyListApi: {
            isProcessing: false,
            error: true,
            data: false
        },
    };
};