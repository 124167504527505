import * as getProjectHelper from "./getProject";
import * as saveProjectHelper from "./saveProject";
import * as publishProjectHelper from "./publishProject";
import * as uploadImageHelper from "./uploadImage";
import * as renameProject from "./renameProject";

export default {
  ...getProjectHelper,
  ...saveProjectHelper,
  ...publishProjectHelper,
  ...uploadImageHelper,
  ...renameProject,
};
