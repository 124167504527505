import {
  RESET_FETCH_DATA_KEY_HEATMAP
} from '../../../constants';
import { withType } from '../../../../utils/actionOperations';

export const heatmapResetFetchDataKey = () => {
  return dispatch => {
    dispatch(withType(RESET_FETCH_DATA_KEY_HEATMAP));
  };
};
