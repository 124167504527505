export const raceEthnicityDemographicTemplate = [
    {
        type: 'Multiple Choice',
        questionDimension: 'Demographics',
        isSelected: false,
        options: [
            {
                label: "Asian",
                value: "1"
            },
            {
                label: "Black",
                value: "2"
            },
            {
                label: "Hispanic/Latino",
                value: "3"
            },
            {
                label: "White",
                value: "4"
            },
            {
                label: "Other",
                value: "5"
            }
        ],
        question: 'Race/Ethnicity.',
        questionLabel: 'Race/Ethnicity',
        isDemographic: true
    }
]