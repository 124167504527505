export const fetchTrendVisualisationStart = (state, payload) => {
  return {
    ...state,
    trendVisualisationData: {
      ...state.trendVisualisationData,
      isProcessing: true,
      error: false, reason: '',
    },
  };
};

export const fetchTrendVisualisationSuccess = (state, payload) => {
  return {
    ...state,
    trendVisualisationData: {
      isProcessing: false,
      error: false,
      reason: '',
      data: payload.data.data.response,
    },
    isTrendVisualisationDataFetched: true,
    byScore: payload.payload.byScore,
    multipleSelect: payload.payload.multipleSelect,
    filters: payload.payload.filters,
    selectedUser: payload.payload.selectedUser,
    directReporteesOnly: payload.payload.directReporteesOnly
  };
};

export const fetchTrendVisualisationFail = (state, payload) => {
  return {
    ...state,
    trendVisualisationData: {
      ...state.trendVisualisationData,
      isProcessing: false,
      error: true,
      reason: payload.error,
    },
    isTrendVisualisationDataFetched: true,
    byScore: payload.payload.byScore,
    multipleSelect: payload.payload.multipleSelect,
    filters: payload.payload.filters,
    selectedUser: payload.payload.selectedUser,
    directReporteesOnly: payload.payload.directReporteesOnly
  };
};
