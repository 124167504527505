import {
  ADD_ADMIN_STARTED,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAILED,
} from "../../constants";
import { ApiBase } from "../../../utils/apiBase";
import { ADD_ADMIN_URL } from "../../../../config/constants";
import { getDataFromUrlToken } from "../../../utils/urlDataStore";
import { URL_DATA } from "../../../../config/urlDataConstants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const addAdmin = (params, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch({ type: ADD_ADMIN_STARTED });
    apiBaseInstance.instance
      .post(ADD_ADMIN_URL, { ...getParams(params), ...getProjectIdAndOrgId(getParams(params)) })
      .then((res) => addAdminSuccess(dispatch, { res, cb }))
      .catch((err) => addAdminFailure(dispatch, err));
  };
};

function addAdminSuccess(dispatch, { res, cb }) {
  cb();
  return dispatch({
    type: ADD_ADMIN_SUCCESS,
    payload: res.data,
  });
}

function addAdminFailure(dispatch, err) {
  return dispatch({
    type: ADD_ADMIN_FAILED,
    payload: err,
  });
}

function getParams(params) {
  const orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
  return {
    ...params,
    email: params.email.toLowerCase(),
    organizationId: orgDetails && orgDetails.organizationId,
    organizationName: orgDetails && orgDetails.name,
    isOrgAdmin: true,
  };
}
