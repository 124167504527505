import { deepCopy } from "../../../../utils/deepCopy";
import { toast } from "../../../../utils/messageUtils";

export const fetchHeatmapQuestionsStarted = (state) => {
    return {
        ...state,
        heatmapQuestionsApi: {
            isProcessing: true,
            error: false
        }
    }

}

export const fetchHeatmapQuestionsSuccess = (state, payload) => {
    let topicQuestions = [];
    let heatmapData = deepCopy(state.heatmapData);
    if (payload.data && payload.data.data) {
        topicQuestions = payload.data.data.dataMap
    }
    if (topicQuestions.length) {
        heatmapData = addTopicQuestionsToTopic({ heatmapData, topicQuestions }, payload.dimension)
    }
    return {
        ...state,
        heatmapQuestionsApi: {
            isProcessing: false,
            error: false
        },
        heatmapData: heatmapData
    }

}

export const fetchHeatmapQuestionsFailed = (state, payload) => {
    toast.error("Failed to fetch questions data.")
    return {
        ...state,
        heatmapQuestionsApi: {
            isProcessing: false,
            error: true
        },
        isDataFetched: false
    }

}

const addTopicQuestionsToTopic = ({ heatmapData, topicQuestions }, dimension) => {
    const data = heatmapData;
    data.forEach(item => {
        if (item.name === dimension) {
            item.children = topicQuestions
        }
    })
    return data
}