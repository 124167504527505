import { STORYBOARD_FETCH_DRIVERS_DATA_SUCCESS } from "../../../constants";
import { withType } from "../../../../utils/actionOperations";

export const storyFetchDriversData = (payload, outcome) => {
  return (dispatch) => {
    fetchDriversDataSuccess(dispatch, payload, outcome);
  };
};
const fetchDriversDataSuccess = (dispatch, res, outcome) => {
  let result = {};
  let topicScoreQuestion, agreeCount, disAgreeCount, text, status;
  if (res.surveyReport && res.surveyReport.dimensionTable) {
    const dimensionTable = res.surveyReport.dimensionTable;
    const itemTable = res.surveyReport.itemTable;
    let sortedTopicQuestion = sortTopicScoreQuestions(itemTable, outcome);
    let totalLength = sortedTopicQuestion.length;
    let checkOutComeVariableData = checkOutComeTopicScore(
      dimensionTable,
      outcome
    );
    let outcomeTopicScore = 0;

    if(checkOutComeVariableData && checkOutComeVariableData.length>0)
    {
    outcomeTopicScore = Math.round(checkOutComeVariableData[0].favScore);
    }

    if (outcomeTopicScore > 85) {
      topicScoreQuestion = sortedTopicQuestion[0].item;
      agreeCount =
        sortedTopicQuestion[0].favScore * sortedTopicQuestion[0].validCount;
      disAgreeCount =
        sortedTopicQuestion[0].unFavScore * sortedTopicQuestion[0].validCount;
      if (outcome === "Engagement") {
        text = `People are highly engaged at your organization, and the ${outcome} score was ${outcomeTopicScore}. That’s a great score—keep up the good work!`;
      } else {
        text = `${outcome} was the focus for this survey, and employees rated it at ${outcomeTopicScore}! That’s a great score—keep up the good work!`;
      }
      status = "positive";
    }
    else if (outcomeTopicScore >= 75 && outcomeTopicScore <= 85) {
      topicScoreQuestion = sortedTopicQuestion[0].item;
      agreeCount =
        sortedTopicQuestion[0].favScore * sortedTopicQuestion[0].validCount;
      disAgreeCount =
        sortedTopicQuestion[0].unFavScore * sortedTopicQuestion[0].validCount;
      if (outcome === "Engagement") {
        text = `People are very engaged at your organization, and the ${outcome} score was ${outcomeTopicScore}.`;
      } else {
        text = `${outcome} was the focus for this survey, and employees rated it at ${outcomeTopicScore}!`;
      }
      status = "positive";
    }
    else if (outcomeTopicScore >= 65 && outcomeTopicScore < 75) {
      topicScoreQuestion = sortedTopicQuestion[0].item;
      agreeCount =
        sortedTopicQuestion[0].favScore * sortedTopicQuestion[0].validCount;
      disAgreeCount =
        sortedTopicQuestion[0].unFavScore * sortedTopicQuestion[0].validCount;
      if (outcome === "Engagement") {
        text = `People are moderately engaged at your organization, and the ${outcome} score was just ${outcomeTopicScore}.`;
      } else {
        text = `${outcome} was the focus for this survey, and employees rated it at ${outcomeTopicScore}!`;
      }
      status = "positive";
    }
    else if (outcomeTopicScore < 65) {
      topicScoreQuestion = sortedTopicQuestion[totalLength - 1].item;
      agreeCount =
        sortedTopicQuestion[totalLength - 1].favScore *
        sortedTopicQuestion[totalLength - 1].validCount;
      disAgreeCount =
        sortedTopicQuestion[totalLength - 1].unFavScore *
        sortedTopicQuestion[totalLength - 1].validCount;
      if (outcome === "Engagement") {
        text = `People are not highly engaged at your organization, and the ${outcome} score was just ${outcomeTopicScore}.`;
      } else {
        text = `${outcome} was the focus for this survey but employees rated it at ${outcomeTopicScore}. That score is a little low.`;
      }
      status = "Negative";
    }
    else {
      if(sortedTopicQuestion && sortedTopicQuestion.length>0)
      {
      topicScoreQuestion = sortedTopicQuestion[totalLength - 1].item;
      agreeCount =
        sortedTopicQuestion[totalLength - 1].favScore *
        sortedTopicQuestion[totalLength - 1].validCount;
      disAgreeCount =
        sortedTopicQuestion[totalLength - 1].unFavScore *
        sortedTopicQuestion[totalLength - 1].validCount;
      if (outcome === "Engagement") {
        text = `People are not highly engaged at your organization, and the ${outcome} score was just ${outcomeTopicScore}.`;
      } else {
        text = `${outcome} was the focus for this survey but employees rated it at ${outcomeTopicScore}. That score is a little low.`;
      }
      status = "Negative";
    }
    }
    result.topicScore = outcomeTopicScore;
    result.topicQuestionDetails = sortedTopicQuestion;
    result.status = status;
    result.text = text;
    result.topicScoreQuestion = topicScoreQuestion;
    result.agreeCount = Math.round(agreeCount / 100);
    result.disAgreeCount = Math.round(disAgreeCount / 100);
    return dispatch(withType(STORYBOARD_FETCH_DRIVERS_DATA_SUCCESS, result));
  }
};
const checkOutComeTopicScore = (data, outcome) => {
  let result = data
    .filter((item) => item.item === outcome)
    .sort((a, b) => b.favScore - a.favScore);
  return result;
};

const sortTopicScoreQuestions = (data, outcome) => {
  let sortQuestions = data
    .filter((item) => item.dimension === outcome)
    .sort((a, b) => b.favScore - a.favScore);
  return sortQuestions;
};
