import { apiInitialState, apiInitialStateAddFn } from "../../../utils/apiStateUtils";
import { fetchFormConfigurations } from "../../inputFormConfigurations";

export function Default() {
  return {
    fetchAdminsApi: apiInitialState(),
    fetchTemplatesForAdminApi: apiInitialState(),
    fetchUsersForAdminApi: apiInitialState(),
    addAdminApi: apiInitialStateAddFn(),
    hrisFileValue: "",
    hrisFileName: "",
    isFormValid: false,
    addAdminPagination: 1,
    managersPagination: 1,
    addAdminBoxConfiguration: (fetchFormConfigurations([
      "adminFirstName",
      "adminLastName",
      "adminEmail",
      "role",
    ])),
    isInviteFormValid: false,
    selectBoxConfiguration: fetchFormConfigurations([
      "existingUserTemplate",
      "newUserTemplate",
    ]),
  };
}