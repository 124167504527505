export function getProjectDetailsSuccess(state, action) {
  return {
    ...state,
    projectDetailsApi: {
      loading: false,
      data: true,
      list: action.data.hits,
      count: action.data.total,
    },
  };
}

export function getProjectDetailsStart(state, action) {
  return {
    ...state,
    projectDetailsApi: { loading: true, error: false, data: false },
  };
}

export function getProjectDetailsFail(state, action) {
  return {
    ...state,
    projectDetailsApi: { loading: false, error: true },
  };
}
