export const initialState = () => {
    return {
        newResetPasswordApi: {
            isProcessing: false,
            data: null,
            error: false,
            message: '',
            code: '',
            reason: ''
        },
    }
};
