import {
    handleNetworkError,
    handleApiResponseError,
    getSuccessResponse
} from '../../../../utils/reducerOperations';
import { newJson } from '../../../../utils/newJson';
import { addAdditionalElementsByCondition, changeSurveyLanguage } from '../../../../utils/attendSurveyUtils';
import { deepCopy } from '../../../../utils/deepCopy';

export function fetchProjectStart(state) {
    return {
        ...state,
        fetchProjectApi: {
            data: null,
            isProcessing: true,
            message: null,
            error: false
        }

    };
};

export function fetchProjectSuccess(state, action) {
    const { data } = action.response;
    data.data = newJson(data.data.response);
    let isAnswerSet = false;
    if (!data.data.questionSet) {
        data.data.questionSet = data.data.answerSet;
        isAnswerSet = true
    }
    data.data.englishQuestionSet = deepCopy(data.data.questionSet);
    return {
        ...state,
        fetchProjectApi: getSuccessResponse(addAdditionalElementsByCondition(data, false, isAnswerSet, action.callbackData)),
        selectedLanguage: data.data.selectedLanguage || 'English',
        translatedTexts: data.data.translatedTexts || {},
    };
};

export function changeLanguage(state, action) {
    return {
        ...state,
        selectedLanguage: action.payload.language,
        fetchProjectApi: changeSurveyLanguage(
            deepCopy(state.fetchProjectApi),
            action.payload.language, false,
            action.payload.translatedDescription,
            action.payload.descriptiveTexts)
    }
}


export function fetchProjectFail(state, action) {
    return {
        ...state,
        fetchProjectApi:
            (action.response
                && action.response.response
                && action.response.response.data
                ? handleApiResponseError(action.response.response.data)
                : handleNetworkError(action.response))
    };
};

export function translateStart(state, action) {
    return {
        ...state,
        translateApi: {
            isProcessing: true,
            error: false
        }
    };

}

export function translateSuccess(state, action) {
    return {
        ...state,
        translateApi: {
            isProcessing: false,
            error: false
        },
        translatedTexts: action.payload.data && action.payload.data.translatedData
    };

}

export function translateFailed(state, action) {
    return {
        ...state,
        translateApi: {
            isProcessing: false,
            error: true
        },
        translatedTexts: state.defaultTexts
    };

}