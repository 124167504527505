import PeopleList from "../People";
import SettingsPage from "../Settings";
import People from "../People/Users";
import HomePage from "../HomePage";
import AddOrganization from "../AddOrganization";
import Admin from "../NLP/subRoutes/Admin";
import AdminDataCollection from "../NLP/subRoutes/AdminDataCollection";
import { ROLES } from "../../../config/constants";
import CohortSuvey from "../CohortSuvey";
const organizationRoute = "/admin/home/organizations";

export default {
  HOME_ROUTER_ROUTES: [
    {
      path: organizationRoute + "/addorganization",
      name: "Add Organization",
      component: AddOrganization,
      roles: [ROLES.ADMIN],
    },
    {
      name: "Add Reports",
      path: organizationRoute + "/:org_id/addreports",
      component: Admin,
      roles: [ROLES.ADMIN, ROLES.ORG_ADMIN, ROLES.SUPER_ADMIN],
    },
    {
      name: "Add Data Collection Reports",
      path: organizationRoute + "/:org_id/:projectType/add-data-collection-reports",
      component: AdminDataCollection,
      roles: [ROLES.ADMIN, ROLES.ORG_ADMIN, ROLES.SUPER_ADMIN],
    },
    {
      name: "Cohort Survey Launch",
      path: organizationRoute + "/:org_id/:projectType/create-cohort-survey",
      component: CohortSuvey,
      roles: [ROLES.ADMIN, ROLES.ORG_ADMIN, ROLES.SUPER_ADMIN],
    },
    {
      path: organizationRoute + "/:org_id/manageusers",
      name: "Manage Users",
      component: PeopleList,
      roles: [ROLES.ADMIN, ROLES.ORG_ADMIN, ROLES.SUPER_ADMIN],
    },
    {
      path: organizationRoute + "/:org_id/people",
      name: "People",
      component: People,
      roles: [ROLES.ADMIN, ROLES.ORG_ADMIN, ROLES.SUPER_ADMIN],
    },
    {
      path: organizationRoute,
      name: "Home",
      component: HomePage,
      roles: [ROLES.ADMIN, ROLES.ORG_ADMIN, ROLES.ORG_MANAGER, ROLES.VIEWER, ROLES.SUPER_ADMIN],
    },
    {
      path: "/admin/home/settings",
      name: "Settings",
      component: SettingsPage,
      roles: [ROLES.ADMIN, ROLES.ORG_ADMIN, ROLES.SUPER_ADMIN],
    },
  ],
};
