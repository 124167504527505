import moment from 'moment';

export const getShortDate = (date) => {
  return moment(date).format('yyyy-MM-DD');
};

export const getDisplayDate = (date) => {
  return moment(date).format('DD MMM yyyy');
};

export const getDisplayDateFormat2 = (date) => {
  if (date) {
    return moment(date).format('MMMM DD, yyyy');
  }
  else {
    return undefined
  }
};

export const getDisplayTime = (date) => {
  return moment(date).format('h:mm a');
}

export const getDisplayTimeCaps = (date) => {
  return moment(date).format('h:mm A');
}


export const getDateFromDateString = (dateString) => {
  return dateString ? moment(dateString) : undefined

}

export const getTimeFromTimeString = (timeString) => {
  return timeString ? moment(timeString, 'hh:mm:ss a') : undefined
}

export const getShortTimeZoneFromDateString = (dateString) => {
  // eslint-disable-next-line
  const timezone = dateString.match(/\(([^\)]+)\)$/)[1];
  return timezone.split('').filter(item =>
    item && item.trim() && (item === item.toUpperCase())).join('')
}

export const combineDateAndTimeToISO = (dateString, timeString) => {
  let time = timeString || '00:00:00';
  let combineDateTimeString = null;
  if (dateString) {
    let formattedDateString = getDisplayDateFormat2(new Date(dateString))
    combineDateTimeString = dateString ? new Date(
      formattedDateString + " " + time
    ).toISOString() : null
  }

  return combineDateTimeString;
}

const pad = (value) => {
  return value < 10 ? '0' + value : value;
}
export const createOffset = (customDate) => {
  var date = customDate || new Date();
  var sign = (date.getTimezoneOffset() > 0) ? "-" : "+";
  var offset = Math.abs(date.getTimezoneOffset());
  var hours = pad(Math.floor(offset / 60));
  var minutes = pad(offset % 60);
  return sign + hours + ":" + minutes;
}


export const disabledDate = (startdate, enddate, current) => {
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 1)
  defaultStartDate.setHours(23);
  defaultStartDate.setMinutes(59);
  defaultStartDate.setSeconds(59);
  if (startdate) {
    startdate.setDate(startdate.getDate() - 1)
    startdate.setHours(23);
    startdate.setMinutes(59);
    startdate.setSeconds(59);
  }
  let startDate = startdate ?startdate: defaultStartDate
  let endDate = new Date(enddate)
  return !current || current.isBefore(startDate) || (endDate && current.isAfter(endDate))
};

export const disabledHours = (data) => {
  let { startTime, endTime, selectedTime } = data
  let { startDate, endDate, selectedDate, startDateHour, endHour } = dateInSimpleFormat(startTime, endTime, selectedTime)
  if (startDate === endDate === selectedDate) {
    return getNumbersBetweenTwoNumbers(parseInt(startDateHour), parseInt(endHour))
  } else if (startDate === selectedDate) {
    return getNumbersBetweenTwoNumbers(parseInt(startDateHour), 23)
  } else if (endDate === selectedDate) {
    return getNumbersBetweenTwoNumbers(0, parseInt(endHour))
  } else {
    return getNumbersBetweenTwoNumbers(0, 23)
  }
}

const dateInSimpleFormat = (startDate, endDate, selectedDate) => {
  let EndDateIsZeroMinute = parseInt(moment(endDate).format("mm")) < 30
  let Endhour = moment(endDate).format("H")
  let StartDateIs30Minute = parseInt(moment(startDate).format("mm")) >= 30
  let Starthour = moment(startDate).format("H")
  return {
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
    selectedDate: moment(selectedDate).format("YYYY-MM-DD"),
    startDateHour: StartDateIs30Minute ? parseInt(Starthour) + 1 : Starthour,
    endHour: EndDateIsZeroMinute ? parseInt(Endhour) - 1 : Endhour
  }
}

const getNumbersBetweenTwoNumbers = (startNumber, endNumber) => {
  let numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  numbers = numbers.filter(item => item < startNumber || item > endNumber)
  return numbers
}

export const UpdateMonth = (month)=>{
  if(month&&month.length<=1){
    return `0${month}`
  }else{
    return month
  }
}