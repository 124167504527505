import React from "react";
import { ListData } from "../../Components/ListRoleandData";
import constants from "./constants";
import helpers from "./helpers";
import { DeleTeUserModal } from './children/DeleteUserModal';
import { getDataFromUrlToken } from '../../utils/urlDataStore';
import { URL_DATA } from '../../../config/urlDataConstants';
import { checkAccessStatus } from '../../utils/rolePermissionUtils';
import { SETTINGS_PERMISSIONS } from '../../../config/userPermissionConstants'
import { SETTINGS_PAGE_SECTION } from '../../../config/tabConstants'

export class AddAdmins extends React.Component {
  state = {
    modalVisibility: false,
    pagination: 1,
    deleting: false,
  };
  componentDidMount = () => {
    this.props.fetchAdmins(this.makeParamsForFetchAdmin());
    this.props.addAdminPaginationChange(this.state.pagination);
  };
  toggleVisibility = () => {
    this.props.clearAdminForm();
    this.setState({
      modalVisibility: !this.state.modalVisibility,
    });
  };
  onClickAddAdmin = () => {
    this.toggleVisibility();
  };
  makeParamsForFetchAdmin = (fromValue = 0) => {
    return {
      currentTab: SETTINGS_PAGE_SECTION.SETTINGS_SUB_TABS.ADMINISTRATOR,
      pagination: { from: fromValue, size: 12 },
    };
  };
  createPropsForAddAdminButton = () => {
    return {
      onClick: this.onClickAddAdmin,
    };
  };
  createPropsForAdminPagination = () => {
    return {
      fetchApi: this.props.fetchAdminsApi,
      onChangePagination: this.onChangePagination,
      pagination: this.props.addAdminPagination,
    };
  };
  onChangePagination = (value) => {
    this.setState({ pagination: value });
    this.props.addAdminPaginationChange(value);
    const fromValue = (value - 1) * 12;
    this.props.fetchAdmins(this.makeParamsForFetchAdmin(fromValue));
  };

  createPropsForAddUserModal = () => {
    return {
      onSubmitModal: this.onSubmitModal,
      addAdminApi: this.props.addAdminApi,
      toggleVisibility: this.toggleVisibility,
      stateValue: this.state,
      addAdminBoxConfiguration: this.props.addAdminBoxConfiguration,
      isFormValid: this.props.isFormValid,
      adminFormChange: this.props.adminFormChange,
    };
  };
  onSubmitModal = () => {
    if (this.props.isFormValid) {
      const requestBody = helpers.generateRequestBody(
        this.props.addAdminBoxConfiguration
      );
      this.props.addAdmin(requestBody, () => {
        this.props.fetchAdmins(this.makeParamsForFetchAdmin());
        this.toggleVisibility();
      });
    }
  };

  deleteIconClick = (data) => {
    this.setState({ userData: data, showDeleteModal: true })
  }

  deleteUser = () => {
    this.setState({ deleting: true })
    const { userId } = this.state.userData
    this.props.deleteAdmin({
      email:userId,
      currentTab: SETTINGS_PAGE_SECTION.SETTINGS_SUB_TABS.ADMINISTRATOR,
    }, (success) => {
      this.setState({ deleting: false })
      if (success) {
        this.onModalClose()
        let value = this.props.addAdminPagination
        const { count } = this.props.fetchAdminsApi.data;
        if (((count % 12) === 1) && (value !== 1)) value = this.props.addAdminPagination - 1
        this.onChangePagination(value)
      }
    })
  }

  renderAddAdminTableContent = () => {
    return (
      <>
        <ListData
          listType={constants.ADD_ADMIN}
          fetchApi={this.props.fetchAdminsApi}
          deleteAdmin={this.deleteIconClick}
        />
        {helpers.addAdminModal(this.createPropsForAddUserModal())}

        {this.props.fetchAdminsApi.data && this.props.fetchAdminsApi.data.count > 12 &&
          helpers.getPaginationForAdmin(this.createPropsForAdminPagination())}
      </>
    );
  };

  renderDeleteModalProps = () => {
    return ({
      visible: this.state.showDeleteModal,
      data: this.state.userData,
      deleteAdmin: this.props.deleteAdmin,
      onCancel: this.onModalClose,
      deleteUser: this.deleteUser,
      loading: this.state.deleting,
    })
  }

  onModalClose = () => {
    this.setState({ userData: {}, showDeleteModal: false })
  }

  render() {
    const org_Id = getDataFromUrlToken(URL_DATA.ORGANIZATION) && getDataFromUrlToken(URL_DATA.ORGANIZATION).organizationId
    return (
      <>
        {org_Id && checkAccessStatus(org_Id, SETTINGS_PERMISSIONS.ADD_ADMIN) ?
          helpers.cardHeaderRight(this.createPropsForAddAdminButton()) : null}
        {this.renderAddAdminTableContent()}
        <DeleTeUserModal {...this.renderDeleteModalProps()} />
      </>
    );
  }
}
