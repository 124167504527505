export function getUserProjectsSuccess(state, action) {
    return {
        ...state,
        userProjectsListApi:{loading:false,data:true},
        userProjectsList:action.data
    };
}

export function getUserProjectsStart(state, action) {

    return {
        ...state,
        userProjectsListApi:{loading:true,error:false,data:false}
    };
}

export function getUserProjectsFail(state, action) {
    return {
        ...state,
        userProjectsListApi:{loading:false,error:true},
        userProjectsList:null
    };
};
