import { CrownFilled, CrownOutlined, StarFilled, StarOutlined } from "@ant-design/icons"
import React from "react"
import SVGIcons from "../../../../../../Components/SVGIcons"

export const getRankingShape = (shape) => {
    return shapes[shape]
}

const shapes = {
    "stars": { notFilled: <span> <SVGIcons type={'SVG-star'} /></span>, filled: <span> <SVGIcons type={'SVG-starfill'} /></span> },
    "heart": { notFilled: <span> <SVGIcons type={'SVG-heart'} /></span>, filled: <span> <SVGIcons type={'SVG-heartfill'} /></span> },
    "thumb": { notFilled: <span> <SVGIcons type={'SVG-tumb'} /></span>, filled: <span> <SVGIcons type={'SVG-thumbsfill'} /></span> },
    "square": { notFilled: <span> <SVGIcons type={'SVG-square'} /></span>, filled: < span > <SVGIcons type={'SVG-squarefill'} /></span> },
    "circle": { notFilled: <span> <SVGIcons type={'SVG-clear'} /></span>, filled: <span> <SVGIcons type={'SVG-circlefill'} /></span> },
}
