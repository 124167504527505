import React, { useState, useEffect } from 'react';
import AddDemographics from '../../../../../../../../Components/AddDemographicsModal';

export const SelectDemographics = (props) => {
  let [selectedColumns, setSelectedColumns] = useState([]);
  useEffect(() => {
    setSelectedColumns(props.selectedColumns);
  }, []);

  useEffect(() => {
    setSelectedColumns(props.selectedColumns);
  }, [props.selectedColumns]);

  const handleSelect = ({ selected }) => {
    let columns = [...selectedColumns];
    if (columns.includes(selected)) {
      columns = columns.filter((item) => item !== selected);
    } else columns.push(selected);
    setSelectedColumns(columns);
  };

  const onCancel = () => {
    setSelectedColumns(props.selectedColumns);
  };

  return (
    <AddDemographics
      columns={props.columns}
      selectedColumns={selectedColumns}
      onSelect={handleSelect}
      onCancel={onCancel}
      onOk={props.onAdd}
    />
  );
};
