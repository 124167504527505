import { SUBMIT_ANSWERSET_URL } from "../../../../../config/constants";
import * as actionTypes from '../../../constants';
import { ApiBase } from '../../../../utils/apiBase';
import { notification } from 'antd';
import constants from '../constants';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';


export function submitSurveyResponse(payload) {

    return (dispatch) => {
        dispatch({ type: actionTypes.SUBMIT_SURVEY_RESPONSE_START });
        const axios = new ApiBase().instance;
        axios.post(SUBMIT_ANSWERSET_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(updateSurveyBlockSuccess
                .bind(null, { dispatch, payload }))
            .catch(updateSurveyBlockFail
                .bind(null, { dispatch, payload }));
    };
};

function updateSurveyBlockSuccess({ dispatch, payload }, response) {
    const {  completed } = payload;
    dispatch({
        type: actionTypes.SUBMIT_SURVEY_RESPONSE_SUCCESS,
        response,
        completed
    });
};

function updateSurveyBlockFail({ dispatch, payload }, error) {
    const { completed } = payload;
    notification.error({
        message: constants.submitAnswerFailureTitle,
        description: constants.submitAnswerFailureMessage
    });

    dispatch({
        type: actionTypes.SUBMIT_SURVEY_RESPONSE_FAIL,
        response: error,
        completed
    });
};
