import { getCustomThreshold } from "../../../../utils/customFunctions";

export const fetchExploreWordcloudDataStart = (state, payload) => {
  return {
    ...state,
    [payload.questionId]: {
      ...state[payload.questionId],
      exploreWordCloudDataApi: {
        data: [],
        isProcessing: true,
        error: null,
        message: null,
      },
    }
  };
};

export const fetchExploreWordcloudDataSuccess = (state, payload) => {
  return {
    ...state,
    [payload.questionId]: {
      ...state[payload.questionId],
      exploreWordCloudDataApi: {
        isProcessing: false,
        data: getWordCloudDataFromPayload(payload),
        error: false,
        message: payload.message,
      },
      isDataAlreadyFetched: true,
      filters: payload.filters,
      byScore: payload.byScore,
      multipleSelect: payload.multipleSelect,
      selectedUser: payload.selectedUser,
      directReporteesOnly: payload.directReporteesOnly,
    },
  };
};

const getWordCloudDataFromPayload = (payload) => {
  let wordCloudData =
    payload.data.data &&
    payload.data.data.response &&
    payload.data.data.response.wordCloudData;
  let filteredWordCloudData =
    wordCloudData && wordCloudData.filter((item) => item.count > getCustomThreshold());
  return filteredWordCloudData;
};

export const fetchExploreWordcloudDataFailed = (state, payload) => {
  return {
    ...state,
    exploreWordCloudDataApi: {
      isProcessing: false,
      data: [],
      error: payload.error,
    },
  };
};
