import { apiInitialState } from '../../../utils/apiStateUtils';

export default{
    commentsApi: apiInitialState(),
    switchCommentApi: apiInitialState(),
    switchCommentLoadingItems: [],
    topicsRepresentationApi: apiInitialState(),
    questionWordCloudApi: apiInitialState(),
    topicsDataApi:apiInitialState(),
    topicsData:null,
    columns:null,
    selectedColumns:null
};