import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const fetchExploreTrendInsightsDataStart = (state) => {
    return {
        ...state,
        fetchExploreTrendInsightsDataApi: getApiStartState()
    }

}

export const fetchExploreTrendInsightsDataSuccess = (state, payload) => {
    return {
        ...state,
        fetchExploreTrendInsightsDataApi: getSuccessResponse(payload.data),
        isTrendInsightsAlreadyFetched:true,
        filters: payload.filters || {},
        selectedUser : payload.selectedUser,
        directReporteesOnly: payload.directReporteesOnly
    }

}

export const fetchExploreTrendInsightsDataFailed = (state, payload) => {
    return {
        ...state,
        fetchExploreTrendInsightsDataApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}

// export const setOutliersDataFetchedKeyasFalse = (state,payload) =>{
//     return{
//         ...state,
//         isTrendInsightsAlreadyFetched:false
//     }
// }

// export const setOutliersFilter=(state,payload)=>{
//     return{
//         ...state,
//         filters:payload
//     }
// }