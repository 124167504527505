import { toast } from "../../../../utils/messageUtils";
import {
  makeKey,
  update,
  getSuccessResponse,
  handleApiResponseError,
  handleNetworkError,
} from "../../../../utils/reducerOperations";

const dimensionListApi = "dimensionListApi";

export function fetchDimensionListSuccess(state, payload) {
  return makeKey(
    dimensionListApi,
    update(
      state.dimensionListApi,
      convertDimensionListToObject(getSuccessResponse(payload))
    )
  );
}

function convertDimensionListToObject(dataObject) {
    let obj = {};
  dataObject.data.forEach(item => {
    let dimensionValue = item.dimension;
    let countValue = item.count;
    obj[dimensionValue] = countValue;
  });
  dataObject.data = obj;
  return dataObject;
}

export function fetchDimensionListStarted(state, payload) {
  return makeKey(
    dimensionListApi,
    update(state.dimensionListApi, {
      isProcessing: true,
      data: null,
      error: null,
    })
  );
}

export function fetchDimensionFailed(state, payload) {
  let response = makeKey(
    dimensionListApi,
    update(state.dimensionListApi, {
      ...(payload.response && payload.response.data
        ? handleApiResponseError(payload.response.data)
        : handleNetworkError(payload)),
    })
  );
  toast.error(response.dimensionListApi.message);
  return response;
}
