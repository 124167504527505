import { Default } from "./initialState";
import {
  fetchPayload,
  setInputFormChange,
} from "../../../utils/reducerOperations";
import { HRIS_UPLOAD_FORM_CHANGE } from "../../constants";

const hrisUploadReducer = (state = Default(), action) => {
  let payload = fetchPayload(action);
  
  switch (action.type) {
    case HRIS_UPLOAD_FORM_CHANGE:
      let configuration = setInputFormChange(
        state.hirsInputBoxConfiguration,
        payload
      );
      return { ...state, hirsInputBoxConfiguration: configuration };

    default:
      return state;
  }
};

export default hrisUploadReducer;
