import React from 'react'
import { DeleteButton } from "../../FormElements/DeleteButton";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { checkAccessStatus, checkIfAdmin } from '../../utils/rolePermissionUtils';
import { GetLocalStorage } from '../../utils/localStorgeOperations';
import{ getDataFromUrlToken} from '../../utils/urlDataStore';
import {URL_DATA} from '../../../config/urlDataConstants'
import {SETTINGS_PERMISSIONS} from '../../../config/userPermissionConstants'

const btnText = "Delete"
const btnType = "danger"
const localStorageUsername="username"

const checkIfSuperAdmin = (superAdmin,org_Id) =>{
  return superAdmin ? superAdmin.includes(org_Id) : false
}

export default {
  ADD_ADMIN: "addAdmin",
  ADD_BUTTON: "Add admin",
  ERROR_MESSAGE: "Please fill all the field",
  ADD: "Add",
  MODAL_HEADER: "Add new Admin",
  ORG_ADMINS: "Organization Admins",
  addAdminRows: [
    {
      label: "First Name",
      value: "firstName",
      placeholder: "Enter First Name",
    },
    { label: "Last Name", value: "lastName", placeholder: "Enter Last Name" },
    { label: "Email ID", value: "email", placeholder: "Enter Email ID" },
    {
      label: "Role Name",
      value: "roleName",
      placeholder: "Enter Role Name",
      disabled: true,
    },
  ],
  addAdminTableColumns: (deleteAdmin) => [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      width: 150,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "userId",
      key: "email",
      width: 200,
    },
    {
      title: '',
      dataIndex: 'delete',
      width: 200,
      render: (_, record) => {
        const org_Id = getDataFromUrlToken(URL_DATA.ORGANIZATION)&&getDataFromUrlToken(URL_DATA.ORGANIZATION).organizationId
        return (
          <DeleteButton
            className="table-btn"
            type={btnType}
            children={btnText}
            onClick={() => deleteAdmin(record)}
            onMouseEnter={() => { }}
            onMouseLeave={() => { }}
            classList={["icon"]}
            iconName={faTrashAlt}
            disabled={getConditionOne(record,org_Id) || getConstionTwo(record,org_Id)}
          />
        );
      }
    }
  ],
};

const getConditionOne = (record,org_Id)=>{
  return !checkIfAdmin() && checkIfSuperAdmin(record.superAdminOrganizationIds,org_Id)
}

const getConstionTwo = (record,org_Id)=>{
  return (record.username === GetLocalStorage(localStorageUsername)) && 
  !checkAccessStatus(getDataFromUrlToken(org_Id),SETTINGS_PERMISSIONS.DELETE_ADMIN)
}
