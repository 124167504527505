export const fetchMultiQuestionsDiagramStart = (state, payload) => {
    return {
        ...state,
        multiSelectDiagramApi: {
            isProcessing: true,
            data: [],
            error: false,
            message: "",
            isAlreadyFetched: false
        },
    };
};

export const fetchMultiQuestionsDiagramSuccess = (state, payload) => {
    let data = payload.data && payload.data.data && payload.data.data.data ? payload.data.data.data : {}
    return {
        ...state,
        multiSelectDiagramApi: {
            isProcessing: false,
            data: getMultiSelectData(data),
            highestScore: getHighestFavScore(data),
            error: false,
            message: "",
            isAlreadyFetched: true
        },
        filters: payload.data.filters,
        byScore: payload.data.byScore,
        multipleSelect: payload.data.multipleSelect,
        selectedUser: payload.data.selectedUser,
        directReporteesOnly: payload.data.directReporteesOnly,
    };
};

export const fetchMultiQuestionsDiagramFail = (state, payload) => {
    return {
        ...state,
        multiSelectDiagramApi: {
            isProcessing: false,
            data: [],
            error: true,
            message: payload.error,
            isAlreadyFetched: false
        },
    };
};

const getMultiSelectData = (data) => {
    let dimensionData = [];
    Object.keys(data || {}).forEach((item) => {
        dimensionData.push({ key: item, value: data[item].histogramData });
    });
    return dimensionData;
};

const getHighestFavScore = (data = {}) => {
    let highestFavScores = [];
    Object.keys(data).forEach((key) => {
        sortData(data[key].histogramData);
        data[key].histogramData &&
            data[key].histogramData[0] &&
            data[key].histogramData.length &&
            highestFavScores.push(data[key].histogramData[0]);
    });
    let highestFavScore =
        highestFavScores.length &&
        highestFavScores.reduce((a, b) => (a.favScore > b.favScore ? a : b));
    return highestFavScore && highestFavScore.favScore
        ? highestFavScore.favScore
        : 0;
};

const sortData = (data) => {
    data.sort((a, b) => {
        if (a.favScore > b.favScore) return -1;
        else if (a.favScore < b.favScore) return 1;
        return 0;
    });
};


