import React from "react";
import Container from "../../Components/Container";
import { CustomTable } from "../../Components/CustomTable";
import Organization from "../../Components/Organization";
import { EmptyState } from "../../Packages/EmptyState";
import constants from "./constants";
import HomeLoader from "../../Components/ContentLoaders/HomeLoader";
import { RouteHeader } from "../../Components/RouteHeader";
import TableLoader from "../../Components/ContentLoaders/TableLoader";
import { IconButton } from "../../FormElements/IconButton/";
import "./index.scss";
import { Pagination } from "../../Components/Pagination";
import {
  getOrgId,
} from "../../utils/localStorgeOperations";
import RenameOrganization from "./RenameOrganization";
import ConfirmationMessage from "../../Components/ConfirmationMessage";
import { getOrgIdFromUrl } from "../../utils/urlProcessor";
import { getNewSurveyPagePath } from "../../utils/getHomePath";
import { ORGANIZATION_PAGE_SECTION } from '../../../config/tabConstants';
import { checkIfAdmin, checkIfOrgAdmin, checkIfSuperAdminOrOrgAdmin, checkMoreThanSingleOrganization, getSingleOrganizationId } from '../../utils/rolePermissionUtils';
import { encryptJson } from "../../utils/urlDataStore";
import { nlpSetURLParams } from "../../utils/nlpURLUtils";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText:"",
      currentPage: 1,
      renameOrgVisible: false,
      orgName: constants.emptyString,
      visibleConfirm: false,
      messageConfirmBox: "",
      confirmBoxOk: false,
      organizationId: "",
      deleteLoading: false,
      org_Id: getOrgIdFromUrl()
    };
    this.bindFunctions();
    if (checkIfAdmin() || checkMoreThanSingleOrganization()) {
      // this.props.getOrganizationProfile({
      //   organizationId: orgId,
      //   currentTab: ORGANIZATION_PAGE_SECTION.ORGANIZATION
      // });
    }
    else {
       const orgDetails = {
    organizationId: getSingleOrganizationId(), 
  };
   const encryptedOrgDetails = encryptJson({orgDetails});
      props.history.push({
        pathname : getNewSurveyPagePath(getSingleOrganizationId()),
        search:nlpSetURLParams({params:encryptedOrgDetails})
      })
    }
    // const orgId = permissions[0].organizationId
    this.onChangePagination(1);

  }

  bindFunctions() {
    this.listOrganization = this.listOrganization.bind(this);
    this.renderOrganizationList = this.renderOrganizationList.bind(this);
    this.renderOrganizations = this.renderOrganizations.bind(this);
    this.showAddOrganization = this.showAddOrganization.bind(this);
    this.organizationIsDeleting = this.organizationIsDeleting.bind(this);
    this.handleDeleteOrganization = this.handleDeleteOrganization.bind(this);
  }
  homePageState(error) {
    return (
      <EmptyState
        customStyle="800"
        type={error}
        emptyStateMessage={constants.ERROR_MESSAGE}
      />
    );
  }

  listOrganization({ isProcessing, data, error }) {
    if (isProcessing) return <HomeLoader />;
    else if (error) this.homePageState(constants.NETWORK_ERROR);
    else if (data && data.Items && data.Items.length)
      return this.renderOrganizations(this.renderOrganizationList);
    else return this.homePageState(constants.states.empty);
  }

  renderOrganizationList() {
    const sortedOrgList = this.props.fetchOrganizationApi.data.Items.sort(
      (org, nextOrg) => org.name.localeCompare(nextOrg.name)
    );
    return sortedOrgList.map((organization) => (
      <Organization
        key={organization.organizationId}
        organization={organization}
        deleteOrganization={this.handleDeleteOrganization}
        isDeleting={this.organizationIsDeleting(organization.organizationId)}
        routeTo={this.props.history.push}
      />
    ));
  }
  listOrganizationOnOrgAdmin = ({ isProcessing, data, error }) => {
    if (isProcessing) return <HomeLoader />;
    else if (error) this.homePageState(constants.states.error);
    else if (data && data.Item) {
      return this.renderOrganizations(this.renderOrganizationListOnOrgAdmin);
    } else this.homePageState(constants.states.empty);
  };
  renderOrganizationListOnOrgAdmin = () => {
    const { Item } = this.props.getOrganizationProfileApi.data;
    return (
      <Organization
        key={Item.organizationId}
        organization={Item}
        isDeleting={this.organizationIsDeleting(Item.organizationId)}
        deleteOrganization={this.handleDeleteOrganization}
        routeTo={this.props.history.push}
      />
    );
  };

  addClassForOrgAdmin = () => {
    let organizationId = getOrgIdFromUrl();
    if (checkIfSuperAdminOrOrgAdmin(organizationId) || checkIfAdmin()) return " single-organization";
    return "";
  };

  renderOrganizations(renderOrg) {
    return (
      <ul
        className={"grid-block organization-list" + this.addClassForOrgAdmin()}
        children={renderOrg()}
      />
    );
  }

  organizationIsDeleting(organizationId) {
    return (
      this.props.deleteOrganizationApi.isProcessing &&
      organizationId === this.props.deleteOrganizationApi.organizationId
    );
  }

  handleDeleteOrganization(params, value) {
    this.setState({ visibleConfirm: true });
    this.setState({ confirmBoxOk: constants.DELETE_ORGANIZATIONS_MESSAGE });
    this.setState({ organizationId: value.organizationId })
    if (value) this.setState({ orgName: value.name });
    this.setState({ messageConfirmBox: `Are you sure you want to delete the ${value.name} organization? ` });

  }

  onDeleteOrganization() {
    let payload = {
      currentTab: ORGANIZATION_PAGE_SECTION.ORGANIZATION,
      from: 0,
      size: constants.LISTSIZE,
      sortField: this.state.sortField
        ? this.state.sortField
        : constants.sortOrganizations.defaulSortKey,
      sortOrder: this.state.sortOrder
        ? this.state.sortOrder
        : constants.sortOrganizations.defaultSortOrder,
    };
    this.props.deleteOrganization({
      currentTab: ORGANIZATION_PAGE_SECTION.ORGANIZATION,
      organizationId: this.state.organizationId
    }, () => {
      this.setState({ currentPage: 1, visibleConfirm: false, deleteLoading: false });
      this.props.fetchOrganizations(payload);
    });
  }

  showAddOrganization() {
    this.props.history.push(constants.path.addOrganization);
  }

  generateAddOrganizationButton = () => {
    const { btn } = constants;
    if (checkIfAdmin()) {
      return (
        <div className="controls">
          <IconButton
            disabled={false}
            onClick={this.showAddOrganization}
            classList={btn.classes}
            iconName={btn.iconName}
            text={btn.text}
          />
        </div>
      );
    }
  };

  getFetchApi = () => {
    if (checkIfSuperAdminOrOrgAdmin(getOrgId()) || checkIfOrgAdmin()) {
      return this.listOrganizationOnOrgAdmin(
        this.props.getOrganizationProfileApi
      );
    }
    return this.listOrganization(this.props.fetchOrganizationApi);
  };
  onChangePagination = (pageNo, cb) => {
    this.setState({ currentPage: pageNo });
    let payload = {
      currentTab: ORGANIZATION_PAGE_SECTION.ORGANIZATION,
      from: (pageNo - 1) * constants.LISTSIZE,
      size: constants.LISTSIZE,
      sortField: this.state.sortField
        ? this.state.sortField
        : constants.sortOrganizations.defaulSortKey,
      sortOrder: this.state.sortOrder
        ? this.state.sortOrder
        : constants.sortOrganizations.defaultSortOrder,
    };
    this.props.fetchOrganizations(payload);
    if (cb && typeof cb === 'function') {
      cb();
    }
  };
  handleTableChange = (pagination, filters, sorter) => {
    let payload = {};
    if (sorter) {
      if (sorter.column && sorter.order) {
        let sorterColumnKey = sorter.columnKey;
        payload.sortField = sorterColumnKey;
        payload.sortOrder = constants.sortOrganizations[sorter.order];
        this.setState({
          sortField: sorterColumnKey,
          sortOrder: constants.sortOrganizations[sorter.order],
        });
      } else {
        if (sorter.columnKey === constants.sortOrganizations.defaulSortKey) {
          payload.sortField = sorter.columnKey;
          payload.sortOrder = constants.sortOrganizations.desc;
          this.setState({
            sortField: constants.sortOrganizations.defaulSortKey,
            sortOrder: constants.sortOrganizations.desc,
          });
        } else {
          this.setState({
            sortField: constants.sortOrganizations.defaulSortKey,
            sortOrder: constants.sortOrganizations.defaultSortOrder,
          });
        }
      }
      this.props.fetchOrganizations({
        currentTab: ORGANIZATION_PAGE_SECTION.ORGANIZATION,
        from: (this.state.currentPage - 1) * constants.LISTSIZE,
        size: constants.LISTSIZE,
        ...payload,
      });
    }
  };
  addOrgButtonClick = () => {
    this.showAddOrganization();
  };
  visibleToggle = (key, value) => {
    this.setState({ [key]: !this.state[key] });
    this.setState({ orgDetails: value });
    if (value) this.setState({ orgName: value.name });
  };
  createTableProps = () => {
    return {
      ...this.props,
      onChangePagination: this.onChangePagination,
      currentPage: this.state.currentPage,
      visibleToggle: this.visibleToggle,
      handleDeleteOrganization: this.handleDeleteOrganization
    };
  };
  confirmationMessage = () => {
    return (
      <ConfirmationMessage
        visibleConfirm={this.state.visibleConfirm}
        confirmBoxCancel={this.confirmBoxCancel}
        onOk={this.confirmBoxOk}
        onCancel={this.confirmBoxCancel}
        heading={this.state.messageConfirmBox}
        loading={this.state.deleteLoading}
        disabled={this.state.deleteLoading}
        confirmButtonLabel={"Yes"}
        cancelButtonLabel={'No'}
        deleteHeader={constants.DELETE_ORGANIZATION__HEADER}
      >
      </ConfirmationMessage>
    );
  };
  confirmBoxCancel = () => {
    this.setState({ visibleConfirm: false });
  };
  confirmBoxOk = () => {
    this.setState({ deleteLoading: true })
    this.onDeleteOrganization();
  };

  getTableColumns = () => {
    const columns = constants.tableColumn(this.createTableProps())
    if (checkIfAdmin()) return columns;
    else {
      return columns.filter(item => item.dataIndex !== 'role')
    }
  }

  isOrganizationManagerPageEmpty = () => {
    if (!checkIfAdmin()) {
      if (this.props.fetchOrganizationApi.data &&
        this.props.fetchOrganizationApi.data.hits.length === 0)
        return true
    }
    return false
  }
  handleChangeSearchInput=(e)=>{
    
    this.setState({searchText:e.target.value})
      }
    
      handleClearSearch=()=>{
        this.setState({searchText:null})
      }
    
      handleSearchEnter=()=>{
        let payload = {
          currentTab: ORGANIZATION_PAGE_SECTION.ORGANIZATION,
          from: 0,
          size: constants.LISTSIZE,
          sortField: this.state.sortField
            ? this.state.sortField
            : constants.sortOrganizations.defaulSortKey,
          sortOrder: this.state.sortOrders
            ? this.state.sortOrder
            : constants.sortOrganizations.defaultSortOrder,
          searchText:this.state.searchText,
        };
          this.props.fetchOrganizations(payload);
        };
  render() {
    return (
      <>
        <RouteHeader
          {...this.props}
          title={this.isOrganizationManagerPageEmpty() ? 'Projects' : 'Organizations'}
          childButton={this.generateAddOrganizationButton()}
          navigationData={constants.navigationList}
          handleChangeInput={this.handleChangeSearchInput}
          handleClickEnter={this.handleSearchEnter}
          handleClearSearch={this.handleClearSearch}
        />
        <div className="organizations-block">
          <Container>
            {!this.props.fetchOrganizationApi.data ? (
              <TableLoader />
            ) : (
              <>
                {this.props.fetchOrganizationApi.error ? (
                  <EmptyState
                    type={"Network Error"}
                    emptyStateMessage={this.props.fetchOrganizationApi.message}
                  />
                ) : this.props.fetchOrganizationApi.data &&
                  this.props.fetchOrganizationApi.data.hits.length > 0 ? (
                  <>
                    <CustomTable
                      className="side-linetbl"
                      dataSource={
                        this.props.fetchOrganizationApi.data
                          ? this.props.fetchOrganizationApi.data.hits
                          : []
                      }
                      columns={this.getTableColumns()}
                      pagination={false}
                      onChange={this.handleTableChange}
                      loading={this.props.fetchOrganizationApi.isProcessing}
                    />
                    {this.props.fetchOrganizationApi.data && this.props.fetchOrganizationApi.data.total &&
                      this.props.fetchOrganizationApi.data.total > 12 ?
                      <Pagination
                        onChange={this.onChangePagination}
                        page={this.state.currentPage}
                        count={this.props.fetchOrganizationApi.data.total}
                        item={constants.ORGANIZATIONS}
                        pageSize={constants.listSize}
                      /> : null}
                  </>
                ) : (
                  checkIfAdmin() ? <EmptyState
                    type={"addOrganization"}
                    component={"addOrganization"}
                    addButtonClick={this.addOrgButtonClick}
                  ></EmptyState> :
                    <EmptyState
                      emptyStateMessage={"You have no access to view any projects"}
                      component="noAccess"
                    />
                )}
              </>
            )}
            {this.state.renameOrgVisible &&
              <RenameOrganization
                renameOrg={this.props.renameOrg}
                renameOrgApi={this.props.renameOrgApi}
                visible={this.state.renameOrgVisible}
                visibleToggle={this.visibleToggle}
                orgDetails={this.state.orgDetails}
                orgName={this.state.orgName}
                currentPage={this.state.currentPage}
                onChangePagination={this.onChangePagination}
                inputBoxConfiguration={this.props.inputBoxConfiguration}
                formInputChange={this.props.formInputChange}
              />}
            {this.confirmationMessage()}
          </Container>
        </div>
      </>
    );
  }
}
export default HomePage;
