export const wellnessTemplate = [
    {
        questionDimension: 'Wellness',
        type: 'Matrix',
        questionLabel: 'Wellness',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: 'It is easy for me to balance work and personal activities.', value: '', isSelected: true
        },

        {
            text: `I feel comfortable raising concerns about my own or a colleague's wellbeing.`, value: '', isSelected: true

        },
        {
            text: 'I am made aware of important deadlines early enough to include my personal life when planning work.', value: '', isSelected: false

        }]
    }
]




