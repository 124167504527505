import { connect } from "react-redux";
import { onChangeLanguage, translateSurvey } from "../../../../../../redux/actions";
import TranslationSection from "./TranslationSection";


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        translateSurvey: (payload, cb) => dispatch(translateSurvey(payload, cb)),
        onChangeLanguage: (payload, cb) => dispatch(onChangeLanguage(payload, cb))


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TranslationSection);