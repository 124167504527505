import {
    FETCH_SPREAD_ANALYSIS_FAIL,
    FETCH_SPREAD_ANALYSIS_STARTED,
    FETCH_SPREAD_ANALYSIS_SUCCESS
} from '../../constants';
import {
    FETCH_SPREAD_ANALYSIS_URL
} from '../../../../config/constants';
import { ApiBase } from '../../../utils/apiBase';
import { withType } from '../../../utils/actionOperations';
import { getDataFromUrl }
    from '../../../utils/getDataFromQueryString';
import { GetLocalStorage } from '../../../utils/localStorgeOperations';
    
export function fetchSpreadAnalysis() {
    const apiBaseInstance = new ApiBase().instance;
    return dispatch => {
        dispatch(withType(FETCH_SPREAD_ANALYSIS_STARTED, {}));
        return apiBaseInstance.post(FETCH_SPREAD_ANALYSIS_URL, {
            projectId: GetLocalStorage("projectId"),
            prevSurveyId: 'pbr1jvp4btn3',
            filters: getDataFromUrl().filters || {}
        })
            .then(res =>{
                fetchSpreadAnalysisSuccess(dispatch, res);})
            .catch(err =>
                fetchSpreadAnalysisFailure(dispatch, err));
    };
}

function fetchSpreadAnalysisSuccess(dispatch, res) {
    return dispatch(
        withType(FETCH_SPREAD_ANALYSIS_SUCCESS, res.data)
    );
}
function fetchSpreadAnalysisFailure(dispatch, err) {
    return dispatch(withType(
        FETCH_SPREAD_ANALYSIS_FAIL, err));
}