export const ageDemographicsTemplate = [
    {
        type: 'Multiple Choice',
        questionDimension: 'Demographics',
        isSelected: false,
        options: [
            {
                label: "Generation  Z (Born 1997-2012)",
                value: "1"
            },
            {
                label: "Millennial (Born 1981-1996)",
                value: "2"
            },
            {
                label: "Generation X (Born 1965-1980)",
                value: "3"
            },
            {
                label: "Baby Boomer (Born 1946-1964)",
                value: "4"
            },
            {
                label: "Silent (Born 1928-1945)",
                value: "5"
            }
        ],
        question: 'Age.',
        questionLabel: 'Age',
        isDemographic: true
    }
]