export const inviteManagersStart = (state) => {
    return {
        ...state,
        inviteManagersApi: {
            isProcessing: true,
            data:null,
            error:false,
            message:''
        },
    }
}

export const inviteManagersSuccess = (state, payload) => {
    return {
        ...state,
        inviteManagersApi:  {
            isProcessing:false,
            data:payload.data.data,
            error:false,
            message:payload.message,
        }
    }
}

export const inviteManagersFailed = (state, payload) => {
    return {
        ...state,
        inviteManagersApi: {
            isProcessing: false,
            data: null,
            error: payload.data.data.error,
            message:payload.message
        }
    }

}