import {
    update,
    makeKey,
} from '../../../../utils/reducerOperations';
import { appendUserRoles } from './appendUserRole';
export function fetchUsersPrevSuccess(state, payload) {
    let nextPageEnabled = false;
    let previousPageEnabled = true;
    let pageKeys = state.fetchUsersApi.pageKeys;
    let page = state.fetchUsersApi.page;
    page--;
    if(payload.data.LastEvaluatedKey) {
        nextPageEnabled = true; 
        pageKeys[page] = payload.data.LastEvaluatedKey;
    }
    if(page === 0) {
        previousPageEnabled = false;
    }
    return makeKey(
        'fetchUsersApi',
        update(state.fetchUsersApi, {
            isProcessing: false,
            data: appendUserRoles(payload.data),
            message: payload.message,
            error: !payload.status,
            nextPageEnabled: nextPageEnabled,
            previousPageEnabled: previousPageEnabled,
            page: page,
            pageKeys: pageKeys
        })
    );
}

// function filterInactiveUser(list) {
//     list.Items = list.Items.filter(eachItem => eachItem.active);
//     return list;
// }

