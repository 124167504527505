import { SURVEY_EDITOR_RANKING_RIGHT_CHANGE_QUESTION_AND_LABEL, SURVEY_EDITOR_RANKING_DRAG_AND_DROP, SURVEY_EDITOR_RANKING_DELETE_OR_ADD_OPTION } from "../../../../../constants";

export const RankingScaleChangeOptionQuestionAndLabel = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_RANKING_RIGHT_CHANGE_QUESTION_AND_LABEL, payload });
    }
};

export const RankingScaleDragAndDrop = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_RANKING_DRAG_AND_DROP, payload });
    }
};

export const RankingScaleDeleteOrAddOption = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_RANKING_DELETE_OR_ADD_OPTION, payload });
    }
};