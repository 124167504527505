import React from "react";
import { ButtonElement } from "../../FormElements/ButtonElement";
import Loader from "../../Packages/Loader";
import constants from "./constants";
export const LoaderButton = (props) => {
  return (
    <ButtonElement
      disabled={props.disabled || props.loader}
      type={props.type || 'primary'}
      test-name="loader-button"
      onClick={props.onClick}
      loading={props.loading}
      className={[...props.classList, getClassName(props)].join(
        constants.emptySpace
      )}
      title={props.title}
    >
      {props.loader && (
        <Loader
          test-name="loader-spin"
          classList={props.loaderClass || constants.emptySpace}
        />
      )}
      <span children={props.text} />
    </ButtonElement>
  );
};

const getClassName = (props) => {
  if (props.disabled || props.loader) {
    return "disabled";
  }
  return "";
};
