const currentPath = "/admin/home/";
export default {
  ROUTES: [
    {
      title: "Organizations",
      btnText: "Manage Organizations",
      path: currentPath + "organizations",
    },
    // {
    //     title:'Users',
    //     btnText:'Manage Users',
    //     path:currentPath+'Users'
    // },
    // {
    //     title:'Data Collection',
    //     btnText:'Manage Data Collection',
    //     path:'/projects'
    // },
    // {
    //     title:'NLP',
    //     btnText:'Manage Text Analysis',
    //     path:'/nlp'
    // }
  ],
  navigationList: ["home"],
};
