import {
  RESET_PASSWORD_FORM_CHANGE,
  RESET_PASSWORD_PROCESS_STARTED,
  RESET_PASSWORD_PROCESS_SUCCESS,
  RESET_PASSWORD_PROCESS_FAILED,
} from "../../constants";
import { fetchPayload, update } from "../../../utils/reducerOperations";
import { Default } from "./InitialState";
import {
  resetPasswordFormChange,
  resetPasswordStarted,
  resetPasswordSuccess,
  resetPasswordFailed,
  resetState,
} from "./Helpers";
const resetPasswordPageReducer = (state = Default(), action) => {
  let resetPayload = fetchPayload(action);
  switch (action.type) {
    case RESET_PASSWORD_FORM_CHANGE:
      return update(
        resetState(state),
        resetPasswordFormChange(state, resetPayload)
      );
    case RESET_PASSWORD_PROCESS_STARTED:
      return update(state, resetPasswordStarted(state, resetPayload));
    default:
      return resetSuccessFail(state, action);
  }
};
const resetSuccessFail = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case RESET_PASSWORD_PROCESS_SUCCESS:
      return update(state, resetPasswordSuccess(state, payload));
    case RESET_PASSWORD_PROCESS_FAILED:
      return update(state, resetPasswordFailed(state, payload));
    default:
      return { ...state };
  }
};

export default resetPasswordPageReducer;
