import { deepCopy } from "../../../../../utils/deepCopy"

export const demographicsDragAndDrop = (state, payload) => {
    let goals = getdragAndDropAppliedGoals(state.goals, payload)
    return {
        ...state,
        goals: goals
    }
}


const getdragAndDropAppliedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    if (payload.destination && payload.source) {
        let destination = payload.destination
        let source = payload.source
        goalsCopy.forEach(item => {
            if (item.goalId === "demographics") {
                item.questions.forEach(question => {
                    if (question.staticQuestionId === payload.staticQuestionId) {
                        const dragQuestion = question.options[source.index]
                        if (dragQuestion) {
                            question.options.splice(source.index, 1)
                            question.options.splice(destination.index, 0, dragQuestion)
                        }
                    }
                })
            }
        })
        return goalsCopy
    } else {
        return goalsCopy
    }
}   