import { Default } from "./IntialState";
import {
  CHANGE_ACTIVE_FL_NAME_FORM_CHANGE,
  CHANGE_ACTIVE_FL_NAME_STARTED,
  CHANGE_ACTIVE_FL_NAME_SUCCESS,
  CHANGE_ACTIVE_FL_NAME_FAILED,
  CHANGE_ACTIVE_FL_NAME_CLEAR
} from "../../constants";
import { fetchPayload, update } from "../../../utils/reducerOperations";
import {
  changeFirstLastNameFormChange,
  changeFirstLastNameStarted,
  changeFirstLastNameSuccess,
  changeFirstLastNameFailed,
  clearChangeFirstLastNameForm,
} from "./Helpers";
const changeFirstLastNamePageReducer = (state = Default(), action) => {
  let resetPayload = fetchPayload(action);
  switch (action.type) {
    case CHANGE_ACTIVE_FL_NAME_CLEAR:
      return clearChangeFirstLastNameForm(state, resetPayload);
    case CHANGE_ACTIVE_FL_NAME_FORM_CHANGE:
      return update(
        state,
        changeFirstLastNameFormChange(state, resetPayload)
      );
    case CHANGE_ACTIVE_FL_NAME_STARTED:
      return update(state, changeFirstLastNameStarted(state, resetPayload));
    default:
      return changeSuccessFail(state, action);
  }
};
const changeSuccessFail = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case CHANGE_ACTIVE_FL_NAME_SUCCESS:
      return update(state, changeFirstLastNameSuccess(state, payload));
    case CHANGE_ACTIVE_FL_NAME_FAILED:
      return update(state, changeFirstLastNameFailed(state, payload));
    default:
      return { ...state };
  }
};
export default changeFirstLastNamePageReducer;
