import React from "react";
import { EmptyState } from "../../../../../../Packages/EmptyState";
import {  getDataFromUrlToken } from "../../../../../../utils/urlDataStore";
import { URL_DATA } from "../../../../../../../config/urlDataConstants";
import { getSelectedUserData } from "../../helper";
import { checkIfObjectsAreEqual } from "../../../../../../utils/customFunctions";
import {
  checkConnectedProjects,
  checkTrendProjects,
} from "../../../../../../utils/trendUtils";
import GraphLoader from "../../../../../../Components/ContentLoaders/GraphLoader";
import TableLoader from "../../../../../../Components/ContentLoaders/TableLoader";
import { getURLFilterAndByScore } from "../../../../../../utils/byScoreFilterUtils";
import { DASHBOARD_TABS } from '../../../../../../../config/tabConstants'
import InsightsTable from "../../../../../../Components/InsightsTable";
import { NEGATIVE_OUTLIER, POSITIVE_OUTLIER, negativeOutliers, positiveOutliers } from "./constants";

export class Outliers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const selectedUserData = getSelectedUserData();
    let filterData = getURLFilterAndByScore(urlFilter)
    if (this.checkIfApiCallRequiredOnMount(filterData.filters, selectedUserData)) {
      this.callApi(filterData.filters, selectedUserData);
    }
  }

  callApi = (filters, selectedUserData) => {
    const projectId =
      this.props.surveyDetailsApi &&
      this.props.surveyDetailsApi.surveyDetails &&
      this.props.surveyDetailsApi.surveyDetails.projectId;
    const hrisFileId =
      this.props.surveyDetailsApi &&
      this.props.surveyDetailsApi.surveyDetails &&
      this.props.surveyDetailsApi.surveyDetails.hrisFileId;
    let trendData = checkConnectedProjects(
      this.props.surveyDetailsApi && this.props.surveyDetailsApi.surveyDetails
    );
    let payload = {
      projectId: projectId,
      filters: filters,
      directReporteesOnly: selectedUserData.directReporteesOnly,
      selectedUser: selectedUserData.selectedUser,
      hrisFileId: hrisFileId,
      currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.OUTLIERS
    };
    if (trendData.length > 0) {
      payload.connectedProjects = trendData;
    }
    this.props.fetchNewDashboardOutliersData(payload);
  };

  checkIfApiCallRequiredOnMount = (urlFilter, selectedUserData) => {
    if (
      !this.props.isOutliersDataAlreadyFetched ||
      !checkIfObjectsAreEqual(urlFilter, this.props.filters) ||
      !checkIfObjectsAreEqual(selectedUserData.directReporteesOnly, this.props.directReporteesOnly) ||
      !checkIfObjectsAreEqual(selectedUserData.selectedUser, this.props.selectedUser)
    ) {
      return true;
    }
    return false;
  };
  componentDidUpdate() {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const selectedUserData = getSelectedUserData();
    let filterData = getURLFilterAndByScore(urlFilter)
    if (
      checkIfObjectsAreEqual(filterData.filters, this.props.filters) &&
      checkIfObjectsAreEqual(selectedUserData.directReporteesOnly, this.props.directReporteesOnly) &&
      checkIfObjectsAreEqual(selectedUserData.selectedUser, this.props.selectedUser)
    ) {
    } else {
      if (!this.props.fetchExploreOutliersDataApi.isProcessing) {
        this.callApi(filterData.filters, selectedUserData);
      }
    }
  }

  render() {
    return (
      <>
        {this.props.fetchExploreOutliersDataApi.isProcessing ?
          <>
            <GraphLoader height={300} width={600} />
            <TableLoader />
          </> :
          <>
            {this.props.fetchExploreOutliersDataApi.error ? (
              <EmptyState
                type={"Network Error"}
                emptyStateMessage={this.props.fetchExploreOutliersDataApi.message}
              />
            ) : (
              <InsightsTable
              negativeFetchKey={NEGATIVE_OUTLIER}
              positiveFetchKey={POSITIVE_OUTLIER} 
              trendData={checkTrendProjects(
                this.props.surveyDetailsApi &&
                this.props.surveyDetailsApi.surveyDetails
              )}
              threshold={this.props.threshold}
              apiData={this.props.fetchExploreOutliersDataApi}
              positiveHeading={positiveOutliers}
              negativeHeading={negativeOutliers}
              />
            )}
          </>}
      </>
    );
  }
}
