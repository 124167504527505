import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { TopicQuestionBarchart } from './NewBarchart';

export const TopicQuestions = (props) => {
  const [tooltip, setTooltip] = useState(false)
  const getBarChartData = (data) => {
    const { favScore, neutralScore, unFavScore } = data;
    return [{ favScore, neutralScore, unFavScore }];
  };

  const onMouseOver = (event, item) => {
    let selectedHtml = []
    let innerHtml = document.getElementsByClassName("barchart-block__left")
    if (innerHtml) {
      for (let data of innerHtml) {
        if (data.innerText === item) {
          selectedHtml.splice(0, 0, data)
        }
      }
      if (selectedHtml && selectedHtml[0] && selectedHtml[0].scrollHeight > 40) {
        setTooltip(item)
      }
    }

  }

  const onMouseLeave = () => {
    setTooltip('')
  }

  const getTopicQuestions = () => {
    let topicQuestions = props.itemTableData
      .filter((item) => item.dimension === props.topic);
    return topicQuestions;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getQuestion = (item) => {
    const question = item.item;

    return (
      <>
        {
          question === tooltip ?
            <Tooltip title={tooltip}>
              {capitalizeFirstLetter(question)}
            </Tooltip>
            : capitalizeFirstLetter(question)

        }
      </>

    )
  };

  return getTopicQuestions().map((item) => {
    return (
      <div key={item.item} className='barchart-block'>
        <div id={item.item} key={item.item} onMouseOver={(event) => onMouseOver(event, item.item)} onMouseLeave={() => onMouseLeave()} className='barchart-block__left'>
          {getQuestion(item)}
        </div>
        <TopicQuestionBarchart data={getBarChartData(item)} />
      </div>
    );
  });
};
