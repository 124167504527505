import { FETCH_STORYBOARD_LOW_OUTCOME_QUESTION_SCORE_SUCCESS } from "../../../constants";
import { withType } from "../../../../utils/actionOperations";

export const lowOutcomeQuestionScore = (payload, outcome) => {
  return (dispatch) => {
    dispatch(withType(FETCH_STORYBOARD_LOW_OUTCOME_QUESTION_SCORE_SUCCESS, payload,
    ));
}
};


