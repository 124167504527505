import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const navigatingComplexity = {
    "blockId": uuidv4(),
    "description": "<p>In this section, please consider the following behaviors focused on areas such as: Accountability, Problem Solving, and Systems Thinking.</p>",
    "goalId": "dynamic180Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Rarely  ",
                    "value": "1"
                },
                {
                    "label": "Not Often Enough",
                    "value": "2"
                },
                {
                    "label": "Often ",
                    "value": "3"
                },
                {
                    "label": "Almost Always  ",
                    "value": "4"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "staticQuestionId": generateStaticQuestionId("Takes accountability for actions and decisions (when things go well and don’t go well)​"),
                    "HTMLText": "<p>Takes accountability for actions and decisions (when things go well and don’t go well)​.</p>",
                    "isSelected": true,
                    "text": "Takes accountability for actions and decisions (when things go well and don’t go well)​.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Manages time and prioritizes tasks efficiently to focus on what matters most​"),
                    "HTMLText": "<p>Manages time and prioritizes tasks efficiently to focus on what matters most​.</p>",
                    "isSelected": true,
                    "text": "Manages time and prioritizes tasks efficiently to focus on what matters most​.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Adjusts easily to change or needed shifts"),
                    "HTMLText": "<p>Adjusts easily to change or needed shifts.</p>",
                    "isSelected": true,
                    "text": "Adjusts easily to change or needed shifts.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Questions or challenges own and others’ assumptions to help see other angles​"),
                    "HTMLText": "<p>Questions or challenges own and others’ assumptions to help see other angles​.</p>",
                    "isSelected": true,
                    "text": "Questions or challenges own and others’ assumptions to help see other angles​.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Analyzes and makes sense of complex information to support problem-solving"),
                    "HTMLText": "<p>Analyzes and makes sense of complex information to support problem-solving.</p>",
                    "isSelected": true,
                    "text": "Analyzes and makes sense of complex information to support problem-solving.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Identifies inter-dependencies and linkages to help self and others see the bigger picture​"),
                    "HTMLText": "<p>Identifies inter-dependencies and linkages to help self and others see the bigger picture​.</p>",
                    "isSelected": true,
                    "text": "Identifies inter-dependencies and linkages to help self and others see the bigger picture​.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Makes connections and translates the bigger picture (e.g., business context, functional goals, team goals) to own area of work.​"),
                    "HTMLText": "<p>Makes connections and translates the bigger picture (e.g., business context, functional goals, team goals) to own area of work​.</p>",
                    "isSelected": true,
                    "text": "Makes connections and translates the bigger picture (e.g., business context, functional goals, team goals) to own area of work.​",
                    "value": uuidv4()
                },
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Navigating Complexity"
}