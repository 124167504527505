import { ApiBase } from "../../../../utils/apiBase";
import { FETCH_HRIS_URL } from "../../../../../config/constants";
import {
  SURVEY_WIZARD_FETCH_HRIS_FILE_START,
  SURVEY_WIZARD_FETCH_HRIS_FILE_SUCCESS,
  SURVEY_WIZARD_FETCH_HRIS_FILE_FAIL,
} from "../../../constants";
import { deepCopy } from '../../../../utils/deepCopy';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const surveyWizardFetchHRISFiles = (params,cb,presetData) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(surveyWizardFetchHRISFileStart(presetData));
    apiBaseInstance
      .post(FETCH_HRIS_URL, { ...params, ...getProjectIdAndOrgId(params) })
      .then((res) => {
        cb&&cb()
        dispatch(surveyWizardFetchHRISFileSuccess(res));
      })
      .catch((err) => {
        cb&&cb()
        dispatch(surveyWizardFetchHRISFileFailure(err));
      });
  };
};

const surveyWizardFetchHRISFileStart = (presetData) => {
  return {
    type: SURVEY_WIZARD_FETCH_HRIS_FILE_START,
    payload : deepCopy(presetData)
  };
};

const surveyWizardFetchHRISFileSuccess = (res) => {
  return {
    type: SURVEY_WIZARD_FETCH_HRIS_FILE_SUCCESS,
    payload: res.data
  };
};
const surveyWizardFetchHRISFileFailure = (error) => {
  return {
    type: SURVEY_WIZARD_FETCH_HRIS_FILE_FAIL,
    payload: error,
  };
};
