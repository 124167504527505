import { GO_TO_PARTICULAR_PAGE, PROCEED_TO_NEXT_STEP } from "../../../constants"

export const handleClickYes = (payload) => {
    return {
        type: GO_TO_PARTICULAR_PAGE,
        payload
    }
}
// edit the payload so this function can be used as generic to go to any of the current index in the answering page


export const handleClickNo = (payload) => {
    return {
        type: PROCEED_TO_NEXT_STEP,
        payload
    }
}