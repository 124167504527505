import {fetchFormConfigurations} from '../../inputFormConfigurations';
import {setInitialState} from '../../../utils/reducerOperations';

export function Default() {
    return {
        isFormVaalid:false,
        forgotPasswordApi: setInitialState({code: ''}),
        inputBoxConfiguration: fetchFormConfigurations(['username'])
    };
}
