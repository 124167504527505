import React from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import "./index.scss";
export const ScrollBar = (props) => {
  return (
    <SimpleBar {...props}>
      {props.children}
    </SimpleBar>
  )
}
export default ScrollBar;