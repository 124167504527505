
export default {
  COMPLETED: "Completed",
  sortHRIS: {
    ascend: "asc",
    descend: "desc",
    keyword: ".keyword",
    defaulSortKey: "uploadDate",
    defaultSortOrder: "desc",
    asc: "asc",
    desc: "desc",
  },
  orgId: "organizationId",
  uploadBtntext: "Your file upload here",
  buttonText: "Upload", //'Process',
  loaderButton: {
    classes: ["loader-button"],
    loaderClass: ["xs white"],
  },
  field: {
    type: "file",
    name: "survey",
    fileType: ".csv",
  },
  libraryUploadText: "Choose topics library file",
  selectHris: "Select HRIS",
  commentsUploadText: "Choose comment file",
  emptyString: "",
  PROJECT_ORIGIN: { commentAnalysis: "Comment Analysis", dataCollection: "Data Collection" },
  hidden: "hidden",
  loading: "loading",
  upload: "upload",
  download: "download",
  batchIdPlaceholder: "Enter project name",
  downloadButtons: {
    topic: {
      name: "topic",
      link: "Download default topic library file",
      caption: "Download Default Topics Library",
    },
    comment: {
      name: "comment",
      heading: "Upload a new text analysis only project",
      text:
        "You can upload a set of survey comments to be analyzed by our system without collecting the data in Culturebie itself. The project you upload can be anonymous (comments without being tied to a specific individual or manager) or tied to HRIS data for your organization. Follow the steps below to add a new text-only project.",
      instrHead: "Required files:",
      instructions: [
        "Comment file – each row of the file represents an employee and each column, aside from demographic information, can represent a question the employee responded to.",
      ],
      subInst: [
        "Question columns must be named in the following way: “Q1: text of question”, “Q2: text of question”, “Q3: text of question”, and so on.",
        "You can also include demographic information columns, such as “Demographics-Gender”, “Demographics-Age”, “Demographics-Race”, and so on. ",
      ],
      heading2: "Anonymous project",
      text2: "Upload only a comment file and select “No HRIS”. ",
      instructions2: [],
      heading3: "Project tied to HRIS data",
      text3:
        "A text-only project tied to HRIS data means that you can grant access to specific subsets of data based on the reporting hierarchy of your organization. Managers will be able to login to Culturebie and see the results of all employees who report to them, provided enough employees have comments.",
      instructions3: [
        "Upload a comment file in the format above, but add a column called “Employee ID” that is the email address of the employee who wrote the comment(s) in each row. ",
        "Select the HRIS file and organizational hierarchy you want to apply to the project.",
      ],
      link: "Download the sample upload file",
      caption: "Download sample upload file",
    },
  },
  admin: "Admin",
  topic: "topic",
  comment: "comment",
  topicLibraryOptions: [
    { name: "Topics library file 1", value: "Topics library file 1", label: "Topics library file 1" },
    { name: "Topics library file 2", value: "Topics library file 2", label: "Topics library file 2" },
    { name: "Topics library file 3", value: "Topics library file 3", label: "Topics library file 3" },
  ],
  hrisOptions: [
    { name: "HRIS file 1", value: "HRIS file 1" },
    { name: "HRIS file 2", value: "HRIS file 2" },
    { name: "HRIS file 3", value: "HRIS file 3" },
  ],
  navigationList: ["home", "organization", "orgName", "survey", "addReports"],
  productionHostName: ["app.culturebie.com", "www.app.culturebie.com"],
  devSampleCommentUrl: 'https://culturebiedev-uploadbucket.s3.amazonaws.com/samplefiles/sample_comments_file.csv',
  devSampleTopicUrl: 'https://culturebiedev-topiclibrarybucket.s3.amazonaws.com/defaultTopicLibrary_culturebie_surveys.csv',
  prodSampleCommentUrl: 'https://culturebie-uploadbucket.s3.amazonaws.com/samplefiles/sample_comments_file.csv',
  prodSampleTopicUrl: 'https://culturebie-topiclibrarybucket.s3.amazonaws.com/defaultTopicLibrary_culturebie_surveys.csv',
};
