export * from "./fetchTopicsData";
export * from "./fetchDemographicSnapshot";
export * from "./fetchHeatmapData";
export * from "./fetchNPLHeatmapData";
export * from "./fetchNPLHeatmapQuestionData";

export function changeTopicsDropdown(state, action) {
  return {
    ...state,
    selectedDemographicTopics: action.newData,
  };
}
export function updateSelectedColumns(state, action) {
  
  let qid=state.fetchQuestionIdApi && state.fetchQuestionIdApi.questionId;
  //let { selectedDemographicColumns,addfilter } = state;
  let { addfilter } = state;
  let selectedDemographicColumns=addfilter[qid];
  if (action.params.selected) {
    const index = (selectedDemographicColumns || []).findIndex(
      (i) => i === action.params.selected
    );
    if (index > -1)
      selectedDemographicColumns = selectedDemographicColumns.filter(
        (i) => i !== action.params.selected
      );
    else selectedDemographicColumns.push(action.params.selected);
  } else selectedDemographicColumns = [];
  addfilter[action.params.questionId]=[...selectedDemographicColumns]
  return {
    ...state,
    selectedDemographicColumns:[...selectedDemographicColumns],
    addfilter:addfilter

  };
}

export function updateNumericSelectedColumns(state, action) {
  if(action.selected)
  {
  let { selectedNumericDemographicsColumns } = state;
  const index = (selectedNumericDemographicsColumns || []).findIndex(
    (i) => i === action.selected
  );
  if (index > -1)
    selectedNumericDemographicsColumns =
      selectedNumericDemographicsColumns.filter((i) => i !== action.selected);
  else selectedNumericDemographicsColumns.push(action.selected);
  return {
    ...state,
    selectedNumericDemographicsColumns: [...selectedNumericDemographicsColumns],
  };
 }
 else{
  return {
    ...state,
    selectedNumericDemographicsColumns: [],
  };
 }
}

export const deselectNumericColumns = (state) =>{
  let { data } = state.numericDemographicsApi;
  let selectedColumns = Object.keys(data);
  return {
      ...state,
      selectedNumericDemographicsColumns: [...selectedColumns],
    };
}

export const deselectQuestionsColumn = (state) =>{
  let data  = state.demographicTopicsData; 
  let qid= state.fetchQuestionIdApi.questionId;
  if(qid){
    let { addfilter } = state;
     let selectedColumns = Object.keys(data);
  addfilter[qid]=[...selectedColumns]
  return {
      ...state,
      addfilter:addfilter
    };
  }
}
export function fetchQuestionId(state, action) {
  return {
      ...state,
      fetchQuestionIdApi:  action.payload,
    };
}
export function clearQuestionId(state) {
  return {
      ...state,
      addfilter:  {},
    };
}