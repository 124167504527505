import React from "react";
import DownloadButtons from "../DownloadButtons";
import { SelectUpload } from "../SelectUpload";
import constants from "../../constants";
import FileUpload from "../../../../../../Components/FileUpload";
import { LoaderButton } from "../../../../../../FormElements/LoaderButton";
import { AsterikLabel } from "../../../../../../Components/AsterikLabel";
import { TextInput } from "../../../../../../FormElements/TextInput/";
import { Row, Col } from "antd";
import helpers from "../../helper";
import {
  getOrgId,
} from "../../../../../../utils/localStorgeOperations";
import { Tooltip } from "antd";
import SVGIcons from "../../../../../../Components/SVGIcons";
import { checkIfAdmin, checkIfOrgAdmin } from '../../../../../../utils/rolePermissionUtils';
import { getOrgIdFromUrl } from '../../../../../../utils/urlProcessor';

class UploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hrisFilesList: [
        {
          label: "No HRIS",
          name: "No HRIS",
          value: "",
        },
      ],
      hrisCurrentPage: 0,
      hrisFileId: "",
      submitToUpload: false,
      hrisExisting: false,
      disableClose: false
    };
  }
  componentDidMount = () => {
    this.props.fetchHrisFile(this.getParamsForPagination(0));
  };

  getParamsForPagination = (from) => {
    return {
      organizationId: getOrgId(),
      pagination: { from: from, size: 12 },
      sortField: constants.sortHRIS.defaulSortKey,
      sortOrder: constants.sortHRIS.defaultSortOrder,
      hrisFileStatus: constants.COMPLETED,
    };
  };
  getDownloadButton = () => (
    <DownloadButtons
      commentApi={this.props.downloadCommentsApi}
      downloadComments={this.props.downloadSampleComments}
      downloadTopic={this.props.downloadDefaultTopics}
      topicApi={this.props.downloadDefaultTopicApi}
    />
  );
  topicFileUpload = () => {
    if (!this.props.isAdmin) return "";
    return (
      <SelectUpload
        index={1}
        onUpload={this.props.onUploadTopic}
        api={this.props.libraryFileApi}
        title={constants.libraryUploadText}
        disable={false}
        onSelect={this.props.onSelectTopic}
        options={constants.topicLibraryOptions}
        defaultSelect="Select Default Topics"
        showSelect={false}
        showUploadButton={true}
        clearTopicFile={this.props.clearTopicFile}
      />
    );
  };

  getHrisOption = () => {
    let hrisFilesList = this.state.hrisFilesList;
    if (this.props.fetchHrisFIleApi.data) {
      let hrisFilesListNext =
        this.props.fetchHrisFIleApi.data.response.hrisFilesList
          .filter((item) => item.bulkJobStatus === "Completed")
          .map((item) => {
            return {
              label: item.hrisFileName,
              name: item.hrisFileName,
              value: item.hrisFileId,
            };
          });

      hrisFilesListNext = [...hrisFilesList, ...hrisFilesListNext];
      return hrisFilesListNext;
    } else return hrisFilesList;
  };
  onScroll = (event) => {
    this.setState({
      hrisCurrentPage: event,
      hrisFilesList: [
        ...new Set([...this.state.hrisFilesList, ...this.getHrisOption()]),
      ],
    });
    this.props.fetchHrisFile(this.getParamsForPagination(event * 12));
  };

  onSelectHris = (value, event) => {
    if (value !== "") this.setState({ hrisFileId: value, hrisExisting: true });
    else this.setState({ hrisFileId: value, hrisExisting: false });
    this.props.onSelectHris(value, event);
  };

  selectHRIS = () => {
    let organizationId = getOrgIdFromUrl();
    if (!checkIfAdmin() && !checkIfOrgAdmin(organizationId)) return "";
    return (
      <SelectUpload
        index={1}
        hideUpload={true}
        onSelect={this.onSelectHris}
        title={constants.selectHris}
        api={this.props.hrisFileApi}
        onUpload={this.props.onUploadHris}
        hrisFileId={this.props.hrisFileId}
        dataLoading={this.props.fetchHrisFIleApi.loading}
        disable={false}
        disableUpload={true}
        options={this.getHrisOption()}
        defaultValue=""
        onScroll={this.onScroll}
        showSelect={true}
        showUploadButton={true}
      />
    );
  };

  batchIdField = () => (
    <div className="required-field">
      <TextInput
        htmlType="text"
        name="projectId"
        placeholder={constants.batchIdPlaceholder}
        value={this.props.currentBatchId}
        handleChange={this.props.onChangeBatchIdField}
        className="enter-batchid"
      />
    </div>
  );

  addSurveyBody = () => (
    <React.Fragment>
      <div className="input-form__body">
        <div className="input-form__body--section">
          <div className="drag-section">
            <Row gutter={16}>
              <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
                <ul className="analytics">
                  <li>
                    <label>
                      Choose comment file <AsterikLabel />
                    </label>
                    {this.dragOption()}
                  </li>
                  {/* <li>{this.commentFileUpload()}</li> */}

                  <li>
                    <label>Upload topics</label>
                    {this.topicFileUpload()}
                  </li>

                  <li>
                    <label>Select HRIS</label>
                    {this.props.fetchHrisFIleApi.data && this.selectHRIS()}
                  </li>

                  <li>
                    <label>
                      Enter project name <AsterikLabel />
                    </label>

                    {this.batchIdField()}
                  </li>
                  <li className="login-btn">{this.processButton()}</li>
                </ul>
              </Col>
              <Col xs={24} sm={24} md={16} lg={18} xl={18} xxl={18}>
                {this.getDownloadButton()}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
  disableClose = () => {
    this.setState({ disableClose: true })
  }
  dragOption = () => (
    <>
      <FileUpload
        handleFileUpload={this.props.onUploadComment}
        fileUpload={this.props.commentsFileApi}
      />
      {/*this.props.commentsFileApi.name*/}
      <Tooltip title={this.props.commentsFileApi.name}>
        <span className="inputFile__name" children={this.changeApi(this.props.commentsFileApi.name)} />
        {this.props.commentsFileApi.name && this.getCloseButton({
          disableClose: this.state.disableClose,
          clearTopicFile: this.props.clearFormData,

        })}
      </Tooltip>
    </>
  );
  changeApi = (api) => {
    return api && api.length > 20
      ? api.slice(0, 20) + "(...)." + api.split(".").pop()
      : api;
  };
  getCloseButton = (obj) => {
    return (
      !obj.disableClose ? <span className="upload-close-icon" onClick={() => obj.clearTopicFile()}>
        <SVGIcons type={'SVG-circle-cross'} />
      </span> : null)
  }
  addJob = (keyName, fileName, topicFileName) => {
    this.props.addCommentsJob(
      {
        keyName,
        topicFileName,
        commentsFileName: fileName,
        projectName: this.props.currentBatchId.trim(),
        projectId: this.props.currentBatchId.trim(),
        projectOrigin: constants.PROJECT_ORIGIN.commentAnalysis,
        organizationId: getOrgId(),
        projectStatus: "processing",
        hrisFileId: this.state.hrisFileId,
        hrisExisting: this.state.hrisExisting,
        userId: localStorage.getItem("userId"),
        topicLibraryExisting: !this.props.libraryFileApi.name ? false : true,
        topicsLibraryFile: this.props.libraryFileApi.name &&
          this.props.libraryFileApi.file &&
          this.props.libraryFileApi.file.name,
        ...helpers.getParamsForAddJob(),
      },
      (isSuccess) => {
        if (isSuccess) {
          this.props.fetchBatchIds();
          this.props.setProjectsCount();
          this.setState({ submitToUpload: false });
          this.props.clearHrisFileId();
        }
      }
    );
  };

  submitToUpload = (cb, fileName) => {
    this.setState({
      submitToUpload: true,
    });
    helpers.submitToUpload(this.props, {
      addJob: this.addJob,
      fileName: fileName,
      cb: this.addJob,
      failed: this.failedProcess,
    });
  };
  failedProcess = () => {
    this.setState({ submitToUpload: false });
    this.props.clearHrisFileId();
  };
  processButton() {
    const { processFilesApi } = this.props;
    return (
      <LoaderButton
        loader={processFilesApi.loading || this.state.submitToUpload}
        onClick={this.submitToUpload}
        disabled={!this.props.commentsFileApi.file || this.props.disableButton || this.state.submitToUpload}
        classList={constants.loaderButton.classes}
        loaderClass={constants.loaderButton.loaderClass}
        text={constants.buttonText}
      />
    );
  }

  addSurveyHeader = () => (
    <div className="input-form__header">
      <h3>
        Narrative Sense
        <sup>TM </sup>
        for Text Analytics
      </h3>
    </div>
  );

  render() {
    return (
      <div className="input-form">
        {this.addSurveyHeader()}
        {this.addSurveyBody()}
      </div>
    );
  }
}

export default UploadForm;
