import {
    SET_NORM_BENCHMARK_VALUE_SUCCESS,
    SET_NORM_BENCHMARK_VALUE_FAILED,
    SET_NORM_BENCHMARK_VALUE_STARTED
} from '../../../constants';
import { SET_NORM_BENCHMARK_CONFIGURATION } from '../../../../../config/constants';
import { withType } from '../../../../utils/actionOperations';
import { ApiBase } from '../../../../utils/apiBase';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const setBenchmarkDetails = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
        dispatch(withType(SET_NORM_BENCHMARK_VALUE_STARTED));
        apiBaseInstance.instance
            .post(SET_NORM_BENCHMARK_CONFIGURATION, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then((response) => {
                dispatch(
                    withType(SET_NORM_BENCHMARK_VALUE_SUCCESS, response.data && response.data.data && response.data.data)
                );
                cb && cb(true, response.data && response.data.data && response.data.data);
            })
            .catch((err) => {
                cb && cb(false);
                dispatch(
                    withType(SET_NORM_BENCHMARK_VALUE_FAILED, {
                        error: err,
                    })
                );
            });
    };
};