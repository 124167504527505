import React from 'react';
import { StoryBoardNewBarChart } from '../../../../../../../../Components/StoryBoardNewBarChart';

export class GraphSection extends React.Component {

    getBarChartData = (histogramData) => {
        const highestScore = this.props.highestScore;
        let multiplier = 1;
        if (highestScore && highestScore > 80) {
            multiplier = 100;
        } else {
            multiplier = 75;
        }
        let graphData = histogramData.map((item) => {
            const value = highestScore ? Math.round(item.favScore) / highestScore : 0;
            return {
                label: item.node,
                subLabel: `${item.count} Respondents`,
                value: `${Math.round(item.favScore)}`,
                width: Math.round(value * multiplier),
                percentage: item.percentage,
                threshold: item.count,
            };
        });
        graphData.sort((first, second) => second.width - first.width)
        return graphData;
    };

    getSortedGraph = (data) => {
        let threshold = this.props.threshold && this.props.threshold.filterThreshold ? this.props.threshold.filterThreshold : 5
        const graphData = data.filter(item => item.key !== 'Manager' && item.key !== 'Average Score')
        graphData.forEach(item => {
            let showData = []
            let insufficentData = []
            item.value.forEach(element => {
                if (element.count >= threshold) {
                    showData.push(element)
                } else {
                    insufficentData.push(element)
                }
            })
            if (showData.length > 1) {
                showData = showData.sort((a, b) => Math.round(b.favScore) - Math.round(a.favScore))
            }
            item.value = [...showData, ...insufficentData]
        })
        return graphData
    }

    render() {
        return (
            <>
                {this.props.data &&
                    this.props.data.length &&
                    this.getSortedGraph(this.props.data)
                        .map((item) => (
                            <div className='div-barchart' key={item.key}>
                                {item.value.length && (
                                    <>
                                        <h2>
                                            <div>
                                                {item.key}
                                            </div>
                                        </h2>
                                        <div>
                                            <StoryBoardNewBarChart
                                                data={this.getBarChartData(item.value)}
                                                showPercentage={true}
                                                limit={100}
                                                graphCursorClass={'progress-no-cursor'}
                                                threshold={this.props.threshold && this.props.threshold.filterThreshold}
                                                hideBackground={true}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
            </>
        );
    }
}
