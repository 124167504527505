import { deepCopy } from "./deepCopy";

export const checkConnectedProjects = (data) => {
    if (data && data.connectedProjects) {
    return data.connectedProjects;
  } else {
    return [];
  }
};

export const checkTrendProjects = (data) => {
  if (data && data.connectedProjects) {
    return true;
  } else {
    return false;
  }
  };

export const checkTrendMappingExist = ({ questionSet, isAllMappedChecking, isQuestionMappedChecking, isTopicMappedChecking, isFileUpload }) => {
  let isMapped = false
  let questionSetCopy = deepCopy(questionSet)
  let mappingQuestionSet = isFileUpload ? questionSetCopy[0].questions : questionSetCopy
  if (questionSet) {
    for (let question of mappingQuestionSet) {
      if (isAllMappedChecking) {
        if ((question.mappingQuestions && question.mappingQuestions.length > 0) || (question.mappingTopics && question.mappingTopics.length > 0)) {
          isMapped = true
          break
        }
      }
      else if (isQuestionMappedChecking && question.mappingQuestions && question.mappingQuestions.length > 0) {
        isMapped = true
        break
      }
      else if (isTopicMappedChecking && question.mappingTopics && question.mappingTopics.length > 0) {
        isMapped = true
        break
      }
    }
  }

  return isMapped
}  