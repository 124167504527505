
import {
    FETCH_FEEDBACK_PROJECT_DETAILS_START,
    FETCH_FEEDBACK_PROJECT_DETAILS_SUCCESS,
    FETCH_FEEDBACK_PROJECT_DETAILS_FAIL,
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { LIST_SURVEY_URL } from "../../../../../config/constants";

export const fetchFeedbackProjectDetails = (payload, cb) => {
    const axios = new ApiBase().instance;
    return (dispatch) => {
        dispatch({ type: FETCH_FEEDBACK_PROJECT_DETAILS_START });
        axios.post(LIST_SURVEY_URL, { ...payload })
            .then((res) => {
                fetchSurveysSuccess(dispatch, res, payload)
                cb && cb(res)
            })
            .catch((err) => fetchSurveysFailed(dispatch, err));
    };
};

function fetchSurveysSuccess(dispatch, res, payload) {
    return dispatch({
        type: FETCH_FEEDBACK_PROJECT_DETAILS_SUCCESS,
        data: res.data.data,
        searchText: payload.searchText
    });
}

function fetchSurveysFailed(dispatch, err) {
    return dispatch({
        type: FETCH_FEEDBACK_PROJECT_DETAILS_FAIL,
        error: err.message,
    });
}

