import React from "react";
import { Dropdown, Nav } from "react-bootstrap";
import Container from "../../Components/Container";
import cbLogo from "../../../public/img/svg-logo.svg";
import { connect } from "react-redux";
import SVGIcons from "../../Components/SVGIcons";
import {
  ClearLocalStorage,
  GetLocalStorage,
  getOrgId,
} from "../../utils/localStorgeOperations";
import "./index.scss";
import constants from "./constants";
import {
  getUsersPath,
  getOrganizationPath,
  getSettingsPagePath,
  getNewSurveyPagePath,
} from "../../utils/getHomePath";
import { UserDetails } from "./userDetails";
import PageGraphics from "../../../public/img/left-hive.svg";
import { setProjectsCount } from "../../redux/actions/MyProjectsAction";
import { getOrganizationProfile } from "../../redux/actions";
import { Spin } from "antd";
import { encryptJson, getDataFromUrlToken } from "../../utils/urlDataStore";
import { URL_DATA } from '../../../config/urlDataConstants'
import { getOrgIdFromUrl } from "../../utils/urlProcessor";
import { checkAccessStatus, checkIfAdmin, checkMoreThanSingleOrganization } from '../../utils/rolePermissionUtils';
import { PEOPLE_PERMISSIONS } from '../../../config/userPermissionConstants'
import qs from "query-string";
import { nlpSetURLParams } from "../../utils/nlpURLUtils";
import { checkPath } from "./helper";


class InnerHeader extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    // this.props.setProjectsCount();
    this.state = {
      orgId: null,
      orgName: null,
      count: null,
      firstName: GetLocalStorage(constants.local.firstName),
      lastName: GetLocalStorage(constants.local.lastName),
      isHomePage: false,
      isCheckPageRoute: false,
      isOrgSelected: false,
      org_Id: getOrgIdFromUrl() || this.getOrgFromToken()
    };
  }

  getOrgFromToken = () => {
    if (getDataFromUrlToken(URL_DATA.ORGANIZATION)) {
      return getDataFromUrlToken(URL_DATA.ORGANIZATION).organizationId
    }
  }

  componentDidMount() {
    let orgId = this.getOrgIdFromUrl(window.location.pathname);
    // if (orgId && !this.getOrgDetails()) {
    if (orgId) {
      this.props.getOrganizationProfile({ organizationId: orgId });
    }
    // }
    if (!orgId) {
      this.setState({
        orgId: null,
        orgName: null,
        count: null,
      });
    }
  }

  getOrgIdFromUrl(pathname) {
    let orgId = undefined;
    orgId = getOrgIdFromUrl()
    return orgId
  }

  componentDidUpdate() {
    if (!this.state.isCheckPageRoute) {
      this.setState({ isHomePage: this.checkIfHomePage() });
      this.setState({ isOrgSelected: this.checkIfOrgSelected() });
      this.setState({ isCheckPageRoute: true });
    }
    if (!this.props.orgDetailsApi.isDataFetched) {
      if (!this.props.orgDetailsApi.isProcessing) {
        let orgId = this.getOrgIdFromUrl(window.location.pathname);
        if (orgId) {
          this.props.getOrganizationProfile({ organizationId: orgId });
        }
      }
    }
    if (!this.props.orgDetailsApi.error) {
      if (this.props.orgDetailsApi.data) {
        let orgData = this.props.orgDetailsApi.data;
        if (
          orgData.organizationId &&
          orgData.organizationId !== this.state.orgId
        ) {
          this.setState({
            orgId: orgData.organizationId,
            orgName: orgData.name,
            count: orgData.projectsCount,
          });
          localStorage.setItem("organizationId", orgData.organizationId);
          localStorage.setItem("organizationName", orgData.name);
          localStorage.setItem(
            "organizationProjectCount",
            orgData.projectsCount
          );
        }
      }
    }
    const { local } = constants;
    let firstName = GetLocalStorage(local.firstName);
    let lastName = GetLocalStorage(local.lastName);
    if (
      this.state.firstName !== firstName ||
      this.state.lastName !== lastName
    ) {
      this.setState({ firstName: firstName, lastName: lastName });
    }
  }

  handleLogout() {
    ClearLocalStorage();
    window.location.href = constants.logoutUrl;
  }
  handleMenuClick(route = constants.emptySpace) {
    this.props.history.push(route);
  }

  userName() {
    return (
      <Dropdown.Item key={1} as="li">
        <Nav.Link>
          <span className="menu-icon">
            <SVGIcons type={"SVG-user-settings"} />
          </span>
          <span children={this.getUserName()} />
        </Nav.Link>
      </Dropdown.Item>
    );
  }
  userRole() {
    return (
      <Dropdown.Item key={2} onClick={this.goToSettingPage} as="li">
        <Nav.Link>
          <span className="menu-icon">
            <SVGIcons type={"SVG-user-settings"} />
          </span>
          <span children={constants.settings} />
        </Nav.Link>
      </Dropdown.Item>
    );
  }

  getUserName() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    lastName = lastName ? lastName : "";
    return firstName + constants.emptySpace + lastName;
  }

  getEmail() {
    const { local } = constants;
    let email = GetLocalStorage(local.email);
    return email;
  }

  getOrganization() {
    const { local } = constants;
    let organizationName = GetLocalStorage(local.organizationName);
    return organizationName;
  }

  getOrganizationProjectCount() {
    return this.props.projectsCount;
  }

  getOrgDetails = () => {
    const orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
    return orgDetails
  }

  userDetails = () => (
    <UserDetails
      userName={this.getUserName()}
      email={this.getEmail()}
      dropDownItems={[this.userRole(), this.logOutItem()]}
    />
  );

  logOutItem() {
    return (
      <Dropdown.Item key={3} onClick={this.handleLogout} as="li">
        <Nav.Link>
          <span className="menu-icon">
            <SVGIcons type={"SVG-logout"} />
          </span>
          <span>{constants.logout}</span>
        </Nav.Link>
      </Dropdown.Item>
    );
  }

  getMenuItems() {
    const userRole = ''//doubt
    return constants.menuItems.filter((item) =>
      item.visibleTo.includes(userRole)
    );
  }

  menuItems = () => {
    return this.getMenuItems().map((item) => (
      <li key={item.name} onClick={() => this.handleMenuClick(item.path)}>
        <a
          href={false}
          className={this.isActive(item) ? constants.active : ""}
          children={item.label}
        />
      </li>
    ));
  };

  isActive = ({ startsWith }) => {
    const pathname = this.props.history.location.pathname.toLowerCase();
    if (pathname.startsWith(startsWith)) return true;
  };
  removeHrisRelatedLocalstorage = () => {
    localStorage.removeItem("nlpHrisId");
    localStorage.removeItem("nlpHrisExisting");
    localStorage.removeItem("directReportees");
    localStorage.removeItem("hrisFileId");
  };

  checkIfGotoProjectListOrNot = () => {
    // this.removeHrisRelatedLocalstorage();
    // // this.props.history.push(getHomePath(getOrgId()));
    // window.location.href = "https://culturebie.com"
    if (this.checkIfOrgSelected()) {
      this.goToProjectLists()
    }
  };

  goToProjectLists = () => {
    if (!window.location.pathname.includes(constants.surveyPath)) {
      this.removeHrisRelatedLocalstorage();
      let orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION)
      const encryptedOrgDetails = encryptJson({ orgDetails });
      this.props.history.push({
        pathname: getNewSurveyPagePath(orgDetails.organizationId),
        search: nlpSetURLParams({ params: encryptedOrgDetails }),
      });
    }

  };
  goToUsersManage = () => {
    let orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION)
    const encryptedOrgDetails = encryptJson({ orgDetails });
    this.removeHrisRelatedLocalstorage();
    this.props.history.push({
      pathname: getUsersPath(this.getOrgIdFromUrl(window.location.pathname) || getOrgId()),
      search: `params=${encodeURIComponent(encryptedOrgDetails)}`
    });
  };

  goToOrganizationLists = () => {
    if (checkIfAdmin() || checkMoreThanSingleOrganization()) {
      this.removeHrisRelatedLocalstorage();
      this.props.history.push({ pathname: getOrganizationPath(), search: '' });
      this.setState({ isCheckPageRoute: false });
    }
  };

  goToSettingPage = () => {
    this.removeHrisRelatedLocalstorage();
    const queryParams = qs.parseUrl(window.location.href).query;
    this.props.history.push({
      pathname: getSettingsPagePath(),
      search: queryParams && queryParams.params ?
        `params=${encodeURIComponent(queryParams.params)}` : undefined
    });
  };

  getUnFoldedMenu = () => {
    return <ul className="unfolded-menu">{this.menuItems()}</ul>;
  };

  checkIfSurveyPage = () => {
    let currentPage = window.location.pathname.split("/").pop();
    return currentPage === "survey";
  };

  checkIfHomePage = () => {
    if (window.location.pathname === constants.homePagePath) {
      localStorage.removeItem("organizationId");
      localStorage.removeItem("organizationName");
      localStorage.removeItem("organizationProjectCount");
      return true;
    } else return false;
  };
  checkIfOrgSelected = () => {
    if (getOrgId()) {
      return true;
    } else {
      return false;
    }
  };

  checkIfPreviewPage = () => {
    if (window.location.pathname.includes(constants.previewProject))
      return true;
    else return false;
  };

  showOrgDetails = () => {
    const orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
    if (orgDetails && orgDetails.name) {
      return true;
    }
    else return false
  }

  getLeftHeadermenu = () =>
    <ul className="page-details">
      <li>
        <a href={false} onClick={this.goToProjectLists} className={`${checkPath(constants.surveyPath)? "global-h3 selected_color":"global-h3"}`}>
          Projects
        </a>
      </li>
      <li>
        {checkAccessStatus(this.state.org_Id, PEOPLE_PERMISSIONS.VIEW) ?
          <a href={false} onClick={this.goToUsersManage} className={`${checkPath(constants.peoplePath)? "global-h3 selected_color":"global-h3"}`}>
            People
          </a> : null}
      </li>
    </ul>
  renderHeaderChildren = () => (
    <>
      <div className="header-block-menu">
        <div className="brand-icon ">
          <a href={false} onClick={this.checkIfGotoProjectListOrNot}>
            <img src={cbLogo} alt={constants.logo} />
          </a>
        </div>
        {this.props.noAccessPage ? "" :
          !this.state.isHomePage && this.getLeftHeadermenu()}
      </div>

      <div className="slot-block" children={this.props.slot} />
      <ul className="top-menu">
        {this.state.isHomePage || this.props.noAccessPage
          ? ""
          : this.showOrgDetails() && (
            <li>
              <Spin spinning={this.props.orgDetailsApi.isProcessing}>
                <a href={false} onClick={this.goToOrganizationLists}>
                  <div className="profile-img">
                    <SVGIcons type={"SVG-organizations"} />
                  </div>
                  <div className="profile-info">
                    <h3>{this.getOrgDetails().name}</h3>
                    <span>
                      {this.getOrgDetails().projectsCount}{" "}
                      {(this.getOrgDetails().projectsCount > 1) ? 'projects' : 'project'}
                    </span>
                  </div>
                </a>
              </Spin>
            </li>
          )}
        <li>
          {this.getUnFoldedMenu()}
          {this.userDetails()}
        </li>
      </ul>
    </>
  );
  render() {
    return (
      <>
        <div className="page-graphics">
          <Container>
            <div className="graphics-left">
              <img src={PageGraphics} alt="Hive" />
            </div>
            <div className="graphics-right">
              <img src={PageGraphics} alt="Hive" />
            </div>
          </Container>
        </div>
        <header
          className={
            "inner-header " + (this.checkIfPreviewPage() ? "survey-header" : "")
          }
        >
          <Container>
            <div className="header-block">{this.renderHeaderChildren()}</div>
          </Container>
        </header>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    projectsCount: state.MyProjectsReducer.projectsCount,
    orgDetailsApi: state.organizationPageReducer.getOrganizationProfileApi,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setProjectsCount: () => dispatch(setProjectsCount()),
    getOrganizationProfile: (params) =>
      dispatch(getOrganizationProfile(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InnerHeader);
