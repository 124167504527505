import { fetchBatchIds } from './getBatchIds';
import { notification } from 'antd';
import * as actionTypes from '../../constants';
import Axios from 'axios';
import constants from './constants';
import { PROCESS_FILES_URL } from '../../../../config/constants';
import {
    GetLocalStorage,
    getOrgId,
} from "../../../utils/localStorgeOperations";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';
import { getUserRole } from '../../../utils/rolePermissionUtils';

export function processFiles(payload,cb){
    return dispatch=>{
        dispatch({type:actionTypes.PROCESS_FILES_START});
        Axios.post(PROCESS_FILES_URL, { ...fileProcessBody(payload), ...constants.formDataHeader, ...getProjectIdAndOrgId(payload) })
            .then(res=>processFilesSuccess({dispatch,res,cb}))
            .catch(err=>processFilesFail(dispatch,err));
    };
}

function processFilesSuccess({dispatch,res,index,file,cb}){
    notification.success({message: constants.formProcessSuccess});
    cb&&cb();
    dispatch({
        type:actionTypes.PROCESS_FILES_SUCCESS,
        data:res.data,
        index:index,
    });
    return dispatch(fetchBatchIds());
}

function processFilesFail(dispatch,err){
    notification.error({message: constants.formProcessFail});
    dispatch({
        type:actionTypes.PROCESS_FILES_FAIL
    });
}

export function reProcessFiles(payload){
    return dispatch=>{
        dispatch({type:actionTypes.REPROCESS_FILES_START});
        Axios.post(PROCESS_FILES_URL, { ...fileProcessBody(payload), ...constants.formDataHeader, ...getProjectIdAndOrgId({...fileProcessBody(payload), ...constants.formDataHeader}) })
            .then(res=>reProcessFilesSuccess({dispatch,res}))
            .catch(err=>reProcessFilesFail(dispatch,err));
    };
}

function reProcessFilesSuccess({dispatch,res,index,file}){
    notification.success({message: constants.formReprocessSuccess});
    dispatch({
        type:actionTypes.REPROCESS_FILES_SUCCESS,
        data:res.data,
        index:index,
    });
    return dispatch(fetchBatchIds());
}

function reProcessFilesFail(dispatch,err){
    notification.error({message: constants.formProcessFail});
    dispatch({
        type:actionTypes.REPROCESS_FILES_FAIL
    });
}
function fileProcessBody (payload) {
    let reqBody = {
        userEmail: GetLocalStorage("username"),
        ...payload,
        projectStatus:constants.processing,
        isProcessed:constants.false,
        userRole: getUserRole(getOrgId()),
        userId: GetLocalStorage("userId"),
        firstName: GetLocalStorage("firstName"),
        lastName: GetLocalStorage("lastName"),
        organizationId: getOrgId(),
    }
    return reqBody;
}