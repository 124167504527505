import {
  VERIFY_FORM_CHANGE,
  VERIFY_PROCESS_STARTED,
  VERIFY_PROCESS_SUCCESS,
  VERIFY_PROCESS_FAILED,
  CLEAR_SESSION_EXPIRED,
} from "../../constants";
import { fetchPayload, update } from "../../../utils/reducerOperations";
import { Default } from "./InitialState";
import {
  verifyFormChange,
  verifyProcessStarted,
  verifyProcessSuccess,
  verifyProcessFailed,
  clearSessionExpired,
} from "./Helpers";

const verifyPageReducer = (state = Default(), action) => {
  let verifyPayload = fetchPayload(action);
  switch (action.type) {
    case VERIFY_FORM_CHANGE:
      return update(state, verifyFormChange(state, verifyPayload));
    case VERIFY_PROCESS_STARTED:
      return update(state, verifyProcessStarted(state, verifyPayload));
    default:
      return verifySuccessFail(state, action);
  }
};
const verifySuccessFail = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case VERIFY_PROCESS_SUCCESS:
      return update(state, verifyProcessSuccess(state, payload));
    case VERIFY_PROCESS_FAILED:
      return update(state, verifyProcessFailed(state, payload));
    case CLEAR_SESSION_EXPIRED:
      return clearSessionExpired(state);
    default:
      return { ...state };
  }
};
export default verifyPageReducer;
//Todo - Confirm password mismatch does not works
