import {
  FETCH_USERS_ADMIN_LIST_STARTED,
  FETCH_USERS_ADMIN_LIST_SUCCESS,
  FETCH_USERS_ADMIN_LIST_FAILED,
  USERS_HRIS_FILE_CHANGE,
} from "../../constants";
import { FETCH_USERS_URL } from "../../../../config/constants";
import { ApiBase } from "../../../utils/apiBase";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchUsersForAdmin = (params) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(fetchUsersForAdminStart());
    apiBaseInstance.instance
      .post(FETCH_USERS_URL, { ...makeUsersParams(params), ...getProjectIdAndOrgId(makeUsersParams(params)) })
      .then((res) => fetchUsersForAdminSuccess(dispatch, res, params.type))
      .catch((err) => fetchUsersForAdminFailure(dispatch, err, params.type));
  };
};

export const fetchUsersForAdminStart = () => {
  return {
    type: FETCH_USERS_ADMIN_LIST_STARTED,
  };
};
function fetchUsersForAdminSuccess(dispatch, res, type) {
  return dispatch({
    type: FETCH_USERS_ADMIN_LIST_SUCCESS,
    payload: res.data,
  });
}
export function fetchUsersForAdminFailure(dispatch, err, type) {
  return dispatch({
    type: FETCH_USERS_ADMIN_LIST_FAILED,
    payload: err,
  });
}

export const hrisfileChange = (value, name) => {
  return {
    type: USERS_HRIS_FILE_CHANGE,
    payload: { value: value, name: name },
  };
};

const makeUsersParams = (params) => {
  return {
    userType: "orgManager",
    ...params
  };
};
