import { deepCopy } from "../../../../../../utils/deepCopy"
import { generateStaticQuestionId } from "../../../../../../utils/goalsUtils"
import { getHTMLRemovedQuestion } from "../helpers"

export const ratingSectionChangeQuestionAndQuestionLabel = (state, payload) => {
    let updatedGoals = getRatingQuestionQuestionANdQuestionLabelChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getRatingQuestionQuestionANdQuestionLabelChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.questionLabel) {
                element.questions[0].questionLabel = payload.value
            } else {
                element.questions[0].question = getHTMLRemovedQuestion(payload.value)
                element.questions[0].HTMLQuestion = payload.value
                element.questions[0].staticQuestionId = generateStaticQuestionId(getHTMLRemovedQuestion(payload.value))
            }
        }
    });
    return goalsCopy
}