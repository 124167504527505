import React from "react";
import { Skeleton } from "antd";

const SingleTreeLoader = () => <Skeleton small active avatar></Skeleton>;

const TreeLoader = (props) =>
  Array(5)
    .fill(null)
    .map((_, index) => <SingleTreeLoader key={index} />);

export default TreeLoader;
