import {
    makeKey,
    update,
    getSuccessResponse,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
export const fetchExecutiveOverviewSuccess = (state, payload) => {
    return makeKey(
        'fetchExecutiveOverviewApi',
        update(state.fetchExecutiveOverviewApi,
            getSuccessResponse(payload))
    );
};

export const fetchExecutiveOverviewStarted=(state, payload)=>{
    return makeKey(
        'fetchExecutiveOverviewApi',
        update(state.fetchExecutiveOverviewApi, {
            isProcessing: true
        })
    );
};

export const fetchExecutiveOverviewFailed=(state, payload)=>{
    let response = makeKey(
        'fetchExecutiveOverviewApi',
        update(state.fetchExecutiveOverviewApi, {
            ...(payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload))
        })
    );
    return response;
};

export const updateCarouselIndex=(state,payload)=>{
    return{
        ...state,
        activeItemIndex:payload
    };
};