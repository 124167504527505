export const fetchTreeDataStart = (state, payload) => {
  return {
    ...state,
    treeDataApi: { isProcessing: true, error: false, reason: '', treeData: [] },
  };
};

export const fetchTreeDataSuccess = (state, payload) => {
  let data = [];
  if (payload.data) {
    try {
      data.push(JSON.parse(payload.data));
    } catch (error) {}
  }
  return {
    ...state,
    treeDataApi: {
      isProcessing: false,
      error: false,
      reason: '',
      treeData: data,
    },
  };
};

export const fetchTreeDataFail = (state, payload) => {
  return {
    ...state,
    treeDataApi: {
      isProcessing: false,
      error: true,
      reason: payload.error,
      treeData: [],
    },
  };
};
