import React from "react";
import constants from "./constants";
import { CustomTable } from "../../../../../../../../Components/CustomTable";

export default class FollowUpQuestionsTableView extends React.Component {
  fetchTableColums = (columns) => {
    let filterData = columns
    if (this.props.projectDetails && this.props.projectDetails.projectType && this.props.projectDetails.projectType === "Very_Simple_Survey") {
      filterData = filterData.filter((item) => item.dataIndex !== "dimension");
    }
    if (!this.props.trendData) {
      filterData = filterData.filter((item) => item.dataIndex !== "trend");
      return filterData;
    } else {
      return columns;
    }
  };

  checkBenchmarkEmpty = () => {
    let flag = false
    for (let item = 0; item < this.props.data.length; item++) {
      if (!this.props.data[item].benchmarkScore) {
        flag = true
      }
      else {
        return false
      }
    }
    return flag
  }

  checkTrendBenchmarkEmpty = () => {
    let flag = false
    for (let item = 0; item < this.props.data.length; item++) {
      if (!this.props.data[item].benchMarkTwoScore) {
        flag = true
      }
      else {
        return false
      }
    }
    return flag
  }

  tableProps = () => {
    return {
      showBenchMark: this.props.projectDetails.projectOringin !== "Comment Analysis",
      allBenchMarkScoreEmpty: this.checkBenchmarkEmpty(),
      allTrendBenchMarkEmpty: this.checkTrendBenchmarkEmpty(),
      benchmarkTooltip: this.props.threshold.benchmarkTooltip ? this.props.threshold.benchmarkTooltip : "",
      threshold: this.props.threshold.filterThreshold
    }
  }

  render() {
    return (
      <>
        <div className="outliers-block">
          <div className="outliers-block__head">
            <h2>Follow up questions</h2>
          </div>
          <CustomTable
            className="no-space"
            dataSource={this.props.data || []}
            columns={this.fetchTableColums(
              constants.tableColumnPositiveOutLiers(this.tableProps())
            )}
            pagination={false}
            loading={this.props.loading}
          />
        </div>
      </>
    );
  }
}
