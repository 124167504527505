export const generateReportMailStart=(state)=>{
    return{
        ...state,
        generateReportMailApi:{
            data:null,
            error:false,
            message:null,
            isProcessing:true
        }
    };
};


export const generateReportMailSuccess=(state,payload)=>{
    return{
        ...state,
        generateReportMailApi:{
            data:payload.payload.data.data.data,
            error:false,
            message:payload.payload.data.data.message,
            isProcessing:false
        }
    };
};

export const generateReportMailFailed=(state,payload)=>{
    return{
        ...state,
        generateReportMailApi:{
            data:payload.payload,
            error:true,
            message:payload,
            isProcessing:false
        }
    };
};