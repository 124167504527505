import { deepCopy } from "../../../../../../utils/deepCopy"

export const handleAddImage = (state, payload) => {
    let updatedQuestionSet = generateBlockWithImage(state.goals, payload)
    return {
        ...state,
        goals: updatedQuestionSet
    }
}

const generateBlockWithImage = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach((block) => {
        if (block.blockId === payload.blockId) {
            block.blockImage = payload.blockImage
            block.blockLayout = payload.blockLayout
        }

    })
    return goalsCopy
}