import { notification } from "antd";
import { deepCopy } from "../../../../utils/deepCopy";

export const markQuestionAsCustomTopic = (state, payload) => {
    const questionTopicLibraryMapping = deepCopy(state.questionTopicLibraryMapping);
    questionTopicLibraryMapping.forEach(item => {
        if (item.questionId === payload.questionId) {
            item.isCustom = payload.value
        }
    })
    return {
        ...state,
        questionTopicLibraryMapping: questionTopicLibraryMapping
    }
}

export const setFileName = (state, payload) => {
    const questionTopicLibraryMapping = deepCopy(state.questionTopicLibraryMapping);
    questionTopicLibraryMapping.forEach(item => {
        if (item.questionId === payload.questionId) {
            item.fileName = payload.fileName
        }
    })
    return {
        ...state,
        questionTopicLibraryMapping: questionTopicLibraryMapping
    }

}

export const uploadQuestionTopicStart = (state, payload) => {
    return {
        ...state,
        uploadQuestionTopicApi: {
            isProcessing: payload.questionId,
            error: false
        }
    }

}

export const uploadQuestionTopicSuccess = (state, payload) => {
    const questionTopicLibraryMapping = deepCopy(state.questionTopicLibraryMapping);
    questionTopicLibraryMapping.forEach(item => {
        if (item.questionId === payload.questionId) {
            item.fileName = payload.fileName;
            item.keyName = payload.keyName;
        }
    })
    return {
        ...state,
        uploadQuestionTopicApi: {
            isProcessing: false,
            error: false
        },
        questionTopicLibraryMapping: questionTopicLibraryMapping
    }

}

export const uploadQuestionTopicFailed = (state, payload) => {
    notification.error({ message: 'Failed to upload topic' });
    return {
        ...state,
        uploadQuestionTopicApi: {
            isProcessing: false,
            error: true
        }
    }

}