import {
    makeKey,
    update,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
export function handleLoginProcessFailed(state, payload) {
    if (payload.response && payload.response.data) {
        return makeKey(
            'loginProcessApi',
            update(
                state.loginProcessApi,
                handleApiResponseError(payload.response.data)
            )
        );
    } else {
        return makeKey(
            'loginProcessApi',
            update(
                state.loginProcessApi,
                handleNetworkError(payload)
            )
        );
    }
}
