import {makeKey,update} from '../../../../utils/reducerOperations';
export function fileUploadSelected(state, payload) {
    return makeKey(
        'inputBoxConfiguration',
        setSelectedFile(state.inputBoxConfiguration, payload)
    );
}
function setSelectedFile(inputBoxConfiguration, payload) {
    return inputBoxConfiguration.map(conf =>
        update(conf, {
            fileName: payload.files[0].name,
            file: payload.files[0],
            isValid:true
        })
    );
}