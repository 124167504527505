import { deepCopy } from "../../../../../utils/deepCopy"
import { toast } from "../../../../../utils/messageUtils"
import { v4 as uuidv4 } from "uuid";

export const addStatement = (state, payload) => {
    let goals = addStatementToGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const addStatementToGoals = (state, payload) => {
    let defaultGoalsCopy = deepCopy(state.defaultGoals)
    let goalsCopy = deepCopy(state.goals)
    let selectedStatement = []
    defaultGoalsCopy.forEach(item => {
        if (item.goalId === payload.goalId) {
            selectedStatement = item.questions[0].matrixQuestions.filter(
                question => question.staticQuestionId === payload.staticQuestionId
            )
        }
    })
    selectedStatement[0].isSelected = true
    goalsCopy.forEach(goal => {
        if (goal.goalId === payload.goalId) {
            toast.success(`Question successfully added to ${goal.dimension}`)
            selectedStatement[0].value = uuidv4()
            goal.questions[0].matrixQuestions.splice(goal.questions[0].matrixQuestions.length, 0, selectedStatement[0])
        }
    })
    return goalsCopy
}

export const addCutomTopicStatement = (state, payload) => {
    let goals = addCustomStatementToGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const addCustomStatementToGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(goal => {
        if (goal.goalId === payload.goalId) {
            goal.questions[0].matrixQuestions.splice(goal.questions[0].matrixQuestions.length, 0,payload.data)
        }
    })
    return goalsCopy
}


