import {
  FETCH_STORYBOARD_FILTER_START,
  FETCH_STORYBOARD_FILTER_SUCCESS,
  FETCH_STORYBOARD_FILTER_FAIL,
} from '../../constants';
import { DASHBOARD_FILTER_URL } from '../../../../config/constants';
import { nlpGetURLParams } from '../../../utils/nlpURLUtils';
import { ApiBase } from '../../../utils/apiBase';
import { getAllDataFromUrlToken, getDataFromUrlToken } from '../../../utils/urlDataStore';
import { getURLFilterAndByScore } from '../../../utils/byScoreFilterUtils';
import { URL_DATA } from '../../../../config/urlDataConstants';
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchStoryboardFilters = ({
  currentTab,
  minCommentsCount = 1,
  selectedColumns: activeFilters = [],
  setFilter,
  dimensions,
  topics,
  selectedQuestionId,
}) => {

  let urlParams = getAllDataFromUrlToken();
  const urlFilter = getDataFromUrlToken(URL_DATA.STORYBOARD_FILTER) || {};
  let filterData = getURLFilterAndByScore(urlFilter)
  let params = {
    ...urlParams.storyboardTreeData,
    ...urlParams.orgDetails,
    ...urlParams.projectDetails,
    filterCountLimit: minCommentsCount,
    ...filterData,
    dimensions: dimensions ? dimensions : undefined,
    topics: topics ? topics : undefined,
    selectedQuestionId
  };
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch({ type: FETCH_STORYBOARD_FILTER_START });
    apiBaseInstance.instance
      .post(DASHBOARD_FILTER_URL, { ...params, ...getProjectIdAndOrgId(params), currentTab })
      .then((res) => fetchFilterSuccess(dispatch, { res, activeFilters }))
      .catch((err) => fetchFilterFail(dispatch, err));
  };
};

function fetchFilterSuccess(dispatch, { res, activeFilters }) {
  const data = res && res.data && res.data.data && res.data.data.filters;
  return dispatch({
    type: FETCH_STORYBOARD_FILTER_SUCCESS,
    payload: { data: formatResponse(data, activeFilters) },
  });
}

function fetchFilterFail(dispatch, err) {
  return dispatch({
    type: FETCH_STORYBOARD_FILTER_FAIL,
    payload: { error: err.message },
  });
}

function formatResponse(data = {}, activeFilter) {
  const { filter } = nlpGetURLParams();
  const filters = [],
    activeFilters = [];
  let question = []
  let options = {}
  Object.keys(data).forEach((label) => {
    let datas = [];
    let subOption = []
    let topicScoreLevelData = []
    if (label === "byScore") {
      for (const [key, value] of Object.entries(data[label])) {
        subOption.push(getSubOption(value, key))
        topicScoreLevelData.push(getTopicFavUnFavNeuMenuData(key, data))
        datas.push(`${key}:${value.count}`)
      }
    }
    else if (label === "multipleSelect") {
      for (const [key, value] of Object.entries(data[label])) {
        let questionObj = {
          text: `${key}:${value.count}`,
          questionId: value.questionId
        }
        question.push(questionObj)
        options[key] = value.options.map((item) => (
          `${item.label}:${item.count}`
        ))

      }
    }

    else {
      for (const [key, value] of Object.entries(data[label])) {
        datas.push(`${key}:${value}`);
      }
    }
    let rec
    if (label === "multipleSelect") {
      rec = {
        label, question, options
      }
    }
    else {
      rec = {
      label,
      selected: false,
      options: (datas || []).map((item) => ({
        value: item,
        selected: (filter[label] || []).includes(item.split(':')[0]),
      })),
      subOption,
      topicScoreLevelData
    };
    }
    if (activeFilter.includes(label)) activeFilters.push(rec);
    else filters.push(rec);
  });
  return { filters, activeFilters };
}

const getSubOption = (value, key) => {
  let datas = []
  value.questions.forEach(item => {
    datas.push({ text: `${item.question}:${item.count}`, questionId: item.questionId })
  })
  return { [key]: datas }
}

const getTopicFavUnFavNeuMenuData = (topic, data) => {
  let topicData = data.byScore[topic]
  return {
    [topic]: [
      ` Favorable: ${topicData.favourable}`,
      ` Unfavorable: ${topicData.unFavourable}`,
      `Neutral: ${topicData.neutral}`
    ]
  }
}
