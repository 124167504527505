import React from "react";
import { StoryBoardNewBarChart } from "../../../../../../../../Components/StoryBoardNewBarChart";

export function StoryboardParticipationOverAllData(props) {
  return (
    <div className="div-barchart">
      <h2> Overall </h2>
      {<StoryBoardNewBarChart graphCursorClass={'progress-no-cursor'} data={getGraphData(props)} hideBackground={true}/>}
    </div>
  );
}
const getGraphData = (props) => {
  let multiplier=1
  if(props.getHighestPercentage>80)
  {
    multiplier=100
  }else{
    multiplier=75
  }
  let graphData = [];
  let data = props.data;
  if (data) {
    graphData = [
      {
        label: "Everyone",
        subLabel: `${data.completedParticipants} of ${data.totalParticipants} completed`,
        value: `${data.participationRate}%`,
        width: Math.round(
          (data.participationRate / props.getHighestPercentage) * multiplier
        ),
        threshold: 10000000000,
      },
    ];
  }
  return graphData;
};
