import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../../utils/goalsUtils";

export const TEMPLATE_DATA = (type, question) => {
    let data = {
        descriptive: {
            "type": "descriptive",
            "blockId": uuidv4(),
            "dimension": "Descriptive text",
            "questions": [
                {
                    "question": question ? question : "Type descriptive text",
                    "questionLabel": "Descriptive text",
                    "isDemographic": false,
                    "isSelected": true,
                    "isCustom": true,
                    "questionDimension": "Type decriptive" + uuidv4(),
                    "type": "Descriptive Text",
                    "position": "vertical",
                    "qid": uuidv4()
                }
            ],
            "isDescriptive": true,
            "isSelected": true,
            "isFromEditor": true,
            "goalId": uuidv4()
        },
        likert: {
            "type": "likert",
            "blockId": uuidv4(),
            "dimension": question ? question : "Type group name",
            "questions": [
                {
                    "questionDimension": question ? question : "Type group name",
                    "type": "Matrix",
                    "questionLabel": question ? question : "",
                    "optionsCount": 5,
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "2"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "3"
                        }
                    ],
                    "question": "",
                    "qid": uuidv4(),
                    "matrixQuestions": [
                        {
                            "text": "",
                            "value": uuidv4(),
                            "isSelected": true,
                            "staticQuestionId": generateStaticQuestionId("")
                        },
                        {
                            "text": "",
                            "value": uuidv4(),
                            "isSelected": true,
                            "staticQuestionId": generateStaticQuestionId("")
                        },
                    ]
                }
            ],
            "isDefault": true,
            "isSelected": true,
            "description": "",
            "isStatic": true,
            "isTemplate": true,
            "goalId": "custom"
        },
        textEntry: {
            "type": "textEntry",
            "blockId": uuidv4(),
            "dimension": "Comments",
            "questions": [
                {
                    "qid": uuidv4(),
                    "type": "Text Entry",
                    "questionLabel": "",
                    "options": [],
                    "isSelected": true,
                    "question": question ? question : "Type question",
                    "staticQuestionId": generateStaticQuestionId(question ? question : "Type question")
                }
            ],
            "isSelected": true,
            "isTextEntryOnly": true,
            "isTemplate": true,
            "goalId": "comments"
        },
        multipleChoice: {
            "type": "multipleChoice",
            "blockId": uuidv4(),
            "dimension": "Demographics",
            "questions": [
                {
                    "type": "Multiple Choice",
                    "questionLabel": "",
                    "questionDimension": "Demographics",
                    "isSelected": true,
                    "options": [
                        {
                            "label": "Option 1",
                            "value": "1",
                            "id": uuidv4()
                        },
                        {
                            "label": "Option 2",
                            "value": "2",
                            "id": uuidv4()
                        },
                    ],
                    "question": question ? question : "Type question",
                    "qid": uuidv4(),
                    "staticQuestionId": generateStaticQuestionId(question ? question : "Type question"),
                    "isDemographic": true
                }
            ],
            "anonymousOnly": true,
            "isDemographic": true,
            "isSelected": true,
            "goalId": "demographics"
        },
        matrix: {
            "type": "matrix",
            "blockId": uuidv4(),
            "dimension": "Custom Matrix",
            "questions": [
                {
                    "questionDimension": "Custom Matrix",
                    "type": "matrix",
                    "questionLabel": "Custom Matrix",
                    "options": [
                        {
                            "label": "Col 1",
                            "value": "1"
                        },
                        {
                            "label": "Col 2",
                            "value": "2"
                        },
                        {
                            "label": "Col 3",
                            "value": "3"
                        }
                    ],
                    "qid": uuidv4(),
                    "question": "",
                    "matrixQuestions": [
                        {
                            "text": "Row 1",
                            "value": uuidv4(),
                            "isSelected": true,
                            "staticQuestionId": generateStaticQuestionId("Row 1" + uuidv4())
                        },
                        {
                            "text": "Row 2",
                            "value": uuidv4(),
                            "isSelected": true,
                            "staticQuestionId": generateStaticQuestionId("Row 2" + uuidv4())
                        }
                    ]
                }
            ],
            "isSelected": true,
            "goalId": "customMatrix"
        },
        yesOrNo: {
            "type": "yesOrNo",
            "blockId": uuidv4(),
            "dimension": "YesOrNo",
            "questions": [
                {
                    "qid": uuidv4(),
                    "type": "yesOrNo",
                    "options": [
                        {
                            "label": "Yes",
                            "id": uuidv4(),
                            "value": "1"
                        },
                        {
                            "label": "No",
                            "id": uuidv4(),
                            "value": "0"
                        }
                    ],
                    "questionDimension": "YesOrNo",
                    "question": question ? question : "Type question",
                    "questionLabel": "",
                    "staticQuestionId": generateStaticQuestionId(question ? question : "Type question")
                }
            ],
            "isTemplate": true,
            "isSelected": true,
            "goalId": "yesOrNo"
        },
        ranking: {
            "type": "ranking",
            "blockId": uuidv4(),
            "dimension": "Ranking",
            "questions": [
                {
                    "type": "ranking",
                    "questionDimension": "Ranking",
                    "isSelected": true,
                    "options": [
                        {
                            "label": "Option 1",
                            "id": uuidv4()
                        },
                        {
                            "label": "Option 2",
                            "id": uuidv4()
                        },
                        {
                            "label": "Option 3",
                            "id": uuidv4()
                        }
                    ],
                    "qid": uuidv4(),
                    "question": question ? question : "Type question",
                    "questionLabel": "",
                    "staticQuestionId": generateStaticQuestionId(question ? question : "Type question")
                }
            ],
            "isTemplate": true,
            "isSelected": true,
            "goalId": "Ranking"
        },
        ratingScale: {
            "type": "ratingScale",
            "dimension": "RatingScale",
            "questions": [
                {
                    "qid": uuidv4(),
                    "questionDimension": "Rating Scale",
                    "type": "ratingScale",
                    "questionLabel": "",
                    "totalRatingValue": 5,
                    "shape": "square",
                    "question": question ? question : "Type question",
                    "staticQuestionId": generateStaticQuestionId(question ? question : "Type question")
                }
            ],
            "blockId": uuidv4(),
            "goalId": "RatingScale"
        },
        employerNetPromoterScore: {
            "type": "employerNetPromoterScore",
            "dimension": "NetPromoterScore",
            "questions": [
                {
                    "qid": uuidv4(),
                    "questionDimension": "Employer Net Promoter Score",
                    "type": "employerNetPromoterScore",
                    "questionLabel": "",
                    "totalPromotingScore": 11,
                    "options": [{
                        "label": "Not likely at all",
                        "value": "1"
                    },
                    {
                        "label": "Extremely likely",
                        "value": "2"
                    },],
                    "question": "How likely are you to recommend us to a friend or colleague?",
                    "staticQuestionId": generateStaticQuestionId("How likely are you to recommend us to a friend or colleague?")
                }
            ],
            "blockId": uuidv4(),
            "goalId": "employerNetPromoterScore"
        }
    }
    return data[type]
}