import React, { useState, useEffect } from 'react';
import AddDemographics from '../../../../../../../../Components/AddDemographicsModal';
export const SelectDemographics = (props) => {
  let [selectedColumns, setSelectedColumns] = useState([]);
  useEffect(() => {
    setSelectedColumns(props.demographicsSelectedColumns);
  }, []);

  useEffect(() => {
    setSelectedColumns(props.demographicsSelectedColumns);
  }, [props.demographicsSelectedColumns]);

  const handleSelect = ({ selected }) => {
    let columns = [...selectedColumns];
    if (columns.includes(selected)) {
      columns = columns.filter((item) => item !== selected);
    } else columns.push(selected);
    setSelectedColumns(columns);
  };

  const onCancel = () => {
    setSelectedColumns(props.demographicsSelectedColumns);
  };

  return (
    <AddDemographics
      columns={props.demographicsColumns}
      selectedColumns={selectedColumns}
      onSelect={handleSelect}
      onCancel={onCancel}
      onOk={props.onAdd}
    />
  );
};
