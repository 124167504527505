export const Default = () => ({
    trendVisualisationData: {
        isProcessing: false,
        data: {
            topicsTrendArray: [],
            questionsTrendArray: []
        },
        error: false,
        message: '',
    },
    isTrendVisualisationDataFetched: false,
    selectedTopics: [],
    selectedQuestions: [],
    filters: {},
    selectedUser: "",
    directReporteesOnly: false,
    byScore: [],
    multipleSelect: [],
    demographicsSelectedTopics: [],
    demographicsSelectedQuestions: [],
    selectedDemographicsTopicTree: [],
    selectedDemographicsQuestionTree: [],
    trendDemographicsVisualisationData: {
        isProcessing: false,
        data: {
            trendData: [],

        },
        error: false,
        message: '',
    },
    trendVisualisationDataQuestions: {
        isProcessing: false,
        data: {
            trendData: [],

        },
        error: false,
        message: '',
    },
});