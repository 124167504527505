import {
  setInputFormChange,
  checkIsFormValid,
  setInitialState,
} from "../../../../utils/reducerOperations";
import { fetchFormConfigurations } from "../../../inputFormConfigurations";
export function handleOrganizationInputChange(state, payload) {
  let configuration = setInputFormChange(state.inputBoxConfiguration, payload);
  return {
    inputBoxConfiguration: configuration,
    isFormValid: checkIsFormValid(configuration),
  };
}

export const clearOrganizationForm = (state, payload) => {
  return {
    ...state,
    inputBoxConfiguration: fetchFormConfigurations(["organizationName"]),
  };
};

export const resetState = (state) => {
  return {
    ...state,
    addOrganizationApi: setInitialState(),
  };
};
