export const constants = {
  INSUFFICIENT_DATA: 'Insufficient data!',
  GRAPH_COLOR_CLASSES: [
    'progress-orange',
    'progress-neutral',
    'progress-green',
    'progress-blue',
    'progress-dark',
    'progress-gray',
  ],
  BACKGROUND_COLOR_CLASSES: [
    'light-orange',
    'light-neutral',
    'light-green',
    'light-blue',
    'light-gray',
    'light-gray',
  ],
  POSTIVEOUTLIERTITLE: 'This group is a positive outlier with a score statistically higher than all other groups',
  NEGATIVEOUTLIERTITLE: 'This group is a negative outlier with a score statistically lower than all other groups'
};
