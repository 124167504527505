import { apiInitialState } from "../../../utils/apiStateUtils";

export default {
  batchIdsApi: apiInitialState(),
  questionsApi: apiInitialState(),
  numericDemographicsApi: apiInitialState(),
  commentsCountApi: apiInitialState(),
  filtersApi: {
    ...apiInitialState(),
  },
  activeFacet: "",
  facetData: [],
  commentStatistic: { data: {} },
  treeDataApi:apiInitialState(),
};
