export const TEXT_SECTION = {
    "feedback360_improvementScaleDrag": "<span><b>PRIORITIES FOR IMPROVEMENT:</b> Out of the behaviors you identified as being demonstrated ‘not often enough' or 'rarely', <b>prioritize the top 3</b> areas this leader should work on, given the organization's priorities and the person's role.</span> ",
    "feedback360_strengthScaleDrag": "<span><b>LEVERAGING TOP·STRENGTHS:</b> Out of the behaviors you identified as being demonstrated 'almost always' or 'often', <b>prioritize the top 3</b> strengths this leader should continue leveraging given the organization's priorities and the person's role.</span>",
    "feedback180_strengthScaleDrag": "<span><b>LEVERAGING TOP·STRENGTHS:</b> Out of the behaviors you identified as being demonstrated 'almost always' or 'often', <b>prioritize the top 3</b> strengths to continue leveraging given the organization's priorities and the person's role.</span>",
    "feedback180_improvementScaleDrag": "<span><b>PRIORITIES FOR IMPROVEMENT:</b> Out of the behaviors you identified as being demonstrated ‘not often enough' or 'rarely', <b>prioritize the top 3</b> areas to work on given the organization's priorities and the person's role.</span>",

}

export const TEXT_SECTION_180_SELF = {
    "feedback180_strengthScaleDrag": "<span><b>LEVERAGING TOP·STRENGTHS:</b> Out of the behaviors you identified as being demonstrated 'almost always' or 'often', <b>prioritize the top 3</b> strengths to continue leveraging given the organization's priorities and your role.</span>",
    "feedback180_improvementScaleDrag": "<span><b>PRIORITIES FOR IMPROVEMENT:</b> Out of the behaviors you identified as being demonstrated ‘not often enough' or 'rarely', <b>prioritize the top 3</b> areas to work on given the organization's priorities and your role.</span>"
}

export const NON_MAPPING_KEYS = ["answer", "question", "questionDimension", "type", "qid"]
export const TOPIC_WISE_MAPPING = "topicWise"
export const NESTED_NON_MAPPING_KEY = ["questions"]