import { deepCopy } from "../../../../../utils/deepCopy"
import { generateStaticQuestionId } from '../../../../../utils/goalsUtils'

export const onBlurInput = (state, payload) => {
    let goals = optionAppliedGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const optionAppliedGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.goalId) {
            item.questions[0].matrixQuestions.forEach(
                question => {
                    if (question.staticQuestionId === payload.id) {
                        question.HTMLText = payload.value
                        let HTMLRemovedText = payload.value.replace(/<[^>]*>?/gm, '')
                        let updatedStaticQuestionId = generateStaticQuestionId(HTMLRemovedText)
                        if (payload.value === "Enter question" || payload.value === "Click the edit icon to edit this question") {
                            updatedStaticQuestionId = question.staticQuestionId
                        }
                        question.text = HTMLRemovedText
                        question.staticQuestionId = updatedStaticQuestionId
                        question.followUpQuestions && Array.isArray(question.followUpQuestions) && question.followUpQuestions.forEach(data => {
                            if (data.type === "Text Entry") {
                                data.selectedQuestionStaticId = updatedStaticQuestionId
                            } else {
                                data.matrixQuestions.forEach(matrix => matrix.selectedQuestionStaticId = updatedStaticQuestionId)
                            }

                        })
                    }
                }
            )
        }
    })
    return goalsCopy
}

export const onBlurInputDescripion = (state, payload) => {
    let goals = descriptionAppliedGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const descriptionAppliedGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.goalId) {
            item.questions[0].question = payload.value
        }
    })
    return goalsCopy
}

//openendedsection
export const onBlurOpenEndedHeadingInput = (state, payload) => {
    let goals = openEndedHeadingEditedGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const openEndedHeadingEditedGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === "comments") {
            item.questions.forEach(
                question => {
                    if (question.staticQuestionId === payload.id) {
                        question.HTMLQuestion = payload.value
                        let HTMLRemovedText = payload.value.replace(/<[^>]*>?/gm, '')
                        question.staticQuestionId = generateStaticQuestionId(HTMLRemovedText)
                        question.question = HTMLRemovedText
                    }
                }
            )
        }
    })
    return goalsCopy
}

const openEndedQuestionLabelEditedGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === "comments") {
            item.questions.forEach(
                question => {
                    if (question.qid === payload.id) {
                        question.questionLabel = payload.value
                    }
                }
            )
        }
    })
    return goalsCopy
}

export const onBlurOpenEndedQuestionLabel = (state, payload) => {
    let goals = openEndedQuestionLabelEditedGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

//demographicsection
export const onBlurDemographicHeading = (state, payload) => {
    let goals = demographicHeadingEditedGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const demographicHeadingEditedGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === "demographics") {
            item.questions.forEach(
                question => {
                    if (question.staticQuestionId === payload.id) {
                        question.HTMLQuestion = payload.value
                        let HTMLRemovedText = payload.value.replace(/<[^>]*>?/gm, '')
                        question.question = HTMLRemovedText
                        question.staticQuestionId = generateStaticQuestionId(HTMLRemovedText)
                    }
                }
            )
        }
    })
    return goalsCopy
}

export const onBlurDemographicQuestionLabel = (state, payload) => {
    let goals = demographicQuestionLabelEditedGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const demographicQuestionLabelEditedGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === "demographics") {
            item.questions.forEach(
                question => {
                    if (question.qid === payload.id) {
                        question.questionLabel = payload.value
                    }
                }
            )
        }
    })
    return goalsCopy
}


export const onBlurDemographicAnswerOption = (state, payload) => {
    let goals = demographicAnswerOptionEditedGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const demographicAnswerOptionEditedGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === "demographics") {
            item.questions.forEach(
                question => {
                    if (question.staticQuestionId === payload.goalId) {
                        question.options.forEach(data => {
                            if (data.id === payload.id) {
                                data.label = payload.value
                            }
                        })
                    }
                }
            )
        }
    })
    return goalsCopy
}

//multipleSelectionSection

export const onBlurMultipleSelectionHeading = (state, payload) => {
    let goals = multipleSelectionHeadingEditedGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const multipleSelectionHeadingEditedGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === "multipleOption") {
            item.questions.forEach(
                question => {
                    if (question.staticQuestionId === payload.id) {
                        question.HTMLQuestion = payload.value
                        let HTMLRemovedText = payload.value.replace(/<[^>]*>?/gm, '')
                        question.question = HTMLRemovedText
                        question.staticQuestionId = generateStaticQuestionId(HTMLRemovedText)
                    }
                }
            )
        }
    })
    return goalsCopy
}

export const onBlurMultipleSelectionQuestionLabel = (state, payload) => {
    let goals = multipleSelectionQuestionLabelEditedGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const multipleSelectionQuestionLabelEditedGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === "multipleOption") {
            item.questions.forEach(
                question => {
                    if (question.qid === payload.id) {
                        question.questionLabel = payload.value
                    }
                }
            )
        }
    })
    return goalsCopy
}

export const onBlurMultipleSelectionAnswerOption = (state, payload) => {
    let goals = multipleSelectionAnswerOptionEditedGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const multipleSelectionAnswerOptionEditedGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === "multipleOption") {
            item.questions.forEach(
                question => {
                    if (question.staticQuestionId === payload.goalId) {
                        question.options.forEach(data => {
                            if (data.id === payload.id) {
                                data.label = payload.value
                            }
                        })
                    }
                }
            )
        }
    })
    return goalsCopy
}