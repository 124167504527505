import * as actionTypes from '../../constants';
import { Default } from './initialState';
import helper from './helper';

function FetchDemographicsDataOfEnteredUserReducer(state = Default(), action) {
    switch (action.type) {
        case actionTypes.FETCH_USER_DEMOGRAPHICS_DATA_START:
            return helper.fetchDemographicsDataStart(state);
        case actionTypes.FETCH_USER_DEMOGRAPHICS_DATA_SUCCESS:
            return helper.fetchDemographicsDataSuccess(state, action);
        case actionTypes.FETCH_USER_DEMOGRAPHICS_DATA_FAIL:
            return helper.fetchDemographicsDataFailed(state, action);
        default: return { ...state };
    }
}

export default FetchDemographicsDataOfEnteredUserReducer