import { fetchPayload } from "../../../utils/reducerOperations";
import {
    FETCH_HEATMAP_DATA_FAILED,
    FETCH_HEATMAP_DATA_START,
    FETCH_HEATMAP_DATA_SUCCESS,
    FETCH_HEATMAP_HEADERS_FAILED,
    FETCH_HEATMAP_HEADERS_START,
    FETCH_HEATMAP_HEADERS_SUCCESS,
    FETCH_HEATMAP_QUESTIONS_FAILED,
    FETCH_HEATMAP_QUESTIONS_START,
    FETCH_HEATMAP_QUESTIONS_SUCCESS,
    UPDATE_HEATMAP_SCORE_LEVEL,
    RESET_FETCH_DATA_KEY_HEATMAP,
    FETCH_MANAGER_HEATMAP_DATA_START,
    FETCH_MANAGER_HEATMAP_DATA_FAILED,
    FETCH_MANAGER_HEATMAP_DATA_SUCCESS,
    FETCH_MANAGER_HEATMAP_HEADERS_FAILED,
    FETCH_MANAGER_HEATMAP_HEADERS_START,
    FETCH_MANAGER_HEATMAP_HEADERS_SUCCESS,
    FETCH_MANAGER_HEATMAP_QUESTIONS_FAILED,
    FETCH_MANAGER_HEATMAP_QUESTIONS_START,
    FETCH_MANAGER_HEATMAP_QUESTIONS_SUCCESS,
    UPDATE_MANAGER_HEATMAP_SCORE_LEVEL,
    RESET_MANAGER_FETCH_DATA_KEY_HEATMAP,
    UPDATE_HEATMAP_COMPARISON_LEVEL,
    UPDATE_MANAGER_HEATMAP_COMPARISON_LEVEL,
} from "../../constants";
import {
    fetchHeatmapHeadersFailed,
    fetchHeatmapHeadersStarted,
    fetchHeatmapHeadersSuccess,
    fetchHeatmapTableFailed,
    fetchHeatmapTableStarted,
    fetchHeatmapTableSuccess,
    fetchHeatmapQuestionsFailed,
    fetchHeatmapQuestionsStarted,
    fetchHeatmapQuestionsSuccess,
    updateScoreLevel,
    heatmapFetchDataKeyReset,
    fetchManagerHeatmapHeadersFailed,
    fetchManagerHeatmapHeadersStarted,
    fetchManagerHeatmapHeadersSuccess,
    fetchManagerHeatmapTableFailed,
    fetchManagerHeatmapTableStarted,
    fetchManagerHeatmapTableSuccess,
    fetchManagerHeatmapQuestionsFailed,
    fetchManagerHeatmapQuestionsStarted,
    fetchManagerHeatmapQuestionsSuccess,
    managerUpdateScoreLevel,
    managerHeatmapFetchDataKeyReset,
    updateComparisonLevel,
    managerUpdateComparisonLevel,
} from "./Helpers";
import { Default } from "./InitialState";

const HeatmapReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        //Heatmap headers list - one time fetch
        case FETCH_HEATMAP_HEADERS_START:
            return fetchHeatmapHeadersStarted(state, payload);
        case FETCH_HEATMAP_HEADERS_SUCCESS:
            return fetchHeatmapHeadersSuccess(state, payload);
        case FETCH_HEATMAP_HEADERS_FAILED:
            return fetchHeatmapHeadersFailed(state, payload);
        //Heatmap columns and data
        case FETCH_HEATMAP_DATA_START:
            return fetchHeatmapTableStarted(state, payload);
        case FETCH_HEATMAP_DATA_SUCCESS:
            return fetchHeatmapTableSuccess(state, payload);
        case FETCH_HEATMAP_DATA_FAILED:
            return fetchHeatmapTableFailed(state, payload);
        //Expandable - Topic Questions Data
        case FETCH_HEATMAP_QUESTIONS_START:
            return fetchHeatmapQuestionsStarted(state, payload);
        case FETCH_HEATMAP_QUESTIONS_SUCCESS:
            return fetchHeatmapQuestionsSuccess(state, payload);
        case FETCH_HEATMAP_QUESTIONS_FAILED:
            return fetchHeatmapQuestionsFailed(state, payload);
        case UPDATE_HEATMAP_SCORE_LEVEL:
            return updateScoreLevel(state,payload);
        case RESET_FETCH_DATA_KEY_HEATMAP:
            return heatmapFetchDataKeyReset(state, payload);
        case FETCH_MANAGER_HEATMAP_HEADERS_START:
            return fetchManagerHeatmapHeadersStarted(state, payload);
        case FETCH_MANAGER_HEATMAP_HEADERS_SUCCESS:
            return fetchManagerHeatmapHeadersSuccess(state, payload);
        case FETCH_MANAGER_HEATMAP_HEADERS_FAILED:
            return fetchManagerHeatmapHeadersFailed(state, payload);
        //Heatmap columns and data
        case FETCH_MANAGER_HEATMAP_DATA_START:
            return fetchManagerHeatmapTableStarted(state, payload);
        case FETCH_MANAGER_HEATMAP_DATA_SUCCESS:
            return fetchManagerHeatmapTableSuccess(state, payload);
        case FETCH_MANAGER_HEATMAP_DATA_FAILED:
            return fetchManagerHeatmapTableFailed(state, payload);
        //Expandable - Topic Questions Data
        case FETCH_MANAGER_HEATMAP_QUESTIONS_START:
            return fetchManagerHeatmapQuestionsStarted(state, payload);
        case FETCH_MANAGER_HEATMAP_QUESTIONS_SUCCESS:
            return fetchManagerHeatmapQuestionsSuccess(state, payload);
        case FETCH_MANAGER_HEATMAP_QUESTIONS_FAILED:
            return fetchManagerHeatmapQuestionsFailed(state, payload);
        case UPDATE_MANAGER_HEATMAP_SCORE_LEVEL:
            return managerUpdateScoreLevel(state, payload);
        case RESET_MANAGER_FETCH_DATA_KEY_HEATMAP:
            return managerHeatmapFetchDataKeyReset(state, payload);

        case UPDATE_HEATMAP_COMPARISON_LEVEL:
            return updateComparisonLevel(state, payload)
        case UPDATE_MANAGER_HEATMAP_COMPARISON_LEVEL:
            return managerUpdateComparisonLevel(state, payload)



        default:
            return { ...state };
    }
};

export default HeatmapReducer;
