import { 
    getSuccessResponse,
    handleApiResponseError, 
    handleNetworkError
} from '../../../../utils/reducerOperations';

export function downloadReportStart(state){
    return{
        ...state,
        downloadReportApi:{
            data:null,
            error:false,
            message:null,
            isProcessing:true
        }
    };
};


export function downloadReportSuccess(state,action){
    return{
        ...state,
        downloadReportApi:getSuccessResponse(action.response)
    };
};

export function downloadReportFailed(state,action){
    return{
        ...state,
        downloadReportApi:{
            ...(action.response && action.response.data
                ? handleApiResponseError(action.response.data)
                : handleNetworkError(action))
        }
    };
};

export function getParticipationDataStart(state){
    return{
        ...state,
        participationTableApi:{
            data:null,
            error:false,
            message:null,
            isProcessing:true
        }
    };
};


export function getParticipationDataSuccess(state,action){
    return{
        ...state,
        participationTableApi:getSuccessResponse(action.response)
    };
};

export function getParticipationDataFailed(state,action){
    return{
        ...state,
        participationTableApi:{
            ...(action.response && action.response.data
                ? handleApiResponseError(action.response.data)
                : handleNetworkError(action.response))
        }
    };
};