import { GetLocalStorage } from './localStorgeOperations'
import { getDataFromUrlToken } from "./urlDataStore";
import { URL_DATA } from '../../config/urlDataConstants'
import { getOrgIdFromUrl } from './urlProcessor';


export const getUserDataFromTree = () => {
  if (GetLocalStorage("selectedUser")) {
    return {
      ...getDirectReportees(),
      selectedUser: GetLocalStorage("selectedUser")
    };
  } else
    return {
      ...getDirectReportees(),
    };
};

const getDirectReportees = () => {
  if (GetLocalStorage("directReporteesOnly")) {
    return {
      directReporteesOnly: GetLocalStorage("directReporteesOnly"),
    };
  } else return {};
};

export const getProjectIdAndOrgId = (payload) => {
  let projectDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS)
  let orgId = fetchOrgId(payload)
  let projectId = payload && payload.projectId ? payload.projectId : projectDetails && projectDetails.projectId
  projectId = projectId === 'undefined' ? undefined : projectId
  return {
    projectId: projectId,
    organizationId: orgId
  }
}

export const fetchOrgId = (payload) => {
  if (payload && payload.organizationId) {
    return payload.organizationId
  }
  else {
    let orgIdFromUrl = getOrgIdFromUrl()
    if (orgIdFromUrl && orgIdFromUrl !== 'undefined' && orgIdFromUrl !== undefined) {
      return orgIdFromUrl
    }
    else {
      let orgIdFromToken = getDataFromUrlToken(URL_DATA.ORGANIZATION)
      let orgId = orgIdFromToken && orgIdFromToken.organizationId === 'undefined' ? undefined : orgIdFromToken && orgIdFromToken.organizationId
      return orgId
    }
  }
}