import { Default } from "./initialState";
import {
  fetchPayload,
  formChangeSuccess,
  update,
  setInitialState,
} from "../../../utils/reducerOperations";
import {
  PEOPLE_SEARCH_FORM_CHANGE,
  CLEAR_PEOPLE_SEARCH_FORM,
} from "../../constants";
import { fetchFormConfigurations } from "../../inputFormConfigurations";
import { apiInitialState } from "../../../utils/apiStateUtils";

const peopleListSearchReducer = (state = Default(), action) => {
  let payload = fetchPayload(action);

  switch (action.type) {
    case PEOPLE_SEARCH_FORM_CHANGE:
      return update(
        {
          ...state,
          inviteManagerApi: setInitialState(),
        },
        formChangeSuccess(state, payload)
      );
    case CLEAR_PEOPLE_SEARCH_FORM:
      return {
        ...state,
        peopleListApi: apiInitialState(),
        isFormValid: false,
        peopleTabelSearch: fetchFormConfigurations(["tableSearch"]),
      };
    default:
      return state;
  }
};

export default peopleListSearchReducer;
