import {
  TOGGLE_BULK_UPLOAD_MODAL,
  HANDLE_BULK_UPLOAD_FILE_DROP,
  ADMIN_BULK_UPLOAD_START,
  ADD_JOB_STARTED,
  ADD_JOB_FAILED,
  ADD_JOB_SUCCESS,
  ADMIN_BULK_UPLOAD_END,
} from "../../constants";
import { ApiBase } from "../../../utils/apiBase";
import { toast } from "../../../utils/messageUtils";
import {
  GET_SIGNED_URL,
  ADD_JOB,
  ADD_COMMENTS_JOB,
} from "../../../../config/constants";
import { GetLocalStorage } from "../../../utils/localStorgeOperations";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const toggleBulkModal = (params) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_BULK_UPLOAD_MODAL,
      payload: params,
    });
  };
};

export const handleFileUpload = (params) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_BULK_UPLOAD_FILE_DROP,
      payload: params,
    });
  };
};

export const bulkUploadStart = (params) => {
  return (dispatch) => {
    dispatch({
      type: ADMIN_BULK_UPLOAD_START,
      payload: params,
    });
  };
};

export const bulkUploadEnd = (params) => {
  return (dispatch) => {
    dispatch({
      type: ADMIN_BULK_UPLOAD_END,
      payload: params,
    });
  }
}

// export const uploadFile = (params, next) => {
//     let formData = new FormData();
//     formData.append(,file );
//     formData.append(userId,GetLocalStorage(userId) );
//     formData.append(userRole,getUserRole() );
//     return dispatch=>{
//         dispatch({type:actionTypes.UPLOAD_COMMENTS_START});
//         Axios.post(UPLOAD_FILE,formData,constants.formDataHeader)
//             .then(res=>fileUploadSuccess({dispatch,res,file}))
//             .catch(err=>fileUploadFail({dispatch,err}));
//     };
// }

export const uploadFile = (params, next) => {
  let apiBase = new ApiBase({
    "Content-Type": params.fileType,
    "x-amz-acl": "public-read",
  });
  return (dispatch) => {
    return apiBase.instance
      .put(params.url, params.file, {
        baseURL: "",
        headers: {
          "Content-Type": params.fileType,
          "x-amz-acl": "public-read",
        },
      })
      .then((response) => {
        next(true);
      })
      .catch((err) => {
        next(false);
      });
  };
};

export const getPreSignedURL = (params, next) => {
  let apiBase = new ApiBase();
  return (dispatch) => {
    return apiBase.instance
      .post(GET_SIGNED_URL, { ...params, ...getProjectIdAndOrgId(params) })
      .then((response) => {
        next(response, true);
      })
      .catch((err) => {
        toast.error(err.message);
        next(null, false);
      });
  };
};

export const addJob = (params, next) => {
  console.trace();
  let apiBase = new ApiBase();
  return (dispatch) => {
    dispatch({ type: ADD_JOB_STARTED });
    return apiBase.instance
      .post(ADD_JOB, { ...params, ...getProjectIdAndOrgId(params) })
      .then((response) => {
        // next(response.data.data.preSignedUrl);
        next(true, response);
        dispatch({ type: ADD_JOB_SUCCESS });
      })
      .catch((err) => {
        next(null);
        dispatch({ type: ADD_JOB_FAILED });
      });
  };
};

export const addCommentsJob = (params, next) => {
  let apiBase = new ApiBase();
  params.organizationName = GetLocalStorage('organizationName');
  return (dispatch) => {
    dispatch({ type: ADD_JOB_STARTED });
    return apiBase.instance
      .post(ADD_COMMENTS_JOB, { ...params, ...getProjectIdAndOrgId(params) })
      .then((response) => {
        // next(response.data.data.preSignedUrl);
        next && next(true);
        dispatch({ type: ADD_JOB_SUCCESS });
      })
      .catch((err) => {
        next && next(null);
        dispatch({ type: ADD_JOB_FAILED });
      });
  };
};
