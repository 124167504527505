export const fetchTrendDemographicsVisualisationStart = (state, payload) => {
    return {
        ...state,
        trendDemographicsVisualisationData: {
            ...state.trendDemographicsVisualisationData,
            isProcessing: true,
            error: false, reason: '',
        },
    };
};

export const fetchTrendDemographicsVisualisationSuccess = (state, payload) => {
    return {
        ...state,
        trendDemographicsVisualisationData: {
            isProcessing: false,
            error: false,
            reason: '',
            data: payload.data.data.response,
        },
        isTrendVisualisationDataFetched: true,
        byScore: payload.payload.byScore,
        multipleSelect: payload.payload.multipleSelect,
        filters: payload.payload.filters,
        selectedUser: payload.payload.selectedUser,
        directReporteesOnly: payload.payload.directReporteesOnly
    };
};

export const fetchTrendDemographicsVisualisationFail = (state, payload) => {
    return {
        ...state,
        trendDemographicsVisualisationData: {
            ...state.trendVisualisationData,
            isProcessing: false,
            error: true,
            reason: payload.error,
        },
    };
};
