export default function () {
  return {
    exploreTopicListApi: {
      isProcessing: false,
      error: false,
      reason: '',
      exploreTopicList: [],
      isApiCalled: false,
    },
    topicsSurveyReportApi: {
      isProcessing: false,
      error: false,
      reason: '',
      surveyReport: { dimensionTable: [], itemTable: [] },
      isApiCalled: false,
    },
    quoteModifying: false,
    sortOrder: 'descending',
    sortOption: 'score',
    filters: {},
    byScore: [],
    multipleSelect: [],
    disableTopicLoader: false,
    selectedUser: '',
    directReporteesOnly: false,
    exploreTopicFilterStatus: {
      isProcessing: false,
      error: false,
      reason: '',
      data: { topicStoriesFilterStatus: '', filters: {}, byScore: [], multipleSelect: [] },
    },
    adminBarchartApi: {
      isProcessing: false,
      error: false,
      reason: '',
      data: { dimensionTable: [], itemTable: [] },
      isApiCalled: false,
    },
  };
}
