import {
  RENAME_PEOPLE_START,
  RENAME_PEOPLE_SUCCESS,
  RENAME_PEOPLE_FAILED,
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { RENAME_PEOPLE } from "../../../../../config/constants";

export const renamePeople = (payload, callback) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(RENAME_PEOPLE_START, {}));
    apiBaseInstance.instance
      .post(RENAME_PEOPLE, payload)
      .then((response) => {
        dispatch(
          withType(RENAME_PEOPLE_SUCCESS, {
            data: response.data,
            message: response.message,
          })
        );
        callback && callback();
      })
      .catch((err) => {
        dispatch(
          withType(RENAME_PEOPLE_FAILED, {
            error: err,
          })
        );
      });
  };
};
