export const fetchVisualizationMatrixDataStart = (state) => {
    return {
        ...state,
        fetchMatrixVisualizationApi: { ...state.fetchMatrixVisualizationApi, isProcessing: true, error: false },

    }
}

export const fetchVisualizationMatrixDataSuccess = (state, payload) => {
    return {
        ...state,
        fetchMatrixVisualizationApi: {
            ...state.fetchMatrixVisualizationApi,
            isProcessing: false,
            data: payload.data.response,
            error: false,
            isDataFetched: true
        },

    }
}

export const fetchVisualizationMatrixDataFail = (state) => {
    return {
        ...state,
        fetchMatrixVisualizationApi: { ...state.fetchMatrixVisualizationApi, isProcessing: false, error: true, isDataFetched: false },
    }
}