import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const reprocessTopicsStarted = (state) => {
    return {
        ...state,
        reprocessTopicsApi: getApiStartState()
    }

}

export const reprocessTopicsSuccess = (state, payload) => {
    return {
        ...state,
        reprocessTopicsApi: getSuccessResponse(payload)
    }

}

export const reprocessTopicsFailed = (state, payload) => {
    return {
        ...state,
        reprocessTopicsApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}