import React, { useState, useEffect } from "react";
import constants from "../constants";
import CustomModal from "../../../Components/CustomModal";
import FormField from "../../../Components/FormField";
import { GetLocalStorage } from "../../../utils/localStorgeOperations";
import { ORGANIZATION_PAGE_SECTION } from '../../../../config/tabConstants';

function RenameOrganization(props) {
  const [orgName, setOrgName] = useState(props.orgName);
  const [orgNameValid, setOrgNameValid] = useState(true);
  const [isOrgNameEdit, setIsOrgNameEdit] = useState(false);
const formConfig = props.inputBoxConfiguration;
if(!isOrgNameEdit){
formConfig[0].value = orgName;}

  useEffect(() => {
    if(!isOrgNameEdit){
    setOrgName(props.orgName)
  }else
  formConfig[0].value = props.inputBoxConfiguration[0].value
  const formValid = props.inputBoxConfiguration.map((item) => item.isValid);
 setOrgNameValid(formValid[0])
  });
  function onClickRename() {
    const { local } = constants;
    let params = {};
    props.inputBoxConfiguration.map((item) => {
      params[item.name] = item.value;
      return 0;
    });
    params.owner = [
      {
        firstName: GetLocalStorage(local.firstName),
        id: GetLocalStorage(local.userId),
        lastName: GetLocalStorage(local.lastName),
      },
    ];
    if (props.isFormValid) {

    }
    props.renameOrg(
      {
        currentTab: ORGANIZATION_PAGE_SECTION.ORGANIZATION,
        organizationId: props.orgDetails.organizationId,
        ...params,
      },
      () => props.onChangePagination(props.currentPage, () => props.visibleToggle("renameOrgVisible", props.orgDetails))
    );
    //setIsOrgNameEdit(false)
  }
  function onCancel() {
    props.visibleToggle("renameOrgVisible", props.orgDetails);
    setIsOrgNameEdit(false)
  }
  function handleChange(e) {
    setIsOrgNameEdit(true)
    setOrgName(e.event.value);
    props.formInputChange(e)
  }

  const { title } = constants.renameOrgModal;
  let minDate = new Date();
  minDate.setDate(new Date().getDate() + 1);
  return (
    <CustomModal
      confirmLoading={props.renameOrgApi.isProcessing}
      title={title}
      visible={props.visible}
      okText={constants.rename}
      okButtonProps={{
        disabled:  !orgNameValid,
        loading: props.renameOrgApi.isProcessing,
      }}
      onOk={onClickRename}
      onCancel={onCancel}
    >
      <ul className="clb-model-form">
      <li>
      {formConfig.map((field, index) => (
      <FormField
        test-name="input-field"
        key={index}
        labelRequired={true}
        required={true}
        index={index}
        field={field}
        onEnter={onClickRename}
        loginFormChange={handleChange}
      />
    ))}
        </li>
      </ul>
    </CustomModal>
  );
}

export default RenameOrganization;
