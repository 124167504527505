export const fetchHeatmapHeadersStarted = (state) => {
    return {
        ...state,
        heatmapHeadersApi: {
            isProcessing: true,
            error: false
        }
    }

}

export const fetchHeatmapHeadersSuccess = (state, payload) => {
    return {
        ...state,
        heatmapHeadersApi: {
            isProcessing: false,
            error: false
        },
        heatmapHeaders: formatHeatmapHeadersToTagObjects(payload.data)
    }

}

export const fetchHeatmapHeadersFailed = (state, payload) => {
    return {
        ...state,
        heatmapHeadersApi: {
            isProcessing: false,
            error: true
        },
        heatmapHeaders: [],
        isDataFetched: false
    }

}


const formatHeatmapHeadersToTagObjects = (headersList) => {
    if (headersList && headersList.length) {
        return headersList.map((item, index) => { return { id: index + 1, content: item } })
    }
    return []

}