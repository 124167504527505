export const initialState = {
  api: {
    isProcessing: false,
    error: false,
    errorMessage: "",
    isInitialApiCalled: false,
  },
  data: [],
  columns: [],
  selectedColumns: [],
  dimensionsApi: {
    isProcessing: false,
    error: false,
    errorMessage: "",
    isInitialApiCalled: false,
    dimensions: [],
  },
  exploreOutliersApi: {
    isProcessing: false,
    error: false,
    errorMessage: "",
    outliers: [],
  },
  filters: {},
  byScore:[],
  multipleSelect: [],
  selectedUser: "",
  directReporteesOnly: false,
};
