
// eslint-disable-next-line max-lines-per-function
export function Default() {
    return {
        isBulkLoading: false,
        isBulkError: false,
        isBulkAddModalVisible: false,
        fileToUpload: null,
        isJobFetchLoading: true,
        isJobFetchingFailed: false,
        jobData: [],
        page: 1,
        size: 5
    };
}
