import { withType } from '../../../../utils/actionOperations';
import {
    REPROCESS_SURVEY_START,
    REPROCESS_SURVEY_SUCCESS,
    REPROCESS_SURVEY_FAILED
} from '../../../constants';
import { REPROCESS_SURVEY_URL } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export function reprocessSurvey(params, cb) {
    const apiBaseInstance = new ApiBase()
    return dispatch => {
        dispatch(withType(REPROCESS_SURVEY_START, {}));
        apiBaseInstance.instance
            .post(REPROCESS_SURVEY_URL, { ...params, ...getProjectIdAndOrgId(params) })
            .then(response => {
                dispatch(withType(REPROCESS_SURVEY_SUCCESS, response));
                cb && cb();
            })
            .catch(err => {
                dispatch(withType(REPROCESS_SURVEY_FAILED, err));
            });
    };
}
