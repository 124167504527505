export const getOrigin = ()=>{
    let origin=window.location.origin;
    if(!origin){
        origin=`${window.location.protocol}//${window.location.host}`
    }
    return origin;
}

export const getDomainName = ()=>{
  return window.location.host;
}