import React from "react";
import constants from "../../constants";
import { Tooltip } from "antd";
import { ButtonElement } from "../../../../../../FormElements/ButtonElement";
import "./index.scss";
import { TextInput } from "../../../../../../FormElements/TextInput/";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function FileUpload({ title, index, api, onUpload }) {
  const { field, loading, upload } = constants;
  return (
    <div className="input-file required-field">
      
      <TextInput
        className="input-file__hidden"
        type={field.type}
        name={field.name}
        handleChange={(e) => onUpload(index, e)}
      />
      <ButtonElement className="input__btn">
      <FontAwesomeIcon icon={faCloudUploadAlt} type={api.loading ? loading : upload}  />
      
        {title}
      </ButtonElement>
      <Tooltip title={!!(!api.loading && api.name) && api.name}>
        <span
          className="input-file__details"
          children={
            !!(!api.loading && api.name) && api.name.length > 20
              ? api.name.slice(0, 20) + "(...)." + api.name.split(".").pop()
              : api.name
          }
        />
      </Tooltip>
    </div>
  );
}
