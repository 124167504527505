export function fetchCommentsSuccess(state, action) {
  return {
      ...state,
      commentsApi:{error:false,loading:false,data:action.data, isProcessing:false},
  };
}

export function fetchCommentsStart(state, action) {

  return {
      ...state,
      commentsApi:{loading:true,error:false, isProcessing:true}
  };
}

export function fetchCommentsFail(state, action) {
  return {
      ...state,
      commentsApi:{loading:false,error:true, isProcessing:false}
  };
};
