import {
  SET_PROJECT_CONFIG,
  GET_PROJECT_CONFIG_START,
  GET_PROJECT_CONFIG_SUCESS,
  GET_PROJECT_CONFIG_FAIL,
  SAVE_PROJECT_FAIL,
  SAVE_PROJECT_START,
  SAVE_PROJECT_SUCCESS,
  EDIT_PROJECT_PUBLISH_START,
  EDIT_PROJECT_PUBLISH_FAIL,
  EDIT_PROJECT_PUBLISH_SUCCESS,
  IMAGE_UPLOAD_START,
  IMAGE_UPLOAD_FAILED,
  IMAGE_UPLOAD_SUCCESS,
  RENAME_PROJECT_SUCCESS,
  RENAME_PROJECT_START,
  RENAME_PROJECT_FAIL,
} from "../../constants";
import { Default } from "./InitialState";
import helper from "./helper/";

const EditProjectReducer = (state = Default(), action) => {
  switch (action.type) {
    case SET_PROJECT_CONFIG:
      return helper.setConfig(state, action);
    case GET_PROJECT_CONFIG_START:
      return helper.getProjectConfigStart(state, action);
    case GET_PROJECT_CONFIG_SUCESS:
      return helper.getProjectConfigSuccess(state, action);
    case GET_PROJECT_CONFIG_FAIL:
      return helper.getProjectConfigfail(state, action);
    case SAVE_PROJECT_START:
      return helper.saveProjectStart(state, action);
    case SAVE_PROJECT_SUCCESS:
      return helper.saveProjectSuccess(state, action);
    case SAVE_PROJECT_FAIL:
      return helper.saveProjectFail(state, action);
    case IMAGE_UPLOAD_START:
      return helper.uploadImageStarted(state, action);
    case IMAGE_UPLOAD_SUCCESS:
      return helper.uploadImageSuccess(state, action);
    case IMAGE_UPLOAD_FAILED:
      return helper.uploadImageFail(state, action);
    default:
      return publishProject(state, action);
  }
};

function publishProject(state, action) {
  switch (action.type) {
    case EDIT_PROJECT_PUBLISH_START:
      return helper.publishProjectStarted(state);
    case EDIT_PROJECT_PUBLISH_FAIL:
      return helper.publishProjectFail(state, action);
    case EDIT_PROJECT_PUBLISH_SUCCESS:
      return helper.publishProjectSuccess(state, action);
    default:
      return renameProject(state, action);
  }
}

function renameProject(state, action) {
  switch (action.type) {
    case RENAME_PROJECT_START:
      return helper.renameProjectStarted(state);
    case RENAME_PROJECT_FAIL:
      return helper.renameProjectFail(state, action);
    case RENAME_PROJECT_SUCCESS:
      return helper.renameProjectSuccess(state, action);
    default:
      return { ...state };
  }
}

export default EditProjectReducer;
