import { deepCopy } from "../../../../../../utils/deepCopy"

export const handleChangeLabel = (state, payload) => {
    let changedGoal = getChangedOptionLabelGoals(state.goals, payload)
    return ({
        ...state,
        goals: changedGoal


    })
}

const getChangedOptionLabelGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(item => {
        if (item.blockId === payload.blockId) {
            item.questions.forEach(question => {
                question.options.forEach((option, index) => {
                    if (option.id === payload.optionId) {
                        option.label = payload.value
                    }
                })

            })
        }

    })
    return goalsCopy
}