export const fileUploadStatusStart =(state,payload)=>{
return{
    ...state,
    HRISUploadStatus:{
      ...state.HRISUploadStatus,
      isProcessing: true,
      error:false
      }
    }
}

export const fileUploadStatusSuccess =(state,payload)=>{
  const data = payload.data.data.response
return{
    ...state,
    HRISUploadStatus:{
      ...state.HRISUploadStatus,
      isProcessing: false,
      data:data,
      error:false
    }
}
}

export const fileUploadStatusFail =(state,payload)=>{
return{
    ...state,
    HRISUploadStatus:{
      ...state.HRISUploadStatus,
      isProcessing: false, 
      error:payload.error
    }
}
}