import React from "react";
import { getDataFromUrlToken } from "../../../../utils/urlDataStore";
import { URL_DATA } from "../../../../../config/urlDataConstants";
import { getFacetDataWithOutByScore } from "../../../../utils/byScoreFilterUtils";
import { checkManager } from '../../../../utils/rolePermissionUtils';
import SVGIcons from "../../../../Components/SVGIcons";
import { compareByAlpha } from "../../../../utils/compareByAlpha";
export const getTopicBadgeColor = (score) => {
  if (score >= 95) return 'blue';
  else if (score >= 85) return 'blue-light';
  else if (score >= 75) return 'sky-blue';
  else if (score >= 65) return 'sky-bluelight';
  else if (score >= 55) return 'gray';
  else if (score >= 45) return 'orange-light';
  else if (score >= 35) return 'orange';
  else if (score >= 25) return 'red-light';
  else if (score >= 15) return 'red';
  return '';
};

export const checkFilterChange = (reducerFilters, urlFilters) => {
  if (Object.keys(reducerFilters).length === 0 && urlFilters === undefined) {
    return false;
  } else if (Object.keys(reducerFilters).length === 0 && urlFilters !== undefined) {
    return true
  } else if (Object.keys(reducerFilters).length !== 0 && urlFilters === undefined) {
    return true
  }
  const reducerFiltersKeys = Object.keys(reducerFilters);
  const urlFiltersKeys = Object.keys(urlFilters);
  if (JSON.stringify(reducerFilters) === JSON.stringify(urlFilters)) return false;
  else if (arrayComparison(reducerFiltersKeys, urlFiltersKeys)) {
    let bothFiltersAreSame = true
    for (let key of reducerFiltersKeys) {
      let firstArray = reducerFilters[key]
      let secondArray = urlFilters[key]
      let isArraysAreSame = arrayComparison(firstArray, secondArray)
      bothFiltersAreSame = bothFiltersAreSame && isArraysAreSame
    }
    return !bothFiltersAreSame
  } else return true

}

const arrayComparison = (firstArray, secondArray) => {
  if (firstArray.length !== secondArray.length) return false
  let ifBothArraysAreSame = true
  for (const value of firstArray) {
    let isInclude = secondArray.includes(value)
    ifBothArraysAreSame = ifBothArraysAreSame && isInclude
  }
  for (const value of secondArray) {
    let isInclude = firstArray.includes(value)
    ifBothArraysAreSame = ifBothArraysAreSame && isInclude
  }
  return ifBothArraysAreSame
}

export const getSelectedUserData = () => {
  const treeData = getDataFromUrlToken(URL_DATA.TREE_DATA);
  const directReporteesOnly =
    treeData && treeData.directReporteesOnly !== undefined
      ? treeData.directReporteesOnly
      : false;
  const selectedUser = treeData && treeData.selectedUser;
  return {
    selectedUser: selectedUser,
    directReporteesOnly: directReporteesOnly,
    selectedUserName: treeData.selectedUserName,
    count: treeData.count
  };
};

export const getStoryboardSelectedUserData = () => {
  const treeData = getDataFromUrlToken(URL_DATA.STORYBOARD_TREEDATA);
  const directReporteesOnly =
    treeData && treeData.directReporteesOnly !== undefined
      ? treeData.directReporteesOnly
      : false;
  const selectedUser = treeData && treeData.selectedUser ? treeData.selectedUser : '';
  return {
    selectedUser: selectedUser,
    directReporteesOnly: directReporteesOnly,
    selectedUserName: treeData && treeData.selectedUserName ? treeData.selectedUserName : ''
  };
};

export const getTrendColumnData = (data) => {

}

export const getFacetData = (data, instance, props) => {
  if (props.isCommentQuestion) {
    let data = props.selectedMenu &&
      props[props.selectedMenu] &&
      props[props.selectedMenu].dashBoardFilterData
    return {
      filters: data ? data : []
    }
  }
  if (!Array.isArray(data)) {
    if (Object.keys(data).length > 0 && data.filters.length > 0) {
      if (removeOutliersAndParticipation(instance) || isManagerPermissionAllowed(instance.props.surveyDetailsApi.surveyDetails)) {
        return getFacetDataWithOutByScore(data)
      } else {
        return data
      }

    } else {
      return data
    }
  } else {
    return data
  }
}

export const isManagerPermissionAllowed = (surveyDetails) => {
  if (surveyDetails) {
    let urlData = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS)
    return checkManager(surveyDetails.organizationId, urlData.projectId) && urlData.completedParticipantsCount !== undefined && urlData.completedParticipantsCount < 20
  } else {
    return false
  }
}

export const removeOutliersAndParticipation = (instance) => {
  return instance.state.selectedMenu === "Participation"
}

export const getMatchedSurveyDetails = (data,isQuestionMapped) => {
  if (data) {
   let filteredData
    if(isQuestionMapped){
       filteredData = data.filter(item => item.isQuestionMapped)

    }
    else{
      filteredData = data.filter(item => item.isTopicMapped)
    }
    return filteredData
  }
  else return []

}

export const addTrendDataToTable = (tableData, showActualScore) => {
  let updatedTableData = []
  tableData.forEach((item, key) => {
    if (item.trend && item.trend.length > 0) {
      let trendDetails = {}
      item.trend.forEach((trendData, index) => {
        let score = 0
        let negative = false
        if (trendData.trendScore < 0) {
          score = showActualScore ? Math.abs(item.favScore - trendData.trendScore) : trendData.trendScore
          negative = true
        } else {
          score = showActualScore ? Math.abs(item.favScore - trendData.trendScore) : trendData.trendScore
        }
        trendDetails = { ...trendDetails, [trendData.projectId]: {score,negative} }
      })
      updatedTableData.push({ ...item, ...trendDetails })
    }
    else {
      updatedTableData.push(item)
    }
  })
  return updatedTableData
}

export const addTrendColomn = (trendData, props,validCountKey) => {
  let trendColomn = []
  let responseKey=validCountKey?validCountKey:"validCount"
  trendData.forEach(matchedSurvey => {
    trendColomn.push({
      title: matchedSurvey.projectName,
      dataIndex: matchedSurvey.projectId,
      align: "center",
      key: matchedSurvey.projectId,
      width: 100,
      sorter: (b, a) => compareTrendScore({ previous: a, next: b, projectId: matchedSurvey.projectId }),
      render: (item, record) => {
        return (
          <>
            {record[responseKey] >= props.threshold ?
              <div className="table-arrows row-content">
                <span>
                  {item && !isNaN(item && item.score) && item.score > 0 && !props.showActualScore ? (
                    <span className="up-arrow">
                      <SVGIcons type={"SVG-thick-uparrow"} />
                    </span>
                  ) : (
                    ""
                  )}
                  {item && !isNaN(item.score) && item.score < 0 && !props.showActualScore ? (
                    <span className="down-arrow">
                      <SVGIcons type={"SVG-thick-downarrow"} />
                    </span>
                  ) : (
                    ""
                  )}{" "}
                </span>{" "}
                {!item || isNaN(item.score) ? "-" : <span className={getClassName(item, props)}>{Math.abs(item.score)}</span>}
              </div> : <div className="table-arrows row-content">-</div>
            }
          </>
        )
      }

    })
  });
  return trendColomn
}

const getClassName = (item, props) => {
  if (props.showActualScore) {
    if (Math.abs(item.score) === 0) {
      return "table-count row-content"
    } else if (item.negative) {
      return "trend-actual-red table-count row-content"
    } else {
      return "trend-actual-green table-count row-content"
    }
  } else {
    return "table-count row-content"
  }
}

const compareTrendScore = ({ previous, next, projectId }) => {
  let prev = previous[projectId] && previous[projectId].score ? previous[projectId].score : -10000
  let nextData = next[projectId] && next[projectId].score ? next[projectId].score : -10000
  let sorter = compareByAlpha(prev, nextData)
  return sorter


}