/* eslint-disable */
export const FORM_VALIDATION_CONSTANTS = {
  //email: /^[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)?@[a-zA-Z][a-zA-Z-0-9]*\.[a-zA-Z]+(\.[a-zA-Z]+)?$/,
  email: /^[a-zA-Z][a-zA-Z0-9_+.']*(\.[a-zA-Z][a-zA-Z0-9_']*)?@[a-zA-Z][a-zA-Z-0-9]*\.[a-zA-Z]+(\.[a-zA-Z]+)?$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@.()^/,!%&*?])[A-Za-z\d#$@.()^/,!%&*?]{8,30}$/,
  organizationName: /.+/,
  name: /^[a-zA-Z][a-zA-Z0-9' ]*$/,
  username: /^[a-zA-Z0-9_.@ ]*$/,
  verificationCode: /^[0-9\b]+$/,
  phoneNumber: /[2-9]{2}\d{8}/,
  template: /.+/,
  allEmail: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z][a-zA-Z-0-9]*\.[a-zA-Z]+(\.[a-zA-Z]+)?$/,
  empty: /^/,
};
