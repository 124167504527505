import { checkIfObjectsAreEqual } from "./customFunctions"
import { deepCopy } from "./deepCopy"

export const getURLFilterAndByScore = (urlFilter) => {
    let urlFilterCopy = deepCopy(urlFilter)
    let filters = {}
    let byScore = {}
    let multipleSelect = {}
    for (let [key, value] of Object.entries(urlFilterCopy)) {
        if (key.startsWith("By score")) {
            byScore[key] = value
        }
        else if (key.startsWith("Multiple select")) {
            multipleSelect[key] = value
        }
        else {
            filters[key] = value
        }
    }
    return {
        byScore: getByScore(byScore),
        filters: filters,
        multipleSelect: getMultipleSelect(multipleSelect)
    }
}

const getByScore = (byScoreData) => {
    let multipleSelectDataCopy = deepCopy(byScoreData)
    let keys = Object.keys(multipleSelectDataCopy)
    let byScore = []
    if (keys.length <= 0) {
        return []
    } else {
        keys.forEach(key => {
            let scoreLevel = multipleSelectDataCopy[key]
            let level = key.split(":")
            if (level.length > 2) {
                let lastElement = level[level.length - 1]; // Get the last element
                let firstElements = level.slice(0, -1).join(':'); // Join all but the last element

                // Update the level array with the first elements and the last element
                level = [firstElements, lastElement];
            }
            if (level.length === 2) {//topicScoreLevel
                scoreLevel.forEach(item => {
                    byScore.push({
                        topic: level[1].trim(),
                        scoreLevel: getScoreLevel(item)
                    })
                })
            } else {//topicQuestion scoere level
                scoreLevel.forEach(item => {
                    byScore.push({
                        topic: level[1].trim(),
                        questionId: level[3].trim(),
                        scoreLevel: getScoreLevel(item)
                    })
                })
            }
        })
        return byScore
    }
}

const getScoreLevel = (level) => {
    if (level.trim() === "Favorable") {
        return "favourable"
    } else if (level.trim() === "Unfavorable") {
        return "unFavourable"
    } else {
        return "neutral"
    }
}

export const checkIfTwoArraysAreIsEqual = (array1, array2) => {
    if (array1 && array1.length === 0 && array2 && array2.length === 0) {
        return true
    }
    if (array1 && array1.length > 0 && array2 && array2.length > 0) {
        let array1Data = { data: array1 }
        let array2Data = { data: array2 }
        return checkIfObjectsAreEqual(array1Data, array2Data)
    } else {
        return false
    }
}

export const getFacetDataWithOutByScore = (data) => {
    if (!Array.isArray(data) && Object.keys(data).length > 0 && data.filters.length > 0) {
        let dataCopy = deepCopy(data)
        let filters = dataCopy.filters
        dataCopy.filters = filters.filter(item => item.label !== "byScore")
        return dataCopy
    }
    return data
}

const getMultipleSelect = (multipleSelectData) => {
    let multipleSelect = []
    let multipleSelectDataCopy = deepCopy(multipleSelectData)
    let keys = Object.keys(multipleSelectDataCopy)
    if (keys.length <= 0) {
        return []
    } else {
        keys.forEach(key => {
            let options = multipleSelectDataCopy[key]
            let level = key.split(":")
            multipleSelect.push({
                questionId: level[2],
                question: level[1],
                options: options,
            })
        })
        return multipleSelect
    }
}