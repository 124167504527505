import * as actionTypes from "../../constants";
import { notification } from "antd";
import constants from "./constants";
import { USER_COMMENTS_URL } from "../../../../config/constants";
import { NLPCommonReqBodies } from "../../../utils/actionOperations";
import { getOrgName } from "../../../utils/localStorgeOperations";
import { ApiBase } from '../../../utils/apiBase';

export function downloadUserComments(payload) {
  let projectId = payload.projectId;
  let projectName = payload.projectName;
  let organizationName = getOrgName();
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch({ type: actionTypes.DOWNLOAD_USER_COMMENT_START });
    apiBaseInstance.instance.post(USER_COMMENTS_URL, {
      ...NLPCommonReqBodies(),
      projectId: projectId,
    })
      .then((response) =>
        downloadUserCommentsSuccess(dispatch, {
          response,
          projectName,
          organizationName,
          payload
        })
      )
      .catch((e) => downloadUserCommentsFailed(dispatch, e));
  };
}

function downloadUserCommentsSuccess(
  dispatch,
  { response, projectName, organizationName, payload }
) {
  const fileUrl = response.data.data.fileURL
  const a = document.createElement('a')
  a.href = fileUrl;
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  payload.callback && payload.callback()
  dispatch({
    type: actionTypes.DOWNLOAD_USER_COMMENT_SUCCESS,
  });
}

function downloadUserCommentsFailed(dispatch, error) {
  notification.error({
    message: constants.downloadingFailed,
    description: error.response && error.response.data,
  });

  dispatch({
    type: actionTypes.DOWNLOAD_USER_COMMENT_FAIL,
  });
}
