import {
    HANDLE_ON_DRAG_END
} from "../../../constants";


export const handleDragEnd = (payload) => {
    return {
        type: HANDLE_ON_DRAG_END,
        payload: payload,
    };
};