import {
    update,
    makeKey,
} from '../../../../utils/reducerOperations';
import { appendUserRoles } from './appendUserRole';
export function fetchUsersNextSuccess(state, payload) {
    let nextPageEnabled = false;
    let previousPageEnabled = true;
    let pageKeys = state.fetchUsersApi.pageKeys;
    let page = state.fetchUsersApi.page++;
    if(payload.data.LastEvaluatedKey) {
        pageKeys[page] = payload.data.LastEvaluatedKey;
        nextPageEnabled = true; 
    }
    return makeKey(
        'fetchUsersApi',
        update(state.fetchUsersApi, {
            data: appendUserRoles(payload.data),
            isProcessing: false,
            pageKeys: pageKeys,
            message: payload.message,
            error: !payload.status,
            nextPageEnabled: nextPageEnabled,
            previousPageEnabled: previousPageEnabled,
            page: state.fetchUsersApi.page++
        })
    );
}
