import React from "react";
import { CheckInLocalStorage } from "./localStorgeOperations";
import { Redirect } from "react-router-dom";
import constants from "./constants";

const redirectTo = (payload) => {
  return (
    <Redirect
      to={{
        pathname: constants.path.login,
        state: { from: payload.location },
      }}
    />
  );
};

export const RenderIfSignIn = (properties, Component) => {
  if (CheckInLocalStorage(constants.local.idToken)) {
    return <Component {...properties} />;
  } else return redirectTo(properties);
};

export const isLoggedIn = () => {
  return CheckInLocalStorage(constants.local.idToken);
};

export const renderComponentWithProps = (properties, Component) => {
  return <Component {...properties} />;
};

export const checkArrayHasLength = (arr) => {
  return Array.isArray(arr) && arr.length;
};

export const getAllowedRoutes = (routes) => {
  // const currentUserRole = getUserRole();
  // return routes.filter(({ roles }) => {
  //   if (!roles) return true;
  //   else if (!checkArrayHasLength(roles)) return true;
  //   else return intersection(roles, [currentUserRole]).length;
  // });
  return routes
};
