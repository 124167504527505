export const Default = () => ({
    surveyQuestionSetApi: {
        isProcessing: false,
        data: {},
        error: false,
        message: ''
    },
    userDemographicsApi: {
        isProcessing: false,
        data: {},
        error: false,
        message: ''
    },
    surveyTranslateApi: {
        isProcessing: false,
        data: {},
        error: false,
        message: ''
    },
    pageBreakAddedQuestionSet: [],
    questionSet: [],
    currentIndex: 0,
    progressIndex: 0,
    selectedLanguage: 'English',
    defaultTexts: {
        summaryRatingHeading: "Summary of Ratings",
        ok: 'Okay',
        continue: 'Continue',
        translatedDescription: undefined,
        back: 'Back to Survey',
        submit: 'Submit',
        languageSelect: 'Select your language',
        placeholder: 'Enter response in any language',
        recordAnswer: 'Click here to record your answer',
        surveyPreviewEndMessage: 'You have reached the end of the survey',
        surveyUnansweredMessage: 'You have not answered any questions',
        surveySubmitMessage: "Please save your responses by clicking the submit button below",
        surveyCompletedMessage: "Thank you! Your response has been recorded. You may now close the browser.",
        summaryPageSalutation: "<h5>Dear Feedback Provider,</h5> <h5>Here is a summary of your ratings across all the behaviors.</h5>",
        summaryPageDetails: "<h5>You have chosen to use a small range of the 6-point scale to rate the participant* on all the listed behaviors. </h5> <h5>It would be highly beneficial to provide greater differentiation and use a wider range of ratings in your feedback to support the participant's growth and development. This would allow the participant to better understand their strengths and focus areas. Please remember that the primary purpose of this 360 Feedback tool is to foster development.</h5><h5>We encourage you to take the time to review the above, and provide comprehensive and well-rounded feedback experience. </h5>",
        summaryRatingsNote: "<h5>*Note: ”Participant” refers to the person for whom you are providing the feedback.</h5>",
        summaryPageYesButton: "Yes, I’d like to review my ratings",
        summaryPageNoButton: "No, let’s proceed",
        stengthSelfMainHeading: "<span><b>TOP STRENGTHS:</b> You identified these behaviors as strengths (rating of \"Usually\" or \"Always\"). Please <b> rank the top 3</b> behaviors to leverage given the organization's priorities and your role.</span>",
        strengthMainHeading: "<span><b>TOP STRENGTHS:</b> You identified these behaviors as strengths (rating of \"Usually\" or \"Always\"). Please <b> rank the top 3</b> behaviors to leverage given the organization's priorities and the person’s role.</span>",
        improvementSelfMainHeading: "<span><b>TOP DEVELOPMENT AREAS:</b> You identified these behaviors as <i>relative</i> development areas (rating of \"Never\", \"Rarely\", \"Occasionally\", and \"Sometimes\"). Please <b>rank the top 3</b> areas to work on given the organization's priorities and your role.</span>",
        improvementMainHeading: "<span><b>TOP DEVELOPMENT AREAS:</b> You identified these behaviors as <i>relative</i> development areas (rating of \"Never\", \"Rarely\", \"Occasionally\", and \"Sometimes\"). Please <b>rank the top 3</b> areas to work on given the organization's priorities and the person’s role.</span>"
    },
    translatedTexts: {},
    submitSurveyResponseApi: {
        isProcessing: false,
        data: {},
        error: false,
        message: ''
    },
    createAnswerSetApi: {
        isProcessing: false,
        error: false,
        message: ''
    },
    surveyCompleted: false,
    isPrevClicked: false,
    indexDetails: {},
    hideErrorBlock: false,
    errorText: ""

});