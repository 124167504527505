import { fetchPayload } from "../../../utils/reducerOperations";
import {
    GET_STORYBOARD_TOPICS_FAILED,
    GET_STORYBOARD_TOPICS_START,
    GET_STORYBOARD_TOPICS_SUCCESS,
    MODIFY_TOPIC_STORY
} from "../../constants";
import {
    getStoryboardTopicsFailed,
    getStoryboardTopicsStarted,
    getStoryboardTopicsSuccess,
    updateStoryboardTopics
} from "./Helpers";
import { Default } from "./InitialState";
const StoryboardTopicReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case GET_STORYBOARD_TOPICS_START:
            return getStoryboardTopicsStarted(state, payload);
        case GET_STORYBOARD_TOPICS_SUCCESS:
            return getStoryboardTopicsSuccess(state, payload);
        case GET_STORYBOARD_TOPICS_FAILED:
            return getStoryboardTopicsFailed(state, payload);
        case MODIFY_TOPIC_STORY:
            return updateStoryboardTopics(state,payload)
        default:
            return { ...state };
    }
};

export default StoryboardTopicReducer;
