import { newJson } from "../../../../utils/newJson";

export function updateTextAnswer(state,payload){
    const textAnswer = payload.event.target.value
    const data = newJson(state.questionSet);
    const originalData = state.questionSet;
        let answeredBlock = undefined;
        let answeredQuestion = undefined;
        originalData.forEach((block, blockIndex) => {
            block && block.questions && block.questions.forEach((item, questionIndex) => {
                if (payload.questionDetails.qid === item.qid) {
                    answeredBlock = blockIndex;
                    answeredQuestion = questionIndex;
                }
            });
        });
        data[answeredBlock].questions[answeredQuestion].answer = JSON.parse(data[answeredBlock].questions[answeredQuestion].answer);
        data[answeredBlock].questions[answeredQuestion].answer.forEach(item => {
            if ( item.isOtherOption) {
                item.textAnswer = textAnswer;
            }
        });
        data[answeredBlock].questions[answeredQuestion].answer=JSON.stringify(data[answeredBlock].questions[answeredQuestion].answer)
        return {
            ...state,
            questionSet: data,
        };
    
}