import { fetchPayload } from "../../../utils/reducerOperations";
import {
    MANAGE_VIEWERS_FETCH_LIST_VIEWERS_STARTED,
    MANAGE_VIEWERS_FETCH_LIST_VIEWERS_SUCCESS,
    MANAGE_VIEWERS_FETCH_LIST_VIEWERS_FAILED,
} from "../../constants";
import {
  listViewersStarted,
  listViewersSuccess,
  listViewersFailed,
} from "./Helper"
import { IniialState } from "./InitialState";

const NewdashboardListViewerReducer = (state = IniialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case MANAGE_VIEWERS_FETCH_LIST_VIEWERS_STARTED:
      return listViewersStarted(state, payload);
    case MANAGE_VIEWERS_FETCH_LIST_VIEWERS_SUCCESS:
      return listViewersSuccess(state, payload);
    case MANAGE_VIEWERS_FETCH_LIST_VIEWERS_FAILED:
      return listViewersFailed(state, payload);
    default:
      return { ...state };
  }
};

export default NewdashboardListViewerReducer;
