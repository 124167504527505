import React, { Component } from 'react'
import { DASHBOARD_TABS } from '../../../../../../../config/tabConstants'
import GraphLoader from '../../../../../../Components/ContentLoaders/GraphLoader'
import { getSelectedUserData } from '../../helper'
import { GraphSection } from './children/GraphSection'
import { checkIfApiCallRequired, getApiPayload, getFilterData } from './helpers'

export default class MultiSelect extends Component {

  componentDidMount() {
    if (!this.props.multiSelectDiagramApi.isAlreadyFetched) {
      this.callApi()
    }
  }

  callApi = () => {
    this.props.fetchMultiQuestionsDiagramData(
      {
        ...getApiPayload(),
        ...getFilterData(),
        ...getSelectedUserData(),
        currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.MULTIPLE_QUESTIONS
      }
    )
  }

  componentDidUpdate() {
    if (checkIfApiCallRequired(this)) {
      if (!this.props.multiSelectDiagramApi.isProcessing) {
        this.callApi()
      }
    }
  }

  getData = () => {
    let totalData = this.props.multiSelectDiagramApi.data && this.props.multiSelectDiagramApi.data.length ? this.props.multiSelectDiagramApi.data : []
    let selectedMenu = this.props.selectedMenu
    let data = []
    totalData.forEach(item => {
      if (item.key === selectedMenu) {
        data.push(item)
      }
    })
    return data
  }

  render() {
    return (
      <div>
        {this.props.multiSelectDiagramApi.isProcessing ?
          <GraphLoader width={600} height={400} />
          : <GraphSection
            threshold={this.props.thresholdApi}
            data={this.getData()}
            highestScore={this.props.multiSelectDiagramApi.highestScore || 0}
          />
        }
      </div>
    )
  }
}
