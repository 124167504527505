import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const visionTemplate = [
    {
        questionDimension: 'Vision',
        type: 'Matrix',
        questionLabel: 'Vision',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: 'My organization has a good long-term future.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My organization has a good long-term future.'),
        },

        {
            text: `I could explain my organization's vision to someone who doesn't work here.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`I could explain my organization's vision to someone who doesn't work here.`),

        },
        {
            text: `I am inspired by my organization's vision.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`I am inspired by my organization's vision.`),

        },
        {
            text: `This organization's vision is realistic and achievable.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`This organization's vision is realistic and achievable.`),

        }]
    }
]

