import {
    makeKey,
    update,
    getSuccessResponse,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';

const fetchSpreadAnalysisApi='fetchSpreadAnalysisApi';
export const fetchSpreadAnalysisSuccess = (state, payload) => {
    return makeKey(
        fetchSpreadAnalysisApi,
        update(state.fetchSpreadAnalysisApi,
            getSuccessResponse(payload))
    );
};

export const fetchSpreadAnalysisStarted=(state, payload)=>{
    return makeKey(
        'fetchSpreadAnalysisApi',
        update(state.fetchSpreadAnalysisApi, {
            isProcessing: true
        })
    );
};

export const fetchSpreadAnalysisFailed=(state, payload)=>{
    let response = makeKey(
        'fetchSpreadAnalysisApi',
        update(state.fetchSpreadAnalysisApi, {
            ...(payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload))
        })
    );

    return response;
};