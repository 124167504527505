import {
    makeKey,
    update,
    getSuccessResponse,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
export const fetchOutliersTableSuccess = (state, payload) => {
    return makeKey(
        'fetchOutliersDataApi',
        update(state.fetchOutliersDataApi,
            getSuccessResponse(payload))
    );
};

export const fetchOutliersStarted=(state, payload)=>{
    return makeKey(
        'fetchOutliersDataApi',
        update(state.fetchOutliersDataApi, {
            isProcessing: true
        })
    );
};

export const fetchOutliersFailed=(state, payload)=>{
    let response = makeKey(
        'fetchOutliersDataApi',
        update(state.fetchOutliersDataApi, {
            ...(payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload))
        })
    );

    return response;
};