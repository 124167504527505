import { deepCopy } from "../../../../utils/deepCopy";
import { inputConditionCheck } from "../../../../utils/heatmapUtils";

export const setDeltaRangeValue = (state, payload) => {
  const { index, id, value } = payload;
  const intValue = value.trim() === "" ? "" : parseInt(value, 10); 
  const thresholdValue = deepCopy(state.heatmapConfiguration.thresholdValue);

  thresholdValue.forEach((item, currentIndex) => {
    if (currentIndex === index) {
      thresholdValue[currentIndex] = { ...item, value: intValue };

      if (currentIndex - 1 !== -1) {
        thresholdValue[currentIndex - 1] = {
          ...thresholdValue[currentIndex - 1],
          maxValue: intValue - 1,
        };
      }
    }
  });

  const { errorMessage, isError } = inputConditionCheck(value, id, thresholdValue);

  return {
    ...state,
    heatmapConfiguration: {
      ...state.heatmapConfiguration,
      thresholdValue: thresholdValue,
      errorMessage: errorMessage,
      isError:isError,
    },
  };
};