export function IniialState() {
  return {
    TrendBenchMarkDataApi: {
      isProcessing: false,
      data: null,
      error: false,
      message: ''
    },
    UpdateTrendBenchMarkDataApi: {
      isProcessing: false,
      data: null,
      error: false,
      message: ''
    },
    isTrendBenchMarkDataAlreadyFetched: false
  };
}