import React from 'react';
import CustomModal from '../../../Components/CustomModal';
import { TextInput } from '../../../FormElements/TextInput';
export default class RenamePeople extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            peopleName:""
        }
    }
     handleChange = (e) => {
        this.setState({
            peopleName: e.target.value,
             });
        this.props.onClickRename(e.target.value);
      };
  render() {
    return (
        <CustomModal
        confirmLoading={this.props.loading}
        title={this.props.heading}
        visible={this.props.visibleConfirm}
        okText={'Rename'}
        okButtonProps={{
          disabled: !this.props.peopleName.trim() || this.props.loading,
          loading: this.props.loading,
        }}
        onOk={this.props.onOk}
        onCancel={this.props.onCancel}
      >
        <ul className="clb-model-form">
          <li>
            <TextInput
              autoFocus={true}
              value={this.props.peopleName}
              placeholder={'Rename'}
              handleChange={this.handleChange}
            />
          </li>
        </ul>
      </CustomModal>
    );
  }
}
