import React from "react";
import FormField from "../../../Components/FormField";

export const AddAdmin = (props) => {
  return (
    <div className="content-block">
      <ul className='vertical-list'>
        {getRows(props)}
      </ul>
    </div>
  );
};

const getRows = (props) => {
  return props.addAdminBoxConfiguration.map((addAdminField, addAdminIndex) => (
    <li key={addAdminIndex}>
      <FormField
        index={addAdminIndex}
        labelRequired={true}
        required={addAdminField.name === "lastName" ? false : true}
        field={addAdminField}
        onEnter={props.onEnter}
        loginFormChange={props.adminFormChange}
        isEmailField={addAdminField.name === "adminEmail" ? true : false}
      />
    </li>
  ));
};
