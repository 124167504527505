import MultiOptionQuestions from "./children/MultipleOptionQuestions"
import MatrixQuestions from "./children/MatrixQuestions"
import DemographicsQuestion from "./children/DemographicsQuestion"
import DescriptiveSection from "./children/DescriptiveSection"
import OpenEndedQuestions from "./children/OpenEndedQuestions"
import React from "react"
import FinalPage from "./children/FinalPage"
import TranslationSection from "./children/TranslationSection"
import RankingQuestion from "./children/RankingQuestion"
import RatingScaleQuestion from "./children/RatingScaleQuestion"
import TableMatrix from "./children/TableMatrix"
import NetPromoterScore from "./children/NetPromoterScore"
import FeedBackRanking from "./children/FeedBackRanking"
import FeedbackTextEntry from "./children/FeedbackTextEntry"
import SummaryRating from "./children/SummaryRating"

export const getComponent = (type, props) => {
    let components = getChildren({ ...props })
    return components[type]
}

const getChildren = (props) => {
    return (
        {
            "matrix": <TableMatrix {...props} />,
            "descriptive": <DescriptiveSection {...props} />,
            "textEntry": <OpenEndedQuestions {...props} />,
            "multipleChoice": <DemographicsQuestion {...props} />,
            "multipleOption": <MultiOptionQuestions {...props} />,
            "Final Page": <FinalPage {...props} />,
            "Translation Page": <TranslationSection {...props} />,
            "yesOrNo": <DemographicsQuestion {...props} />,
            "ranking": <RankingQuestion {...props} />,
            "ratingScale": <RatingScaleQuestion {...props} />,
            "likert": <MatrixQuestions {...props} />,
            "employerNetPromoterScore": <NetPromoterScore {...props} />,
            "priorityDrag": <FeedBackRanking {...props} />,
            "priorityComments": <FeedbackTextEntry {...props} />,
            "summaryRating": <SummaryRating {...props} />
        }

    )
}

export default {
    FINAL_PAGE: "Final Page",
    TRANSLATION_PAGE: "Translation Page",
}

export const KEY_CONSTANTS = {
    STRENGTH_SELF_MAIN_HEADING: "stengthSelfMainHeading",
    STRENGTH_MAIN_HEADING: "strengthMainHeading",
    IMPROVEMENT_SELF_MAIN_HEADING: "improvementSelfMainHeading",
    IMPROVEMENT_MAIN_HEADING: "improvementMainHeading",
    SUMMARY_PAGE_NO_BUTTON: "summaryPageNoButton",
    SUMMARY_PAGE_YES_BUTTON: "summaryPageYesButton",
    SUMMARY_PAGE_NOTE: "summaryRatingsNote",
    SUMMARY_PAGE_DETAILS: "summaryPageDetails",
    SUMMARY_PAGE_SALUTATION: "summaryPageSalutation",
    CONTINUE_BUTTON: 'continue',
    SUBMIT_SURVEY_MODAL: "surveyCompletedMessage",
    FINAL_PAGE_SUBMIT_TEXT: "surveySubmitMessage",
    SURVEY_PREVIEW_END: "surveyPreviewEndMessage",
    SURVEY_UNANSWERED_MESSAGE: "surveyUnansweredMessage",
    PLACEHOLDER_LABEL: "placeholder",
    TRANSLATED_DESCRIPTION: "translatedDescription",
    HTML_TEXT: "HTMLText",
    NORMAL_TEXT: "text",
    LABEL: "label",
    QUESTION: "question",
    HTML_QUESTION: "HTMLQuestion",
    BACK_TO_SURVEY: "back",
    SUBMIT_TEXT: "submit",
    HTML_QUESTION_SELF : "HTMLSelfQuestion",
    SELF_QUESTION:"selfQuestion",
    SELF: "self",
    DEFAULT_LANGUAGE: "English",
    ANSWER_LABEL: "answerLabel",
    SUMMARY_PAGE_HEADING: "summaryRatingHeading"
}
export const TRANSLATION_KEYS_CHINESE = [
    "summaryPageNoButton",
    "summaryPageYesButton",
    "summaryRatingsNote",
    "summaryPageDetails",
    "summaryPageSalutation",
    "stengthSelfMainHeading",
    "strengthMainHeading",
    "improvementSelfMainHeading",
    "improvementMainHeading",
    "languageSelect",
    "summaryRatingHeading"
]

export const TRANSLATION_KEYS_VALUES_SIMPLIFIED_CHINESE = {
    summaryRatingHeading: "评估总结",
    summaryPageNoButton: "不，我们继续",
    summaryPageYesButton: "是的，我想再看一下我的评分",
    summaryRatingsNote: "<h5>*注：\"被评估者 \"是指您提供评估的对象。</h5>",
    summaryPageDetails: "<h5>您对所有行为的评估选择趋同， 都选择了\"通常\" 或\"总是\"。 这将使被评估者很难区分自己的优势和需要发展的方面。</h5> <h5>在您的评估中提供更大的差异化并使用范围更广的评分来支持被评估者的成长和发展将是非常有益的。这将使被评估者更好地了解自己的优势和重点发展领域。请记住，360 反馈工具的主要目的是促进发展。</h5> <h5>我们鼓励您花点时间重新审核您的评分，尽量提供全面和完善的反馈。</h5>",
    summaryPageSalutation: "<h5>亲爱的评估者，</h5> <h5>以下是您对所有行为的评估总结<h5>",
    stengthSelfMainHeading: "<span><b>最大优势：</b>您将这些行为确定为优势（您对它们的评级为 \"通常 \"或 \"总是\"）。请结合公司的优先事项和被评估者的个人职责，从中挑出他/她需要更多使用的 前 3种行为。</span>",
    strengthMainHeading: "<span><b>最大优势：</b>您将这些行为确定为优势（您对它们的评级为 \"通常 \"或 \"总是\"）。请结合公司的优先事项和被评估者的个人职责，从中挑出他/她需要更多使用的 前 3种行为。</span>",
    improvementSelfMainHeading: "<span><b>最佳发展领域：</b>您将这些行为确定为相对需要发展的领域（您对它们的评级为 \"从不\"、\"很少\"、\"偶尔\" 和 \"有时\"）。请结合公司的优先事项和被评估者的个人职责，从中挑出他/她需要重点加强的前 3 个领域。</span>",
    improvementMainHeading: "<span><b>最佳发展领域：</b>您将这些行为确定为相对需要发展的领域（您对它们的评级为 \"从不\"、\"很少\"、\"偶尔\" 和 \"有时\"）。请结合公司的优先事项和被评估者的个人职责，从中挑出他/她需要重点加强的前 3 个领域。</span>"
}

export const TRANSLATION_KEYS_VALUES_CHINESE = {
    summaryRatingHeading: "意見回饋摘要",
    summaryPageNoButton: "不用，我們繼續",
    summaryPageYesButton: "是的，我想查看我的評分",
    summaryRatingsNote: "<h5>*註：「參與者」是指您提供回饋的對象。</h5>",
    summaryPageDetails: "<h5>您在使用 6 分量表的評分標準來對參與者*的所有列出的行為進行評分時，各項評分都相當接近。 </h5> <h5>在回饋中讓各項行為評分具更大差異，將評分範圍擴大，對參與者的成長和發展會很有幫助。這將使其能更了解他的優勢和待發展領域。請記住，此 360 度回饋工具的主要目的是促進發展。</h5><h5>我們鼓勵您花時間審視上述內容，並提供完整且全面的回饋體驗。</h5>",
    summaryPageSalutation: "<h5>親愛的回饋提供者,</h5> <h5>以下是您對所有行為的評分回饋摘要。</h5>",
    stengthSelfMainHeading: "<span><b>最佳優勢: </b>以下是您評為優勢的行為（評定為「通常」或「總是」）。請根據組織/部門的優先事項和其個人的角色，選擇三項該主管可善加運用的行為。</span>",
    strengthMainHeading: "<span><b>最佳優勢：</b>以下是您評為優勢的行為（評定為「通常」或「總是」）。請根據組織/部門的優先事項和其個人的角色，選擇三項該主管可善加運用的行為。</span>",
    improvementSelfMainHeading: "<span><b>主要待發展領域：</b> 以下是您相對來說評為待發展領域的行為（評定為「從不」、「很少」、「偶爾」和「有時」）。請根據組織/部門的優先事項和其個人的角色，選擇最重要的 3 項該主管可再進一步精進的行為，並進行排序。</span>",
    improvementMainHeading: "<span><b>主要待發展領域：</b> 以下是您相對來說評為待發展領域的行為（評定為「從不」、「很少」、「偶爾」和「有時」）。請根據組織/部門的優先事項和其個人的角色，選擇最重要的 3 項該主管可再進一步精進的行為，並進行排序。</span>"
}