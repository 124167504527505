import {
    fetchSurveysStart,
    fetchSurveysSuccess,
    fetchSurveysFail,
    setSearchTextForSurveyPage,
    setPageNumberForSurveyPage,
    setSortOrderAndSortField,
    NewSurveyPageFetchOrganizationStarted,
    NewSurveyPageFetchOrganizationSuccess,
    NewSurveyPageFetchOrganizationFailed,
    fetchProjectDetailsStart,
    fetchProjectDetailsSuccess,
    fetchProjectDetailsFail,
    createProjectStart,
    createProjectSuccess,
    createProjectFail,
    modifyProjectStatusStart,
    modifyProjectStatusSuccess,
    modifyProjectStatusFailed,
    publishProjectStarted,
    publishProjectSuccess,
    publishProjectFailed,
    fetchFeedbackProjectDetailsStart,
    fetchFeedbackProjectDetailsSuccess,
    fetchFeedbackProjectDetailsFail,
    fetchProjectsTabConfigurationStart,
    fetchProjectsTabConfigurationSuccess,
    fetchProjectsTabConfigurationFail,
} from "./Helpers";
import {
    FETCH_SURVEYS_STARTED,
    FETCH_SURVEYS_SUCCESS,
    FETCH_SURVEYS_FAILED,
    SET_SEARCH_TEXT_FOR_SURVEY_PAGE,
    SET_PAGE_NUMBER_FOR_SURVEY_PAGE,
    SET_SORT_ORDER_AND_SORT_FIELD,
    NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_START,
    NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_SUCCESS,
    NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_FAILED,
    FETCH_PROJECT_DETAILS_START,
    FETCH_PROJECT_DETAILS_SUCCESS,
    FETCH_PROJECT_DETAILS_FAIL,
    NEW_PROJECT_PAGE_CREATE_PROJECT_START,
    NEW_PROJECT_PAGE_CREATE_PROJECT_SUCCESS,
    NEW_PROJECT_PAGE_CREATE_PROJECT_FAIL,
    MODIFY_PROJECT_STATUS_START,
    MODIFY_PROJECT_STATUS_SUCCESS,
    MODIFY_PROJECT_STATUS_FAILED,
    PUBLISH_SURVEY_START,
    PUBLISH_SURVEY_SUCCESS,
    PUBLISH_SURVEY_FAILED,

    FETCH_FEEDBACK_PROJECT_DETAILS_START,
    FETCH_FEEDBACK_PROJECT_DETAILS_SUCCESS,
    FETCH_FEEDBACK_PROJECT_DETAILS_FAIL,

    FETCH_TAB_CONFIGURATION_IN_PROJECTS_START,
    FETCH_TAB_CONFIGURATION_IN_PROJECTS_SUCCESS,
    FETCH_TAB_CONFIGURATION_IN_PROJECTS_FAIL
} from "../../constants";
import { IniialState } from "./initialState";

const SurveysPageReducer = (state = IniialState(), action) => {
    switch (action.type) {
        case FETCH_SURVEYS_STARTED:
            return fetchSurveysStart(state, action);
        case FETCH_SURVEYS_SUCCESS:
            return fetchSurveysSuccess(state, action.data, action.searchText);
        case FETCH_SURVEYS_FAILED:

            return fetchSurveysFail(state, action);
        case SET_SEARCH_TEXT_FOR_SURVEY_PAGE:

            return setSearchTextForSurveyPage(state, action.searchText);
        case SET_PAGE_NUMBER_FOR_SURVEY_PAGE:

            return setPageNumberForSurveyPage(state, action.pageNumber);
        case SET_SORT_ORDER_AND_SORT_FIELD:

            return setSortOrderAndSortField(state, action.data);
        case NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_START:
            return NewSurveyPageFetchOrganizationStarted(state, action.payload);
        case NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_SUCCESS:
            return NewSurveyPageFetchOrganizationSuccess(state, action.payload);
        case NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_FAILED:

            return NewSurveyPageFetchOrganizationFailed(state, action.payload);
        case FETCH_PROJECT_DETAILS_START:
            return fetchProjectDetailsStart(state, action.payload);
        case FETCH_PROJECT_DETAILS_SUCCESS:
            return fetchProjectDetailsSuccess(state, action.payload);
        case FETCH_PROJECT_DETAILS_FAIL:
            return fetchProjectDetailsFail(state, action.payload);

        case NEW_PROJECT_PAGE_CREATE_PROJECT_START:
            return createProjectStart(state, action.payload);
        case NEW_PROJECT_PAGE_CREATE_PROJECT_SUCCESS:
            return createProjectSuccess(state, action.payload);
        case NEW_PROJECT_PAGE_CREATE_PROJECT_FAIL:
            return createProjectFail(state, action.payload);

        case MODIFY_PROJECT_STATUS_START:
            return modifyProjectStatusStart(state, action.payload);
        case MODIFY_PROJECT_STATUS_SUCCESS:
            return modifyProjectStatusSuccess(state, action.payload);
        case MODIFY_PROJECT_STATUS_FAILED:
            return modifyProjectStatusFailed(state, action.payload);

        case PUBLISH_SURVEY_START:
            return publishProjectStarted(state, action.payload);
        case PUBLISH_SURVEY_SUCCESS:
            return publishProjectSuccess(state, action.payload);
        case PUBLISH_SURVEY_FAILED:
            return publishProjectFailed(state, action.payload);


        case FETCH_FEEDBACK_PROJECT_DETAILS_START:
            return fetchFeedbackProjectDetailsStart(state, action.payload);
        case FETCH_FEEDBACK_PROJECT_DETAILS_SUCCESS:
            return fetchFeedbackProjectDetailsSuccess(state, action.data, action.searchText);
        case FETCH_FEEDBACK_PROJECT_DETAILS_FAIL:
            return fetchFeedbackProjectDetailsFail(state, action.payload);


        case FETCH_TAB_CONFIGURATION_IN_PROJECTS_START:
            return fetchProjectsTabConfigurationStart(state, action);
        case FETCH_TAB_CONFIGURATION_IN_PROJECTS_SUCCESS:
            return fetchProjectsTabConfigurationSuccess(state, action.data, action.searchText);
        case FETCH_TAB_CONFIGURATION_IN_PROJECTS_FAIL:
            return fetchProjectsTabConfigurationFail(state, action);

        default:
            return state;
    }
};

export default SurveysPageReducer;