import { getSuccessResponse } from "../../../../utils/reducerOperations";
import { toast } from "../../../../utils/messageUtils";
import { fetchFormConfigurations } from "../../../inputFormConfigurations";
export function addOrganizationSuccess(state, payload) {
  toast.success("Organization created successfully");
  return {
    addOrganizationApi: getSuccessResponse(payload),
    inputBoxConfiguration: fetchFormConfigurations(["organizationName"]),
  };
}
