export function getUserProjectsSuccess(state, action) {
    return {
        ...state,
        surveyListApi:{loading:false,data:true,list:action.data.hits,count:action.data.total},
        surveyList:action.data.hits
    };
}

export function getUserProjectsStart(state, action) {

    return {
        ...state,
        surveyListApi:{loading:true,error:false,data:false}
    };
}

export function getUserProjectsFail(state, action) {
    return {
        ...state,
        surveyListApi:{loading:false,error:true},
        surveyList:null
    };
};
