import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const outSideComfortZone = {
    "blockId": uuidv4(),
    "goalId": "dynamic360Strength",
    allowNAOption: true,
    "description": "<p>Leaders who get out of their comfort zone are models for embracing change. Below are the behaviors that help us win in this space. Please share your perspective on how often you feel the following behaviors are demonstrated by this leader.</p>",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Rarely  ",
                    "value": "1"
                },
                {
                    "label": "Not Often Enough",
                    "value": "2"
                },
                {
                    "label": "Often ",
                    "value": "3"
                },
                {
                    "label": "Almost Always  ",
                    "value": "4"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "staticQuestionId": generateStaticQuestionId("Is a genuine dual citizen of the team/function and Business Unit."),
                    "HTMLText": "<p>Is a genuine dual citizen of the team/function and Business Unit.</p>",
                    "isSelected": true,
                    "text": "Is a genuine dual citizen of the team/function and Business Unit.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Works well in complex and ambiguous situations."),
                    "HTMLText": "<p>Works well in complex and ambiguous situations.</p>",
                    "isSelected": true,
                    "text": "Works well in complex and ambiguous situations.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Uses diverse networks to drive high performance."),
                    "HTMLText": "<p>Uses diverse networks to drive high performance.</p>",
                    "isSelected": true,
                    "text": "Uses diverse networks to drive high performance.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Fosters a climate of open and candid dialogues."),
                    "HTMLText": "<p>Fosters a climate of open and candid dialogues.</p>",
                    "isSelected": true,
                    "text": "Fosters a climate of open and candid dialogues.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Creates inclusion by seeking diverse perspectives and experiences."),
                    "HTMLText": "<p>Creates inclusion by seeking diverse perspectives and experiences.</p>",
                    "isSelected": true,
                    "text": "Creates inclusion by seeking diverse perspectives and experiences.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Challenges the status quo and organizational practices that aren't productive."),
                    "HTMLText": "<p>Challenges the status quo and organizational practices that aren't productive.</p>",
                    "isSelected": true,
                    "text": "Challenges the status quo and organizational practices that aren't productive.",
                    "value": uuidv4()
                }
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Get outside comfort zone"
}