import {
    makeKey,
    update,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
import {toast} from '../../../../utils/messageUtils';
export function parseCsvFailed(state, payload) {
    let response = makeKey(
        'parseCsvApi',
        update(state.parseCsvApi, {
            ...(payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload))
        })
    );
    toast.error(response.parseCsvApi.message);
    return response;
}
