import React, { Component } from "react";
import { Breadcrumb } from "antd";
import Container  from "../../Components/Container";
import { intersection } from "lodash";
import constants from "./constants";
import SearchInput from "../SearchItem";
import "./index.scss";
export class RouteHeader extends Component {
  breadcrumbClicked = (url) => {
    if (url !== constants.ORG_NAME) this.props.history.push(url);
  };

  getClassNameForBreadCrumb = (i, label) => {
    const dataLength = this.props.navigationData
      ? this.props.navigationData.length
      : 0;
    if (i + 1 === dataLength) return constants.WITH_ACTIVE;
    else if (label() === "orgName") return "never-active-bc";
    return constants.WITHOUT_ACTIVE;
  };
  generateBreadCrumbFromData = () => {
    return (this.props.navigationData || []).map((nav, i) => {
      let routeFunction = constants.ROUTES[nav];
      let labelFunction = constants.LABELS[nav];
      let rolesFunction = constants.ROLES[nav];
      let classFunction = constants.CLASS[nav];
      const { org_id } = this.props.match ? this.props.match.params : null;
      if (
        !rolesFunction().length ||
        intersection(rolesFunction(), ['']).length//doubt
      ) {
        return (
          <Breadcrumb.Item
            className={this.getClassNameForBreadCrumb(i, classFunction)}
            onClick={() => this.breadcrumbClicked(routeFunction(org_id))}
          >
            {labelFunction()}
          </Breadcrumb.Item>
        );
      } else return null;
    });
  };
  render = () => {
    return (
      <div className="breadcrumb-wrapper">
        <Container>
          <div className="route-header-main">
              <div className="main-pageheader">
                <h1>{this.props.title?this.props.title:"Organizations"}</h1>
              </div>
              <div className="main-search">
              {this.props.title === "Organizations" && (
               <SearchInput
                  value={this.props.inputValue}
                  onChange={this.props.handleChangeInput}
                  onPressEnter={this.props.handleClickEnter}
                  clearSearch={this.props.handleClearSearch}
                />)}
                   </div>
              <div align="right">{this.props.childButton}</div>
           
          </div>
        </Container>
      </div>
    );
  };
}
