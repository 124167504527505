import { deepCopy } from "../../../../utils/deepCopy"

export const editBenchMark = (state, payload) => {
    let updatedData = getUpdatedData(state.BenchMarkDataApi.data, payload)
    return {
        ...state,
        BenchMarkDataApi: {
            ...state.BenchMarkDataApi,
            data: updatedData,

        }
    }
}

const getUpdatedData = (payload, data) => {
    let dataCopy = deepCopy(data)
    payload.forEach((element, index) => {
        if (dataCopy.isFromTopic) {
            if (!element.questionId && element.topicId === dataCopy.id) {
                element.benchmarkScore = dataCopy.value?parseInt(dataCopy.value):undefined
            }
        } else {
            if (element.questionId === dataCopy.id) {
                element.benchmarkScore = dataCopy.value?parseInt(dataCopy.value):undefined
            }
        }
    });
    return payload
}