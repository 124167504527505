import { deepCopy } from "../../../../utils/deepCopy";
import { indexBasedColorPicker } from "../../../../utils/heatmapUtils";

export const removeComponentButton = (state, payload) => {
  const { selectedItem, colors } = payload;


  const updatedThresholdValue = deepCopy(state.heatmapConfiguration.thresholdValue);
  const newUpdatedThresholdValue = updatedThresholdValue
  .filter((item) => item.id !== selectedItem.id)
  .map((item, index) => {
    return {
      ...item,
      id: (index + 1).toString(),
    };
  });

const updatedColoredArray = indexBasedColorPicker(newUpdatedThresholdValue,colors);

return {
  ...state,
  heatmapConfiguration: {
    ...state.heatmapConfiguration,
    thresholdValue: updatedColoredArray,
  },
};

};