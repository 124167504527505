export const formatComparisonLevelForDisplay = (comparisonLevels) => {
    let formattedLevelOptions = comparisonLevels.map((item) => {
        return (
            {
                label: defaultLevelsLabels[item] ? defaultLevelsLabels[item] : item,
                value: item
            }
        )
    })
    return formattedLevelOptions
}

const defaultLevelsLabels = {
    "currentGroup": "Current Group",
    "overallCompany": "Overall Company"
}