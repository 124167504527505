import { deepCopy } from '../../../../../utils/deepCopy';
import { v4 as uuidv4 } from 'uuid';

export const selectQuestions = (state, payload) => {
    return {
        ...state,
        ...selectQuestion(state, payload)
    };
};

export const editQuestions = (state, payload) => {
    return {
        ...state,
        ...editQuestion(state, payload)
    };
};


export const addQuestions = (state, payload) => {
    return {
        ...state,
        ...addQuestion(state, payload)
    };
};



const selectQuestion = (state, payload) => {
    const questionSet = deepCopy(state.questionSet);
    let questionBlock = questionSet.find(block => block.goalId === payload.goalId)
    const goals = deepCopy(state.goals)
    const question = payload.question;
    if (payload.customType) {
        questionBlock.questions[question].isSelected = payload.selected
    }
    else {
        questionBlock.questions[0].matrixQuestions[question].isSelected = payload.selected
    }
    mapQuestionsToGoals(goals, questionBlock)
    return { questionSet, goals }
}

const editQuestion = (state, payload) => {
    const questionSet = deepCopy(state.questionSet);
    const goals = deepCopy(state.goals)
    let questionBlock = questionSet.find(block => block.goalId === payload.goalId)
    const question = payload.question;
    if (payload.customType) {
        questionBlock.questions[question].question = payload.questionField
        questionBlock.questions[question].questionLabel = payload.questionLabel || payload.questionField
        questionBlock.questions[question].options = payload.options
        questionBlock.questions[question].isEdited = true
    }
    else {
        questionBlock.questions[0].matrixQuestions[question].text = payload.questionField
        questionBlock.questions[0].matrixQuestions[question].isEdited = true
    }
    mapQuestionsToGoals(goals, questionBlock)
    return { questionSet, goals }
}

const addQuestion = (state, payload) => {
    const questionSet = deepCopy(state.questionSet);
    const goals = deepCopy(state.goals)
    let questionBlock = questionSet.find(block => block.goalId === payload.goalId)
    if (payload.customType) {
        let newQuestion = {};
        if (payload.isTextEntryOnly) {
            newQuestion = getTextEntryQuestion(payload, questionBlock)
        }
        else {
            newQuestion = {
                question: payload.questionField,
                isSelected: true,
                qid: uuidv4(),
                options: payload.options,
                isCustom: true,
                questionDimension: questionBlock.dimension,
                type: 'Multiple Choice',
                questionLabel: payload.questionField,
                isDemographic: true,
                isTemplate: true
            }
        }
        questionBlock.questions.push(newQuestion);
    }
    else {
        let newQuestion = {
            text: payload.questionField,
            isSelected: true,
            isCustom: true,
            value: uuidv4(),
        }
        questionBlock.questions[0].matrixQuestions.push(newQuestion);
    }
    mapQuestionsToGoals(goals, questionBlock)
    return { questionSet, goals }
}

const getTextEntryQuestion = (payload, questionBlock) => {
    let textEntryQuestion = {
        question: payload.questionField,
        isSelected: true,
        qid: uuidv4(),
        options: [],
        type: 'Text Entry',
        questionDimension: questionBlock.dimension,
        questionLabel: payload.questionLabel,
        isTemplate: true,
        isCustom: true
    }
    return textEntryQuestion
}

const mapQuestionsToGoals = (goals, currentBlock) => {
    goals.forEach(item => {
        if (item.goalId === currentBlock.goalId) {
            item.questions = currentBlock.questions
        }
    })
}