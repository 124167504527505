import { customFormChangeSuccess } from "../../../../utils/reducerOperations";
import {  apiInitialStateAddFn } from "../../../../utils/apiStateUtils";
import { fetchFormConfigurations } from "../../../inputFormConfigurations";
import {settingNameAndEmailFormConfiguration} from "../IntialState";
export function changeFirstLastNameFormChange(state, payload) {
  return customFormChangeSuccess(
    { state: state, key: "nameAndEmailFormConfiguration" },
    payload
  );
}

export function clearChangeFirstLastNameForm(state, payload) {
  return {
    ...state,
    changeFirstLastNameApi: apiInitialStateAddFn(),
    isFormValid: false,
    nameAndEmailFormConfiguration: settingNameAndEmailFormConfiguration(fetchFormConfigurations([
      "firstName",
      "lastName",
      "email",
    ]))
  };
}