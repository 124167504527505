import { connect } from 'react-redux';
import DemographicsTopics from './DemographicsTopics';
import { withRouter } from 'react-router-dom';
import { setDemographicsTopicQuestions } from '../../../../../../../../../../redux/actions';

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {


    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DemographicsTopics));
