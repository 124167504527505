export function createAnswerSetFailed(state, payload) {
    return {
        ...state,
        createAnswerSetApi:{
            isProcessing:false,
            error: true,
            message: ''
        }
        
    };
};

export function createAnswerSetSuccess(state, payload) {
    return {
        ...state,
        createAnswerSetApi:{
            isProcessing:false,
            error: false,
            message: ''
        }
    };
};