import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const connectivityAndCollaboration = {
    "blockId": uuidv4(),
    "description": "<p>In this section, please consider the following behaviors focused on areas such as: Communication, Networks, and Influence.</p>",
    "goalId": "dynamic180Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Rarely  ",
                    "value": "1"
                },
                {
                    "label": "Not Often Enough",
                    "value": "2"
                },
                {
                    "label": "Often ",
                    "value": "3"
                },
                {
                    "label": "Almost Always  ",
                    "value": "4"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "staticQuestionId": generateStaticQuestionId("Effectively communicates ideas and perspectives to others"),
                    "HTMLText": "<p>Effectively communicates ideas and perspectives to others.</p>",
                    "isSelected": true,
                    "text": "Effectively communicates ideas and perspectives to others.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Actively listens to others’ perspectives and incorporates feedback or ideas into the decision-making process"),
                    "HTMLText": "<p>Actively listens to others’ perspectives and incorporates feedback or ideas into the decision-making process.</p>",
                    "isSelected": true,
                    "text": "Actively listens to others’ perspectives and incorporates feedback or ideas into the decision-making process.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Handles conflicts well within own team and with others across the organization"),
                    "HTMLText": "<p>Handles conflicts well within own team and with others across the organization.</p>",
                    "isSelected": true,
                    "text": "Handles conflicts well within own team and with others across the organization.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Builds and maintains strong, diverse professional relationships"),
                    "HTMLText": "<p>Builds and maintains strong, diverse professional relationships.</p>",
                    "isSelected": true,
                    "text": "Builds and maintains strong, diverse professional relationships.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Takes the time to include others and bring about different perspectives"),
                    "HTMLText": "<p>Takes the time to include others and bring about different perspectives.</p>",
                    "isSelected": true,
                    "text": "Takes the time to include others and bring about different perspectives.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Builds and maintains credibility and confidence with others"),
                    "HTMLText": "<p>Builds and maintains credibility and confidence with others.</p>",
                    "isSelected": true,
                    "text": "Builds and maintains credibility and confidence with others.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Influences stakeholders or peers without direct authority"),
                    "HTMLText": "<p>Influences stakeholders or peers without direct authority.</p>",
                    "isSelected": true,
                    "text": "Influences stakeholders or peers without direct authority.",
                    "value": uuidv4()
                },
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Connectivity & Collaboration"
}