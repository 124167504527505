import { customFormChangeSuccess } from "../../../../utils/reducerOperations";
import {  apiInitialStateAddFn } from "../../../../utils/apiStateUtils";
import { fetchFormConfigurations } from "../../../inputFormConfigurations";
export function mailTemplateChange(state, payload) {
  return customFormChangeSuccess(
    { state: state, key: "emailTemplateBoxConfiguration" },
    payload
  );
}
export function clearTemplateForm(state, payload) {
  return {
    ...state,
    addAdminApi: apiInitialStateAddFn(),
    isFormValid: false,
    emailTemplateBoxConfiguration: fetchFormConfigurations([
      "templateName",
      "template",
    ]),
  };
}

export const onEmailTemplatePagination = (state, payload) => {
  return {
    ...state,
    emailTemplatePagination: payload,
  };
};
