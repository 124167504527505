import React from 'react';
import constants from './constants';
import { ButtonElement } from "../../FormElements/ButtonElement";
import SVGIcons from '../../Components/SVGIcons';
export const DeleteButton = props => (
    <ButtonElement
        test-name='icon-btn'
        variant='link'
        disabled={props.disabled}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}
        className={(props.classList || []).join(constants.emptySpace)}>
          <SVGIcons type={'SVG-delete'} icon={setCurrentIcon(props)} />  
        <span children={props.text || null} />
    </ButtonElement>
);

function setCurrentIcon(props) {
    return props.loading
        ? constants.loadingIcon
        : props.iconName;
}