export const Default = () => ({
    cohortSurveyList: {
        isProcessing: false,
        error: false,
        reason: '',
        data: null,
        count: 0
    },
    fetchCohortSurveyChildListingApi: {
        isProcessing: false,
        error: false,
        reason: '',
        data: null,
    },
    fetchChildCohortListingInSurveyWizard: {
        isProcessing: false,
        error: false,
        reason: '',
        data: [],
        dropdownData: []
    },
    pageNumberCohort: 1,

});