import { connect } from "react-redux";
import { TrendInsights } from "./TrendInsights";
import { withRouter } from "react-router-dom";
import { NewDashboardGetQuestionsTableTrend, fetchNewDashboardTrendInsightsData } from "../../../../../../redux/actions";
const mapStateToProps = (state) => {
  return {
    getTrendProjectsApi: state.ExploreQuestionsReducer.getTrendProjectsApi,
    isTrendComparisonRecallNeeded:state.ExploreQuestionsReducer.isTrendInsightsAlreadyFetched,
    isDataFetched:state.ExploreQuestionsReducer.isDataFetched,
    ...state.DashBoardReducer,
    threshold: state.DashBoardReducer.thresholdApi.data,
    ...state.NewDashboardExploreTrendInsightsReducer,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNewDashboardTrendInsightsData: (params) =>
      dispatch(fetchNewDashboardTrendInsightsData(params)),
      NewDashboardGetQuestionsTableTrend: (params, cb) => dispatch(NewDashboardGetQuestionsTableTrend(params, cb))

  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrendInsights));
