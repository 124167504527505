import { makeKey, update } from '../../../../utils/reducerOperations';
export function filterOrganizations(data) {
    if (data && data.hits && Array.isArray(data.hits)) {
        data.Items = data.hits.filter(
            item =>
                item.hasOwnProperty('name') &&
                item.hasOwnProperty('organizationId')
        );
        return data;
    }
    return {
        hits:[],
        Items:[]
    }
}
export function fetchOrganizationSuccess(state, payload) {
    return makeKey(
        'fetchOrganizationApi',
        update(state.fetchOrganizationApi, {
            isProcessing: false,
            data: filterOrganizations(payload.data),
            message: payload.message,
            error: !payload.status
        })
    );
}