export const collaborationTemplate = [
    {
        questionDimension: 'Collaboration',
        type: 'Matrix',
        questionLabel: 'Collaboration',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: 'People freely help one another at my organization.', value: '', isSelected: true
        },
        {
            text: 'Different teams work well together to achieve common goals.', value: '', isSelected: true

        },
        {
            text: 'The organization encourages us to build connections within and across teams.', value: '', isSelected: true

        }]
    }
]


