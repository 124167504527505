import { deepCopy } from "../../../../../utils/deepCopy"
import { toast } from "../../../../../utils/messageUtils"

export const addDemographicQuestion = (state, payload) => {
    let goals = addDemographicQuestionToGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const addDemographicQuestionToGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === "demographics") {
            item.questions.splice(item.questions.length, 0, payload.data)
        }
    })
    toast.success("Question added successfully")
    return goalsCopy
}