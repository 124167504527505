export const initialState = () => {
  return {
    surveyListApi: {
      isProcessing: false,
      error: false,
      reason: '',
      surveyList: [],
    },
    surveyDetailsApi: {
      isProcessing: false,
      error: false,
      reason: '',
      surveyDetails: [],
    },
    storyboardThreshold: '',
    treeDataApi: {
      isProcessing: false,
      error: false,
      reason: '',
      treeData: [],
    },
    dashBoardFilterApi: {
      isProcessing: false,
      error: false,
      reason: '',
      facetData: { filters: [] },
    },
    storyboardFilterApi: {
      isProcessing: false,
      error: false,
      reason: '',
      facetData: { filters: [] },
    },
    thresholdApi: {
      data: null,
      isProcessing: false,
      error: true
    },
    surveyReportApi: {
      isProcessing: false,
      error: false,
      reason: '',
      surveyReport: { dimensionTable: [], itemTable: [] },
    },
    completedSurveyListApi: {
      isProcessing: false,
      error: false,
      reason: '',
      surveyList: [],
    },
    exploreOverAllParticipationDataApi: {
      data: null,
      isProcessing: false,
      error: null,
      message: null,
    },
    setProjectConfigApi: { isProcessing: false, error: false, data: null },
    storyboardElementsApi: {
      isProcessing: false,
      error: false,
      reason: '',
      storyboardElements: [],
    },
    storyboardFilterStatus: {
      isProcessing: false,
      error: false,
      reason: '',
      data: {
        topStoryFilterStatus: '',
        filters: {},
        byScore: [],
        directReporteesOnly: false,
        selectedUser: '',
        hrisFileId: ''
      },
    },
    heatmapConfiguration:{
      comparisonColumn:['overallCompany'],
      colorPalette:"palette1",
      displayColorLogic:"delta",
      isError:false,
      errorMessage:"",
      displayComparisonOptions:[],
      thresholdValue:[
        {
          id:"1",
          value:5,
          maxValue:9,
          minColor:"#FDAE61",
          maxColor:"#ABD9E9"
        },
          {
          id:"2",
          value:10,
          maxValue:14,
          minColor:"#F46D43",
          maxColor:"#74ADD1"
        },
          {
          id:"3",
          value:15,
          maxValue:15,
          minColor:"#D73027",
          maxColor:"#4575B4"
        }
      ]   
    },
    byScore: [],
    multipleSelect: [],
    filters: {},
    duplicateFilterData: [],
    dashboardTabs: []
  };
};
