import React from "react";
import "./index.scss";
import { getApiErrorMessage } from "../../utils/getApiErrorMessage";
import noData from "../../../public/img/nodat-img.jpg";
import noNetwork from "../../../public/img/no-network.jpg";
import noTableData from "../../../public/img/no-data.jpg";
import addOrganizationIcon from "../../../public/img/organization.png";
import noAccess from "../../../public/img/no-access.png";

export const EmptyState = ({
  type,
  emptyStateMessage,
  component,
  ...props
}) => {
  let customStyle = {};
  if (props.customHeight) {
    customStyle = {
      minHeight: props.customHeight + "px",
    };
  }
  return (
    <div class="empty-state" style={customStyle}>
      <div class="empty-state__content">
        <span class="empty-state__content--image">
          <img alt="error" src={getErrorImage(type, component)} />
        </span>
        <p>{getApiErrorMessage(type, emptyStateMessage)}</p>
        {getAddButtonElement(type, props)}
      </div>
    </div>
  );
};

const getErrorImage = (type, component) => {
  if (type === "Network Error") {
    return noNetwork;
  } else if (component === "table") return noTableData;
  else if (component === "filter") return noData;
  else if (component === "addOrganization") return addOrganizationIcon;
  else if (component ==="noAccess") return noAccess
  return noData;
};

const getAddButtonElement = (type, props) => {
  if (type === "addOrganization")
    return <a href={false} className={""} onClick={() => props.addButtonClick()}><span>Add organization</span></a>;
};
