import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers";
import { createStore, applyMiddleware, compose } from "redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./Components/ProtectedRoute";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { ToastContainer } from "react-toastify";
import * as serviceWorker from "./serviceWorker";
import { MAIN_ROUTES } from "../config/constants";
import {
  PREVIEW_PROJECT_PATH,
  NEW_DASHBOARD,
  SCREENSHOT_PATH_FOR_PDF
} from "./utils/routePath";
import { ResourceLoader } from "./Components/ResourceLoader";
import { MainFrame } from "./Routes/MainFrame";
// import Feedback360ResultsPage from "./Routes/Feedback360ResultsPage"
const Login = lazy(() => import("./Routes/Login"));
const VerifyPage = lazy(() => import("./Routes/VerifyPage"));
const WelcomePage = lazy(() => import("./Routes/WelcomePage"));
const ResetPassword = lazy(() => import("./Routes/ResetPassword"));
const UserHomeRoute = lazy(() => import("./Routes/UserHomeRoute"));
const EmployeeSurveys = lazy(() => import("./Routes/EmployeeSurveys"));
const NarrativeSense = lazy(() => import("./Routes/NarrativeSense"));
const NotFoundComponent = lazy(() => import("./Components/NotFoundComponent"));

const CsvDownload = lazy(() => import("./Routes/CsvDownload"));
const NLPDashboard = lazy(() => import("./Routes/NewDashboard"));
const RedirectComponent = lazy(() => import("./Routes/RedirectComponent"))
const NoAccessState = lazy(() => import("./Components/NoAccess"))
const NewForgotPassWord = lazy(() => import('./Routes/NewForgotPassword'))
const SurveyAnsweringPage = lazy(() => import("./Routes/SurveyAnsweringPage"))
const ResultsPage = lazy(() => import("./Routes/ResultsPage"))
const Feedback360ResultsPage = lazy(() => import("./Routes/Feedback360ResultsPage"))
const cohortSuvey = lazy(()=> import("./Routes/CohortSuvey/cohortSuvey"))
const PdfScreenshot = lazy(() => import("./Routes/PdfScreenshot"))

const Routes = [
  { key: 1, path: MAIN_ROUTES.ATTEND_SURVEY, component: SurveyAnsweringPage },
  { key: 2, path: MAIN_ROUTES.LOGIN, component: Login },
  { key: 3, path: MAIN_ROUTES.RESET_PASSWORD, component: ResetPassword },
  { key: 4, path: MAIN_ROUTES.FORGOT_PASSWORD, component: NewForgotPassWord },
  { key: 5, path: MAIN_ROUTES.VERIFY_PASSWORD, component: VerifyPage },
  { key: 11, path: MAIN_ROUTES.MANAGE_ORG, component: MainFrame },
  { key: 23, path: NEW_DASHBOARD, component: NLPDashboard },
  { key: 15, path: MAIN_ROUTES.USER_HOME, component: UserHomeRoute },
  { key: 21, path: PREVIEW_PROJECT_PATH, component: SurveyAnsweringPage },
  { key: 40, path: MAIN_ROUTES.FEEDBACK_360_RESULT, component: Feedback360ResultsPage },
  { key: 80, path: SCREENSHOT_PATH_FOR_PDF, component: PdfScreenshot }

];
const welcomPageRoutes = [
  { key: 40, path: MAIN_ROUTES.RESULTS_PAGE, component: ResultsPage },
  { key: 30, path: MAIN_ROUTES.NO_ACCESS, component: NoAccessState },
  { key: 17, path: MAIN_ROUTES.DASHBOARD, component: WelcomePage },
  { key: 19, path: MAIN_ROUTES.NARRATIVE_SENSE, component: NarrativeSense },
  { key: 20, path: MAIN_ROUTES.EMPLOYEE_SURVEY, component: EmployeeSurveys },
];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middleware = []
if (window.location.hostname === "app.culturebie.com" ||
  window.location.hostname === "www.app.culturebie.com") {
  middleware = [...middleware, thunk]
} else {
  middleware = [...middleware, thunk, logger];
}

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <Router>
        <Suspense fallback={<ResourceLoader />}>
          <Switch>
            {Routes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                component={route.component}
              />
            ))}
            <Route
              exact
              path="/"
              component={
                window.location.hostname === "app.culturebie.com" ||
                  window.location.hostname === "www.app.culturebie.com"
                  ? RedirectComponent
                  : RedirectComponent
              }
            />
            {window.location.hostname === "app.culturebie.com" ||
              window.location.hostname === "www.app.culturebie.com"
              ? welcomPageRoutes.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  component={route.component}
                />
              ))
              : welcomPageRoutes.map((route) => (
                <ProtectedRoute
                  key={route.key}
                  path={route.path}
                  component={route.component}
                />
              ))}
            <ProtectedRoute key={22} path="/downloadReportCsv/:fileName" component={CsvDownload} />
            <Route path="*" exact={true} component={NotFoundComponent} />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
    <ToastContainer />
  </React.Fragment>,
  document.getElementById("root")
);

serviceWorker.unregister();
