import { ApiBase } from "../../../utils/apiBase";
import { FETCH_OUTLIERS_URL } from "../../../../config/constants";
import {
  EXPLORE_FETCH_OUTLIERS_DATA_START,
  EXPLORE_FETCH_OUTLIERS_DATA_SUCCESS,
  EXPLORE_FETCH_OUTLIERS_DATA_FAILED,
} from "../../constants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const exploreFetchOutliersData = (params) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(fetchOutliersStart());
    apiBaseInstance.instance
      .post(FETCH_OUTLIERS_URL, { ...params, ...getProjectIdAndOrgId(params) })
      .then((res) => {
        dispatch(fetchOutliersSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchOutliersFailure(err));
      });
  };
};

const fetchOutliersStart = () => {
  return {
    type: EXPLORE_FETCH_OUTLIERS_DATA_START,
  };
};

const fetchOutliersSuccess = (res) => {
  return {
    type: EXPLORE_FETCH_OUTLIERS_DATA_SUCCESS,
    payload: res,
  };
};

const fetchOutliersFailure = (error) => {
  return {
    type: EXPLORE_FETCH_OUTLIERS_DATA_FAILED,
    payload: { errorMessage: error },
  };
};
