import { Input, Radio } from 'antd'
import React, { Component } from 'react'
import parse from "html-react-parser";
import { getQuestionTranslation } from '../../../../../../utils/newAttendSurveyUtils';
import { KEY_CONSTANTS } from '../../constants';
import "./index.scss";
import ErrorBlock from '../ErrorBlock';
import { AsterikLabel } from '../../../../../../Components/AsterikLabel';
import { getClassNameForMatrix } from '../../helper';
import ScrollBar from '../../../../../../Packages/ScrollBar';
import { TextInput } from '../../../../../../FormElements/TextInput';

export default class DemographicsQuestion extends Component {

  updateDemographicsAnswer = (event, questionDetails) => {
    let selectedOptionObject = this.updateOtherOptionValue(event,questionDetails)
    this.props.updateAnswer({ value: JSON.stringify([selectedOptionObject]), question: questionDetails, blockId: this.props.questionDetails.blockId, isFromMultipleOption: true })

    setTimeout(() => {
      this.props.updateCurrentIndex()
    }, 400)
  }

  updateOtherOptionValue=(event,questionDetails)=>{
   let optionObject = questionDetails.options.find(item => item.value === event.target.value)
   if(optionObject.isOtherOption){
    return {answer:event.target.value,isOtherOption:optionObject.isOtherOption,textAnswer:""}
   }
   else{
    return{answer:event.target.value}
   }
  }

  getColorForSelectedAnswer = (value, answer) => {
    if (answer && Array.isArray(answer)) {
      let findAnswerIndex = answer.findIndex((item) => item.answer === value)
      if (findAnswerIndex != -1) {
        return "item-active"
      }
    }
    return ""
  }


  getOptions = (questionDetails) => {
        const isOtherOptionEnabled = questionDetails.options.find(item => item.isOtherOption);
        let answerArray
        if (this.props.currentQuestionSet.answer) { 
           answerArray = JSON.parse(this.props.currentQuestionSet.answer);
      }    
    const isOtherOptionSelected = isOtherOptionEnabled &&
    answerArray &&
    answerArray.length > 0 &&
    answerArray[0].isOtherOption;
    return (
      <>
      <Radio.Group
        className='answer-generation'
        value={answerArray && answerArray.length > 0 && answerArray[0].answer}
        name="radiogroup" onChange={(event) => { this.updateDemographicsAnswer(event, questionDetails) }}>
        {questionDetails.options.map((item, index) => {
          return <div className={this.getColorForSelectedAnswer(item.value, answerArray) + ' answer-generation__element'}>
            <Radio className='generation-item' key={item.id} value={item.value}>
              <div className='answer-text'> {getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: KEY_CONSTANTS.LABEL })}</div>
            </Radio>
          </div>
        })}
   {isOtherOptionSelected && (
        <div className='otherOption-textInput'>
            <TextInput
               value={answerArray && answerArray.length > 0 && answerArray[0].textAnswer}
                placeholder="Enter other option"
                disabled={!isOtherOptionSelected}
                handleChange={(event) => this.props.updateTextAnswer({event, questionDetails})}
            />
         </div>
        )}
      </Radio.Group>
   
      </>
    )
  }

  render() {
    return (
      <>
        <div className='newsurvey-block '>
        <div className="demographicradio-block">
            {this.props.questionDetails.questions.map((item, index) => {
              return <>
                <div className='demographicradio-block__header'>
                  <h3 className={this.props.questionDetails.isRequired ? 'heading-asterisk' : ""}>
                    {this.props.selectedLanguage === KEY_CONSTANTS.DEFAULT_LANGUAGE ? parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: item.HTMLQuestion ? KEY_CONSTANTS.HTML_QUESTION : KEY_CONSTANTS.QUESTION })) : <p>{parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: item.HTMLQuestion ? KEY_CONSTANTS.HTML_QUESTION : KEY_CONSTANTS.QUESTION }))}</p>}
                  </h3>
                  {item.description && item.description.length > 0 && <p>{item.description}</p>}
                </div>
                <div className='demographicradio-block__content'>
                {this.getOptions(item)}
                </div>
              </>
            })}
          <ErrorBlock manageScroller={this.props.manageScroller}/>
        </div>
        <div>
        </div>
        </div>
      </>

    )
  }
}
