export function fetchSnashotDataSuccess(state, action) {

    return {
        ...state,
        demographicSnapshotApi:{error:false,loading:false},
        demographicSnapshotData:action.data,
        demographicSnapshotColumns:(action.columns||[]).map(i=>({label:i,value:i})),
        demographicSnapshotDataFields:getDataFields(action.data)
    };
}

export function fetchSnashotDataStart(state, action) {
    return {
        ...state,
        demographicSnapshotApi:{loading:true,error:false},
        demographicSnapshotColumns:(action.clear
            ?null:state.demographicSnapshotColumns)
    };
}

export function fetchSnashotDataFail(state, action) {
    return {
        ...state,
        demographicSnapshotApi:{loading:false,error:true}
    };
};


function getDataFields(data=[]){
    const fields=[];
    Object.keys(data[0]||[]).forEach(key=>{
        if(key!=='demographic')fields.push(key);
    });
    return fields;
}