import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const competenceTemplate = [
    {
        questionDimension: 'Competence',
        type: 'Matrix',
        questionLabel: 'Competence',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: 'I feel like I can accomplish anything at work.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I feel like I can accomplish anything at work.')
        },
        {
            text: 'My work challenges me in a good way.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My work challenges me in a good way.')

        },
        {
            text: 'I feel accomplished after a day of work.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I feel accomplished after a day of work.')

        },
        {
            text: 'I am good at what I do at work.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I am good at what I do at work.')

        }]
    }
]

