import { Default } from "./InitialState";
import {
    FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_START,
    FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_SUCCCESS,
    FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_FAIL,
    FETCH_FEED_BACK_360_MATIX_VISUALIZATION_DATA_START,
    FETCH_FEED_BACK_360_MATIX_VISUALIZATION_DATA_SUCCCESS,
    FETCH_FEED_BACK_360_MATIX_VISUALIZATION_DATA_FAIL,
    FETCH_FEED_BACK_360_CIRCUMPLEX_DATA_START,
    FETCH_FEED_BACK_360_CIRCUMPLEX_DATA_SUCCESS,
    FETCH_FEED_BACK_360_CIRCUMPLEX_DATA_FAIL
}
    from '../../constants'
import {
    fetchResultsDataStart, fetchResultsDataFail, fetchResultsDataSuccess, fetchVisualizationMatrixDataStart,
    fetchVisualizationMatrixDataSuccess,
    fetchVisualizationMatrixDataFail,
    fetchCircumplexDataSuccess,
    fetchCircumplexDataStart,
    fetchCircumplexDataFail
} from "./Helper";
const FeedBack360Reducer = (state = Default(), action) => {
    switch (action.type) {
        case FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_START:
            return fetchResultsDataStart(state, action.payload);
        case FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_SUCCCESS:
            return fetchResultsDataSuccess(state, action.payload);
        case FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_FAIL:
            return fetchResultsDataFail(state, action.payload);

        case FETCH_FEED_BACK_360_MATIX_VISUALIZATION_DATA_START:
            return fetchVisualizationMatrixDataStart(state, action.payload);
        case FETCH_FEED_BACK_360_MATIX_VISUALIZATION_DATA_SUCCCESS:
            return fetchVisualizationMatrixDataSuccess(state, action.payload);
        case FETCH_FEED_BACK_360_MATIX_VISUALIZATION_DATA_FAIL:
            return fetchVisualizationMatrixDataFail(state, action.payload);

        case FETCH_FEED_BACK_360_CIRCUMPLEX_DATA_START:
            return fetchCircumplexDataStart(state,action.payload)
        case FETCH_FEED_BACK_360_CIRCUMPLEX_DATA_SUCCESS:
            return fetchCircumplexDataSuccess(state,action.payload)
        case FETCH_FEED_BACK_360_CIRCUMPLEX_DATA_FAIL:
            return fetchCircumplexDataFail(state,action.payload)


        default:
            return { ...state }
    }
}

export default FeedBack360Reducer