
export const modifyProjectStatusStart = (state,payload)=>{
    return{
        ...state,
        stopResponseCollectionApi:{
            data:null,
            isProcessing:true,
            error:false,
            message:null
        }
    };
}

export const modifyProjectStatusSuccess = (state,payload)=>{
    return{
        ...state,
        stopResponseCollectionApi:{
            data:payload.data,
            isProcessing:false,
            error:false,
            message:payload.message
        }
    };
}

export const modifyProjectStatusFailed = (state,payload)=>{
    return{
        ...state,
        stopResponseCollectionApi:{
            data:false,
            isProcessing:false,
            error:true,
            message:payload.message
        }
    };
}