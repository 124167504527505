import { URL_DATA } from "../../../../../../../config/urlDataConstants";
import { getURLFilterAndByScore } from "../../../../../../utils/byScoreFilterUtils";
import { checkConnectedProjects } from "../../../../../../utils/trendUtils";
import { getDataFromUrlToken } from "../../../../../../utils/urlDataStore";
import { getSelectedUserData } from "../../helper";

export const fetchDriverDataPayload = (instance,outcome) => {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER) || {};
    const selectedUserData = getSelectedUserData();
    let filterData = getURLFilterAndByScore(urlFilter)
    const projectId =
        instance.props.surveyDetailsApi &&
        instance.props.surveyDetailsApi.surveyDetails &&
        instance.props.surveyDetailsApi.surveyDetails.projectId;
    const hrisFileId =
        instance.props.surveyDetailsApi &&
        instance.props.surveyDetailsApi.surveyDetails &&
        instance.props.surveyDetailsApi.surveyDetails.hrisFileId;
    let payload = {
        projectId: projectId,
        ...filterData,
        outcomeVariable:outcome ,
        topics : [outcome],
        directReporteesOnly: selectedUserData.directReporteesOnly,
        selectedUser: selectedUserData.selectedUser,
        hrisFileId: hrisFileId
    };
    let trendData = checkConnectedProjects(
        instance.props.surveyDetailsApi && instance.props.surveyDetailsApi.surveyDetails
    );
    if (trendData.length > 0) {
        payload.connectedProjects = trendData;
    }
    return payload
}