import {
    update,
    makeKey,
} from '../../../../utils/reducerOperations';

export function deleteActiveUserStarted(state, payload) {
    return makeKey(
        'deleteUserApi',
        update(state.deleteUserApi, {
            isProcessing: true,
            userId: payload.userId
        })
    );
}
