import {
  EXPLORE_TOPIC_ADMIN_BARCHART_DATA_START,
  EXPLORE_TOPIC_ADMIN_BARCHART_DATA_SUCCESS,
  EXPLORE_TOPIC_ADMIN_BARCHART_DATA_FAILED,
} from '../../constants';
import { FETCH_SCORES_URL } from '../../../../config/constants';
import { ApiBase } from '../../../utils/apiBase';
import { withType } from '../../../utils/actionOperations';
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchAdminBarchartData = (payload, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(EXPLORE_TOPIC_ADMIN_BARCHART_DATA_START, {}));
    apiBaseInstance.instance
      .post(FETCH_SCORES_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        cb && cb();
        dispatch(
          withType(EXPLORE_TOPIC_ADMIN_BARCHART_DATA_SUCCESS, {
            data: response.data && response.data.data, outcome: payload.outcome
          })
        );
      })
      .catch((err) => {
        dispatch(
          withType(EXPLORE_TOPIC_ADMIN_BARCHART_DATA_FAILED, {
            error: err,
          })
        );
      });
  };
};
