import { DELETE_REMINDER_URL } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';
import { toast } from "../../../../utils/messageUtils";
import constants from './constants';

export const deleteReminder = (payload,callback) => {
    const apiBaseInstance = new ApiBase().instance;
    return dispatch => {
        apiBaseInstance
            .post(DELETE_REMINDER_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then((res) => {
                toast.success(constants.DELETE_REMINDER_SUCCESS_MESSAGE);
                callback&&callback(true,{...payload})
            })
            .catch(err => {
                callback&&callback(false,{...payload})
                toast.error(constants.DELETE_REMINDER_FAILURE_MESSAGE);
            });
    };
};
