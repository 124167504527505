import {
    FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_START,
    FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_SUCCCESS,
    FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_FAIL
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { GET_FEED_BACK_360_RESULTS_PAGE_DATA } from "../../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchFeedback360ResultsPageData = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
        dispatch(withType(FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_START, {}));
        apiBaseInstance.instance
            .post(GET_FEED_BACK_360_RESULTS_PAGE_DATA, { ...getProjectIdAndOrgId(payload), ...payload })
            .then((response) => {
                dispatch(
                    withType(FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_SUCCCESS, { ...response.data, ...payload })
                );
                cb && cb(true, response.data);
            })
            .catch((err) => {
                cb && cb();
                dispatch(
                    withType(FETCH_FEED_BACK_360_RESULTS_PAGE_DATA_FAIL, {
                        error: err,
                    })
                );
            });
    };
};