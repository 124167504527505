import { URL_DATA } from "../../../../../../../config/urlDataConstants"
import { checkIfTwoArraysAreIsEqual, getURLFilterAndByScore } from "../../../../../../utils/byScoreFilterUtils"
import { checkIfObjectsAreEqual } from "../../../../../../utils/customFunctions"
import { getDataFromUrlToken } from "../../../../../../utils/urlDataStore"
import { getSelectedUserData } from "../../helper"

export const getApiPayload = (props) => {
    let projectData = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS)
    let orgData = getDataFromUrlToken(URL_DATA.ORGANIZATION)
    return {
        ...projectData,
        ...orgData
    }
}

export const getFilterData = () => {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(urlFilter)
    return filterData
}

export const checkIfApiCallRequired = (instance) => {
    const selectedUserData = getSelectedUserData();
    let filterData = getFilterData()
    if (
        instance.props.multiSelectDiagramApi.isAlreadyFetched &&
        checkIfObjectsAreEqual(filterData.filters, instance.props.MultiQuestionFilters) &&
        checkIfTwoArraysAreIsEqual(filterData.byScore, instance.props.MultiQuestionbyScore) &&
        checkIfTwoArraysAreIsEqual(filterData.multipleSelect, instance.props.MultiQuestionMultipleSelect) &&
        selectedUserData.directReporteesOnly === instance.props.MultiQuestionDirectReportees &&
        selectedUserData.selectedUser === instance.props.MultiQuestionSelectedUser
    ) {
        return false;
    }
    return true;
};