import {
  COMMENTS_FETCH_SENTIMENTS_STARTED,
  COMMENTS_FETCH_SENTIMENTS_SUCCESS,
  COMMENTS_FETCH_SENTIMENTS_FAILED,
  UPDATE_COMMENT_QUESTION_ID,
  DOWNLOADS_COMMENTS_BY_BATCH_ID_START,
  DOWNLOADS_COMMENTS_BY_BATCH_ID_SUCCESS,
  DOWNLOADS_COMMENTS_BY_BATCH_ID_FAILED,
  UPDATE_COMMENTS_SEARCH_KEY
} from "../../../constants";
import {
  SENTIMENTAL_URL,
  USER_COMMENTS_URL,
} from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const commentsfetchSentiment = (payload, cb) => {
  let from= payload.from?payload.from:0
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
      let isDataAlreadyFetched;
      if (payload.isDataAlreadyFetched) {
        isDataAlreadyFetched = false;
      } else {
        isDataAlreadyFetched = true;
      }
      dispatch(
        withType(COMMENTS_FETCH_SENTIMENTS_STARTED, {
          selectedQuestionId: payload.selectedQuestionId,
          isDataAlreadyFetched: isDataAlreadyFetched,
          from:from,
          searchText:payload.data.text
        })
      );
    return apiBaseInstance
      .post(SENTIMENTAL_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        dispatch(
          withType(COMMENTS_FETCH_SENTIMENTS_SUCCESS, {
            data: {
              data: res.data.data.data,
              topics: res.data.data.filters.topic,
              filters: res.data.data.filters,
              selectedQuestionId: res.data.data.selectedQuestionId,
              data_count: res.data.data.totalNumberOfDocuments,
            },
            selectedQuestionId: payload.selectedQuestionId,
            selectedTopic: payload.data.topic[0],
            selectedfilters: payload.filters,
            byScore: payload.byScore,
            multipleSelect: payload.multipleSelect,
            pagination: payload.pagination,
            selectedUser: payload.selectedUser,
            directReporteesOnly: payload.directReporteesOnly,
            from:from,
            searchText:payload.data.text
          })
        );
        cb &&
          setTimeout(() => {
            cb();
          }, 100);
      })
      .catch((err) => {
        dispatch(withType(COMMENTS_FETCH_SENTIMENTS_FAILED, err));
      });
  };
};

export const updateCommentQuestionId = (payload) => {
  return (dispatch) => {
    dispatch(withType(UPDATE_COMMENT_QUESTION_ID, payload));
  };
};

export const downloadsCommentsByBatchID = (payload, cb) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(DOWNLOADS_COMMENTS_BY_BATCH_ID_START, {}));
    return apiBaseInstance
      .post(USER_COMMENTS_URL, {
        ...payload,
        ...getProjectIdAndOrgId(payload)
      })
      .then((res) => downloadCommentSuccess({ dispatch, res, payload }))
      .catch((err) => {
        dispatch(withType(DOWNLOADS_COMMENTS_BY_BATCH_ID_FAILED, err));
      });
  };
};

function downloadCommentSuccess({ dispatch, res, payload }) {
  const fileUrl = res.data.data.fileURL;
  const a = document.createElement("a");
  a.href = fileUrl;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  return dispatch({
    type: DOWNLOADS_COMMENTS_BY_BATCH_ID_SUCCESS,
    payload: payload,
  });
}

export const updateSearchCommentsKey = (payload) => {
  return (dispatch) => {
    dispatch(withType(UPDATE_COMMENTS_SEARCH_KEY, payload));
  };
};

