import { deepCopy } from "../../../../utils/deepCopy"
import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const listTextEntryQuestionsStarted = (state) => {
    return {
        ...state,
        listTextEntryQuestionsApi: getApiStartState()
    }

}

export const listTextEntryQuestionsSuccess = (state, payload) => {
    return {
        ...state,
        listTextEntryQuestionsApi: getSuccessResponse(payload),
        ...formatDefaultList(payload.data.data.questionTopicLibraryMapping),
    }

}

const formatDefaultList = (data) => {
    const topicJson = deepCopy(data);
    topicJson.forEach(item => {
        if (!item.hasOwnProperty('isCustom')) {
            item.isCustom = false;
        }
    })
    const questionTopicLibraryMapping = topicJson;
    const defaultList = deepCopy(topicJson);
    return { questionTopicLibraryMapping, defaultList }
}

export const listTextEntryQuestionsFailed = (state, payload) => {
    return {
        ...state,
        listTextEntryQuestionsApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        },
        questionTopicLibraryMapping: []
    }

}