import {
    DELETE_ORGANISATION_PROJECT_START,
    DELETE_ORGANISATION_PROJECT_SUCCESS,
    DELETE_ORGANISATION_PROJECT_FAIL
} from '../../constants';
import { ApiBase } from '../../../utils/apiBase';
import { 
    DELETE_ORGANISATION_PROJECT_URL 
} from '../../../../config/constants';
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export function deleteOrganisationProject(payload, callback) {
    return dispatch => {
        const Axios = new ApiBase().instance;
        dispatch({ type: DELETE_ORGANISATION_PROJECT_START });
        Axios.post(DELETE_ORGANISATION_PROJECT_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(res=>{
                dispatch({
                    type: DELETE_ORGANISATION_PROJECT_SUCCESS,
                    data:res.data
                });
                callback && callback();
            })
            .catch(e=>dispatch({
                type:DELETE_ORGANISATION_PROJECT_FAIL,
                error:e
            }));
    };
}