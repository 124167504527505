import {
    FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_START,
    FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_SUCCESS,
    FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_FAIL
} from "../../../constants";
import { GET_MULTI_SELECT_QUESTIONS_DIAGRAM } from "../../../../../config/constants";
import { withType } from "../../../../utils/actionOperations";
import { ApiBase } from "../../../../utils/apiBase";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchMultiQuestionsDiagramData = (payload, cb) => {
    const apiBaseInstance = new ApiBase().instance;
    return (dispatch) => {
        dispatch(withType(FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_START, {}));
        return apiBaseInstance
            .post(GET_MULTI_SELECT_QUESTIONS_DIAGRAM, {
                ...payload, ...getProjectIdAndOrgId(payload)
            })
            .then((res) => {
                dispatch(
                    withType(FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_SUCCESS, {
                        data: {
                            data: res.data.data,
                            filters: payload.filters,
                            byScore: payload.byScore,
                            multipleSelect: payload.multipleSelect,
                            selectedUser: payload.selectedUser,
                            directReporteesOnly: payload.directReporteesOnly,
                        },
                    }),
                );
                cb && setTimeout(() => { cb(); }, 0);
            })
            .catch((err) => {
                dispatch(withType(FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_FAIL, err));
            });
    };
};