import { fetchPayload } from "../../../utils/reducerOperations";
import {
    FETCH_STATUS_REPORT_DATA_START,
    FETCH_STATUS_REPORT_DATA_SUCCESS,
    FETCH_STATUS_REPORT_DATA_FAIL
} from "../../constants";
import { fetchStatusReportDataFailed, fetchStatusReportDataStart, fetchStatusReportDataSuccess } from "./helper"

import { IniialState } from "./InitialState";

const StatusPageReducer = (state = IniialState(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case FETCH_STATUS_REPORT_DATA_START:
            return fetchStatusReportDataStart(state, payload);
        case FETCH_STATUS_REPORT_DATA_SUCCESS:
            return fetchStatusReportDataSuccess(state, payload);
        case FETCH_STATUS_REPORT_DATA_FAIL:
            return fetchStatusReportDataFailed(state, payload);

        default:
            return { ...state };
    }
};

export default StatusPageReducer;