import { deepCopy } from "../../../../../utils/deepCopy"

export const onDeleteDisplayCondition = (state, payload) => {
    let deletedDisplayTopic = deleteDisplayCondition(state, payload)
    return {
        ...state,
        goals: deletedDisplayTopic
    }
}

const deleteDisplayCondition = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(goal => {
        if (goal.blockId === payload.blockId) {
            goal.isConditionalTopicSection = false
            goal.hrisBasedCondition = []
        }
    })
    return goalsCopy
}