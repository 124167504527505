import { newJson } from "../../../../utils/newJson"
import { getFilteredMappedSurveyDetails } from "../../../../utils/trendPairingUtils"

export const fetchTrendBenchMarkDataStart = (state) => {
    return {
        ...state,
        trendPairingDataApi: {
            ...state.trendPairingDataApi,
            isProcessing: true,
            data: null,
            error: false,
            message: ''
        }
    }

}

export const fetchTrendBenchMarkDataSuccess = (state, payload) => {
    let surveyDetails = state.listComparisonSurveyApi.data
    let filteredAlredyMappedData = getFilteredMappedSurveyDetails(surveyDetails)
    let data = payload && payload.data && payload.data.data && payload.data.data.response
    let newCurrentSurveyData = data && data.currentSurvey

    if (state.trendPairingDataApi.currentSurvey.length > 0 && !payload.isInitialCall) {
        if (payload.isDataCollection) {
            newCurrentSurveyData = getAddedWithExistingCurrentSurveyDataCollection(newCurrentSurveyData, state.trendPairingDataApi.currentSurvey)
        }
        else {
            newCurrentSurveyData = getAddedWithExistingCurrentSurvey(newCurrentSurveyData, state.trendPairingDataApi.currentSurvey)
        }
    }
    return {
        ...state,
        trendPairingDataApi: {
            isProcessing: false,
            currentSurvey: newCurrentSurveyData ? newCurrentSurveyData : [],
            unmappedSurvey: data && data.unmappedSurvey ? data.unmappedSurvey : [],
            error: false,
            message: payload.message,
            alreadyCalled: true
        },
        pairingTableDropDownValue: filteredAlredyMappedData.length > 0 ? filteredAlredyMappedData[0].projectId : undefined
    }

}

export const fetchTrendBenchMarkDataFailed = (state, payload) => {
    return {
        ...state,
        trendPairingDataApi: {
            ...state.trendPairingDataApi,
            isProcessing: false,
            data: null,
            error: payload.error,
        },
    }

}


const getAddedWithExistingCurrentSurveyDataCollection = (currentSurvey, existingCurrentSurvey) => {
    let existingSurveyCopy = newJson(existingCurrentSurvey)
    currentSurvey[0].questionSet[0].questions.forEach((item, key) => {
        if (item.type === "Multiple Choice") {
            if (item.mappingQuestions && item.mappingQuestions.length > 0) {
                let existingMapping = existingSurveyCopy[0].questionSet[0].questions[key].mappingQuestions ? existingSurveyCopy[0].questionSet[0].questions[key].mappingQuestions : []
                let updatedMappingQuestions = [...item.mappingQuestions, ...existingMapping]
                let generateUniqueArray = updatedMappingQuestions.reduce((unique, nextObj) => {
                    if (!unique.some(obj => obj.projectId === nextObj.projectId && obj.questionId === nextObj.questionId)) {
                        unique.push(nextObj);
                    }
                    return unique;
                }, []);
                existingSurveyCopy[0].questionSet[0].questions[key].mappingQuestions = generateUniqueArray
            }
        }
    }
    )
    return existingSurveyCopy
}

const getAddedWithExistingCurrentSurvey = (currentSurvey, existingCurrentSurvey) => {
    let existingSurveyCopy = newJson(existingCurrentSurvey)
    currentSurvey[0].questionSet.forEach((topics, topicIndex) => {
        if (topics.isDefault || topics.isCustomTopic) {
            topics.questions[0].matrixQuestions.forEach((question, matrixIndex) => {
                if (question.mappingQuestions && question.mappingQuestions.length > 0) {
                    let existingMapping = existingSurveyCopy[0].questionSet[topicIndex].questions[0].matrixQuestions[matrixIndex].mappingQuestions ? existingSurveyCopy[0].questionSet[topicIndex].questions[0].matrixQuestions[matrixIndex].mappingQuestions : []
                    let updatedMappingQuestions = [...question.mappingQuestions, ...existingMapping]
                    let generateUniqueArray = updatedMappingQuestions.reduce((unique, nextObj) => {
                        if (!unique.some(obj => obj.projectId === nextObj.projectId && obj.questionId === nextObj.questionId)) {
                            unique.push(nextObj);
                        }
                        return unique;
                    }, []);
                    existingSurveyCopy[0].questionSet[topicIndex].questions[0].matrixQuestions[matrixIndex].mappingQuestions = generateUniqueArray
                }
            })

        }
    })
    return existingSurveyCopy
}