import {
    PUBLISH_SURVEY_START,
    PUBLISH_SURVEY_SUCCESS,
    PUBLISH_SURVEY_FAILED,
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { PUBLISH_PROJECT_URL } from "../../../../../config/constants";
import { getDomainName } from "../../../../utils/getDomainName";
import { toast } from "../../../../utils/messageUtils";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const publishSurvey = (payload, cb) => {
    payload.sourceDomainName = getDomainName()
    let message = payload.message ? payload.message : "Survey published successfully"
    payload.message = undefined
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
        dispatch(withType(PUBLISH_SURVEY_START, {}));
        apiBaseInstance.instance
            .post(PUBLISH_PROJECT_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then((response) => {
                dispatch(
                    withType(PUBLISH_SURVEY_SUCCESS, response.data)
                );
                toast.success(message)
                cb && cb(true);
            })
            .catch((err) => {
                dispatch(
                    withType(PUBLISH_SURVEY_FAILED, {
                        error: err,
                    })
                );
                toast.error(err.reason||"Failed to publish the survey")
                cb && cb();
            });
    };
};