export function fetchTopicsDataSuccess(state, action) {
  return {
      ...state,
      topicsDataApi:{error:false,loading:false},
      topicsData:action.data,
      columns:action.columns,
      selectedColumns:Object.keys(action.data),
  };
}

export function fetchTopicsDataStart(state, action) {

  return {
      ...state,
      topicsData:null,
      topicsDataApi:{loading:true,error:false}
  };
}

export function fetchTopicsDataFail(state, action) {
  return {
      ...state,
      topicsDataApi:{loading:false,error:true}
  };
};
