import { 
  apiSuccessState, 
  apiFailureState
 } from "../../../../utils/apiStateUtils";

export function fetchTagsSuccess(state, payload) {
  return {
    ...state,
    fetchTagsApi:apiSuccessState(state.fetchTagsApi,payload.payload),
    tags:payload.payload.data.Items
  }
}

export function fetchTagsStarted(state, payload) {
  return {
    ...state,
    fetchTagsApi:{
      ...state.fetchTagsApi,
      loading:true,
      error:false
    }
  }
}

export function fetchTagsFail(state, payload) {
  return {
    ...state,
    fetchTagsApi:apiFailureState(state.fetchTagsApi,payload)
  }
}