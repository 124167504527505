import { toast } from "../../../../utils/messageUtils";

export function addTemplateSuccess(state, payload) {
  toast.success("New template created successfully");
  return {
    ...state,
    addTemplateApi: {
      loading: false,
      error: false,
    },
  };
}

export function addTemplateStarted(state, payload) {
  return {
    ...state,
    addTemplateApi: {
      loading: true,
      error: false,
    },
  };
}

export function addTemplateFail(state, payload) {
  toast.error("Failed to create new template");
  return {
    ...state,
    addTemplateApi: {
      loading: false,
      error: true,
    },
  };
}
