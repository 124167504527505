import React, { Component } from 'react'
import cbLogo from "../../../public/img/footer-logo-blue.png";
import "./index.scss";
import Container from "../../Components/Container";
import SVGIcons from '../../Components/SVGIcons';
import MailToLink from '../FeedBackModal/MailToLink';
import constants from './constants';
import { openNewTab } from './helper';

export default class Footer extends Component {

    getTopSection = () => {
        return (
            <div className='foot-block__top'>
                <div className='foot-block__top--logo'>
                    <p>
                        <img src={cbLogo} alt={'logo'} />
                    </p>
                </div>
                <div className='foot-block__top--link'>
                    {/* <a href={false} onClick={() => openNewTab(constants.PRIVACY_POLICY_PATH)} >Privacy Policy</a>
                    <a href={false} onClick={() => openNewTab(constants.TERMS_AND_CONDITION_PATH)} >Terms & Conditions</a> */}
                    <a href={false} onClick={() => openNewTab(constants.COOKIE_POLICY_PATH)} >Cookies & Privacy Policy</a>
                </div>
                <div className='foot-block__top--follow'>
                    <p>Follow Us On</p>
                    <a href={false} onClick={() => openNewTab(constants.LINKED_IN_PATH)} ><SVGIcons type={'SVG-linkedin'} /></a>

                </div>
            </div>
        )
    }

    getMiddleSection = () => {
        return (
            <div className='foot-block__center'>
                <p>
                    206 E Huron St, Ann Arbor, MI 48104
                </p>
                <MailToLink text={"support@culturebie.com"} />
            </div>
        )
    }

    getBottomSection = () => {
        return (
            <div className='foot-block__bottom'>
                © {this.getYear()} Culturebie. All rights reserved<span className='foot-block__bottom--vertical-bar'>&nbsp;|</span> <span style={{ cursor: 'pointer' }} onClick={this.redirectToReleaseUpdates}>&nbsp;v1.4.8</span>
            </div>
            
        )
    }

    redirectToReleaseUpdates = () => {
        window.open('https://www.culturebie.com/product-release-updates')//do not update
    }

    getYear = () => {
        return new Date().getUTCFullYear().toString()
    }

    render() {
        return (
            <div className='foot-block'>
                <Container>
                    {this.getTopSection()}
                    {this.getMiddleSection()}
                    {this.getBottomSection()}
                </Container>
            </div>
        )
    }
}
