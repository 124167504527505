import { FETCH_ORGANIZATION_URL } from "../../../../config/constants";
import { ApiBase } from "../../../utils/apiBase";
import {
  FETCH_ORGANIZATION_LIST_STARTED,
  FETCH_ORGANIZATION_LIST_SUCCESS,
  FETCH_ORGANIZATION_LIST_FAILED,
} from "../../constants";
import { withType } from "../../../utils/actionOperations";
import { getOrgId } from "../../../utils/localStorgeOperations";

export const fetchOrganizations = (params) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(FETCH_ORGANIZATION_LIST_STARTED, {}));
    apiBaseInstance.instance
      .post(FETCH_ORGANIZATION_URL, { ...makeParamsForListOrg(params) })
      .then((res) => {
        dispatch(withType(FETCH_ORGANIZATION_LIST_SUCCESS, res.data));
      })
      .catch((err) => {
        dispatch(withType(FETCH_ORGANIZATION_LIST_FAILED, err));
      });
  };
};

const makeParamsForListOrg = (params) => {
  if (getOrgId())
    return {
      ...params,
    };
  return { ...params };
};
