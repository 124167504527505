import { connect } from "react-redux";
import AddOrganization from "./AddOrganization";
import {
  formInputChange,
  addOrganization,
  clearOrganizationForm,
} from "../../redux/actions";
import { SelectState } from "./Selector";

const mapStateToProps = (state) => ({ ...SelectState(state) });

const mapDispatchToProps = (dispatch) => ({
  formInputChange: (event) => dispatch(formInputChange(event)),
  addOrganization: (params, cb) => dispatch(addOrganization(params, cb)),
  clearOrganizationForm: (params) => dispatch(clearOrganizationForm(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganization);
