import React from "react";
import ContentLoader from "react-content-loader";

const GraphLoader = ({ height = 100, width = 400 }) => (
  <ContentLoader
    height={height}
    width={width}
    speed={1}
    animation={true}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="30" y="30" rx="1" ry="1" width={width - 60} height={height - 60} />
  </ContentLoader>
);

export default GraphLoader;
