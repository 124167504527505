import React from "react";
import CustomModal from "../../Components/CustomModal";
import constants from "./constants";
import { UploadHRISFile } from "./UploadHRISFile";
import {
  SetLocalStorage,
} from "../../utils/localStorgeOperations";
import { ButtonElement } from "../../FormElements/ButtonElement";
import { toast } from "../../utils/messageUtils";
import { Pagination } from "../../Components/Pagination";
import { PEOPLE_PAGE_SECTION } from '../../../config/tabConstants';
import { encryptJson, getDataFromUrlToken } from '../../utils/urlDataStore';
import { URL_DATA } from '../../../config/urlDataConstants';
import { CHILD_COHORT_HRIS, FEEDBACK_360_HRIS_TYPE, PARENT_COHORT_HRIS } from "../../../config/questionTypeConstants";

export const inviteUserModal = (props) => {
  return (
    <CustomModal
      visible={props.isModalVisible}
      getContainer="#managers-modalMount"
      title={constants.INFO}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={[
        <ButtonElement
          key="submit"
          type="primary"
          className=""
          loading={props.loading}
          onClick={props.handleOk}
        >
          Upload
        </ButtonElement>,
      ]}
    >
      <UploadHRISFile
        {...props}
        isFormValid={props.isInviteFormValid}
        onEnter={props.onSubmitModal}
        inputBoxConfiguration={props.inputBoxConfiguration}
        hrisFileUploadFormChange={props.hrisFileUploadFormChange}
        fetchApi={props.fetchTemplatesForAdminApi}
      />
    </CustomModal>
  );
};

export const getHRISViewClick = (doc, props) => {
  SetLocalStorage({ hrisFileId: doc.hrisFileId });
  getHRISViewClickHistory(props, doc);
};
// function getHRISViewClickHistory(props, doc) {
//   props.history.push("people" + "?hrisFileId=" + doc.hrisFileId);
//   //props.fetchHRIS({ hrisFileId: getHrisFileId() });
// }

function getHRISViewClickHistory(props, doc) {
  let orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION)
  let feedbackHris = isFeedbackHris(doc)
  const encryptedOrgDetails = encryptJson({ orgDetails, hrisFileId: doc.hrisFileId, isFeedback360: feedbackHris });
  props.history.push({
    pathname: "people", search: `params=${encodeURIComponent(encryptedOrgDetails)}`
  });
  //props.fetchHRIS({ hrisFileId: getHrisFileId() });
}

const isFeedbackHris = (doc) => {
  if (doc.hrisFileType) {
    if (doc.hrisFileType === FEEDBACK_360_HRIS_TYPE || doc.hrisFileType === PARENT_COHORT_HRIS || doc.hrisFileType === CHILD_COHORT_HRIS) {
      return true
    }
  }
  return false

}

export const fetchDataForTree = (props) => {
  return [JSON.parse(props.fetchHrisHierarchyApi.data.hrisFilesList.hierarchy)];
};

export const submitToUpload = (props, data) => {
  props.bulkUploadStart();
  let file = props.fileToUpload;
  props.getPreSignedURL(
    {
      currentTab: PEOPLE_PAGE_SECTION.PEOPLE,
      fileName: file[0].name,
      fileType: file[0].type,
    },
    (response, success) => {
      if (success) {
        props.uploadFile(
        {
          url: response ? response.data.data.preSignedUrl : "",
          file: file[0],
          fileType: file[0].type,
        },
        (isSuccess) => {
          if (isSuccess) {
            data.addJob(
              file[0].name,
              getKeyNameForAddJob(response),
              data.fileName,
              data.cb
            );
            toast.success(constants.UPLOAD_SUCCESS_MESSAGE);
          } else {
            props.bulkUploadEnd()
            toast.error(constants.UPLOAD_ERROR_MESSAGE);
          }
        }
      );
      }
      else {
        props.bulkUploadEnd()
      }
    }
  );
};
const getKeyNameForAddJob = (response) => {
  let fileName = response ? response.data.data.fileurl : "";
  if (
    window.location.hostname === "app.culturebie.com" ||
    window.location.hostname === "www.app.culturebie.com"
  ) {
    return fileName && fileName.split("/culturebie-uploadbucket/")[1];
  } else if (window.location.hostname === "qa.culturebie.com" ||
    window.location.hostname === "www.qa.culturebie.com") {
    return fileName && fileName.split("/culturebieqa-uploadbucket/")[1];
  }
  else return fileName && fileName.split("/culturebiedev-uploadbucket/")[1];
};
export const getParamsForAddJob = () => {
  return {
    ownerEmail: localStorage.getItem("username"),
    ownerId: localStorage.getItem("userId"),
  };
};
export const getPageDivForRenderUsersList = ({
  fetchHRISApi,
  onChange,
  page,
  item,
}) => {
  if (!fetchHRISApi.data) return null;
  else if (fetchHRISApi.data.count <= 12) return null;
  return (
    <Pagination
      page={page}
      onChange={onChange}
      count={fetchHRISApi.data.count}
      item={item}
    />
  );
};
export default {
  fetchDataForTree: fetchDataForTree,
  getHRISViewClick: getHRISViewClick,
  inviteUserModal: inviteUserModal,
  submitToUpload: submitToUpload,
  getParamsForAddJob: getParamsForAddJob,
  getPageDivForRenderUsersList: getPageDivForRenderUsersList,
};
