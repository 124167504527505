import React from "react";
import constants from "./constants";
import { Tooltip } from "antd";
import { ButtonElement } from "../../FormElements/ButtonElement";
import "./index.scss";
import { SelectElement } from "../../FormElements/SelectElement";
import SVGIcons from '../SVGIcons';
import Loader from "../../Packages/Loader";

export function SelectUpload({
  title,
  index,
  api,
  onUpload,
  disable,
  options,
  dataLoading,
  onSelect,
  hideUpload,
  disableUpload,
  hrisFileId,
  defaultSelect,
  onScroll,
  showSelect,
  showUploadButton,
  clearTopicFile,
  disableClose,
  className,
  loader,
  id
}) {
  return (
    <div className="topic-main-class">
      {showSelect &&
        getDropDown(
          {
            title: title,
            loading: dataLoading,
            onSelect: onSelect,
            hrisFileId: hrisFileId,
            defaultSelect: defaultSelect,
            onScroll: onScroll,
          },
          options
        )}
      {getUploadButton(api, {
        hideUpload: hideUpload,
        disableUpload: disableUpload,
        onUpload: onUpload,
        disable: disable,
        index: index,
        clearTopicFile: clearTopicFile,
        disableClose: disableClose,
        className: className,// eslint-disable-next-line
        loader: loader && loader.children || loader,
        id: id,
        title: title
      })}
    </div>
  );
}

const getUploadButton = (api, obj) => {
  const { field } = constants;
  if (obj.hideUpload) return null;
  return (
    <div className={`inputFile ${obj.className ? obj.className : ''}`} style={getStyle(obj.disable)}>
      <ButtonElement
        className="input__icon  ant-btn-primary" disabled={obj.disableUpload || obj.loader}>
         <Tooltip placement='right' title={obj.title} overlayClassName="right-tooltip">
        <input
          className="inputFile__hidden"
          name={field.name}
          id={obj.id}
          onChange={(e) => obj.onUpload(obj.index, e)}
          type={field.type}
          title=""
        ></input>
        </Tooltip>
        {obj.loader && (
          <Loader
            classList={["xs white"]}
          />
        )}
        <SVGIcons type={'SVG-upload'} />
      </ButtonElement>

      <Tooltip title={!!(!api.loading && api.name) && api.name}>
        <span children={changeApi(api)} className="inputFile__name" />
        {!api.loading && api.name ? getCloseButton(obj) : ''}
      </Tooltip>
    </div>
  );
};

const changeApi = (api) => {
  return !!(!api.loading && api.name) && api.name.length > 20
    ? api.name.slice(0, 20) + "(...)." + api.name.split(".").pop()
    : api.name;
};

const getCloseButton = (obj) => {
  return (
    !obj.disableClose ? <span className="upload-close-icon" onClick={() => obj.clearTopicFile()}>
      <SVGIcons type={'SVG-circle-cross'} />
    </span> : null)
}

const getStyle = (disable) => {
  if (disable) return { pointerEvents: "none" };
  return {};
};
const getDropDown = (obj, options) => {
  return (
    <SelectElement
      className="topic-library-dropdown"
      placeholder={obj.title}
      value={obj.hrisFileId ? obj.hrisFileId : undefined}
      loading={obj.loading}
      onChange={obj.onSelect}
      optionList={options}
      onScroll={obj.onScroll}
      defaultValue={obj.defaultValue}
    >
      {/* <Option key='' value=''>
        <Tooltip title='Select HRIS'>No HRIS</Tooltip>
      </Option> */}
      {/* {generateOption(options)} */}
    </SelectElement>
  );
};
