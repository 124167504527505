import { fetchFormConfigurations } from "../../inputFormConfigurations";
import { setInitialState } from "../../../utils/reducerOperations";
export function Default() {
  return {
    fetchUsersApi: setInitialState(),
    changeRoleApi: setInitialState(),
    fetchHRISApi: setInitialState(),
    fetchHrisHierarchyApi: setInitialState(),
    isFormValid: false,
    hrisHRISFileValue: "",
    hrisUsersPagination:1,
    inviteUserApi: setInitialState(),
    deleteUserApi: setInitialState({ userId: null }),
    searchBoxConfiguration: fetchFormConfigurations(["search"]),
  };
}
