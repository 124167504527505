import {
  FETCH_TEMPLATE_ADMIN_LIST_STARTED,
  FETCH_TEMPLATE_ADMIN_LIST_SUCCESS,
  FETCH_TEMPLATE_ADMIN_LIST_FAILED,
} from "../../constants";

import { ApiBase } from "../../../utils/apiBase";
import { getOrgId } from "../../../utils/localStorgeOperations";
import { FETCH_MAIL_TEMPLATE_URL } from "../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';


export const fetchTemplatesForAdmin = (param) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch({
      type: FETCH_TEMPLATE_ADMIN_LIST_STARTED,
      payload: param,
    });
    apiBaseInstance.instance
      .post(FETCH_MAIL_TEMPLATE_URL, { ...makeParams(param), ...getProjectIdAndOrgId(makeParams(param)) })
      .then((res) => fetchTemplatesForAdminSuccess(dispatch, res))
      .catch((err) => fetchTemplatesForAdminFailure(dispatch, err));
  };
};
function fetchTemplatesForAdminSuccess(dispatch, res) {
  return dispatch({
    type: FETCH_TEMPLATE_ADMIN_LIST_SUCCESS,
    payload: res.data,
  });
}
function fetchTemplatesForAdminFailure(dispatch, err) {
  return dispatch({
    type: FETCH_TEMPLATE_ADMIN_LIST_FAILED,
    payload: err,
  });
}

const makeParams = (params) => {
  return {
    organizationId: getOrgId()
  };
};
