
export const getBenchmarkDetailsStarted = (state, payload) => {
    return {
        ...state,
        fetchBenchmarkDetails: {
            ...state.fetchBenchmarkDetails,
            isProcessing: true,
            error: false
        }
    };
};

export const getBenchmarkDetailsSuccess = (state, payload) => {
    return {
        ...state,
        fetchBenchmarkDetails: {
            data: payload.response,
            isProcessing: false,
            error: false
        }
    };
};

export const getBenchmarkDetailsFail = (state, payload) => {
    return {
        ...state,
        fetchBenchmarkDetails: {
            data: null,
            isProcessing: false,
            error: true
        }
    };
};