import { connect } from "react-redux";
import TableMatrix from "./TableMatrix";
import { handleChangeTableMatrix } from "../../../../../../redux/actions";


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handleChangeTableMatrix: (payload) => dispatch((handleChangeTableMatrix(payload)))

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TableMatrix);