import { deepCopy } from "../../../../utils/deepCopy";

export const fetchManagerHeatmapTableStarted = (state, payload) => {
    return {
        ...state,
        managerHeatmapTableApi: {
            isProcessing: true,
            error: false,
            requestId: payload.requestId ? payload.requestId : undefined
        },
        managerSelectedHeaders: payload.data || [],
        filters: payload.filters,
        byScore: payload.byScore,
        multipleSelect: payload.multipleSelect,
        selectedUserData: payload.selectedUserData
    }
}

export const fetchManagerHeatmapTableSuccess = (state, payload) => {
    let heatmapColumns = [];
    let heatmapData = [];
    let heatmapColumnList = []
    let initialLoad = payload.request.initialLoad
    if (payload.data && payload.data.data) {
        heatmapColumns = payload.data.data.columnData;
        heatmapColumnList = payload.data.data.columnList
        heatmapData = payload.data.data.dataMap
        if (payload.request.projectType && payload.request.projectType === "Very_Simple_Survey") {
        } else {
            heatmapData.forEach((item) => {
                if (item.name !== "Responses") {
                    item.children = [];
                    item.key = item.name
                }
            })
        }

    }
    if (state.managerHeatmapTableApi.requestId) {
        if (state.managerHeatmapTableApi.requestId === payload.data.data.requestId) {
            return {
                ...state,
                managerHeatmapTableApi: {
                    ...state.managerHeatmapTableApi,
                    isProcessing: false,
                    error: false,
                    isDesiredRequestMet: true
                    //requestId: undefined
                },
                managerHeatmapColumns: deepCopy(heatmapColumns),
                managerHeatmapData: deepCopy(heatmapData),
                managerHeatmapChildrenList: deepCopy(heatmapColumns),
                managerHeatmapColumnList: deepCopy(heatmapColumnList),
                isDataFetchedManager: initialLoad ? true : state.isDataFetched
            }
        }
        else {
            return {
                ...state,
                managerHeatmapTableApi: {
                    ...state.managerHeatmapTableApi,
                    isProcessing: state.managerHeatmapTableApi.isDesiredRequestMet ? false : true,
                    error: false,
                    isDesiredRequestMet: false
                    //requestId: undefined
                },
            }
        }


    }
    else {
        return {
            ...state,
            managerHeatmapTableApi: {
                ...state.managerHeatmapTableApi,
                isProcessing: false,
                error: false,
            },
            managerHeatmapColumns: deepCopy(heatmapColumns),
            managerHeatmapData: deepCopy(heatmapData),
            managerHeatmapChildrenList: deepCopy(heatmapColumns),
            managerHeatmapColumnList: deepCopy(heatmapColumnList),
            isDataFetchedManager: initialLoad ? true : state.isDataFetched
        }
    }
}


export const fetchManagerHeatmapTableFailed = (state, payload) => {
    return {
        ...state,
        managerHeatmapTableApi: {
            isProcessing: false,
            error: true
        },
        managerHeatmapColumns: [],
        managerHeatmapData: [],
        managerHeatmapChildrenList: [],
        managerHeatmapColumnList: [],
        isDataFetchedManager: false
    }


}

export const managerUpdateScoreLevel = (state, payload) => {
    return { ...state, managerSelectedScoreLevel: payload.data };
};

export const managerUpdateComparisonLevel = (state, payload) => {
    return { ...state, managerSelectedComparisonLevel: payload.data };
};