import {
    FETCH_MANAGER_HEATMAP_QUESTIONS_START,
    FETCH_MANAGER_HEATMAP_QUESTIONS_SUCCESS,
    FETCH_MANAGER_HEATMAP_QUESTIONS_FAILED
} from '../../../constants';
import { GET_MANAGER_HEATMAP_QUESTION_DATA } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { withType } from '../../../../utils/actionOperations';
import { getDataFromUrlToken } from '../../../../utils/urlDataStore';
import { URL_DATA } from '../../../../../config/urlDataConstants';
import { getSelectedUserData } from '../../../../Routes/NewDashboard/subroutes/Explore/helper';
import { getURLFilterAndByScore } from '../../../../utils/byScoreFilterUtils';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchManagerHeatmapTopicQuestions = (payload) => {
    const apiBaseInstance = new ApiBase();
    let filters = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(filters)
    let selectedUserData = getSelectedUserData();
    return dispatch => {
        dispatch(withType(FETCH_MANAGER_HEATMAP_QUESTIONS_START, {}));
        apiBaseInstance.instance
            .post(GET_MANAGER_HEATMAP_QUESTION_DATA, {
                ...payload,
                ...filterData,
                ...selectedUserData,
                ...getProjectIdAndOrgId(payload)
            })
            .then(response => {
                dispatch(withType(FETCH_MANAGER_HEATMAP_QUESTIONS_SUCCESS, {
                    data: response.data,
                    dimension: payload.dimension
                }));
            })
            .catch(err => {
                dispatch(withType(FETCH_MANAGER_HEATMAP_QUESTIONS_FAILED, {
                    error: err
                }));
            });
    };
};

