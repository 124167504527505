import CryptoJS from 'crypto-js'

const secretKey = 't7p8mde1NkZOzKyugLYq';

export const decryptResponse = (response) => {
    try {
        const bytes = CryptoJS.AES.decrypt(response.dataToken, secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // console.log("Token decrypted succefully")
        return decryptedData;
    } catch (e) {
        console.log("Decryption error", e)
        return response.data
    }
}

export const encryptData = (data, key) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(data, key).toString();
        return encryptedData;
    } catch (e) {
        console.error("Encryption error", e)
    }
}

export const decryptData = (encryptedToken, key) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } catch (e) {
        console.log("Decryption error", e)
    }
}


export const encryptJson = (jsonData) => {
    const stringifiedJson = JSON.stringify(jsonData);
    var urlToken = CryptoJS.AES.encrypt(stringifiedJson, secretKey).toString();
    return urlToken;
}