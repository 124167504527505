import { deepCopy } from '../../../../../utils/deepCopy';
import { v4 as uuidv4 } from 'uuid';
import { defaultTemplate } from '../../InitialState/Templates/defaultTemplate';
import { goals as defaultGoals } from '../../InitialState/defaultGoals';
import { getOrgName } from "../../../../../utils/localStorgeOperations";
import { notification } from 'antd';

export const addEngagement = (state, payload) => {
    return {
        ...state,
        ...addEngagementByDefault(state, payload)
    }
}

export const addDemographics = (state, payload) => {
    return {
        ...state,
        ...addDemographicsByDefault(state, payload)
    }
}

export const addGoals = (state, payload) => {
    return {
        ...state,
        ...addNewGoal(state, payload)
    };
};

export const editGoals = (state, payload) => {
    return {
        ...state,
        ...editGoal(state, payload)
    };
};

export const selectGoals = (state, payload) => {
    return {
        ...state,
        ...selectGoalAndAddQuestions(state, payload)
    };
};

export const markAsOutcome = (state, payload) => {
    return {
        ...state,
        ...markDimensionAsOutcome(state, payload)
    };
};


const addEngagementByDefault = (state, payload) => {
    const questionSet = deepCopy(state.questionSet || [])
    const goals = deepCopy(state.goals)
    const engagement = goals[0];
    if (!engagement.questions[0].qid) {
        engagement.questions[0].qid = uuidv4();
    }
    engagement.questions[0].isDefault = true;
    engagement.questions[0].isTemplate = true;
    engagement.questions[0].matrixQuestions.forEach(item => {
        if (!item.value) {
            item.value = uuidv4();
        }
    })
    const comments = goals[1];
    comments.questions.forEach((item, index) => {
        if (!item.qid) {
            item.qid = uuidv4();
        }
        item.isTemplate = true
    })
    comments.questions[0].question =
        comments.questions[0].question + ` in ${getOrgName()}?`
    questionSet.push(engagement);
    questionSet.push(comments);
    return { questionSet, goals }
}

const addDemographicsByDefault = (state, payload = {}) => {
    const questionSet = deepCopy(state.questionSet || [])
    const goals = deepCopy(state.goals);
    const demographics = goals.find(item => item.goalId === 'demographics');
    if (!payload.remove) {
        demographics.isSelected = true;
        demographics.questions.forEach(item => {
            if (!item.qid) {
                item.qid = uuidv4()
            }
            item.isTemplate = true
        })
        questionSet.push(demographics);
    } else {
        demographics.isSelected = false;
        const demographicQuestions = questionSet.find(item => item.goalId === 'demographics')
        if (demographicQuestions) {
            const index = questionSet.indexOf(demographicQuestions)
            questionSet.splice(index, 1)
        }
    }
    return { questionSet, goals }
}

const addNewGoal = (state, payload) => {
    const questionSet = deepCopy(state.questionSet);
    const goals = deepCopy(state.goals)
    let questions = deepCopy(defaultTemplate)
    questions.forEach(item => {
        item.questionDimension = payload.dimension;
        item.questionLabel = payload.dimension
        if (!item.qid) {
            item.qid = uuidv4();
        }
        item.matrixQuestions.forEach(question => {
            if (!question.value) {
                question.value = uuidv4();
            }
        })
    })
    let newGoal = {
        dimension: payload.dimension,
        label: payload.dimension,
        questions: questions,
        isSelected: true,
        isDefault: true,
        isCustom: true,
        isTemplate: true,
        goalId: uuidv4()
    }
    goals.push(newGoal)
    questionSet.push(newGoal)
    return { questionSet, goals }
}

const editGoal = (state, payload) => {
    const questionSet = deepCopy(state.questionSet);
    const goals = deepCopy(state.goals)
    const editedGoal = goals.find(item => item.goalId === payload.goal.goalId)
    const questions = editedGoal.questions;
    if (payload.dimension && payload.dimension !== editedGoal.dimension) {
        if (questionSet.length) {
            let goalIndexInQuestionSet = null;
            questionSet.forEach((item, index) => {
                if (item.dimension === editedGoal.dimension) {
                    goalIndexInQuestionSet = index
                }
            })
            if (goalIndexInQuestionSet) {
                questionSet[goalIndexInQuestionSet].dimension = payload.dimension
                questionSet[goalIndexInQuestionSet].label = payload.dimension
                if (questionSet[goalIndexInQuestionSet].questions.length > 0) {
                    questionSet[goalIndexInQuestionSet].questions[0].questionDimension = payload.dimension
                    questionSet[goalIndexInQuestionSet].questions[0].questionLabel = payload.dimension

                }
            }
        }
        editedGoal.dimension = payload.dimension
        editedGoal.label = payload.dimension
        questions.map(question => question.questionDimension = payload.dimension)
        return { questionSet, goals }
    } else {
        return {}
    }
}

const selectGoalAndAddQuestions = (state, payload) => {
    const questionSet = deepCopy(state.questionSet);
    const goals = deepCopy(state.goals)
    const removeItem = questionSet.find(block => block.goalId === payload.goalId)
    if (removeItem) {
        if (!removeItem.isOutcomeVariable) {
            if (questionSet.length > 1) {
                questionSet.splice(questionSet.indexOf(removeItem), 1)
                goals.forEach(block => {
                    if (block.goalId === removeItem.goalId) {
                        block.isSelected = false
                    }
                })
            } else {
                notification.warning({ message: 'Atleast one goal needs to be selected' })
            }
        } else {
            // notification.warning({ message: 'Outcome goal cannot be removed' })
        }
    }
    else {
        goals.forEach(block => {
            if (block.goalId === payload.goalId) {
                block.isSelected = true;
                const defaultBlock = defaultGoals.find(item => item.goalId === block.goalId);
                if (defaultBlock) {
                    block.questions = deepCopy(defaultBlock.questions)
                }
                if (block.isDefault) {
                    block.questions[0].matrixQuestions.forEach(item => {
                        if (!item.value) {
                            item.value = uuidv4();
                        }
                    })
                    if (!block.questions[0].qid) {
                        block.questions[0].qid = uuidv4();
                    }
                    block.questions[0].isDefault = true;
                    block.questions[0].isTemplate = true
                } else {
                    block.questions.forEach(item => {
                        if (!item.qid) {
                            item.qid = uuidv4();
                        }
                        item.isTemplate = true
                    })
                }
                questionSet.push(block)
            }
        })
    }
    return { questionSet, goals }

}

const markDimensionAsOutcome = (state, payload) => {
    const goals = deepCopy(state.goals);
    const questionSet = deepCopy(state.questionSet);
    let outcomeBlock = undefined;
    let questionSetHasOutcomeBlock = false;
    goals.forEach(item => {
        if (item.goalId === payload.goalId) {
            item.isOutcomeVariable = payload.outcomeStatus
            item.isSelected = true
            if (item.isDefault) {
                item.questions[0].matrixQuestions.forEach(question => {
                    if (!question.value) {
                        question.value = uuidv4();
                    }
                })
                if (!item.questions[0].qid) {
                    item.questions[0].qid = uuidv4();
                }
                item.questions[0].isDefault = true;
                item.questions[0].isTemplate = true
            } else {
                item.questions.forEach(question => {
                    if (!question.qid) {
                        question.qid = uuidv4();
                    }
                    question.isTemplate = true
                    question.isDemographic = false
                })
            }
            outcomeBlock = item;
        } else {
            item.isOutcomeVariable = false
        }
    })
    questionSet.forEach(item => {
        if (item.goalId === payload.goalId) {
            item.isOutcomeVariable = payload.outcomeStatus
            questionSetHasOutcomeBlock = true
        } else {
            item.isOutcomeVariable = false
        }
        if (!item.isDefault) {
            item.questions.forEach(question => {
                question.isDemographic = false
            })
        }
    })
    if (!questionSetHasOutcomeBlock) {
        questionSet.push(deepCopy(outcomeBlock))
    }
    return { goals, questionSet }
}