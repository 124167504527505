import React from "react";
import { Redirect } from "react-router-dom";
import RenderAllowedRoutes from "../RenderAllowedRoutes";
import { getAllowedRoutes, isLoggedIn } from "../../utils/checkSignInAndRender";

// if the route allows all the roles,
// then we dont need to add roles key to the routeConfig

export const PrivateRoutes = ({ routeConfig }) => {
  let allowedRoutes = [];
  if (isLoggedIn()) {
    allowedRoutes = getAllowedRoutes(routeConfig);
  } else return <Redirect to="/login" />;
  return <RenderAllowedRoutes routes={allowedRoutes} />;
};
