
export const getCommentThemeStart = (state, payload) => {
  return {
    ...state,
    [payload.selectedQuestionId]: {
      ...state[payload.selectedQuestionId],
      getCommentsIsProcessing: payload.isDataAlreadyFetched,
      topics:
        state[payload.selectedQuestionId].topic != null
          ? state[payload.selectedQuestionId].topic
          : null,
      error: null,
      selectedTopic: "",
    },
  };
};

export const getCommentThemeSuccess = (state, payload) => {
  let AllThemes = "All Themes";
  payload.topics[AllThemes] = payload.allThemeCount ? payload.allThemeCount : 0;
  return {
    ...state,
    [payload.selectedQuestionId]: {
      ...state[payload.selectedQuestionId],
      topics: payload.topics,
      selectedTopic: payload.selectedTopic,
      getCommentsIsProcessing: false,
    },
  };
};

export const getCommentThemeFail = (state, payload) => {
  return {
    ...state,
    [payload.selectedQuestionId]: {
      getCommentsIsProcessing: false,
      topics: [],
      error: null,
      pagination: false,
    },
  };
};

export const getCommentsSelectedTopic = (state, payload) => {
  return {
    ...state,
    [payload.selectedQuestionId]: {
      ...state[payload.selectedQuestionId],
      selectedTopic: payload.topic[0] || "",
    },
  };
};
