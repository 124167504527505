import React, { useState } from 'react';
import GraphLoader from '../../../../../../../../Components/ContentLoaders/GraphLoader';
import constants from '../../constants';
import { AllThemeBarChart } from './AllThemeBarChart';
import { ThemeBarChart } from './ThemeBarChart';
import { SelectDemographics } from './SelectDemographics';
import TopicCommentModal from '../TopicCommentModal';

export const BarChartLine = (props) => {
  let [topic, setTopic] = useState();
  let [data, setData] = useState([]);
  let [isModalVisible, setIsModalVisible] = useState(false);
  let [current, setCurrent] = useState(1);

  const renderLoader = () => <GraphLoader height={300} width={600} />;

  const onBarChartClick = (item) => {
    const topic = item.label;
    setTopic(topic);
    setIsModalVisible(true);
    setData([]);
    setCurrent(1);
    getComments({ size: 10, from: 0, topic });
  };

  const handleScroll = () => {
    if (
      props.exploreTopicCommentsApi.data &&
      current <= props.exploreTopicCommentsApi.data.totalNumberOfDocuments / 10
    ) {
      onChangepagination();
    }
  };

  const onChangepagination = () => {
    let page = current;
    setCurrent(page + 1);
    if (
      props.exploreTopicCommentsApi.data &&
      props.exploreTopicCommentsApi.data.totalNumberOfDocuments <
      (current - 1) * 10
    ) {
      setCurrent(1);
    } else {
      getComments({
        size: 10,
        from: page * 10,
        topic,
      });
    }
  };

  const getComments = ({ size, from, topic }) => {
    let payload = {
      size: size,
      from: from,
      topic: [topic],
      projectId: props.projectId,
      projectOrigin: props.projectOrigin,
      selectedQuestionId: props.selectedQuestionId,
    };
    props.fetchExploreTopicComments(payload, (responseData) => {
      let newData = [...data, ...responseData];
      setData(newData);
    });
  };

  const handleOkModal = () => {
    setTopic('');
    setIsModalVisible(false);
    setData([]);
    setCurrent(1);
  };

  return (
    <>
      {props.topic === constants.defaultTopic ? (
        props.allThemeDataApi.isProcessing || props.props[props.props.question].exploreQuestionCommentsApi.isProcessing || props.loading ? (
          renderLoader()
        ) : (
          <AllThemeBarChart
            threshold={props.props.thresholdApi.data && props.props.thresholdApi.data.filterThreshold}
            defaultTopicIsSelected={props.defaultTopicIsSelected}
            data={props.allThemeDataApi.data}
            onBarchartClick={onBarChartClick}
          />
        )
      ) : props.themeTopicDataApi.isProcessing || props.props[props.props.question].exploreQuestionCommentsApi.isProcessing ? (
        renderLoader()
      ) : (
        <>
          <ThemeBarChart
            threshold={props.props.thresholdApi.data && props.props.thresholdApi.data.filterThreshold}
            defaultTopicIsSelected={props.defaultTopicIsSelected}
            data={props.themeTopicDataApi.data}
            onRemove={props.onRemove}
            onBarchartClick={onBarChartClick}
          />
          <SelectDemographics
            columns={props.themeTopicDataApi.columns}
            onAdd={props.onDemographicsChange}
            selectedColumns={props.themeTopicDataApi.selectedColumns}
          />
        </>
      )}
      <TopicCommentModal
        topic={topic}
        isModalVisible={isModalVisible}
        handleOkModal={handleOkModal}
        api={props.exploreTopicCommentsApi}
        handleScroll={handleScroll}
        data={data}
      />
    </>
  );
};
