import { fetchPayload, update } from "../../../utils/reducerOperations";
import {
    CREATE_PROJECT_FORM_CHANGE,
    ADD_GOALS,
    SELECT_GOALS,
    EDIT_GOALS,
    SELECT_QUESTION,
    EDIT_QUESTION,
    ADD_QUESTION,
    SET_WIZARD_CONFIG,
    ADD_ENGAGEMENT_BY_DEFAULT,
    SET_ACTIVE_MENU,
    SET_PROJECT_DETAILS,
    SET_SUVREY_INVITATION,
    SET_DEFAULT_WIZARD_STATE,
    ADD_DEMOGRAPHICS_BY_DEFAULT,
    GET_WIZARD_CONFIG_START,
    GET_WIZARD_CONFIG_FAIL,
    GET_WIZARD_CONFIG_SUCCESS,
    SET_SELECTED_WIZARD_MENU,
    MARK_AS_OUTCOME,
    UPLOAD_TRANSLATED_FILE_FAILED,
    UPLOAD_TRANSLATED_FILE_START,
    UPLOAD_TRANSLATED_FILE_SUCCESS,
    CHECK_TRANSLATION_STATUS_START,
    CHECK_TRANSLATION_STATUS_SUCCESS,
    CHECK_TRANSLATION_STATUS_FAILED
} from "../../constants";
import {
    handleCreateProjectFormChange,
    updatedState,
    selectGoals,
    addGoals,
    editGoals,
    selectQuestions,
    editQuestions,
    addQuestions,
    setProjectConfig,
    addEngagement,
    setActiveMenu,
    setProjectDetails,
    setSurveyInvitation,
    addDemographics,
    getWizardConfigStart,
    getWizardConfigFailed,
    getWizardConfigSuccess,
    setSelectedMenu,
    markAsOutcome,
    uploadTranslatedQuestionsStart,
    uploadTranslatedQuestionsSuccess,
    uploadTranslatedQuestionsFailed,
    checkTranslatedQuestionsStart,
    checkTranslatedQuestionsSuccess,
    checkTranslatedQuestionsFailed
} from "./Helpers";
import { Default } from "./InitialState";
const SurveyWizardReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case CREATE_PROJECT_FORM_CHANGE:
            return update(updatedState(state), handleCreateProjectFormChange(state, payload));
        case ADD_ENGAGEMENT_BY_DEFAULT:
            return addEngagement(state, payload);
        case ADD_DEMOGRAPHICS_BY_DEFAULT:
            return addDemographics(state, payload);
        case SELECT_GOALS:
            return selectGoals(state, payload)
        case ADD_GOALS:
            return addGoals(state, payload)
        case EDIT_GOALS:
            return editGoals(state, payload)
        case SELECT_QUESTION:
            return selectQuestions(state, payload)
        case EDIT_QUESTION:
            return editQuestions(state, payload)
        case ADD_QUESTION:
            return addQuestions(state, payload)
        case SET_WIZARD_CONFIG:
            return setProjectConfig(state, payload)
        case SET_ACTIVE_MENU:
            return setActiveMenu(state, payload)
        case SET_PROJECT_DETAILS:
            return setProjectDetails(state, payload)
        case SET_SUVREY_INVITATION:
            return setSurveyInvitation(state, payload)
        case GET_WIZARD_CONFIG_START:
            return getWizardConfigStart(state, payload)
        case GET_WIZARD_CONFIG_FAIL:
            return getWizardConfigFailed(state, Default())
        case GET_WIZARD_CONFIG_SUCCESS:
            return getWizardConfigSuccess(state, payload)
        case SET_SELECTED_WIZARD_MENU:
            return setSelectedMenu(state, payload)
        case MARK_AS_OUTCOME:
            return markAsOutcome(state, payload)
        case SET_DEFAULT_WIZARD_STATE:
            return { ...Default() }
        case UPLOAD_TRANSLATED_FILE_START:
            return uploadTranslatedQuestionsStart(state, payload);
        case UPLOAD_TRANSLATED_FILE_SUCCESS:
            return uploadTranslatedQuestionsSuccess(state, payload)
        case UPLOAD_TRANSLATED_FILE_FAILED:
            return uploadTranslatedQuestionsFailed(state, payload)
        case CHECK_TRANSLATION_STATUS_START:
            return checkTranslatedQuestionsStart(state, payload)
        case CHECK_TRANSLATION_STATUS_SUCCESS:
            return checkTranslatedQuestionsSuccess(state, payload)
        case CHECK_TRANSLATION_STATUS_FAILED:
            return checkTranslatedQuestionsFailed(state, payload)
        default:
            return updatedState(state);
    }
};

export default SurveyWizardReducer;
