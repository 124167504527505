import {
    GENERATE_TEXT_SUMMARIZATION_DATA_START,
    GENERATE_TEXT_SUMMARIZATION_DATA_SUCCESS,
    GENERATE_TEXT_SUMMARIZATION_DATA_FAIL
} from "../../../constants";
import { TEXT_SUMMARIZATION } from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { getDataFromUrlToken } from "../../../../utils/urlDataStore";
import { URL_DATA } from "../../../../../config/urlDataConstants";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const getCommentsSummarization = (payload, cb) => {
    const apiBaseInstance = new ApiBase().instance;
    const projDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS);
    return (dispatch) => {
        dispatch(withType(GENERATE_TEXT_SUMMARIZATION_DATA_START, { ...payload }));
        return apiBaseInstance
            .post(TEXT_SUMMARIZATION, {
                ...payload, ...getProjectIdAndOrgId(payload)
            })
            .then((res) => {
                dispatch(
                    withType(GENERATE_TEXT_SUMMARIZATION_DATA_SUCCESS, {
                        ...payload,
                        data: {
                            data: res.data.data,
                        },
                    })
                );
                cb && setTimeout(() => { cb(); }, 200);
            })
            .catch((err) => {
                dispatch(withType(GENERATE_TEXT_SUMMARIZATION_DATA_FAIL, { ...payload }));
            });
    };
};


