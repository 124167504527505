import { deepCopy } from "../../../../../utils/deepCopy"

export const addConditionalDisplayTopics = (state, payload) => {
    let addedCondition = addTopicConditionForDisplay(state, payload)
    return {
        ...state,
        goals: addedCondition
    }
}

const addTopicConditionForDisplay = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(goal => {
        if (goal.goalId === payload.goalId) {
            goal.isConditionalTopicSection = true
        }
    })
    return goalsCopy
}