import React from 'react';
import { TreeSelect } from 'antd';

const { TreeNode } = TreeSelect;

export class TreeSelectComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state={}
    }

    generateNodes = (data) => {
        if (data && data.length) {
            return data.map(item =>
                item.children ?
                    <TreeNode value={item.value} title={<span>{item.label}</span>} displayLabel={item.displayLabel} isLeaf={item.isLeaf} {...item}>
                        {this.generateNodes(item.children)}
                    </TreeNode>
                    : <TreeNode value={item.value} title={<span>{item.label}</span>} displayLabel={item.displayLabel} isLeaf={item.isLeaf} {...item} />
            )
        }
    }

    render = () => {
        return (
            <TreeSelect
                getPopupContainer={this.props.getPopupContainer}
                showSearch={this.props.showSearch}
                value={this.props.value}
                placeholder={this.props.placeholder}
                allowClear={this.props.allowClear}
                treeDefaultExpandAll={this.props.treeDefaultExpandAll}
                onChange={this.props.onChange}
                onSelect={this.props.onSelect}
                className={this.props.className}
                treeNodeLabelProp={this.props.treeNodeLabelProp}
                labelInValue={this.props.labelInValue}
            >
                {this.generateNodes(this.props.data)}
            </TreeSelect>
        );
    };

}

