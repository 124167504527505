import React from "react";
import GraphLoader from "../../../../../../../../Components/ContentLoaders/GraphLoader";
import CustomModal from "../../../../../../../../Components/CustomModal";

export default class RenderCommentStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    isModalVisible: false,
  }

  longestComment = () => {
    return (
      <CustomModal
        visible={this.state.isModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleOk}
        okText="Ok"
        title=""
      >
        <span className="largecommentmodal-title">
          <span className="largecommentmodal-title__name">
            {this.props.commentStatistics &&
              Object.entries(this.props.commentStatistics).map((item) =>
                item[0] === "longestCommentWordCount" ? "Longest comment " : ""
              )}
          </span>
          <span className="largecommentmodal-title__count">
            {this.props.commentStatistics &&
              Object.entries(this.props.commentStatistics).map((item) =>
                item[0] === "longestCommentWordCount" ? item[1] + " words" : ""
              )}
          </span>
        </span>
        <span className="longest-comment">
          {this.props.commentStatistics &&
            Object.entries(this.props.commentStatistics).map((item) =>
              item[0] === "longestComment" ? item[1] : ""
            )}
        </span>
      </CustomModal>
    );
  };
  showModal = () => {
    this.setState({
      ...this.state,
      isModalVisible: true,
    });
  };
  handleOk = () => {
    this.setState({
      ...this.state,
      isModalVisible: false,
    });
  };

  getCommentsPercentage = () => {
    return (
      <>{
        this.props.commentStatistics && !!this.props.commentStatistics.commentPercentage &&
        <li>
          <div className="compare-count">
            <h4>
              {`${Math.round(this.props.commentStatistics.commentPercentage)}%`}
            </h4>
            <span>of respondents</span>
          </div>
          <p>wrote a comment</p>
        </li>
      }
      </>
    )
  }

  render() {
    return (
      <>
        {this.props.props[this.props.props.question].explorecommentStatisticApi.isProcessing ?
          <GraphLoader height={120} width={600} /> 
          :
          <ul className="compare-wrapper" id="scrolltop">
            {this.getCommentsPercentage()}
            {this.longestComment()}
            <li>
              <div className="compare-count">
                <h4>
                  {this.props.commentStatistics &&
                    Object.entries(this.props.commentStatistics)
                      .filter((item) => item[0] === "commentCount")
                      .map((item) => item[1])}
                </h4>
                <span></span>
              </div>
              <p>
                {this.props.commentStatistics &&
                  Object.entries(this.props.commentStatistics)
                    .filter((item) => item[0] === "commentCount")
                    .map((item) =>
                      item[1] > 1 ? "Total comments" : "Total comment"
                    )}
              </p>
            </li>
            <li>
              <div className="compare-count">
                <h4>
                  {this.props.commentStatistics &&
                    Object.entries(this.props.commentStatistics).map((item) =>
                      item[0] === "averageCommentWordCount"
                        ? Math.round(item[1])
                        : ""
                    )}
                </h4>
                <span>words</span>
              </div>
              <p>Average length</p>
            </li>
            <li onClick={this.showModal} className="explore-longestcomment">
              <div className="compare-count">
                <h4>
                  {this.props.commentStatistics &&
                    Object.entries(this.props.commentStatistics).map((item) =>
                      item[0] === "longestCommentWordCount"
                        ? parseFloat(item[1].toFixed(1))
                        : ""
                    )}
                </h4>
                <span>words</span>
              </div>
              <p>Longest comment</p>
            </li>
          </ul>
        }
      </>
    );
  }
}
