import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const engagementTemplate = [
    {
        questionDimension: 'Engagement',
        type: 'Matrix',
        questionLabel: 'Engagement',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: 'I can see the positive impact of my work on someone else.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I can see the positive impact of my work on someone else.'),
        },
        {
            text: 'My organization motivates me to go above and beyond what I would normally do.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My organization motivates me to go above and beyond what I would normally do.'),

        },
        {
            text: 'I would recommend my organization as a great place to work.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I would recommend my organization as a great place to work.'),

        },
        {
            text: 'I have fun at work.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I have fun at work.'),

        },
        {
            text: 'My work is meaningful.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My work is meaningful.'),

        },

        {
            text: 'My work is an important part of who I am as a person.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My work is an important part of who I am as a person.'),

        },
        {
            text: 'I am proud to be a part of my organization.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I am proud to be a part of my organization.'),

        }
        ]
    }
]

