import { replaceTypes } from "./newAttendSurveyUtils";
import { v4 as uuidv4 } from "uuid";

export const generateGoalsForSurveyEditor = (data, fromAnsweringPage) => {
    let updatedGoal = []
    data.questionSet.forEach((block, index) => {
        if (block.isDefault || block.isCustom) {
            let likertObject = generateLikertObject(block)
            updatedGoal.push(likertObject)
        }
        else if (block.isDemographic && block.questions.length > 0) {
            let demographicsData = generateSplitData(block)
            updatedGoal.push(...demographicsData)

        }
        else if (block.isMultipleOption && block.questions.length > 0) {
            let multipleOptionData = generateSplitData(block)
            updatedGoal.push(...multipleOptionData)

        }
        else if (block.isTextEntryOnly && block.questions.length > 0) {
            let textEntryData = generateSplitData(block)
            updatedGoal.push(...textEntryData)
        }
        else if (fromAnsweringPage && block.type === "matrix") {
            let likertToMatrixObject = generateLikertObject(block)
            updatedGoal.push(likertToMatrixObject)
        }
        else if (fromAnsweringPage) {
            updatedGoal.push({ ...block, blockId: block.blockId ? block.blockId : uuidv4() })
        }

    });
    return updatedGoal
}

export const generateLikertObject = (block) => {
    let likertObject = {
        ...block,
        blockId: block.blockId ? block.blockId : uuidv4(),
        type: "likert",
        questions: block.questions.map((question) => {
            let filteredOptionsCount = question.options.filter((item) => !item.disabled)
            return {
                ...question,
                optionsCount: question.optionsCount ? question.optionsCount : filteredOptionsCount.length,
                options: [
                    {
                        "label": "Strongly disagree",
                        "value": "1"
                    },
                    {
                        "label": "Neither agree nor disagree",
                        "value": "2"
                    },
                    {
                        "label": "Strongly agree",
                        "value": "3"
                    },
                ]
            }
        })
    }
    return likertObject
}

const generateSplitData = (block) => {
    let demographicsData = []
    block.questions.forEach((question) => {
        if (question.type !== "Page Break") {
            demographicsData.push(
                {
                    ...block,
                    blockId: block.blockId ? block.blockId : uuidv4(),
                    type: replaceTypes(question.type),
                    questions: [{ ...question }]
                }
            )
        }
    })
    return demographicsData
}