export const jobTenureDemographicTemplate = [
    {
        type: 'Multiple Choice',
        questionDimension: 'Demographics',
        isSelected: false,
        options: [
            {
                label: "Less than 1 year",
                value: "1"
            },
            {
                label: "1-3 years",
                value: "2"
            },
            {
                label: "4-6 years",
                value: "3"
            },
            {
                label: "6-8 years",
                value: "4"
            },
            {
                label: "More than 8 years",
                value: "5"
            }
        ],
        question: 'Job Tenure.',
        questionLabel: 'Job Tenure',
        isDemographic: true
    }
]