import {
    FETCH_STATUS_OF_MANAGER_HEATMAP_START,
    FETCH_STATUS_OF_MANAGER_HEATMAP_SUCCESS,
    FETCH_STATUS_OF_MANAGER_HEATMAP_FAIL
} from "../../constants";
import { ApiBase } from "../../../utils/apiBase";
import { withType } from "../../../utils/actionOperations";
import { GET_MANAGER_HEATMAP_STATUS } from "../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const getManagerHeamapStatus = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
        dispatch(withType(FETCH_STATUS_OF_MANAGER_HEATMAP_START, {}));
        apiBaseInstance.instance
            .post(GET_MANAGER_HEATMAP_STATUS, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then((response) => {
                dispatch(
                    withType(FETCH_STATUS_OF_MANAGER_HEATMAP_SUCCESS, {
                        data: response.data,

                    })
                );
                cb && cb(response);
            })
            .catch((err) => {
                dispatch(
                    withType(FETCH_STATUS_OF_MANAGER_HEATMAP_FAIL, {
                        error: err,
                    })
                );
                cb && cb(false);
            });
    };
};
