import React from "react";
import { ReloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { ButtonElement } from "../../FormElements/ButtonElement";
export const ReloadButton = ({ loading, onClick }) => {
  return (
    <ButtonElement
      test-name="loader-button"
      className="refresh-btn"
      onClick={onClick}
    >
      {loading ? <LoadingOutlined /> : <ReloadOutlined />}
    </ButtonElement>
  );
};
