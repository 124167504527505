export const handleSortChange = (state, payload) => {
    return {
        ...state,
        fetchCohortSurveyChildListingApi: {
            ...state.fetchCohortSurveyChildListingApi,
            [payload.projectId]: {
                ...state.fetchCohortSurveyChildListingApi[payload.projectId],
                pageNumber: payload.pageNumber ? payload.pageNumber : state.fetchCohortSurveyChildListingApi[payload.projectId].pageNumber,
                sortField: payload.sortField ? payload.sortField : state.fetchCohortSurveyChildListingApi[payload.projectId].sortField,
                sortOrder: payload.sortOrder ? payload.sortOrder : state.fetchCohortSurveyChildListingApi[payload.projectId].sortOrder,
            },
        },


    }

}