import talentAnalytics from '../../public/img/pilot.png';
import techHR from '../../public/img/dart.png';
import aiHR from '../../public/img/car.png';
import integHR from '../../public/img/color.png';
import rubyRails from '../../public/img/ruby.png';
export default {
  resources_title: 'Resources',
  resources_resources: [{
    label: 'Trends and Practices in Talent Analytics',
    img: talentAnalytics,
    link: 'https://www.shrm.org/hr-today/' + 'trends-and-forecasting/special-reports' + '-and-expert-views/Documents/2017%2010_SHRM-' + 'SIOP%20Talent%20Analytics.pdf'
  }, {
    label: 'Technology and HR',
    img: techHR,
    link: 'https://thetechnoskeptic.com/inhuman-resources/'
  }, {
    label: 'Navigating AI in HR',
    img: aiHR,
    link: 'https://www.ipacweb.org/2019Workshops'
  }, {
    label: 'Integrated Talent Analytics',
    img: integHR,
    link: 'https://www.nytimes.com/2019/06/15' + '/upshot/how-to-win-neil-irwin.html'
  }, {
    label: 'Commentary - Ruby on Rails Applications',
    img: rubyRails,
    link: 'https://codesmithdev.com/6-examples-of-' + 'ruby-on-rails-applications/'
  }]
};