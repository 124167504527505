import { COHORT_SURVEY_SECTION, SURVEY_WIZARD_TABS } from "../../../config/tabConstants";
import { toast } from "../../utils/messageUtils";


export const submitToUpload = (props, data) => {
  props.bulkUploadStart();
  let file = props.fileToUpload;
  props.getPreSignedURL(
    {
      currentTab: COHORT_SURVEY_SECTION.CREATE_COHORT_SURVEY,
      fileName: file[0].name,
      fileType: file[0].type,
    },
    (response, success) => {
      if (success) {
        props.uploadFile(
          {
            url: response ? response.data.data.preSignedUrl : "",
            file: file[0],
            fileType: file[0].type,
          },
          (isSuccess) => {
            if (isSuccess) {
              data.addJob(
                file[0].name,
                getKeyNameForAddJob(response),
                data.fileName
              );
              toast.success("Hris file uploaded successfully");
            } else {
              props.bulkUploadEnd();
              toast.error("Failed to upload HRIS File!");
            }
          }
        );
      }
      else {
        props.bulkUploadEnd();
      }
    }
  );
};

const getKeyNameForAddJob = (response) => {
  let fileName = response ? response.data.data.fileurl : "";
  if (
    window.location.hostname === "app.culturebie.com" ||
    window.location.hostname === "www.app.culturebie.com"
  ) {
    return fileName && fileName.split("/culturebie-uploadbucket/")[1];
  } else if (window.location.hostname === "qa.culturebie.com" ||
    window.location.hostname === "www.qa.culturebie.com") {
    return fileName && fileName.split("/culturebieqa-uploadbucket/")[1];
  }
  else return fileName && fileName.split("/culturebiedev-uploadbucket/")[1];
};