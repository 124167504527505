import { connect } from "react-redux";
import  FinalPage  from "./FinalPage";


const mapStateToProps = (state) => {
    return {
        createAnswerSetApi:state.SurveyAnsweringPageReducer.createAnswerSetApi
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
 

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinalPage);