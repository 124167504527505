import { ApiBase } from '../../../../utils/apiBase';
import { FETCH_DIMENSIONSLIST_URL } from '../../../../../config/constants';
import {
  FETCH_EXPLORE_DIMENSIONS_STARTED,
  FETCH_EXPLORE_DIMENSIONS_SUCCESS,
  FETCH_EXPLORE_DIMENSIONS_FAILED,
} from '../../../constants'
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchDriverDimensions = (params, callBack) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(fetchDimensionListStart());
    apiBaseInstance.instance
      .post(FETCH_DIMENSIONSLIST_URL, { ...params, ...getProjectIdAndOrgId(params) })
      .then((res) => {
        const data =
          (res && res.data && res.data.data && res.data.data.data) || [];
        let topic = data.length ? data[0].dimension : undefined;
        dispatch(fetchDimensionListSuccess({ data, topic, outcome: params.outcomeVariable }, callBack));
      })
      .catch((err) => {
        dispatch(fetchDimensionListFailure(err));
      });
  };
};

const fetchDimensionListStart = () => {
  return {
    type: FETCH_EXPLORE_DIMENSIONS_STARTED,
  };
};

const fetchDimensionListSuccess = ({ data, topic, outcome }, callBack) => {
  callBack && callBack(true, outcome || topic);
  return {
    type: FETCH_EXPLORE_DIMENSIONS_SUCCESS,
    payload: { data, topic: outcome || topic },
  };
};

const fetchDimensionListFailure = (error) => {
  return {
    type: FETCH_EXPLORE_DIMENSIONS_FAILED,
    payload: { errorMessage: error },
  };
};
