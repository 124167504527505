//fetchUserDemographics
import * as actionTypes from '../../../constants';
import { FETCH_USER_DEMOGRAPHICS } from "../../../../../config/constants";
import { ApiBase } from '../../../../utils/apiBase';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export function fetchUserDemographicsData(data, cb) {
    return dispatch => {
        dispatch({ type: actionTypes.NEW_FETCH_USER_DEMOGRAPHICS_DATA_START });
        const axios = new ApiBase().instance;
        axios.post(FETCH_USER_DEMOGRAPHICS, { ...data, ...getProjectIdAndOrgId(data) })
            .then(fetchUserDemographicsSuccess.bind(null, dispatch, cb))
            .catch(fetchUserDemographicsFail.bind(null, dispatch));
    };
};

function fetchUserDemographicsSuccess(dispatch, cb, response) {
    cb && cb(true, response);
    return dispatch({
        type: actionTypes.NEW_FETCH_USER_DEMOGRAPHICS_DATA_SUCCESS,
        response
    });
};

function fetchUserDemographicsFail(dispatch, err) {
    return dispatch({
        type: actionTypes.NEW_FETCH_USER_DEMOGRAPHICS_DATA_FAIL,
        response: err
    });
};