export const getFilteredMappedSurveyDetails = (data) => {
    let filteredData = []
    if (data && data.length > 0) {
        filteredData = data.filter(item => item.isMappedSurvey)
    }
    return filteredData
}

export const generateBlockDataForPairingSurveys = ({ projectId, pairingSurveyData, currentData, isDataCollectionCurrentSurvey }) => {
    let generateDraggingQuestions = []
    let staticIds = []
    let filteredDraggableQuestions = getDraggableQuestions(pairingSurveyData)
    let filteredCurrentSurveyData = isDataCollectionCurrentSurvey ? filterOutQuestionsForDataCollectionCurrentSurvey(currentData) : filterOutMatrixQuestions(currentData)
    if (filteredDraggableQuestions && filteredDraggableQuestions.length > 0) {

        staticIds = isDataCollectionCurrentSurvey ? getStaticIdsForDataCollectionCurrentSurvey(filteredCurrentSurveyData, projectId) : getStaticIdsFromCurrentSurvey(filteredCurrentSurveyData, projectId)
        filteredDraggableQuestions.forEach((topics, key) => {
            topics.questions[0].matrixQuestions.forEach((matrixQuestion, index) => {
                if (!staticIds.includes(matrixQuestion.staticQuestionId)) {
                    generateDraggingQuestions.push({
                        ...matrixQuestion,
                        goalId: topics.goalId,
                        dimension: topics.dimension

                    })
                }
            })
        })
    }
    return generateDraggingQuestions
}

const getDraggableQuestions = (currentPairingSurveyQuestion) => {
    if (currentPairingSurveyQuestion && Object.keys(currentPairingSurveyQuestion).length > 0) {
        let filteredList = []
        filteredList = currentPairingSurveyQuestion.questionSet.filter(item => item.isDefault || item.isCustomTopic)
        return filteredList
    }
    else {
        return []
    }

}

export const filterOutMatrixQuestions = (currentSurveyQuestion) => {
    if (currentSurveyQuestion && currentSurveyQuestion.length > 0) {
        let filteredList = []
        filteredList = currentSurveyQuestion[0].questionSet.filter(item => item.isDefault || item.isCustomTopic)
        return filteredList
    }
    else {
        return []
    }

}

export const generateBlockDataForDataCollection = ({ projectId, pairingSurveyData, currentData, isDataCollectionCurrentSurvey }) => {
    let generateDraggingQuestions = []
    let staticIds = []
    let filteredCurrentSurveyData = isDataCollectionCurrentSurvey ? filterOutQuestionsForDataCollectionCurrentSurvey(currentData) : filterOutMatrixQuestions(currentData)
    let draggableQuestions = getDraggableQuestionsForDataCollection(pairingSurveyData)
    staticIds = isDataCollectionCurrentSurvey ? getStaticIdsForDataCollectionCurrentSurvey(filteredCurrentSurveyData, projectId) : getStaticIdsFromCurrentSurvey(filteredCurrentSurveyData, projectId)
    draggableQuestions.forEach((item, key) => {
        if (!staticIds.includes(item.staticQuestionId)) {
            generateDraggingQuestions.push({
                dimension: item.questionDimension,
                dimensionId: item.dimensionsId,
                text: item.question,
                questionId: item.questionId,
                staticQuestionId: item.staticQuestionId
            })
        }
    })
    return generateDraggingQuestions

}

const getDraggableQuestionsForDataCollection = (pairingSurveyData) => {
    if (pairingSurveyData && Object.keys(pairingSurveyData).length > 0) {
        let filteredList = []
        filteredList = pairingSurveyData.questionSet[0].questions.filter(item => item.type === "Multiple Choice")
        return filteredList
    }
    else {
        return []
    }
}

const getStaticIdsFromCurrentSurvey = (filteredCurrentSurveyData, projectId) => {
    let staticIds = []
    filteredCurrentSurveyData.forEach((currentSurveyTopic) => {
        currentSurveyTopic.questions[0].matrixQuestions.forEach((matrixQuestion, index) => {
            if (matrixQuestion.mappingQuestions && matrixQuestion.mappingQuestions.length > 0) {
                matrixQuestion.mappingQuestions.forEach((mappingQuestion, i) => {
                    if (mappingQuestion.projectId === projectId) {
                        staticIds.push(mappingQuestion.questionId)
                    }
                })
            }
        })
    })
    return staticIds
}

export const isFromDataCollection = ({ comparisonSurvey, projectId }) => {
    let filteredData = []
    if (comparisonSurvey && comparisonSurvey.length > 0 && projectId) {
        filteredData = comparisonSurvey.filter(item => item.projectId === projectId)
        if (filteredData.length > 0) {
            if (filteredData[0].isFileUpload) {
                return true
            }
        }
    }
    return false

}

export const filterOutQuestionsForDataCollectionCurrentSurvey = (currentSurveyQuestion) => {
    if (currentSurveyQuestion && currentSurveyQuestion.length > 0) {
        let filteredList = []
        filteredList = currentSurveyQuestion[0].questionSet[0].questions.filter(item => item.type === "Multiple Choice")
        if (filteredList.length > 0) {
            return filteredList
        }
    }
    return []
}

export const generateBlockDataForCurrentSurvey = (currentData) => {
    let generatedBlockData = []
    let filteredCurrentSurveyData = filterOutMatrixQuestions(currentData)
    if (filteredCurrentSurveyData.length > 0) {
        filteredCurrentSurveyData.forEach((topics, key) => {
            topics.questions[0].matrixQuestions.forEach((matrixQuestion, index) => {
                generatedBlockData.push({
                    ...matrixQuestion,
                    goalId: topics.goalId,
                    dimension: topics.dimension

                })

            })
        })
    }

    return generatedBlockData
}

export const generateBlockDataForCurrentSurveyDataCollection = (currentData) => {
    let generatedDataCollectionBlock = []
    let filteredCurrentSurveyData = filterOutQuestionsForDataCollectionCurrentSurvey(currentData)
    if (filteredCurrentSurveyData.length > 0) {
        filteredCurrentSurveyData.forEach((item, key) => {
            generatedDataCollectionBlock.push({

                staticQuestionId: item.staticQuestionId,
                dimension: item.questionDimension,
                text: item.question,
                questionId: item.questionId,
                value: item.questionId,
                mappingQuestions: item.mappingQuestions ? item.mappingQuestions : []

            })
        })
    }
    return generatedDataCollectionBlock
}

export const getSelectedSurveyDetails = (projectId, data) => {
    let currentSurvey = undefined
    if (projectId && projectId !== "" && data) {
        currentSurvey = data.find((item, index) => item.projectId === projectId)
    }
    if (currentSurvey) {
        return currentSurvey
    }
    return {}
}

export const currentPairingProjectDetails = ({ comparisonSurvey, projectId }) => {
    let filteredData = []
    let flag = false
    if (comparisonSurvey && comparisonSurvey.length > 0 && projectId) {
        filteredData = comparisonSurvey.filter(item => item.projectId === projectId)
        if (filteredData.length > 0) {
            if (filteredData[0].isFileUpload) {
                flag = true
            }
        }
    }
    return { isDataCollection: flag, projectName: filteredData[0].projectName, projectId: filteredData[0].projectId }

}

const getStaticIdsForDataCollectionCurrentSurvey = (filteredCurrentSurveyData, projectId) => {
    let staticIds = []
    filteredCurrentSurveyData.forEach((currentSurveyTopic) => {
        if (currentSurveyTopic.mappingQuestions && currentSurveyTopic.mappingQuestions.length > 0) {
            currentSurveyTopic.mappingQuestions.forEach((mappingQuestion, i) => {
                if (mappingQuestion.projectId === projectId) {
                    staticIds.push(mappingQuestion.questionId)
                }
            })
        }
    })
    return staticIds
}

export const generateBlockDataForDataCollectionTopics = ({ projectId, pairingSurveyData, currentData, isDataCollectionCurrentSurvey }) => {
    let generateDraggingQuestions = []
    let dimensionIds = []
    let filteredCurrentSurveyData = isDataCollectionCurrentSurvey ? filterOutQuestionsForDataCollectionCurrentSurvey(currentData) : filterOutMatrixQuestions(currentData)
    let draggableQuestions = getDraggableQuestionsForDataCollection(pairingSurveyData)
    dimensionIds = isDataCollectionCurrentSurvey ? getDimensionForDataCollectionCurrentSurvey(filteredCurrentSurveyData, projectId) : getDimensionForCurrentSurvey(filteredCurrentSurveyData, projectId)
    draggableQuestions.forEach((item, key) => {
        if (!dimensionIds.includes(item.dimensionsId) && (generateDraggingQuestions.findIndex((question) => question.dimensionsId === item.dimensionsId) === -1)) {
            generateDraggingQuestions.push({
                dimensionsId: item.dimensionsId,
                staticQuestionId: item.staticQuestionId,
                dimension: item.questionDimension,
                text: item.question,
                questionId: item.questionId,
                value: item.questionId,
                mappingQuestions: item.mappingQuestions ? item.mappingQuestions : []

            })
        }
    })
    return generateDraggingQuestions

}

export const generateBlockDataForPairingSurveyTopics = ({ projectId, pairingSurveyData, currentData, isDataCollectionCurrentSurvey }) => {
    let generateDraggingQuestions = []
    let dimensionsIds = []
    let filteredDraggableQuestions = getDraggableQuestions(pairingSurveyData)
    let filteredCurrentSurveyData = isDataCollectionCurrentSurvey ? filterOutQuestionsForDataCollectionCurrentSurvey(currentData) : filterOutMatrixQuestions(currentData)
    if (filteredDraggableQuestions && filteredDraggableQuestions.length > 0) {

        dimensionsIds = isDataCollectionCurrentSurvey ? getDimensionForDataCollectionCurrentSurvey(filteredCurrentSurveyData, projectId) : getDimensionForCurrentSurvey(filteredCurrentSurveyData, projectId)
        filteredDraggableQuestions.forEach((topics, key) => {
            let currentDimension = topics.questions[0].matrixQuestions[0].dimensionsId
            if (!dimensionsIds.includes(currentDimension)) 
            generateDraggingQuestions.push({
                ...topics,
                dimensionsId: currentDimension

            })
        })
    }
    return generateDraggingQuestions
}



export const generateBlockDataForCurrentSurveyTopics = (currentData) => {
    let generatedBlockData = []
    let filteredCurrentSurveyData = filterOutMatrixQuestions(currentData)
    if (filteredCurrentSurveyData.length > 0) {
        filteredCurrentSurveyData.forEach((topics, key) => {
            generatedBlockData.push({
                ...topics,
                dimensionsId: topics.questions[0].matrixQuestions[0].dimensionsId

            })
        })
    }

    return generatedBlockData
}

export const generateBlockDataForCurrentSurveyTopicsDataCollection = (currentData) => {
    let generatedDataCollectionBlock = []
    let filteredCurrentSurveyData = filterOutQuestionsForDataCollectionCurrentSurvey(currentData)
    if (filteredCurrentSurveyData.length > 0) {
        filteredCurrentSurveyData.forEach((item, key) => {
            if ((generatedDataCollectionBlock.findIndex((question) => question.dimensionsId === item.dimensionsId) === -1)) {
                generatedDataCollectionBlock.push({
                    dimensionsId: item.dimensionsId,
                    staticQuestionId: item.staticQuestionId,
                    dimension: item.questionDimension,
                    text: item.question,
                    questionId: item.questionId,
                    value: item.questionId,
                    mappingTopics: item.mappingTopics ? item.mappingTopics : []

                })
            }

        })
    }
    return generatedDataCollectionBlock
}

const getDimensionForDataCollectionCurrentSurvey = (filteredCurrentSurveyData, projectId) => {
    let dimensionIds = []
    filteredCurrentSurveyData.forEach((currentSurveyTopic) => {
        if (currentSurveyTopic.mappingTopics && currentSurveyTopic.mappingTopics.length > 0) {
            currentSurveyTopic.mappingTopics.forEach((mappingTopic, i) => {
                if (mappingTopic.projectId === projectId) {
                    dimensionIds.push(mappingTopic.dimensionsId)
                }
            })
        }
    })
    return dimensionIds
}

const getDimensionForCurrentSurvey = (filteredCurrentSurveyData, projectId) => {
    let dimensionsIds = []
    filteredCurrentSurveyData.forEach((currentSurveyTopic) => {
        if (currentSurveyTopic.mappingTopics && currentSurveyTopic.mappingTopics.length > 0) {
            currentSurveyTopic.mappingTopics.forEach((mappedTopic, i) => {
                if (mappedTopic.projectId === projectId) {
                    // eslint-disable-next-line
                    let currentDimension = currentSurveyTopic.questions[0].matrixQuestions[0].dimensionsId
                    dimensionsIds.push(mappedTopic.dimensionsId)
                }
            })
        }

    })
    return dimensionsIds
}
