import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const careerDevelopmentTemplate = [
    {
        questionDimension: 'Career Development',
        type: 'Matrix',
        questionLabel: 'Career Development',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: 'I have good opportunities for advancement at my organization.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I have good opportunities for advancement at my organization.')
        },
        {
            text: 'I can grow and develop my skills here.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I can grow and develop my skills here.')

        },
        {
            text: 'This is a great company for me to grow as a professional.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('This is a great company for me to grow as a professional.')

        }]
    }
]

