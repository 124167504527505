import { deepCopy } from "../../../../../../utils/deepCopy"
import { automaticChoices } from "../../../InitialState/automaticChoices"

export const likertOptionChange = (state, payload) => {
    let updatedGoals = getOptionChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getOptionChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            let selectedData = automaticChoices[payload.index]
            element.questions[0].options = selectedData.options
        }
    });
    return goalsCopy
}

export const likertOptionLabelChange = (state, payload) => {
    let updatedGoals = getOptionLabelChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getOptionLabelChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            element.questions[0].options[payload.index].label = payload.value
        }
    });
    return goalsCopy
}