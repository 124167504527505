import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
export const ButtonElement = (props) => {
  return (
    <Button
      id={props.id}
      style={props.styleProps || {}}
      color={props.color}
      onClick={props.onClick}
      title={props.title}
      disabled={props.disabled}
      variant={props.variant}
      loading={props.loading || false}
      type={props.type || null}
      className={`${props.className || ''}`}
    >
      <>
        {props.children}
      </>
    </Button>
  );
};
ButtonElement.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  type: PropTypes.string,
  styleProps: PropTypes.object
};
