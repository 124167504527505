import { deepCopy } from "./deepCopy";
const crypto = require('crypto')

export const checkQuestionEmpty = (goals) => {
    let goalsCopy = deepCopy(goals)
    let isEmpty = true;
    if (goalsCopy && Array.isArray(goalsCopy)) {
        goalsCopy.forEach(item => {
            if (!item.isHiddenFromEditSurvey)
                if (!item.isDefault) {
                    if (item.questions.length > 0) {
                        isEmpty = false;
                    }
                }
                else {
                    if (item.questions[0].matrixQuestions.length > 0) {
                        isEmpty = false;
                    }
                }
        })
    }
    return isEmpty
}

export const generateStaticQuestionId = (questionString) => {
    var hash = crypto.createHash('sha256');
    if (questionString && typeof questionString === 'string') {
        return hash.update(questionString.toLowerCase()).digest("hex")
    }
    else {
        return null
    }
}

export const questionSetToBenchmarkFormat = (questionSet, projectType) => {
    let benchmarkData = []
    if (checkQuestionEmpty(questionSet)) {
        return []
    } else if (projectType !== "Very_Simple_Survey") {
        questionSet.forEach(item => {
            if (item.isDefault) {
                let data = {
                    topic: item.dimension,
                    topicId: item.questions[0] && item.questions[0].matrixQuestions[0].dimensionsId
                }
                benchmarkData.splice(benchmarkData.length, 0, data)
                item.questions[0] && item.questions[0].matrixQuestions.forEach(question => {
                    data = {
                        topic: item.dimension,
                        topicId: question.dimensionsId,
                        question: question.text,
                        questionId: question.staticQuestionId
                    }
                    benchmarkData.splice(benchmarkData.length, 0, data)
                })
            }
        })
    } else {
        questionSet.forEach(item => {
            if (item.isDefault) {
                item.questions[0] && item.questions[0].matrixQuestions.forEach(question => {
                    let data = {
                        topic: item.dimension,
                        topicId: question.dimensionsId,
                        question: question.text,
                        questionId: question.staticQuestionId
                    }
                    benchmarkData.splice(benchmarkData.length, 0, data)
                })
            }
        })
    }
    return benchmarkData
}
