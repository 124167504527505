import { fetchFormConfigurations } from "../../inputFormConfigurations";
import { setInitialState } from "../../../utils/reducerOperations";
export function Default() {
  return {
    isFormValid: false,
    fetchManagersApi: setInitialState(),
    inviteManagerApi: setInitialState(),
    inputBoxConfiguration: fetchFormConfigurations(["subject", "message"]),
  };
}
