import {
  SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_STARTED,
  SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_SUCCESS,
  SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_FAILED,
} from "../../../../constants";

import { ApiBase } from "../../../../../utils/apiBase";
import { RESPONSES_URL } from "../../../../../../config/constants";
import { withType } from "../../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../../utils/apiUtils';

export const SurveyWizardFetchResponses = (payload, cb) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_STARTED, {}));
    return apiBaseInstance
      .post(RESPONSES_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        dispatch(
          withType(SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_SUCCESS, {
            payload: res.data,
          })
        );
        cb && cb()
      })
      .catch((err) => {
        dispatch(withType(SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_FAILED, { payload: err }));
      });
  };
};