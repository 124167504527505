import React from "react";
import { BarChartLine } from "./childrens/BarChartLine";
import { EmptyState } from "../../../../../../Packages/EmptyState";
import WordCloud from "./childrens/WordCloud";
import RenderCommentStatistics from "./childrens/CommentsStatistics";
import RenderForceGraph from "./childrens/RenderRelationGraph";
import TopicChooser from "./childrens/TopicChooser";
import constants from "./constants";
import GraphLoader from "../../../../../../Components/ContentLoaders/GraphLoader";
import { getDataFromUrlToken } from "../../../../../../utils/urlDataStore";
import { URL_DATA } from "../../../../../../../config/urlDataConstants";
import { checkIfObjectsAreEqual, getCustomThreshold } from "../../../../../../utils/customFunctions";
import { getSelectedUserData } from "../../helper";
import { getApiCallPayload, getApiDatas, getLoader, getError } from "./commentQuestionsHelper";
import { checkIfTwoArraysAreIsEqual, getURLFilterAndByScore } from "../../../../../../utils/byScoreFilterUtils";
import { DASHBOARD_TABS } from '../../../../../../../config/tabConstants';

export class CommentQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      showLoader: false,
      loading: false,
      demographics: [],
    };
  }

  showThresholdEmptystate = (data) => {
    if (data && data.commentCount !== undefined) {
      let threshold = this.props.thresholdApi.data && this.props.thresholdApi.data.filterThreshold ? this.props.thresholdApi.data.filterThreshold : 5
      if (data.commentCount >= threshold) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  componentDidMount() {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const selectedUserData = getSelectedUserData();
    if (this.checkIfApiCallRequired(urlFilter, selectedUserData)) {
      this.apiCall();
      // this.props.fetchDashBoardFilters({ selectedQuestionId: this.props.question })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.didUpdateFunction(prevProps)
  }

  didUpdateFunction = (prevProps) => {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const selectedUserData = getSelectedUserData();
    let treeDataChanged = selectedUserData.directReporteesOnly !== this.props[this.props.question].directReporteesOnly ||
      selectedUserData.selectedUser !== this.props[this.props.question].selectedUser
    if (this.checkIfApiCallRequired(urlFilter, selectedUserData)) {
      if (!getLoader(this.props)) {
        this.apiCall(treeDataChanged);
      }
    }
    let conditionOne = prevProps[this.props.question].allThemeDataApi.isProcessing &&
      !this.props[this.props.question].allThemeDataApi.isProcessing
    let conditionTwo = prevProps[this.props.question].themeTopicDataApi.isProcessing && !this.props[this.props.question].themeTopicDataApi.isProcessing
    if (conditionOne || conditionTwo) {
      this.setState({ showLoader: false });
    }
  }

  checkIfApiCallRequired = (urlFilter, selectedUserData) => {
    let filterData = getURLFilterAndByScore(urlFilter)
    if (
      !this.props[this.props.question].isDataAlreadyFetched ||
      !checkIfObjectsAreEqual(filterData.filters, this.props[this.props.question].filters) ||
      !checkIfTwoArraysAreIsEqual(filterData.byScore, this.props[this.props.question].byScore) ||
      !checkIfTwoArraysAreIsEqual(filterData.multipleSelect, this.props[this.props.question].multipleSelect) ||
      selectedUserData.directReporteesOnly !== this.props[this.props.question].directReporteesOnly ||
      selectedUserData.selectedUser !== this.props[this.props.question].selectedUser
    ) {
      return true;
    }
    return false;
  };

  apiCall = (filter) => {
    this.props.setQuestionTopic({ questionId: this.props.question, topic: "All Themes" })
    const surveyDetails = this.props.surveyDetailsApi.surveyDetails;
    let payload = getApiCallPayload(surveyDetails, this.props);
    if (filter) {
      payload.filters = {}
    }
    this.setState({ showLoader: true, loading: true });
    this.props.fetchExploreCommentStatisticsData({
      ...payload,
      batch_id: surveyDetails && surveyDetails.projectId,
    }, (success, response) => {
      this.setState({ loading: false })
      let threshold = this.props.thresholdApi.data && this.props.thresholdApi.data.filterThreshold ? this.props.thresholdApi.data.filterThreshold : 5
      if (response && response.data && response.data.commentCount >= threshold) {
        this.props.fetchExploreQuestionComments(
          {
            ...payload,
            enableFilter: true,
          },
          this.getHistogramData
        );

        this.props.fetchExploreWordcloudData({
          ...payload,
        });
        this.props.fetchExploreRelationData({
          ...payload,
          size: 10,
        });
      }
    });
  };

  getHistogramData = ({ success, projectDetails }) => {
    const filters = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(filters)
    const topic = this.props[this.props.question].selectedTopic
    const selectedUserData = getSelectedUserData();
    projectDetails.filters = filterData.filters;
    projectDetails.byScore = filterData.byScore
    projectDetails.directReporteesOnly = selectedUserData.directReporteesOnly;
    projectDetails.selectedUser = selectedUserData.selectedUser;
    projectDetails.currentTab = DASHBOARD_TABS.EXPLORE_SUB_TABS.COMMENT_QUESTIONS
    if (topic === "All Themes") {
      this.props.fetchAllThemeData(projectDetails);
    } else {
      this.getDemographicTopicData(topic)
    }
  };

  getDemographicTopicData = (value, demographics) => {
    const surveyDetails = this.props.surveyDetailsApi.surveyDetails;
    const topic = this.props[this.props.question].selectedTopic
    let payload = getApiCallPayload(surveyDetails, this.props);
    const columns =
      this.props[this.props.question].themeTopicDataApi.selectedColumns || [];
    this.props.fetchDemographicTopicData({
      ...payload,
      topics: value ? [value] : [topic],
      columns: demographics ? demographics : columns,
    });
  };

  topicChooserOnChange = (value) => {
    const surveyDetails = this.props.surveyDetailsApi.surveyDetails;
    this.props.setQuestionTopic({ questionId: this.props.question, topic: value })
    let payload = getApiCallPayload(surveyDetails, this.props);
    if (value !== constants.defaultTopic) {
      payload.topics = [value];
    } else {
      payload.topics = []
    }
    this.props.fetchExploreWordcloudData(payload);
    this.getHistogramData({
      success: true,
      projectDetails: {
        projectOrigin: surveyDetails.projectOrigin,
        projectId: surveyDetails.projectId,
      },
    });
    this.props.fetchExploreRelationData({
      ...payload,
      size: 10,
    });
    this.props.fetchExploreCommentStatisticsData({
      ...payload,
      batch_id: surveyDetails && surveyDetails.projectId,
      // topics: [value]
    });
    this.getDemographicTopicData(value);
  };

  onDemographicsChange = (selectedColumns) => {
    this.getDemographicTopicData(null, selectedColumns);
    this.props.updateThemeStateValues({ selectedColumns });
  };

  onRemove = (value) => {
    let selectedColumns = this.props[
      this.props.question
    ].themeTopicDataApi.selectedColumns.filter((item) => item !== value);
    this.getDemographicTopicData(null, selectedColumns);
    this.props.updateThemeStateValues({ selectedColumns });
  };

  filterQuestionDataByThreshold = (questionComments) => {
    return questionComments.filter((item) => item.count > getCustomThreshold());
  };

  getCommments = (payload, cb) => {
    const hrisFileId = this.props.surveyDetailsApi.surveyDetails.hrisFileId;
    const filters = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(filters)
    const selectedUserData = getSelectedUserData();
    let paylaodData = {
      ...payload,
      ...filterData,
      currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.COMMENT_QUESTIONS,
      directReporteesOnly: selectedUserData.directReporteesOnly,
      selectedUser: selectedUserData.selectedUser,
      hrisFileId: hrisFileId
    }
    this.props.fetchExploreTopicComments(paylaodData, cb)
  }

  render() {
    const apiData = getApiDatas(this.props)
    const topic = this.props[this.props.question].selectedTopic
    return (
      <>
        {/* {getLoader(this.props, this.state.showLoader) && !getError(this.props) ? (
          // <GraphLoader height={300} width={600} /> ) :  */}
        {this.showThresholdEmptystate(apiData.commentStatistics) ?
          <EmptyState emptyStateMessage={"The number of comments is below the threshold so you cannot see any data"} />
          : !getError(this.props) ? (
            <>
              <div style={{ marginBottom: "30px" }}>
                <TopicChooser
                  loading={this.state.loading}
                  props={this.props}
                  selected={topic}
                  threshold={this.props.thresholdApi.data && this.props.thresholdApi.data.filterThreshold ? this.props.thresholdApi.data.filterThreshold : 5}
                  topicChooserOnChange={this.topicChooserOnChange}
                  data={apiData.questionComments}
                  totalNumberOfDocuments={apiData.totalNumberOfDocuments}
                />
              </div>
              <RenderCommentStatistics
                props={this.props}
                commentStatistics={apiData.commentStatistics}
              />
              <BarChartLine
                loading={this.state.loading}
                props={this.props}
                defaultTopicIsSelected={topic === constants.defaultTopic}
                topic={topic}
                allThemeDataApi={this.props[this.props.question].allThemeDataApi}
                themeTopicDataApi={
                  this.props[this.props.question].themeTopicDataApi
                }
                onDemographicsChange={this.onDemographicsChange}
                onRemove={this.onRemove}
                fetchExploreTopicComments={(payload, cb) => this.getCommments(payload, cb)}
                projectId={apiData.surveyDetails.projectId}
                projectOrigin={apiData.surveyDetails.projectOrigin}
                selectedQuestionId={this.props.question}
                exploreTopicCommentsApi={this.props.exploreTopicCommentsApi}
              />
              {this.props[this.props.question].exploreWordCloudDataApi.isProcessing || this.state.loading ?
                <GraphLoader height={300} width={600} />
                : <WordCloud props={this.props} wordCloudData={apiData.wordCloudData} />}
              <RenderForceGraph
                question={this.props.question}
                threshold={this.props.thresholdApi.data && this.props.thresholdApi.data.filterThreshold ? this.props.thresholdApi.data.filterThreshold : 5}
                loading={this.state.loading}
                props={this.props}
                fetchExploreTopicComments={(payload, cb) => this.getCommments(payload, cb)}
                projectId={apiData.surveyDetails.projectId}
                projectOrigin={apiData.surveyDetails.projectOrigin}
                selectedQuestionId={this.props.question}
                relationData={apiData.relationData.response}
                allowedLinks={apiData.relationData.links}
                apicall={this.fetchtopicComments}
                exploreTopicCommentsApi={this.props.exploreTopicCommentsApi}
              />
            </>
          ) : (
            <EmptyState type={"Network Error"} component="table" />
          )}
      </>
    );
  }
}
