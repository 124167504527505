import {
  apiFailureState,
  apiSuccessState,
  apiStartState,
} from "../../../../utils/apiStateUtils";
export function fetchTopicsDataSuccess(state, action) {
  let { addfilter } = state;
  let qid=""
   qid= state.fetchQuestionIdApi.questionId;
   addfilter[qid]= Object.keys(action.data || {});
  return {
    ...state,
    demographicTopicApi: { error: false, loading: false },
    demographicTopicsData: action.data,
    demographicTopics: (action.topics || []).map((item) => ({
      label: item,
      value: item,
    })),
    demographicColumns: action.columns || [],
    
    addfilter:addfilter,
    selectedDemographicColumns:  Object.keys(action.data || {}),
  };
}

export function fetchTopicsDataStart(state, action) {
  return {
    ...state,
    demographicTopicsData: null,
    demographicTopicApi: { loading: true, error: false },
    selectedDemographicTopics: action.clear
      ? null
      : state.selectedDemographicTopics,
  };
}

export function fetchTopicsDataFail(state, action) {
  return {
    ...state,
    demographicTopicApi: { loading: false, error: true },
  };
}

export function fetchNumericDemographicsStart(state) {
  return {
    ...state,
    numericDemographicsApi: apiStartState(state.numericDemographicsApi),
    numericDemographicsTopicsData: null,
    numericDemographicsTopicApi: { loading: true, error: false },
  };
}

export function fetchNumericDemographicsSuccess(state, action) {
  return {
    ...state,
    numericDemographicsApi: apiSuccessState(
      state.numericDemographicsApi,
      action
    ),
    numericDemographicsTopicApi: { error: false, loading: false },
    numericDemographicsTopicsData: action.data,
    numericDemographicsTopics: (action.topics || []).map((item) => ({
      label: item,
      value: item,
    })),
    numericDemographicsColumns: action.columns || [],
    selectedNumericDemographicsColumns:action.selectedColumns.length>0 ? action.selectedColumns: Object.keys(action.data || {}),
    // selectedNumericDemographicsColumns: Object.keys(action.data || {}),
  };
}

export function fetchNumericDemographicsFail(state, action) {
  return {
    ...state,
    numericDemographicsApi: apiFailureState(
      state.numericDemographicsApi,
      action
    ),
  };
}
