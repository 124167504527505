import {
  makeKey,
  update,
  handleApiResponseError,
  handleNetworkError,
} from "../../../../utils/reducerOperations";
import { toast } from "../../../../utils/messageUtils";

export function changePasswordFailed(state, payload) {
  if (payload.response && payload.response.data && payload.response.data.reason)
    toast.error("Password is incorrect");
  else toast.error("Password change failed");
  let response = makeKey(
    "changePasswordApi",
    update(
      state.changePasswordApi,
      payload.response && payload.response.data
        ? handleApiResponseError(payload.response.data)
        : handleNetworkError(payload)
    )
  );
  return response;
}
