import { connect } from "react-redux";
import FeedbackTextEntry from "./FeedbackTextEntry";
import { handleChangeNotAnswerNeedCheckBox, handleChangePriorityText } from "../../../../../../redux/actions";


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleChangePriorityText: (payload) => dispatch(handleChangePriorityText(payload)),
        handleChangeNotAnswerNeedCheckBox: (payload) => dispatch(handleChangeNotAnswerNeedCheckBox(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackTextEntry);