import { fetchPayload } from "../../../utils/reducerOperations";
import {
    UN_PUBLISH_SURVEY_START,
    UN_PUBLISH_SURVEY_SUCCESS,
    UN_PUBLISH_SURVEY_FAILED
} from "../../constants";
import {
    unPublishSurveyStarted,
    unPublishSurveyFailed,
    unPublishSurveySuccess,
    
} from "./Helpers";
import { Default } from "./InitialState";
const UnPublishSurveyReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        //unPublish
        case UN_PUBLISH_SURVEY_START:
            return unPublishSurveyStarted(state, payload);
        case UN_PUBLISH_SURVEY_SUCCESS:
            return unPublishSurveySuccess(state, payload);
        case UN_PUBLISH_SURVEY_FAILED:
            return unPublishSurveyFailed(state, payload);
        
        default:
            return { ...state };
    }
};

export default UnPublishSurveyReducer;
