import React, { Component } from 'react'
import parse from "html-react-parser";
import { checkIfShowBoxLabel, getClassNameForMatrix } from './helpers';
import { getQuestionTranslation } from '../../../../../../utils/newAttendSurveyUtils';
import { KEY_CONSTANTS } from '../../constants';
import constants from './constants';
import "./index.scss";
import ErrorBlock from '../ErrorBlock';
import { AsterikLabel } from '../../../../../../Components/AsterikLabel';
import ScrollBar from '../../../../../../Packages/ScrollBar';
export default class MatrixQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverData: {
        index: "",
        staticId: ""
      }
    }
  }

  onSelectAnswer = (answerKey) => {
    let answers = []
    let { currentQuestionNumber,
      index,
      question,
      currentItem
    } = answerKey
    let answeredQuestionIsPresent = []
    if (this.props.currentQuestionSet && this.props.currentQuestionSet.answer) {
      answers = JSON.parse(this.props.currentQuestionSet.answer);
      answeredQuestionIsPresent = answers.filter(
        (items) => items.questionId === currentItem.value && items.answer === (index + 1).toString()
      );
    }
    if (!answeredQuestionIsPresent.length) {
      let answeredQuestion = answers.filter(
        (item) => item.questionId !== currentItem.value
      );
      let questionDimension =
        question && question.questionDimension;
      answeredQuestion.push({
        questionId: currentItem.value,
        questionNumber: currentQuestionNumber + 1,
        answer: index + 1,
        questionDimension: questionDimension,
        type: 'likert',
        question: currentItem.text,
        englishQuestion: currentItem.englishQuestion,
        staticQuestionId: currentItem.staticQuestionId
        // selectedQuestion: currentQuestion
      });
      this.props.updateAnswer({
        value: JSON.stringify(answeredQuestion),
        question: question,
        blockId: this.props.questionDetails.blockId
      })
      this.props.manageScroller()
      setTimeout(() => {
        this.props.updateCurrentIndex()

      }, 400)

    }
  }

  getClassName(currentQuestion, optionNumber) {
    if (currentQuestion.staticQuestionId === this.state.hoverData.staticId) {
      if (this.state.hoverData.index >= optionNumber)
        return `option-color${optionNumber}`
    }
    if (this.props.currentQuestionSet && this.props.currentQuestionSet.answer) {
      let answeredQuestion = JSON.parse(this.props.currentQuestionSet.answer)
      let result = answeredQuestion.filter(
        (item) => item.questionId === currentQuestion.value
      );
      if (result.length > 0) {
        if (result[0].answer >= optionNumber + 1)
          return 'option-color' + optionNumber;
      } else return '';
    }
    return ''
  }

  isLabelVisible = (answer, key, currentQuestion) => {
    let answeredQuestion = this.props.currentQuestionSet && this.props.currentQuestionSet.answer ? JSON.parse(this.props.currentQuestionSet.answer) : []
    let result = answeredQuestion.filter(
      (item) => item.questionId && item.questionId === currentQuestion.value
    );
    if (result.length) {
      if (result[0].answer === key + 1 && result[0].answer !== 5 && result[0].answer !== 1) {
        return getQuestionTranslation({ language: this.props.selectedLanguage, question: answer, key: KEY_CONSTANTS.LABEL })
      }
    } else {
      return '';
    }
  };

  onHoverChange = (currentItem, index) => {
    this.setState({
      hoverData: {
        index: index,
        staticId: currentItem.staticQuestionId
      }
    })
  }

  resetClassName = () => {
    this.setState({
      hoverData: {
        index: "",
        staticId: ""
      }
    })
  }

  getSelectionBox = ({ question, currentQuestionNumber, currentItem }) => {
    let options =
      question &&
      question.options.filter((item) => !item.disabled);
    return (
      <>
        <ul className='matrix-block__area--list'>
          {Array.from({ length: question.optionsCount }, (_, i) => i).map((item, index) => {
            return <li >
              <div
                onMouseOver={() => this.onHoverChange(currentItem, index)}
                onMouseLeave={() => this.resetClassName()}
                key={index}
                id={item}
                className={this.getClassName(currentItem, index) + ' box-color'}
                onClick={() => this.onSelectAnswer({
                  currentQuestionNumber,
                  index,
                  question,
                  currentItem

                })}>
              </div>
              {/* <span>{this.isLabelVisible(item, index, currentItem)}
              {checkIfShowBoxLabel(index) &&
                getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: KEY_CONSTANTS.LABEL })}
            </span> */}
            </li>
          })}

        </ul>
        <div className='text-survey'>
          {options.map((item, index) => {
            return (
              <span>{getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: KEY_CONSTANTS.LABEL })}</span>
            )
          })}
        </div>
      </>
    )
  }

  showNotApplicable = ({ question }) => {
    if (this.props.questionDetails.allowNAOption) {
      return true
    }
    else {
      return false
    }
  }


  onClickNaOption = ({ e, currentItem, currentQuestionNumber, question }) => {
    let answers = []
    let answeredQuestion = []
    if (this.props.currentQuestionSet && this.props.currentQuestionSet.answer) {
      answers = JSON.parse(this.props.currentQuestionSet.answer);
    }
    if (e) {
      answeredQuestion = answers.filter(
        (items) => items.questionId !== currentItem.value)
      let questionDimension =
        this.props.question && this.props.question.questionDimension;
      answeredQuestion.push({
        questionId: currentItem.value,
        questionNumber: currentQuestionNumber + 1,
        answer: constants.NOT_APPLICABLE,
        questionDimension: questionDimension,
        type: 'Matrix',
        question: currentItem.text,
        englishQuestion: currentItem.englishQuestion,
        staticQuestionId: currentItem.staticQuestionId
      });
    }
    else {
      answeredQuestion = answers.filter(
        (items) => items.questionId !== currentItem.value)
    }
    this.props.updateAnswer({
      value: JSON.stringify(answeredQuestion),
      question: question,
      blockId: this.props.questionDetails.blockId
    })
    this.props.manageScroller()
    setTimeout(() => {
      this.props.updateCurrentIndex()
    }, 400)
  }

  isNaOptionChecked = ({ currentItem }) => {
    if (this.props.currentQuestionSet && this.props.currentQuestionSet.answer) {
      let answers = JSON.parse(this.props.currentQuestionSet.answer);
      let answeredQuestion = answers.filter(
        (items) => items.questionId === currentItem.value)
      if (answeredQuestion.length && answeredQuestion[0].answer === constants.NOT_APPLICABLE) {

        return true
      }
    }
    return false
  }

  getNASection = ({ currentQuestionNumber, currentItem, question }) => {
    let isNaOptionChecked = this.isNaOptionChecked({ currentItem })
    return (
      <div className='matrix-block__area--activecheck'>
        <div className={isNaOptionChecked ? 'active-item answer' : 'active-item'} onClick={() => {
          this.onClickNaOption({ e: !isNaOptionChecked, currentItem, currentQuestionNumber, question })
        }}>
        </div>
        <span>N/A</span>
      </div>
    )
  }

  render() {
    return (
      <div className='newsurvey-block'>
      <div className="matrix-wrapper">
        <div className='matrix-wrapper__top'>
        <div className='matrix-wrapper__top--head'>
          <h2>
        {this.props.questionDetails.isShowTopic=== true?
          this.props.questionDetails.dimension:""}
          </h2>
          <h5>{this.props.questionDetails.description && parse(this.props.questionDetails.description)}</h5>
          </div>
            {this.props.questionDetails &&
              this.props.questionDetails.questions[0] &&
              this.props.questionDetails.questions[0].matrixQuestions &&
              <>
                {this.props.questionDetails.questions[0].question &&
                  this.props.questionDetails.questions[0].question.length > 0 &&
                  <span className='matrix-block__matrix-description'> {parse(this.props.questionDetails.questions[0].question)}</span>}
                {this.props.questionDetails.questions[0].matrixQuestions.map((item, index) => {
                  return <div className='matrix-block'>
                    <div className='matrix-block__quest'>
                      <h4 className={this.props.questionDetails.isRequired ? 'heading-asterisk' : ""}>
                        {/* <span>
                          {`${index + 1})`}
                        </span> */}
                        <>
                          {parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: item.HTMLText ? KEY_CONSTANTS.HTML_TEXT : KEY_CONSTANTS.NORMAL_TEXT }))}
                          {/* {this.props.questionDetails.isRequired && < AsterikLabel className="required-icon" />} */}
                        </>
                      
                      </h4>
                    </div>
                    <div className='matrix-block__area'>
                      <div className='matrixbox-wrapper'>
                      {this.getSelectionBox({ question: this.props.questionDetails.questions[0], currentQuestionNumber: index, currentItem: item })}
                      </div>
                      {this.showNotApplicable({ question: this.props.questionDetails.questions[0] }) ? <> {this.getNASection({ currentQuestionNumber: index, currentItem: item, question: this.props.questionDetails.questions[0] })} </> : ""}
                   
                    </div>

                  </div>
                })}
              </>
          }
          <ErrorBlock manageScroller={this.props.manageScroller} />
        </div>
      </div>
      </div>
    )
  }
}
