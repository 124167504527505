import {
    FETCH_STORYBOARD_DRIVERS_FAILED,
    FETCH_STORYBOARD_DRIVERS_START,
    FETCH_STORYBOARD_DRIVERS_SUCCESS
} from '../../constants';
import { FETCH_DRIVERS_URL } from '../../../../config/constants';
import { ApiBase } from '../../../utils/apiBase';
import { withType } from '../../../utils/actionOperations';
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchStoryboardDrivers = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(FETCH_STORYBOARD_DRIVERS_START, {}));
        apiBaseInstance.instance
            .post(FETCH_DRIVERS_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                dispatch(withType(FETCH_STORYBOARD_DRIVERS_SUCCESS, {
                    data: response.data,
                    message: response.message,
                }));
                setTimeout(() => {
                    cb && cb();
                }, 0)
            })
            .catch(err => {
                dispatch(withType(FETCH_STORYBOARD_DRIVERS_FAILED, {
                    error: err
                }));
            });
    };
};

