import * as actionTypes from "../../constants";
import * as helper from "./helper";
import initialState from "./intialState";

const NLPRouteReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NLP_BATCHIDS_START:
      return helper.fetchBatchIdsStart(state);
    case actionTypes.FETCH_NLP_BATCHIDS_SUCCESS:
      return helper.fetchBatchIdsSuccess(state, action);
    case actionTypes.FETCH_NLP_BATCHIDS_FAIL:
      return helper.fetchBatchIdsFail(state, action);
    case actionTypes.FETCH_NLP_FILTER_START:
      return helper.fetchFilterStart(state);
    case actionTypes.FETCH_NLP_FILTER_SUCCESS:
      return helper.fetchFilterSuccess(state, action);
    case actionTypes.FETCH_NLP_FILTER_FAIL:
      return helper.fetchFilterFail(state, action);
    case actionTypes.UPDATE_NLP_FACET_GROUP:
      return helper.updateNLPFacetGroup(state, action);
    case actionTypes.UPDATE_NLP_FACET_GROUP_ITEM:
      return helper.updateNLPFacetGroupItem(state, action);
    default:
      return nlpSecondReducer(state, action);
  }
};

function nlpSecondReducer(state, action) {
  switch (action.type) {
    case actionTypes.ADD_ADDITIONAL_FILTERS:
      return helper.addAdditionalFilter(state, action);
    case actionTypes.FILTER_SEARCH_CHANGE:
      return helper.addFilterSearchKey(state, action);
    case actionTypes.FETCH_NLP_COMMENT_COUNT_START:
      return helper.fetchCommentsCountStart(state);
    case actionTypes.FETCH_NLP_COMMENT_COUNT_SUCCESS:
      return helper.fetchCommentsCountSuccess(state, action);
    case actionTypes.FETCH_NLP_COMMENT_COUNT_FAIL:
      return helper.fetchCommentsCountFail(state, action);
    case actionTypes.FETCH_COMMENT_STATISTICS:
      return helper.fetchCommentStatisticsStart(state);
    case actionTypes.FETCH_COMMENT_STATISTICS_SUCCESS:
      return helper.fetchCommentStatisticsSuccess(state, action);
    case actionTypes.FETCH_COMMENT_STATISTICS_FAIL:
      return helper.fetchCommentStatisticsFail(state, action);
    case actionTypes.FETCH_BATCH_QUESTIONS_START:
      return helper.fetchBatchQuestionsStart(state);
    case actionTypes.FETCH_BATCH_QUESTIONS_SUCCESS:
      return helper.fetchBatchQuestionsSuccess(state, action);
    case actionTypes.FETCH_BATCH_QUESTIONS_FAIL:
      return helper.fetchBatchQuestionsFail(state, action);
    default:
      return nlpThirdReducer(state, action);
  }
}

const nlpThirdReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NLP_TREE_DATA_STARTED:
      return helper.fetchTreeDataStart(state, action);
    case actionTypes.FETCH_NLP_TREE_DATA_SUCCEED:
      return helper.fetchTreeDataSuccess(state, action);
    case actionTypes.FETCH_NLP_TREE_DATA_FAILED:
      return helper.fetchTreeDataFail(state, action);
    case actionTypes.SELECT_REPORT:
      return helper.changeProject(state, action);
    
    default:
      return { ...state };
  }
};

export default NLPRouteReducer;
