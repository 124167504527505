import { Affix } from "antd";
import React from "react";
import GraphLoader from "../../../../../../../../Components/ContentLoaders/GraphLoader";
import { SelectElement } from "../../../../../../../../FormElements/SelectElement";
class TopicChooser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  onChangeTopic = (value) => {
    this.props.topicChooserOnChange(value)
  }

  getData = (data) => {
    return data.filter(item => item.count >= this.props.threshold)
  }

  render() {
    let data = this.getData(this.props.data)
    return (
      <>
        {this.props.props[this.props.props.question].exploreQuestionCommentsApi.isProcessing || this.props.loading ?
          <GraphLoader height={100} width={600} />
          :
          <Affix offsetTop={5} >
          <React.Fragment>
            <div className="topic-chooser comments-input">
                <div id='sdsdsds' className="commentquestion-search"></div> 
              <SelectElement
                  getPopupContainer={(trigger) => document.getElementById('sdsdsds')}
                showSearch
                className="topic-select"
                onChange={this.onChangeTopic}

                value={this.props.selected}
                optionList={data && data}
                placeholder={"Select Theme"}
              ></SelectElement>
            </div>
          </React.Fragment>
          </Affix >
        }
      </>
    );
  }
}

export default TopicChooser;
