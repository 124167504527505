import {
    makeKey,
    update,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
export function getUserProfileFailed(state, payload) {
    if (payload.data && payload.data.response)
        return makeKey(
            'getUserProfileApi',
            update(
                state.getUserProfileApi,
                handleApiResponseError(payload.response.data)
            )
        );
    else
        return makeKey(
            'getUserProfileApi',
            update(
                state.getUserProfileApi,
                handleNetworkError(payload)
            )
        );
}
