export function Default() {
    return {

        fetchResultsPageApi: {
            isProcessing: false,
            error: false,
            data: null,

        },
        fetchMatrixVisualizationApi: {
            isProcessing: false,
            error: false,
            data: null,

        },
        fetchCircumplexDataApi:{
            isProcessing:false,
            error:false,
            data:null,
        }

    }
}