import React from "react";
import { ButtonElement } from "../../FormElements/ButtonElement";
import CustomModal from "../../Components/CustomModal";
import style from "./style.module.scss";
import { LoaderButton } from "../../FormElements/LoaderButton";
export default class ConfirmationMessage extends React.Component {
  render() {
    return (
      <CustomModal
        closable={true}
        onCancel={this.props.confirmBoxCancel}
        visible={this.props.visibleConfirm}
        title={this.props.deleteHeader}
        onOk={this.props.onOk}
        footer={
          <>
            <LoaderButton
              data-test="login-btn"
              loader={this.props.loading ? this.props.loading : null}
              onClick={this.props.onOk}
              classList={["loader-button"]}
              loaderClass={["xs white"]}
              text={"Yes"}
              disabled={this.props.disabled?true:false}
            />
            <ButtonElement
              onClick={this.props.confirmBoxCancel}
              children={"No"}
            />
          </>
        }
      >
        <ul className="clb-model-form">
          <label>
            <span class="label-name">{this.props.heading}</span>
          </label>
          <div className={style.confirmationMessage__content}>
            {this.props.children}
          </div>
        </ul>
      </CustomModal>
    );
  }
}
