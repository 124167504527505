import { fetchPayload } from "../../../utils/reducerOperations";
import {
    LIST_TEXT_QUESTIONS_FAILED,
    LIST_TEXT_QUESTIONS_START,
    LIST_TEXT_QUESTIONS_SUCCESS,
    MARK_AS_CUSTOM_TOPIC,
    SET_FILE_NAME,
    UPLOAD_QUESTION_TOPIC_FAILED,
    UPLOAD_QUESTION_TOPIC_SUCCESS,
    UPLOAD_QUESTION_TOPIC_START,
    REPROCESS_SURVEY_START,
    REPROCESS_SURVEY_SUCCESS,
    REPROCESS_SURVEY_FAILED
} from "../../constants";
import {
    listTextEntryQuestionsFailed,
    listTextEntryQuestionsStarted,
    listTextEntryQuestionsSuccess,
    markQuestionAsCustomTopic,
    reprocessSurveyFailed,
    reprocessSurveyStart,
    reprocessSurveySuccess,
    setFileName,
    uploadQuestionTopicFailed,
    uploadQuestionTopicStart,
    uploadQuestionTopicSuccess
} from "./Helpers";
import { Default } from "./InitialState";
const QuestionsReprocessReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        //list
        case LIST_TEXT_QUESTIONS_START:
            return listTextEntryQuestionsStarted(state, payload);
        case LIST_TEXT_QUESTIONS_SUCCESS:
            return listTextEntryQuestionsSuccess(state, payload);
        case LIST_TEXT_QUESTIONS_FAILED:
            return listTextEntryQuestionsFailed(state, payload);
        case MARK_AS_CUSTOM_TOPIC:
            return markQuestionAsCustomTopic(state, payload)
        case SET_FILE_NAME:
            return setFileName(state, payload)
        case UPLOAD_QUESTION_TOPIC_START:
            return uploadQuestionTopicStart(state, payload)
        case UPLOAD_QUESTION_TOPIC_SUCCESS:
            return uploadQuestionTopicSuccess(state, payload)
        case UPLOAD_QUESTION_TOPIC_FAILED:
            return uploadQuestionTopicFailed(state, payload)
        case REPROCESS_SURVEY_START:
            return reprocessSurveyStart(state, payload)
        case REPROCESS_SURVEY_SUCCESS:
            return reprocessSurveySuccess(state, payload)
        case REPROCESS_SURVEY_FAILED:
            return reprocessSurveyFailed(state, payload)
        default:
            return { ...state };
    }
};

export default QuestionsReprocessReducer;
