import { EXPORT_FORM_CHANGE, EXPORT_FORM_CLEAR } from '../../constants';
import { withType } from '../../../utils/actionOperations';

export const exportFormChange = event => {
    return dispatch => {
        dispatch(withType(EXPORT_FORM_CHANGE, event));
    };
};

export const exportFormClear = () => {
    return dispatch => {
        dispatch(withType(EXPORT_FORM_CLEAR, {}));
    };
};
