import { checkIfPreviewLink } from "../../../../../../utils/newAttendSurveyUtils"
import { getIndexRoute } from "../../../../../../utils/urlProcessor"
import constants from "../../../../constants"

export const getTranslatedTextPayload = (instance, value) => {
    let payload = {
        isPreview: checkIfPreviewLink(constants.PREVIEW_LINK),
        language: value,
        orgId: instance.surveyQuestionSetData.organizationId,
        projectId: getIndexRoute(),
        translateJson: instance.defaultTexts
    }
    if (instance.surveyQuestionSetData && instance.surveyQuestionSetData.translatedDescription) {
        if (!Object.keys(instance.surveyQuestionSetData.translatedDescription).includes(value)) {
            payload.translateJson.translatedDescription = instance.surveyQuestionSetData.aboutSurvey
        }
    }
    else {
        payload.translateJson.translatedDescription = instance.surveyQuestionSetData.aboutSurvey
    }
    return payload
}