import * as actionTypes from "../../constants";
import { LIST_SURVEY_URL } from "../../../../config/constants";
import { NLPCommonReqBodies, getSurveyListQueryParams } from "../../../utils/actionOperations";
import { ApiBase } from "../../../utils/apiBase";
import { getOrgIdFromUrl } from "../../../utils/urlProcessor";

export const getSurveys = (payload,cb) => {
  const orgId = getOrgIdFromUrl()
  const fetchProps = getSurveyListQueryParams(payload)
  const axios = new ApiBase().instance;
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_SURVEY_LIST_STARTED });
    axios.post(LIST_SURVEY_URL, { ...NLPCommonReqBodies(true, orgId), ...fetchProps })
      .then((res) => {getUserProjectsSuccess(dispatch, res)
      cb && cb(res) 
       })
      .catch((err) => getUserProjectsFailed(dispatch, err));
  };
};

function getUserProjectsFailed(dispatch, err) {
  return dispatch({
    type: actionTypes.GET_SURVEY_LIST_FAIL,
    error: err.message,
  });
}
function getUserProjectsSuccess(dispatch, res) {
  return dispatch({
    type: actionTypes.GET_SURVEY_LIST_SUCCESS,
    data: res.data.data,
  });
}
