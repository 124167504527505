import { deepCopy } from "../../../../utils/deepCopy";
import { nlpGetURLParams } from "../../../../utils/nlpURLUtils";
import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"
import { TOPIC_GROUPS } from './constants';

export const getStoryboardTopicsStarted = (state) => {
    return {
        ...state,
        storyboardTopicsApi: getApiStartState()
    }

}

export const getStoryboardTopicsSuccess = (state, payload) => {
    const groupedData = groupStoryboardData(getSuccessResponse(payload));
    console.log('Grouped data', groupedData)
    return {
        ...state,
        storyboardTopicsApi: groupedData
    }

}

const groupStoryboardData = (payload) => {
    let topicList = payload.data.data.hits;
    const { outcome } = nlpGetURLParams();
    let outcomeVariable = outcome && outcome !== 'undefined' ? outcome : 'Engagement'
    let groupedTopics = [];
    let isCustomTopicOutcome = true;
    let topicsWithOutcomeSeperated = deepCopy(TOPIC_GROUPS);
    if (outcomeVariable !== 'Engagement') {
        topicsWithOutcomeSeperated.forEach((group) => {
            if (group.value.includes(outcomeVariable)) {
                isCustomTopicOutcome = false;
                group.value.splice(group.value.indexOf(outcomeVariable), 1);
                topicsWithOutcomeSeperated.push({
                    key: outcomeVariable,
                    value: [outcomeVariable]
                })
            }
        })
        if (isCustomTopicOutcome) {
            topicsWithOutcomeSeperated.push({
                key: outcomeVariable,
                value: [outcomeVariable]
            })
        }
    }
    topicsWithOutcomeSeperated.forEach((group) => {
        let topicsUnderGroup = topicList.filter(item => group.value.includes(item.topic))
        if (topicsUnderGroup.length > 1) {
            topicsUnderGroup = topicsUnderGroup.map(item => {
                return {
                    ...item,
                    type: group.key,
                    questionId: item.topic,
                    questionText: item.topic,
                }
            })
            groupedTopics.push(topicsUnderGroup);
            topicList = topicList.filter(item => !group.value.includes(item.topic))
        }
        if (topicsUnderGroup.length === 1) {
            topicsUnderGroup = topicsUnderGroup.map(item => {
                return {
                    ...item,
                    type: item.topic,
                    questionId: item.topic,
                    questionText: item.topic,
                }
            })
            groupedTopics.push(topicsUnderGroup);
            topicList = topicList.filter(item => !group.value.includes(item.topic))
        }
    })
    if (topicList.length > 3) {
        topicList = topicList.map(item => {
            return {
                ...item,
                type: 'Custom topics',
                questionId: item.topic,
                questionText: item.topic,
            }
        })
        groupedTopics.push(topicList)
    } else {
        topicList.forEach(item => {
            groupedTopics.push([{
                ...item,
                type: item.topic,
                questionId: item.topic,
                questionText: item.topic,
            }])
        })
    }
    console.log("Grouped", groupedTopics)
    // eslint-disable-next-line
    groupedTopics = groupedTopics.sort((a, b) => {
        // eslint-disable-next-line
        if (a[0].type === outcomeVariable)
            return -1;
        if (a[0].type === "Custom topics")
            return 1; // eslint-disable-next-line
    })
    payload.data.data = groupedTopics;
    return payload;
}

export const getStoryboardTopicsFailed = (state, payload) => {
    return {
        ...state,
        storyboardTopicsApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}

export const updateStoryboardTopics = (state, payload) => {
    return {
        ...state,
        storyboardTopicsApi: {...state.storyboardTopicsApi ,data:{...payload}}
    }

}