import { connect } from 'react-redux';
import { Comments } from './Comments';
import {
  changeExploreQuote,
  showPreviousExploreQuote,
  fetchExploreTopicList,
  disableTopicLoading
} from '../../../../../../../../redux/actions';

const mapStateToProps = (state) => {
  return {
    surveyDetails: state.DashBoardReducer.surveyDetailsApi.surveyDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeExploreQuote: (params, callBack) =>
      dispatch(changeExploreQuote(params, callBack)),
    showPreviousExploreQuote: (params, callBack) =>
      dispatch(showPreviousExploreQuote(params, callBack)),
    fetchExploreTopicList: (params) => dispatch(fetchExploreTopicList(params)),
    disableTopicLoading: (params) => dispatch(disableTopicLoading(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
