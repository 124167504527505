import { apiFailureState, apiSuccessState } from "../../../utils/apiStateUtils";

export function fetchInsightsTableCallSuccess(state, action) {
  return {
    ...state,
    insightsApiData: apiSuccessState(state.insightsApiData, action),
  };
}

export function fetchInsightsTableCallStart(state, action) {
  return {
    ...state,
    insightsApiData: {
      ...state.insightsApiData,
      loading: true,
    },
  };
}

export function fetchInsightsTableCallFail(state, action) {
  return {
    ...state,
    insightsApiData: apiFailureState(state.insightsApiData, action),
  };
}

export function fetchNumericInsightsCallSuccess(state, action) {
  return {
    ...state,
    numericInsightsApi: apiSuccessState(state.numericInsightsApi, action),
  };
}

export function fetchNumericInsightsCallStart(state, action) {
  return {
    ...state,
    numericInsightsApi: {
      ...state.numericInsightsApi,
      loading: true,
    },
  };
}

export function fetchNumericInsightsCallFail(state, action) {
  return {
    ...state,
    numericInsightsApi: apiFailureState(state.numericInsightsApi, action),
  };
}
