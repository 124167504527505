import {
    LIST_REMINDER_START,
    LIST_REMINDER_SUCCESS,
    LIST_REMINDER_FAILED
} from '../../../constants';
import { LIST_REMINDER_URL } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { withType } from '../../../../utils/actionOperations';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const listReminder = (payload) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(LIST_REMINDER_START, {}));
        apiBaseInstance.instance
            .post(LIST_REMINDER_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                dispatch(withType(LIST_REMINDER_SUCCESS, {
                    data: response.data,
                    message: response.message
                }));
            })
            .catch(err => {
                dispatch(withType(LIST_REMINDER_FAILED, {
                    error: err
                }));
            });
    };
};

