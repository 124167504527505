import {
    makeKey,
    update,
    getSuccessResponse,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
const pivotApi = 'pivotApi';
export function updatePivotStarted(state, payload) {
    return makeKey(
        pivotApi,
        update(state.pivotApi, {
            isProcessing: true,
            data: state.pivotApi.data ? {
                ...state.pivotApi.data,
                cols:payload.cols,
                rows:payload.rows
            } : null,
            error: null
        })
    );
}

export function updatePivotSuccess(state, payload) {
    return makeKey(
        pivotApi,
        update(state.pivotApi, getSuccessResponse(payload))
    );
}

export function updatePivotFailed(state, payload) {
    return makeKey(
        pivotApi,
        update(state.pivotApi, {
            ...(payload.response && payload.response.data
                ? {
                    ...handleApiResponseError(payload.response.data),
                    data: null
                }
                : {
                    ...handleNetworkError(payload),
                    data: null
                })
        })
    );
}