import React from "react";
import { UserOutlined } from "@ant-design/icons";
export const Message = {
  error: {
    key: "user_error",
    type: "danger",
    text: "Users Fetching Error",
  },
  empty: {
    key: "user_empty",
    type: "warning",
    text: "No Users Avalible",
  },
  active: {
    key: "user_empty",
    type: "warning",
    text: "No active Users Available",
  },
};
export default {
  ERROR_MESSAGE: "No users found!",
  ZERO: "0",
  ADD_USER_MODAL_HEADER: "Add User",
  MANAGER: "Manager",
  MANAGER_KEY: "manager",
  MANAGER_PLACEHOLDER: "Select Manager",
  USER_ADD_VALIDATION: "Please enter all fields",
  UPLOAD_SUCCESS_MESSAGE:
    "Thank you. Your hris file was uploaded successfully",
  ADD: "Add",
  addUserRows: [
    {
      label: "First Name",
      value: "firstName",
      placeholder: "Enter First Name",
    },
    { label: "Last Name", value: "lastName", placeholder: "Enter Last Name" },
    { label: "Email ID", value: "email", placeholder: "Enter Email ID" },
    {
      label: "Role Name",
      value: "roleName",
      placeholder: "Enter Role Name",
      disabled: true,
    },
  ],
  searchPlaceholder: "Search",
  hrisFileWarning: "No hierarchy found!",
  defaultHrisValue: "defaultHrisValue",
  states: {
    error: "error",
    empty: "empty",
    active: "active",
  },
  loaderClasses: ["lg", "blue"],
  btn: {
    classes: ["loader-btn"],
    loaderClasses: ["sm", "white"],
    text: "Add",
  },
  searchBtn: {
    classes: ["loader-btn"],
    loaderClasses: ["sm", "white"],
    text: "Search",
  },
  data: [
    {
      title: "ffounder@yopmail.com",
      value: 5643,
      key: "founder@yopmail.com",
      children: [
        {
          title: "projectmanager@yopmail.com",
          value: 5622,
          key: "projectmanager@yopmail.com",
          children: [
            {
              title: "projectlead1@yopmail.com",
              value: 1193,
              key: "projectlead1@yopmail.com",
              children: [
                {
                  title: "team1developer1@yopmail.com",
                  value: 1193,
                  key: "team1developer1@yopmail.com",
                  switcherIcon: <UserOutlined />,
                },
                {
                  title: "team1developer2@yopmail.com",
                  value: 1193,
                  key: "team1developer2@yopmail.com",
                  switcherIcon: <UserOutlined />,
                },
              ],
            },
            {
              title: "projectlead2@yopmail.com",
              value: 1193,
              key: "projectlead2@yopmail.com",
              children: [
                {
                  title: "team2developer1@yopmail.com",
                  value: 1193,
                  key: "team2developer1@yopmail.com",
                  switcherIcon: <UserOutlined />,
                },
                {
                  title: "team2developer2@yopmail.com",
                  value: 1193,
                  key: "team2developer2@yopmail.com",
                  switcherIcon: <UserOutlined />,
                },
              ],
            },
            {
              title: "projectlead3@yopmail.com",
              value: 1193,
              key: "projectlead3@yopmail.com",
              children: [
                {
                  title: "team3developer1@yopmail.com",
                  value: 1193,
                  key: "team3developer1@yopmail.com",
                  switcherIcon: <UserOutlined />,
                },
                {
                  title: "team3developer2@yopmail.com",
                  value: 1193,
                  key: "team3developer2@yopmail.com",
                  switcherIcon: <UserOutlined />,
                },
              ],
            },
            {
              title: "projectlead4@yopmail.com",
              value: 1193,
              key: "projectlead4@yopmail.com",
              switcherIcon: <UserOutlined />,
            },
            {
              title: "projectlead5@yopmail.com",
              value: 1193,
              key: "projectlead5@yopmail.com",
              switcherIcon: <UserOutlined />,
            },
            {
              title: "projectlead6@yopmail.com",
              value: 1193,
              key: "projectlead6@yopmail.com",
              switcherIcon: <UserOutlined />,
            },
          ],
        },
      ],
    },
  ],
};

export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_NEXT = "FETCH_USERS_NEXT";
export const FETCH_USERS_PREV = "FETCH_USERS_PREV";
