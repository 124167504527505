import React from 'react';
import CustomModal from '../../../Components/CustomModal';
import { ButtonElement } from '../../../FormElements/ButtonElement';
import { LoaderButton } from '../../../FormElements/LoaderButton';

export const DeleTeUserModal = (props) => {
  let firstName, lastName;
  if (props.data) {
    firstName = props.data.firstName;
    lastName = props.data.lastName;
  }
  const getDeleteModalFooter = () => {
    return [
      <LoaderButton
        loader={props.loading}
        disabled={props.loading}
        onClick={props.deleteUser}
        classList={['loader-button']}
        loaderClass={['xs white']}
        text='Yes'
      />,
      <ButtonElement onClick={props.onCancel} children='No' />,
    ];
  };
  return (
    <CustomModal
      title={'Delete admin'}
      visible={props.visible}
      footer={getDeleteModalFooter()}
      closable={true}
      onCancel={props.onCancel}
      commentModal={true}
    >
      Are you sure you want to delete the admin {firstName + ' ' + lastName}?
    </CustomModal>
  );
};
