import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const cultureOfInclusion = {
    "blockId": uuidv4(),
    "description": "<p>Enable everyone to do their best work.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategoryId": generateStaticQuestionId("Enable psychological safety"),
                    "subCategory":"Enable psychological safety",
                    "staticQuestionId": generateStaticQuestionId("Treats all people with respect, regardless of their position or background"),
                    "HTMLText": "<p>Treats all people with respect, regardless of their position or background</p>",
                    "isSelected": true,
                    "text": "Treats all people with respect, regardless of their position or background",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Learn and Adapt to Cultural Contexts"),
                    "subCategory":"Learn and Adapt to Cultural Contexts",
                    "staticQuestionId": generateStaticQuestionId("Seeks opportunities to learn about different cultures, value systems, and beliefs"),
                    "HTMLText": "<p>Seeks opportunities to learn about different cultures, value systems, and beliefs</p>",
                    "isSelected": true,
                    "text": "Seeks opportunities to learn about different cultures, value systems, and beliefs",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Learn and Adapt to Cultural Contexts"),
                    "subCategory":"Learn and Adapt to Cultural Contexts",
                    "staticQuestionId": generateStaticQuestionId("Adapts behaviors based on cultural context"),
                    "HTMLText": "<p>Adapts behaviors based on cultural context</p>",
                    "isSelected": true,
                    "text": "Adapts behaviors based on cultural context",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Build diverse teams"),
                    "subCategory":"Build diverse teams",
                    "staticQuestionId": generateStaticQuestionId("Works well with people who differ in race, gender, culture, generation, or age"),
                    "HTMLText": "<p>Works well with people who differ in race, gender, culture, generation, or age</p>",
                    "isSelected": true,
                    "text": "Works well with people who differ in race, gender, culture, generation, or age",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Build diverse teams"),
                    "subCategory":"Build diverse teams",
                    "staticQuestionId": generateStaticQuestionId("Hires people with a diversity of skills, backgrounds, perspectives, and experiences; values diverse contributions"),
                    "HTMLText": "<p>Hires people with a diversity of skills, backgrounds, perspectives, and experiences; values diverse contributions</p>",
                    "isSelected": true,
                    "text": "Hires people with a diversity of skills, backgrounds, perspectives, and experiences; values diverse contributions",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Enable psychological safety"),
                    "subCategory":"Enable psychological safety",
                    "staticQuestionId": generateStaticQuestionId("Fosters an environment where all team members feel valued and respected"),
                    "HTMLText": "<p>Fosters an environment where all team members feel valued and respected</p>",
                    "isSelected": true,
                    "text": "Fosters an environment where all team members feel valued and respected",
                    "value": uuidv4()
                },
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Culture of Inclusion"
}