import {
    UPDATE_SURVEY_ANSWER,
    DISTRIBUTE_SEND_EMAIL_START,
    DISTRIBUTE_SEND_EMAIL_SUCCESS,
    DISTRIBUTE_SEND_EMAIL_FAIL,
    DISTRIBUTE_EMAIL_UPDATE_FORM
} from '../../constants';
import { EMAIL_DISTRIBUTION_URL } from '../../../../config/constants';
import { ApiBase } from '../../../utils/apiBase';
import { 
    getValueFromQueryString
} from '../../../utils/getValueFromUrl';
import { newJson } from '../../../utils/newJson';

export function updateAnswer(payload){
    return{
        type:UPDATE_SURVEY_ANSWER,
        payload
    };
}

export function sendDistributionEmail(payload){
    // payload.organisationId='orgID1';
    payload.projectId=getValueFromQueryString('projectId');
    return dispatch => {
        dispatch({ type: DISTRIBUTE_SEND_EMAIL_START });
        const axios = new ApiBase().instance;
        axios.post(EMAIL_DISTRIBUTION_URL,payload)
            .then(sendMailSuccess.bind(null,dispatch))
            .catch(sendMailFailed.bind(null,dispatch));
    };
}
    
function sendMailSuccess (dispatch,response){
    dispatch({ 
        type: DISTRIBUTE_SEND_EMAIL_SUCCESS,
        response
    });
};

function sendMailFailed (dispatch,error){
    dispatch({
        type:DISTRIBUTE_SEND_EMAIL_FAIL,
        response:error
    });
};

export function setEmailFormConfig(newConfig){
    return {
        type:DISTRIBUTE_EMAIL_UPDATE_FORM,
        newConfig:newJson(newConfig)
    };
};

export function updateEmailFormConfig({parentKey,key,value,config}){
    config[parentKey].fields
        .find(field=>field.key===key).value=value;
    return dispatch=>{
        dispatch(setEmailFormConfig(config));
    };
};