import {
  FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_FAIL,
  FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_START,
  FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_SUCCESS,
  SET_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_FETCHED_FALSE,
  SET_NEW_DASHBOARD_EXPLORE_OUTLIERS_FILTERS,
} from "../../../constants";
import { FETCH_OUTLIERS_URL } from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchNewDashboardOutliersData = (payload) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_START, {}));
    return apiBaseInstance
      .post(FETCH_OUTLIERS_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        dispatch(
          withType(FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_SUCCESS, {
            data: res.data,
            filters: payload.filters,
            selectedUser: payload.selectedUser,
            directReporteesOnly: payload.directReporteesOnly,
          })
        );
      })
      .catch((err) => {
        return dispatch(
          withType(FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_FAIL, err)
        );
      });
  };
};

export const setExploreOutlierDataFetchedKeyFlase = () => {
  return (dispatch) => {
    dispatch(
      withType(SET_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_FETCHED_FALSE, {})
    );
  };
};

export const setExploreOutliersFilters = () => {
  return (dispatch) => {
    dispatch(withType(SET_NEW_DASHBOARD_EXPLORE_OUTLIERS_FILTERS, {}));
  };
};
