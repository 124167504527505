import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const getTopicListStarted = (state) => {
    return {
        ...state,
        getTopicListApi: getApiStartState()
    }

}

export const getTopicListSuccess = (state, payload) => {
    return {
        ...state,
        getTopicListApi: getSuccessResponse(payload)
    }

}

export const getTopicListFailed = (state, payload) => {
    return {
        ...state,
        getTopicListApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}