import React from 'react';
import Comments from './Comments';
import { Spin } from 'antd';

export class TopicComments extends React.Component {
  getTopicComments = () => {
    let topicComments = this.props.data.topicComments;
    if(topicComments&&topicComments.positiveComments){
      return [
        ...topicComments.positiveComments,
        ...topicComments.negativeComments,
      ];
    }
    
  };

  render() {
    const { data } = this.props;
    const comments = this.getTopicComments();
    return (
      <>
        {comments && comments.length ? (
          <>
            <h2>Sample comments</h2>
            <div className='topics-description__content'>
              {/* <div className='topics-description__content--left'> */}
              {/* <div className='warning-box'>
            <div className='warning-box__icon'>
              <SVGIcons type={'SVG-warning-light'} />
            </div>
            <div className='warning-box__content'>
              <p>
                <b>Tip:</b> Try helping managers build more skills around
                communication
              </p>
            </div>
          </div> */}
              {/* </div> */}
              {this.props.showStoryboardProgress ? (
                <div className='topics-description__loader'>
                  <Spin spinning={this.props.showStoryboardProgress} />
                  <span>Processing</span>

                </div>
              ) : (
                <Comments
                  comments={comments}
                  topicObject={data}
                  className='custom-topic-comments'
                />
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </>
    );
  }
}
