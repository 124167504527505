import {
    SET_PAGINATION_FOR_COHORT,
    SET_SORT_ORDER_AND_SORT_FIELD_FOR_COHORT
} from "../../../constants";


export const handleChangeSortOrderAndSortField = (payload, cb) => {
    return (dispatch) => {
        dispatch({
            type: SET_SORT_ORDER_AND_SORT_FIELD_FOR_COHORT,
            payload: payload
        });
    }
};


