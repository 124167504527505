import { 
    DISTRIBUTE_SEND_EMAIL_START,
    DISTRIBUTE_SEND_EMAIL_SUCCESS,
    DISTRIBUTE_SEND_EMAIL_FAIL,
    DISTRIBUTE_EMAIL_UPDATE_FORM
} from '../../constants';
import { Default } from './InitialState';
import { 
    sendMailStart,
    sendMailSuccess,
    sendMailfail,
    updateEmailForm
} from './helper';


const emailDistributionReducer = (state=Default(), action) => {
    switch (action.type) {
    case DISTRIBUTE_SEND_EMAIL_START:
        return sendMailStart(state,action);
    case DISTRIBUTE_SEND_EMAIL_SUCCESS:
        return sendMailSuccess(state,action);
    case DISTRIBUTE_SEND_EMAIL_FAIL:
        return sendMailfail(state,action);
    case DISTRIBUTE_EMAIL_UPDATE_FORM:
        return updateEmailForm(state,action);
    default: return {...state};
    }
};

export default emailDistributionReducer;