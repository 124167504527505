import { fetchPayload } from "../../../utils/reducerOperations";
import {
    FETCH_TREND_INSIGHTS_DATA_START,
    FETCH_TREND_INSIGHTS_DATA_SUCCESS,
    FETCH_TREND_INSIGHTS_DATA_FAIL
} from "../../constants";
import { fetchTrendInsightsDataFailed, fetchTrendInsightsDataStart, fetchTrendInsightsDataSuccess } from "./Helpers";

import { IniialState } from "./InitialState";

const NewdashboardTrendInsightsReducer = (state = IniialState(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case FETCH_TREND_INSIGHTS_DATA_START:
            return fetchTrendInsightsDataStart(state, payload);
        case FETCH_TREND_INSIGHTS_DATA_SUCCESS:
            return fetchTrendInsightsDataSuccess(state, payload);
        case FETCH_TREND_INSIGHTS_DATA_FAIL:
            return fetchTrendInsightsDataFailed(state, payload);

        default:
            return { ...state };
    }
};

export default NewdashboardTrendInsightsReducer;