export function IniialState() {
    return {
        listViewersApi:{
            searchText: '',
            isProcessing:false,
            data:null,
            error:false,
            message:''
        },
        viewersList:[],
        isDataAlreadyFech:false
    };
}