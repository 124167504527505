import { 
    apiStartState,
    apiFailureState,
    apiSuccessState
} from '../../../../utils/apiStateUtils';

export function jobApprovalRequestStart(state) {
    return {
        ...state,
        jobApprovalApi: apiStartState(null,true)
    };
};

export function jobApprovalRequestSuccess(state, action) {
    return {
        ...state,
        jobApprovalApi: apiSuccessState(state.jobApprovalApi,action)
    };
}

export function jobApprovalRequestFail(state, action) {
    return {
        ...state,
        jobApprovalApi: apiFailureState(state.jobApprovalApi,action)
    };
};