import { FEEDBACK_360_TYPE } from "../../../../../config/questionTypeConstants"
import { deepCopy } from "../../../../utils/deepCopy"
import { generateUpdatedEditSurveyBlock, getDescriptionBlock } from "../../../../utils/newAttendSurveyUtils"

export const handleCopyEditSurveyToAnsweringPage = (state, payload) => {
    let projectData = payload.wizardReducer.surveyWizardProjectDetailsApi.data
    let questionSetCopy = deepCopy(payload.wizardReducer.goals)
    questionSetCopy.push({isFinalPage : true, endMessage : payload.wizardReducer.localStateData.endMessage })
    let updatedGoals = !FEEDBACK_360_TYPE.includes(payload.projectType) ? generateUpdatedEditSurveyBlock({ questionSet: questionSetCopy }, false, false, undefined) : questionSetCopy
    updatedGoals.splice(0, 0, getDescriptionBlock(payload.wizardReducer.localStateData.aboutSurvey))
    let logoData = payload.wizardReducer.logoData && payload.wizardReducer.logoData.logoUrl !== null ? { ...state.surveyQuestionSetApi, data: { logoUrl: payload.wizardReducer.logoData.logoUrl, projectType: projectData.childProjectType ? projectData.childProjectType : projectData.projectType } } : { ...state.surveyQuestionSetApi, data: { projectType: projectData.childProjectType ? projectData.childProjectType : projectData.projectType } }
    return {
        ...state,
        pageBreakAddedQuestionSet: updatedGoals,
        questionSet: !FEEDBACK_360_TYPE.includes(payload.projectType) ? payload.wizardReducer.goals : updatedGoals,
        currentIndex: 0,
        progressIndex: 0,
        surveyQuestionSetApi: logoData
    }
}
