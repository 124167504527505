import {
    makeKey,
    update,
    getSuccessResponse
} from '../../../../utils/reducerOperations';
export function deleteSurveySuccess(state, payload) {
    return {
        deleteSurveyApi: update(state.deleteSurveyApi, {
            ...getSuccessResponse(payload),
            projectId: payload.projectId
        }),
        fetchSurveyApi: update(
            state.fetchSurveyApi,
            makeKey(
                'data',
                update(state.fetchSurveyApi.data, {
                    Items: filterDeleted(
                        state.fetchSurveyApi.data.Items,
                        payload.projectId)
                })
            )
        )
    };
}
function filterDeleted(Items, projectId) {
    return Items.filter(survey => survey.projectId !== projectId);
}

