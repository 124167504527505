import {
    ADD_REMINDER_START,
    ADD_REMINDER_SUCCESS,
    ADD_REMINDER_FAILED
} from '../../../constants';
import { ADD_REMINDER_URL } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { withType } from '../../../../utils/actionOperations';
import constants from './constants'
import { toast } from '../../../../utils/messageUtils';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';


export const addReminder = (payload, callback) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(ADD_REMINDER_START, {}));
        apiBaseInstance.instance
            .post(ADD_REMINDER_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                dispatch(withType(ADD_REMINDER_SUCCESS, {
                    data: response.data,
                    message: response.message
                }));
                toast.success(constants.ADD_REMINDER_SUCCESS_MESSAGE);
                callback && callback(true,payload);
            })
            .catch(err => {
                toast.success(constants.ADD_REMINDER_FAILURE_MESSAGE);
                dispatch(withType(ADD_REMINDER_FAILED, {
                    error: err
                }));
            });
    };
};

