import { Tooltip } from 'antd';
import React from 'react';
import './index.scss';

export class OutlierBarchart extends React.Component {
    render() {
        return (
            <>
                <div className='outliers-progress progress-bar'>
                    <Tooltip
                        placement='bottom'
                        title={"Favorable"}
                    >
                        <div
                            className='favourable'
                            style={{
                                width: `${Math.round(this.props.data*100)}%`,
                                minWidth: Math.round(this.props.data*100) === 0 ? '0%' : '30px',
                                padding: Math.round(this.props.data*100) === 0 && '0',
                            }}
                        >
                            <p>{`${Math.round(this.props.data*100)} `}</p>
                        </div>
                    </Tooltip>
                    <div
                        className='neutral'
                        style={{
                            width: `0%`,
                            minWidth: '0%',
                            padding: '0',
                        }}
                    >
                        <p>{``}</p>
                    </div>
                    <div
                        className='unfavourable'
                        style={{
                            width: `0%`,
                            minWidth: '0%',
                            padding: '0',
                        }}
                    >
                        <p>{``}</p>
                    </div>
                </div>
            </>
        );
    }
}