export function switchCommentSuccess(state, action) {
  return {
      ...state,
      switchCommentApi:{error:false,loading:false},
      commentsApi:updateCommentsApi(state.commentsApi,action),
      switchCommentLoadingItems:removeIndex(
        state.switchCommentLoadingItems,action.index
      )
  };
};

export function switchCommentStart(state, action) {
  const {switchCommentLoadingItems} = state;
  switchCommentLoadingItems.push(action.index);
  return {
      ...state,
      switchCommentApi:{loading:true,error:false},
      switchCommentLoadingItems:[...switchCommentLoadingItems]
  };
};

export function switchCommentFail(state, action) {
  return {
      ...state,
      switchCommentApi:{loading:false,error:true},
      switchCommentLoadingItems:removeIndex(
        state.switchCommentLoadingItems,action.index
      )
  };
};


function removeIndex(items,itemIndex){
  const index=items.findIndex(item=>item===itemIndex);
  if(index>-1)items.splice(index,1);
  return [...items];
};

function updateCommentsApi(api,{index,data}){
  if(!(api.data&&api.data.randomComments))return api;
  if(!(index>-1))return api;
  if(!data.randomCommentFromQuestion)return api;
  api.data.randomComments[index]={...data.randomCommentFromQuestion};
  return {...api};
}