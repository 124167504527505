import {
  getApiStartState,
  getSuccessResponse,
  handleApiResponseError,
  handleNetworkError,
} from "../../../../utils/reducerOperations";
import { notification } from "antd";
import { RENAME_ORG_SUCCESS } from "./constants";

export function renameOrgStarted(state) {
  return {
    ...state,
    renameOrgApi: getApiStartState(),
  };
}

export function renameOrgSuccess(state, action) {
  const { response, requestPayload } = action;
  showSuccessNotification(requestPayload);
  return {
    ...state,
    renameOrgApi: getSuccessResponse(response),
  };
}

export function renameOrgFail(state, { response }) {
  const res =
    response.response && response.response.data
      ? response.response.data
      : response;
  notification.error({ message: res.message });
  return {
    ...state,
    renameOrgApi:
      response.response && response.response.data
        ? handleApiResponseError(res)
        : handleNetworkError(res),
  };
}

function showSuccessNotification(request) {
  notification.success({ message: RENAME_ORG_SUCCESS });
}
