export const careerDevelopmentTemplate = [
    {
        questionDimension: 'Career Development',
        type: 'Matrix',
        questionLabel: 'Career Development',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: 'I have good opportunities for advancement at my organization.', value: '', isSelected: true
        },
        {
            text: 'I can grow and develop my skills here.', value: '', isSelected: true

        },
        {
            text: 'This is a great company for me to grow as a professional.', value: '', isSelected: true

        }]
    }
]

