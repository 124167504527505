import { JOB_REQUEST_URL } from '../../../../config/constants';
import constants from './constants';
import { notification } from 'antd';
import { fetchBatchIds } from '.';
import { approvePayLoadItems } from './payloadGenerator';
import {
    APPROVE_JOB_REQUEST_START,
    APPROVE_JOB_REQUEST_SUCCESS,
    APPROVE_JOB_REQUEST_FAIL
} from '../../constants';
import { ApiBase } from '../../../utils/apiBase';

export function approveJobRequest(doc) {
    const payload = approvePayLoadItems(doc, constants);
    const apiBase = new ApiBase().instance;
    return dispatch => {
        dispatch({ type: APPROVE_JOB_REQUEST_START });
        apiBase.post(JOB_REQUEST_URL, payload)
            .then(approveJobRequestSuccess.bind(null, dispatch))
            .catch(approveJobRequestFail.bind(null, dispatch));
    };
}

function approveJobRequestSuccess(dispatch, res) {
    notification.success({ message: 'Approved' });
    dispatch({
        type: APPROVE_JOB_REQUEST_SUCCESS,
        data: res.data
    });
    dispatch(fetchBatchIds());
}

function approveJobRequestFail(dispatch, err) {
    notification.error({ message: 'Failed' });
    dispatch({
        type: APPROVE_JOB_REQUEST_FAIL,
        error: err && err.message
    });
}
