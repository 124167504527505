import {  } from '../../../../config/constants';
import constants from './constants';
import { approvePayLoadItems } from './payloadGenerator';
import { notification } from 'antd';
import { fetchBatchIds } from '.';
import Axios from 'axios';
import { BULK_BATCH_ID_OPERATION } from '../../../../config/constants';
import { 
    BATCH_APPROVE_JOBS_SUCCESS,
    BATCH_APPROVE_JOBS_FAIL
} from '../../constants';

export function batchApproveJobsRequest(doc){
    let payload = getPayload(doc);
    return dispatch=>{
        Axios.post(BULK_BATCH_ID_OPERATION,payload)
            .then(res => requestSuccess(dispatch,res))
            .catch(err => requestFail(dispatch,err));
    };
}

function requestSuccess(dispatch,res){
    notification.success({message:constants.approved});
    dispatch({
        type:BATCH_APPROVE_JOBS_SUCCESS,
        data:res.data
    });
    dispatch(fetchBatchIds());
}

function requestFail(dispatch,err){
    notification.error({message:constants.failed});
    dispatch({
        type:BATCH_APPROVE_JOBS_FAIL,
        error:err&&err.message
    });
}

function getPayload(doc){
    let payLoadForAPI=[]
    doc.forEach(row=>{
        payLoadForAPI.push({
            operation: 'approve',
            batchIdDocument:approvePayLoadItems(row,constants)
        })
    })
    return payLoadForAPI;
}