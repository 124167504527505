import * as helper from './helper';
import * as actionTypes from '../../constants';
import initialState from './intialState';

const insightsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_NLP_INSIGHTS_START:
            return helper.fetchInsightsTableCallStart(state, action);
        case actionTypes.FETCH_NLP_INSIGHTS_SUCCESS:
            return helper.fetchInsightsTableCallSuccess(state, action);
        case actionTypes.FETCH_NLP_INSIGHTS_FAIL:
            return helper.fetchInsightsTableCallFail(state, action);
        case actionTypes.FETCH_NUMERIC_INSIGHTS_START:
            return helper.fetchNumericInsightsCallStart(state, action);
        case actionTypes.FETCH_NUMERIC_INSIGHTS_SUCCESS:
            return helper.fetchNumericInsightsCallSuccess(state, action);
        case actionTypes.FETCH_NUMERIC_INSIGHTS_FAIL:
            return helper.fetchNumericInsightsCallFail(state, action);
        default:
            return state;
    }
};

export default insightsReducer;