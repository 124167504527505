import { fetchPayload } from "../../../utils/reducerOperations";
import {
    FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_STARTED,
    FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_SUCCESS,
    FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_FAILED,
} from "../../constants";
import {
    fetchFollowUpQuestionsReportStart,
    fetchFollowUpQuestionsReportSuccess,
    fetchFollowUpQuestionsReportFailed,
} from "./Helper";
import { InitialState } from "./InitialState";

const FetchFollowUpReportTableReducer = (state = InitialState(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_STARTED:
            return fetchFollowUpQuestionsReportStart(state, payload);
        case FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_SUCCESS:
            return fetchFollowUpQuestionsReportSuccess(state, payload);
        case FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_FAILED:
            return fetchFollowUpQuestionsReportFailed(state, payload);
        default:
            return { ...state };
    }
};

export default FetchFollowUpReportTableReducer;
