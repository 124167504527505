
import {
    update,
    makeKey,
} from '../../../../utils/reducerOperations';

export function fetchUsersStarted(state, payload) {
    return makeKey(
        'fetchUsersApi',
        update(state.fetchUsersApi, {isProcessing: true})
    );
}
