import { getSelectedUserData } from "../../helper";
import { getDataFromUrlToken } from "../../../../../../utils/urlDataStore";
import { URL_DATA } from "../../../../../../../config/urlDataConstants";
import { getURLFilterAndByScore } from "../../../../../../utils/byScoreFilterUtils";
import { DASHBOARD_TABS } from '../../../../../../../config/tabConstants';

export const getApiCallPayload = (surveyDetails, props) => {
  const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
  let filterData = getURLFilterAndByScore(urlFilter)
  const selectedUserData = getSelectedUserData();
  let payload = {
    currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.COMMENT_QUESTIONS,
    projectId: surveyDetails.projectId,
    selectedQuestionId: props.question,
    topics: getSelectedTopic(props.question, props),
    ...filterData,
    projectOrigin: surveyDetails.projectOrigin,
    directReporteesOnly: selectedUserData.directReporteesOnly,
    selectedUser: selectedUserData.selectedUser,
    hrisFileId: surveyDetails.hrisFileId
  };
  return payload
}

export const getSelectedTopic = (question, props) => {
  if (question && props[question] && props[question].selectedTopic) {
    return props[question].selectedTopic === "All Themes" ? [] : [props[question].selectedTopic]
  }
  return []
}

export const getApiDatas = (props) => {
  const wordCloudData =
    props[props.question].exploreWordCloudDataApi.data;
  const commentStatistics =
    props[props.question].explorecommentStatisticApi.data &&
    props[props.question].explorecommentStatisticApi.data;
  const questionComments =
    props[props.question].exploreQuestionCommentsApi.data;
  const totalNumberOfDocuments =
    props[props.question].exploreQuestionCommentsApi.totalComments;
  const relationData =
    props[props.question].exploreRelationDataApi.data;
  const surveyDetails =
    props.surveyDetailsApi && props.surveyDetailsApi.surveyDetails;
  return {
    wordCloudData: wordCloudData,
    commentStatistics: commentStatistics,
    questionComments: questionComments,
    totalNumberOfDocuments: totalNumberOfDocuments,
    relationData: relationData,
    surveyDetails: surveyDetails
  }
}

export const getLoader = (props, showLoader) => {
  return (
    props[props.question].explorecommentStatisticApi.isProcessing ||
    props[props.question].allThemeDataApi.isProcessing ||
    props[props.question].exploreWordCloudDataApi.isProcessing ||
    props[props.question].exploreRelationDataApi.isProcessing ||
    props[props.question].exploreQuestionCommentsApi.isProcessing ||
    showLoader
  );
};

export const getError = (props) => {
  return (
    props[props.question].explorecommentStatisticApi.error ||
    props[props.question].allThemeDataApi.error ||
    props[props.question].exploreWordCloudDataApi.error ||
    props[props.question].exploreRelationDataApi.error ||
    props[props.question].exploreQuestionCommentsApi.error
  );
};

