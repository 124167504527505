import {
  INPUT_ADD_USER,
  HRIS_HRIS_FILE_CHANGE,
  USERS_HRIS_PAGINATION_CHANGE,
} from "../../constants";
export const addUserInputChange = (event) => {
  return (dispatch) => {
    dispatch({
      type: INPUT_ADD_USER,
      payload: event,
    });
  };
};

export const hrisHrisfileChange = (value) => {
  return {
    type: HRIS_HRIS_FILE_CHANGE,
    payload: value,
  };
};

export const onUsersHrisPaginationChange = (page) => {
  return {
    type: USERS_HRIS_PAGINATION_CHANGE,
    payload: page,
  };
};
