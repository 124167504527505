import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { MainFrame as Component } from "./MainFrame";
import { getUserProfile } from "../../redux/actions";
import { SelectState } from "./Selector";

const mapDispatchToProps = (dispatch) => ({
  getUserProfile: (param) => dispatch(getUserProfile(param)),
});
const mapStateToProps = (state) => ({ ...SelectState(state) });

export const MainFrame = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Component));
