import {getFacetFields} from './Utils/getFacetFields';
import {update,
    getSuccessResponse} from '../../../../utils/reducerOperations';
import {setSelectedFacets} from './Utils/setSelectedFacets';

export function filterAggregationSuccess(state, payload) {
    let facetObject = getFacetFields(
        payload.data
    );
    return {
        facetObject,
        selectedFacets: setSelectedFacets(facetObject),
        filterAggregationApi: update(
            state.filterAggregationApi,
            getSuccessResponse(payload))
    };
}
