import { setInitialState } from "../../../../utils/reducerOperations";
import { fetchFormConfigurations } from "../../../inputFormConfigurations";
export function Default() {
    return {
        inviteViewerApi: setInitialState(),
        isFormValid: false,
        inputBoxConfiguration: fetchFormConfigurations([
            "name",
            "lastName",
            "email",
          ]),
        listViewersApi:setInitialState(),
        viewersList:[]
    };
}
