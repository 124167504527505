import {
    MODIFY_PROJECT_STATUS_START,
    MODIFY_PROJECT_STATUS_SUCCESS,
    MODIFY_PROJECT_STATUS_FAILED,
  } from "../../../constants";
  import { ApiBase } from "../../../../utils/apiBase";
  import { withType } from "../../../../utils/actionOperations";
  import { STOP_RESPONSE_COLLECTION_URL } from "../../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';
  
export const modifyProjectStatus = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
      dispatch(withType(MODIFY_PROJECT_STATUS_START, {}));
      apiBaseInstance.instance
        .post(STOP_RESPONSE_COLLECTION_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
        .then((response) => {
          dispatch(
            withType(MODIFY_PROJECT_STATUS_SUCCESS,response.data)
          );
          cb && cb(true);
        })
        .catch((err) => {
          cb && cb();
          dispatch(
            withType(MODIFY_PROJECT_STATUS_FAILED, {
              error: err,
            })
          );
        });
    };
  };