import { InitialState } from "./InitialState";

const ImageUploadReducer = (state = InitialState(), action) => {
  switch (action.type) {
    default:
      return { ...state };
  }
};

export default ImageUploadReducer;
