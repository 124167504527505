import {
    getSuccessResponse,
} from '../../../utils/reducerOperations';
import { addAdditionalElementsByCondition, changeSurveyLanguage } from '../../../utils/attendSurveyUtils';
import { deepCopy } from '../../../utils/deepCopy';

export function updateSurveyAnswer(state, action) {

    const data = state.fetchProjectApi.data;
    data.questions = action.payload;
    return {
        ...state,
        fetchProjectApi: {
            ...state.fetchProjectApi,
            data,
        }
    };
}

export function getProjectStart(state, action) {
    return {
        ...state,
        fetchProjectApi: {
            ...state.fetchProjectApi,
            data: null,
            isProcessing: true,
            error: false,
            message: null
        }
    };
}


export function getProjectSuccess(state, action) {
    let data = deepCopy(action.config)
    data.data.englishQuestionSet = deepCopy(data.data.questionSet);
    return {
        ...state,
        fetchProjectApi: getSuccessResponse(
            addAdditionalElementsByCondition(data, true))
    };
}

export function changeLanguage(state, action) {
    return {
        ...state,
        selectedLanguage: action.payload.language,
        fetchProjectApi: changeSurveyLanguage(
            deepCopy(state.fetchProjectApi),
            action.payload.language, true,
            action.payload.translatedDescription,
            action.payload.descriptiveTexts)
    };

}

export function translatePreviewStart(state, action) {
    return {
        ...state,
        translateApi: {
            isProcessing: true,
            error: false
        }
    };

}

export function translatePreviewSuccesss(state, action) {
    return {
        ...state,
        translateApi: {
            isProcessing: false,
            error: false
        },
        translatedTexts: action.payload.data && action.payload.data.translatedData
    };

}

export function translatePreviewFailed(state, action) {
    console.log("Error", action)
    return {
        ...state,
        translateApi: {
            isProcessing: false,
            error: true
        }
    };

}

export function getProjectfail(state, action) {
    return {
        ...state,
        fetchProjectApi: {
            ...state.fetchProjectApi,
            error: true,
            message: action.message,
            isProcessing: false,
            data: null
        }
    };
}