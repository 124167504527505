import {
    update,
    makeKey,
} from '../../../../utils/reducerOperations';
import {toast} from '../../../../utils/messageUtils';

export function activeUserRoleChangeStarted(state, payload) {
    toast.info('Updating role');
    return makeKey(
        'changeRoleApi',
        update(state.changeRoleApi, {isProcessing: true})
    );
}
