import {
    SET_STORYBOARD_TOP_AND_BOTTOM_TOPICS,
    CLEAR_STORYBOARD_TOP_AND_BOTTOM_TOPICS
} from '../../../constants';
import { withType } from '../../../../utils/actionOperations';

export const setTopAndBottomTopics = (payload) =>{
    return (dispatch) => {
            dispatch(withType(SET_STORYBOARD_TOP_AND_BOTTOM_TOPICS, payload,
            ));
        }
    }

export const clearTopAndBottomTopics = ()=>{
    return (dispatch) => {
        dispatch(withType(CLEAR_STORYBOARD_TOP_AND_BOTTOM_TOPICS, {},
        ));
    }
}    
