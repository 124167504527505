import React from "react";
import { ButtonElement } from "../../FormElements/ButtonElement";
import constants from "./constants";
import { AddAdmin } from "./children/AddAdmin";
import CustomModal from "../../Components/CustomModal";
import { Pagination } from "../../Components/Pagination";
import { getDomainName } from "../../utils/getDomainName";
import { SETTINGS_PAGE_SECTION } from '../../../config/tabConstants';
import { USER_ROLES } from '../../../config/userConfigurations';

const cardHeaderRight = (props) => (
  <ButtonElement
    onClick={props.onClick}
    type="primary"
  >
    {constants.ADD_BUTTON}
  </ButtonElement>
);

export const addAdminModal = (props) => {
  return (
    <CustomModal
      onOk={props.onSubmitModal}
      title={constants.MODAL_HEADER}
      okButtonProps={{
        loading: props.addAdminApi.loading,
        disabled: !props.isFormValid,
      }}
      okText={constants.ADD}
      closable={true}
      onCancel={props.toggleVisibility}
      visible={props.stateValue.modalVisibility}
    >
      <AddAdmin
        isFormValid={props.isFormValid}
        onEnter={props.onSubmitModal}
        addAdminBoxConfiguration={props.addAdminBoxConfiguration}
        adminFormChange={props.adminFormChange}
      />
    </CustomModal>
  );
};

const getPaginationForAdmin = (props) => {
  const { data } = props.fetchApi;
  if (data && data.paginatedUserData && data.paginatedUserData.length) {
    return (
      <Pagination
        item={constants.ORG_ADMINS}
        onChange={props.onChangePagination}
        page={props.pagination}
        count={data.count}
      />
    );
  }
};

const generateRequestBody = (adminField) => {
  return {
    currentTab: SETTINGS_PAGE_SECTION.SETTINGS_SUB_TABS.ADMINISTRATOR,
    firstName: adminField[0].value,
    lastName: adminField[1].value !== undefined ? adminField[1].value : "",
    email: adminField[2].value,
    sourceDomainName: getDomainName(),
    inviteUserRole: USER_ROLES.ORG_ADMIN
  };
};

export default {
  cardHeaderRight: cardHeaderRight,
  addAdminModal: addAdminModal,
  getPaginationForAdmin: getPaginationForAdmin,
  generateRequestBody: generateRequestBody,
};
