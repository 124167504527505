import {
  UPDATE_CONTACT_FORM_FIELD,
  SUBMIT_CONTACT_FORM_START,
  UPDATE_CONTACT_FORM_CHECK_BOX,
  SUBMIT_CONTACT_FORM_SUCCESS,
  SUBMIT_CONTACT_FORM_FAIL,
  CLEAR_CONTACT_FORM,
} from "../../constants";
import { withType } from "../../../utils/actionOperations";
import { ApiBase } from "../../../utils/apiBase";
import { CONTACT_FORM_URL } from "../../../../config/constants";

export const onChangeContactFormField = (event) => {
  return (dispatch) => {
    dispatch(withType(UPDATE_CONTACT_FORM_FIELD, event));
  };
};

export const onChangeContactFormCheckBox = (payload) => {
  return {
    type: UPDATE_CONTACT_FORM_CHECK_BOX,
    payload,
  };
};

export const clearContactForm = () => {
  return {
    type: CLEAR_CONTACT_FORM,
  };
};

export const onSubmitContactForm = (payload, cb) => {
  // const data = {
  //     ToAddresses: constants.contact_toAddresses,
  //     mailContents: JSON.stringify(payload),
  //     mailSubject: constants.contact_mailSubject
  // };
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(SUBMIT_CONTACT_FORM_START, payload));
    apiBaseInstance.instance
      .post(CONTACT_FORM_URL, payload)
      .then((res) => {
        dispatch(withType(SUBMIT_CONTACT_FORM_SUCCESS, res.data));
      })
      .then((result) => dispatch(cb()))
      .catch((err) => {
        dispatch(withType(SUBMIT_CONTACT_FORM_FAIL, err));
      });
  };
};
