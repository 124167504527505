import React from "react";
import SVGIcons from "../../../../../../../../Components/SVGIcons";
export default class UsersSvgIcons extends React.Component {
  renderScoreIcons = (score) => {
    let totalScore = Math.ceil(score * 100);
    const overallScoreIconsLength =
      totalScore >= 96 ? 26 : Math.ceil(totalScore / 4);
    let icons = [];
    for (let i = 0; i <= 25; i++) {
      if (overallScoreIconsLength >= i) {
        icons.push(<SVGIcons key={i} type={"SVG-users"} />);
      } else if (overallScoreIconsLength < i) {
        icons.push(
          <span className={"icon"}>
            <SVGIcons key={i} type={"SVG-users"} />
          </span>
        );
      }
    }
    return icons;
  };
  render() {
    return (
      <div className="drivers-block">
        <h1>Overall {this.props.outcomeVariable&&this.props.outcomeVariable} score</h1>
        <ul className="explore-users-block blue">
          <li>
            <h2>{Math.round(this.props.totalScore * 100)}</h2>
          </li>
          <li>
            <div>{this.renderScoreIcons(this.props.totalScore)}</div>
            <div>{this.renderScoreIcons(this.props.totalScore)}</div>
          </li>
        </ul>
      </div>
    );
  }
}
