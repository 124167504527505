import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const feedbackIdeasComments = {
    "blockId": uuidv4(),
    "goalId": "comments",
    "isTemplate": true,
    "questions": [
        {
            "staticQuestionId": generateStaticQuestionId("Please share a couple feedforward ideas or advice for what can specifically be done by this leader to improve (i.e., offer some positive suggestions for the future)."),
            "question": "Please share a couple feedforward ideas or advice for what can specifically be done by this leader to improve (i.e., offer some positive suggestions for the future).",
            "HTMLQuestion": "<p>Please share a couple feedforward ideas or advice for what can specifically be done by this leader to improve (i.e., offer some positive suggestions for the future).</p>",
            "options": [],
            "isSelected": true,
            "type": "Text Entry",
            "qid": uuidv4(),
            "questionLabel": ""
        }
    ],
    "isSelected": true,
    "isTextEntryOnly": true,
    "type": "textEntry",
    "dimension": "Comments"
}

export const feedbackIdeasCommentsApplied = {
    "blockId": uuidv4(),
    "goalId": "comments",
    "isTemplate": true,
    "questions": [
        {
            "selfQuestion": "As you are reflecting on your own leadership, imagine you are giving advice to someone else in your situation. What’s the one piece of advice you’d have for them to improve  their effectiveness?",
            "HTMLSelfQuestion": "<p>As you are reflecting on your own leadership, imagine you are giving advice to someone else in your situation. What’s the one piece of advice you’d have for them to improve  their effectiveness?</p>",
            "staticQuestionId": generateStaticQuestionId("What would be your one piece of advice for this leader to improve their effectiveness?"),
            "question": "What would be your one piece of advice for this leader to improve their effectiveness?",
            "HTMLQuestion": "<p>What would be your one piece of advice for this leader to improve their effectiveness?</p>",
            "options": [],
            "isSelected": true,
            "type": "Text Entry",
            "qid": uuidv4(),
            "questionLabel": ""
        }
    ],
    "isSelected": true,
    "isTextEntryOnly": true,
    "type": "textEntry",
    "dimension": "Comments"
}