import { GET_HRIS_STATUS } from "../../../../../config/constants";
import { withType } from "../../../../utils/actionOperations";
import { ApiBase } from "../../../../utils/apiBase";
import { FILE_UPLOAD_STATUS_FAIL, FILE_UPLOAD_STATUS_START, FILE_UPLOAD_STATUS_SUCCESS } from "../../../constants";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fileUploadStatus =(params,cb) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
      dispatch(withType(FILE_UPLOAD_STATUS_START, params));
      apiBaseInstance.instance
        .post(GET_HRIS_STATUS, { ...params, ...getProjectIdAndOrgId(params) })
        .then((response) => {
          dispatch(
            withType(FILE_UPLOAD_STATUS_SUCCESS, response,{
            })

          );
          cb && cb(response);
        })
        .catch((err) => {
          dispatch(
            withType(FILE_UPLOAD_STATUS_FAIL, {
              error: err,
            })
          );
          cb && cb(false);
        });
    };
  };