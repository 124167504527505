import { fetchPayload } from "../../../utils/reducerOperations";
import {
  FETCH_BENCHMARK_DATA_START,
  FETCH_BENCHMARK_DATA_SUCCESS,
  FETCH_BENCHMARK_DATA_FAIL,
  EDIT_BENCHMARK_DATA,
  UPDATE_BENCHMARK_DATA_START,
  UPDATE_BENCHMARK_DATA_SUCCESS,
  UPDATE_BENCHMARK_DATA_FAIL
} from "../../constants";
import {
  FetchBenchMarkDataStart,
  FetchBenchMarkDataSuccess,
  FetchBenchMarkDataFailed,
  editBenchMark,
  UpdateBenchMarkDataStart,
  UpdateBenchMarkDataSuccess,
  UpdateBenchMarkDataFailed
} from "./Helper"
import { IniialState } from "./InitialState";

const NewdashboardBenchMarkReducer = (state = IniialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_BENCHMARK_DATA_START:
      return FetchBenchMarkDataStart(state, payload);
    case FETCH_BENCHMARK_DATA_SUCCESS:
      return FetchBenchMarkDataSuccess(state, payload);
    case FETCH_BENCHMARK_DATA_FAIL:
      return FetchBenchMarkDataFailed(state, payload);

    case UPDATE_BENCHMARK_DATA_START:
      return UpdateBenchMarkDataStart(state, payload);
    case UPDATE_BENCHMARK_DATA_SUCCESS:
      return UpdateBenchMarkDataSuccess(state, payload);
    case UPDATE_BENCHMARK_DATA_FAIL:
      return UpdateBenchMarkDataFailed(state, payload);

    case EDIT_BENCHMARK_DATA:
      return editBenchMark(state, payload);
    default:
      return { ...state };
  }
};

export default NewdashboardBenchMarkReducer;