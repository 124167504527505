export const engagementTemplate = [
    {
        questionDimension: 'Engagement',
        type: 'Matrix',
        questionLabel: 'Engagement',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: 'I can see the positive impact of my work on someone else.', value: '', isSelected: true
        },
        {
            text: 'My organization motivates me to go above and beyond what I would normally do.', value: '', isSelected: true

        },
        {
            text: 'I would recommend my organization as a great place to work.', value: '', isSelected: true

        },
        {
            text: 'I have fun at work.', value: '', isSelected: false

        },
        {
            text: 'My work is meaningful.', value: '', isSelected: false

        },

        {
            text: 'My work is an important part of who I am as a person.', value: '', isSelected: false

        },
        {
            text: 'I am proud to be a part of my organization.', value: '', isSelected: false

        }
        ]
    }
]

