/*eslint-disable*/

import { getOrgId } from "./localStorgeOperations";

//projects section
export const PROJECTS_PATH = "/home/" + getOrgId() + "/survey/projects";

export const CREATE_PROJECT = PROJECTS_PATH + "/create-project";

export const PROJECT_ROUTE = "/home/:org_id/survey/projects"
export const EDIT_PROJECT_ROUTE = PROJECT_ROUTE + "/edit-project";
export const CREATE_PROJECT_ROUTE = PROJECT_ROUTE + "/create-project";
export const DISTRIBUTION_SECTION_ROUTE = PROJECT_ROUTE + "/distribution";

export const EDIT_PROJECT_PATH = PROJECTS_PATH + "/edit-project";
export const EDIT_PROJECT = "/edit-project";
export const PUBLISHED_PROJECT_PATH = PROJECTS_PATH + "/published";
export const DRAFT_PROJECT_PATH = PROJECTS_PATH + "/draft";
export const PREVIEW_PROJECT_PATH = "/preview-project";
export const NEW_DASHBOARD = "/home/:orgId/survey/newdashboard";
export const SURVEY_PARTICIPATION = "/:orgId/surveyparticipationrate";

export const DISTRIBUTION_SECTION_PATH = PROJECTS_PATH + "/distribution";
export const DISTRIBUTIONS_PATH = DISTRIBUTION_SECTION_PATH + "/distributions";
export const PROJECT_REPORT_PATH = DISTRIBUTION_SECTION_PATH + "/reports";
export const DISTRIBUTION_PREVIEW_PATH = DISTRIBUTION_SECTION_PATH + "/edit-project";
export const PARTICIPATION_PATH = DISTRIBUTION_SECTION_PATH + "/participation";
export const ANONYMOUS_LINK_PATH = "/survey/cbs/form";
export const SCREENSHOT_PATH_FOR_PDF = "/pdfscreenshot"

