export const InitialState=()=>({
    generateReportMailApi:{
        isProcessing:false,
        data:null,
        error:false,
        message:''
    },
    downloadUserCommentsApi:{
        isProcessing:false,
        error:false
    },
    exportDashboardPptApi:{
        isProcessing:false,
        error:false,
    },
    exportPptStatusApi :{
      isProcessing: false,
      data:{},
      error:false
    }
});