import { withType } from '../../../../utils/actionOperations';
import { UploadFile } from '../../../../utils/uploadApi';
import {
    UPLOAD_QUESTION_TOPIC_FAILED,
    UPLOAD_QUESTION_TOPIC_START,
    UPLOAD_QUESTION_TOPIC_SUCCESS,
    MARK_AS_CUSTOM_TOPIC,
    SET_FILE_NAME
} from '../../../constants';
import { GET_SIGNED_URL } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';


export const markQuestionAsCustomTopic = payload => {
    return dispatch => {
        dispatch(withType(MARK_AS_CUSTOM_TOPIC, payload));
    };
};

export const setFileName = payload => {
    return dispatch => {
        dispatch(withType(SET_FILE_NAME, payload));
    };
};

export function uploadQuestionTopic(params, cb) {
    const apiBaseInstance = new ApiBase()
    const payload = {
        fileName: params.fileName,
        fileType: params.fileType,
        isTopic: true,
        currentTab: params.currentTab
    }
    return dispatch => {
        dispatch(withType(UPLOAD_QUESTION_TOPIC_START, { questionId: params.questionId }));
        apiBaseInstance.instance
            .post(GET_SIGNED_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                return uploadToStorage(dispatch, {
                    ...response,
                    ...{ params }
                });
            })
            .catch(err => {
                dispatch(withType(UPLOAD_QUESTION_TOPIC_FAILED, err));
            });
    };
}

function getHeader(fileType) {
    return {
        headers: {
            'Content-Type': fileType
        }
    };
}


const getKeyNameForAddTopicLibraryJob = (response) => {
    let fileName = response ? response : "";
    if (window.location.hostname === "app.culturebie.com" ||
        window.location.hostname === "www.app.culturebie.com") {
        return fileName && fileName.split("/culturebie-topiclibrarybucket/")[1];
    } else if (window.location.hostname === "qa.culturebie.com" ||
        window.location.hostname === "www.qa.culturebie.com") {
        return fileName && fileName.split("/culturebieqa-topiclibrarybucket/")[1];
    }
    else {
        return fileName && fileName.split("/culturebiedev-topiclibrarybucket/")[1];
    }
};


function uploadToStorage(dispatch, urlResponse) {
    const payload = {
        url: urlResponse.data.data.preSignedUrl,
        file: urlResponse.params.file,
        options: getHeader(urlResponse.params.fileType)
    }
    UploadFile(payload)
        .then(response => dispatch(withType(UPLOAD_QUESTION_TOPIC_SUCCESS, {
            ...payload,
            ...urlResponse.params,
            keyName: getKeyNameForAddTopicLibraryJob(urlResponse.data.data.fileurl)
        })))
        .catch(error => {
            return dispatch(withType(UPLOAD_QUESTION_TOPIC_FAILED, error));
        });
}



