import {
    FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_STARTED,
    FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_SUCCESS,
    FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_FAILED,
} from "../../../constants";

import { ApiBase } from "../../../../utils/apiBase";
import { FETCH_FOLLOW_UP_QUESTION_DATA } from "../../../../../config/constants";
import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchFollowUpQuestionsReportData = (payload) => {
    const apiBaseInstance = new ApiBase().instance;
    return (dispatch) => {
        dispatch(withType(FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_STARTED, {}));
        return apiBaseInstance
            .post(FETCH_FOLLOW_UP_QUESTION_DATA, {
                ...payload,
                ...getProjectIdAndOrgId(payload)
            })
            .then((res) => {
                dispatch(
                    withType(FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_SUCCESS, {
                        data: res.data,
                    })
                );
            })
            .catch((err) => {
                dispatch(withType(FETCH_FOLLOWUP_QUESTIONS_REPORT_DATA_FAILED, err));
            });
    };
};