import React, { Component } from "react";
import CustomModal from "../../../Components/CustomModal";
import FeedBackForm from "../FeedbackForm";
import { LoaderButton } from "../../../FormElements/LoaderButton";
import constants from "../constants";
import { checkEmailisValid } from "../../../utils/regularExpression";

class FeedBackModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      inputBoxes: constants.inputBoxes,
      subject: constants.subject,
      buttonDisabled: true,
      isFormValid: false,
    };
  }

  sendFeedbackFormChange = (data) => {// eslint-disable-next-line
    this.state.inputBoxes[data.index].value = data.value;// eslint-disable-next-line
    let isFormValid = this.checkIsFormValid();
    if (isFormValid) {
      this.setState({
        ...this.state,
        buttonDisabled: false,
        isFormValid: true,
      });
      this.removeMessageFromInputField();
    } else {
      this.setState({
        ...this.state,
        buttonDisabled: true,
        isFormValid: false,
      });
    }
  };

  removeMessageFromInputField = () => {// eslint-disable-next-line
    this.state.inputBoxes[0].message = "";// eslint-disable-next-line
    this.state.inputBoxes[1].message = "";// eslint-disable-next-line
    this.state.inputBoxes[2].message = "";// eslint-disable-next-line
  };

  checkIsFormValid = () => {
    let emailIsValid = checkEmailisValid(this.state.inputBoxes[1].value)
    let isFormValid =
      this.state.inputBoxes[0].value !== "" &&
      emailIsValid &&
      this.state.inputBoxes[2].value !== "";
    if (!emailIsValid) {
        if(this.state.inputBoxes[1].value !== ""){// eslint-disable-next-line
            this.state.inputBoxes[1].message = "Invalid email";
        }else{// eslint-disable-next-line
            this.state.inputBoxes[1].message = ""
        }
    }
    return isFormValid;
  };

  sendFeedback = (event) => {
    this.setState((prevState) => ({ ...prevState, loading: true }));
    let payload = {
      name: this.state.inputBoxes[0].value,
      email: this.state.inputBoxes[1].value,
      message: this.state.inputBoxes[2].value,
      subject: this.state.subject,
    };
    this.props.NewdashboarStoryboardSendFeedback(payload, this.changeLoader);
  };

  changeLoader = () => {
    this.setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
    this.handleCancel();
  };

  handleCancel = () => {// eslint-disable-next-line
    this.state.inputBoxes[0].value = "";// eslint-disable-next-line
    this.state.inputBoxes[1].value = "";// eslint-disable-next-line
    this.state.inputBoxes[2].value = "";// eslint-disable-next-line
    this.setState({
      ...this.state,
      buttonDisabled: true,
      isFormValid: false,
    });
    this.removeMessageFromInputField();
    this.props.handleCancel();
  };

  render() {
    return (
      <CustomModal
        visible={this.props.isModalVisible}
        title="Send queries"
        onCancel={this.handleCancel}
        footer={[
          <LoaderButton
            data-test="Send"
            loader={this.state.loading}
            disabled={this.state.buttonDisabled}
            onClick={this.sendFeedback}
            classList={["loader-button"]}
            loaderClass={["xs white"]}
            text="Send"
          />,
        ]}
      >
        <FeedBackForm
          inputBoxes={this.state.inputBoxes}
          sendFeedbackFormChange={this.sendFeedbackFormChange}
        />
      </CustomModal>
    );
  }
}

export default FeedBackModal;
