import React from "react";
import { Pagination as AntdPagination } from "antd";
import constants from "./constants";
import "./index.scss";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Pagination = ({ onChange, count, page, item, pageSize }) => {
  const size = pageSize || 12;
  return (
    <div className="user-pagination">
      <div className="user-pagination__message">
        <span>
          {constants.SHOWING}
        </span>
        <span>
          {getStartingValue(page, size)}-{getEndingValue(page, count, size)}
        </span>
        <span>{constants.OF}</span>
        <span>{count}</span>
        <span>{item ? item : constants.EMPLOYEES}</span>
      </div>
      <AntdPagination
        itemRender={itemRender}
        current={page}
        pageSize={size}
        onChange={onChange}
        total={count}
        showSizeChanger={false}
        showTitle={false}
      />
    </div>
  );
};

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <a href={false}> <FontAwesomeIcon icon={faChevronLeft} aria-hidden="true" className="icons" /> </a>;
  }
  if (type === 'next') {
    return <a href={false}> <FontAwesomeIcon icon={faChevronRight} aria-hidden="true" className="icons" /> </a>;
  }
  return originalElement;
}

const getStartingValue = (page, size) => (page - 1) * size + 1;
const getEndingValue = (page, count, size) => {
  return (page - 1) * size + size < count ? (page - 1) * size + size : count;
};
