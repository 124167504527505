import { deepCopy } from "../../../../../utils/deepCopy"

export const editDescriptiveQuestionLable = (state, payload) => {
    let goals = editDescriptiveQuestionLableToGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

export const editDescriptiveText = (state, payload) => {
    let goals = editDescriptiveTextToGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const editDescriptiveQuestionLableToGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    if (payload.value && payload.value !== "") {
        goalsCopy.forEach(element => {
            if (element.goalId === payload.id) {
                element.dimension = payload.value
                element.questions[0].questionLabel = payload.value
            }
        });
    }
    return goalsCopy
}

const editDescriptiveTextToGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(element => {
        if (element.goalId === payload.id) {
            element.questions[0].question = payload.value
        }
    });
    return goalsCopy
}

