
export function surveywizardParticipationUpdateSelectedColumn(state, action) {
    if(action)
    {
    let { surveywizardParticipationSelectedColumns } = state;
    const index = (surveywizardParticipationSelectedColumns || []).findIndex(
      (i) => i === action
    );
    if (index > -1)
    surveywizardParticipationSelectedColumns =
    surveywizardParticipationSelectedColumns.filter((i) => i !== action);
    else surveywizardParticipationSelectedColumns.push(action);
    return {
      ...state,
      surveywizardParticipationSelectedColumns: [...surveywizardParticipationSelectedColumns],
    };
  }
  else
  {
    return {
        ...state,
        surveywizardParticipationSelectedColumns: [],
      };
  }
}

export function surveywizardDeselectParticipationColumns(state){
  let { surveywizardParticipationDataApi } = state;
  const {result} = surveywizardParticipationDataApi.data.aggregatedDemographicsResponse;
  let selectedColumns = Object.keys(result);
  return {
      ...state,
      surveywizardParticipationSelectedColumns: [...selectedColumns],
    };
}