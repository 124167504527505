import { deepCopy } from "../../../../../../utils/deepCopy"

export const changeDescriptiveText = (state, payload) => {
    let updatedGoals = editDescriptiveTextToGoals(state, payload)
    return {
        ...state,
        goals: updatedGoals

    }
}

const editDescriptiveTextToGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            element.questions[0].question = payload.value
        }
    });
    return goalsCopy
}
