import { fetchPayload } from "../../../utils/reducerOperations";
import {
    FETCH_MULTISELECT_QUESTION_START,
    FETCH_MULTISELECT_QUESTION_SUCCESS,
    FETCH_MULTISELECT_QUESTION_FAIL,
    FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_START,
    FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_SUCCESS,
    FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_FAIL
} from "../../constants";
import {
    fetchMultiQuestionsStart,
    fetchMultiQuestionsSuccess,
    fetchMultiQuestionsFailed,
    fetchMultiQuestionsDiagramStart,
    fetchMultiQuestionsDiagramSuccess,
    fetchMultiQuestionsDiagramFail
} from "./Helper";
import { InitialState } from "./InitialState";

const MultiSelectQuestionsReducer = (state = InitialState(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case FETCH_MULTISELECT_QUESTION_START:
            return fetchMultiQuestionsStart(state, payload);
        case FETCH_MULTISELECT_QUESTION_SUCCESS:
            return fetchMultiQuestionsSuccess(state, payload);
        case FETCH_MULTISELECT_QUESTION_FAIL:
            return fetchMultiQuestionsFailed(state, payload);
        case FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_START:
            return fetchMultiQuestionsDiagramStart(state, payload);
        case FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_SUCCESS:
            return fetchMultiQuestionsDiagramSuccess(state, payload);
        case FETCH_MULTISELECT_QUESTION_DIAGRAM_DATA_FAIL:
            return fetchMultiQuestionsDiagramFail(state, payload);
        default:
            return { ...state };
    }
};

export default MultiSelectQuestionsReducer;
