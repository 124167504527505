import { fetchPayload } from "../../../utils/reducerOperations";
import {
  STORYBOARD_FETCH_DRIVERS_DATA_START,
  STORYBOARD_FETCH_DRIVERS_DATA_SUCCESS,
  STORYBOARD_FETCH_DRIVERS_DATA_FAIL,
  FETCH_STORYBOARD_LOW_OUTCOME_QUESTION_SCORE_SUCCESS,
} from "../../constants";
import {
  storyboardFetchDriversStarted,
  storyboardFetchDriversFailed,
  storyboardFetchDriversSuccess,
  storyboardLowOutcomeQuestionScoreSuccess
} from "./Helpers/index.js";
import { Default } from "./InitialState";
const StoryOutcomeTopicReducer = (state = Default(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case STORYBOARD_FETCH_DRIVERS_DATA_START:
      return storyboardFetchDriversStarted(state, payload);
    case STORYBOARD_FETCH_DRIVERS_DATA_SUCCESS:
      return storyboardFetchDriversSuccess(state, payload);
    case STORYBOARD_FETCH_DRIVERS_DATA_FAIL:
      return storyboardFetchDriversFailed(state, payload);
    // LOWOUTCOME

    case FETCH_STORYBOARD_LOW_OUTCOME_QUESTION_SCORE_SUCCESS:
      return storyboardLowOutcomeQuestionScoreSuccess(state, payload);

    default:
      return { ...state };
  }
};

export default StoryOutcomeTopicReducer;
