import { apiSuccessSortState, apiFailureState } from "../../../../utils/apiStateUtils";

export function fetchTopicsRepresentationSuccess(state, action) {
    return {
        ...state,
        topicsRepresentationApi:
        apiSuccessSortState(state.topicsRepresentationApi,action)
    };
}

export function fetchTopicsRepresentationStart(state, action) {
    return {
        ...state,
        topicsRepresentationApi:{
            ...state.topicsRepresentationApi,
            loading:true
        }
    };
}

export function fetchTopicsRepresentationFail(state, action) {
    return {
        ...state,
        topicsRepresentationApi: 
        apiFailureState(state.topicsRepresentationApi,action)
    };
};