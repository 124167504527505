import {
    GET_COMMENT_THEMES_STARTED,
    GET_COMMENT_THEMES_SUCCESS,
    GET_COMMENT_THEMES_FAILED,
    UPDATE_GET_COMMENTS_SELECTED_TOPIC
  } from "../../../constants";
  import {
    GET_COMMENTS_THEME_URL,
  } from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
  import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';
  
  export const getCommentThemes = (payload) => {
    let isDataAlreadyFetched;
      if (payload.isDataAlreadyFetched) {
        isDataAlreadyFetched = false;
      } else {
        isDataAlreadyFetched = true;
      }
    const apiBaseInstance = new ApiBase().instance;
    return (dispatch) => {
        dispatch(
          withType(GET_COMMENT_THEMES_STARTED, {
            selectedQuestionId: payload.selectedQuestionId,
            isDataAlreadyFetched:isDataAlreadyFetched
          })
        );
      return apiBaseInstance
        .post(GET_COMMENTS_THEME_URL, {
          ...payload, ...getProjectIdAndOrgId(payload)
        })
        .then((res) => {
          dispatch(
            withType(GET_COMMENT_THEMES_SUCCESS, {
             
              topics: res.data.data.themes,
              allThemeCount: res.data.data.totalCommentsCount,
              selectedQuestionId: payload.selectedQuestionId,
              selectedTopic:payload.topic
            })
          );
          
        })
        .catch((err) => {
          dispatch(withType(GET_COMMENT_THEMES_FAILED, err));
        });
    };
  };
  
  export const updateGetCommentsSelectedTopic = (payload) => {
    return (dispatch) => {
      dispatch(withType(UPDATE_GET_COMMENTS_SELECTED_TOPIC, payload));
    };
  };