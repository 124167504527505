import { 
    apiStartState,
    apiFailureState,
    apiSuccessState
} from '../../../../utils/apiStateUtils';

export function uploadFileStart(state,action) {
    return {
        ...state,
        commentsFileApi: apiStartState(state.commentsFileApi)
    };
};
export function uploadFileSuccess(state, action) {
    const api=apiSuccessState(state.commentsFileApi, action);
    return {
        ...state,
        commentsFileApi: {
            ...api,
            name:action.fileName,
            file:action.file
        }
    };
}
export function uploadFileFail(state, action) {
    return {
        ...state,
        commentsFileApi: apiFailureState(state.commentsFileApi,action)
    };
};