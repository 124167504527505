import React from 'react';
import CustomModal from '../../../../../../../../Components/CustomModal';
import style from './style.module.scss'

class ConfirmationModal extends React.Component {

    renderModalBody = () => {
        return (
            <div className={style.submitModal}>
                <img alt="thankyou" src="/img/thank-you.gif" />
                <h2 children={this.props.surveyCompletedMessage} />
            </div>
        );
    }

    render() {
        return (
            <CustomModal
                width={"70%"}
                className="thank-you-modal"
                closable={false}
                footer={null}
                onCancel={() => { }}
                visible={this.props.visiblity}
                children={this.renderModalBody()} />
        );
    }
}
export default ConfirmationModal;