import React from "react";
import { LoaderButton } from "../../FormElements/LoaderButton";
import * as moment from "moment";
const users = {
  header: "Users",
};

const organization = {
  header: "Organization Users",
};

export default {
  users,
  organization,
  userRoute: "/admin/home/manageusers",
  dots: "...",
  Users: " Users",
  ORG_ID: "organizationId",
  LOGS: "Logs",
  logTableColumns: () => [
    {
      title: "HRIS File Name",
      dataIndex: "hrisFileName",
      key: "hrisFileName",
    },
    {
      title: "Owner Email",
      dataIndex: "ownerEmail",
      key: "ownerEmail",
    },
    {
      title: "Log Source",
      dataIndex: "logType",
      key: "logType",
      render: (data) => getLogType(data),
    },
    ...getLogTableColumns(),
  ],

  roleTableColumns: ({ onDelete }) => [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
    },
    ...getRoleTableColumns(),
    {
      title: "",
      key: "delete",
      dataIndex: "delete",
      render: (_, rec) => (
        <LoaderButton
          onClick={() => onDelete(rec)}
          classList={["btn", "btn-danger", "loader-btn"]}
          loader={false}
          loaderClass={["sm", "white"]}
          text={"Delete"}
        />
      ),
    },
  ],
};

const getRoleTableColumns = () => {
  return [
    {
      title: "Permissions",
      dataIndex: "tags",
      key: "tags",
      width: 600,
      render: (data) => data.join(" , "),
    },
    {
      title: "",
      key: "edit",
      dataIndex: "edit",
      width: 90,
      render: () => (
        <LoaderButton
          onClick={() => { }}
          classList={["btn", "btn-info", "loader-btn"]}
          loader={false}
          loaderClass={["sm", "white"]}
          text={"Edit"}
        />
      ),
    },
  ];
};

const getLogTableColumns = () => {
  return [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data) => renderStatus(data),
    },
    {
      title: "Created At",
      dataIndex: "uploadDate",
      key: "uploadDate",
      render: (data) => moment(data).fromNow(),
    },
  ];
};

const renderStatus = (data) => {
  if (data.bulkJobStatus === "Completed") {
    return <span className="completedtag">{data.bulkJobStatus}</span>;
  } else if (data.bulkJobStatus === "Failed") {
    return (
      <>
        <span className="failedtag">{data.bulkJobStatus}</span>
        <p className="failed-reason">{data.failureReason}</p>
      </>
    );
  } else if (data.bulkJobStatus) {
    return <span className="queuedTag">{data.bulkJobStatus}</span>;
  } else if (data.status === "Completed") {
    return <span className="completedtag">{data.status}</span>;
  } else if (data.status === "Failed") {
    return (
      <>
        <span className="failedtag">{data.status}</span>
        <p className="failed-reason">{data.failureReason}</p>
      </>
    );
  } else if (data.status) {
    return <span className="queuedTag">{data.status}</span>;
  }
};
export const Message = {
  error: {
    key: "role_error",
    type: "danger",
    text: "Roles Fetching Error",
  },
  empty: {
    key: "role_empty",
    type: "warning",
    text: "No Roles Avalible",
  },
};

const getLogType = (data) => {
  return data === "userCreation"
    ? "Invite Manager Process"
    : "HRIS file process";
};
