import { deepCopy } from "../../../../../../utils/deepCopy"

export const likertRequiredChange = (state, payload) => {
    let updatedGoals = getRequiredChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}

export const likertShowTopicChange = (state, payload)=>{
    let updatedGoals = getRequiredChangedTopic(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}

const getRequiredChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            element.isRequired = payload.value
        }
    });
    return goalsCopy
}

const getRequiredChangedTopic = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            element.isShowTopic = payload.value
        }
    });
    return goalsCopy
}

export const likertOutComeVariableChange = (state, payload) => {
    let updatedGoals = getOutcomeVariableChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getOutcomeVariableChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    if (payload.value) {
        goalsCopy.forEach(element => {
            if (element.blockId === payload.blockId) {
                element.isOutcomeVariable = true
            } else {
                element.isOutcomeVariable = false
            }
        });
    } else {
        goalsCopy.forEach(element => {
            if (element.blockId === payload.blockId) {
                element.isOutcomeVariable = false
            }
        });
    }

    return goalsCopy
}


export const likertChnageOptionsCount = (state, payload) => {
    let updatedGoals = getOptionsCountChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getOptionsCountChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    if (payload.value) {
        goalsCopy.forEach(element => {
            if (element.blockId === payload.blockId) {
                element.questions[0].optionsCount = payload.value
                if (element.isDefault || element.isCustom) {
                    element.questions[0].matrixQuestions.forEach((matrixQuestions, index) => {
                        if (matrixQuestions.followUpQuestions && matrixQuestions.followUpQuestions.length > 0) {
                            matrixQuestions.followUpQuestions.forEach((followUp) => {
                                if (followUp.condition && followUp.condition.length > 0) {
                                    followUp.condition = ["1", "2"]
                                }

                            })
                        }
                    })
                }

            }
        });
    }

    return goalsCopy
}

export const likertNaOptionChange = (state, payload) => {
    let updatedGoals = getNaOptionChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}

const getNaOptionChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            element.allowNAOption = payload.value
        }
    });
    return goalsCopy
}