import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";
export const diversityTemplate = [
    {
        questionDimension: 'Diversity',
        type: 'Matrix',
        questionLabel: 'Diversity',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: 'My organization values diverse perspectives.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My organization values diverse perspectives.'),
        },
        {
            text: 'All employees, regardless of their backgrounds, are treated fairly.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('All employees, regardless of their backgrounds, are treated fairly.'),

        },
        {
            text: 'Diversity and inclusion are important priorities for my organization.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('Diversity and inclusion are important priorities for my organization.'),

        },
        {
            text: 'Senior leaders demonstrate that they are committed to diversity and inclusion.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('Senior leaders demonstrate that they are committed to diversity and inclusion.'),

        }]
    }
]

export const diversityMatrixTemplate = [
    {
        questionDimension: 'Diversity',
        type: 'matrix',
        questionLabel: 'Diversity',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        qid: uuidv4(),
        question: '',
        matrixQuestions: [{
            text: 'My organization values diverse perspectives.', value: '', isSelected: true, staticQuestionId: generateStaticQuestionId('My organization values diverse perspectives.'),
        },
        {
            text: 'All employees, regardless of their backgrounds, are treated fairly.', value: '', isSelected: true, staticQuestionId: generateStaticQuestionId('All employees, regardless of their backgrounds, are treated fairly.'),

        },
        {
            text: 'Diversity and inclusion are important priorities for my organization.', value: '', isSelected: true, staticQuestionId: generateStaticQuestionId('Diversity and inclusion are important priorities for my organization.'),

        },
        {
            text: 'Senior leaders demonstrate that they are committed to diversity and inclusion.', value: '', isSelected: false, staticQuestionId: generateStaticQuestionId('Senior leaders demonstrate that they are committed to diversity and inclusion.'),

        }]
    }
]