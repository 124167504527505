import { deepCopy } from "../../../../utils/deepCopy";
import { indexBasedColorPicker } from "../../../../utils/heatmapUtils";
import { CUSTOM_THRESHOLD } from "../constants";

export const selectedPalette = (state, payload) => {
  const { value, colors,selectedOption } = payload;
  const updatedThresholdValue = deepCopy(state.heatmapConfiguration.thresholdValue);
if(selectedOption === CUSTOM_THRESHOLD){
  updatedThresholdValue.forEach((threshold, index) => {
    switch (index) {
      case 0:
        threshold.color = colors[0];//colors are set constant since the colors in the corresponding position in colors array will be set as intial colors 
        break;
      case 1:
        threshold.color = colors[3];
        break;
      case 2:
        threshold.color = colors[5];
        break;
      case 3:
        threshold.color = colors[6];
        break;
      default:
        break;
    }
  });
}else{

  const minColorArray = colors.slice(0, 3).reverse();
  const maxColorArray = colors.slice(4)
    updatedThresholdValue.forEach((threshold, index) => {
    if (maxColorArray[index] && minColorArray[index]) {
      threshold.maxColor = maxColorArray[index];
      threshold.minColor = minColorArray[index];
    }
  });
}

    return {
      ...state,
      heatmapConfiguration: {
        ...state.heatmapConfiguration,
        colorPalette: value,
        thresholdValue: updatedThresholdValue,
      },
    };
  };
  