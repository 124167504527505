export const saveHeatmapConfigStart = (state, payload) => {
    return {
      ...state,
      setProjectConfigApi: { isProcessing: true, error: false, data: null }
    };
  };
  
export const saveHeatmapConfigSuccess =(state,payload)=>{
    return {
        ...state,
        setProjectConfigApi: { isProcessing: true, error: false, data: null }
      };
}

export const saveHeatmapConfigfail= (state,payload)=>{
    return{
        ...state,
        setProjectConfigApi: { isProcessing: false, error: true, data: null }
    }
}