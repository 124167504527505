export const wordCloudData =  {
    wordCloudFields: {
        wordField: "node",
        valueField: "count",
        filePrefix: "wordCloud",
        tooltipFormat: "{word}: {value}",
        property: "fill",
        minColor: "#92D08B",
        maxColor: "#675993",
        dataField: "value",
        hoverField: "hover",
      },
    
    wordCloudDivId :  "chartdiv",
    
    amchartmenu: [
        {
          label: "",
          menu: [
            {
              label: "image",
              menu: [
                { type: "png", label: "PNG" },
                { type: "jpg", label: "JPG" },
                { type: "pdf", label: "PDF" },
              ],
            },
          ],
        },
      ],

}