export const updatePairedComparisonSurvey = (state, payload) => {
    return {
        ...state,
        listComparisonSurveyApi: {
            isProcessing: false,
            data: payload.updatedData,
            error: false,
            message: ''
        },
        trendPairingDataApi: {
            ...state.trendPairingDataApi,
            currentSurvey: payload.updatedCurrentSurvey ? payload.updatedCurrentSurvey : state.trendPairingDataApi.currentSurvey
        },
        isTrendPairingDataAlreadyFetched: true
    }

}