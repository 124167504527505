import {
  GET_BATCH_COMMENT_QUESTIONS_STARTED,
  GET_BATCH_COMMENT_QUESTIONS_SUCCESS,
  GET_BATCH_COMMENT_QUESTIONS_FAILED,
} from "../../../constants";
import { BATCH_QUESTIONS_URL } from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { getDataFromUrlToken } from "../../../../utils/urlDataStore";
import { URL_DATA } from "../../../../../config/urlDataConstants";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const getBatchCommentQuestions = (payload,cb) => {
  const apiBaseInstance = new ApiBase().instance;
  const projDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS);
  payload.projectOrigin=projDetails.projectOrigin
  payload.selectedQuestionId=false
  payload.selectedUser=false
  payload.projectId = projDetails.projectId
  return (dispatch) => {
    dispatch(withType(GET_BATCH_COMMENT_QUESTIONS_STARTED, {}));
    return apiBaseInstance
      .post(BATCH_QUESTIONS_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        dispatch(
          withType(GET_BATCH_COMMENT_QUESTIONS_SUCCESS, {
            data: {
              data: res.data.data.currentBatchQuestions,
            },
          })
        );
        cb && setTimeout(() => { cb(); }, 200);
      })
      .catch((err) => {
        dispatch(withType(GET_BATCH_COMMENT_QUESTIONS_FAILED, err));
      });
  };
};


