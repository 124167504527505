import {FEEDBACK_360_SURVEY} from "../../../../../config/questionTypeConstants"
import { deepCopy } from "../../../../utils/deepCopy";
import { isAppliedProjectType } from "../../../../utils/newAttendSurveyUtils";
import { ALWAYS_SCALE, ERROR_MESSAGE_DRAG_IMPROVEMENT, ERROR_MESSAGE_DRAG_IMPROVEMENT_FOR_APPLIED, ERROR_MESSAGE_DRAG_NUMBER, ERROR_MESSAGE_DRAG_STRENGTH, ERROR_MESSAGE_DRAG_STRENGTH_FOR_APPLIED, NON_MAPPING_KEYS, SUMMARY_TYPE, USUALLY_SCALE } from "../../../reducers/SurveyAnsweringPageReducer/constants"

export const behaviourDragUpdate = (state, payload) => {
    let errorMessage = ERROR_MESSAGE_DRAG_STRENGTH
    let errorMessageForImprovement = ERROR_MESSAGE_DRAG_IMPROVEMENT
    if (state.surveyQuestionSetApi.data && state.surveyQuestionSetApi.data.projectType && (state.surveyQuestionSetApi.data.projectType === FEEDBACK_360_SURVEY.DYNAMIC_360_APPLIED_MATERIALS || state.surveyQuestionSetApi.data.projectType === FEEDBACK_360_SURVEY.DYNAMIC_360_APPLIED_MATERIALS_DIRECTORS)) {
        errorMessage = ERROR_MESSAGE_DRAG_STRENGTH_FOR_APPLIED
        errorMessageForImprovement = ERROR_MESSAGE_DRAG_IMPROVEMENT_FOR_APPLIED
    }
    let pageBreakCopy = deepCopy(state.pageBreakAddedQuestionSet)
    let questionSetCopy = deepCopy(state.questionSet)
    let pageIndex =pageBreakCopy[state.currentIndex]
    if(pageIndex.goalId=== FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG || pageIndex.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG || pageIndex.goalId === FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG_180 || pageIndex.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG_180 ){
    let answeredQuestionDetails = pageIndex.questions[0];
        let questionCount = 0
            if (answeredQuestionDetails) {
                for (const key in answeredQuestionDetails) {
                    if (answeredQuestionDetails[key].questions && Array.isArray(answeredQuestionDetails[key].questions)) {
                        questionCount += answeredQuestionDetails[key].questions.length;
                    }
                }
                if (questionCount < 3) {
                    if (state.surveyQuestionSetApi.data && state.surveyQuestionSetApi.data.projectType && isAppliedProjectType(state.surveyQuestionSetApi.data.projectType) && pageBreakCopy.find(item => item.type === SUMMARY_TYPE)) {
                        return {
                            //skip the block if count is less than 3
                            ...state, currentIndex: state.currentIndex + 2, progressIndex: state.progressIndex + 2
                        }
                    }
                    if (pageIndex.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG || pageIndex.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG_180) {
                        return { ...state, errorText: errorMessage, hideErrorBlock: false }
                    }
                    else if (pageIndex.goalId === FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG || pageIndex.goalId === FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG_180) {
                        return { ...state, errorText: errorMessageForImprovement, hideErrorBlock: false }
                    }
                }
                else {
                    return { ...state, errorText: "", hideErrorBlock: true }
                }
            }
            else {
                if (pageIndex.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG || pageIndex.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG_180) {
                    return { ...state, errorText: errorMessage, hideErrorBlock: false }
                }
                else if (pageIndex.goalId === FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG || pageIndex.goalId === FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG_180) {
                    return { ...state, errorText: errorMessageForImprovement, hideErrorBlock: false }
                }
            }
        }

}



//To split usually to dev areas use this code


// const getAlwaysAndUsuallyItems = (key, currentData) => {
//     let usuallyItems = {}
//     let alwaysItems = {}
//     let currentDetailsKeys = Object.keys(currentData[key])
//     if (currentDetailsKeys.includes(ALWAYS_SCALE)) {
//         alwaysItems[key] = {
//             [ALWAYS_SCALE]: currentData[key][ALWAYS_SCALE],
//             answerLabel: currentData[key].answerLabel,
//             questions: currentData[key].questions
//         }

//     }
//     if (currentDetailsKeys.includes(USUALLY_SCALE)) {
//         usuallyItems[key] = {
//             [USUALLY_SCALE]: currentData[key][USUALLY_SCALE],
//             answerLabel: currentData[key].answerLabel,
//             questions: currentData[key].questions
//         }

//     }

//     return { alwaysItems, usuallyItems }
// }

// const checkIfMappingExist = (currentItem) => {
//     for (let key in currentItem) {
//         if (!NON_MAPPING_KEYS.includes(key)) {
//             return true
//         }
//     }
//     return false
// }

// const generateDragQuestionsWithTopics = (currentObject, topicsObject) => {
//     let generatedObject = {}
//     Object.keys(currentObject).forEach((key) => {
//         if (NON_MAPPING_KEYS.includes(key)) {
//             generatedObject[key] = currentObject[key]
//         }
//     })
//     generatedObject = { ...generatedObject, ...topicsObject }
//     return generatedObject
// }