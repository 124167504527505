export const downloadDashboardPPTStart = (state) =>{
    return {
        ...state,
        exportDashboardPptApi: {
          isProcessing: true,
          error:false
        },
      };
}

export const downloadDashboardPPTSuccess = (state) =>{
    return {
        ...state,
        exportDashboardPptApi: {
          isProcessing: false,
          error:false
        },
      };
}

export const downloadDashboardPPTFail = (state) =>{
    return {
        ...state,
        exportDashboardPptApi: {
          isProcessing: false,
          error:true
        },
      };
}