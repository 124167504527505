import {
  FETCH_TREE_DATA_STARTED,
  FETCH_TREE_DATA_SUCCEED,
  FETCH_TREE_DATA_FAILED,
} from '../../constants';
import {
  getOrgId,
  getLoginUserEmail,
} from '../../../utils/localStorgeOperations';
import { ApiBase } from '../../../utils/apiBase';
import { NLP_TREE_DATA_GET_URL } from '../../../../config/constants';
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchTreeData = (params, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch({
      type: FETCH_TREE_DATA_STARTED,
    });
    apiBaseInstance.instance
      .post(NLP_TREE_DATA_GET_URL, { ...nlpOrgTreeBody(params), ...getProjectIdAndOrgId(nlpOrgTreeBody(params)) })
      .then((res) => {
        dispatch({
          type: FETCH_TREE_DATA_SUCCEED,
          payload: { data: res && res.data && res.data.data },
        })
        let treeData = res && res.data && res.data.data;
        let treeJson = treeData && JSON.parse(treeData)
        setTimeout(() => cb && cb([treeJson]), 0)
      }
      )
      .catch((err) =>
        dispatch({
          type: FETCH_TREE_DATA_FAILED,
          payload: { error: err },
        })
      );
  };
};

const nlpOrgTreeBody = (params) => {
  return {
    email: getLoginUserEmail(),
    organizationId: getOrgId(),
    jobId: params.projectId,
    ...params
  };
};
