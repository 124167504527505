import {
  INPUT_ADD_ORGANIZATION,
  CLEAR_ORGANIZATION_FORM,
} from "../../constants";
import { withType } from "../../../utils/actionOperations";

export const formInputChange = (event) => {
  return (dispatch) => {
    dispatch(withType(INPUT_ADD_ORGANIZATION, event));
  };
};

export const clearOrganizationForm = () => {
  return {
    type: CLEAR_ORGANIZATION_FORM,
  };
};
