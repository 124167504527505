export function deleteRoleSuccess(state, payload) {
  return {
    ...state,
    deleteRoleApi:{
      loading:false,
      error:false
    }
  }
}

export function deleteRoleStarted(state, payload) {
  return {
    ...state,
    deleteRoleApi:{
      loading:true,
      error:false
    }
  }
}

export function deleteRoleFail(state, payload) {
  return {
    ...state,
    deleteRoleApi:{
      loading:false,
      error:true
    }
  }
}