import {
  getApiStartState,
  getSuccessResponse,
} from "../../../../utils/reducerOperations";

export const storyboardQuestionsStarted = (state) => {
  return {
    ...state,
    storyboardQuestionsApi: getApiStartState(),
  };
};

export const storyboardQuestionsSuccess = (state, payload) => {
  return {
    ...state,
    storyboardQuestionsApi: getSuccessResponse(payload && payload.data),
    batchQuestions:
      payload.data &&
      payload.data.data &&
      payload.data.data.currentBatchQuestions,
  };
};

export const storyboardQuestionsFailed = (state, payload) => {
  return {
    ...state,
    storyboardQuestionsApi: {
      isProcessing: false,
      data: null,
      error: payload.error,
    },
  };
};

export const storyboardGoingWellStarted = (state) => {
  return {
    ...state,
    storyboardGoingWellApi: getApiStartState(),
  };
};

export const storyboardGoingWellSuccess = (state, payload) => {
  return {
    ...state,
    storyboardGoingWellApi: {
      isProcessing: false,
      data:
        payload.data.data &&
        payload.data.data.response &&
        payload.data.data.response.wordCloudData &&
        getWordcloudData(
          payload.data.data.response.wordCloudData,
          state.wordCloudMinimumCount
        ),
      error: !payload.data.status,
      message: payload.data.message,
    },
  };
};

const getWordcloudData = (data, count) => {
  return data.filter((item) => item.count > count);
};

export const storyboardGoingWellFailed = (state, payload) => {
  return {
    ...state,
    storyboardGoingWellApi: {
      isProcessing: false,
      data: null,
      error: payload.error,
    },
  };
};
export const storyboardImprovementStarted = (state) => {
  return {
    ...state,
    storyboardImprovementApi: getApiStartState(),
  };
};

export const storyboardImprovementSuccess = (state, payload) => {
  return {
    ...state,
    storyboardImprovementApi: {
      isProcessing: false,
      data:
        payload.data.data &&
        payload.data.data.response &&
        payload.data.data.response.wordCloudData &&
        getWordcloudData(
          payload.data.data.response.wordCloudData,
          state.wordCloudMinimumCount
        ),
      error: !payload.data.status,
      message: payload.data.message,
    },
  };
};

export const storyboardImprovementFailed = (state, payload) => {
  return {
    ...state,
    storyboardImprovementApi: {
      isProcessing: false,
      data: null,
      error: payload.error,
    },
  };
};

export const storyboardQuestionCommentsStarted = (state, payload) => {
  return {
    ...state,
    [payload.key]: getApiStartState(),
  };
};

export const storyboardQuestionCommentsSuccess = (state, payload) => {
  return {
    ...state,
    [payload.data.key]: getSuccessResponse(payload && payload.data),
  };
};

export const storyboardQuestionCommentsFailed = (state, payload) => {
  return {
    ...state,
    goingWellCommentsApi: {
      isProcessing: false,
      data: null,
      error: payload.error,
    },
    improvementCommentsApi: {
      isProcessing: false,
      data: null,
      error: payload.error,
    },
  };
};

export const storyboardHighCountCommentsStarted = (state, payload) => {
  return {
    ...state,
    storyboardFetchHighCountCommentQuestionApi: getApiStartState(),
  };
}

export const storyboardHighCountCommentsSuccess = (state, payload) => {
  return {
    ...state,
    storyboardFetchHighCountCommentQuestionApi: {
      isProcessing: false,
      data:
        payload.data.data &&
        payload.data.data.response &&
        payload.data.data.response.wordCloudData &&
        getWordcloudData(
          payload.data.data.response.wordCloudData,
          state.wordCloudMinimumCount
        ),
      error: !payload.data.status,
      message: payload.data.message,
    },
  };
}

export const storyboardHighCountCommentsFailed = (state, payload) => {
  return {
    ...state,
    storyboardFetchHighCountCommentQuestionApi: {
      isProcessing: false,
      data: null,
      error: payload.error,
    },
  };
}

export const storyboardLessCountCommentsSuccess = (state, payload) => {
  return {
    ...state,
    storyboardFetchLessCountCommentQuestionApi: {
      isProcessing: false,
      data:
        payload.data.data &&
        payload.data.data.response &&
        payload.data.data.response.wordCloudData &&
        getWordcloudData(
          payload.data.data.response.wordCloudData,
          state.wordCloudMinimumCount
        ),
      error: !payload.data.status,
      message: payload.data.message,
    },
  };
}

export const storyboardLessCountCommentsFailed = (state, payload) => {
  return {
    ...state,
    storyboardFetchLessCountCommentQuestionApi: {
      isProcessing: false,
      data: null,
      error: payload.error,
    },
  };
}

export const storyboardLessCountCommentsStarted = (state, payload) => {
  return {
    ...state,
    storyboardFetchLessCountCommentQuestionApi: getApiStartState(),
  };
}