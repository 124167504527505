import { deepCopy } from "../../../../../../utils/deepCopy"

export const handleBlockDragEnd = (state, payload) => {
    let dragUpdatedGoal = getDragUpdatedGoals(state.goals, payload)
    return ({
        ...state,
        goals: dragUpdatedGoal
    })
}

const getDragUpdatedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    const draggedBlock = goalsCopy[payload.sourceIndex]
    if (draggedBlock) {
        goalsCopy.splice(payload.sourceIndex, 1)
        goalsCopy.splice(payload.destinationIndex, 0, draggedBlock)
    }
    return goalsCopy
}