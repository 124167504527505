import { 
    getSuccessResponse, handleApiResponseError, handleNetworkError,
} from '../../../utils/reducerOperations';

export function stopResponseCollectionStart(state,action){
    return{
        ...state,
        stopResponseCollectionApi:{
            data:null,
            isProcessing:true,
            error:false,
            message:null
        }
    };
}

export function stopResponseCollectionSuccess(state,action){
    return{
        ...state,
        stopResponseCollectionApi:getSuccessResponse(action.payload)
    };
}

export function stopResponseCollectionFail(state,action){
    return{
        ...state,
        stopResponseCollectionApi:
        (action.payload 
            && !action.payload.data
            ? handleApiResponseError(action.payload)
            : handleNetworkError(action.payload))
    };
}