export const downloadPptStatusStart = (state,payload)=>{ 
    return {
        ...state,
        exportPptStatusApi:{
            ...state.exportPptStatusApi,
            isProcessing: true,
            error:false
            }
    }
}

export const downloadPptStatusSuccess = (state,payload)=>{
    const data = payload.data.data.response
    return{
        ...state,
        exportPptStatusApi:{
          ...state.exportPptStatusApi,
          isProcessing: false,
          data:data,
          error:false
        }
    }
}

export const downloadPptStatusFail = (state,payload)=>{
    return{
        ...state,
        exportPptStatusApi:{
          ...state.exportPptStatusApi,
          isProcessing: false, 
          error:true
        }
    }
}