import React, { memo } from "react";
import { Switch, Route } from "react-router-dom";
import { renderComponentWithProps } from "../../utils/checkSignInAndRender";
const RenderAllowedRoutes = ({ routes }) => {
  return (
    <Switch>
      {routes.map((route) => {
        const { path, component, children, name, roles, ...rest } = route;
        return (
          <Route
            {...rest}
            key={path}
            path={path}
            render={(properties) => {
              return renderComponentWithProps(properties, component);
            }}
          />
        );
      })}
    </Switch>
  );
};

export default memo(RenderAllowedRoutes);
