import {
    FETCH_SURVEYS_STARTED,
    FETCH_SURVEYS_SUCCESS,
    FETCH_SURVEYS_FAILED
} from "../../../constants";
import { LIST_SURVEY_URL } from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";

export const fetchSurveys = (payload, cb) => {
    const axios = new ApiBase().instance;
    return (dispatch) => {
        dispatch({ type: FETCH_SURVEYS_STARTED });
        axios.post(LIST_SURVEY_URL, { ...payload })
            .then((res) => {
                fetchSurveysSuccess(dispatch, res, payload)
                cb && cb(res)
            })
            .catch((err) => fetchSurveysFailed(dispatch, err));
    };
};

function fetchSurveysSuccess(dispatch, res, payload) {
    return dispatch({
        type: FETCH_SURVEYS_SUCCESS,
        data: res.data.data,
        searchText: payload.searchText
    });
}

function fetchSurveysFailed(dispatch, err) {
    return dispatch({
        type: FETCH_SURVEYS_FAILED,
        error: err.message,
    });
}

