import React from 'react';
import constants from './constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonElement } from "../../FormElements/ButtonElement";
export const IconButton = props => (
    <ButtonElement
        test-name='icon-btn'
        disabled={props.disabled}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}
        className={(props.classList || []).join(constants.emptySpace)}>
            <FontAwesomeIcon icon={setCurrentIcon(props)} />
      
        <span children={props.text || null} />
    </ButtonElement>
);

function setCurrentIcon(props) {
    return props.loading
        ? constants.loadingIcon
        : props.iconName;
}