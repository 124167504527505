import { deepCopy } from "./deepCopy";

export const getOuterTreeDataChildren = (data) => {
  if (data && data.treeData && data.treeData.length > 0) {
    let topLevelManagers = {};
    let rootManagers = deepCopy(data.treeData[0].children);
    if(rootManagers.length>1){
      rootManagers.sort(function (a, b) {
        return b.count - a.count
    })
    rootManagers = [rootManagers[0]]
    }
    if (rootManagers && rootManagers.length === 1 &&
      rootManagers[0].children &&
      rootManagers[0].children.length>0) {
      rootManagers = rootManagers[0].children
    }
    if (!rootManagers[0].children) {
      return undefined
    }
    else {
      rootManagers && Array.isArray(rootManagers) && rootManagers.forEach((item, index) => {
        topLevelManagers[item.key] = item.title;
      });
      return topLevelManagers
    }
  }
};

export const getDefaultHighestChildManager=(data)=>{
  if (data && data.treeData && data.treeData.length > 0) {
    let rootManagers = deepCopy(data.treeData[0].children);
    if(rootManagers.length>0){
      rootManagers.sort(function (a, b) {
        return b.count - a.count
    })
    rootManagers = [rootManagers[0]]
    }
    return rootManagers
  }
  return null
}
