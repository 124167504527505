import {
  makeKey,
  update,
  getSuccessResponse,
} from "../../../../utils/reducerOperations";
//import { toast } from "../../../../utils/messageUtils";

export function responsesSuccess(state, payload) {
 // toast.success("Password reset successful");
  return makeKey(
    "responsesApi",
    update(state.responsesApi, {
      ...getSuccessResponse(payload),
      code: payload.code,
    })
  );
}
