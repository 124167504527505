import { fetchPayload } from "../../../utils/reducerOperations";
import * as helper from "./Helper";
import {
  MANAGE_USERS_FETCH_USERS_STARTED,
  MANAGE_USERS_FETCH_USERS_SUCCESS,
  MANAGE_USERS_FETCH_USERS_FAILED,
  MANAGE_USERS_INVITE_MANAGERS_STARTED,
  MANAGE_USERS_INVITE_MANAGERS_SUCCESS,
  MANAGE_USERS_INVITE_MANAGERS_FAILED,
  MANAGE_USERS_FETCH_HRIS_FILE_NAME_STARTED,
  MANAGE_USERS_FETCH_HRIS_FILE_NAME_SUCCESS,
  MANAGE_USERS_FETCH_HRIS_FILE_NAME_FAILED,
  REVOKE_USERS_FAIL,
  REVOKE_USERS_START,
  REVOKE_USERS_SUCCESS
} from "../../constants";
import { InitialState } from "./InitialState";

const FetchManageUsersReducer = (state = InitialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case MANAGE_USERS_FETCH_USERS_STARTED:
      return helper.fetchUsersStart(state, payload);
    case MANAGE_USERS_FETCH_USERS_SUCCESS:
      return helper.fetchUsersSuccess(state, payload);
    case MANAGE_USERS_FETCH_USERS_FAILED:
      return helper.fetchUsersFailed(state, payload);
    case MANAGE_USERS_INVITE_MANAGERS_STARTED:
      return helper.inviteManagersStart(state, payload);
    case MANAGE_USERS_INVITE_MANAGERS_SUCCESS:
      return helper.inviteManagersSuccess(state, payload);
    case MANAGE_USERS_INVITE_MANAGERS_FAILED:
      return helper.inviteManagersFailed(state, payload);
    case MANAGE_USERS_FETCH_HRIS_FILE_NAME_STARTED:
      return helper.fetchHRISFileNameStart(state, payload);
    case MANAGE_USERS_FETCH_HRIS_FILE_NAME_SUCCESS:
      return helper.fetchHRISFileNameSuccess(state, payload);
    case MANAGE_USERS_FETCH_HRIS_FILE_NAME_FAILED:
      return helper.fetchHRISFileNameFailed(state, payload);
    case REVOKE_USERS_START:
      return helper.revokeAccesStart(state, payload);
    case REVOKE_USERS_SUCCESS:
      return helper.revokeAccesSuccess(state, payload);
    case REVOKE_USERS_FAIL:
      return helper.revokeAccesFailed(state, payload);
    default:
      return { ...state };
  }
};

export default FetchManageUsersReducer;
