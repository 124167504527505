import * as actionTypes from "../../constants";
import { ApiBase } from "../../../utils/apiBase";
import { RENAME_ORG_URL } from "../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export function renameOrg(payload, callback) {
  return (dispatch) => {
    dispatch({ type: actionTypes.RENAME_ORG_START });
    const axios = new ApiBase().instance;
    axios
      .post(RENAME_ORG_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((res) => {
        dispatch({ type: actionTypes.RENAME_ORG_SUCCESS, response: res });
        callback && callback();
        renameOrgSuccess.bind(null, dispatch, payload);
      })
      .catch((err) =>
        dispatch({
          type: actionTypes.RENAME_ORG_FAIL,
          response: err,
        })
      );
  };
}

function renameOrgSuccess(dispatch, payload, response) {
  return dispatch({
    type: actionTypes.RENAME_ORG_SUCCESS,
    response,
    requestPayload: payload,
  });
}
