import { deepCopy } from "../../../../utils/deepCopy"

export const onDragEndPriority = (state, payload) => {
    let changedQuestionSet = getDragUpdatedQuestionSet(state, payload)
    return ({
        ...state,
        questionSet: changedQuestionSet.questionSetCopy,
        pageBreakAddedQuestionSet: changedQuestionSet.pageBreakBlockCopy,
        hideErrorBlock: true,

    })
}

const getDragUpdatedQuestionSet = (state, payload) => {
    let destinationId = JSON.parse(payload.destinationDetails.droppableId).id
    let questionSetCopy = deepCopy(state.questionSet)
    let updatedPriority = []
    let findedIndexOfList = -1
    let pageBreakBlockCopy = deepCopy(state.pageBreakAddedQuestionSet)
    questionSetCopy.forEach((item, index) => {
        if (item.blockId === payload.blockId) {
            let quesionType = item.type
            updatedPriority = item.questions.length > 0 && item.questions[0].answer ? JSON.parse(item.questions[0].answer) : []
            findedIndexOfList = updatedPriority.findIndex((prirority) => prirority.answer === destinationId.toString())
            if (findedIndexOfList !== -1) {
                updatedPriority.splice(findedIndexOfList, 1)
                updatedPriority.splice(findedIndexOfList, 0, { ...payload.sourceDetails, answer: destinationId.toString(),type:quesionType })
            }
            else {
                updatedPriority.push({ ...payload.sourceDetails, answer: destinationId.toString()})
            }
            let newUpdatedPriority = deepCopy(updatedPriority)
            item.questions[0] = { ...item.questions[0], answer: JSON.stringify(newUpdatedPriority), type: quesionType, questionDimension: item.dimension, question: item.dimension }
        }
    })
    let findedIndex = pageBreakBlockCopy.findIndex((item) => item.blockId === payload.blockId)
    if (findedIndex !== -1) {
        pageBreakBlockCopy[findedIndex].questions[0].answer = JSON.stringify(updatedPriority)
    }
    return { questionSetCopy, pageBreakBlockCopy }

}

export const onChangePriorityText = (state, payload) => {
    let changedQuestionSet = changePriorityText(state, payload)
    return ({
        ...state,
        questionSet: changedQuestionSet.questionSetCopy,
        pageBreakAddedQuestionSet: changedQuestionSet.pageBreakBlockCopy,
        hideErrorBlock: true,
        errorText: ""

    })
}

const changePriorityText = (state, payload) => {
    let questionSetCopy = deepCopy(state.questionSet)
    let pageBreakBlockCopy = deepCopy(state.pageBreakAddedQuestionSet)
    let updatedPriority = {}
    let findedIndex
    let mainIndex
    let pageBreakIndex = pageBreakBlockCopy.findIndex((item => item.blockId === payload.blockId))
    questionSetCopy.forEach((item, index) => {
        if (item.blockId === payload.blockId) {
            mainIndex = index
            findedIndex = item.questions.findIndex((item) => item.qid === payload.currentItemId)
            item.questions[findedIndex] = { ...item.questions[findedIndex], answer: payload.textAnswer }

        }

    })
    if (findedIndex !== -1 && pageBreakIndex !== -1) {
        pageBreakBlockCopy[pageBreakIndex].questions[findedIndex] = { ...pageBreakBlockCopy[pageBreakIndex].questions[findedIndex], answer: payload.textAnswer }
    }
    return { questionSetCopy, pageBreakBlockCopy }
}

export const handleChangeCheckBoxChange = (state, payload) => {
    let changedQuestionSet = changePriorityCheckBoxData(state, payload)
    return {
        ...state,
        questionSet: changedQuestionSet.questionSetCopy,
        pageBreakAddedQuestionSet: changedQuestionSet.pageBreakBlockCopy,
        hideErrorBlock: true,
        errorText: ""
    }
}

const changePriorityCheckBoxData = (state, payload) => {
    let questionSetCopy = deepCopy(state.questionSet)
    let pageBreakBlockCopy = deepCopy(state.pageBreakAddedQuestionSet)
    let pageBreakIndex = pageBreakBlockCopy.findIndex((item => item.blockId === payload.blockId))

    let findedIndex
    let mainIndex
    questionSetCopy.forEach((item, index) => {
        if (item.blockId === payload.blockId) {
            mainIndex = index
            findedIndex = item.questions.findIndex((item) => item.qid === payload.currentItemId)
            item.questions[findedIndex] = { ...item.questions[findedIndex], checkboxValue: payload.checked, answer: "" }

        }

    })
    if (findedIndex !== -1 && pageBreakIndex !== -1) {
        pageBreakBlockCopy[pageBreakIndex].questions[findedIndex] = { ...pageBreakBlockCopy[pageBreakIndex].questions[findedIndex], checkboxValue: payload.checked, answer: "" }
    }
    return { questionSetCopy, pageBreakBlockCopy }
}
