
export const setBenchmarkDetailsStarted = (state, payload) => {
    return {
        ...state,
        setBenchmarkDetails: {
            ...state.setBenchmarkDetails,
            isProcessing: true,
            error: false
        }
    };
};

export const setBenchmarkDetailsSuccess = (state, payload) => {
    return {
        ...state,
        setBenchmarkDetails: {
            data: payload.response,
            isProcessing: false,
            error: false
        }
    };
};

export const setBenchmarkDetailsFail = (state, payload) => {
    return {
        ...state,
        setBenchmarkDetails: {
            data: null,
            isProcessing: false,
            error: true
        }
    };
};