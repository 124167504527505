import {  } from '../../../../config/constants';
import constants from './constants';
import { notification } from 'antd';
import { fetchBatchIds } from '.';
import Axios from 'axios';
import { BULK_BATCH_ID_OPERATION } from '../../../../config/constants';
import { 
    BATCH_DELETE_JOBS_SUCCESS,
    BATCH_DELETE_JOBS_FAIL
} from '../../constants';

export function batchDeleteJobsRequest(doc){
    let deletePayload = getPayload(doc);
    return dispatch=>{
        Axios.post(BULK_BATCH_ID_OPERATION,deletePayload)
            .then(res => deleteRequestSuccess(dispatch,res))
            .catch(err => requestFail(dispatch,err));
    };
}

function deleteRequestSuccess(dispatch,res){
    notification.success({message:constants.deleted});
    dispatch({
        type:BATCH_DELETE_JOBS_SUCCESS,
        data:res.data
    });
    dispatch(fetchBatchIds());
}

function requestFail(dispatch,err){
    notification.error({message:constants.failed});
    dispatch({
        type:BATCH_DELETE_JOBS_FAIL,
        error:err&&err.message
    });
}

function getPayload(doc){
    let payLoadForAPI=[]
    doc.forEach(row=>{
        payLoadForAPI.push({
            operation: 'delete',
            batchIdDocument:docDeletePayLoad(row)
        })
    })
    return payLoadForAPI;
}
function docDeletePayLoad(doc){
    return {
        projectId : doc.projectId,
        userId : doc.userId,
        user : doc.user,
    };
}