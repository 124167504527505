export default {
  services_title: 'Services',
  services_services: [{
    text: 'Build Talent Analytics Function',
    paragraph: "Set up talent analytics function and strategy Staff\n               and on-board talent analytics professionals"
  }, {
    text: 'Deliver Trainings and Workshops',
    paragraph: "Deliver basic, intermediate and advance capability \n              building sessions for HR team on data analytics"
  }, {
    text: 'Assess Diversity & Inclusion ',
    paragraph: "Source and analyze\n               data to understand develop, \n              and act on representation and inclusion"
  }, {
    text: 'Conduct Action Planning',
    paragraph: "Use results from engagement survey data and \n              organizational context to facilitate\n               building action plans"
  }]
};