export const fetchMultiQuestionsStart = (state, payload) => {
    return {
      ...state,
      multiSelectQuestionsApi: {
        isProcessing: true,
        data: [],
        error: false,
        message: "",
      },
    };
  };
  
  export const fetchMultiQuestionsSuccess = (state, payload) => {
    return {
      ...state,
      multiSelectQuestionsApi: {
        isProcessing: false,
        data:payload.data&&payload.data.data,
        error: false,
        message: "",
      },
    };
  };
  
  export const fetchMultiQuestionsFailed = (state, payload) => {
    return {
      ...state,
      multiSelectQuestionsApi: {
        isProcessing: false,
        data: [],
        error: true,
        message: payload.error,
      },
    };
  };
  
  
  