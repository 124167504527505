import { deepCopy } from "../../../../../../utils/deepCopy"
import { toast } from "../../../../../../utils/messageUtils"
import { getFilteredMenuData } from "../../../../../../utils/newAttendSurveyUtils"
import { getBlockQuestion } from "./helpers"

export const addQuestionBlock = (state, payload) => {
    let updatedGoals = questionBlockAddedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals,

    })
}

const questionBlockAddedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    if (payload.rightSection) {
        goalsCopy.splice(payload.selectedIndex, 1, payload.block)
    } else {
        let indexValue = payload.selectedIndex === 0||payload.selectedIndex ? payload.selectedIndex + 1 : 0
        goalsCopy.splice(indexValue, 0, payload.block)
        toast.success("Successfully added")
    }
    return goalsCopy
}