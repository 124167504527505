import React from "react";
import { Modal } from "antd";
import Icon from '@ant-design/icons';
import { IconButton } from "../../FormElements/IconButton";
import { Card } from 'react-bootstrap';
import { ButtonElement } from "../../FormElements/ButtonElement";
import "./index.scss";
import constants from "./constants";
import { checkIfAdmin } from '../../utils/rolePermissionUtils';

class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isHovered: false };
    this.toggleHover = this.toggleHover.bind(this);
    this.reRouteTo = this.reRouteTo.bind(this);
    this.deleteOrganization = this.deleteOrganization.bind(this);
    this.deleteOrganizationButton = this.deleteOrganizationButton.bind(this);
  }

  deleteOrganization(event) {
    this.props.deleteOrganization({
      organizationId: this.props.organization.organizationId,
    });
  }

  organizationId() {
    return this.props.organization.organizationId;
  }
  organizationNameForUrl() {
    return this.props.organization.name.split(" ").join("_");
  }
  organizationName() {
    return this.props.organization.name;
  }
  reRouteTo(event) {
    const { path } = constants;
    const orgId = this.organizationId();
    const organizationName = this.organizationName();
    localStorage.setItem("organizationId", orgId);
    localStorage.setItem("organizationName", organizationName);
    switch (event.target.innerText) {
      case constants.VIEW_SURVEY.toUpperCase():
        this.props.routeTo(path.survey + orgId + "/surveys");
        break;
      case constants.MANAGE_USER.toUpperCase():
        this.props.routeTo(`${path.users}${orgId}/manageusers`);
        break;
      default:
        break;
    }
  }

  onDeleteOrganization = (ok) => {
    Modal.confirm({
      title: constants.DELETE_QUESTION,
      icon: <Icon type={constants.WARNING} />,
      content: constants.DELETE_WARNING,
      okText: constants.DELETE,
      okButtonProps: constants.OK_BUTTON_PROP,
      onOk() {
        return ok();
      },
      onCancel() { },
    });
  };

  toggleHover() {
    this.setState((prevState) => ({ isHovered: !prevState.isHovered }));
  }

  deleteOrganizationButton() {
    const { iconButton } = constants;
    if (checkIfAdmin()) {
      return (
        <IconButton
          test-name="delete-organization"
          loading={this.props.isDeleting}
          disabled={false}
          onClick={() => this.onDeleteOrganization(this.deleteOrganization)}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
          classList={iconButton.classes}
          iconName={
            this.state.isHovered ? iconButton.iconDelete : iconButton.iconName
          }
        />
      );
    }
  }

  organizationBody() {
    const { button } = constants;
    return (
      <Card.Body test-name="organization-body">
        <ButtonElement
          variant={button.variant}
          size={button.size}
          onClick={this.reRouteTo}
          block
          children={constants.VIEW_SURVEY}
        />
        <ButtonElement
          variant={button.variant}
          size={button.size}
          onClick={this.reRouteTo}
          block
          children={constants.MANAGE_USER}
        />
      </Card.Body>
    );
  }
  organizationHeader(title = constants.defaultTitle) {
    return (
      <Card.Header>
        <h3 children={title} />
        <div className="controls" children={this.deleteOrganizationButton()} />
      </Card.Header >
    );
  }
  render() {
    let { name } = this.props.organization;
    return (
      <li test-name="organization">
        <Card>
          {this.organizationHeader(name)}
          {this.organizationBody()}
        </Card>
      </li>
    );
  }
}
export default Organization;
