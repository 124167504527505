import { connect } from "react-redux";
import { SelectState } from "./Selector";
import Logs from "./Logs";
import "./index.scss";

import {
  fetchUserCreationLogs,
  fetchHRIS,
  logsPaginationChange,
} from "../../redux/actions";

const mapStateToProps = (state) => ({ ...SelectState(state) });

const mapDispatchToProps = (dispatch) => ({
  fetchUserCreationLogs: (params) => dispatch(fetchUserCreationLogs(params)),
  fetchHRIS: (params) => dispatch(fetchHRIS(params)),
  logsPaginationChange: (params) => dispatch(logsPaginationChange(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
