
export const fetchStoryboardElementsStart = (state, payload) => {
  return {
    ...state,
    storyboardElementsApi: {
      isProcessing: true,
      error: false,
      reason: '',
      storyboardElements: [],
    },
  };
};

export const fetchStoryboardElementsSuccess = (state, payload) => {
  let data = payload.data || {};
  const storyboardElements = Object.values(data) || [];
  let noDuplicateElementId = findIfMoreThanOnePositiveOrNegativeOutlier(storyboardElements)
  return {
    ...state,
    storyboardElementsApi: {
      isProcessing: false,
      error: false,
      reason: '',
      storyboardElements: noDuplicateElementId,
    },
  };
};

export const fetchStoryboardElementsFail = (state, payload) => {
  return {
    ...state,
    storyboardElementsApi: {
      isProcessing: false,
      error: true,
      reason: payload.error,
      storyboardElements: [],
    },
  };
};

const findIfMoreThanOnePositiveOrNegativeOutlier = (data) => {
  let updatedData = []
  let count = 0
  data.forEach((item) => {
    if (item.elementId === "positiveOrNegativeOutcomeOutlier") {
      count = count + 1
    }
    if (count >= 2 && item.elementId === "positiveOrNegativeOutcomeOutlier") {
      updatedData.push({
        "elementId": "positiveOrNegativeOutcomeOutlier1",
        "cardColor": item.cardColor,
        "title": item.title
      })
    }
    else updatedData.push(item)
  })
  return updatedData
}