import React from "react";
import constants, {
  ADDED_COLOUMN_DOWNLOAD,
  ADDTIONAL_SCORE_COLOUMNS,
  KEYS_FOR_DOWNLOADING,
} from "./constants";
import { CustomTable } from "../../../../../../../../Components/CustomTable";
import { Switch } from "antd";
import { ButtonElement } from "../../../../../../../../FormElements/ButtonElement";
import { Excel } from "antd-table-saveas-excel";
import { getDataFromUrlToken } from "../../../../../../../../utils/urlDataStore";
import { URL_DATA } from "../../../../../../../../../config/urlDataConstants";
import { downloadAsExcel } from "../../../../../../../../utils/parseAndDownloadExcel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import SVGIcons from "../../../../../../../../Components/SVGIcons";
import { addTrendColomn } from "../../../../helper";

export default class QuestionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showActualTrendScore: false,
    };
  }

  fetchTableColums = (columns) => {
    let filterData = columns;
    if (
      this.props.projectDetails &&
      this.props.projectDetails.projectType &&
      this.props.projectDetails.projectType === "Very_Simple_Survey"
    ) {
      filterData = filterData.filter((item) => item.dataIndex !== "dimension");
    }
    if (this.props.trendColomns.length > 0) {
      let addedColomnForTrend = addTrendColomn(
        this.props.trendColomns,
        this.tableProps()
      );

      filterData.splice(3, 0, ...addedColomnForTrend);
      return [...filterData];
    } else {
      return filterData;
    }
  };

  checkBenchmarkEmpty = () => {
    let flag = false;
    for (let item = 0; item < this.props.data.length; item++) {
      if (!this.props.data[item].benchmarkScore) {
        flag = true;
      } else {
        return false;
      }
    }
    return flag;
  };

  checkTrendBenchmarkEmpty = () => {
    let flag = false;
    for (let item = 0; item < this.props.data.length; item++) {
      if (!this.props.data[item].benchMarkTwoScore) {
        flag = true;
      } else {
        return false;
      }
    }
    return flag;
  };

  tableProps = () => {
    return {
      showBenchMark:
        this.props.projectDetails.projectOringin !== "Comment Analysis",
      allBenchMarkScoreEmpty: this.checkBenchmarkEmpty(),
      allTrendBenchMarkEmpty: this.checkTrendBenchmarkEmpty(),
      benchmarkTooltip: this.props.threshold.benchmarkTooltip
        ? this.props.threshold.benchmarkTooltip
        : "",
      threshold: this.props.threshold.filterThreshold,
      showActualScore: this.props.showActualTrendScore,
    };
  };

  handleClickDownload = () => {
    let columnsForTable = this.fetchTableColums(
      constants.tableColumnPositiveOutLiers(this.tableProps())
    );
    let formattedColoumn = [];
    columnsForTable.forEach((columns) => {
      if (columns.title === "Score") {
        formattedColoumn.push(...ADDED_COLOUMN_DOWNLOAD);
      } else if (columns.key === "benchmarkScore") {
        columns.title = "Benchmark";
        delete columns.render;
        delete columns.width;
        formattedColoumn.push(columns);
      } else {
        delete columns.render;
        delete columns.width;
        formattedColoumn.push(columns);
      }
    });
    let excelData = this.props.data.map((data) => {
      if (data.trend && data.trend.length > 0) {
        let trendObject = {};
        data.trend.forEach((item) => {
          trendObject[item.projectId] = data[item.projectId]
            ? data[item.projectId].score
            : "-";
        });
        return {
          ...data,
          ...trendObject,
          benchmarkScore: data.benchmarkScore ? data.benchmarkScore : "-",
        };
      } else {
        let updatedData = {
          ...data,
          benchmarkScore: data.benchmarkScore ? data.benchmarkScore : "-",
        };
        return updatedData;
      }
    });

    const projectDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS);
    let fileName = projectDetails.projectName
      ? `${projectDetails.projectName}-questions-${new Date().toISOString()}`
      : "questions";
    downloadAsExcel({
      data: excelData,
      coloumns: formattedColoumn,
      name: fileName,
    });
  };

  getExcelField = (excelData, field) => {
    let keyFromApi = KEYS_FOR_DOWNLOADING[field];
    if (keyFromApi) {
      if (field === "Average") {
        return excelData[keyFromApi].toFixed(2);
      } else return excelData[keyFromApi];
    } else {
      let trendField = excelData.trend.find(
        (trendDetails) => trendDetails.projectName === field
      );
      return trendField ? trendField.trendScore : "-";
    }
  };

  render() {
    return (
      <>
        <div className="outliers-block">
          <div className="outliers-block__head">
            <h2>Questions</h2>
            <div className="question-table-right">
              {this.props.trendColomns.length > 0 && (
                <span className="trend-actual-score">
                  <p className="trend-actual-score__label">
                    Show trend actual score
                  </p>
                  <Switch
                    checked={this.props.showActualTrendScore}
                    onChange={(e) => {
                      this.props.onChangeActualScore(e);
                    }}
                  />
                </span>
              )}
              <ButtonElement
                className="download-heatmap"
                onClick={this.handleClickDownload}
              >
                <SVGIcons type={"SVG-download"} /> Download As Excel
              </ButtonElement>
            </div>
          </div>
          <CustomTable
            scroll={{ x: 1000 }}
            className="no-space"
            dataSource={this.props.data || []}
            columns={this.fetchTableColums(
              constants.tableColumnPositiveOutLiers(this.tableProps())
            )}
            pagination={false}
            loading={this.props.loading}
          />
        </div>
      </>
    );
  }
}
