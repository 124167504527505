
export function exploreParticipationUpdateSelectedColumn(state, action) {
    if(action)
    {
    let { exploreParticipationSelectedColumns } = state;
    const index = (exploreParticipationSelectedColumns || []).findIndex(
      (i) => i === action
    );
    if (index > -1)
    exploreParticipationSelectedColumns =
    exploreParticipationSelectedColumns.filter((i) => i !== action);
    else exploreParticipationSelectedColumns.push(action);
    return {
      ...state,
      exploreParticipationSelectedColumns: [...exploreParticipationSelectedColumns],
    };
  }
  else
  {
    return {
        ...state,
        exploreParticipationSelectedColumns: [],
      };
  }
}

export function expolreDeselectParticipationColumns(state){
  let { exploreParticipationDataApi } = state;
  const {result} = exploreParticipationDataApi.data.aggregatedDemographicsResponse;
  let selectedColumns = Object.keys(result);
  return {
      ...state,
      exploreParticipationSelectedColumns: [...selectedColumns],
    };
}