import { fetchPayload } from "../../../utils/reducerOperations";
import * as helper from "./Helpers";
import {
  FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_FAIL,
  FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_START,
  FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_SUCCESS,
  SET_NEW_DASHBOARD_EXPLORE_DRIVER_DATA_FETCHED_FALSE,
  SET_NEW_DASHBOARD_EXPLORE_DRIVER_FILTERS,
  FETCH_EXPLORE_DIMENSIONS_STARTED,
  FETCH_EXPLORE_DIMENSIONS_SUCCESS,
  FETCH_EXPLORE_DIMENSIONS_FAILED,
  SET_EXPLORE_TOPIC
} from "../../constants";
import { InitialState } from "./InitialState";

const FetchExploreDriversDataReducer = (state = InitialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_START:
      return helper.fetchExploreDriversDataStart(state, payload);
    case FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_SUCCESS:
      return helper.fetchExploreDriversDataSuccess(state, payload);
    case FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_FAIL:
      return helper.fetchExploreDriversDataFailed(state, payload);
    case SET_NEW_DASHBOARD_EXPLORE_DRIVER_DATA_FETCHED_FALSE:
      return helper.setDataFetchedKeyasFalse(state, payload);
    case SET_NEW_DASHBOARD_EXPLORE_DRIVER_FILTERS:
      return helper.setDriverFilter(state, payload)
    case FETCH_EXPLORE_DIMENSIONS_STARTED:
      return helper.fetchDimensionsStarted(state, payload);
    case FETCH_EXPLORE_DIMENSIONS_SUCCESS:
      return helper.fetchDimensionsSuccess(state, payload);
    case FETCH_EXPLORE_DIMENSIONS_FAILED:
      return helper.fetchDimensionsFailed(state, payload)
    case SET_EXPLORE_TOPIC:
      return helper.setExploreTopic(state, payload)
    default:
      return { ...state };
  }
};

export default FetchExploreDriversDataReducer;
