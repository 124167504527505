import React from "react";


export const COMMENT_ANALYSIS = "Comment Analysis";
export const MENU_ITEMS = [
  { key: "Topics", label: "Topics" },
  { key: "Questions", label: "Questions" },
  { key: "TrendInsights", label: "Trend Insights" },
  { key: "Drivers", label: "Drivers" },
  { key: "Outliers", label: "Outliers" },
  { key: "Participation", label: "Participation" },
  { key: "Demographics", label: "Demographics" },
  { key: "Trend", label: "Trend", extra: <span className="beta-tag">BETA</span> }
];

export const DEFAULT_MENUS = ["Topics", "Questions", "Drivers", "Outliers", "Participation", "Demographics", "Trend","TrendInsights"]
export const SUBMENUTITLE = "Text Questions"
export const MULTI_SELECT_TITLE = "Others"