import { setInitialState } from "../../../utils/reducerOperations";

export const initialState = () => {
  return {
    participationSurveyDetailsApi: {
      isProcessing: false,
      error: false,
      reason: "",
      surveyDetails: [],
    },
    surveyParticipationDataApi: setInitialState(),
    surveyParticipationSelectedColumns: [],
    isInitialApiCalled: false,
    surveyDistributionDataApi: setInitialState(),
    selectedUser: "",
    directReporteesOnly: false,
    filters: {},
  };
};
