export const constants = {
  columns: [
    'Pay Rate Type',
    'Business Title',
    'Functional VP',
    'Job Family',
    'Job Family Group',
    'Country - Work',
    'Location',
    'Management Level',
    'Age Group',
    'Gender',
    'Ethnicity',
    'Average Score',
  ],
  selectedColumns: ['Average Score', 'Gender', 'Ethnicity'],
  scoreTypes: [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Favorable',
      value: 'favourable',
    },
    {
      label: 'Neutral',
      value: 'neutral'
    },
    {
      label: 'Unfavorable',
      value: 'unFavourable',
    },
  ],
  defaultScoreType: 'all'
};
