/*eslint-disable*/
export default{
    channelTypes:{
        anounymous:'anonymous',
        email:'email',
        qr:'qr'
    },
    fetchMode:{
        answer:'answer'
    },
    queryParams:{
        channelType:'SCT',
        userKey:'UK'
    },
    submitAnswerFailureTitle:'Failed',
    submitAnswerFailureMessage:'Sorry, We are facing a technical issue. Please try again later'
};
