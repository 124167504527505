export const exploreOverAllParticipationDataStart = (state) => {
  return {
    ...state,
    exploreOverAllParticipationDataApi: {
      data: null,
      isProcessing: true,
      error: null,
      message: null,
    },
  };
};

export const exploreOverAllParticipationDataSuccess = (state, payload) => {
  return {
    ...state,
    exploreOverAllParticipationDataApi: {
      isProcessing: false,
      data: payload.data.data,
      error: false,
      message: payload.message,
    },
  };
};

export const exploreOverAllParticipationDataFailed = (state, payload) => {
  return {
    ...state,
    exploreOverAllParticipationDataApi: {
      isProcessing: false,
      data: null,
      error: payload.error,
    },
  };
};
