const initialData=()=>({
    loading:true,
    error:null,
    data:null
});

export default{
    demographicTopicApi: initialData(),
    demographicTopics:null,
    selectedDemographicTopics:null,
    demographicTopicsData:null,
    demographicColumns:null,
    selectedDemographicColumns:{},
    selectedNumericDemographicsColumns:[],
    heatmapApi: initialData(),
    nlpHeatmapApi: initialData(),
    nlpHeatmapQuestionApi: initialData(),
    demographicSnapshotApi: initialData(),
    demographicSnapshotColumns: null,
    demographicSnapshotData: null,
    demographicSnapshotDataFields: null,
    heatmapData:{
        rows: null,
        cols: null,
        hiddenFromDragDrop: ['FavScore'],
        rendererName: 'Table Heatmap',
        aggregatorName: 'Integer Sum',
        vals: ['FavScore'],
        data:[]
    },
    addfilter:{},
    fetchQuestionIdApi:null,
};