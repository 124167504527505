import { apiInitialState } from "../../../utils/apiStateUtils";

export default {
  keyword: null,
  topicsApi: apiInitialState(),
  keywordApi: apiInitialState(),
  topicsRelationApi: apiInitialState(),
  topicsBarRepresentationApi: apiInitialState(),
  topicsWordCloudApi: apiInitialState(),
  currentTopicReport: null,
};
