import {
  HRIS_UPLOAD_FORM_CHANGE,
  CLEAR_HRIS_UPLOAD_FORM,
} from "../../constants";
export const hrisFileUploadFormChange = (event) => {
  return {
    type: HRIS_UPLOAD_FORM_CHANGE,
    payload: event,
  };
};

export const clearInviteManagersForm = () => {
  return {
    type: CLEAR_HRIS_UPLOAD_FORM,
  };
};
