import { SET_SELECTED_DELTA_VALUE, SET_SELECTED_THRESHOLD_VALUE } from "../../constants"

export const setDeltaRangeValue =(payload)=>{
    return(
        {
            type: SET_SELECTED_DELTA_VALUE,
            payload
        }
    )
  }

  export const setThresholdRangeValue = (payload) => {
    return (
      {
        type: SET_SELECTED_THRESHOLD_VALUE,
        payload,
      }
    )
  }
 
  