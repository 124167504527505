import React from 'react'
import { compareByAlpha } from "../../../../../../../../utils/compareByAlpha";
import SVGIcons from '../../../../../../../../Components/SVGIcons';
import parse from "html-react-parser";

export default {
  tableColumnDrivers: (props) => [
    {
      title: "Impact",
      key: "impact",
      width: 100,
      dataIndex: "impact",
      render: (str, record) => (
        <>
          {str
            ? str.length > 24
              ? str.slice(0, 24) + "..."
              : str.charAt(0).toUpperCase() + str.slice(1)
            : ""}
        </>
      ),
      defaultSortOrder: "descend",
      sorter: (a, b) => compareByAlpha(a.coeffRank, b.coeffRank),
    },
    {
      title: "Topics",
      key: "dimension",
      dataIndex: "dimension",
      width: 100,

      sorter: (a, b) => compareByAlpha(a.dimension, b.dimension),
    },
    {
      title: "Questions",
      dataIndex: "item",
      key: "item",
      sorter: (a, b) => compareByAlpha(a.item, b.item),
      render: (str, record) => (
        <>
          {/* <Tooltip placement="topLeft" title={str}> */}
          {parse(str)}
          {/* </Tooltip> */}
        </>
      ),
    },
    {
      title: "Score",
      key: "favScore",
      dataIndex: "favScore",
      render: (str, record) => (
        <>
          {getRoundedData(record.favScore)}
        </>
      ),
      sorter: (a, b) => getRoundedData(a.favScore)-getRoundedData(b.favScore),
    },
    {
      title: "Trend",
      dataIndex: "trend",
      key: "trend",
      render: (item, record) => (
        <div className="table-arrows">
          <span>
            {!isNaN(item) && item > 0 ? (
              <SVGIcons type={"SVG-thick-uparrow"} />
            ) : (
              ""
            )}
            {!isNaN(item) && item < 0 ? (
              <SVGIcons type={"SVG-thick-downarrow"} />
            ) : (
              ""
            )}{" "}
          </span>{" "}
          {isNaN(item) ? item : Math.abs(item)}
        </div>
      ),
    },
    // {
    //   title: "UnfavScore",
    //   key: "unFavScore",
    //   dataIndex: "unFavScore",
    //   render: (str, record) => (
    //     <>
    //       {getRoundedData(record.unFavScore)}
    //     </>
    //   ),
    //   sorter: (a, b) => compareByAlpha(a.favScore, b.favScore),
    // },
    // {
    //   title: "NeutralScore",
    //   key: "neutralScore",
    //   dataIndex: "neutralScore",
    //   render: (str, record) => (
    //     <>
    //       {getRoundedData(record.neutralScore)}
    //     </>
    //   ),
    //   sorter: (a, b) => compareByAlpha(a.favScore, b.favScore),
    // },
  ]
}

const getRoundedData = (number) => {
  return Math.round(number * 100)
}