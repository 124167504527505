import * as helper from "./helper";
import * as actionTypes from "../../constants";
import initialState from "./intialState";

const NLPStoryboardOverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_OVERVIEW_DATA_START:
      return helper.fetchOverviewStart(state, action);
    case actionTypes.FETCH_OVERVIEW_DATA_SUCCESS:
      return helper.fetchOverviewSuccess(state, action);
    case actionTypes.FETCH_OVERVIEW_DATA_FAIL:
      return helper.fetchOverviewFail(state, action);
    case actionTypes.FETCH_OVERVIEW_RESULT_DATA_START:
      return helper.fetchOverviewResultStart(state, action);
    case actionTypes.FETCH_OVERVIEW_RESULT_DATA_SUCCESS:
      return helper.fetchOverviewResultSuccess(state, action);
    case actionTypes.FETCH_OVERVIEW_RESULT_DATA_FAIL:
      return helper.fetchOverviewResultFail(state, action);
    default:
      return state;
  }
};

export default NLPStoryboardOverviewReducer;
