import {
  FETCH_STORYBOARD_STATUS_STARTED,
  FETCH_STORYBOARD_STATUS_SUCCESS,
  FETCH_STORYBOARD_STATUS_FAILED,
  STORYBOARD_STATUS_CHANGE,
  STORYBOARD_FILTER_CHANGE,
} from '../../constants';
import {
  STORYBOARD_FILTER_STATUS,
  GET_STORYBOARD_ELEMENTS,
} from '../../../../config/constants';
import { ApiBase } from '../../../utils/apiBase';
import { withType } from '../../../utils/actionOperations';
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const applyFilterOnStoryboard = (payload, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    apiBaseInstance.instance
      .post(GET_STORYBOARD_ELEMENTS, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        dispatchFilter(dispatch, {
          filters: payload.filters,
          byScore: payload.byScore,
          multipleSelect: payload.multipleSelect
        });
        cb && cb(true);
      })
      .catch((err) => {
        dispatchFilter(dispatch, {
          filters: payload.filters,
          byScore: payload.byScore,
          multipleSelect: payload.multipleSelect
        });
        cb && cb(false);
      });
  };
};

const dispatchFilter = (dispatch, result) => {
  dispatch(
    withType(STORYBOARD_FILTER_CHANGE, {
      data: result,
    })
  );
};

export const fetchStoryboardStatus = (payload, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(FETCH_STORYBOARD_STATUS_STARTED, {}));
    apiBaseInstance.instance
      .post(STORYBOARD_FILTER_STATUS, {...payload,...getProjectIdAndOrgId(payload)})
      .then((response) => {
        let result =
          (response.data &&
            response.data.data &&
            response.data.data.response) ||
          {};
        let data = (result.storyboardStatusData &&
          result.storyboardStatusData._source) || {
          topStoryFilterStatus: '',
          filters: {},
          byScore: [],
          multipleSelect: [],
          directReporteesOnly: false,
          selectedUser: '',
        };
        if (data.selectedUser === undefined) data.selectedUser = '';
        cb && cb(data);
        dispatch(
          withType(FETCH_STORYBOARD_STATUS_SUCCESS, {
            data: result,
          })
        );
      })
      .catch((err) => {
        dispatch(
          withType(FETCH_STORYBOARD_STATUS_FAILED, {
            error: err,
          })
        );
      });
  };
};

export const clearStoryboardStatus = (payload, cb) => {
  return { type: STORYBOARD_STATUS_CHANGE };
};
