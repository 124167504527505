import {
  SURVEY_WIZARD_FETCH_SURVEY_LIST_START,
  SURVEY_WIZARD_FETCH_SURVEY_LIST_SUCCESS,
  SURVEY_WIZARD_FETCH_SURVEY_LIST_FAIL,
  FETCH_COHORT_CHILD_REMAP
} from "../../../constants";
import { LIST_SURVEY_URL } from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";

export const fetchSurveyWizardSurveyList = (params, cb) => {
  const axios = new ApiBase().instance;
  return (dispatch) => {
    dispatch({ type: SURVEY_WIZARD_FETCH_SURVEY_LIST_START });
    axios
      .post(LIST_SURVEY_URL, {
        ...params
      })
      .then((res) => {
        dispatch({
          type: SURVEY_WIZARD_FETCH_SURVEY_LIST_SUCCESS,
          payload: { data: res && res.data && res.data.data },
        });
        cb && setTimeout(() => { cb(); }, 100);
      })
      .catch((err) =>
        dispatch({
          type: SURVEY_WIZARD_FETCH_SURVEY_LIST_FAIL,
          payload: { error: err && err.message },
        })
      );
  };
};


export const fetchCohortChildListRemap = (data) => {
return (dispatch) => {
    dispatch({ type: FETCH_COHORT_CHILD_REMAP ,data});
  }
}
