import React, { Component } from 'react'
import { TreeSelect } from 'antd'
import { toast } from '../../../../../../../../utils/messageUtils';
import { getSelectedMenus } from '../../helpers';

export default class TreeSelectValue extends Component {
    constructor(props) {
        super(props);


    }

    onChange = (data, values) => {
        if (data.length < 1) {
            toast.error("Atleast 1 selection needed")
        } else {
            this.props.onChange(data, this.props.topicSelection)
        }
    }

    callApi = (topics, questions) => {
        this.props.callAPI(topics, questions)
    }

    render() {
        return (
            <div>
                <TreeSelect
                    {...this.props.advancedProps}
                    showSearch
                    style={{ width: '100%' }}
                    value={this.props.selectedData}
                    multiple
                    onChange={this.onChange}
                    treeData={this.props.data}
                    className="trendnew-select"
                />
            </div>
        )
    }
}
