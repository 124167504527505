import { connect } from "react-redux";
import FeedBackRanking from "./FeedBackRanking";
import { behaviourDragUpdateAction, handleDragEndPriority } from "../../../../../../redux/actions";
import { handleBehaviourDragDelete } from "../../../../../../redux/actions/SurveyAnsweringPageAction/Helper/behaviorDragDelete";


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        behaviourDragUpdate: (payload) => dispatch(behaviourDragUpdateAction(payload)),
        handleDragEndPriority: (payload) => dispatch(handleDragEndPriority(payload)),
        handleBehaviourDragDelete: (payload) => dispatch(handleBehaviourDragDelete(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedBackRanking);