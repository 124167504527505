import Axios from 'axios';

export const uploadImage = (params, next) => {
  let buffered
  if (params.fileType === "image/svg+xml") {
    buffered = Buffer.from(params.base64Url.toString().replace(/^data:image\/[a-zA-Z+]+;base64,/, ""), 'base64')
  }
  else {
    buffered = Buffer.from(params.base64Url.toString().replace(/^data:image\/\w+;base64,/, ""), "base64");
  }

  return async (dispatch) => {
    Axios({
      method: 'put',
      headers: { 'Content-Type': params.fileType },
      url: params.url,
      data: buffered,
    })
      .then((response) => {
        next(true, response);
      })
      .catch((err) => {
        next(false);
      });
  };
};

