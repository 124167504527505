import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const futureWorkTemplate = [
    {
        questionDimension: 'Future of work',
        type: 'Matrix',
        questionLabel: 'Future of work',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: 'I am at least as productive when working from home as when working in an office.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I am at least as productive when working from home as when working in an office.'),
        },
        {
            text: 'I have the technology I need to work remotely.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I have the technology I need to work remotely.'),

        },
        {
            text: 'My organization can succeed if workers like me rarely go to a physical office.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My organization can succeed if workers like me rarely go to a physical office.'),

        },
        {
            text: 'We have a clear work-from-home policy.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('We have a clear work-from-home policy.'),

        }]
    }
]
