import { connect } from 'react-redux';
import MultiSelect from './MultiSelect';
import { fetchMultiQuestionsDiagramData } from '../../../../../../redux/actions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        ...state.MultiSelectQuestionsReducer,
        MultiQuestionFilters: state.MultiSelectQuestionsReducer.filters,
        MultiQuestionbyScore: state.MultiSelectQuestionsReducer.byScore,
        MultiQuestionMultipleSelect: state.MultiSelectQuestionsReducer.multipleSelect,
        MultiQuestionSelectedUser: state.MultiSelectQuestionsReducer.selectedUser,
        MultiQuestionDirectReportees: state.MultiSelectQuestionsReducer.directReporteesOnly,
        ...state.DashBoardReducer,
        thresholdApi: state.DashBoardReducer.thresholdApi.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMultiQuestionsDiagramData: (params, callBack) => dispatch(fetchMultiQuestionsDiagramData(params, callBack)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiSelect));
