import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const fetchExploreDriversDataStart = (state) => {
    return {
        ...state,
        fetchExploreDriversDataApi: getApiStartState()
    }

}

export const fetchExploreDriversDataSuccess = (state, payload) => {
    const data = payload.data && payload.data.data && payload.data.data.response
        && payload.data.data.response.driverSummary && payload.data.data.response.driverSummary
    const strengthAndWeakness = removeZeroInStrengthAndHundredInWeakness({ ...data })
    return {
        ...state,
        fetchExploreDriversDataApi: {
            ...getSuccessResponse(payload.data),
            strengthAndWeakness: strengthAndWeakness
        },
        isDataAlreadyFetched: true,
        byScore:payload.byScore,
        multipleSelect: payload.multipleSelect,
        filters: payload.filters,
        selectedUser: payload.selectedUser,
        directReporteesOnly: payload.directReporteesOnly
    }

}

export const fetchExploreDriversDataFailed = (state, payload) => {
    return {
        ...state,
        fetchExploreDriversDataApi: {
            isProcessing: false,
            data: null,
            error: payload.data&&payload.data.error
        }
    }

}

export const setDataFetchedKeyasFalse = (state, payload) => {
    return {
        ...state,
        isDataAlreadyFetched: false
    }
}

export const setDriverFilter = (state, payload) => {
    return {
        ...state,
        filters: payload
    }
}

export const setExploreTopic = (state, payload) => {
    return {
        ...state,
        ...payload
    }
}

const removeZeroInStrengthAndHundredInWeakness = (strengthAndWeakness) => {
    let strengths = []
    let weaknesses = []
    if (strengthAndWeakness.strengths && strengthAndWeakness.strengths.length > 0) {
        strengths = strengthAndWeakness.strengths.filter(item => Math.round(item.favScore * 100) !== 0)
    }

    if (strengthAndWeakness.weaknesses && strengthAndWeakness.weaknesses.length > 0) {
        weaknesses = strengthAndWeakness.weaknesses.filter(item => Math.round(item.favScore * 100) !== 100)
    }

    if (strengths.length > 0 && weaknesses.length > 0) {
        strengths = removeSameFavScoreAsWeaknessesAndBelowFiftyDataFromStrengths(strengths, weaknesses)
        weaknesses = removeSameFavScoreAsStrengthAndAboveFiftyDataFromWeaknesses(strengths, weaknesses)
    }
    return {
        strengths: strengths,
        weaknesses: weaknesses
    }
}

const removeSameFavScoreAsWeaknessesAndBelowFiftyDataFromStrengths = (strengths, weaknesses) => {
    let filterAppliedStrengths = []
    for (const data in weaknesses) {
        filterAppliedStrengths = strengths.filter(item => {
            let weaknessScore = Math.round(data.favScore * 100)
            let strengthsScore = Math.round(item.favScore * 100)
            if (weaknessScore === strengthsScore && strengthsScore < 50) {
                return false
            } else {
                return true
            }
        })
    }
    return filterAppliedStrengths
}

const removeSameFavScoreAsStrengthAndAboveFiftyDataFromWeaknesses = (strengths, weaknesses) => {
    let filterAppliedWeaknesses = []
    for (const data in strengths) {
        filterAppliedWeaknesses = weaknesses.filter(item => {
            let weaknessScore = Math.round(item.favScore * 100)
            let strengthsScore = Math.round(data.favScore * 100)
            if (weaknessScore === strengthsScore && strengthsScore > 50) {
                return false
            } else {
                return true
            }
        })
    }
    return filterAppliedWeaknesses
}

