import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const fetchExploreOutliersDataStart = (state) => {
    return {
        ...state,
        fetchExploreOutliersDataApi: getApiStartState()
    }

}

export const fetchExploreOutliersDataSuccess = (state, payload) => {
    return {
        ...state,
        fetchExploreOutliersDataApi: getSuccessResponse(payload.data),
        isOutliersDataAlreadyFetched:true,
        filters: payload.filters || {},
        selectedUser : payload.selectedUser,
        directReporteesOnly: payload.directReporteesOnly
    }

}

export const fetchExploreOutliersDataFailed = (state, payload) => {
    return {
        ...state,
        fetchExploreOutliersDataApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}

export const setOutliersDataFetchedKeyasFalse = (state,payload) =>{
    return{
        ...state,
        isOutliersDataAlreadyFetched:false
    }
}

export const setOutliersFilter=(state,payload)=>{
    return{
        ...state,
        filters:payload
    }
}