import * as actionTypes from '../../constants';
import { Default } from './initialState';
import helper from './helper';
import { translateFailed, translateStart, translateSuccess } from './helper/fetchSurvey';

function AttendSurveyReducer(state = Default(), action) {
    switch (action.type) {
        case actionTypes.GET_ATTENT_SURVEY_DATA_START:
            return helper.fetchProjectStart(state);
        case actionTypes.GET_ATTENT_SURVEY_DATA_SUCCESS:
            return helper.fetchProjectSuccess(state, action);
        case actionTypes.GET_ATTENT_SURVEY_DATA_FAIL:
            return helper.fetchProjectFail(state, action);
        case actionTypes.ATTENT_SURVEY_UPDATE_ANSWER:
            return updateAnswer(state, action);
        case actionTypes.UPDATE_SURVEY_PROCESS_LIST:
            return updateSurveyProcessList(state, action);
        case actionTypes.CHANGE_SURVEY_LANGUAGE:
            return helper.changeLanguage(state, action)
        case actionTypes.TRANSLATE_START:
            return translateStart(state, action)
        case actionTypes.TRANSLATE_SUCCESS:
            return translateSuccess(state, action)
        case actionTypes.TRANSLATE_FAIL:
            return translateFailed(state, action)
        default: return submitSurvey(state, action);
    }
};

function submitSurvey(state, action) {
    switch (action.type) {
        case actionTypes.SUBMIT_ANSWERSET_START:
            return helper.submitAnswerStart(state);
        case actionTypes.SUBMIT_ANSWERSET_SUCCESS:
            return helper.submitAnswerSuccess(state, action);
        case actionTypes.SUBMIT_ANSWERSET_FAIL:
            return helper.submitAnswerFail(state, action);
        default: return { ...state };
    }

}

function updateAnswer(state, { newQuestionSet }) {
    return {
        ...state,
        fetchProjectApi: {
            ...state.fetchProjectApi,
            data: {
                ...state.fetchProjectApi.data,
                questionSet: newQuestionSet
            }
        }
    };
};

function updateSurveyProcessList(state, { index }) {
    return {
        ...state,
        processList: [...state.processList, index]
    };
}

export default AttendSurveyReducer;