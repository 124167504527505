import {
    update,
    getSuccessResponse
} from '../../../../utils/reducerOperations';
export function parseCsvSuccess(state, payload) {
    return {
        parseCsvApi: update(
            state.parseCsvApi,
            getSuccessResponse(payload)
        )
    };
}
