import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const sendPreviewMailStarted = (state) => {
    return {
        ...state,
        sendPreviewMailApi: getApiStartState()
    }

}

export const sendPreviewMailSuccess = (state, payload) => {
    return {
        ...state,
        sendPreviewMailApi: getSuccessResponse(payload)
    }

}

export const sendPreviewMailFailed = (state, payload) => {
    return {
        ...state,
        sendPreviewMailApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}