import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const empowermentTemplate = [
    {
        questionDimension: 'Empowerment',
        type: 'Matrix',
        questionLabel: 'Empowerment',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: 'I can make an impact at my organization.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I can make an impact at my organization.'),
        },
        {
            text: 'I am involved in making decisions that affect me and my work.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I am involved in making decisions that affect me and my work.'),

        },
        {
            text: 'I have everything I need to do my job effectively.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I have everything I need to do my job effectively.'),

        },
        {
            text: `I know how my work contributes to my organization's goals.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`I know how my work contributes to my organization's goals.`),

        }
        ]
    }
]
