import { fetchPayload } from "../../../utils/reducerOperations";
import {
  FETCH_NEWDASHBOARD_BATCH_QUESTIONS_START,
  FETCH_NEWDASHBOARD_BATCH_QUESTIONS_SUCCESS,
  FETCH_NEWDASHBOARD_BATCH_QUESTIONS_FAILED,
  SET_NEWDAHSBOARD_COMMENT_QUESTION_INITIAL_STATE_SUCCESS
} from "../../constants";
import {
  fetchBatchQuestionStart,
  fetchBatchQuestionSuccess,
  fetchBatchQuestionFailed,
  setExploreInitialstateSuccess
} from "./Helper";
import { InitialState } from "./InitialState";

const getBatchQuestionsIdReducer = (state = InitialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_NEWDASHBOARD_BATCH_QUESTIONS_START:
      return fetchBatchQuestionStart(state, payload);
    case FETCH_NEWDASHBOARD_BATCH_QUESTIONS_SUCCESS:
      return fetchBatchQuestionSuccess(state, payload);
    case FETCH_NEWDASHBOARD_BATCH_QUESTIONS_FAILED:
      return fetchBatchQuestionFailed(state, payload);
    case SET_NEWDAHSBOARD_COMMENT_QUESTION_INITIAL_STATE_SUCCESS:
        return setExploreInitialstateSuccess(state, payload);
    default:
      return { ...state };
  }
};

export default getBatchQuestionsIdReducer;
