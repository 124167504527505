import { deepCopy } from "../../../../utils/deepCopy"


export const handleChangeBenchmarkNorms = (state, payload) => {
    let normDataCopy = deepCopy(state.fetchBenchmarkDetails.data.normScoreData)
    let mainIndex = normDataCopy.findIndex(item => item.categoryId === payload.record.categoryId)
    let subIndex = normDataCopy[mainIndex].subCategory.findIndex(item => item.subCategoryId === payload.record.subCategoryId)
    if (mainIndex !== -1 && subIndex !== -1) {
        normDataCopy[mainIndex].subCategory[subIndex].value = payload.upatedValue

    }
    return {
        ...state,
        fetchBenchmarkDetails: {
            ...state.fetchBenchmarkDetails,
            data: { ...state.fetchBenchmarkDetails.data, normScoreData: normDataCopy }
        }
    }
}
