import {
  FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_FAIL,
  FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_SUCCESS,
  FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_START,
  } from "../../../constants";
  import { GET_TREND_INSIGHTS_DATA, } from "../../../../../config/constants";
  import { ApiBase } from "../../../../utils/apiBase";
  import { withType } from "../../../../utils/actionOperations";
  import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';
  
  export const fetchNewDashboardTrendInsightsData = (payload) => {
    const apiBaseInstance = new ApiBase().instance;
    return (dispatch) => {
      dispatch(withType(FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_START, {}));
      return apiBaseInstance
        .post(GET_TREND_INSIGHTS_DATA, {
          ...payload, ...getProjectIdAndOrgId(payload)
        })
        .then((res) => {
          dispatch(
            withType(FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_SUCCESS, {
              data: res.data,
              filters: payload.filters,
              selectedUser: payload.selectedUser,
              directReporteesOnly: payload.directReporteesOnly,
            })
          );
        })
        .catch((err) => {
          return dispatch(
            withType(FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_FAIL, err)
          );
        });
    };
  };
  