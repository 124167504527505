import React from "react";
import { Modal } from "antd";
import { ButtonElement } from "../../FormElements/ButtonElement";
import { LoaderButton } from "../../FormElements/LoaderButton";
import ScrollBar from "../../Packages/ScrollBar";
export default function (props) {
  return (
    <Modal
      cancel={props.onCancel}
      className={props.className}
      footer={
        props.footer
          ? props.footer
          : [
            props.onCancel ? (
              <ButtonElement
                onClick={props.onCancel ? props.onCancel : () => { }}
                children={props.cancelText || "Cancel"}
                styleProps={
                  props.cancelButtonStyleProps
                    ? props.cancelButtonStyleProps
                    : null
                }
              />
            ) : (
              ""
            ),
            props.onOk ? (
              <LoaderButton
                data-test="login-btn"
                loader={
                  props.okButtonProps ? props.okButtonProps.loading : null
                }
                disabled={
                  props.okButtonProps ? props.okButtonProps.disabled : null
                }
                onClick={props.onOk}
                classList={["loader-button"]}
                loaderClass={["xs white"]}
                text={props.okText}
              />
            ) : (
              ""
            ),
          ]
      }
      {...props}
    >
      {!props.commentModal ?
        <ScrollBar className="model-content-scroll">
          {props.children}
        </ScrollBar> : props.children}
    </Modal>
  );
}
