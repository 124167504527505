import React from "react";
import { ButtonElement } from "../../FormElements/ButtonElement";
import moment from "moment";
import helpers from "./helpers";
import { SpinLoader } from "../../Components/SpinLoader";
import { DotMenu } from "../../Components/DotMenu";
import { checkIfAdmin, checkIfSuperAdminOrOrgAdmin } from '../../utils/rolePermissionUtils';
import { getOrgIdFromUrl } from '../../utils/urlProcessor';
import { Tooltip } from "antd";

export default {
  warning: "",
  error: "error",
  emptyMessage: "No data found",
  emptySpace: " ",

  ROOT_MANAGER_EMAIL_ID: "rootmanager@test.com",
  INVITE_MODAL_HEADER: "Invite all managers",
  INFO: "Upload HRIS File",
  EXISTING_USER_PLACEHOLDER: "Choose existing user template",
  EXISTING_USER_LABEL: "New User Email Template",
  NEW_USER_LABEL: "Existing User Email Template",
  NEW_USER_PLACEHOLDER: "Choose new user template",
  ADD_USER: " Add User",
  INVITE_MANAGERS: "Invite Managers",
  DELETE_USERS: "Delete Users",
  USERS: "users",
  INVITE: "Invite",
  UPLOAD_SUCCESS_MESSAGE: "Hris file uploaded successfully",
  UPLOAD_ERROR_MESSAGE: "Hris file upload failed",
  DELETE_HEADER:"Delete HRIS File",
  renameProjectModal: {
    title: "Rename project",
    placeholder: "Project Name",
  },
  defaultState: {
    pageSize: 10,
    projectId: undefined,
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
  sortPeople: {
    ascend: "asc",
    descend: "desc",
    keyword: ".keyword",
    defaultSortField: "employeeName",
    defaulSortKey: "employeeName.keyword",
    defaultSortOrder: "descend",
    asc: "ascend",
  },
  sortHRIS: {
    ascend: "asc",
    descend: "desc",
    keyword: ".keyword",
    defaulSortKey: "uploadDate",
    defaultSortOrder: "desc",
    asc: "asc",
    desc: "desc",
  },
  tableColumn: (props, cb) => [ 
    {
      title: <div> Name</div>,
      key: "hrisFileName",
      dataIndex: "hrisFileName",
      width: 200,
      render: (str, record) => (
        <>
          {record.bulkJobStatus === "Processing" ||
            record.bulkJobStatus === "Queued" ? (
            <>
              {" "}
              <span class="disable-text break-word">
                <SpinLoader />
                {str}
              </span>{" "}
            </>
          ) : record.bulkJobStatus !== "Completed" ? (
            <span class="disable-text break-word ">{str}</span>
          ) : (
            <span className="break-word">{str}</span>
          )}
          <span className="people-label">{record.uploadedFileName}</span>
        </>
      ),
      sorter: true, //(a, b) => compareByAlpha(a.hrisFileName, b.hrisFileName),
    },
    {
      title: "Created",
      key: "uploadDate",
      dataIndex: "uploadDate",
      showSorterTooltip: false,
      width: 180,
      defaultSortOrder: "descend",
      render: (str, record) => {
        if (str) {
          var year = new Date(str).getFullYear();
          return record.bulkJobStatus !== "Completed" ? (
            <span class="disable-text">
              {year === new Date().getFullYear()
                ? moment(str).format("MMM DD")
                : moment(str).format("MMM DD YYYY")}
            </span>
          ) : year === new Date().getFullYear() ? (
            moment(str).format("MMM DD")
          ) : (
            moment(str).format("MMM DD YYYY")
          );
        } else return str;
      },
      sorter: true, //(a, b) => compareByAlpha(b.uploadDate, a.uploadDate),
    },

    {
      title: "Employees",
      key: "employeeCount",
      dataIndex: "employeeCount",
      showSorterTooltip: false,
      width: 100,
      render: (str, record) => (
        <>
          {record.bulkJobStatus === "Failed" ? (
            <Tooltip title = {record.failureReason}>
            <span className="status-text">{record.bulkJobStatus}</span>
            </Tooltip>
          ) :record.bulkJobStatus === "Queued"?(<span className="people-table-cell">{record.bulkJobStatus}</span>): str ? (
            <span className="people-table-cell">{str}</span>
          ) : (
            <span className="people-table-cell">{record.usersCount}</span>
          )}
        </>
      ),
      sorter: true, //(a, b) => compareByAlpha(b.usersCount, a.usersCount),
    },
    {
      dataIndex: "",
      title: "",
      key: "",
      width: 50,
      align: "center",
      render: (doc) => (
        <ButtonElement
          className="switch-btn"
          onClick={(e) => {
            helpers.getHRISViewClick(doc, props);
          }}
          disabled={doc.bulkJobStatus !== "Completed"}
          children="View"
        />
      ),
    },
    {
      dataIndex: "",
      title: "",
      key: "",
      width: 50,
      align: "center",
      render: (_, doc) => {
        let organizationId = getOrgIdFromUrl();
        return (<>{checkIfAdmin() || checkIfSuperAdminOrOrgAdmin(organizationId) ? (<DotMenu itemList={menuList(doc, props, cb)} />) : ""}</>)
      },
    },
  ],

  tableColumnPeople: (props, cb) => [
    {
      title: "Name",
      key: "employeeName",
      dataIndex: "employeeName",
      width: 100,
      defaultSortOrder: "ascend",
      sorter: true, //(a, b) => compareByAlpha(a.employeeName, b.employeeName),
      render: (text) =>text && text.charAt(0).toUpperCase() + text.slice(1),
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      showSorterTooltip: false,
      width: 100,
      sorter: true, //(a, b) => compareByAlpha(a.email, b.email),
    },
  ],
  tableColumnForFeedback360: (props, cb) => [
    {
      title: "Name",
      key: "employeeName",
      dataIndex: "employeeName",
      width: 100,
      defaultSortOrder: "ascend",
      sorter: true, //(a, b) => compareByAlpha(a.employeeName, b.employeeName),
      render: (text) =>text && text.charAt(0).toUpperCase() + text.slice(1),
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      showSorterTooltip: false,
      width: 100,
      sorter: true, //(a, b) => compareByAlpha(a.email, b.email),
    },
    {
      title: "Leader",
      key: "selfName",
      dataIndex: "selfName",
      showSorterTooltip: false,
      width: 100,
      sorter: true,
      render: (text) =>text && text.charAt(0).toUpperCase() + text.slice(1),
    },
    {
      title: "Relation",
      key: "relation",
      dataIndex: "relation",
      showSorterTooltip: false,
      width: 100,
      sorter: true,
      render: (text) =>text && text.charAt(0).toUpperCase() + text.slice(1),
    },
  ]
};





const menuList = (value, props, cb) => {
  const menuList = [
    {
      label: "Rename",
      onMenuItemClick: () => renamePeople(value, cb),
      value: value,
      props: props,

    },
    {
      label: "Delete",
      onMenuItemClick: () => deletePeople(value, cb),
      value: value,
      props: props,
      disable: value.isHrisAttached ? value.isHrisAttached : false,
      disableReason: "Hris file is already attached to a project"
    }
  ];
  return menuList;
}
const renamePeople = (value, cb) => {
  cb("renamePeople", value);
};
const deletePeople = (value, cb) => {
  cb("deletePeople", value);
};