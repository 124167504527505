import {
    FETCH_MANAGER_HEATMAP_HEADERS_START,
    FETCH_MANAGER_HEATMAP_HEADERS_SUCCESS,
    FETCH_MANAGER_HEATMAP_HEADERS_FAILED
} from '../../../constants';
import { DASHBOARD_FILTER_URL } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { withType } from '../../../../utils/actionOperations';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchManagerHeatmapHeaders = (payload, callback) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(FETCH_MANAGER_HEATMAP_HEADERS_START, {}));
        apiBaseInstance.instance
            .post(DASHBOARD_FILTER_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => formatFiltersToHeaderList(dispatch, response.data, callback))
            .catch(err => {
                dispatch(withType(FETCH_MANAGER_HEATMAP_HEADERS_FAILED, {
                    error: err
                }));
            });
    };
};

const formatFiltersToHeaderList = (dispatch, data, callback) => {
    let heatmapHeaders = [];
    if (data && data.data && data.data.filters) {
        heatmapHeaders = Object.keys(data.data.filters)
        heatmapHeaders = heatmapHeaders.sort()
    }
    dispatch(withType(FETCH_MANAGER_HEATMAP_HEADERS_SUCCESS, {
        data: heatmapHeaders,
    }));
    callback && callback(heatmapHeaders)
}
