import { notification } from 'antd';
import { DELETE_VIEWERS_URL } from '../../../../../config/constants';
import constants from './constants';
import { ApiBase } from "../../../../utils/apiBase";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const NewdashboardDeleteViewers = (payload, callback) => {
    const apiBaseInstance = new ApiBase().instance;
    return dispatch => {
        apiBaseInstance.post(DELETE_VIEWERS_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(res => deleteViewerSuccess(dispatch, res, callback))
            .catch(err => deleteViewerFailed(dispatch, err));
    };
};

function deleteViewerSuccess(dispatch, res, callback) {
    notification.success({ message: constants.deleteViewersSuccessMsg });
    callback && callback()
}

function deleteViewerFailed(dispatch, err) {
    notification.error({ message: constants.deleteViewersFailedMsg });
    return dispatch({ type: '' });
}