import { fetchFormConfigurations } from "../../inputFormConfigurations";
import { setInitialState } from "../../../utils/reducerOperations";
export function Default() {
  return {
    addOrganizationFormValid: false,
    isFormValid: false,
    addOrganizationApi: setInitialState(),
    fetchOrganizationApi: setInitialState(),
    deleteOrganizationApi: setInitialState(),
    inputBoxConfiguration: fetchFormConfigurations(["organizationName"]),
    renameOrgApi: setInitialState(),
  };
}
