import React from "react";
import { StoryBoardNewBarChart } from "../../../../../../../../Components/StoryBoardNewBarChart";
import { constants } from '../../constants';

export function StoryboardParticipationTopicData(props) {
  return Object.keys(props.data || {})
    .filter((item) => item !== "Average Score")
    .sort((a, b) => {
      if (a === 'Manager' || b === 'Manager') return -1
      else return 0
    })
    .map(renderGraph(props.data, props));
}
const getGraphData = (api, props) => {
  let multiplier = 1
  if (props.getHighestPercentage && props.getHighestPercentage > 80) {
    multiplier = 100
  } else {
    multiplier = 75
  }

  let histogramData = api.data.histogramData;
  let graphData = histogramData.map((item) => ({
    label: item.node,
    subLabel: `${item.completed} of ${item.total} Complete`,
    value: `${Math.round(item.percentage)}%`,
    width: Math.round((item.percentage / props.getHighestPercentage) * multiplier),
    threshold: item.completed,
    percentage: item.percentage
  }));
  graphData.sort(((first, second) => customSort(first, second, props)))
  return graphData;
};


function customSort(first, second, props) {
  if (first.threshold > props.threshold && second.threshold > props.threshold) {
    if (first.percentage < second.percentage) {
      return 1; 
    } else if (first.percentage > second.percentage) {
      return -1;
    } else {
      return 0;
    }
  } else if (first.threshold <= props.threshold && second.threshold <= props.threshold) {
    return 0;
  } else if (first.threshold > props.threshold && second.threshold <= props.threshold) {
    return -1;
  } else {
    return 1;
  }
}

const renderGraph = (data, props) => (itemName, key) => {
  const { api } = makeApiData(props.data[itemName], itemName, props);
  return (
    <div className="div-barchart" key={key}>
      <h2>

        {itemName !== "Average Score" ? constants.titlePrefix : ""} {itemName}
        {itemName !== "Average Score" ?
          (Object.keys(data).length > 1 ? (
            <div
              onClick={() =>
                props.onRemoveItem && props.onRemoveItem(itemName)
              }
            >
              <p>remove</p>
            </div>) : ""
          ) : (
            ""
          )}
      </h2>
      <StoryBoardNewBarChart graphCursorClass={'progress-no-cursor'} data={getGraphData(api, props)} hideBackground={true} threshold={props.threshold} />
    </div>
  );
};

function makeApiData(data, key, props) {
  return {
    api: {
      data: {
        histogramData: data.histogramData,
      },
    },
  };
}



