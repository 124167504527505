import { ROLE_PERMISSION_SECRET } from "../../../../../config/userConfigurations";
import { deepCopy } from "../../../../utils/deepCopy";
import { encryptData } from "../../../../utils/encryptionUtils";
import { SetLocalStorage } from "../../../../utils/localStorgeOperations";
import {
  makeKey,
  update,
  getSuccessResponse,
} from "../../../../utils/reducerOperations";

export function getUserProfileSuccess(state, payload) {
  SetLocalStorage(encryptPermissions(payload.data));
  localStorage.setItem("isUserProfileSuccess",true)
  return makeKey(
    "getUserProfileApi",
    update(state.getUserProfileApi,getSuccessResponse(payload))
  );
}

const encryptPermissions = (data) => {
  var copiedData = deepCopy(data)
  const key = ROLE_PERMISSION_SECRET;
  copiedData.userRolePermissions = encryptData(
    JSON.stringify(copiedData.userRolePermissions), key)
  return copiedData
}