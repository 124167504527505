import {
    SET_PAGE_NUMBER_FOR_SURVEY_PAGE
} from "../../../constants";


export const setPageNumberForSurveyPage = (payload, cb) => {
    return (dispatch) => {
        dispatch({
            type: SET_PAGE_NUMBER_FOR_SURVEY_PAGE,
            pageNumber: payload
        });
    }
};