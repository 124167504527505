import { notification } from 'antd';
import { DELETE_RESPONSE_URL } from '../../../../../config/constants';
import constants from './constants';
import { ApiBase } from "../../../../utils/apiBase";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const NewdashboardDeleteSurveyResponse = (params = {}, callback) => {
    const apiBaseInstance = new ApiBase().instance;
    return dispatch => {
        apiBaseInstance.post(DELETE_RESPONSE_URL, { ...params, ...getProjectIdAndOrgId(params) })
            .then(res => deleteBatchIdSuccess(dispatch, res, callback))
            .catch(err => deleteBatchIdFailed(dispatch, err));
    };
};

function deleteBatchIdSuccess(dispatch, res, callback) {
    notification.success({ message: constants.deleteUserResponseSuccessMsg });
    callback && callback();
}

function deleteBatchIdFailed(dispatch, err) {
    notification.error({ message: constants.deleteUserResponseFailedMsg });
    return dispatch({ type: '' });
}