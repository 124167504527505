import { DASHBOARD_TABS } from '../../../../../config/tabConstants';

export const getProjectDataForApiCall = (projectDetails) => {
    return {
      currentTab: DASHBOARD_TABS.HEATMAP,
        projectId: projectDetails.projectId,
        projectType:projectDetails.projectType,
        organizationId: projectDetails.organizationId,
        projectOrigin: projectDetails.projectOrigin,
        selectedQuestionId: false,
        hrisFileId: projectDetails.hrisFileId,
        directReporteesOnly: false,
        selectedUserName: "",
        isAnonymous: projectDetails.isAnonymous,
        outcomeVariable: projectDetails.outcomeVariable,
        filterCountLimit: 1,
        filters: {}
    }
}

export const SCORE_TYPES = {
    scoreTypes: [
      {
        label: 'Favorable',
        value: 'favourable',
      },
      {
        label: 'Neutral',
        value: 'neutral',
      },
      {
        label: 'Unfavorable',
        value: 'unFavourable',
      },
    ],
    defaultScoreType: 'favourable',
  };

export const HEATMAP_TYPE = {
  OVERALL: 'Overall Heatmap',
  MANAGER: 'Heatmap for Manager'
}