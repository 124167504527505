import { deepCopy } from "../../../utils/deepCopy";
import { fetchFormConfigurations } from "../../inputFormConfigurations";

export function settingNameAndEmailFormConfiguration(props) {
  for (let i = 0; i < props.length; i++) {
    let value = localStorage.getItem(props[i].name !== "email" ? props[i].name : "username") !== "null" ? localStorage.getItem(props[i].name !== "email" ? props[i].name : "username") : "";
    props[i].value = value;
  }

  return props;
}

export const Default = () => ({
  nameAndEmailFormConfiguration: settingNameAndEmailFormConfiguration(deepCopy(fetchFormConfigurations([
    "firstName",
    "lastName",
    "email",
  ]))),
});

export default { settingNameAndEmailFormConfiguration, Default }