import {
    makeKey,
    update,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
export function getOrganizationProfileFailed(state, payload) {
    if (payload.data && payload.data.response)
        return makeKey(
            'getOrganizationProfileApi',
            update(
                state.getOrganizationProfileApi,
                {...handleApiResponseError(payload.response.data),isDataFetched: true}
            )
        );
    else
        return makeKey(
            'getOrganizationProfileApi',
            update(
                state.getOrganizationProfileApi,
                {...handleNetworkError(payload),isDataFetched: true}
            )
        );
}
