import * as actionTypes from "../../constants";
import * as helper from "./helper";
import initialState from "./intialState";

const sentimentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SENTIMENT_START:
      return helper.fetchSentimentStart(state, action);
    case actionTypes.FETCH_SENTIMENT_SUCCESS:
      return helper.fetchSentimentSuccess(state, action);
    case actionTypes.FETCH_SENTIMENT_FAIL:
      return helper.fetchSentimentFail(state, action);
    case actionTypes.FETCH_BATCH_IDS_STARTED:
      return helper.batchIdFetchStart(state);
    case actionTypes.FETCH_BATCH_IDS_SUCCESS:
      return helper.batchIdFetchSuccess(state, action);
    case actionTypes.FETCH_BATCH_IDS_FAIL:
      return helper.batchIdFetchFail(state, action);
    case actionTypes.FETCH_SENTIMENT_TOPICS_START:
      return helper.fetchSentimentTopicStart(state, action);
    case actionTypes.FETCH_SENTIMENT_TOPICS_SUCCESS:
      return helper.fetchSentimentTopicSuccess(state, action);
    default:
      return filters(state, action);
  }
};

function filters(state, action) {
  switch (action.type) {
    case actionTypes.FETCH_FILTERS_STARTED:
      return helper.fetchFiltersStart(state);
    case actionTypes.FETCH_FILTERS_SUCCESS:
      return helper.fetchFiltersSuccess(state, action);
    case actionTypes.FETCH_FILTERS_FAIL:
      return helper.fetchFiltersFail(state, action);
    case actionTypes.UPDATE_ACTIVE_FACET:
      return updateActiveFacet(state, action);
    case actionTypes.UPDATE_FACET_LIST:
      return helper.updateFacetList(state, action);
    case actionTypes.RESET_FACET_LIST:
      return helper.resetFacetList(state);
    case actionTypes.MAP_SENTIMENT_TO_FILTER:
      return helper.mapSentimentToFilter(state, action);
    case actionTypes.FETCH_SENTIMENT_TOPICS_SUCCESS:
      return helper.fetchSentimentTopicSuccess(state, action);
    default:
      return { ...state };
  }
}

function updateActiveFacet(state, { facet }) {
  return {
    ...state,
    activeFacet: state.activeFacet === facet ? "" : facet,
  };
}

export default sentimentReducer;
