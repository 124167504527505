import React from "react";
import StackChart from "../StackChart";
import {
  checkRootManager,
} from "../../../../../../../../utils/localStorgeOperations";
import moment from "moment";
import { Modal } from "antd";
import { getOrgIdFromUrl } from '../../../../../../../../utils/urlProcessor';
import { getDataFromUrlToken } from '../../../../../../../../utils/urlDataStore';
import { URL_DATA } from '../../../../../../../../../config/urlDataConstants';
import { checkManager } from '../../../../../../../../utils/rolePermissionUtils';

export class ParticipationOverallGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showResponseChartModal: false,
    };
  }
  getgroupParticipationRate = () => {
    let organizationId = getOrgIdFromUrl();
    let urlData = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS)
    return (
      <>{
        checkManager(organizationId, urlData.projectId) && !checkRootManager() ? (
      <>
        {(this.props.getParticipationRateApi.data &&
          this.props.getParticipationRateApi.data.aggregatedDemographicsResponse
            .participation.groupLevel.percentage !== 100 && (
            <li>
              <div className="compare-count">
                <h4>
                  {
                    this.props.getParticipationRateApi.data
                      .aggregatedDemographicsResponse.participation.groupLevel
                      .percentage
                  }
                  %
                </h4>
              </div>
              <p>Your group’s participation rate</p>
            </li>
          )) || (
            <li>
              <div className="compare-count">
                <h4>
                  {this.props.getParticipationRateApi.data
                    .aggregatedDemographicsResponse
                    .completedGroupParticipants ||
                    this.props.getParticipationRateApi.data
                      .aggregatedDemographicsResponse.completedParticipants}
                </h4>
              </div>
              <p>Your group’s number of respondents</p>
            </li>
          ) ||
          0}
      </>
        ) : null
      }
      </>)
  }

  getWholeParticipationRate = () => (
    <>
      {(this.props.getParticipationRateApi.data &&
        this.props.getParticipationRateApi.data.aggregatedDemographicsResponse
          .participation.companyLevel.percentage !== 100 && (
          <li>
            <div className="compare-count">
              <h4>
                {
                  this.props.getParticipationRateApi.data
                    .aggregatedDemographicsResponse.participation.companyLevel
                    .percentage
                }
                %
              </h4>
            </div>
            <p>Whole org participation rate</p>
          </li>
        )) || (
          <li>
            <div className="compare-count">
              <h4>
                {this.props.getParticipationRateApi.data &&
                  this.props.getParticipationRateApi.data
                    .aggregatedDemographicsResponse
                  ? this.props.getParticipationRateApi.data
                    .aggregatedDemographicsResponse
                    .completedGroupParticipants ||
                  this.props.getParticipationRateApi.data
                    .aggregatedDemographicsResponse.completedParticipants
                  : 0}
              </h4>
            </div>
            <p>Whole org number of respondents</p>
          </li>
        ) ||
        0}
    </>
  );
  getMiniChartTicks = (data) => {
    if (data && data.length) {
      let firstTick = this.props.getDistributionDataApi.data.histogramData[0].date;
      let lastTick =
        this.props.getDistributionDataApi.data.histogramData[
          this.props.getDistributionDataApi.data.histogramData.length - 1
        ].date;
      return [firstTick, lastTick];
    }
    return null;
  };
  formatXAxis = (date) => {
    let currentDate = new Date();
    currentDate = moment(currentDate).format("DD/MM/YY");
    if (moment(date).format("DD/MM/YY") === currentDate) {
      return "Today";
    } else return moment(date).format("MMM DD");
  };
  renderResponseChartModal = () => {
    return (
      <Modal
        title="Responses"
        className="response-chart-modal"
        width={800}
        visible={this.state.showResponseChartModal}
        onOk={() => this.setState({ showResponseChartModal: false })}
        onCancel={() => this.setState({ showResponseChartModal: false })}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        {this.getExpandedResponseChart()}
      </Modal>
    );
  };
  getExpandedResponseChart = () => {
    return (
      <StackChart
        data={
          (this.props.getDistributionDataApi.data &&
            this.props.getDistributionDataApi.data.histogramData) ||
          []
        }
        isAnonymous={this.props.surveyDetails.isAnonymous}
        showLegend={true}
        showTooltip={true}
        formatXAxis={this.formatXAxis}
        width={700}
        height={400}
      />
    );
  };
  render() {
    return (
      <>
        <div className="compare-wrapper">
          {this.getgroupParticipationRate()}
          {this.getWholeParticipationRate()}

          {this.props.surveyDetails &&
            this.props.surveyDetails.isFileUpload ? null : (
            <li className="participation-barchart">
              {this.props.getDistributionDataApi.data &&
                this.props.getDistributionDataApi.data.histogramData &&
                this.props.getDistributionDataApi.data.histogramData.length ? (
                <StackChart
                  onClick={(e) =>
                    this.setState({ showResponseChartModal: true })
                  }
                  data={
                    (this.props.getDistributionDataApi.data &&
                      this.props.getDistributionDataApi.data.histogramData) ||
                    []
                  }
                  ticks={this.getMiniChartTicks()}
                  hideYAxis={true}
                  formatXAxis={this.formatXAxis}
                  width={200}
                  height={100}
                />
              ) : (
                <span className="no-response-bar">
                  <div className="no-response-bar__line"></div>
                </span>
              )}
            </li>
          )}
          <this.renderResponseChartModal />
        </div>
      </>
    );
  }
}
