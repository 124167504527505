import { 
    apiStartState,
    apiFailureState,
    apiSuccessState
} from '../../../../utils/apiStateUtils';


export function batchDeleteStart(state) {
    return {
        ...state,
        batchDeleteApi: apiStartState(state.batchDeleteApi)
    };
};

export function batchDeleteSuccess(state, action) {
    return {
        ...state,
        batchDeleteApi: apiSuccessState(state.batchDeleteApi,action)
    };
}

export function batchDeleteFail(state, action) {
    return {
        ...state,
        batchDeleteApi: apiFailureState(state.batchDeleteApi,action)
    };
};