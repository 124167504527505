import {
    FETCH_EXECUTIVE_OVERVIEW_SUCCESS, FETCH_EXECUTIVE_OVERVIEW_FAILED,
    FETCH_EXECUTIVE_OVERVIEW_STARTED,UPDATE_CAROUSEL_INDEX,
    UPADTE_CAROUSEL_WIDTH
} from '../../constants';
import { FETCH_EXECUTIVE_OVERVIEW_DATA }
    from '../../../../config/constants';
import { ApiBase } from '../../../utils/apiBase';
import { withType } from '../../../utils/actionOperations';
import { getDataFromUrl } 
    from '../../../utils/getDataFromQueryString';
import { GetLocalStorage } from '../../../utils/localStorgeOperations';

export const updateCarouselWidth=(payload)=>{
    return dispatch=>{
        dispatch(withType(UPADTE_CAROUSEL_WIDTH,payload));
    };
};

export const fetchExecutiveOverviewData = () => {
    const apiBaseInstance = new ApiBase().instance;
    return dispatch => {
        dispatch(withType(FETCH_EXECUTIVE_OVERVIEW_STARTED, {}));
        apiBaseInstance.post(FETCH_EXECUTIVE_OVERVIEW_DATA, {
            filters:getDataFromUrl().filters||{},
            projectId : GetLocalStorage("projectId")
        })
            .then(res =>
                fetchExecutiveDataSuccess(dispatch, res))
            .catch(err =>
                fetchExecutiveDataFailed(dispatch, err));
    };
};

const fetchExecutiveDataSuccess=(dispatch, res)=> {
    return dispatch(
        withType(FETCH_EXECUTIVE_OVERVIEW_SUCCESS, res.data)
    );
};
const fetchExecutiveDataFailed=(dispatch, err)=> {
    return dispatch(withType(
        FETCH_EXECUTIVE_OVERVIEW_FAILED, err));
};

export const updateCarouselIndex=(value)=>{
    return dispatch=>{
        dispatch(withType(UPDATE_CAROUSEL_INDEX,value));
    };
};