import axios from 'axios';
import {
    API_BASE_URL,
    API_BASE_STAGE_URL
} from '../../config/constants';
import {
    ClearLocalStorage,
    GetLocalStorage
} from '../utils/localStorgeOperations';
import constants from './constants';
import { decryptResponse } from '../utils/encryptionUtils';

export class ApiBase {
    constructor(params) {
        this.instance = axios.create({
            baseURL: API_BASE_URL + API_BASE_STAGE_URL,
            headers: this.handleHeaders(params)
        });
        this.instance.interceptors.response.use(
            response => {
                if (response.data && response.data.dataToken) {
                    response.data.data = decryptResponse(response.data)
                }
                return response
            },
            error => {
                this.getErrorResponse(error);
                return Promise.reject(error);
            }
        );
    }

    getErrorResponse(error) {
        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.status === 401
        ) {
            if(error.response.data.code === "tokenExpired"){
                window.location.href = constants.path.accessDenied
            }else if (window.location.pathname !== constants.path.accessDenied && window.location.pathname !== '/' && window.location.pathname !== '/login' && window.location.pathname !== '/forgot') {
                window.location.href = constants.path.accessDenied
            }else{
                ClearLocalStorage()
            }
        }
    }
    handleHeaders = (params) => {
        let token = GetLocalStorage(constants.local.idToken);
        if (params) return params;
        else if (!token)
            return constants.defaultRequestHeader;
        else
            return {
                ...constants.defaultRequestHeader,
                Authorization: token
            };
    }
}