import { notification } from "antd";
import * as actionTypes from "../../constants";
import constants from "./constants";

export function uploadTopicLibrary(file) {
  return dispatch=>{
    dispatch({type:actionTypes.UPLOAD_TOPIC_FILE_START});
    fileUploadSuccess({dispatch,file})
};
}
function fileUploadSuccess({dispatch,res,file}){
  notification.success({
      message: constants.fileUpoladSuccess,
      status: 'done'
  });
  dispatch({
      type:actionTypes.UPLOAD_TOPIC_FILE_SUCCESS,
      data:file.data,
      fileName:file.name,
      file:file,
      status: 'done'
  });
}
