import {
    FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_START,
    FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_SUCCESS,
    FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_FAILED
} from '../../../constants';
import { COMMENT_STATISTICS } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { withType } from '../../../../utils/actionOperations';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchExploreCommentStatisticsData = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_START, { questionId: payload.selectedQuestionId }));
        apiBaseInstance.instance
            .post(COMMENT_STATISTICS, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                dispatch(withType(FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_SUCCESS, {
                    data: response.data,
                    message: response.message,
                    filters: payload.filters,
                    byScore: payload.byScore,
                    multipleSelect: payload.multipleSelect,
                    selectedUser: payload.selectedUser,
                    directReporteesOnly: payload.directReporteesOnly,
                    questionId: payload.selectedQuestionId
                }));
                cb && cb(true,response.data);
            })
            .catch(err => {
                dispatch(withType(FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_FAILED, {
                    error: err,
                    questionId: payload.selectedQuestionId
                }));
                cb && cb(false);
            });
    };
};
