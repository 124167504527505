import {
    TREND_AUTOMATCHING_START,
} from "../../../constants";
import { withType } from "../../../../utils/actionOperations";


export const autoMatchSurveys = (payload, cb) => {
    return (dispatch) => {
        dispatch(withType(TREND_AUTOMATCHING_START, {...payload}));
    };
};
