import { Tooltip } from 'antd';
import React from 'react';
import './index.scss';

export class DemographicsBarChart extends React.Component {

    showChart = (item) => {
        let isShowChart = true
        let parsed = parseInt(item.favourableDetails.value)
        if ((item.favourableDetails.value === null || parseInt(item.favourableDetails.value) === 0) &&
            (item.neutralDetails.value === null || parseInt(item.neutralDetails.value) === 0) &&
            (item.unFavourableDetails.value === null || parseInt(item.unFavourableDetails.value) === 0)) {
            isShowChart = false
        }

        return isShowChart
    }

    getWholeGraph = (item) => {
        let neutralDetails = item.neutralDetails
        let favDetails = item.favourableDetails
        let unFavDetails = item.unFavourableDetails
        let totalLength = parseInt(favDetails.value) + parseInt(unFavDetails.value) + parseInt(neutralDetails.value)
        let unfavAdjustWidth = totalLength === 100 ? unFavDetails.value : (100 - totalLength) + parseInt(unFavDetails.value)
        return (
            <>{this.showChart(item) ? <div className='topics-progress progress-bar'>
                <Tooltip
                    placement='bottom'
                    title={favDetails.value < 5 ? `${favDetails.value} Favorable` : "Favorable"}
                >
                    <div
                        className='favourable'
                        style={{
                            width: `${favDetails.value}%`,
                            // minWidth: favDetails.value === 0 ? '0%' : this.getMinWidth(favDetails),
                            padding: favDetails.value === 0 && '0',
                        }}
                    >
                        {
                            favDetails.value >= 5 &&
                            <p>{`${favDetails.value} `}</p>
                        }
                    </div>
                </Tooltip>
                <Tooltip
                    placement='bottom'
                    title={neutralDetails.value < 5 ? `${neutralDetails.value} Neutral` : "Neutral"}
                >
                    <div
                        className='neutral-fullgraph'
                        style={{
                            width: `${neutralDetails.value}%`,
                            // minWidth: neutralDetails.value === 0 ? '0%' : this.getMinWidth(neutralDetails),
                            padding: neutralDetails.value === 0 && '0',
                        }}
                    >
                        {
                            neutralDetails.value >= 5 &&
                            <p>{`${neutralDetails.value} `}</p>
                        }
                    </div>
                </Tooltip>
                <Tooltip
                    placement='bottom'
                    title={unFavDetails.value < 5 ? `${unfavAdjustWidth} Unfavorable` : "Unfavorable"}
                >
                    <div
                        className='unfavourable'
                        style={{
                            width: `${unfavAdjustWidth}%`,
                            // minWidth: unFavDetails.value === 0 ? '0%' : this.getMinWidth(unFavDetails),
                            padding: unFavDetails.value === 0 && '0',
                        }}
                    >
                        {
                            unFavDetails.value >= 5 &&
                            <p>{`${unFavDetails.value} `}</p>
                        }
                    </div>
                </Tooltip>
            </div> : <> Insufficient data!</>}</>

        )
    }

    getNeutralScoreGraph = (item) => {
        let neutralDetails = item.neutralDetails
        return (
            <>
                {neutralDetails.value === "0" ? 'Insufficient data!' :
                    <div className='topics-progress progress-bar'>
                        <div
                            className='neutral'
                            style={{
                                width: `${neutralDetails.width}%`,
                                // minWidth: item.neutral === 0 ? '0%' : this.getMinWidth(item),
                                padding: neutralDetails.value === 0 && '0',
                            }}
                        >
                            <p>{`${neutralDetails.value} `}</p>
                        </div>
                    </div>
                }
            </>
        )
    }

    getFavScoreGraph = (item) => {
        let favDetails = item.favourableDetails
        return (
            <>
                {
                    favDetails.value === "0" ? 'Insufficient data!' :
                        <div className='topics-progress progress-bar'>

                            <div
                                className='favourable'
                                style={{
                                    width: `${favDetails.width}%`,
                                    // minWidth: item.favourable === 0 ? '0%' : this.getMinWidth(item),
                                    padding: favDetails.value === 0 && '0',
                                }}
                            >

                                <p>{`${favDetails.value} `}</p>

                            </div>
                        </div>
                }
            </>
        )
    }

    getUnfavGraph = (item) => {
        let unFavDetails = item.unFavourableDetails
        return (
            <>
                {
                    unFavDetails.value === "0" ? 'Insufficient data!' :
                        <div className='topics-progress progress-bar'>

                            <div
                                className='unfavourable'
                                style={{
                                    width: `${unFavDetails.width}%`,
                                    // minWidth: item.unFavourable === 0 ? '0%' : this.getMinWidth(item),
                                    padding: unFavDetails.value === 0 && '0',
                                }}
                            >
                                <p>{`${unFavDetails.value} `}</p>

                            </div>
                        </div>}
            </>
        )
    }

    render() {
        return (
            <>

                {this.props.scoreType === "all" && this.getWholeGraph(this.props.data)}
                {this.props.scoreType === "favourable" && this.getFavScoreGraph(this.props.data)}
                {this.props.scoreType === "unFavourable" && this.getUnfavGraph(this.props.data)}
                {this.props.scoreType === "neutral" && this.getNeutralScoreGraph(this.props.data)}


            </>
        );
    }
}