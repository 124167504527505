import { apiInitialState } from '../../../utils/apiStateUtils';
export function Default() {
    return {
        fetchRolesApi: apiInitialState(),
        fetchTagsApi: apiInitialState(),
        createRoleApi: apiInitialState(),
        deleteRoleApi: apiInitialState(),
        tags:[]
    };
}
