import { fetchPayload } from "../../../utils/reducerOperations";
import {
  EDIT_TREND_BENCHMARK_DATA,
  UPDATE_TREND_BENCHMARK_DATA_START,
  UPDATE_TREND_BENCHMARK_DATA_SUCCESS,
  UPDATE_TREND_BENCHMARK_DATA_FAIL,
  FETCH_TREND_BENCHMARK_DATA_START,
  FETCH_TREND_BENCHMARK_DATA_SUCCESS,
  FETCH_TREND_BENCHMARK_DATA_FAIL,
} from "../../constants";
import { editTrendBenchMark, FetchTrendBenchMarkDataFailed, FetchTrendBenchMarkDataStart, FetchTrendBenchMarkDataSuccess, UpdateTrendBenchMarkDataFailed, UpdateTrendBenchMarkDataStart, UpdateTrendBenchMarkDataSuccess } from './Helper';

import { IniialState } from "./InitialState";

const NewdashboardTrendBenchmarkReducer = (state = IniialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_TREND_BENCHMARK_DATA_START:
      return FetchTrendBenchMarkDataStart(state, payload);
    case FETCH_TREND_BENCHMARK_DATA_SUCCESS:
      return FetchTrendBenchMarkDataSuccess(state, payload);
    case FETCH_TREND_BENCHMARK_DATA_FAIL:
      return FetchTrendBenchMarkDataFailed(state, payload);

    case UPDATE_TREND_BENCHMARK_DATA_START:
      return UpdateTrendBenchMarkDataStart(state, payload);
    case UPDATE_TREND_BENCHMARK_DATA_SUCCESS:
      return UpdateTrendBenchMarkDataSuccess(state, payload);
    case UPDATE_TREND_BENCHMARK_DATA_FAIL:
      return UpdateTrendBenchMarkDataFailed(state, payload);

    case EDIT_TREND_BENCHMARK_DATA:
      return editTrendBenchMark(state, payload);
    default:
      return { ...state };
  }
};

export default NewdashboardTrendBenchmarkReducer;