export const initialState = () => {
  return {
    newForgotPasswordApi: {
      isProcessing: false,
      data: null,
      error: false,
      message: '',
      code: '',
      reasson: ''
    },
  }
};
