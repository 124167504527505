import React from "react";
export const storyboardLowOutcomeQuestionScoreSuccess = (state, payload) => {

  const fetchlowOutcomeQuestion = fetchlowOutcomeQuestionScore(payload)
  return {
    ...state,
    storyboardLowOutcomeQuestionScoreApi: {
      isProcessing: false,
      data: fetchlowOutcomeQuestion,
    }
  }

}

const fetchlowOutcomeQuestionScore = (res) => {

  let result = {};
  if (res.surveyreporttabledata && res.surveyreporttabledata.dimensionTable) {
    const dimensionTable = res.surveyreporttabledata.dimensionTable;
    const itemTable = res.surveyreporttabledata.itemTable;
    const outcome = res.outComeVariable;
    let checkOutComeVariableData = checkOutComeTopicScore(
      dimensionTable,
      outcome
    );
    let outcomeTopicScore = Math.round(checkOutComeVariableData.length && checkOutComeVariableData[0].favScore);

    let filteredQuestionScores = getLowQuestionDetails(itemTable.filter(itemData => itemData.dimension === outcome))
    for (let i = 0; i < filteredQuestionScores.length; i++) {
      let favScore = filteredQuestionScores[i].favScore
      let totfavScore = 0;
      for (let k = 0; k < filteredQuestionScores.length; k++) {
        if (filteredQuestionScores[i] !== filteredQuestionScores[k]) {
          totfavScore += filteredQuestionScores[k].favScore
        }
      }
      let diff = favScore - (totfavScore / (filteredQuestionScores.length - 1))
      if (diff >= 10 && favScore >= 75) {
        result.favScore = favScore;
        result.diff = Math.ceil(diff);
        result.totfavScore = totfavScore;

        if (outcome === "Engagement") {
          let shortQuestion = filteredQuestionScores[0].shortName ? filteredQuestionScores[0].shortName : filteredQuestionScores[0].item.slice(0, -1);
          result.text = `Although employees were Engaged overall (${outcomeTopicScore}), people were much less positive about "${shortQuestion} (${Math.ceil(filteredQuestionScores[0].favScore)})".`
        } else {
          result.text = <>Although the score on {outcome} was high at {outcomeTopicScore}, people rated one question {Math.ceil(diff)} points lower than the others.</>
        }
        result.questionDetails = getLowQuestionDetails([...filteredQuestionScores])[0];
        result.status = "negative"
        break;
      }
    }
    return result;

  }
};

const getLowQuestionDetails = (data) => {
  let sortedData = data
    .sort((a, b) => a.favScore - b.favScore);
  return sortedData
}

const checkOutComeTopicScore = (data, outcome) => {
  let result = data
    .filter((item) => item.item === outcome)
    .sort((a, b) => b.favScore - a.favScore);
  return result;
};