import { deepCopy } from "../../../../../../utils/deepCopy"

export const likertDragAndDrop = (state, payload) => {
    let updatedGoals = getDragAndDropedChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getDragAndDropedChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            let sourceElement = element.questions[0].matrixQuestions[payload.source];
            let targetElement = element.questions[0].matrixQuestions[payload.target]
            element.questions[0].matrixQuestions[payload.source] = targetElement
            element.questions[0].matrixQuestions[payload.target] = sourceElement
        }
    });
    return goalsCopy
}
