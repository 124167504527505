import {
    FETCH_TREND_INSIGHTS_DATA_START,
    FETCH_TREND_INSIGHTS_DATA_SUCCESS,
    FETCH_TREND_INSIGHTS_DATA_FAIL
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';
import { GET_TREND_INSIGHTS_DATA } from "../../../../../config/constants";

export const getTrendInsightsTableData = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
        dispatch(withType(FETCH_TREND_INSIGHTS_DATA_START, {}));
        apiBaseInstance.instance
            .post(GET_TREND_INSIGHTS_DATA, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then((response) => {
                dispatch(
                    withType(FETCH_TREND_INSIGHTS_DATA_SUCCESS, {
                        data: response.data,
                        message: response.message,
                    })
                );
                cb && cb(true);
            })
            .catch((err) => {
                dispatch(
                    withType(FETCH_TREND_INSIGHTS_DATA_FAIL, {
                        error: err,
                    })
                );
            });
    };
};
