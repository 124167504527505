import { fetchPayload, update } from "../../../utils/reducerOperations";
import {
    EXPORT_FORM_CHANGE,
    EXPORT_FORM_CLEAR
} from "../../constants";
import { handleExportFormChange } from './handleExportFormChange'
import { Default } from "./InitialState";
const ExportFormReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case EXPORT_FORM_CHANGE:
            return update(state, handleExportFormChange(state, payload));
        case EXPORT_FORM_CLEAR:
            return {
                ...Default()
            };
        default:
            return { ...state }
    }
};


export default ExportFormReducer;
