import React, { Component } from 'react'
import { Checkbox, Input } from 'antd';
import { ButtonElement } from '../../../../../../FormElements/ButtonElement';
import parse from "html-react-parser";
import { getQuestionTranslation, getTextForTranslation } from '../../../../../../utils/newAttendSurveyUtils';
import { KEY_CONSTANTS } from '../../constants';
import "./index.scss";
import ErrorBlock from '../ErrorBlock';
import { AsterikLabel } from '../../../../../../Components/AsterikLabel';
import { getClassNameForMatrix } from '../../helper';
import ScrollBar from '../../../../../../Packages/ScrollBar';
import { generateAnswerForMultipleSelection, getAnswerValue } from './helpers';
import { TextInput } from '../../../../../../FormElements/TextInput';
export default class MultiOptionQuestions extends Component {


  updateMultiOptionAnswer = (payload) => {
    this.props.updateAnswer(payload)

    setTimeout(() => {
      this.props.updateCurrentIndex()
    }, 400)
  }

  getColorForSelectedAnswer = (value, answer) => {
    if (answer) {
      let findAnswerIndex = answer.findIndex((item) => item.answer === value)
      if (findAnswerIndex != -1) {
        return "element-active"
      }
    }
    return ""
  }

  getOptions = (questionDetails) => {
    const isOtherOptionEnabled = questionDetails.options.find(item => item.isOtherOption);
    let answerArray
        if (this.props.currentQuestionSet.answer) {
           answerArray = JSON.parse(this.props.currentQuestionSet.answer);
      }  
    let isOtherOptionSelected 
     isOtherOptionEnabled &&
     answerArray &&
     answerArray.length > 0 &&
     answerArray.forEach(item => {
    if(item.isOtherOption){
      isOtherOptionSelected =true
    }
  });
    return (
      <>
      <Checkbox.Group
        className='mutioption-checkbox'
        value={getAnswerValue(answerArray)}
        onChange={(event) => { this.updateMultiOptionAnswer({ value: generateAnswerForMultipleSelection(event, questionDetails.options,this.props.currentQuestionSet.answer), question: questionDetails, isFromMultipleOption: true, blockId: this.props.questionDetails.blockId }) }}>
        {questionDetails.options.map((item, index) => {
          return <div className={this.getColorForSelectedAnswer(item.value, answerArray) + ' mutioption-checkbox__element'}>
            <Checkbox className='mutioption-item' key={item.id} value={item.value}>
              <div className='multipleoption-text'> {getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: KEY_CONSTANTS.LABEL })}</div> </Checkbox>
          </div>
        })}
           { isOtherOptionSelected && (
        <div className='otherOption-textInput'>
            <TextInput
               value={answerArray && answerArray.length > 0 && answerArray.find(item => item.isOtherOption).textAnswer || '' }
                placeholder="Enter other option"
                disabled={!isOtherOptionSelected}
                handleChange={(event) => this.props.updateTextAnswer({event, questionDetails})}
            />
        </div>
        )}
      </Checkbox.Group>
   
      </>
    )
  }

  render() {
    return (
      <>
        <div className="multioption-block">
            {this.props.questionDetails.questions.map((item, index) => {
              return <>
                <div className='multioption-block__header'>
                  <h3 className={this.props.questionDetails.isRequired ? 'heading-asterisk' : ""}>
                    {this.props.selectedLanguage === KEY_CONSTANTS.DEFAULT_LANGUAGE ? parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: item.HTMLQuestion ? KEY_CONSTANTS.HTML_QUESTION : KEY_CONSTANTS.QUESTION })) : <p>{parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: item.HTMLQuestion ? KEY_CONSTANTS.HTML_QUESTION : KEY_CONSTANTS.QUESTION }))}</p>}
                  </h3>
                  <h5>Multiple Choice Select. Select as many choices as you want.</h5>
                  {item.description && item.description.length > 0 && <p>{item.description}</p>}
                </div>
                <div className='multioption-block__content'>
                  {this.getOptions(item)}
                </div>
              </>
            })
            }

          <div className='multioption-block__button'>
            {/* <ButtonElement type={'primary'} className='multipleoption-btn' onClick={() => this.props.handleGoPrevAndNext(false, false)}>
              {getTextForTranslation({
                language: this.props.selectedLanguage,
                defaultTexts: this.props.defaultTexts,
                translatedTexts: this.props.translatedTexts,
                key: KEY_CONSTANTS.CONTINUE_BUTTON
              })}
            </ButtonElement> */}
          </div>
          <ErrorBlock manageScroller={this.props.manageScroller} />
        </div >
        <div>

        </div>
      </>
    )
  }
}
