import { DYNAMIC_360_GOAL_IDS, FEEDBACK_360_SURVEY, FEEDBACK_GOAL_IDS, REDIRECTION_CONSTANTS } from "../../../../../config/questionTypeConstants"
import { deepCopy } from "../../../../utils/deepCopy"
import { checkIfJson, getCurrentQuestionSetDetails, isAppliedProjectType } from "../../../../utils/newAttendSurveyUtils"
import { newJson } from "../../../../utils/newJson"
import { COMMON_ERROR_MESSAGE, ERROR_MESSAGE_CONDITIONAL_FEEDBACK, ERROR_MESSAGE_DRAG, ERROR_MESSAGE_FEEDBACK_TEXT, FEEDBACK_ERROR, FEEDBACK_ERROR_EQUAL, FEEDBACK_ERROR_NOT_EQUAL, NA_CONDITION, NA_CONDITION_ARRAY, ERROR_MESSAGE_DRAG_NUMBER, ERROR_MESSAGE_DRAG_STRENGTH, ERROR_MESSAGE_DRAG_IMPROVEMENT, ERROR_MESSAGE_DRAG_STRENGTH_FOR_APPLIED, ERROR_MESSAGE_DRAG_IMPROVEMENT_FOR_APPLIED, SUMMARY_TYPE } from "../constants"
import { generateSummaryRatings } from "./summaryRatingUtils"

export const goToPrevOrNext = (state, payload) => {
    let newCurrentIndex
    let currentIndex = state.currentIndex
    let progressIndex = state.progressIndex
    let isGoPrev = state.isPrevClicked
    let questionSetCopy = deepCopy(state.questionSet)
    let data = deepCopy(state.pageBreakAddedQuestionSet)
    questionsToCommentOnStrength(data, state.currentIndex, questionSetCopy)
    questionsToCommentsOnImprovement(data, state.currentIndex, questionSetCopy)
    let { isFromGoBackFirstPage, goPrev } = payload
        currentIndex = goPrev ? state.currentIndex - 1 : state.currentIndex
    let { requiredSatisfied, errorText } = goPrev ? { requiredSatisfied: true, errorText: "" } : checkWheatherIsRequiredSatisfied(data[state.currentIndex], questionSetCopy, state)
    if (!goPrev && requiredSatisfied) {
                currentIndex = goPrev ? state.currentIndex - 1 : state.currentIndex + 1
    }

        progressIndex = goPrev ? state.progressIndex - 1 : state.progressIndex

    if (isFromGoBackFirstPage) {
        data[data.length-1]={...data[data.length-1],isFromRedirection:false}
                return {
            progressIndex: 0,
            currentIndex: 0,
            questionSetCopy,
            pageBreakAddedQuestionSetCopy:data
        }
    }
    if (goPrev) {
        isGoPrev = true
    }
    else {
        isGoPrev = false
    }
    if (data[currentIndex].type === SUMMARY_TYPE) {
        let summayRatingDetails = generateSummaryRatings(questionSetCopy)
        if (!summayRatingDetails.isCurrentIndexChangeNeeded) {
            data[currentIndex] = {
                ...data[currentIndex], questions: [{
                    summaryResult: summayRatingDetails.summaryScale,
                    totalQuestionsCount: summayRatingDetails.totalQuestionsCount
                }]
            }
        }
        else {
            currentIndex = goPrev ? currentIndex - 1 : currentIndex + 1
            progressIndex = goPrev ? progressIndex - 1 : progressIndex + 1
        }


    }
    let totalArray = data.filter(item => !item.isQuestionLevelFollowUp && !item.isWelcomeMessage && !item.isFromBlockLevelFollowUp && !item.isRedirectionPage && !item.isTranslationSelection);
    let findedcurrentProgressIndexFromTotal
    data.forEach((item, index) => {
                if (index === currentIndex) {
                         findedcurrentProgressIndexFromTotal = getProgressIndexIfIsFromRedirection(item, totalArray)
                        //  findedcurrentProgressIndexFromTotal = findedcurrentProgressIndexFromTotal === -1 ? progressIndex : findedcurrentProgressIndexFromTotal


            if (goPrev && data[state.currentIndex].isFromRedirection) {
                let findedPreviousIndex = data.findIndex((currentItem) => data[state.currentIndex].previousBlockId === currentItem.blockId)
                newCurrentIndex = findedPreviousIndex
                let newProgressIndex = getProgressIndexIfIsFromRedirection(data[findedPreviousIndex], totalArray)
                progressIndex = newProgressIndex != -1 ? newProgressIndex : findedPreviousIndex
                let currentData = deepCopy({ ...data[state.currentIndex], isFromRedirection: false })
                delete currentData.previousBlockId
                data[state.currentIndex] = currentData
            }
            if (item.isQuestionLevelFollowUp) {
                let updatedQuestionSet = checkConditionForDisplay(item, state.questionSet, state.pageBreakAddedQuestionSet)
                questionSetCopy = updatedQuestionSet.questionSetCopy
                if (updatedQuestionSet.ruleSatisfied) {
                    if (item.isRedirectionPage) {
                        if (!goPrev) {
                            let redirectionIndex = findIndexForRedirection(item, data)
                             handleClearAnswerOnRedirect(item,questionSetCopy)
                            if (redirectionIndex != -1) {
                                                                progressIndex = findIndexForRedirection(item, totalArray) + 1
                                newCurrentIndex = redirectionIndex
                                data[newCurrentIndex] = { ...data[newCurrentIndex], isFromRedirection: true, previousBlockId: data[state.currentIndex].blockId }
                            }
                            else {
                                currentIndex = currentIndex + 1
                            }
                        }
                        else {
                            newCurrentIndex = data.findIndex((currentItem) => item.selectedBlockId === currentItem.blockId)
                        }
                    }
                    progressIndex = !item.isRedirectionPage ? findedcurrentProgressIndexFromTotal != -1 ? findedcurrentProgressIndexFromTotal : progressIndex : progressIndex
                } else {
                    progressIndex = findedcurrentProgressIndexFromTotal != -1 ? findedcurrentProgressIndexFromTotal : progressIndex
                    currentIndex = goPrev ? currentIndex - 1 : currentIndex + 1
                    if (goPrev && !updatedQuestionSet.ruleSatisfied && state.pageBreakAddedQuestionSet[currentIndex].isQuestionLevelFollowUp) {
                        let conditionSatisfied = checkConditionForDisplay(state.pageBreakAddedQuestionSet[currentIndex], state.questionSet, state.pageBreakAddedQuestionSet)
                        do {
                            currentIndex = conditionSatisfied.ruleSatisfied ? currentIndex : currentIndex - 1
                            conditionSatisfied = state.pageBreakAddedQuestionSet[currentIndex].isQuestionLevelFollowUp ? checkConditionForDisplay(state.pageBreakAddedQuestionSet[currentIndex], state.questionSet, state.pageBreakAddedQuestionSet) : { ruleSatisfied: true }
                        } while (!conditionSatisfied.ruleSatisfied);
                    }
                }
            }
            else if (goPrev && state.pageBreakAddedQuestionSet[state.currentIndex].isQuestionLevelFollowUp) {
                let followUpQuestion = checkConditionForDisplay(state.pageBreakAddedQuestionSet[state.currentIndex], state.questionSet, state.pageBreakAddedQuestionSet)
                                progressIndex = followUpQuestion.ruleSatisfied ? state.progressIndex : progressIndex
            }
            else if(item.isFromBlockLevelFollowUp){
                let blockLevelFollowUp = checkConditionForDisplay(item, state.questionSet, state.pageBreakAddedQuestionSet)
                questionSetCopy = blockLevelFollowUp.questionSetCopy
                progressIndex = getProgressIndexFromConditionalFollowup(item, totalArray)
                if (goPrev && !blockLevelFollowUp.ruleSatisfied && state.pageBreakAddedQuestionSet[currentIndex].isFromBlockLevelFollowUp) {
                    let conditionSatisfied = checkConditionForDisplay(state.pageBreakAddedQuestionSet[currentIndex], state.questionSet, state.pageBreakAddedQuestionSet)
                    do {
                        currentIndex = conditionSatisfied.ruleSatisfied ? currentIndex : currentIndex - 1
                        conditionSatisfied = state.pageBreakAddedQuestionSet[currentIndex].isFromBlockLevelFollowUp ? checkConditionForDisplay(state.pageBreakAddedQuestionSet[currentIndex], state.questionSet, state.pageBreakAddedQuestionSet) : { ruleSatisfied: true }
                    } while (!conditionSatisfied.ruleSatisfied);

                }
                else {
                currentIndex = !blockLevelFollowUp.ruleSatisfied ? currentIndex + 1 : currentIndex
                }
            }
            else if (!goPrev && requiredSatisfied) {
                                        progressIndex = goPrev ? progressIndex - 1 : progressIndex + 1
                }
            else if(item.isWelcomeMessage){
                progressIndex =0
            }
            else{
                progressIndex = !data[state.currentIndex].isFinalPage && !data[currentIndex].isTranslationSelection ? getProgressIndexFromConditionalFollowup(item, totalArray) : progressIndex
                if (goPrev && state.surveyQuestionSetApi && state.surveyQuestionSetApi.data && state.surveyQuestionSetApi.data.projectType && isAppliedProjectType(state.surveyQuestionSetApi.data.projectType)) {
                    let indexDetails = appliedFeedbackSurveyIndexHandler(progressIndex, currentIndex, data)
                    newCurrentIndex = indexDetails.newCurrentIndexApplied
                    progressIndex = indexDetails.newProgressIndexApplied
                }

            }
        }
    })
    // let indexDetails = navigateToPage(progressIndex, currentIndex, data, goPrev)
        return { currentIndex: newCurrentIndex ? newCurrentIndex : currentIndex, progressIndex: progressIndex, isGoPrev, questionSetCopy, requiredSatisfied, pageBreakAddedQuestionSetCopy: data, errorText: errorText }
}

const checkConditionForDisplay = (questionDetails, questionSet, pageBreakAddedQuestionSet) => {
    let questionSetCopy = deepCopy(questionSet)
    let ruleSatisfied = false
    let pageBreakBlockCopy = deepCopy(pageBreakAddedQuestionSet)
    let submittedAnswer
    if(questionDetails.questions && questionDetails.questions[0].isFromBlockLevelFollowUp ){
        let updatedBlockDetails = handleShowQuestionLevelFollowUp(questionDetails,questionSetCopy)
        return { ...updatedBlockDetails,pageBreakBlockCopy }
    }
    else if (questionDetails.isMultipleOptionFollowUp) {
        let updatedBlockDetails = handleShowFollowUpForMultipleChoice(questionDetails, questionSetCopy)
        return { ...updatedBlockDetails, pageBreakBlockCopy }
    }
    let conditionToDisplay = questionDetails.type === "textEntry" ? questionDetails.condition : questionDetails.questions[0].matrixQuestions[0].condition
    let staticId = questionDetails.type === "textEntry" ? questionDetails.questions[0].selectedQuestionStaticId : questionDetails.questions[0].matrixQuestions[0].selectedQuestionStaticId
    if (questionDetails.isMultipleOptionFollowUp) {
    }
    else if (conditionToDisplay === undefined || conditionToDisplay === "" || conditionToDisplay.length === 0) {
        ruleSatisfied = true
    }
    else {
        if (conditionToDisplay && Array.isArray(conditionToDisplay)) {
            if (conditionToDisplay.length > 0) {
                questionSetCopy.forEach((block, key) => {
                    if (block.goalId === questionDetails.selectedGoalId) {
                        if (block.questions[0] && block.questions[0].answer) {
                            let parsedAnswers = JSON.parse(block.questions[0].answer);
                            parsedAnswers.forEach((answerGroup) => {
                                if (answerGroup.staticQuestionId === staticId) {
                                    ruleSatisfied = conditionToDisplay.includes(`${answerGroup.answer}`)
                                    let labelForSubmittedAnswer = block.questions[0].options.filter((item) =>
                                        // eslint-disable-next-line
                                        item.value == answerGroup.answer
                                    )
                                    // submittedAnswer = labelForSubmittedAnswer[0].label
                                }

                            })
                        }
                        if (!ruleSatisfied) {
                            block.questions[0].matrixQuestions.forEach(item => {
                                if (item.staticQuestionId === staticId) {
                                    item.followUpQuestions.forEach(followUp => {
                                        if (followUp.goalId === questionDetails.goalId && followUp.answer) {
                                            followUp.answer = null
                                        }
                                    })

                                }
                            })
                        }
                    }
                })
            }
            else {
                ruleSatisfied = true
            }

        }
    }
    return { ruleSatisfied, questionSetCopy, pageBreakBlockCopy }
}

export const checkWheatherAllQuestionsAnswered = () => {

}

export const checkIfTwoArraysAreEqual = (array1, array2) => {
    let newArray = []
    if (array1.length !== array2.length) return false
    array1.forEach(item => {
        if (array2.includes(item)) {
            newArray.push(item)
        }
    })
    return newArray.length === array1.length
}

export const updateCurrentIndex = () => {

}

export const checkWheatherIsRequiredSatisfied = (pageBreakAddedQuestionSetDetails, questionSet, state) => {
    let strengthError = ERROR_MESSAGE_DRAG_STRENGTH
    let improvementError = ERROR_MESSAGE_DRAG_IMPROVEMENT
    if (state.surveyQuestionSetApi && state.surveyQuestionSetApi.data && state.surveyQuestionSetApi.data.projectType && isAppliedProjectType(state.surveyQuestionSetApi.data.projectType)) {
        strengthError = ERROR_MESSAGE_DRAG_STRENGTH_FOR_APPLIED
        improvementError = ERROR_MESSAGE_DRAG_IMPROVEMENT_FOR_APPLIED

    }
    let answer = getCurrentQuestionSetDetails({ props: { questionDetails: pageBreakAddedQuestionSetDetails, questionSet: questionSet } })
    if (pageBreakAddedQuestionSetDetails.isRequired) {
        if (pageBreakAddedQuestionSetDetails.isDefault || pageBreakAddedQuestionSetDetails.isDefault) {
            let flag = false
            let matrixanswer = answer && answer.answer ? JSON.parse(answer.answer) : []
            for (let matrixIndex = 0; matrixIndex < pageBreakAddedQuestionSetDetails.questions[0].matrixQuestions.length; matrixIndex++) {
                let findedIndex = matrixanswer.findIndex((item) => item.staticQuestionId === pageBreakAddedQuestionSetDetails.questions[0].matrixQuestions[matrixIndex].staticQuestionId)
                if (findedIndex === -1) {
                    return { errorText: COMMON_ERROR_MESSAGE, requiredSatisfied: false }
                }
                else {
                    flag = true
                }
            }
            return { errorText: !flag ? COMMON_ERROR_MESSAGE : "", requiredSatisfied: flag }
        }
        if (typeof (answer.answer) === "number" || (answer.answer && answer.answer.length > 0)) {
            if (pageBreakAddedQuestionSetDetails.type === "matrix") {
                let matrixRequired = pageBreakAddedQuestionSetDetails.questions[0].matrixQuestions.length === JSON.parse(answer.answer).length
                let feedbackObject = pageBreakAddedQuestionSetDetails.feedbackBasedCondition ? handlefeedbackBasedCondition({ answer, pageBreakAddedQuestionSetDetails, matrixRequired }) : { errorText: !matrixRequired ? COMMON_ERROR_MESSAGE : "", requiredSatisfied: matrixRequired }
                return { errorText: feedbackObject.errorText, requiredSatisfied: feedbackObject.requiredSatisfied }
            }
            return { errorText: "", requiredSatisfied: true }
        }
        else {
            return { errorText: COMMON_ERROR_MESSAGE, requiredSatisfied: false }
        }
    }
    else if (pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG || pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG || pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG_180 || pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG_180) {
        if (pageBreakAddedQuestionSetDetails.questions[0] && pageBreakAddedQuestionSetDetails.questions[0].answer) {
            let answeredQuestionDetails = pageBreakAddedQuestionSetDetails.questions[0];
            let questionCount = 0
            if (answeredQuestionDetails) {
                for (const key in answeredQuestionDetails) {
                    if (answeredQuestionDetails[key].questions && Array.isArray(answeredQuestionDetails[key].questions)) {
                        questionCount += answeredQuestionDetails[key].questions.length;
                    }
                }

            }
            if (questionCount === 0 && state.surveyQuestionSetApi && state.surveyQuestionSetApi.data && state.surveyQuestionSetApi.data.projectType && isAppliedProjectType(state.surveyQuestionSetApi.data.projectType)) {
                //   return { errorText: "No questions found Message", requiredSatisfied: false, extraOneNeeded: true }

            }
            let rankingCount = pageBreakAddedQuestionSetDetails.totalRanking


            let dragSatisfied = rankingCount === JSON.parse(pageBreakAddedQuestionSetDetails.questions[0].answer).length
            return { errorText: !dragSatisfied ? ERROR_MESSAGE_DRAG : "", requiredSatisfied: dragSatisfied }
        }
        else {
            if (pageBreakAddedQuestionSetDetails.questions[0]) {
                let answeredBlockDetails = pageBreakAddedQuestionSetDetails.questions[0]
                let behaviourQuestions = 0
                for (const key in answeredBlockDetails) {
                    if (answeredBlockDetails[key].questions && Array.isArray(answeredBlockDetails[key].questions)) {
                        behaviourQuestions += answeredBlockDetails[key].questions.length;
                    }
                }

                if (behaviourQuestions === 0) {
                    //  return { errorText: "hellooo", requiredSatisfied: false }
                }

                else if (behaviourQuestions < 3) {
                    if(pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG || pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG_180){
                        return { errorText: strengthError, requiredSatisfied: false }
                    }
                    else if(pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG || pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG_180){
                        return { errorText: ERROR_MESSAGE_DRAG_IMPROVEMENT, requiredSatisfied: false }
                    }
                    
                }
            }
            else {
                if(pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG || pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG_180){
                    return { errorText: strengthError, requiredSatisfied: false }
                }
                else if(pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG){
                    return { errorText: ERROR_MESSAGE_DRAG_IMPROVEMENT, requiredSatisfied: false }
                }
            }
            return { errorText: ERROR_MESSAGE_DRAG, requiredSatisfied: false }
        }
    }
    else if (pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.COMMENTS_ON_IMPROVEMENT || pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.COMMENTS_ON_STRENGTH || pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG_180 || pageBreakAddedQuestionSetDetails.goalId === FEEDBACK_360_SURVEY.IMPROVEMENT_DRAG_180) {
        let answeredPriorityLength = answer.answer.length
        const sumOfAnswers = answer.answer.reduce((accumulator, currentItem) => {
            if ((currentItem.answer && currentItem.answer.length > 0) || currentItem.checkboxValue) {
                return accumulator = accumulator + 1
            }
            return accumulator
        }, 0);

        if (sumOfAnswers === answeredPriorityLength) {
            return { errorText: "", requiredSatisfied: true }
        }
        else {
            return { errorText: ERROR_MESSAGE_FEEDBACK_TEXT, requiredSatisfied: false }
        }
    }
    else if (pageBreakAddedQuestionSetDetails.feedbackBasedCondition) {
        let feedbackSatisfyBlock = handlefeedbackBasedCondition({ answer, pageBreakAddedQuestionSetDetails, matrixRequired: true })
        return { errorText: !feedbackSatisfyBlock.requiredSatisfied ? ERROR_MESSAGE_CONDITIONAL_FEEDBACK : "", requiredSatisfied: feedbackSatisfyBlock.requiredSatisfied }
    }
    return { errorText: "", requiredSatisfied: true }
}


const questionsToCommentOnStrength = (pageBreakQuestions, currentIndex, questionSetCopy) => {
    if(pageBreakQuestions[currentIndex].goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG || pageBreakQuestions[currentIndex].goalId === FEEDBACK_360_SURVEY.STRENGTH_DRAG_180)   
    {
        let questionSetStrengthIndex = questionSetCopy.findIndex((item) => item.goalId === FEEDBACK_360_SURVEY.COMMENTS_ON_STRENGTH)

        let findedDragIndex = pageBreakQuestions.findIndex((item) => item.goalId === FEEDBACK_360_SURVEY.COMMENTS_ON_STRENGTH)
        let questionArray = pageBreakQuestions[currentIndex].questions[0] && pageBreakQuestions[currentIndex].questions[0].answer ? JSON.parse(pageBreakQuestions[currentIndex].questions[0].answer) : []
        let updatedQuestionsArray = questionArray.map((item) => {
            let currentItem = pageBreakQuestions[findedDragIndex].questions.find((commentItem, index) => commentItem.qid === item.id)
            return {
                ...item,
                id: item.id,
                label: item.label,
                question: item.question,
                questionDimension: item.questionDimension,
                selectedLabelAswer: item.selectedLabelAswer,
                staticQuestionId: item.staticQuestionId,
                priorityValue: item.answer,
                type: "Text Entry",
                englishQuestion: item.question,
                qid: item.id,
                answer: currentItem && currentItem.answer ? currentItem.answer : "",
                isCheckboxNeeded: pageBreakQuestions[findedDragIndex].isCheckboxNeeded,
                checkboxValue: currentItem && currentItem.checkboxValue ? currentItem.checkboxValue : false
            }
        })
        pageBreakQuestions[findedDragIndex].questions = updatedQuestionsArray
        questionSetCopy[questionSetStrengthIndex].questions = updatedQuestionsArray

    }

}

const questionsToCommentsOnImprovement = (pageBreakQuestions, currentIndex, questionSetCopy) => {
    if(pageBreakQuestions[currentIndex].goalId === DYNAMIC_360_GOAL_IDS.IMPROVEMENT_DRAG || pageBreakQuestions[currentIndex].goalId === DYNAMIC_360_GOAL_IDS.IMPROVEMENT_DRAG_180)
    {
        let questionSetStrenthDragIndex = questionSetCopy.findIndex((item) => item.goalId === FEEDBACK_360_SURVEY.COMMENTS_ON_IMPROVEMENT)
        let findedDragIndex = pageBreakQuestions.findIndex((item) => item.goalId === FEEDBACK_360_SURVEY.COMMENTS_ON_IMPROVEMENT)
        let questionArray = pageBreakQuestions[currentIndex].questions[0] && pageBreakQuestions[currentIndex].questions[0].answer ? JSON.parse(pageBreakQuestions[currentIndex].questions[0].answer) : []
        let updatedQuestionsArray = questionArray.map((item) => {
            let currentItem = pageBreakQuestions[findedDragIndex].questions.find((commentItem, index) => commentItem.qid === item.id)
            return {
                ...item,
                id: item.id,
                label: item.label,
                question: item.question,
                questionDimension: item.questionDimension,
                selectedLabelAswer: item.selectedLabelAswer,
                staticQuestionId: item.staticQuestionId,
                priorityValue: item.answer,
                type: "Text Entry",
                englishQuestion: item.question,
                qid: item.id,
                answer: currentItem && currentItem.answer ? currentItem.answer : "",
                isCheckboxNeeded: pageBreakQuestions[findedDragIndex].isCheckboxNeeded,
                checkboxValue: currentItem && currentItem.checkboxValue ? currentItem.checkboxValue : false
            }
        })
        pageBreakQuestions[findedDragIndex].questions = updatedQuestionsArray
        questionSetCopy[questionSetStrenthDragIndex].questions = updatedQuestionsArray

    }
}

const navigateToPage = (progressIndex, currentIndex, pageBreakQuestions, goPrev) => {
    if (pageBreakQuestions[currentIndex].questions && pageBreakQuestions[currentIndex].questions.length === 0) {
        if (!goPrev) {
            currentIndex = currentIndex + 2
            progressIndex = progressIndex + 2
           return navigateToPage(progressIndex, currentIndex, pageBreakQuestions)
        }
        else {
            currentIndex = currentIndex - 2
            progressIndex = progressIndex - 2
        }

    }
    return {currentIndex,progressIndex}
}

const handlefeedbackBasedCondition = ({ answer, pageBreakAddedQuestionSetDetails, matrixRequired }) => {
    if (matrixRequired && answer.answer && answer.answer.length > 0) {
        let parsedAnswer = JSON.parse(answer.answer)
        let condition = pageBreakAddedQuestionSetDetails.feedbackBasedCondition[0].answeringCondition
        const sumOfImprovement = getSummedValue(parsedAnswer, condition[0])
        const sumOfStrength = getSummedValue(parsedAnswer, condition[1])
        const sumOfNa = getSummedValue(parsedAnswer, NA_CONDITION)
        // if (sumOfNa === 0) {
            if (sumOfImprovement >= condition[0].numberOfAnswersNeeded && sumOfStrength >= condition[1].numberOfAnswersNeeded) {
                return { errorText: "", requiredSatisfied: true }
            }
            else {
                // return { errorText: ERROR_MESSAGE_CONDITIONAL_FEEDBACK, requiredSatisfied: false }
                return { errorText: "", requiredSatisfied: true }
            }
        // }
        // else {
        //     let totalNumberOfAnswersNeeded = pageBreakAddedQuestionSetDetails.questions[0].matrixQuestions.length - sumOfNa
        //     let splittedValue = Math.floor(totalNumberOfAnswersNeeded / 2)
        //     let balanceRequired = totalNumberOfAnswersNeeded - splittedValue
        //     let errorBlock = handleNaSelectedConditionsSatisfied({ sumOfStrength, sumOfImprovement, splittedValue, balanceRequired })
        //     return errorBlock
        // }

    }
    else {
        return { errorText: "", requiredSatisfied: false }
    }



}

const getSummedValue = (parsedAnswer, curretCondition) => {
    let count = parsedAnswer.reduce((accumulator, currentItem) => {
        if (curretCondition.columnValues.includes(currentItem.columnId)) {
            return accumulator = accumulator + 1
        }
        return accumulator
    }, 0);
    return count
}

const handleNaSelectedConditionsSatisfied = (selectedDetails) => {
    if (selectedDetails.sumOfImprovement === selectedDetails.balanceRequired || selectedDetails.sumOfImprovement === selectedDetails.splittedValue) {
        if (selectedDetails.sumOfStrength === selectedDetails.balanceRequired || selectedDetails.sumOfStrength === selectedDetails.splittedValue) {
            return { errorText: "", requiredSatisfied: true }
        }
        else {
            return { errorText: selectedDetails.splittedValue === selectedDetails.balanceRequired ? FEEDBACK_ERROR_EQUAL(selectedDetails.balanceRequired, selectedDetails.splittedValue) : FEEDBACK_ERROR_NOT_EQUAL(selectedDetails.balanceRequired, selectedDetails.splittedValue), requiredSatisfied: false }
        }
    }
    else {
        return { errorText: selectedDetails.splittedValue === selectedDetails.balanceRequired ? FEEDBACK_ERROR_EQUAL(selectedDetails.balanceRequired, selectedDetails.splittedValue) : FEEDBACK_ERROR_NOT_EQUAL(selectedDetails.balanceRequired, selectedDetails.splittedValue), requiredSatisfied: false }

    }

}

const handleShowQuestionLevelFollowUp = (currentDetails,questionSet)=>{
    let parsedAnswer
    let showQuestion = false
    let findedBlockOnQuestionset = questionSet.find(item => item.blockId === currentDetails.questions[0].selectedBlockId)
    if (currentDetails.condition && currentDetails.condition.length === 0) {
        return { ruleSatisfied: true, questionSetCopy: questionSet }
    }
    if (findedBlockOnQuestionset.questions && findedBlockOnQuestionset.questions[0].answer) {
        parsedAnswer = checkIfJson(findedBlockOnQuestionset.questions[0].answer) ? JSON.parse(findedBlockOnQuestionset.questions[0].answer) : []
        for (let findedAnswer = 0; findedAnswer < parsedAnswer.length; findedAnswer++) {
            if (currentDetails.condition.includes(parsedAnswer[findedAnswer].answer)) {
                showQuestion = true
                break;
            }
        }
        if(!showQuestion){
       questionSet = handleClearAnswerOnDeselect(questionSet,currentDetails)
        }
    }
 return { ruleSatisfied: showQuestion, questionSetCopy: questionSet }
}

const handleClearAnswerOnDeselect =(questionSet,currentDetails)=>{
    let answeredBlock = undefined;
    let answeredQuestion = undefined;
    const data = newJson(questionSet);
        questionSet.forEach((block, blockIndex) => {
            if (block.blockId === currentDetails.selectedBlockId) {
                block.questions[0].followUpQuestions.forEach((item, matrixQuestionIndex) => {
                    if (item.qid === currentDetails.questions[0].qid) {
                        answeredBlock = blockIndex;
                        answeredQuestion = matrixQuestionIndex
                    }
                })
                if(questionSet[answeredBlock].questions[0].followUpQuestions[answeredQuestion].answer){
                data[answeredBlock].questions[0].followUpQuestions[answeredQuestion].answer = null
                }
            }
    
        });
    return data
}

const handleShowFollowUpForMultipleChoice = (currentDetails, questionSet) => {
    let showQuestion = false
    questionSet.forEach((block, key) => {
        if (block.blockId === currentDetails.selectedGoalId) {
            let foundedAnswer = block.questions && block.questions[0] && block.questions[0].answer
            if (foundedAnswer && checkIfSelectedIdExist(foundedAnswer, currentDetails.selectedId)) {
                showQuestion = true
            }
        }
    })
    return { ruleSatisfied: showQuestion, questionSetCopy: questionSet }

}

const handleClearAnswerOnRedirect = (item,questionSet)=>{
    let foundIndex = questionSet.findIndex(value => value.blockId === item.selectedBlockId)
    for(let foundIndexToChange = foundIndex +1 ; foundIndexToChange < questionSet.length ;foundIndexToChange++){
        if(questionSet[foundIndexToChange].type === "likert"){
            if(questionSet[foundIndexToChange].questions && questionSet[foundIndexToChange].questions[0] && questionSet[foundIndexToChange].questions[0].answer){
                questionSet[foundIndexToChange].questions[0].answer = null
                if(questionSet[foundIndexToChange].questions[0].matrixQuestions){
                    questionSet[foundIndexToChange].questions[0].matrixQuestions.forEach(matrixQuestion => {
                        if(matrixQuestion.followUpQuestions){
                            matrixQuestion.followUpQuestions.forEach(followUp =>{
                                if(followUp.answer){
                                    followUp.answer = null
                                }
                            })
                        }
                    })
                }
            }
        }
        else{
            if(questionSet[foundIndex].questions && questionSet[foundIndex].questions[0] ){
                let selectedAnswer
                if(questionSet[foundIndex].questions[0] && questionSet[foundIndex].questions[0].answer)
                selectedAnswer = JSON.parse(questionSet[foundIndex].questions[0].answer)
                let valueIndex = selectedAnswer[0].answer
                let fileterdOption = questionSet[foundIndex].questions[0].options.filter(item => item.value !== valueIndex)
                fileterdOption.forEach(option =>{
                    if(option.followUpQuestions){
                    option.followUpQuestions.forEach(followUpQuestion =>{
                        if(followUpQuestion.answer){
                            followUpQuestion.answer =null
                     }
                    })
                }
                })
            }
            if(questionSet[foundIndexToChange].questions && questionSet[foundIndexToChange].questions[0] && questionSet[foundIndexToChange].questions[0].answer){
                questionSet[foundIndexToChange].questions[0].answer = null
                if(questionSet[foundIndexToChange].questions[0] && questionSet[foundIndexToChange].questions[0].followUpQuestions){
                    questionSet[foundIndexToChange].questions[0].followUpQuestions.forEach(followup =>{
                        followup.answer = null
                    })
                }
            }
        }
    }
}

const checkIfSelectedIdExist = (foundedAnswer, selectedId) => {
    foundedAnswer=JSON.parse(foundedAnswer)
    let findedIndex = foundedAnswer.findIndex((item) => item.answer === selectedId)
    if (findedIndex != -1) {
        return true
    }
    return false
}

const findIndexForRedirection = (redirectionData, currentPageBreakedQuestionSet) => {
        let redirectionIndex = -1
        let redirectionBlock =redirectionData.condition && Array.isArray(redirectionData.condition) ?
        redirectionData.condition[0].redirectionBlockId:
        redirectionData.condition.redirectionBlockId
    if (redirectionBlock) {
        if (redirectionBlock === REDIRECTION_CONSTANTS.FINAL_PAGE) {
            return currentPageBreakedQuestionSet.length - 1
        }
        redirectionIndex = currentPageBreakedQuestionSet.findIndex((currentPage) => currentPage.blockId === redirectionBlock)
    }
    return redirectionIndex
}

const getProgressIndexFromConditionalFollowup =(currentIndexData, currentPageBreakedQuestionSet)=>{
    let currentIndexForBack = -1
    currentIndexForBack = currentPageBreakedQuestionSet.findIndex((currentData) => currentData.blockId === currentIndexData.blockId || currentData.blockId === currentIndexData.selectedBlockId )
    if (currentIndexForBack != -1) {
        return currentIndexForBack + 1

    }
    return currentIndexForBack
}

const getProgressIndexIfIsFromRedirection = (currentIndexData, currentPageBreakedQuestionSet) => {
        let currentIndexForBack = -1
    currentIndexForBack = currentPageBreakedQuestionSet.findIndex((currentData) => currentData.blockId === currentIndexData.blockId || currentData.blockId === currentIndexData.selectedGoalId)
    if (currentIndexForBack != -1) {
        return currentIndexForBack + 1

    }
    return currentIndexForBack

}

const getCurrentIndexFromConditionalFollowup = (redirectionData, currentPageBreakedQuestionSet) => {
    let indexFromCoditionalFollowup = -1
    if (redirectionData.condition && redirectionData.selectedBlockId) {
        indexFromCoditionalFollowup = currentPageBreakedQuestionSet.findIndex((currentPage) => currentPage.blockId === redirectionData.selectedBlockId)
    }
    return indexFromCoditionalFollowup
}

const appliedFeedbackSurveyIndexHandler = (progressIndex, currentIndex, data) => {
    let newCurrentIndexApplied = currentIndex
    let newProgressIndexApplied = progressIndex
    if (data.find(item => item.type === SUMMARY_TYPE)) {
        if (data[currentIndex].type === SUMMARY_TYPE) {
            newCurrentIndexApplied = newCurrentIndexApplied - 1
            newProgressIndexApplied = newProgressIndexApplied - 1
        }
        else if (data[newCurrentIndexApplied].goalId === FEEDBACK_360_SURVEY.COMMENTS_ON_STRENGTH) {
            if (data[newCurrentIndexApplied].questions.length < 3) {
                newCurrentIndexApplied -= 3
                newProgressIndexApplied -= 3
            }
        }
        else if (data[newCurrentIndexApplied].goalId === FEEDBACK_360_SURVEY.COMMENTS_ON_IMPROVEMENT) {
            if (data[newCurrentIndexApplied].questions.length < 3) {
                newCurrentIndexApplied -= 2
                newProgressIndexApplied -= 2
                return appliedFeedbackSurveyIndexHandler(newProgressIndexApplied, newCurrentIndexApplied, data)

            }
        }
    }

    return {
        newCurrentIndexApplied,
        newProgressIndexApplied
    }
}