import React from "react";
import { compareByAlpha } from "../../../../../../../utils/compareByAlpha";
import constants from "../constants";
import { Capitalize } from "../../../../../../../utils/textManipulations";
import { LoaderButton } from "../../../../../../../FormElements/LoaderButton";

export function addUserColumn(columns, isAdmin) {
  if (isAdmin)
    columns.splice(2, 0, {
      title: Capitalize(constants.userTitle),
      dataIndex: constants.owner,
      key: constants.owner,
      sorter: (a, b) => compareByAlpha(a.owner, b.owner),
      render: (text) => getMutliUserSelect(text),
    });
  return columns;
}

const getMutliUserSelect = (text) => {
  return (
    <div className="table-multiselect">
      {/* <ReactMultiSelectCheckboxes
        placeholderButtonLabel	={text}
        width={250}
        options={options}
      /> */}
      {text}
    </div>
  );
};

export function addDownloadButton({
  columns,
  isAdmin,
  downloadByBatchId,
  getDownloadingState,
}) {
  if (isAdmin)
    columns.push({
      title: constants.download,
      key: constants.download,
      dataIndex: constants.download,
      render: (_, doc) => {
        return (
         
          <LoaderButton
                  data-test="login-btn"
                  loader={
                    getDownloadingState(doc.projectId)
                  }
                 
                  onClick={downloadByBatchId.bind(null, doc)}
                  classList={["loader-button"]}
                  loaderClass={["xs white"]}
                  text={constants.download}
                />
        );
      },
    });
  return columns;
}
