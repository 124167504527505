import { setInitialState } from "../../../../utils/reducerOperations";
export function Default() {
    return {
        listTextEntryQuestionsApi: setInitialState(),
        questionTopicLibraryMapping: [],
        uploadQuestionTopicApi: {
            isProcessing: false,
            error: false
        },
        reprocessSurveyApi: {
            isProcessing: false,
            error: false
        },
        defaultList: []
    };
}
