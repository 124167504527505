import {
    NEW_RESET_PASSWORD_PROCESS_FAILED,
    NEW_RESET_PASSWORD_PROCESS_SUCCESS,
    NEW_RESET_PASSWORD_PROCESS_STARTED
} from '../../constants';
import { RESET_URL } from '../../../../config/constants';
import { ApiBase } from '../../../utils/apiBase';
import { withType } from '../../../utils/actionOperations';

export const newResetPasswordAction = (params, cb) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {

        dispatch(withType(NEW_RESET_PASSWORD_PROCESS_STARTED, params));
        apiBaseInstance.instance
            .post(RESET_URL, params)
            .then((res) => {
                dispatch(withType(NEW_RESET_PASSWORD_PROCESS_SUCCESS, res.data));
                cb && cb(true);
            })
            .catch((err) => {
                dispatch(withType(NEW_RESET_PASSWORD_PROCESS_FAILED, err));
                cb && cb(false, err);
            });

    }
}
