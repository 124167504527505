import React from "react";
import { Tooltip, Modal } from "antd";
import Icon from '@ant-design/icons';
import { ButtonElement } from "../../../../../../../FormElements/ButtonElement";
import constants from "../constants";
class BatchOperation extends React.Component {
  state = { visibility: false, operator: null };

  componentWillUpdate(nextProps) {
    if (this.isApproved(nextProps) || this.isDeleted(nextProps))
      this.toggleVisibility();
  }

  isApproved = (nextProps) =>
    this.props.batchApprovalApi.loading && !nextProps.batchApprovalApi.loading;

  isDeleted = (nextProps) =>
    this.props.batchDeleteApi.loading && !nextProps.batchDeleteApi.loading;

  toggleVisibility = () => {
    this.props.clearBatchOperation();
    this.setState({
      visibility: !this.state.visibility,
      operator: null,
    });
  };

  renderIcons = () => {
    const { icon, title, titlePlacement } = constants.batchOperationIcon;
    if (this.state.visibility)
      return <Icon onClick={this.toggleVisibility} type={constants.close} />;
    return (
      <Tooltip title={title} placement={titlePlacement}>
        <Icon type={icon} onClick={this.toggleVisibility} />
      </Tooltip>
    );
  };

  showModal = () => {
    let title = constants.batchApproveConfirmationMessage;
    let okType = constants.primary;
    let okText = constants.approve;
    let onOk = this.props.batchApprove;
    if (this.state.operator === "delete") {
      title = constants.batchDeleteConfirmationMessage;
      okType = constants.danger;
      okText = constants.delete;
      onOk = this.props.batchDelete;
    }
    const onClickOk = () => onOk(this.props.selectedRows);
    this.showConfirm({ title, okType, onClickOk, okText });
  };

  getJobsList = () => {
    return (
      <ul className="modal-batchid__list">
        {this.props.selectedRows.map((row) => (
          <li children={row.projectId} />
        ))}
      </ul>
    );
  };
  showConfirm({ title, okType, onClickOk, okText }) {
    Modal.confirm({
      title,
      content: this.getJobsList(),
      okType,
      onOk: onClickOk,
      okText,
    });
  }

  renderOperator = () => {
    const count = (this.props.selectedRows || []).length;
    let type = constants.primary,
      children = constants.approve;
    let loading = this.props.batchApprovalApi.loading;
    if (this.state.operator === "delete") {
      type = constants.danger;
      children = constants.delete;
      loading = this.props.batchDeleteApi.loading;
    }
    return (
      <div className="render-operator">
        <ButtonElement
          loading={loading}
          onClick={this.showModal.bind(null)}
          disabled={!count}
          type={type}
          children={children + " selected " + count + " items"}
        />
      </div>
    );
  };

  onChangeOperator = (operator) => {
    this.props.setBatchOperation(operator);
    this.setState({ operator });
  };

  getOperations = () =>
    constants.operations.filter((item) => {
      if (item.adminOnly) {
        if (this.props.isAdmin) return true;
      } else return true;
      return false
    });

  renderOperations = () => {
    if (!this.state.visibility) return "";
    if (this.state.operator) return this.renderOperator();
    return (
      <div className="select-operation">
        {this.getOperations().map((item) => (
          <span
            onClick={this.onChangeOperator.bind(null, item.name)}
            children={"Batch " + item.label}
          />
        ))}
      </div>
    );
  };

  render() {
    //   if(!GetLocalStorage('showChart'))return'';
    return (
      <div className="batch-operations">
        {this.renderOperations()}
        {this.renderIcons()}
      </div>
    );
  }
}
export default BatchOperation;
