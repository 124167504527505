import { newJson } from "../../../../../../utils/newJson";
import constants from "./constants"

export const checkIfPreviewLink = () => {
    return window.location.href.includes(constants.PREVIEW_LINK)
}

export const disableSubmitOnNoAnswer = (questionSet) => {
    const data = newJson(questionSet);
    if (Array.isArray(data) && data.length) {
        for (let i = 0; i < data.length; i++) {
            if (Array.isArray(data[i].questions)) {
                if (data[i].questions.length > 0 && data[i].questions[0].answer && data[i].questions[0].answer.length) {
                    return false
                }
                else if (data[i].haveFollowUpQuestion && data[i].followUpQuestions[0].answer && data[i].followUpQuestions[0].answer.length) {
                    return false
                }
            }

        }
    }
    return true
}