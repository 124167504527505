import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const strengthScaleDrag = {
    "blockId": uuidv4(),
    "goalId": "feedback360_strengthScaleDrag",
    "isHiddenFromEditSurvey": true,
    "totalRanking": 3,
    "questions": [
        {
            "question": "Build great teams and organizations",
            "type": "priorityDrag",
            "questionDimension": "Builds great teams and organizations"
        }
    ],
    "totalAnswersCount": 4,
    "type": "priorityDrag",
    "dimension": "Build great teams and organizations"
}

export const strengthScaleDragForAppliedMaterials = {
    "blockId": uuidv4(),
    "goalId": "feedback360_strengthScaleDrag",
    "isHiddenFromEditSurvey": true,
    "totalRanking": 3,
    "questions": [
        {
            "question": "Build great teams and organizations",
            "type": "priorityDrag",
            "questionDimension": "Builds great teams and organizations"
        }
    ],
    "selfMainHeading": "<span><b>TOP STRENGTHS:</b> You identified these behaviors as strengths (rating of \"Usually\" or \"Always\"). Please <b> rank the top 3</b> behaviors to leverage given the organization's priorities and your role.</span>",
    "mainHeading": "<span><b>TOP STRENGTHS:</b> You identified these behaviors as strengths (rating of \"Usually\" or \"Always\"). Please <b> rank the top 3</b> behaviors to leverage given the organization's priorities and the person’s role.</span>",
    "instructionHeader":"DRAG AND DROP THE TOP 3 STRENGTHS TO LEVERAGE",
    "selfInstructionHeader": "DRAG AND DROP THE TOP 3 STRENGTHS TO LEVERAGE",
    "displayMappingLogic": "topicWise",
    "totalAnswersCount": 4,
    "type": "priorityDrag",
    "dimension": "Build great teams and organizations"
}