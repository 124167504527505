import React, { Component } from 'react'
import DownloadLinks from '../DownloadLinks'
import constants from './constants'

class VerySimpleSurveyRightSection extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <div>
                <DownloadLinks
                    dataCollectionLink={constants.DATA_COLLECTION_LINK}
                    description={"Very simple survey"}
                    hideTopicLibrary = {true}
                />
            </div>
        )
    }
}

export default VerySimpleSurveyRightSection