import React from 'react';
import { Collapsible } from '../../../../../../../Components/Collapse';
import SVGIcons from '../../../../../../../Components/SVGIcons';
import { PanelContent } from './PanelContent';
import { getSelectedUserData, getTopicBadgeColor } from '../../../helper';
import { deepCopy } from '../../../../../../../utils/deepCopy';
import GraphLoader from '../../../../../../../Components/ContentLoaders/GraphLoader';
import { getDataFromUrlToken } from '../../../../../../../utils/urlDataStore';
import { URL_DATA } from '../../../../../../../../config/urlDataConstants';
import { getURLFilterAndByScore } from '../../../../../../../utils/byScoreFilterUtils';
import { checkIfAnyFilterIsApplied } from '../../../../../../../utils/filterUtils';
import { Tooltip } from 'antd'
import { getBenchmarkTooltipTitle } from '../../../../../../../utils/benchmarkUtils';

export class Panel extends React.Component {

  getDimesionScores = (item) => {
    let topicList = deepCopy(item);
    let dimensionTable = deepCopy(this.props.dimensionTable);
    let filteredDimensionItem = dimensionTable.filter(
      (data) => data.item === topicList.topic
    );
    return filteredDimensionItem;
  };

  showOutliersIcon = (topicList) => {
    const halfLength = topicList ? topicList.length / 2 : 0;
    const topicsWithOutliers = topicList.filter((item) => {
      return (item.positiveOutliers && !!item.positiveOutliers.length) || (item.negativeOutliers && !!item.negativeOutliers.length);
    });
    const outlierTopicLength = topicsWithOutliers.length;
    return halfLength > outlierTopicLength;
  };

  checkIfFilterAppliedOrTreeDataSelected = () => {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER) || {};
    let filterData = getURLFilterAndByScore(urlFilter)
    const selectedUserData = getSelectedUserData();
    let NoFilterExist = Object.keys(filterData.filters).length === 0;
    let noByScoreExist = filterData.byScore.length === 0
    let noMultipleSelectExist = filterData.byScore.length === 0
    let noTreeDataExist =
      !selectedUserData.selectedUser || selectedUserData.selectedUser === '';
    return NoFilterExist && noTreeDataExist && noByScoreExist && noMultipleSelectExist;
  };

  getTopicStoryItem = (dimensionItem) => {
    let topicStoryItem =
      this.props.api.exploreTopicList.filter(
        (topicStory) => topicStory.topic === dimensionItem.item
      ) || [];
    return topicStoryItem.length ? topicStoryItem[0] : {};
  };

  getSignedValue = (value) => {
    if (value < 0) {
      return (<span className="down-arrow">
        <SVGIcons type={"SVG-thick-downarrow"} />
        {Math.abs(value)}
      </span>
      )
    } else if (value > 0) {
      return <span className="up-arrow">
        <SVGIcons type={"SVG-thick-uparrow"} />
        {value}
      </span>
    } else {
      return 0
    }
  }

  getTrendSignedValue = (value) => {
    if (value < 0) {
      return (<span className="down-arrow">
        <SVGIcons type={"SVG-thick-downarrow"} />
        {Math.abs(value)}
      </span>
      )
    } else if (value > 0) {
      return <span className="up-arrow">
        <SVGIcons type={"SVG-thick-uparrow"} />
        {value}
      </span>
    } else {
      return `0`
    }
  }

  getBenchMarkValue = (topicScore, benchmarkScore) => {
    if (topicScore && benchmarkScore) {
      let value = topicScore - benchmarkScore
      return this.getSignedValue(value)
    } else {
      return 0
    }

  }

  getDimensionTable = () => {
    let dimensionTableCopy = deepCopy(this.props.dimensionTable)
    return dimensionTableCopy
  }

  render() {
    //uncomment below lines for working expected as based on threshold

    return (
      <>
        {this.getDimensionTable().map((dimesionItem) => {
          let item = this.getTopicStoryItem(dimesionItem);
          return !dimesionItem ? (
            <GraphLoader width={600} height={400} />
          ) : (
            <Collapsible
              // preventExpand={dimesionItem.validCount < this.props.threshold}
              // showArrow={dimesionItem.validCount >= this.props.threshold}
              key={item.topic}
              className='topics-collaps'
              panels={[
                {
                  header: (
                    <>
                      {/* <Tooltip title={dimesionItem.validCount < this.props.threshold ? "There were not enough responses to show data" : ""}> */}
                      <div className='topics-items'>
                        <div
                          className={`number-circle ${getTopicBadgeColor(
                            dimesionItem.favScore ? dimesionItem.favScore : 0
                          )}`}
                        >
                          {dimesionItem.favScore ? dimesionItem.favScore : 0}
                          {/* {dimesionItem.validCount < this.props.threshold ? "-" : dimesionItem.favScore ? dimesionItem.favScore : 0} */}
                        </div>
                        <div className='topics-items__text-area'>
                          <h4>{dimesionItem.item}</h4>
                          {/* {dimesionItem.validCount < this.props.threshold ? "" : */}
                          {false ? "" :
                            <div className='text-block'>
                              {dimesionItem.benchmarkScore !== undefined && <Tooltip title={getBenchmarkTooltipTitle(this.props.benchmarkTooltip)}><p>{this.getBenchMarkValue(dimesionItem.favScore, dimesionItem.benchmarkScore)} vs benchmark</p></Tooltip>}
                              {dimesionItem.benchmarkScore !== undefined && <p className='dot-block'>.</p>}
                              {dimesionItem.benchMarkTwoScore !== undefined && !checkIfAnyFilterIsApplied() && <p>{this.getTrendSignedValue(dimesionItem.favScore - dimesionItem.benchMarkTwoScore)} trend</p>}
                              {dimesionItem.benchMarkTwoScore !== undefined && !checkIfAnyFilterIsApplied() && <p className='dot-block'>.</p>}
                              {dimesionItem.topicAverage !== undefined && <><p>{dimesionItem.topicAverage.toFixed(2)} average</p><p className='dot-block'>.</p></>}
                              <p>{`${dimesionItem.validCount || 0} responses`}</p>
                              {(item.positiveOutliers && item.negativeOutliers) && (!!item.positiveOutliers.length ||
                                !!item.negativeOutliers.length) &&
                                this.checkIfFilterAppliedOrTreeDataSelected() &&
                                this.showOutliersIcon(
                                  this.props.api.exploreTopicList
                                ) && <SVGIcons type={'SVG-explanation'} />}
                              {this.props.outcome &&
                                this.props.outcome === item.topic && (
                                  <p className='icon-block'>
                                    <SVGIcons type={'SVG-star'} />
                                    Outcome
                                  </p>
                                )}
                            </div>
                          }
                        </div>
                      </div>
                      {/* </Tooltip> */}
                    </>
                  ),
                  children: (
                    <PanelContent
                      topicStoryLoading={this.props.topicStoryLoading}
                      dimesionItem={dimesionItem}
                      key={item.topic}
                      data={item}
                      topic={dimesionItem.item}
                      topicListApi={this.props.api}
                      itemTableData={this.props.itemTableData}
                      showStoryboardProgress={
                        this.props.showStoryboardProgress
                      }
                    />
                  ),
                },
              ]}
            />
          );
        })}
      </>


    );
  }
}
