export const setPageNumberForSurveyPage = (state,payload)=>{
    if (payload.isFromFeedbackTab) {
        return {
            ...state,
            pageNumberFeedback: payload.pageNumber
        }
    }
    else if (payload.bothReset) {
        return {
            ...state,
            pageNumber: payload.pageNumber,
            pageNumberFeedback: payload.pageNumber
        }
    }
    else {
        return {
            ...state,
            pageNumber: payload.pageNumber
        }
    }

}