import React from "react";
import { Menu } from "antd";
import "./index.scss";
const { SubMenu } = Menu;

const MenuLeft = (props) => {
  return (
    <div className={`left-menu ${props.className ? props.className : ''}`}>
      <div className="left-menu__item">
        <Menu
          defaultSelectedKeys={props.defaultSelectedKeys}
          defaultOpenKeys={props.defaultOpenKeys}
          mode="inline"
          selectedKeys={props.selectedKeys}
          onClick={props.onClick}
        >
          {props.menuTitle ? titleWrapper(props) : props.subMenuTitle? subMenuWrapper(props): renderMenuItems(props)}
        </Menu>
      </div>
    </div>
  );
};

const titleWrapper = (props) => {
  return (
    <Menu.ItemGroup title={props.menuTitle}>
      {renderMenuItems(props)}
    </Menu.ItemGroup>)
}

const renderMenuItems = (props) => {
  return props.menuItems.map((item) => (
    <Menu.Item key={item.key} disabled={item.disabled}>{item.label}{item.extra}</Menu.Item>
  ))

}
const subMenuWrapper = (props) => {
  return (
    <SubMenu key={props.subMenuTitle}  title={props.subMenuTitle}>
    {renderMenuItems(props)}
  </SubMenu> )
}


export default MenuLeft;
