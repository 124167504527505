import React from 'react';
import { TreeSelectComponent } from '../../../../../../../../Components/TreeSelectComponent';
export const SelectTopic = (props) => {


  const handleTreeChange = (value, node) => {
    props.onSelectChange(node);
  }

  const getOptionList = () => {
    const dimensions = props.dimensions || [];
    if (props.isVerySimpleSurvey) {
      return dimensions[0] && dimensions[0].questions.map((item) => ({
        label: item.question,
        value: item.questionId,
        displayLabel: item.question,
        dimension: item.dimension,
        isQuestion: true
      }));
    } else {
      return dimensions.map((item) => ({
        label: item.dimension,
        value: item.dimension,
        displayLabel: item.dimension,
        dimension: item.dimension,
        children: item.questions ? item.questions.map(question => {
          let displayLabel = getDisplayLabel(item.dimension, question.question)
          return {
            label: question.question,
            value: question.questionId,
            dimension: item.dimension,
            displayLabel: displayLabel,
            isQuestion: true
          }
        }) : []
      }));
    }
  };

  const getDisplayLabel = (dimension, question) => {
    return `${dimension}: ${question}`
  }

  return (
    <div className='compare-demographics'>
      <div className='topic-chooser comments-input'>
        <TreeSelectComponent
          getPopupContainer={(trigger) => document.getElementById('demographics-dropdown')}
          placeholder={ props.isVerySimpleSurvey?"Select question":"Select Topic"}
          treeNodeLabelProp='displayLabel'
          className='topic-select'
          data={getOptionList()}
          value={props.selectedQuestionId || props.topic}
          onSelect={handleTreeChange}
          labelInValue={true}
        />
      </div>
    </div>
  );
};
