import { deepCopy } from "../../../../utils/deepCopy"
import { checkWhetherAllQuestionsAnswered } from "./updateAnswer"

export const onChangeRating = (state, payload) => {
    let changedQuestionSet = getChangedRatingQuestionSet(state.questionSet, payload, state)
    return ({
        ...state,
        questionSet: changedQuestionSet.questionSetCopy,
        indexDetails: changedQuestionSet.indexData,
        hideErrorBlock: changedQuestionSet.indexData.requiredSatisfied
    })
}

const getChangedRatingQuestionSet = (questionSet, payload, state) => {
    let questionSetCopy = deepCopy(questionSet)
    let questionBlock = {}
    let answer = payload.index
    questionSetCopy.forEach((item, index) => {
        if (item.blockId === payload.blockId) {
            questionBlock = item
            item.questions.forEach(question => {
                if (question.answer === payload.index) {
                    question.answer = ""
                    answer = ""
                }
                else {
                    question.answer = payload.index
                    answer = payload.index
                }

            })


        }
    })

    let indexData = checkWhetherAllQuestionsAnswered(questionSetCopy, questionBlock.questions[0], state.pageBreakAddedQuestionSet[state.currentIndex], state)
    return { questionSetCopy, indexData }

}