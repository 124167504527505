import {
    SURVEY_EDITOR_LIKERT_QUESTION_LABEL_CHANGE,
    SURVEY_EDITOR_LIKERT_QUESTION_ADD_FOLLOWUP,
    SURVEY_EDITOR_LIKERT_QUESTION_DELETE_QUESTION,
    SURVEY_EDITOR_LIKERT_QUESTION_ADD_QUESTION,
    SURVEY_EDITOR_LIKERT_QUESTION_DELETE_FOLLOWUP,
    SURVEY_EDITOR_LIKERT_QUESTION_CHANGE_FOLLOWUP_CONDITION,
    SURVEY_EDITOR_LIKERT_QUESTION_CHANGE_FOLLOWUP_LABEL,
    SURVEY_EDITOR_MULTIPLE_CHOICE_CHANGE_FOLLOWUP_LABEL,
    SURVEY_EDITOR_MULTIPLE_CHOICE_DELETE_FOLLOWUP
} from "../../../../../constants";


export const likertQuestionLabelChange = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_QUESTION_LABEL_CHANGE, payload });
    }
};

export const likertQuestionAddFollowUp = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_QUESTION_ADD_FOLLOWUP, payload });
    }
};

export const likertQuestionDeleteQuestion = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_QUESTION_DELETE_QUESTION, payload });
    }
};

export const likertQuestionAddQuestion = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_QUESTION_ADD_QUESTION, payload });
    }
};

export const likertQuestionDeleteFollowUp = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_QUESTION_DELETE_FOLLOWUP, payload });
    }
};

export const likertQuestionChangeFollowUpCondition = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_QUESTION_CHANGE_FOLLOWUP_CONDITION, payload });
    }
};

export const likertQuestionChangeFollowUpLabel = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_QUESTION_CHANGE_FOLLOWUP_LABEL, payload });
    }
};

export const multipleOptionChangeFollowUpLabel = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MULTIPLE_CHOICE_CHANGE_FOLLOWUP_LABEL, payload });
    }
};

export const multipleChoiceDeleteFollowUp = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MULTIPLE_CHOICE_DELETE_FOLLOWUP, payload });
    }
};
