import { nlpGetURLParams } from "../../../../utils/nlpURLUtils";

export function fetchNLPHeatmapStarted(state, action) {
  return {
    ...state,
    nlpHeatmapApi: {
      ...state.nlpHeatmapApi,
      loading: true,
      error: false
    },
    nlpHeatmapData: { ...state.nlpHeatmapData, ...action.data }
  };
}

export function fetchNLPHeatmapSuccess(state, action) {
  const { outcome } = nlpGetURLParams();
  let outcomeVariable = '';
  if (outcome && outcome !== 'undefined') {
    outcomeVariable = outcome
  }

  let columnData = action.data.data.columnData//.filter((item)=> dataMapResponseArr.includes(item.dataIndex))
  let topData = action.data.data.dataMap.filter((item) => item.name === "Responses" || item.name === outcomeVariable)
  let secondData = action.data.data.dataMap.filter((item) => item.name !== "Responses" && item.name !== outcomeVariable).sort((a, b) => a.name.localeCompare(b.name))
  let dataMap = [...topData, ...secondData];
  action.data.data.dataMap = dataMap;
  action.data.data.columnData = columnData;
  return {
    ...state,
    nlpHeatmapApi: {
      loading: false,
      data: action.data,
      error: false
    },
    nlpHeatmapData: { ...state.nlpHeatmapData, ...action.data }
  };
}

export function fetchNLPHeatmapFailed(state, action) {
  return {
    ...state,
    nlpHeatmapApi: {
      loading: false,
      error: true
    }
  }
}