import {
    makeKey,
    update,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
import {toast} from '../../../../utils/messageUtils';
function failureResponse(state, payload) {
    return makeKey(
        'uploadSurveyApi',
        update(state.uploadSurveyApi, {
            ...(payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload))
        })
    );
}
export function fileUploadFailed(state, payload) {
    let failureRes = failureResponse(state, payload);
    toast.error(failureRes.uploadSurveyApi.message);
    return failureRes;
}
