import { questionSetToBenchmarkFormat } from "../../../../utils/goalsUtils"

export const FetchBenchMarkDataStart = (state) => {
    return {
        ...state,
        BenchMarkDataApi: {
            isProcessing: true,
            data: null,
            error: false,
            message: ''
        }
    }

}

export const FetchBenchMarkDataSuccess = (state, payload) => {
    return {
        ...state,
        BenchMarkDataApi: {
            isProcessing: false,
            data: getBenchMarkData(payload),
            error: false,
            message: payload.message
        },
        isBenchMarkDataAlreadyFetched: true
    }

}

export const FetchBenchMarkDataFailed = (state, payload) => {
    return {
        ...state,
        BenchMarkDataApi: {
            isProcessing: false,
            data: null,
            error: payload.error,
        },
    }

}

const getBenchMarkData = (response) => {
    let benchmarkData = []
    if (response.data && response.data.data && response.data.data.response) {
        benchmarkData = response.data.data.response
    }
    if (benchmarkData.length === 0) {
        benchmarkData = questionSetToBenchmarkFormat(response.projectDetails.questionSet, response.projectDetails.projectType)
    }
    return benchmarkData
}
