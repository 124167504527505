import { customFormChangeSuccess } from "../../../../utils/reducerOperations";
import {  apiInitialStateAddFn } from "../../../../utils/apiStateUtils";
import { fetchFormConfigurations } from "../../../inputFormConfigurations";
export function changePasswordFormChange(state, payload) {
  return customFormChangeSuccess(
    { state: state, key: "passwordFormConfiguration" },
    payload
  );
}

export function clearChangePasswordForm(state, payload) {
  return {
    ...state,
    changePasswordApi: {
      ...apiInitialStateAddFn(),
      isProcessing: state.changePasswordApi && state.changePasswordApi.isProcessing
    },
    isFormValid: false,
    passwordFormConfiguration: fetchFormConfigurations([
      "password",
      "newPassword",
      "confirm",
    ]),
  };
}

