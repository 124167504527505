import { deepCopy } from "../../../../../utils/deepCopy"

export const moveUporMoveDown = (state, payload) => {
    let goals = moveUpOrMoveDownTopicsInGoals(state.goals, payload)
    return {
        ...state,
        goals: goals
    }
}

const moveUpOrMoveDownTopicsInGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    let fromIndex = payload.fromIndex
    let toIndex = payload.toIndex
    let replaceElement = goalsCopy[fromIndex];
    let element = goalsCopy[toIndex]
    goalsCopy[fromIndex] = element
    goalsCopy[toIndex] = replaceElement
    return goalsCopy
}

export const moveUporMoveDownOpenEndedQuestions = (state, payload) => {
    let goals = moveUpOrMoveDownOPenEndedQuestionsInGoals(state.goals, payload)
    return {
        ...state,
        goals: goals
    }
}

const moveUpOrMoveDownOPenEndedQuestionsInGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    let fromIndex = payload.fromIndex
    let toIndex = payload.toIndex
    goalsCopy.forEach(item => {
        if (item.goalId === "comments") {
            let replaceElement = item.questions[fromIndex];
            let element = item.questions[toIndex]
            item.questions[fromIndex] = element
            item.questions[toIndex] = replaceElement
        }
    })

    return goalsCopy
}

export const moveUporMoveDownDemographicsQuestions = (state, payload) => {
    let goals = moveUporMoveDownDemographicsQuestionsInGoals(state.goals, payload)
    return {
        ...state,
        goals: goals
    }
}

const moveUporMoveDownDemographicsQuestionsInGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    let fromIndex = payload.fromIndex
    let toIndex = payload.toIndex
    goalsCopy.forEach(item => {
        if (item.goalId === "demographics") {
            let replaceElement = item.questions[fromIndex];
            let element = item.questions[toIndex]
            item.questions[fromIndex] = element
            item.questions[toIndex] = replaceElement
        }
    })

    return goalsCopy
}

export const moveUporMoveDownMultipleSelectedQuestions = (state, payload) => {
    let goals = moveUporMoveDownMultipleSelectedQuestionsInGoals(state.goals, payload)
    return {
        ...state,
        goals: goals
    }
}

const moveUporMoveDownMultipleSelectedQuestionsInGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    let fromIndex = payload.fromIndex
    let toIndex = payload.toIndex
    goalsCopy.forEach(item => {
        if (item.goalId === "multipleOption") {
            let replaceElement = item.questions[fromIndex];
            let element = item.questions[toIndex]
            item.questions[fromIndex] = element
            item.questions[toIndex] = replaceElement
        }
    })

    return goalsCopy
}