import * as actionTypes from "../../constants";
import { INSIGHTS_URL } from "../../../../config/constants";
import { ApiBase } from "../../../utils/apiBase";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';
export * from './numericInsightsActionHelper';

export const fetchNLPinsights = (id, payload) => {
  return (dispatch) => {
    const axios = new ApiBase().instance;
    dispatch({ type: actionTypes.FETCH_NLP_INSIGHTS_START });
    axios
      .post(INSIGHTS_URL, {
        ...payload,
        ...getProjectIdAndOrgId(payload),
        selectedQuestionId: id
      })
      .then((res) => fetchInsightDataSuccess({ dispatch, res }))
      .catch((err) => fetchInsightDataFailed(dispatch, err));
  };
};

function fetchInsightDataSuccess({ dispatch, res }) {
  return dispatch({
    type: actionTypes.FETCH_NLP_INSIGHTS_SUCCESS,
    data: res.data,
  });
}

function fetchInsightDataFailed(dispatch, err) {
  return dispatch({
    type: actionTypes.FETCH_NLP_INSIGHTS_FAIL,
    error: err.message,
  });
}
