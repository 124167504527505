import { deepCopy } from "../../../../../../utils/deepCopy"
import { generateStaticQuestionId } from "../../../../../../utils/goalsUtils"
import { v4 as uuidv4 } from "uuid";
import { getHTMLRemovedQuestion } from "../helpers";

export const likertQuestionLabelChange = (state, payload) => {
    let updatedGoals = getQuestionLabelChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getQuestionLabelChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    let staticQuestionSet = payload.value === "" ? uuidv4() : generateStaticQuestionId(getHTMLRemovedQuestion(payload.value))
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            element.questions[0].matrixQuestions[payload.index].text = getHTMLRemovedQuestion(payload.value)
            element.questions[0].matrixQuestions[payload.index].HTMLText = payload.value
            element.questions[0].matrixQuestions[payload.index].staticQuestionId = staticQuestionSet
            element.questions[0].matrixQuestions[payload.index].followUpQuestions &&
                element.questions[0].matrixQuestions[payload.index].followUpQuestions.forEach(followUp => {
                    if (followUp.type === "Matrix") {
                        followUp.matrixQuestions[0].selectedQuestionStaticId = staticQuestionSet
                    } else {
                        followUp.selectedQuestionStaticId = staticQuestionSet
                    }
                })
        }
    });
    return goalsCopy
}

export const likertQuestionChangeFollowUpCondition = (state, payload) => {
    let updatedGoals = getQuestionConditionChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getQuestionConditionChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            element.questions[0].matrixQuestions[payload.matrixIndex].followUpQuestions[payload.followUpIndex].condition = payload.value
        }
    });
    return goalsCopy
}

export const likertQuestionChangeFollowUpLabel = (state, payload) => {
    let updatedGoals = getFollowupQuestionLabelChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getFollowupQuestionLabelChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.matrixSection) {
                element.questions[0].matrixQuestions[payload.matrixIndex].followUpQuestions[payload.followUpIndex].matrixQuestions[0].HTMLText = payload.value
                element.questions[0].matrixQuestions[payload.matrixIndex].followUpQuestions[payload.followUpIndex].matrixQuestions[0].text = getHTMLRemovedQuestion(payload.value)
                element.questions[0].matrixQuestions[payload.matrixIndex].followUpQuestions[payload.followUpIndex].matrixQuestions[0].staticQuestionId = generateStaticQuestionId(payload.value)
            } else if (payload.description) {
                element.questions[0].matrixQuestions[payload.matrixIndex].followUpQuestions[payload.followUpIndex].questionLabel = payload.value
            } else {
                element.questions[0].matrixQuestions[payload.matrixIndex].followUpQuestions[payload.followUpIndex].HTMLQuestion = payload.value
                element.questions[0].matrixQuestions[payload.matrixIndex].followUpQuestions[payload.followUpIndex].question = getHTMLRemovedQuestion(payload.value)
            }

        }
    });
    return goalsCopy
}

export const likertQuestionDeleteFollowUp = (state, payload) => {
    let updatedGoals = getQuestionFollowUpDeletedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getQuestionFollowUpDeletedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            let followUp = element.questions[0].matrixQuestions[payload.matrixIndex].followUpQuestions
            followUp.splice(payload.followUpIndex, 1)
            element.questions[0].matrixQuestions[payload.matrixIndex].haveFollowUpQuestion = element.questions[0].matrixQuestions[payload.matrixIndex].followUpQuestions && element.questions[0].matrixQuestions[payload.matrixIndex].followUpQuestions.length > 0 ? true : false
        }
    });
    return goalsCopy
}

export const likertQuestionAddFollowUp = (state, payload) => {
    let updatedGoals = getFollowUpAddedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}

const getFollowUpAddedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            let followupQuestion = {}
            if (payload.matrixType) {
                followupQuestion = getMatrixFollowUpQuestion(element.questions[0].matrixQuestions[payload.index], element)
            } else {
                followupQuestion = getTextEntryFollowUpQuestion(element.questions[0].matrixQuestions[payload.index], element)
            }
            let followup = element.questions[0].matrixQuestions[payload.index].followUpQuestions && Array.isArray(element.questions[0].matrixQuestions[payload.index].followUpQuestions)
                ? [...element.questions[0].matrixQuestions[payload.index].followUpQuestions, followupQuestion]
                : [followupQuestion]
            element.questions[0].matrixQuestions[payload.index].followUpQuestions = followup
            element.questions[0].matrixQuestions[payload.index].haveFollowUpQuestion = true
        }
    });
    return goalsCopy
}

export const likertQuestionAddQuestion = (state, payload) => {
    let updatedGoals = getQuestionAddedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getQuestionAddedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            element.questions[0].matrixQuestions.splice(element.questions[0].matrixQuestions.length, 0, payload.data)
        }
    });
    return goalsCopy
}

export const likertQuestionDeleteQuestion = (state, payload) => {
    let updatedGoals = getQuestionDeleteedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getQuestionDeleteedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    let newMatrix = []
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            element.questions[0].matrixQuestions.forEach((question, index) => {
                if (index !== payload.index) {
                    newMatrix.push(question)
                }
            })
            element.questions[0].matrixQuestions = newMatrix
        }
    });
    return goalsCopy
}


const getTextEntryFollowUpQuestion = (matrixData, topicDetails) => {
    return ({
        condition: [],
        dimension: "Comments",
        topicDimension: topicDetails.dimension,
        isFollowUpQuestion: true,
        question: 'Any Comments?',
        options: [],
        type: "Text Entry",
        value: "",
        qid: uuidv4(),
        questionLabel: `${topicDetails.dimension} - Any Comments?`,
        isSelected: true,
        staticQuestionId: generateStaticQuestionId(`Any Comments${topicDetails.dimension}`),
        selectedQuestionStaticId: matrixData.staticQuestionId,
        selectedTopic: topicDetails.dimension,
        selectedQid: topicDetails.questions[0].qid,
        goalId: `${topicDetails.goalId + uuidv4()}`,
        selectedGoalId: topicDetails.goalId,
        selectedBlockId: topicDetails.blockId
    })
}

const getMatrixFollowUpQuestion = (matrixData, blockData) => {
    return (
        {
            question: "",
            optionsCount: 5,
            options: [
                {
                    label: "Strongly disagree",
                    value: "1"
                },
                {
                    label: "Neither agree nor disagree",
                    value: "2"
                },
                {
                    label: "Strongly agree",
                    value: "3"
                }
            ],
            matrixQuestions: [
                {
                    selectedQuestionStaticId: matrixData.staticQuestionId,
                    selectedTopic: blockData.dimension,
                    staticQuestionId: generateStaticQuestionId(`Enter followup question${uuidv4()}`),
                    isSelected: true,
                    text: "Enter followup question",
                    value: uuidv4()
                },
            ],
            isFollowUpQuestion: true,
            selectedGoalId: blockData.goalId,
            questionDimension: blockData.dimension,
            type: "Matrix",
            selectedQid: blockData.questions[0].qid,
            condition: [],
            qid: uuidv4(),
            goalId: `${blockData.goalId + uuidv4()}`,
            questionLabel: `${blockData.goalId}-follow-up`,
            isFollowUpFromQuestionLevel: true,
            selectedBlockId: blockData.blockId
        }
    )
}

export const multipleOptionFollowUpLabelChange = (state, payload) => {
    let updatedGoals = getFollowupQuestionLabelChangedGoalsForMultipleOption(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}



const getFollowupQuestionLabelChangedGoalsForMultipleOption = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.matrixSection) {
                element.questions[0].options[payload.optionIndex].followUpQuestions[payload.followupIndex].matrixQuestions[0].HTMLText = payload.value
                element.questions[0].options[payload.optionIndex].followUpQuestions[payload.followupIndex].matrixQuestions[0].text = getHTMLRemovedQuestion(payload.value)
                element.questions[0].options[payload.optionIndex].followUpQuestions[payload.followupIndex].matrixQuestions[0].staticQuestionId = generateStaticQuestionId(payload.value)
            } else if (payload.description) {
                element.questions[0].options[payload.optionIndex].followUpQuestions[payload.followupIndex].questionLabel = payload.value
            } else {
                element.questions[0].options[payload.optionIndex].followUpQuestions[payload.followupIndex].HTMLQuestion = payload.value
                element.questions[0].options[payload.optionIndex].followUpQuestions[payload.followupIndex].question = getHTMLRemovedQuestion(payload.value)
            }

        }
    });
    return goalsCopy
}


export const multipleChoiceDeleteFollowUp = (state, payload) => {
    let updatedGoals = getMultipleChoiceFollowUpDeletedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}

const getMultipleChoiceFollowUpDeletedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            let followUp = element.questions[0].options[payload.optionIndex].followUpQuestions
            followUp.splice(payload.followupIndex, 1)
            // element.questions[0].options[payload.optionIndex].haveFollowUpQuestion = element.questions[0].matrixQuestions[payload.optionIndex].followUpQuestions && element.questions[0].matrixQuestions[payload.optionIndex].followUpQuestions.length > 0 ? true : false
        }
    });
    return goalsCopy
}

export const redirectConditionValue = (state, payload) => {
    let updatedGoals = handleAddRedirectionCondition(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}

const handleAddRedirectionCondition = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            element.questions[0].options[payload.optionIndex].followUpQuestions[payload.followupIndex].condition = [ {redirectionBlockId: payload.value} ]
        }
    });
    return goalsCopy

}