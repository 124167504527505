import React from "react";
import SearchInput from "../../Components/SearchItem";
export class TableSearch extends React.Component {
  onInputChange = (event) => {
    this.props.onInputChange({
      index: this.props.index,
      event: event,
    });
  };
  render() {
    const { searchConfig, onSearch } = this.props;
    return (
      <>
        <SearchInput
          key={searchConfig.name}
          value={searchConfig.value}
          placeholder={searchConfig.placeholder}
          onPressEnter={onSearch}
          onChange={this.onInputChange}
          clearSearch={this.props.clearSearch}
        />
      </>
    );
  }
}
