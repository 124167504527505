import {
  FETCH_SURVEY_DETAILS_STARTED,
  FETCH_SURVEY_DETAILS_SUCCESS,
  FETCH_SURVEY_DETAILS_FAILED,
} from '../../constants';
import { LIST_SURVEY_URL } from '../../../../config/constants';
import {
  getSurveyListQueryParams,
  listSurveyCommonBody,
} from '../../../utils/actionOperations';
import { ApiBase } from '../../../utils/apiBase';
import { getOrgIdFromUrl } from '../../../utils/urlProcessor';

export const fetchSurveyDetails = (params, cb) => {
  const orgId = getOrgIdFromUrl();
  const fetchProps = getSurveyListQueryParams(params);
  const axios = new ApiBase().instance;
  return (dispatch) => {
    dispatch({ type: FETCH_SURVEY_DETAILS_STARTED });
    axios
      .post(LIST_SURVEY_URL, {
        ...listSurveyCommonBody(true, orgId),
        ...fetchProps,
        searchText: params.projectId,
      })
      .then((res) => {
        dispatch({
          type: FETCH_SURVEY_DETAILS_SUCCESS,
          payload: { data: res && res.data && res.data.data },
        });
        cb && cb(res && res.data && res.data.data);
      })
      .catch((err) =>
        dispatch({
          type: FETCH_SURVEY_DETAILS_FAILED,
          payload: { error: err && err.message },
        })
      );
  };
};
