import * as actionTypes from '../../../constants';
import { TRANSLATE_TEXT } from "../../../../../config/constants";
import { ApiBase } from '../../../../utils/apiBase';

export function translateSurvey(params, cb) {
    //     let translatedAboutSurvey;
    // if (params.translatedAboutSurvey) {
    //     translatedAboutSurvey = params.translatedAboutSurvey
    //     delete params.translatedAboutSurvey
    // }
    return dispatch => {
        dispatch({ type: actionTypes.SURVEY_PAGE_TRANSLATION_START });
        const axios = new ApiBase().instance;
        axios.post(TRANSLATE_TEXT, { ...params })
            .then(translateTextSuccess.bind(null, dispatch, cb, params))
            .catch(translateTextFailed.bind(null, dispatch, cb));
    };

}

function translateTextSuccess(dispatch, cb, translatedAboutSurvey, res) {
    dispatch({
        type: actionTypes.SURVEY_PAGE_TRANSLATION_SUCCESS,
        payload: res.data,

    });
    if (translatedAboutSurvey) cb && cb({ translatedData: { translatedDescription: translatedAboutSurvey } });
    else cb && cb(res.data && res.data.data);
};

function translateTextFailed(dispatch, cb, error) {
    dispatch({
        type: actionTypes.SURVEY_PAGE_TRANSLATION_FAIL,
        error: error.response && error.response.data.message
    });
    cb && cb();
};