import { deepCopy } from "../../../../utils/deepCopy"

export const modifyProjectDataStart = (state, payload) => {
    let payloadData = payload && payload.hrisFileName ? deepCopy(payload) : {}
    let dropDownValue = getpresetDatatInDropDownFormat({ ...payloadData })
    if (payload) {
        if (payload.isFromHRISUpload) {
            return {
                ...state,
                modifyProjectDataApi: {
                    ...state.modifyProjectDataApi,
                    isProcessing: true,
                },
                surveyWizardProjectDetailsApi: {
                    ...state.surveyWizardProjectDetailsApi,
                    hrisData: { ...payloadData }
                },
                surveyWizardHrisFIleApi: {
                    ...state.surveyWizardHrisFIleApi,
                    data: {
                        response: {
                            hrisFilesList: [
                                { ...dropDownValue }
                            ]
                        }
                    }
                }
            }
        }
        if (payload.hrisFileId) {
            return {
                ...state,
                modifyProjectDataApi: {
                    ...state.modifyProjectDataApi,
                    isProcessing: true,
                },
                surveyWizardProjectDetailsApi: {
                    ...state.surveyWizardProjectDetailsApi,
                    hrisData: { ...payload }
                }
            }
        } else {
            return {
                ...state,
                modifyProjectDataApi: {
                    ...state.modifyProjectDataApi,
                    isProcessing: true,
                },
                surveyWizardProjectDetailsApi: {
                    ...state.surveyWizardProjectDetailsApi,
                    data: {
                        ...state.surveyWizardProjectDetailsApi.data,
                        ...payload
                    },
                }
            };
        }
    } else {
        return {
            ...state,
            modifyProjectDataApi: {
                ...state.modifyProjectDataApi,
                isProcessing: true,
            }
        };
    }

}

export const modifyProjectDataSuccess = (state, payload) => {
    return {
        ...state,
        modifyProjectDataApi: {
            isProcessing: false,
            data: payload.data,
            error: false,
            message: payload.message
        },
    };
}

export const modifyProjectDataFail = (state, payload) => {
    return {
        ...state,
        modifyProjectDataApi: {
            isProcessing: false,
            error: true,
            data: false,
            message: payload.message,
        },
    };
};


const getpresetDatatInDropDownFormat = (payload) => {
    if (payload.hrisFileId) {
        return {
            hrisFileId: payload.hrisFileId,
            hrisFileName: payload.hrisFileName,
        }
    } else {
        return {}
    }

}
