import { ApiBase } from "../../../utils/apiBase";
import { FETCH_HRIS_HIERARCHY } from "../../../../config/constants";
import {
  FETCH_HRIS_HIERARCHY_STARTED,
  FETCH_HRIS_HIERARCHY_SUCCESS,
  FETCH_HRIS_HIERARCHY_FAILED,
} from "../../constants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchHrisHierarchy = (params) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(fetchHrisHierarchyStart());
    apiBaseInstance.instance
      .post(FETCH_HRIS_HIERARCHY, { ...params, ...getProjectIdAndOrgId(params) })
      .then((res) => dispatch(fetchHrisHierarchySuccess(res)))
      .catch((err) => dispatch(fetchHrisHierarchyFailure(err)));
  };
};

export const fetchHrisHierarchyStart = () => {
  return {
    type: FETCH_HRIS_HIERARCHY_STARTED,
  };
};

export const fetchHrisHierarchySuccess = (res) => {
  return {
    type: FETCH_HRIS_HIERARCHY_SUCCESS,
    payload: res && res.data ? res.data : [],
  };
};
export const fetchHrisHierarchyFailure = (error) => {
  return {
    type: FETCH_HRIS_HIERARCHY_FAILED,
    payload: error,
  };
};
