import React from "react";
import constants from "./constants";
import { CustomTable } from "../../CustomTable";


export default class OutliersTableData extends React.Component {
  fetchTableColums = (columns) => {
    let wholeOrgRemoved =[]
    if (!this.props.trendData) {
     let filterData= columns.filter((item) => !(item.dataIndex === "trend" || item.key==="vsWholeOrg"))
     if(this.props.insightsTrendColomns){
      return [...filterData,...this.props.insightsTrendColomns]
     }
     else{
      return filterData;
     }
    } else {
      if(this.props.insightsTrendColomns){
        wholeOrgRemoved=columns.filter((item)=>item.key!=="vsWholeOrg")
        return [...wholeOrgRemoved,...this.props.insightsTrendColomns]
      }
      return columns;
    }
  };
 
  render() {
    return (
      <>
        <CustomTable
          className="no-space"
          dataSource={this.props.data}
          columns={this.fetchTableColums(
            constants.tableColumnPositiveOutLiers(this.props.tableProps)
          )}
          onChange={this.props.onChange}
          pagination={false}
          loading={this.props.loading}
        />
      </>
    );
  }
}
