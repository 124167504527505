import React from "react";
import { Popconfirm, Table, Empty } from "antd";
import constants from "./constants";
import TableLoader from "../../../../../../Components/ContentLoaders/TableLoader";
import RenderComponent from "../../../../../../Components/RenderChildPage";
import {
  isJobCompleted,
  isJobProcessing,
  disableFieldByOperationType,
  isJobProcessed,
} from "../../helper";
import { addTimeZonePicker } from "./children/addTimezonePicker";
import { getColumnSearchProps } from "./children/addSearch";
import BatchOperation from "./children/batchOperation";
import { FileUpload } from "../../children/FileUpload";
import { addUserColumn, addDownloadButton } from "./children/addUserCoulmn";
import { ButtonElement } from "../../../../../../FormElements/ButtonElement";
import { DeleteButton } from "../../../../../../FormElements/DeleteButton";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import TopicLibraryUpload from "../TopicLibraryReprocess";
import { LoaderButton } from "../../../../../../FormElements/LoaderButton";
import {
  GetLocalStorage,
} from "../../../../../../utils/localStorgeOperations";
import { TEXT_ANALYSIS_PAGE_SECTION } from '../../../../../../../config/tabConstants';
class RenderBatchIds extends React.Component {
  constructor(props) {
    super(props);
    this.state = constants.defaultState;
    this.props.fetchData({ currentTab: TEXT_ANALYSIS_PAGE_SECTION.TEXT_ANALYSIS_TABLE, });
  }
  getTimezonePicker = () => addTimeZonePicker.bind(this)();
  getColumnSearchProps = (field) => getColumnSearchProps.bind(this)(field);
  getColumns = () => {
    let tableColumns = constants.columns({
      searchProp: this.getColumnSearchProps,
      getTimeZonePicker: this.getTimezonePicker,
      isAdmin: this.props.isAdmin,
    });
    tableColumns = addUserColumn(tableColumns, this.props.isAdmin);
    tableColumns = this.addApprovalColumn(tableColumns);
    tableColumns = addDownloadButton({
      columns: tableColumns,
      isAdmin: this.props.isAdmin,
      downloadByBatchId: this.downloadByBatchId,
      getDownloadingState: this.getDownloadingState,
    });

    tableColumns = this.addReprocessButon(tableColumns);
    tableColumns = this.addDeletButton(tableColumns);
    return tableColumns;
  };
  downloadByBatchId = (doc) => {
    this.setState({ downloadId: doc.projectId });
    let payload = {};
    payload.projectId = doc.projectId;
    payload.projectName = doc.projectName;
    this.props.downloadComments(payload);
  };
  getDownloadingState = (id) =>
    this.state.downloadId === id && this.props.downloadApi.loading;

  addApprovalColumn(columns) {
    if (this.props.isAdmin) {
      columns.push({
        title: constants.approval,
        render: (_, doc) => (
          <LoaderButton
            data-test="login-btn"
            disabled={!!isJobCompleted(doc.projectStatus)}
            loader={this.getLoadingState(doc)}
            onClick={this.onClickApprove.bind(null, doc)}
            classList={["loader-button"]}
            loaderClass={["xs white"]}
            text={
              isJobProcessed(doc.projectStatus)
                ? constants.approved
                : constants.approve
            }
          />
        ),
      });
    }
    return columns;
  }
  toggleLoading = () => {
    this.setState({ isProcess: false });
    this.props.fetchData({ currentTab: TEXT_ANALYSIS_PAGE_SECTION.TEXT_ANALYSIS_TABLE, });
  };
  onHandleSubmit = (projectId) => {
    this.setState({ isProcess: true });
    this.props.deleteBatchId(
      {
        userId: GetLocalStorage("userId"),
        userEmail: GetLocalStorage("username"),
        projectId: projectId,
      },
      this.toggleLoading()
    );
  };
  addDeletButton = (columns) => {
    const { popup } = constants;
    const jsx = (_, record) => {
      return (
        <Popconfirm
          placement="topRight"
          title={popup.title}
          //onCancel={() =>this.props.deleteBatchId(record.projectId)}
          onCancel={() => this.onHandleSubmit(record.projectId)}
          okText={popup.cancelText}
          cancelText={popup.okText}
          okButtonProps={{ type: "default" }}
          cancelButtonProps={{ type: "primary" }}
        >
          <DeleteButton
            className="table-btn"
            title={constants.deleteTooltip(
              isJobProcessing(record.projectStatus)
            )}
            disabled={isJobProcessing(record.projectStatus)}
            type={popup.btnType}
            children={popup.btnText}
            onMouseEnter={() => {}}
            onMouseLeave={() => {}}
            classList={["icon"]}
            iconName={faTrashAlt}
          />
        </Popconfirm>
      );
    };
    columns.push({ title: constants.delete, render: jsx });
    return columns;
  };
  addReprocessButon = (columns) => {
    if (this.props.isAdmin) {
      columns.push({
        title: "Reprocess",
        render: (_, doc) => (
          <ButtonElement
            className="table-btn"
            title={
              isJobCompleted(doc.projectStatus)
                ? constants.reprocessTooltipCompoleted(
                    isJobCompleted(doc.projectStatus)
                  )
                : constants.reprocessTooltip(isJobCompleted(doc.projectStatus))
            }
            onClick={this.onClickReprocess.bind(null, doc)}
            disabled={
              isJobProcessing(doc.projectStatus) ||
              !!isJobCompleted(doc.projectStatus)
            }
            children={constants.reprocess}
          />
        ),
      });
    }
    return columns;
  };
  // eslint-disable-next-line
  getLoadingState = ({ projectId: projectId }) =>
    this.state.approveId === projectId && this.props.approveApi.loading;

  onClickApprove = (doc) => {
    this.setState({ approveId: doc.projectId });
    this.props.onApprove({ ...doc, currentTab: TEXT_ANALYSIS_PAGE_SECTION.TEXT_ANALYSIS_TABLE });
  };
  onClickReprocess = (doc) => {
    this.setState({
      showReprocessUpload: true,
      selectedBatchId: doc.projectId,
      selectedDocument: doc,
    });
  };
  onUploadFile = () => {
    this.setState({ showReprocessUpload: false });
    this.props.submitReprocessFile(this.state.selectedBatchId);
  };
  descriptionText() {
    return <p className="description">{constants.description}</p>;
  }
  topicFileUpload = () => {
    return (
      <FileUpload
        index={1}
        onUpload={this.props.onReprocessUpload}
        api={this.props.reprocessUploadApi}
        title={"Choose file"}
      />
    );
  };
  submitButton = () => {
    const { reprocessFilesApi } = this.props;
    return (
      <div className="reprocess-btn">
        <ButtonElement
          className="table-btn"
          title={"REPROCESS"}
          onClick={this.onUploadFile}
          children={constants.reprocess}
          loading={reprocessFilesApi.loading}
        />
      </div>
    );
  };

  onCancelReprocess = () => {
    this.setState({
      selectedDocument: undefined,
    });
  };
  renderModal = () => {
    return (
      this.state.selectedDocument && (
        <TopicLibraryUpload
          selectedDocument={this.state.selectedDocument}
          title={this.descriptionText()}
          onCancel={this.onCancelReprocess}
        />
      )
    );
  };
  getRowSelectionProps = () => {
    if (!this.state.activeBatchOperation) return null;
    return {
      type: "checkbox",
      onChange: (_, selectedRows) => this.setState({ selectedRows }),
      getCheckboxProps: (record) => ({
        disabled: disableFieldByOperationType(
          this.state.activeBatchOperation,
          record
        ),
      }),
    };
  };
  clearBatchOperation = () =>
    this.setState({
      activeBatchOperation: null,
      selectedRows: [],
    });
  setBatchOperation = (activeBatchOperation) =>
    this.setState({ activeBatchOperation });

  renderBatchIdList = () => (
    <Table
      rowKey="projectId"
      rowSelection={this.getRowSelectionProps()}
      dataSource={this.props.batchIdsApi.data}
      columns={this.getColumns()}
      pagination={false}
      loading={this.state.isProcess || this.props.batchIdsApi.loading}
    />
  );

  getBatchOperationProps = () => ({
    clearBatchOperation: this.clearBatchOperation,
    setBatchOperation: this.setBatchOperation,
    selectedRows: this.state.selectedRows,
    batchApprove: this.props.batchApprove,
    batchDelete: this.props.batchDelete,
    batchDeleteApi: this.props.batchDeleteApi,
    batchApprovalApi: this.props.batchApprovalApi,
    isAdmin: this.props.isAdmin,
  });
  render() {
    return (
      <div className="analytics-reports">
        <h3 children={constants.tableTitle} />
        <BatchOperation {...this.getBatchOperationProps()} />
        {this.renderModal()}
        <RenderComponent
          error={constants.errorMessage}
          emptyComponent={<Empty />}
          children={this.renderBatchIdList}
          loader={<TableLoader />}
          api={this.props.batchIdsApi}
        />
      </div>
    );
  }
}
export default RenderBatchIds;
