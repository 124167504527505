import { initialState } from "./initialState";
import {
  FETCH_DEMOGRAPHICS_DATA_START,
  FETCH_DEMOGRAPHICS_DATA_SUCCESS,
  FETCH_DEMOGRAPHICS_DATA_FAIL,
  CLEAR_EXPLORE_DEMOGRAPHICS_REDUCER,
  FETCH_DEMOGRAPHICS_DIMENSIONS_STARTED,
  FETCH_DEMOGRAPHICS_DIMENSIONS_SUCCESS,
  FETCH_DEMOGRAPHICS_DIMENSIONS_FAILED,
  UPDATE_DEMOGRAPHIC_REDUCER,
  EXPLORE_FETCH_OUTLIERS_DATA_START,
  EXPLORE_FETCH_OUTLIERS_DATA_SUCCESS,
  EXPLORE_FETCH_OUTLIERS_DATA_FAILED,
} from "../../constants";
import {
  fetchDemographicsDataStarted,
  fetchDemographicsDataSuccess,
  fetchDemographicsDataFailed,
  resetDemograhicValues,
  fetchDimensionsStarted,
  fetchDimensionsSuccess,
  fetchDimensionsFailed,
  updateDemographicReducer,
  fetchOutliersStarted,
  fetchOutliersSuccess,
  fetchOutliersFailed
} from "./helper";

const ExploreDemographicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEMOGRAPHICS_DATA_START:
      return fetchDemographicsDataStarted(state, action.payload);
    case FETCH_DEMOGRAPHICS_DATA_SUCCESS:
      return fetchDemographicsDataSuccess(state, action.payload);
    case FETCH_DEMOGRAPHICS_DATA_FAIL:
      return fetchDemographicsDataFailed(state, action.payload);
    case CLEAR_EXPLORE_DEMOGRAPHICS_REDUCER:
      return resetDemograhicValues(state, action.payload);
    case FETCH_DEMOGRAPHICS_DIMENSIONS_STARTED:
      return fetchDimensionsStarted(state, action.payload);
    case FETCH_DEMOGRAPHICS_DIMENSIONS_SUCCESS:
      return fetchDimensionsSuccess(state, action.payload);
    case FETCH_DEMOGRAPHICS_DIMENSIONS_FAILED:
      return fetchDimensionsFailed(state, action.payload);
    case UPDATE_DEMOGRAPHIC_REDUCER:
      return updateDemographicReducer(state, action.payload);
    case EXPLORE_FETCH_OUTLIERS_DATA_START:
      return fetchOutliersStarted(state);
    case EXPLORE_FETCH_OUTLIERS_DATA_SUCCESS:
      return fetchOutliersSuccess(state, action.payload);
    case EXPLORE_FETCH_OUTLIERS_DATA_FAILED:
      return fetchOutliersFailed(state, action.payload);
    default:
      return state;
  }
};

export default ExploreDemographicsReducer;
