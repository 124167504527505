import {
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_SUCCESS,
  CREATE_PROJECT_START,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAIL,
  FETCH_PROJECTS_FAIL,
  SET_ORGANIZATION_PROJECT_COUNT
} from "../../constants";
import { ApiBase } from "../../../utils/apiBase";
import {
  CREATE_SURVEY_URL,
  LIST_SURVEY_URL,
} from "../../../../config/constants";
import { filterSurveyList } from "../../../utils/filterSurveyList";
import { getDomainName } from "../../../utils/getDomainName";
import { getOrgId } from '../../../utils/localStorgeOperations';

export function fetchProjects({ type }) {
  return (dispatch) => {
    const Axios = new ApiBase().instance;
    dispatch({ type: FETCH_PROJECTS_START });
    Axios.post(LIST_SURVEY_URL, {
      status: type,
    })
      .then((res) => {
        const data = filterSurveyList(formatProjects(res.data.data));
        dispatch({
          type: FETCH_PROJECTS_SUCCESS,
          data: { ...res.data, data },
        });
      })
      .catch((e) =>
        dispatch({
          type: FETCH_PROJECTS_FAIL,
          error: e,
        })
      );
  };
}

export function formatProjects(data) {
  return (
    data.hits &&
    data.hits.map((item) => ({
      projectId: item.projectId,
      createdDate: item.projectCreationDate,
      projectModifiedDate: item.projectModifiedDate || item.projectCreationDate,
      projectName: item.projectName,
      status: item.status,
      questionsCount: item.questionCount,
      blockCount: item.blockCount,
    }))
  );
}

export function createProject(payload, callback) {
  const data = {
    projectName: payload.projectName,
    projectStatus: "new",
    questionCount: 0,
    hrisFileId: payload.hrisFileId,
    hrisFileName: payload.hrisFileName,
    questionSetTemplate: payload.template,
    surveyEndDate: payload.surveyEndDate,
    organizationId: payload.orgId || getOrgId(),
    sourceDomainName: getDomainName(),
    aboutSurvey: payload.aboutSurvey,
    isAnonymous: payload.isAnonymous,
    surveyLaunchDate: payload.surveyLaunchDate,
    isLocalizationEnabled: payload.isLocalizationEnabled,
    translateAboutSurvey: payload.translateAboutSurvey
  };
  return (dispatch) => {
    dispatch({ type: CREATE_PROJECT_START });
    const axios = new ApiBase().instance;
    axios
      .post(CREATE_SURVEY_URL, { ...data })
      .then(createProjectSuccess.bind(null, dispatch, callback, payload))
      .catch(createProjectFailed.bind(null, dispatch));
  };
}

function createProjectSuccess(dispatch, callback, payload, res) {
  dispatch({
    type: CREATE_PROJECT_SUCCESS,
    response: res.data,
  });
  dispatch(setProjectsCount());
  if (!callback) {
    let redirectPath = window.location.href;
    redirectPath = redirectPath + "/projects/edit-project?projectId=" + res.data.data.projectId;
    window.location.href = redirectPath;
  } else {
    callback(res.data);
  }
}

function createProjectFailed(dispatch, error) {
  return dispatch({
    type: CREATE_PROJECT_FAIL,
    response: error,
  });
}

export function setProjectsCount() {
  return dispatch => {
    const Axios = new ApiBase().instance;
    Axios.post(LIST_SURVEY_URL, {
    })
      .then(res => {
        dispatch({
          type: SET_ORGANIZATION_PROJECT_COUNT,
          count: res.data.data.total
        });
      })
      .catch(e => dispatch({ type: '' }));
  };
}

export { deleteProject } from "./deleteProject";
export { copyProject } from "./copyProject";
export { deleteOrganisationProject } from "./deleteOrganisationProject";