import {
  FETCH_DIMENSIONSLIST_STARTED,
  FETCH_DIMENSIONSLIST_SUCCESS,
  FETCH_DIMENSIONSLIST_FAILED,
} from "../../constants";
import { update, fetchPayload } from "../../../utils/reducerOperations";
import { Default } from "./InitialState";
import * as helper from './helper';

const DimensionListReducer = (state = Default(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_DIMENSIONSLIST_STARTED:
      return update(state, helper.fetchDimensionListStarted(state, payload));
    case FETCH_DIMENSIONSLIST_SUCCESS:
      return update(state, helper.fetchDimensionListSuccess(state, payload));
    case FETCH_DIMENSIONSLIST_FAILED:
      return update(state, helper.fetchDimensionFailed(state, payload));
    default:
      return { ...state };
  }
};

export default DimensionListReducer;
