import { SET_PAGE_NUMBER_FOR_COHORT_SURVEY_PAGE } from "../../../constants";


export const setPageNumberForCohortSurveyPage = (payload, cb) => {
    return (dispatch) => {
        dispatch({
            type: SET_PAGE_NUMBER_FOR_COHORT_SURVEY_PAGE,
            payload: payload
        });
    }
};