import {fetchFormConfigurations} from '../../inputFormConfigurations';
import {setInitialState} from '../../../utils/reducerOperations';
export function Default() {
    return {
        resetPasswordApi: setInitialState({code: ''}),
        isFormValid:false,
        inputBoxConfiguration: fetchFormConfigurations([
            'verificationCode',
            'newPassword',
            'confirm'
        ])
    };
}
