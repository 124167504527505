import { fetchPayload, update } from "../../../utils/reducerOperations";
import {
  LOGIN_FORM_CHANGE,
  LOGIN_PROCESS_SUCCESS,
  LOGIN_PROCESS_FAILED,
  LOGIN_PROCESS_STARTED,
} from "../../constants";
import {
  handleLoginFormChange,
  handleLoginProcessStarted,
  handleLoginProcessSuccess,
  handleLoginProcessFailed,
  updatedState,
} from "./Helpers";
import { Default } from "./InitialState";
const loginPageReducer = (state = Default(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case LOGIN_FORM_CHANGE:
      return update(updatedState(state), handleLoginFormChange(state, payload));
    case LOGIN_PROCESS_STARTED:
      return update(state, handleLoginProcessStarted(state, payload));
    default:
      return loginSuccessFail(state, action);
  }
};
const loginSuccessFail = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case LOGIN_PROCESS_SUCCESS:
      return update(state, handleLoginProcessSuccess(state, payload));
    case LOGIN_PROCESS_FAILED:
      return update(state, handleLoginProcessFailed(state, payload));
    default:
      return { ...state };
  }
};

export default loginPageReducer;
