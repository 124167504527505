import {
  formChangeSuccess,
  setInitialState,
} from "../../../../utils/reducerOperations";
export function handleLoginFormChange(state, payload) {
  return formChangeSuccess(state, payload);
}

export const updatedState = (state) => {
  return {
    ...state,
    loginProcessApi: setInitialState(),
  };
};
