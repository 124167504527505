import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const improvementScaleDrag180 = {
    "blockId": uuidv4(),
    "goalId": "feedback180_improvementScaleDrag",
    "isHiddenFromEditSurvey": true,
    "totalRanking": 3,
    "questions": [
        {
            "question": "Build great teams and organizations",
            "type": "priorityDrag",
            "questionDimension": "Builds great teams and organizations"
        }
    ],
    "totalAnswersCount": 4,
    "type": "priorityDrag",
    "dimension": "Builds great teams and organizations"
}