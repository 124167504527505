export const defaultTemplate = [
    {
        questionDimension: '',
        type: 'Matrix',
        questionLabel: '',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: 'Question 1.', value: '', isSelected: false, isCustom: true
        },
        {
            text: 'Question 2.', value: '', isSelected: false, isCustom: true

        },
        {
            text: 'Question 3.', value: '', isSelected: false, isCustom: true

        },
        {
            text: 'Question 4.', value: '', isSelected: false, isCustom: true

        }]
    }
]


