import { inputConditionCheck } from "../../../../utils/heatmapUtils";

export const setThresholdRangeValue = (state, payload) => {
    const { id, value } = payload;
    const updatedThresholdValue = state.heatmapConfiguration.thresholdValue.map((item) =>
    item.id === id ? { ...item, value } : item
  );

  const { errorMessage, isError } = inputConditionCheck(value, id, updatedThresholdValue);

    return {
      ...state,
      heatmapConfiguration: {
        ...state.heatmapConfiguration,
        thresholdValue: updatedThresholdValue,
          errorMessage: errorMessage,
          isError:isError,
      },
    };
  };
  