import { deepCopy } from "../../../../../../utils/deepCopy"
import { v4 as uuidv4 } from "uuid";


export const handleChangeMultiOptionToggle = (state, payload) => {
    let updatedGoalsWithToggle = getUpdatedToggleGoals(state.goals, payload)
        return ({
        ...state,
        goals: updatedGoalsWithToggle
    })
}

const getUpdatedToggleGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
        goalsCopy.forEach(item => {
        if (item.blockId === payload.blockId) {
                        if (payload.toggleValue) {
                item.dimension = "multipleOption"
                item.goalId = "multipleOption"
                item.isDemographic = false
                item.isMultipleOption = true
                item.type = "multipleOption"
                item.questions.forEach((question, index) => {
                    question.type = "multipleOption"
                    question.questionDimension = "multipleOption"
                })


            }
            else {
                item.dimension = "Demographics"
                item.goalId = "demographics"
                item.isDemographic = true
                item.isMultipleOption = false
                item.type = "multipleChoice"
                item.questions.forEach((question, index) => {
                    question.type = "Multiple Choice"
                    question.questionDimension = "Demographics"
                })
            }
        }

    })
    return goalsCopy
}

export const handleChangeToggleWriteInOption = (state, payload) => {
    let updatedGoalsWithToggle = getUpdatedToggleGoalsForOther(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoalsWithToggle
    })
}

const getUpdatedToggleGoalsForOther = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    let otherOption = {
        label: 'Other',
        id: uuidv4(),
        value: uuidv4(),
        isOtherOption:true
    };
    goalsCopy.forEach(item => {
        if (item.blockId === payload.blockId) {
                item.isOtherOptionEnabled = payload.value
                if (payload.value) {
                    const isOtherOptionPresent = item.questions[0].options.some(option => option.isOtherOption);
                    
                    if (!isOtherOptionPresent) {
                        item.isOtherOptionEnabled = true;
                        item.questions[0].options.push(otherOption);
                    }
                }             
                else{
                item.questions[0].options = item.questions[0].options.filter(option => !option.isOtherOption);
                }     
        }

    })
    return goalsCopy
}