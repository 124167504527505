import {
  FETCH_LOGS_LIST_STARTED,
  FETCH_LOGS_LIST_SUCCESS,
  FETCH_LOGS_LIST_FAILED,
} from "../../constants";

import { ApiBase } from "../../../utils/apiBase";
import { FETCH_LOGS_URL } from "../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchUserCreationLogs = (params) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch({
      type: FETCH_LOGS_LIST_STARTED,
      payload: params,
    });
    apiBaseInstance.instance
      .post(FETCH_LOGS_URL, { ...params, ...getProjectIdAndOrgId(params) })
      .then((res) => fetchLogsSuccess(dispatch, res))
      .catch((err) => fetchLogsFailure(dispatch, err));
  };
};
function fetchLogsSuccess(dispatch, res) {
  return dispatch({
    type: FETCH_LOGS_LIST_SUCCESS,
    payload: res.data,
  });
}
function fetchLogsFailure(dispatch, err) {
  return dispatch({
    type: FETCH_LOGS_LIST_FAILED,
    payload: err,
  });
}
