import { setInitialState } from '../../../utils/reducerOperations';

export const Default=()=>({
    config:{
        questions:[],
        projectName:''
    },
    getProjectConfigApi:setInitialState(),
    saveProjectApi:setInitialState(),
    publishProjectApi:setInitialState(),
    uploadImageApi:{
        loading:false,
        fileUrl:null,
        error:false
    },
    renameProjectApi:setInitialState()
});