import { Tooltip } from 'antd';
import React from 'react';
import './index.scss';

export class TopicQuestionBarchart extends React.Component {

    showChart = () => {
        let isShowChart = true
        this.props.data.forEach((item, key) => {
            if ((item.favScore === null || item.favScore === 0) &&
                (item.neutralScore === null || item.neutralScore === 0) &&
                (item.unFavScore === null || item.unFavScore === 0)) {
                isShowChart = false
            }
        })
        return isShowChart
    }

    render() {
        return (
            <>
                {this.showChart() ? <>{this.props.data.map((item, index) =>
                    <div className='topicsnew-progress  progress-bar'>
                        <Tooltip
                            placement='bottom'
                            title={item.favScore < 5 ? `${item.favScore} Favorable` :"Favorable"}
                        >
                            <div
                                className='favourable'
                                style={{
                                    width: `${item.favScore}%`,
                                    // minWidth: item.favScore === 0 ? '0%' : this.getMinWidth(item),
                                    padding: item.favScore === 0 && '0',
                                }}
                            >
                                {
                                    item.favScore >= 5 &&
                                    <p>{`${item.favScore} `}</p>
                                }
                            </div>
                        </Tooltip>
                        <Tooltip
                            placement='bottom'
                            title={item.neutralScore < 5 ? `${item.neutralScore} Favorable` :"Neutral"}
                        >
                            <div
                                className='neutral'
                                style={{
                                    width: `${item.neutralScore}%`,
                                    // minWidth: item.neutralScore === 0 ? '0%' : this.getMinWidth(item),
                                    padding: item.neutralScore === 0 && '0',
                                }}
                            >
                                {
                                    item.neutralScore >= 5 &&
                                    <p>{`${item.neutralScore} `}</p>
                                }
                            </div>
                        </Tooltip>
                        <Tooltip
                            placement='bottom'
                            title={item.unFavScore < 5 ? `${item.unFavScore} Unfavorable` :"Unfavorable"}
                        >
                            <div
                                className='unfavourable'
                                style={{
                                    width: `${item.unFavScore}%`,
                                    // minWidth: item.unFavScore === 0 ? '0%' : this.getMinWidth(item),
                                    padding: item.unFavScore === 0 && '0',
                                }}
                            >
                                {
                                    item.unFavScore >= 5 &&
                                    <p>{`${item.unFavScore} `}</p>
                                }
                            </div>
                        </Tooltip>
                    </div>
                )}</> : "Insufficient data!"}

            </>
        );
    }
}