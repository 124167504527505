import { deepCopy } from "../../../../../utils/deepCopy"
import { toast } from "../../../../../utils/messageUtils"
import { v4 as uuidv4 } from "uuid";

export const addDescriptiveQuestions = (state, payload) => {
    let goals = addDescriptiveQuestionToGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const addDescriptiveQuestionToGoals = (state, payload) => {
    toast.success(`Descriptive text added successfully`)
    let goalsCopy = deepCopy(state.goals)
    let descriptiveQuestion = getDescriptiveQuestionTemplate()
    goalsCopy.unshift(descriptiveQuestion)
    return goalsCopy
}

const getDescriptiveQuestionTemplate = () => {
    return (
        {
            dimension: "Descriptive text",
            questions: [
                {
                    question:"Edit the text here" ,
                    questionLabel: "Descriptive text",
                    isDemographic: false,
                    isSelected: true,
                    isCustom: true,
                    questionDimension: "Click to edit dimension ",
                    type: "Descriptive Text",
                    position: "vertical",
                    qid: uuidv4()
                }
            ],
            isDescriptive : true,
            isSelected: true,
            isFromEditor: true,
            goalId: uuidv4()
        }
    )
}