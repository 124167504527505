import { checkIFQAEnv } from "./helper";

const domain = checkIFQAEnv() ? "https://culturebieqa" : "https://culturebie";

const returnDomain = window.location.origin + "/";
const urlSuffix = ".auth.us-east-1.amazoncognito.com/logout";
const clientId = checkIFQAEnv() ? "?client_id=7kpf0iuutpctflek2dfcf8vj3o" : "?client_id=1ki0acdsspveoijk0etauf4mbj";
const logoutUri = "&logout_uri=" + returnDomain;
const redirectUrl = "&redirect_uri=" + returnDomain;
export default {
  surveyPath:"surveys",
  peoplePath : "manageusers",
  logoutUrl: domain + urlSuffix + clientId + logoutUri + redirectUrl,
  userLogo: "user role icon",
  emptySpace: " ",
  userPath: "/home",
  adminPath: "/admin/home/",
  logout: "Log Out",
  settings: "Settings",
  home: "Home",
  users: "Users",
  logo: "logo",
  active: "active",
  organizations: "organizations",
  homePagePath: "/admin/home/organizations",
  previewProject: "projects/preview-project",
  local: {
    firstName: "firstName",
    lastName: "lastName",
    email: "username",
    organizationName: "organizationName",
    organizationProjectCount: "organizationProjectCount",
  },
  dropDown: {
    variant: "none",
    id: "profile-img",
  },
  menuItems: [
    //     {
    //     label:'Users',
    //     name:'Users',
    //     startsWith:'/admin/home/users',
    //     path:'/admin/home/users',
    //     visibleTo:['Admin']
    // },
    // {
    //   label: "Organisations",
    //   name: "organisations",
    //   startsWith: "/admin/home/organisations",
    //   path: "/admin/home/organisations",
    //   visibleTo: ["Admin"],
    // },
    // {
    //     label:'Data Collection',
    //     name:'projects',
    //     startsWith:'/projects',
    //     path:'/projects',
    //     visibleTo:['Admin','Viewer']
    // },
    // {
    //     label:'NLP',
    //     name:'nlp',
    //     startsWith:'/nlp',
    //     path:'/nlp/dashboard',
    //     visibleTo:['Admin','Viewer']
    // },
    // {
    //     label:'Survey',
    //     name:'survey',
    //     startsWith:'/home/survey',
    //     path:'/home/survey',
    //     visibleTo:['Viewer']
    // }
  ],
  foldedMenu: {
    iconType: "menu",
    trigger: "click",
  },
};
