import {
    makeKey,
    update,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
import {toast} from '../../../../utils/messageUtils';
export function deleteSurveyFailed(state, payload) {
    let response = makeKey(
        'inviteUserApi',
        update(state.inviteUserApi, {
            ...(payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload)),
                projectId: payload.projectId
        })
    );
    toast.error(response.inviteUserApi.message);
    return response;
}
