import React from "react";
import CombinedHeader from "../../Components/CombinedHeader";
import FooterMain from "../../Components/FooterMain";
import constants from "./constants";
import { PrivateRoutes } from "../../Components/PrivateRoutes";

export const MainFrame = (props) => (
  <main className="main-wrapper">
    <CombinedHeader
      {...props}
      history={props.history}
      pathName={props.location.pathname}
    />
    <div className="admin-content">
      {/* {props.children} */}
      <PrivateRoutes routeConfig={constants.MAINFRAME_ROUTES} />
    </div>
    <FooterMain />
  </main>
);
