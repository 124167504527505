import {
    careerDevelopmentTemplate,
    leadershipTemplate,
    diversityTemplate,
    communicationTemplate,
    engagementTemplate,
    agilityTemplate,
    collaborationTemplate,
    commitmentTemplate,
    competenceTemplate,
    empowermentTemplate,
    futureWorkTemplate,
    inclusionTemplate,
    managerTemplate,
    visionTemplate,
    wellnessTemplate,
    leadingChangeTemplate,
    changeReadinessTemplate,
    changeEnablerTemplate,
    needForChangeTemplate,
    demographicTemplateGender,
    demographicTemplateGeneration,
    demographicTemplateJobTenure,
    demographicTemplateRace,
    demographicTemplateWork,
    commentsTemplateGoingWell,
    commentsTemplateImprovement,
    multipleOptionTemplateWork,
    multipleOptionTemplateRace,
    multipleOptionTemplateJobTenure,
    multipleOptionTemplateGeneration,
    multipleOptionTemplateGender,
    diversityMatrixTemplate
} from './Templates';

import { v4 as uuidv4 } from "uuid";

import { deepCopy } from '../../../../utils/deepCopy';
import { generateStaticQuestionId } from '../../../../utils/goalsUtils';
import { winningStrategy } from './Templates/dynamic360WinningStrategyTemplate';
import { executesUrgency } from './Templates/dynamic360ExecutesUrgency';
import { outSideComfortZone } from './Templates/dynamic360OutsideComfortZone';
import { buildGreatTeam } from './Templates/dynamic360BuildTeam';
import { strengthScaleDrag, strengthScaleDragForAppliedMaterials } from './Templates/dynamic360StrengthScale';
import { strengthComments, strengthCommentsAppliedMaterials } from './Templates/dynamic360StrenthComments';
import { improvementScaleDrag, improvementScaleDragForAppliedMaterials } from './Templates/dynamic360ImprovementScaleDrag';
import { improvementComments, improvementCommentsForApplied } from './Templates/dynamic360ImprovementComments';
import { feedbackIdeasComments, feedbackIdeasCommentsApplied } from './Templates/dynamic360Comment';
import { growthAndDevelopment } from './Templates/dynamic180GrowthAndDevelopment';
import { connectivityAndCollaboration } from './Templates/dynamic180ConnectivityAndCollaboration';
import { adaptability } from './Templates/dynamic180Adaptability';
import { navigatingComplexity } from './Templates/dynamic180NavigatingComplexity';
import { strengthScaleDrag180 } from './Templates/dynamic180StrengthScaleDrag';
import { strengthComments180 } from './Templates/dynamic180StrengthComments';
import { improvementScaleDrag180 } from './Templates/dynamic180ImprovementScaleDrag';
import { improvementComments180 } from './Templates/dynamic180ImprovementComments';
import { feedbackIdeasComments180 } from './Templates/dynamic180FeedbackIdeasComments';
import { collaboration } from './Templates/dynamic360AppliedMaterialsCollaboration';
import { coaching } from './Templates/dynamic360AppliedMaterialsCoaching';
import { teamDevelopment } from './Templates/dynamic360AppliedMaterialsTeamDevelopment';
import { cultureOfInclusion } from './Templates/dynamic360AppliedMaterialsCultureOfInclusion';
import { selfAwareness } from './Templates/dynamic360AppliedMaterialsSelfAwarness';
import { summaryOfRatings } from './Templates/summaryOfRatings';
import { selfAwarenessDirector } from './Templates/dynamic360AppliedMaterialsSelfAwarnessDirector';
import { teamDevelopmentDirector } from './Templates/dynamic360AppliedMaterialsTeamDevelopmentDirector';
import { collaborationDirector } from './Templates/dynamic360AppliedMaterialsCollaborationDirector';
import { coachingDirector } from './Templates/dynamic360AppliedMaterialsCoachingDirector';
import { cultureOfInclusionDirector } from './Templates/dynamic360AppliedMaterialsCultureOfInclusionDirector';

export const goals = [
    {
        dimension: 'Engagement',
        questions: deepCopy(engagementTemplate),
        isDefault: true,
        isSelected: true,
        description: "",
        blockId: uuidv4(),
        isStatic: true,
        type: "likert",
        isOutcomeVariable: true,
        isTemplate: true,
        goalId: 'engagement'
    },
    {
        dimension: 'Agility',
        questions: deepCopy(agilityTemplate),
        isDefault: true,
        blockId: uuidv4(),
        isTemplate: true,
        type: "likert",
        isSelected: false,
        goalId: 'agility'
    },
    {
        dimension: 'Career Development',
        questions: deepCopy(careerDevelopmentTemplate),
        isDefault: true,
        isTemplate: true,
        blockId: uuidv4(),
        type: "likert",
        isSelected: false,
        goalId: 'career'
    },
    {
        dimension: 'Change Readiness',
        questions: deepCopy(changeReadinessTemplate),
        isDefault: true,
        blockId: uuidv4(),
        type: "likert",
        isTemplate: false,
        goalId: 'changereadiness'
    },
    {
        dimension: 'Change Enabler',
        questions: deepCopy(changeEnablerTemplate),
        isDefault: true,
        type: "likert",
        blockId: uuidv4(),
        isTemplate: false,
        goalId: 'changeenabler'
    },
    {
        dimension: 'Collaboration',
        questions: deepCopy(collaborationTemplate),
        isDefault: true,
        isTemplate: true,
        type: "likert",
        blockId: uuidv4(),
        isSelected: false,
        goalId: 'collaboration'
    },
    {
        dimension: 'Commitment',
        questions: deepCopy(commitmentTemplate),
        isDefault: true,
        isTemplate: true,
        blockId: uuidv4(),
        isSelected: false,
        type: "likert",
        goalId: 'commitment'
    },
    {
        dimension: 'Communication',
        questions: deepCopy(communicationTemplate),
        isDefault: true,
        isSelected: false,
        blockId: uuidv4(),
        type: "likert",
        isTemplate: true,
        goalId: 'communication'
    },
    {
        dimension: 'Competence',
        questions: deepCopy(competenceTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        type: "likert",
        blockId: uuidv4(),
        goalId: 'competence'
    },
    {
        dimension: 'Diversity',
        questions: deepCopy(diversityTemplate),
        isDefault: true,
        type: "likert",
        isSelected: true,
        blockId: uuidv4(),
        isTemplate: true,
        goalId: 'diversity'
    },
    {
        dimension: 'Empowerment',
        questions: deepCopy(empowermentTemplate),
        isDefault: true,
        isTemplate: true,
        blockId: uuidv4(),
        isSelected: false,
        type: "likert",
        goalId: 'empowerment'
    },
    {
        dimension: 'Future of Work',
        questions: deepCopy(futureWorkTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        type: "likert",
        blockId: uuidv4(),
        goalId: 'futureofwork'
    },
    {
        dimension: 'Inclusion',
        questions: deepCopy(inclusionTemplate),
        isDefault: true,
        isTemplate: true,
        type: "likert",
        blockId: uuidv4(),
        isSelected: false,
        goalId: 'inclusion'
    },
    {
        dimension: 'Leading Change',
        questions: deepCopy(leadingChangeTemplate),
        isDefault: true,
        isTemplate: false,
        blockId: uuidv4(),
        type: "likert",
        goalId: 'leadingchange'
    },
    {
        dimension: 'My Managers',
        questions: deepCopy(managerTemplate),
        isDefault: true,
        blockId: uuidv4(),
        isTemplate: true,
        type: "likert",
        isSelected: false,
        goalId: 'managers'
    },
    {
        dimension: 'Need for Change',
        questions: deepCopy(needForChangeTemplate),
        isDefault: true,
        blockId: uuidv4(),
        isTemplate: false,
        type: "likert",
        goalId: 'needforchange'
    },
    {
        dimension: 'Senior Leaders',
        questions: deepCopy(leadershipTemplate),
        isDefault: true,
        isTemplate: true,
        blockId: uuidv4(),
        type: "likert",
        isSelected: true,
        goalId: 'seniorleaders'
    },
    {
        dimension: 'Vision',
        questions: deepCopy(visionTemplate),
        isDefault: true,
        type: "likert",
        blockId: uuidv4(),
        isTemplate: true,
        isSelected: false,
        goalId: 'vision'
    },
    {
        dimension: 'Wellness',
        questions: deepCopy(wellnessTemplate),
        isDefault: true,
        isTemplate: true,
        blockId: uuidv4(),
        isSelected: false,
        type: "likert",
        goalId: 'wellness'
    },
    // {
    //     dimension: 'multipleOption',
    //     questions: deepCopy(multipleOptionTemplate),
    //     isTemplate: true,
    //     anonymousOnly: true,
    //     isMultipleOption: true,
    //     isSelected: true,
    //     goalId: 'multipleOption'
    // },
    // {
    //     dimension: 'Comments',
    //     questions: deepCopy(commentsTemplate),
    //     isSelected: true,
    //     isTextEntryOnly: true,
    //     isTemplate: true,
    //     goalId: 'comments'
    // },
    {
        blockId: uuidv4(),
        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateGender),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics'
    },
    {
        blockId: uuidv4(),
        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateGeneration),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics'
    },
    {
        blockId: uuidv4(),
        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateJobTenure),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics'
    },
    {
        blockId: uuidv4(),
        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateRace),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics'
    },
    {
        blockId: uuidv4(),
        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateWork),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics'
    },
    {
        dimension: 'multipleOption',
        questions: deepCopy(multipleOptionTemplateGender),
        isTemplate: true,
        anonymousOnly: true,
        isMultipleOption: true,
        isSelected: true,
        goalId: 'multipleOption'
    },
    {
        dimension: 'multipleOption',
        questions: deepCopy(multipleOptionTemplateGeneration),
        isTemplate: true,
        anonymousOnly: true,
        isMultipleOption: true,
        isSelected: true,
        goalId: 'multipleOption'
    },
    {
        dimension: 'multipleOption',
        questions: deepCopy(multipleOptionTemplateJobTenure),
        isTemplate: true,
        anonymousOnly: true,
        isMultipleOption: true,
        isSelected: true,
        goalId: 'multipleOption'
    },
    {
        dimension: 'multipleOption',
        questions: deepCopy(multipleOptionTemplateRace),
        isTemplate: true,
        anonymousOnly: true,
        isMultipleOption: true,
        isSelected: true,
        goalId: 'multipleOption'
    },
    {
        dimension: 'multipleOption',
        questions: deepCopy(multipleOptionTemplateWork),
        isTemplate: true,
        anonymousOnly: true,
        isMultipleOption: true,
        isSelected: true,
        goalId: 'multipleOption'
    },
    {
        blockId: uuidv4(),
        dimension: 'Comments',
        questions: deepCopy(commentsTemplateGoingWell),
        isSelected: true,
        isTextEntryOnly: true,
        isTemplate: true,
        goalId: 'comments'
    },
    {
        blockId: uuidv4(),
        dimension: 'Comments',
        questions: deepCopy(commentsTemplateImprovement),
        isSelected: true,
        isTextEntryOnly: true,
        isTemplate: true,
        goalId: 'comments'
    },
]

export const verySimpleSurveyGoals = []

export const employeeExperienceCheck = [
    {
        ...getDescriptiveTestTemplate("<p>The first set of questions are about your personal experiences and thoughts relating to your job. We’ll ask some questions about your job and your direct manager. Think about your own experiences when answering—not those of others in the organization.</p>"),
    },
    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Engagement',
        questions: deepCopy(engagementTemplate),
        isDefault: true,
        isSelected: true,
        description: "",
        isStatic: true,
        isOutcomeVariable: true,
        isTemplate: true,
        goalId: 'engagement'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Commitment',
        questions: deepCopy(commitmentTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'commitment'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Competence',
        questions: deepCopy(competenceTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'competence'
    },
    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Empowerment',
        questions: deepCopy(empowermentTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'empowerment'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Inclusion',
        questions: deepCopy(inclusionTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'inclusion'
    },
    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Career Development',
        questions: deepCopy(careerDevelopmentTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'career'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Wellness',
        questions: deepCopy(wellnessTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'wellness'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'My Managers',
        questions: deepCopy(managerTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'managers'
    },
    {
        ...getDescriptiveTestTemplate("<p>Now we have some questions about how the organization functions more broadly. Think about your own experiences, but also those of people you know and your general thoughts and impressions about this organization.</p>"),
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Future of Work',
        questions: deepCopy(futureWorkTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'futureofwork'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Agility',
        questions: deepCopy(agilityTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'agility'
    },
    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Collaboration',
        questions: deepCopy(collaborationTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'collaboration'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Communication',
        questions: deepCopy(communicationTemplate),
        isDefault: true,
        isSelected: false,
        isTemplate: true,
        goalId: 'communication'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Diversity',
        questions: deepCopy(diversityTemplate),
        isDefault: true,
        isSelected: true,
        isTemplate: true,
        goalId: 'diversity'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Vision',
        questions: deepCopy(visionTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'vision'
    },

    {
        ...getDescriptiveTestTemplate("<p>Ok! This is the last section of the survey. Thanks for coming this far. We have just a few questions about the organization’s senior leaders.</p>"),
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Senior Leaders',
        questions: deepCopy(leadershipTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: true,
        goalId: 'seniorleaders'
    },
    {
        type: 'textEntry',
        blockId: uuidv4(),
        dimension: 'Comments',
        questions: deepCopy(commentsTemplateGoingWell),
        isSelected: true,
        isTextEntryOnly: true,
        isTemplate: true,
        goalId: 'comments'
    },
    {
        type: 'textEntry',
        blockId: uuidv4(),
        dimension: 'Comments',
        questions: deepCopy(commentsTemplateImprovement),
        isSelected: true,
        isTextEntryOnly: true,
        isTemplate: true,
        goalId: 'comments'
    },

]

function getDescriptiveTestTemplate(question) {
    return (
        {
            type: "descriptive",
            blockId: uuidv4(),
            dimension: "Descriptive text",
            questions: [
                {
                    question: question,
                    questionLabel: "Descriptive text",
                    isDemographic: false,
                    isSelected: true,
                    isCustom: true,
                    questionDimension: "Click to edit dimension ",
                    type: "Descriptive Text",
                    position: "vertical",
                    qid: uuidv4()
                }
            ],
            isDescriptive: true,
            isSelected: true,
            isFromEditor: true,
            goalId: uuidv4()
        }
    )
}

export const quickPulseGoals = [

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Engagement',
        questions: deepCopy(engagementTemplate),
        isDefault: true,
        isSelected: true,
        description: "",
        isStatic: true,
        isOutcomeVariable: true,
        isTemplate: true,
        goalId: 'engagement'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Commitment',
        questions: deepCopy(commitmentTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'commitment'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Competence',
        questions: deepCopy(competenceTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'competence'
    },
    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Empowerment',
        questions: deepCopy(empowermentTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'empowerment'
    },
    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Inclusion',
        questions: deepCopy(inclusionTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'inclusion'
    },
    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Career Development',
        questions: deepCopy(careerDevelopmentTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'career'
    },
    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Agility',
        questions: deepCopy(agilityTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'agility'
    },
    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Communication',
        questions: deepCopy(communicationTemplate),
        isDefault: true,
        isSelected: false,
        isTemplate: true,
        goalId: 'communication'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Vision',
        questions: deepCopy(visionTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'vision'
    },

    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'My Managers',
        questions: deepCopy(managerTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        goalId: 'managers'
    },
    {
        type: "likert",
        blockId: uuidv4(),
        dimension: 'Senior Leaders',
        questions: deepCopy(leadershipTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: true,
        goalId: 'seniorleaders'
    },
    {
        type: 'multipleChoice',
        blockId: uuidv4(),
        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateGender),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics'
    },
    {
        type: 'multipleChoice',
        blockId: uuidv4(),
        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateGeneration),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics'
    },
    {
        type: 'multipleChoice',
        blockId: uuidv4(),
        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateJobTenure),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics'
    },
    {
        type: 'multipleChoice',
        blockId: uuidv4(),
        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateRace),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics'
    },
    {
        type: 'multipleChoice',
        blockId: uuidv4(),
        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateWork),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics'
    },
]

export const dummyGoals = [
    {
        dimension: 'Engagement',
        questions: deepCopy(engagementTemplate),
        isDefault: true,
        isSelected: true,
        description: "",
        isStatic: true,
        type: "likert",
        isOutcomeVariable: true,
        isTemplate: true,
        goalId: 'engagement'
    },
    {
        dimension: 'Agility',
        questions: deepCopy(agilityTemplate),
        isDefault: true,
        isTemplate: true,
        type: "likert",
        isSelected: false,
        goalId: 'agility'
    },
    {
        dimension: 'Career Development',
        questions: deepCopy(careerDevelopmentTemplate),
        isDefault: true,
        isTemplate: true,
        type: "likert",
        isSelected: false,
        goalId: 'career'
    },
    {
        dimension: 'Change Readiness',
        questions: deepCopy(changeReadinessTemplate),
        isDefault: true,
        type: "likert",
        isTemplate: false,
        goalId: 'changereadiness'
    },
    {
        dimension: 'Change Enabler',
        questions: deepCopy(changeEnablerTemplate),
        isDefault: true,
        type: "likert",

        isTemplate: false,
        goalId: 'changeenabler'
    },
    {
        dimension: 'Collaboration',
        questions: deepCopy(collaborationTemplate),
        isDefault: true,
        isTemplate: true,
        type: "likert",

        isSelected: false,
        goalId: 'collaboration'
    },
    {
        dimension: 'Commitment',
        questions: deepCopy(commitmentTemplate),
        isDefault: true,
        isTemplate: true,

        isSelected: false,
        type: "likert",
        goalId: 'commitment'
    },
    {
        dimension: 'Communication',
        questions: deepCopy(communicationTemplate),
        isDefault: true,
        isSelected: false,

        type: "likert",
        isTemplate: true,
        goalId: 'communication'
    },
    {
        dimension: 'Competence',
        questions: deepCopy(competenceTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        type: "likert",

        goalId: 'competence'
    },
    {
        dimension: 'Diversity',
        questions: deepCopy(diversityTemplate),
        isDefault: true,
        type: "likert",
        isSelected: true,

        isTemplate: true,
        goalId: 'diversity'
    },
    {
        dimension: 'Empowerment',
        questions: deepCopy(empowermentTemplate),
        isDefault: true,
        isTemplate: true,

        isSelected: false,
        type: "likert",
        goalId: 'empowerment'
    },
    {
        dimension: 'Future of Work',
        questions: deepCopy(futureWorkTemplate),
        isDefault: true,
        isTemplate: true,
        isSelected: false,
        type: "likert",

        goalId: 'futureofwork'
    },
    {
        dimension: 'Inclusion',
        questions: deepCopy(inclusionTemplate),
        isDefault: true,
        isTemplate: true,
        type: "likert",

        isSelected: false,
        goalId: 'inclusion'
    },
    {
        dimension: 'Leading Change',
        questions: deepCopy(leadingChangeTemplate),
        isDefault: true,
        isTemplate: false,

        type: "likert",
        goalId: 'leadingchange'
    },
    {
        dimension: 'My Managers',
        questions: deepCopy(managerTemplate),
        isDefault: true,

        isTemplate: true,
        type: "likert",
        isSelected: false,
        goalId: 'managers'
    },
    {
        dimension: 'Need for Change',
        questions: deepCopy(needForChangeTemplate),
        isDefault: true,

        isTemplate: false,
        type: "likert",
        goalId: 'needforchange'
    },
    {
        dimension: 'Senior Leaders',
        questions: deepCopy(leadershipTemplate),
        isDefault: true,
        isTemplate: true,

        type: "likert",
        isSelected: true,
        goalId: 'seniorleaders'
    },
    {
        dimension: 'Vision',
        questions: deepCopy(visionTemplate),
        isDefault: true,
        type: "likert",

        isTemplate: true,
        isSelected: false,
        goalId: 'vision'
    },
    {
        dimension: 'Wellness',
        questions: deepCopy(wellnessTemplate),
        isDefault: true,
        isTemplate: true,

        isSelected: false,
        type: "likert",
        goalId: 'wellness'
    },

]

export const defaultMultipleChoice = [
    {

        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateGender),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics',
        type: 'multipleChoice',
    },
    {

        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateGeneration),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics',
        type: 'multipleChoice',
    },
    {

        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateJobTenure),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics',
        type: 'multipleChoice',
    },
    {

        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateRace),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics',
        type: 'multipleChoice',
    },
    {

        dimension: 'Demographics',
        questions: deepCopy(demographicTemplateWork),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        isSelected: true,
        goalId: 'demographics',
        type: 'multipleChoice',
    },
]

export const defaultMultipleOptions = [
    {
        dimension: 'multipleOption',
        questions: deepCopy(multipleOptionTemplateGender),
        isTemplate: true,
        anonymousOnly: true,
        isMultipleOption: true,
        isSelected: true,
        goalId: 'multipleOption',
        type: 'multipleOption',
    },
    {
        dimension: 'multipleOption',
        questions: deepCopy(multipleOptionTemplateGeneration),
        isTemplate: true,
        anonymousOnly: true,
        isMultipleOption: true,
        isSelected: true,
        goalId: 'multipleOption',
        type: 'multipleOption',
    },
    {
        dimension: 'multipleOption',
        questions: deepCopy(multipleOptionTemplateJobTenure),
        isTemplate: true,
        anonymousOnly: true,
        isMultipleOption: true,
        isSelected: true,
        goalId: 'multipleOption',
        type: 'multipleOption',
    },
    {
        dimension: 'multipleOption',
        questions: deepCopy(multipleOptionTemplateRace),
        isTemplate: true,
        anonymousOnly: true,
        isMultipleOption: true,
        isSelected: true,
        goalId: 'multipleOption',
        type: 'multipleOption',
    },
    {
        dimension: 'multipleOption',
        questions: deepCopy(multipleOptionTemplateWork),
        isTemplate: true,
        anonymousOnly: true,
        isMultipleOption: true,
        isSelected: true,
        goalId: 'multipleOption',
        type: 'multipleOption',
    }

]

export const defaultTextEntry = [
    {

        dimension: 'Comments',
        questions: deepCopy(commentsTemplateGoingWell),
        isSelected: true,
        isTextEntryOnly: true,
        isTemplate: true,
        goalId: 'comments',
        type: 'textEntry',
    },
    {

        dimension: 'Comments',
        questions: deepCopy(commentsTemplateImprovement),
        isSelected: true,
        isTextEntryOnly: true,
        isTemplate: true,
        goalId: 'comments',
        type: 'textEntry',
    },
]

export const dynamic360Feedback = [

    {
        ...deepCopy(buildGreatTeam),

    },
    {
        ...deepCopy(winningStrategy),

    },
    {
        ...deepCopy(executesUrgency),

    },
    {
        ...deepCopy(outSideComfortZone)
    },
    {
        ...deepCopy(strengthScaleDrag)
    },
    {
        ...deepCopy(strengthComments())
    },
    {
        ...deepCopy(improvementScaleDrag)
    },

    {
        ...deepCopy(improvementComments())
    },
    {
        ...deepCopy(feedbackIdeasComments)
    }
]

export const dynamic360FeedbackAppliedMaterials = [

    {
        ...deepCopy(collaboration),

    },
    {
        ...deepCopy(coaching),

    },
    {
        ...deepCopy(teamDevelopment),

    },
    {
        ...deepCopy(cultureOfInclusion)
    },
    {
        ...deepCopy(selfAwareness)
    },
    {
        ...deepCopy(summaryOfRatings)
    },
    {
        ...deepCopy(strengthScaleDragForAppliedMaterials)
    },
    {
        ...deepCopy(strengthCommentsAppliedMaterials(true))
    },
    {
        ...deepCopy(improvementScaleDragForAppliedMaterials)
    },

    {
        ...deepCopy(improvementCommentsForApplied(true))
    },
    {
        ...deepCopy(feedbackIdeasCommentsApplied)
    }
]

export const dynamic360FeedbackAppliedMaterialsDirector = [
    {
        ...deepCopy(selfAwarenessDirector)
    },
    {
        ...deepCopy(teamDevelopmentDirector),

    },
    {
        ...deepCopy(collaborationDirector),

    },
    {
        ...deepCopy(coachingDirector),

    },
    {
        ...deepCopy(cultureOfInclusionDirector)
    },
    {
        ...deepCopy(summaryOfRatings)
    },
    {
        ...deepCopy(strengthScaleDragForAppliedMaterials)
    },
    {
        ...deepCopy(strengthCommentsAppliedMaterials(true))
    },
    {
        ...deepCopy(improvementScaleDragForAppliedMaterials)
    },

    {
        ...deepCopy(improvementCommentsForApplied(true))
    },
    {
        ...deepCopy(feedbackIdeasCommentsApplied)
    }
]

export const dynamic180Feedback = [
    {
        ...deepCopy(growthAndDevelopment)
    },
    {
        ...deepCopy(connectivityAndCollaboration)
    },
    {
        ...deepCopy(adaptability)
    },
    {
        ...deepCopy(navigatingComplexity)
    },
    {
        ...deepCopy(strengthScaleDrag180)
    },
    {
        ...deepCopy(strengthComments180)
    },
    {
        ...deepCopy(improvementScaleDrag180)
    },
    {
        ...deepCopy(improvementComments180)
    },
    {
        ...deepCopy(feedbackIdeasComments180)
    }
]