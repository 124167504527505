import React from "react";
import constants from "../constants";
import UserLoader from "../UserLoader";
import { EmptyState as Empty } from "../../../Packages/EmptyState";
import { Pagination } from "../../../Components/Pagination";

export function listUsersData(fetchUsersApi, renderUsersList) {
  const { states } = constants;
  if (fetchUsersApi.isProcessing !== false) {
    return <UserLoader hasWrapper={true} classList={constants.loaderClasses} />;
  } else if (fetchUsersApi.error) {
    return usersPageState(fetchUsersApi);
  } else if (emptyUsersChecking(fetchUsersApi)) {
    return usersPageState(states.empty);
  } else if (activeUsersChecking(fetchUsersApi)) {
    return usersPageState(states.active);
  } else if (fetchUsersApi.data) {
    return renderUsersList();
  }
}

function usersPageState(type) {
  return (
    <Empty type={type.message} emptyStateMessage={constants.ERROR_MESSAGE} />
  );
}
function emptyUsersChecking(fetchUsersApi) {
  if (fetchUsersApi.data && fetchUsersApi.count === 0) return true;
  else return false;
}
function activeUsersChecking(fetchUsersApi) {
  if (
    fetchUsersApi.data &&
    fetchUsersApi.count &&
    fetchUsersApi.data.Items.length === 0
  ) {
    return true;
  } else return false;
}

export function getPageDivForRenderUsersList({
  fetchUsersApi,
  onChange,
  page,
}) {
  if (!fetchUsersApi.data) return null;
  else if (fetchUsersApi.count < 12) return null;
  return (
    <Pagination page={page} onChange={onChange} count={fetchUsersApi.count} />
  );
}
