import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const buildGreatTeam = {
    "blockId": uuidv4(),
    "description": "<p>Leaders who build great teams and organizations act in a way that people want to follow them. Below are the behaviors that help us win in this space. Please share your perspective on how often you feel the following behaviors are demonstrated by this leader.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Rarely  ",
                    "value": "1"
                },
                {
                    "label": "Not Often Enough",
                    "value": "2"
                },
                {
                    "label": "Often ",
                    "value": "3"
                },
                {
                    "label": "Almost Always  ",
                    "value": "4"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "staticQuestionId": generateStaticQuestionId("Is able to stay focused even when under pressure."),
                    "HTMLText": "<p>Is able to stay focused even when under pressure.</p>",
                    "isSelected": true,
                    "text": "Is able to stay focused even when under pressure.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Admits mistakes and learns from criticism and different points of view."),
                    "HTMLText": "<p>Admits mistakes and learns from criticism and different points of view.</p>",
                    "isSelected": true,
                    "text": "Admits mistakes and learns from criticism and different points of view.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Cultivates a continuous learning and growth culture."),
                    "HTMLText": "<p>Cultivates a continuous learning and growth culture.</p>",
                    "isSelected": true,
                    "text": "Cultivates a continuous learning and growth culture.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Provides ongoing coaching and feedback."),
                    "HTMLText": "<p>Provides ongoing coaching and feedback.</p>",
                    "isSelected": true,
                    "text": "Provides ongoing coaching and feedback.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Inspires, motivates, and brings people along."),
                    "HTMLText": "<p>Inspires, motivates, and brings people along.</p>",
                    "isSelected": true,
                    "text": "Inspires, motivates, and brings people along.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Makes high-quality people decisions to build high performance teams."),
                    "HTMLText": "<p>Makes high - quality people decisions to build high performance teams.</p>",
                    "isSelected": true,
                    "text": "Makes high - quality people decisions to build high performance teams.",
                    "value": uuidv4()
                },
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Build great teams and organizations"
}