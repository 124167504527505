import { projectsRouteKeyMapper } from './constants';

export function mapKeysForUI(payload) {
    const response={};
    Object.keys(payload).forEach(key => {
        response[getKey(key,'ui')]=payload[key];
    });
    return response;
};

export function mapKeysForApi(payload) {
    const response={};
    Object.keys(payload).forEach(key => {
        response[getKey(key,'api')]=payload[key];
    });
    return response;
};

function getKey (key,context='ui'){
    const mapTo = context==='ui'?'api':'ui';
    const keys=projectsRouteKeyMapper.find(item=>key===item[mapTo]);
    return keys?keys[context]:key;
};