export * from './fetchTopicsRepresentation';
export * from './fetchTopicsWordcloud';
export * from './fetchQuestionComments';
export * from './fetchTopicsData';
export * from './switchQuestionComment';

export function updateSelectedColumns(state,action){
  let {selectedColumns=[]} = state;
  const index = (selectedColumns).findIndex(i=>i===action.selected);
  if(index>-1)
    selectedColumns=selectedColumns.filter(i=>i!==action.selected);
  else selectedColumns.push(action.selected);
  return {
    ...state,
    selectedColumns:[...selectedColumns]
  }
}