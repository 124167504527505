import {createSelector} from 'reselect';

const getUserCreationLogReducer = survey => survey.UserCreationLogReducer;

const StateSelector = (userCreationLogReducer) => ({
    ...userCreationLogReducer
});

export const SelectState = createSelector(
    [getUserCreationLogReducer],
    StateSelector
);