import { deepCopy } from "../../../../../utils/deepCopy"
import { toast } from "../../../../../utils/messageUtils"

export const deleteMultipleSelectionQuestion = (state, payload) => {
    let goals = deleteMultipleSelectionQuestionToGoals(state, payload)
    toast.success("Question deleted successfully.")
    return {
        ...state,
        goals: goals
    }
}

const deleteMultipleSelectionQuestionToGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    let goals = deepCopy(goalsCopy)
    let selectedDemographics = []
    goals.forEach(item => {
        if (item.goalId === "multipleOption") {
            selectedDemographics = item.questions.filter(
                question => question.staticQuestionId !== payload.question.staticQuestionId
            )
        }
    })
    goalsCopy.forEach(goal => {
        if (goal.goalId === "multipleOption") {
            goal.questions = selectedDemographics
        }
    })
    return goalsCopy
}