import {
  FETCH_DEMOGRAPHICS_DATA_START,
  FETCH_DEMOGRAPHICS_DATA_SUCCESS,
  FETCH_DEMOGRAPHICS_DATA_FAIL,
  CLEAR_EXPLORE_DEMOGRAPHICS_REDUCER,
} from "../../constants";
import { ApiBase } from "../../../utils/apiBase";
import { NUMERIC_DEMOGRAPHICS_URL } from "../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchDemographicsData = (payload) => {
  let columns = payload.columns;
  if (columns && columns.length === 1 && columns[0] === "Average Score") {
    payload.columns = [];
  }
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch({ type: FETCH_DEMOGRAPHICS_DATA_START });
    apiBaseInstance.instance
      .post(NUMERIC_DEMOGRAPHICS_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) =>
        fetchNumericDemographicsSuccess(dispatch, { res }, payload)
      )
      .catch((err) => fetchNumericDemographicsFailed(dispatch, err));
  };
};

function fetchNumericDemographicsSuccess(dispatch, { res }, payload) {
  const responseData = (res.data && res.data.data) || {};
  dispatch({
    type: FETCH_DEMOGRAPHICS_DATA_SUCCESS,
    payload: {
      data: responseData,
      filters: payload.filters,
      byScore:payload.byScore,
      multipleSelect: payload.multipleSelect,
      selectedUser: payload.selectedUser,
      directReporteesOnly: payload.directReporteesOnly,
    },
  });
}

function fetchNumericDemographicsFailed(dispatch, err) {
  return dispatch({
    type: FETCH_DEMOGRAPHICS_DATA_FAIL,
    payload: { errorMessage: err.message },
  });
}

export const resetDemograhicReducer = () => {
  return { type: CLEAR_EXPLORE_DEMOGRAPHICS_REDUCER };
};
