import { fetchPayload } from "../../../utils/reducerOperations";
import {
    FETCH_EXPLORE_QUESTIONS_START,
    FETCH_EXPLORE_QUESTIONS_SUCCESS,
    FETCH_EXPLORE_QUESTIONS_FAILED,
    SET_API_CALLED_KEY_EXPLORE_QUESTIONS,
    GET_QUESTION_TABLE_TREND_SUCCESS,
    GET_QUESTION_TABLE_TREND_FAIL,
    GET_QUESTION_TABLE_TREND_START
} from "../../constants";
import {
    fetchExploreQuestionsFailed,
    fetchExploreQuestionsStarted,
    fetchExploreQuestionsSuccess,
    getTrendDataForTableFailed,
    getTrendDataForTableStart,
    getTrendDataForTableSuccess,
    setIsApiCalledExploreQuestions
} from "./Helpers";
import { Default } from "./InitialState";

const ExploreQuestionsReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case FETCH_EXPLORE_QUESTIONS_START:
            return fetchExploreQuestionsStarted(state, payload);
        case FETCH_EXPLORE_QUESTIONS_SUCCESS:
            return fetchExploreQuestionsSuccess(state, payload);
        case FETCH_EXPLORE_QUESTIONS_FAILED:
            return fetchExploreQuestionsFailed(state, payload);
        case SET_API_CALLED_KEY_EXPLORE_QUESTIONS:
            return setIsApiCalledExploreQuestions(state, payload);
        case GET_QUESTION_TABLE_TREND_SUCCESS:
            return getTrendDataForTableSuccess(state, payload);
        case GET_QUESTION_TABLE_TREND_FAIL:
            return getTrendDataForTableFailed(state, payload);
        case GET_QUESTION_TABLE_TREND_START:
            return getTrendDataForTableStart(state, payload);


        default:
            return { ...state };
    }
};

export default ExploreQuestionsReducer;
