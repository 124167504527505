export const fetchCircumplexDataStart = (state) => {
    return {
        ...state,
        fetchCircumplexDataApi: { ...state.fetchCircumplexDataApi, isProcessing: true, error: false },

    }
}

export const fetchCircumplexDataSuccess = (state, payload) => {
    return {
        ...state,
        fetchCircumplexDataApi: {
            ...state.fetchCircumplexDataApi,
            isProcessing: false,
            data: payload.data.response[0],
            error: false,
            isDataFetched: true
        },

    }
}

export const fetchCircumplexDataFail = (state) => {
    return {
        ...state,
        fetchCircumplexDataApi: { ...state.fetchCircumplexDataApi, isProcessing: false, error: true, isDataFetched: false },
    }
}