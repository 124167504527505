import {
  FETCH_ADMIN_LIST_STARTED,
  FETCH_ADMIN_LIST_SUCCESS,
  FETCH_ADMIN_LIST_FAILED,
} from "../../constants";

import { ApiBase } from "../../../utils/apiBase";
import { FETCH_USERS_URL } from "../../../../config/constants";
import { getOrgId } from "../../../utils/localStorgeOperations";
import { getDataFromUrlToken } from "../../../utils/urlDataStore";
import { URL_DATA } from "../../../../config/urlDataConstants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchAdmins = (params) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch({
      type: FETCH_ADMIN_LIST_STARTED,
      payload: params,
    });
    apiBaseInstance.instance
      .post(FETCH_USERS_URL, { ...makeParams(params), ...getProjectIdAndOrgId(makeParams(params)) })
      .then((res) => fetchAdminSuccess(dispatch, res))
      .catch((err) => fetchAdminFailure(dispatch, err));
  };
};
function fetchAdminSuccess(dispatch, res) {
  return dispatch({
    type: FETCH_ADMIN_LIST_SUCCESS,
    payload: res.data,
  });
}
function fetchAdminFailure(dispatch, err) {
  return dispatch({
    type: FETCH_ADMIN_LIST_FAILED,
    payload: err,
  });
}

const makeParams = (paginationParam) => {
  const orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
  return {
    userType: "orgAdmin",
    organizationId: (orgDetails && orgDetails.organizationId) || getOrgId(),
    ...paginationParam
  };
};
