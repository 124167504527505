import {
  FETCH_EXPLORE_TOPIC_LIST_START,
  FETCH_EXPLORE_TOPIC_LIST_SUCCESS,
  FETCH_EXPLORE_TOPIC_LIST_FAILED,
  RESET_EXPLORE_TOPIC_STATE,
  SET_QUOTE_MODIFICATION_LOADING_STATE,
  SORT_EXPLORE_TOPIC_LIST,
  FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_STARTED,
  FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_SUCCESS,
  FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_FAILED,
  DISABLE_EXPLORE_TOPIC_LOADING,
  FETCH_STORYBOARD_FILTER_STATUS_STARTED,
  FETCH_STORYBOARD_FILTER_STATUS_SUCCESS,
  FETCH_STORYBOARD_FILTER_STATUS_FAILED,
  STORYBOARD_FILTER_STATUS_CHANGE,
  TOPICS_SET_FILTER_SUCCESS
} from '../../constants';
import {
  STORYBOARD_TOPIC_URL,
  CHANGE_QUOTE_URL,
  PREVIOUS_QUOTE_URL,
  FETCH_SCORES_URL,
  STORYBOARD_FILTER_STATUS,
} from '../../../../config/constants';
import { ApiBase } from '../../../utils/apiBase';
import { withType } from '../../../utils/actionOperations';
import { notification } from 'antd';
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchExploreTopicList = (payload, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(FETCH_EXPLORE_TOPIC_LIST_START, {}));
    apiBaseInstance.instance
      .post(STORYBOARD_TOPIC_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        cb && cb();
        dispatch(
          withType(FETCH_EXPLORE_TOPIC_LIST_SUCCESS, {
            data: response.data && response.data.data,
            filters: payload.filters,
            byScore: payload.byScore,
            multipleSelect: payload.multipleSelect,
            selectedUser: payload.selectedUser,
            directReporteesOnly: payload.directReporteesOnly,
          })
        );
      })
      .catch((err) => {
        dispatch(
          withType(FETCH_EXPLORE_TOPIC_LIST_FAILED, {
            error: err,
          })
        );
      });
  };
};

export const resetExploreTopicState = () => {
  return withType(RESET_EXPLORE_TOPIC_STATE);
};

export const changeExploreQuote = (payload, callback) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(SET_QUOTE_MODIFICATION_LOADING_STATE, { loading: true }));
    apiBaseInstance.instance
      .post(CHANGE_QUOTE_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        dispatch(
          withType(SET_QUOTE_MODIFICATION_LOADING_STATE, { loading: false })
        );
        callback && callback(true);
        notification.success({ message: 'Comment modified successfully.' });
      })
      .catch((err) => {
        dispatch(
          withType(SET_QUOTE_MODIFICATION_LOADING_STATE, { loading: false })
        );
        callback && callback(false);
        if (err.response && err.response.status === 404)
          notification.info({ message: 'No quotes left' });
        else notification.error({ message: 'Comment modification failed.' });
      });
  };
};

export const showPreviousExploreQuote = (payload, callback) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(SET_QUOTE_MODIFICATION_LOADING_STATE, { loading: true }));
    apiBaseInstance.instance
      .post(PREVIOUS_QUOTE_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        dispatch(
          withType(SET_QUOTE_MODIFICATION_LOADING_STATE, { loading: false })
        );
        callback && callback(true);
        notification.success({ message: 'Comment modified successfully.' });
      })
      .catch((err) => {
        dispatch(
          withType(SET_QUOTE_MODIFICATION_LOADING_STATE, { loading: false })
        );
        callback && callback(false);
        notification.error({ message: 'Comment modification failed.' });
      });
  };
};

export const sortExploreTopicList = (payload) => {
  return withType(SORT_EXPLORE_TOPIC_LIST, payload);
};

export function exploreTopicsFetchSurveyReportData(payload, cb) {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_STARTED, {}));
    apiBaseInstance
      .post(FETCH_SCORES_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => exploreTopicsFetchSurveyReportSuccess(dispatch, res, cb, payload))
      .catch((err) => exploreTopicsFetchSurveyReportFailure(dispatch, err));
  };
}

function exploreTopicsFetchSurveyReportSuccess(dispatch, res, cb, payload) {
  const data = (res.data && res.data.data && res.data.data.response) || {
    dimensionTable: [],
    itemTable: [],
  };
  cb && cb();
  return dispatch(
    withType(FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_SUCCESS, {
      data,
      filters: payload.filters,
      byScore: payload.byScore,
      multipleSelect: payload.multipleSelect,
      selectedUser: payload.selectedUser,
      directReporteesOnly: payload.directReporteesOnly,
    })
  );
}

function exploreTopicsFetchSurveyReportFailure(dispatch, err) {
  return dispatch(
    withType(FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_FAILED, { error: err })
  );
}

export const disableTopicLoading = (disableTopicLoading) => {
  return (dispatch) => {
    dispatch(
      withType(DISABLE_EXPLORE_TOPIC_LOADING, {
        disableTopicLoading: disableTopicLoading,
      })
    );
  };
};

export const applyFilterOnExploreTopicList = (payload, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    apiBaseInstance.instance
      .post(STORYBOARD_TOPIC_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        dispatch(
          withType(TOPICS_SET_FILTER_SUCCESS, {
            filters: payload.filters,
            byScore: payload.byScore,
            multipleSelect: payload.multipleSelect,
            selectedUser: payload.selectedUser,
            directReporteesOnly: payload.directReporteesOnly,
          })
        );
        cb && cb(true)
      }
      )
      .catch((err) => {
        cb && cb(false);
      });
  };
};

export const fetchStoryboardFilterStatus = (payload, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(FETCH_STORYBOARD_FILTER_STATUS_STARTED, {}));
    apiBaseInstance.instance
      .post(STORYBOARD_FILTER_STATUS, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        cb && cb((response.data &&
          response.data.data &&
          response.data.data.response) ||
          {});
        dispatch(
          withType(FETCH_STORYBOARD_FILTER_STATUS_SUCCESS, {
            data:
              (response.data &&
                response.data.data &&
                response.data.data.response) ||
              {},
          })
        );
      })
      .catch((err) => {
        dispatch(
          withType(FETCH_STORYBOARD_FILTER_STATUS_FAILED, {
            error: err,
          })
        );
      });
  };
};

export const clearStoryboardFilterStatus = (payload, cb) => {
  return { type: STORYBOARD_FILTER_STATUS_CHANGE };
};

export * from './fetchAdminBarchartData'
export * from './setIsApiCalledKey'