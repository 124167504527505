import {
    FORGOT_PASSWORD_FORM_CHANGE,
    FORGOT_PASSWORD_PROCESS_FAILED,
    FORGOT_PASSWORD_PROCESS_SUCCESS,
    FORGOT_PASSWORD_PROCESS_STARTED
} from '../../constants';
import {
    fetchPayload,
    update
} from '../../../utils/reducerOperations';
import {Default} from './InitialState';
import {
    ForgotPasswordChange,
    ForgotPasswordStarted,
    ForgotPasswordSuccess,
    ForgotPasswordFailed
} from './Helpers';
const forgotPasswordPageReducer = (
    state = Default(),
    action
) => {
    let payload = fetchPayload(action);
    switch (action.type) {
    case FORGOT_PASSWORD_FORM_CHANGE:
        return ForgotPasswordChange(state, payload);
    case FORGOT_PASSWORD_PROCESS_STARTED:
        return update(
            state,
            ForgotPasswordStarted(state, payload)
        );
    default:
        return passwordSuccessFailed(state, action);
    }
};
const passwordSuccessFailed = (state, action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
    case FORGOT_PASSWORD_PROCESS_SUCCESS:
        return update(
            state,
            ForgotPasswordSuccess(state, payload)
        );
    case FORGOT_PASSWORD_PROCESS_FAILED:
        return update(
            state,
            ForgotPasswordFailed(state, payload)
        );
    default:
        return {...state};
    }
};

export default forgotPasswordPageReducer;
