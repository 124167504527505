import { deepCopy } from "../../../../../utils/deepCopy"

export const topicOptionChange = (state, payload) => {
    let goals = optionAppliedGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const optionAppliedGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    let autmaticChoices = deepCopy(state.automaticChoices)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.goalId) {
            let scaleOptions = autmaticChoices.filter(item => item.value === payload.selectedScale)
            item.scaleOption = scaleOptions[0].value
            item.questions[0].options = getScaleOptionsList(payload, scaleOptions[0].options)
            item.dimension = payload.dimension
            item.allowNAOption = payload.allowNAOption
            item.questions[0].questionDimension = payload.dimension
            item.questions[0].matrixQuestions.forEach(question => {
                question.followUpQuestions && Array.isArray(question.followUpQuestions) && question.followUpQuestions.forEach(data => {
                    if (data.type === "Text Entry") {
                        data.dimension = payload.dimension
                        data.topicDimension = payload.dimension
                        if (data.questionLabel.includes(`Any Comments?`)) {
                            data.questionLabel = `${payload.dimension} - Any Comments?`
                        }
                    } else {
                        data.questionDimension = payload.dimension
                        data.matrixQuestions.forEach(matrix => matrix.selectedTopic = payload.dimension)
                    }

                })
            });
        }
    })
    return goalsCopy
}

const getScaleOptionsList = (payload, autmaticChoices) => {
    let choicesCopy = deepCopy(autmaticChoices)
    if (payload.allowNAOption) {
        choicesCopy.splice(choicesCopy.length, 0, notApplicableData)
    }
    return choicesCopy
}

const notApplicableData = { value: "6", label: "Not Applicable", disabled: true }