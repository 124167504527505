import { fetchPayload } from "../../../utils/reducerOperations";
import {
  FETCH_TREND_PAIRING_DATA_START,
  FETCH_TREND_PAIRING_DATA_SUCCESS,
  FETCH_TREND_PAIRING_DATA_FAIL,
  FETCH_COMPARISON_SURVEY_DATA_START,
  FETCH_COMPARISON_SURVEY_DATA_SUCCESS,
  FETCH_COMPARISON_SURVEY_DATA_FAIL,
  UPDATE_TREND_DATA_SUCCESS,
  UPDATE_TREND_DATA_START,
  UPDATE_TREND_DATA_FAIL,
  HANDLE_CHANGE_PAIRING_SURVEY_DROPDOWN,
  UPDATE_COMPARISON_SURVEY_DATA,
  HANDLE_ON_DRAG_END,
  HANDLE_DELETE_MATCHED_QUESTION,
  TREND_AUTOMATCHING_START,
  ON_CHANGE_TREND_TOGGLE

} from "../../constants";
import { fetchTrendBenchMarkDataFailed, fetchTrendBenchMarkDataStart, fetchTrendBenchMarkDataSuccess, 
  getComparisonSurveyDataFailed, getComparisonSurveyDataStart, getComparisonSurveyDataSuccess, handleChangePairingDropdown, 
  handleDeleteMatchedQuestion, handleDragEnd, updatePairedComparisonSurvey, updateTrendDataFailed, updateTrendDataStart, 
  updateTrendDataSuccess, autoMatchSurveys, handleChangeTrendToggle
} from './Helper';

import { IniialState } from "./InitialState";

const NewdashboardTrendPairingReducer = (state = IniialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_TREND_PAIRING_DATA_START:
      return fetchTrendBenchMarkDataStart(state, payload);
    case FETCH_TREND_PAIRING_DATA_SUCCESS:
      return fetchTrendBenchMarkDataSuccess(state, payload);
    case FETCH_TREND_PAIRING_DATA_FAIL:
      return fetchTrendBenchMarkDataFailed(state, payload);

    case FETCH_COMPARISON_SURVEY_DATA_START:
      return getComparisonSurveyDataStart(state, payload);
    case FETCH_COMPARISON_SURVEY_DATA_SUCCESS:
      return getComparisonSurveyDataSuccess(state, payload);
    case FETCH_COMPARISON_SURVEY_DATA_FAIL:
      return getComparisonSurveyDataFailed(state, payload);


    case UPDATE_TREND_DATA_START:
      return updateTrendDataStart(state, payload);
    case UPDATE_TREND_DATA_SUCCESS:
      return updateTrendDataSuccess(state, payload);
    case UPDATE_TREND_DATA_FAIL:
      return updateTrendDataFailed(state, payload);

    case
      HANDLE_CHANGE_PAIRING_SURVEY_DROPDOWN:
      return handleChangePairingDropdown(state, payload);
    case UPDATE_COMPARISON_SURVEY_DATA:
      return updatePairedComparisonSurvey(state, payload);

    case HANDLE_ON_DRAG_END:
      return handleDragEnd(state, payload);

    case HANDLE_DELETE_MATCHED_QUESTION:
      return handleDeleteMatchedQuestion(state, payload);

    case TREND_AUTOMATCHING_START:
      return autoMatchSurveys(state, payload);

    case ON_CHANGE_TREND_TOGGLE:
      return handleChangeTrendToggle(state, payload)

    default:
      return { ...state };
  }
};

export default NewdashboardTrendPairingReducer;