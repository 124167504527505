export default {
    routeName: 'answer',
    channelTypes: {
        anounymous: 'anonymous',
        email: 'email',
        qr: 'qr'
    },
    PREVIEW_LINK: 'preview-project',
    SURVEY_NOT_LIVE_MESSAGE: "This survey is not live now",
    SURVEY_SCHEDULED: "scheduled",
    SURVEY_PRE_ACTIVE_MESSAGE: 'This survey will be available after ',
    SURVEY_CLOSED: "closed",
    CLOSED_SURVEY_RESPONSE: 'This survey has been closed',
    SURVEY_NOT_FOUND: "Couldn't find survey",
    PROJECT_ID: 'projectId',
    FETCH_MODE: {
        ANSWER: 'answer',
        PREVIEW: "preview"

    },
    dummy: [
        {
            "dimension": "",
            "questions": [
                {
                    "question": "<p>Share your thoughts and opinions.</p><p>We want to know your thoughts about your work and our organization so we can improve.</p><p>Please take a few minutes to complete this survey.</p><p><em>Takes 15-20 minutes</em></p>",
                    "questionLabel": "",
                    "isSelected": true,
                    "isCustom": true,
                    "questionDimension": "",
                    "qid": "39fc1ed3-9305-41b6-91b9-cb9098757911",
                    "type": "Descriptive Text",
                    "options": []
                }
            ],
            "goalId": "1987879b-0ce8-4bfa-afaa-c27f94f5b997"
        },
        {
            "isOutcomeVariable": true,
            "isDefault": true,
            "isStatic": true,
            "goalId": "engagement",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Engagement",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "4b2488e86b8a0f3f25f1f30bb001f52538fe92808fa9d30da7a4c76297736c2e",
                            "questionId": "a2357cb3-808e-4de7-a60d-3da621e846c2",
                            "isSelected": true,
                            "questionIdLabel": "N1",
                            "dimensionsId": "495b93a386a436dc7ba0be4c6efe2c04139ec00d8612a51fe9494c0eaec13e78",
                            "followUpQuestions": [],
                            "text": "I can see the positive impact of my work on someone else.",
                            "value": "a2357cb3-808e-4de7-a60d-3da621e846c2",
                            "haveFollowUpQuestion": true,
                            "englishQuestion": "I can see the positive impact of my work on someone else."
                        }
                    ],
                    "qid": "3e1882f7-6984-4eee-be62-50bb13b96534",
                    "questionLabel": "Engagement"
                }
            ],
            "isSelected": true,
            "description": "",
            "dimension": "Engagement",
            "followUpQuestions": []
        },
        {
            "isOutcomeVariable": true,
            "isDefault": true,
            "isStatic": true,
            "goalId": "engagement",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Engagement",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "204d70e78d0e5b642d8eea5adb67a35aa61f0426126d373d1bd103c16fa1d800",
                            "questionId": "abfc645d-7ac2-4f05-b352-e2466a926d56",
                            "isSelected": true,
                            "questionIdLabel": "N2",
                            "dimensionsId": "495b93a386a436dc7ba0be4c6efe2c04139ec00d8612a51fe9494c0eaec13e78",
                            "text": "My organization motivates me to go above and beyond what I would normally do.",
                            "value": "abfc645d-7ac2-4f05-b352-e2466a926d56",
                            "englishQuestion": "My organization motivates me to go above and beyond what I would normally do."
                        },
                        {
                            "staticQuestionId": "13c1769f222d1c24fb4dafe7c52f48439f1dc5d72fe5956ee01569286307d12f",
                            "questionId": "cb7873e9-cdbc-49a6-a16f-bc6c19b0a1c1",
                            "isSelected": true,
                            "questionIdLabel": "N3",
                            "dimensionsId": "495b93a386a436dc7ba0be4c6efe2c04139ec00d8612a51fe9494c0eaec13e78",
                            "text": "I would recommend my organization as a great place to work.",
                            "value": "cb7873e9-cdbc-49a6-a16f-bc6c19b0a1c1",
                            "englishQuestion": "I would recommend my organization as a great place to work."
                        }
                    ],
                    "qid": "3e1882f7-6984-4eee-be62-50bb13b96534",
                    "questionLabel": "Engagement"
                }
            ],
            "isSelected": true,
            "description": "This is Engagement",
            "dimension": "Engagement",
            "followUpQuestions": []
        },
        {
            "isDefault": true,
            "goalId": "commitment",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Commitment",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "dcb75be00bdf6b1bc7189781eb9c5baaf5f996578eddce46edfa469162b71169",
                            "questionId": "a70dd540-841d-4c22-8ea5-b81cf3440259",
                            "isSelected": true,
                            "questionIdLabel": "N4",
                            "dimensionsId": "6a19f0fb4be54511524bcd5b0c98b38da1ee049a39735c39311e10336024436f",
                            "text": "My organization values me as an individual.",
                            "value": "a70dd540-841d-4c22-8ea5-b81cf3440259",
                            "englishQuestion": "My organization values me as an individual."
                        },
                        {
                            "staticQuestionId": "12e9aa5937082fe7c690bd8f162571103c01d04781289c986dbb2f5c028ee008",
                            "questionId": "d2ad2bde-124b-43d0-a5bd-01096704a97e",
                            "isSelected": true,
                            "questionIdLabel": "N5",
                            "dimensionsId": "6a19f0fb4be54511524bcd5b0c98b38da1ee049a39735c39311e10336024436f",
                            "text": "I see my organization's success as my success.",
                            "value": "d2ad2bde-124b-43d0-a5bd-01096704a97e",
                            "englishQuestion": "I see my organization's success as my success."
                        }
                    ],
                    "qid": "f51e074d-6937-462f-b054-6ac5dd2d9da9",
                    "questionLabel": "Commitment"
                }
            ],
            "isSelected": true,
            "followUpQuestions": [],
            "dimension": "Commitment",
            "haveFollowUpQuestion": true
        },
        {
            "dimension": "Comments",
            "questions": [
                {
                    "goalId": "commitment",
                    "questionId": "0297ae4d-7a45-4b41-bde4-5a9c91ca6733",
                    "question": "Any Comments?",
                    "dimensionsId": "01db91d06032cc64162c16f8e35725b0e632beebdde8b2c2459979d04fb1e20c",
                    "type": "Text Entry",
                    "qid": "0297ae4d-7a45-4b41-bde4-5a9c91ca6733",
                    "questionLabel": "Commitment - Any Comments?",
                    "condition": "",
                    "staticQuestionId": "af10e9e3b0da42836916e7204cdd0739528837fa89f21c45d340d1ad7cffce8f",
                    "options": [],
                    "isSelected": true,
                    "questionIdLabel": "T1",
                    "isFollowUpQuestion": true,
                    "dimension": "Comments",
                    "value": "",
                    "topicDimension": "Commitment",
                    "englishQuestion": "Any Comments?"
                }
            ]
        },
        {
            "isDefault": true,
            "goalId": "empowerment",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Empowerment",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "d80d96f802a0c2b698d25fbbcb60ca76dc6ee8536020c3ea1102deadceafc43b",
                            "questionId": "5e599e70-589a-45bb-bc46-d429619d7098",
                            "isSelected": true,
                            "questionIdLabel": "N9",
                            "dimensionsId": "ca9195d998f68dfb2b4ea6c640943a567bc1f0e6cf05adc0ddf9f852b21ed0fc",
                            "text": "I can make an impact at my organization.",
                            "value": "5e599e70-589a-45bb-bc46-d429619d7098",
                            "englishQuestion": "I can make an impact at my organization."
                        },
                        {
                            "staticQuestionId": "a39cd07f6cd8cd851273fcc47eb267a4877b99e016153cb922eb3da8fafc28a0",
                            "questionId": "4d0b18e6-eff8-4c04-8ef3-c65d66cec3ec",
                            "isSelected": true,
                            "questionIdLabel": "N10",
                            "dimensionsId": "ca9195d998f68dfb2b4ea6c640943a567bc1f0e6cf05adc0ddf9f852b21ed0fc",
                            "text": "I am involved in making decisions that affect me and my work.",
                            "value": "4d0b18e6-eff8-4c04-8ef3-c65d66cec3ec",
                            "englishQuestion": "I am involved in making decisions that affect me and my work."
                        },
                        {
                            "staticQuestionId": "311c9417c64519f65d21830f92081e56d571f79859a3945fffeab2256b56a7a5",
                            "questionId": "0192d6e4-cece-4709-b91c-14b64c800666",
                            "isSelected": true,
                            "questionIdLabel": "N11",
                            "dimensionsId": "ca9195d998f68dfb2b4ea6c640943a567bc1f0e6cf05adc0ddf9f852b21ed0fc",
                            "text": "I have everything I need to do my job effectively.",
                            "value": "0192d6e4-cece-4709-b91c-14b64c800666",
                            "englishQuestion": "I have everything I need to do my job effectively."
                        }
                    ],
                    "qid": "870310d2-ada9-445b-8b4e-31dc2994e1f2",
                    "questionLabel": "Empowerment"
                }
            ],
            "isSelected": true,
            "dimension": "Empowerment",
            "followUpQuestions": []
        },
        {
            "isDefault": true,
            "goalId": "inclusion",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Inclusion",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "186056cae796c15f8c86d67810672987aae1d1fa9b7ef799d3bc4420af6f234f",
                            "questionId": "cf503294-f539-4acb-9abf-573672487d8d",
                            "isSelected": true,
                            "questionIdLabel": "N12",
                            "dimensionsId": "e18ba6a8049379ef22dee71e1c428126e7f2e13446778fb26d5f6b00da2daa50",
                            "text": "I consider some co-workers as true friends.",
                            "value": "cf503294-f539-4acb-9abf-573672487d8d",
                            "englishQuestion": "I consider some co-workers as true friends."
                        },
                        {
                            "staticQuestionId": "8df15c5cfb1893fc89232595d20d9dc293f526047ab7235029bb83583cfc5990",
                            "questionId": "690e627e-51f9-4c7c-bf58-f8f39ee1d00d",
                            "isSelected": true,
                            "questionIdLabel": "N13",
                            "dimensionsId": "e18ba6a8049379ef22dee71e1c428126e7f2e13446778fb26d5f6b00da2daa50",
                            "text": "People care about me at work.",
                            "value": "690e627e-51f9-4c7c-bf58-f8f39ee1d00d",
                            "englishQuestion": "People care about me at work."
                        },
                        {
                            "staticQuestionId": "966b65dfe313c54899911d5cbd775dcb272b602903f1fcf69c17293ca9d88638",
                            "questionId": "074ee84d-5ce8-49b8-a0a9-02a207241061",
                            "isSelected": true,
                            "questionIdLabel": "N14",
                            "dimensionsId": "e18ba6a8049379ef22dee71e1c428126e7f2e13446778fb26d5f6b00da2daa50",
                            "text": "I feel like I belong at my organization.",
                            "value": "074ee84d-5ce8-49b8-a0a9-02a207241061",
                            "englishQuestion": "I feel like I belong at my organization."
                        }
                    ],
                    "qid": "27ec3895-f927-4a51-8024-1f26a64731b7",
                    "questionLabel": "Inclusion"
                }
            ],
            "isSelected": true,
            "dimension": "Inclusion",
            "followUpQuestions": []
        },
        {
            "isDefault": true,
            "goalId": "career",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Career Development",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "f526cdb07e164fb973a63c709e9e07722807c0ce0f611436e9f3746af144db28",
                            "questionId": "e8b997dd-8812-4d95-a987-0610c9d5ac61",
                            "isSelected": true,
                            "questionIdLabel": "N15",
                            "dimensionsId": "8fd6200ab94bc74a5753736f8db757e53536bd0fa87254e0d971b30ea0ec760e",
                            "text": "I have good opportunities for advancement at my organization.",
                            "value": "e8b997dd-8812-4d95-a987-0610c9d5ac61",
                            "englishQuestion": "I have good opportunities for advancement at my organization."
                        },
                        {
                            "staticQuestionId": "98629875d62fa00353c88e173bc641cbe7b03347786b3d768c1f0a930a195cc4",
                            "questionId": "669659fd-95b7-4575-8727-2ed697e53ee1",
                            "isSelected": true,
                            "questionIdLabel": "N16",
                            "dimensionsId": "8fd6200ab94bc74a5753736f8db757e53536bd0fa87254e0d971b30ea0ec760e",
                            "text": "I can grow and develop my skills here.",
                            "value": "669659fd-95b7-4575-8727-2ed697e53ee1",
                            "englishQuestion": "I can grow and develop my skills here."
                        },
                        {
                            "staticQuestionId": "5959685d92da227722072a2ed658c2063aa0f330c1e7384b686056d0a9c8500e",
                            "questionId": "a0d8217d-2d91-4b99-97fd-c389b09aaef7",
                            "isSelected": true,
                            "questionIdLabel": "N17",
                            "dimensionsId": "8fd6200ab94bc74a5753736f8db757e53536bd0fa87254e0d971b30ea0ec760e",
                            "text": "This is a great company for me to grow as a professional.",
                            "value": "a0d8217d-2d91-4b99-97fd-c389b09aaef7",
                            "englishQuestion": "This is a great company for me to grow as a professional."
                        }
                    ],
                    "qid": "863a67ab-54e5-45c3-83d4-07cabead5593",
                    "questionLabel": "Career Development"
                }
            ],
            "isSelected": true,
            "dimension": "Career Development",
            "followUpQuestions": []
        },
        {
            "isDefault": true,
            "goalId": "wellness",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Wellness",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "d22599584893313e70ac27885f4c15079354722486f977ce7decd4f21712e7f6",
                            "questionId": "5f28e80f-cf1d-4f71-b782-5d84046fd6e0",
                            "isSelected": true,
                            "questionIdLabel": "N18",
                            "dimensionsId": "bc90a50b2fa0d31ce6125f9ca1fb49983accd9400aeb1ea193af349c9be5f08d",
                            "text": "It is easy for me to balance work and personal activities.",
                            "value": "5f28e80f-cf1d-4f71-b782-5d84046fd6e0",
                            "englishQuestion": "It is easy for me to balance work and personal activities."
                        },
                        {
                            "staticQuestionId": "79c83dde67ed595b8c21ef7cde324e25502cf011b6fdf4a1dedc6741a1e14c95",
                            "questionId": "9c3f6513-2321-4f12-875b-e027a0d58093",
                            "isSelected": true,
                            "questionIdLabel": "N19",
                            "dimensionsId": "bc90a50b2fa0d31ce6125f9ca1fb49983accd9400aeb1ea193af349c9be5f08d",
                            "text": "I feel comfortable raising concerns about my own or a colleague's wellbeing.",
                            "value": "9c3f6513-2321-4f12-875b-e027a0d58093",
                            "englishQuestion": "I feel comfortable raising concerns about my own or a colleague's wellbeing."
                        }
                    ],
                    "qid": "b64c8f7d-49af-4c85-8413-6276db47cc86",
                    "questionLabel": "Wellness"
                }
            ],
            "isSelected": true,
            "dimension": "Wellness",
            "followUpQuestions": []
        },
        {
            "isDefault": true,
            "goalId": "managers",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "My Manager",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "f5177b3e76fda3085cf6f3a6692abdefa9d29058b10e7340b95b3d45a07e8269",
                            "questionId": "35c3d398-27b9-4c77-8c34-15c63fddc7a7",
                            "isSelected": true,
                            "questionIdLabel": "N20",
                            "dimensionsId": "453bc0cb291fb32153784bfea2496aca72da9d79d373a0cbee1b8ef4dd6c6e88",
                            "text": "My manager gives me useful feedback that helps me improve.",
                            "value": "35c3d398-27b9-4c77-8c34-15c63fddc7a7",
                            "englishQuestion": "My manager gives me useful feedback that helps me improve."
                        },
                        {
                            "staticQuestionId": "7c2edda651a56122a1496a46768ae6362641fac1e467da1cbbef0155b87359ff",
                            "questionId": "b4f9132d-511b-47ad-9c06-5ec4fb62e8cc",
                            "isSelected": true,
                            "questionIdLabel": "N21",
                            "dimensionsId": "453bc0cb291fb32153784bfea2496aca72da9d79d373a0cbee1b8ef4dd6c6e88",
                            "text": "My manager helps me set clear goals and priorities for my work.",
                            "value": "b4f9132d-511b-47ad-9c06-5ec4fb62e8cc",
                            "englishQuestion": "My manager helps me set clear goals and priorities for my work."
                        },
                        {
                            "staticQuestionId": "a7aeb66eee4f2b45877b95c8854baae8acc88ca3d3ad60c0a74fd5576ecba0af",
                            "questionId": "7c186b83-e51b-4526-8558-5fda5b38e20a",
                            "isSelected": true,
                            "questionIdLabel": "N22",
                            "dimensionsId": "453bc0cb291fb32153784bfea2496aca72da9d79d373a0cbee1b8ef4dd6c6e88",
                            "text": "My manager cares about me as a person, not just as an employee.",
                            "value": "7c186b83-e51b-4526-8558-5fda5b38e20a",
                            "englishQuestion": "My manager cares about me as a person, not just as an employee."
                        }
                    ],
                    "qid": "a7d2ed5b-6b2b-48d9-9169-1ea136f7e76f",
                    "questionLabel": "My Manager"
                }
            ],
            "isSelected": true,
            "dimension": "My Managers",
            "followUpQuestions": []
        },
        {
            "isFromEditor": true,
            "goalId": "ea6cd9ee-89d3-4821-b6b4-d05051cf2896",
            "questions": [
                {
                    "question": "<p>Now we have some questions about how the organization functions more broadly. Think about your own experiences, but also those of people you know and your general thoughts and impressions about this organization.</p>",
                    "isDemographic": false,
                    "isSelected": true,
                    "isCustom": true,
                    "questionDimension": "Click to edit dimension ",
                    "position": "vertical",
                    "type": "Descriptive Text",
                    "qid": "bbe0c1ce-ce38-44a4-aa47-3d1ce991a42d",
                    "questionLabel": "Descriptive text"
                }
            ],
            "isDescriptive": true,
            "isSelected": true,
            "dimension": "Descriptive text"
        },
        {
            "isDefault": true,
            "goalId": "futureofwork",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Future of work",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "9541382d4c053fef8599b27679d6f4955d4c6442eb13ac03812fc88b20124e67",
                            "questionId": "280502c2-b0a9-4fe2-83ba-26a453d6a2d5",
                            "isSelected": true,
                            "questionIdLabel": "N23",
                            "dimensionsId": "b80856b226c854ddb0ad6146c083f98e6503a125767a37ab9028539455e12218",
                            "text": "I am at least as productive when working from home as when working in an office.",
                            "value": "280502c2-b0a9-4fe2-83ba-26a453d6a2d5",
                            "englishQuestion": "I am at least as productive when working from home as when working in an office."
                        },
                        {
                            "staticQuestionId": "f11fd621b092ce59842a3e598d46d17a7379e65715f15bcdc09867e997087eb4",
                            "questionId": "493d5c62-0e04-4bf5-b38f-c1cb042402fa",
                            "isSelected": true,
                            "questionIdLabel": "N24",
                            "dimensionsId": "b80856b226c854ddb0ad6146c083f98e6503a125767a37ab9028539455e12218",
                            "text": "I have the technology I need to work remotely.",
                            "value": "493d5c62-0e04-4bf5-b38f-c1cb042402fa",
                            "englishQuestion": "I have the technology I need to work remotely."
                        },
                        {
                            "staticQuestionId": "42afc4ddac824b487a27ea1c1e8a63866dd9da8eb112a6ed0ccd529cd969cd11",
                            "questionId": "cc32cd2b-ce83-40f2-b71c-f1d6166fdb1c",
                            "isSelected": true,
                            "questionIdLabel": "N25",
                            "dimensionsId": "b80856b226c854ddb0ad6146c083f98e6503a125767a37ab9028539455e12218",
                            "text": "My organization can succeed if workers like me rarely go to a physical office.",
                            "value": "cc32cd2b-ce83-40f2-b71c-f1d6166fdb1c",
                            "englishQuestion": "My organization can succeed if workers like me rarely go to a physical office."
                        }
                    ],
                    "qid": "e9a198a0-4884-4cd1-b27b-669f1662074f",
                    "questionLabel": "Future of work"
                }
            ],
            "isSelected": true,
            "dimension": "Future of Work",
            "followUpQuestions": []
        },
        {
            "isDefault": true,
            "goalId": "agility",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Agility",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "0a7dc573cc4a969833001124d0f45996fea6e0d6dd3940b261825542a36bfc48",
                            "questionId": "f5d80b04-570e-4729-9928-13679360e7f1",
                            "isSelected": true,
                            "questionIdLabel": "N26",
                            "dimensionsId": "b22bacd700565d3972cceed017d92f0eaa48eabadb3c4607c77d3c4b45c084cd",
                            "text": "We quickly bounce back from failures and setbacks.",
                            "value": "f5d80b04-570e-4729-9928-13679360e7f1",
                            "englishQuestion": "We quickly bounce back from failures and setbacks."
                        },
                        {
                            "staticQuestionId": "42637a733f921187e95db75fb54b84edeb22051a7c546ebbc63e52a7188387cf",
                            "questionId": "6bce136e-f739-4166-a5b4-d7e1826cb612",
                            "isSelected": true,
                            "questionIdLabel": "N27",
                            "dimensionsId": "b22bacd700565d3972cceed017d92f0eaa48eabadb3c4607c77d3c4b45c084cd",
                            "text": "My organization has formal processes in place to learn from failures.",
                            "value": "6bce136e-f739-4166-a5b4-d7e1826cb612",
                            "englishQuestion": "My organization has formal processes in place to learn from failures."
                        },
                        {
                            "staticQuestionId": "002be5fd705b42ea2598534b5be1f838461c138efc1bb1075f6690be43f9f823",
                            "questionId": "65808121-819c-417f-8f72-d99d7c602d42",
                            "isSelected": true,
                            "questionIdLabel": "N28",
                            "dimensionsId": "b22bacd700565d3972cceed017d92f0eaa48eabadb3c4607c77d3c4b45c084cd",
                            "text": "We quickly change tactics if something doesn't work.",
                            "value": "65808121-819c-417f-8f72-d99d7c602d42",
                            "englishQuestion": "We quickly change tactics if something doesn't work."
                        }
                    ],
                    "qid": "57f876cd-6ffe-477e-8139-dfcdf5dd5a9f",
                    "questionLabel": "Agility"
                }
            ],
            "isSelected": true,
            "dimension": "Agility",
            "followUpQuestions": []
        },
        {
            "isDefault": true,
            "goalId": "collaboration",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Collaboration",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "7b3c1df96314472d03d6e581d40e9709f1dc95f606ec4d3cb3f1d31d4765abb5",
                            "questionId": "de81f02a-37e0-40e2-8cc5-40595aff1d4d",
                            "isSelected": true,
                            "questionIdLabel": "N29",
                            "dimensionsId": "0d5a011050205b392ce21075879cfcdeb36ffe7affc285b6b3f0a15e62f79525",
                            "text": "People freely help one another at my organization.",
                            "value": "de81f02a-37e0-40e2-8cc5-40595aff1d4d",
                            "englishQuestion": "People freely help one another at my organization."
                        },
                        {
                            "staticQuestionId": "3fc3cfa411beb4dc171bd597456b14463d13006994d44097c44555a44a44ad75",
                            "questionId": "552ae404-dfea-4418-b7d5-3e6c8bc6b9fd",
                            "isSelected": true,
                            "questionIdLabel": "N30",
                            "dimensionsId": "0d5a011050205b392ce21075879cfcdeb36ffe7affc285b6b3f0a15e62f79525",
                            "text": "Different teams work well together to achieve common goals.",
                            "value": "552ae404-dfea-4418-b7d5-3e6c8bc6b9fd",
                            "englishQuestion": "Different teams work well together to achieve common goals."
                        },
                        {
                            "staticQuestionId": "a6e7b9d0f1b3dcb088c3d534e2ada18d14687d8894086bef9902e6ce87cb796d",
                            "questionId": "bebd9775-a8f6-47f0-a97d-9b8e45529c99",
                            "isSelected": true,
                            "questionIdLabel": "N31",
                            "dimensionsId": "0d5a011050205b392ce21075879cfcdeb36ffe7affc285b6b3f0a15e62f79525",
                            "text": "The organization encourages us to build connections within and across teams.",
                            "value": "bebd9775-a8f6-47f0-a97d-9b8e45529c99",
                            "englishQuestion": "The organization encourages us to build connections within and across teams."
                        }
                    ],
                    "qid": "426c3902-0ed3-44fa-a13b-ae5f48f3f01c",
                    "questionLabel": "Collaboration"
                }
            ],
            "isSelected": true,
            "dimension": "Collaboration",
            "followUpQuestions": []
        },
        {
            "isDefault": true,
            "goalId": "communication",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Communication",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "f098d2b18c5f5e36fc1880f6902912c231097c516417fc08aa44e54afc71b38b",
                            "questionId": "eddcc165-3600-4c26-b789-477bab5591e5",
                            "isSelected": true,
                            "questionIdLabel": "N32",
                            "dimensionsId": "d4e16994df9002e53294f899714d7137592fd13ba986fcd070214dc85d659968",
                            "text": "This organization listens to what I have to say.",
                            "value": "eddcc165-3600-4c26-b789-477bab5591e5",
                            "englishQuestion": "This organization listens to what I have to say."
                        },
                        {
                            "staticQuestionId": "e62763f850410f0cfb5306888e6290fce2619269c717c33d2072825d49ee3a8b",
                            "questionId": "a53b7952-2f38-42da-a757-4a4a60dbdb7b",
                            "isSelected": true,
                            "questionIdLabel": "N33",
                            "dimensionsId": "d4e16994df9002e53294f899714d7137592fd13ba986fcd070214dc85d659968",
                            "text": "There is honest and open two-way communication at my organization.",
                            "value": "a53b7952-2f38-42da-a757-4a4a60dbdb7b",
                            "englishQuestion": "There is honest and open two-way communication at my organization."
                        },
                        {
                            "staticQuestionId": "f96105408f875db82fb0570c2b557aff933a872052510732067cc4c0d7a877c1",
                            "questionId": "2f134067-225e-4e26-81e6-c4dbfa642cf5",
                            "isSelected": true,
                            "questionIdLabel": "N34",
                            "dimensionsId": "d4e16994df9002e53294f899714d7137592fd13ba986fcd070214dc85d659968",
                            "text": "My organization is effective communicating about changes.",
                            "value": "2f134067-225e-4e26-81e6-c4dbfa642cf5",
                            "englishQuestion": "My organization is effective communicating about changes."
                        }
                    ],
                    "qid": "7204f7ab-963e-4273-824b-5a17eca912bb",
                    "questionLabel": "Communication"
                }
            ],
            "isSelected": true,
            "dimension": "Communication",
            "followUpQuestions": []
        },
        {
            "isDefault": true,
            "goalId": "diversity",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Diversity",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "6416bdb997aa1aaba6b772ce5988a172e00c241128384adb3cbac657fac9938c",
                            "questionId": "ef0288ba-ee1f-4466-8fd8-af2962626ffa",
                            "isSelected": true,
                            "questionIdLabel": "N35",
                            "dimensionsId": "f277b1934a3bf105a7d2730f9ab3a398311ec76c0aa491b9b6c8283957751d85",
                            "text": "My organization values diverse perspectives.",
                            "value": "ef0288ba-ee1f-4466-8fd8-af2962626ffa",
                            "englishQuestion": "My organization values diverse perspectives."
                        },
                        {
                            "staticQuestionId": "a20210cd03abe8c89b2ff388e89640be4cfef7f473e931fe160b78bce6a440bf",
                            "questionId": "83a8c569-e48a-4656-a42b-bcd8125496f8",
                            "isSelected": true,
                            "questionIdLabel": "N36",
                            "dimensionsId": "f277b1934a3bf105a7d2730f9ab3a398311ec76c0aa491b9b6c8283957751d85",
                            "text": "All employees, regardless of their backgrounds, are treated fairly.",
                            "value": "83a8c569-e48a-4656-a42b-bcd8125496f8",
                            "englishQuestion": "All employees, regardless of their backgrounds, are treated fairly."
                        },
                        {
                            "staticQuestionId": "e39c27cf2b84a65df94a1b6c5eb549f497e0d51736b87da21d121846cdc93baa",
                            "questionId": "8aba38e8-c19d-466a-b99a-aaeaa4d5ba18",
                            "isSelected": true,
                            "questionIdLabel": "N37",
                            "dimensionsId": "f277b1934a3bf105a7d2730f9ab3a398311ec76c0aa491b9b6c8283957751d85",
                            "text": "Diversity and inclusion are important priorities for my organization.",
                            "value": "8aba38e8-c19d-466a-b99a-aaeaa4d5ba18",
                            "englishQuestion": "Diversity and inclusion are important priorities for my organization."
                        }
                    ],
                    "qid": "cd90fa2a-827b-46c4-9ea6-844dfdc069cd",
                    "questionLabel": "Diversity"
                }
            ],
            "isSelected": true,
            "dimension": "Diversity",
            "followUpQuestions": []
        },
        {
            "isDefault": true,
            "goalId": "vision",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Vision",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "4069cccc8b4552a0a62bcfb8e843343b2f5c684bdaef9814067923804769c501",
                            "questionId": "02d62e60-73db-4d73-b7e8-b4e9d2b949db",
                            "isSelected": true,
                            "questionIdLabel": "N38",
                            "dimensionsId": "5944ae849448011ca08c3785f1de1a54c8a96d6c23f787f9d962b624edd4151d",
                            "text": "My organization has a good long-term future.",
                            "value": "02d62e60-73db-4d73-b7e8-b4e9d2b949db",
                            "englishQuestion": "My organization has a good long-term future."
                        },
                        {
                            "staticQuestionId": "0ac55623360ea0a531b0991c6fe0875861d308773bd5a873330d5c658c551c62",
                            "questionId": "ba955674-70bf-4f32-b743-2d528abaab21",
                            "isSelected": true,
                            "questionIdLabel": "N39",
                            "dimensionsId": "5944ae849448011ca08c3785f1de1a54c8a96d6c23f787f9d962b624edd4151d",
                            "text": "I could explain my organization's vision to someone who doesn't work here.",
                            "value": "ba955674-70bf-4f32-b743-2d528abaab21",
                            "englishQuestion": "I could explain my organization's vision to someone who doesn't work here."
                        },
                        {
                            "staticQuestionId": "15d9d38dc03c12b4ba0ba36ba77053fe4b50158f181cf3a856478d489a608084",
                            "questionId": "af4bdc18-78ec-4f00-a898-35d7fadcce42",
                            "isSelected": true,
                            "questionIdLabel": "N40",
                            "dimensionsId": "5944ae849448011ca08c3785f1de1a54c8a96d6c23f787f9d962b624edd4151d",
                            "text": "I am inspired by my organization's vision.",
                            "value": "af4bdc18-78ec-4f00-a898-35d7fadcce42",
                            "englishQuestion": "I am inspired by my organization's vision."
                        }
                    ],
                    "qid": "8f22048a-aee1-41da-8a99-0f7eb05d099c",
                    "questionLabel": "Vision"
                }
            ],
            "isSelected": true,
            "dimension": "Vision",
            "followUpQuestions": []
        },
        {
            "isFromEditor": true,
            "goalId": "eb2cb851-a4e8-4c12-b2b7-36b3a20648e9",
            "questions": [
                {
                    "question": "<p>Ok! This is the last section of the survey. Thanks for coming this far. We have just a few questions about the organization’s senior leaders.</p>",
                    "isDemographic": false,
                    "isSelected": true,
                    "isCustom": true,
                    "questionDimension": "Click to edit dimension ",
                    "position": "vertical",
                    "type": "Descriptive Text",
                    "qid": "88c27d3d-dc9a-4245-91df-1d671c113c9a",
                    "questionLabel": "Descriptive text"
                }
            ],
            "isDescriptive": true,
            "isSelected": true,
            "dimension": "Descriptive text"
        },
        {
            "isDefault": true,
            "goalId": "seniorleaders",
            "isTemplate": true,
            "questions": [
                {
                    "question": "",
                    "options": [
                        {
                            "label": "Strongly disagree",
                            "value": "1",
                            "englishLabel": "Strongly disagree"
                        },
                        {
                            "label": "Somewhat disagree",
                            "value": "2",
                            "englishLabel": "Somewhat disagree"
                        },
                        {
                            "label": "Neither agree nor disagree",
                            "value": "3",
                            "englishLabel": "Neither agree nor disagree"
                        },
                        {
                            "label": "Somewhat agree",
                            "value": "4",
                            "englishLabel": "Somewhat agree"
                        },
                        {
                            "label": "Strongly agree",
                            "value": "5",
                            "englishLabel": "Strongly agree"
                        }
                    ],
                    "questionDimension": "Senior Leaders",
                    "type": "Matrix",
                    "matrixQuestions": [
                        {
                            "staticQuestionId": "1a9d14afa7c19f19eb9b0b488699cbf31b8da30c25341f3820c6fe567971726b",
                            "questionId": "eba2d3ab-e288-4b71-a2e7-3acc015ef56f",
                            "isSelected": true,
                            "questionIdLabel": "N41",
                            "dimensionsId": "a81dc95979d4ad61b5ccc8fc92d3c99bb43838f09f10207aee2f269f22e92bba",
                            "text": "Senior leaders are moving the organization in the right direction.",
                            "value": "eba2d3ab-e288-4b71-a2e7-3acc015ef56f",
                            "englishQuestion": "Senior leaders are moving the organization in the right direction."
                        },
                        {
                            "staticQuestionId": "df9e5d4d0fc7bf3906f00d0f16d15ea5509cdf210007d8710b64c7f360aabf74",
                            "questionId": "fe46e7ff-b351-412b-8859-c826d6754669",
                            "isSelected": true,
                            "questionIdLabel": "N42",
                            "dimensionsId": "a81dc95979d4ad61b5ccc8fc92d3c99bb43838f09f10207aee2f269f22e92bba",
                            "text": "Senior leaders demonstrate that people are critical to the organization's success.",
                            "value": "fe46e7ff-b351-412b-8859-c826d6754669",
                            "englishQuestion": "Senior leaders demonstrate that people are critical to the organization's success."
                        },
                        {
                            "staticQuestionId": "71098e5b2eb7b0181a3eb85f15d9c8e4b4e9f08d4a7480c131954acfd1030974",
                            "questionId": "4631c6c8-e249-470b-a9ce-60e56894a227",
                            "isSelected": true,
                            "questionIdLabel": "N43",
                            "dimensionsId": "a81dc95979d4ad61b5ccc8fc92d3c99bb43838f09f10207aee2f269f22e92bba",
                            "text": "Senior leaders are good role models of our organization's core values.",
                            "value": "4631c6c8-e249-470b-a9ce-60e56894a227",
                            "englishQuestion": "Senior leaders are good role models of our organization's core values."
                        }
                    ],
                    "qid": "785b0eb1-d6f9-490b-96b0-8c93a2c7d540",
                    "questionLabel": "Senior Leaders"
                }
            ],
            "isSelected": true,
            "dimension": "Senior Leaders",
            "followUpQuestions": []
        },
        {
            "goalId": "demographics",
            "isDemographic": true,
            "isTemplate": true,
            "questions": [
                {
                    "staticQuestionId": "968ba19021404998a19084fdbb660e0f97067e2266259fa037f1ead8abd96c5d",
                    "questionId": "a4490e64-0ed5-4bf4-a84d-550195a21a48",
                    "question": "How long have you worked in your current job?",
                    "isDemographic": true,
                    "isSelected": true,
                    "options": [
                        {
                            "label": "Less than 1 year",
                            "id": "job tenure-1",
                            "value": "1"
                        },
                        {
                            "label": "1-3 years",
                            "id": "job tenure-2",
                            "value": "2"
                        },
                        {
                            "label": "4-6 years",
                            "id": "job tenure-3",
                            "value": "3"
                        },
                        {
                            "label": "6-8 years",
                            "id": "job tenure-4",
                            "value": "4"
                        },
                        {
                            "label": "More than 8 years",
                            "id": "job tenure-5",
                            "value": "5"
                        }
                    ],
                    "questionIdLabel": "N44",
                    "questionDimension": "Demographics",
                    "dimensionsId": "122c16fb143d8fe5bf603071d2d74a7d5a0b9013d33a356719f4f370af66b1fd",
                    "type": "Multiple Choice",
                    "qid": "a4490e64-0ed5-4bf4-a84d-550195a21a48",
                    "questionLabel": "Job Tenure"
                }
            ],
            "isSelected": true,
            "anonymousOnly": true,
            "dimension": "Demographics"
        },
        {
            "goalId": "multipleOption",
            "isTemplate": true,
            "questions": [
                {
                    "staticQuestionId": "fdda566a412450eb122ffdf72eee58acf3959d50c77c8c2b6d2406e4c720c277",
                    "questionId": "4c38db5c-8f30-408b-9b75-2aece9da33c5",
                    "question": "What generation are you a part of?",
                    "isSelected": true,
                    "options": [
                        {
                            "label": "Generation  Z (Born 1997-2012)",
                            "id": "Age-1",
                            "value": "1"
                        },
                        {
                            "label": "Millennial (Born 1981-1996)",
                            "id": "Age-2",
                            "value": "2"
                        },
                        {
                            "label": "Generation X (Born 1965-1980)",
                            "id": "Age-3",
                            "value": "3"
                        },
                        {
                            "label": "Baby Boomer (Born 1946-1964)",
                            "id": "Age-4",
                            "value": "4"
                        },
                        {
                            "label": "Silent (Born 1928-1945)",
                            "id": "Age-5",
                            "value": "5"
                        }
                    ],
                    "questionIdLabel": "N89",
                    "questionDimension": "multipleOption",
                    "dimensionsId": "a3d8c023ee484efca21b8ad32444dbe2f0f9dd310af44728120dee463e831112",
                    "type": "multipleOption",
                    "isMultipleOption": true,
                    "qid": "4c38db5c-8f30-408b-9b75-2aece9da33c5",
                    "questionLabel": "Age"
                }
            ],
            "isSelected": true,
            "anonymousOnly": true,
            "dimension": "multipleOption",
            "isMultipleOption": true
        },
        {
            "dimension": "Comments",
            "questions": [
                {
                    "staticQuestionId": "10cb190a4da579c7666817a069c75b7f8cdc16242c626fc0468bad4fd09bad5b",
                    "questionId": "cbcbfe5b-df31-4ace-b741-371d20e410bb",
                    "question": "All things considered, what is going well?",
                    "options": [],
                    "isSelected": true,
                    "questionIdLabel": "T1",
                    "dimensionsId": "01db91d06032cc64162c16f8e35725b0e632beebdde8b2c2459979d04fb1e20c",
                    "type": "Text Entry",
                    "qid": "cbcbfe5b-df31-4ace-b741-371d20e410bb",
                    "questionLabel": "Going well",
                    "englishQuestion": "All things considered, what is going well?"
                }
            ]
        },
        {
            "dimension": "Comments",
            "questions": [
                {
                    "staticQuestionId": "6ea97a866f450f1e68bb0dccaa1a9d80d42b1809fcf59631f56ea5b5642ca63d",
                    "questionId": "a8fb8f12-38e4-4143-97b0-22cca4b50a39",
                    "question": "What is the number one thing you would improve about your organization?",
                    "isSelected": true,
                    "options": [],
                    "questionIdLabel": "T3",
                    "dimensionsId": "01db91d06032cc64162c16f8e35725b0e632beebdde8b2c2459979d04fb1e20c",
                    "type": "Text Entry",
                    "qid": "a8fb8f12-38e4-4143-97b0-22cca4b50a39",
                    "questionLabel": "Improvement",
                    "englishQuestion": "What is the number one thing you would improve about your organization?"
                }
            ]
        }
    ]
}