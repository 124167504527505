import { SURVEY_EDITOR_ADD_BLOCK, SURVEY_EDITOR_HANDLE_BLOCK_DRAG_END, SURVEY_EDITOR_HANDLE_CREATE_DUPLICATE, SURVEY_EDITOR_HANDLE_DELETE_BLOCK } from "../../../../../constants";

export const handleDeleteBlock = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_HANDLE_DELETE_BLOCK, payload });
        cb && cb()
    }
};


export const handleDuplicateBlock = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_HANDLE_CREATE_DUPLICATE, payload });
    }
};

export const handleBlockDragEnd = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_HANDLE_BLOCK_DRAG_END, payload });
    }
};

export const addBlockMenu = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_ADD_BLOCK, payload });
    }
};