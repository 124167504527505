import {
  FETCH_ALLTHEME_DATA_START,
  FETCH_ALLTHEME_DATA_SUCCESS,
  FETCH_ALLTHEME_DATA_FAIL,
} from "../../../constants";
import { TOPIC_REPRESENTATION_URL } from "../../../../../config/constants";
import {
  NLPCommonReqBodies,
  getUserDataFromTree,
} from "../../../../utils/actionOperations";
import { ApiBase } from "../../../../utils/apiBase";
import { getProjectIdAndOrgId } from "../../../../utils/apiUtils";

export const fetchAllThemeData = (params) => {
  const apiBaseInstance = new ApiBase().instance;
  let requestField = {
    ...NLPCommonReqBodies(),
    ...getUserDataFromTree(),
    ...params,
    ...getProjectIdAndOrgId(params)
  };
  return (dispatch) => {
    dispatch({ type: FETCH_ALLTHEME_DATA_START ,payload:{questionId : params.selectedQuestionId}});
    apiBaseInstance
      .post(TOPIC_REPRESENTATION_URL, requestField)
      .then((res) =>
        fetchQuestionTopicsRepresentationSuccess({ dispatch, res, params })
      )
      .catch((err) => fetchQuestionTopicsRepresentationFailed(dispatch, err,params));
  };
};

function fetchQuestionTopicsRepresentationSuccess({ dispatch, res, params }) {
  const data =
    (res.data &&
      res.data.data &&
      res.data.data.response &&
      res.data.data.response.data) ||
    {};
  return dispatch({
    type: FETCH_ALLTHEME_DATA_SUCCESS,
    payload: {
      data,
      filters: params.filters,
      byScore: params.byScore,
      multipleSelect: params.multipleSelect,
      selectedUser: params.selectedUser,
      directReporteesOnly: params.directReporteesOnly,
      questionId : params.selectedQuestionId
    },
  });
}

function fetchQuestionTopicsRepresentationFailed(dispatch, err,params) {
  return dispatch({
    type: FETCH_ALLTHEME_DATA_FAIL,
    payload: { error: err.message ,questionId : params.selectedQuestionId},
  });
}
