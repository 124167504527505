import { createSelector } from "reselect";

const getAddAdminPageReducer = (survey) => survey.addAdminPageReducer;

const StateSelector = (addAdminPageReducer) => ({
  ...addAdminPageReducer,
});

export const SelectState = createSelector(
  [getAddAdminPageReducer],
  StateSelector
);
