export const fetchTrendInsightsDataStart = (state, payload) => {
    return {
        ...state,
        trendInsightsDataApi: {
            isProcessing: true,
            error: false,
            errorMessage: "",
        },
    };
}

export const fetchTrendInsightsDataSuccess = (state, payload) => {
    return {
        ...state,
        trendInsightsDataApi: {
            data: payload.data.data,
            isProcessing: false,
            error: false,
            errorMessage: "",
        },
    };
}

export const fetchTrendInsightsDataFailed = (state, payload) => {
    return {
        ...state,
        trendInsightsDataApi: {
            isProcessing: false,
            error: true,
            errorMessage: payload.errorMessage,

        },
    };
}



