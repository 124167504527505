import { notification } from "antd";

export const reprocessSurveyStart = (state, payload) => {
    return {
        ...state,
        reprocessSurveyApi: {
            isProcessing: true,
            error: false
        }
    }

}

export const reprocessSurveySuccess = (state, payload) => {
    notification.success({ message: 'Survey is being reprocessed' });
    return {
        ...state,
        reprocessSurveyApi: {
            isProcessing: false,
            error: false
        },
    }

}

export const reprocessSurveyFailed = (state, payload) => {
    notification.error({ message: 'Failed to reprocess' });
    return {
        ...state,
        reprocessSurveyApi: {
            isProcessing: false,
            error: true
        }
    }

}