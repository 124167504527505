import { deepCopy } from "../../../../../../utils/deepCopy"

export const ratingSectionOptionCountAndShapeChange = (state, payload) => {
    let updatedGoals = getRatingQuestionCountShapeChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getRatingQuestionCountShapeChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.optionCount) {
                element.questions[0].totalRatingValue = payload.value
            } else {
                element.questions[0].shape = payload.value
            }
        }
    });
    return goalsCopy
}