import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const listViewersStarted = (state) => {
    return {
        ...state,
        listViewersApi: getApiStartState()
    }

}

export const listViewersSuccess = (state, payload) => {
    return {
        ...state,
        viewersList:payload.data.data.viewersList,
        listViewersApi: getSuccessResponse(payload.data)
    }

}

export const listViewersFailed = (state, payload) => {
    return {
        ...state,
        listViewersApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}
