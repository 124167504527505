import React from "react";
import { Select } from "antd";
const renderOption = (props) => {
  return props.data.map((item) => (
    <Select.Option value={item[props.dataKey.value]}>
      {item[props.dataKey.label]}
    </Select.Option>
  ));
};

const getClearValue = () => {
  return {
    target: {
      value: "",
    },
  };
};

export default {
  renderOption: renderOption,
  getClearValue: getClearValue,
};
