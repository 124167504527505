import { formatComparisonLevelForDisplay } from "../../../../utils/surveyConfiguration";

export const getThresholdValueStarted = (state, payload) => {
  return {
    ...state,
    thresholdApi:{
      ...state.thresholdApi,
      isProcessing: true,
      error:false
    }
  };
};

export const getThresholdValueSuccess = (state, payload) => {
  if (payload.response && payload.response.heatmapConfiguration) {
    return {
      ...state,
      heatmapConfiguration: payload.response.heatmapConfiguration,
      displayComparisonOptions: payload.response.heatmapConfiguration.comparisonColumn ? formatComparisonLevelForDisplay(payload.response.heatmapConfiguration.comparisonColumn) : [],
      thresholdApi: {
        data: payload.response,
        isProcessing: false,
        error: false
      }
    }
  }
  return {
    ...state,
    thresholdApi:{
      data:payload.response,
      isProcessing: false,
      error:false
    }
  };
};

export const getThresholdValueFail = (state, payload) => {
  return {
    ...state,
    thresholdApi:{
      data:null,
      isProcessing: false,
      error:true
    }
  };
};