import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const improvementScaleDrag = {
    "blockId": uuidv4(),
    "goalId": "feedback360_improvementScaleDrag",
    "isHiddenFromEditSurvey": true,
    "totalRanking": 3,
    "questions": [
        {
            "question": "Build great teams and organizations",
            "type": "priorityDrag",
            "questionDimension": "Builds great teams and organizations"
        }
    ],
    "totalAnswersCount": 4,
    "type": "priorityDrag",
    "dimension": "Builds great teams and organizations"
}

export const improvementScaleDragForAppliedMaterials = {
    "blockId": uuidv4(),
    "goalId": "feedback360_improvementScaleDrag",
    "isHiddenFromEditSurvey": true,
    "totalRanking": 3,
    "selfMainHeading": "<span><b>TOP DEVELOPMENT AREAS:</b> You identified these behaviors as <i>relative</i> development areas (rating of \"Never\", \"Rarely\", \"Occasionally\", and \"Sometimes\"). Please <b>rank the top 3</b> areas to work on given the organization's priorities and your role.</span>",
    "mainHeading": "<span><b>TOP DEVELOPMENT AREAS:</b> You identified these behaviors as <i>relative</i> development areas (rating of \"Never\", \"Rarely\", \"Occasionally\", and \"Sometimes\"). Please <b>rank the top 3</b> areas to work on given the organization's priorities and the person’s role.</span>",
    "instructionHeader": "DRAG AND DROP THE TOP 3 DEVELOPMENT AREAS TO WORK ON",
    "selfInstructionHeader": "DRAG AND DROP THE TOP 3 DEVELOPMENT AREAS TO WORK ON",
    "questions": [
        {
            "question": "Build great teams and organizations",
            "type": "priorityDrag",
            "questionDimension": "Builds great teams and organizations"
        }
    ],
    "displayMappingLogic": "topicWise",
    "totalAnswersCount": 4,
    "type": "priorityDrag",
    "dimension": "Builds great teams and organizations"
}