import React from "react";
import { COMMENT_ANALYSIS, MENU_ITEMS, MULTI_SELECT_TITLE } from "./constants";
import { Tooltip } from "antd";
import Topics from "./children/Topics";
import Questions from "./children/Questions";
import Drivers from "./children/Drivers";
import Outliers from "./children/Outliers";
import Participation from "./children/Participation";
import Demographics from "./children/Demographics";
import CommentQuestions from "./children/CommentQuestions";
import ReadMore from "../../../../Components/ReadMore";
import { SUBMENUTITLE } from "./constants";
import { encryptData, getDataFromUrlToken } from "../../../../utils/urlDataStore";
import { nlpSetURLParams } from "../../../../utils/nlpURLUtils";
import { URL_DATA } from '../../../../../config/urlDataConstants';
import { checkManager } from '../../../../utils/rolePermissionUtils';
import { DASHBOARD_TABS } from '../../../../../config/tabConstants';
import MenuLeft from "../../../../Components/MenuLeft";
import MultiSelect from "./children/MultiSelect Questions";
import Trend from './children/Trend'
import { createDropDownData } from "./children/Trend/helpers";
import TrendInsights from "./children/TrendInsights";

export const getDummyContent = (props, quesionMenu, instance) => {
  const surveyDetails =
    props.surveyDetailsApi && props.surveyDetailsApi.surveyDetails;
  const outcomeVariable = surveyDetails && surveyDetails.outcomeVariable;
  const projectOrigin = surveyDetails && surveyDetails.projectOrigin;
  const overAllParticipationRate =
    props.exploreOverAllParticipationDataApi &&
    props.exploreOverAllParticipationDataApi.data &&
    props.exploreOverAllParticipationDataApi.data
      .aggregatedDemographicsResponse &&
    props.exploreOverAllParticipationDataApi.data.aggregatedDemographicsResponse
      .participationRate;
  let menus = MENU_ITEMS;
  if (isPermissionAllowed(surveyDetails)) {
    menus = removeTabFromNavigation(menus, "Outliers");
  }
  if (overAllParticipationRate && overAllParticipationRate === 100) {
    menus = removeTabFromNavigation(menus, "Participation");
  }
  if (surveyDetails.projectType && surveyDetails.projectType === "Very_Simple_Survey") {
    menus = removeTabFromNavigation(menus, "Topics")
    menus = removeTabFromNavigation(menus, "Drivers")
    menus = removeTabFromNavigation(menus, "Outliers")
  }
  if (getOpenSurveyCondition(surveyDetails)) {
    menus = removeTabFromNavigation(menus, "Topics")
  }
  if (surveyDetails.demographics && surveyDetails.demographics.length) {

  } else {
    menus = removeTabFromNavigation(menus, "Demographics")
  }
  if (projectOrigin === COMMENT_ANALYSIS) {
    menus = []
  } else if (!outcomeVariable) {
    menus = removeTabFromNavigation(menus, "Drivers")
  }
  let { topicsData, questionsData } = getTreeSelectData(surveyDetails)
  if (topicsData.length < 1 && questionsData.length < 1) {
    menus = removeTabFromNavigation(menus, "Trend")
  }
  if(topicsData.length<1){
    menus = removeTabFromNavigation(menus, "TrendInsights")

  }
  return menus
};

const getTreeSelectData = (surveyDetails) => {
  if (surveyDetails && surveyDetails.questionSet) {
    let { topicsData, questionsData } = createDropDownData(surveyDetails.questionSet, surveyDetails.isFileUpload)
    return { topicsData, questionsData }
  }
  return { topicsData: [], questionsData: [] }
}


const removeTabFromNavigation = (menus, removeTab) => {
  if (removeTab) {
    return menus.filter(item => item.key !== removeTab)
  } else if (removeTab === "all") {
    return []
  } else {
    return menus
  }
}

export const getOpenSurveyCondition = (surveyDetails) => {
  if (surveyDetails.projectStatus === "live") {
    return true
  }
  return false
}

export const getQuestions = (props) => {
  const questionsData =
    props.batchQuestionsApi.data &&
      props.batchQuestionsApi.data.data &&
      props.batchQuestionsApi.data.data.length > 0
      ? props.batchQuestionsApi.data.data.map((item) => {
        let Questiontext =
          <Tooltip title={item.questionText}>
            <span>{item.questionLabel?item.questionLabel:item.questionText}</span>
          </Tooltip>
        return { key: item.questionId, label: Questiontext };
      })
      : [];
  return !props.batchQuestionsApi.isProcessing ? questionsData : [];
};

export const getMultiQuestionsMenu = (props) => {
  let menus = []
  let quesionMenu = props.multiSelectQuestionsApi.data ? props.multiSelectQuestionsApi.data : []
  quesionMenu.forEach(item => {
    let data = {
      key: item.question,
      label: <Tooltip title={item.question}>
        <span>{item.question}</span>
      </Tooltip>,
      id: item.questionId
    }
    menus.splice(menus.length, 0, data)
  })
  return menus
}

export const checkIfQuestionMenu = (selectedMenu, instance) => {
  let menu = getMultiQuestionsMenu(instance.props)
  let isMultipleMenu = false
  menu.forEach(item => {
    if (item.key === selectedMenu) {
      isMultipleMenu = true
    }
  })
  return isMultipleMenu
}

export const renderSubMenus = (selectedMenu, instance) => {
  if (checkIfQuestionMenu(selectedMenu, instance)) {
    return <MultiSelect
      selectedMenu={selectedMenu}
    />
  }
  switch (selectedMenu) {
    case "Topics":
      return <Topics
        toggleSampleCommentsLaoding={instance.toggleSampleCommentsLaoding}
      />;
    case "Questions":
      return <Questions />;
    case "Drivers":
      return <Drivers />;
    case "Outliers":
      return <Outliers />;
    case "Participation":
      return <Participation />;
    case "Demographics":
      return <Demographics />;
    case "Trend":
      return <Trend />
    case "TrendInsights":
      return <TrendInsights/>

    default:
      return !instance.props.batchQuestionsApi.isProcessing &&
        instance.props.exploreReducerInitialStateSet ? (
        <CommentQuestions question={selectedMenu} />
      ) : (
        ""
      );
  }
};

export const removeSubTabInUrl = (subTab, props) => {
  if (subTab) {
    const selectedTabUpdatedParams = encryptData({ selectedTab: "Explore" })
    props.history.push({
      search: `params=${encodeURIComponent(selectedTabUpdatedParams)}`
    })
  }
}

export const getReadMore = (lines, content) => {
  return (
    <ReadMore
      lines={lines}
      textContent={content}
    ></ReadMore>
  );
};

export const callExploreDashBoardFilterApi = (selectedMenu, props, filters) => {
  props.fetchExploreDashboardFilters({
    currentTab: DASHBOARD_TABS.EXPLORE,
    minCommentsCount: 1,
    setFilter: false,
    dimensions: [],
    topics: [],
    selectedQuestionId: selectedMenu,
    filters
  })
}

export const fetchDefaultMenu = (props, flag) => {
  let projectOrigin =
    props.surveyDetailsApi &&
    props.surveyDetailsApi.surveyDetails &&
    props.surveyDetailsApi.surveyDetails.projectOrigin;

  const questionsData =
    props.batchQuestionsApi.data &&
    props.batchQuestionsApi.data.data &&
    props.batchQuestionsApi.data.data;
  if (
    !props.loading &&
    flag &&
    questionsData &&
    questionsData.length > 0 &&
    projectOrigin === COMMENT_ANALYSIS
  ) {
    return [SUBMENUTITLE];
  } else {
    if (!props.loading) {
      return [SUBMENUTITLE];
    }
    return []
  }
}


export const isPermissionAllowed = (surveyDetails) => {
  if (surveyDetails) {
    let urlData = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS)
    return checkManager(surveyDetails.organizationId, urlData.projectId) && urlData.completedParticipantsCount !== undefined && urlData.completedParticipantsCount < 20
  }
  else {
    return false
  }
}
export const clearFilter = (props, isCommentQuestion) => {
  let encryptedUrlParams = encryptData({ dashboardFilter: {}, commentQuestion: isCommentQuestion });
  props.history.push({ search: nlpSetURLParams({ params: encryptedUrlParams }) });
};

export const getSelectedTab = () => {
  const commentQuestion = getDataFromUrlToken('commentQuestion');
  return commentQuestion
};

export const changeTab = (commentQuestion) => {
  const selectedTabUpdatedParams = encryptData({ commentQuestion: commentQuestion });
  this.props.history.push({
    search: `params=${encodeURIComponent(selectedTabUpdatedParams)}`,
  });
};

export const getLoading = (props) => {
  if (props.isCommentQuestion) {
    return props.selectedMenu &&
      props[props.selectedMenu] &&
      props[props.selectedMenu].dashboardFilterProcessing
  } else {
    return props.dashBoardFilterApi.isProcessing
  }

}

export const getMultiSelectMenus = (instance) => {
  return (
    <>
      {instance.props.surveyDetailsApi.surveyDetails && instance.props.surveyDetailsApi.surveyDetails.haveMultiOption ?
        <MenuLeft
          mode="inline"
          className="newdashboard-menu"
          selectedKeys={instance.state.selectedMenu}
          onClick={(event) => {
            instance.onChangeMenu(event)
            window.scrollTo(0, 0);
          }}
          subMenuTitle={MULTI_SELECT_TITLE}
          menuItems={getMultiQuestionsMenu(instance.props)}
        ></MenuLeft> : ""}
    </>
  );
};

