import React from "react";
import constants from "../../constants";
import { Tooltip, Spin } from "antd";
import { checkIfAdmin } from '../../../../../../utils/rolePermissionUtils';
class DownloadButtons extends React.Component {
  getInstructionsForDownload = (type) => {
    const { heading,
      text,
      instructions,
      subInst,
      heading2,
      text2,
      instructions2,
      heading3,
      text3,
      instrHead3,
      instructions3, } = constants.downloadButtons[type];
    return (
      <div className="download-instructions">
        <span className="download-heading" children={heading} />
        <span className="download-instructions" children={text} />
        <ol>
          {instructions.map((instruction) => (
            <li className="instructions">
              <ol type="a">
                {instruction}
                {subInst.map((instruction) => (
                  <li className="instructions">{instruction}</li>
                ))}
              </ol>
            </li>
          ))}
        </ol>
        <span className="download-heading" children={heading2} />
        <span className="download-instructions" children={text2} />
        <ol>
          {instructions2.map((instruction2) => (
            <li className="instructions">{instruction2}</li>
          ))}
        </ol>
        <span className="download-heading" children={heading3} />
        <span className="download-instructions" children={text3} />
        <span className="download-instructions" children={instrHead3} />
        <ol>
          {instructions3.map((instruction3) => (
            <li className="instructions">{instruction3}</li>
          ))}
        </ol>
      </div>
    );
  };

  checkForInstructionCall = (type) => {
    if (type === constants.comment) {
      return this.getInstructionsForDownload(type);
    }
    return;
  };

  getDownloadUrl = (type) => {
    let host =  window.location.hostname;
    if(type===constants.downloadButtons.comment.name){
      if(constants.productionHostName.includes(host)){
        return constants.prodSampleCommentUrl;
      }
      else return constants.devSampleCommentUrl;
    }
    else{
      if(constants.productionHostName.includes(host)){
        return constants.prodSampleTopicUrl;
      }
      else return constants.devSampleTopicUrl;
    }
  }

  getDownload = (type) => {
    const { caption, link } = constants.downloadButtons[type];
    return (
      <div className="download-links">
        {this.checkForInstructionCall(type)}
        <Tooltip overlayClassName="right-tooltip" placement="right" title={caption}>
          <a href={this.getDownloadUrl(type)}
            className="download-link"
            // onClick={this.onClickDownload.bind(null, type)}
            children={link}
          />
          <Spin
            size="small"
            className="download-loader"
            spinning={this.isLoading(type)}
          />
        </Tooltip>
      </div>
    );
  };

  onClickDownload = (type) => {
    if (type === constants.comment) return this.props.downloadComments();
    else if (type === constants.topic) return this.props.downloadTopic();
  };

  isLoading = (type) => {
    if (type === constants.comment) return this.props.commentApi.loading;
    else if (type === constants.topic) return this.props.topicApi.loading;
  };

  getDownloadTopic = () =>
    !!(checkIfAdmin()) &&
    this.getDownload(constants.downloadButtons.topic.name);

  getDownloadComment = () =>
    this.getDownload(constants.downloadButtons.comment.name);

  render() {
    return (
      <div className="instructions-wrappper">
        {this.getDownloadComment()}
        {this.getDownloadTopic()}
      </div>
    );
  }
}
export default DownloadButtons;
