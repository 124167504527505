import { makeKey } from "../../../../utils/reducerOperations";
import { fetchFormConfigurations } from "../../../inputFormConfigurations";

export function handleChange(state, payload) {
  return makeKey("products", [...updateSelected(state.products, payload.key)]);
}

function updateSelected(products, key) {
  return products.map(({ ...product }, index) => {
    if (index === key) {
      product.isActive = true;
    } else product.isActive = false;
    return { ...product };
  });
}

export function clearContactForm(state, payload) {
  return {
    ...state,
    isFormValid: false,
    inputBoxConfiguration: fetchFormConfigurations([
      "name",
      "username",
      "message",
    ]),
  };
}
