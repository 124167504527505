import { matchPath } from "react-router";
import { toast } from "../../../../utils/messageUtils";
import { checkIfAdmin, checkIfSuperAdminOrOrgAdmin } from '../../../../utils/rolePermissionUtils';
import { getOrgIdFromUrl } from '../../../../utils/urlProcessor';

export function isJobCompleted(string = "") {
  return !!!(string === "needs_review");
}
export function isJobCompletedStatus(string = "") {
  return !!!(string === "completed");
}
export function isJobProcessing(string = "") {
  let organizationId = getOrgIdFromUrl();
  if (checkIfSuperAdminOrOrgAdmin(organizationId) || checkIfAdmin()) {
    return string === "needs_review" || string === "processing";
  }
  return string === "processing";
}

export function isJobProcessed(state = "") {
  return state === "processed";
}

export function disableFieldByOperationType(type, record) {
  if (type === "delete") return isJobProcessing(record.job_state);
  if (type === "approve") return isJobCompleted(record.job_state);
}

export const fileUpload = (props, data, benchMarkName) => {
  let fileTopic = props.libraryFileApi;
  let file = props.commentsFileApi;
  props.getPreSignedURL(
    {
      fileName: fileTopic.file.name,
      fileType: fileTopic.file.type,
      isTopic: true,
    },
    (response) => {
      props.uploadFile(
        {
          url: response ? response.data.data.preSignedUrl : "",
          file: fileTopic.file,
          fileType: fileTopic.file.type,
        },
        (isSuccess) => {
          if (isSuccess) {

            submitJob(props, file, data, getKeyNameForAddTopicLibraryJob(response), benchMarkName);
            toast.success("Successfully uploaded topic library");
          } else {
            toast.error("Topic library file upload unsuccessful");
            data.failed();

          }
        }
      );

    }
  );
}

export const benchMarkUpload = (props, data, instance) => {
  let benchMarkFile = instance.state.benchMarkFile
  props.getPreSignedURL(
    {
      fileName: benchMarkFile.name,
      fileType: benchMarkFile.type,
    },
    (response) => {
      props.uploadFile(
        {
          url: response ? response.data.data.preSignedUrl : "",
          file: benchMarkFile,
          fileType: benchMarkFile.type,
        },
        (isSuccess) => {
          if (isSuccess) {
            benchMarkCallBack(props, data, getKeyNameForAddJob(response))
            toast.success("Benchmark file uploaded successfully");
          } else {
            toast.error("Benchmark file upload unsuccessful");
          }
        }
      );

    }
  );
}

const benchMarkCallBack = (props, data, benchMarkName) => {
  let file = props.commentsFileApi;
  let fileTopic = props.libraryFileApi;
  if (fileTopic.file) {
    fileUpload(props, data, benchMarkName)
  } else {
    submitJob(props, file, data, null, benchMarkName);
  }
}

export const submitToUpload = (props, data, instance) => {
  props.bulkUploadStart();
  let file = props.commentsFileApi;
  let fileTopic = props.libraryFileApi;
  if (instance.state.benchMarkFile && instance.state.benchMarkFile.name) {
    benchMarkUpload(props, data, instance)
  } else if (fileTopic.file) {
    fileUpload(props, data)
  } else {
    submitJob(props, file, data);
  }
};

const submitJob = (props, file, data, topicFileName, benchMarkFileName) => {
  props.getPreSignedURL(
    {
      fileName: file.name,
      fileType: file.data && file.data.type,
    },
    (response) => {
      //data.cb();
      props.uploadFile(
        {
          url: response ? response.data.data.preSignedUrl : "",
          file: file.file,
          fileType: file.data.type,
        },
        (isSuccess) => {
          if (isSuccess) {
            data.addJob(getKeyNameForAddJob(response), file.name, topicFileName, benchMarkFileName);
            toast.success("Successfully uploaded");
          } else {
            toast.error("File upload unsuccessful")
          }
        }
      );
    }
  );
}
const getKeyNameForAddJob = (response) => {
  let fileName = response ? response.data.data.fileurl : "";
  if (
    window.location.hostname === "app.culturebie.com" ||
    window.location.hostname === "www.app.culturebie.com"
  ) {
    return fileName && fileName.split("/culturebie-uploadbucket/")[1];
  } else if (window.location.hostname === "qa.culturebie.com" ||
    window.location.hostname === "www.qa.culturebie.com") {
    return fileName && fileName.split("/culturebieqa-uploadbucket/")[1];
  }
  else return fileName && fileName.split("/culturebiedev-uploadbucket/")[1];
};

const getKeyNameForAddTopicLibraryJob = (response) => {
  let fileName = response ? response.data.data.fileurl : "";
  if (window.location.hostname === "app.culturebie.com" ||
    window.location.hostname === "www.app.culturebie.com"){
      return fileName && fileName.split("/culturebie-topiclibrarybucket/")[1];
    }else if(window.location.hostname === "qa.culturebie.com" ||
    window.location.hostname === "www.qa.culturebie.com"){
      return fileName && fileName.split("/culturebieqa-topiclibrarybucket/")[1];
    }else {
    return fileName && fileName.split("/culturebiedev-topiclibrarybucket/")[1];
  }
};

export const getParamsForAddJob = () => {
  return {
    ownerEmail: localStorage.getItem("username"),
    ownerId: localStorage.getItem("userId"),
  };
};

export default {
  submitToUpload: submitToUpload,
  getParamsForAddJob: getParamsForAddJob,
};

export const getProjectType = (currentPath) => {
  let path = matchPath(window.location.pathname, {
    path: currentPath
  })
  if (!path || !path.params) {
    path = matchPath(window.location.pathname, {
      path: '/admin/home/organizations/:org_id/'
    })
  }
  const params = path && path.params
  return params ? params.projectType : undefined
}