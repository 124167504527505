import { getFilteredMappedSurveyDetails } from "../../../../utils/trendPairingUtils"

export const getComparisonSurveyDataStart = (state) => {
    return {
        ...state,
        listComparisonSurveyApi: {
            ...state.listComparisonSurveyApi,
            isProcessing: true,
            data: null,
            error: false,
            message: ''
        }
    }

}

export const getComparisonSurveyDataSuccess = (state, payload) => {
    let surveyDetails = payload.data && payload.data.data && payload.data.data.response && payload.data.data.response.surveys
    let filteredAlredyMappedData = getFilteredMappedSurveyDetails(surveyDetails)
    return {
        ...state,
        listComparisonSurveyApi: {
            isProcessing: false,
            data: surveyDetails,
            error: false,
            message: ''
        },
        isTrendPairingDataAlreadyFetched: true,
        pairingTableDropDownValue: filteredAlredyMappedData.length > 0 ? filteredAlredyMappedData[0].projectId : undefined
    }

}

export const getComparisonSurveyDataFailed = (state, payload) => {
    return {
        ...state,
        listComparisonSurveyApi: {
            ...state.listComparisonSurveyApi,
            isProcessing: false,
            data: null,
            error: true,
            message: ''
        }
    }

}
