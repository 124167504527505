import { 
    handleNetworkError,
    handleApiResponseError,
    getSuccessResponse
} from '../../../../utils/reducerOperations';

export function submitAnswerStart (state){
    return {
        ...state,
        submitAnswerApi:{
            data:null,
            isProcessing:true,
            message:null,
            error:false            
        }
    };
};

export function submitAnswerSuccess (state,action){
    const {data} = action.response;
    return {
        ...state,
        surveyCompleted:!!action.completed,
        submitAnswerApi:getSuccessResponse(data),
    };
};

export function submitAnswerFail (state,action){
    return {
        ...state,
        submitAnswerApi:
      (action.response 
          && action.response.response
          && action.response.response.data
          ? handleApiResponseError(action.response.response.data)
          : handleNetworkError(action.response))
  
    };
};