import {
  GET_OUTLIERS_DATA_FAIL,
  GET_OUTLIERS_DATA_START,
  GET_OUTLIERS_DATA_SUCCESS,
  SET_STORYBOARD_OUTLIER_DATA,
  FETCH_STORYBOARD_DEMOGRAPHICS_START,
  FETCH_STORYBOARD_DEMOGRAPHICS_SUCCESS,
  FETCH_STORYBOARD_DEMOGRAPHICS_FAILED
} from '../../constants';
import { FETCH_OUTLIERS_URL } from '../../../../config/constants';
import { ApiBase } from '../../../utils/apiBase';
import { withType } from '../../../utils/actionOperations';
import { NUMERIC_DEMOGRAPHICS_URL } from "../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const getStoryBoardOutliersData = (payload,cb) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(GET_OUTLIERS_DATA_START, {}));
    apiBaseInstance
      .post(FETCH_OUTLIERS_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => getOutliersSuccess(dispatch, res,cb))
      .catch((err) => getOutliersDataFailed(dispatch, err));
  };
};

const getOutliersSuccess = (dispatch, res,cb) => {
  const data = (res.data && res.data.data) || {};
  cb&&cb({...data})
  return dispatch(withType(GET_OUTLIERS_DATA_SUCCESS, { data }));
};

const getOutliersDataFailed = (dispatch, err) => {
  return dispatch(withType(GET_OUTLIERS_DATA_FAIL, { error: err }));
};

export const storyBoardOutliersSetData = (data) => {
  return (dispatch) => {
    dispatch(withType(SET_STORYBOARD_OUTLIER_DATA, { data }));
  };
};

export const fetchStoryboardDemographicsData = (payload) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch({ type: FETCH_STORYBOARD_DEMOGRAPHICS_START });
    apiBaseInstance.instance
      .post(NUMERIC_DEMOGRAPHICS_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) =>
        fetchNumericDemographicsSuccess(dispatch, { res }, payload)
      )
      .catch((err) => fetchNumericDemographicsFailed(dispatch, err));
  };
};

function fetchNumericDemographicsSuccess(dispatch, { res }, payload) {
  const responseData = (res.data && res.data.data) || {};
  dispatch({
    type: FETCH_STORYBOARD_DEMOGRAPHICS_SUCCESS,
    payload: {
      data: responseData,
    },
  });
}

function fetchNumericDemographicsFailed(dispatch, err) {
  return dispatch({
    type: FETCH_STORYBOARD_DEMOGRAPHICS_FAILED,
    payload: { errorMessage: err.message },
  });
}
