import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const inviteViewerStarted = (state) => {
    return {
        ...state,
        inviteViewerApi: getApiStartState()
    }

}

export const inviteViewerSuccess = (state, payload) => {
    return {
        ...state,
        inviteViewerApi: getSuccessResponse(payload)
    }

}

export const inviteViewerFailed = (state, payload) => {
    return {
        ...state,
        inviteViewerApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}
