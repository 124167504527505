import React from 'react';
import { SelectUpload } from "../../../../../../Components/SelectUpload";
import CustomModal from "../../../../../../Components/CustomModal";
import helpers from './helper';
import { checkIfAdmin } from '../../../../../../utils/rolePermissionUtils';

class TopicLibraryReprocess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            submitToUpload: false
        }
    }

    componentDidMount() {
        this.props.clearTopicFile();
    }


    onUploadTopicLibrary = (_, event) => {
        if (event.target.files.length)
            this.props.uploadTopicLibrary(event.target.files[0]);
        event.target.value = null;
    };

    submitToUpload = (cb, fileName) => {
        this.setState({
            submitToUpload: true,
        });
        helpers.submitToUpload(this.props, {
            addJob: this.props.addJob,
            fileName: fileName,
            cb: this.callback,
            failed: this.handleFailure,
        });
    };

    callback = (response, fileName, uploadedFile) => {
        let reprocessCommentsPayload = {
            topicFileName: fileName,
            keyName: this.props.selectedDocument.keyName,
            projectId: this.props.selectedDocument.projectId,
            projectOrigin: this.props.selectedDocument.projectOrigin,
            isReProcessed: true,
            topicLibraryExisting: true,
            topicsLibraryFile: uploadedFile && uploadedFile.name
        }
        this.props.addCommentsJob(reprocessCommentsPayload, this.clearState)
    }

    clearState = () => {
        this.props.clearTopicFile();
        this.props.onCancel();
    }


    handleFailure = () => {
        console.log("failure")
        this.setState({ submitToUpload: false })
    }

    getUploadModal = () => {
        return (<CustomModal
            title={`Reprocess ${this.props.selectedDocument.projectName}`}
            visible={true}
            okButtonProps={{
                loading: this.state.submitToUpload,
                disabled: this.state.submitToUpload || !this.props.libraryFileApi.file
            }}
            style={{ top: 0 }}
            onOk={(e) => { this.submitToUpload() }}
            onCancel={this.clearState}
            okText="Reprocess"
        >
            <div className='drag-section'>
                <ul>
                    <li>
                        <label>Choose and upload a new topic library to re-process the batch</label>
                        {this.topicFileUpload()}
                    </li>
                </ul>
            </div>

        </CustomModal>)
    }

    topicFileUpload = () => {
        if (!checkIfAdmin()) return null;
        return (
            <SelectUpload
                index={1}
                onUpload={this.onUploadTopicLibrary}
                api={this.props.libraryFileApi}
                title={"Upload theme library"}
                disable={false}
                onSelect={() => { }}
                options={[]}
                defaultSelect="Select Default Topics"
                showSelect={false}
                showUploadButton={true}
                clearTopicFile={this.props.clearTopicFile}
            />
        );
    };



    render() {
        return (
            <>{this.getUploadModal()}</>
        )

    }
}
export default TopicLibraryReprocess;
