import {
  FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_FAIL,
  FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_SUCCESS,
  FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_START,
  SET_NEW_DASHBOARD_EXPLORE_DRIVER_DATA_FETCHED_FALSE,
  SET_NEW_DASHBOARD_EXPLORE_DRIVER_FILTERS,
  SET_EXPLORE_TOPIC,
} from "../../../constants";
import { FETCH_DRIVERS_URL } from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchExploreDriversData = (payload) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_START, {}));
    return apiBaseInstance
      .post(FETCH_DRIVERS_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        dispatch(
          withType(FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_SUCCESS, {
            data: res.data,
            filters: payload.filters,
            byScore:payload.byScore,
            multipleSelect: payload.multipleSelect,
            selectedUser: payload.selectedUser,
            directReporteesOnly: payload.directReporteesOnly,
          })
        );
      })
      .catch((err) => {
        dispatch(withType(FETCH_NEW_DASHBOARD_EXPLORE_DRIVERS_DATA_FAIL, err));
      });
  };
};

export const setExploreDriverDataFetchedKeyFlase = () => {
  return (dispatch) => {
    dispatch(withType(SET_NEW_DASHBOARD_EXPLORE_DRIVER_DATA_FETCHED_FALSE, {}));
  };
};

export const setExploreDriverFilters = (payload) => {
  return (dispatch) => {
    dispatch(withType(SET_NEW_DASHBOARD_EXPLORE_DRIVER_FILTERS, payload));
  };
};

export const setSelectedTopic = (payload)=>{
  return (dispatch) => {
    dispatch(withType(SET_EXPLORE_TOPIC, payload));
  };
}