import React from "react";
import StrengthAndWeaknessDataView from "./children/DriversDataViewBothStrengthAndWeakness";
import AllDriverTable from "./children/AllDriversTable";
import { URL_DATA } from "../../../../../../../config/urlDataConstants";
import { checkIfObjectsAreEqual } from "../../../../../../utils/customFunctions";
import UsersSvgIcons from "./children/UsersSvgSection";
import { EmptyState } from "../../../../../../Packages/EmptyState";
import { getDataFromUrlToken } from "../../../../../../utils/urlDataStore";
import { getSelectedUserData } from "../../helper";
import {
  checkTrendProjects,
} from "../../../../../../utils/trendUtils";
import GraphLoader from "../../../../../../Components/ContentLoaders/GraphLoader";
import TableLoader from "../../../../../../Components/ContentLoaders/TableLoader";
import { checkIfTwoArraysAreIsEqual, getURLFilterAndByScore } from "../../../../../../utils/byScoreFilterUtils";
import { SelectTopic } from "./children/SelectTopic";
import { fetchDriverDataPayload } from "./helper";
import { DASHBOARD_TABS } from '../../../../../../../config/tabConstants';

export class Drivers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    const surveyDetails = this.props.surveyDetailsApi &&
      this.props.surveyDetailsApi.surveyDetails
    if (!this.props.selectedTopic) {
      this.props.setSelectedTopic({ selectedTopic: surveyDetails.outcomeVariable })
    }
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const selectedUserData = getSelectedUserData();
    if (this.checkIfApiCallRequired(urlFilter, selectedUserData)) {
      this.callApi(urlFilter, selectedUserData);
    }
  }

  componentDidUpdate() {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER) || {};
    const selectedUserData = getSelectedUserData();
    let filterData = getURLFilterAndByScore(urlFilter)
    if (
      checkIfObjectsAreEqual(filterData.filters, this.props.filters) &&
      checkIfTwoArraysAreIsEqual(filterData.byScore, this.props.byScore) &&
      checkIfTwoArraysAreIsEqual(filterData.multipleSelect, this.props.multipleSelect) &&
      checkIfObjectsAreEqual(selectedUserData.directReporteesOnly, this.props.directReporteesOnly) &&
      checkIfObjectsAreEqual(selectedUserData.selectedUser, this.props.selectedUser)
    ) {
    } else {
      if (!this.props.fetchExploreDriversDataApi.isProcessing) {
        this.callApi(urlFilter, selectedUserData);
      }
    }
  }

  callApi = (filters, selectedUserData) => {
    const surveyDetails = this.props.surveyDetailsApi &&
      this.props.surveyDetailsApi.surveyDetails
    let payload = fetchDriverDataPayload(this,surveyDetails.outcomeVariable)
    payload.currentTab = DASHBOARD_TABS.EXPLORE_SUB_TABS.DRIVERS
    this.props.setSelectedTopic({ selectedTopic: surveyDetails.outcomeVariable, selectedQuestionId: '' })
    this.props.fetchDriverDimensions({
      currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.DRIVERS, projectId: surveyDetails.projectId, outcomeVariable: surveyDetails.outcomeVariable, projectType: surveyDetails.projectType
    })
    if (!this.props.selectedTopic) {
      this.props.setSelectedTopic({ selectedTopic: surveyDetails.outcomeVariable })
    }
    this.props.fetchExploreDriversData(payload)
  };

  callExploreApiOnTopicChange = (node) => {
    let selectedQuestionId = node.isQuestion ? node.value : undefined
    this.props.setSelectedTopic({ selectedTopic: node.dimension, selectedQuestionId: selectedQuestionId })
    let payload = fetchDriverDataPayload(this)
    payload.currentTab = DASHBOARD_TABS.EXPLORE_SUB_TABS.DRIVERS
    payload.outcomeVariable = node.dimension
    payload.topics = [node.dimension]
    payload.selectedQuestionId = selectedQuestionId
    this.props.fetchExploreDriversData(payload)
  }

  checkIfApiCallRequired = (urlFilter, selectedUserData) => {
    let filterData = getURLFilterAndByScore(urlFilter)
    if (
      !this.props.isDataAlreadyFetched ||
      !checkIfObjectsAreEqual(filterData.filters, this.props.filters) ||
      !checkIfTwoArraysAreIsEqual(filterData.byScore, this.props.byScore) ||
      !checkIfTwoArraysAreIsEqual(filterData.multipleSelect, this.props.multipleSelect) ||
      !checkIfObjectsAreEqual(selectedUserData.directReporteesOnly, this.props.directReporteesOnly) ||
      !checkIfObjectsAreEqual(selectedUserData.selectedUser, this.props.selectedUser)
    ) {
      return true;
    }
    return false;
  };

  render() {
    const surveyDetails =
      this.props.surveyDetailsApi &&
      this.props.surveyDetailsApi.surveyDetails
    const strengths =
      this.props.fetchExploreDriversDataApi.strengthAndWeakness &&
      this.props.fetchExploreDriversDataApi.strengthAndWeakness.strengths &&
      this.props.fetchExploreDriversDataApi.strengthAndWeakness.strengths
    const weaknesses =
      this.props.fetchExploreDriversDataApi.strengthAndWeakness &&
      this.props.fetchExploreDriversDataApi.strengthAndWeakness.weaknesses &&
      this.props.fetchExploreDriversDataApi.strengthAndWeakness.weaknesses
    const tableData =
      this.props.fetchExploreDriversDataApi.data &&
      this.props.fetchExploreDriversDataApi.data.response &&
      this.props.fetchExploreDriversDataApi.data.response.scatterPlot &&
      this.props.fetchExploreDriversDataApi.data.response.scatterPlot.map(
        (item) => ({ ...item, key: item.id })
      );
    const totalScore =
      this.props.fetchExploreDriversDataApi.data &&
      this.props.fetchExploreDriversDataApi.data.response &&
      this.props.fetchExploreDriversDataApi.data.response.driverDimension &&
      this.props.fetchExploreDriversDataApi.data.response.driverDimension
        .favScore;
    return (
      <>
        {this.props.driverDimensionsApi.isProcessing ?
          <GraphLoader height={100} width={600} />
          : surveyDetails.projectType && surveyDetails.projectType !== "Very_Simple_Survey" &&
          <div>
            <SelectTopic
              dimensions={this.props.driverDimensionsApi.dimensions}
              onSelectChange={this.callExploreApiOnTopicChange}
              topic={this.props.selectedTopic}
              selectedQuestionId={this.props.selectedQuestionId}
            />
          </div>
        }
        {this.props.fetchExploreDriversDataApi.isProcessing ?
          <>
            <GraphLoader height={300} width={600} />
            <TableLoader />
          </> :
          <>
            {this.props.fetchExploreDriversDataApi.error ? (
              <EmptyState
                type={"Network Error"}
                emptyStateMessage={this.props.fetchOutliersDataApi.message}
              />
            ) : (
              <>
                <UsersSvgIcons
                  totalScore={totalScore}
                  outcomeVariable={this.props.selectedTopic}
                />
                <StrengthAndWeaknessDataView
                  strengths={strengths}
                  weaknesses={weaknesses}
                />
                <AllDriverTable
                  loading={this.props.fetchExploreDriversDataApi.isProcessing}
                  tableData={tableData}
                  trendData={checkTrendProjects(
                    this.props.surveyDetailsApi &&
                    this.props.surveyDetailsApi.surveyDetails
                  )}
                />
              </>
            )}
          </>}
      </>
    );
  }
}
