import React from "react";
import { Route } from "react-router-dom";
import { update } from "../../utils/reducerOperations";
import { RenderIfSignIn } from "../../utils/checkSignInAndRender";

class ProtectedRoute extends React.Component {
  render() {
    return (
      <Route
        path={this.props.path}
        name={this.props.name}
        // key={this.props.key}
        test-name="protected-route"
        render={(properties) => {
          return RenderIfSignIn(
            update(properties, {
              match: this.props.computedMatch,
            }),
            this.props.component
          );
        }}
      />
    );
  }
}
export default ProtectedRoute;
