import {
    update,
    makeKey,
} from '../../../../utils/reducerOperations';
export function inviteUserStarted(state) {
    return makeKey(
        'inviteUserApi',
        update(state.inviteUserApi, {
            isProcessing: true
        })
    );
}
