import {
    CSV_DOWNLOAD_FAILED,
    CSV_DOWNLOAD_START,
    CSV_DOWNLOAD_SUCCESS
} from '../../constants';
import { CSV_DOWNLOAD_URL } from '../../../../config/constants';
import { ApiBase } from '../../../utils/apiBase';
import { withType } from '../../../utils/actionOperations';

export const csvDownload = (payload,cb) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(CSV_DOWNLOAD_START, {}));
        apiBaseInstance.instance
            .get(`${CSV_DOWNLOAD_URL}/${payload.fileName}`, {})
            .then((res)=>{
                downloadCsvSuccess(dispatch,res,cb)
            }
                
                )
            .catch((err)=>downloadCsvFailed(dispatch,err,cb));
    };
};

function downloadCsvSuccess(dispatch, response,cb) {
    const a = document.createElement('a')
    const data = response.data.data
    a.href = decodeURIComponent(data.response.url);
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    dispatch(withType(CSV_DOWNLOAD_SUCCESS, {
        data: response.data,
        message: response.message
    }));
    cb&&cb(true)
}

function downloadCsvFailed(dispatch, error,cb) {
    dispatch(withType(CSV_DOWNLOAD_FAILED, {
        error: error
    }));
    cb&&cb(false)
}

