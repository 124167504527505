import {
  update,
  makeKey,
  handleApiResponseError,
  handleNetworkError,
  storeDefaultHRISValue,
} from "../../../../utils/reducerOperations";

export const fetchHrisHierarchyStarted = (state) => {
  return makeKey(
    "fetchHrisHierarchyApi",
    update(state.fetchHrisHierarchyApi, { isProcessing: true, error: false })
  );
};
export const fetchHrisHierarchySuccess = (state, payload) => {
  storeDefaultHRISValue(payload.data);
  return makeKey(
    "fetchHrisHierarchyApi",
    update(state.fetchHrisHierarchyApi, {
      message: payload.message,
      isProcessing: false,
      data: payload.data.response,
      error: !payload.status,
      page: 0,
    })
  );
};
export const fetchHrisHierarchyFailed = (state, payload) => {
  return makeKey(
    "fetchHrisHierarchyApi",
    update(
      state.fetchHrisHierarchyApi,
      payload.response && payload.response.data
        ? handleApiResponseError(payload.response.data)
        : handleNetworkError(payload)
    )
  );
};
