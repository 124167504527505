import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const changeEnablerTemplate = [
    {
        questionDimension: 'Change Enabler',
        type: 'Matrix',
        questionLabel: 'Change Enabler',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: `We have the the right skills to use the new ERP system.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`We have the the right skills to use the new ERP system.`)
        },
        {
            text: `After the implementation, the new ERP system will make me more effective at my job.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`After the implementation, the new ERP system will make me more effective at my job.`)

        },
        {
            text: `My job will be secure during and after the ERP project implementation.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`My job will be secure during and after the ERP project implementation.`)

        }]
    }
]

