import { PROJECT_ORIGIN, LIST_SURVEY_CONSTANTS } from "./constants"
import { SetLocalStorage } from "./localStorgeOperations";
import { EDIT_PROJECT } from "./routePath";
import { encryptData } from "./urlDataStore";

export const getProjectNameSubTitle = (record) => {
    if (record.projectOrigin === PROJECT_ORIGIN.DATA_COLLECTION)
        return `${record.blockCount} topics · ${record.questionCount} questions`
    else return record.projectOrigin
}

export const redirectToProjectPage = (record, props) => {
    const projectPath = window.location.pathname + "/projects";
    const queryParams = new URLSearchParams(window.location.search);
    const params = queryParams.get('params');
    let search = `?params=${params}` + LIST_SURVEY_CONSTANTS.queryStringForProjectId + record.projectId;
    let pathname = projectPath + '/create-project';
    // pathname=pathname + window.location.search
    if (record.projectStatus === LIST_SURVEY_CONSTANTS.published) {
        // pathname = projectPath + "/distribution" + EDIT_PROJECT;
        search = search + LIST_SURVEY_CONSTANTS.queryStringForPublishedProject
    }
    if (record.projectStatus === LIST_SURVEY_CONSTANTS.scheduled) {
        search = search + LIST_SURVEY_CONSTANTS.queryStringForScheduledProject
    }
    if (record.projectStatus === LIST_SURVEY_CONSTANTS.closed) {
        if (window.location.hostname === "app.culturebie.com" ||
            window.location.hostname === "www.app.culturebie.com") {
            pathname = window.location.pathname + "/nlp/dashboard/overview";
            search = "?batch=" + record.projectId;
        }
        else {
            pathname = window.location.pathname + "/nlp/dashboard/overview";
            search = "?batch=" + record.projectId;
        }
    }

    localStorage.setItem("projectId", record.projectId);
    localStorage.setItem("projectOrigin", record.projectOrigin);
    props.history.push({ pathname, search });
};
export const redirectToDCProjectPage = (record, props) => {
    SetLocalStorage({ projectId: record.projectId, projectName: record.projectName })
    const projectPath = window.location.pathname + "/projects";
    let search = LIST_SURVEY_CONSTANTS.queryStringForProject + record.projectId;
    let pathname = projectPath + EDIT_PROJECT;
    if (record.projectStatus === LIST_SURVEY_CONSTANTS.published) {
        pathname = window.location.pathname.replace('home/', "");
        pathname = pathname.replace('/survey', "") + '/surveyparticipationrate'
        const projectDetails = {
            projectId: record.projectId,
        }
        let encryptedData = encryptData({ projectDetails });
        window.location.href = pathname + `?params=${encodeURIComponent(encryptedData)}`



    }
    if (record.projectStatus === LIST_SURVEY_CONSTANTS.closed) {
        if (window.location.hostname === "app.culturebie.com" ||
            window.location.hostname === "www.app.culturebie.com") {
            pathname = window.location.pathname + "/nlp/dashboard/overview";
            search = "?batch=" + record.projectId +
                (record.outcomeVariable ? `&outcome=${encodeURIComponent(record.outcomeVariable)}` : '');
        }
        else {
            pathname = window.location.pathname + "/nlp/dashboard/overview";
            search = "?batch=" + record.projectId +
                (record.outcomeVariable ? `&outcome=${encodeURIComponent(record.outcomeVariable)}` : '');
        }
    }
    localStorage.setItem("projectId", record.projectId);
    localStorage.setItem("projectOrigin", record.projectOrigin);
    props.history.push({ pathname, search });
}
export const getDefaultSortOrder = (key, props) => {
    if (key === props.sortField) {
        return props.sortOrder
    }
    return
}


