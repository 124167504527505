import {
    FETCH_SURVEY_WIZARD_PROJECT_DETAILS_START,
    FETCH_SURVEY_WIZARD_PROJECT_DETAILS_SUCCCESS,
    FETCH_SURVEY_WIZARD_PROJECT_DETAILS_FAIL,
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { FETCH_PROJECT_DETAILS } from "../../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchSurveyWizardProjectDetails = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
        dispatch(withType(FETCH_SURVEY_WIZARD_PROJECT_DETAILS_START,{}));
        apiBaseInstance.instance
            .post(FETCH_PROJECT_DETAILS, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then((response) => {
                dispatch(
                    withType(FETCH_SURVEY_WIZARD_PROJECT_DETAILS_SUCCCESS, { ...response.data, ...payload })
                );
                cb && cb(true,response.data);
            })
            .catch((err) => {
                cb && cb();
                dispatch(
                    withType(FETCH_SURVEY_WIZARD_PROJECT_DETAILS_FAIL, {
                        error: err,
                    })
                );
            });
    };
};