import React, { Component } from 'react';
import constants from './constants';
import './index.scss';
class Loader extends Component {
    constructor(props) {
        super(props);
        // render Fucntions
        this.renderWithWrapper = this.renderWithWrapper
            .bind(this);
        this.renderWithoutWrapper = this.renderWithoutWrapper
            .bind(this);
    }

    // Function renders loader with wrapper
    renderWithWrapper() {
        return (
            <div
                className={
                    this.props.hasOverlay
                        ? constants.loaderWithOverlay
                        : constants.loaderClass
                }
                children={this.renderWithoutWrapper()} />
        );
    }

    // Function renders loader without wrapper
    renderWithoutWrapper() {
        let classes = [constants.loader];
        const { classList, objectType } = constants;
        if (this.props.hasOwnProperty(classList) &&
            typeof this.props.classList === objectType)
            classes = classes.concat(this.props.classList);
        let list = [];
        for (let i = 0; i < 13; i++) {
            list.push(<div key={i} className='loader__item' />);
        }
        return (
            <div
                className={classes.join(constants.emptySpace)}
                children={list} />
        );
    }

    render() {
        if (this.props.hasOwnProperty(constants.hasWrapper) &&
            this.props.hasWrapper === true)
            return this.renderWithWrapper();
        else
            return this.renderWithoutWrapper();
    }
}
export default Loader;
