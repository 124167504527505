export const fetchHRISDataStart = (state, payload) => {
    return {
        ...state,
        HRISFilterData: {
            isProcessing: true,
            error: false,
            reason: '',
            facetData: [],
        },
    };
};

export const fetchHRISDataSuccess = (state, payload) => {
    const data = payload.data.data
    const demoGraphics = getDemographics(data)
    return {
        ...state,
        HRISFilterData: {
            isProcessing: false,
            error: false,
            reason: '',
            demographics: demoGraphics,
            data: data,
        },
    };
};

export const fetchHRISDataFail = (state, payload) => {
    return {
        ...state,
        HRISFilterData: {
            isProcessing: false,
            error: true,
            reason: payload.error,
            facetData: [],
        },
    };
};

const getDemographics = (data) => {
    return Object.keys(data)
}
