import { fetchPayload, update } from "../../../utils/reducerOperations";
import { Default } from "./InitialState";
import {
  INPUT_ADD_ORGANIZATION,
  ADD_ORGANIZATION_STARTED,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_FAILED,
  FETCH_ORGANIZATION_LIST_STARTED,
  FETCH_ORGANIZATION_LIST_SUCCESS,
  FETCH_ORGANIZATION_LIST_FAILED,
  DELETE_ORGANIZATION_STARTED,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAILED,
  CLEAR_ORGANIZATION_FORM,
  RENAME_ORG_START,
  RENAME_ORG_FAIL,
  RENAME_ORG_SUCCESS,
} from "../../constants";
import {
  fetchOrganizationStarted,
  fetchOrganizationSuccess,
  fetchOrganizationFailed,
  handleOrganizationInputChange,
  addOrganizationStarted,
  addOrganizationFailure,
  addOrganizationSuccess,
  deleteOrganizationStarted,
  deleteOrganizationSuccess,
  deleteOrganizationFailed,
  clearOrganizationForm,
  resetState,
  renameOrgStarted,
  renameOrgSuccess,
  renameOrgFail,
} from "./Helpers";
const homePageReducer = (state = Default(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_ORGANIZATION_LIST_STARTED:
      return update(state, fetchOrganizationStarted(state, payload));
    case FETCH_ORGANIZATION_LIST_SUCCESS:
      return update(state, fetchOrganizationSuccess(state, payload));
    case CLEAR_ORGANIZATION_FORM:
      return update(state, clearOrganizationForm(state, payload));
    default:
      return getOrganizationFailed(state, action);
  }
};
const getOrganizationFailed = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_ORGANIZATION_LIST_FAILED:
      return update(state, fetchOrganizationFailed(state, payload));
    case INPUT_ADD_ORGANIZATION:
      return update(
        resetState(state),
        handleOrganizationInputChange(state, payload)
      );
    default:
      return addOrganizationStartSuccess(state, action);
  }
};
const addOrganizationStartSuccess = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case ADD_ORGANIZATION_STARTED:
      return update(state, addOrganizationStarted(state, payload));
    case ADD_ORGANIZATION_SUCCESS:
      return update(state, addOrganizationSuccess(state, payload));
    default:
      return addDeleteOrganization(state, action);
  }
};
const addDeleteOrganization = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case ADD_ORGANIZATION_FAILED:
      return update(state, addOrganizationFailure(state, payload));
    case DELETE_ORGANIZATION_STARTED:
      return update(state, deleteOrganizationStarted(state, payload));
    default:
      return deleteOrganizationSuccessFail(state, action);
  }
};
const deleteOrganizationSuccessFail = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case DELETE_ORGANIZATION_SUCCESS:
      return update(state, deleteOrganizationSuccess(state, payload));
    case DELETE_ORGANIZATION_FAILED:
      return update(state, deleteOrganizationFailed(state, payload));
    default:
      return renameOrg(state, action);
  }
};
const renameOrg = (state, action) => {
  switch (action.type) {
    case RENAME_ORG_START:
      return renameOrgStarted(state);
    case RENAME_ORG_FAIL:
      return renameOrgFail(state, action);
    case RENAME_ORG_SUCCESS:
      return renameOrgSuccess(state, action);
    default:
      return { ...state };
  }
};
export default homePageReducer;
// ToDo: Fetch Organization list Failed Case
