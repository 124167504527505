import React from 'react'
import { LoaderButton } from '../../../../../../FormElements/LoaderButton';
import parse from "html-react-parser";
import constants from './constants';
import './index.scss'
import { KEY_CONSTANTS } from '../../constants';
import { getQuestionTranslation, getTextForManualTranslation } from '../../../../../../utils/newAttendSurveyUtils';


class SummaryRating extends React.Component {

    render() {
        let questionDetails = this.props.questionDetails.questions[0] ? this.props.questionDetails.questions[0] : {}
        return (
            <div className='summary-page'>
                <h3> {parse(getTextForManualTranslation({
                    language: this.props.selectedLanguage,
                    defaultTexts: this.props.defaultTexts,
                    translatedTexts: this.props.translatedTexts,
                    key: KEY_CONSTANTS.SUMMARY_PAGE_HEADING
                }))}</h3>
                {parse(getTextForManualTranslation({
                    language: this.props.selectedLanguage,
                    defaultTexts: this.props.defaultTexts,
                    translatedTexts: this.props.translatedTexts,
                    key: KEY_CONSTANTS.SUMMARY_PAGE_SALUTATION
                }))}

                {/* <h5>Dear Feedback Provider,</h5>
                <h5>Here is a summary of your ratings across all the behaviors.</h5> */}

                <div className='summaryblock-result'>{
                    questionDetails.summaryResult && Object.keys(questionDetails.summaryResult).map((currentLabel) =>
                        <div className='box-container'>
                            <div className='box-container__box'> <span>
                                {getQuestionTranslation({ language: this.props.selectedLanguage, question: questionDetails.summaryResult[currentLabel], key: KEY_CONSTANTS.ANSWER_LABEL })}
                            </span></div>
                            <span>{questionDetails.totalQuestionsCount && questionDetails.totalQuestionsCount !== 0 ? `${Math.round((questionDetails.summaryResult[currentLabel].scaleCount / questionDetails.totalQuestionsCount) * 100)}%` : "0%"}</span>
                        </div>
                    )
                }
                </div>
                {parse(getTextForManualTranslation({
                    language: this.props.selectedLanguage,
                    defaultTexts: this.props.defaultTexts,
                    translatedTexts: this.props.translatedTexts,
                    key: KEY_CONSTANTS.SUMMARY_PAGE_DETAILS
                }))}

                {/* <h5>
                    You have chosen to use a small range of the 6-point scale to rate the participant* on all the listed behaviors.
                </h5>
                <h5>It would be highly beneficial to provide greater differentiation and use a wider range of ratings in your feedback to support the participant's growth and development. This would allow the participant to better understand their strengths and focus areas. Please remember that the primary purpose of this 360 Feedback tool is to foster development.</h5>
                <h5>We encourage you to take the time to review the above, and provide comprehensive and well-rounded feedback experience. </h5> */}

                <div className='confirm-block'>
                    <div className='confirm-block__button'>
                    <LoaderButton
                            text={getTextForManualTranslation({
                                language: this.props.selectedLanguage,
                                defaultTexts: this.props.defaultTexts,
                                translatedTexts: this.props.translatedTexts,
                                key: KEY_CONSTANTS.SUMMARY_PAGE_YES_BUTTON
                            })}
                        onClick={() => this.props.handleClickYes()}
                        classList={["loader-button", "final-btn"]}
                        />
                    </div>
                    <div className='confirm-block__button'>
                    <LoaderButton
                            text={getTextForManualTranslation({
                                language: this.props.selectedLanguage,
                                defaultTexts: this.props.defaultTexts,
                                translatedTexts: this.props.translatedTexts,
                                key: KEY_CONSTANTS.SUMMARY_PAGE_NO_BUTTON
                            })}
                        onClick={() => this.props.handleClickNo()}
                        classList={["loader-button", "final-btn"]}
                    />


                    </div>
                </div>
                {parse(getTextForManualTranslation({
                    language: this.props.selectedLanguage,
                    defaultTexts: this.props.defaultTexts,
                    translatedTexts: this.props.translatedTexts,
                    key: KEY_CONSTANTS.SUMMARY_PAGE_NOTE
                }))}
            </div>
        )
    }

}

export default SummaryRating;
