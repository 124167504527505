export function downloadCommentBatchStart(state) {
    return {
      ...state,
      downloadCommentsBatchApi: {
        isProcessing: true,
      },
    };
  }
  
  export function downloadCommentBatchSuccess(state) {
    return {
      ...state,
      downloadCommentsBatchApi: {
        isProcessing: false,
      },
    };
  }
  
  export function downloadCommentBatchFail(state) {
    return {
      ...state,
      downloadCommentsBatchApi: {
        isProcessing: false,
      },
    };
  }