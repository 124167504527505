import { deepCopy } from "../../../../../utils/deepCopy"
import { toast } from "../../../../../utils/messageUtils"
import { v4 as uuidv4 } from "uuid";

export const addTopic = (state, payload) => {
    let goals = addTopicToGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const addTopicToGoals = (state, payload) => {
    let payloadCopy = deepCopy(payload)
    toast.success(`${payload.dimension} topic added successfully`)
    payloadCopy.isSelected = true
    payloadCopy.isOutcomeVariable = false
    payloadCopy.questions[0].qid = uuidv4()
    let questions = payloadCopy.questions[0].matrixQuestions
    questions = questions.filter(item => item.isSelected)
    payloadCopy.questions[0].matrixQuestions = questions
    payloadCopy.questions[0].matrixQuestions.forEach(question => {
        question.value = uuidv4()
    })
    let goalsCopy = deepCopy(state.goals)
    let copyOfGoalsCopy = deepCopy(goalsCopy)
    let commentsAndDemographics = copyOfGoalsCopy.filter(item => item.goalId === "comments" || item.goalId === "demographics" || item.goalId === "multipleOption")
    let commentsAndDemographicsLength = commentsAndDemographics ? commentsAndDemographics.length : 0
    let length = goalsCopy.length
    goalsCopy.splice(length - commentsAndDemographicsLength, 0, payloadCopy)
    return goalsCopy
}

export const addCustomTopic = (state, payload) => {
    let goals = addCustomTopicToGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const addCustomTopicToGoals = (state, payload) => {
    let payloadCopy = deepCopy(payload.payload)
    let message = payload.projectType!=="Very_Simple_Survey"?"custom topic":"question group"
    toast.success(`${payload.payload.dimension} ${message} added successfully`)
    let goalsCopy = deepCopy(state.goals)
    let copyOfGoalsCopy = deepCopy(goalsCopy)
    let commentsAndDemographics = copyOfGoalsCopy.filter(item => item.goalId === "comments" || item.goalId === "demographics" || item.goalId === "multipleOption")
    let commentsAndDemographicsLength = commentsAndDemographics ? commentsAndDemographics.length : 0
    let length = goalsCopy.length
    goalsCopy.splice(length - commentsAndDemographicsLength, 0, payloadCopy)
    return goalsCopy
}