import { connect } from 'react-redux';
import DemographicsTrend from './DemographicsTrend';
import { withRouter } from 'react-router-dom';
import { getDemographicsTrendData, setDemographicsTopicQuestions, setDemographicsTreeDataSelect } from '../../../../../../../../redux/actions';

const mapStateToProps = (state) => {
    return {
        projectDetails: state.DashBoardReducer.surveyDetailsApi.surveyDetails,
        ...state.TrendVisualisationReducer,
        demographicsData: state.DashBoardReducer.duplicateFilterData,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setDemographicsTopicQuestions: (payload) => dispatch((setDemographicsTopicQuestions(payload))),
        setDemographicsTreeDataSelect: (payload) => dispatch(setDemographicsTreeDataSelect(payload)),
        getDemographicsTrendData: (params, cb) => dispatch(getDemographicsTrendData(params, cb))

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DemographicsTrend));
