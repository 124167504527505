import React from 'react';
import Icon from '@ant-design/icons';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import moment from 'moment-timezone';

function handleTimezoneChange({confirm,onChange},timezone){
    onChange(timezone);
    confirm();
}

const getFilterDropdown=(onChange,{confirm})=>(
    <div>
        <TimezonePicker
            absolute      = {true}
            placeholder   = "Select timezone..."
            onChange      = {handleTimezoneChange.bind(null,{
                confirm,
                onChange
            })} />
    </div>
);

export function addTimeZonePicker(){
    const {timezone} = this.state;
    const onChange=(timezone)=>this.setState({timezone});

    return {
        filterDropdown : getFilterDropdown.bind(null,onChange),
        filterIcon : ()=><Icon type='setting'  />,
        render : (dateString)=>moment
            .tz(dateString,timezone)
            .format('DD MMM YYYY, h:mm a'),
    };
}