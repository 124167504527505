import React from "react";
import { Row, Col } from "antd";
import Container from "../../../Components/Container";
import { CustomTable } from "../../../Components/CustomTable";
import TreeLoader from "../../../Components/ContentLoaders/TreeLoader";
import { TableSearch } from "../../../Components/TableSearch";
import { EmptyState } from "../../../Packages/EmptyState";
import constants from "../constants";
import { getOrgIdFromUrl } from "../../../utils/urlProcessor";
import "../index.scss";
import {
  SetLocalStorage,
} from "../../../utils/localStorgeOperations";
import { InfiniteTree } from "../../../Components/InfiniteTree";
import { SelectElement } from "../../../FormElements/SelectElement/";
import helpers from "../helpers";
import { getPageDivForRenderUsersList } from "../../../Components/UsersComponent/helper/listUserData";
import {  USERS_PAGE_SECTION } from '../../../../config/tabConstants';
import { getDataFromUrlToken } from '../../../utils/urlDataStore';
class People extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.fetchUsersApi.isProcessing == null) {
      this.props.fetchHRIS(this.getParamsForPaginationHIRS(0));
    }
    const hrisFileId = getDataFromUrlToken('hrisFileId');
    this.props.fetchUsers({
      currentTab: USERS_PAGE_SECTION.USERS,
      hrisFileId: hrisFileId,
      pagination: { from: 0, size: 12 },
    });
    this.props.fetchHrisHierarchy({
      currentTab: USERS_PAGE_SECTION.USERS,
      isPeoplePage:true,
      hrisFileId: hrisFileId,
      organizationId: getOrgIdFromUrl(),
    });
    this.state = {
      organizationId:getOrgIdFromUrl(),
      isModalVisible: false,
      hrisFileId: hrisFileId,
      search: "",
      sortField: constants.sortPeople.defaultSortField,
      selectedUser: true,
      treeNodeLevel: 0,
    };
    this.props.onUsersHrisPaginationChange(1);
  }
  getParamsForPaginationHIRS = (from) => {
    // const { hrisFileId } = nlpGetURLParams();
    const hrisFileId = getDataFromUrlToken('hrisFileId')
    return {
      currentTab: USERS_PAGE_SECTION.USERS,
      organizationId: getOrgIdFromUrl(),
      pagination: { from: from, size: 120 },
      searchText: "",
      sortField: "hrisFileName.keyword",
      sortOrder: "asc",
      hrisFileId : hrisFileId
    };
  };
  hrisFileChange = (event) => {
    this.setState({
      ...this.state,
      hrisFileId: event,
    });
    SetLocalStorage({ hrisFileId: event });
    SetLocalStorage({ defaultHrisValue: event });
    SetLocalStorage({ nlpHrisId: event });
    this.props.fetchUsers({
      currentTab: USERS_PAGE_SECTION.USERS,
      hrisFileId: event,
      pagination: { from: 0, size: 12 },
    });
    this.props.fetchHrisHierarchy({
      currentTab: USERS_PAGE_SECTION.USERS,
      isPeoplePage:true,
      hrisFileId: event,
      organizationId: getOrgIdFromUrl(),
    });
    this.props.onUsersHrisPaginationChange(1);
    this.setState({ selectedUser: false });
  };
  onTreeSelect = (treeNode) => {
    const hrisFileId = getDataFromUrlToken('hrisFileId')
    if (treeNode) {
      this.setState({ pagination: 1 });
      this.props.onUsersHrisPaginationChange(1);
      if (treeNode.id !== constants.ROOT_MANAGER_EMAIL_ID) {
        this.props.fetchUsers({
          currentTab: USERS_PAGE_SECTION.USERS,
          hrisFileId: hrisFileId,
          pagination: { from: 0, size: 12 },
          selectedManagerId: treeNode.id,
        });
        this.setState({ selectedUser: treeNode.id });
      } else {
        this.props.fetchUsers({
          currentTab: USERS_PAGE_SECTION.USERS,
          hrisFileId: hrisFileId,
          pagination: { from: 0, size: 12 },
        });
        this.setState({ selectedUser: treeNode.id });
      }
    }
  };
  renderTree = () => {
    return (
      <InfiniteTree
        isPeoplePage={true}
        width="100%"
        rowHeight={30}
        onTreeSelect={this.onTreeSelect}
        removeSelection={this.props.removeSelection}
        data={helpers.fetchDataForTree(this.props)}
        searchBoxNoVisibility={true}
        searchItemValue={this.state.search}
        selectedUser={this.state.selectedUser}
        treeNodeLevel={this.state.treeNodeLevel}
      />
    );
  };

  renderTreeComponent = () => {
    const { fetchHrisHierarchyApi } = this.props;
    if (fetchHrisHierarchyApi.error) {
      return (
        <EmptyState
          component="filter"
          type={fetchHrisHierarchyApi.message}
          emptyStateMessage={constants.hrisFileWarning}
        />
      );
    } else if (fetchHrisHierarchyApi.isProcessing) {
      return <TreeLoader />;
    } else if (
      fetchHrisHierarchyApi.data &&
      fetchHrisHierarchyApi.data.hrisFilesList.hierarchy != null
    ) {
      return this.renderTree();
    }
    return [];
  };
  getParamsForPagination = (from) => {
    const hrisFileId = getDataFromUrlToken('hrisFileId');
    const { selectedUser } = this.state;
    let managerId = {};
    if (selectedUser && selectedUser !== constants.ROOT_MANAGER_EMAIL_ID)
      managerId.selectedManagerId = selectedUser;
    if (selectedUser === true) managerId.selectedManagerId = undefined;
    let sortFieldTemp = this.state.sortField;
    if (constants.sortPeople.defaulSortKey === sortFieldTemp) {
      sortFieldTemp = "employeeName";
    }
    return {
      currentTab: USERS_PAGE_SECTION.USERS,
      pagination: { from: from, size: 12 },
      hrisFileId:hrisFileId,
      ...managerId,
      ...this.makeApiParams({}, this.getCurrentHrisValue()),
      searchText: this.state.search,
      sortField: sortFieldTemp + constants.sortPeople.keyword,
      sortOrder:
        this.state.sortOrder === constants.sortPeople.defaultSortOrder
          ? constants.sortPeople.descend
          : constants.sortPeople.ascend,
    };
  };
  getCurrentHrisValue = () => {
    const hrisFileId = getDataFromUrlToken('hrisFileId');
    if (this.props.fetchHRISApi.data != null) {
      const { hrisFileValue } = this.props;
      return hrisFileValue ? hrisFileValue : hrisFileId;
    }
    return hrisFileId;
  };
  makeApiParams(itemObject, hrisFileId) {
    return this.props.isMainUser
      ? itemObject
      : {
          hrisFileId: hrisFileId,
          ...itemObject,
        };
  }
  fetchRouteParam() {
    return this.props.match.params.org_id;
  }
  onChangePagination = (value) => {
    this.setState({ pagination: value });
    this.props.onUsersHrisPaginationChange(value);
    const fromValue = (value - 1) * 12;
    this.props.fetchUsers(this.getParamsForPagination(fromValue));
  };

  onSearchTextChange = ({ event }) => {
    let params = { searchText: event.target.value };
    if (event.target.value === "") {
      this.setState({
        selectedUser: false,
      });
    }
    this.setState({
      ...this.state,
      pagination: 1,
      selectedManager: null,
      removeSelection: true,
      search: event.target.value,
      treeNodeLevel: 6,
    });
    this.props.onUsersHrisPaginationChange(1);
    this.props.fetchUsers({
      currentTab: USERS_PAGE_SECTION.USERS,
      ...params,
      pagination: { from: 0, size: 12 },
      ...this.makeApiParams({}, this.getCurrentHrisValue()),
    });
  };

  clearSearchResult = () => {
    const hrisFileId = getDataFromUrlToken('hrisFileId');
    this.props.fetchUsers({
      currentTab: USERS_PAGE_SECTION.USERS,
      pagination: { from: 0, size: 12 },
      ...this.makeApiParams({}, this.getCurrentHrisValue()),
    });
    this.props.clearPeopleSearch();

    this.setState({
      ...this.state,
      search: "",
      selectedUser: false,
      removeSelection: true,
      selectedManager: null,
      treeNodeLevel: 0,
    });
    this.props.fetchHrisHierarchy({
      currentTab: USERS_PAGE_SECTION.USERS,
      isPeoplePage:true,
      hrisFileId: hrisFileId,
      organizationId: getOrgIdFromUrl(),
    });
  };
  handleTableChange = (pagination, filters, sorter) => {
    // this.setState({ pagination: 1 });
    this.props.onUsersHrisPaginationChange(1);
    let payload = {};
    if (sorter) {
      if (sorter.column && sorter.order) {
        payload.sortField =
          sorter.columnKey !== constants.sortPeople.defaulSortKey
            ? sorter.columnKey + constants.sortPeople.keyword
            : sorter.columnKey;
        payload.sortOrder = constants.sortPeople[sorter.order];
        this.setState({
          sortField: sorter.columnKey,
          sortOrder: sorter.order,
          pagination: 1,
        });
      } else {
        if (sorter.columnKey === constants.sortPeople.defaulSortKey) {
          payload.sortField = sorter.columnKey;
          payload.sortOrder = constants.sortPeople.ascend;
          this.setState({
            sortField: constants.sortPeople.defaulSortKey,
            sortOrder: constants.sortPeople.asc,
            pagination: 1,
          });
        } else {
          this.setState({
            sortField: constants.sortPeople.defaulSortKey,
            sortOrder: constants.sortPeople.defaultSortOrder,
            pagination: 1,
          });
        }
      }
      this.props.fetchUsers({
        ...this.getParamsForPagination(0),
        ...payload,
      });
    }
  };
  render() {
    const feedbackHris = getDataFromUrlToken('isFeedback360');
    return (
      <div className="people-list">
        <div className="people-list__header">
          <Container>
            <div className="header-block">
              <div className="people-select">
                <SelectElement
                  className="heading-select"
                  onChange={this.hrisFileChange}
                  value={
                    this.props.fetchHRISApi.data != null &&
                    this.props.fetchHRISApi.data.hrisFilesList &&
                    this.state.hrisFileId
                  }
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  optionList={
                    this.props.fetchHRISApi.data != null &&
                    this.props.fetchHRISApi.data.hrisFilesList
                      .filter((item) => item.bulkJobStatus === "Completed")
                      .map((item) => ({
                        value: item.hrisFileId,
                        label: item.hrisFileName,
                      }))
                  }
                ></SelectElement>
              </div>
            </div>
          </Container>
        </div>
        <div className="people-list__content">
          <Container>
            <div className="people-search">
              <TableSearch
                index={0}
                onInputChange={this.onSearchTextChange}
                onSearch={(event) => {
                  this.onSearchTextChange({ event: event });
                }}
                searchConfig={{
                  ...this.props.peopleSearch,
                  value: this.state.search,
                }}
                clearSearch={this.state.search !== "" && this.clearSearchResult}
              />
            </div>
            <div className="people-details">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8} lg={8} xl={6} xxl={6}>
                  {this.renderTreeComponent()}
                </Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={18} xxl={18}>
                  {this.props.fetchUsersApi.error ? (
                    <EmptyState type={this.props.fetchUsersApi.message} />
                  ) : (
                    <div div className="employees-list">
                      <CustomTable
                        className="no-space "
                        dataSource={
                          this.props.fetchUsersApi.data &&
                          this.props.fetchUsersApi.data.length > 0
                            ? this.props.fetchUsersApi.data
                            : []
                        }
                        loading={this.props.fetchUsersApi.isProcessing}
                          columns={feedbackHris ? constants.tableColumnForFeedback360(this.props) : constants.tableColumnPeople(this.props)}
                        pagination={false}
                        onChange={this.handleTableChange}
                      />
                      {getPageDivForRenderUsersList({
                        fetchUsersApi: this.props.fetchUsersApi,
                        page: this.props.hrisUsersPagination,
                        onChange: this.onChangePagination,
                      })}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
export default People;
