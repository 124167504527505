import {
    EXPLORE_GET_DISTIRBUTION_DATA_START,
    EXPLORE_GET_DISTIRBUTION_DATA_SUCCESS,
    EXPLORE_GET_DISTIRBUTION_DATA_FAILED
  } from "../../../constants";
  import { GET_DISTRIBUTION_DATA_URL } from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
  import { withType } from "../../../../utils/actionOperations";
  import { createOffset } from "../../../../utils/timeUtils";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';
  
  export const exploreGetDistributionData = (payload) => {
    const apiBaseInstance = new ApiBase().instance;
    payload.timeZone = createOffset();
    return (dispatch) => {
      dispatch(withType(EXPLORE_GET_DISTIRBUTION_DATA_START, {}));
      return apiBaseInstance
        .post(GET_DISTRIBUTION_DATA_URL, {
          ...payload, ...getProjectIdAndOrgId(payload)
        })
        .then((res) => {
          dispatch(withType(EXPLORE_GET_DISTIRBUTION_DATA_SUCCESS, {
            data: res.data,
            filters: payload.filters,
            selectedUser: payload.selectedUser,
            directReporteesOnly: payload.directReporteesOnly,
          }));
        })
        .catch((err) => {
          dispatch(withType(EXPLORE_GET_DISTIRBUTION_DATA_FAILED, err));
        });
    };
  };
  
  
  