import React from "react";
import constants from "../constants";
import FormField from "../../../Components/FormField";
import { LoaderButton } from "../../../FormElements/LoaderButton";
import { ButtonElement } from "../../../FormElements/ButtonElement";
import { deepCopy } from "../../../utils/deepCopy";
import { SETTINGS_PAGE_SECTION } from '../../../../config/tabConstants';
import { getDataFromUrlToken } from '../../../utils/urlDataStore';
import { URL_DATA } from '../../../../config/urlDataConstants';

const changeName = (props) => {

  let formConfig = deepCopy(props.nameAndEmailFormConfiguration);
  formConfig[0].label = constants.firstNameLabel;
  formConfig[1].label = constants.lastNameLabel;
  formConfig[2].label = constants.emailLabel;
  formConfig[2].value = localStorage.getItem("username");
  formConfig[2].disabled = true;

  const renderFormFieldsFromConfig = (formConfig) => {
    return formConfig.map((field, index) => (
      <li key={index} className="login-block">
        <FormField
          index={index}
          required={false}
          field={field}
          labelRequired={true}
          onEnter={() => { }}
          loginFormChange={props.changeFirstLastNameFormChange}
        />
      </li>
    ));
  };
  const changeNameOnClick = () => {
    props.changeFirstLastNameAction(generateRequestBody(), () =>
      getDataFromUrlToken(URL_DATA.ORGANIZATION) && props.getOrganizationProfile({
        organizationId: getDataFromUrlToken(URL_DATA.ORGANIZATION).organizationId,
        currentTab: SETTINGS_PAGE_SECTION.SETTINGS_SUB_TABS.NAME_AND_EMAIL,
      }));
  };
  const generateRequestBody = () => {
    return {
      currentTab: SETTINGS_PAGE_SECTION.SETTINGS_SUB_TABS.NAME_AND_EMAIL,
      userId: localStorage.getItem("userId"),
      firstName: formConfig[0].value,
      lastName: formConfig[1].value,
      employeeName: formConfig[0].value + " " + formConfig[1].value
    };
  };
  const renderFormSubmitButton = (props) => {
    const submitButton = { ...constants.submitButton };
    if (props && props.submitText) submitButton.text = props.submitText;
    return (
      <div className='split-small'>
        <LoaderButton
          data-test="settings-submit-btn"
          loader={
            props.changeFirstLastNameApi && props.changeFirstLastNameApi.isProcessing
          }
          disabled={!props.isFormValid}
          onClick={() => {
            changeNameOnClick();
          }}
          classList={submitButton.classess}
          loaderClass={submitButton.loaderClass}
          text={submitButton.text}
        />
      </div>
    );
  };

  const onCancelNameChange = () => {
    props.onCancelNameChange()
  }

  const renderFormCanceButton = () => {
    return (
      <div className='split-small'>
        <ButtonElement

          onClick={() => {
            onCancelNameChange();
          }}>
          {constants.clearButton}
        </ButtonElement>
      </div>
    )
  }
  return (
    <div div className='settings-contact'>
      <h2>Your name and email address</h2>
      <ul className='vertical-list '>
        {renderFormFieldsFromConfig(formConfig)}
        <li>
          <div className='split-block'>
            {renderFormCanceButton()}
            {renderFormSubmitButton({
              submitText: constants.submitButton.text,
              isFormValid: props.nameFormValid,
              changeFirstLastNameApi: props.changeFirstLastNameApi
            })}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default changeName;
