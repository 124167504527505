import React, { useState } from "react";
import constants from "../constants";
import FormField from "../../../Components/FormField";
import { LoaderButton } from "../../../FormElements/LoaderButton";
import { ButtonElement } from "../../../FormElements/ButtonElement";
import { SETTINGS_PAGE_SECTION } from '../../../../config/tabConstants';

const changePassword = (props) => {
  // eslint-disable-next-line
  const [state, setState] = useState({
    password: "",
    newPassword: "",
    confirm: "",
  });
  let formConfig = props.passwordFormConfiguration;
  formConfig[0].label = constants.currentPasswordLabel;
  formConfig[1].label = constants.newPasswordLabel;
  formConfig[2].label = constants.confirmPasswordLabel;

  const renderFormFieldsFromConfig = (formConfig) => {
    return formConfig.map((field, index) => (
      <li key={index} className="login-block">
        <FormField
          index={index}
          required={true}
          field={field}
          labelRequired={true}
          onEnter={() => { }}
          loginFormChange={props.changePasswordFormChange}
        />
      </li>
    ));
  };
  const changePasswordOnClick = () => {
    props.getUserProfile({ currentTab: SETTINGS_PAGE_SECTION.SETTINGS_SUB_TABS.PASSWORD });
    props.changePasswordAction(generateRequestBody());
    props.clearChangePasswordForm({});
  };
  const generateRequestBody = () => {
    return {
      currentTab: SETTINGS_PAGE_SECTION.SETTINGS_SUB_TABS.PASSWORD,
      accessToken: localStorage.getItem("accessToken"),
      currentPassword: formConfig[0].value,
      newPassword: formConfig[1].value,
    };
  };
  const renderFormSubmitButton = (props) => {
    const submitButton = { ...constants.submitButton };
    if (props && props.submitText) submitButton.text = props.submitText;
    return (
      <div className='split-small'>
        <LoaderButton
          data-test="settings-submit-btn"
          loader={
            props.changePasswordApi && props.changePasswordApi.isProcessing
          }
          disabled={!props.isFormValid}
          onClick={() => {
            changePasswordOnClick();
          }}
          classList={submitButton.classess}
          loaderClass={submitButton.loaderClass}
          text={submitButton.text}
        />
      </div>
    );
  };


  const onClearPassword = () => {
    props.clearChangePasswordForm({});
  }

  const renderFormCanceButton = () => {
    return (
      <div className='split-small'>
        <ButtonElement color="secondary"
          onClick={() => {
            onClearPassword();
          }}>
          {constants.clearButton}
        </ButtonElement>
      </div>
    )

  }
  return (
    <div div className='settings-contact'>
      <h2>Change your password</h2>
      <ul className='vertical-list '>
        {renderFormFieldsFromConfig(formConfig)}
        <li>
          <div className='split-block'>
            {renderFormCanceButton()}
            {renderFormSubmitButton({
              submitText: constants.changePasswordSubmitText,
              isFormValid: props.passwordFormVaild,
              changePasswordApi: props.changePasswordApi,
            })}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default changePassword;
