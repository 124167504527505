import React from 'react';
import { Checkbox } from 'antd';
import './index.scss';
const CheckboxElement = props => {
    const inputChange = (eve) => {
        props.handleChange &&
            props.handleChange({
                value: eve.target.checked,
                name: eve.target.name,
                index: props.index,
                formObject: props.formObject,
            });
    };
    return (
        <Checkbox
            type='checkbox'
            name={props.label}
            disabled={props.disabled}
            checked={props.selected}
            onChange={inputChange}>
            {props.displayName ? props.displayName : props.label}
        </Checkbox>
    );
};

export default CheckboxElement;
