import React, { Component } from 'react'
import { LoaderButton } from '../../../../../../FormElements/LoaderButton'
import { getTextForTranslation } from '../../../../../../utils/newAttendSurveyUtils'
import ConfirmationModal from './children/ConfirmModal/submitSurveyModal'
import { KEY_CONSTANTS } from '../../constants'
import { checkIfPreviewLink, disableSubmitOnNoAnswer } from './helper'
import "./index.scss";
import parse from "html-react-parser";
import { Spin } from 'antd'
export default class FinalPage extends Component {

    handleClick = (isButtonDisable) => {
        this.props.handleGoPrevAndNext(false, true, isButtonDisable)

    }

    getKey = () => {
        if (checkIfPreviewLink() || this.props.isFromEditorPreview) {
                return KEY_CONSTANTS.SURVEY_PREVIEW_END
        }
        else if (disableSubmitOnNoAnswer(this.props.questionSet)) {
            return KEY_CONSTANTS.SURVEY_UNANSWERED_MESSAGE
        }
        else {
            return KEY_CONSTANTS.FINAL_PAGE_SUBMIT_TEXT
        }

    }

    render() {
        let isButtonDisable = checkIfPreviewLink() || disableSubmitOnNoAnswer(this.props.questionSet) || this.props.isFromEditorPreview
        return (
            <Spin spinning={this.props.createAnswerSetApi.isProcessing}>
            <div className='finalarea-block'>
                <div className='finalarea-block__header'>
                        <h5>{this.props.questionDetails.endMessage
                            ? parse(this.props.questionDetails.endMessage.message)
                        :getTextForTranslation({
                        language: this.props.selectedLanguage,
                        defaultTexts: this.props.defaultTexts,
                        translatedTexts: this.props.translatedTexts,
                        key: this.getKey()
                        })}</h5>
                </div>
                <div className='finalarea-block__button'>
                    <LoaderButton
                        text={isButtonDisable ? getTextForTranslation({
                            language: this.props.selectedLanguage,
                            defaultTexts: this.props.defaultTexts,
                            translatedTexts: this.props.translatedTexts,
                            key: KEY_CONSTANTS.BACK_TO_SURVEY
                        }) : getTextForTranslation({
                            language: this.props.selectedLanguage,
                            defaultTexts: this.props.defaultTexts,
                            translatedTexts: this.props.translatedTexts,
                            key: KEY_CONSTANTS.SUBMIT_TEXT
                        })}
                        onClick={() => this.handleClick(isButtonDisable)}
                        loader={this.props.submitSurveyLoading}
                        classList={["loader-button","final-btn"]}
                        loaderClass={["xs dark"]}
                    />
                </div>

                <ConfirmationModal
                    visiblity={this.props.isSurveyCompleted}
                    closeWindow={() => window.close()}
                    surveyCompletedMessage={getTextForTranslation({
                        language: this.props.selectedLanguage,
                        defaultTexts: this.props.defaultTexts,
                        translatedTexts: this.props.translatedTexts,
                        key: KEY_CONSTANTS.SUBMIT_SURVEY_MODAL
                    })}
                />
            </div>
            </Spin>
        )
    }
}
