import queryString from 'query-string';
import constants from './constants';

export const getDataFromUrl = () => {
    let query = queryString.parse(window.location.search);
    let comparisonDomain = query.comparisonDomain 
    || constants.defaultComparisonDomain;
    let leftFilters = query.leftFilters
     && JSON.parse(
         decodeURIComponent(query.leftFilters));
    let data = {
        comparisonDomain,
        filters: leftFilters || {}
    };
    return data;
};