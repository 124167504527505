import {
  makeKey,
  update,
  handleApiResponseError,
  handleNetworkError,
} from "../../../../utils/reducerOperations";
import { toast } from "../../../../utils/messageUtils";

export function responsesFailed(state, payload) {
  toast.error("Failed");
  let response = makeKey(
    "responsesApi",
    update(
      state.responsesApi,
      payload.response && payload.response.data
        ? handleApiResponseError(payload.response.data)
        : handleNetworkError(payload)
    )
  );
  return response;
}
