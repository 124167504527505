import { deepCopy } from "../../../../../../utils/deepCopy"
import { getHTMLRemovedQuestion } from "../helpers"

export const onChangeTextEntryQuestionAndLabel = (state, payload) => {
    let updatedGoals = getDataChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getDataChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.questionLabelChange) {
                element.questions[0].questionLabel = payload.value
            } else {
                if(payload.isSelfQuestion){
                    element.questions[0].HTMLSelfQuestion = payload.value
                    element.questions[0].selfQuestion = getHTMLRemovedQuestion(payload.value)
                }
                else{
                    element.questions[0].HTMLQuestion = payload.value
                    element.questions[0].question = getHTMLRemovedQuestion(payload.value)
                }
            }
        }
    });
    return goalsCopy
}

