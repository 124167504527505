import { notification } from 'antd';
import * as actionTypes from '../../../constants';
import { CREATE_ANSWERSET_URL } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';
import { getValueFromQueryString } from '../../../../utils/getValueFromUrl';
import { getIndexRoute } from '../../../../utils/urlProcessor';
import constants from '../constants';

export const generateAnswerSet = ({ state, dispatch, isFromUpdateAnswer, type, payload }) => {
    let isIndexDetailsDifferent = isFromUpdateAnswer ? state.indexDetails.currentIndex && state.currentIndex !== state.indexDetails.currentIndex : true
    if (isIndexDetailsDifferent) {
        let requestBody = getAnswerSetPayload(state)
        let updatedPayload={...payload,isFromGenerateAnswerSet:true}
        dispatch({ type: type, payload:updatedPayload });
        const axios = new ApiBase().instance;
        axios.post(CREATE_ANSWERSET_URL, { ...requestBody, ...getProjectIdAndOrgId(requestBody) })
            .then(updateSurveyBlockSuccess
                .bind(null, { dispatch, requestBody }))
            .catch(updateSurveyBlockFail
                .bind(null, { dispatch, requestBody }));
    }
}

const getAnswerSetPayload = (state) => {
    let payload = {
        projectType: state.surveyQuestionSetApi.data.projectType,
        attemptProgress: state.currentIndex + 1,
        channelType: getValueFromQueryString('SCT'),
        userKey: getValueFromQueryString('UK'),
        projectId: getIndexRoute(),
        selectedLanguage: state.surveyQuestionSetApi.selectedLanguage,
        isLocalizationEnabled: state.surveyQuestionSetApi.data.isLocalizationEnabled,
        translateAboutSurvey: state.surveyQuestionSetApi.data.translateAboutSurvey,
        translatedTexts: state.translatedTexts,
        answerSet: state.questionSet,
        logoUrl: state.surveyQuestionSetApi.data.logoUrl ? state.surveyQuestionSetApi.data.logoUrl : null,
        organizationId: state.surveyQuestionSetApi.data.organizationId,
        projectCreationDate: state.surveyQuestionSetApi.data.projectCreationDate
    }
    return payload
}

function updateSurveyBlockSuccess({ dispatch, payload }, response) {
    dispatch({ type:actionTypes.GENERATE_ANSWER_SET_SUCCESS });
};

function updateSurveyBlockFail({ dispatch, payload }, error) {
    notification.error({
        message: constants.submitAnswerFailureTitle,
        description: constants.submitAnswerFailureMessage
    });
    dispatch({ type:actionTypes.GENERATE_ANSWER_SET_FAIL });
};