import { toast } from '../../../../utils/messageUtils';
import {
    makeKey,
    update,
    getSuccessResponse,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';

const fetchSnapshotApi = 'fetchSnapshotApi';

export function fetchSurveySnapshotSuccess(state, payload) {
    return makeKey(
        fetchSnapshotApi,
        update(state.fetchSnapshotApi, getSuccessResponse(payload))
    );
}

export function fetchSurveySnapshotStarted(state, payload) {
    return makeKey(
        fetchSnapshotApi,
        update(state.fetchSnapshotApi, {
            isProcessing: true,
            data: null,
            error: null
        })
    );
}

export function fetchSurveySnapshotFailed(state, payload) {
    let response = makeKey(
        fetchSnapshotApi,
        update(state.fetchSnapshotApi, {
            ...(payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload))
        })
    );
    toast.error(response.fetchSnapshotApi.message);
    return response;
}

