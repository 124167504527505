import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";
export const leadingChangeTemplate = [
    {
        questionDimension: 'Leading Change',
        type: 'Matrix',
        questionLabel: 'Leading Change',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: `I understand the purpose and outcomes of the ERP project.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`I understand the purpose and outcomes of the ERP project.`),
        },
        {
            text: `Project leaders and executives are doing a good job of keeping us informed about the ERP project.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`Project leaders and executives are doing a good job of keeping us informed about the ERP project.`),

        },
        {
            text: `Project leaders and executives truly value my perspective on the ERP project.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`Project leaders and executives truly value my perspective on the ERP project.`),

        },
        {
            text: `I understand how successful ERP project implementation will lead to achieving organizational goals.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`I understand how successful ERP project implementation will lead to achieving organizational goals.`),

        }]
    }
]

