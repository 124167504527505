export const initialState = {
  fetchStoryboardTopAndBottomTopicsApi: {
    topDimensions: [],
    bottomDimensions: [],
    topQuestions: [],
    bottomQuestions: [],
    isDataAlreadySet: false,
    projectId: "",
    topGraphData: [],
    bottomGraphData: [],
    highgestScoreInTopData: 0,
    highestScoreInBottomData: 0,
  },
};
