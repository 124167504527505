import { setInitialState } from "../../../utils/reducerOperations";
import { fetchFormConfigurations } from "../../inputFormConfigurations";

export const Default = () => ({
  navVisibility: "hide",
  uploadSurveyApi: setInitialState(),
  fetchSurveyApi: setInitialState(),
  deleteSurveyApi: setInitialState(),
  parseCsvApi: setInitialState(),
  selectedFacets: [],
  showSurveys: false,
  facetObject: null,
  defaultFacetGroup: "",
  filterAggregationApi: setInitialState(),
  fetchSurveyTableApi: setInitialState(),
  inputBoxConfiguration: fetchFormConfigurations(["surveyFile"]),
});
