import {setInitialState} from '../../../utils/reducerOperations';
import { comparisonsFilters } from '../../../../config/constants';

export const Default=()=>({
    fetchOutlierApi:setInitialState(),
    filters:getComparisonsFilters(),
    outcomeVariable:undefined    
});

function getComparisonsFilters(data) {
    return comparisonsFilters.map(item =>({
        ...item,
        checked : true,
        visible : true
    }));
}