import { FETCH_ORGANIZATION_URL } from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
import {
    NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_START,
    NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_SUCCESS,
    NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_FAILED,
} from "../../../constants";
import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const NewSurveyPageFetchOrganizations = (params) => {
    const apiBaseInstance = new ApiBase().instance;
    return (dispatch) => {
        dispatch(withType(NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_START, {}));
        apiBaseInstance
            .post(FETCH_ORGANIZATION_URL, { ...params, ...getProjectIdAndOrgId(params) })
            .then((res) => {
                dispatch(withType(NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_SUCCESS, res.data));
            })
            .catch((err) => {
                dispatch(withType(NEW_SURVEY_PAGE_FETCH_ORGANIZATION_LIST_FAILED, err));
            });
    };
};
