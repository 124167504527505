import { fetchPayload } from "../../../utils/reducerOperations";
import {
    SEND_PREVIEW_MAIL_FAILED,
    SEND_PREVIEW_MAIL_START,
    SEND_PREVIEW_MAIL_SUCCESS
} from "../../constants";
import {
    sendPreviewMailFailed,
    sendPreviewMailStarted,
    sendPreviewMailSuccess
} from "./Helpers";
import { Default } from "./InitialState";
const PreviewMailReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        //add
        case SEND_PREVIEW_MAIL_START:
            return sendPreviewMailStarted(state, payload);
        case SEND_PREVIEW_MAIL_SUCCESS:
            return sendPreviewMailSuccess(state, payload);
        case SEND_PREVIEW_MAIL_FAILED:
            return sendPreviewMailFailed(state, payload);
        default:
            return { ...state };
    }
};

export default PreviewMailReducer;
