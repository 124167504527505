export const TOPIC_GROUPS = [
    {
        key: 'Engagement',
        value: ['Engagement', 'Commitment']
    },
    {
        key: 'Work Itself',
        value: ['Competence', 'Empowerment', 'Career Development', 'Future of Work', 'Wellness']
    },
    {
        key: 'Diversity & Inclusion',
        value: ['Inclusion', 'Diversity']
    },
    {
        key: 'Working Together',
        value: ['Collaboration', 'Communication', 'Agility']
    },
    {
        key: 'Leaders & Managers',
        value: ['My Manager', 'Senior Leaders', 'Vision']
    },
    {
        key: 'Change Readiness',
        value: ['Change Readiness', 'Leading Change', 'Need for Change', 'Change Enabler']
    }
]