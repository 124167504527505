import {
  FETCH_SURVEYWIZARD_PARTICIPATION_START,
  FETCH_SURVEYWIZARD_PARTICIPATION_SUCCESS,
  FETCH_SURVEYWIZARD_PARTICIPATION_FAIL,
} from "../../../../constants";
import { GET_PARTICIPATON_RATE_URL } from "../../../../../../config/constants";
import { ApiBase } from "../../../../../utils/apiBase";
import { withType } from "../../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../../utils/apiUtils';

export const fetchSurveywizardParticipationData = (payload, cb) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(FETCH_SURVEYWIZARD_PARTICIPATION_START, {}));
    return apiBaseInstance
      .post(GET_PARTICIPATON_RATE_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        dispatch(
          withType(FETCH_SURVEYWIZARD_PARTICIPATION_SUCCESS, {
            data: res.data,
            filters: payload.filters,
            selectedUser: payload.selectedUser,
            directReporteesOnly: payload.directReporteesOnly,
          })
        );
        setTimeout(() => {
          cb && cb();
        }, 200);
      })
      .catch((err) => {
        dispatch(withType(FETCH_SURVEYWIZARD_PARTICIPATION_FAIL, err));
      });
  };
};

