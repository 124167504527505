import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";
export const collaborationTemplate = [
    {
        questionDimension: 'Collaboration',
        type: 'Matrix',
        questionLabel: 'Collaboration',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',

        qid: uuidv4(),
        matrixQuestions: [{
            text: 'People freely help one another at my organization.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('People freely help one another at my organization.')
        },
        {
            text: 'Different teams work well together to achieve common goals.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('Different teams work well together to achieve common goals.')

        },
        {
            text: 'The organization encourages us to build connections within and across teams.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('The organization encourages us to build connections within and across teams.')

        }]
    }
]


