import * as fetchDemographicsDataHelper from './fetchDemographicsData';
import * as fetchQuestionSet from './fetchQuestionSet'
import * as updateAnswers from './updateAnswer'
import * as updateCurrentIndex from './updateCurrentIndex'
import * as submitSurvey from './submitSurveyResponse'
import * as surveyTranslate from './translateSurvey'
import * as onChangeLanguage from './onChangeSurveyLanguage'
import * as backFromEnd from './backToFirstPage'
import * as submitAnswerSet from './createAnswerSet'
import * as handleChangeRanking from './handleChangeRanking'
import * as handleChangeRating from './handleChangeRating'
import * as handleChangeTableMatrix from './handleChangeTableMatrix'
import * as editSurveyToAnsweringPage from './copyEditSurveyToAnsweringPage'
import * as NetPromoterHelpers from './handleChangeNetPromoter'
import * as feedbackHelper from './feedback360'
import * as behaviourDragHelper from './behaviourDrag'
import * as handleChangeTextAnswer from './handleChangeTextAnswer'
import * as behaviourDragDelete from './behaviorDragDelete'
import * as summaryRatingUtils from './summaryRatingUtils'
export default {
    ...fetchDemographicsDataHelper,
    ...fetchQuestionSet,
    ...updateAnswers,
    ...updateCurrentIndex,
    ...submitSurvey,
    ...surveyTranslate,
    ...onChangeLanguage,
    ...backFromEnd,
    ...submitAnswerSet,
    ...handleChangeRanking,
    ...handleChangeRating,
    ...handleChangeTableMatrix,
    ...editSurveyToAnsweringPage,
    ...NetPromoterHelpers,
    ...feedbackHelper,
    ...behaviourDragHelper,
    ...handleChangeTextAnswer,
    ...behaviourDragDelete,
    ...summaryRatingUtils
};