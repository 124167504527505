import { 
    apiStartState,
    apiFailureState,
    apiSuccessState,
    apiSuccessSortState
} from '../../../utils/apiStateUtils';

export function fetchTopicsStart(state) {
    return {
        ...state,
        topicsApi: apiStartState(state.topicsApi)
    };
};

export function fetchTopicsSuccess(state, action) {
    return {
        ...state,
        keyword:action.keyword,
        topicsApi: apiSuccessState(state.topicsApi,action)
    };
}

export function fetchTopicsFail(state, action) {
    return {
        ...state,
        topicsApi: apiFailureState(state.topicsApi,action)
    };
};

export function fetchKeywordStart(state) {
    return {
        ...state,
        keywordApi: apiStartState(state.keywordApi)
    };
};

export function fetchKeywordSuccess(state, action) {
    return {
        ...state,
        keywordApi: apiSuccessState(state.keywordApi,action)
    };
}

export function fetchKeywordFail(state, action) {
    return {
        ...state,
        keywordApi: apiFailureState(state.keywordApi,action)
    };
};


export function fetchTopicsRelationStart(state) {
    return {
        ...state,
        topicsRelationApi: {
            ...apiStartState(state.topicsRelationApi),
            data:null
        }
    };
};

export function fetchTopicsRelationSuccess(state, action) {
    return {
        ...state,
        topicsRelationApi:
         apiSuccessState(state.topicsRelationApi,action)
    };
}

export function fetchTopicsRelationFail(state, action) {
    return {
        ...state,
        topicsRelationApi: 
        apiFailureState(state.topicsRelationApi,action)
    };
};

export function fetchTopicsBarRepresentationSuccess(state, action) {
    return {
        ...state,
        topicsBarRepresentationApi:
        apiSuccessSortState(state.topicsBarRepresentationApi,action)
    };
}

export function fetchTopicsBarRepresentationStart(state, action) {
    return {
        ...state,
        topicsBarRepresentationApi:{
            ...state.topicsBarRepresentationApi,
            loading:true
        }
    };
}

export function fetchTopicsBarRepresentationFail(state, action) {
    return {
        ...state,
        topicsBarRepresentationApi: 
        apiFailureState(state.topicsBarRepresentationApi,action)
    };
};

export function fetchTopicsWordcloudStart(state, action){
    return {
        ...state,
        topicsWordCloudApi:{
            ...state.topicsWordCloudApi,
            loading:true
        }
    };
}
export function fetchTopicsWordcloudSuccess(state, action){
    return {
        ...state,
        topicsWordCloudApi:
        apiSuccessState(state.topicsWordCloudApi,action)
    };
}

export function fetchTopicsWordcloudFail(state, action) {
    return {
        ...state,
        topicsWordCloudApi: 
        apiFailureState(state.topicsWordCloudApi,action)
    };
};
export function setCurrentTopicReport(state, action) {
    return {
        ...state,
        currentTopicReport:action.event
    }
}