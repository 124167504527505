import { setInitialState } from '../../../../utils/reducerOperations';

export const InitialState = () => ({
    multiSelectQuestionsApi: setInitialState(),
    multiSelectDiagramApi: setInitialState(),
    filters: {},
    selectedUser: "",
    directReporteesOnly: false,
    byScore: [],
    multipleSelect: []
});