import React from "react";
import DownloadButtons from "../DownloadButtons";
//import { FileUpload } from "../FileUpload";
import { SelectUpload } from "../SelectUpload";
import qs from "query-string";
import { DatePicker, Spin, TimePicker } from "antd";
import constants from "../../constants";
import FileUpload from "../../../../../../Components/FileUpload";
import { LoaderButton } from "../../../../../../FormElements/LoaderButton";
import { AsterikLabel } from "../../../../../../Components/AsterikLabel";
import { TextInput } from "../../../../../../FormElements/TextInput/";
import { Row, Col } from "antd";
import helpers, { getProjectType } from "../../helper";
import {
  getOrgId,
} from "../../../../../../utils/localStorgeOperations";
import { getOrgIdFromUrl } from "../../../../../../utils/urlProcessor";
import { Tooltip } from "antd";
import SVGIcons from "../../../../../../Components/SVGIcons";
import { clearBenchMarkData, onChangeBenchMarkData } from "./helper";
import SimpleSurveyRightSection from "../SimpleSurveyRightSection";
import VerySimpleSurveyRightSection from "../VerySimpleSurveyRightSection";
import { getNewSurveyPagePath } from "../../../../../../utils/getHomePath";
import { checkIfAdmin, checkIfOrgAdmin } from '../../../../../../utils/rolePermissionUtils';
import { getShortTimeZoneFromDateString } from "../../../../../../utils/timeUtils";
import moment from "moment";
import {
  ExclamationCircleTwoTone
} from '@ant-design/icons';
import { getDispalyDateInIsoFormat } from "../../../../../SurveyWizard/children/Setup/children/RemidersSection/children/AddReminderModal/helper";

class UploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hrisFilesList: [
        {
          label: "No HRIS",
          name: "No HRIS",
          value: "",
        },
      ],
      hrisCurrentPage: 0,
      hrisFileId: "",
      submitToUpload: false,
      hrisExisting: false,
      disableClose: false,
      benchMarkFile: {},
      benchMarkFileSelected: false,
      benchMarkFileName: "",
      benchMarkFileType: "",
      timeEvent: moment(new Date().toISOString()),
      dateEvent: moment(new Date().toISOString()),
      date: moment(new Date().toISOString()).format("dddd, MMMM Do YYYY"),
      time: moment(new Date().toISOString()).format(`h:mm a [${getShortTimeZoneFromDateString(new Date().toString())}]`)
    };
  }
  componentDidMount = () => {
    this.props.fetchHrisFile(this.getParamsForPagination(0));
  };

  getTooltip = () => {
    return (
      <Tooltip
        placement={"right"}
        title={"If you leave this field empty it will automatically set the current date and time"}
      >
        <div className='yellowalert-upload'>
          <SVGIcons type={`SVG-warning`} />
          {/*  <ExclamationCircleTwoTone /> */}
        </div>
      </Tooltip>
    )
  }

  getParamsForPagination = (from) => {
    return {
      organizationId: getOrgId(),
      pagination: { from: from, size: 12 },
      sortField: constants.sortHRIS.defaulSortKey,
      sortOrder: constants.sortHRIS.defaultSortOrder,
      hrisFileStatus: constants.COMPLETED,
    };
  };

  getDownloadButton = () => {
    let projectType = getProjectType("/admin/home/organizations/:org_id/:projectType/add-data-collection-reports")
    if (projectType === "Simple_Survey") {
      return <SimpleSurveyRightSection />
    } else if (projectType === "Very_Simple_Survey") {
      return <VerySimpleSurveyRightSection />
    } else {
      return <DownloadButtons
        commentApi={this.props.downloadCommentsApi}
        downloadComments={this.props.downloadSampleComments}
        downloadTopic={this.props.downloadDefaultTopics}
        topicApi={this.props.downloadDefaultTopicApi}
        page={"dataCollection"}
      />
    }
  }
  topicFileUpload = () => {
    if (!this.props.isAdmin) return "";
    return (
      <SelectUpload
        index={1}
        onUpload={this.props.onUploadTopic}
        api={this.props.libraryFileApi}
        title={constants.libraryUploadText}
        disable={false}
        onSelect={this.props.onSelectTopic}
        options={constants.topicLibraryOptions}
        defaultSelect="Select Default Topics"
        showSelect={false}
        showUploadButton={true}
        clearTopicFile={this.props.clearTopicFile}
      />
    );
  };

  benchMarkFileUpload = () => {
    return (
      <SelectUpload
        index={1}
        onUpload={(event, data) => onChangeBenchMarkData(event, data, this)}
        api={{
          name: this.state.benchMarkFileName,
          file: this.state.benchMarkFile,
          loading: false
        }}
        disable={false}
        onSelect={this.props.onSelectTopic}
        showSelect={false}
        defaultSelect="Select Default Benchmark"
        showUploadButton={true}
        clearTopicFile={() => clearBenchMarkData(this)}
      />
    );
  };

  getHrisOption = () => {
    let hrisFilesList = this.state.hrisFilesList;
    if (this.props.fetchHrisFIleApi.data) {
      let hrisFilesListNext =
        this.props.fetchHrisFIleApi.data.response.hrisFilesList
          .filter((item) => item.bulkJobStatus === "Completed")
          .map((item) => {
            return {
              label: item.hrisFileName,
              name: item.hrisFileName,
              value: item.hrisFileId,
            };
          });

      hrisFilesListNext = [...hrisFilesList, ...hrisFilesListNext];
      return hrisFilesListNext;
    } else return hrisFilesList;
  };
  onScroll = (event) => {
    this.setState({
      hrisCurrentPage: event,
      hrisFilesList: [
        ...new Set([...this.state.hrisFilesList, ...this.getHrisOption()]),
      ],
    });
    this.props.fetchHrisFile(this.getParamsForPagination(event * 12));
  };

  onSelectHris = (value, event) => {
    if (value !== "") this.setState({ hrisFileId: value, hrisExisting: true });
    else this.setState({ hrisFileId: value, hrisExisting: false });
    this.props.onSelectHris(value, event);
  };

  selectHRIS = () => {
    let organizationId = getOrgId();
    if (checkIfAdmin() || checkIfOrgAdmin(organizationId)) {
      return (
        <SelectUpload
          index={1}
          hideUpload={true}
          onSelect={this.onSelectHris}
          title={constants.selectHris}
          api={this.props.hrisFileApi}
          onUpload={this.props.onUploadHris}
          hrisFileId={this.props.hrisFileId}
          dataLoading={this.props.fetchHrisFIleApi.loading}
          disable={false}
          disableUpload={true}
          options={this.getHrisOption()}
          defaultValue=""
          onScroll={this.onScroll}
          showSelect={true}
          showUploadButton={true}
        />
      );
    }

  };

  batchIdField = () => (
    <div className="required-field">
      <TextInput
        htmlType="text"
        name="projectId"
        placeholder={constants.batchIdPlaceholder}
        value={this.props.currentBatchId}
        handleChange={this.props.onChangeBatchIdField}
        className="enter-batchid"
      />
    </div>
  );

  getProjectType = () => {
    let projectType = getProjectType("/admin/home/organizations/:org_id/:projectType/add-data-collection-reports")
    return projectType
  }

  handleTimeChange = (event, data) => {
    this.setState({
      timeEvent: event,
      time: data
    })
  }

  handleDateChange = (event, data) => {
    this.setState({
      dateEvent: event,
      date: data
    })
  }

  getDateTimePicker = () => {
    return (
      <div className="datetime-block">
        <DatePicker
          inputReadOnly={true}
          value={this.state.dateEvent}
          format={"dddd, MMMM Do YYYY"}
          className="reminder-block__input"
          placeholder={"Select date"}
          onChange={this.handleDateChange}
        />
        <TimePicker
          inputReadOnly={true}
          value={this.state.timeEvent}
          format={`h:mm a [${getShortTimeZoneFromDateString(new Date().toString())}]`}
          className="reminder-block__input"
          placeholder={"Select time"}
          onChange={this.handleTimeChange}
        />
      </div>
    )
  }

  addSurveyBody = () => (
    <React.Fragment>
      <div className="input-form__body">
        <div className="input-form__body--section">

          <Row gutter={16}>
            {/* xs={24} sm={24} md={8} lg={6} xl={6} xxl={6} */}
            <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
              <div className="drag-section">

                <ul className="analytics">
                  <li>
                    <label>
                      Choose data collection file <AsterikLabel />
                    </label>
                    {this.dragOption()}
                  </li>
                  {/* <li>{this.commentFileUpload()}</li> */}
                  {this.getProjectType() !== "Very_Simple_Survey" &&
                    <li>
                      <label>Upload topics</label>
                      {this.topicFileUpload()}
                    </li>
                  }
                  <li>
                    <label>Upload benchmark</label>
                    {this.benchMarkFileUpload()}
                  </li>
                  <li>
                    <Spin spinning={this.props.fetchHrisFIleApi.data ? false : true}>
                      <label>Select HRIS</label>
                      {this.selectHRIS()}
                    </Spin>
                  </li>
                  <li>
                    <label>
                      Enter project name <AsterikLabel />
                    </label>

                    {this.batchIdField()}
                  </li>
                  <li>
                    <label>
                      Project creation date
                      {this.getTooltip()}
                    </label>
                    {this.getDateTimePicker()}
                  </li>
                  <li className="login-btn">{this.processButton()}</li>
                </ul>
              </div>

            </Col>
            <Col xs={24} sm={24} md={16} lg={18} xl={18} xxl={18}>
              {this.getDownloadButton()}
            </Col>

          </Row>
        </div>
      </div>

    </React.Fragment>
  );
  disableClose = () => {
    this.setState({ disableClose: true })
  }
  dragOption = () => (
    <>
      <FileUpload
        handleFileUpload={this.props.onUploadComment}
        fileUpload={this.props.commentsFileApi}
      />
      {/*this.props.commentsFileApi.name*/}
      <Tooltip title={this.props.commentsFileApi.name}>
        <span className="inputFile__name" children={this.changeApi(this.props.commentsFileApi.name)} />
        {this.props.commentsFileApi.name && this.getCloseButton({
          disableClose: this.state.disableClose,
          clearTopicFile: this.props.clearFormData,

        })}
      </Tooltip>
    </>
  );
  changeApi = (api) => {
    return api && api.length > 20
      ? api.slice(0, 20) + "(...)." + api.split(".").pop()
      : api;
  };

  getCloseButton = (obj) => {
    return (
      !obj.disableClose ? <span className="upload-close-icon" onClick={() => obj.clearTopicFile()}>
        <SVGIcons type={'SVG-circle-cross'} />
      </span> : null)
  }

  getSelectedDate = () => {
    if (this.state.date && this.state.time) {
      return getDispalyDateInIsoFormat(this.state.date, this.state.time)
    } else if (this.state.date && !this.state.time) {
      return getDispalyDateInIsoFormat(this.state.date, moment(new Date().toISOString()).format(`h:mm a [${getShortTimeZoneFromDateString(new Date().toString())}]`))
    } else if (!this.state.date && this.state.time) {
      return getDispalyDateInIsoFormat(moment(new Date().toISOString()).format("dddd, MMMM Do YYYY"), this.state.time)
    }
    return getDispalyDateInIsoFormat(moment(new Date().toISOString()).format("dddd, MMMM Do YYYY"), moment(new Date().toISOString()).format(`h:mm a [${getShortTimeZoneFromDateString(new Date().toString())}]`))
  }

  addJob = (keyName, fileName, topicFileName, benchMarkFIleName) => {
    let projectType = getProjectType("/admin/home/organizations/:org_id/:projectType/add-data-collection-reports")
    let selectedCreationTime = this.getSelectedDate()
    this.props.addCommentsJob(
      {
        keyName,
        selectedCreationTime,
        topicFileName,
        benchmarkFileName: benchMarkFIleName,
        commentsFileName: fileName,
        projectName: this.props.currentBatchId.trim(),
        projectId: this.props.currentBatchId.trim(),
        projectType: projectType,
        projectOrigin: constants.PROJECT_ORIGIN.dataCollection,
        organizationId: getOrgIdFromUrl(),
        projectStatus: "processing",
        hrisFileId: this.state.hrisFileId,
        hrisExisting: this.state.hrisExisting,
        userId: localStorage.getItem("userId"),
        topicLibraryExisting: !this.props.libraryFileApi.name ? false : true,
        topicsLibraryFile:
          (this.props.libraryFileApi.name &&
            this.props.libraryFileApi.file &&
            this.props.libraryFileApi.file.name) ||
          null,
        ...helpers.getParamsForAddJob(),
      },
      (isSuccess) => {
        if (isSuccess) {
          this.props.fetchBatchIds();
          this.props.setProjectsCount();
          this.setState({ submitToUpload: false });
          this.props.clearHrisFileId();
          this.redirectToProjectList()
        }
      }
    );
  };

  redirectToProjectList = () => {
    const orgId = getOrgIdFromUrl('/admin/home/organizations/:org_id/');
    const queryParams = qs.parseUrl(window.location.href).query;
    this.props.history.push({
      pathname: getNewSurveyPagePath(orgId),
      search: queryParams && queryParams.params ?
        `params=${encodeURIComponent(queryParams.params)}` : undefined
    })
  }

  submitToUpload = (cb, fileName) => {
    this.setState({
      submitToUpload: true,
    });
    helpers.submitToUpload(this.props, {
      addJob: this.addJob,
      fileName: fileName,
      cb: this.addJob,
      failed: this.failedProcess,
    }, this);
  };
  failedProcess = () => {
    this.setState({ submitToUpload: false });
    this.props.clearHrisFileId();
  };
  processButton() {
    const { processFilesApi, commentsFileApi } = this.props;
    console.log('Comments file api', commentsFileApi)
    return (
      <LoaderButton
        loader={processFilesApi.loading || this.state.submitToUpload}
        onClick={this.submitToUpload}
        disabled={!commentsFileApi.file ||
          this.props.disableButton || this.state.submitToUpload}
        classList={constants.loaderButton.classes}
        loaderClass={constants.loaderButton.loaderClass}
        text={constants.buttonText}
      />
    );
  }

  addSurveyHeader = () => (
    <div className="input-form__header">
      {/* <h3>
        Narrative Sense
        <sup>TM </sup>
        for Text Analytics
      </h3> */}
    </div>
  );

  render() {
    return (
      <div className="input-form">
        {this.addSurveyHeader()}
        {this.addSurveyBody()}
      </div>
    );
  }
}

export default UploadForm;