import { Spin } from 'antd';
import parse from 'html-react-parser';
import React from 'react';
import SVGIcons from '../../../../../../../Components/SVGIcons';
import Comments from './Comments';

export class OutliersSection extends React.Component {
  renderOutliers = (outliers, isPositive) => {
    return outliers.map((outlier) => {
      return (
        <div key={outlier.outlier} className='topics-description__content'>
          <div className='topics-description__content--left'>
            <div className='warning-box'>
              <div className='warning-box__icon'>
                <SVGIcons type={'SVG-warning-alert'} />
              </div>
              <div className='warning-box__content'>
                <p>
                  <b>{isPositive ? 'Notice: ' : 'Warning: '}</b>
                  {parse(outlier.outlier)}
                </p>
              </div>
            </div>
          </div>
          <Comments comments={outlier.comments} topicObject={this.props.data} />
        </div>
      );
    });
  };

  render() {
    const { positiveOutliers, negativeOutliers } = this.props;
    return (
      <>
        <h2>Outliers</h2>
        {this.props.showStoryboardProgress ? (
          <div className='topics-description__loader'>
            <Spin spinning={this.props.showStoryboardProgress} />
            <span>Processing</span>
          </div>
        ) : (
          <>
            {this.renderOutliers(positiveOutliers, true)}
            {this.renderOutliers(negativeOutliers, false)}
          </>
        )}
      </>
    );
  }
}
