import * as helper from './helper';
import * as actionTypes from '../../constants';
import initialState from './intialState';

const NLPStoryboardQuestionReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.FETCH_QUESTION_TOPICS_REPRESENTATION_START:
        return helper.fetchTopicsRepresentationStart(state, action);
    case actionTypes.FETCH_QUESTION_TOPICS_REPRESENTATION_SUCCESS:
        return helper.fetchTopicsRepresentationSuccess(state, action);
    case actionTypes.FETCH_QUESTION_TOPICS_REPRESENTATION_FAIL:
        return helper.fetchTopicsRepresentationFail(state, action);
    case actionTypes.FETCH_QUESTION_TOPICS_WORDCLOUD_START:
        return helper.fetchWordcloudStart(state, action);
    case actionTypes.FETCH_QUESTION_TOPICS_WORDCLOUD_SUCCESS:
        return helper.fetchWordcloudSuccess(state, action);
    case actionTypes.FETCH_QUESTION_TOPICS_WORDCLOUD_FAIL:
        return helper.fetchWordcloudFail(state, action);
    case actionTypes.FETCH_QUESTION_TOPICSDATA_START:
        return helper.fetchTopicsDataStart(state, action);
    case actionTypes.FETCH_QUESTION_TOPICSDATA_SUCCESS:
        return helper.fetchTopicsDataSuccess(state, action);
    case actionTypes.FETCH_QUESTION_TOPICSDATA_FAIL:
        return helper.fetchTopicsDataFail(state, action);
    case actionTypes.UPDATE_SELECTED_COLUMNS:
        return helper.updateSelectedColumns(state, action);
    default: return reducer2(action,state);
    }
};

function reducer2(action,state){
    switch (action.type) {
    case actionTypes.FETCH_QUESTION_COMMENTS_START:
        return helper.fetchCommentsStart(state, action);
    case actionTypes.FETCH_QUESTION_COMMENTS_SUCCESS:
        return helper.fetchCommentsSuccess(state, action);
    case actionTypes.FETCH_QUESTION_COMMENTS_FAIL:
        return helper.fetchCommentsFail(state, action);
    case actionTypes.SWITCH_QUESTION_COMMENT_START:
        return helper.switchCommentStart(state, action);
    case actionTypes.SWITCH_QUESTION_COMMENT_SUCCESS:
        return helper.switchCommentSuccess(state, action);
    case actionTypes.SWITCH_QUESTION_COMMENT_FAIL:
        return helper.switchCommentFail(state, action);
    default:return state;
    }
}

export default NLPStoryboardQuestionReducer;