import { nlpGetURLParams } from "./nlpURLUtils"
import _ from "lodash";
import { getDataFromUrlToken } from "./urlDataStore";
import { URL_DATA } from "../../config/urlDataConstants";

export const getCustomThreshold = (checkEquals) => {
    if (getProjectId() === "5hjs8ksyxe9jw") {
        return 2
    }
    if (getProjectId() === "b1n0db8kwcc9kci") {
        return 2
    }
    return 5
}

const getProjectId = () => {
    const projectDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS)
    if (projectDetails && projectDetails.projectId) {
        return projectDetails.projectId
    }
    if (nlpGetURLParams() && nlpGetURLParams().batch) {
        return nlpGetURLParams().batch
    }

}

export const checkIfObjectsAreEqual = (firstObject, secondObject) => {
    const ob1 = !firstObject ? {} : firstObject;
    const ob2 = !secondObject ? {} : secondObject;
    return _.isEqual(ob1, ob2)
}