import React from 'react';
import { sortOptions, topicSortOptions, constants } from './constants';
import { SelectElement } from '../../../../../../FormElements/SelectElement';
import { Panel } from './children/Panel';
import {
  getDataFromUrlToken,
} from '../../../../../../utils/urlDataStore';
import GraphLoader from '../../../../../../Components/ContentLoaders/GraphLoader';
import { EmptyState } from '../../../../../../Packages/EmptyState';
import { Spin, Switch } from 'antd';
import { URL_DATA } from '../../../../../../../config/urlDataConstants';
import { checkIfObjectsAreEqual } from '../../../../../../utils/customFunctions';
import { getSelectedUserData } from '../../helper';
import { getApiPayload } from './helper';
import {
  checkIfTwoArraysAreIsEqual,
  getURLFilterAndByScore,
} from '../../../../../../utils/byScoreFilterUtils';
import AdminBarChart from './children/AdminBarChart';
import { DASHBOARD_TABS } from '../../../../../../../config/tabConstants'
import { checkIfAdmin } from '../../../../../../utils/rolePermissionUtils';

export class Topics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOptions: sortOptions,
      selectedSortOption: this.props.exploreTopicListApi.sortOption,
      topicSortOptions: topicSortOptions,
      selectedTopicSortOption: this.props.exploreTopicListApi.sortOrder,
      showStoryboardProgress: false,
      storyboardStatus:
        this.props.exploreTopicFilterStatus.data.topicStoriesFilterStatus,
      filters: this.props.exploreTopicFilterStatus.data.filters,
      showLoader: false,
      showStoryboardError: false,
      showBarChart: false,
    };
  }

  componentDidMount() {
    const { projectId } = getDataFromUrlToken('projectDetails');
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(urlFilter);
    const selectedUserData = getSelectedUserData();
    if (this.checkIfApiCallRequiredOnMount(urlFilter, selectedUserData)) {
      this.callApi({
        filters: filterData.filters,
        byScore: filterData.byScore,
        selectedUserData,
        isFiltered: false,
        topicStoryFilter: false,
      });
      this.props.fetchExploreTopicList(
        {
          currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TOPICS,
          hrisFileId: this.props.surveyDetails.hrisFileId,
          projectId: projectId,
          ...filterData,
          directReporteesOnly: selectedUserData.directReporteesOnly,
          selectedUser: selectedUserData.selectedUser,
          isAnonymous: this.props.surveyDetails.isAnonymous ? true : false,
        },
        () => {
          this.props.fetchStoryboardFilterStatus(
            { projectId, type: 'topicStoryFilter', currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TOPICS, },
            (response) => {
              this.mapStoryboardStatusToState(response)
            }
          );
        }
      );
    } else {
      this.props.fetchStoryboardFilterStatus(
        { projectId, type: 'topicStoryFilter', currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TOPICS, },
        (response) => this.mapStoryboardStatusToState(response)
      );
    }
  }

  recheckStoryboardStatus = () => {
    const { projectId } = getDataFromUrlToken('projectDetails');
    setTimeout(() => {
      this.props.fetchStoryboardFilterStatus(
        { projectId, type: 'topicStoryFilter', currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TOPICS, },
        (response) => this.mapStoryboardStatusToState(response)
      );
    }, 5000);
  };

  getTopicStories = ({ isFiltered, topicStoryFilter }, callBack) => {
    const { projectId } = getDataFromUrlToken('projectDetails');
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(urlFilter);
    const selectedUserData = getSelectedUserData();
    this.props.fetchExploreTopicList(
      {
        currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TOPICS,
        hrisFileId: this.props.surveyDetails.hrisFileId,
        projectId: projectId,
        ...filterData,
        directReporteesOnly: selectedUserData.directReporteesOnly,
        selectedUser: selectedUserData.selectedUser,
        isFiltered: isFiltered || false,
        topicStoryFilter: topicStoryFilter || false,
        isAnonymous: this.props.surveyDetails.isAnonymous ? true : false,
      },
      () => callBack && callBack()
    );
  };

  mapStoryboardStatusToState = (response, filterGenerated) => {
    let data = (response &&
      response.storyboardStatusData &&
      response.storyboardStatusData._source) || {
      topicStoriesFilterStatus: '',
      filters: {},
      byScore: [],
    };
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER) || {};
    let filterData = getURLFilterAndByScore(urlFilter);
    const selectedUserData = getSelectedUserData();
    if (
      !Object.keys(filterData.filters).length &&
      !filterData.byScore.length &&
      !filterData.multipleSelect.length &&
      !selectedUserData.directReporteesOnly &&
      !selectedUserData.selectedUser
    ) {
      this.getTopicStories({}, () => {
        this.setState({
          showStoryboardProgress: false,
          storyboardStatus: 'complete',
        })
      }
      );
    } else if (
      this.getCondition(selectedUserData, data, filterData) &&
      !filterGenerated
    ) {
      this.setState({
        showStoryboardProgress: true,
        storyboardStatus: 'processing',
      });
      const { projectId } = getDataFromUrlToken('projectDetails');
      const filters = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
      let filtersData = getURLFilterAndByScore(filters);

      this.props.applyFilterOnExploreTopicList(
        {
          currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TOPICS,
          isAnonymous: this.props.surveyDetails.isAnonymous ? true : false,
          projectId: projectId,
          hrisFileId: this.props.surveyDetails.hrisFileId,
          ...filtersData,
          directReporteesOnly: selectedUserData.directReporteesOnly,
          selectedUser: selectedUserData.selectedUser,
          isFiltered: false,
          topicStoryFilter: true,
        },
        (success) => {
          if (success) {
            const { projectId } = getDataFromUrlToken('projectDetails');
            this.props.fetchStoryboardFilterStatus(
              { projectId, type: 'topicStoryFilter', currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TOPICS, },
              (response) => this.mapStoryboardStatusToState(response, true)
            );
          }
        }
      );
    } else {
      if (data.topicStoriesFilterStatus === 'complete') {
        this.getTopicStories({ isFiltered: true }, () => {
          this.setState({
            showStoryboardProgress: false,
            storyboardStatus: 'success',
            filters: data.filters,
            byScore: data.byScore,
            showStoryboardError: false,
          })
        }
        );
      } else if (data.topicStoriesFilterStatus === 'processing') {
        this.setState({
          showStoryboardProgress: true,
          storyboardStatus: 'processing',
          showStoryboardError: false,
        });
        this.recheckStoryboardStatus();
      } else if (data.topicStoriesFilterStatus === 'failed') {
        this.setState({
          showStoryboardProgress: false,
          storyboardStatus: 'failed',
          showStoryboardError: true,
        });
      }
    }
  };

  getCondition = (selectedUserData, data, filterData) => {
    return !checkIfObjectsAreEqual(filterData.filters, data.filters) ||
      !checkIfTwoArraysAreIsEqual(filterData.byScore, data.byScore) ||
      !checkIfTwoArraysAreIsEqual(filterData.multipleSelect, data.multipleSelect) ||
      selectedUserData.directReporteesOnly !== data.directReporteesOnly ||
      selectedUserData.selectedUser !== this.getUser(data)
  }

  getUser = (data) => {
    return data.selectedUser ? data.selectedUser : ""
  }

  callApi =   (
    { filters, byScore, selectedUserData, isFiltered, topicStoryFilter },
    cb
  ) => {
    this.setState({ showLoader: true })
    let payload = getApiPayload(this.props);
    this.props.sortExploreTopicList({
      option: "score",
      sortOption: constants.DESCENDING,
    });
    const projectDetails = getDataFromUrlToken('projectDetails');
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(urlFilter);
    const userData = getSelectedUserData();
    if (!this.props.topicsSurveyReportApi.isProcessing && !this.props.topicsSurveyReportApi.error) {
      payload.connectedProjects = projectDetails.connectedProjects
      this.props.exploreTopicsFetchSurveyReportData({ ...payload, currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TOPICS }, () => {
        cb && cb();
        // this.setState({ showLoader: false })
        !this.props.adminBarchartApi.isProcessing &&
          this.props.fetchAdminBarchartData({
            currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TOPICS,
            projectId: projectDetails.projectId,
            ...filterData,
            directReporteesOnly: userData.directReporteesOnly,
            selectedUser: userData.selectedUser,
            adminBarChart: true,
            filters: filterData.filters,
            byScore: filterData.byScore,
            userData,
            isFiltered: false,
            topicStoryFilter: false,
            outcome: projectDetails.outcomeVariable,
            connectedProjects: projectDetails.connectedProjects,
            hrisFileId: projectDetails.hrisFileId,
            isAnonymous: projectDetails.isAnonymous,
          }, () => { });
      });
    }
    this.setState({ showLoader: false })
  };

  checkIfApiCallRequiredOnMount = (urlFilter, selectedUserData) => {
    let filterData = getURLFilterAndByScore(urlFilter);
    if (
      !this.props.exploreTopicListApi.isApiCalled ||
      !this.props.topicsSurveyReportApi.isApiCalled ||
      !checkIfObjectsAreEqual(filterData.filters, this.props.filters) ||
      !checkIfTwoArraysAreIsEqual(filterData.byScore, this.props.byScore) ||
      !checkIfTwoArraysAreIsEqual(filterData.multipleSelect, this.props.multipleSelect) ||
      !checkIfObjectsAreEqual(
        selectedUserData.directReporteesOnly,
        this.props.directReporteesOnly
      ) ||
      !checkIfObjectsAreEqual(
        selectedUserData.selectedUser,
        this.props.selectedUser
      )
    ) {
      return true;
    }
    return false;
  };

  componentDidUpdate(prevProps) {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER) || {};
    const selectedUserData = getSelectedUserData();
    let filterData = getURLFilterAndByScore(urlFilter);
    // if (this.state.showLoader) {
    //   this.setState({ showLoader: false })
    // }

    if (
      this.props.exploreTopicListApi.isApiCalled &&
      this.props.topicsSurveyReportApi.isApiCalled &&
      checkIfObjectsAreEqual(filterData.filters, this.props.filters) &&
      checkIfTwoArraysAreIsEqual(filterData.byScore, this.props.byScore) &&
      checkIfTwoArraysAreIsEqual(filterData.multipleSelect, this.props.multipleSelect) &&
      selectedUserData.directReporteesOnly === this.props.directReporteesOnly &&
      selectedUserData.selectedUser === this.props.selectedUser
    ) {
    } else {
      if (
        !this.props.exploreTopicListApi.isProcessing &&
        !this.props.topicsSurveyReportApi.isProcessing
      ) {
        this.callApi(
          {
            ...filterData,
            selectedUserData,
            isFiltered: false,
            topicStoryFilter: false,
          },
          () => {
            const { projectId } = getDataFromUrlToken('projectDetails');
            this.props.fetchStoryboardFilterStatus(
              { projectId, type: 'topicStoryFilter', currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TOPICS },
              (response) => this.mapStoryboardStatusToState(response)
            );
          }
        );
      }
    }
  }

  getOptions = () =>
    this.state.sortOptions.map((item) => ({
      label: item.label,
      value: item.value,
    }));

  handleSortOptionChange = (value) => {
    if (value === constants.TOPIC) {
      this.setState({
        selectedSortOption: value,
        selectedTopicSortOption: constants.ASCENDING,
      });
    } else this.setState({ selectedSortOption: value });
  };

  handleTopicSortOptionChange = (value) =>
    this.setState({ selectedTopicSortOption: value });

  handleOptionClick = (value) => {
    if (value === this.props.exploreTopicListApi.sortOption) {
      if (this.props.exploreTopicListApi.sortOrder === constants.ASCENDING) {
        this.props.sortExploreTopicList({
          option: this.props.exploreTopicListApi.sortOption,
          sortOption: constants.DESCENDING,
        });
      } else if (
        this.props.exploreTopicListApi.sortOrder === constants.DESCENDING
      ) {
        this.props.sortExploreTopicList({
          option: this.props.exploreTopicListApi.sortOption,
          sortOption: constants.ASCENDING,
        });
      }
    } else {
      this.props.sortExploreTopicList({
        option: value,
        sortOption: value === "topic" ? constants.ASCENDING : constants.DESCENDING,
      });
    }
  };

  getLoader = () => {
    return (
      this.state.showLoader || this.props.topicsSurveyReportApi.isProcessing
    );
  };

  checkDimensionTableLength = () => {
    return (
      this.props.surveyReport &&
      this.props.surveyReport.dimensionTable &&
      this.props.surveyReport.dimensionTable.length
    );
  };

  setShowBarChart = (showBarChart) => {
    this.setState({ showBarChart });
  };

  render() {
    return (
      <div className='topics-block'>
        {this.getLoader() ? (
          <GraphLoader width={600} height={400} />
        ) : this.props.surveyDetails.storyBoardStatus === constants.COMPLETED &&
          this.state.showStoryboardError !== 'failed' ? (
          <Spin spinning={this.props.quoteModifying}>
            <div className='topics-block__header'>
              {
                !this.state.showBarChart && <div className='topics-block__header--left'>
                  <SelectElement
                    disableSearch={true}
                    optionList={this.getOptions()}
                    className='topics-category'
                    value={this.props.exploreTopicListApi.sortOption}
                    showArrow={true}
                    showCustomIcon={true}
                    onSelect={this.handleOptionClick}
                    selectedSortOption={this.props.exploreTopicListApi.sortOption}
                    selectedTopicSortOption={
                      this.props.exploreTopicListApi.sortOrder
                    }
                  />
                </div>
              }
              <div className='topics-block__header--right'>
                <div className='toggle-block'>
                  <p>Show bar chart</p>
                  <Switch
                    checked={this.state.showBarChart}
                    onChange={this.setShowBarChart}
                  />
                </div>
              </div>
            </div>
            {this.state.showBarChart ? (
              <AdminBarChart />
            ) :
              // this.props.exploreTopicListApi &&
              //   this.props.exploreTopicListApi.exploreTopicList &&
              //   this.props.exploreTopicListApi.exploreTopicList.length &&
              this.props.surveyReport &&
                this.props.surveyReport.dimensionTable &&
                this.props.surveyReport.dimensionTable.length ? (
                <Panel
                  topicStoryLoading={this.props.exploreTopicListApi.isProcessing}
                  api={this.props.exploreTopicListApi}
                  itemTableData={this.props.surveyReport.itemTable}
                  dimensionTable={this.props.surveyReport.dimensionTable}
                  outcome={this.props.surveyDetails.outcomeVariable}
                  showStoryboardProgress={this.state.showStoryboardProgress}
                  threshold={this.props.thresholdData && this.props.thresholdData.filterThreshold ? this.props.thresholdData.filterThreshold : 5}
                  benchmarkTooltip={this.props.thresholdData && this.props.thresholdData.benchmarkTooltip ? this.props.thresholdData.benchmarkTooltip : ""}
                />
              ) : (
                <EmptyState emptyStateMessage={constants.EMPTY_MESSAGE} />
              )}
          </Spin>
        ) : (
          <EmptyState
            emptyStateMessage={
              this.props.surveyDetails.storyBoardStatus === constants.PROCESSING
                ? constants.GENERATING_TOPICS
                : constants.TOPIC_FAILED
            }
            customHeight='400'
          />
        )}
      </div>
    );
  }
}
