export const fetchNeumericDemographicsStart = (state, payload) => {
    return {
        ...state,
        neumericDemographicsApi: {
            isProcessing: true,
            error: false,
            reason: '',
            data: {},
        },
    };
};

export const fetchNeumericDemographicsSuccess = (state, payload) => {
    let data = payload.data && payload.data.data && payload.data.data["Average Score"] 
    && payload.data.data["Average Score"].histogramData
    && payload.data.data["Average Score"].histogramData[0]
    return {
        ...state,
        neumericDemographicsApi: {
            isProcessing: false,
            error: false,
            reason: '',
            data: data?data:{},
        },
    };
};

export const fetchNeumericDemographicsFail = (state, payload) => {
    return {
        ...state,
        neumericDemographicsApi: {
            isProcessing: false,
            error: true,
            reason: payload.error,
            data: {},
        },
    };
};
