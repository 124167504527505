import { getOrgId } from "./localStorgeOperations";
const apiBase = {
  errorCode: "tokenExpired",
};

const colors = [
  "#7FFF00",
  "red",
  "darkgreen",
  "violet",
  "orange",
  "blue",
  "black",
  "cyan",
  "lightblue",
  "yellow",
  "salmon",
  "olive",
  "darkred",
  "darkcyan",
];
export default {
  apiBase,
  path: {
    login: "/login",
    userHome: "/dashboard",
    adminHome: "/admin/home/organizations",
    managerHome: () => "/home/" + getOrgId() + "/survey",
    commonHome: "/dashboard",
    accessDenied: "/acccessDenied"
  },
  INSUFFICIENT_DATA: "Insufficient data!",
  EMPTY_STRING: "",
  local: {
    idToken: "idToken",
    userRole: "userRole",
  },
  defaultComparisonDomain: "gender",
  colors,
  defaultRequestHeader: {
    "Content-Type": "application/json",
  },
  outcomeVariable: "Engagement",
};

export const ROLES = {
  ADMIN: "Admin",
  ORG_ADMIN: "Organization Admin",
  ORG_MANAGER: "Organization Manager",
  VIEWER: "Viewer",
};

export const ROLES_US_SPELLING = {
  ORG_ADMIN: "Organization Admin",
  ORG_MANAGER: "Organization Manager",
};

export const projectsRouteKeyMapper = [
  {
    ui: "projectId",
    api: "projectId",
  },
  {
    ui: "questions",
    api: "questionSet",
  },
  {
    ui: "projectName",
    api: "projectName",
  },
  {
    ui: "public",
    api: "isPublic",
  },
];
export const emailRegex = /^[a-zA-Z][a-zA-Z0-9_+.'-]*(\.[a-zA-Z][a-zA-Z0-9_']*)?@[a-zA-Z][a-zA-Z-0-9]*\.[a-zA-Z]+(\.[a-zA-Z]+)?$/
export const PROJECT_ORIGIN = {
  DATA_COLLECTION: "Data Collection",
  COMMENT_ANALYSIS: "Comment Analysis",
};

export const LIST_SURVEY_CONSTANTS = {
  queryStringForProject: "?projectId=",
  queryStringForPublishedProject: "&active=true",
  queryStringForScheduledProject: "&active=true",
  published: "live",
  closed: "complete",
  scheduled: "scheduled",
  queryStringForProjectId: "&projectId=",
};
