import {
  GetLocalStorage,
  getOrgId,
} from "./localStorgeOperations";
import _ from "lodash";
import {
  getDefaultHrisValue,
} from "./localStorgeOperations";

export function withType(type, payload) {
  return { type, payload };
}

export function dummyApi(time = 1000, failure) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (failure) return reject({ data: { message: "nw error" } });
      return resolve({ data: { data: [] } });
    }, time);
  });
}

export function NLPSurveyListDefaultFilter() {
  return {
    from: 0,
    size: 200,
    filters: {
      listSurveyFilter: {
        should: {
          projectOrigin: ["Data Collection", "Text Analysis"],
          projectStatus: ["closed"],
        },
        must_not: {
          projectStatus: ["processing"],
        },
      },
    },
  };
}

export function NLPCommonReqBodies(survey, orgId) {
  return {
    userId: GetLocalStorage("userId"),
    userEmail: GetLocalStorage("username"),
    projectId: survey ? null : GetLocalStorage("projectId"),
    organizationId: orgId || getOrgId(),
    projectOrigin: GetLocalStorage("projectOrigin"),
  };
}

export const listSurveyCommonBody = (survey, orgId) =>{
  return {
    ...getNlpHrisFileId(), organizationId: orgId
  };
}

const getNlpHrisFileId = () => {
  if (
    GetLocalStorage("nlpHrisId") &&
    GetLocalStorage("nlpHrisId") !== "undefined"
  ) {
    return {
      hrisFileId: GetLocalStorage("nlpHrisId"),
    };
  }
  return {};
};

export const NLPTreeDataBody = (checked) => {
  return {
    filePath: GetLocalStorage("comments_file_path"),
    projectId: GetLocalStorage("projectId"),
    directReports: checked,
    ...NLPCommonReqBodies(),
  };
};

const getDirectReportees = () => {
  if (GetLocalStorage("directReporteesOnly")) {
    return {
      directReporteesOnly: GetLocalStorage("directReporteesOnly"),
    };
  } else return { directReporteesOnly: false };
};

export const getUserDataFromTree = () => {
  if (GetLocalStorage("selectedUser")) {
    return {
      ...getDirectReportees(),
      selectedUser: GetLocalStorage("selectedUser"),
      selectedChildren: GetLocalStorage("selectedChildren")
        ? GetLocalStorage("selectedChildren").split(",")
        : [],
    };
  } else
    return {
      selectedUser: false,
      ...getDirectReportees(),
    };
};

export const getProjectOrigin = () => {
  return {
    projectOrigin: GetLocalStorage("projectOrigin"),
  };
};

export const checkIfDataForTree = (data) => {
  if (data) {
    if (_.isEmpty(data)) {
      return [];
    }
    return [data];
  }
  return [];
};

export const getDefaultHRISValue = (payload) => {
  if (payload.response) {
    if (payload.response.hrisFilesList[0]) {
      return payload.response.hrisFilesList[0].hrisFileId;
    }
  }
};
export const getParamsForFetchUser = (params, hrisFileId) => {
  return {
    hrisFileId: hrisFileId ? hrisFileId : getDefaultHrisValue(),
    organizationId: params.organizationId,
    pagination: params.pagination ? params.pagination : { from: 0, size: 12 },
  };
};
export const getParamsForFetchHierarchy = (params, hrisFileId) => {
  return {
    hrisFileId: hrisFileId ? hrisFileId : getDefaultHrisValue(),
    organizationId: params.organizationId,
  };
};

export const getSurveyListQueryParams = (payload) => {
  const requestBody = {
    from: (payload && payload.from) || 0,
    searchText: (payload && payload.searchText) || null,
    sortField: (payload && payload.sortField) || null,
    sortOrder: (payload && payload.sortOrder) || null,
    ...payload,
  };
  return requestBody;
};
