import { connect } from 'react-redux';
import { Questions } from './Questions';
import { fetchExploreQuestions, fetchFollowUpQuestionsReportData, NewDashboardGetQuestionsTableTrend } from '../../../../../../redux/actions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => {
  return {
    ...state.ExploreQuestionsReducer,
    ...state.FetchFollowUpReportTableReducer,
    projectDetails: state.DashBoardReducer.surveyDetailsApi.surveyDetails,
    thresholdData: state.DashBoardReducer.thresholdApi.data,
    listComparisonSurveyApi: state.NewdashboardTrendPairingReducer.listComparisonSurveyApi
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchExploreQuestions: (params) => dispatch(fetchExploreQuestions(params)),
    fetchFollowUpQuestionsReportData: (params, cb) => dispatch(fetchFollowUpQuestionsReportData(params, cb)),
    NewDashboardGetQuestionsTableTrend: (params, cb) => dispatch(NewDashboardGetQuestionsTableTrend(params, cb))

  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Questions));
