import { connect } from "react-redux";
import RatingScaleQuestion from "./RatingScaleQuestion";
import { handleChangeRating, updateCurrentIndex } from "../../../../../../redux/actions";


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handleChangeRating: (payload) => dispatch((handleChangeRating(payload))),
        updateCurrentIndex: (payload) => dispatch((updateCurrentIndex(payload)))

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RatingScaleQuestion);