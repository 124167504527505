import {
  STOP_RESPONSE_COLLECTION_FAIL,
  STOP_RESPONSE_COLLECTION_SUCCESS,
  STOP_RESPONSE_COLLECTION_START,
} from "../../constants";
import { Default } from "./IntialState";
import {
  stopResponseCollectionStart,
  stopResponseCollectionSuccess,
  stopResponseCollectionFail,
} from "./helper";

const stopResponseCollectionReducer = (state = Default(), action) => {
  switch (action.type) {
    case STOP_RESPONSE_COLLECTION_START:
      return stopResponseCollectionStart(state, action);
    case STOP_RESPONSE_COLLECTION_SUCCESS:
      return stopResponseCollectionSuccess(state, action);
    case STOP_RESPONSE_COLLECTION_FAIL:
      return stopResponseCollectionFail(state, action);
    default:
      return { ...state };
  }
};

export default stopResponseCollectionReducer;
