import React from 'react';
import { StoryBoardNewBarChart } from '../../../../../../../../Components/StoryBoardNewBarChart';

export const AllThemeBarChart = (props) => {
  return (
    <>
      <div className='barchart-head'>
        <h2 className='head-barchart'>Themes from comments</h2>
      </div>
      <div className='div-barchart'>
        <div>
          <StoryBoardNewBarChart
          isFromCommentQuestions={true}
            defaultTopicIsSelected={props.defaultTopicIsSelected}
            labelClass = {props.defaultTopicIsSelected?'barchart-cursor':''}
            graphCursorClass = {props.defaultTopicIsSelected?'':'progress-no-cursor'}
            data={props.data}
            limit={3}
            threshold={props.threshold}
            onClick={props.onBarchartClick}
            hideBackground={true}
          />
        </div>
      </div>
    </>
  );
};
