export function fetchHeatmapStarted(state, action) {
  return {
    ...state,
    heatmapApi: {
      ...state.heatmapApi,
      loading: true,
      error: false
    },
    heatmapData:{...state.heatmapData,...action.data}
  };
}

export function fetchHeatmapSuccess(state, action) {
  return {
    ...state,
    heatmapApi:{
      loading:false,
      data:true,
      error:false
    },
    heatmapData:{...state.heatmapData,...action.data}
  };
}

export function fetchHeatmapFailed(state, action) {
  return{
    ...state,
    heatmapApi:{
      loading:false,
      error:true
    }
  }
}