
export const UpdateTrendBenchMarkDataStart = (state) => {
    return {
        ...state,
        UpdateTrendBenchMarkDataApi: {
            isProcessing: true,
            data: null,
            error: false,
            message: ''
        }
    }

}

export const UpdateTrendBenchMarkDataSuccess = (state, payload) => {
    return {
        ...state,
        UpdateTrendBenchMarkDataApi: {
            isProcessing: false,
            data: payload.data,
            error: false,
            message: payload.message
        }
    }

}

export const UpdateTrendBenchMarkDataFailed = (state, payload) => {
    return {
        ...state,
        UpdateTrendBenchMarkDataApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}
