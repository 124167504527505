import * as actionTypes from '../../../constants';
import { ApiBase } from '../../../../utils/apiBase';
import {
    FETCH_QUESTIONSET_URL,
} from '../../../../../config/constants';
import {
    getValueFromQueryString
} from '../../../../utils/getValueFromUrl';
import constants from '../constants';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export function fetchQuestionSet(payload, callbackData) {
    return dispatch => {
        dispatch({ type: actionTypes.FETCH_ATTENT_SURVEY_DATA_START });
        const axios = new ApiBase().instance;
        axios.post(FETCH_QUESTIONSET_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(fetchSurveySuccess.bind(null, dispatch, callbackData))
            .catch(fetchSurveyFail.bind(null, dispatch));
    };
};

function fetchSurveySuccess(dispatch, callbackData, response) {
    return dispatch({
        type: actionTypes.FETCH_ATTENT_SURVEY_DATA_SUCCESS,
        response,
        callbackData
    });
};

function fetchSurveyFail(dispatch, err) {
    return dispatch({
        type: actionTypes.FETCH_ATTENT_SURVEY_DATA_FAIL,
        response: err
    });
};

// export function attendSurveyUpdateAnswer(newQuestionSet) {
//     return {
//         type: actionTypes.ATTENT_SURVEY_UPDATE_ANSWER,
//         newQuestionSet
//     };
// };

function getValues() {
    return {
        channelType: getValueFromQueryString('SCT'),
        userKey: getValueFromQueryString('UK'),
    };
}

export function getRequestBody(projectId) {

    const { channelType, userKey } = getValues();

    const response = {
        projectId,
        fetchMode: constants.fetchMode.answer,
        channelType
    };
    if (channelType === constants.channelTypes.email)
        if (userKey)
            response.userKey = userKey;
    if (!channelType)
        response.channelType = constants.channelTypes.anounymous;
    return response;
}

// export function submitAnswerSet(payload) {
//     let { URL, requestBody } = getSubmitRequestBody(payload);
//     if (payload.completed)
//         URL = SUBMIT_ANSWERSET_URL;

//     return (dispatch, getState) => {
//         const attendSurveyReducer = getState().AttendSurveyReducer;
//         requestBody.projectType = payload.projectType
//         requestBody.selectedLanguage = attendSurveyReducer.selectedLanguage
//         requestBody.translatedTexts = attendSurveyReducer.translatedTexts;
//         requestBody.isLocalizationEnabled = attendSurveyReducer.fetchProjectApi.data.isLocalizationEnabled;
//         requestBody.translateAboutSurvey = attendSurveyReducer.fetchProjectApi.data.translateAboutSurvey;
//         requestBody.organizationId = payload.orgId
//         requestBody.hrisFileId = payload.hrisFileId
//         dispatch({ type: actionTypes.SUBMIT_ANSWERSET_START });
//         const axios = new ApiBase().instance;
//         axios.post(URL, { ...requestBody, ...getProjectIdAndOrgId(requestBody) })
//             .then(updateSurveyBlockSuccess
//                 .bind(null, { dispatch, payload }))
//             .catch(updateSurveyBlockFail
//                 .bind(null, { dispatch, payload }));
//     };
// };


// export function updateSurveyBlock(payload) {
//     const { channelType } = getRequestBody();
//     // if(channelType===constants.channelTypes.anounymous)
//     //     payload.answerSet=[payload.answerSet[payload.index]];
//     return submitAnswerSet(payload);
// };


// function getSubmitRequestBody({ index, answerSet, completed }) {
//     let URL = SUBMIT_ANSWERSET_URL;
//     const { userKey, channelType, projectId } = getRequestBody();
//     if (channelType === constants.channelTypes.email)
//         URL = CREATE_ANSWERSET_URL;
//     const requestBody = {
//         organizationId: getOrgId(),
//         userKey,
//         projectId,
//         channelType,
//         answerSet,
//         attemptProgress: index + 1
//     };
//     return { URL, requestBody };
// };

// function updateSurveyBlockSuccess({ dispatch, payload }, response) {
//     const { index, completed } = payload;
//     dispatch({
//         type: actionTypes.SUBMIT_ANSWERSET_SUCCESS,
//         response,
//         completed
//     });
//     !completed &&
//         dispatch({ type: actionTypes.UPDATE_SURVEY_PROCESS_LIST, index });
// };

// function updateSurveyBlockFail({ dispatch, payload }, error) {
//     const { completed } = payload;
//     notification.error({
//         message: constants.submitAnswerFailureTitle,
//         description: constants.submitAnswerFailureMessage
//     });

//     dispatch({
//         type: actionTypes.SUBMIT_ANSWERSET_FAIL,
//         response: error,
//         completed
//     });
// };

// export function changeLanguage(payload) {
//     return {
//         type: actionTypes.CHANGE_SURVEY_LANGUAGE,
//         payload
//     }
// };

// export function translateTexts(params, cb) {
//     let translatedAboutSurvey;
//     if (params.translatedAboutSurvey) {
//         translatedAboutSurvey = params.translatedAboutSurvey
//         delete params.translatedAboutSurvey
//     }
//     return dispatch => {
//         dispatch({ type: actionTypes.TRANSLATE_START });
//         const axios = new ApiBase().instance;
//         axios.post(TRANSLATE_TEXT, { ...params })
//             .then(translateTextSuccess.bind(null, dispatch, cb, translatedAboutSurvey))
//             .catch(translateTextFailed.bind(null, dispatch, cb));
//     };
// }

// function translateTextSuccess(dispatch, cb, translatedAboutSurvey, res) {
//     dispatch({
//         type: actionTypes.TRANSLATE_SUCCESS,
//         payload: res.data
//     });
//     if (translatedAboutSurvey) cb && cb({ translatedData: { translatedDescription: translatedAboutSurvey } });
//     else cb && cb(res.data && res.data.data);
// };

// function translateTextFailed(dispatch, cb, error) {
//     dispatch({
//         type: actionTypes.TRANSLATE_FAIL,
//         error: error.response && error.response.data.message
//     });
//     cb && cb();
// };