import Settings from "./SettingsPage";
import { connect } from "react-redux";
import {
  changePasswordAction,
  changePasswordFormChange,
  clearChangePasswordForm,
  getUserProfile,
  changeFirstLastNameAction,
  changeFirstLastNameFormChange,
  onCancelNameChange,
  getOrganizationProfile,
  bulkUploadStart,
  getPreSignedURL,
  uploadFile,
} from "../../redux/actions";

import { uploadTopicLibrary } from "../../redux/actions/NLPAdminAction";

const mapStateToProps = (state) => {
  return {
    ...state.settingsPageReducer,
    ...state.AdminReducer,
    ...state.changeFirstLastNamePageReducer,
    passwordFormVaild: state.settingsPageReducer.isFormValid,
    nameFormValid: state.changeFirstLastNamePageReducer.isFormValid,
  };
};
const mapDispatchToProps = (dispatch) => ({
  changePasswordAction: (params) => dispatch(changePasswordAction(params)),
  changePasswordFormChange: (params) =>
    dispatch(changePasswordFormChange(params)),
  clearChangePasswordForm: (params) =>
    dispatch(clearChangePasswordForm(params)),
  getUserProfile: (params) => dispatch(getUserProfile(params)),
  changeFirstLastNameAction: (params, next) =>
    dispatch(changeFirstLastNameAction(params, next)),
  changeFirstLastNameFormChange: (params) =>
    dispatch(changeFirstLastNameFormChange(params)),
  onCancelNameChange: () => dispatch(onCancelNameChange()),
  getOrganizationProfile: (params) => dispatch(getOrganizationProfile(params)),
  
  bulkUploadStart: (params) => dispatch(bulkUploadStart(params)),
  getPreSignedURL: (params, next) => dispatch(getPreSignedURL(params, next)),
  uploadFile: (params, next) => dispatch(uploadFile(params, next)),

  uploadTopicLibrary: (file) => dispatch(uploadTopicLibrary(file)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
