import React from "react";
import { Redirect } from "react-router-dom";
import { Menu, Row, Col } from "antd";
import Logs from "../Logs";
import AddAdmin from "../AddAdmin";
import "./index.scss";
import ChangePassword from "./ChangePassword";
import NameChange from "./NameChange";
import TopicLibrary from "./TopicLibrary";
import Container from "../../Components/Container";
import {
  getOrgId,
} from "../../utils/localStorgeOperations";
import { getDataFromUrlToken } from "../../utils/urlDataStore";
import { URL_DATA } from "../../../config/urlDataConstants";
import qs from "query-string";
import { getSettingsPagePath } from "../../utils/getHomePath";
import { getOrgIdFromUrl } from "../../utils/urlProcessor";
import { getTabIdFromUrl } from "../../utils/urlDataStore";
import { checkAccessStatus, checkIfAdmin } from "../../utils/rolePermissionUtils";
import { SETTINGS_PERMISSIONS } from "../../../config/userPermissionConstants";
class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: checkIfAdmin() ? "topicLibrary" : "nameEmail",
      name: localStorage.getItem("firstName"),
      email: localStorage.getItem("username"),
      newPassword: "",
      confirm: "",
      password: "",
      isFormValid: true,
      isCheckPageRoute: false,
      isOrgSelected: false,
    };
  }
  componentDidMount() {

    this.setState({ isOrgSelected: this.checkIfOrgSelected() });
    const tabID = getTabIdFromUrl(URL_DATA.ORGANIZATION);
    if (tabID) {
      let tabName = this.getTabName(tabID);
      this.setState({ activeMenu: tabName });
    }

  }
  handleMenuClick = (e) => {
    this.setState({ activeMenu: e.key });
    const orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
    let tabId = this.getTabId(e.key);
    if (!orgDetails) {
      let url = `/admin/home/settings?tabId=${tabId}`;
      this.props.history.push(url);
    } else {

      const queryParams = qs.parseUrl(window.location.href).query;
      this.props.history.push({
        pathname: getSettingsPagePath(
          this.getOrgIdFromUrl(window.location.pathname) || getOrgId()
        ),
        search:
          queryParams && queryParams.params
            ? `params=${encodeURIComponent(queryParams.params)}&tabId=${tabId
            }`
            : undefined,
      });
    }
  };
  getOrgIdFromUrl(pathname) {
    let orgId = undefined;
    orgId = getOrgIdFromUrl();
    return orgId;
  }
  getRenderComponent = () => {
    if (this.state.activeMenu === "topicLibrary") {
      return this.getTopicLibraryComponent();
    } else if (this.state.activeMenu === "nameEmail") {
      return this.getNameEmailComponent();
    } else if (this.state.activeMenu === "password") {
      return this.getPasswordComponent();
    } else if (this.state.activeMenu === "administrators") {
      return this.getAdminComponent();
    } else if (this.state.activeMenu === "logs") {
      return this.getLogsComponent();
    } else if (this.state.activeMenu === "selectOrganization") {
      return <Redirect to="organizations" />;
    }
  };
  getTabId = (activeMenu) => {
    if (activeMenu === "topicLibrary") {
      return 1;
    } else if (activeMenu === "nameEmail") {
      return 2;
    } else if (activeMenu === "password") {
      return 3;
    } else if (activeMenu === "administrators") {
      return 4;
    } else if (activeMenu === "logs") {
      return 5;
    }
  };
  getTabName = (tabId) => {
    if (tabId === 1) {
      return "topicLibrary";
    } else if (tabId === 2) {
      return "nameEmail";
    } else if (tabId === 3) {
      return "password";
    } else if (tabId === 4) {
      return "administrators";
    } else if (tabId === 5) {
      return "logs";
    }
  };

  getTopicLibraryComponent = () => {
    return <TopicLibrary {...this.props} />;
  };
  getNameEmailComponent = () => {
    return <NameChange {...this.props} />;
  };
  getPasswordComponent = () => {
    return <ChangePassword {...this.props} />;
  };
  getAdminComponent = () => {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="administrator-wrapper">
            <div className="administrator-wrapper__header">
              <h2>Culturebie administrators</h2>
              <p>
                Culturebie administrators can manage all aspects of your
                Culturebie data, including adding and removing HRIS files,
                viewing all survey responses, and inviting more admins.
              </p>
              <p>
                To grant managers access to survey response data, use
                <a href={"organizations/" + getOrgId() + "/manageusers"}>
                  {""}the people tab.
                </a>
              </p>
            </div>
            <div className="administrator-wrapper__table">
              <AddAdmin />
            </div>
          </div>
        </Col>
      </Row>
    );
  };
  getLogsComponent = () => {
    return (
      <div>
        <Logs />
      </div>
    );
  };
  checkIfOrgSelected = () => {
    if (getOrgId()) {
      return true;
    } else {
      return false;
    }
  };

  showOrgMenu = (orgDetails) => {
    return checkAccessStatus(orgDetails.organizationId, SETTINGS_PERMISSIONS.VIEW_ADMINS) ||
      checkAccessStatus(orgDetails.organizationId, SETTINGS_PERMISSIONS.VIEW_LOGS)
  }


  render() {
    const orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
    return (
      <Container>
        <div className="settings-page">
          <div className="settings-page__head">
            <h1>Settings</h1>
          </div>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <aside className="nlp-sidebar">
                <div className="left-menu  setting-left-menu">
                  <div className="left-menu__item">
                    <Menu
                      selectedKeys={[this.state.activeMenu]}
                      mode="inline"
                      onClick={this.handleMenuClick}
                    >
                      <Menu.ItemGroup key="account" title="ACCOUNT">
                        {checkIfAdmin() && (
                          <Menu.Item key="topicLibrary">
                            Topic Library
                          </Menu.Item>
                        )}
                        <Menu.Item key="nameEmail">Name & Email</Menu.Item>
                        <Menu.Item key="password">Password</Menu.Item>
                      </Menu.ItemGroup>
                      {orgDetails && this.showOrgMenu(orgDetails) && (
                        <Menu.ItemGroup key="organization" title="ORGANIZATION">
                          {checkAccessStatus(orgDetails.organizationId, SETTINGS_PERMISSIONS.VIEW_ADMINS) &&
                            <Menu.Item key="administrators">
                              Administrators
                            </Menu.Item>}
                          {checkAccessStatus(orgDetails.organizationId, SETTINGS_PERMISSIONS.VIEW_LOGS) &&
                            <Menu.Item key="logs">Logs</Menu.Item>}
                        </Menu.ItemGroup>
                      )}
                      {!orgDetails && (
                        <Menu.ItemGroup key="organization" title="ORGANIZATION">
                          <Menu.Item key="selectOrganization">
                            Select organization
                          </Menu.Item>
                        </Menu.ItemGroup>
                      )}
                    </Menu>
                  </div>
                </div>
              </aside>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
              <div className="nlp-maincontent">{this.getRenderComponent()}</div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
export default SettingsPage;
