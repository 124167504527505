import {
    SET_SORT_ORDER_AND_SORT_FIELD
} from "../../../constants";


export const setSortOrderAndSortField = (payload, cb) => {
    return (dispatch) => {
        dispatch({
            type: SET_SORT_ORDER_AND_SORT_FIELD,
            data: payload
        });
    }
};