import { connect } from "react-redux";
import { SelectState } from "./Selector";
import PeopleList from "./PeopleList";
import {
  getOrganizationProfile,
  fetchHRIS,
  fetchHRISOnSearch,
  fetchUserCreationLogs,
  hrisHrisfileChange,
  hrisfileChange,
  hrisFileUploadFormChange,
  toggleBulkModal,
  handleFileUpload,
  bulkUploadStart,
  getPreSignedURL,
  uploadFile,
  addJob,
  onUsersHrisPaginationChange,
  renamePeople,
  deletePeople
} from "../../redux/actions";
import { bulkUploadEnd } from '../../redux/actions/UserUploadAction/userUpload';

const mapStateToProps = (state) => ({...state.PeopleListingReducer, ...SelectState(state) });

const mapDispatchToProps = (dispatch) => ({
 
  hrisHrisfileChange: (params) => dispatch(hrisHrisfileChange(params)),
  hrisfileChange: (params, name) => dispatch(hrisfileChange(params, name)),
  fetchUserCreationLogs: (params) => dispatch(fetchUserCreationLogs(params)),
  getOrganizationProfile: (params) => dispatch(getOrganizationProfile(params)),
  fetchHRIS: (params, hrisFileId) => dispatch(fetchHRIS(params, hrisFileId)),
  fetchHRISOnSearch: (params) => dispatch(fetchHRISOnSearch(params)),
  onUsersHrisPaginationChange: (params) =>
    dispatch(onUsersHrisPaginationChange(params)),
  handleFileUpload: (params) => {
    dispatch(handleFileUpload(params));
  },
  hrisFileUploadFormChange: (param) => {
    dispatch(hrisFileUploadFormChange(param));
  },
  toggleBulkModal: (params, cb) => dispatch(toggleBulkModal(params, cb)),
  bulkUploadStart: (params, next) => {
    dispatch(bulkUploadStart(params, next));
  },
  getPreSignedURL: (params, next) => {
    dispatch(getPreSignedURL(params, next));
  },
  uploadFile: (params, next) => {
    dispatch(uploadFile(params, next));
  },
  addJob: (params, next) => {
    dispatch(addJob(params, next));
  },
  renamePeople: (params,cb) => {
    dispatch(renamePeople(params,cb));
  },
  deletePeople: (params,cb) => {
    dispatch(deletePeople(params,cb));
  },
  bulkUploadEnd: (params) => { dispatch(bulkUploadEnd(params)) },
  
});

export default connect(mapStateToProps, mapDispatchToProps)(PeopleList);
