import {
    update,
    makeKey,
    getSuccessResponse
} from '../../../../utils/reducerOperations';

export function deleteActiveUserSuccess(state, payload) {
    return {
        deleteUserApi: update(state.deleteUserApi, {
            ...getSuccessResponse(payload),
            userId: payload.data.Attributes.userId
        }),
        fetchUsersApi: update(
            state.fetchUsersApi,
            makeKey(
                'data',
                update(state.fetchUsersApi.data, {
                    Items: filterDeletedUser(
                        state.fetchUsersApi.data.Items,
                        payload.data.Attributes.userId
                    )
                })
            )
        )
    };
}
function filterDeletedUser(Items, userId) {
    return Items.filter(user => user.userId !== userId);
}
