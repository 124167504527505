import { connect } from "react-redux";
import SummaryRating from "./SummaryRating";
import { handleClickNo, handleClickYes } from "../../../../../../redux/actions";



const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleClickYes: (payload) => dispatch((handleClickYes(payload))),
        handleClickNo: (payload) => dispatch((handleClickNo(payload)))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryRating);