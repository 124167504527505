import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const commitmentTemplate = [
    {
        questionDimension: 'Commitment',
        type: 'Matrix',
        questionLabel: 'Commitment',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: 'My organization values me as an individual.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My organization values me as an individual.')
        },
        {
            text: `I see my organization's success as my success.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`I see my organization's success as my success.`)

        },
        {
            text: `I haven't looked for other jobs in the last six months.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`I haven't looked for other jobs in the last six months.`)

        }]
    }
]

