import { deepCopy } from "../../../../../../utils/deepCopy"
import { v4 as uuidv4 } from "uuid";
export const handleDuplicateBlock = (state, payload) => {
    let updatedGoal = getDeletedGoals(state.goals, payload)
    return (
        {
            ...state,
            goals: updatedGoal
        }
    )
}

const getDeletedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    let duplicatedData = generateDuplicateData(payload)
    goalsCopy.splice(payload.index + 1, 0, duplicatedData)
    return goalsCopy
}

const generateDuplicateData = (duplicateData) => {
    let duplicateCopy = deepCopy(duplicateData)
    let duplicateQuestions = duplicateCopy.blockData.questions[0]
    duplicateQuestions.qid = uuidv4()
    if (duplicateCopy.blockData.type === "likert" || duplicateCopy.blockData.type === "matrix") {
        duplicateQuestions.matrixQuestions = duplicateQuestions.matrixQuestions.map((question) => {
            return {
                ...question,
                value: uuidv4()
            }
        })
    }
    else if (duplicateCopy.blockData.type === "multipleChoice" || duplicateCopy.blockData.type === "multipleOption") {
        duplicateQuestions.options = duplicateQuestions.options.map((question) => {
            return {
                ...question,
                value: uuidv4()
            }
        })
    }
    let updatedData = { ...duplicateData.blockData, blockId: uuidv4(), questions: [{ ...duplicateQuestions }] }
    return updatedData
}