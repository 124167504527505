import { decryptData } from './encryptionUtils'
import { ROLE_PERMISSION_SECRET, USER_ROLES } from '../../config/userConfigurations'
import { ClearLocalStorage } from './localStorgeOperations'

export const getOrgPermissions = (orgId) => {

}

export const userRoleDetailsForGivenOrg = (orgId) => {

}

export const getTabPermissionsForUser = (userId) => {

}

export const checkAccessStatus = (orgId, accessFeature, projectId) => {
  const decryptedRoles = decryptRoles()
  if (decryptedRoles && decryptedRoles.userRolePermission) {
    if (checkIfAdmin()) {
      return true
    }
    if (checkIfSuperAdminOrOrgAdmin(orgId)) {
      return checkOrgLevelPermission(accessFeature, orgId)
    } else if (projectId && orgId) {
      return checkProjectLevelPermission(accessFeature, projectId, orgId)
    } else if (orgId) {
      return checkOrgLevelPermission(accessFeature, orgId)
    } else {
      checkUserLevelPersmission(accessFeature)
    }
  } else {
    ClearLocalStorage();
    window.location.href = "/login";
  }

}

const checkProjectLevelPermission = (accessFeature, projectId, orgId) => {
  if (checkIfAdmin()) {
    return true
  }
  let flag = false
  const decryptedRoles = decryptRoles()
  if (decryptedRoles) {
    for (let item = 0; item < decryptedRoles.userRolePermission.orgLevel.length; item++) {
      if (decryptedRoles.userRolePermission.orgLevel[item].orgId === orgId) {
        for (let projectLevelCount = 0; projectLevelCount < decryptedRoles.userRolePermission.orgLevel[item].projectLevel.length; projectLevelCount++) {
          if (decryptedRoles.userRolePermission.orgLevel[item].projectLevel[projectLevelCount].projectId === projectId) {
            if (decryptedRoles.userRolePermission.orgLevel[item].projectLevel[projectLevelCount].featureAccess.includes(accessFeature)) {
              flag = true
            }
            else {
              flag = false
            }
          }
        }
      }
    }
  }
  return flag
}

const checkOrgLevelPermission = (accessFeature, orgId) => {
  if (checkIfAdmin()) {
    return true
  }
  const decryptedRoles = decryptRoles()
  if (decryptedRoles) {
    for (let item = 0; item < decryptedRoles.userRolePermission.orgLevel.length; item++) {
      if (decryptedRoles.userRolePermission.orgLevel[item].orgId === orgId) {
        if (decryptedRoles.userRolePermission.orgLevel[item].featureAccess) {
          return decryptedRoles.userRolePermission.orgLevel[item].featureAccess.includes(accessFeature)
        }
      }
    }
  }
  return false
}

const checkUserLevelPersmission = (accessFeature) => {
  if (checkIfAdmin()) {
    return true
  }
  let flag = false
  const decryptedRoles = decryptRoles()
  if (decryptedRoles && decryptedRoles.userRolePermission.userLevel) {
    for (let item = 0; item < decryptedRoles.userRolePermission.userLevel.length; item++) {
      if (decryptedRoles.userRolePermission.userLevel[item].featureAccess.includes(accessFeature)) {
        return true
      }
      else return false
    }
  }
  return flag
}

const decryptRoles = () => {
  const userRolePermissionsKey = localStorage.getItem('userRolePermissions')
  const decryptedRoles = decryptData(
    userRolePermissionsKey,
    ROLE_PERMISSION_SECRET)
  return { userRolePermission: decryptedRoles }
}

export const checkIfAdmin = () => {
  const decryptedRoles = decryptRoles()
  if (decryptedRoles &&
    decryptedRoles.userRolePermission &&
    decryptedRoles.userRolePermission.isAdmin) {
    return true
  }
  else return false
}

export const checkIfOrgAdmin = (orgId) => {
  if (checkIfAdmin()) {
    return false
  }
  let flag = false
  const decryptedRoles = decryptRoles()
  if (decryptedRoles && decryptedRoles.userRolePermission) {
    for (let item = 0; item < decryptedRoles.userRolePermission.orgLevel.length; item++) {
      if (decryptedRoles.userRolePermission.orgLevel[item].orgId === orgId) {
        if (decryptedRoles.userRolePermission.orgLevel[item].userRole === USER_ROLES.ORG_ADMIN) {
          return true
        }
        else return false
      }
    }
  }
  return flag
};

export const checkIfSuperAdmin = (orgId) => {
  if (checkIfAdmin()) {
    return false
  }
  let flag = false
  const decryptedRoles = decryptRoles()
  if (decryptedRoles && decryptedRoles.userRolePermission) {
    for (let item = 0; item < decryptedRoles.userRolePermission.orgLevel.length; item++) {
      if (decryptedRoles.userRolePermission.orgLevel[item].orgId === orgId) {
        if (decryptedRoles.userRolePermission.orgLevel[item].userRole === USER_ROLES.SUPER_ADMIN) {
          return true
        }
        else return false
      }
    }
  }
  return flag
}

export const checkManager = (orgId, projectId) => {
  if (checkIfAdmin() || checkIfSuperAdminOrOrgAdmin(orgId)) {
    return false
  }
  let flag = false
  const decryptedRoles = decryptRoles()
  if (decryptedRoles && decryptedRoles.userRolePermission) {
    for (let item = 0; item < decryptedRoles.userRolePermission.orgLevel.length; item++) {
      if (decryptedRoles.userRolePermission.orgLevel[item].orgId === orgId) {
        for (let projectLevelCount = 0; projectLevelCount < decryptedRoles.userRolePermission.orgLevel[item].projectLevel.length; projectLevelCount++) {
          if (decryptedRoles.userRolePermission.orgLevel[item].projectLevel[projectLevelCount].projectId === projectId) {
            if (decryptedRoles.userRolePermission.orgLevel[item].projectLevel[projectLevelCount].userRole === USER_ROLES.ORG_MANAGER) {
              flag = true
            }
            else { flag = false }
          }
        }
      }
    }
  }
  return flag
};

export const checkIfSuperAdminOrOrgAdmin = (orgId,) => {
  return checkIfSuperAdmin(orgId) || checkIfOrgAdmin(orgId)
};


export const checkRootManager = () => {

};

export const getUserRole = (orgId, projectId) => {
  if (checkIfAdmin()) {
    return USER_ROLES.ADMIN
  }
  const decryptedRoles = decryptRoles()
  let userRole = ''
  if (orgId && decryptedRoles && decryptedRoles.userRolePermission) {
    for (let item = 0; item < decryptedRoles.userRolePermission.orgLevel.length; item++) {
      if (decryptedRoles.userRolePermission.orgLevel[item].orgId === orgId) {
        userRole = decryptedRoles.userRolePermission.orgLevel[item].userRole
      }
    }
  }
  if (orgId && projectId && !checkIfSuperAdminOrOrgAdmin(orgId) && decryptedRoles.userRolePermission) {
    for (let item = 0; item < decryptedRoles.userRolePermission.orgLevel.length; item++) {
      if (decryptedRoles.userRolePermission.orgLevel[item].orgId === orgId) {
        for (let projectLevelCount = 0; projectLevelCount < decryptedRoles.userRolePermission.orgLevel[item].projectLevel.length; projectLevelCount++) {
          if (decryptedRoles.userRolePermission.orgLevel[item].projectLevel[projectLevelCount].projectId === projectId) {
            userRole = decryptedRoles.userRolePermission.orgLevel[item].projectLevel[projectLevelCount].userRole
          }
        }
      }
    }
  }
  return userRole
}

export const checkMoreThanSingleOrganization = () => {
  const decryptedRoles = decryptRoles()
  if (decryptedRoles.userRolePermission &&
    decryptedRoles.userRolePermission.orgLevel &&
    decryptedRoles.userRolePermission.orgLevel.length > 1) {
    return true
  }
  else return false
}

export const getSingleOrganizationId = () => {
  const decryptedRoles = decryptRoles()
  let orgId = ''
  if (decryptedRoles.userRolePermission &&
    decryptedRoles.userRolePermission.orgLevel) {
    decryptedRoles.userRolePermission.orgLevel.forEach((item) => {
      orgId = item.orgId
    })
  }
  return orgId
}

export const getViewerCorrespondManagerId = (orgId, projectId) => {
  let data = {
    managerIdFound: false,
    managerId: "",
    managerName: ""
  }
  if (getUserRole(orgId, projectId) === "Viewer") {
    let decryptedRoles = decryptRoles()
    for (let item = 0; item < decryptedRoles.userRolePermission.orgLevel.length; item++) {
      if (decryptedRoles.userRolePermission.orgLevel[item].orgId === orgId) {
        for (let projectLevelCount = 0; projectLevelCount < decryptedRoles.userRolePermission.orgLevel[item].projectLevel.length; projectLevelCount++) {
          if (decryptedRoles.userRolePermission.orgLevel[item].projectLevel[projectLevelCount].projectId === projectId) {
            let projectLevelData = decryptedRoles.userRolePermission.orgLevel[item].projectLevel[projectLevelCount]
            if (projectLevelData.managerId && projectLevelData.managerName) {
              data.managerIdFound = true
              data.managerId = projectLevelData.managerId
              data.managerName = projectLevelData.managerName
            }
          }
        }
      }
    }
  }
  return data
}

