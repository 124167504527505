import {getFacetFields} from './Utils/getFacetFields';
import {setSelectedFacets} from './Utils/setSelectedFacets';

export function facetChange(state, payload) {
    let facetObject = payload.hasOwnProperty('main')
        ? updateFacetObject([...state.facetObject], payload)
        : getFacetFields(state.filterAggregationApi.data);
    return {
        facetObject,
        selectedFacets: payload.hasOwnProperty('main')
            ? setSelectedFacets(facetObject)
            : []
    };
}
function updateFacetObject(facetObject, payload) {
    return facetObject.map(mainItem => {
        if (mainItem.main === payload.main) {
            mainItem[mainItem.main] = mainItem[
                mainItem.main
            ].map(subItem => {
                if (subItem.value === payload.value)
                    subItem.selected = !subItem.selected;
                return subItem;
            });
        }
        let selectedArray = mainItem[mainItem.main].filter(
            subItem => subItem.selected
        );
        if (selectedArray.length > 0) mainItem.selected = true;
        else mainItem.selected = false;
        return mainItem;
    });
}
