import {
  MANAGE_USERS_FETCH_USERS_STARTED,
  MANAGE_USERS_FETCH_USERS_SUCCESS,
  MANAGE_USERS_FETCH_USERS_FAILED,
} from "../../../constants";

import { ApiBase } from "../../../../utils/apiBase";
import { FETCH_USERS_URL } from "../../../../../config/constants";
import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const NewdashboardFetchUsers = (payload, cb) => {
  if (payload.sortField === 'status.keyword') {
    payload.sortStatus = true
    payload.sortField = ''
  }
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(MANAGE_USERS_FETCH_USERS_STARTED, {}));
    return apiBaseInstance
      .post(FETCH_USERS_URL, {
        ...payload,
        ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        cb && cb(true)
        dispatch(
          withType(MANAGE_USERS_FETCH_USERS_SUCCESS, {
            data: res.data,
          })
        );
      })
      .catch((err) => {
        cb && cb()
        dispatch(withType(MANAGE_USERS_FETCH_USERS_FAILED, err));
      });

  };
};
