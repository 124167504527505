import React from "react";
import constants from "./constants";
import ModalWindow from "../../Components/CsvUploadModal";
import helpers from "./helpers";
//import "../index.scss";

export const UploadHRISFile = (props) => {
  return (
    <div className="create-header">
      <div className="info-block">
        {constants.INFO}
      </div>
     
       <ModalWindow
          handleModalState={handleModalState}
          submitToUpload={submitToUpload}
          {...props}
        /> 
       {/* <FileUpload fileToUpload={props.fileToUpload} {...props} /> */}
      {/* {getInviteForm(props)}  */}
      
    </div>
  );
};
const submitToUpload = (cb, fileName) => {
  helpers.submitToUpload(this.props, {
    addJob: this.addJob,
    fileName: fileName,
    cb: cb,
  });
};
const handleModalState = (cb) =>{
  this.props.toggleBulkModal(false, cb);
}