import { FORM_VALIDATION_CONSTANTS } from "./regexConfigurations";
const formConfigurations = [
  {
    name: "name",
    type: "text",
    label: "Name",
    placeholder: "Name",
    isValid: true,
    rule: {
      condition: getRuleCondition("name"),
      emptyMessage: "Name cannot be empty!",
      errorMessage: "Please enter a valid name",
    },
    message: "",
    value: "",
  },
  {
    name: "firstName",
    type: "text",
    placeholder: "First Name",
    label: "First Name",
    isValid: true,
    rule: {
      condition: getRuleCondition("name"),
      errorMessage: "Please enter a valid name",
      emptyMessage: "First Name cannot be empty!",
    },
    message: "",
    value: "",
  },
  {
    name: "lastName",
    type: "text",
    placeholder: "Last name",
    label: "Last Name",
    isValid: true,
    // notRequired: true,
    rule: {
      condition: getRuleCondition("name"),
      errorMessage: "Please enter a valid name",
      emptyMessage: "Last Name cannot be empty!",
    },
    message: "",
    value: "",
  },
  {
    name: "adminFirstName",
    type: "text",
    placeholder: "First Name",
    label: "First Name",
    isValid: true,
    rule: {
      condition: getRuleCondition("name"),
      errorMessage: "Please enter a valid name",
      emptyMessage: "First Name cannot be empty!",
    },
    message: "",
    value: "",
  },
  {
    name: "adminLastName",
    type: "text",
    placeholder: "Last Name",
    label: "Last Name",
    isValid: false,
    // notRequired: true,
    rule: {
      condition: getRuleCondition("name"),
      errorMessage: "Please enter a valid name",
      emptyMessage: "Last Name cannot be empty!",
    },
    message: "",
    value: "",
  },
  {
    name: "username",
    type: "email",
    placeholder: "Email",
    label: "Email",
    isValid: true,
    message: "",
    rule: {
      condition: getRuleCondition("allEmail"),
      errorMessage: "Invalid email Id",
      emptyMessage: "Email cannot be empty",
    },
    value: "",
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    placeholder: "Password",
    isValid: true,
    message: "",
    rule: {
      condition: getRuleCondition("password"),
      errorMessage: "Invalid password",
      emptyMessage: "Password cannot be empty",
    },
    value: "",
  },
  {
    name: "organizationName",
    type: "text",
    placeholder: "Organization name",
    label: "Organization name",
    isValid: true,
    required: true,
    rule: {
      condition: getRuleCondition("organizationName"),
      errorMessage: "Invalid organization name",
      emptyMessage: "Organization name cannot be empty",
    },
    message: "",
    value: "",
  },
  {
    name: "verificationCode",
    type: "text",
    placeholder: "Enter code",
    label: "Verification Code",
    isValid: true,
    rule: {
      condition: getRuleCondition("verificationCode"),
      errorMessage: "Please enter valid code",
      emptyMessage: "verification field can't be empty!",
    },
    message: "",
    value: "",
  },
  {
    name: "newPassword",
    type: "password",
    label: "New Password",
    placeholder: "New password",
    rule: {
      condition: getRuleCondition("password"),
      errorMessage: "Invalid Password",
      emptyMessage: "Password cannot be empty!",
      confirmMessage: "Confirm password does not matches",
    },
    isValid: true,
    message: "",
    value: "",
    hint: 'Your password must be at least 8 characters long, contain at least 1 uppercase letter, 1 lowercase letter, 1 special character (@,#!...), and 1 number.'
  },
  {
    name: "confirm",
    type: "password",
    label: "Confirm Password",
    placeholder: "Confirm new password",
    rule: {
      condition: getRuleCondition("password"),
      errorMessage: "Confirm password does not matches",
      emptyMessage: "Confirm Password cannot be empty!",
    },
    isValid: true,
    message: "",
    value: "",
  },
  {
    name: "email",
    type: "email",
    placeholder: "Email",
    label: "Email",
    isValid: true,
    rule: {
      condition: getRuleCondition("email"),
      errorMessage: "Invalid Email",
      emptyMessage: "Email field can't be empty!",
    },
    message: "",
    value: "",
  },
  {
    name: "adminEmail",
    type: "email",
    placeholder: "Admin Email",
    label: "Admin Email",
    isValid: true,
    rule: {
      condition: getRuleCondition("email"),
      errorMessage: "Invalid Email",
      emptyMessage: "Email field can't be empty!",
    },
    message: "",
    value: "",
  },
  {
    name: "workEmail",
    type: "email",
    placeholder: "Work Email",
    isValid: true,
    rule: {
      condition: getRuleCondition("email"),
      errorMessage: "Invalid Email",
      emptyMessage: "Email field can't be empty!",
    },
    message: "",
    value: "",
  },
  {
    name: "phone",
    type: "number",
    placeholder: "Phone Number",
    isValid: true,
    rule: {
      condition: getRuleCondition("phoneNumber"),
      errorMessage: "Invalid Phone Number",
      emptyMessage: " Phone number field can't be empty!",
    },
    message: "",
    value: "",
  },
  {
    name: "surveyFile",
    type: "file",
    isValid: false,
    message: "",
    fileName: "",
    fileType: "doc/csv",
    file: {},
  },
  {
    name: "hrisFile",
    type: "file",
    isValid: false,
    message: "",
    fileName: "",
    fileType: "doc/csv",
    file: {},
    rule: {
      condition: getRuleCondition("template"),
      emptyMessage: "HRIS file can not be empty!",
      errorMessage: "Please add a valid hris file",
    },
  },
  {
    name: "hrisFileName",
    type: "text",
    label: "HRIS file name",
    placeholder: "HRIS file name",
    isValid: true,
    rule: {
      condition: getRuleCondition("name"),
      emptyMessage: "HRIS file name can not be empty!",
      errorMessage: "Please enter a valid HRIS file name",
    },
    message: "",
    value: "",
  },
  {
    name: "projectId",
    type: "text",
    isValid: false,
    message: "",
    value: "",
    placeholder: "Batch Id",
  },
  {
    name: "search",
    type: "text",
    placeholder: "Search by Email or Name",
    isValid: true,
    rule: {
      condition: getRuleCondition("username"),
      errorMessage: "",
      emptyMessage: "",
    },
    message: "",
    value: "",
  },
  {
    name: "tableSearch",
    type: "text",
    placeholder: "Search",
    isValid: true,
    rule: {
      condition: getRuleCondition("username"),
      errorMessage: "",
      emptyMessage: "",
    },
    message: "",
    value: "",
  },
  {
    name: "role",
    type: "text",
    placeholder: "Role Name",
    label: "Role",
    isValid: true,
    disabled: true,
    rule: {
      condition: getRuleCondition("name"),
      errorMessage: "Please enter a valid Role",
      emptyMessage: "Role cannot be empty!",
    },
    message: "",
    value: "Organization Admin",
  },
  {
    name: "templateName",
    type: "text",
    placeholder: "Email Subject",
    label: "Email Subject",
    isValid: true,
    rule: {
      condition: getRuleCondition("template"),
      errorMessage: "Please enter a valid name",
      emptyMessage: "Template name cannot be empty!",
    },
    message: "",
    value: "",
  },
  {
    name: "template",
    type: "text",
    placeholder: "Email Body Template",
    label: "Email Body Template",
    isValid: true,
    component: "textarea",
    rule: {
      condition: getRuleCondition("template"),
      errorMessage: "Please enter template",
      emptyMessage: "Template cannot be empty!",
    },
    message: "",
    value: "",
  },
  {
    name: "existingUserTemplate",
    type: "text",
    placeholder: "Choose existing user template",
    label: "Existing User Email Template",
    isValid: true,
    keyName: "",
    component: "select",
    rule: {
      condition: getRuleCondition("template"),
      errorMessage: "",
      emptyMessage: "",
    },
    message: "",
    value: "",
  },
  {
    name: "newUserTemplate",
    type: "text",
    placeholder: "Choose new user template",
    label: "New User Email Template",
    isValid: true,
    keyName: "",
    component: "select",
    rule: {
      condition: getRuleCondition("template"),
      errorMessage: "",
      emptyMessage: "",
    },
    message: "",
    value: "",
  },
  {
    name: "subject",
    type: "text",
    label: "Subject",
    placeholder: "Email Subject",
    isValid: false,
    rule: {
      condition: getRuleCondition("name"),
      emptyMessage: "Subject cannot be empty!",
      errorMessage: "Please enter a valid subject",
    },
    message: "",
    value: "",
  },
  {
    name: "message",
    type: "text",
    placeholder: "Message",
    label: "Message",
    isValid: false,
    component: "textarea",
    rule: {
      condition: getRuleCondition("template"),
      errorMessage: "Please enter a valid message",
      emptyMessage: "Message cannot be empty!",
    },
    message: "",
    value: "",
  },
  {
    name: "projectName",
    type: "text",
    placeholder: "Name your project",
    label: "",
    isValid: false,
    rule: {
      condition: getRuleCondition("template"),
      errorMessage: "Please enter a valid name",
      emptyMessage: "Project name cannot be empty!",
    },
    message: "",
    value: "",
  },
];

function getRuleCondition(type) {
  return FORM_VALIDATION_CONSTANTS[type];
}

export function fetchFormConfigurations(nameArray) {
  return formConfigurations.filter((configuration) =>
    nameArray.includes(configuration.name)
  );
}
