import { fetchFormConfigurations } from "../../inputFormConfigurations";
import { products } from "./configurations";
import { setInitialState } from "../../../utils/reducerOperations";

export function Default() {
  return {
    products,
    contactProcessApi: setInitialState(),
    contactFormApi: setInitialState(),
    isFormValid: false,
    inputBoxConfiguration: fetchFormConfigurations([
      "name",
      "username",
      "message",
    ]),
    popUpVisible: false,
    showContact: false,
    showTeam: false,
    showResources: false,
  };
}
