import React from "react";
import { Pagination } from "../../Components/Pagination";
import constants from "./constants";

const getLogsPagination = (props) => {
  const { data } = props.fetchApi;
  if (data && data.paginatedHRISLogData && data.paginatedHRISLogData.length) {
    return (
      <Pagination
        item={constants.LOGS}
        onChange={props.onChangePagination}
        count={props.fetchApi.data.count}
        page={props.pagination}
      />
    );
  }
};

export default {
  getPagination: getLogsPagination,
};
