import {
    getApiStartState,
    getSuccessResponse,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
import { notification } from 'antd';
import { PUBLISH_SURVEY_SUCCESS, SCHEDULE_SURVEY_SUCCESS } from './constants'

export function publishProjectStarted(state) {
    return {
        ...state,
        publishProjectApi: getApiStartState()
    };
};

export function publishProjectSuccess(state, action) {
    const { response, requestPayload } = action;
    showSuccessNotification(requestPayload);
    return {
        ...state,
        publishProjectApi: getSuccessResponse(response)
    };
};

export function publishProjectFail(state, { response }) {
    const res = response.response && response.response.data
        ? response.response.data
        : response;
    notification.error({ message: res.reason || res.message });
    return {
        ...state,
        publishProjectApi: response.response && response.response.data
            ? handleApiResponseError(res)
            : handleNetworkError(res)
    };
};

function showSuccessNotification(request) {
    if (request && request.surveyLaunchDate) {
        let launchDate = new Date(request.surveyLaunchDate);
        let currentDate = new Date();
        if (launchDate.getTime() > currentDate.getTime()) {
            notification.success({ message: SCHEDULE_SURVEY_SUCCESS });
        } else {
            notification.success({ message: PUBLISH_SURVEY_SUCCESS });
        }
    } else {
        notification.success({ message: PUBLISH_SURVEY_SUCCESS });
    }
}