import {
    FETCH_PROJECT_START,
    FETCH_PROJECT_FAIL,
    FETCH_PROJECT_SUCCESS,
    UPDATE_SURVEY_ANSWER,
    CHANGE_SURVEY_LANGUAGE_PREVIEW,
    TRANSLATE_PREVIEW_START,
    TRANSLATE_PREVIEW_SUCCESS,
    TRANSLATE_PREVIEW_FAIL
} from '../../constants';
import { Default } from './InitialState';
import {
    getProjectStart,
    getProjectSuccess,
    getProjectfail,
    updateSurveyAnswer,
    changeLanguage,
    translatePreviewStart,
    translatePreviewSuccesss,
    translatePreviewFailed
} from './helper';


const PreviewProjectReducer = (state = Default(), action) => {
    switch (action.type) {
        case UPDATE_SURVEY_ANSWER:
            return updateSurveyAnswer(state, action);
        case FETCH_PROJECT_START:
            return getProjectStart(state, action);
        case FETCH_PROJECT_SUCCESS:
            return getProjectSuccess(state, action);
        case FETCH_PROJECT_FAIL:
            return getProjectfail(state, action);
        case CHANGE_SURVEY_LANGUAGE_PREVIEW:
            return changeLanguage(state, action)
        case TRANSLATE_PREVIEW_START:
            return translatePreviewStart(state, action)
        case TRANSLATE_PREVIEW_SUCCESS:
            return translatePreviewSuccesss(state, action)
        case TRANSLATE_PREVIEW_FAIL:
            return translatePreviewFailed(state, action)
        default: return { ...state };
    }
};

export default PreviewProjectReducer;