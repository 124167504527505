import { connect } from 'react-redux';
import { Demographics } from './Demographics';
import {
  fetchDemographicsData,
  fetchDimensions,
  updateDemographicReducer,
  exploreFetchOutliersData,
} from '../../../../../../redux/actions';
import {withRouter} from 'react-router-dom';

const mapStateToProps = (state) => {
  return {
    ...state.ExploreDemographicsReducer,
    demographicsData: state.ExploreDemographicsReducer.data,
    demographicsApi: state.ExploreDemographicsReducer.api,
    demographicsColumns: state.ExploreDemographicsReducer.columns,
    demographicsSelectedColumns:
      state.ExploreDemographicsReducer.selectedColumns,
    dimensionsApi: state.ExploreDemographicsReducer.dimensionsApi,
    highestFavScore: state.ExploreDemographicsReducer.highestFavScore,
    highestNeutralScore: state.ExploreDemographicsReducer.highestNeutralScore,
    highestUnfavScore: state.ExploreDemographicsReducer.highestUnfavScore,
    topic: state.ExploreDemographicsReducer.topic,
    selectedQuestionId: state.ExploreDemographicsReducer.selectedQuestionId,
    exploreOutliersApi: state.ExploreDemographicsReducer.exploreOutliersApi,
    treeDataApi: state.DashBoardReducer.treeDataApi,
    surveyDetailsApi: state.DashBoardReducer.surveyDetailsApi,
    thresholdApi: state.DashBoardReducer.thresholdApi.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDemographicsData: (params) => dispatch(fetchDemographicsData(params)),
    fetchDimensions: (params, callBack) =>
      dispatch(fetchDimensions(params, callBack)),
    updateDemographicReducer: (params) =>
      dispatch(updateDemographicReducer(params)),
    exploreFetchOutliersData: (params) => dispatch(exploreFetchOutliersData(params)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Demographics));
