import { fetchPayload } from "../../../utils/reducerOperations";
import {
    FETCH_STORYBOARD_DRIVERS_SUCCESS,
    FETCH_STORYBOARD_DRIVERS_START,
    FETCH_STORYBOARD_DRIVERS_FAILED
} from "../../constants";
import {
    storyboardDriversFailed,
    storyboardDriversStarted,
    storyboardDriversSuccess
} from "./Helpers";
import { Default } from "./initialState";

const StoryboardDriversReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case FETCH_STORYBOARD_DRIVERS_START:
            return storyboardDriversStarted(state, payload);
        case FETCH_STORYBOARD_DRIVERS_SUCCESS:
            return storyboardDriversSuccess(state, payload);
        case FETCH_STORYBOARD_DRIVERS_FAILED:
            return storyboardDriversFailed(state, payload);
        default:
            return { ...state };
    }
};

export default StoryboardDriversReducer;
