import {
    SURVEY_EDITOR_LIKERT_OPTION_CHANGE,
    SURVEY_EDITOR_LIKERT_OPTION_CHANGE_LABEL
} from "../../../../../constants";


export const likertOptionChange = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_OPTION_CHANGE, payload });
    }
};

export const likertOptionChangeLabel = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_OPTION_CHANGE_LABEL, payload });
    }
};