import { fetchBatchIds } from "./getBatchIds";
import * as actionTypes from "../../constants";

export function updateFields({ event, index, target }) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_FIELDS,
      event: event ? event : target,
      index,
    });
  };
}

export function clearFormData() {
  return {
    type: actionTypes.CLEAR_FILES_DATA,
  };
}

export function clearTopicFile(){
  return{
    type: actionTypes.CLEAR_TOPIC_FILE,
  }
}

export { fetchBatchIds };

export { deleteBatchId } from "./deleteBatchId";

export { onDeleteSurveyResponse } from "./onDeleteSurveyResponse";

export { downloadSampleComments } from "./downloadSampleComments";

export { uploadTopicLibrary } from "./uploadTopicLibrary";
export {uploadHrisFile} from './uploadHrisFile'

export { uploadCommentFile, uploadReprocessFile } from "./uploadCommentFile";

export { processFiles, reProcessFiles } from "./processFiles";

export { approveJobRequest } from "./approveJobRequest";

export { downloadUserComments } from "./downloadUserComments";

export { batchApproveJobsRequest } from "./batchApprove";

export { batchDeleteJobsRequest } from "./batchDelete";

export { setBatchIds } from "./setBatchIds";

export { getCsvColumns } from "./getCsvColumns";

export { fetchHRISFile } from "./getHrisFile";
