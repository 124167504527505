import * as helper from "./helper";
import { update } from "../../../utils/reducerOperations";
import * as actionTypes from "../../constants";
import initialState from "./intialState";

const AdminSurveyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SURVEY_LIST_STARTED:
      return helper.getUserProjectsStart(state, action);
    case actionTypes.GET_SURVEY_LIST_SUCCESS:
      return helper.getUserProjectsSuccess(state, action);
    case actionTypes.GET_SURVEY_LIST_FAIL:
      return helper.getUserProjectsFail(state, action);
      case actionTypes.GET_PROJECT_DETAILS_STARTED:
      return helper.getProjectDetailsStart(state, action);
    case actionTypes.GET_PROJECT_DETAILS_SUCCESS:
      return helper.getProjectDetailsSuccess(state, action);
    case actionTypes.GET_PROJECT_DETAILS_FAIL:
      return helper.getProjectDetailsFail(state, action);
    case actionTypes.SURVEY_SEARCH_CHANGE:
      return update(state, helper.onSurveySearchChange(state, action.payload));
    case actionTypes.CLEAR_SURVEY_SEARCH:
      return helper.clearSurveySearch(state, action);
    case actionTypes.ON_SEARCH:
        return helper.onSearch(state, action);
    default:
      return state;
  }
};

export default AdminSurveyListReducer;
