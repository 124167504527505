import { connect } from "react-redux";
import {
  fetchOrganizations,
  deleteOrganization,
  getOrganizationProfile,
  renameOrg,
  formInputChange
} from "../../redux/actions";
import { SelectState } from "./Selector";
import HomePage from "./HomePage";
import "./index.scss";

const mapStateToProps = (state) => ({ ...SelectState(state) });

const mapDispatchToProps = (dispatch) => ({
  formInputChange: (event) => dispatch(formInputChange(event)),
  fetchOrganizations: (params) => dispatch(fetchOrganizations(params)),
  deleteOrganization: (params, callback) => dispatch(deleteOrganization(params, callback)),
  getOrganizationProfile: (params) => dispatch(getOrganizationProfile(params)),
  renameOrg: (payload, callback) => dispatch(renameOrg(payload, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
