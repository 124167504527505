import {
  MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_STARTED,
  MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_SUCCESS,
  MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_FAILED,
} from "../../../constants";
import { notification } from "antd";
import { USER_COMMENTS_URL } from "../../../../../config/constants";
import { NLPCommonReqBodies } from "../../../../utils/actionOperations";
import { ApiBase } from "../../../../utils/apiBase";

export function NewdashboardDownloadUserComments(payload,cb) {
  let projectId = payload.projectId;
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch({ type:MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_STARTED });
    apiBaseInstance
      .post(USER_COMMENTS_URL, {
        ...payload,
        ...NLPCommonReqBodies(),
        projectId: projectId,
      })
      .then((response) =>
      NewdashboardDownloadUserCommentsSuccess(dispatch, {
          response,
          cb
        })
      )
      .catch((e) => NewdashboardDownloadUserCommentsFailed(dispatch, e,cb));
  };
}

function NewdashboardDownloadUserCommentsSuccess(
  dispatch,
  { response,cb}
) {
  const fileUrl = response.data.data.fileURL;
  const a = document.createElement("a");
  a.href = fileUrl;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  cb && cb();
  dispatch({
    type:MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_SUCCESS,
  });
}

function NewdashboardDownloadUserCommentsFailed(dispatch, error,cb) {
  notification.error({
    message: "Downloading failed",
    description: error.response && error.response.data,
  });

  dispatch({
    type:MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_FAILED,
  });
  cb&&cb()
}
