export const needForChangeTemplate = [
    {
        questionDimension: 'Need for Change',
        type: 'Matrix',
        questionLabel: 'Need for Change',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: `The current ERP makes my job more difficult than it should be.`, value: '', isSelected: false
        },
        {
            text: `I think the ERP project is necessary for the success of BMC.`, value: '', isSelected: false

        }]
    }
]

