import { SET_DASHBOARD_TABS } from "../../constants"


export const setDashboardTabs = (payload) => {
    return (
        {
            type: SET_DASHBOARD_TABS,
            payload,
        }
    )
}
