import {
    getSuccessResponse,
    handleApiResponseError,
    handleNetworkError

} from '../../../../utils/reducerOperations';
import { notification } from 'antd';

export function saveProjectSuccess(state, action) {
    // notification.success({message:'saved'});
    return {
        ...state,
        saveProjectApi: getSuccessResponse(action.response)
    };
};

export function saveProjectStart(state, action) {
    return {
        ...state,
        saveProjectApi: {
            ...state.saveProjectApi,
            isProcessing: true,
            data: null,
            message: null,
            error: null
        }
    };
};

export function saveProjectFail(state, { response }) {
    let payload = response;
    let api = handleNetworkError;
    if (response && response.response && response.response.data) {
        api = handleApiResponseError;
        payload = response.response.data;
    }
    notification.error({ message: payload.message });
    return {
        ...state,
        saveProjectApi: api(payload)
    };
};