export default {
  CANCEL: "Cancel",
  local: {
    firstName: "firstName",
    userId: "userId",
    lastName: "lastName",
  },
  title: "Organization Settings",
  subTitle: "Organization name",
  footer: {
    classes: ["loader-btn"],
    loaderClass: ["sm", "white"],
    text: "Submit",
  },
  ORGANIZATION_ROUTE: "/admin/home/organizations",
  navigationList: ["home", "organization", "addOrganization"],
  clearForm: {
    event: {
      target: {
        value: "",
      },
    },
    index: 0,
  },
};
