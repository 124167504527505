import { deepCopy } from "../../../../../../utils/deepCopy"
import { generateStaticQuestionId } from "../../../../../../utils/goalsUtils"
import { getHTMLRemovedQuestion } from "../helpers"

export const rankingSectionChangeQuestionAndQuestionLabel = (state, payload) => {
    let updatedGoals = getRankingQuestionQuestionANdQuestionLabelChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getRankingQuestionQuestionANdQuestionLabelChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.questionLabelSection) {
                element.questions[0].questionLabel = payload.value
            } else if (payload.optionSection) {
                element.questions[0].options[payload.optionIndex].label = payload.value
            } else {
                element.questions[0].question = getHTMLRemovedQuestion(payload.value)
                element.questions[0].HTMLQuestion = payload.value
                element.questions[0].staticQuestionId = generateStaticQuestionId(getHTMLRemovedQuestion(payload.value))
            }
        }
    });
    return goalsCopy
}

export const rankingSectionDragAndDrop = (state, payload) => {
    let updatedGoals = getRankingQuestionDragANdDropChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getRankingQuestionDragANdDropChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            let sourceElement = element.questions[0].options[payload.source];
            let targetElement = element.questions[0].options[payload.target]
            element.questions[0].options[payload.source] = targetElement
            element.questions[0].options[payload.target] = sourceElement
        }
    });
    return goalsCopy
}

export const rankingSectionAddOrDeleteOption = (state, payload) => {
    let updatedGoals = getRankingQuestionAddOrDeletedOptiosnGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getRankingQuestionAddOrDeletedOptiosnGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.delete) {
                element.questions[0].options.splice(payload.removeIndex, 1)
            } else {
                element.questions[0].options.splice(element.questions[0].options.length, 0, payload.data)
            }
        }
    });
    return goalsCopy
}