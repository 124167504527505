
export const createProjectStart = (state) => {
    return {
        ...state,
        createProjectApi: {
            isProcessing: true,
            data: null,
            error: null,
            message: null,
        },
    };
}

export const createProjectSuccess = (state, action) => {
    let payload = action.response
    return {
        ...state,
        createProjectApi: {
            isProcessing: false,
            data: payload.data,
            error: !payload.status,
            message: payload.message,
        }
    };
}

export const createProjectFail = (state, action) => {
    return {
        ...state,
        createProjectApi: {
            isProcessing: false,
            data: null,
            error: true,
            message: null,
        },
    };
}