import React from 'react';
import Icon from '@ant-design/icons';
import constants from '../constants';
import { ButtonElement } from "../../../../../../../FormElements/ButtonElement";
import { TextInput } from "../../../../../../../FormElements/TextInput/";
export function getColumnSearchProps(dataIndex, disable) {
    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm,
            clearFilters }) => (
            <div style={{ padding: 8 }}>
                {searchField.bind(this)({
                    setSelectedKeys, selectedKeys, confirm, dataIndex
                })}
                {searchActionButton.bind(this)({
                    selectedKeys, confirm, dataIndex, clearFilters, disable
                })}
            </div>),
        filterIcon: () => <Icon type='search' />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase()
                .includes(value.toLowerCase()),
    };
}

function searchActionButton({ selectedKeys, confirm,
    dataIndex, clearFilters, disable }) {
    const { reset, search } = constants.searchInTable;
    return <span>
        <ButtonElement
            disabled={disable}
            type={search.btnType}
            onClick={() => handleSearch.bind(this)({
                disable,
                selectedKeys,
                confirm, dataIndex
            })}
            size={search.size}
            style={{ width: 90, marginRight: 8 }}
            children={search.text} />
        <ButtonElement
            onClick={() => handleReset.bind(this, disable)(clearFilters)}
            size={reset.size} style={{ width: 90 }}
            children={reset.text} />
    </span>;
}

function searchField({ setSelectedKeys, selectedKeys,
    confirm, dataIndex }) {
    return <TextInput
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        handleChange={e => setSelectedKeys(e.target.value ?
            [e.target.value] : [])}
        // onPressEnter={() => handleSearch.bind(this)({
        //     selectedKeys, confirm, dataIndex
        // })}
        style={constants.searchInTable.style} />;
}

function handleSearch({ selectedKeys, confirm, dataIndex, disable }) {
    confirm();
    if (!disable) {
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    }
};

function handleReset(clearFilters, disable) {
    if (!disable) {
        clearFilters();
        this.setState({ searchText: '' });
    }
};