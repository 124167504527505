import {
    EDIT_BENCHMARK_DATA,
} from "../../../constants";
import { withType } from "../../../../utils/actionOperations";

export const editBenchMarkData = (payload) => {
    return (dispatch) => {
        dispatch(withType(EDIT_BENCHMARK_DATA, payload));
    }
};
