import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const adaptability = {
    "blockId": uuidv4(),
    "description": "<p>In this section, please consider the following behaviors focused on areas such as: Learning Agility, Flexibility, and Resilience.</p>",
    "goalId": "dynamic180Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Rarely  ",
                    "value": "1"
                },
                {
                    "label": "Not Often Enough",
                    "value": "2"
                },
                {
                    "label": "Often ",
                    "value": "3"
                },
                {
                    "label": "Almost Always  ",
                    "value": "4"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "staticQuestionId": generateStaticQuestionId("Handles stress well and stays focused even when under pressure"),
                    "HTMLText": "<p>Handles stress well and stays focused even when under pressure.</p>",
                    "isSelected": true,
                    "text": "Handles stress well and stays focused even when under pressure.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Admits mistakes and learns from feedback and different points of view"),
                    "HTMLText": "<p>Admits mistakes and learns from feedback and different points of view.</p>",
                    "isSelected": true,
                    "text": "Admits mistakes and learns from feedback and different points of view.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Adapts to new challenges or unfamiliar situations"),
                    "HTMLText": "<p>Adapts to new challenges or unfamiliar situations.</p>",
                    "isSelected": true,
                    "text": "Adapts to new challenges or unfamiliar situations.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Easily grasps new concepts and applies them effectively in different situations"),
                    "HTMLText": "<p>Easily grasps new concepts and applies them effectively in different situations.</p>",
                    "isSelected": true,
                    "text": "Easily grasps new concepts and applies them effectively in different situations.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Explores new approaches or experiments with different solutions when faced with unfamiliar tasks or challenges"),
                    "HTMLText": "<p>Explores new approaches or experiments with different solutions when faced with unfamiliar tasks or challenges.</p>",
                    "isSelected": true,
                    "text": "Explores new approaches or experiments with different solutions when faced with unfamiliar tasks or challenges.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Deals well with setbacks and seeks support when needed"),
                    "HTMLText": "<p>Deals well with setbacks and seeks support when needed.</p>",
                    "isSelected": true,
                    "text": "Deals well with setbacks and seeks support when needed.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Adapts approach and style when working with different backgrounds and personalities"),
                    "HTMLText": "<p>Adapts approach and style when working with different backgrounds and personalities.</p>",
                    "isSelected": true,
                    "text": "Adapts approach and style when working with different backgrounds and personalities.",
                    "value": uuidv4()
                },
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Adaptability"
}