import {
  NEW_FORGOT_PASSWORD_PROCESS_STARTED,
  NEW_FORGOT_PASSWORD_PROCESS_SUCCESS,
  NEW_FORGOT_PASSWORD_PROCESS_FAILED,
} from "../../constants";
import { FORGOT_PASSWORD_URL } from "../../../../config/constants";
import { ApiBase } from "../../../utils/apiBase";
import { withType } from "../../../utils/actionOperations";

export const newForgotPasswordAction = (params, cb) => {
  params.url = window.location.origin
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(NEW_FORGOT_PASSWORD_PROCESS_STARTED, params));
    apiBaseInstance.instance
      .post(FORGOT_PASSWORD_URL, getForgotParams(params))
      .then((res) => {
        dispatch(withType(NEW_FORGOT_PASSWORD_PROCESS_SUCCESS, res.data));
        cb && cb(true);
      })
      .catch((err) => {
        dispatch(withType(NEW_FORGOT_PASSWORD_PROCESS_FAILED, err));
        cb && cb(false);
      });
  };
};

const getForgotParams = (params) => {
  return {
    ...params,
    username: params.username.toLowerCase(),
  };
};
