import React from "react";
import ContentLoader from "react-content-loader";
import constants from "./constants";

const Loader = (props) => {
  return (
    <ContentLoader
      height={30}
      width={1060}
      speed={2}
      gradientRatio={1.2}
      animate={true}
      primaryColor="#d9d9d9"
      secondaryColor="#ecebeb"
    >
      <rect x="30" y="13" rx="6" ry="6" width="20" height="20" />
      {contentByCondition(props.flag)}
      <rect x="0" y="39" rx="6" ry="6" width="1060" height="10" />
    </ContentLoader>
  );
};

const contentByCondition = (flag) => {
  let content = constants.defaultLoader;
  if (flag) content = constants.spreadAnalysisLoader;
  return content.map((item, key) => getContent(item, key));
};

const getContent = (value = {}, key) => {
  const random = Math.random() * (1 - 0.7) + 0.7;
  const { x, y, rx, ry, width, height } = constants.defaultValues;
  return (
    <rect
      key={key}
      x={value.x || x}
      y={value.y || y}
      rx={value.rx || rx}
      ry={value.ry || ry}
      width={(value.width || width) * random}
      height={value.height || height}
    />
  );
};

const TableLoader = (props) =>
  Array(8)
    .fill(null)
    .map((_, index) => (
      <Loader
        key={index}
        style={{ opacity: Number(2 / index).toFixed(1) }}
        flag={props.flag}
      />
    ));

export default TableLoader;
