
export const fetchProjectDetailsStart = (state, payload) => {
    return {
        ...state,
        projectDetailsApi: {
            isProcessing: true,
            error: false,
            data: false,
            message : '',
            reminderData:[],
            hriesData:{}
        }
    };
}

export const fetchProjectDetailsSuccess = (state, payload) => {
    return {
        ...state,
        projectDetailsApi: {
            isProcessing: false,
            data: payload.data.response.projectDetails.jobData[0]._source,
            reminderData:payload.data.response.projectDetails.remainderData,
            hrisData:payload.data.response.projectDetails.hrisData?payload.data.response.projectDetails.hrisData:{},
            error:false,
            message:payload.message
        },
    };
}

export const fetchProjectDetailsFail = (state, payload) => {
    return {
        ...state,
        projectDetailsApi: {
            isProcessing: false,
            error: true,
            data : false,
            message:payload.message ,
            reminderData:[],
            hriesData:{}
        },
    };
};