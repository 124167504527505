export const fetchExploreQuestionsStarted = (state, payload) => {
    return {
        ...state,
        exploreQuestionsApi: {
            isProcessing: true,
            error: false,
            reason: '',
        },
        exploreQuestions: { dimensionTable: [], itemTable: [] },
    };
};

export const fetchExploreQuestionsSuccess = (state, payload) => {
    return {
        ...state,
        exploreQuestionsApi: {
            isProcessing: false,
            error: false,
            reason: '',
        },
        exploreQuestions: payload.data,
        isDataFetched: true,
        filters : payload.filters,
        byScore: payload.byScore,
        multipleSelect: payload.multipleSelect,
        selectedUser : payload.selectedUser,
        directReporteesOnly: payload.directReporteesOnly
    };
};

export const fetchExploreQuestionsFailed = (state, payload) => {
    return {
        ...state,
        exploreQuestionsApi: {
            isProcessing: false,
            error: true,
            reason: payload.error,
        },
        exploreQuestions: { dimensionTable: [], itemTable: [] },
    };
};

export const setIsApiCalledExploreQuestions = (state, payload) => {
    return {
        ...state,
        isDataFetched: false,
        isTrendInsightsAlreadyFetched:false,

    }

}


export const getTrendDataForTableStart = (state) => {
    return {
        ...state,
        getTrendProjectsApi: {
            ...state.getTrendProjectsApi,
            isProcessing: true,
            data: null,
            error: false,
            message: ''
        }
    }

}

export const getTrendDataForTableSuccess = (state, payload) => {
    let surveyDetails = payload.data && payload.data.data && payload.data.data.response && payload.data.data.response.surveys
    return {
        ...state,
        isTrendInsightsAlreadyFetched:true,
        getTrendProjectsApi: {
            isProcessing: false,
            data: surveyDetails,
            error: false,
            message: ''
        },
    }

}

export const getTrendDataForTableFailed = (state, payload) => {
    return {
        ...state,
        getTrendProjectsApi: {
            ...state.getTrendProjectsApi,
            isProcessing: false,
            data: null,
            error: true,
            message: ''
        }
    }

}
