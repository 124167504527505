import {
  REVOKE_USERS_FAIL,
  REVOKE_USERS_START,
  REVOKE_USERS_SUCCESS
} from "../../../constants";

import { ApiBase } from "../../../../utils/apiBase";
import { REVOKE_USER_ACCESS } from "../../../../../config/constants";
import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const NewdashboardRevokeAccess = (payload, cb) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(REVOKE_USERS_START, {}));
    return apiBaseInstance
      .post(REVOKE_USER_ACCESS, {
        ...payload,
        ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        dispatch(
          withType(REVOKE_USERS_SUCCESS, {
            data: res.data,
          })
        );
        cb && cb(true);
      })
      .catch((err) => {
        cb && cb(false);
        dispatch(withType(REVOKE_USERS_FAIL, err));
      });
  };
};