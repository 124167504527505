export const exploreQuestionCommentsStart = (state, payload) => {
  return {
    ...state,
    [payload.questionId]:{
      ...state[payload.questionId],
    exploreQuestionCommentsApi: {
      data: [],
      totalComments: 0,
      isProcessing: true,
      error: null,
      message: null,
    },
  }
  };
};

export const exploreQuestionCommentsSuccess = (state, payload) => {
  const data = getCommentsData(payload);
  return {
    ...state,
    [payload.questionId]: {
      ...state[payload.questionId],
      exploreQuestionCommentsApi: {
        data: data,
        totalComments: payload.data.data.totalNumberOfDocuments,
        isProcessing: false,
        error: false,
        message: null,
      },
      isDataAlreadyFetched: true,
      filters: payload.filters,
      byScore: payload.byScore,
      multipleSelect: payload.multipleSelect,
      selectedUser: payload.selectedUser,
      directReporteesOnly: payload.directReporteesOnly,
    },
  };
};

export const exploreQuestionCommentsFailed = (state, payload) => {
  return {
    ...state,
    [payload.questionId]:{
      ...state[payload.questionId],
    exploreQuestionCommentsApi: {
      isProcessing: false,
      data: [],
      error: payload.error,
    },
  }
  };
};

const getCommentsData = (payload) => {
  let totalNumberOfDocuments = payload.data.data.totalNumberOfDocuments;
  let object =
    payload.data.data.filters &&
    payload.data.data.filters.topic &&
    payload.data.data.filters.topic;
  let comments = [];
  for (const [key, value] of Object.entries(object)) {
    comments.push({ value: key, label: key, count: value });
  }
  comments.sort((first, second) => first.label.localeCompare(second.label));
  comments.splice(0, 0, {
    value: "All Themes",
    label: "All Themes",
    count: totalNumberOfDocuments,
  });
  return comments;
};
