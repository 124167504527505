import React from "react";
import { compareByAlpha } from "../../../../../../../../utils/compareByAlpha";
import SVGIcons from "../../../../../../../../Components/SVGIcons";
import { QuestionBarchart } from "../Barchart";
import { Tooltip } from "antd";
import parse from "html-react-parser";
import { getBenchmarkTooltipTitle } from '../../../../../../../../utils/benchmarkUtils';

export default {
  tableColumnPositiveOutLiers: (props) => [
    {
      title: "Topics",
      key: "dimension",
      dataIndex: "dimension",
      width: 150,
      sorter: (b, a) => compareByAlpha(a.dimension, b.dimension),
    },
    {
      title: "Questions",
      key: "item",
      dataIndex: "item",
      width: 400,
      showSorterTooltip: false,
      render: (str, record) => (
        <>
          {/* <Tooltip placement="topLeft" title={str}> */}
          {parse(str)}
          {/* </Tooltip> */}
        </>
      ),
      sorter: (b, a) => compareByAlpha(a.item, b.item),
    },
    {
      title: "Score",
      key: "results",
      width: 230,
      dataIndex: ["favScore", "unFavScore", "neutralScore"],
      sorter: (b, a) => compareByAlpha(a.favScore, b.favScore),
      render: (item, record) =>
        record.validCount >= props.threshold ? (
          <QuestionBarchart setMinWidth={true} data={[record]} />
        ) : (
          "Insufficient data!"
        ),
    },

    ...props.showBenchMark && !props.allBenchMarkScoreEmpty ? [{
      title: <Tooltip title={getBenchmarkTooltipTitle(props.benchmarkTooltip)}>Benchmark</Tooltip>,
      dataIndex: "benchmarkScore",
      key: "benchmarkScore",
      align: "center",
      width: 80,
      sorter: (b, a) => compareBenchMark(a, b),
      render: (item, record) => (
        record.validCount >= props.threshold ?
          <div className="table-arrows table-count row-content">
            {record.benchmarkScore ?
              getSignedValue(record)
              : "-"}
          </div> : <div className="table-arrows  row-content">-</div>
      ),
    },] : [],

    {
      title: "Average",
      key: "average",
      dataIndex: "average",
      width: 50,
      align: "center",
      showSorterTooltip: false,
      render: (str, record) => (
        <>
          {/* <Tooltip placement="topLeft" title={str}> */}

          <span className="table-count row-content">{str.toFixed(2)}</span>
          {/* </Tooltip> */}
        </>
      ),
      sorter: (b, a) => compareByAlpha(a.average, b.average),
    },
    {
      dataIndex: "validCount",
      title: "Responses",
      align: "center",
      key: "validCount",
      width: 50,
      sorter: (b, a) => compareByAlpha(a.validCount, b.validCount),
      render: (item, record) => <span className="table-count row-content">{item}</span>,
    },
  ],
};

const getSignedValue = (record) => {
  let value = record.favScore - record.benchmarkScore
  if (value < 0) {
    return <span className="down-arrow">
      <SVGIcons type={"SVG-thick-downarrow"} />
      {Math.abs(value)}
    </span>
  } else if (value > 0) {
    return <span className="up-arrow">
      <SVGIcons type={"SVG-thick-uparrow"} />
      {value}
    </span>
  } else {
    return <span>
      0
    </span>
  }
}

const compareBenchMark = (a, b) => {
  let valueA = a.benchmarkScore ? a.favScore - a.benchmarkScore : 0
  let valueB = b.benchmarkScore ? b.favScore - b.benchmarkScore : 0
  let result = compareByBenchmark(valueA, valueB)
  return result
}

export const compareByBenchmark = (a, b) => {
  if (a > b) return -1;
  else if (a < b) return 1;
  else return 0;
};

const compareTrendScore = ({ previous, next, projectId }) => {
  let prev = previous[projectId] && previous[projectId].score ? previous[projectId].score : -10000
  let nextData = next[projectId] && next[projectId].score ? next[projectId].score : -10000
  let sorter = compareByAlpha(prev, nextData)
  return sorter


}

export const addTrendColomn = (trendData, props) => {
  let trendColomn = []
  trendData.forEach(matchedSurvey => {
    trendColomn.push({
      title: matchedSurvey.projectName,
      dataIndex: matchedSurvey.projectId,
      align: "center",
      key: matchedSurvey.projectId,
      width: 100,
      sorter: (b, a) => compareTrendScore({ previous: a, next: b, projectId: matchedSurvey.projectId }),
      render: (item, record) => {
        return (
          <>
            {record.validCount >= props.threshold ?
              <div className="table-arrows row-content">
                <span>
                  {item && !isNaN(item && item.score) && item.score > 0 && !props.showActualScore ? (
                    <span className="up-arrow">
                      <SVGIcons type={"SVG-thick-uparrow"} />
                    </span>
                  ) : (
                    ""
                  )}
                  {item && !isNaN(item.score) && item.score < 0 && !props.showActualScore ? (
                    <span className="down-arrow">
                      <SVGIcons type={"SVG-thick-downarrow"} />
                    </span>
                  ) : (
                    ""
                  )}{" "}
                </span>{" "}
                {!item || isNaN(item.score) ? "-" : <span className={getClassName(item, props)}>{Math.abs(item.score)}</span>}
              </div> : <div className="table-arrows row-content">-</div>
            }
          </>
        )
      }

    })
  });
  return trendColomn
}

const getClassName = (item, props) => {
  if (props.showActualScore) {
    if (Math.abs(item.score) === 0) {
      return "table-count row-content"
    } else if (item.negative) {
      return "trend-actual-red table-count row-content"
    } else {
      return "trend-actual-green table-count row-content"
    }
  } else {
    return "table-count row-content"
  }
}

export const ADDTIONAL_SCORE_COLOUMNS = ["Favourable", "Neutral", "Unfavourable"]
export const KEYS_FOR_DOWNLOADING = {
  "Average": "average",
  "Favourable": "favScore",
  "Neutral": "neutralScore",
  "Questions": "item",
  "Responses": "validCount",
  "Topics": "dimension",
  "Unfavourable": "unFavScore"
}

export const ADDED_COLOUMN_DOWNLOAD = [{
  title: "Favourable Score",
  dataIndex: "favScore",
  key: "favScore",
}, {
  title: "Neutral Score",
  dataIndex: "neutralScore",
  key: "neutralScore",
},
{
  title: "Unfavourable Score",
  dataIndex: "unFavScore",
  key: "unFavScore",
},
]
