import { connect } from "react-redux";
import { Drivers } from "./Drivers";
import {withRouter} from 'react-router-dom';
import { fetchExploreDriversData ,setExploreDriverFilters,
  fetchDriverDimensions,setSelectedTopic } from '../../../../../../redux/actions';
const mapStateToProps = (state) => {
  return { ...state.FetchExploreDriversDataReducer,
     fetchOutliersDataApi:state.DashBoardReducer.fetchOutliersDataApi,
     surveyDetailsApi: state.DashBoardReducer.surveyDetailsApi
   };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedTopic: (params) => dispatch(setSelectedTopic(params)),
    fetchDriverDimensions : (params) => dispatch(fetchDriverDimensions(params)),
    fetchExploreDriversData : (params) => dispatch(fetchExploreDriversData(params)),
    setExploreDriverFilters : (params) => dispatch(setExploreDriverFilters(params))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Drivers));
