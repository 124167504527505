import {
  ON_CHANGE_LOGO,
} from "../../../../constants";
import { withType } from "../../../../../utils/actionOperations";

export const onLogoChange = (fileUrl) => {
  return (dispatch) => {
    dispatch(
      withType(ON_CHANGE_LOGO, {
        fileUrl: fileUrl,
      })
    );
  }
};
