export function fetchUsersLogSuccess(state, payload) {
  return {
    ...state,
    fetchUsersLogApi: {
      ...state.fetchUsersLogApi,
      loading: false,
      error: false,
      data: payload.data,
      data_count: payload.data.total,
    },
  };
}

export function fetchUsersLogStarted(state, payload) {
  return {
    ...state,
    fetchUsersLogApi: {
      ...state.fetchUsersLogApi,
      loading: true,
      error: false,
      data:null,
    },
  };
}

export function fetchUsersLogFailed(state, payload) {
  return {
    ...state,
    fetchUsersLogApi: {
      ...state.fetchUsersLogApi,
      loading: false,
      message: "Network Error",
      error: payload.error || true,
      data:null,
    },
  };
}

export function logsPaginationChange(state, payload) {
  return {
    ...state,
    logsPagination: payload,
  };
}
