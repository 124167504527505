import {  update } from '../../../../utils/reducerOperations';

import {
    formChangeSuccess
} from '../../../../utils/reducerOperations';

export const ForgotPasswordChange=(state,payload)=>{
    state=ForgotPasswordClearError(state);
    return update(
        state,
        onChange(state, payload)
    );
};

export function onChange(state, payload) {
    let newState = ForgotPasswordClearError(state);
    return formChangeSuccess(newState, payload);
}

function ForgotPasswordClearError(state) {
    return{
        ...state,
        forgotPasswordApi:{
            ...state.forgotPasswordApi,
            error:false
        }
    };
}