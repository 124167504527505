import { connect } from "react-redux";
import { Participation } from "./Participation";
import {
  fetchExploreParticipationData,
  exploreUpdateParticipationSelectedTopicColumns,
  exploreDeselectParticipationcolumns,
  exploreGetDistributionData
} from "../../../../../../redux/actions";
import { withRouter } from "react-router-dom";
const mapStateToProps = (state) => {
  return { ...state.ExploreParticipationDataReducer, surveyDetails: state.DashBoardReducer.surveyDetailsApi, treeDataApi: state.DashBoardReducer.treeDataApi, thresholdData: state.DashBoardReducer.thresholdApi.data };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchExploreParticipationData: (params, cb) =>
      dispatch(fetchExploreParticipationData(params, cb)),
    exploreUpdateParticipationSelectedTopicColumns: (payload) =>
      dispatch(exploreUpdateParticipationSelectedTopicColumns(payload)),
    exploreDeselectParticipationcolumns: () =>
      dispatch(exploreDeselectParticipationcolumns()),
      exploreGetDistributionData: (payload) =>
      dispatch(exploreGetDistributionData(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Participation));

