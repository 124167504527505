

export const COMMON_ERROR_MESSAGE = "Oops! Please enter an answer"
export const ERROR_MESSAGE_FEEDBACK_TEXT = "Oops! Enter all text answers."
export const ERROR_MESSAGE_CONDITIONAL_FEEDBACK = "Please select at least 3 behaviors as Strengths and at least 3 behaviors as Improvement Areas"
export const ERROR_MESSAGE_DRAG = "Please drag and drop all the top 3 behaviors"
export const ERROR_MESSAGE_DRAG_NUMBER = "Oops! Please select at least 3 behaviors"
export const ERROR_MESSAGE_DRAG_STRENGTH = "Oops! Please select at least 3 strengths"
export const ERROR_MESSAGE_DRAG_STRENGTH_FOR_APPLIED = "Use the back button (<-) to select at least 3 strengths"
export const ERROR_MESSAGE_DRAG_IMPROVEMENT_FOR_APPLIED = "Use the back button (<-) to select at least 3 development areas"
export const ERROR_MESSAGE_DRAG_IMPROVEMENT = "Oops! Please select at least 3 improvement areas"
export const NA_CONDITION = {
    columnValues: ["0"]
}
export const FEEDBACK_ERROR_NOT_EQUAL = (strengthNeeded, ImprovementNeeded) => `Please select at least ${strengthNeeded} Or ${ImprovementNeeded} behaviors as Strengths and at least ${strengthNeeded} Or ${ImprovementNeeded} behaviors as Improvement Areas`
export const FEEDBACK_ERROR_EQUAL = (strengthNeeded) => `Please select at least ${strengthNeeded} behaviors as Strengths and at least ${strengthNeeded} behaviors as Improvement Areas`
export const STRENGTH_VALUES = ["3", "4"]
export const IMPROVEMENT_VALUES = ["1", "2"]
export const USUALLY_SCALE="5"
export const ALWAYS_SCALE="6"
export const NON_MAPPING_KEYS = ["answer", "question", "questionDimension", "type", "qid"];
export const SUMMARY_TYPE = "summaryRating";
export const CANT_ANSWER = "0"