import { connect } from "react-redux";
import { updateAnswer, updateCurrentIndex } from "../../../../../../redux/actions/SurveyAnsweringPageAction";
import  OpenEndedQuestions  from "./OpenEndedQuestions";


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAnswer: (payload) => dispatch(updateAnswer(payload)),
        updateCurrentIndex: () => dispatch(updateCurrentIndex())

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenEndedQuestions);