import { Default } from "./initialState";
import {
  fetchPayload,
  formChangeSuccess,
  update,
  setInitialState
} from "../../../utils/reducerOperations";

import { INVITE_MANAGERS_FORM_CHANGE,CLEAR_MANAGERS_INVITE_FORM } from "../../constants";
import { fetchFormConfigurations } from "../../inputFormConfigurations";

const inviteManagerReducer = (state = Default(), action) => {
  let payload = fetchPayload(action);

  switch (action.type) {
    case INVITE_MANAGERS_FORM_CHANGE:
      return update(
        {
          ...state,
          inviteManagerApi: setInitialState(),
        },
        formChangeSuccess(state, payload)
      );
    case CLEAR_MANAGERS_INVITE_FORM: 
    return update(
      {
        ...state,
        inputBoxConfiguration: fetchFormConfigurations(["subject", "message"]),
      },
    );
    default:
      return state;
  }
};

export default inviteManagerReducer;
