import { notification } from 'antd';
import * as actionTypes from '../../constants';
import Axios from 'axios';
import constants from './constants';
import {UPLOAD_FILE} from '../../../../config/constants';
import {
    GetLocalStorage, getOrgId
} from '../../../utils/localStorgeOperations';
import { getUserRole } from '../../../utils/rolePermissionUtils';


export function uploadCommentFile(file){
    return dispatch=>{
        dispatch({type:actionTypes.UPLOAD_COMMENTS_START});
        fileUploadSuccess({dispatch,file})
        // Axios.post(UPLOAD_FILE,fileUploadReq(file),constants.formDataHeader)
        //     .then(res=>fileUploadSuccess({dispatch,res,file}))
        //     .catch(err=>fileUploadFail({dispatch,err}));
    };
}

function fileUploadSuccess({dispatch,res,file}){
    notification.success({
        message: constants.fileUpoladSuccess,
        status: 'done'
    });
    dispatch({
        type:actionTypes.UPLOAD_COMMENTS_SUCCESS,
        data:file,
        fileName:file.name,
        file:file,
        status: 'done'
    });
}

export function uploadReprocessFile(file){
    return dispatch=>{
        dispatch({type:actionTypes.UPLOAD_REPROCESS_START});
        Axios.post(UPLOAD_FILE,fileUploadReq(file),constants.formDataHeader)
            .then(res=>reprocessUploadSuccess({dispatch,res,file}))
            .catch(err=>reprocessUploadFail({dispatch,err}));
    };
}

function reprocessUploadSuccess({dispatch,res,file}){
    notification.success({
        message: constants.fileUpoladSuccess
    });
    dispatch({
        type:actionTypes.UPLOAD_REPROCESS_SUCCESS,
        data:res.data,
        fileName:file.name
    });
}

function reprocessUploadFail({dispatch,err,field}){
    notification.error({
        message: constants.fileUploadFail
    });
    dispatch({
        type:actionTypes.UPLOAD_REPROCESS_FAIL,
        field
    });
}

function fileUploadReq (file) {
    const {userId,userRole} = constants;
    let formData = new FormData();
    formData.append(userRole, getUserRole(getOrgId()));
    formData.append(userId,GetLocalStorage(userId) );
    formData.append(constants.file,file );
    return formData;
}