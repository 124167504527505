import {
    careerDevelopmentTemplate,
    leadershipTemplate,
    diversityTemplate,
    communicationTemplate,
    engagementTemplate,
    agilityTemplate,
    collaborationTemplate,
    commitmentTemplate,
    competenceTemplate,
    empowermentTemplate,
    futureWorkTemplate,
    inclusionTemplate,
    managerTemplate,
    visionTemplate,
    wellnessTemplate,
    leadingChangeTemplate,
    changeReadinessTemplate,
    changeEnablerTemplate,
    needForChangeTemplate
} from './Templates';

import { deepCopy } from '../../../../utils/deepCopy';
import { ageDemographicsTemplate } from './Templates/ageDemographicsTemplate';
import { genderDemographicsTemplate } from './Templates/genderDemographicsTemplate';
import { jobTenureDemographicTemplate } from './Templates/jobTenureDemographicTemplate';
import { raceEthnicityDemographicTemplate } from './Templates/raceEthinicityDemographicTemplate';
import { remoteDemographicsTemplate } from './Templates/remoteDaysDemographicsTemplate';
import { goingWellTextEntryTemplate } from './Templates/goingWellTextEntryTemplate';
import { improvementTextEntryTemplate } from './Templates/improvementTextEntryTemplate';

export const goals = [
    {
        dimension: 'Engagement',
        questions: deepCopy(engagementTemplate),
        isDefault: true,
        isSelected: true,
        isStatic: true,
        isOutcomeVariable: true,
        type:"likert",
        isTemplate: true,
        goalId: 'engagement'
    },
    {
        dimension: 'Diversity',
        questions: deepCopy(diversityTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'diversity'
    },
    {
        dimension: 'Communication',
        questions: deepCopy(communicationTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'communication'
    },
    {
        dimension: 'Senior Leaders',
        questions: deepCopy(leadershipTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'seniorleaders'
    },
    {
        dimension: 'Career Development',
        questions: deepCopy(careerDevelopmentTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'career'
    },
    {
        dimension: 'My Managers',
        questions: deepCopy(managerTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'managers'
    },
    {
        dimension: 'Commitment',
        questions: deepCopy(commitmentTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'commitment'
    },
    {
        dimension: 'Competence',
        questions: deepCopy(competenceTemplate),
        isDefault: true,
        type:"likert",
        isTemplate: true,
        goalId: 'competence'
    },
    {
        dimension: 'Empowerment',
        questions: deepCopy(empowermentTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'empowerment'
    },
    {
        dimension: 'Inclusion',
        questions: deepCopy(inclusionTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'inclusion'
    },
    {
        dimension: 'Future of Work',
        questions: deepCopy(futureWorkTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'futureofwork'
    },
    {
        dimension: 'Wellness',
        questions: deepCopy(wellnessTemplate),
        isDefault: true,
        isTemplate: true,
        goalId: 'wellness',
        type:"likert",
    },
    {
        dimension: 'Agility',
        questions: deepCopy(agilityTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'agility'
    },
    {
        dimension: 'Collaboration',
        questions: deepCopy(collaborationTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'collaboration'
    },
    {
        dimension: 'Vision',
        questions: deepCopy(visionTemplate),
        isDefault: true,
        isTemplate: true,
        goalId: 'vision',
        type:"likert",
    },
    {
        dimension: 'Leading Change',
        questions: deepCopy(leadingChangeTemplate),
        isDefault: true,
        type:"likert",
        isTemplate: true,
        goalId: 'leadingchange'
    },
    {
        dimension: 'Change Readiness',
        questions: deepCopy(changeReadinessTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'changereadiness'
    },
    {
        dimension: 'Need for Change',
        questions: deepCopy(needForChangeTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'needforchange'
    },
    {
        dimension: 'Change Enabler',
        questions: deepCopy(changeEnablerTemplate),
        isDefault: true,
        isTemplate: true,
        type:"likert",
        goalId: 'changeenabler'
    },
    {
        dimension: 'Demographics',
        questions: deepCopy(ageDemographicsTemplate),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        goalId: 'demographics'
    },
    {
        dimension: 'Demographics',
        questions: deepCopy(genderDemographicsTemplate),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        goalId: 'demographics'
    },
    {
        dimension: 'Demographics',
        questions: deepCopy(jobTenureDemographicTemplate),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        goalId: 'demographics'
    },
    {
        dimension: 'Demographics',
        questions: deepCopy(raceEthnicityDemographicTemplate),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        goalId: 'demographics'
    },
    {
        dimension: 'Demographics',
        questions: deepCopy(remoteDemographicsTemplate),
        isTemplate: true,
        anonymousOnly: true,
        isDemographic: true,
        goalId: 'demographics'
    },
    {
        dimension: 'Comments',
        questions: deepCopy(goingWellTextEntryTemplate),
        isSelected: true,
        isTextEntryOnly: true,
        isTemplate: true,
        goalId: 'comments'
    },
    {
        dimension: 'Comments',
        questions: deepCopy(improvementTextEntryTemplate),
        isSelected: true,
        isTextEntryOnly: true,
        isTemplate: true,
        goalId: 'comments'
    }
]