import { } from '../../constants';
import { ApiBase } from '../../../utils/apiBase';
import { DELETE_ADMIN_URL } from '../../../../config/constants';
import { notification } from "antd";
import { getDataFromUrlToken } from "../../../utils/urlDataStore";
import { URL_DATA } from "../../../../config/urlDataConstants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const deleteAdmin = (params, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    apiBaseInstance.instance
      .post(DELETE_ADMIN_URL, { ...getParams(params), ...getProjectIdAndOrgId(getParams(params)) })
      .then((res) => {
        notification.success({ message: "Admin deleted successfully" });
        cb(true);
      })
      .catch((err) => {
        notification.error({ message: "Failed to delete admin" })
        cb(false);
      });
  };
};

function getParams(params) {
  const orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
  console.log("Org details", orgDetails)
  return {
    ...params,
    organizationId: orgDetails && orgDetails.organizationId,
  };
}
