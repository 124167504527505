import { GET_SIGNED_URL } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';

export const getPreSignedUrlForImage = (params, next) => {
  let apiBase = new ApiBase();
  return (dispatch) => {
    return apiBase.instance
      .post(GET_SIGNED_URL, { ...params })
      .then((response) => {
        next(response);
      })
      .catch((err) => {
        next(null);
      });
  };
};