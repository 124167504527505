import { Dropdown } from 'antd';
import { Menu } from 'antd';
import React from 'react';
import { DASHBOARD_TABS } from '../../../../../../../../../config/tabConstants';
import { URL_DATA } from '../../../../../../../../../config/urlDataConstants';
import SVGIcons from '../../../../../../../../Components/SVGIcons';
import { getURLFilterAndByScore } from '../../../../../../../../utils/byScoreFilterUtils';
import { getDataFromUrlToken } from '../../../../../../../../utils/urlDataStore';
import { getSelectedUserData } from '../../../../helper';

export class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedCommentsId: [],
    };
  }

  formatCommentText = (item) => {
    let expandedCommentsId = this.state.expandedCommentsId;
    if (item.text && item.text.length > 260) {
      if (!expandedCommentsId.includes(item.commentId)) {
        let trimmedString = item.text.substr(0, 260);
        trimmedString = trimmedString.substr(
          0,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
        );
        return (
          <>
            {trimmedString + '...'}
            <a
              href={false}
              onClick={() => {
                expandedCommentsId.push(item.commentId);
                this.setState({ expandedCommentsId });
              }}
            >
              Show more <SVGIcons type={'SVG-chevron-down'} />
            </a>
          </>
        );
      } else {
        return (
          <>
            {item.text + ' '}
            <a
              href={false}
              onClick={() => {
                expandedCommentsId = expandedCommentsId.filter(
                  (id) => item.commentId !== id
                );
                this.setState({ expandedCommentsId });
              }}
            >
              Show less <SVGIcons type={'SVG-chevron-up'} />
            </a>
          </>
        );
      }
    } else return item.text;
  };

  handleChangeQuote = (commentObject, showPrevious) => {
    const filters = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER) || {};
    const selectedUserData = getSelectedUserData();
    let filterData = getURLFilterAndByScore(filters)
    let topicStoryFilter = false;
    if (Object.keys(filterData.filters).length || filterData.byScore.length || filterData.multipleSelect.length
      || selectedUserData.directReporteesOnly || selectedUserData.selectedUser !== "") {
      topicStoryFilter = true;
    }
    const topicObject = this.props.topicObject;
    let projectId = topicObject.projectId;
    let topic = topicObject.topic;
    let changeQuote = commentObject;
    let isAnonymous = undefined;
    if (this.props.surveyDetails) {
      isAnonymous = this.props.surveyDetails.isAnonymous;
    }
    let payload = {
      currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TOPICS,
      projectId: projectId,
      topic,
      hrisFileId: this.props.surveyDetails.hrisFileId,
      isAnonymous: isAnonymous,
      id: topicObject.id,
      topicStoryFilter,
      ...selectedUserData,
      ...filterData,
    };
    if (!showPrevious) {
      this.props.disableTopicLoading(true)
      payload.changeQuote = changeQuote;
      this.props.changeExploreQuote(payload, this.reloadList);
    } else {
      this.props.disableTopicLoading(true)
      payload.previousQuote = changeQuote;
      this.props.showPreviousExploreQuote(payload, this.reloadList);
    }
  };

  reloadList = (success) => {
    if (success) {
      const selectedUserData = getSelectedUserData();
      const filters = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER) || {};
      let filterData = getURLFilterAndByScore(filters);
      let isFiltered = false;
      if (Object.keys(filterData.filters).length || filterData.byScore.length ||
      selectedUserData.directReporteesOnly || selectedUserData.selectedUser !== "")
        isFiltered = true;
      const { projectId } = getDataFromUrlToken('projectDetails');
      this.props.fetchExploreTopicList(
        {
          currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.TOPICS,
          projectId,
          ...filterData,
          isFiltered,
          directReporteesOnly: selectedUserData.directReporteesOnly,
          selectedUser: selectedUserData.selectedUser,
          isAnonymous: this.props.surveyDetails.isAnonymous,
          hrisFileId: this.props.surveyDetails.hrisFileId,
        },
        () => this.props.disableTopicLoading(false)
      );
    }
  };

  renderCommentOptions = (commentObject) => {
    return (
      <Menu>
        <Menu.Item key='0'>
          <a href={false} onClick={(e) => this.handleChangeQuote(commentObject)}>
            Change quote
          </a>
        </Menu.Item>
        {!commentObject.isDefault && (
          <Menu.Item key='1'>
            <a href={false} onClick={(e) => this.handleChangeQuote(commentObject, true)}>
              Show previous quote
            </a>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  formatDemographicSegmentName = (name) => {
    if (name) {
      return name.charAt(0).toUpperCase() + name.slice(1)
    }
    return ''
  }

  render() {
    return (
      <div className={`topics-description__content--right ${this.props.className || ''}`}>
        {this.props.comments.map((commentObject) => (
          <ul key={commentObject.commentId} className='review-area'>
            <li>
              <SVGIcons type={'SVG-quote'} />
            </li>
            <li>
              <div className="content-block">
                <div className="content-block__left">
                  {this.formatCommentText(commentObject)}
                  <p style={{ fontStyle: 'normal' }}>{this.formatDemographicSegmentName(commentObject.outlierDemographics
                    && commentObject.outlierDemographics.segment)}</p>
                </div>
                <div className="content-block__right">
                  <div className='download-menu'>
                    <Dropdown
                      overlay={this.renderCommentOptions(commentObject)}
                      trigger={['click']}
                      placement='topRight'
                    >
                      <a
                        href={false}
                        className='ant-dropdown-link'
                        onClick={(e) => e.preventDefault()}
                      >
                        <span className='download-menu__icon'>
                          <span>
                            <SVGIcons type={'SVG-dot-menu'} />
                          </span>
                        </span>
                      </a>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </li>

          </ul>
        ))}
      </div>
    );
  }
}
