
import { deepCopy } from "../../../../utils/deepCopy";
import { newJson } from "../../../../utils/newJson";
import { checkIfTwoArraysAreEqual, checkWheatherIsRequiredSatisfied, goToPrevOrNext } from "./updateSurveyAnsweringUtils";

export function updateAnswer(state, payload) {
        let answerUpdatedQuestionSet = updateQuestionSet(state, payload.value, payload.question, payload.isFromMultipleOption, payload.blockId)
    return {
        ...state,
        questionSet: answerUpdatedQuestionSet.indexData.questionSetCopy ? answerUpdatedQuestionSet.indexData.questionSetCopy : answerUpdatedQuestionSet.data,
        // currentIndex: answerUpdatedQuestionSet.indexData.currentIndex,
        // progressIndex: answerUpdatedQuestionSet.indexData.progressIndex,
        indexDetails: answerUpdatedQuestionSet.indexData,
        hideErrorBlock: answerUpdatedQuestionSet.indexData.requiredSatisfied ? answerUpdatedQuestionSet.indexData.requiredSatisfied : false,
        pageBreakAddedQuestionSet: answerUpdatedQuestionSet.pageBreakBlockCopy ? answerUpdatedQuestionSet.pageBreakBlockCopy : state.pageBreakAddedQuestionSet
    };
};


const updateQuestionSet = (state, value, question, isFromMultipleOption, blockId) => {
        const data = newJson(state.questionSet);
    let questionBlock = {}
    if (question.isQuestionLevelFollowUp) {
                let updatedQuestionSet = handleQuestionLevelFollowUpSelect(state, value, question)
        questionBlock = updatedQuestionSet.questionBlock
        let indexData = checkWhetherAllQuestionsAnswered(updatedQuestionSet.data, questionBlock, state.pageBreakAddedQuestionSet[state.currentIndex], state)
        return { data: updatedQuestionSet.data, indexData, pageBreakBlockCopy: indexData.pageBreakAddedQuestionSetCopy}
    }
    else if(question.isFromBlockLevelFollowUp){
                let updatedQuestionSets = handleBlockLevelFollowUpSelect(state,value,question)
        let indexData = checkWhetherAllQuestionsAnswered(updatedQuestionSets.data, questionBlock, state.pageBreakAddedQuestionSet[state.currentIndex], state)
        return{data:updatedQuestionSets.data,indexData}
    }
    else {
                if (!isFromMultipleOption && Number.isInteger(value)) value = value.toString();
        if (!isFromMultipleOption && Array.isArray(value)) value = value.toString();
        const originalData = state.questionSet;
        let answeredBlock = undefined;
        let answeredQuestion = undefined;
        if (question.isFollowUpQuestion) {
            originalData.forEach((block, blockIndex) => {
                if (block.goalId === question.goalId) {
                    block.followUpQuestions.forEach((item, questionIndex) => {
                        if (item.qid === question.qid) {
                            answeredBlock = blockIndex;
                            answeredQuestion = questionIndex;
                        }
                    })
                }
            });
            data[answeredBlock].followUpQuestions[answeredQuestion].answer = value;
            questionBlock = data[answeredBlock].followUpQuestions[answeredQuestion]
        }
        else {
                        originalData.forEach((block, blockIndex) => {
                block && block.questions && block.questions.forEach((item, questionIndex) => {
                    if (question.qid === item.qid) {
                        answeredBlock = blockIndex;
                        answeredQuestion = questionIndex;
                    }
                });
            });
            data[answeredBlock].questions[answeredQuestion].answer = value;
            questionBlock = data[answeredBlock].questions[answeredQuestion]

        }
    }
    let valueArray =value
    if(isFromMultipleOption){
         valueArray = JSON.parse(value)
        if ( valueArray.find(item => item.isOtherOption)) {
        let indexData =  { currentIndex: state.currentIndex, progressIndex: state.progressIndex, requiredSatisfied: true }
        return { data, indexData };
        
        } 
        else {
                let indexData = checkWhetherAllQuestionsAnswered(data, questionBlock, state.pageBreakAddedQuestionSet[state.currentIndex], state);
                return { data, indexData,pageBreakBlockCopy:indexData.pageBreakAddedQuestionSetCopy?indexData.pageBreakAddedQuestionSetCopy:undefined };
            }
    }else{
        let indexData = checkWhetherAllQuestionsAnswered(data, questionBlock, state.pageBreakAddedQuestionSet[state.currentIndex], state);
        return { data, indexData,pageBreakBlockCopy:indexData.pageBreakAddedQuestionSetCopy?indexData.pageBreakAddedQuestionSetCopy:undefined };
    }
    
};

const handleBlockLevelFollowUpSelect = (state,value,question) =>{
    const originalData = state.questionSet;
    let questionBlock = {}
    const data = newJson(state.questionSet);
    let answeredBlock = undefined;
    let answeredQuestion = undefined;
    let matrixIndex = undefined
    let selectedId
    if(question.type === "likert"){
        selectedId = question.selectedBlockId
    }
    else{
        selectedId= question.selectedGoalId
    }
    originalData.forEach((block, blockIndex) => {
        if (block.blockId === selectedId) {
            block.questions[0].followUpQuestions.forEach((item, matrixQuestionIndex) => {
                if (item.qid === question.qid) {
                    answeredBlock = blockIndex;
                    answeredQuestion = matrixQuestionIndex
                }
            })
            data[answeredBlock].questions[0].followUpQuestions[answeredQuestion].answer = value
            questionBlock = data[answeredBlock].questions[0].followUpQuestions[answeredQuestion]
        }

    });
    return { data, questionBlock }
}
const handleQuestionLevelFollowUpSelect = (state, value, question) => {
    if (Number.isInteger(value)) value = value.toString();
    if (Array.isArray(value)) value = value.toString();
    const originalData = state.questionSet;
    let questionBlock = {}
    const data = newJson(state.questionSet);
    let answeredBlock = undefined;
    let answeredQuestion = undefined;
    let matrixIndex = undefined
    if (question.isMultipleOptionFollowUp) {
        let updatedFollowUpAnswerSet = handleSelectMutilpleChoiceFollowUp({ originalData, data, question, value })
        return updatedFollowUpAnswerSet
    }
    else {
        originalData.forEach((block, blockIndex) => {
            if (block.goalId === question.selectedGoalId) {
                block.questions[0].matrixQuestions.forEach((item, matrixQuestionIndex) => {
                    if (item.staticQuestionId === question.selectedMatrixQuestionQuestionId) {
                        answeredBlock = blockIndex;
                        matrixIndex = matrixQuestionIndex;
                        if (item.followUpQuestions) {
                            item.followUpQuestions.forEach((followupQuestions, followUpIndex) => {
                                if (followupQuestions.qid === question.qid)
                                    answeredQuestion = followUpIndex
                            })
                        }
                    }
                })
                data[answeredBlock].questions[0].matrixQuestions[matrixIndex].followUpQuestions[answeredQuestion].answer = value;
                questionBlock = data[answeredBlock].questions[0].matrixQuestions[matrixIndex].followUpQuestions[answeredQuestion]
            }

        });
    }

    return { data, questionBlock }
}

export const checkWhetherAllQuestionsAnswered = (data, questionSet, currentBlock, state) => {
    if (!state.isPrevClicked) {
        let goToNext = false
        let numberOfQuestionsInCurrentBlock = currentBlock &&
            Array.isArray(currentBlock.questions) &&
            currentBlock.questions[0].type === "likert" &&
            currentBlock.questions[0].matrixQuestions ? currentBlock.questions[0].matrixQuestions.length : null
        if (numberOfQuestionsInCurrentBlock === 1) {
                        let result = goToPrevOrNext({ questionSet: data, currentIndex: state.currentIndex, progressIndex: state.progressIndex, pageBreakAddedQuestionSet: state.pageBreakAddedQuestionSet }, { goPrev: false, isFromCheckWheather: true })
                        return result
        }
        if (singleQuestionsInpage.includes(currentBlock.questions[0].type)) {
            if (questionSet.qid === currentBlock.questions[0].qid) {
                if (questionSet.answer.length > 0 || questionSet) {
                    goToNext = true
                }
            }
            if (goToNext) {
                                let newResult = goToPrevOrNext({ questionSet: data, pageBreakAddedQuestionSet: state.pageBreakAddedQuestionSet, currentIndex: state.currentIndex, progressIndex: state.progressIndex }, { goPrev: false, isFromCheckWheather: true })
                return { ...newResult}
            }
        } else if (currentBlock.isDefault || currentBlock.isCustom) {
            let currentstaticIds = []
            let questiontaticIds = []
            currentBlock.questions[0].matrixQuestions.forEach(item => { currentstaticIds.push(item.staticQuestionId) })
            questionSet.answer && JSON.parse(questionSet.answer).forEach(item => {
                if (currentstaticIds.includes(item.staticQuestionId)) {
                    questiontaticIds.push(item.staticQuestionId)
                }

            })
            if (checkIfTwoArraysAreEqual(currentstaticIds, questiontaticIds)) {
                                let result = goToPrevOrNext({ questionSet: data, pageBreakAddedQuestionSet: state.pageBreakAddedQuestionSet, currentIndex: state.currentIndex, progressIndex: state.progressIndex }, { goPrev: false, isFromCheckWheather: true })
                return result
            }
        }
    };
    return { currentIndex: state.currentIndex, progressIndex: state.progressIndex, requiredSatisfied: true }



}


export const updateIndexDetailsWithData = (state, payload) => {
    let updatedCreateAnswerSet = payload && payload.isFromGenerateAnswerSet ? {
        isProcessing: true,
        error: false,
        message: ''
    } : state.createAnswerSetApi
    return {
        ...state,
        currentIndex: state.indexDetails.currentIndex,
        progressIndex: state.indexDetails.progressIndex,
        createAnswerSetApi: updatedCreateAnswerSet,
        // isRequired: state.currentIndex === state.indexDetails.currentIndex ? true : false
    }
}

const singleQuestionsInpage = ["Multiple Choice", "yesOrNo", "ratingScale", "employerNetPromoterScore"]

const handleSelectMutilpleChoiceFollowUp = ({ data, originalData, question, value }) => {
    let questionBlock = {};
    let answeredBlock = undefined;
    let answeredQuestion = undefined;
    let matrixIndex = undefined
    originalData.forEach((block, blockIndex) => {
        if (block.blockId === question.selectedGoalId) {
            block.questions[0].options.forEach((item, matrixQuestionIndex) => {
                if (item.id === question.selectedQuestionId) {
                    answeredBlock = blockIndex;
                    matrixIndex = matrixQuestionIndex;
                    if (item.followUpQuestions) {
                        item.followUpQuestions.forEach((followupQuestions, followUpIndex) => {
                            if (followupQuestions.qid === question.qid)
                                answeredQuestion = followUpIndex
                        })
                    }
                }
            })
            data[answeredBlock].questions[0].options[matrixIndex].followUpQuestions[answeredQuestion].answer = value;
            questionBlock = data[answeredBlock].questions[0].options[matrixIndex].followUpQuestions[answeredQuestion]
        }

    });
    return { data, questionBlock }
}