import {
  FETCH_EXPLORE_COMMENTS_DATA_START,
  FETCH_EXPLORE_COMMENTS_DATA_SUCCESS,
  FETCH_EXPLORE_COMMENTS_DATA_FAILED,
} from '../../../constants';
import { SENTIMENTAL_URL } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { withType } from '../../../../utils/actionOperations';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchExploreQuestionComments = (payload, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(FETCH_EXPLORE_COMMENTS_DATA_START, { key: payload.key ,questionId : payload.selectedQuestionId}));
    apiBaseInstance.instance
      .post(SENTIMENTAL_URL, {
        ...payload,
        ...getProjectIdAndOrgId(payload),
        data: {
          projectId: [payload.projectId],
          sentiments: [],
          text: '',
          topic: [],
        },
      })
      .then((response) => {
        dispatch(
          withType(FETCH_EXPLORE_COMMENTS_DATA_SUCCESS, {
            data: { ...response.data, key: payload.key },
            message: response.message,filters:payload.filters,
            selectedUser: payload.selectedUser,
            byScore: payload.byScore,
            multipleSelect: payload.multipleSelect,
            directReporteesOnly: payload.directReporteesOnly,
            questionId : payload.selectedQuestionId
          })
        );
        setTimeout(() => {
          cb && cb({ success: true, projectDetails: payload });
        }, 0);
      })
      .catch((err) => {
        dispatch(
          withType(FETCH_EXPLORE_COMMENTS_DATA_FAILED, {
            error: err,
            key: payload.key,
            questionId : payload.selectedQuestionId
          })
        );
      });
  };
};
