import { withType } from '../../../../utils/actionOperations';
import { UPDATE_MANAGER_HEATMAP_COMPARISON_LEVEL, UPDATE_MANAGER_HEATMAP_SCORE_LEVEL } from '../../../constants';

export * from './managerHeatmapHeadersAction';
export * from './managerHeatmapQuestionsAction';
export * from './managerHeatmapTableAction';
export * from './managerHeatmapResetFetchDataKeyAction'

export const updateManagerScoreLevel = (params) => {
  return withType(UPDATE_MANAGER_HEATMAP_SCORE_LEVEL, {
    data: params.scoreLevel,
  });
};

export const updateManagerComparisonLevel = (params) => {
  return withType(UPDATE_MANAGER_HEATMAP_COMPARISON_LEVEL, {
    data: params.comparisonLevel,
  });
};
