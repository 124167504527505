import React from 'react';
import { Tooltip } from 'antd';
import { constants } from './constants';
import './index.scss';

export class TopicBarChartLine extends React.Component {
  state = {
    screenWidth: window.screen.width
  }
  componentDidMount() {
    window.addEventListener('resize', this.onWidthChange, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWidthChange, true);
  }

  onWidthChange = (event) => {
    this.setState({ screenWidth: window.screen.width })
  }

  showText = (score, text) => {
    if (text === constants.FAVOURABLE) {
      if (score >= 13 && this.state.screenWidth > 992) return true;
      return false;
    } else if (text === constants.NEUTRAL) {
      if (score >= 11 && this.state.screenWidth > 992) return true;
      return false;
    } else if (text === constants.UNFAVOURABLE) {
      if (score >= 15 && this.state.screenWidth > 992) return true;
      return false;
    }
  };

  getTitle = (text, score) => {
    return text + score + '%';
  };

  isInsufficientData = () => {
    if (this.props.data.favScore === 0 &&
      this.props.data.neutralScore === 0 &&
      this.props.data.unFavScore === 0) {
      return true
    }
    return false
  }

  render() {
    const props = this.props;
    return (
      <>
        {this.isInsufficientData() ? '' :
          <div className='progress-bar'>
        <Tooltip
          placement='bottom'
          title={
            this.showText(props.data.favScore, constants.FAVOURABLE)
              ? ''
              : constants.FAVOURABLE
          }
        >
          <div
            className='favourable'
            style={{ width: `${props.data.favScore}%`, padding: props.data.favScore === 0 && '0' }}

          >
              <p>
              <b>{`${props.data.favScore} `}</b>
              {this.showText(props.data.favScore, constants.FAVOURABLE) && constants.FAVOURABLE}
            </p>
          </div>
        </Tooltip>
        <Tooltip
          placement='bottom'
          title={
            this.showText(props.data.neutralScore, constants.NEUTRAL)
              ? ''
              : constants.NEUTRAL
          }
        >
          <div
            className='neutral'
            style={{ width: `${props.data.neutralScore}%`, padding: props.data.neutralScore === 0 && '0' }}
          >
              <p>
              <b>{`${props.data.neutralScore} `}</b>
              {this.showText(props.data.neutralScore, constants.NEUTRAL) && constants.NEUTRAL}
            </p>
          </div>
        </Tooltip>
        <Tooltip
          placement='bottom'
          title={
            this.showText(props.data.unFavScore, constants.UNFAVOURABLE)
              ? ''
              : constants.UNFAVOURABLE
          }
        >
          <div
            className='unfavourable'
            style={{ width: `${props.data.unFavScore}%`, padding: props.data.unFavScore === 0 && '0' }}
          >
              <p>
              <b>{`${props.data.unFavScore} `}</b>
              {this.showText(props.data.unFavScore, constants.UNFAVOURABLE) && constants.UNFAVOURABLE}
            </p>
          </div>
        </Tooltip>
          </div>}

      </>
    );
  }
}
