import { fetchPayload, update } from "../../../utils/reducerOperations";
import { Default } from "./InitialState";
import {
  FETCH_LOGS_LIST_STARTED,
  FETCH_LOGS_LIST_SUCCESS,
  FETCH_LOGS_LIST_FAILED,
  LOGS_PAGINATION_CHANGE,
} from "../../constants";
import {
  fetchUsersLogStarted,
  fetchUsersLogSuccess,
  fetchUsersLogFailed,
  logsPaginationChange,
} from "./Helpers";
const usersPageReducer = (state = Default(), action) => {
  let userPagePayload = fetchPayload(action);
  switch (action.type) {
    case FETCH_LOGS_LIST_STARTED:
      return update(state, fetchUsersLogStarted(state, userPagePayload));
    case FETCH_LOGS_LIST_SUCCESS:
      return update(state, fetchUsersLogSuccess(state, userPagePayload));
    case FETCH_LOGS_LIST_FAILED:
      return update(state, fetchUsersLogFailed(state, userPagePayload));
    case LOGS_PAGINATION_CHANGE:
      return update(state, logsPaginationChange(state, userPagePayload));
    default:
      return state;
  }
};

export default usersPageReducer;
