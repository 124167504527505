import React from "react";
import SVGIcons from '../../Components/SVGIcons';
export const AsterikLabel = () => {
  return (
    <label class="required-icon">
      <span>
      <SVGIcons type={'SVG-rating'} />
      </span>
    </label>
  );
};
