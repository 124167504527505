import { update, makeKey } from "../../../../utils/reducerOperations";
import { appendUserRoles } from "./appendUserRole";
export function fetchUsersSuccess(state, payload) {
  let nextPageEnabled = false;
  let pageKeys = [];
  if (payload.data.LastEvaluatedKey) {
    nextPageEnabled = true;
    pageKeys[0] = payload.data.LastEvaluatedKey;
  }
  return makeKey(
    "fetchUsersApi",
    update(state.fetchUsersApi, {
      message: payload.message,
      isProcessing: false,
      data: appendUserRoles(payload.data.paginatedUserData),
      count: payload.data.count,
      nextPageEnabled: nextPageEnabled,
      error: !payload.status,
      previousPageEnabled: false,
      page: 0,
      pageKeys: pageKeys,
    })
  );
}
// function filterInactiveUser(list) {
//     list.Items = list.Items.filter(eachItem => eachItem.active);
//     return list;
// }
