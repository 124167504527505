import {
    SURVEY_EDITOR_LIKERT_REQUIRED_CHANGE,
    SURVEY_EDITOR_LIKERT_CHANGE_OUTCOME_VARIABLE,
    SURVEY_EDITOR_LIKERT_CHANGE_OPTIONS_COUNT,
    SURVEY_EDITOR_CHANGE_TOPIC,
    SURVEY_EDITOR_LIKERT_CHANGE_NA_OPTION,
    SURVEY_EDITOR_MATRIX_CHANGE_NA_OPTION,
} from "../../../../../constants";


export const likertChangeRequired = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_REQUIRED_CHANGE, payload });
    }
};

export const likertChangeShowTopic = (payload,cb)=>{
    return (dispatch)=>{
        dispatch({type: SURVEY_EDITOR_CHANGE_TOPIC,payload})
    }

}

export const likertChangeOutComeVariable = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_CHANGE_OUTCOME_VARIABLE, payload });
    }
};

export const likertChangeOptionsCount = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_CHANGE_OPTIONS_COUNT, payload });
    }
};

export const likertChangeNaOption = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_LIKERT_CHANGE_NA_OPTION, payload });
    }
};

export const matrixNaOptionChange = (payload) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MATRIX_CHANGE_NA_OPTION, payload });
    }
}