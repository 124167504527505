export const indexBasedColorPicker =(updatedThresholdValue,colors)=>{
    let minValueArray = colors.slice(0, 3);
    let maxValueArray = colors.slice(4,6);
    const updatedThresholdValueLength = updatedThresholdValue.length-1;
    const newUpdatedThresholdValue = updatedThresholdValue.slice(0,updatedThresholdValueLength)
    let middleIndex;
    if (updatedThresholdValueLength % 2 === 1) {
      middleIndex = Math.ceil(updatedThresholdValueLength / 2);
    } else {
      middleIndex = Math.ceil(updatedThresholdValueLength / 2) + 1;
    }
    const updatedColoredArray = updatedThresholdValue.map(
      (thresholdData, index) => {
        if (parseInt(thresholdData.id) < middleIndex) {
          return {
            ...thresholdData,
            color: minValueArray[index],
          };
        } else if(index === updatedThresholdValueLength){
          return{
            ...thresholdData
          }
        }else if (parseInt(thresholdData.id)  === middleIndex) {
          return {
            ...thresholdData,
            color: colors[3],
          };
        }
      else  {
         let maxValueArrayLength = maxValueArray.length
          let colorArrayIndex = ((updatedThresholdValueLength-thresholdData.id)+maxValueArrayLength-1) % maxValueArrayLength
          return {
            ...thresholdData,
            color: maxValueArray[colorArrayIndex],
          };
        }
    }
    );
    return updatedColoredArray
}

export const inputConditionCheck =(value,id,thresholdValue)=>{
  let errorMessage = "";
let isError = false;
if (isNaN(value)) {
  errorMessage = "Oops! Please enter a valid number.";
  isError = true
} else if (value.length > 2) {
  errorMessage = "Oops! Please enter only two digits.";
  isError = true
} else if (parseInt(id) !== 1 && parseInt(value) < thresholdValue[parseInt(id) - 2].value) {
  errorMessage = "Oops! Invalid Input";
  isError = true
} else if (parseInt(id) !== thresholdValue.length && parseInt(value) > thresholdValue[parseInt(id)].value || value === "") {
  errorMessage = "Oops! Invalid Input";
  isError = true
} else if(parseInt(id) === thresholdValue.length && parseInt(value) > 100){
  errorMessage = "Oops! Invalid Input";
  isError = true
}

return { errorMessage, isError };
}