import { URL_DATA } from "../../config/urlDataConstants"
import { getSelectedUserData, getStoryboardSelectedUserData } from "../Routes/NewDashboard/subroutes/Explore/helper"
import { getURLFilterAndByScore } from "./byScoreFilterUtils"
import { getDataFromUrlToken } from "./urlDataStore"

export const checkIfAnyFilterIsApplied = () => {
    let dashBoardFilters = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER)
    let storyboardFIlters = getDataFromUrlToken(URL_DATA.STORYBOARD_FILTER)
    let storyboardHierarchy = getStoryboardSelectedUserData()
    let dashboardHierarchy = getSelectedUserData()
    const storyboardFiltersByScore = getURLFilterAndByScore(storyboardFIlters)
    const dashBoardFiltersByScore = getURLFilterAndByScore(dashBoardFilters)
    if (Object.keys(storyboardFiltersByScore.filters).length > 0 ||
        Object.keys(dashBoardFiltersByScore.filters).length > 0 ||
        dashBoardFiltersByScore.byScore.length > 0 ||
        storyboardFiltersByScore.byScore.length > 0 ||
        storyboardHierarchy.selectedUser !== "" ||
        dashboardHierarchy.selectedUser !== "" 
    ){
        return true
    }
    return false
}