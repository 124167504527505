import React from 'react'
import { COHORT_SURVEY_TYPE, FEEDBACK_360_SURVEY, FEEDBACK_360_TYPE } from '../../../config/questionTypeConstants';
import { COHORT_SURVEY_SECTION, PROJECTS_PAGE_SECTIONS, SURVEY_WIZARD_TABS } from '../../../config/tabConstants';
import { URL_DATA } from '../../../config/urlDataConstants';
import { deepCopy } from '../../utils/deepCopy';
import { getDataFromUrlToken } from '../../utils/urlDataStore';
import { COHORT_HRIS_FILE_TYPE } from './constants';
import * as helper from './fileUploadHelper'

export const submitToUpload = (cb, fileName, instance) => {
    helper.submitToUpload(instance.props, {
        addJob: (file, keyName, fileName) => addJob(file, keyName, fileName, instance, cb),
        fileName: fileName,
        cb: cb,
        instance: instance
    });
};

const addJob = (uploadedFileName, keyName, fileName, instance, cb) => {
    let orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
    instance.props.addJob(
        {
            currentTab: COHORT_SURVEY_SECTION.CREATE_COHORT_SURVEY,
            keyName,
            hrisFileName: fileName,
            hrisFileType: COHORT_HRIS_FILE_TYPE,
            uploadedFileName,
            organizationId: orgDetails.organizationId,
            ownerEmail: localStorage.getItem("username"),
            ownerId: localStorage.getItem("userId"),
        },
        (isSuccess, response) => {
            if (isSuccess) {
                if (response && response.data && response.data.data) {
                    instance.closeHRISUploadModal(cb)
                    let fileId = response.data.data.hrisFileId
                    let fileName = response.data.data.hrisFileName
                    instance.onUploadClick(fileName,fileId)
                }
            } else {
                instance.closeHRISUploadModal(cb)
            }
        }
    );
};

export const getModifyProjectDataPayload = (data, response) => {
    const orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
    const projDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS);
    return {
        currentTab: PROJECTS_PAGE_SECTIONS.COHORT_SURVEY,
        organizationId: orgDetails.organizationId,
        projectId: response.data.projectId,
        configurationAffectedSurveys: [],
        isForCohortParent: true,
        updateDoc: { doc: { ...data } }
    }
}

export const getFetchHRISPayload = () => {
    let orgDetails = getDataFromUrlToken(URL_DATA.ORGANIZATION);
    return {
        currentTab: COHORT_SURVEY_SECTION.CREATE_COHORT_SURVEY,
        isFromNewSetUpTab: true,
        pagination: { from: 0, size: 100 },
        sortField: "uploadDate",
        sortOrder: "desc",
        ...orgDetails
    }
}

export const getHRISFileName = (id, instance) => {
    let idMatchingHrisFile = {}
    if (instance.props.surveyWizardHrisFIleApi.data &&
        instance.props.surveyWizardHrisFIleApi.data.response &&
        instance.props.surveyWizardHrisFIleApi.data.response.count > 0) {
        let response = deepCopy(instance.props.surveyWizardHrisFIleApi.data.response)
        idMatchingHrisFile = response.hrisFilesList.find(item =>
            item.hrisFileId === id)
    }
    if (idMatchingHrisFile.hrisFileName) {
        return idMatchingHrisFile.hrisFileName
    } else {
        return ""
    }
}

export const createProjectPayload = (instance) => {
    const orgData = getDataFromUrlToken(URL_DATA.ORGANIZATION);
    return {
        isForCohortParent: true,
        currentTab: PROJECTS_PAGE_SECTIONS.CREATE,
        projectName: instance.state.inputValue,
        organizationId: orgData && orgData.organizationId,
        projectStatus: "draft",
        questionCount: 0,
        hrisFileId: instance.state.selectedOption,
        projectType: COHORT_SURVEY_TYPE,
        childProjectType: instance.state.childProjectType
    }
}


export const generateQuestionSetBasedOnProject = (instance) => {
    if (instance.projectType === "Simple custom survey" || instance.projectType === "Very simple custom survey") {
        return []
    }
    else if (instance.projectType === "employee_experience_check") {
        return instance.employeeExperienceCheckGoals
    }
    else if (instance.projectType === "quick_pulse") {
        return instance.quickPulseGoals
    }
    else if (instance.projectType === FEEDBACK_360_SURVEY.DYNAMIC) {
        return instance.feedBack360Dynamic
    }
    else if (instance.projectType === FEEDBACK_360_SURVEY.DYNAMIC_180) {
        return instance.feedBack180Dynamic
    }
    else if (instance.projectType === FEEDBACK_360_SURVEY.DYNAMIC_360_APPLIED_MATERIALS) {
        return instance.feedback360AppliedMaterials
    }
    else if (instance.projectType === FEEDBACK_360_SURVEY.DYNAMIC_360_APPLIED_MATERIALS_DIRECTORS) {
        return instance.feedback360AppliedMaterialsDirector
    }
    return []
}

export const selectHRISOptionList= (data) => {
    const { hrisData, hrisFilesStatus, id,isFromCohort } = data;
    let employeeCount = hrisFilesStatus && hrisFilesStatus.employeeCount ? hrisFilesStatus.employeeCount : undefined;
    if (hrisData && hrisData.response && hrisData.response.hrisFilesList) {
        let HRISData = deepCopy(hrisData.response.hrisFilesList)
     
        if (isFromCohort) {
            HRISData = HRISData.filter(item => item.hrisFileType === "cohortHris");
        }

        let HRISOptionList = HRISData.map(item => {
            let foundHris
            let hrisStatus = false
            if(hrisFilesStatus && hrisFilesStatus.bulkJobStatus === "Completed"){

                if(item.hrisFileId === id){
                    foundHris = item
                    hrisStatus = true
                 }
            }
            return ({
                value: item.hrisFileId,
                label: item.hrisFileName,
                labelValue:
                    <div className="participants-block">
                        <p>{item.hrisFileName}</p>
                        <span>{hrisStatus ? getEmployeeCount(employeeCount) : getEmployeeCount(item.employeeCount)}</span>
                    </div >
            })
        })
        return HRISOptionList
    } else {
        return []
    }
}

const getEmployeeCount = (count) => {
    if (count) {
        return count + " people"
    } else {
        return "File is being processed"
    }
}