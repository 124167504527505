export const changeReadinessTemplate = [
    {
        questionDimension: 'Change Readiness',
        type: 'Matrix',
        questionLabel: 'Change Readiness',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: `I am confident that the ERP project will succeed.`, value: '', isSelected: false
        },
        {
            text: `My manager and peers speak positively about the ERP project.`, value: '', isSelected: false

        }]
    }
]

