import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const addReminderStarted = (state) => {
    return {
        ...state,
        addReminderApi: getApiStartState()
    }

}

export const addReminderSuccess = (state, payload) => {
    return {
        ...state,
        addReminderApi: getSuccessResponse(payload)
    }

}

export const addReminderFailed = (state, payload) => {
    return {
        ...state,
        addReminderApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}