export function fetchNLPHeatmapQuestionStarted(state, action) {
  return {
    ...state,
    nlpHeatmapQuestionApi: {
      ...state.nlpHeatmapQuestionApi,
      loading: true,
      error: false,
    },
    nlpHeatmapQuestionData: { ...state.nlpHeatmapQuestionData, ...action.data },
  };
}

export function fetchNLPHeatmapQuestionSuccess(state, action) {
  let dataMap = [...state.nlpHeatmapApi.data.data.dataMap];
  let nlpHeatMapApiData = dataMap.map((item) => {
    if (item.name === action.data.payload.dimension) {
      item.children = action.data.data.dataMap;
    }
    return item;
  });

  let nlpHeatMapApiObj = {...state.nlpHeatmapApi.data.data,dataMap:nlpHeatMapApiData};
  return {
    ...state,
    nlpHeatmapApi: {...state.nlpHeatmapApi, data: {data:nlpHeatMapApiObj}},
    nlpHeatmapQuestionApi: {
      loading: false,
      data: action.data,
      error: false,
    },
    nlpHeatmapQuestionData: { ...state.nlpHeatmapQuestionData, ...action.data },
  };
}

export function fetchNLPHeatmapQuestionFailed(state, action) {
  return {
    ...state,
    nlpHeatmapQuestionApi: {
      loading: false,
      error: true,
    },
  };
}
