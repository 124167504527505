import {
  setInputFormChange,
  checkIsFormValid,
} from "../../../../utils/reducerOperations";
export function inputAddUser(state, payload) {
  let configuration = setInputFormChange(state.searchBoxConfiguration, payload);
  return {
    searchBoxConfiguration: configuration,
    isFormValid: checkIsFormValid(configuration),
  };
}

export const hrisFileChange = (state, payload) => {
  if (typeof payload === "object") {
    payload = "";
  }
  return {
    ...state,
    hrisHRISFileValue: payload,
  };
};

export const onHrisUsersPaginationChange = (state, payload) => {
  return {
    ...state,
    hrisUsersPagination: payload,
  };
};
