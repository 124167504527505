import {
  apiFailureState,
  apiSuccessState,
  apiStartState,
} from "../../../utils/apiStateUtils";

export function fetchSentimentStart(state, action) {
  return {
    ...state,
    sentimentApi: {
      ...apiStartState(),
      data:
        action.from === 0
          ? null
          : state.sentimentApi.data != null
          ? state.sentimentApi.data
          : null,
    },
  };
}

export function fetchSentimentSuccess(state, action) {
  let commentsList = [];
  if (action.data.data) {
    if (state.sentimentApi.data != null) {
      commentsList = [...state.sentimentApi.data.data, ...action.data.data];
    } else {
      commentsList = action.data.data;
    }
  }

  return {
    ...state,
    sentimentApi: {
      loading: false,
      error: false,
      data: {
        ...action.data,
        data: commentsList,
      },
      data_count: action.count,
    },
    hasMore: commentsList.length === action.count || commentsList.length > action.count  ? false : true,
  };
}

export function fetchSentimentFail(state, action) {
  return {
    ...state,
    sentimentApi: apiFailureState(state.sentimentApi, action),
  };
}

export function batchIdFetchStart(state) {
  return {
    ...state,
    batchIdsApi: apiStartState(state.batchIdsApi),
  };
}

export function batchIdFetchSuccess(state, action) {
  return {
    ...state,
    batchIdsApi: apiSuccessState(state.batchIdsApi, action),
  };
}

export function batchIdFetchFail(state, action) {
  return {
    ...state,
    batchIdsApi: apiFailureState(state.batchIdsApi, action),
  };
}

export function fetchFiltersStart(state) {
  return {
    ...state,
    filtersApi: apiStartState(state.filtersApi),
  };
}

export function fetchFiltersSuccess(state, action) {
  action.data.forEach((item) => {
    if (item.value === "projectId")
      item.projectId = sortArray(item.projectId, "value");
    if (item.value === "topic") item.topic = sortArray(item.topic, "value");
  });
  return {
    ...state,
    filtersApi: apiSuccessState(state.filtersApi, action),
  };
}

export function fetchFiltersFail(state, action) {
  return {
    ...state,
    filtersApi: apiFailureState(state.filtersApi, action),
  };
}

export function updateFacetList(state, { main, value }) {
  const { data } = state.filtersApi;
  const facetGroup = data.find((filter) => filter.main === main);
  if (facetGroup) {
    const facet = facetGroup[main].find((item) => item.value === value);
    if (facet) {
      facet.selected = !facet.selected;
      if (facetGroup[main].find((facet) => facet.selected))
        facetGroup.selected = true;
      else facetGroup.selected = false;
    }
  }
  return {
    ...state,
    filtersApi: {
      ...state.filtersApi,
      data,
    },
  };
}

export function resetFacetList(state) {
  const { data } = state.filtersApi;
  data.forEach((facetGroup) => {
    facetGroup.selected = false;
    facetGroup[facetGroup.main].forEach((facet) => {
      facet.selected = false;
    });
  });
  return {
    ...state,
    filtersApi: {
      ...state.filtersApi,
      data,
    },
  };
}

export function mapSentimentToFilter(state, action) {
  const { data } = state.filtersApi;
  data.forEach((filter) => {
    filter[filter.value].forEach((item) => {
      if (action.newFilters[filter.value]) {
        if (action.newFilters[filter.value][item.value])
          item.count = action.newFilters[filter.value][item.value];
        else item.count = null;
      }
    });
  });
  return {
    ...state,
    filtersApi: {
      ...state.filtersApi,
      data,
    },
  };
}

function sortArray(array = [], field) {
  return array.sort((item1, item2) => {
    const a = item1[field];
    const b = item2[field];
    if (a > b) return 1;
    else if (a < b) return -1;
    else return 0;
  });
}

export function fetchSentimentTopicStart(state, action) {
  
  return {
    ...state,
    sentimentTopicApi: {
      loading: true,
      error: false,
      data: null,
      data_count: 0,
    }
  };
}

export function fetchSentimentTopicSuccess(state, action) {
  
  return {
    ...state,
    sentimentTopicApi: {
      loading: false,
      error: false,
      data: {
        ...action.data
      },
      data_count: action.count
    }
  };
}