import { deepCopy } from "../../../../utils/deepCopy"

export const behaviourDragDelete = (state, payload) => {
    let changedQuestionSet = deleteUpdatedQuestionset(state, payload)
    return ({
        ...state,
        questionSet: changedQuestionSet.questionSetCopy,
        pageBreakAddedQuestionSet: changedQuestionSet.pageBreakBlockCopy,
        hideErrorBlock: true,

    })
}

export const deleteUpdatedQuestionset = (state,payload)=>{
    let questionSetCopy = deepCopy(state.questionSet)
    let updatedPriority = []
    let pageBreakBlockCopy = deepCopy(state.pageBreakAddedQuestionSet)
    questionSetCopy.forEach((item, index) => {
        if (item.blockId === payload.blockId) {
            updatedPriority = item.questions.length > 0 && item.questions[0].answer ? JSON.parse(item.questions[0].answer) : []
            updatedPriority.forEach((answer)=>{
                if(answer.id === payload.selectedAnswer.id){
                    let findedIndex = pageBreakBlockCopy.findIndex((item) => item.blockId === payload.blockId)
                    let questionSetIndex = questionSetCopy.findIndex((item) => item.blockId === payload.blockId)
                    if (findedIndex !== -1) {
                       let draggedAnswer =  pageBreakBlockCopy[findedIndex].questions[0].answer
                       draggedAnswer = JSON.parse(draggedAnswer)
                       let updatedAnswer = draggedAnswer.filter(item => item.id !== answer.id);
                       updatedAnswer = JSON.stringify(updatedAnswer)
                       pageBreakBlockCopy[findedIndex].questions[0].answer = updatedAnswer
                        questionSetCopy[questionSetIndex].questions[0].answer = updatedAnswer
                    }
                }
            })
        }
    })
    return { questionSetCopy, pageBreakBlockCopy }

}