import {
    SETUP_SEND_PREVIEW_MAIL_START,
    SETUP_SEND_PREVIEW_MAIL_SUCCESS,
    SETUP_SEND_PREVIEW_MAIL_FAIL
} from '../../../constants';
import { SEND_PREVIEW_MAIL_URL } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { withType } from '../../../../utils/actionOperations';
import { toast } from '../../../../utils/messageUtils';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';


export const NewSurveyWizardSendPreviewMail = (payload, callback) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(SETUP_SEND_PREVIEW_MAIL_START, {}));
        apiBaseInstance.instance
            .post(SEND_PREVIEW_MAIL_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                dispatch(withType(SETUP_SEND_PREVIEW_MAIL_SUCCESS, {
                    data: response.data,
                    message: response.message
                }));
                toast.success(`Test email sent successfully to ${payload.recipientMailId} `)
                callback && callback(true);
            })
            .catch(err => {
                dispatch(withType(SETUP_SEND_PREVIEW_MAIL_FAIL, {
                    error: err
                }));
                callback && callback(false)
                toast.error("Error occuerd while sending preview email!")
            });
    };
};

