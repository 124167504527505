import HomeRouter from "../HomeRouter";
import AdminDashBoard from "../AdminDashBoard";
import SettingsPage from "../Settings";

export default {
  MAINFRAME_ROUTES: [
    {
      path: "/admin/home/organizations",
      name: "Organizations",
      component: HomeRouter,
    },
    {
      path: "/admin/home/settings",
      name: "Settings",
      component: SettingsPage,
    },
    {
      path: "/admin/home",
      name: "Home",
      component: AdminDashBoard,
    }
  ],
  organizations: "Organizations",
};
