import {
  makeKey,
  update,
  getSuccessResponse,
} from "../../../../utils/reducerOperations";
import { toast } from "../../../../utils/messageUtils";
export function deleteOrganizationSuccess(state, payload) {
  let deleteApi = update(state.deleteOrganizationApi, {
    ...getSuccessResponse(payload),
    organizationId: payload.organizationId,
  });
  toast.success("Organization deleted successfully");
  return {
    deleteOrganizationApi: deleteApi,
    fetchOrganizationApi: updateFetchOrganizationApi(state, deleteApi),
  };
}
function updateFetchOrganizationApi(state, deleteApi) {
  return makeKey(
    "data",
    update(
      state.fetchOrganizationApi.data,
      makeKey(
        "Items",
        filterDeletedOrganization(
          state.fetchOrganizationApi.data.Items,
          deleteApi.organizationId
        )
      )
    )
  );
}
function filterDeletedOrganization(Items, organizationId) {
  return Items.filter((org) => org.organizationId !== organizationId);
}
