export const fetchSurveyListStart = (state, payload) => {
  return {
    ...state,
    surveyListApi: { isProcessing: true, error: false, reason: '', surveyList: [] },
  };
};

export const fetchSurveyListSuccess = (state, payload) => {
  let data = (payload.data && payload.data.hits) || [];
  return {
    ...state,
    surveyListApi: {
      isProcessing: false,
      error: false,
      reason: '',
      surveyList: data,
    },
  };
};

export const fetchSurveyListFail = (state, payload) => {
  return {
    ...state,
    surveyListApi: {
      isProcessing: false,
      error: true,
      reason: payload.error,
      surveyList: [],
    },
  };
};


export const fetchCompletedSurveyListStart = (state, payload) => {
  return {
    ...state,
    completedSurveyListApi: { isProcessing: true, error: false, reason: '', surveyList: [] },
  };
};

export const fetchCompletedSurveyListSuccess = (state, payload) => {
  let data = (payload.data && payload.data.hits) || [];
  let total= (payload.data && payload.data.total) || [];
  return {
    ...state,
    completedSurveyListApi: {
      isProcessing: false,
      error: false,
      reason: '',
      surveyList: data,
      total:total
    },
  };
};

export const fetchCompletedSurveyListFail = (state, payload) => {
  return {
    ...state,
    completedSurveyListApi: {
      isProcessing: false,
      error: true,
      reason: payload.error,
      surveyList: [],
    },
  };
};
