import React from "react";

export const ResourceLoader = () => {
    return (
        <div class="resource-loading">
            <div class="resource-loading__item">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>)
}