export const fetchDemographicsDataStarted = (state) => {
  return {
    ...state,
    api: {
      isProcessing: true,
      error: false,
      errorMessage: "",
    },
    data: [],
    columns: [],
    selectedColumns: [],
  };
};

export const fetchDemographicsDataSuccess = (state, payload) => {
  const data = payload.data;
  const highestFavScore = getHighestFavScore(data.data, "favourable");
  const highestNeutralScore = getHighestFavScore(data.data, "neutral")
  const highestUnfavScore = getHighestFavScore(data.data, "unFavourable")
  const selectedColumns =
    data.selectedColumns && data.selectedColumns.length
      ? data.selectedColumns
      : data.data
      ? Object.keys(data.data)
      : [];
  return {
    ...state,
    api: {
      isProcessing: false,
      error: false,
      errorMessage: "",
      isInitialApiCalled: true,
    },
    data: getDimensionData(data.data) || [],
    columns: data.columns || {},
    selectedColumns,
    highestFavScore,
    highestNeutralScore,
    highestUnfavScore,
    filters: payload.filters,
    byScore:payload.byScore,
    multipleSelect: payload.multipleSelect,
    selectedUser: payload.selectedUser,
    directReporteesOnly: payload.directReporteesOnly,
  };
};

const getDimensionData = (data) => {
  let dimensionData = [];
  let averageScoreData = {};
  Object.keys(data || {}).forEach((item) => {
    if (item === "Average Score") {
      averageScoreData.key = item;
      averageScoreData.value = data[item].histogramData;
    } else dimensionData.push({ key: item, value: data[item].histogramData });
  });
  Object.keys(averageScoreData).length &&
    dimensionData.unshift(averageScoreData);
  return dimensionData;
};

const getHighestFavScore = (data = {}, scoreType) => {
  let highestFavScores = [];
  Object.keys(data).forEach((key) => {
    sortData(data[key].histogramData, scoreType);
    data[key].histogramData &&
      data[key].histogramData[0] &&
      data[key].histogramData.length &&
      highestFavScores.push(data[key].histogramData[0]);
  });
  let highestFavScore =
    highestFavScores.length &&
    highestFavScores.reduce((a, b) => (a[scoreType] > b[scoreType] ? a : b));
  return highestFavScore && highestFavScore[scoreType]
    ? highestFavScore[scoreType]
    : 0;
};


const sortData = (data, scoreType) => {
  data.sort((a, b) => {
    if (a[scoreType] > b[scoreType]) return -1;
    else if (a[scoreType] < b[scoreType]) return 1;
    return 0;
  });
};

export const fetchDemographicsDataFailed = (state, payload) => {
  return {
    ...state,
    api: {
      isProcessing: false,
      error: true,
      errorMessage: payload.errorMessage,
    },
    data: [],
    columns: [],
    selectedColumns: [],
  };
};

export const resetDemograhicValues = (state) => {
  return {
    ...state,
    api: {
      isProcessing: false,
      error: false,
      errorMessage: "",
      isInitialApiCalled: false,
    },
    data: [],
    columns: [],
    selectedColumns: [],
    dimensionsApi: {
      isProcessing: false,
      error: false,
      errorMessage: "",
      isInitialApiCalled: false,
      dimensions: [],
    },
  };
};

export const fetchDimensionsStarted = (state) => {
  return {
    ...state,
    dimensionsApi: {
      isProcessing: true,
      error: false,
      errorMessage: "",
      isInitialApiCalled: false,
      dimensions: [],
    },
  };
};

export const fetchDimensionsSuccess = (state, payload) => {
  const data = payload.data;
  return {
    ...state,
    dimensionsApi: {
      isProcessing: false,
      error: false,
      errorMessage: "",
      isInitialApiCalled: true,
      dimensions: data,
    },
    topic: payload.topic,
  };
};

export const fetchDimensionsFailed = (state, payload) => {
  return {
    ...state,
    dimensionsApi: {
      isProcessing: false,
      error: true,
      errorMessage: payload.errorMessage,
      isInitialApiCalled: false,
      dimensions: [],
    },
  };
};

export const updateDemographicReducer = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

export const fetchOutliersStarted = (state) => {
  return {
    ...state,
    exploreOutliersApi: {
      isProcessing: true,
      error: false,
      errorMessage: "",
      outliers: [],
    },
  };
};

export const fetchOutliersSuccess = (state, payload) => {
  const data = payload.data;
  return {
    ...state,
    exploreOutliersApi: {
      isProcessing: false,
      error: false,
      errorMessage: "",
      outliers: data,
    },
  };
};

export const fetchOutliersFailed = (state, payload) => {
  return {
    ...state,
    exploreOutliersApi: {
      isProcessing: false,
      error: true,
      errorMessage: payload.errorMessage,
      outliers: [],
    },
  };
};
