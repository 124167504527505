import { setInitialState } from "../../../../utils/reducerOperations";
export default {
  initialState: {
    exploreWordCloudDataApi: {
      isProcessing: false,
      data: [],
      error: false,
      message: "",
    },
    explorecommentStatisticApi: setInitialState(),
    exploreQuestionCommentsApi: {
      isProcessing: false,
      data: [],
      totalComments: 0,
      error: false,
      message: "",
    },
    exploreRelationDataApi: {
      isProcessing: false,
      data: [],
      error: false,
      message: "",
    },

    allThemeDataApi: {
      isProcessing: false,
      data: [],
      error: false,
      message: "",
    },
    themeTopicDataApi: {
      isProcessing: false,
      data: [],
      columns: [],
      selectedColumns: [],
      error: false,
      message: "",
      isInitialApiCalled: false,
    },
    isDataAlreadyFetched : false,
    selectedTopic : "All Themes"
  },
  filters : {},
  byScore:[],
  multipleSelect: [],
  selectedUser : "",
  directReporteesOnly: false,
};
