import { SURVEY_RESULT_PPT_GENERATOR_STATUS } from "../../../../../config/constants";
import { withType } from "../../../../utils/actionOperations";
import { ApiBase } from "../../../../utils/apiBase";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';
import { EXPORT_PPT_STATUS_FAIL, EXPORT_PPT_STATUS_START, EXPORT_PPT_STATUS_SUCCESS } from "../../../constants";

export const downloadPPTStatus =(params,cb)=>{
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
      dispatch(withType(EXPORT_PPT_STATUS_START, params));
      apiBaseInstance.instance
        .post(SURVEY_RESULT_PPT_GENERATOR_STATUS, { ...params, ...getProjectIdAndOrgId(params) })
        .then((response) => {
          downloadPptIfSuccess(dispatch, response, cb, params)
        })
        .catch((err) => {
          dispatch(
            withType(EXPORT_PPT_STATUS_FAIL, {
              error: err,
            })
          );
          cb && cb(false);
        });
    };
}

function downloadPptIfSuccess(dispatch, response, cb, params) {
  if (response && response.data && response.data.data && response.data.data.pptUrl) {
    const data = response.data.data.pptUrl;
    const fileName = `${params.projectName}-report.pptx`;

    // Fetch the file data
    fetch(data)
      .then(response => response.blob())
      .then(blob => {
        const blobType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        const blobOptions = { type: blobType };

        // Create a link element
        const a = document.createElement('a');

        // Create a URL for the Blob
        const url = URL.createObjectURL(new Blob([blob], blobOptions));

        // Set the href and download attributes
        a.href = url;
        a.download = fileName;

        // Append the link to the body, click it, and remove it
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Release the object URL
        URL.revokeObjectURL(url);

        dispatch(withType(EXPORT_PPT_STATUS_SUCCESS, {
          data: response.data,
          message: response.message
        }));

        cb && cb(response);
      })
      .catch(error => {
        console.error('Error fetching or processing PPT file:', error);
        cb && cb(false);
      });
  } else {
    cb && cb(false);
  }
}