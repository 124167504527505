import {
  LOGIN_PROCESS_STARTED,
  LOGIN_PROCESS_SUCCESS,
  LOGIN_PROCESS_FAILED,
} from "../../constants";

import { LOGIN_URL } from "../../../../config/constants";
import { ApiBase } from "../../../utils/apiBase";
import { withType } from "../../../utils/actionOperations";

export const loginAction = (params,cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(LOGIN_PROCESS_STARTED));
    return apiBaseInstance.instance
      .post(LOGIN_URL, params)
      .then((res) => {
        dispatch(withType(LOGIN_PROCESS_SUCCESS, res.data));
        cb&&cb(true,res.data)
      })
      .catch((err) => {
        dispatch(withType(LOGIN_PROCESS_FAILED, err));
        cb&&cb(false)
      });
  };
};

