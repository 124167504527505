import {
    makeKey,
    update,
    getSuccessResponse,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
export const fetchScoresTableSuccess = (state, payload) => {
    return makeKey(
        'fetchScoresTableApi',
        update(state.fetchScoresTableApi,
            getSuccessResponse(payload))
    );
};

export const fetchScoresTableStarted=(state, payload)=>{
    return makeKey(
        'fetchScoresTableApi',
        update(state.fetchScoresTableApi, {
            isProcessing: true
        })
    );
};

export const fetchScoresTableFailed=(state, payload)=>{
    let response = makeKey(
        'fetchScoresTableApi',
        update(state.fetchScoresTableApi, {
            ...(payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload))
        })
    );

    return response;
};