export default {
  buttonText: "Upload", //'Process',
  loaderButton: {
    classes: ["loader-button"],
    loaderClass: ["xs white"],
  },
  homePagePath: "/admin/home/organizations",
  topicLibraryOptions:[],
  firstNameLabel: "Your first name",
  lastNameLabel: "Your last name",
  emailLabel: "Your email address",
  currentPasswordLabel: "Current password",
  newPasswordLabel: "Choose a new password",
  confirmPasswordLabel: "Confirm new password",
  submitButton: {
    classess: [" loader-button"],
    loaderClass: ["xs white"],
    text: "Save",
  },
  changePasswordSubmitText: "Save",
  cancel: "Cancel",
  clearButton: "Clear"
};
