import { notification } from "antd";
import * as actionTypes from "../../constants";
import Axios from "axios";
import constants from "./constants";
import { UPLOAD_FILE } from "../../../../config/constants";
import {
  GetLocalStorage, getOrgId,
} from "../../../utils/localStorgeOperations";
import { getUserRole } from '../../../utils/rolePermissionUtils';

export function uploadHrisFile(file) {
  const { userId, userRole } = constants;
  let formData = new FormData();
  formData.append(constants.file, file);
  formData.append(userRole, getUserRole(getOrgId()));
  formData.append(userId, GetLocalStorage(userId));
  return (dispatch) => {
    dispatch({ type: actionTypes.UPLOAD_HRIS_FILE_START });
    Axios.post(UPLOAD_FILE, formData, constants.formDataHeader)
      .then((res) => uploadHrisSuccess({ dispatch, res, file }))
      .catch((err) => uploadHrisFailed({ dispatch, err }));
  };
}

function uploadHrisSuccess({ dispatch, res, file }) {
  notification.success({
    message: constants.fileUpoladSuccess,
  });
  dispatch({
    type: actionTypes.UPLOAD_HRIS_FILE_SUCCESS,
    data: res.data,
    fileName: file.name,
  });
}

function uploadHrisFailed({ dispatch, err, field }) {
  notification.error({
    message: constants.fileUploadFail,
  });
  dispatch({ type: actionTypes.UPLOAD_HRIS_FILE_FAIL });
}
