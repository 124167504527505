import queryString from 'query-string';

export function getFacetFields(dataArray) {
    let query = window.location.search;
    return Object.keys(dataArray||{}).map(key => {
        let array = {
            [key]: makeSubmenuArray(
                dataArray[key].buckets, key
            ),
            main: key,
            value: key,
            selected: fieldVerificationRoot(query, key)
        };
        return array;
    });
}

function makeSubmenuArray(submenuArray=[], rootKey) {
    let queryStr = window.location.search;
    return submenuArray.map(menu => ({
        value: menu.key,
        selected: fieldVerificationChild(
            { queryStr, rootKey, key: menu.key })
    }));
}

export const fieldVerificationRoot = (payload, key) => {
    let query = queryString.parse(payload);
    if (query.leftFilters) {
        let decoded = JSON
            .parse(decodeURIComponent(query.leftFilters));
        if (decoded[key]) return true;
    }
    else return 0;
};

export const fieldVerificationChild = (
    { queryStr, rootKey, key }) => {
    let query = queryString.parse(queryStr);
    if (query.leftFilters) {
        let decoded = JSON
            .parse(decodeURIComponent(query.leftFilters));
        if (decoded[rootKey])
            if (decoded[rootKey]
                .includes(key)) return true;
    }
    else return 0;
};