import {
  FETCH_BENCHMARK_DATA_START,
  FETCH_BENCHMARK_DATA_SUCCESS,
  FETCH_BENCHMARK_DATA_FAIL,
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { FETCH_BENCH_MARK } from "../../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const NewdashboardFetchBenchMarkData = (payload, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(FETCH_BENCHMARK_DATA_START, {}));
    apiBaseInstance.instance
      .post(FETCH_BENCH_MARK, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        dispatch(
          withType(FETCH_BENCHMARK_DATA_SUCCESS, {
            data: response.data,
            projectDetails: { questionSet: payload.questionSet, projectType: payload.projectType },
            message: response.message,
          })
        );
        cb && cb();
      })
      .catch((err) => {
        dispatch(
          withType(FETCH_BENCHMARK_DATA_FAIL, {
            error: err,
          })
        );
      });
  };
};
