import Axios from 'axios';
import * as actionTypes from '../../constants';
import { notification } from 'antd';
import constants from './constants';
import { SAMPLE_COMMENTS_URL } from '../../../../config/constants';
import { blobDownload } from '../../../utils/blobDownload';
import { NLPCommonReqBodies } from '../../../utils/actionOperations';

export function downloadSampleComments() {
    return dispatch => {
        dispatch({ type: actionTypes.DOWNLOAD_SAMPLE_COMMENT_START });
        Axios
            .get(SAMPLE_COMMENTS_URL,NLPCommonReqBodies())
            .then(response => sampleDownloadSuccess(dispatch, response))
            .catch(e =>downloadFailed(dispatch,e));
    };
}

function sampleDownloadSuccess(dispatch, response) {
    blobDownload({data:response.data,fileName:constants.sampleCommentFileName});
    dispatch({
        type: actionTypes.DOWNLOAD_SAMPLE_COMMENT_SUCCESS
    });
}

function downloadFailed(dispatch, error) {
    notification.error({
        message:constants.downloadingFailed,
        description:error.response&&error.response.data
    });

    dispatch({
        type: actionTypes.DOWNLOAD_SAMPLE_COMMENT_FAIL
    });
}