import { fetchPayload } from "../../../utils/reducerOperations";
import {
    ADD_REMINDER_FAILED,
    ADD_REMINDER_START,
    ADD_REMINDER_SUCCESS,
    EDIT_REMINDER_FAILED,
    EDIT_REMINDER_START,
    EDIT_REMINDER_SUCCESS,
    LIST_REMINDER_FAILED,
    LIST_REMINDER_START,
    LIST_REMINDER_SUCCESS
} from "../../constants";
import {
    addReminderStarted,
    addReminderFailed,
    addReminderSuccess,
    editReminderFailed,
    editReminderStarted,
    editReminderSuccess,
    listReminderFailed,
    listReminderStarted,
    listReminderSuccess
} from "./Helpers";
import { Default } from "./InitialState";
const SurveyReminderReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        //add
        case ADD_REMINDER_START:
            return addReminderStarted(state, payload);
        case ADD_REMINDER_SUCCESS:
            return addReminderSuccess(state, payload);
        case ADD_REMINDER_FAILED:
            return addReminderFailed(state, payload);
        //edit
        case EDIT_REMINDER_START:
            return editReminderStarted(state, payload);
        case EDIT_REMINDER_SUCCESS:
            return editReminderSuccess(state, payload);
        case EDIT_REMINDER_FAILED:
            return editReminderFailed(state, payload);
        //list
        case LIST_REMINDER_START:
            return listReminderStarted(state, payload);
        case LIST_REMINDER_SUCCESS:
            return listReminderSuccess(state, payload);
        case LIST_REMINDER_FAILED:
            return listReminderFailed(state, payload);
        default:
            return { ...state };
    }
};

export default SurveyReminderReducer;
