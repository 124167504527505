export const fetchAllThemeStart = (state, payload) => {
  return {
    ...state,
    [payload.questionId]:{
      ...state[payload.questionId],
    allThemeDataApi: {
      isProcessing: true,
      data: [],
      error: false,
      message: "",
    },}
  };
};

export const fetchAllThemeSuccess = (state, payload) => {
  const data = formatResponse(payload.data);
  return {
    ...state,
    [payload.questionId]:{
      ...state[payload.questionId],
      allThemeDataApi: {
        isProcessing: false,
        data,
        error: false,
        message: "",
      },
      isDataAlreadyFetched : true,
      filters: payload.filters,
      byScore: payload.byScore,
      multipleSelect: payload.multipleSelect,
      selectedUser: payload.selectedUser,
      directReporteesOnly: payload.directReporteesOnly,
    },
  };
};

export const fetchAllThemeFailed = (state, payload) => {
  return {
    ...state,
    [payload.questionId]:{
      ...state[payload.questionId],
    allThemeDataApi: {
      isProcessing: false,
      data: [],
      error: true,
      message: payload.error,
    },
  }
  };
};

const formatResponse = (data) => {
  if (data && data.histogramData && data.histogramData.length) {
    const totalCommentsCount = data.filteredCommentsCount;
    let others;
    let formattedHistogramData = [];
    data.histogramData.forEach((item) => {
      if (item.node === "Other") others = item;
      else formattedHistogramData.push(item);
    });
    others && formattedHistogramData.push(others);
    const highestCount = data.histogramData[0].count;
    const highestCommentCount = Math.round(
      (highestCount / totalCommentsCount) * 100
    );
    let multipiler=1
    if(highestCommentCount>80){
      multipiler=100
    }else{
      multipiler=75
    }
    const formattedData = formattedHistogramData.map((item) => {
      let value = Math.round((item.count / totalCommentsCount) * 100);
      let graphData = {
        label: item.node,
        subLabel: `${item.count} Comments`,
        value: `${value}%`,
        width: Math.round((value / highestCommentCount) * multipiler),
        threshold: item.count,
      };
      return graphData;
    });
    return formattedData;
  }
  return [];
};
