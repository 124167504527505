import { connect } from "react-redux";
import { SelectState } from "../Selector";
import People from "./People";
import {
  fetchHRIS,
  fetchUsers,
  fetchHrisHierarchy,
  onUsersHrisPaginationChange,
} from "../../../redux/actions";
import {
  peopleSearch,
  clearPeopleSearch,
} from "../../../redux/actions/AddAdminPageAction/peopleListSearch";

const mapStateToProps = (state) => ({ ...SelectState(state) });

const mapDispatchToProps = (dispatch) => ({
  fetchHRIS: (params, hrisFileId) => dispatch(fetchHRIS(params, hrisFileId)),
  fetchUsers: (params) => dispatch(fetchUsers(params)),
  fetchHrisHierarchy: (params) => dispatch(fetchHrisHierarchy(params)),
  onUsersHrisPaginationChange: (params) =>
    dispatch(onUsersHrisPaginationChange(params)),
  peopleSearch: (params) => {
    dispatch(peopleSearch(params));
  },
  clearPeopleSearch: (params) => {
    dispatch(clearPeopleSearch(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(People);
