import { fetchFormConfigurations } from '../../inputFormConfigurations';
export function Default() {
    return {
        isFormValid: false,
        exportFormConfiguration: getCustomConfiguration()
    };
}

function getCustomConfiguration() {
    let configuration = fetchFormConfigurations([
        'email'
    ]);
    configuration[0].value = ""
    return configuration;
}
