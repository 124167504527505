import {
    FETCH_COHORT_SURVEY_DATA_START,
    FETCH_COHORT_SURVEY_DATA_SUCCESS,
    FETCH_COHORT_SURVEY_DATA_FAIL
} from "../../../constants";
import {
    LIST_SURVEY_URL,
} from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchCohortSurveyList = (payload, cb) => {
    const apiBaseInstance = new ApiBase().instance;
    return (dispatch) => {
        dispatch(
            withType(FETCH_COHORT_SURVEY_DATA_START, {})
        );
        return apiBaseInstance
            .post(LIST_SURVEY_URL, {
                ...payload, ...getProjectIdAndOrgId(payload)
            })
            .then((res) => {
                dispatch(
                    withType(FETCH_COHORT_SURVEY_DATA_SUCCESS, {
                        data: res.data,
                        payload: payload
                    })
                );
                cb && cb()
            })
            .catch((err) => {
                dispatch(withType(FETCH_COHORT_SURVEY_DATA_FAIL, { err, payload: payload }));
                cb && cb()
            });
    };
};
