import {
  apiStartState,
  apiFailureState,
  apiSuccessState,
} from "../../../../utils/apiStateUtils";
import { sortHrisFileList } from "../../../../utils/reducerOperations";

export function fetchHrisFileStart(state, action) {
  return {
    ...state,
    fetchHrisFIleApi: apiStartState(state.fetchHrisFIleApi),
  };
}
export function fetchHrisFileSuccess(state, action) {
  const newPayload = sortHrisFileList(action.payload);
  return {
    ...state,
    fetchHrisFIleApi: apiSuccessState(state.fetchHrisFIleApi, newPayload),
  };
}
export function fetchHrisFileFail(state, action) {
  return {
    ...state,
    commentsFileApi: apiFailureState(state.fetchHrisFIleApi, action),
  };
}
