export const fetchTrendVisualisationQuestionsStart = (state, payload) => {
    return {
        ...state,
        trendVisualisationDataQuestions: {
            ...state.trendVisualisationDataQuestions,
            isProcessing: true,
            error: false, reason: '',
        },
    };
};

export const fetchTrendVisualisationQuestionsSuccess = (state, payload) => {
    return {
        ...state,
        trendVisualisationDataQuestions: {
            isProcessing: false,
            error: false,
            reason: '',
            data: payload.data.data.response,
        },
        isTrendVisualisationDataFetched: true,
        byScore: payload.payload.byScore,
        multipleSelect: payload.payload.multipleSelect,
        filters: payload.payload.filters,
        selectedUser: payload.payload.selectedUser,
        directReporteesOnly: payload.payload.directReporteesOnly
    };
};

export const fetchTrendVisualisationQuestionsFail = (state, payload) => {
    return {
        ...state,
        trendVisualisationDataQuestions: {
            ...state.trendVisualisationDataQuestions,
            isProcessing: false,
            error: true,
            reason: payload.error,
        },
        isTrendVisualisationDataFetched: true,
        byScore: payload.payload.byScore,
        multipleSelect: payload.payload.multipleSelect,
        filters: payload.payload.filters,
        selectedUser: payload.payload.selectedUser,
        directReporteesOnly: payload.payload.directReporteesOnly
    };
};
