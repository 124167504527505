import { Default } from './initialState';
import {
    update,
    fetchPayload
} from '../../../utils/reducerOperations';
import * as helper from './helper';
import {
    FETCH_OUTLIERS_DATA_FAIL,
    FETCH_OUTLIERS_DATA_START,
    FETCH_OUTLIERS_DATA_SUCCESS
} from '../../constants';

const OutliersPageReducer = (state=Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
    case FETCH_OUTLIERS_DATA_START:
        return update(state,
            helper.fetchOutliersStarted(state, payload)
        );
    case FETCH_OUTLIERS_DATA_SUCCESS:
        return update(state,
            helper.fetchOutliersTableSuccess(state, payload)
        );
    case FETCH_OUTLIERS_DATA_FAIL:
        return update(state,
            helper.fetchOutliersFailed(state, payload)
        );
    default: return {...state};
    }
};

export default OutliersPageReducer;