import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const exploreTopicCommentsStart = (state, payload) => {
    return {
        ...state,
        exploreTopicCommentsApi: getApiStartState()
    }
}

export const exploreTopicCommentsSuccess = (state, payload) => {
    return {
        ...state,
        exploreTopicCommentsApi: getSuccessResponse(payload && payload.data),
    }
}

export const exploreTopicCommentsFailed = (state, payload) => {
    return {
        ...state,
        exploreTopicCommentsApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }
}