import { customFormChangeSuccess } from "../../../../utils/reducerOperations";
import {  apiInitialStateAddFn } from "../../../../utils/apiStateUtils";
import { fetchFormConfigurations } from "../../../inputFormConfigurations";
export function adminFormChange(state, payload) {
  return customFormChangeSuccess(
    { state: state, key: "addAdminBoxConfiguration" },
    payload
  );
}

export function clearAdminForm(state, payload) {
  return {
    ...state,
    addAdminApi: apiInitialStateAddFn(),
    isFormValid: false,
    addAdminBoxConfiguration: fetchFormConfigurations([
      "adminFirstName",
      "adminLastName",
      "adminEmail",
      "role",
    ]),
  };
}

export const addAdminPaginationChange = (state, payload) => {
  return {
    ...state,
    addAdminPagination: payload,
  };
};
