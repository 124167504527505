export function fetchOverviewSuccess(state, action) {
  return {
      ...state,
      overviewApi:{error:false,loading:false,data:action.data},
  };
}

export function fetchOverviewStart(state, action) {

  return {
      ...state,
      overviewApi:{loading:true,error:false}
  };
}

export function fetchOverviewFail(state, action) {
  return {
      ...state,
      overviewApi:{loading:false,error:true}
  };
};

export function fetchOverviewResultSuccess(state, action) {
  return {
      ...state,
      overviewResultApi:{error:false,loading:false,data:action.data},
  };
}

export function fetchOverviewResultStart(state, action) {

  return {
      ...state,
      overviewResultApi:{loading:true,error:false}
  };
}

export function fetchOverviewResultFail(state, action) {
  return {
      ...state,
      overviewResultApi:{loading:false,error:true}
  };
};

