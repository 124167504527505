import { deepCopy } from "../../../../../../utils/deepCopy"
import { getProjectDataForApiCall } from "../../../Heatmap/constants"

export const createDropDownData = (questionSet, isDataCollectionUpload, isFromDemographics, generateTreeDataQuestions) => {
    let topicsData = []
    let questionsData = []
    let topicIdData = []
    if (isDataCollectionUpload) {
        const { updatedQuestionSet, dimensionIds } = getUpdatedData(questionSet)
        for (let key in updatedQuestionSet) {
            if (updatedQuestionSet[key][0].mappingTopics && updatedQuestionSet[key][0].mappingTopics.length) {
                let generatedObject = isFromDemographics ? {
                    value: updatedQuestionSet[key][0].dimensionsId,
                    label: updatedQuestionSet[key][0].questionDimension,
                    isDemographicsTopic: true,
                } : {
                    value: updatedQuestionSet[key][0].dimensionsId,
                    title: updatedQuestionSet[key][0].questionDimension,
                    isTopic: true,
                }
                topicsData.push(generatedObject)
            }

            if (generateTreeDataQuestions && updatedQuestionSet[key].length) {
                let childrenData = []
                let treeDataQuestion = {
                    title: updatedQuestionSet[key][0].questionDimension,
                    value: updatedQuestionSet[key][0].questionDimension,
                    children: []
                }
                updatedQuestionSet[key].forEach(child => {
                    if (child.mappingQuestions && child.mappingQuestions.length) {
                        childrenData.push({ title: child.question, value: child.staticQuestionId })
                    }
                })

                treeDataQuestion.children = childrenData
                if (treeDataQuestion.children.length > 0) {
                    questionsData.push(treeDataQuestion)
                }


            }
            else {
                if (updatedQuestionSet[key].length) {
                    updatedQuestionSet[key].forEach(item => {
                        if (item.mappingQuestions && item.mappingQuestions.length) {
                            let questionObject = isFromDemographics ? { value: item.staticQuestionId, label: item.question } : {
                                value: item.staticQuestionId, title: item.question
                            }
                            questionsData.push(questionObject)
                        }
                    })
                }
            }

        }
    } else {
        questionSet.forEach(question => {
            if (question.isDefault || question.isCustom) {
                if (question.mappingTopics && question.mappingTopics.length) {
                    let nonDataCollectionTopics = isFromDemographics ? {
                        value: question.questions[0].matrixQuestions[0].dimensionsId,
                        label: question.dimension,
                        isTopic: true,
                    } : {
                        value: question.questions[0].matrixQuestions[0].dimensionsId,
                        title: question.dimension,
                        isTopic: true,
                    }
                    topicsData.push(nonDataCollectionTopics)
                }
                question.questions[0].matrixQuestions.forEach(matrix => {
                    if (matrix.mappingQuestions && matrix.mappingQuestions.length) {
                        let nonDataCllectionTopics = isFromDemographics ? { value: matrix.staticQuestionId, label: matrix.text } : { value: matrix.staticQuestionId, title: matrix.text }
                        questionsData.push(nonDataCllectionTopics)
                    }
                })
            }
        });
    }
    return { topicsData, questionsData }
}

const getUpdatedData = (questionSet) => {
    let updatedQuestionSet = {}
    let dimensionIds = []
    questionSet[0].questions.forEach(question => {
        if (question.type === "Multiple Choice") {
            dimensionIds.push(question.dimensionsId)
            if (updatedQuestionSet[question.dimensionsId]) {
                updatedQuestionSet[question.dimensionsId].push(question)
            } else {
                updatedQuestionSet[question.dimensionsId] = [question]
            }
        }
    })
    dimensionIds = [...new Set(dimensionIds)]
    return { updatedQuestionSet, dimensionIds }
}

const checkDisable = (question) => {
    let disableClick = true
    if (question.mappingTopics && Array.isArray(question.mappingTopics) && question.mappingTopics.length > 0) {
        disableClick = false
    }
    return disableClick
}

export const checkIfShowEmptyState = (menu) => {
    if (menu.length >= 2) {
        return false
    } else if (menu.children.length > 1) {
        return false
    }
    return true
}

export const getSelectedMenus = (topicsData, questionsData, alreadyfetrched, props) => {
    if (alreadyfetrched) {
        return { topics: props.selectedTopics, questions: props.selectedQuestions }
    }
    let topics = []
    let questions = []
    topicsData.forEach(topic => {
        if (topics.length < 2) {
            topics.push(topic.value)
        }
    })
    questionsData.forEach(question => {
        question.children && question.children.forEach((child) => {
            if (questions.length < 2) {
                questions.push(child.value)
            }
        })

    })
    return { topics, questions }
}

export const getAPIPayload = (surveyDetails) => {
    let payload = { ...getProjectDataForApiCall(surveyDetails) }
    return payload
}

export const showDataSection = (data) => {
    let updatedData = []
    if (!Array.isArray(data) || data.length <= 1) {
        return false
    } else {
        data.forEach(item => {
            if (updatedData.includes(item.surveyName)) {

            } else {
                updatedData.push(item.surveyName)
            }
        })
        return updatedData.length > 0 ? true : false
    }
}

export const replaceCurrentSurveyWithName = (data, projectName, answer) => {
    let markedOptions = []
    if (answer && answer.length > 0) {
        answer.forEach((options) => {
            let splittedItem = options.split(":")
            if (splittedItem[1]) {
                markedOptions.push(splittedItem[1])
            }
        })
    }
    let dataCopy = deepCopy(data)
    dataCopy.forEach(item => {
        if (item.surveyName === "current_survey") {
            item.surveyName = projectName
        }
    })
    let filteredData = dataCopy.filter((data) => markedOptions.includes(data.label))

    return answer && markedOptions.length > 0 ? filteredData : dataCopy
}

export const generateDataForExcel = ({ trendData, projectName, key, instance, dropdownData }) => {
    let dataCopy = deepCopy(trendData)
    if (key === "Demographics-Topics" || key === "Demographics-Questions") {
        return getDemographicsTopicsExport({ trendData: dataCopy, dropdownData, instance, key, projectName })
    }
    let coloumnData = [
        {
            "title": key,
            "key": "label",
            "dataIndex": "label"
        },]

    let { data, childrenData } = getColumnData(dataCopy, projectName)
    coloumnData = [...coloumnData, ...childrenData]
    return { columns: coloumnData, data: data }

}

const getDemographicsTopicsExport = ({ trendData, dropdownData, instance, key, projectName }) => {
    let selectedTreeKey = key === "Demographics-Topics" ? "selectedDemographicsTopicTree" : "selectedDemographicsQuestionTree"
    let selectedDemographicsKey = key === "Demographics-Topics" ? "demographicsSelectedTopics" : "demographicsSelectedQuestions"
    let titleContent = dropdownData.find((data) => data.value === instance[selectedDemographicsKey])
    let selectedDemographics = instance[selectedTreeKey][0] && instance[selectedTreeKey][0].split(":")[1] ? instance[selectedTreeKey][0].split(":")[0] : instance[selectedTreeKey][0]
    let coloumnData = [
        {
            "title": titleContent.label,
            "key": "sample",
            "dataIndex": "sample",
            children: [{
                "title": selectedDemographics,
                "key": "label",
                "dataIndex": "label"
            }]
        },]

    let { data, childrenData } = getColumnData(trendData, projectName)

    coloumnData[0].children = [...coloumnData[0].children, ...childrenData]
    return { columns: coloumnData, data: data }
}

const getColumnData = (trendData, projectName) => {
    let excelData = []
    let childrenData = []

    trendData.forEach((item) => {
        let alreadyPresent = childrenData.findIndex(col => col.key === item.surveyName)
        if (alreadyPresent === -1) {
            let coloumn = {
                title: item.surveyName === "current_survey" ? projectName : item.surveyName,
                key: item.surveyName,
                dataIndex: item.surveyName
            }
            childrenData.push(coloumn)
        }
    })
    trendData.forEach((excelRow) => {
        let isRowExist = excelData.findIndex(findedRow => findedRow.label === excelRow.label)
        if (isRowExist === -1) {
            let filterData = trendData.filter((filter) => filter.label === excelRow.label)
            let row = {}
            if (filterData && filterData.length > 0) {
                filterData.forEach((rowData) => {
                    row = { ...row, label: rowData.label, [rowData.surveyName]: rowData.trendScore }
                })
            }
            excelData.push(row)
        }


    })
    return { data: excelData, childrenData }
}