import {
  FETCH_TREND_VISUALIZATION_DATA_START,
  FETCH_TREND_VISUALIZATION_DATA_SUCCESS,
  FETCH_TREND_VISUALIZATION_DATA_FAIL,
  SET_TOPICS_QUESTIONS_TREND,
  SET_DEMOGRAPHICS_TOPICS_QUESTIONS_TREND,
  SET_DEMOGRAPHICS_TREE_DATA_SELECT,
  FETCH_TREND_DEMOGRAPHICS_VISUALIZATION_DATA_START,
  FETCH_TREND_DEMOGRAPHICS_VISUALIZATION_DATA_SUCCESS,
  FETCH_TREND_DEMOGRAPHICS_VISUALIZATION_DATA_FAIL,
  FETCH_TREND_DEMOGRAPHICS_QUESTIONS_VISUALIZATION_DATA_START,
  FETCH_TREND_DEMOGRAPHICS_QUESTIONS_VISUALIZATION_DATA_SUCCESS,
  FETCH_TREND_DEMOGRAPHICS_QUESTIONS_VISUALIZATION_DATA_FAIL
} from "../../constants";
import { Default } from "./InitialState/index";
import {
  fetchTrendVisualisationStart,
  fetchTrendVisualisationSuccess,
  fetchTrendVisualisationFail,
  setTrendDemographicsTopicsQuestions,
  setTrendTreeSelectTopicQuestions,
  fetchTrendDemographicsVisualisationStart,
  fetchTrendDemographicsVisualisationSuccess,
  fetchTrendDemographicsVisualisationFail,
  fetchTrendVisualisationQuestionsStart,
  fetchTrendVisualisationQuestionsSuccess,
  fetchTrendVisualisationQuestionsFail
} from "./Helpers";
import { setTrendTopicsQuestions } from "./Helpers/setTrendTopicsAndQuestiosn";

const TrendVisualisationReducer = (state = Default(), action) => {
  switch (action.type) {
    case FETCH_TREND_VISUALIZATION_DATA_START:
      return fetchTrendVisualisationStart(state, action.payload);
    case FETCH_TREND_VISUALIZATION_DATA_SUCCESS:
      return fetchTrendVisualisationSuccess(state, action.payload);
    case FETCH_TREND_VISUALIZATION_DATA_FAIL:
      return fetchTrendVisualisationFail(state, action.payload);

    case SET_TOPICS_QUESTIONS_TREND:
      return setTrendTopicsQuestions(state, action.payload);

    case SET_DEMOGRAPHICS_TOPICS_QUESTIONS_TREND:
      return setTrendDemographicsTopicsQuestions(state, action.payload)
    case SET_DEMOGRAPHICS_TREE_DATA_SELECT:
      return setTrendTreeSelectTopicQuestions(state, action.payload)

    case FETCH_TREND_DEMOGRAPHICS_VISUALIZATION_DATA_START:
      return fetchTrendDemographicsVisualisationStart(state, action.payload);
    case FETCH_TREND_DEMOGRAPHICS_VISUALIZATION_DATA_SUCCESS:
      return fetchTrendDemographicsVisualisationSuccess(state, action.payload);
    case FETCH_TREND_DEMOGRAPHICS_VISUALIZATION_DATA_FAIL:
      return fetchTrendDemographicsVisualisationFail(state, action.payload);

    case FETCH_TREND_DEMOGRAPHICS_QUESTIONS_VISUALIZATION_DATA_START:
      return fetchTrendVisualisationQuestionsStart(state, action.payload);
    case FETCH_TREND_DEMOGRAPHICS_QUESTIONS_VISUALIZATION_DATA_SUCCESS:
      return fetchTrendVisualisationQuestionsSuccess(state, action.payload);
    case FETCH_TREND_DEMOGRAPHICS_QUESTIONS_VISUALIZATION_DATA_FAIL:
      return fetchTrendVisualisationQuestionsFail(state, action.payload);

    default:
      return { ...state };
  }
};

export default TrendVisualisationReducer;
