import { 
    getSuccessResponse, handleApiResponseError, handleNetworkError,
     
} from '../../../utils/reducerOperations';
import { notification } from 'antd';

export function sendMailStart(state,action){
    return{
        ...state,
        sendEmailApi:{
            data:null,
            isProcessing:true,
            error:false,
            message:null
        }
    };
}

export function sendMailSuccess(state,action){
    return{
        ...state,
        sendEmailApi:getSuccessResponse(action.response.data)
    };
}

export function sendMailfail(state,action){
    notification.error({message:'Email sending failed'});
    return{
        ...state,
        sendEmailApi:
        (action.response 
            && action.response.response
            && action.response.response.data
            ? handleApiResponseError(action.response.response.data)
            : handleNetworkError(action.response))
    };
}

export function updateEmailForm (state,action){
    return{
        ...state,
        emailConfig:{...action.newConfig}
    };
};