import { toast } from '../../../../utils/messageUtils';
import {
    makeKey,
    update,
    getSuccessResponse,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';

const apiKey='fetchDriversDataApi';
export const fetchDriversDataSuccess = (state, payload) => {
    return makeKey(
        apiKey,
        update(state.fetchDriversDataApi,
            getSuccessResponse(payload))
    );
};

export const fetchDriversDataStarted=(state)=>{
    return makeKey(
        apiKey,
        update(state.fetchDriversDataApi, {
            isProcessing: true
        })
    );
};

export const fetchDriversDataFailed=(state, payload)=>{
    let response = makeKey(
        apiKey,
        update(state.fetchDriversDataApi, {
            ...(payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload))
        })
    );
    toast.error(response.fetchDriversDataApi.message);
    return response;
};

export const updateScatterOpacity=(state,payload)=>{
    let {fetchDriversDataApi}=state;
    let {dataForGraph} = fetchDriversDataApi&&
        fetchDriversDataApi.data&&fetchDriversDataApi.data.response;
    Object.keys(dataForGraph).forEach(item=>{
        if(payload.action==='hover'){
            if(dataForGraph[item].label===payload.key)
                dataForGraph[item].opacity=1;
            else dataForGraph[item].opacity=.3;
        }
        else
            dataForGraph[item].opacity=1;
    });
    return{
        ...state,fetchDriversDataApi:{...state.fetchDriversDataApi,
            data:{...state.fetchDriversDataApi.data,response:{
                ...state.fetchDriversDataApi.data.response,
                dataForGraph:dataForGraph}}}
    };
};