import React, { Component } from 'react';
import { Select } from 'antd';
import { ButtonElement } from '../../../../../../FormElements/ButtonElement';
import { KEY_CONSTANTS } from '../../constants';
import { getQuestionTranslation, getTextForTranslation } from '../../../../../../utils/newAttendSurveyUtils';
import ErrorBlock from '../ErrorBlock';
import { AsterikLabel } from '../../../../../../Components/AsterikLabel';
import parse from "html-react-parser";
import ScrollBar from '../../../../../../Packages/ScrollBar';

const { Option } = Select
class RankingQuestion extends Component {

    handleChangeRankingQuestion = (event, question, index) => {
        this.props.handleChangeRanking({ blockId: this.props.questionDetails.blockId, changedRank: event.toString(), question: question, optionIndex: index })


    }

    generateRankingOptions = (item) => {
        let renderComponent = []
        for (let index = 0; index < item.options.length; index++) {
            renderComponent.push(<div className='survey-rankmanager'>

                <div>
                    <Select
                        value={item.options[index].answer ? item.options[index].answer : "_"}
                        onChange={(event) => {
                            this.handleChangeRankingQuestion(event, item.options[index], index)
                        }}
                    >
                        {Array.from({ length: item.options.length }).map((value, index) => (
                            <Option value={index}>{index + 1}</Option>
                        ))}
                    </Select>
                </div>
                <div className='survey-rankmanager__label'>{parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: item.options[index], key: "label" }))}</div>

            </div>);
        }
        return renderComponent
    }

    render() {
        return (

            <div>

                {this.props.questionDetails.questions.map((question) => {
                    return (
                        <>
                            <h3 className={this.props.questionDetails.isRequired ? 'heading-asterisk' : ""}>
                                {this.props.selectedLanguage === KEY_CONSTANTS.DEFAULT_LANGUAGE ? parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: question, key: question.HTMLQuestion ? KEY_CONSTANTS.HTML_QUESTION : KEY_CONSTANTS.QUESTION })) : <p>{parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: question, key: question.HTMLQuestion ? KEY_CONSTANTS.HTML_QUESTION : KEY_CONSTANTS.QUESTION }))}</p>}

                                </h3>
                            {this.generateRankingOptions(question)}
                        </>
                    )
                }

                )}
                <div className='multioption-block__button'>
                    {/* <ButtonElement type={'primary'} className='multipleoption-btn' onClick={() => this.props.handleGoPrevAndNext(false, false)}>
                        {getTextForTranslation({
                            language: this.props.selectedLanguage,
                            defaultTexts: this.props.defaultTexts,
                            translatedTexts: this.props.translatedTexts,
                            key: KEY_CONSTANTS.CONTINUE_BUTTON
                        })}
                    </ButtonElement> */}
                </div>
                <ErrorBlock manageScroller={this.props.manageScroller}/>

            </div>
        );
    }
}

export default RankingQuestion;
