import {
    FETCH_TAB_CONFIGURATION_IN_PROJECTS_START,
    FETCH_TAB_CONFIGURATION_IN_PROJECTS_SUCCESS,
    FETCH_TAB_CONFIGURATION_IN_PROJECTS_FAIL
} from "../../../constants";
import { FETCH_SURVEY_LIST_TAB_CONFIGURATION, LIST_SURVEY_URL } from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";

export const fetchTabsForProjectsPage = (payload, cb) => {
    const axios = new ApiBase().instance;
    return (dispatch) => {
        dispatch({ type: FETCH_TAB_CONFIGURATION_IN_PROJECTS_START });
        axios.post(FETCH_SURVEY_LIST_TAB_CONFIGURATION, { ...payload })
            .then((res) => {
                fetchSurveysSuccess(dispatch, res, payload)
                cb && cb(res)
            })
            .catch((err) => fetchSurveysFailed(dispatch, err));
    };
};

function fetchSurveysSuccess(dispatch, res, payload) {
    return dispatch({
        type: FETCH_TAB_CONFIGURATION_IN_PROJECTS_SUCCESS,
        data: res.data.data,
        searchText: payload.searchText
    });
}

function fetchSurveysFailed(dispatch, err) {
    return dispatch({
        type: FETCH_TAB_CONFIGURATION_IN_PROJECTS_FAIL,
        error: err.message,
    });
}

