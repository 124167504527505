import React from 'react';
import { Line } from '@ant-design/plots';

export const MultipleAreaGraph = (props) => {

  let trim = props.data.length > 9 ? 20 : 30
  if (props.data.length > 20) {
    trim = 10
  }
  let x = props.showProjectGroup ? 'surveyName' : 'label'
  let seriesField = props.showProjectGroup ? 'label' : 'surveyName'

  const config = {
    data: props.data,
    xField: x,
    yField: 'trendScore',
    seriesField: seriesField,
    color: ["#4575b4", " #74add1", "#abd9e9", "#c9eff3", "#fdae61", " #658F9E", "#d73027"],
    areaStyle: {
      fillOpacity: 0.7,
    },
    legend: {
      layout: 'horizontal',
      position: 'top',
      maxRow: 10,
      maxCol: 10,
      offsetX: 10,
      offsetY: 10
    },
    meta: {
      label: {
        nice: true,
        range: [0, 1],
      },
    },
    smooth: true,
    xAxis: {
      label: {
        formatter: (value) => {
          return value.length > trim ? `${value.slice(0, trim)}...` : value;
        },

      },
    },
    yAxis: {
      label: {
        formatter: (value) => {
          return '';
        },
      },
    },
  };
  return <Line {...config} />;
};