import {
  EXPLORE_UPDATE_PARTICIPATION_COLUMNS_LIST,
  EXPLORE_DESELECT_PARTICIPATION_COLUMNS,
} from "../../../constants";
import { withType } from "../../../../utils/actionOperations";

export const exploreUpdateParticipationSelectedTopicColumns = ({
  selected,
}) => {
  return (dispatch) => {
    dispatch(withType(EXPLORE_UPDATE_PARTICIPATION_COLUMNS_LIST, selected));
  };
};

export const exploreDeselectParticipationcolumns = () => {
  return (dispatch) => {
    dispatch(withType(EXPLORE_DESELECT_PARTICIPATION_COLUMNS, {}));
  };
};

