import { createSelector } from "reselect";

const getUsersPageReducer = (survey) => survey.usersPageReducer;
const getOrganizationPageRrducer = (organization) =>
  organization.organizationPageReducer;
const getHrisUploadReducer = (state) => state.hrisUploadReducer;
const getRolesPageReducer = (survey) => survey.rolesPageReducer;
const getUserUploadReducer = (survey) => survey.UserUploadReducer;
const getAddAdminReducer = (reducer) => {
  return {
    hrisFileValue: reducer.addAdminPageReducer.hrisFileValue,
    addAdminPagination: reducer.addAdminPageReducer.addAdminPagination,
    managersPagination: reducer.addAdminPageReducer.managersPagination,
  };
};
const getUserCreationLogReducer = (reducer) => {
  return { logsPagination: reducer.UserCreationLogReducer.logsPagination };
};
const getaddTemplatesPageReducer = (reducer) => {
  return {
    emailTemplatePagination:
      reducer.addTemplatesPageReducer.emailTemplatePagination,
  };
};

const StateSelector = (
  usersReducer,
  organizationReducer,
  rolesPageReducer,
  userUploadReducer,
  addAdminPageReducer,
  UserCreationLogReducer,
  addTemplatesPageReducer,
  hrisUploadReducer
) => ({
  ...usersReducer,
  ...organizationReducer,
  ...rolesPageReducer,
  ...userUploadReducer,
  ...addAdminPageReducer,
  ...UserCreationLogReducer,
  ...addTemplatesPageReducer,
  ...hrisUploadReducer,
});

export const SelectState = createSelector(
  [
    getUsersPageReducer,
    getOrganizationPageRrducer,
    getRolesPageReducer,
    getUserUploadReducer,
    getAddAdminReducer,
    getUserCreationLogReducer,
    getaddTemplatesPageReducer,
    getHrisUploadReducer,
  ],
  StateSelector
);
