import React from "react";
import { URL_DATA } from "../../../../../../../config/urlDataConstants";
import { checkIfObjectsAreEqual } from "../../../../../../utils/customFunctions";
import { deepCopy } from "../../../../../../utils/deepCopy";
import {
  getDataFromUrlToken,
} from "../../../../../../utils/urlDataStore";
import QuestionScores from "./children/QuestionScores";
import QuestionTable from "./children/TableView";
import { addTrendDataToTable, getMatchedSurveyDetails, getSelectedUserData } from "../../helper";
import {
  checkConnectedProjects,
  checkTrendProjects,
} from "../../../../../../utils/trendUtils";
import GraphLoader from "../../../../../../Components/ContentLoaders/GraphLoader";
import TableLoader from "../../../../../../Components/ContentLoaders/TableLoader";
import { checkIfTwoArraysAreIsEqual, getURLFilterAndByScore } from "../../../../../../utils/byScoreFilterUtils";
import { DASHBOARD_TABS } from '../../../../../../../config/tabConstants';
import FollowUpQuestionsTableView from "./children/FollowUpQuestionsTableView";
export class Questions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showActualScore: false
    };

  }

  componentDidMount() {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const selectedUserData = getSelectedUserData();
    if (this.checkIfApiCallRequiredOnMount(urlFilter, selectedUserData)) {
      this.callApi(urlFilter, selectedUserData);
    }
  }

  checkIfApiCallRequiredOnMount = (urlFilter, selectedUserData) => {
    let filterData = getURLFilterAndByScore(urlFilter)
    if (
      !this.props.isDataFetched ||
      !checkIfObjectsAreEqual(filterData.filters, this.props.filters) ||
      !checkIfTwoArraysAreIsEqual(filterData.byScore, this.props.byScore) ||
      !checkIfTwoArraysAreIsEqual(filterData.multipleSelect, this.props.multipleSelect) ||
      !checkIfObjectsAreEqual(selectedUserData.directReporteesOnly, this.props.directReporteesOnly) ||
      !checkIfObjectsAreEqual(selectedUserData.selectedUser, this.props.selectedUser)
    ) {
      return true;
    }
    return false;
  };

  callApi = (filters, selectedUserData) => {
    let filterData = getURLFilterAndByScore(filters)
    const projectId = this.props.projectDetails.projectId;
    let trendData = checkConnectedProjects(this.props.projectDetails);
    let payload = {
      currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.QUESTIONS,
      projectId: projectId,
      ...filterData,
      projectType: this.props.projectDetails.projectType,
      directReporteesOnly: selectedUserData.directReporteesOnly,
      selectedUser: selectedUserData.selectedUser,
      hrisFileId: this.props.projectDetails.hrisFileId
    };
    if (trendData.length > 0) {
      payload.connectedProjects = trendData;
    }
    this.props.fetchExploreQuestions(payload);
    this.props.fetchFollowUpQuestionsReportData(payload);
    let projectDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS);
    this.props.NewDashboardGetQuestionsTableTrend(
      {
        currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.QUESTIONS,
        projectId: projectDetails.projectId,
        organizationId: projectDetails.organizationId,
        projectOrigin: projectDetails.projectOrigin,
        projectType: projectDetails.projectType,
        isAnonymous: projectDetails.isAnonymous,

      }
    )

  };

  componentDidUpdate() {
    const selectedUserData = getSelectedUserData();
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(urlFilter)
    if (
      checkIfObjectsAreEqual(filterData.filters, this.props.filters) &&
      checkIfTwoArraysAreIsEqual(filterData.byScore, this.props.byScore) &&
      checkIfTwoArraysAreIsEqual(filterData.multipleSelect, this.props.multipleSelect) &&
      checkIfObjectsAreEqual(
        selectedUserData.directReporteesOnly,
        this.props.directReporteesOnly
      ) &&
      checkIfObjectsAreEqual(
        selectedUserData.selectedUser,
        this.props.selectedUser
      )
    ) {
    } else {
      if (!this.props.exploreQuestionsApi.isProcessing) {
        this.callApi(urlFilter, selectedUserData);
      }
    }
  }

  getTopAndBottomScores = () => {
    let itemTable = deepCopy(this.props.exploreQuestions.itemTable);
    itemTable = itemTable.filter((item) => item.validCount >= this.props.thresholdData.filterThreshold);
    itemTable = itemTable.filter(item => Math.round(item.favScore) !== 0)
    const sortedTable = itemTable.sort((a, b) => b.favScore - a.favScore);
    let topScores = sortedTable.slice(0, 3);
    let bottomScores = deepCopy(sortedTable)
      .reverse()
      .slice(0, 3)
      .sort((a, b) => b.favScore - a.favScore);
    return { topScores, bottomScores };
  };
  checkAllScoreEqual = (itemTable) => {
    if (itemTable && itemTable.length) {
      var firstFavScore = itemTable[0].favScore;
      const allEqual = itemTable.every((val) => val.favScore === firstFavScore);
      return allEqual;
    } else {
      return true;
    }
  };
  checkAllCommonScore = (topScore, bottomScore) => {
    const topFavscores = topScore.map((item) => item.favScore);
    const bottomFavscores = bottomScore.map((item) => item.favScore);
    const intersection = topFavscores.filter((element) =>
      bottomFavscores.includes(element)
    );
    if (intersection && intersection.length) {
      return true;
    } else {
      return false;
    }
  };
  checkQuestionScoreVisibility = () => {
    let itemTable = deepCopy(this.props.exploreQuestions.itemTable);
    itemTable = itemTable.filter((item) => item.validCount >= this.props.thresholdData.filterThreshold);
    const sortedTable = itemTable.sort((a, b) => b.favScore - a.favScore);
    let topScores = sortedTable.slice(0, 3);
    let bottomScores = deepCopy(sortedTable)
      .reverse()
      .slice(0, 3)
      .sort((a, b) => b.favScore - a.favScore);
    if (
      this.checkAllCommonScore(topScores, bottomScores) ||
      this.checkAllScoreEqual(itemTable)
    ) {
      return true;
    } else {
      return false;
    }
  };

  getItemTable = (itemTable) => {
    let outcomeVariable =
      this.props.projectDetails && this.props.projectDetails.outcomeVariable
        ? this.props.projectDetails.outcomeVariable
        : "";
    let sortedList = [];
    let outcomeList = [];
    if (outcomeVariable || outcomeVariable === "") {
      //filter out outcome list
      outcomeList = itemTable.filter(item => item.dimension === outcomeVariable);
      sortedList = itemTable.filter(item => item.dimension !== outcomeVariable);
    }
    //sort rest of the list without outcome
    sortedList = sortedList.sort(
      function (a, b) {
        if (a.dimension === b.dimension) {
          //favScore sorting is needed only when dimension are same
          return b.favScore - a.favScore;
        }
        return a.dimension > b.dimension ? 1 : -1;
      })
    outcomeList = outcomeList.sort(
      function (a, b) {
        return b.favScore - a.favScore;
      })
    //combine lists ,place outcome elements first
    let combinedList = [...outcomeList, ...sortedList];
    let updatedDataWithTrend = addTrendDataToTable(combinedList, this.state.showActualScore) 
    return updatedDataWithTrend

  };
  render() {
    return (
      <>
        {this.props.exploreQuestionsApi.isProcessing || this.props.fetchFollowUpQuestionReportApi.isProcessing || this.props.listComparisonSurveyApi.isProcessing ? (
          <>
            <GraphLoader height={300} width={600} />
            <TableLoader />
          </>
        ) : (
          <>
            {this.props.exploreQuestions && (
              <>
                {!this.checkQuestionScoreVisibility() ? (
                  <QuestionScores {...this.getTopAndBottomScores()} />
                ) : (
                  ""
                )}
                <QuestionTable
                  threshold={this.props.thresholdData && this.props.thresholdData}
                  loading={this.props.exploreQuestionsApi.isProcessing}
                  data={this.getItemTable(
                    this.props.exploreQuestions.itemTable
                  )}
                  onChangeActualScore={(value) => { this.setState({ showActualScore: value }) }}
                  showActualTrendScore={this.state.showActualScore}
                  projectDetails={this.props.projectDetails}
                  trendData={checkTrendProjects(this.props.projectDetails)}
                  trendColomns={getMatchedSurveyDetails(this.props.getTrendProjectsApi.data,true)}

                />
              </>
            )
            }
            {
              this.props.fetchFollowUpQuestionReportApi.data &&
              this.props.fetchFollowUpQuestionReportApi.data.FollowUpQuestionItemTable &&
              this.props.fetchFollowUpQuestionReportApi.data.FollowUpQuestionItemTable.length > 0 && (
                <>
                  { }
                  < FollowUpQuestionsTableView
                    threshold={this.props.thresholdData && this.props.thresholdData}
                    loading={this.props.fetchFollowUpQuestionReportApi.isProcessing}
                    data={this.getItemTable(
                      this.props.fetchFollowUpQuestionReportApi.data &&
                      this.props.fetchFollowUpQuestionReportApi.data.FollowUpQuestionItemTable
                    )}
                    projectDetails={this.props.projectDetails}
                    trendData={checkTrendProjects(this.props.projectDetails)}
                  />

                </>
              )
            }
          </>
        )
        }
      </>
    );
  }
}
