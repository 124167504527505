import React, { Component } from "react";
import "./style.css";
const d3 = Object.assign(
  {},
  require("d3"),
  require("d3-selection"),
  require("d3-selection-multi"),
  require("d3-scale-chromatic")
);

d3.functor = function functor(v) {
  return typeof v === "function"
    ? v
    : function () {
      return v;
    };
};

d3.tip = function () {
  var direction = d3_tip_direction,
    offset = d3_tip_offset,
    html = d3_tip_html,
    node = initNode(),
    svg = null,
    point = null,
    target = null;

  function tip(vis) {
    svg = getSVGNode(vis);
    point = svg.createSVGPoint();
    document.body.appendChild(node);
  }

  // Public - show the tooltip on the screen
  //
  // Returns a tip
  tip.show = function () {
    var args = Array.prototype.slice.call(arguments);
    if (args[args.length - 1] instanceof SVGElement) target = args.pop();

    var content = html.apply(this, args),
      poffset = offset.apply(this, args),
      dir = direction.apply(this, args),
      nodel = getNodeEl(),
      i = directions.length,
      coords,
      scrollTop = document.documentElement.scrollTop || document.body.scrollTop,
      scrollLeft =
        document.documentElement.scrollLeft || document.body.scrollLeft;

    nodel
      .html(content)
      .style("position", "absolute")
      .style("opacity", 1)
      .style("pointer-events", "all");

    while (i--) nodel.classed(directions[i], false);
    coords = direction_callbacks[dir].apply(this);
    nodel
      .classed(dir, true)
      .style("top", coords.top + poffset[0] + scrollTop + "px")
      .style("left", coords.left + poffset[1] + scrollLeft + "px");

    return tip;
  };

  // Public - hide the tooltip
  //
  // Returns a tip
  tip.hide = function () {
    var nodel = getNodeEl();
    nodel.style("opacity", 0).style("pointer-events", "none");
    return tip;
  };

  // Public: Proxy attr calls to the d3 tip container.  Sets or gets attribute value.
  //
  // n - name of the attribute
  // v - value of the attribute
  //
  // Returns tip or attribute value
  tip.attr = function (n, v) {
    if (arguments.length < 2 && typeof n === "string") {
      return getNodeEl().attr(n);
    } else {
      var args = Array.prototype.slice.call(arguments);
      d3.selection.prototype.attr.apply(getNodeEl(), args);
    }

    return tip;
  };

  // Public: Proxy style calls to the d3 tip container.  Sets or gets a style value.
  //
  // n - name of the property
  // v - value of the property
  //
  // Returns tip or style property value
  tip.style = function (n, v) {
    if (arguments.length < 2 && typeof n === "string") {
      return getNodeEl().style(n);
    } else {
      var args = Array.prototype.slice.call(arguments);
      if (args.length === 1) {
        var styles = args[0];
        Object.keys(styles).forEach(function (key) {
          return d3.selection.prototype.style.apply(getNodeEl(), [
            key,
            styles[key],
          ]);
        });
      }
    }

    return tip;
  };

  // Public: Set or get the direction of the tooltip
  //
  // v - One of n(north), s(south), e(east), or w(west), nw(northwest),
  //     sw(southwest), ne(northeast) or se(southeast)
  //
  // Returns tip or direction
  tip.direction = function (v) {
    if (!arguments.length) return direction;
    direction = v == null ? v : d3.functor(v);

    return tip;
  };

  // Public: Sets or gets the offset of the tip
  //
  // v - Array of [x, y] offset
  //
  // Returns offset or
  tip.offset = function (v) {
    if (!arguments.length) return offset;
    offset = v == null ? v : d3.functor(v);

    return tip;
  };

  // Public: sets or gets the html value of the tooltip
  //
  // v - String value of the tip
  //
  // Returns html value or tip
  tip.html = function (v) {
    if (!arguments.length) return html;
    html = v == null ? v : d3.functor(v);

    return tip;
  };

  // Public: destroys the tooltip and removes it from the DOM
  //
  // Returns a tip
  tip.destroy = function () {
    if (node) {
      getNodeEl().remove();
      node = null;
    }
    return tip;
  };

  function d3_tip_direction() {
    return "n";
  }

  function d3_tip_offset() {
    return [0, 0];
  }

  function d3_tip_html() {
    return " ";
  }

  var direction_callbacks = {
    n: direction_n,
    s: direction_s,
    e: direction_e,
    w: direction_w,
    nw: direction_nw,
    ne: direction_ne,
    sw: direction_sw,
    se: direction_se,
  };

  var directions = Object.keys(direction_callbacks);

  function direction_n() {
    var bbox = getScreenBBox();
    return {
      top: bbox.n.y - node.offsetHeight,
      left: bbox.n.x - node.offsetWidth / 2,
    };
  }

  function direction_s() {
    var bbox = getScreenBBox();
    return {
      top: bbox.s.y,
      left: bbox.s.x - node.offsetWidth / 2,
    };
  }

  function direction_e() {
    var bbox = getScreenBBox();
    return {
      top: bbox.e.y - node.offsetHeight / 2,
      left: bbox.e.x,
    };
  }

  function direction_w() {
    var bbox = getScreenBBox();
    return {
      top: bbox.w.y - node.offsetHeight / 2,
      left: bbox.w.x - node.offsetWidth,
    };
  }

  function direction_nw() {
    var bbox = getScreenBBox();
    return {
      top: bbox.nw.y - node.offsetHeight,
      left: bbox.nw.x - node.offsetWidth,
    };
  }

  function direction_ne() {
    var bbox = getScreenBBox();
    return {
      top: bbox.ne.y - node.offsetHeight,
      left: bbox.ne.x,
    };
  }

  function direction_sw() {
    var bbox = getScreenBBox();
    return {
      top: bbox.sw.y,
      left: bbox.sw.x - node.offsetWidth,
    };
  }

  function direction_se() {
    var bbox = getScreenBBox();
    return {
      top: bbox.se.y,
      left: bbox.e.x,
    };
  }

  function initNode() {
    var node = d3.select(document.createElement("div"));
    node
      .style("position", "absolute")
      .style("top", 0)
      .style("opacity", 0)
      .style("pointer-events", "none")
      .style("box-sizing", "border-box");

    return node.node();
  }

  function getSVGNode(el) {
    el = el.node();
    if (el.tagName.toLowerCase() === "svg") return el;

    return el.ownerSVGElement;
  }

  function getNodeEl() {
    if (node === null) {
      node = initNode();
      // re-add node to DOM
      document.body.appendChild(node);
    }
    return d3.select(node);
  }

  // Private - gets the screen coordinates of a shape
  //
  // Given a shape on the screen, will return an SVGPoint for the directions
  // n(north), s(south), e(east), w(west), ne(northeast), se(southeast), nw(northwest),
  // sw(southwest).
  //
  //    +-+-+
  //    |   |
  //    +   +
  //    |   |
  //    +-+-+
  //
  // Returns an Object {n, s, e, w, nw, sw, ne, se}
  function getScreenBBox() {
    var targetel = target || d3.event.target;

    while (
      "undefined" === typeof targetel.getScreenCTM &&
      "undefined" === targetel.parentNode
    ) {
      targetel = targetel.parentNode;
    }

    var bbox = {},
      matrix = targetel.getScreenCTM(),
      tbbox = targetel.getBBox(),
      width = tbbox.width,
      height = tbbox.height,
      x = tbbox.x,
      y = tbbox.y;

    point.x = x;
    point.y = y;
    bbox.nw = point.matrixTransform(matrix);
    point.x += width;
    bbox.ne = point.matrixTransform(matrix);
    point.y += height;
    bbox.se = point.matrixTransform(matrix);
    point.x -= width;
    bbox.sw = point.matrixTransform(matrix);
    point.y -= height / 2;
    bbox.w = point.matrixTransform(matrix);
    point.x += width;
    bbox.e = point.matrixTransform(matrix);
    point.x -= width / 2;
    point.y -= height / 2;
    bbox.n = point.matrixTransform(matrix);
    point.y += height;
    bbox.s = point.matrixTransform(matrix);

    return bbox;
  }

  return tip;
};

export default class RelationGraph extends Component {

  state = { current: 1, hasMore: false, topic: "" };

  componentDidMount() {

    this.drawGraph(
      d3,
      this.props.nodes,
      this.props.links,
      "#networkGraph",
      this.props.nodeClick,
      this.props.linkClick
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.question !== this.props.question) {
      d3.select("#networkGraph").selectAll("svg > *").remove();
      this.drawGraph(
        d3,
        this.props.nodes,
        this.props.links,
        "#networkGraph",
        this.props.nodeClick,
        this.props.linkClick
      );
    }


  }

  drawGraph = (d3, nodes, links, element, nodeClick, linkClick) => {
    var circleWidth = 15;
    var nodeSizeMax = d3.max(nodes, function (d) {
      return d.value;
    });
    var nodeSizeMin = d3.min(nodes, function (d) {
      return d.value;
    });
    var nodeScaler = d3
      .scaleLinear()
      .domain([nodeSizeMin, nodeSizeMax])
      .range([45, 80]);
    for (var nodeIdx in nodes) {
      var node = nodes[nodeIdx];
      node["value"] = nodeScaler(nodes[nodeIdx]["value"]);
    }

    var linkSizeMax = d3.max(links, function (d) {
      return d.width;
    });
    var linkSizeMin = d3.min(links, function (d) {
      return d.width;
    });
    var linkScaler = d3
      .scaleLinear()
      .domain([linkSizeMin, linkSizeMax])
      .range([2, 10]);
    for (var idx in links) {
      var link = links[idx];
      link["width"] = linkScaler(link["width"]);
    }

    var palette = {
      lightgray: "#ffffff",
      gray: "#708284",
      mediumgray: "#536870",
      blue: "#3B757F",
    };

    var paletteArray = [
      "#F46D43",
      "#69A5A2",
      "#3D728B",
      "#4575B4",
      "#5C6970",
    ];

    // var colors = d3.scaleOrdinal(d3.schemeTableau10);

    var svg = d3.select(element),
      width = +svg.attr("width"),
      height = +svg.attr("height"),
      // eslint-disable-next-line
      node,// eslint-disable-next-line
      link;
    svg
      .append("defs")
      .append("marker")
      .attr('id', 'arrowhead')
      .attr('viewBox', '-0 -5 10 10')
      .attr('refX', 13)
      .attr('refY', 0)
      .attr('orient', 'auto')
      .attr('markerWidth', 13)
      .attr('markerHeight', 13)
      .attr('xoverflow', 'visible')
      .append("svg:path")
      .attr("d", "M 0,-5 L 10 ,0 L 0,5")
      .attr("fill", "#999")
      .style("stroke", "none");

    var simulation = d3
      .forceSimulation()
      .velocityDecay(0.3)
      .force(
        "link",
        d3
          .forceLink()
          .id(function (d) {
            return d.id;
          })
          .distance(10)
          .strength(1)
      )
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force(
        "collide",
        d3
          .forceCollide()
          .radius(function (d) {
            return d.value + 30;
          })
          .iterations(1)
      );

    update(links, nodes);

    function update(links, nodes) {
      link = svg
        .selectAll(".link")
        .data(links)
        .enter()
        .append("line")
        .attr("class", "link")
        .attr("stroke-width", function (link) {
          return link.width;
        });
      link.append("title").text((n) => {
        console.log("Link props", n)
        return n.title;
      });

      link.on("click", function (d) {
        console.log(d.target.__data__.source.name)
        //alert("Clicked : " + d.source.name + " -> " + d.target.name);
        linkClick(d.target.__data__.source.name, d.target.__data__.target.name);
      });

      // link.attr("line").innerHtml = "<title>Tooltip Custom</title>"

      var tip = d3
        .tip()
        .attr("class", "d3-tip")
        .html(function (d) {
          return "<span>" + d.commentsCount + " </span>";
        });

      svg.call(tip);
      link
        .on("mouseover", function (d) {
          svg.style("cursor", "pointer");
        })
        .on("mouseout", function (d) {
          svg.style("cursor", "");
        });

      node = svg
        .selectAll(".node")
        .data(nodes)
        .enter()
        .append("g")
        .attr("class", "node");

      node
        .on("mouseover", function (d) {
          // tip.show({ commentsCount: d.currentTarget.__data__.name }, this);
          svg.style("cursor", "pointer");
        })
        .on("mouseout", function (d) {
          // tip.hide(d);
          svg.style("cursor", "");
        });

      node
        .append("circle")
        .attr("cx", function (d) {
          return d.x;
        })
        .attr("cy", function (d) {
          return d.y;
        })
        .attr("r", function (d, i) {
          d.radius = circleWidth + d.value;
          return circleWidth + d.value - 4;
        })
        .attr("fill", function (d, i) {
          if (i > 4) {
            i = 4;
          }
          return paletteArray[i];
        })
        .attr("stroke-width", function (d, i) {
          return 0;
        })
        .style("stroke", function (d) {
          return "white";
        });

      node
        .append("text")
        .text(function (d) {
          return d.name;
        })
        .attr("font-family", "Inter")
        .attr("dy", 0)
        //.attr("x",10)
        //.attr("y",-20)
        .attr("fill", function (d, i) {
          return palette.lightgray;
        })
        .attr("text-anchor", function (d, i) {
          return "middle";
        })
        .attr("font-size", function (d, i) {
          d.requiredLength = this.getComputedTextLength();
          return ".9em";
        })
        .call(wrap, 20);

      node.on("click", function (d) {
        nodeClick(d);
      });
      // eslint-disable-next-line
      var text = d3
        .selectAll("text")
        .text(function (d, i) {
          var name = "";
          var commentsCount = 0;
          if (d) {
            name = d.name;
            commentsCount = d.commentsCount;
          }
          return name + " (" + commentsCount + ")";
        })
        .attr("y", 0)
        .call(wrap, 0);
      simulation.nodes(nodes).on("tick", ticked);
    }

    function wrap(text, width) {
      text.each(function (d) {
        if (d) {
          width = d.radius;
          var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            y = text.attr("y"),
            dy = parseFloat(text.attr("dy")),
            tspan = text
              .text(null)
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", dy + "em");
          while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(" "));
            if (
              word.substring(0, 1) === "(" ||
              tspan.node().getComputedTextLength() > Number(width) + 40
            ) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text
                .append("tspan")
                .attr("x", 0)
                .attr("y", y)
                .attr("dy", ++lineNumber * lineHeight + dy + "em")
                .text(word);

            }
          }
        }
      });
    }

    function ticked() {
      node
        .attr("cx", function (d) {
          let radius = d.radius;
          return (d.x = Math.max(radius, Math.min(width - radius, d.x)));
        })
        .attr("cy", function (d) {
          let radius = d.radius;
          return (d.y = Math.max(radius, Math.min(height - radius, d.y)));
        });

      node.attr("transform", function (d, i) {
        return "translate(" + d.x + "," + d.y + ")";
      });

      link
        .attr("x1", function (d) {
          return d.source.x;
        })
        .attr("y1", function (d) {
          return d.source.y;
        })
        .attr("x2", function (d) {
          return d.target.x;
        })
        .attr("y2", function (d) {
          return d.target.y;
        });
    }
  };

  render = () => {
    // eslint-disable-next-line
    let node = {};
    // eslint-disable-next-line
    let link = {
      stroke: "#999",
      strokeOpacity: ".6",
    };
    let svg = {
      borderStyle: "dashed",
    };
    // eslint-disable-next-line
    let d3Tip = {
      lineHeight: 1,
      padding: "6px",
      background: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      borderRadius: "4px",
      fontSize: "12px",
    };

    return (
      <>
        <svg
          id="networkGraph"
          width={this.props.width}
          height={this.props.height}
        ></svg>
      </>
    );
  };
}
