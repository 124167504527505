import React, { Component } from 'react'
import { MultipleGraphChart } from '../../../../../../Components/MultipleGraphChart'
import { MultipleAreaGraph } from '../../../../../../Components/MultipleAreaGraph'
import TreeSelectValue from './children/TreeSelect'
import { Spin, Switch } from 'antd'
import './index.scss'
import { createDropDownData, generateDataForExcel, getAPIPayload, getSelectedMenus, replaceCurrentSurveyWithName, showDataSection } from './helpers'
import GraphLoader from '../../../../../../Components/ContentLoaders/GraphLoader'
import SVGIcons from '../../../../../../Components/SVGIcons'
import { getSelectedUserData } from '../../helper'
import { DASHBOARD_TABS } from '../../../../../../../config/tabConstants';
import { URL_DATA } from '../../../../../../../config/urlDataConstants'
import { checkIfTwoArraysAreIsEqual, getURLFilterAndByScore } from '../../../../../../utils/byScoreFilterUtils'
import { checkIfObjectsAreEqual } from '../../../../../../utils/customFunctions'
import { getDataFromUrlToken } from '../../../../../../utils/urlDataStore'
import GroupedBarChart from '../../../../../../Components/GroupBarchart'
import DemographicsTrend from './children/DemographicsTrend'
import { getSelectedFilters } from './children/DemographicsTrend/helper'
import { ButtonElement } from '../../../../../../FormElements/ButtonElement'
import { downloadAsExcel } from '../../../../../../utils/parseAndDownloadExcel'
export default class Trend extends Component {

  constructor(props) {
    super(props);
    let { topicsData, questionsData } = this.getTreeSelectData()
    let { topics, questions } = getSelectedMenus(topicsData, questionsData, this.props.isTrendVisualisationDataFetched, this.props)
    this.props.setTopoicsAndQuestions({ topics, questions })
    this.state = {
      topicsData: topicsData,
      questionsData: questionsData,
      topicProjectGroup: false,
      questionProjectGroup: false,
      topicGraph: "vertical-bar",
      questionGraph: "vertical-bar",
      spinLoader: false,
      spinLoaderDemographics: false
    };
  }

  handleGraphChange = (value, topics) => {
    if (topics) {
      this.setState({
        topicGraph: value
      })
    } else {
      this.setState({
        questionGraph: value
      })
    }
  }

  getTopicsToggle = () => {
    return (
      <div className='icon-trendblock'>
        <span className={this.state.topicGraph === "vertical-bar" ? "active-trendicon" : ""} onClick={(value) => this.handleGraphChange("vertical-bar", true)} >  <SVGIcons type={"SVG-graphnew"} /></span>
        <span className={this.state.topicGraph === "line" ? "active-trendicon" : ""} onClick={(value) => this.handleGraphChange("line", true)}>  <SVGIcons type={"SVG-lingraph"} /></span>
        <span className={this.state.topicGraph === "horizontal-bar" ? "active-trendicon" : ""} onClick={(value) => this.handleGraphChange("horizontal-bar", true)}>  <SVGIcons type={"SVG-ranking"} /></span>
      </div>
    )
  }

  getQuestionsToggle = () => {
    return (
      <div className='icon-trendblock'>
        <span className={this.state.questionGraph === "vertical-bar" ? "active-trendicon" : ""} onClick={(value) => this.handleGraphChange("vertical-bar")} >  <SVGIcons type={"SVG-graphnew"} /></span>
        <span className={this.state.questionGraph === "line" ? "active-trendicon" : ""} onClick={(value) => this.handleGraphChange("line")}>  <SVGIcons type={"SVG-lingraph"} /></span>
        <span className={this.state.questionGraph === "horizontal-bar" ? "active-trendicon" : ""} onClick={(value) => this.handleGraphChange("horizontal-bar")}>  <SVGIcons type={"SVG-ranking"} /></span>
      </div>
    )
  }


  getGraph = (topics, graphData) => {
    let data = topics ? this.state.topicGraph : this.state.questionGraph
    let showProjectGroup = topics ? this.state.topicProjectGroup : this.state.questionProjectGroup
    if (data === "vertical-bar") {
      return <div className='multiplearea-graphblock'>
        <MultipleGraphChart data={graphData} showProjectGroup={showProjectGroup} />
      </div>
    } else if (data === "line") {
      return <div className='multiplearea-graphblock'>
        <MultipleAreaGraph data={graphData} showProjectGroup={showProjectGroup} />
      </div>
    } else {
      return <div className='multiplearea-graphblock'>
        <GroupedBarChart data={graphData} showProjectGroup={showProjectGroup} />
      </div>
    }
  }

  componentDidMount() {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const selectedUserData = getSelectedUserData();
    if (this.checkIfApiCallRequiredOnMount(urlFilter, selectedUserData)) {
      let { topicsData, questionsData } = this.getTreeSelectData()
      let { topics, questions } = getSelectedMenus(topicsData, questionsData, false, this.props)
      this.callAPI(topics, questions)
      let topicsDemographics = createDropDownData(this.props.projectDetails.questionSet, this.props.projectDetails.isFileUpload, true)
      let demographicsTopics = { topics: topicsDemographics.topicsData && topicsDemographics.topicsData[0] && topicsDemographics.topicsData[0].value ? topicsDemographics.topicsData[0].value : "", questions: topicsDemographics.questionsData && topicsDemographics.questionsData[0] && topicsDemographics.questionsData[0].value ? topicsDemographics.questionsData[0].value : "" }
      { demographicsTopics.topics.length > 0 && this.callDemographicsTrendApi([demographicsTopics.topics], [demographicsTopics.questions],) }
      { demographicsTopics.questions.length > 0 && this.callDemographicsTrendQuestionsApi([demographicsTopics.topics], [demographicsTopics.questions],) }
    }
  }

  checkIfApiCallRequiredOnMount = (urlFilter, selectedUserData) => {
    let filterData = getURLFilterAndByScore(urlFilter)
    if (
      !this.props.isTrendVisualisationDataFetched ||
      !checkIfObjectsAreEqual(filterData.filters, this.props.filters) ||
      !checkIfTwoArraysAreIsEqual(filterData.byScore, this.props.byScore) ||
      !checkIfTwoArraysAreIsEqual(filterData.multipleSelect, this.props.multipleSelect) ||
      !checkIfObjectsAreEqual(selectedUserData.directReporteesOnly, this.props.directReporteesOnly) ||
      !checkIfObjectsAreEqual(selectedUserData.selectedUser, this.props.selectedUser)
    ) {
      return true;
    }
    return false;
  };

  componentDidUpdate() {
    const selectedUserData = getSelectedUserData();
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(urlFilter)
    if (
      checkIfObjectsAreEqual(filterData.filters, this.props.filters) &&
      checkIfTwoArraysAreIsEqual(filterData.byScore, this.props.byScore) &&
      checkIfTwoArraysAreIsEqual(filterData.multipleSelect, this.props.multipleSelect) &&
      checkIfObjectsAreEqual(
        selectedUserData.directReporteesOnly,
        this.props.directReporteesOnly
      ) &&
      checkIfObjectsAreEqual(
        selectedUserData.selectedUser,
        this.props.selectedUser
      )
    ) {
    } else {
      if (!this.props.trendVisualisationData.isProcessing) {
        this.callAPI(this.props.selectedTopics, this.props.selectedQuestions);
      }
      if (!this.props.trendDemographicsVisualisationData.isProcessing) {
        let demographicsFilersTopic = getSelectedFilters(this.props.selectedDemographicsTopicTree, this.props.demographicsData)
        this.callDemographicsTrendApi([this.props.demographicsSelectedTopics], [this.props.demographicsSelectedQuestions], demographicsFilersTopic)
      }
      if (!this.props.trendVisualisationDataQuestions.isProcessing) {
        let demographicsFilersQuestions = getSelectedFilters(this.props.selectedDemographicsQuestionTree, this.props.demographicsData)
        this.callDemographicsTrendQuestionsApi([this.props.demographicsSelectedTopics], [this.props.demographicsSelectedQuestions], demographicsFilersQuestions)
      }

    }
  }

  getTreeSelectData = () => {
    let { topicsData, questionsData } = createDropDownData(this.props.projectDetails.questionSet, this.props.projectDetails.isFileUpload, false, true)
    return { topicsData, questionsData }
  }

  callAPI = (topics, questions) => {
    let payload = { ...getAPIPayload(this.props.projectDetails), selectedTopics: topics, selectedQuestions: questions }
    payload.currentTab = DASHBOARD_TABS.EXPLORE_SUB_TABS.TREND
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(urlFilter)
    const selectedUserData = getSelectedUserData();
    this.props.getTrendData({ ...payload, ...filterData, ...selectedUserData }, () => {
      this.setState({
        spinLoader: false
      })
    })
  }

  callDemographicsTrendApi = (topics, questions, demographicsFilter) => {
    this.setState({
      spinLoaderDemographics: true
    })
    let data = this.props.demographicsData[0] && this.props.demographicsData[0].children && this.props.demographicsData[0].children[0] && this.props.demographicsData[0].children[0].value ? { [this.props.demographicsData[0].value]: [] } : {}
    let payload = { ...getAPIPayload(this.props.projectDetails), selectedField: topics, isTopic: true, }
    payload.currentTab = DASHBOARD_TABS.EXPLORE_SUB_TABS.TREND
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(urlFilter)
    const selectedUserData = getSelectedUserData();
    this.props.getDemographicsTrendData({ ...payload, ...filterData, ...selectedUserData, demographicsFilters: demographicsFilter ? demographicsFilter : data }, () => {
      this.setState({
        spinLoaderDemographics: false
      })
    })
  }

  callDemographicsTrendQuestionsApi = (topics, questions, demographicsFilter) => {
    this.setState({
      spinLoaderDemographics: true
    })
    let data = this.props.demographicsData[0] && this.props.demographicsData[0].children && this.props.demographicsData[0].children[0] && this.props.demographicsData[0].children[0].value ? { [this.props.demographicsData[0].value]: [] } : {}
    let payload = { ...getAPIPayload(this.props.projectDetails), selectedField: questions }
    payload.currentTab = DASHBOARD_TABS.EXPLORE_SUB_TABS.TREND
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(urlFilter)
    const selectedUserData = getSelectedUserData();
    this.props.getDemographicsQuestionsTrendData({ ...payload, ...filterData, ...selectedUserData, demographicsFilters: demographicsFilter ? demographicsFilter : data }, () => {
      this.setState({
        spinLoaderDemographics: false
      })
    })
  }


  getTopicSection = () => {
    let graphData = replaceCurrentSurveyWithName(this.props.trendVisualisationData.data.topicsTrendArray, this.props.projectDetails.projectName)
    return (
      <>
        <>
          <div className='trend-graph-chooser-header'>
            {this.getTopicsSelectData()}
            <span className='graph-toggle-button'>{this.getTopicsToggle()}</span>
          </div>
          {this.getGraph(true, graphData)}
        </>
      </>
    )
  }

  getQuestionsSection = () => {
    let graphData = replaceCurrentSurveyWithName(this.props.trendVisualisationData.data.questionsTrendArray, this.props.projectDetails.projectName)
    return (
      <>
        <div className='trend-graph-chooser-header'>
          {this.getQuestionsSelectData()}
          <span className='graph-toggle-button'>{this.getQuestionsToggle()}</span>
        </div>
        {this.getGraph(false, graphData)}
      </>
    )
  }

  onChangeSelect = (data, fromTopic) => {
    if (fromTopic) {
      this.setState({
        spinLoader: true
      })
      this.props.setTopoicsAndQuestions({ topics: data, questions: this.props.selectedQuestions })
      this.callAPI(data, this.props.selectedQuestions)
    } else {
      this.setState({
        spinLoader: true
      })
      this.props.setTopoicsAndQuestions({ topics: this.props.selectedTopics, questions: data })
      this.callAPI(this.props.selectedTopics, data)
    }
  }

  getTopicsSelectData = () => {
    return (<div className='trend-graph-chooser-header__left'>
      {this.state.topicsData.length > 0 &&
        <TreeSelectValue data={this.state.topicsData} topicSelection={true} selectedData={this.props.selectedTopics} onChange={this.onChangeSelect} />
      }
    </div>
    )
  }

  getQuestionsSelectData = () => {
    return (<div className='trend-graph-chooser-header__left'>
      {this.state.questionsData.length > 0 &&
        <TreeSelectValue data={this.state.questionsData} selectedData={this.props.selectedQuestions} onChange={this.onChangeSelect} advancedProps={{
          treeCheckable: true,
          showCheckedStrategy: 'SHOW_CHILD'
        }} />
      }
    </div>
    )
  }

  getLoader = () => {
    if (this.props.trendVisualisationData.isProcessing && !this.state.spinLoader) {
      return true
    }
    return false
  }

  handleDownloadExcel = ({ key, trendData, dropdownData }) => {
    let { columns, data } = generateDataForExcel({ trendData, projectName: this.props.projectDetails.projectName, key, instance: this.props, dropdownData })

    downloadAsExcel({ data: data, coloumns: columns, name: "sss" })

  }

  render() {
    return (
      <div className='explore-trend-graph-section'>
        <Spin spinning={this.state.spinLoader}>
          {this.getLoader()
            ? <GraphLoader height={300} width={600} />
            : <>
              {showDataSection(this.props.trendVisualisationData.data.topicsTrendArray) && this.state.topicsData.length > 0 &&
                <div className='edit-survey-topicsection'>
                  <div className='trend-graph-chooser-header'>
                    <h3>Topics</h3>
                    <div className='question-table-right'>
                      <p><span className='trend-toggle-label'>Switch axis</span> <Switch checked={this.state.topicProjectGroup} onChange={(value) => this.setState({ topicProjectGroup: value })} /></p>
                      <ButtonElement className='download-heatmap' onClick={() => this.handleDownloadExcel({ key: "Topics", trendData: this.props.trendVisualisationData.data.topicsTrendArray })}><SVGIcons type={'SVG-download'} />Download as excel</ButtonElement>
                    </div>

                  </div>
                  {this.getTopicSection()}
                </div>
              }

              {showDataSection(this.props.trendVisualisationData.data.topicsTrendArray) && this.state.topicsData.length > 0
                && <div className='trend-border'></div>}

              {showDataSection(this.props.trendVisualisationData.data.questionsTrendArray) && this.state.questionsData.length > 0 &&
                <div className='edit-survey-questionsection'>
                  <div className='trend-graph-chooser-header'>
                    <h3>Questions</h3>
                    <div className='question-table-right'>
                      <p><span className='trend-toggle-label'>Switch axis</span>
                        <Switch checked={this.state.questionProjectGroup} onChange={(value) => this.setState({ questionProjectGroup: value })} />
                      </p>
                      <ButtonElement className='download-heatmap' onClick={() => this.handleDownloadExcel({ key: "Questions", trendData: this.props.trendVisualisationData.data.questionsTrendArray })}><SVGIcons type={'SVG-download'} />Download as excel</ButtonElement>
                    </div>
                  </div>
                  {this.getQuestionsSection()}
                </div>
              }
              {showDataSection(this.props.trendVisualisationData.data.topicsTrendArray) && this.state.topicsData.length > 0
                && <div className='trend-border'></div>}
              {/* uncomment below for showing demographics trend */}
              {this.props.demographicsData.length > 0 && <DemographicsTrend callDemographicsTrendApi={this.callDemographicsTrendApi} spinLoading={this.state.spinLoaderDemographics} callDemographicsTrendQuestionsApi={this.callDemographicsTrendQuestionsApi}
                handleDownloadExcel={this.handleDownloadExcel} />}
            </>

          }
        </Spin>
      </div>
    )
  }
}
