import * as helper from './helper';
import * as actionTypes from '../../constants';
import initialState from './intialState';

const UserLandingPageReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.GET_USER_PROJECTS_STARTED:
        return helper.getUserProjectsStart(state, action);
    case actionTypes.GET_USER_PROJECTS_SUCCESS:
        return helper.getUserProjectsSuccess(state, action);
    case actionTypes.GET_USER_PROJECTS_FAIL:
        return helper.getUserProjectsFail(state, action);
    default: return state;
    }
};

export default UserLandingPageReducer;