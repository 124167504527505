import {
  PRODUCT_CHANGE,
  SHOW_MODAL,
  POPUPMENU_VISIBILITY,
  UPDATE_CONTACT_FORM_CHECK_BOX,
  UPDATE_CONTACT_FORM_FIELD,
  SUBMIT_CONTACT_FORM_START,
  SUBMIT_CONTACT_FORM_FAIL,
  SUBMIT_CONTACT_FORM_SUCCESS,
  CLEAR_CONTACT_FORM,
} from "../../constants";
import {
  fetchPayload,
  update,
  formChangeSuccess,
} from "../../../utils/reducerOperations";
import { Default } from "./InitialState";
import {
  handleChange,
  updateShowModalState,
  updatePopupVisibility,
  clearContactForm,
} from "./Helper";
import {
  submitContactFormStarted,
  submitContactFormSuccess,
  submitContactFormFailed,
  updateContactFormCheckBox,
} from "./Helper/handleContactFormChange";

const welcomePageReducer = (state = Default(), action) => {
  let welcomePayload = fetchPayload(action);
  switch (action.type) {
    case PRODUCT_CHANGE:
      return update(state, handleChange(state, welcomePayload));
    case SHOW_MODAL:
      return update(state, updateShowModalState(state, welcomePayload));
    default:
      return contactFormChange(state, action);
  }
};
const contactFormChange = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case CLEAR_CONTACT_FORM:
      return update(state, clearContactForm(state, payload));
    case POPUPMENU_VISIBILITY:
      return update(state, updatePopupVisibility(state, payload));
    case UPDATE_CONTACT_FORM_FIELD:
      return update(state, formChangeSuccess(state, payload));
    case UPDATE_CONTACT_FORM_CHECK_BOX:
      return updateContactFormCheckBox(state, payload);
    default:
      return submitContactForm(state, action);
  }
};

const submitContactForm = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case SUBMIT_CONTACT_FORM_START:
      return update(state, submitContactFormStarted(state, payload));
    case SUBMIT_CONTACT_FORM_SUCCESS:
      return update(state, submitContactFormSuccess(state, payload));
    case SUBMIT_CONTACT_FORM_FAIL:
      return update(state, submitContactFormFailed(state, payload));
    default:
      return { ...state };
  }
};
export default welcomePageReducer;
