export default {
  defaultLoader: [
    {
      x: 64,
      y: 13,
      width: 170,
      height: 7,
    },
    {
      x: 200,
      y: 13,
      width: 350,
      height: 7,
    },
    {
      x: 580,
      y: 13,
      width: 70,
      height: 7,
    },
    {
      x: 683,
      y: 13,
      width: 78,
      height: 7,
    },
    {
      x: 785,
      y: 13,
      width: 117,
      height: 7,
    },
    {
      x: 968,
      y: 13,
      width: 83,
      height: 7,
    },
  ],
  spreadAnalysisLoader: [
    {
      x: 30,
      y: 13,
      width: 100,
    },
    {
      x: 200,
      y: 13,
      width: 450,
    },
    {
      x: 700,
      y: 13,
      width: 120,
    },
    {
      x: 900,
      y: 13,
      width: 120,
    },
  ],
  defaultValues: {
    rx: 6,
    ry: 6,
    height: 5,
    x: 5,
    y: 5,
    width: 5,
  },
};
