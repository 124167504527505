
export const UpdateBenchMarkDataStart = (state) => {
    return {
        ...state,
        UpdateBenchMarkDataApi: {
            isProcessing: true,
            data: null,
            error: false,
            message: ''
        }
    }

}

export const UpdateBenchMarkDataSuccess = (state, payload) => {
    return {
        ...state,
        UpdateBenchMarkDataApi: {
            isProcessing: false,
            data: payload.data,
            error: false,
            message: payload.message
        }
    }

}

export const UpdateBenchMarkDataFailed = (state, payload) => {
    return {
        ...state,
        UpdateBenchMarkDataApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}
