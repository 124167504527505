import { connect } from "react-redux";
import ErrorBlock from "./ErrorBlock";


const mapStateToProps = (state) => {
    return {
        ...state.SurveyAnsweringPageReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBlock);