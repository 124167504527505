import { connect } from "react-redux";
import { updateAnswer, updateCurrentIndex, updateTextAnswer } from "../../../../../../redux/actions/SurveyAnsweringPageAction";
import  MultiOptionQuestions  from "./MultiOptionQuestions";


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAnswer: (payload) => dispatch(updateAnswer(payload)),
        updateCurrentIndex: () => dispatch(updateCurrentIndex()),
        updateTextAnswer:(payload) => dispatch(updateTextAnswer(payload))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiOptionQuestions);