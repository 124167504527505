import initialState from './initialState';
import {
  getExploreTopicListStarted,
  getExploreTopicListSuccess,
  getExploreTopicListFailed,
  resetValues,
  setQuoteModifyingLoading,
  sortExploreTopicList,
  fetchTopicSurveyReportStart,
  fetchTopicSurveyReportSuccess,
  fetchTopicSurveyReportFail,
  disableTopicLoading,
  fetchExploreTopicFilterStatusStart,
  fetchExploreTopicFilterStatusSuccess,
  fetchExploreTopicFilterStatusFail,
  clearExploreTopicFilterStatus,
  adminBarchartDataStart,
  adminBarchartDataSuccess,
  adminBarchartDataFail,
  setApiCalledKey
} from './Helpers';
import {
  FETCH_EXPLORE_TOPIC_LIST_START,
  FETCH_EXPLORE_TOPIC_LIST_SUCCESS,
  FETCH_EXPLORE_TOPIC_LIST_FAILED,
  RESET_EXPLORE_TOPIC_STATE,
  SET_QUOTE_MODIFICATION_LOADING_STATE,
  SORT_EXPLORE_TOPIC_LIST,
  FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_STARTED,
  FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_SUCCESS,
  FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_FAILED,
  DISABLE_EXPLORE_TOPIC_LOADING,
  FETCH_STORYBOARD_FILTER_STATUS_STARTED,
  FETCH_STORYBOARD_FILTER_STATUS_SUCCESS,
  FETCH_STORYBOARD_FILTER_STATUS_FAILED,
  STORYBOARD_FILTER_STATUS_CHANGE,
  EXPLORE_TOPIC_ADMIN_BARCHART_DATA_START,
  EXPLORE_TOPIC_ADMIN_BARCHART_DATA_SUCCESS,
  EXPLORE_TOPIC_ADMIN_BARCHART_DATA_FAILED,
  SET_API_CALLED_KEY_EXPLORE_TOPICS
} from '../../constants';

const ExploreTopicReducer = (state = initialState(), action) => {
  switch (action.type) {
    case FETCH_EXPLORE_TOPIC_LIST_START:
      return getExploreTopicListStarted(state, action.payload);
    case FETCH_EXPLORE_TOPIC_LIST_SUCCESS:
      return getExploreTopicListSuccess(state, action.payload);
    case FETCH_EXPLORE_TOPIC_LIST_FAILED:
      return getExploreTopicListFailed(state, action.payload);
    case RESET_EXPLORE_TOPIC_STATE:
      return resetValues(state);
    case SET_QUOTE_MODIFICATION_LOADING_STATE:
      return setQuoteModifyingLoading(state, action.payload);
    case SORT_EXPLORE_TOPIC_LIST:
      return sortExploreTopicList(state, action);
    case FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_STARTED:
      return fetchTopicSurveyReportStart(state, action.payload);
    case FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_SUCCESS:
      return fetchTopicSurveyReportSuccess(state, action.payload);
    case FETCH_EXPLORE_TOPICS_REPORT_TABLE_DATA_FAILED:
      return fetchTopicSurveyReportFail(state, action.payload);
    case DISABLE_EXPLORE_TOPIC_LOADING:
      return disableTopicLoading(state, action.payload);
    case FETCH_STORYBOARD_FILTER_STATUS_STARTED:
      return fetchExploreTopicFilterStatusStart(state, action.payload);
    case FETCH_STORYBOARD_FILTER_STATUS_SUCCESS:
      return fetchExploreTopicFilterStatusSuccess(state, action.payload);
    case FETCH_STORYBOARD_FILTER_STATUS_FAILED:
      return fetchExploreTopicFilterStatusFail(state, action.payload);
    case STORYBOARD_FILTER_STATUS_CHANGE:
      return clearExploreTopicFilterStatus(state);
    // case TOPICS_SET_FILTER_SUCCESS:
    //   return setFilterSuccess(state, action.payload);
    case EXPLORE_TOPIC_ADMIN_BARCHART_DATA_START:
      return adminBarchartDataStart(state, action.payload);
    case EXPLORE_TOPIC_ADMIN_BARCHART_DATA_SUCCESS:
      return adminBarchartDataSuccess(state, action.payload);
    case EXPLORE_TOPIC_ADMIN_BARCHART_DATA_FAILED:
      return adminBarchartDataFail(state, action.payload);
    case SET_API_CALLED_KEY_EXPLORE_TOPICS:
      return setApiCalledKey(state, action.payload);
    default:
      return state;
  }
};

export default ExploreTopicReducer;