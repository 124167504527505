import { newJson } from "../../../../utils/newJson"

export const handleDeleteMatchedQuestion = (state, payload) => {
    let updatedCurrentSurvey = state.trendToggle ? deleteTopicsBasedMapping(state, payload) : deleteCurrentSurveyMappedQuestion(state, payload)
    return {
        ...state,
        trendPairingDataApi: {
            ...state.trendPairingDataApi,
            currentSurvey: updatedCurrentSurvey
        }
    }
}

const deleteCurrentSurveyMappedQuestion = (state, payload) => {
    let currentDataCopy = newJson(state.trendPairingDataApi.currentSurvey)
    if (payload.isDataCollection) {
        let currentSurveyIndex = currentDataCopy[0].questionSet[0].questions.findIndex(item =>
            item.staticQuestionId === payload.parentQuestionDetails.staticQuestionId)
        let mappingQuestion = currentDataCopy[0].questionSet[0].questions[currentSurveyIndex].mappingQuestions ? currentDataCopy[0].questionSet[0].questions[currentSurveyIndex].mappingQuestions : []
        let filteredMappingQuestion = mappingQuestion.filter(item => item.questionId !== payload.mappingQuestionDetails.questionId)
        currentDataCopy[0].questionSet[0].questions[currentSurveyIndex].mappingQuestions = filteredMappingQuestion
    }
    else {
        let { topicIndex, matrixIndex } = getIndex(currentDataCopy, payload.parentQuestionDetails)
        let currentMappingQuestion = currentDataCopy[0].questionSet[topicIndex].questions[0].matrixQuestions[matrixIndex].mappingQuestions ? currentDataCopy[0].questionSet[topicIndex].questions[0].matrixQuestions[matrixIndex].mappingQuestions : []
        let filteredCurrentMappingQuestion = currentMappingQuestion.filter(item => item.questionId !== payload.mappingQuestionDetails.questionId)
        currentDataCopy[0].questionSet[topicIndex].questions[0].matrixQuestions[matrixIndex].mappingQuestions = filteredCurrentMappingQuestion
    }
    return currentDataCopy
}

const getIndex = (currentDataCopy, destination) => {
    let topicIndex = undefined
    let matrixIndex = undefined
    currentDataCopy[0].questionSet.forEach((currentTopic, index) => {
        if (currentTopic.goalId === destination.goalId) {
            currentTopic.questions[0].matrixQuestions.forEach((matrixQuestion, key) => {
                if (matrixQuestion.staticQuestionId === destination.staticQuestionId) {
                    topicIndex = index
                    matrixIndex = key
                }
            }
            )
        }


    })
    return { topicIndex, matrixIndex }
}

const deleteTopicsBasedMapping = (state, payload) => {
    let currentDataCopy = newJson(state.trendPairingDataApi.currentSurvey)
    if (payload.isDataCollection) {
        let currentSurveyIndex = currentDataCopy[0].questionSet[0].questions.findIndex(item =>
            (item.dimensionsId === payload.parentQuestionDetails.dimensionsId) && (item.type === "Multiple Choice"))
        let mappedTopics = currentDataCopy[0].questionSet[0].questions[currentSurveyIndex].mappingTopics ? currentDataCopy[0].questionSet[0].questions[currentSurveyIndex].mappingTopics : []
        let filteredMappedTopic = mappedTopics.filter(item => ((item.dimensionsId !== payload.mappingQuestionDetails.dimensionsId) && (item.projectId !== payload.mappingQuestionDetails.projectId)))
        currentDataCopy[0].questionSet[0].questions[currentSurveyIndex].mappingTopics = filteredMappedTopic
    }
    else {
        let topicIndex = currentDataCopy[0].questionSet.findIndex(item =>
            item.dimension === payload.parentQuestionDetails.dimension)
        let currentMappingQuestion = currentDataCopy[0].questionSet[topicIndex].mappingTopics ? currentDataCopy[0].questionSet[topicIndex].mappingTopics : []
        let filteredCurrentTopic = currentMappingQuestion.filter(item => item.dimensionsId !== payload.mappingQuestionDetails.dimensionsId)
        currentDataCopy[0].questionSet[topicIndex].mappingTopics = filteredCurrentTopic
    }
    return currentDataCopy
}