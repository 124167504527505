import * as actionTypes from "../../constants";
import { ApiBase } from "../../../utils/apiBase";
import { BATCH_QUESTIONS_URL } from "../../../../config/constants";
import {
  NLPCommonReqBodies,
  getUserDataFromTree,
} from "../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchBatchQuestions = (cb, payload) => {
  const apiBaseInstance = new ApiBase();
  let apiPayload = payload;
  if (!apiPayload) {
    apiPayload = {
      ...NLPCommonReqBodies(),
      ...getUserDataFromTree(),
      projectOrigin: localStorage.getItem("projectOrigin"),
    }
  }
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_BATCH_QUESTIONS_START });
    apiBaseInstance.instance
      .post(BATCH_QUESTIONS_URL, { ...apiPayload, ...getProjectIdAndOrgId(apiPayload) })
      .then((res) => fetchBatchQuestionsSuccess(dispatch, { res, cb }))
      .catch((err) => fetchBatchQuestionsFailed(dispatch, err));
  };
};

function fetchBatchQuestionsSuccess(dispatch, { res, cb }) {
  dispatch({
    type: actionTypes.FETCH_BATCH_QUESTIONS_SUCCESS,
    data: questionsResponse(res.data.data.currentBatchQuestions)//res.data.data.currentBatchQuestions,
  });
  cb && cb();
}
function questionsResponse(data) {
  return data.map((item) => { item.type = "Comments"; return item })
}
function fetchBatchQuestionsFailed(dispatch, err) {
  return dispatch({
    type: actionTypes.FETCH_BATCH_QUESTIONS_FAIL,
    error: err.message,
  });
}
