import * as actionTypes from "../../constants";
// import Axios from "axios";
import { BATCH_IDS_URL } from "../../../../config/constants";
import { NLPCommonReqBodies } from "../../../utils/actionOperations";
import { ApiBase } from "../../../utils/apiBase";

export const fetchBatchIds = (payload) => {
  const axios = new ApiBase().instance
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_BATCH_IDS_STARTED });
    axios.post(BATCH_IDS_URL, { ...NLPCommonReqBodies(), ...payload })
      .then((res) => fetchBatchIdsSuccess(dispatch, res))
      .catch((err) => fetchBatchIdsFailed(dispatch, err));
  };
};
function fetchBatchIdsFailed(dispatch, err) {
  return dispatch({
    type: actionTypes.GET_BATCH_IDS_FAIL,
    error: err.message,
  });
}
function fetchBatchIdsSuccess(dispatch, res) {
  return dispatch({
    type: actionTypes.GET_BATCH_IDS_SUCCESS,
    data: res.data.data.response.data,
  });
}
