import { connect } from "react-redux";
import { AddAdmins } from "./AddAdmin";
import { SelectState } from "./Selector";
// import "./index.scss";
import {
  addAdmin,
  fetchAdmins,
  adminFormChange,
  clearAdminForm,
  addAdminPaginationChange,
  deleteAdmin,
} from "../../redux/actions";

const mapStateToProps = (state) => ({ ...SelectState(state) });

const mapDispatchToProps = (dispatch) => ({
  addAdmin: (arg, cb) => dispatch(addAdmin(arg, cb)),
  fetchAdmins: (params) => dispatch(fetchAdmins(params)),
  adminFormChange: (params) => dispatch(adminFormChange(params)),
  clearAdminForm: (params) => dispatch(clearAdminForm(params)),
  addAdminPaginationChange: (params) =>
    dispatch(addAdminPaginationChange(params)),
  deleteAdmin: (params,callback) => dispatch(deleteAdmin(params,callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAdmins);
