import { UPDATE_THEME_STATE_VALUES } from '../../../constants';

export * from './fetchWordCloudData';
export * from './fetchCommentStatisctics';
export * from './fetchCommentsData';
export * from './fetchRelationData';
export * from './fetchTopicComments';
export * from './fetchAllThemeData';
export * from './fetchDemographicTopicData';
export * from './fetchExploreDashboardFilters'
export * from './setQuestionTopic'

export const updateThemeStateValues = (params) => {
  return {
    type: UPDATE_THEME_STATE_VALUES,
    payload: { params },
  };
};
