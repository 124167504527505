import React from "react";
import { getDataFromUrlToken } from "../../../../../../utils/urlDataStore";
import { URL_DATA } from "../../../../../../../config/urlDataConstants";
import { SelectTopic } from "./children/SelectTopic";
import { TopicGraph } from "./children/TopicGraph";
import { constants } from "./constants";
import GraphLoader from "../../../../../../Components/ContentLoaders/GraphLoader";
import { SelectDemographics } from "./children/SelectDemographics";
import { getDefaultHighestChildManager, getOuterTreeDataChildren } from "../../../../../../utils/treeApiUtils";
import { getSelectedUserData } from "../../helper";
import { checkIfObjectsAreEqual } from "../../../../../../utils/customFunctions";
import { deepCopy } from "../../../../../../utils/deepCopy";
import { checkIfTwoArraysAreIsEqual, getURLFilterAndByScore } from "../../../../../../utils/byScoreFilterUtils";
import { Affix, Select } from "antd";
import { DASHBOARD_TABS } from '../../../../../../../config/tabConstants';
import SVGIcons from "../../../../../../Components/SVGIcons";
const { Option } = Select;

export class Demographics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColumns: constants.selectedColumns,
      questionIsSelected: false
    };
  }

  componentDidMount() {
    const filters = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER) || {};
    const selectedUserData = getSelectedUserData();
    if (this.checkIfApiCallRequired(filters, selectedUserData)) {
      this.apiCall();
    }
  }

  componentDidUpdate() {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER) || {};
    const selectedUserData = getSelectedUserData();
    let filterData = getURLFilterAndByScore(urlFilter)
    if (
      checkIfObjectsAreEqual(filterData.filters, this.props.filters) &&
      checkIfTwoArraysAreIsEqual(filterData.byScore, this.props.byScore) &&
      checkIfTwoArraysAreIsEqual(filterData.multipleSelect, this.props.multipleSelect) &&
      checkIfObjectsAreEqual(selectedUserData.directReporteesOnly, this.props.directReporteesOnly) &&
      checkIfObjectsAreEqual(selectedUserData.selectedUser, this.props.selectedUser)
    ) {
    } else {
      if (
        !this.props.dimensionsApi.isProcessing &&
        !this.props.exploreOutliersApi.isProcessing &&
        !this.props.demographicsApi.isProcessing
      ) {
        this.getNumericDemographics(
          this.props.topic,
          this.props.demographicsSelectedColumns,
          this.props.selectedQuestionId
        );
        this.getOutliers();
      }

    }
  }

  apiCall = () => {
    const surveyDetails =
      this.props.surveyDetailsApi &&
      this.props.surveyDetailsApi.surveyDetails
    this.props.fetchDimensions(
      { currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.DEMOGRAPHICS, projectId: surveyDetails.projectId, outcomeVariable: surveyDetails.outcomeVariable, projectType: surveyDetails.projectType },
      (success, topic) => {
        if (success) {
          this.getNumericDemographics(topic);
        }
        this.getOutliers();
      }
    );
  };

  checkIfApiCallRequired = (urlFilter, selectedUserData) => {
    let filterData = getURLFilterAndByScore(urlFilter)
    if (
      !this.props.dimensionsApi.isInitialApiCalled ||
      !checkIfObjectsAreEqual(filterData.filters, this.props.filters) ||
      !checkIfTwoArraysAreIsEqual(filterData.byScore, this.props.byScore) ||
      !checkIfTwoArraysAreIsEqual(filterData.multipleSelect, this.props.multipleSelect) ||
      !checkIfObjectsAreEqual(selectedUserData.directReporteesOnly, this.props.directReporteesOnly) ||
      !checkIfObjectsAreEqual(selectedUserData.selectedUser, this.props.selectedUser)
    ) {
      return true;
    }
    return false;
  };

  getOutliers = () => {
    const projectId = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS).projectId;
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const selectedUserData = getSelectedUserData();
    const projectDetails =
      this.props.surveyDetailsApi && this.props.surveyDetailsApi.surveyDetails
    let filterData = getURLFilterAndByScore(urlFilter)
    this.props.exploreFetchOutliersData({
      currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.DEMOGRAPHICS,
      projectId: projectId,
      ...selectedUserData,
      hrisFileId: projectDetails.hrisFileId,
      filters: filterData.filters
    });
  };

  getNumericDemographics = (topic, columns = [], selectedQuestionId, scoreLevel) => {
    const selectedUserData = getSelectedUserData();
    const filters = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER) || {};
    let filterData = getURLFilterAndByScore(filters)
    const projectId = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS).projectId;
    const projectDetails =
      this.props.surveyDetailsApi && this.props.surveyDetailsApi.surveyDetails;
    const topLevelManagers =
      this.props.treeDataApi &&
      getOuterTreeDataChildren(this.props.treeDataApi);
    this.props.fetchDemographicsData({
      currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.DEMOGRAPHICS,
      topics: topic ? [topic] : [],
      ...filterData,
      columns,
      topLevelManagers,
      projectId,
      projectType: projectDetails.projectType,
      selectedQuestionId,
      hrisFileId: projectDetails.hrisFileId,
      isAnonymous: projectDetails.isAnonymous || false,
      directReporteesOnly: selectedUserData.directReporteesOnly,
      selectedUser: selectedUserData.selectedUser,
      // scoreLevel: scoreLevel || this.props.scoreLevel
    });
  };

  addDemographics = (selectedColumns) => {
    this.getNumericDemographics(
      this.props.topic,
      selectedColumns,
      this.props.selectedQuestionId
    );
    this.props.updateDemographicReducer({ selectedColumns });
  };

  onSelectChange = (node) => {
    let questionIsSelected = false
    if (node.isQuestion) {
      questionIsSelected = true
    }
    this.setState({
      questionIsSelected: questionIsSelected
    })
    this.getNumericDemographics(
      node.dimension,
      this.props.demographicsSelectedColumns,
      node.isQuestion ? node.value : undefined
    );
    this.props.updateDemographicReducer({
      topic: node.dimension,
      selectedQuestionId: node.isQuestion ? node.value : undefined,
    });
  };

  onRemove = (value) => {
    let selectedColumns = this.props.demographicsSelectedColumns.filter(
      (item) => item !== value
    );
    this.getNumericDemographics(
      this.props.topic,
      selectedColumns,
      this.props.selectedQuestionId
    );
    this.props.updateDemographicReducer({ selectedColumns });
  };

  onChangeScoreType = (event, value) => {
    let scoreLevel = event;
    // this.getNumericDemographics(
    //   this.props.topic,
    //   this.props.demographicsSelectedColumns,
    //   this.props.selectedQuestionId,
    //   scoreLevel
    // );
    this.props.updateDemographicReducer({ scoreLevel });
  }

  getDemographicScoreLevel = () => {
    return deepCopy(constants.scoreTypes).map(item => {
      item.onMenuItemClick = this.onChangeScoreType
      return item
    })
  }

  getSelectedScoreLevel = () => {
    return this.props.scoreLevel || constants.defaultScoreType
  }

  getByManagerToolTip = () => {
    let rootManager = getDefaultHighestChildManager(this.props.treeDataApi)
    const selectedUserData = getSelectedUserData();
    if (selectedUserData.selectedUserName && selectedUserData.selectedUserName !== "") {
      return `Showing all managers directly reporting to ${selectedUserData.selectedUserName}`
    } else if (rootManager && rootManager[0] && rootManager[0].title) {
      return `Showing all managers directly reporting to ${rootManager[0].title}`
    } else {
      return ''
    }
  }

  scoreLevelSelect = () => {
    return (
      <div className="score-block">
        <p>Score Type:{" "}</p>
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          bordered={false}
          suffixIcon = {<SVGIcons type={"SVG-dropdown"}/>}
          value={this.getSelectedScoreLevel()}
          onChange={(event) => {
            this.onChangeScoreType(event)
          }}
        >
          {constants.scoreTypes.map((value) => (
            <Option key={value.value}>{value.label}</Option>
          ))}
        </Select>

      </div>
    )
  }

  render() {
    const surveyDetails =
      this.props.surveyDetailsApi &&
      this.props.surveyDetailsApi.surveyDetails
    return (
      <>
        {this.props.dimensionsApi.isProcessing ? (
          <GraphLoader height={300} width={600} />
        ) : (
          <>
            <div className="demographics-block">
                <Affix offsetTop={5}>
              <div className="demographics-block__header">
                    <div className="demographics-block__header--left"> 
                      <div id='demographics-dropdown' className="commentquestion-search"></div>               
                  <SelectTopic
                    isVerySimpleSurvey={surveyDetails.projectType && surveyDetails.projectType === "Very_Simple_Survey"}
                    dimensions={this.props.dimensionsApi.dimensions}
                    onSelectChange={this.onSelectChange}
                    topic={this.props.topic}
                    selectedQuestionId={this.props.selectedQuestionId}
                  />

                </div>
                <div className="demographics-block__header--right">
                  {this.scoreLevelSelect()}
                  {/* <DotMenu
                    selectedKeys={[this.getSelectedScoreLevel()]}
                    itemList={this.getDemographicScoreLevel()} /> */}
                </div>
              </div>
                </Affix>
            </div>
            {/* <RadioGroup
              value={this.props.scoreLevel}
              className='demographics-radio-groups'
              data={constants.scoreTypes}
              onChange={this.onChangeScoreType}
              defaultValue={constants.defaultScoreType} /> */}
            {this.props.demographicsApi.isProcessing ? (
              <GraphLoader height={300} width={600} />
            ) : (
              <>
                <TopicGraph
                      scoreType={this.getSelectedScoreLevel()}
                  threshold={this.props.thresholdApi}
                  data={this.props.demographicsData}
                      highestFavScore={this.props.highestFavScore}
                      highestNeutralScore={this.props.highestNeutralScore}
                      highestUnfavScore={this.props.highestUnfavScore}
                  onRemove={this.onRemove}
                  byManagerToolTip={this.getByManagerToolTip()}
                  outliers={this.props.exploreOutliersApi}
                  topics={this.props.topic}
                  questionIsSelected={this.state.questionIsSelected}
                />
                <SelectDemographics
                  demographicsColumns={this.props.demographicsColumns}
                  onAdd={this.addDemographics}
                  demographicsSelectedColumns={
                    this.props.demographicsSelectedColumns
                  }
                />
              </>
            )}
          </>
        )}
      </>
    );
  }
}
