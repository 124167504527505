import { fetchPayload, update } from "../../../utils/reducerOperations";
import { fetchFormConfigurations } from "../../inputFormConfigurations";
import {
    INVITE_VIEWER_START,
    INVITE_VIEWER_SUCCESS,
    INVITE_VIEWER_FAILED,
    LIST_VIEWERS_START,
    LIST_VIEWERS_SUCCESS,
    LIST_VIEWERS_FAILED,
    INVITE_VIEWER_FORM ,
    CLEAR_INVITE_VIEWER_FORM
} from "../../constants";
import {
    inviteViewerStarted,
inviteViewerSuccess,
inviteViewerFailed,
listViewersStarted,
listViewersSuccess,
listViewersFailed,
    } from "./Helpers";
import { formChangeSuccess } from "../../../utils/reducerOperations";    
import { Default } from "./InitialState";
const InviteViewerReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        //invite
        case INVITE_VIEWER_START:
            return inviteViewerStarted(state, payload);
        case INVITE_VIEWER_SUCCESS:
            return inviteViewerSuccess(state, payload);
        case INVITE_VIEWER_FAILED:
            return inviteViewerFailed(state, payload);
        //list
        case LIST_VIEWERS_START:
            return listViewersStarted(state, payload);
        case LIST_VIEWERS_SUCCESS:
            return listViewersSuccess(state, payload);
        case LIST_VIEWERS_FAILED:
            return listViewersFailed(state, payload);
         
        case INVITE_VIEWER_FORM:
            return update(
                {
                  ...state
                },
                formChangeSuccess(state, payload)
              );
        case CLEAR_INVITE_VIEWER_FORM:
            return update(
                {
                  ...state,
                  inputBoxConfiguration: fetchFormConfigurations(["name","lastName","email",]),
                }
                );

        default:
            return { ...state };
    }
};

export default InviteViewerReducer;
