import { setInitialState } from "../../../utils/reducerOperations";
export function Default() {
    return {
        storyboardQuestionsApi: setInitialState(),
        storyboardGoingWellApi: setInitialState(),
        storyboardImprovementApi: setInitialState(),
        batchQuestions: [],
        goingWellWordCloud: [],
        improvementWordCloud: [],
        goingWellCommentsApi: setInitialState(),
        improvementCommentsApi: setInitialState(),
        wordCloudMinimumCount: 5,
        storyboardFetchHighCountCommentQuestionApi: setInitialState(),
        storyboardFetchLessCountCommentQuestionApi: setInitialState()
    };
}
