import {
    makeKey,
    update,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
import {toast} from '../../../../utils/messageUtils';
export function filterAggregationFailed(state, payload) {
    let response = makeKey(
        'filterAggregationApi',
        update(state.filterAggregationApi, {
            ...(payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload))
        })
    );
    toast.error(response.filterAggregationApi.message);
    return response;
}
