import {
    makeKey,
    update,
    getSuccessResponse
} from '../../../../utils/reducerOperations';
export function fetchSurveyListSuccess(state, payload) {
    return makeKey(
        'fetchSurveyApi',
        update(state.fetchSurveyApi, getSuccessResponse(payload))
    );
}
