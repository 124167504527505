import { fetchFormConfigurations } from '../../../inputFormConfigurations';
import { goals } from './defaultGoals';
import { deepCopy } from '../../../../utils/deepCopy';
export function Default() {
    return {
        isCreateFormValid: false,
        createProjectConfiguration: fetchFormConfigurations([
            'projectName',
        ]),
        createProjectFields: {
            aboutSurvey: undefined,
            surveyLaunchDate: undefined,
            surveyLaunchTime: undefined,
            endDate: undefined,
            endTime: undefined,
            isAnonymous: false,
            hrisFileId: "",
            updatedProjectName: '',
            isLocalizationEnabled: false,
            translateAboutSurvey: false,
        },
        goals: deepCopy(goals),
        questionSet: [], //engagement will be present by default
        wizardConfig: {},
        activatedStep: 0,
        getProjectDetailsApi: {
            isProcessing: false,
            error: null
        },
        selectedMenu: "create",
        uploadTranslatedQuestionsApi: {
            isProcessing: false,
            error: false
        },
        checkTranslatedQuestionsApi: {
            isProcessing: false,
            error: false
        }
    };
}
