import {
    FETCH_STORYBOARD_QUESTION_COMMENTS_FAILED,
    FETCH_STORYBOARD_QUESTION_COMMENTS_START,
    FETCH_STORYBOARD_QUESTION_COMMENTS_SUCCESS
} from '../../constants';
import { SENTIMENTAL_URL } from '../../../../config/constants';
import { ApiBase } from '../../../utils/apiBase';
import { withType } from '../../../utils/actionOperations';
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchStoryboardQuestionComments = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(FETCH_STORYBOARD_QUESTION_COMMENTS_START, { key: payload.key }));
        apiBaseInstance.instance
            .post(SENTIMENTAL_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                dispatch(withType(FETCH_STORYBOARD_QUESTION_COMMENTS_SUCCESS, {
                    data: { ...response.data, key: payload.key },
                    message: response.message,
                }));
                setTimeout(() => {
                    cb && cb();
                }, 0)
            })
            .catch(err => {
                dispatch(withType(FETCH_STORYBOARD_QUESTION_COMMENTS_FAILED, {
                    error: err,
                    key: payload.key
                }));
            });
    };
};

