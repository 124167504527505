import * as am4core from '@amcharts/amcharts4/core';
import * as am4pluginsWordCloud from "@amcharts/amcharts4/plugins/wordCloud";

export function wordCloudSeries(wordCloud, wordCloudFields) {
    let series = wordCloud.series.push(new am4pluginsWordCloud.WordCloudSeries());
    series.randomness = 0.1;
    series.minFontSize = 30;
    series.rotationThreshold = 0;
    series.dataFields.word = wordCloudFields.wordField;
    series.dataFields.value = wordCloudFields.valueField;
    series.heatRules.push({
        target: series.labels.template,
        property: wordCloudFields.property,
        min: am4core.color(wordCloudFields.minColor),
        max: am4core.color(wordCloudFields.maxColor),
        dataField: wordCloudFields.dataField
    });
    series.labels.template.tooltipText = wordCloudFields.tooltipFormat;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("rgba(0, 0, 0, 0.75)");
    series.tooltip.background.cornerRadius = 4;
    series.tooltip.background.stroke = am4core.color("rgba(0, 0, 0, 0.75)");
    series.tooltip.label.fill = am4core.color("#fff");
    return series;
}