import React, { Component } from "react";
import Tree from "react-infinite-tree";
import TreeNode from "./TreeNode";
import Toggler from "./Toggler";
import { Tooltip } from "antd";
import { EmptyState } from "../../Packages/EmptyState";
import { UserOutlined } from "@ant-design/icons";
import constants from "./constants";
import SearchInput from "../../Components/SearchItem";
import "./index.scss";
let level = 0;

export class InfiniteTree extends Component {
  treeRef = React.createRef();
  state = {
    toggle: "open",
    searchValue: "",
    selected: "",
    isTreeEmpty: false,
    nodeLevel: 0,

  };
  componentDidMount = () => {
    const height = document.getElementById("scroll_block_wrap").clientHeight;
    this.setState({ windowHeight: height });
  };
  componentDidUpdate = (props) => {
    if (this.props.searchItemValue && this.props.searchItemValue !== this.state.searchValue) {

      this.setState({ toggle: "open", searchValue: this.props.searchItemValue })
      this.peoplesearchTree(this.props.searchItemValue)
    }
    const { tree } = this.treeRef.current;
    if (this.props.removeSelection) tree.selectNode(null);
    if (
      this.props.searchItemValue === "" &&
      this.props.searchItemValue !== this.state.searchValue
    ) {
      this.setState({
        searchValue: this.props.searchItemValue,
      });
    }
    if (this.props.searchItemValue) {
      if (this.props.searchItemValue !== this.state.searchValue) {
        this.setState({
          searchValue: this.props.searchItemValue,
        });
      }
    }
  };
  updateLevel = () => {
    level = 0;
  };
  getTogglerForTree = (node, tree, nodeLevel, selected) => {
    let toggleState = "";
    const hasChildren = node.hasChildren();

    const selectedNodeValue = this.props.selectedUser
      ? this.props.selectedUser
      : localStorage.getItem("selectedUser");
    if (
      (hasChildren && node.loadOnDemand) ||
      (hasChildren && !node.state.open)
    ) {
      toggleState = constants.CLOSED;
      //if (this.state.toggle === constants.CLOSE) tree.closeNode(node);
    }
    if (hasChildren && node.state.open) toggleState = constants.OPENED;
    //if (this.props.treeNodeLevel < 1) {
    if (this.state.searchValue === '' || this.props.treeNodeLevel < 1) {
      if (hasChildren && node.level < nodeLevel) {
        if (this.state.toggle === constants.OPEN) {
          tree.openNode(node);
        }
        if (selectedNodeValue) {
          toggleState = constants.OPENED;
          tree.openNode(node);
        }
      }
    } else {
      let nodeSearchKey = this.openNode(node, tree);
      if (nodeSearchKey && nodeSearchKey > 0) {
        if (hasChildren && node.level) {
          if (this.state.toggle === constants.OPEN) {
            tree.openNode(node);
          }
          if (selectedNodeValue) {
            toggleState = constants.OPENED;
            tree.openNode(node);
          }
        }
      }
      else {
        tree.closeNode(node);
      }
    }

    return (
      <Toggler
        state={toggleState}
        onClick={() => {
          if (toggleState === constants.CLOSED) {
            tree.openNode(node);
            this.setState({ toggle: constants.OPEN });
          } else if (toggleState === constants.OPENED) {
            tree.closeNode(node);
            this.setState({ toggle: constants.CLOSE });
          }
        }}
      />
    );
  };
  trimNodeName = (node) => {
    return node.name.length > 20 ? (
      <Tooltip placement="right" title={node.name}>
        {node.name.slice(0, 20) + "... "}
      </Tooltip>
    ) : (
      node.name
    );
  };
  getTitleForSearch = (node) => {
    const { searchValue } = this.state;
    let beforeStr,
      afterStr,
      matchedValue,
      index = null;
    if (searchValue.length) {
      index = node.name.toLowerCase().indexOf(searchValue.toLowerCase());
      beforeStr = node.name.substr(0, index);
      afterStr = node.name.substr(index + searchValue.length);
      matchedValue = node.name.substr(index, searchValue.length);
    }
    return searchValue.length && index > -1 ? (
      <span>
        {beforeStr}
        <span className="site-tree-search-value">{matchedValue}</span>
        {afterStr}
      </span>
    ) : (
      <>{this.trimNodeName(node)}</>
    );
  };
  openNode = (node, tree) => {
    const { searchValue } = this.state;
    // eslint-disable-next-line
    let beforeStr,// eslint-disable-next-line
      afterStr,// eslint-disable-next-line
      matchedValue,// eslint-disable-next-line
      index = null;

    var stack = [],
      ii,
      stackResult = [];
    stack.push(node);
    while (stack.length > 0) {
      node = stack.pop();
      if (searchValue.length) {
        index = node.name.toLowerCase().indexOf(searchValue.toLowerCase());
        beforeStr = node.name.substr(0, index);
        afterStr = node.name.substr(index + searchValue.length);
        matchedValue = node.name.substr(index, searchValue.length);
      }
      if (searchValue.length && index > -1) {
        stackResult.push(node.level);
        return node.level;
      } else if (node.children && node.children.length) {
        for (ii = 0; ii < node.children.length; ii += 1) {
          stack.push(node.children[ii]);
        }
      }
    }
    return 0;
  };

  getTreeNode = (node, tree) => {
    const title = this.getTitleForSearch(node);
    //tree.openNode(node);
    var selected = false;
    /* if (this.props.treeNodeLevel != 0) {
       var nodeLevel = this.props.treeNodeLevel;
     } else {*/
    var nodeLevel = 2;
    /// }

    const selectedNodeValue = this.props.selectedUser
      ? this.props.selectedUser
      : localStorage.getItem("selectedUser");

    if (selectedNodeValue === node.id) {
      selected = true;
      nodeLevel = node.state.depth;
    }
    return (
      <TreeNode
        className="tree-nodeitem"
        selected={selected}
        tabIndex={node.index}
        depth={node.state.depth}
        open={selected}
      >
        {this.getTogglerForTree(node, tree, nodeLevel, selected)}
        <span
          className={`tree-node-name ${node.value >= this.props.threshold
            ? ""
            : !this.props.isPeoplePage
              ? "disabledClass"
              : ""
            }`}
          onClick={(event) =>
            node.value >= this.props.threshold
              ? tree.selectNode(node)
              : !this.props.isPeoplePage
                ? null
                : tree.selectNode(node)
          }
        >
          {/* <span className="tree-node-icon">{node.icon ? node.icon : null}</span> */}
          {title}
        </span>
        <span className="tree-node-value">{node.value}</span>
      </TreeNode>
    );
  };
  updateTreeData = (data) => {
    level = !this.state.searchValue.length ? level + 1 : level + 0;
    return data.map((treeNode, i) => {
      if (!treeNode.children) {
        return {
          id: treeNode.key,
          name: treeNode.title,
          level: level,
          value: treeNode.count === 0 ? "-" : treeNode.count,
          icon: <UserOutlined />,
        };
      } else if (treeNode.children) {
        return {
          id: treeNode.key,
          name: treeNode.title,
          value: treeNode.count === 0 ? "-" : treeNode.count,
          level: level,
          loadOnDemand: true,
          children: this.updateTreeData(treeNode.children),
        };
      }
      return {};
    });
  };
  renderInfiniteTree = () => {
    return (
      <Tree
        id="infinite-tree-id"
        className="infinite-scroller"
        style={{ overflow: "hidden" }}
        width={this.props.width}
        height={300}
        ref={this.treeRef}
        rowHeight={30}
        onSelectNode={this.props.onTreeSelect}
        data={this.updateTreeData(this.props.data)}
      >
        {({ node, tree }) => {
          return (
            <div className="infinite-scroller__content">
              {this.getTreeNode(node, tree)}
            </div>
          );
        }}
      </Tree>
    );
  };

  peoplesearchTree = async (e) => {
    this.setState({ toggle: "open" })
    if (this.props.searchItemValue) {
      this.setState({ searchValue: e });
    }
    const { tree } = this.treeRef.current;
    if (this.props.searchItemValue) {
      const filterOptions = {
        caseSensitive: false,
        exactMatch: false,
        includeAncestors: true,
        includeDescendants: true,
      };
      await tree.filter(
        this.props.searchItemValue
          ? this.props.searchItemValue
          : this.props.searchItemValue,
        filterOptions
      );
      this.checkIfTreeEmpty();
    } else {
      await tree.unfilter();
      this.checkIfTreeEmpty();
    }
  };
  searchTree = async (e) => {
    this.setState({ toggle: "open" })
    if (this.props.searchItemValue) {
      this.setState({ searchValue: e });
    } else this.setState({ searchValue: e.target.value });
    const { tree } = this.treeRef.current;
    if (e.target.value || this.props.searchItemValue) {
      const filterOptions = {
        caseSensitive: false,
        exactMatch: false,
        includeAncestors: true,
        includeDescendants: true,
      };
      await tree.filter(
        this.props.searchItemValue
          ? this.props.searchItemValue
          : e.target.value,
        filterOptions
      );
      this.checkIfTreeEmpty();
    } else {
      await tree.unfilter();
      this.checkIfTreeEmpty();
    }
  };
  checkIfTreeEmpty = () => {
    let domElement = document.getElementById("infinite-tree-id");
    if (!domElement.textContent) {
      this.setState({ isTreeEmpty: true });
    } else this.setState({ isTreeEmpty: false });
  };

  clearTree = (props) => {
    this.setState({ searchValue: "" });
    this.props.clearTreeData()

  };

  render = () => {
    const { isTreeEmpty } = this.state;
    this.updateLevel();
    return (
      <>
        {this.props.searchBoxNoVisibility ? (
          ""
        ) : (
          <div className="node-search">
            <SearchInput
              value={this.state.searchValue}
              onChange={this.searchTree}
              clearSearch={this.clearTree}
            />
          </div>
        )}

        <div className={!isTreeEmpty ? "node-tree-node" : ""}>
          <EmptyState
            component={constants.FILTER}
            emptyStateMessage={constants.NOT_FOUND}
          />
        </div>
        <div className="node-result" id="scroll_block_wrap">
          {this.renderInfiniteTree()}
        </div>
      </>
    );
  };
}
