
export const publishProjectStarted = (state) => {
    return {
        ...state,
        publishSurveyApi: {
            isProcessing: true,
            data: null,
            message: null,
            error: false,
        }
    };
};

export const publishProjectSuccess = (state, payload) => {
    return {
        ...state,
        publishSurveyApi: {
            isProcessing: false,
            data: payload.data,
            message: payload.message,
            error: false,
        }
    };
};

export const publishProjectFailed = (state, payload) => {
    return {
        ...state,
        publishSurveyApi: {
            isProcessing: false,
            data: payload.data,
            message: payload.message,
            error: true,
        }
    }
}
