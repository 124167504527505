import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const storyboardDriversStarted = (state) => {
    return {
        ...state,
        storyboardDriversApi: getApiStartState()
    }

}

export const storyboardDriversSuccess = (state, payload) => {
    return {
        ...state,
        storyboardDriversApi: getSuccessResponse(payload && payload.data),
    }

}

export const storyboardDriversFailed = (state, payload) => {
    return {
        ...state,
        storyboardDriversApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        },
    }

}