import {
  FETCH_EXPLORE_QUESTIONS_FAILED,
  FETCH_EXPLORE_QUESTIONS_SUCCESS,
  FETCH_EXPLORE_QUESTIONS_START,
  SET_API_CALLED_KEY_EXPLORE_QUESTIONS,
  GET_QUESTION_TABLE_TREND_START,
  GET_QUESTION_TABLE_TREND_FAIL,
  GET_QUESTION_TABLE_TREND_SUCCESS
} from "../../constants";
import { FETCH_SCORES_URL, GET_COMPARISON_SUREVEY_DETAILS } from "../../../../config/constants";
import { ApiBase } from "../../../utils/apiBase";
import { withType } from "../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export function fetchExploreQuestions(payload) {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(FETCH_EXPLORE_QUESTIONS_START, {}));
    apiBaseInstance
      .post(FETCH_SCORES_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => fetchSurveyReportSuccess(dispatch, res, payload))
      .catch((err) => fetchSurveyReportFailure(dispatch, err));
  };
}

function fetchSurveyReportSuccess(dispatch, res, payload) {
  const data = (res.data && res.data.data && res.data.data.response) || {
    dimensionTable: [],
    itemTable: [],
  };
  return dispatch(
    withType(FETCH_EXPLORE_QUESTIONS_SUCCESS, {
      data,
      filters: payload.filters,
      byScore: payload.byScore,
      multipleSelect: payload.multipleSelect,
      selectedUser: payload.selectedUser,
      directReporteesOnly: payload.directReporteesOnly,
    })
  );
}

function fetchSurveyReportFailure(dispatch, err) {
  return dispatch(withType(FETCH_EXPLORE_QUESTIONS_FAILED, { error: err }));
}

export const setIsApiCalledInExploreQuestion = () => {
  return dispatch => {
    dispatch(withType(SET_API_CALLED_KEY_EXPLORE_QUESTIONS));
  };
};

export const NewDashboardGetQuestionsTableTrend = (payload, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(GET_QUESTION_TABLE_TREND_START, {}));
    apiBaseInstance.instance
      .post(GET_COMPARISON_SUREVEY_DETAILS, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        dispatch(
          withType(GET_QUESTION_TABLE_TREND_SUCCESS, {
            data: response.data,
            projectDetails: { questionSet: payload.questionSet, projectType: payload.projectType },
            message: response.message,
          })
        );
        cb && cb(true, response.data && response.data.data && response.data.data.response && response.data.data.response.surveys);
      })
      .catch((err) => {
        dispatch(
          withType(GET_QUESTION_TABLE_TREND_FAIL, {
            error: err,
          })
        );
        cb && cb(false);
      });
  };
};
