import {
  TOPIC_RELATION_URL,
} from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from '../../../../utils/actionOperations';
import {
    FETCH_EXPLORE_RELATION_DATA_START,
    FETCH_EXPLORE_RELATION_DATA_SUCCESS,
    FETCH_EXPLORE_RELATION_DATA_FAILED
} from '../../../constants';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchExploreRelationData = (payload,cb) => {
  const apiBaseInstance = new ApiBase().instance
  return (dispatch) => {
    dispatch(withType(FETCH_EXPLORE_RELATION_DATA_START, {questionId : payload.selectedQuestionId}));
    apiBaseInstance
      .post(TOPIC_RELATION_URL, {
        ...payload,
        ...getProjectIdAndOrgId(payload),
        data: {},
      })
      .then(response => {
        dispatch(withType(FETCH_EXPLORE_RELATION_DATA_SUCCESS, {
            data: response.data,
            message: response.message,
            filters:payload.filters,
            byScore: payload.byScore,
          multipleSelect: payload.multipleSelect,
            selectedUser: payload.selectedUser,
            directReporteesOnly: payload.directReporteesOnly,
            questionId : payload.selectedQuestionId
        }));
        setTimeout(() => {
            cb && cb();
        }, 0)
    })
    .catch(err => {
        dispatch(withType(FETCH_EXPLORE_RELATION_DATA_FAILED, {
            error: err,
            questionId : payload.selectedQuestionId
        }));
    });
  };
};

