import { fetchPayload } from "../../../utils/reducerOperations";
import {
    SET_STORYBOARD_TOP_AND_BOTTOM_TOPICS,
    CLEAR_STORYBOARD_TOP_AND_BOTTOM_TOPICS
} from "../../constants";
import {
    setTopAndBottomTopics,
    clearAllData
} from "./Helpers";
import { initialState } from "./InitialState";
const fetchStoryboardTopAndBottomTopicsReducer = (state = initialState, action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case SET_STORYBOARD_TOP_AND_BOTTOM_TOPICS:
            return setTopAndBottomTopics(state, payload);
        case CLEAR_STORYBOARD_TOP_AND_BOTTOM_TOPICS:
            return clearAllData(state);    
        default:
            return { ...state };
    }
};

export default fetchStoryboardTopAndBottomTopicsReducer;
