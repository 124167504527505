import { ADD_ORGANIZATION_URL } from "../../../../config/constants";
import { ApiBase } from "../../../utils/apiBase";
import {
  ADD_ORGANIZATION_STARTED,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_FAILED,
} from "../../constants";
import { withType } from "../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const addOrganization = (params, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(ADD_ORGANIZATION_STARTED, params));
    apiBaseInstance.instance
      .post(ADD_ORGANIZATION_URL, { ...params, ...getProjectIdAndOrgId(params) })
      .then((res) => {
        dispatch(withType(ADD_ORGANIZATION_SUCCESS, res.data));
        cb();
      })
      .catch((err) => {
        dispatch(withType(ADD_ORGANIZATION_FAILED, err));
      });
  };
};
