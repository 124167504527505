import { deepCopy } from "../../../../../../utils/deepCopy"

export const handleDragEndDemographicsOption = (state, payload) => {
    let updatedGoals = getdragAndDropAppliedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getdragAndDropAppliedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(item => {
        if (item.blockId === payload.blockId) {
            item.questions.forEach(question => {
                const dragQuestion = question.options[payload.sourceIndex]
                if (dragQuestion) {
                    question.options.splice(payload.sourceIndex, 1)
                    question.options.splice(payload.destinationIndex, 0, dragQuestion)
                }
            })
        }
    })
    return goalsCopy
}
