import { SET_PROJECT_CONFIG } from "../../constants";
import { getProjectById } from "./getProjectById";
import { saveProject } from "./saveProject";
import { publishProject } from "./publishProject";
import { imageUpload } from "./fileUpload";
import { renameProject } from "./renameProject";

export function setProjectsConfig(config) {
  return {
    type: SET_PROJECT_CONFIG,
    config,
  };
}

export {
  getProjectById,
  saveProject,
  publishProject,
  imageUpload,
  renameProject,
};
