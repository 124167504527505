import {
  RESPONSES_PROCESS_STARTED,
  RESPONSES_PROCESS_SUCCESS,
  RESPONSES_PROCESS_FAILED,
} from "../../constants";
import { fetchPayload, update } from "../../../utils/reducerOperations";
import { Default } from "./InitialState";
import {
  responsesStarted,
  responsesSuccess,
  responsesFailed,
} from "./Helpers";
const responsesPageReducer = (state = Default(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case RESPONSES_PROCESS_STARTED:
      return update(state, responsesStarted(state, payload));
    case RESPONSES_PROCESS_SUCCESS:
      return update(state, responsesSuccess(state, payload));
    default:
      return responsesFail(state, action);
  }
};
const responsesFail = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case RESPONSES_PROCESS_FAILED:
      return update(state, responsesFailed(state, payload));
    default:
      return { ...state };
  }
};

export default responsesPageReducer;
