export const changeEnablerTemplate = [
    {
        questionDimension: 'Change Enabler',
        type: 'Matrix',
        questionLabel: 'Change Enabler',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: `We have the the right skills to use the new ERP system.`, value: '', isSelected: false
        },
        {
            text: `After the implementation, the new ERP system will make me more effective at my job.`, value: '', isSelected: false

        },
        {
            text: `My job will be secure during and after the ERP project implementation.`, value: '', isSelected: false

        }]
    }
]

