import React, { useState } from "react";
import CustomModal from "../CustomModal";
import FileUpload from "../FileUpload";
import { AsterikLabel } from "../../Components/AsterikLabel";
import "./index.scss";
import { TextInput } from "../../FormElements/TextInput/";
import CheckboxElement from "../../FormElements/CheckboxElement";
import { FEEDBACK_360_SURVEY } from "../../../config/questionTypeConstants";
import { SelectElement } from "../../FormElements/SelectElement";
import { selectHrisType } from "./constants";
// eslint-disable-next-line max-lines-per-function
export function ModalWindow(props) {
  const [name, setName] = useState("");

  let defaultValue = "Normal Rater File";

  if (props.projectType === "360FeedbackDynamic" || props.projectType === "180FeedbackDynamic" || props.projectType === "360FeedbackDynamicForAppliedMaterials" ) {
    defaultValue = "360 Feedback Rater File";
} else if (props.projectType === "cohortSurvey") {
    defaultValue = "Cohort Rater File";
}
else {
  defaultValue = "Normal Rater File"
}
  return (
    <>
      <CustomModal
        title="Upload new HRIS data"
        visible={props.isBulkAddModalVisible}
        okButtonProps={{
          loading: props.isBulkLoading,
          disabled: props.fileToUpload && name ? false : true,
        }}
        style={{ top: 0 }}
        onOk={(e) => props.submitToUpload(setName, name)}
        onCancel={(e) => props.handleModalState(setName)}
        okText="Upload"
      >
        <FileUpload
          fileToUpload={props.fileToUpload}
          {...props}
          contentLiOne="Mandatory Headers in csv: First_Name , Email , Manager_ID"
          contentLiTwo="The root manager's Manager_ID should be ROOT"
        />
        <div className="feedbackcheckbox-wrapper">
          {/* {props.showCheckBox && !props.isFromPeopleTab && (
            <CheckboxElement
            label="360 Feedback Rater File"
          handleChange={props.handlechange}
          selected={props.checkBoxIsChecked}
          disabled={props.isFromSetUpTab}
         />
          )} */}
          {
            (
              <SelectElement
              optionList={selectHrisType.selectOptionList}
              onChange = {props.handleOptionChange}
              defaultValue={defaultValue}
              disabled={props.isFromSetUpTab}
              />
            )
          }
        </div>
        <div className="input-name-wrapper">
          <AsterikLabel className="required-icon" />
          <TextInput
            className="change-name"
            value={name}
            placeholder="Enter HRIS file version name"
            name=""
            htmlType=""
            handleChange={(e) => setName(e.target.value)}
          />
        </div>

      </CustomModal>
    </>
  );
}

export default ModalWindow;
