export const surveyDistributionDataStart = (state) => {
  return {
    ...state,
    surveyDistributionDataApi: {
      data: null,
      isProcessing: true,
      error: null,
      message: null,
    },
  };
};

export const surveyDistributionDataSuccess = (state, payload) => {
 
  return {
    ...state,
    surveyDistributionDataApi: {
      isProcessing: false,
      data: payload.payload.data.data,
      error: !payload.status,
      message: payload.message,
    },
    filters: payload.filters,
  };
};

export const surveyDistributionDataFailed = (state, payload) => {
  return {
    ...state,
    surveyDistributionDataApi: {
      isProcessing: false,
      data: null,
      error: true,
    },
  };
};

