export const revokeAccesStart = (state) => {
  return {
    ...state,
    revokeUserApi: {
      isProcessing: true,
      error: false,
      message: ''
    }
  }

}

export const revokeAccesSuccess = (state, payload) => {
  return {
    ...state,
    revokeUserApi: {
      isProcessing: false,
      error: false,
      message: payload.message
    }
  }
}


export const revokeAccesFailed = (state, payload) => {
  return {
    ...state,
    revokeUserApi: {
      isProcessing: false,
      error: true,
      message: payload.message
    }
  }

}