import {
    ON_CHANGE_TREND_TOGGLE
} from "../../../constants";


export const handleChangeTrendToggle = (event) => {
    return {
        type: ON_CHANGE_TREND_TOGGLE,
        payload: event,
    };
};