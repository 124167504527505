export function Default() {
    return {
        exploreQuestionsApi: {
            isProcessing: false,
            error: false,
            reason: '',
        },
        getTrendProjectsApi: {
            isProcessing: false,
            data: null,
            error: false,
            message: '',
        },
        isTrendInsightsAlreadyFetched:false,
        isDataFetched: false,
        filters : {},
        byScore:[],
        multipleSelect: [],
        selectedUser : "",
        directReporteesOnly: false
    };

}