export const surveywizardParticipationDataStart = (state) => {
  return {
    ...state,
    surveywizardParticipationDataApi: {
      data: null,
      isProcessing: true,
      error: null,
      message: null
    },
  };
};

export const surveywizardParticipationDataSuccess = (state, payload) => {
  return {
    ...state,
    surveywizardParticipationDataApi: {
      isProcessing: false,
      data: payload.data.data,
      error: false,
      message: payload.message,
    },
    exploreParticipationSelectedColumns: Object.keys(
      payload.data.data.aggregatedDemographicsResponse.result || {}
    )
  };
};

export const surveywizardParticipationDataFailed = (state, payload) => {
  return {
    ...state,
    exploreParticipationDataApi: {
      isProcessing: false,
      data: null,
      error: payload.error,
    },
  };
};

