import {
    makeKey,
    update,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
export function ForgotPasswordFailed(state, payload) {
    let response = makeKey(
        'forgotPasswordApi',
        update(
            state.forgotPasswordApi,
            payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload)
        )
    );
    return response;
}
