export const inclusionTemplate = [
    {
        questionDimension: 'Inclusion',
        type: 'Matrix',
        questionLabel: 'Inclusion',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [
            {
                text: 'I consider some co-workers as true friends.', value: '', isSelected: true

            },
            {
                text: 'People care about me at work.', value: '', isSelected: true

            },
            {
                text: 'I feel like I belong at my organization.', value: '', isSelected: true

            },
            {
                text: 'People at my organization are like one, big family.', value: '', isSelected: false
            }]
    }
]



