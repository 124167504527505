import {
    makeKey,
    update,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
export function fetchOrganizationFailed(state, payload) {
    return makeKey(
        'fetchOrganizationApi',
        update(
            state.fetchOrganizationApi,
            payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload)
        )
    );
}
