import { QUESTION_TYPE } from "../../../../../../../config/questionTypeConstants"
import { deepCopy } from "../../../../../../utils/deepCopy"
import { generateStaticQuestionId } from "../../../../../../utils/goalsUtils"
import { goals } from "../../../InitialState/defaultGoals"
import { getHTMLRemovedQuestion } from "../helpers"

export const matrixLabelChange = (state, payload) => {
    let updatedGoals = matrixLabelChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const matrixLabelChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.optionSection) {
                element.questions[0].options[payload.optionIndex].label = payload.value
            } else {
                element.questions[0].matrixQuestions[payload.questionIndex].text = getHTMLRemovedQuestion(payload.value)
                element.questions[0].matrixQuestions[payload.questionIndex].HTMLText = payload.value
                element.questions[0].matrixQuestions[payload.questionIndex].staticQuestionId = generateStaticQuestionId(getHTMLRemovedQuestion(payload.value))
            }
        }
    });
    return goalsCopy
}

export const matrixAddColumOrRow = (state, payload) => {
    let updatedGoals = matrixColOrRowAddedChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const matrixColOrRowAddedChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.optionSection) {
                element.questions[0].options.splice(element.questions[0].options.length, 0, payload.data)
                let findedNaIndex = element.questions[0].options.findIndex((item => item.value === "0"))
                if (findedNaIndex !== -1) {
                    let naOption = element.questions[0].options.splice(findedNaIndex, 1)
                    element.questions[0].options.splice(element.questions[0].options.length - 1, 0, naOption[0])

                }

            } else {
                element.questions[0].matrixQuestions.splice(element.questions[0].matrixQuestions.length, 0, payload.data)
            }
        }
    });
    return goalsCopy
}


export const matrixRemoveColumOrRow = (state, payload) => {
    let updatedGoals = matrixColOrRowRemovedChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const matrixColOrRowRemovedChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.optionSection && element.questions[0].options.length > 1) {
                element.questions[0].options.splice(payload.index, 1)
            } else if (!payload.optionSection && element.questions[0].matrixQuestions.length > 1) {
                element.questions[0].matrixQuestions.splice(payload.index, 1)
            }
        }
    });
    return goalsCopy
}

export const generateSubCategoryForMatrix = (state, payload) => {
    let options = []
    let goalsCopy = deepCopy(state.goals)
    let findedBlock = goalsCopy.find((currentBlock) => currentBlock.blockId === payload.blockId)
    if (findedBlock && findedBlock.type && findedBlock.type === QUESTION_TYPE.MATRIX) {
        findedBlock.questions[0].matrixQuestions.forEach((item) => {
            let find = options.find((optionItem) => item.subCategoryId === optionItem.subCategoryId)
            if (!find) {
                options.push({ subCategoryId: item.subCategoryId, subCategory: item.subCategory })
            }
        })
    }
    let updatedSubcategoryDetails = deepCopy(state.subcategoryDetails)
    updatedSubcategoryDetails[payload.blockId] = { options: options }
    return {
        ...state,
        subcategoryDetails: {
            ...updatedSubcategoryDetails
        }
    }
}

export const handleAddNewSubcategory = (state, payload) => {
    let updatedSubcategoryDetails = deepCopy(state.subcategoryDetails)
    updatedSubcategoryDetails[payload.blockId] = { options: [...updatedSubcategoryDetails[payload.blockId].options, { subCategoryId: generateStaticQuestionId(payload.name), subCategory: payload.name }] }
    return {
        ...state,
        subcategoryDetails: {
            ...updatedSubcategoryDetails
        }
    }
}

export const handleChangeSubcategoryToQuestions = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    let findedIndexOfBlockId = goalsCopy.findIndex((item) => item.blockId === payload.blockId)
    if (findedIndexOfBlockId != -1) {
        goalsCopy[findedIndexOfBlockId].questions[0].matrixQuestions[payload.questionIndex].subCategory = payload.option.subCategory
        goalsCopy[findedIndexOfBlockId].questions[0].matrixQuestions[payload.questionIndex].subCategoryId = payload.option.subCategoryId
    }
    return {
        ...state,
        goals: goalsCopy
    }
}

export const deleteSubcategory = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    let updatedSubcategoryDetails = deepCopy(state.subcategoryDetails)
    let updatedOptions = updatedSubcategoryDetails[payload.blockId].options
    updatedOptions.splice(payload.index, 1) // delete from options array
    updatedSubcategoryDetails[payload.blockId] = { options: updatedOptions }
    // deleting from questionset
    let findedIndexOfBlockId = goalsCopy.findIndex((item) => item.blockId === payload.blockId)
    if (findedIndexOfBlockId != -1) {
        goalsCopy[findedIndexOfBlockId].questions[0].matrixQuestions.forEach((question) => {
            if (question.subCategoryId === payload.option.subCategoryId) {
                question.subCategoryId = ""
                question.subCategory = ""
            }
        })

    }

    return {
        ...state,
        goals: goalsCopy,
        subcategoryDetails: {
            ...updatedSubcategoryDetails
        }
    }
}

export const handleChangeSubcategory = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    let updatedSubcategoryDetails = deepCopy(state.subcategoryDetails)
    let updatedOptions = updatedSubcategoryDetails[payload.blockId].options
    updatedOptions[payload.index] = { subCategoryId: generateStaticQuestionId(payload.value), subCategory: payload.value }
    updatedSubcategoryDetails[payload.blockId] = { options: updatedOptions }
    let findedIndexOfBlockId = goalsCopy.findIndex((item) => item.blockId === payload.blockId)
    if (findedIndexOfBlockId != -1) {
        goalsCopy[findedIndexOfBlockId].questions[0].matrixQuestions.forEach((question) => {
            if (question.subCategoryId === payload.option.subCategoryId) {
                question.subCategoryId = generateStaticQuestionId(payload.value)
                question.subCategory = payload.value
            }
        })

    }

    return {
        ...state,
        goals: goalsCopy,
        subcategoryDetails: {
            ...updatedSubcategoryDetails
        }
    }
}