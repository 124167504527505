import {
  update,
  makeKey,
  handleApiResponseError,
  handleNetworkError,
} from "../../../../utils/reducerOperations";
import { toast } from "../../../../utils/messageUtils";
export function inviteUserFailure(state, payload) {
  let response = makeKey(
    "inviteUserApi",
    update(state.inviteUserApi, {
      ...(payload.response && payload.response.data
        ? handleApiResponseError(payload.response.data)
        : handleNetworkError(payload)),
      userId: null,
    })
  );
  toast.error("Failed to invite users");
  return response;
}
