import React from 'react';
import { TopicAdminBarChartLine } from '../../../../../../../../Components/TopicAdminBarChartLine';
import { Tooltip } from "antd";
import { getBenchmarkTooltipTitle } from '../../../../../../../../utils/benchmarkUtils';
import { compareByAlpha } from "../../../../../../../../utils/compareByAlpha";
import SVGIcons from '../../../../../../../../Components/SVGIcons';

const getScores = (data) => {
  return {
    favScore: Math.round(data.favScore),
    neutralScore: Math.round(data.neutralScore),
    unFavScore: Math.round(data.unFavScore),
  };
};

export default {
  tableColumns: (props) => [
    {
      title: "Topics",
      dataIndex: 'item',
      width: 150,
      key: 'topic',
      // colSpan: 2,
    },
    {
      title: "Score",
      dataIndex: 'chart',
      width: 400,
      key: 'chart',
      sorter: (b, a) => compareByAlpha(a.favScore, b.favScore),
      // colSpan: 0,
      render: (item, record) => (
        <TopicAdminBarChartLine data={getScores(record)} />
      ),
    },
    {
      title: <Tooltip title={getBenchmarkTooltipTitle(props.benchmarkTooltip)}><span>Benchmark</span></Tooltip>,
      dataIndex: 'benchMark',
      width: 80,
      align: "center",
      key: 'benchmark',
      render: (item, record) => {
        return <div className="table-arrows table-count row-content center-content">
          {record.benchmarkScore ?
            getSignedValue(record)
            : "-"}
        </div>
      }
    },
    // {
    //   title: '2-Year Trend',
    //   dataIndex: 'unFavScore',
    //   width: '10%',
    //   key: 'secondTrend',
    // },
  ],
  data: [
    {
      topic: 'qwerty',
      masco: 'qwerty',
      trend: 'qwerty',
      secondTrend: 'qwerty',
      chart: 'abcd',
    },
  ],
  ERROR_MESSAGE: 'Failed to fetch data.',
};

const compareTrendScore = ({ previous, next, projectId }) => {
  let prev = previous[projectId] && previous[projectId].score ? previous[projectId].score : -10000
  let nextData = next[projectId] && next[projectId].score ? next[projectId].score : -10000
  let sorter = compareByAlpha(prev, nextData)
  return sorter
}

export const addTrendColomn = (trendData, props) => {
  let trendColomn = []
  trendData.forEach(matchedSurvey => {
    trendColomn.push({
      title: matchedSurvey.projectName,
      dataIndex: matchedSurvey.projectId,
      align: "center",
      key: matchedSurvey.projectId,
      width: 100,
      sorter: (b, a) => compareTrendScore({ previous: a, next: b, projectId: matchedSurvey.projectId }),
      render: (item, record) => {
        return (
          <>
            {record.validCount >= props.threshold ?
              <div className="table-arrows row-content center-content">
                <span>
                  {item && !isNaN(item.score) && item.score > 0 && !props.showActualScore ? (
                    <span className="up-arrow">
                      <SVGIcons type={"SVG-thick-uparrow"} />
                    </span>
                  ) : (
                    ""
                  )}
                  {item && !isNaN(item.score) && item.score < 0 && !props.showActualScore ? (
                    <span className="down-arrow">
                      <SVGIcons type={"SVG-thick-downarrow"} />
                    </span>
                  ) : (
                    ""
                  )}{" "}
                </span>{" "}
                {!item || isNaN(item.score) ? "-" : <span className={getClassName(item, props)}>{Math.abs(item.score)}</span>}
              </div> : <div className="table-arrows row-content">-</div>
            }
          </>
        )
      }

    })
  });
  return trendColomn
}

const getSignedValue = (record) => {
  let value = record.favScore - record.benchmarkScore
  if (value < 0) {
    return <span className="down-arrow">
      <SVGIcons type={"SVG-thick-downarrow"} />
      {Math.abs(value)}
    </span>
  } else if (value > 0) {
    return <span className="up-arrow">
      <SVGIcons type={"SVG-thick-uparrow"} />
      {value}
    </span>
  } else {
    return <span>
      0
    </span>
  }
}

const getClassName = (item, props) => {
  if (props.showActualScore) {
    if (Math.abs(item.score) === 0) {
      return "table-count row-content"
    } else if (item.negative) {
      return "trend-actual-red table-count row-content"
    } else {
      return "trend-actual-green table-count row-content"
    }
  } else {
    return "table-count row-content"
  }
}

export const ADDED_COLOUMN_DOWNLOAD = [{
  title: "Favourable Score",
  dataIndex: "favScore",
  key: "favScore",
}, {
  title: "Neutral Score",
  dataIndex: "neutralScore",
  key: "neutralScore",
},
{
  title: "Unfavourable Score",
  dataIndex: "unFavScore",
  key: "unFavScore",
},
]