import React from 'react'
import { Tooltip } from "antd";
import SVGIcons from '../../SVGIcons';
import { getDefaultSortOrder } from '../../../utils/listSurveyUtils';
import { OutlierBarchart } from '../OutlierBarChart';

export default {
  tableColumnPositiveOutLiers: (props) => [
    {
      title: "Type",
      key: "type",
      width: 200,
      dataIndex: "type",
      render: (str, record) => (
        <>
          {str ? (
            str.length > 18 ? (
              <Tooltip placement="top" title={str}>
                {str.slice(0, 18) + "..."}
              </Tooltip>
            ) : (
              str.charAt(0).toUpperCase() + str.slice(1)
            )
          ) : (
            ""
          )}
        </>
      ),

      sorter: (a, b) => { },
      defaultSortOrder: getDefaultSortOrder("type", props),
    },
    {
      title: "Group",
      key: "segment",
      width: 200,
      dataIndex: "segment",
      render: (str, record) => (
        <>
          {str ? (
            str.length > 18 ? (
              <Tooltip placement="top" title={str}>
                {str.slice(0, 18) + "..."}
              </Tooltip>
            ) : (
              str.charAt(0).toUpperCase() + str.slice(1)
            )
          ) : (
            ""
          )}
        </>
      ),
      sorter: (a, b) => { },
      defaultSortOrder: getDefaultSortOrder("segment", props),
    },
    {
      title: "Size",
      dataIndex: "size",
      width: 80,
      key: "size",
      render: (item, record) => <span className="table-count">{item}</span>,
      sorter: (a, b) => { },
      defaultSortOrder: getDefaultSortOrder("size", props),
    },
    {
      title: "Topic",
      width: 150,
      dataIndex: "dimension",
      key: "dimension",
      sorter: (a, b) => { },
      defaultSortOrder: getDefaultSortOrder("dimension", props),
    },
    {
      title: "Results",
      key: "favScore",
      dataIndex: "favScore",
      width: 153,
      sorter: (a, b) => { },
      defaultSortOrder: getDefaultSortOrder("favScore", props),
      render: (item, record) =>
        record.size >= props.threshold ? (
          <OutlierBarchart data={item} /> // <NumberFormat value={item * 100} decimalScale={0} displayType="text" />
        ) : (
          "Insufficient data!"
        ),
    },
    {
      title: "vs. Whole Org",
      dataIndex: "vsWholeOrg",
      key: "vsWholeOrg",
      width: 100,
      align: "center",
      render: (item, record) =>
        <span className="table-count row-content">
          {
            record.size >= props.threshold ? Math.sign(item) === 1 ? "+" + Math.round(item) : Math.round(item) : "-"
          }
        </span>,
      sorter: (a, b) => { },
      defaultSortOrder: getDefaultSortOrder("vsWholeOrg", props)
    },
    {
      title: "Trend",
      dataIndex: "trend",
      key: "trend",
      render: (item, record) => (
        <div className="table-arrows">
          <span>
            {!isNaN(item) && item > 0 ? (
              <SVGIcons type={"SVG-thick-uparrow"} />
            ) : (
              ""
            )}
            {!isNaN(item) && item < 0 ? (
              <SVGIcons type={"SVG-thick-downarrow"} />
            ) : (
              ""
            )}{" "}
          </span>{" "}
          {isNaN(item) ? item : Math.abs(item)}
        </div>
      ),
    },
  ],
}