import {update,fetchPayload} from '../../../utils/reducerOperations';
import * as helper from './helper';
import {
    FETCH_OUTLIER_FAIL,
    FETCH_OUTLIER_STARTED,
    UPDATE_FILTER_VISIBILITY,
    FETCH_OUTLIER_SUCCESS,
    CHANGE_OUTCOME_VARIABLE
} from '../../constants';

import { Default } from './InitialState';


const calloutReducer = (state=Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
    case FETCH_OUTLIER_STARTED:
        return update(state,
            helper.fetchOutlierStarted(state, payload));
    case FETCH_OUTLIER_SUCCESS:
        return update(state,
            helper.fetchOutlierSuccess(state, payload));
    case FETCH_OUTLIER_FAIL:
        return update(state,
            helper.fetchOutlierFailed(state, payload));
    case UPDATE_FILTER_VISIBILITY:
        return update(state,
            helper.updateFiltersVisibility(state, action));
    case CHANGE_OUTCOME_VARIABLE:
        return {...state,outcomeVariable:action.value};
    default: return {...state};
    }
};

export default calloutReducer;