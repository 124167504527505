import { fetchPayload, update } from "../../../utils/reducerOperations";
import { Default } from "./InitialState";
import {
  FETCH_USERS_LIST_STARTED,
  FETCH_USERS_LIST_SUCCESS,
  FETCH_USERS_LIST_FAILED,
  CHANGE_ACTIVE_USER_ROLE_STARTED,
  CHANGE_ACTIVE_USER_ROLE_SUCCESS,
  CHANGE_ACTIVE_USER_ROLE_FAILED,
  DELETE_ACTIVE_USER_STARTED,
  DELETE_ACTIVE_USER_SUCCESS,
  DELETE_ACTIVE_USER_FAILED,
  INPUT_ADD_USER,
  INVITE_ADMIN_USER_STARTED,
  INVITE_ADMIN_USER_SUCCESS,
  INVITE_ADMIN_USER_FAILED,
  TOGGLE_BULK_UPLOAD_MODAL,
  FETCH_USERS_NEXT_LIST_SUCCESS,
  FETCH_USERS_PREV_LIST_SUCCESS,
  FETCH_USERS_NEXT_LIST_FAILED,
  FETCH_USERS_PREV_LIST_FAILED,
  FETCH_HRIS_LIST_FAILED,
  FETCH_HRIS_LIST_STARTED,
  FETCH_HRIS_LIST_SUCCESS,
  FETCH_HRIS_HIERARCHY_STARTED,
  FETCH_HRIS_HIERARCHY_SUCCESS,
  FETCH_HRIS_HIERARCHY_FAILED,
  HRIS_HRIS_FILE_CHANGE,
  USERS_HRIS_PAGINATION_CHANGE,
} from "../../constants";
import {
  fetchUsersStarted,
  fetchUsersSuccess,
  fetchUsersFailure,
  activeUserRoleChangeStarted,
  activeUserRoleChangeSuccess,
  activeUserRoleChangeFailure,
  deleteActiveUserStarted,
  deleteActiveUserSuccess,
  deleteActiveUserFailure,
  inputAddUser,
  inviteUserStarted,
  inviteUserSuccess,
  inviteUserFailure,
  fetchUsersNextSuccess,
  fetchUsersPrevSuccess,
  fetchHRISStarted,
  fetchHRISSuccess,
  fetchHRISFailed,
  fetchHrisHierarchyStarted,
  fetchHrisHierarchySuccess,
  fetchHrisHierarchyFailed,
  hrisFileChange,
  onHrisUsersPaginationChange,
} from "./Helpers";
const usersPageReducer = (state = Default(), action) => {
  let userPagePayload = fetchPayload(action);
  switch (action.type) {
    case HRIS_HRIS_FILE_CHANGE:
      return update(state, hrisFileChange(state, userPagePayload));
    case FETCH_USERS_LIST_STARTED:
      return update(state, fetchUsersStarted(state, userPagePayload));
    case FETCH_USERS_LIST_SUCCESS:
      return update(state, fetchUsersSuccess(state, userPagePayload));
    case FETCH_USERS_NEXT_LIST_SUCCESS:
      return update(state, fetchUsersNextSuccess(state, userPagePayload));
    case FETCH_USERS_PREV_LIST_SUCCESS:
      return update(state, fetchUsersPrevSuccess(state, userPagePayload));
    case TOGGLE_BULK_UPLOAD_MODAL:
      return update(state, {});
    default:
      return fetchUserFail(state, action);
  }
};
const fetchUserFail = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_USERS_LIST_FAILED:
      return update(state, fetchUsersFailure(state, payload));
    case FETCH_USERS_NEXT_LIST_FAILED:
      return update(state, fetchUsersFailure(state, payload));
    case FETCH_USERS_PREV_LIST_FAILED:
      return update(state, fetchUsersFailure(state, payload));
    case CHANGE_ACTIVE_USER_ROLE_STARTED:
      return update(state, activeUserRoleChangeStarted(state, payload));
    default:
      return alterRoleSuccessFail(state, action);
  }
};
const alterRoleSuccessFail = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case CHANGE_ACTIVE_USER_ROLE_SUCCESS:
      return update(state, activeUserRoleChangeSuccess(state, payload));
    case CHANGE_ACTIVE_USER_ROLE_FAILED:
      return update(state, activeUserRoleChangeFailure(state, payload));
    case USERS_HRIS_PAGINATION_CHANGE:
      return update(state, onHrisUsersPaginationChange(state, payload));
    default:
      return deleteUserSuccessFail(state, action);
  }
};
const deleteUserSuccessFail = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case DELETE_ACTIVE_USER_STARTED:
      return update(state, deleteActiveUserStarted(state, payload));
    case DELETE_ACTIVE_USER_SUCCESS:
      return update(state, deleteActiveUserSuccess(state, payload));
    default:
      return deleteUserFail(state, action);
  }
};
const deleteUserFail = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case DELETE_ACTIVE_USER_FAILED:
      return update(state, deleteActiveUserFailure(state, payload));
    case INPUT_ADD_USER:
      return update(state, inputAddUser(state, payload));
    default:
      return inviteUserStartSuccess(state, action);
  }
};
const inviteUserStartSuccess = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case INVITE_ADMIN_USER_STARTED:
      return update(state, inviteUserStarted(state, payload));
    case INVITE_ADMIN_USER_SUCCESS:
      return update(state, inviteUserSuccess(state, payload));
    default:
      return inviteUserFail(state, action);
  }
};
const inviteUserFail = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case INVITE_ADMIN_USER_FAILED:
      return update(state, inviteUserFailure(state, payload));
    default:
      return fetchHRIS(state, action);
  }
};
const fetchHRIS = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_HRIS_LIST_STARTED:
      return update(state, fetchHRISStarted(state, payload));
    case FETCH_HRIS_LIST_SUCCESS:
      return update(state, fetchHRISSuccess(state, payload));
    case FETCH_HRIS_LIST_FAILED:
      return update(state, fetchHRISFailed(state, payload));
    default:
      return fetchHRISHierarchy(state, action);
  }
};
const fetchHRISHierarchy = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_HRIS_HIERARCHY_STARTED:
      return update(state, fetchHrisHierarchyStarted(state, payload));
    case FETCH_HRIS_HIERARCHY_SUCCESS:
      return update(state, fetchHrisHierarchySuccess(state, payload));
    case FETCH_HRIS_HIERARCHY_FAILED:
      return update(state, fetchHrisHierarchyFailed(state, payload));
    default:
      return { ...state };
  }
};
export default usersPageReducer;
