import { getNewSurveyPagePath } from '../../utils/getHomePath';
import { encryptJson } from '../../utils/urlDataStore';
import { nlpSetURLParams } from '../../utils/nlpURLUtils';

export const switchOrganization = (item, props) => {
  const orgId = item.organizationId;
  const orgDetails = {
    organizationId: item.organizationId,
    name: item.name,
    projectsCount: item.projectCount,
    projectCount:item.projectCount
  };
  const encryptedOrgDetails = encryptJson({orgDetails});
  localStorage.setItem('organizationId', orgId);
  localStorage.setItem('organizationName', item.name);
  localStorage.setItem('organizationProjectCount', item.projectCount);
  props.history.push({
    pathname: getNewSurveyPagePath(orgId),
    search: nlpSetURLParams({params: encryptedOrgDetails}),
  });
};
