import {Default} from './InitialState';
import { 
    FETCH_ROLES_LIST_STARTED, 
    FETCH_ROLES_LIST_SUCCESS, 
    FETCH_ROLES_LIST_FAILED,
    FETCH_TAGS_LIST_STARTED, 
    FETCH_TAGS_LIST_SUCCESS, 
    FETCH_TAGS_LIST_FAILED, 
    CREATE_ROLE_STARTED,
    CREATE_ROLE_SUCCESS,
    CREATE_ROLE_FAILED,
    DELETE_ROLE_STARTED,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAILED
} from '../../constants';
import {
    fetchRolesFail,
    fetchRolesStarted,
    fetchRolesSuccess,
    fetchTagsFail,
    fetchTagsStarted,
    fetchTagsSuccess,
    createRoleStarted,
    createRoleSuccess,
    createRoleFail,
    deleteRoleStarted, 
    deleteRoleSuccess, 
    deleteRoleFail
} from './Helpers';
const rolesPageReducer = (state = Default(), action) => {
    switch (action.type) {
    case FETCH_ROLES_LIST_STARTED: return fetchRolesStarted(state,action); 
    case FETCH_ROLES_LIST_SUCCESS: return fetchRolesSuccess(state,action); 
    case FETCH_ROLES_LIST_FAILED: return fetchRolesFail(state,action); 
    case FETCH_TAGS_LIST_STARTED: return fetchTagsStarted(state,action); 
    case FETCH_TAGS_LIST_SUCCESS: return fetchTagsSuccess(state,action); 
    case FETCH_TAGS_LIST_FAILED: return fetchTagsFail(state,action); 
    case CREATE_ROLE_STARTED: return createRoleStarted(state,action); 
    case CREATE_ROLE_SUCCESS: return createRoleSuccess(state,action); 
    case CREATE_ROLE_FAILED: return createRoleFail(state,action); 
    case DELETE_ROLE_STARTED: return deleteRoleStarted(state,action); 
    case DELETE_ROLE_SUCCESS: return deleteRoleSuccess(state,action); 
    case DELETE_ROLE_FAILED: return deleteRoleFail(state,action); 
    default:
        return {...state};
    }
};

export default rolesPageReducer;
