import { customFormChangeSuccess,filterSurveys } from "../../../../utils/reducerOperations";
import { apiInitialState } from "../../../../utils/apiStateUtils";
import { fetchFormConfigurations } from "../../../inputFormConfigurations";
export function onSurveySearchChange(state, payload) {
  return customFormChangeSuccess(
    { state: state, key: "suveyTableSearch" },
    payload
  );
}

export function clearSurveySearch(state, payload) {
  return {
    ...state,
    surveyListApi: apiInitialState(),
    isFormValid: false,
    suveyTableSearch: fetchFormConfigurations(["tableSearch"]),
  };
}

export function onSearch(state, action) {
  return {
    ...state,
    surveyList: filterSurveys(action.payload)
  };
}
