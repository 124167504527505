export function IniialState() {
    return {
        BenchMarkDataApi:{
            isProcessing:false,
            data:null,
            error:false,
            message:''
        },
        UpdateBenchMarkDataApi:{
            isProcessing:false,
            data:null,
            error:false,
            message:''
        },
        isBenchMarkDataAlreadyFetched:false
    };
}