export const genderDemographicsTemplate = [
    {
        type: 'Multiple Choice',
        questionLabel: 'Gender',
        questionDimension: 'Demographics',
        isSelected: true,
        options: [
            {
                label: "Male",
                value: "1"
            },
            {
                label: "Female",
                value: "2"
            },
            {
                label: "Other",
                value: "3"
            }
        ],
        question: 'Gender.',
        isDemographic: true
    },
]