import React, { Component } from 'react';
import FormField from "../../../Components/FormField";

class FeedBackForm extends Component {
    render() {
        return (
          <div>
            {this.props.inputBoxes.map((field, index) => (
            <FormField
              index={index}
              labelRequired={true}
              required={true}
              field={field}
              onInputChange={(value) =>
                this.props.sendFeedbackFormChange({
                  value: value.event.target.value,
                  index: value.index,
                  name: value.name,
                })}
            />
            ))}
          </div>
        );
      }
}

export default FeedBackForm;