// /*eslint-disable*/
import React from 'react';
import { Card } from 'react-bootstrap';
import { ButtonElement } from "../../FormElements/ButtonElement"
export function MenuCard({ path, btnText, title, onClick }) {
    return (
        <Card>
            <Card.Header>
                <h3 children={title} />
            </Card.Header>
            <Card.Body>
                <ButtonElement
                    block
                    onClick={onClick.bind(null, path)}
                    children={btnText}
                    variant='outline-primary' />
            </Card.Body>
        </Card>
    );
}