import React from 'react';
import { Dropdown } from 'react-bootstrap';
import constants from './constants';
import SVGIcons from '../../Components/SVGIcons';
export function UserDetails({ dropDownItems, userName, email }) {
    return (
        <Dropdown>
            <Dropdown.Toggle
                as='a'
                variant={constants.dropDown.variant}
                id={constants.dropDown.id}
                children={<SVGIcons type={'SVG-profile'} />}>
                <UserName userName={userName} email={email} />
            </Dropdown.Toggle>
            <Dropdown.Menu as='ul'>
                {dropDownItems.map(item => item)}
            </Dropdown.Menu>
        </Dropdown>
    );
}

function UserName({ userName, email }) {
    return (
        <>
            <div className="profile-img">
                {<SVGIcons type={'SVG-profile'} />}
            </div>
            <div className='profile-info' >
                <h3>{userName}</h3>
                <span>{email}</span>
            </div>
        </>
    );
}