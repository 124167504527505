import React from "react";
import { TimePicker } from "antd";
import moment from "moment";
export default function Timepicker(props) {
  return (
    <TimePicker
      defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
      placeholder={props.placeholder}
      onChange={props.onChange}
      className={`${props.className || ""}`}
      defaultValue={props.defaultValue}
      disabledHours={(h) => console.log('Disabled hours', h)}
      minuteStep={props.minuteStep}
      format={props.format}
      value={props.value || undefined}
      disabled={props.disabled}
      inputReadOnly={true}
      allowClear={!props.disableClear}
      onSelect={props.onSelect && props.onSelect}
      onOpenChange={props.onOpenChange && props.onOpenChange}
      showNow={!props.hideShowNow}
    />
  );
}
