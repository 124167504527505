import * as actionTypes from '../../constants';
import { BATCH_IDS_URL } from '../../../../config/constants';
import { NLPCommonReqBodies } from '../../../utils/actionOperations';
import { ApiBase } from '../../../utils/apiBase';

export const fetchBatchIds = () => {
    const axios = new ApiBase().instance
    return dispatch => {
        dispatch({ type: actionTypes.FETCH_BATCH_IDS_STARTED });
        axios.post(BATCH_IDS_URL, NLPCommonReqBodies())
            .then(res => fetchBatchIdsSuccess(dispatch, res))
            .catch(err => fetchBatchIdsFailed(dispatch, err));
    };
};

function fetchBatchIdsSuccess(dispatch, res) {
    return dispatch({
        type: actionTypes.FETCH_BATCH_IDS_SUCCESS,
        data: res.data.data.map(item => item.batch_id)
    });
}

function fetchBatchIdsFailed(dispatch, err) {
    return dispatch({
        type: actionTypes.FETCH_BATCH_IDS_FAIL,
        error: err.message
    });
}

export function updateActiveFacet(facet) {
    return {
        type: actionTypes.UPDATE_ACTIVE_FACET,
        facet
    };
}

export function updateFacetList({ main, value }) {
    return {
        type: actionTypes.UPDATE_FACET_LIST,
        main,
        value
    };
}

export function resetFacetList() {
    return {
        type: actionTypes.RESET_FACET_LIST
    };
}

export {
    fetchSentiment
} from './fetchSentiments';

export {
    fetchSentimentTopics
} from './fetchSentimentTopics';

export {
    loadInitialData
} from './loadInitialData';