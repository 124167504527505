import {
    FETCH_COMPARISON_SURVEY_DATA_START,
    FETCH_COMPARISON_SURVEY_DATA_SUCCESS,
    FETCH_COMPARISON_SURVEY_DATA_FAIL
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { GET_COMPARISON_SUREVEY_DETAILS } from "../../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const NewDashboardFetchComparisonSurveyDetails = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
        dispatch(withType(FETCH_COMPARISON_SURVEY_DATA_START, {}));
        apiBaseInstance.instance
            .post(GET_COMPARISON_SUREVEY_DETAILS, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then((response) => {
                dispatch(
                    withType(FETCH_COMPARISON_SURVEY_DATA_SUCCESS, {
                        data: response.data,
                        projectDetails: { questionSet: payload.questionSet, projectType: payload.projectType },
                        message: response.message,
                    })
                );
                cb && cb(true, response.data && response.data.data && response.data.data.response && response.data.data.response.surveys);
            })
            .catch((err) => {
                dispatch(
                    withType(FETCH_COMPARISON_SURVEY_DATA_FAIL, {
                        error: err,
                    })
                );
                cb && cb(false);
            });
    };
};
