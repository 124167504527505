import React from "react";
import { Card } from 'react-bootstrap';
import FormField from "../../Components/FormField";
import { LoaderButton } from "../../FormElements/LoaderButton";
import { ButtonElement } from "../../FormElements/ButtonElement";
import { GetLocalStorage } from "../../utils/localStorgeOperations";
import constants from "./constants";
import { RouteHeader } from "../../Components/RouteHeader";
import "./index.scss";
import Container from "../../Components/Container";
import { ORGANIZATION_PAGE_SECTION } from '../../../config/tabConstants';

class AddOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.addOrganization = this.addOrganization.bind(this);
  }

  componentDidMount = () => {
    this.props.clearOrganizationForm();
  };

  addOrganization() {
    const { local } = constants;
    let params = {};
    params.currentTab = ORGANIZATION_PAGE_SECTION.ORGANIZATION
    this.props.inputBoxConfiguration.map((item) => {
      params[item.name] = item.value;
      return 0;
    });
    params.owner = [
      {
        firstName: GetLocalStorage(local.firstName),
        id: GetLocalStorage(local.userId),
        lastName: GetLocalStorage(local.lastName),
      },
    ];
    if (this.props.isFormValid) {
      this.props.addOrganization(params, this.returnToOrganizationListing);
    }
  }
  returnToOrganizationListing = () => {
    this.props.history.push(constants.ORGANIZATION_ROUTE);
  };

  listAddOrganizationFields() {
   
    return this.props.inputBoxConfiguration.map((field, index) => (
      <FormField
        test-name="input-field"
        key={index}
        labelRequired={true}
        required={true}
        index={index}
        field={field}
        onEnter={this.addOrganization}
        loginFormChange={this.props.formInputChange}
        value="addORganization"
      />
    ));
  }

  addOrganizationError() {
    if (
      this.props.addOrganizationApi.isProcessing === false &&
      this.props.addOrganizationApi.error === true
    ) {
      return (
        <li>
          <p className="error-message">
            {this.props.addOrganizationApi.message}
          </p>
        </li>
      );
    } else {
      return null;
    }
  }
  onCancelAddOrganization = () => {
    this.props.clearOrganizationForm();
    this.returnToOrganizationListing();
  };

  addOrganizationFooter() {
    let { addOrganizationApi, inputBoxConfiguration } = this.props;
    let formValue = inputBoxConfiguration.map((item) => item.value);
    const { footer } = constants;
    return (
      <div className="organization-footer">
        <ButtonElement color="secondary" onClick={this.onCancelAddOrganization}>
          {constants.CANCEL}
        </ButtonElement>
        <LoaderButton
          disabled={!formValue[0] || !this.props.isFormValid || formValue[0].trim()===""}
          onClick={this.addOrganization}
          classList={footer.classes}
          loader={addOrganizationApi.isProcessing}
          loaderClass={footer.loaderClass}
          text={footer.text}
        />
      </div>
    );
  }

  getRouteHeader = () => {
    return (
      <RouteHeader {...this.props} navigationData={constants.navigationList} />
    );
  };
  render() {
    return (
      <>
        {this.getRouteHeader()}
        <Container>
          <div className="add-organization">
            <div className="add-organization__inner">
              <Card>
                <Card.Header test-name="header">
                  <h3 children={constants.title} />
                </Card.Header>
                <Card.Body>
                  {this.listAddOrganizationFields()}
                  {this.addOrganizationError()}
                </Card.Body>
                <Card.Footer children={this.addOrganizationFooter()} />
              </Card>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default AddOrganization;
