import React from 'react';
import { SelectModal } from '../SelectModal';
import { notification } from 'antd';

class AddDemographics extends React.Component {
  state = { modalVisibility: false };

  onSelectItem = (column) => {
    const { selectedColumns } = this.props;
    if (selectedColumns.length <= 1 && selectedColumns.includes(column.label))
      notification.warn({
        message: 'At least one demographic must be selected',
      });
    else this.props.onSelect && this.props.onSelect({ selected: column.label });
  };

  toggleModal = () =>
    this.setState({ modalVisibility: !this.state.modalVisibility });

  onOk = () => {
    this.props.onOk && this.props.onOk(this.props.selectedColumns);
    this.toggleModal();
  };

  onCancel = () => {
    this.props.onCancel  && this.props.onCancel();
    this.toggleModal();
  };

  render() {
    return (
      <React.Fragment>
        <div className='add-demographics'>
          {this.props.fromNewSurveyWizardResponsePage?'':"Add demographic:"}
          <div
            onClick={this.toggleModal}
            className='demographics__add-category-button'
          >
            <p>{this.props.label?this.props.label:"Choose..."}</p>
          </div>
        </div>
        <SelectModal
          items={this.props.columns}
          activeItems={this.props.selectedColumns}
          onCancel={this.onCancel}
          onOk={this.onOk}
          onSelect={this.onSelectItem}
          visible={this.state.modalVisibility}
          okButtonProps={this.props.columns && this.props.columns.length ?false:true}
        />
      </React.Fragment>
    );
  }
}

export default AddDemographics;
