export const fetchResultsDataStart = (state) => {
    return {
        ...state,
        fetchResultsPageApi: { ...state.fetchResultsPageApi, isProcessing: true, error: false },

    }
}

export const fetchResultsDataSuccess = (state, payload) => {
    return {
        ...state,
        fetchResultsPageApi: {
            ...state.fetchResultsPageApi,
            isProcessing: false,
            data: payload.data.response
        },

    }
}

export const fetchResultsDataFail = (state) => {
    return {
        ...state,
        fetchResultsPageApi: { ...state.fetchResultsPageApi, isProcessing: false, error: true },
    }
}