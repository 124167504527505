import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const storyboardParticipationStarted = (state) => {
    return {
        ...state,
        storyboardParticipationApi: getApiStartState()
    }

}

export const storyboardParticipationSuccess = (state, payload) => {
    return {
        ...state,
        storyboardParticipationApi: getSuccessResponse(payload && payload.data),
        overallParticipationRate: mapOverallParticipationRate(payload && payload.data)
    }

}

export const storyboardParticipationFailed = (state, payload) => {
    return {
        ...state,
        storyboardParticipationApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        },
        overallParticipationRate: {
            isDataFetched: false,
            projectId: undefined,
        }
    }

}

const mapOverallParticipationRate = (participationData) => {
    if (participationData && participationData.data &&
        participationData.data.aggregatedDemographicsResponse) {
        const data = participationData.data.aggregatedDemographicsResponse;
        const projectId = participationData.projectData.projectId
        return {
            projectId,
            ...data,
            isDataFetched: true
        }

    }
    return {
        isDataFetched: false,
        projectId: undefined,
    }

}