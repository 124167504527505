export const setThresholdValueStarted = (state, payload) => {
  return {
    ...state,
    setProjectConfigApi: { isProcessing: true, error: false, data: null }
  };
};

export const setThresholdValueSuccess = (state, payload) => {
  return {
    ...state,
    setProjectConfigApi: { isProcessing: true, error: false, data: null }
  };
};

export const setThresholdValueFail = (state, payload) => {
  return {
    ...state,
    setProjectConfigApi: { isProcessing: false, error: true, data: null }
  };
};