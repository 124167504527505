import {createSelector} from 'reselect';

const getProfilePageReducer = state =>
    state.getProfilePageReducer;

const StateSelector = state => state;

export const SelectState = createSelector(
    [getProfilePageReducer],
    StateSelector
);
