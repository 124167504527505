import {
  FETCH_USERS_LIST_STARTED,
  FETCH_USERS_LIST_SUCCESS,
  FETCH_USERS_LIST_FAILED,
} from "../../constants";

import { ApiBase } from "../../../utils/apiBase";
import { FETCH_USERS_URL } from "../../../../config/constants";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

export const fetchUsers = (params) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(fetchUsersStart());
    apiBaseInstance.instance
      .post(FETCH_USERS_URL, { ...params, ...getProjectIdAndOrgId(params) })
      .then((res) => fetchUsersSuccess(dispatch, res))
      .catch((err) => fetchUsersFailure(dispatch, err));
  };
};

export const fetchUsersStart = () => {
  return {
    type: FETCH_USERS_LIST_STARTED,
  };
};
function fetchUsersSuccess(dispatch, res) {
  return dispatch({
    type: FETCH_USERS_LIST_SUCCESS,
    payload: res.data,
  });
}
export function fetchUsersFailure(dispatch, err) {
  return dispatch({
    type: FETCH_USERS_LIST_FAILED,
    payload: err,
  });
}
