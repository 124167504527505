import { withType } from '../../../../utils/actionOperations';
import { UploadFile, getKeyNameForFileUpload } from '../../../../utils/uploadApi';
import {
    NEW_WIZARD_UPLOAD_TRANSLATED_FILE_START,
    NEW_WIZARD_UPLOAD_TRANSLATED_FILE_SUCCESS,
    NEW_WIZARD_UPLOAD_TRANSLATED_FILE_FAILED,
    NEW_WIZARD_CHECK_TRANSLATION_STATUS_START,
    NEW_WIZARD_CHECK_TRANSLATION_STATUS_SUCCESS,
    NEW_WIZARD_CHECK_TRANSLATION_STATUS_FAILED
} from '../../../constants';
import { GET_SIGNED_URL, TRANSLATE_QUESTION_SET, GET_SURVEY_TRANSLATION_STATUS } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';


export function NewWizardUploadTranslatedQuestions(params, cb) {
    const apiBaseInstance = new ApiBase()
    const payload = {
        fileName: params.fileName,
        fileType: params.fileType,
    }
    return dispatch => {
        dispatch(withType(NEW_WIZARD_UPLOAD_TRANSLATED_FILE_START, {}));
        apiBaseInstance.instance
            .post(GET_SIGNED_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                return uploadToStorage(dispatch, {
                    ...response,
                    ...{ params }
                }, cb);
            })
            .catch(err => {
                dispatch(withType(NEW_WIZARD_UPLOAD_TRANSLATED_FILE_FAILED, err));
            });
    };
}

function getHeader(fileType) {
    return {
        headers: {
            'Content-Type': fileType
        }
    };
}



function uploadToStorage(dispatch, urlResponse, cb) {
    const payload = {
        url: urlResponse.data.data.preSignedUrl,
        file: urlResponse.params.file,
        options: getHeader(urlResponse.params.fileType)
    }
    UploadFile(payload)
        .then(response => {
            let maptoProjectPayload = {
                organizationId: urlResponse.params.organizationId,
                projectId: urlResponse.params.projectId,
                translatedQuestionSetKeyName: getKeyNameForFileUpload(urlResponse.data.data.fileurl)
            }
            cb && cb(maptoProjectPayload)
        })
        .catch(error => {
            return dispatch(withType(NEW_WIZARD_UPLOAD_TRANSLATED_FILE_FAILED, error));
        });
}

export const newWizardmapKeynameToProject = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        apiBaseInstance.instance
            .post(TRANSLATE_QUESTION_SET, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                dispatch(withType(NEW_WIZARD_UPLOAD_TRANSLATED_FILE_SUCCESS, {}))
                cb && cb(true)
            })
            .catch(err => {
                dispatch(withType(NEW_WIZARD_UPLOAD_TRANSLATED_FILE_FAILED, err));
                cb && cb(false)
            });
    };
};


export const NewWizardCheckTranslationStatus = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(NEW_WIZARD_CHECK_TRANSLATION_STATUS_START, {}));
        apiBaseInstance.instance
            .post(GET_SURVEY_TRANSLATION_STATUS, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                cb && cb(response)
                dispatch(withType(NEW_WIZARD_CHECK_TRANSLATION_STATUS_SUCCESS, response));
            })
            .catch(err => {
                cb && cb(err)
                dispatch(withType(NEW_WIZARD_CHECK_TRANSLATION_STATUS_FAILED, err));
            });
    };
};



