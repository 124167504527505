import React from "react";
import { switchOrganization } from "./switchOrganization";
import { ButtonElement } from "../../FormElements/ButtonElement";
import { DotMenu } from "../../Components/DotMenu";
import {ORGANIZATION_PERMISSIONS} from '../../../config/userPermissionConstants'
import { checkAccessStatus } from "../../utils/rolePermissionUtils";

export const Message = {
  error: {
    key: "organization_error",
    type: "danger",
    text: "Organizations Fetching Error",
  },
  empty: {
    key: "organization_empty",
    type: "warning",
    text: "Organizations List is Empty",
  },
};
const renameSurvey = (event, value, props) => {
  props.visibleToggle("renameOrgVisible", value);
};
const deleteSurvey = (event, value, props) => {
  props.handleDeleteOrganization("renameOrgVisible", value);
};
const menuList = (value, props) => {
  let menuList = [
    {
      label: "Rename",
      onMenuItemClick: renameSurvey,
      value: value,
      props: props,
      accessKey : ORGANIZATION_PERMISSIONS.RENAME
    },
    { 
      label: "Delete", 
      onMenuItemClick: deleteSurvey, 
      value: value, 
      props: props ,
      accessKey : ORGANIZATION_PERMISSIONS.DELETE
    },
  ];
  const filteredMenuList = menuList.filter(item=> checkAccessStatus(value.organizationId,item.accessKey))
  return filteredMenuList;
};
export default {
  local: {
    firstName: "firstName",
    userId: "userId",
    lastName: "lastName",
  },
  emptyString: "",
  renameOrgModal: {
    title: "Rename organization",
    placeholder: "Organization name",
  },
  rename: "Rename",
  LISTSIZE: 12,
  NETWORK_ERROR: "Network Error",
  ERROR_MESSAGE: "No organization found!",
  path: {
    addOrganization: "/admin/home/organizations/addorganization",
  },
  ORG_ADMIN: "Organization Admin",
  organization: "Organization",
  btn: {
    classes: ["header-btn"],
    text: "New organization",
  },
  states: {
    error: "error",
    empty: "empty",
  },
  ORGANIZATIONS: "Organizations",
  DELETE_ORGANIZATION__HEADER:"Delete organization",
  DELETE_ORGANIZATIONS_MESSAGE: "Are you sure you want to delete this organization?",
  navigationList: ["home", "organization"],
  tableColumn: (props) => [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      render: (str, record) => (
        <>
          <b className=" td-title" children={str} />
        </>
      ),
      sorter: true, //(a,b)=>compareByAlpha(a.name.toLowerCase(), b.name.toLowerCase()),
    },
    {
      title: "Projects",
      key: "projectCount",
      dataIndex: "projectCount",
      render: (str, record) => <span className="table-count">{str}</span>,
    },
    {
      dataIndex: "role",
      title: "Role",
      key: "role",
      render: (text) => {
        return "Admin";
      },
    },
    {
      title: "",
      key: "results",
      dataIndex: "",
      width: 10,
      render: (item, doc) => (
        <ButtonElement
          onClick={() => {
            switchOrganization(doc, props);
          }}
          className="switch-btn"
          children="Select"
        />
      ),
    },
    {
      render: (_, doc) =>menuList(doc,props).length !==0 ? <DotMenu itemList={menuList(doc, props)} />:null,
    },
  ],
  sortOrganizations: {
    ascend: "asc",
    descend: "desc",
    defaulSortKey: "name",
    defaultSortOrder: "asc",
    asc: "asc",
    desc: "desc",
  },
};
