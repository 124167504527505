import {
    makeKey,
    update,
    getSuccessResponse
} from '../../../../utils/reducerOperations';
import {
    SetLocalStorage
} from '../../../../utils/localStorgeOperations';
export function handleLoginProcessSuccess(state, payload) {
    SetLocalStorage(payload.data);
    return makeKey(
        'loginProcessApi',
        update(
            state.loginProcessApi,
            getSuccessResponse(payload)
        )
    );
}
