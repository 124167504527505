import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const agilityTemplate = [
    {
        questionDimension: 'Agility',
        type: 'Matrix',
        questionLabel: 'Agility',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: 'We quickly bounce back from failures and setbacks.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('We quickly bounce back from failures and setbacks.')
        },
        {
            text: 'My organization has formal processes in place to learn from failures.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My organization has formal processes in place to learn from failures.')

        },

        {
            text: `We quickly change tactics if something doesn't work.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`We quickly change tactics if something doesn't work.`)

        },
        {
            text: 'We remain calm and collected when facing tough situations.', value: uuidv4(), isSelected: false, staticQuestionId: generateStaticQuestionId('We remain calm and collected when facing tough situations.')

        }]
    }
]

