import {
  getSuccessResponse,
  handleApiResponseError,
  handleNetworkError,
} from "../../../utils/reducerOperations";

export function getDistributionDataStart(state, action) {
  return {
    ...state,
    getDistributionDataApi: {
      data: null,
      isProcessing: true,
      error: false,
      message: null,
    },
  };
}

export function getDistributionDataSuccess(state, action) {
  return {
    ...state,
    getDistributionDataApi: getSuccessResponse(action.payload),
  };
}

export function getDistributionDataFail(state, action) {
  return {
    ...state,
    getDistributionDataApi:
      action.payload && !action.payload.data
        ? handleApiResponseError(action.payload)
        : handleNetworkError(action.payload),
  };
}
