import React from 'react';
import { constants } from './constants';
import { Tooltip } from 'antd';
import SVGIcons from '../SVGIcons';
import { DemographicsBarChart } from '../DemographicsBarChart';

export class StoryBoardNewBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: this.props.limit || 3,
      showItems: this.props.limit || 3,
      threshold: this.props.threshold || 5,
    };
  }

  getBackgroundColor = (index) => {
    return constants.BACKGROUND_COLOR_CLASSES[index % 6];
  };

  getGraphColor = (index) => {
    return constants.GRAPH_COLOR_CLASSES[index % 6];
  };

  showMoreOnClick = () => {
    let value = this.state.limit;
    if (this.state.showItems > 8) {
      value = this.props.data.length + 1 - this.state.showItems;
    }
    this.setState((prevState) => ({
      showItems: prevState.showItems + value,
    }));
  };

  showLessOnClick = () => {
    this.setState({ showItems: this.state.limit });
  };

  getLabel = (label) => {
    if (label.length > 24) {
      return (
        <Tooltip placement='top' title={label}>
          {`${label.slice(0, 24)}...`}
        </Tooltip>
      );
    }
    return label;
  };


  //remove calling this function when explore comments tab to activate threshold

  getThresholdChecking = (key) => {
    if (this.props.isFromCommentQuestions) {
      return true
    }
    return key
  }

  render() {
    return (
      <>
        <ul className='barchart-block '>
          {this.props.data.slice(0, this.state.showItems).map((item, index) => {
            let width = parseInt(item.width) < 5 ? 5 : item.width
            let lessThanThreshold = this.props.isFromCommentQuestions ? parseInt(item.value.replace("%", ""), 10) < this.state.threshold : item.threshold < this.state.threshold
            let thresholdCheckingValue = this.props.isFromCommentQuestions ? parseInt(item.value.replace("%", ""), 10) : item.threshold
            return (
              <li key={item.label}>
                <div className='barchart-block__label'>
                  <h3 className={lessThanThreshold ? '' : this.props.labelClass}
                    onClick={() => !lessThanThreshold && this.props.onClick && this.props.defaultTopicIsSelected && this.props.onClick(item)}>
                    {this.getLabel(item.label)}{' '}
                    {this.props.outliers &&
                      !this.props.outliers.isProcessing &&
                      checkOutliners(item.label, this.props)}
                  </h3>
                  {this.getThresholdChecking(thresholdCheckingValue >= this.state.threshold) && item.subLabel && (
                    <p>{item.subLabel}</p>
                  )}
                </div>
                {this.getThresholdChecking(thresholdCheckingValue >= this.state.threshold) ? (
                  <div

                    className='barchart-block__bar'
                  >
                    {this.props.scoreType ? <DemographicsBarChart scoreType={this.props.scoreType} data={item} /> : <div
                      className={`barchart-block__bar--growth
                    ${this.props.hideBackground ? "" : (this.getBackgroundColor(
                        index
                      ))}`}
                    >
                      <span
                        className={`progress-value ${this.props.graphCursorClass} ${this.getGraphColor(
                          index
                        )}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.onClick && this.props.defaultTopicIsSelected && this.props.onClick(item);
                        }}
                        style={{ width: width + '%' }}
                      >
                        {this.props.showPercentage ? item.value + "%" : item.value}
                      </span>
                    </div>
                    }

                  </div>
                ) : (
                  <div className='barchart-block__text-block'>
                    {constants.INSUFFICIENT_DATA}
                  </div>
                )}
              </li>
            );
          })}
        </ul>


        {this.props.data && this.props.data.length > this.state.showItems && (
          <div className='show-less'>
            <a href={false} onClick={this.showMoreOnClick}>
              Show more <SVGIcons type={'SVG-chevron-down'} />
            </a>
          </div>
        )}
        {this.props.data &&
          this.props.data.length > this.state.limit &&
          this.props.data.length <= this.state.showItems && (
            <div className='show-less'>
              <a href={false} onClick={this.showLessOnClick}>
                Show less <SVGIcons type={'SVG-chevron-up'} />
              </a>
            </div>
          )}
      </>
    );
  }
}

function checkOutliners(itemName, props) {
  if (props.questionIsSelected) {
    return ''
  }
  if (checkPositiveOutliner(itemName, props)) {
    return (
      <Tooltip placement='top' title={constants.POSTIVEOUTLIERTITLE}>
        <span>
          <SVGIcons type={'SVG-warning-blue'} />
        </span>
      </Tooltip>
    );
  } else if (checkNegativeOutliner(itemName, props)) {
    return (
      <Tooltip placement='top' title={constants.NEGATIVEOUTLIERTITLE}>
        <span>
          <SVGIcons type={'SVG-warning-red'} />
        </span>
      </Tooltip>
    );
  }
}

function checkPositiveOutliner(itemName, props) {
  let topic = props.topics;
  let outliers = props.outliers.outliers;
  if (topic) {
    let chosenTopic = undefined;
    if (Array.isArray(topic)) {
      chosenTopic = topic[0];
    } else {
      chosenTopic = topic;
    }
    if (chosenTopic) {
      return outliers.positiveOutliers.some(
        (item) =>
          props.outliersData === item.type &&
          itemName === item.segment &&
          item.dimension === chosenTopic
      );
    }
    return false;
  }
  return false;
}
function checkNegativeOutliner(itemName, props) {
  let topic = props.topics;
  let outliers = props.outliers.outliers;
  if (topic) {
    let chosenTopic = undefined;
    if (Array.isArray(topic)) {
      chosenTopic = topic[0];
    } else {
      chosenTopic = topic;
    }
    if (chosenTopic) {
      return outliers.negativeOutliers.some(
        (item) =>
          props.outliersData === item.type &&
          itemName === item.segment &&
          item.dimension === chosenTopic
      );
    }
    return false;
  }
  return false;
}
