import {
    REPROCESS_TOPICS_FAILED,
    REPROCESS_TOPICS_START,
    REPROCESS_TOPICS_SUCCESS
} from '../../../constants';
import { REPROCESS_TOPICS } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { withType } from '../../../../utils/actionOperations';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const reprocessTopics = (payload,cb) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(REPROCESS_TOPICS_START, {}));
        apiBaseInstance.instance
            .post(REPROCESS_TOPICS, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                dispatch(withType(REPROCESS_TOPICS_SUCCESS, {
                    data: response.data,
                    message: response.message
                }));
                cb && cb();
            })
            .catch(err => {
                dispatch(withType(REPROCESS_TOPICS_FAILED, {
                    error: err
                }));
            });
    };
};

