import React from 'react';
import { DatePicker } from 'antd';
const defaultDateFormat = "MMMM D, YYYY"
const LOCALE = {
    "lang": {
      "locale": "en_US",
      "placeholder": "Select date",
      "rangePlaceholder": ["Start date", "End date"],
      "today": "Today",
      "now": "Now",
      "backToToday": "Back to today",
      "ok": "Ok",
      "clear": "Clear",
      "month": "Month",
      "year": "Year",
      "timeSelect": "Select time",
      "dateSelect": "Select date",
      "monthSelect": "Choose a month",
      "yearSelect": "Choose a year",
      "decadeSelect": "Choose a decade",
      "yearFormat": "YYYY",
      "dateFormat": "M/D/YYYY",
      "dayFormat": "D",
      "dateTimeFormat": "M/D/YYYY HH:mm:ss",
      "monthFormat": "MMMM",
      "monthBeforeYear": true,
      "previousMonth": "Previous month (PageUp)",
      "nextMonth": "Next month (PageDown)",
      "previousYear": "Last year (Control + left)",
      "nextYear": "Next year (Control + right)",
      "previousDecade": "Last decade",
      "nextDecade": "Next decade",
      "previousCentury": "Last century",
      "nextCentury": "Next century"
    },
    "timePickerLocale": {
      "placeholder": "Select time"
    },
    "dateFormat": defaultDateFormat,
    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
    "weekFormat": "YYYY-wo",
    "monthFormat": "YYYY-MM"
  };

const dateRender = (current) => { 
    return <div className="ant-picker-cell-inner" title="">
        {current.date()}
        </div>
};

export default function Datepicker(props) {
    const defaultMinDate = new Date();
    defaultMinDate.setDate(defaultMinDate.getDate() - 1)
    defaultMinDate.setHours(23);
    defaultMinDate.setMinutes(59);
    defaultMinDate.setSeconds(59);
    const minDate = props.minDate || defaultMinDate
    const maxDate = props.maxDate
    return (
        <DatePicker
            format={props.dateFormat || defaultDateFormat}
            placeholder={props.placeholder}
            disabledDate={d => !d || d.isBefore(minDate) || (maxDate&&d.isAfter(maxDate))}
            onChange={props.onChange}
            className={`${props.className || ''}`}
            value={props.value || undefined}
            defaultValue={props.defaultValue}
            locale={LOCALE}
            dateRender={dateRender}
            inputReadOnly={true}
        />
    );
}