export const InitialState=()=>({
    fetchUsersApi:{
        isProcessing: false,
        data:null,
        error:false,
        message:'',
        count: 0,
    },
    isDataAlreadyFetched:false,
    inviteManagersApi:{
        isProcessing:false,
        data:null,
        error:false,
        message:''
    },
    fetchHRISFileDetailsApi:{
        isProcessing:false,
        data:null,
        error:false,
        message:''
    },
    revokeUserApi: {
        isProcessing: false,
        error: false,
        message: ''
    }
});