

export const updateTrendDataStart = (state, payload) => {
  if (payload.isFromSurveyTags) {
    return {
      ...state,
      updateTrendDataApi: {
        isProcessing: true,
        data: null,
        error: false,
        message: ''
      },
      listComparisonSurveyApi: {
        isProcessing: false,
        data: payload.updatedData,
        error: false,
        message: ''
      },
      trendPairingDataApi: {
        ...state.trendPairingDataApi,
        currentSurvey: payload.updatedSurveyDetails ? payload.updatedSurveyDetails : state.trendPairingDataApi.currentSurvey
      },
      isTrendPairingDataAlreadyFetched: true

    }
  }
  else {
  return {
    ...state,
    updateTrendDataApi: {
      isProcessing: true,
      data: null,
      error: false,
      message: ''
    }
  }
  }


}

export const updateTrendDataSuccess = (state, payload) => {
  return {
    ...state,
    updateTrendDataApi: {
      isProcessing: false,
      data: payload.data,
      error: false,
      message: payload.message
    },

  }

}

export const updateTrendDataFailed = (state, payload) => {
  return {
    ...state,
    updateTrendDataApi: {
      isProcessing: false,
      data: null,
      error: payload.error,
    },
  }

}

