export var CULTUREBIE_USER_ROLES = [{
  roleId: 1,
  value: 'admin',
  roleName: 'Admin'
}, {
  roleId: 2,
  value: 'viewer',
  roleName: 'Viewer'
}];
export var ROLE_PERMISSION_SECRET = "zi3DM49NT9SvdElbj4nJ";
export var USER_ROLES = {
  ORG_ADMIN: 'Organization Admin',
  SUPER_ADMIN: 'Super Admin',
  ORG_MANAGER: 'Organization Manager',
  VIEWER: 'Viewer',
  ADMIN: 'Admin'
};