import { ADD_COMPONENT, REMOVE_COMPONENT, SET_SELECTED_COMPARISON, SET_SELECTED_PALETTE, SET_SELECTED_RANGE_SELECTOR } from "../../constants";

export const setComparison = (payload) => {
    return(
        {
            type: SET_SELECTED_COMPARISON,
            payload,
        }
    )
  };

  export const setRangeSelector=(payload)=>{
    return(
        {
            type: SET_SELECTED_RANGE_SELECTOR,
            payload,
        }
    )
  }
  
  export const setPalette =(payload)=>{
    return(
        {
            type: SET_SELECTED_PALETTE,
            payload,
        }
    )
  }

  export const addComponentButton =(payload)=>{
    return(
        {
            type: ADD_COMPONENT,
            payload,
        }
    )
  }

  export const removeComponentButton =(payload)=>{
    return(
        {
            type:REMOVE_COMPONENT,
            payload
        }
    )
  }