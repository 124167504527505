import {
  makeKey,
  update,
  getSuccessResponse,
} from "../../../../utils/reducerOperations";
import { toast } from "../../../../utils/messageUtils";

export function changeFirstLastNameSuccess(state, payload) {
  toast.success("First or last name changed successfully");
  return makeKey(
    "changeFirstLastNameApi",
    update(state.changeFirstLastNameApi, {
      ...getSuccessResponse(payload),
      code: payload.code,
    })
  );
}
