import {
    GET_TOPIC_LIST_START,
    GET_TOPIC_LIST_SUCCESS,
    GET_TOPIC_LIST_FAILED
} from '../../../constants';
import { STORYBOARD_TOPIC_URL } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { withType } from '../../../../utils/actionOperations';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const getTopicList = (payload) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(GET_TOPIC_LIST_START, {}));
        apiBaseInstance.instance
            .post(STORYBOARD_TOPIC_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                dispatch(withType(GET_TOPIC_LIST_SUCCESS, {
                    data: response.data,
                    message: response.message
                }));
            })
            .catch(err => {
                dispatch(withType(GET_TOPIC_LIST_FAILED, {
                    error: err
                }));
            });
    };
};

