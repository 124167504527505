import {
  getSuccessResponse,
  handleApiResponseError,
  handleNetworkError,
} from "../../../../utils/reducerOperations";
import { notification } from "antd";
import { toast } from "../../../../utils/messageUtils";

function failureCase(response,copyProjectFail) {
  let api = handleNetworkError;
  let payload = response;
  let message = response.message
  if (response && response.response && response.response.data) {
    payload = response.response.data;
    message = payload.message
    api = handleApiResponseError;
  }
  if(copyProjectFail){
    message =  `Copy survey failed. \n Reason: ${message}`
  }
  notification.error({ message: message });
  return api(payload);
}

function startCase() {
  return {
    isProcessing: true,
    data: null,
    error: null,
    message: null,
  };
}

export function createProjectStart(state) {
  return {
    ...state,
    createProjectApi: startCase(),
  };
}

export function createProjectSuccess(state, action) {
  return {
    ...state,
    createProjectApi: getSuccessResponse(action.response),
  };
}

export function createProjectFail(state, action) {
  return {
    ...state,
    createProjectApi: failureCase(action.response,true),
  };
}

export function saveQuestionSetStart(state) {
  return {
    ...state,
    saveQuestionSetApi: startCase(),
  };
}

export function saveQuestionSetSuccess(state, action) {
  return {
    ...state,
    saveQuestionSetApi: getSuccessResponse(action.response),
  };
}

export function saveQuestionSetFail(state, action) {
  return {
    ...state,
    saveQuestionSetApi: failureCase(action.response),
  };
}

export function fetchProjectsStart(state, action) {
  return {
    ...state,
    myProjectsApi: {
      ...state.myProjectsApi,
      isProcessing: true,
      data: null,
      error: null,
      message: null,
    },
  };
}

export function fetchProjectsSuccess(state, action) {
  return {
    ...state,
    myProjectsApi: getSuccessResponse(action.data),
  };
}

export function fetchProjectsFail(state, action) {
  return {
    ...state,
    myProjectsApi: failureCase(action.error),
  };
}

export function setProjectCount(state, action) {
  return {
    ...state,
    projectsCount: action.count,
  };
}

export function copySurveyStart(state, action) {
  return {
    ...state,
    copySurveyApi: {
      ...state.copySurveyApi,
      isProcessing: true,
      data: null,
      error: null,
      message: null,
    },
  };
}

export function copySurveySuccess(state, action) {
  return {
    ...state,
    copySurveyApi: getSuccessResponse(action.data),
  };
}

export function copySurveyFail(state, action) {
  return {
    ...state,
    copySurveyApi:failureCase(action.response),
  };
}

export function deleteProjectStarted(state, action) {
  return {
    ...state,
    deleteProjectApi: {
      ...state.deleteProjectApi,
      isProcessing: true,
      data: null,
      error: null,
      message: null,
    },
  };
}

export function deleteProjectSuccess(state, action) {
  toast.success("Project deleted successfully");
  return {
    ...state,
    deleteProjectApi: getSuccessResponse(action.data),
  };
}

export function deleteProjectFailure(state, action) {
  return {
    ...state,
    deleteProjectApi: failureCase(action.error),
  };
}
