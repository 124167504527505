import {
    UPDATE_BENCHMARK_DATA_START,
    UPDATE_BENCHMARK_DATA_SUCCESS,
    UPDATE_BENCHMARK_DATA_FAIL,
  } from "../../../constants";
  import { ApiBase } from "../../../../utils/apiBase";
  import { withType } from "../../../../utils/actionOperations";
import { UPDATE_BENCH_MARK } from "../../../../../config/constants";
import { toast } from "../../../../utils/messageUtils";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';
  
export const NewdashboardUpdateBenchMarkData = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
      dispatch(withType(UPDATE_BENCHMARK_DATA_START, {}));
      apiBaseInstance.instance
        .post(UPDATE_BENCH_MARK, { ...payload, ...getProjectIdAndOrgId(payload) })
        .then((response) => {
          toast.success("Benchmark score successfully updated.")
          dispatch(
            withType(UPDATE_BENCHMARK_DATA_SUCCESS, {
              data: response.data,
              message: response.message,
            })
          );
          cb && cb(true);
        })
        .catch((err) => {
          toast.error("Benchmark score update failed.")
          dispatch(
            withType(UPDATE_BENCHMARK_DATA_FAIL, {
              error: err,
            })
          );
        });
    };
  };
  