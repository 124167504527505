import { fetchPayload } from "../../../utils/reducerOperations";
import {
    GET_TOPIC_LIST_FAILED,
    GET_TOPIC_LIST_SUCCESS,
    GET_TOPIC_LIST_START,
    GET_TEXT_QUESTION_LIST_SUCCESS,
    GET_TEXT_QUESTION_LIST_FAILED,
    GET_TEXT_QUESTION_LIST_START,
    GET_THEMES_LIST_SUCCESS,
    GET_THEMES_LIST_FAILED,
    GET_THEMES_LIST_START,
    REPROCESS_TOPICS_SUCCESS,
    REPROCESS_TOPICS_START,
    REPROCESS_TOPICS_FAILED
} from "../../constants";
import {
    getTextQuestionListFailed,
    getTextQuestionListStarted,
    getTextQuestionListSuccess,
    getThemesListFailed,
    getThemesListStarted,
    getThemesListSuccess,
    getTopicListFailed,
    getTopicListStarted,
    getTopicListSuccess,
    reprocessTopicsFailed,
    reprocessTopicsStarted,
    reprocessTopicsSuccess
} from "./Helpers";
import { Default } from "./InitialState";
const CustomTopicMappingReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        //Topic List
        case GET_TOPIC_LIST_START:
            return getTopicListStarted(state, payload);
        case GET_TOPIC_LIST_SUCCESS:
            return getTopicListSuccess(state, payload);
        case GET_TOPIC_LIST_FAILED:
            return getTopicListFailed(state, payload);
        //Text Questions
        case GET_TEXT_QUESTION_LIST_START:
            return getTextQuestionListStarted(state, payload);
        case GET_TEXT_QUESTION_LIST_SUCCESS:
            return getTextQuestionListSuccess(state, payload);
        case GET_TEXT_QUESTION_LIST_FAILED:
            return getTextQuestionListFailed(state, payload);
        //Themes
        case GET_THEMES_LIST_START:
            return getThemesListStarted(state, payload);
        case GET_THEMES_LIST_SUCCESS:
            return getThemesListSuccess(state, payload);
        case GET_THEMES_LIST_FAILED:
            return getThemesListFailed(state, payload);
        //Reprocess topics
        case REPROCESS_TOPICS_START:
            return reprocessTopicsStarted(state, payload);
        case REPROCESS_TOPICS_SUCCESS:
            return reprocessTopicsSuccess(state, payload);
        case REPROCESS_TOPICS_FAILED:
            return reprocessTopicsFailed(state, payload);
        default:
            return { ...state };
    }
};

export default CustomTopicMappingReducer;
