import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const needForChangeTemplate = [
    {
        questionDimension: 'Need for Change',
        type: 'Matrix',
        questionLabel: 'Need for Change',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: `The current ERP makes my job more difficult than it should be.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`The current ERP makes my job more difficult than it should be.`),
        },
        {
            text: `I think the ERP project is necessary for the success of BMC.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`I think the ERP project is necessary for the success of BMC.`),

        }]
    }
]

