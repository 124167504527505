import { deepCopy } from "../../../../../../utils/deepCopy"
import { getHTMLRemovedQuestion } from "../helpers"

export const handleChangeMultiOptionChange = (state, payload) => {
    let changedGoal = getChangedGoal(state.goals, payload)
    return (
        {
            ...state,
            goals: changedGoal
        }
    )
}

const getChangedGoal = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach((goal, index) => {
        if (payload.blockId === goal.blockId) {
            goal.questions.forEach((item) => {
                if (!payload.isFromDescription) {
                    item.question = getHTMLRemovedQuestion(payload.value)
                    item.HTMLQuestion = payload.value

                }
                else {
                    item.questionLabel = payload.value
                }

            })
        }
    })
    return goalsCopy
}