import { deepCopy } from "../../../../../../utils/deepCopy"

export const onChangeLikertDimensionOrDescription = (state, payload) => {
    let updatedGoals = getDataChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getDataChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.descriptionChange) {
                element.description = payload.value
            } else {
                element.dimension = payload.value
                if (!payload.isFeedBackSurvey) {
                    element.goalId = payload.value.toLowerCase()
                }
                element.questions[0].matrixQuestions.forEach(matrix => {
                    matrix.followUpQuestions && matrix.followUpQuestions.forEach(followUp => {
                        if (followUp.type === "Matrix") {
                            followUp.matrixQuestions[0].selectedTopic = payload.value
                            followUp.selectedGoalId = payload.value.toLowerCase()
                            followUp.questionDimension = payload.value
                        } else {
                            followUp.topicDimension = payload.value
                            followUp.selectedTopic = payload.value
                            followUp.selectedGoalId = payload.value.toLowerCase()
                        }
                    })
                })
            }
        }
    });
    return goalsCopy
}
