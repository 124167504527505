import { deepCopy } from "../../../../../utils/deepCopy"

export const unMarkOutCome = (state, payload) => {
    let goals = unMarkOutcomeTopic(state.goals, payload)
    return {
        ...state,
        goals: goals
    }
}

const unMarkOutcomeTopic = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(item => {
        if (item.isDefault && item.isSelected) {
            item.isOutcomeVariable = false
        }
    })
    return goalsCopy
}