import { deepCopy } from "../../../../utils/deepCopy"

export const onChangeRanking = (state, payload) => {
    let changedQuestionSet = getChangedRankingQuestionSet(state, payload)
    return ({
        ...state,
        questionSet: changedQuestionSet.questionSetCopy,
        pageBreakAddedQuestionSet: changedQuestionSet.pageBreakBlockCopy

    })
}

const getChangedRankingQuestionSet = (state, payload) => {
    let options = []
    let questionSetCopy = deepCopy(state.questionSet)
    let pageBreakBlockCopy = deepCopy(state.pageBreakAddedQuestionSet)
    questionSetCopy.forEach((item, index) => {
        if (item.blockId === payload.blockId) {
            item.questions.forEach(question => {
                const changedOption = question.options[payload.optionIndex]
                if (changedOption) {
                    question.options.splice(payload.optionIndex, 1)
                    let answer = parseInt(payload.changedRank)
                    question.options.splice(payload.changedRank, 0, { ...changedOption, answer: (answer + 1).toString() })
                    options = question.options.map((updateOption, index) => {
                        return ({ ...updateOption, answer: (index + 1).toString() })
                    })
                    question.options = options
                    question.answer = JSON.stringify(options)
                }

            })


        }

    })
    let findedIndex = pageBreakBlockCopy.findIndex((item) => item.blockId === payload.blockId)
    if (findedIndex !== -1) {
        pageBreakBlockCopy[findedIndex].questions[0].options = options
    }
    return { questionSetCopy, pageBreakBlockCopy }

}