
import { notification } from "antd";
import { checkIfAdmin, checkIfSuperAdminOrOrgAdmin } from '../../../../../../utils/rolePermissionUtils';
import { getOrgIdFromUrl } from '../../../../../../utils/urlProcessor';

export function isJobCompleted(string = "") {
  return !!!(string === "needs_review");
}
export function isJobCompletedStatus(string = "") {
  return !!!(string === "completed");
}
export function isJobProcessing(string = "") {
  let organizationId = getOrgIdFromUrl();
  if (checkIfSuperAdminOrOrgAdmin(organizationId) || checkIfAdmin()) {
    return string === "needs_review" || string === "processing";
  }
  return string === "processing";
}

export function isJobProcessed(state = "") {
  return state === "processed";
}

export function disableFieldByOperationType(type, record) {
  if (type === "delete") return isJobProcessing(record.job_state);
  if (type === "approve") return isJobCompleted(record.job_state);
}

export const submitToUpload = (props, data) => {
  props.bulkUploadStart();
  let fileTopic = props.libraryFileApi;
  if (fileTopic.file) {
    props.getPreSignedURL(
      {
        fileName: fileTopic.file.name,
        fileType: fileTopic.file.type,
        isTopic: true,
      },
      (response) => {
        props.uploadFile(
          {
            url: response ? response.data.data.preSignedUrl : "",
            file: fileTopic.file,
            fileType: fileTopic.file.type,
          },
          (isSuccess) => {
            if (isSuccess) {
              data.cb(response.data, getKeyNameForAddTopicLibraryJob(response), fileTopic.file);
              // notification.success({ message: "Successfully Topic library uploaded!" });
            } else {
              notification.error({ message: "Topic library file upload unsuccessful" });
              data.failed();

            }
          }
        );

      }
    );
  }
  else {
    data.failed()
  }
};

const getKeyNameForAddTopicLibraryJob = (response) => {
  let fileName = response ? response.data.data.fileurl : "";
  if (window.location.hostname === "app.culturebie.com" ||
    window.location.hostname === "www.app.culturebie.com") {
    return fileName && fileName.split("/culturebie-topiclibrarybucket/")[1];
  } else if (window.location.hostname === "qa.culturebie.com" ||
    window.location.hostname === "www.qa.culturebie.com") {
    return fileName && fileName.split("/culturebieqa-topiclibrarybucket/")[1];
  } else {
    return fileName && fileName.split("/culturebiedev-topiclibrarybucket/")[1];
  }
};

export default {
  submitToUpload: submitToUpload,
};
