export const fetchUsersStart = (state) => {
    return {
        ...state,
        fetchUsersApi: {
            ...state.fetchUsersApi,
            isProcessing: true,
        },
    }

}

export const fetchUsersSuccess = (state, payload) => {
    return {
        ...state,
        fetchUsersApi:  {
            isProcessing:false,
            data:payload.data.data,
            error:false,
            message:payload.message,
            count: payload.data.data.count,
        }
    }
}

export const fetchUsersFailed = (state, payload) => {
    return {
        ...state,
        fetchUsersApi: {
            isProcessing: false,
            data: null,
            error: !payload.status,
            message:payload.message
        }
    }

}