import { deepCopy } from "../../../../../../utils/deepCopy"

export const handleDeleteBlock = (state, payload) => {
    let updatedGoal = getDeletedGoals(state.goals, payload)
    return (
        {
            ...state,
            goals: updatedGoal
        }
    )
}

const getDeletedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    let findedIndex = goalsCopy.findIndex((item) => item.blockId === payload.blockData.blockId)
    goalsCopy.splice(findedIndex, 1)
    return goalsCopy
}