// import Axios from 'axios';
import { notification } from 'antd';
import { fetchBatchIds } from './getBatchIds';
import {  DELETE_ORGANISATION_PROJECT_URL } from '../../../../config/constants';
import constants from './constants';
import { ApiBase } from '../../../utils/apiBase';

export const deleteBatchId = (id, cb) => {
    const Axios = new ApiBase().instance;
    return dispatch => {
        Axios.post(DELETE_ORGANISATION_PROJECT_URL, id)
            .then(res => deleteBatchIdSuccess(dispatch, res, cb))
            .catch(err => deleteBatchIdFailed(dispatch, err));
    };
};

function deleteBatchIdSuccess(dispatch, res, cb) {
    notification.success({ message: constants.batchIdDeleteSuccessMsg });
    dispatch(fetchBatchIds())
    // setTimeout(() =>dispatch(fetchBatchIds()), 1);
    cb && cb();
}

function deleteBatchIdFailed(dispatch, err) {
    notification.error({ message: constants.batchIdDeleteFailedMsg });
    //cb && cb();
    return dispatch({ type: '' });
}
