import {
    makeKey,
    update,
    getSuccessResponse
} from '../../../../utils/reducerOperations';
import {
    SetLocalStorage
} from '../../../../utils/localStorgeOperations';
export function ForgotPasswordSuccess(state, payload) {
    SetLocalStorage(payload.data);
    return makeKey(
        'forgotPasswordApi',
        update(state.forgotPasswordApi, {
            ...getSuccessResponse(payload),
            code: payload.code
        })
    );
}
