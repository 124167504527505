import {
  SURVEYWIZARD_UPDATE_PARTICIPATION_COLUMN_LIST,
  SURVEYWIZARD_DESELECT_PARTICIPATION_COLUMN,
} from "../../../../constants";
import { withType } from "../../../../../utils/actionOperations";

export const surveywizardUpdateParticipationSelectedTopicColumns = ({
  selected,
}) => {
  return (dispatch) => {
    dispatch(withType(SURVEYWIZARD_UPDATE_PARTICIPATION_COLUMN_LIST, selected));
  };
};

export const surveywizardDeselectParticipationcolumns = () => {
  return (dispatch) => {
    dispatch(withType(SURVEYWIZARD_DESELECT_PARTICIPATION_COLUMN, {}));
  };
};

