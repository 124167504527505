import { notification } from "antd";
import {
    customFormChangeSuccess,
} from "../../../../../utils/reducerOperations";
import { getDisplayDate, getDisplayTime } from "../../../../../utils/timeUtils"
export function handleCreateProjectFormChange(state, payload) {
    if (payload.key === 'projectName')
        return customFormChangeSuccess({ state, key: 'createProjectConfiguration' }, payload);
    else
        return mapFormFieldValue(state, payload)
}

export const updatedState = (state) => {
    return {
        ...state
    };
};

export const setProjectConfig = (state, payload) => {
   
    return {
        ...state,
        wizardConfig: payload.config,
    };
 };

export const setActiveMenu = (state, payload) => {
    return {
        ...state,
        activatedStep: state.activatedStep < payload.step ? payload.step : state.activatedStep
    };
};

export const setProjectDetails = (state, payload) => {
    return {
        ...state,
        ...mapExistingProjectDetails(state, payload)
    };
};

export const setSurveyInvitation = (state, payload) => {
    notification.success({ message: payload.notification || "Invitation updated" })
    return {
        ...state,
        surveyInvitation: {
            ...state.surveyInvitation,
            ...payload
        }
    };
};

export const setSelectedMenu = (state, payload) => {
    return {
        ...state,
        selectedMenu: payload.selectedMenu,
        activatedStep: payload.activatedStep || state.activatedStep
    };
};

export const getWizardConfigStart = (state, payload) => {
    return {
        ...state,
        getProjectDetailsApi: {
            isProcessing: true,
            error: null
        }
    };
};

export const getWizardConfigSuccess = (state, payload) => {
    return {
        ...state,
        ...mapExistingProjectDetails(state, payload),
        getProjectDetailsApi: {
            isProcessing: false,
            error: null
        },
    };
};

export const getWizardConfigFailed = (state, payload) => {
    return {
        ...state,
        getProjectDetailsApi: {
            isProcessing: false,
            error: true
        },
        ...payload
    };
};



const mapFormFieldValue = (state, payload) => {
    return {
        ...state,
        createProjectFields: {
            ...state.createProjectFields,
            [payload.key]: payload.value
        }
    }
}

const mapSelectedQuestions = (payload, state) => {
    if (payload.projectStatus === 'new') {
        return state.questionSet
    }
    let selectedQuestions = [];
    if (payload.goals) {
        selectedQuestions = payload.goals.filter(item => item.isSelected)
    }
    let newSelectedQuestions = [];
    if (payload.questions) {
        payload.questions.forEach((question) => {
            const questionObject = selectedQuestions.find(item => item.goalId === question.goalId)
            questionObject && newSelectedQuestions.push(questionObject)
        });
    }
    return newSelectedQuestions
}

const mapExistingProjectDetails = (state, payload) => {
    const { endDate, endTime, launchDate, launchTime } =
        getUpdatedDate({
            surveyEndDate: payload.surveyEndDate,
            surveyLaunchDate: payload.surveyLaunchDate
        });
    return {
        ...state,
        wizardConfig: {
            ...payload
        },
        goals: payload.goals || state.goals,
        questionSet: mapSelectedQuestions(payload, state),
        createProjectFields: {
            hrisFileId: payload.hrisFileId,
            endDate: endDate,
            endTime: endTime,
            surveyLaunchDate: launchDate,
            surveyLaunchTime: launchTime,
            isAnonymous: payload.isAnonymous,
            aboutSurvey: payload.aboutSurvey,
            hrisFileName: payload.hrisFileName,
            isReusable: payload.isReusable,
            generateLink: payload.generateLink,
            topicFileName: payload.topicFileName,
            topicFileUrl: payload.topicFileUrl,
            updatedProjectName: payload.projectName,
            isLocalizationEnabled: payload.isLocalizationEnabled,
            translateAboutSurvey: payload.translateAboutSurvey,
        },
        surveyInvitation: payload.invitation,
        ...customFormChangeSuccess({
            state, key: 'createProjectConfiguration'
        }, {
            index: 0, event: { target: { value: payload.projectName } }
        }),
        activatedStep: getActivatedStep(payload.projectStatus),
        reOrdered: payload.reOrdered
    }

}

const getActivatedStep = (status) => {
    let activatedStep = 2;
    if (status === 'draft') {
        activatedStep = 5
    }
    if (status === 'published' || status === 'scheduled') {
        activatedStep = 6
    }
    return activatedStep
}

const getUpdatedDate = ({ surveyEndDate, surveyLaunchDate }) => {
    let endDate, endTime, launchDate, launchTime,
        possibleLaunchDate, possibleEndDate, launch, end;
    const surveyLaunchDateObject = new Date(surveyLaunchDate)
    const surveyEndDateObject = new Date(surveyEndDate)
    if (surveyLaunchDate) {
        possibleLaunchDate = getPossibleMinimumLaunchTime(surveyLaunchDateObject)
        possibleEndDate = new Date(possibleLaunchDate)
        possibleEndDate.setMinutes(possibleEndDate.getMinutes() + 30)
    }
    if (surveyEndDate && surveyLaunchDate) {
        if (surveyLaunchDateObject < possibleLaunchDate) launch = possibleLaunchDate
        else launch = surveyLaunchDateObject
        if (surveyEndDateObject < possibleEndDate) end = possibleEndDate
        else end = surveyEndDateObject
    }
    else if (surveyEndDate) {
        possibleEndDate = getPossibleMinimumLaunchTime(new Date())
        if (surveyEndDateObject < possibleEndDate) end = possibleEndDate
        else end = surveyEndDateObject
    } else if (surveyLaunchDate) {
        if (surveyLaunchDateObject < possibleLaunchDate) launch = possibleLaunchDate
        else launch = surveyLaunchDateObject
    }
    if (launch) {
        launchDate = getDisplayDate(launch)
        launchTime = getDisplayTime(launch)
    }
    if (end) {
        endDate = getDisplayDate(end)
        endTime = getDisplayTime(end)
    }
    return { endDate, endTime, launchDate, launchTime };
}

const getPossibleMinimumLaunchTime = (date, referenceDate = new Date()) => {
    let hourDifference = getHourDifference(referenceDate, date);
    let newDate = new Date(date)
    if (hourDifference < .5) {
        newDate = new Date(referenceDate)
        newDate.setSeconds(0)
        newDate.setMilliseconds(0)
        if (newDate.getMinutes() < 30) {
            newDate.setMinutes(60)
        }
        else {
            newDate.setHours(newDate.getHours() + 1)
            newDate.setMinutes(30)
        }
    }
    return newDate;
}

const getHourDifference = (lesserDate, greaterDate) => {
    return (greaterDate - lesserDate) / (60 * 60 * 1000);
}