import {
    FETCH_MANAGER_HEATMAP_DATA_START,
    FETCH_MANAGER_HEATMAP_DATA_SUCCESS,
    FETCH_MANAGER_HEATMAP_DATA_FAILED
} from '../../../constants';
import { GET_MANAGER_HEATMAP } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { withType } from '../../../../utils/actionOperations';
import { getDataFromUrlToken } from '../../../../utils/urlDataStore';
import { URL_DATA } from '../../../../../config/urlDataConstants';
import { getSelectedUserData } from '../../../../Routes/NewDashboard/subroutes/Explore/helper';
import { getURLFilterAndByScore } from '../../../../utils/byScoreFilterUtils';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchManagerHeatmapTableColumnsAndData = (payload) => {
    const apiBaseInstance = new ApiBase();
    let filters = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(filters)
    let selectedUserData = getSelectedUserData();
    payload.filters = filters || {}
    return dispatch => {
        dispatch(withType(FETCH_MANAGER_HEATMAP_DATA_START, {
            requestId: payload.requestId,
            data: payload.columns,
            ...filterData,
            selectedUserData: selectedUserData,
        }));
        apiBaseInstance.instance
            .post(GET_MANAGER_HEATMAP, { ...payload, ...filterData, ...selectedUserData, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                dispatch(withType(FETCH_MANAGER_HEATMAP_DATA_SUCCESS, {
                    data: response.data,
                    request: payload
                }));
            })
            .catch(err => {
                dispatch(withType(FETCH_MANAGER_HEATMAP_DATA_FAILED, {
                    error: err
                }));
            });
    };
};

