import React from "react";
import RelationGraph from "../RelationGraph";
import { EmptyState } from "../../../../../../../../Packages/EmptyState";
import TopicCommentModal from "../TopicCommentModal";
import GraphLoader from "../../../../../../../../Components/ContentLoaders/GraphLoader";
export default class RenderForceGraph extends React.Component {
  state = {
    isModalVisible: false,
    topic: [],
    hasMore: false,
    current: 1,
    isFetching: false,
    commentsData: [],
  };

  handleScroll = () => {
    if (
      this.props.exploreTopicCommentsApi.data &&
      this.state.current <=
      this.props.exploreTopicCommentsApi.data.totalNumberOfDocuments / 10
    ) {
      this.onChangepagination();
    }
    this.setState({ isFetching: true });
  };

  onChangepagination = () => {
    var page = this.state.current;
    this.setState({
      current: page + 1,
    });
    if (
      this.props.exploreTopicCommentsApi.data &&
      this.props.exploreTopicCommentsApi.data.totalNumberOfDocuments <
      (this.state.current - 1) * 10
    ) {
      this.setState({
        hasMore: false,
        current: 1,
      });
    } else {
      let payload = this.getTopicCommmentsApiPayload(
        10,
        page * 10,
        this.state.topic
      );
      this.props.fetchExploreTopicComments(payload, (data) => {
        this.setState({
          ...this.state,
          commentsData: this.state.commentsData.concat(data),
        });
      });
    }
    this.setState({ isFetching: true });
  };

  getTopicCommmentsApiPayload = (size, from, topic) => {
    return {
      size: size,
      from: from,
      topic: topic,
      projectId: this.props.projectId,
      projectOrigin: this.props.projectOrigin,
      selectedQuestionId: this.props.selectedQuestionId,
    };
  };

  showCommentModal = (item, itemTarget) => {
    let name = itemTarget !== undefined ? item : item.target.__data__.name
    // let name = item
    var topics = [];
    if (itemTarget !== undefined) topics = [item, itemTarget];
    else {
      topics = [name];
    }

    let payload = this.getTopicCommmentsApiPayload(10, 0, topics);
    this.setState({
      isModalVisible: true,
      commentsData: [],
      hasMore: true,
      topic: topics,
      current: 1,
    });
    this.props.fetchExploreTopicComments(payload, (data) => {
      this.setState({
        ...this.state,
        commentsData: data,
      });
    });
    document.body.classList.add("modal-open");
  };

  nodesArrayGeneration() {
    var nodesArr = [];
    var nodeData = {};
    var dataLength = this.props.relationData
      ? this.props.relationData.length
      : 0;
    var links = [];
    var forceGraphData = {};
    for (var i = 0; i < dataLength; i++) {
      if (
        this.props.relationData[i].node !== "Other" &&
        this.props.relationData[i].value >= this.props.threshold
      ) {
        nodeData = {
          name: this.props.relationData[i].node,
          value: this.props.relationData[i].value,
          commentsCount: this.props.relationData[i].value,
          target: this.props.relationData[i].link,
          linkWidths: this.props.relationData[i].linkWidths,
        };
        nodesArr.push(nodeData);
      }
    }
    links = this.linksArrayGeneration(nodesArr);
    forceGraphData = { nodes: nodesArr, links: links };
    return forceGraphData;
  }

  linksArrayGeneration(nodes) {
    var links = [];
    const allowedLinks = this.props.allowedLinks || [];
    allowedLinks.forEach(link => {
      let source = nodes.find(node => node.name === link.source)
      let target = nodes.find(node => node.name === link.target)
      let width = link.width;
      let title = link.width
      if (source && target && width) {
        links.push({ source, target, width, title })
      }
    })
    return links;
  }

  findAllowedLinks = (source, target) => {
    const allowedLinks = this.props.allowedLinks;
    // eslint-disable-next-line
    const isAllowed = allowedLinks ? allowedLinks.find(link => {
      if (source.name === link.source || source.name === link.target) {
        if (target.name === link.source || target.name === link.target) {
          return true
        }
        return false
      }
    }
    ) : false
    if (isAllowed) return true
  }

  handleOk = () => {
    this.setState({
      ...this.state,
      isModalVisible: false,
    });
    document.body.classList.remove("modal-open");
    this.setState({ isFetching: true });
  };
  render() {
    const forceGraphData = this.nodesArrayGeneration();
    return (
      <>
        {this.props.props[this.props.props.question].exploreRelationDataApi.isProcessing || this.props.loading ?
          <GraphLoader height={300} width={600} />
          :
          <>
            <h2 class="head-barchart">Connections between themes</h2>
            {forceGraphData.links.length === 0 ||
              forceGraphData.nodes.length === 0 ? (
              <EmptyState emptyStateMessage={"No connection between comment themes"} />
            ) : (
              <div className="connection-diagram">
                <RelationGraph
                  question={this.props.question}
                  nodes={forceGraphData.nodes}
                  links={forceGraphData.links}
                  height="500"
                  width="900"
                  linkClick={this.showCommentModal}
                  nodeClick={this.showCommentModal}
                  hasMore={this.state.hasMore}
                />
                <TopicCommentModal
                  topic={
                    this.state.topic[1]
                      ? this.state.topic[0] + " <-> " + this.state.topic[1]
                      : this.state.topic[0]
                  }
                  isModalVisible={this.state.isModalVisible}
                  handleOkModal={this.handleOk}
                  handleScroll={this.handleScroll}
                  data={this.state.commentsData}
                  api={this.props.exploreTopicCommentsApi}
                />
              </div>
            )}
          </>
        }
      </>
    );
  }
}

