export const setSortOrderAndSortField = (state,payload)=>{
    if (payload.isFromFeedbackTab) {
        return {
            ...state,
            sortOrderFeedback: payload.sortOrder,
            sortFieldFeedback: payload.sortField
        }
    }
    else {
        return {
            ...state,
            sortOrder: payload.sortOrder,
            sortField: payload.sortField
        }
    }

}