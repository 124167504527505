import Axios from 'axios';
import * as actionTypes from '../../constants';
import { notification } from 'antd';
import constants from './constants';
import { DEFAULT_LIBRARY_URL } from '../../../../config/constants';
import { blobDownload } from '../../../utils/blobDownload';
import { NLPCommonReqBodies } from '../../../utils/actionOperations';

export function downloadDefaultTopics() {
    return dispatch => {
        dispatch({ type: actionTypes.DOWNLOAD_DEFAULT_TOPICS_START });
        Axios
            .get(DEFAULT_LIBRARY_URL,{...NLPCommonReqBodies()})
            .then(response => downloadSuccess(dispatch, response))
            .catch(e =>downloadFailed(dispatch,e));
    };
}

function downloadSuccess(dispatch, response) {
    blobDownload({data:response.data,fileName:constants.defaultFileName});
    dispatch({
        type: actionTypes.DOWNLOAD_DEFAULT_TOPICS_SUCCESS
    });
}

function downloadFailed(dispatch, error) {
    notification.error({
        message:constants.downloadingFailed,
        description:error.response&&error.response.data
    });

    dispatch({
        type: actionTypes.DOWNLOAD_DEFAULT_TOPICS_FAIL
    });
}