import React from "react";
import { Card } from 'react-bootstrap';
import constants from "./constants";
import { MenuCard } from "../../Components/MenuCard";
import { RouteHeader } from "../../Components/RouteHeader";
import "./index.scss";

class AdminDashBoard extends React.Component {
  getMenuItem = () =>
    constants.ROUTES.map((route) => (
      <li key={route.title}>
        <MenuCard
          onClick={this.onClickMenuItem}
          title={route.title}
          btnText={route.btnText}
          path={route.path}
        />
      </li>
    ));

  onClickMenuItem = (path) => this.props.history.push(path);

  render() {
    return (
      <React.Fragment>
        <RouteHeader
          {...this.props}
          navigationData={constants.navigationList}
        />
        <Card className="admin-content">
          <ul className="menu-grid org-card" children={this.getMenuItem()} />
        </Card>
      </React.Fragment>
    );
  }
}
export default AdminDashBoard;
