import {toast} from '../../../../utils/messageUtils';
import {
    update,
    makeKey,
    getSuccessResponse
} from '../../../../utils/reducerOperations';

export function activeUserRoleChangeSuccess(state, payload) {
    toast.success(payload.message);
    return {
        changeRoleApi: updateChangeRoleApi(state, payload),
        fetchUsersApi: updateFetchUsersApi(state, payload)
    };
}
function updateFetchUsersApi(state, payload) {
    return update(
        state.fetchUsersApi,
        makeKey(
            'data',
            update(state.fetchUsersApi.data, {
                Items: alterUserRole(
                    state.fetchUsersApi.data.Items,
                    payload.data.Attributes
                )
            })
        )
    );
}
function updateChangeRoleApi(state, payload) {
    return update(
        state.changeRoleApi,
        getSuccessResponse(payload)
    );
}
function alterUserRole(Items, attributes) {
    return Items.map(userInfo => {
        if (attributes.userId === userInfo.userId) {
            return updateUserInfo(userInfo, attributes);
        } else return userInfo;
    });
}
function updateUserInfo(info, attributes) {
    return update(info, {
        ...attributes
    });
}
