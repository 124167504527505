import constants from './constants'

export const setInitialState = (state, payload) => {
  const questionsSet = {...payload}
  return {
    ...state,
    ...getQuestionInitialState(questionsSet.data.data)
  };
  };
  
  const getQuestionInitialState=(questionSet)=>{
      let data = {}
      if(questionSet.length>0){
        for(const element of questionSet){
            data[element.questionId] = {...constants.initialState}
        }
      }
      return data
  }