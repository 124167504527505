import { deepCopy } from "../../../../../utils/deepCopy"
import { toast } from "../../../../../utils/messageUtils"

export const deleteStatement = (state, payload) => {
    let goals = deleteStatementToGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const deleteStatementToGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    let selectedStatements = []
    let questionsCount = getQuestionsCount(state, payload)
    if (questionsCount <= 1) {
        toast.error("Topic must have atleast one statement!")
    } else {
        toast.success("Question deleted successfully.")
        goalsCopy.forEach(item => {
            if (item.goalId === payload.goalId) {
                selectedStatements = item.questions[0].matrixQuestions.filter(
                    question => question.staticQuestionId !== payload.staticQuestionId
                )
            }
        })
        goalsCopy.forEach(goal => {
            if (goal.goalId === payload.goalId) {
                goal.questions[0].matrixQuestions = selectedStatements
            }
        })
    }
    return goalsCopy
}

const getQuestionsCount = (state, payload) => {
    let questionsCount = 0
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.goalId) {
            item.questions[0].matrixQuestions.forEach(
                question => questionsCount += 1
            )
        }
    })
    return questionsCount
}