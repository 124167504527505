import { Tooltip } from 'antd'
import React, { PureComponent } from 'react'
import constants from './constants'

class DownloadLinks extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    DownloadButton = () => {
        return (
            <div>
                {this.DownloadButtonContent("Download sample data collection file", this.downloadSampleDataCollection, true)}
                {!this.props.hideTopicLibrary&&this.DownloadButtonContent("Download sample topic library file", this.downloadSampleTopicLibrary)}
                {this.DownloadButtonContent("Download sample benchmark file", this.downloadBenchMarkFile)}
            </div>

        )
    }

    DownloadButtonContent = (title, onClick, firstButton) => {
        return      <div>

            {
                firstButton && <h3 >
                    {this.props.description}
                </h3>
            }
            <Tooltip overlayClassName="right-tooltip" placement="right" title={title}>
                <span
                    className="download-link"
                    onClick={onClick}
                    children={title}
                />
            </Tooltip>
        </div>
    }

    downloadSampleDataCollection = () => {
        let downloadLink = this.props.dataCollectionLink ? this.props.dataCollectionLink : constants.DATA_COLLECTION_LINK
        const link = document.createElement('a');
        link.href = downloadLink;
        link.click();
    }

    downloadSampleTopicLibrary = () => {
        const link = document.createElement('a');
        link.href = constants.TOPIC_LIBRARY_LINK;
        link.click();
    }

    downloadBenchMarkFile = () => {
        const link = document.createElement('a');
        link.href = constants.BENCHMARK_LINK;
        link.click();
    }

    render() {
        return (
            <div className="download-block__content">
                {this.DownloadButton()}
            </div>
        )
    }
}

export default DownloadLinks