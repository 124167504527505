
export const listViewersStarted = (state) => {
    return {
        ...state,
        listViewersApi: {
            isProcessing:true,
            data:null,
            error:false,
            message:''
        }
    }

}

export const listViewersSuccess = (state, payload) => {
    return {
        ...state,
        viewersList:payload.data.data.viewersList,
        listViewersApi: {
            isProcessing:false,
            data:payload.data.data,
            error:false,
            message: payload.message,
            searchText: payload.searchText
        }
    }

}

export const listViewersFailed = (state, payload) => {
    return {
        ...state,
        listViewersApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}
