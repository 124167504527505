import { 
  apiSuccessState, 
  apiFailureState
 } from "../../../../utils/apiStateUtils";

export function fetchRolesSuccess(state, payload) {
  return {
    ...state,
    fetchRolesApi:apiSuccessState(state.fetchRolesApi,payload.payload)
  }
}

export function fetchRolesStarted(state, payload) {
  return {
    ...state,
    fetchRolesApi:{
      ...state.fetchRolesApi,
      loading:true,
      error:false
    }
  }
}

export function fetchRolesFail(state, payload) {
  return {
    ...state,
    fetchRolesApi:apiFailureState(state.fetchRolesApi,payload)
  }
}
