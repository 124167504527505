import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";
export const changeReadinessTemplate = [
    {
        questionDimension: 'Change Readiness',
        type: 'Matrix',
        questionLabel: 'Change Readiness',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: `I am confident that the ERP project will succeed.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`I am confident that the ERP project will succeed.`)
        },
        {
            text: `My manager and peers speak positively about the ERP project.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`My manager and peers speak positively about the ERP project.`)

        }]
    }
]

