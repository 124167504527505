import {
    SET_SEARCH_TEXT_FOR_SURVEY_PAGE
} from "../../../constants";


export const setSearchTextForSurveyPage = (payload, cb) => {
    return (dispatch) => {
        dispatch({
            type: SET_SEARCH_TEXT_FOR_SURVEY_PAGE,
            searchText: payload
        });
    }
};