import { fetchFormConfigurations } from "../../inputFormConfigurations";
import { apiInitialState } from "../../../utils/apiStateUtils";

export default {
  processFilesApi: {
    loading: false,
  },
  reprocessFilesApi: apiInitialState(),
  inputBoxConfiguration: fetchFormConfigurations(["batchId"]),
  libraryFileApi: {
    loading: false,
  },
  hrisFileApi: apiInitialState(),
  commentsFileApi: {
    loading: false,
  },
  reprocessUploadApi: apiInitialState(),
  downloadDefaultTopicApi: { loading: false },
  getCsvColumnsApi: { loading: false },
  downloadCommentsApi: { loading: false },
  getBatchIdsApi: apiInitialState(),
  jobApprovalApi: apiInitialState(),
  batchApprovalApi: apiInitialState(),
  batchDeleteApi: apiInitialState(),
  fetchHrisFIleApi: apiInitialState(),
  currentBatchId: "",
  downloadUserCommentsApi: { loading: false },
};
