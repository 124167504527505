import React from "react";
import constants from "./constants";
import { CustomTable } from "../../../../../../../../Components/CustomTable";


export default class AllDriverTable extends React.Component {
  fetchTableColums = (columns) => {
    if (!this.props.trendData) {
      let filterData = columns.filter((item) => item.dataIndex !== "trend")
      return filterData;
    } else {
      return columns;
    }
  };
  render() {
    return (
      <div className="drivers-content">
        <div className="drivers-content__item">
          <div className="all-drivers">
            <h2>All drivers</h2>
            <CustomTable
              loading={this.props.loading}
              className="no-space"
              dataSource={this.props.tableData}
              columns={this.fetchTableColums(constants.tableColumnDrivers())}
              pagination={false}
            />
          </div>
        </div>
      </div>
    );
  }
}
