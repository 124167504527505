export const fetchFollowUpQuestionsReportStart = (state, payload) => {
    return {
        ...state,
        fetchFollowUpQuestionReportApi: {
            isProcessing: true,
            data: [],
            error: false,
            message: "",
        },
    };
};

export const fetchFollowUpQuestionsReportSuccess = (state, payload) => {
    return {
        ...state,
        fetchFollowUpQuestionReportApi: {
            isProcessing: false,
            data: payload.data && payload.data.data && payload.data.data.response,
            error: false,
            message: "",
        },
    };
};

export const fetchFollowUpQuestionsReportFailed = (state, payload) => {
    return {
        ...state,
        fetchFollowUpQuestionReportApi: {
            isProcessing: false,
            data: [],
            error: true,
            message: payload.error,
        },
    };
};