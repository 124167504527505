import { notification } from "antd";

export const toast = {
    success: message =>
        notification.success({
            message: message,
            placement: "topRight",
        }),
    error: message =>
        notification.error({
            message: message,
            placement: "topRight",
        }),
    info: message =>
        notification.info({
            message: message,
            placement: "topRight",
        }),
    warning: message =>
        notification.warn({
            message: message,
            placement: "topRight",
        })
};
