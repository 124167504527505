

export function getHomePath(orgId) {
  return "/dashboard";
  // if (getUserRole() === "Admin") return "/admin/home/organisations";
  // else if (getUserRole() === "Organisation Admin")
  //   return "/admin/home/organisations";
  // else return "/home/" + orgId + "/survey";
}

export function getProjectPath(orgId) {
  return "/home/"+orgId+"/survey";
}

export const getNewSurveyPagePath = (orgId) =>{
  return `/home/${orgId}/surveys/normalSurvey`
}


export function getUsersPath(orgId) {
  return "/admin/home/organizations/"+orgId+"/manageusers";
}
export function getPeoplePath(orgId) {
  return "/admin/home/organizations/"+orgId+"/people";
}
export function getOrganizationPath() {
  return "/admin/home/organizations";
}
export function getSettingsPagePath() {
  return "/admin/home/settings";
}
