import React from 'react';
import InnerHeader from '../InnerHeader';
class CombinedHeader extends React.Component {
    render() {
        return (
            <>
                <InnerHeader
                    test-name='combined-header'
                    history={this.props.history} />
            </>
        );
    }
}
export default CombinedHeader;
