import {
    update,
    makeKey,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';

export function activeUserRoleChangeFailure(state, payload) {
    return makeKey(
        'changeRoleApi',
        update(
            state.changeRoleApi,
            payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload)
        )
    );
}
