
import { getValueFromQueryString } from '../../../../utils/getValueFromUrl';
import * as actionTypes from '../../../constants';
import { generateAnswerSet } from './createAnswerSet';

export function updateAnswer(payload) {
    return {
        type: actionTypes.UPDATE_ANSWERS_IN_SURVEY_PAGE,
        payload
    };
};

export function updateCurrentIndex(payload, cb) {
    const channelType = getValueFromQueryString("SCT")
    if (channelType) {
        return (dispatch, getState) => {
            generateAnswerSet({ state: getState().SurveyAnsweringPageReducer, dispatch: dispatch, isFromUpdateAnswer: true, type: actionTypes.UPDATE_CURRENT_INDEX_DETAILS })
        }
    }
    else {
        return {
            type: actionTypes.UPDATE_CURRENT_INDEX_DETAILS,
        };
    }

};

export function updateTextAnswer(payload){
    return{
        type: actionTypes.UPDATE_TEXT_ANSWER_IN_SURVEY,
        payload
    }
}

export const handleChangeRanking = (payload, cb) => {
    return {
        type: actionTypes.HANDLE_CHANGE_RANKING,
        payload
    };
}

export const handleChangeRating = (payload, cb) => {
    return {
        type: actionTypes.HANDLE_CHANGE_RATING,
        payload
    };
}

export const handleChangeTableMatrix = (payload) => {
    return {
        type: actionTypes.HANDLE_CHANGE_TABLE_MATRIX,
        payload
    }
}

export const handleChangeNetPromoter = (payload, cb) => {
    return {
        type: actionTypes.HANDLE_CHANGE_NETPROMOTER,
        payload
    };
}
