export const newForgotPasswordStart = (state, payload) => {

    return {
        ...state,
        newForgotPasswordApi: {
            isProcessing: true,
            data: null,
            error: false,
            message: '',
            code: ''
        },
    };
};

export const newForgotPasswordSuccess = (state, payload) => {

    return {
        ...state,
        newForgotPasswordApi: {
            isProcessing: false,
            data: payload && payload.data,
            error: false,
            message: '',
            code: payload && payload.code
        },
    };
};

export const newForgotPasswordFailed = (state, payload) => {
    let errorResponse = payload && payload.response && payload.response.data ? payload.response.data : payload
    let message = errorResponse && errorResponse.message && errorResponse.message === 'Network Error' ? 'Network error' : errorResponse.message
    return {
        ...state,
        newForgotPasswordApi: {
            isProcessing: false,
            data: null,
            error: true,
            message: message,
            code: errorResponse && errorResponse.code ? errorResponse.code : 'networkError',
            reason: errorResponse && errorResponse.reason
        },
    };
};

export const newForgotPasswordClearState = (state, payload) => {
    return {
        ...state,
        newForgotPasswordApi: {
            isProcessing: false,
            data: null,
            error: false,
            message: '',
            code: '',
            reason: ''
        },
    };
};