import { fetchPayload } from "../../../utils/reducerOperations";
import {
    FETCH_STORYBOARD_PARTICIPATION_SUCCESS,
    FETCH_STORYBOARD_PARTICIPATION_START,
    FETCH_STORYBOARD_PARTICIPATION_FAILED
} from "../../constants";
import {
    storyboardParticipationFailed,
    storyboardParticipationStarted,
    storyboardParticipationSuccess
} from "./Helpers";
import { Default } from "./initialState";

const StoryboardParticipationReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case FETCH_STORYBOARD_PARTICIPATION_START:
            return storyboardParticipationStarted(state, payload);
        case FETCH_STORYBOARD_PARTICIPATION_SUCCESS:
            return storyboardParticipationSuccess(state, payload);
        case FETCH_STORYBOARD_PARTICIPATION_FAILED:
            return storyboardParticipationFailed(state, payload);
        default:
            return { ...state };
    }
};

export default StoryboardParticipationReducer;
