import {
  getApiStartState,
  getSuccessResponse,
  handleApiResponseError,
  handleNetworkError,
} from "../../../../utils/reducerOperations";
import { notification } from "antd";
import { RENAME_PROJECT_SUCCESS } from "./constants";

export function renameProjectStarted(state) {
  return {
    ...state,
    renameProjectApi: getApiStartState(),
  };
}

export function renameProjectSuccess(state, action) {
  const { response, requestPayload } = action;
  showSuccessNotification(requestPayload);
  return {
    ...state,
    renameProjectApi: getSuccessResponse(response),
  };
}

export function renameProjectFail(state, { response }) {
  const res =
    response.response && response.response.data
      ? response.response.data
      : response;
  notification.error({ message: res.message });
  return {
    ...state,
    renameProjectApi:
      response.response && response.response.data
        ? handleApiResponseError(res)
        : handleNetworkError(res),
  };
}

function showSuccessNotification(request) {
  notification.success({ message: RENAME_PROJECT_SUCCESS });
}
