import {
  apiSuccessState,
  apiFailureState,
} from "../../../../utils/apiStateUtils";

export function fetchAdminsSuccess(state, payload) {
  return {
    ...state,
    fetchAdminsApi: apiSuccessState(state.fetchAdminsApi, payload.payload),
  };
}

export function fetchAdminsStarted(state, payload) {
  return {
    ...state,
    fetchAdminsApi: {
      ...state.fetchAdminsApi,
      loading: true,
      error: false,
    },
  };
}

export function fetchAdminsFail(state, payload) {
  return {
    ...state,
    fetchAdminsApi: apiFailureState(state.fetchAdminsApi, payload),
  };
}
