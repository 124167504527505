import {
    makeKey,
    update,
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';
import {toast} from '../../../../utils/messageUtils';
export function addOrganizationFailure(state, payload) {
    let response = makeKey(
        'addOrganizationApi',
        update(
            state.addOrganizationApi,
            payload.response && payload.response.data
                ? handleApiResponseError(payload.response.data)
                : handleNetworkError(payload)
        )
    );
    toast.error(response.addOrganizationApi.message);
    return response;
}
