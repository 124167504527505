export const fetchStoryboardFilterStatusStart = (state, payload) => {
  return {
    ...state,
    storyboardFilterStatus: {
      isProcessing: true,
      error: false,
      reason: '',
      data: state.storyboardFilterStatus.data,
    },
  };
};

export const fetchStoryboardFilterStatusSuccess = (state, payload) => {
  let data = (payload.data &&
    payload.data.storyboardStatusData &&
    payload.data.storyboardStatusData._source) || {
    topStoryFilterStatus: '',
    filters: {},
    byScore: [],
    multipleSelect: []
  };
  return {
    ...state,
    storyboardFilterStatus: {
      isProcessing: false,
      error: false,
      reason: '',
      data: data,
    },
  };
};

export const fetchStoryboardFilterStatusFail = (state, payload) => {
  return {
    ...state,
    storyboardFilterStatus: {
      isProcessing: false,
      error: true,
      reason: payload.error,
      data: state.storyboardFilterStatus.data,
    },
  };
};

export const clearStoryboardFilterStatus = (state) => {
  return {
    ...state,
    storyboardFilterStatus: {
      isProcessing: false,
      error: false,
      reason: '',
      data: { topStoryFilterStatus: '', filters: {}, byScore: [], multipleSelect: [] },
    },
  };
};

export const changeStoryboardFilter = (state, payload) => {
  return {
    ...state,
    ...payload.data,
  };
};
