import React from 'react';
import Footer from '../NewFooter'
import './index.scss';
export default function () {
    return (
        <>
            <Footer />
        </>
    );
}

// eslint-disable-next-line
const redirectToReleaseUpdates = () => {
    window.open('https://www.culturebie.com/product-release-updates')//do not update
}