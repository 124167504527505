import React from "react";
import Dropzone from "react-dropzone";
import "./index.scss";
import SVGIcons from '../../Components/SVGIcons';
class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileNames: [],
    };
  }

  handleDrop = (acceptedFiles) => {
    let supportedFormats = [
      "application/vnd.ms-excel",
      "text/plain",
      "text/csv",
      "text/tsv",
    ];
    if (supportedFormats.indexOf(acceptedFiles[0].type) > -1) {
      this.props.handleFileUpload(acceptedFiles);
    } else {
      alert("Not supported format");
    }
  };

  // eslint-disable-next-line max-lines-per-function
  render() {
    return (
        <div
        className="bulk-upload-container"
      > 
      
      <Dropzone onDrop={this.handleDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <section>
            {!this.props.fileToUpload ? (
              <div className="drop_area" {...getRootProps()}>
                 <div className="upload-icon">
                 <SVGIcons type={'SVG-upload'} />
                </div>
                <input {...getInputProps()} />

                <p > 
                  Drag 'n' drop csv file here, or click to select files
                </p>
                <ul className="mandatory-fields">
                  <li>
                    {this.props.contentLiOne}
                  </li>
                  <li>{this.props.contentLiTwo}</li>
                </ul>
              </div>
            ) : (
              <span className="upload-file">
                {this.props.fileToUpload[0].name}
                <span
                  className="close-icon"
                  onClick={() => this.props.handleFileUpload(null)}
                >
                  <SVGIcons type={'SVG-cross'} />
                </span>
              </span>
            )}
          </section>
        )}
      </Dropzone>
      </div>
    );
  }
}
export default FileUpload;
