import { withType } from '../../../../utils/actionOperations';
import { UPDATE_HEATMAP_COMPARISON_LEVEL, UPDATE_HEATMAP_SCORE_LEVEL } from '../../../constants';

export * from './heatmapHeadersAction';
export * from './heatmapQuestionsAction';
export * from './heatmapTableAction';
export * from './heatmapResetFetchDataKeyAction'

export const updateScoreLevel = (params) => {
  return withType(UPDATE_HEATMAP_SCORE_LEVEL, {
    data: params.scoreLevel,
  });
};

export const updateComparisonLevel = (params) => {
  return withType(UPDATE_HEATMAP_COMPARISON_LEVEL, {
    data: params.comparisonLevel,
  });
};
