import {
  FETCH_DASHBOARD_EXPLORE_FILTER_START,
  FETCH_DASHBOARD_EXPLORE_FILTER_SUCCESS,
  FETCH_DASHBOARD_EXPLORE_FILTER_FAILED,
  CHANGE_DASHBOARD_EXPLORE_SEARCH_FILTER
} from "../../../constants";
import { DASHBOARD_FILTER_URL } from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
import { getAllDataFromUrlToken, getDataFromUrlToken } from "../../../../utils/urlDataStore";
import { withType } from "../../../../utils/actionOperations";
import { getURLFilterAndByScore } from "../../../../utils/byScoreFilterUtils";
import { URL_DATA } from "../../../../../config/urlDataConstants";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchExploreDashboardFilters = ({
  minCommentsCount = 1,
  selectedColumns: activeFilters = [],
  setFilter,
  dimensions,
  topics,
  selectedQuestionId,
  filters,
  currentTab
}) => {
  let urlParams = getAllDataFromUrlToken();
  let urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER) || {};
  if (filters) {
    urlFilter = filters.filters
  }
  let filterData = getURLFilterAndByScore(urlFilter)
  let params = {
    ...urlParams.treeData,
    ...urlParams.orgDetails,
    ...urlParams.projectDetails,
    filterCountLimit: minCommentsCount,
    dimensions: dimensions ? dimensions : undefined,
    ...filterData,
    topics: topics ? topics : undefined,
    selectedQuestionId
  };
  // if (!fetchExploreDashboardFilters) {
  //   fetchExploreDashboardFilters = false;
  // }
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch({
      type: FETCH_DASHBOARD_EXPLORE_FILTER_START, payload: {
        selectedQuestionId: selectedQuestionId
      }
    });
    apiBaseInstance.instance
      .post(DASHBOARD_FILTER_URL, { ...params, ...getProjectIdAndOrgId(params), currentTab })
      .then((res) => fetchFilterSuccess(dispatch, { res, activeFilters }, params))
      .catch((err) => fetchFilterFail(dispatch, err));
  };
};

function fetchFilterSuccess(dispatch, { res, activeFilters }, params) {
  const data = res && res.data && res.data.data && res.data.data.filters;
  return dispatch({
    type: FETCH_DASHBOARD_EXPLORE_FILTER_SUCCESS,
    payload: { data: data, selectedQuestionId: params.selectedQuestionId },
  });
}

function fetchFilterFail(dispatch, err) {
  return dispatch({
    type: FETCH_DASHBOARD_EXPLORE_FILTER_FAILED,
    payload: { error: err.message },
  });
}


export const changeExploreDashboardSearchFilter = (payload) => {
  return (dispatch) => {
    dispatch(withType(CHANGE_DASHBOARD_EXPLORE_SEARCH_FILTER, payload));
  };
};