import {
    SET_SELECTED_QUESTION_TOPIC
} from '../../../constants';
import { withType } from '../../../../utils/actionOperations';

export const setQuestionTopic = (payload) => {
    return dispatch => {
        dispatch(withType(SET_SELECTED_QUESTION_TOPIC, { ...payload }));
    }

};
