import {
    handleApiResponseError,
    handleNetworkError
} from '../../../../utils/reducerOperations';

export function setConfig(state, action) {
    console.log('Goals', action.config.goals)
    console.log('Questions', action.config.questions)
    return {
        ...state,
        config: {
            ...state.config,
            ...action.config,
        },
        uploadImageApi: {
            loading: false,
            fileUrl: null,
            error: false
        }
    };
}

export function getProjectConfigStart(state, action) {
    return {
        ...state,
        getProjectConfigApi: {
            ...state.getProjectConfigApi,
            isProcessing: true,
            error: false,
            data: null
        }
    };
}

export function getProjectConfigSuccess(state, action) {
    return {
        ...state,
        getProjectConfigApi: {
            ...state.getProjectConfigApi,
            isProcessing: false,
            error: false,
            data: 'sucess'
        }
    };
}

export function getProjectConfigfail(state, action) {
    return {
        ...state,
        getProjectConfigApi:
            (action.response
                && action.response.response
                && action.response.response.data
                ? handleApiResponseError(action.response.response.data)
                : handleNetworkError(action.response))
    };
}