import { InitialState } from "./InitialState";
import {
  MANAGE_EXPORT_GENERATE_REPORT_MAIL_STARTED,
  MANAGE_EXPORT_GENERATE_REPORT_MAIL_SUCCESS,
  MANAGE_EXPORT_GENERATE_REPORT_MAIL_FAILED,
  MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_STARTED,
  MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_SUCCESS,
  MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_FAILED,
  EXPORT_DASHBOARD_PPT_START,
  EXPORT_DASHBOARD_PPT_SUCCESS,
  EXPORT_DASHBOARD_PPT_FAIL,
  EXPORT_PPT_STATUS_FAIL,
  EXPORT_PPT_STATUS_SUCCESS,
  EXPORT_PPT_STATUS_START,
} from "../../constants";
import {
  generateReportMailStart,
  generateReportMailSuccess,
  generateReportMailFailed,
  downloadUserCommentStart,
  downloadUserCommentSuccess,
  downloadUserCommentFailed,
  downloadDashboardPPTStart,
  downloadDashboardPPTSuccess,
  downloadDashboardPPTFail,
  downloadPptStatusSuccess,
  downloadPptStatusStart,
  downloadPptStatusFail,
} from "./Helper";

const NewDAshboardGenerateReportMail = (state = InitialState(), payload) => {
  switch (payload.type) {
    case MANAGE_EXPORT_GENERATE_REPORT_MAIL_STARTED:
      return generateReportMailStart(state);
    case MANAGE_EXPORT_GENERATE_REPORT_MAIL_SUCCESS:
      return generateReportMailSuccess(state, payload);
    case MANAGE_EXPORT_GENERATE_REPORT_MAIL_FAILED:
      return generateReportMailFailed(state, payload);
    case MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_STARTED:
      return downloadUserCommentStart(state);
    case MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_SUCCESS:
      return downloadUserCommentSuccess(state);
    case MANAGE_EXPORT_DOWNLOAD_USER_COMMENTS_FAILED:
      return downloadUserCommentFailed(state);
    case EXPORT_DASHBOARD_PPT_START:
      return downloadDashboardPPTStart(state);
    case EXPORT_DASHBOARD_PPT_SUCCESS:
      return downloadDashboardPPTSuccess(state);
    case EXPORT_DASHBOARD_PPT_FAIL:
      return downloadDashboardPPTFail(state);
    case EXPORT_PPT_STATUS_START:
      return downloadPptStatusStart(state);
    case EXPORT_PPT_STATUS_SUCCESS:
      return downloadPptStatusSuccess(state);
    case EXPORT_PPT_STATUS_FAIL:
      return downloadPptStatusFail(state);
    default:
      return { ...state };
  }
};

export default NewDAshboardGenerateReportMail;
