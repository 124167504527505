import { deepCopy } from "../../../../../utils/deepCopy"
import { toast } from "../../../../../utils/messageUtils"

export const deleteOpenEndedQuestion = (state, payload) => {
    let goals = deleteOpenEndedQuestionFromGoals(state.goals, payload)
    toast.success("Question deleted successfully")
    return {
        ...state,
        goals: goals
    }
}

const deleteOpenEndedQuestionFromGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    let selectedOpenEndedQuestion = []
    goalsCopy.forEach(item => {
        if (item.goalId === "comments") {
            selectedOpenEndedQuestion = item.questions.filter(
                question => question.staticQuestionId !== payload.staticQuestionId
            )
        }
    })
    goalsCopy.forEach(goal => {
        if (goal.goalId === "comments") {
            goal.questions = selectedOpenEndedQuestion
        }
    })
    return goalsCopy
}