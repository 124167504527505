import {
    makeKey,
    update,
    getSuccessResponse
} from '../../../../utils/reducerOperations';
export function fetchSurveyTableSuccess(state, payload) {
    return makeKey(
        'fetchSurveyTableApi',
        update(state.fetchSurveyTableApi, getSuccessResponse(payload))
    );
}
