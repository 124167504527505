import { GET_USER_PROFILE_URL } from "../../../../config/constants";
import { ApiBase } from "../../../utils/apiBase";
import {
  GET_USER_PROFILE_STARTED,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILED,
} from "../../constants";
import { withType } from "../../../utils/actionOperations";
import { ClearLocalStorage } from "../../../utils/localStorgeOperations";
export const getUserProfile = (payload, cb) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(GET_USER_PROFILE_STARTED, {}));
    apiBaseInstance.instance
      .post(GET_USER_PROFILE_URL, { ...payload })
      .then((res) => {
        dispatch(withType(GET_USER_PROFILE_SUCCESS, res.data));
        cb && cb(true)
      })
      .catch((err) => {
        dispatch(withType(GET_USER_PROFILE_FAILED, err));
        payload.currentTab === "login" && ClearLocalStorage()
        cb && cb(false)
      });
  };
};
