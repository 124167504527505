import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const inclusionTemplate = [
    {
        questionDimension: 'Inclusion',
        type: 'Matrix',
        questionLabel: 'Inclusion',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [
            {
                text: 'I consider some co-workers as true friends.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I consider some co-workers as true friends.'),

            },
            {
                text: 'People care about me at work.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('People care about me at work.'),

            },
            {
                text: 'I feel like I belong at my organization.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I feel like I belong at my organization.'),

            },
            {
                text: 'People at my organization are like one, big family.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('People at my organization are like one, big family.'),
            }]
    }
]



