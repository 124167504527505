import productImgOne from '../../public/img/surveyicon.png';
import productImgTwo from '../../public/img/dashboardicon.png';
import productImgThree from '../../public/img/advancedicon.png';
import productImgFour from '../../public/img/cleanicon.png';
export default {
  products_heading: 'Products',
  products_products: [{
    label: 'Employee Listening',
    img: productImgOne,
    isActive: true,
    text: "Collect and analyze employee survey data to get \n            insights that inform talent strategy and action plans.",
    subMenu: ['Employee Experience', 'Leadership 360', 'Performance'],
    subMenuContent: [{
      label: 'Employee Experience',
      text: "We perform employee surveys and analyze \n                    the data to develop action plans that improve\n                     employee engagement,culture, and performance"
    }, {
      label: 'Leadership 360',
      text: "We conduct 360 feedback surveys that result \n                    in insights for leadership and manager\n                     development"
    }, {
      label: 'Performance',
      text: "Collect, analyze, and act on  survey done \n                    for individuals performance assessment and \n                    feedback culture, and pulse surveys"
    }]
  }, {
    label: 'Talent Dashboards',
    img: productImgTwo,
    isActive: false,
    text: "Visualize talent and culture KPIs (Key Performance \n                Indicators) from HR data",
    subMenu: ['HR KPI Dashboard', 'Diversity & Inclusion Dashboard', 'Talent Acquisition Dashboard '],
    subMenuContent: [{
      label: 'HR KPI Dashboard',
      text: "Use standard or custom KPIs and take action\n                     to assess performance of HR  and talent processes\n                      real-time"
    }, {
      label: 'Diversity & Inclusion Dashboard',
      text: 'Do a real time deep-dive into KPIs' + ' that give insights about status and issues' + ' around diversity and inclusion'
    }, {
      label: 'Talent Acquisition Dashboard',
      text: "Do a real time deep-dive into KPIs that \n                    give insights about the performance of talent \n                    acquisition processes (sourcing,screening, \n                        selection, and offer acceptance)"
    }]
  }, {
    label: 'Advanced Analytics',
    img: productImgThree,
    isActive: false,
    text: 'Build predictive models ' + 'and draw insights from ' + 'multiple data sources.',
    subMenu: ['Predictive Analysis', 'Organization Network Analysis', 'Text Data Analysis'],
    subMenuContent: [{
      label: 'Predictive Analysis',
      text: 'Build predictive models to' + 'answer specific questions across' + ' the talent life-cycle (attract, ' + 'select, develop, engage, and retain)'
    }, {
      label: 'Organization Network Analysis',
      text: 'Understand how employees and' + ' functions interact with each other ' + 'to inform work design and inclusion issues'
    }, {
      label: 'Text Data Analysis',
      text: 'Analyze comments from survey data' + ' and other sources to surface topics and sentiments'
    }]
  }, {
    label: 'HR Data Cleaning',
    img: productImgFour,
    isActive: false,
    text: 'Prepare HR data ' + 'for integration and analysis',
    subMenu: ['Automated Data Quality Audits', 'Automated Data Transformation'],
    subMenuContent: [{
      label: 'Data Quality Audits',
      text: 'Select the rules for the HR ' + 'data quality audit and get automated' + ' reports on the data quality issues'
    }, {
      label: 'Data Preparation',
      text: 'Automate the data integrations ' + 'of multiple reports, and transformations' + ' that you need for analysis and visualizations '
    }]
  }]
};