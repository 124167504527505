import React, { Component } from 'react';
import "./index.scss";
import SVGIcons from "../../../../../../Components/SVGIcons";
class ErrorBlock extends Component {

    componentDidUpdate() {
        if (!this.props.hideErrorBlock) {
            this.props.manageScroller && this.props.manageScroller(true);
        }
    }

    render() {
        return (
            <>
                {!this.props.hideErrorBlock && <div className='survey-error'>
                    <SVGIcons type={'SVG-error'} />
                    <span>{this.props.errorText ? this.props.errorText : "Oops! Please make a selection"}</span>
                </div>
                }
            </>

        );
    }
}

export default ErrorBlock;
