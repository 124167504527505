import {
    SURVEY_PARTICIPANT_DOWNLOAD_FAIL,
    SURVEY_PARTICIPANT_DOWNLOAD_START,
    SURVEY_PARTICIPANT_DOWNLOAD_SUCCESS
} from '../../constants';
import { ApiBase } from '../../../utils/apiBase';
import { notification } from 'antd';
import { DOWNLOAD_PARTICIPANTS_URL } from '../../../../config/constants';

export function downloadParticipantLinks(payload, callback) {
    return dispatch => {
        dispatch({ type: SURVEY_PARTICIPANT_DOWNLOAD_START });
        const axios = new ApiBase().instance;
        axios.post(DOWNLOAD_PARTICIPANTS_URL, payload)
            .then(downloadCsvSuccess.bind(null, dispatch, payload, callback))
            .catch(downloadCsvFailed.bind(null, dispatch));
    };
};


function downloadCsvSuccess(dispatch, payload, callback, response) {
    const csvData = response.data.data;
    if (!csvData.fileUrl)
        notification.info({ message: csvData.message });
    else {
        const a = document.createElement('a')
        a.href = csvData.fileUrl;
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }
    dispatch({
        type: SURVEY_PARTICIPANT_DOWNLOAD_SUCCESS,
        response: response.data
    });
    callback && callback()
}

function downloadCsvFailed(dispatch, error) {
    notification.error({ message: 'Failed' });
    dispatch({
        type: SURVEY_PARTICIPANT_DOWNLOAD_FAIL,
        response: error
    });
}
