export const managerTemplate = [
    {
        questionDimension: 'My Manager',
        type: 'Matrix',
        questionLabel: 'My Manager',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: 'My manager gives me useful feedback that helps me improve.', value: '', isSelected: true
        },
        {
            text: 'My manager helps me set clear goals and priorities for my work.', value: '', isSelected: true

        },
        {
            text: 'My manager cares about me as a person, not just as an employee.', value: '', isSelected: true

        },
        {
            text: 'My manager appreciates extra efforts.', value: '', isSelected: false

        }]
    }
]


