import { fetchPayload } from "../../../utils/reducerOperations";
import * as helper from "./Helpers";
import {
  FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_FAIL,
  FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_START,
  FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_SUCCESS,
  SET_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_FETCHED_FALSE,
  SET_NEW_DASHBOARD_EXPLORE_OUTLIERS_FILTERS
} from "../../constants";
import { InitialState } from "./InitialState";

const FetchExploreOutliersDataReducer = (state = InitialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_START:
      return helper.fetchExploreOutliersDataStart(state, payload);
    case FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_SUCCESS:
      return helper.fetchExploreOutliersDataSuccess(state, payload);
    case FETCH_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_FAIL:
      return helper.fetchExploreOutliersDataFailed(state, payload);
    case SET_NEW_DASHBOARD_EXPLORE_OUTLIERS_DATA_FETCHED_FALSE:
      return helper.setOutliersDataFetchedKeyasFalse(state,payload);
      case SET_NEW_DASHBOARD_EXPLORE_OUTLIERS_FILTERS :
        return helper.setOutliersFilter(state,payload)    
    default:
      return { ...state };
  }
};

export default FetchExploreOutliersDataReducer;