import * as actionTypes from '../../constants';
import { ApiBase } from '../../../utils/apiBase';
import { mapKeysForApi } from '../../../utils/projectsMapKeys';
import { PUBLISH_PROJECT_URL } from '../../../../config/constants';
import {
    getValueFromQueryString
} from '../../../utils/getValueFromUrl';
import { DISTRIBUTIONS_PATH } from '../../../utils/routePath';
import { getDomainName } from "../../../utils/getDomainName";
import { getProjectIdAndOrgId } from '../../../utils/apiUtils';

const redirectUrl = DISTRIBUTIONS_PATH + '?projectId=';

export function publishProject(payload, callback) {
    payload = mapKeysForApi(payload);
    payload = formatRequestBlody(payload);
    payload.sourceDomainName = getDomainName()
    return dispatch => {
        dispatch({ type: actionTypes.EDIT_PROJECT_PUBLISH_START });
        const axios = new ApiBase().instance;
        axios.post(PUBLISH_PROJECT_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(publishSurveySuccess.bind(null, dispatch, payload, callback))
            .catch(err => dispatch({
                type: actionTypes.EDIT_PROJECT_PUBLISH_FAIL,
                response: err
            }));
    };
};

function publishSurveySuccess(dispatch, payload, callback, response) {
    const projectId = getValueFromQueryString('projectId');
    if (window.location.href.includes('/edit-project')) {
        if (payload.projectStatus === "scheduled") {
            const projectPath = "/home/" + payload.organizationId + "/survey/projects/edit-project?projectId=";
            window.location.href = projectPath + projectId + "&active=true";
        } else {
            window.location.href = redirectUrl + projectId + "&active=true";
        }
    }
    callback && callback();
    return dispatch({
        type: actionTypes.EDIT_PROJECT_PUBLISH_SUCCESS,
        response,
        requestPayload: payload
    });
};

function formatRequestBlody(payload) {
    payload.projectStatus = payload.status ? payload.status : 'published';
    payload.questionSet = payload.questionSet
        .filter((item) => {
            if (item.isDefault) {
                if (item.questions[0].matrixQuestions.length) {
                    return true;
                }
            } else {
                if (item.questions.length) return true
            }
            return false
        })
    payload.questionCount = getQuestionCount(payload.questionSet)
    payload.blockCount = payload.questionSet.length;
    return payload;
};

function getQuestionCount(questionSet) {
    let questions = [];
    let matrixCount = 0;
    if (questionSet && questionSet.length) {
        questionSet.forEach(item => {
            if (item.isDefault) {
                matrixCount = matrixCount + item.questions[0].matrixQuestions.length
            } else {
                questions = questions.concat(item.questions)
            }
        })
    }
    questions = questions.filter(item => item.type !== "Page Break")
    const count = questions.length + matrixCount;
    return count
}