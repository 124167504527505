import {
  makeKey,
  update,
  handleApiResponseError,
  handleNetworkError,
} from "../../../../utils/reducerOperations";
import { toast } from "../../../../utils/messageUtils";
export function deleteOrganizationFailed(state, payload) {
  toast.error("Delete organization failed");
  return makeKey(
    "deleteOrganizationApi",
    update(
      state.deleteOrganizationApi,
      payload.response && payload.response.data
        ? {
            ...handleApiResponseError(payload.response.data),
            organizationId: payload.organizationId,
          }
        : {
            ...handleNetworkError(payload),
            organizationId: payload.organizationId,
          }
    )
  );
}
