import { deepCopy } from '../../../../utils/deepCopy';

export const setOutlierData = (state, payload) => {
  let {
    outcomeVariable,
    overallScore,
    headerNegative,
    headerPositive,
    outlierScoreNegative,
    outlierScorePositive,
    outlierScoreTextNegative,
    outlierScoreTextPositive,
    segmentTotalRespondentsNegative,
    segmentTotalRespondentsPositive
  } = getRightSectionData(deepCopy(payload.data));
  if (outcomeVariable) {
    let positiveLeftSectionData = getLeftSectionData({
      outcomeVariable,
      header: headerPositive,
      outlierScoreText: outlierScoreTextPositive,
      isPositiveOutlier: true,
      segmentTotalRespondents: segmentTotalRespondentsPositive
    });
    let negativeLeftSectionData = getLeftSectionData({
      outcomeVariable,
      header: headerNegative,
      outlierScoreText: outlierScoreTextNegative,
      isPositiveOutlier: false,
      segmentTotalRespondents: segmentTotalRespondentsNegative
    });
    return {
      ...state,
      leftSectionData: {
        ...state.leftSectionData,
        positiveData: { title: positiveLeftSectionData.title, mainText: positiveLeftSectionData.mainText, details: positiveLeftSectionData.details },
        negativeData: { title: negativeLeftSectionData.title, mainText: negativeLeftSectionData.mainText, details: negativeLeftSectionData.details }
      },
      rightSectionData: {
        ...state.rightSectionData,
        positiveData: { header: headerPositive, outlierScoreText: outlierScoreTextPositive, outlierScore: Math.round(outlierScorePositive * 100), overallScore: Math.round(overallScore), overallScoreText: 'All respondents', },
        negativeData: { header: headerNegative, outlierScoreText: outlierScoreTextNegative, outlierScore: Math.round(outlierScoreNegative * 100), overallScore: Math.round(overallScore), overallScoreText: 'All respondents', },
      },
    };
  }
};

const getTotalRespondedntsOfSegment = (data, segment, outcome) => {
  let totalRespondends = 0
  data.forEach(item => {
    if (item.segment === segment && item.dimension === outcome) {
      totalRespondends = item.size
    }
  })
  return totalRespondends
}

const getRightSectionData = (data) => {
  const { surveyDetails, surveyReport, outlierData } = { ...data };
  const { outcomeVariable } = surveyDetails;
  let outcomeTopicData,
    overallScore,
    outlierPositive,
    outlierNegative,
    headerNegative,
    outlierScoreTextNegative,
    outlierScoreNegative,
    segmentTotalRespondentsPositive,
    headerPositive,
    outlierScoreTextPositive,
    outlierScorePositive,
    segmentTotalRespondentsNegative;
  if (outcomeVariable) {
    outcomeTopicData = surveyReport.dimensionTable.find(
      (item) => item.item === outcomeVariable
    );
    overallScore = outcomeTopicData.favScore;
    outlierPositive = outlierData.positiveOutliers
        .filter((item) => item.dimension === outcomeVariable)
    if (outlierPositive && Array.isArray(outlierPositive) && outlierPositive.length > 0) {
      outlierPositive = outlierPositive.reduce((previousOutlier, currentOutlier) =>
          previousOutlier.size > currentOutlier.size
            ? previousOutlier
            : currentOutlier
        );
    }
    outlierNegative = outlierData.negativeOutliers
        .filter((item) => item.dimension === outcomeVariable)
    if (outlierNegative && Array.isArray(outlierNegative) && outlierNegative.length > 0) {
      outlierNegative = outlierNegative.reduce((previousOutlier, currentOutlier) =>
            previousOutlier.size > currentOutlier.size
              ? previousOutlier
              : currentOutlier
          );
        }

    let positiveOutliersDataList = outlierData.positiveOutliers
    let negativeOutliersDataList = outlierData.negativeOutliers
    segmentTotalRespondentsNegative = getTotalRespondedntsOfSegment(negativeOutliersDataList, outlierNegative.segment, outcomeVariable)
    segmentTotalRespondentsPositive = getTotalRespondedntsOfSegment(positiveOutliersDataList, outlierPositive.segment, outcomeVariable)
    headerNegative = outlierNegative.type;
    outlierScoreNegative = outlierNegative.favScore;
    outlierScoreTextNegative = outlierNegative.segment;
    headerPositive = outlierPositive.type;
    outlierScoreTextPositive = outlierPositive.segment;
    outlierScorePositive = outlierPositive.favScore;
  }
  return {
    outcomeVariable,
    overallScore,
    headerNegative,
    headerPositive,
    outlierScoreNegative,
    outlierScorePositive,
    outlierScoreTextNegative,
    outlierScoreTextPositive,
    segmentTotalRespondentsNegative,
    segmentTotalRespondentsPositive
  };
};

const getLeftSectionData = ({
  outcomeVariable,
  header,
  outlierScoreText,
  isPositiveOutlier,
  segmentTotalRespondents
}) => {
  let title, mainText;
  let details =
    'These results are not by chance—our statistical algorithm has verified them. Try to find out what is going so well with these employees by checking comments, exploring question scores, and just talking to them.';
  if (outcomeVariable === 'Engagement') {
    if (isPositiveOutlier) {
      title = 'Very engaged group';
      mainText = `People in the ${outlierScoreText} (${header}) segment (${segmentTotalRespondents} respondents) had significantly higher engagement than the rest of the employees!`;
    } else {
      details = 'These results are not by chance—our statistical algorithm has verified them. Try to find out what areas can be improved for these employees by checking comments, exploring question scores, and just talking to them.'
      title = 'Very disengaged group';
      mainText = `People in the ${outlierScoreText} (${header}) segment (${segmentTotalRespondents} respondents) had significantly lower engagement than the rest of the employees.`;
    }
  } else {
    if (isPositiveOutlier) {
      title = `Positive group on ${outcomeVariable}`;
      mainText = `People in the ${outlierScoreText} (${header}) segment (${segmentTotalRespondents} respondents) scored significantly higher than everyone else on ${outcomeVariable}!`;
    } else {
      details = 'These results are not by chance—our statistical algorithm has verified them. Try to find out what areas can be improved for these employees by checking comments, exploring question scores, and just talking to them.'
      title = `Negative group on ${outcomeVariable}`;
      mainText = `People in the ${outlierScoreText} (${header}) segment (${segmentTotalRespondents} respondents) scored significantly lower than everyone else on ${outcomeVariable}!`;
    }
  }
  return { title, mainText, details };
};
