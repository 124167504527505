import Admin from "./Admin";
import { connect } from "react-redux";
import { downloadDefaultTopics } from "../../../../redux/actions/NLPAdminAction/downloadDefaultTopics";
import {
  fetchBatchIds,
  clearFormData,
  deleteBatchId,
  uploadCommentFile,
  processFiles,
  reProcessFiles,
  updateFields,
  downloadSampleComments,
  uploadTopicLibrary,
  approveJobRequest,
  downloadUserComments,
  batchApproveJobsRequest,
  batchDeleteJobsRequest,
  setBatchIds,
  uploadReprocessFile,
  getCsvColumns,
  fetchHRISFile,
  uploadHrisFile,
  clearTopicFile,
} from "../../../../redux/actions/NLPAdminAction";
import { getSurveys } from "../../../../redux/actions/AdminSurveyListAction";
import {
  addCommentsJob,
  bulkUploadStart,
  getPreSignedURL,
  uploadFile,
} from "../../../../redux/actions";
import { setProjectsCount } from "../../../../redux/actions/MyProjectsAction";

function mapStateToProps(state) {
  return { ...state.AdminReducer };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSurveys: (payload) => dispatch(getSurveys(payload)),
    updateFields: (payload) => dispatch(updateFields(payload)),
    setBatchIds: (payload) => dispatch(setBatchIds(payload)),
    uploadCommentFile: (file) => dispatch(uploadCommentFile(file)),
    processFiles: (payload, cb) => dispatch(processFiles(payload, cb)),
    getCsvColumns: (payload) => dispatch(getCsvColumns(payload)),
    reProcessFiles: (payload) => dispatch(reProcessFiles(payload)),
    clearFormData: () => dispatch(clearFormData()),
    downloadDefaultTopics: () => dispatch(downloadDefaultTopics()),
    fetchBatchIds: (payload) => dispatch(fetchBatchIds(payload)),
    deleteBatchId: (id,cb) => dispatch(deleteBatchId(id,cb)),
    downloadSampleComments: () => dispatch(downloadSampleComments()),
    uploadTopicLibrary: (file) => dispatch(uploadTopicLibrary(file)),
    uploadHrisFile: (file) => dispatch(uploadHrisFile(file)),
    uploadReprocessFile: (file) => dispatch(uploadReprocessFile(file)),
    approveJobRequest: (doc) => dispatch(approveJobRequest(doc)),
    downloadUserComments: (payload) => dispatch(downloadUserComments(payload)),
    batchApproveJobsRequest: (payload) =>
      dispatch(batchApproveJobsRequest(payload)),
    batchDeleteJobsRequest: (payload) =>
      dispatch(batchDeleteJobsRequest(payload)),
    fetchHrisFile: (params) => dispatch(fetchHRISFile(params)),
    addCommentsJob: (params, next) => dispatch(addCommentsJob(params, next)),
    bulkUploadStart: (params) => dispatch(bulkUploadStart(params)),
    getPreSignedURL: (params, next) => dispatch(getPreSignedURL(params, next)),
    uploadFile: (params, next) => dispatch(uploadFile(params, next)),
    setProjectsCount: () => dispatch(setProjectsCount()),
    clearTopicFile: () => dispatch(clearTopicFile()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
