import React from "react";
import TableLoader from "../ContentLoaders/TableLoader";
import { EmptyState } from "../../Packages/EmptyState";
import { Table, Modal } from "antd";
import Icon from '@ant-design/icons';
import logsConstants from "../../Routes/Logs/constants";
import {rolesConstants} from "./constants";
import userConstants from "./constants";
import addAdminConstants from "../../Routes/AddAdmin/constants";
import mailTemplateConstants from "./constants";

export function ListData(props) {
  if (props.fetchApi.loading || props.fetchApi.loading === null)
    return <TableLoader />;
  if (props.fetchApi.error) return getMessage(props.fetchApi, props.listType);
  if (checkRole(props)) return getMessage(props.fetchApi, "role");
  if (checkUsers(props)) return getMessage(props.fetchApi, "users");
  if (checkLog(props)) return getMessage(props.fetchApi, "log");
  if (checkMailTemplate(props))
    return getMessage(props.fetchApi, "mailTemplate");
  if (checkAddAdmin(props)) return getMessage(props.fetchApi, "addAdmin");
  return getTable(props);
}

const checkRole = (props) => {
  return (
    props.listType === "role" &&
    props.fetchApi.data &&
    props.fetchApi.data.Items &&
    props.fetchApi.data.Items.length < 1
  );
};
const checkAddAdmin = (props) => {
  return (
    props.listType === "addAdmin" &&
    props.fetchApi.data &&
    props.fetchApi.data.paginatedUserData &&
    props.fetchApi.data.paginatedUserData.length < 1
  );
};
const checkLog = (props) => {
  return (
    props.listType === "log" &&
    props.fetchApi.data &&
    props.fetchApi.data.paginatedHRISLogData &&
    props.fetchApi.data.paginatedHRISLogData.length < 1
  );
};
const checkUsers = (props) => {
  return (
    props.listType === "users" &&
    props.fetchApi.data &&
    props.fetchApi.data.Items &&
    props.fetchApi.data.Items.length < 1
  );
};
const checkMailTemplate = (props) => {
  return (
    props.listType === "mailTemplate" &&
    props.fetchApi.data &&
    props.fetchApi.data.response &&
    props.fetchApi.data.response.mailTemplateList &&
    props.fetchApi.data.response.mailTemplateList.paginatedMailTemplateData &&
    props.fetchApi.data.response.mailTemplateList.paginatedMailTemplateData < 1
  );
};
const getTable = (props) => {
  if (props.listType === "log") {
    return (
      <Table className="project-table  "
        dataSource={getStatusObject(props)}
        pagination={false}
        columns={logsConstants.logTableColumns()}
      />
    );
  } else if (props.listType === "role") {
    return getRoleTable(props);
  } else if (props.listType === "users") {
    return (
      <Table className="no-space"
        // rowSelection={props.onSelect}
        pagination={false}
        dataSource={props.fetchApi.data.Items}
        columns={userConstants.adminTableColumns(props)}
      />
    );
  } else if (props.listType === "mailTemplate") {
    return getMailTemplateTable(props);
  } else if (props.listType === "addAdmin") return getAddAdminTable(props);
};

const getRoleTable = (props) => {
  return (
    <Table className="no-space"
      pagination={false}
      dataSource={props.fetchApi.data.Items}
      columns={rolesConstants.roleTableColumns({
        onDelete: onDelete.bind(null, props.delete),
      })}
    />
  );
};

const getAddAdminTable = (props) => {
  let columns = addAdminConstants.addAdminTableColumns(props.deleteAdmin)
  return (
    <Table className="project-table  settings-table"
      pagination={false}
      dataSource={props.fetchApi.data.paginatedUserData}
      columns={columns}
    // : columns.slice(0,columns.length-1)
    />
  );
};

const getMailTemplateTable = (props) => {
  return (
    <Table className="no-space"
      pagination={false}
      dataSource={
        props.fetchApi.data.response.mailTemplateList.paginatedMailTemplateData
      }
      columns={mailTemplateConstants.mailTemplateTableColumns()}
    />
  );
};
function getMessage(api, type) {
  var message = "";
  if (type === "log") message = "No logs found";
  else if (type === "role") message = "No Roles found ";
  else if (type === "users") message = "No Managers found ";
  else if (type === "mailTemplate") message = "No Templates found ";
  else if (type === "addAdmin") message = "No Admins found ";
  return (
    <EmptyState
      type={api.message}
      component="table"
      emptyStateMessage={message}
    />
  );
}

function onDelete(ok, rec) {
  Modal.confirm({
    title: "Do you Want to delete this role?",
    icon: <Icon type="warning" />,
    content: "Deleting this role cannot be undone.",
    okText: "Delete",
    okButtonProps: { type: "danger" },
    onOk() {
      return new Promise((resolve) => {
        return ok(rec.roleId, resolve);
      });
    },
    onCancel() { },
  });
}

const getStatusObject = (props) => {
  if (props.fetchApi.data) {
    return props.fetchApi.data.paginatedHRISLogData.map((item) => {
      return {
        ...item,
        status: {
          bulkJobStatus: item.bulkJobStatus,
          failureReason: item.failureReason,
        },
      };
    });
  } else return [];
};
