import {createSelector} from 'reselect';

const gethomePageReducer = state => state.homePageReducer;

const StateSelector = state => state;

export const SelectState = createSelector(
    [gethomePageReducer],
    StateSelector
);
