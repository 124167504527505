import { deepCopy } from "../../../../utils/deepCopy"

export const editTrendBenchMark = (state, payload) => {
  let updatedData = getUpdatedData(state.TrendBenchMarkDataApi.data, payload)
  return {
    ...state,
    TrendBenchMarkDataApi: {
      ...state.TrendBenchMarkDataApi,
      data: updatedData,

    }
  }
}

const getUpdatedData = (payload, data) => {
  let dataCopy = deepCopy(data)
  payload.forEach((element, index) => {
    if (dataCopy.isFromTopic) {
      if (!element.questionId && element.topicId === dataCopy.id) {
        element.benchMarkTwoScore = dataCopy.value ? parseInt(dataCopy.value) : undefined
      }
    } else {
      if (element.questionId === dataCopy.id) {
        element.benchMarkTwoScore = dataCopy.value ? parseInt(dataCopy.value) : undefined
      }
    }
  });
  return payload
}