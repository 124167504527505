import {
  PEOPLE_SEARCH_FORM_CHANGE,
  CLEAR_PEOPLE_SEARCH_FORM,
} from "../../constants";
export const peopleSearch = (event) => {
  return {
    type: PEOPLE_SEARCH_FORM_CHANGE,
    payload: event,
  };
};

export const clearPeopleSearch = () => {
  return {
    type: CLEAR_PEOPLE_SEARCH_FORM,
  };
};

