export const fetchManagerHeatmapHeadersStarted = (state) => {
    return {
        ...state,
        managerHeatmapHeadersApi: {
            isProcessing: true,
            error: false
        }
    }

}

export const fetchManagerHeatmapHeadersSuccess = (state, payload) => {
    return {
        ...state,
        managerHeatmapHeadersApi: {
            isProcessing: false,
            error: false
        },
        managerHeatmapHeaders: formatHeatmapHeadersToTagObjects(payload.data)
    }

}

export const fetchManagerHeatmapHeadersFailed = (state, payload) => {
    return {
        ...state,
        managerHeatmapHeadersApi: {
            isProcessing: false,
            error: true
        },
        manageHeatmapHeaders: [],
        isDataFetchedManager: false
    }

}


const formatHeatmapHeadersToTagObjects = (headersList) => {
    if (headersList && headersList.length) {
        return headersList.map((item, index) => { return { id: index + 1, content: item } })
    }
    return []

}