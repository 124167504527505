import { SURVEY_EDITOR_MATRIX_CHANGE_LABELS, SURVEY_EDITOR_MATRIX_ADD_ROW_OR_COL, SURVEY_EDITOR_MATRIX_REMOVE_ROW_OR_COL, SURVEY_EDITOR_MATRIX_SUBCATEGORY_GENERATION, SURVEY_EDITOR_MATRIX_ADD_SUBCATEGORY, SURVEY_EDITOR_MATRIX_EDIT_SUBCATEGORY_IN_QUESTIONS, SURVEY_EDITOR_MATRIX_DELETE_SUBCATEGORY, SURVEY_EDITOR_MATRIX_CHANGE_SUBCATEGORY } from "../../../../../constants";

export const matrixChangeLabels = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MATRIX_CHANGE_LABELS, payload });
    }
};

export const matrixAddOrRowOrCol = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MATRIX_ADD_ROW_OR_COL, payload });
    }
};

export const matrixRemoveAddOrRowOrCol = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MATRIX_REMOVE_ROW_OR_COL, payload });
    }
};

export const subcategoryGenerationForMatrix = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MATRIX_SUBCATEGORY_GENERATION, payload });
    }
};

export const addNewSubcategory = (payload) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MATRIX_ADD_SUBCATEGORY, payload });
    }
}

export const handleChangesubCategoryToQuestions = (payload) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MATRIX_EDIT_SUBCATEGORY_IN_QUESTIONS, payload });
    }
}

export const deleteSubCategory = (payload) => {
    return (dispatch) => {
        dispatch({ type: SURVEY_EDITOR_MATRIX_DELETE_SUBCATEGORY, payload });
    }
}

export const handleChangeSubcategoryName = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SURVEY_EDITOR_MATRIX_CHANGE_SUBCATEGORY, payload
        });
    }
}