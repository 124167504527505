import { fetchPayload } from "../../../utils/reducerOperations";
import {
    FETCH_STORYBOARD_QUESTIONS_SUCCESS,
    FETCH_STORYBOARD_QUESTIONS_START,
    FETCH_STORYBOARD_QUESTIONS_FAILED,
    FETCH_STORYBOARD_GOING_WELL_SUCCESS,
    FETCH_STORYBOARD_GOING_WELL_START,
    FETCH_STORYBOARD_GOING_WELL_FAILED,
    FETCH_STORYBOARD_IMPROVEMENT_SUCCESS,
    FETCH_STORYBOARD_IMPROVEMENT_START,
    FETCH_STORYBOARD_IMPROVEMENT_FAILED,
    FETCH_STORYBOARD_QUESTION_COMMENTS_START,
    FETCH_STORYBOARD_QUESTION_COMMENTS_SUCCESS,
    FETCH_STORYBOARD_QUESTION_COMMENTS_FAILED,
    FETCH_STORYBOARD_HIGH_COUNT_COMMENT_QUESTION_FAILED,
    FETCH_STORYBOARD_HIGH_COUNT_COMMENT_QUESTION_START,
    FETCH_STORYBOARD_HIGH_COUNT_COMMENT_QUESTION_SUCCESS,
    FETCH_STORYBOARD_LESS_COUNT_COMMENT_QUESTION_FAILED,
    FETCH_STORYBOARD_LESS_COUNT_COMMENT_QUESTION_START,
    FETCH_STORYBOARD_LESS_COUNT_COMMENT_QUESTION_SUCCESS
} from "../../constants";
import {
    storyboardQuestionsFailed,
    storyboardQuestionsStarted,
    storyboardQuestionsSuccess,
    storyboardGoingWellFailed,
    storyboardGoingWellStarted,
    storyboardGoingWellSuccess,
    storyboardImprovementFailed,
    storyboardImprovementStarted,
    storyboardImprovementSuccess,
    storyboardQuestionCommentsStarted,
    storyboardQuestionCommentsSuccess,
    storyboardQuestionCommentsFailed,
    storyboardHighCountCommentsStarted,
    storyboardHighCountCommentsSuccess,
    storyboardHighCountCommentsFailed,
    storyboardLessCountCommentsStarted,
    storyboardLessCountCommentsSuccess,
    storyboardLessCountCommentsFailed
} from "./Helpers";
import { Default } from "./initialState";

const StoryboardQuestionsReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case FETCH_STORYBOARD_QUESTIONS_START:
            return storyboardQuestionsStarted(state, payload);
        case FETCH_STORYBOARD_QUESTIONS_SUCCESS:
            return storyboardQuestionsSuccess(state, payload);
        case FETCH_STORYBOARD_QUESTIONS_FAILED:
            return storyboardQuestionsFailed(state, payload);
        case FETCH_STORYBOARD_GOING_WELL_START:
            return storyboardGoingWellStarted(state, payload);
        case FETCH_STORYBOARD_GOING_WELL_SUCCESS:
            return storyboardGoingWellSuccess(state, payload);
        case FETCH_STORYBOARD_GOING_WELL_FAILED:
            return storyboardGoingWellFailed(state, payload);
        case FETCH_STORYBOARD_IMPROVEMENT_START:
            return storyboardImprovementStarted(state, payload);
        case FETCH_STORYBOARD_IMPROVEMENT_SUCCESS:
            return storyboardImprovementSuccess(state, payload);
        case FETCH_STORYBOARD_IMPROVEMENT_FAILED:
            return storyboardImprovementFailed(state, payload);
        case FETCH_STORYBOARD_QUESTION_COMMENTS_START:
            return storyboardQuestionCommentsStarted(state, payload);
        case FETCH_STORYBOARD_QUESTION_COMMENTS_SUCCESS:
            return storyboardQuestionCommentsSuccess(state, payload);
        case FETCH_STORYBOARD_QUESTION_COMMENTS_FAILED:
            return storyboardQuestionCommentsFailed(state, payload);
        case FETCH_STORYBOARD_HIGH_COUNT_COMMENT_QUESTION_START:
            return storyboardHighCountCommentsStarted(state, payload);
        case FETCH_STORYBOARD_HIGH_COUNT_COMMENT_QUESTION_SUCCESS:
            return storyboardHighCountCommentsSuccess(state, payload);
        case FETCH_STORYBOARD_HIGH_COUNT_COMMENT_QUESTION_FAILED:
            return storyboardHighCountCommentsFailed(state, payload);
        case FETCH_STORYBOARD_LESS_COUNT_COMMENT_QUESTION_START:
            return storyboardLessCountCommentsStarted(state, payload);
        case FETCH_STORYBOARD_LESS_COUNT_COMMENT_QUESTION_SUCCESS:
            return storyboardLessCountCommentsSuccess(state, payload);
        case FETCH_STORYBOARD_LESS_COUNT_COMMENT_QUESTION_FAILED:
            return storyboardLessCountCommentsFailed(state, payload);
        default:
            return { ...state };
    }
};

export default StoryboardQuestionsReducer;
