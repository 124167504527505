export const clearBenchMarkData = (instance) => {
    instance.setState({
        benchMarkFile: {},
        benchMarkFileSelected: false,
        benchMarkFileName:"",
        benchMarkFileType:""
    })
}

export const onChangeBenchMarkData = (eventData, data, instance) => {
    let file = {}
    if (data.target.files.length){
        file = data.target.files[0]
    }
    instance.setState({
        benchMarkFile: file,
        benchMarkFileSelected: true,
        benchMarkFileName:file.name,
        benchMarkFileType:file.type
    })
}