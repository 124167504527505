/*eslint-disable*/
export var API_BASE_URL = window.location.hostname == "app.culturebie.com" || window.location.hostname == "www.app.culturebie.com" ? "https://ohq8qauxv8.execute-api.us-east-1.amazonaws.com/dev" : window.location.hostname == "qa.culturebie.com" || window.location.hostname == "www.qa.culturebie.com" ? "https://9evejf8f3h.execute-api.us-east-1.amazonaws.com/dev" : "https://server-dev.culturebie.com";
//export const API_BASE_UR = "https://lmi7d1h4w3.execute-api.us-east-1.amazonaws.com"
export var API_BASE_STAGE_URL = "/api";
export var LOGIN_URL = "/users/nativelogin";
export var REGISTER_URL = "/users/nativesignup";
export var VERIFY_URL = "users/respondtoauthchallenge";
export var FORGOT_PASSWORD_URL = "/users/nativeforgotpassword";
export var CHANGE_PASSWORD_URL = "/users/nativechangepassword";
export var RESET_URL = "/users/nativeresetpassword";
export var RESPONSES_URL = "/survey/getemailedparticipants";
export var ADD_JOB = "/users/addusersuploadjob";
export var ADD_COMMENTS_JOB = "/survey/commentsFileProcessJob";
export var ADD_ORGANIZATION_URL = "/organization/createorganization";
export var FETCH_ORGANIZATION_URL = "/organization/listorganization";
export var ADD_ADMIN_URL = "/users/admincreateuser";
export var INVITE_USER_URL = "/org/createLoginUser";
export var CREATE_MAIL_TEMPLATE_URL = "/org/createMailTemplate";
export var FETCH_MAIL_TEMPLATE_URL = "/org/listMailTemplate";
export var FETCH_ROLE_URL = "/roles/listroles";
export var CREATE_ROLE_URL = "/roles/createrole";
export var DELETE_ROLE_URL = "/roles/deleterole";
export var FETCH_TAGS_URL = "/roles/listtags";
export var GET_USER_PROFILE_URL = "/users/getuserprofile";
export var FETCH_USERS_URL = "/users/listusers";
export var SEARCH_USERS_URL = "/org/searchUser";
export var FETCH_HRIS_URL = "/users/listHrisFiles";
export var FETCH_HRIS_HIERARCHY = "users/getHrisHierarchy";
export var UPDATE_USER_URL = "/users/updateuserprofile";
export var UPDATE_USER_ROLE_URL = "/users/updateuserrole";
export var GET_SIGNED_URL = "/files/getSignedUrl";
export var DELETE_USER_URL = "/users/disableuser";
export var FETCH_LOGS_URL = "/users/userCreationLogs";
export var CREATE_SURVEY_URL = "/survey/createsurvey";
export var FETCH_SURVEY_URL = "/survey/listsurvey";
export var FETCH_DIMENSIONSLIST_URL = "/survey/getDimensions";
export var GET_DISTRIBUTION_DATA_URL = "/survey/getsurveydistributiondata";
export var GET_PARTICIPATON_RATE_URL = "/survey/surveyParticipationRate";
export var DELETE_ORGANIZATION_URL = "/organization/deleteorganization";
export var DELETE_SURVEY_URL = "/survey/deletesurvey";
export var PARSE_CSV_URL = "/survey/parsecsv";
export var GET_ORGANIZATION_PROFILE_URL = "/organization/getorganizationprofile";
export var GET_FILTER_AGGREGATION_URL = "/survey/getfilters";
export var FETCH_SURVEY_SNAPSHOT_URL = "/survey/surveyreportcomparisontabledata";
export var FETCH_EXECUTIVE_OVERVIEW_DATA = "/survey/renderdashboarddata";
export var filterObjects = ["dimensions", "gender", "FLSAStatus"];
export var CONTACT_FORM_URL = "https://ohq8qauxv8.exec" + "ute-api.us-east-1.amazonaws.com/dev/api/home/contactUsMessageSend";
export var FETCH_SCORES_URL = "/survey/rendersurveyreporttabledata";
export var FETCH_DRIVERS_URL = "/survey/renderdriverdata";
export var FETCH_OUTLIERS_URL = "/survey/getoutlierdetails";
export var PivotTableFields = ["gender", "dimensions", "ethnicity", "tenure", "FLSAStatus"];
export var FETCH_PIVOT_DATA_API = "/survey/surveyreportheatmaptabledata";
export var FETCH_OUTLIER_DATA = "/...";
export var FETCH_SPREAD_ANALYSIS_URL = "/survey/rendersurveyreporttabledata";
export var SAVE_PROJECT_URL = "survey/createQuestionSet";
export var RENAME_PROJECT_URL = "survey/renamesurvey";
export var COPY_PROJECT_URL = "survey/copySurvey";
export var RENAME_ORG_URL = "organization/renameorganization";
export var FETCH_QUESTIONSET_URL = "survey/fetchQuestionSet";
export var CREATE_ANSWERSET_URL = "survey/createAnswerSet";
export var SUBMIT_ANSWERSET_URL = "survey/submitSurveyResponse";
export var PUBLISH_PROJECT_URL = "survey/publishSurvey";
export var LIST_SURVEY_URL = "survey/listsurvey";
export var INSIGHTS_URL = "survey/insightDetails";
export var NUMERIC_INSIGHTS_URL = "survey/numericInsightDetails";
export var FETCH_ANSWER_SET = "survey/fetchAnswerSet";
export var EMAIL_DISTRIBUTION_URL = "survey/distributeSurvey";
export var DOWNLOAD_REPORT_URL = "survey/exportSurveyData";
export var DOWNLOAD_PARTICIPANTS_URL = "survey/getsurveyparticipants";
export var GET_RESPONSES_AS_CSV = "survey/getResponsesAsCSV";
export var GET_SURVEY_PARTICIPATION_URL = "survey/getParticipationRecords";
export var DELETE_PROJECT_URL = "survey/sasa";
export var ADD_REMINDER_URL = "survey/surveynotificationscheduler";
export var EDIT_REMINDER_URL = "survey/editscheduledsurveynotification";
export var LIST_REMINDER_URL = "survey/getschedulednotfications";
export var LIST_TEXT_QUESTIONS_URL = "survey/listSurveytextquestions";
export var REPROCESS_SURVEY_URL = "/survey/reprocessproject";
export var SEND_PREVIEW_MAIL_URL = "survey/sendsurveypreviewemail";
export var CSV_DOWNLOAD_URL = "getsurveyresponsefile";
export var STORYBOARD_TOPIC_URL = "gettopicstories";
export var DELETE_PEOPLE = "/organization/deletehris";
export var RENAME_PEOPLE = "/organization/renamehris";
export var DELETE_ADMIN_URL = "/users/admindeleteuser";
export var TRANSLATE_QUESTION_SET = "/survey/translatequestionset";
export var TRANSLATE_TEXT = "/survey/translatetext";
export var MODIFY_TOPIC_STORY_URL = "modifytopicstories";
export var PREVIOUS_QUOTE_URL = "previousquote";
export var CHANGE_QUOTE_URL = "changequote";
export var GET_SURVEY_TRANSLATION_STATUS = "/survey/gettranslatequestionsetstatus";
export var GET_THEMES_LIST = "/survey/getprojectthemes";
export var REPROCESS_TOPICS = "/survey/createcustomtopicthemesgrouping";
export var GET_STORYBOARD_ELEMENTS = 'getstoryboardelements';
export var comparisonsFilters = [{
  label: "Gender",
  text: "gender"
}, {
  label: "Tenure",
  text: "tenure"
}, {
  label: "FLSA",
  text: "FLSAStatus"
}, {
  label: "Ethnicity",
  text: "ethnicity"
}, {
  label: "Latest Performance Rating",
  text: "latestPerformanceRating"
}];
export var downloadTableCsvButtonText = " Download as CSV";

/* NLP */

var nlpApiUrl = window.location.hostname == "app.culturebie.com" || window.location.hostname == "www.app.culturebie.com" ? "https://api.nlp.culturebie.com/api" : window.location.hostname == "qa.culturebie.com" || window.location.hostname == "www.qa.culturebie.com" ? "https://9evejf8f3h.execute-api.us-east-1.amazonaws.com/dev" : "https://server-dev.culturebie.com";
if (localStorage.getItem("CB_NLP_ENV") == "development") nlpApiUrl = "http://localhost:9090";
var baseUrl = nlpApiUrl;
var nodeBaseUrl = API_BASE_URL + API_BASE_STAGE_URL;
export var BASE_URL = baseUrl;
export var TOPICS_URL = baseUrl + "/topics";
export var KEYWORD_URL = baseUrl + "/keywords";
export var COMMENTS_URL = baseUrl + "/comments";
//export const SENTIMENTAL_URL = "/search";
export var SENTIMENTAL_URL = nodeBaseUrl + "/getcomments";
export var TOPIC_RELATION_URL = nodeBaseUrl + "/topics/relation";
export var UPLOAD_FILE = baseUrl + "/upload";
export var BATCH_IDS_URL = nodeBaseUrl + "/batchids";
export var NLP_FILTER_URL = baseUrl + "/nlpfilters";
export var DASHBOARD_FILTER_URL = nodeBaseUrl + "/survey/dashBoardFilters";
export var FETCH_FILTERS_URL = baseUrl + "/filter";
export var DELETE_BATCH_IDS_URL = baseUrl + "/batch_ids/delete/";
export var JOB_REQUEST_URL = nodeBaseUrl + "/survey/approvejob";
export var PROCESS_FILES_URL = baseUrl + "/comments/process";
export var DEFAULT_LIBRARY_URL = baseUrl + "/downloadDefaultTopicsLibrary";
export var SAMPLE_COMMENTS_URL = baseUrl + "/downloadSampleCommentsFile";
export var USER_COMMENTS_URL = nodeBaseUrl + "/getbatchascsv";
export var LATEST_JOB_URL = nodeBaseUrl + "/getlatestjob";
export var BULK_BATCH_ID_OPERATION = baseUrl + "/bulkBatchIdOperation";
export var TOPIC_REPRESENTATION_URL = nodeBaseUrl + "/topics/histogramdata";
export var TOPIC_WORDCLOUD_URL = nodeBaseUrl + "/topics/wordclouddata";
export var GET_COLUMN_URL = baseUrl + "/getCsvColumns";
export var COMMENT_STATISTICS = nodeBaseUrl + "/commentsstatistics";
export var NLP_INSIGHTS_URL = baseUrl + "/insights";
export var NLP_DEMOGRAPHIC_TOPICS_DATA_URL = nodeBaseUrl + "/getdemographictopicdata";
export var NLP_DEMOGRAPHIC_SNAPSHOT_URL = baseUrl + "/getDemographicSnapShotData";
export var NLP_TOPIC_OVERVIEW_URL = baseUrl + "/getStoryBoardOverviewData";
export var STORYBOARD_TOPRESULTS_URL = baseUrl + "/getStoryBoardTopResults";
export var BATCH_QUESTIONS_URL = nodeBaseUrl + "/survey/getBatchQuestions";
export var NUMERIC_DEMOGRAPHICS_URL = nodeBaseUrl + "/survey/numericDemographics";
export var STOP_RESPONSE_COLLECTION_URL = nodeBaseUrl + "/survey/modifyProjectStatus";
export var QUESTION_COMMENTS_URL = baseUrl + "/fetchQuestionCommments";
export var SWITCH_QUESTION_COMMENTS_URL = baseUrl + "/getRandomCommentFromQuestion";
export var NLP_COMPARISON_HEATMAP_URL = baseUrl + "/getComparisonsHeatmapData";
export var NLP_HEATMAP_URL = nodeBaseUrl + "/survey/getheatmapData";
export var NLP_HEATMAP_QUES_URL = nodeBaseUrl + "/survey/getheatmapquestiondata";
export var NLP_TREE_DATA_GET_URL = API_BASE_URL + API_BASE_STAGE_URL + "/org/surveyListHierarchy";
export var DELETE_ORGANISATION_PROJECT_URL = nodeBaseUrl + "/survey/deleteproject";
export var DELETE_RESPONSE_URL = nodeBaseUrl + "/survey/deleteuserresponse";
export var DELETE_VIEWERS_URL = nodeBaseUrl + "/survey/deleteviewer";
export var UN_PUBLISH_SURVEY_URL = "/survey/unpublishsurvey";
export var CREATEVIEWER = "/survey/createviewer";
export var LISTVIEWERUSERS = "/survey/listviewerusers";
export var FETCH_BENCH_MARK = "/survey/getBenchmarkData";
export var UPDATE_BENCH_MARK = "/survey/updatebenchmarkdata";
export var FETCH_HRIS_DETAILS_URL = "/survey/gethrisfiledetails";
export var GET_COMMENTS_THEME_URL = "/survey/getprojectthemes";
export var GET_TREND_VISUALISATION = "/survey/getTrendScore";
export var SEND_FEEDBACK_URL = "/home/contactUsMessageSend";
export var FETCH_PROJECT_DETAILS = "/survey/getProjectDetails";
export var MODIFY_PROJECT_DATA = "/survey/modifyProject";
export var DELETE_REMINDER_URL = "/survey/deleteScheduledSurveyNotification";
export var STORYBOARD_FILTER_STATUS = '/getstoryboardfilterstatus';
export var MODIFY_PROJECT_CONFIGURATIONS = "/survey/modifyprojectconfigurations";
export var GET_PROJECT_CONFIGURATION = "survey/getprojectconfigurations";
export var GET_MULTI_SELECT_QUESTIONS = "survey/getMultipleSelectQuestions";
export var GET_MULTI_SELECT_QUESTIONS_DIAGRAM = "survey/multiSelectQuestions";
export var GET_RESULTS_PAGE_DATA = "survey/getResultPageData";
export var GET_FEED_BACK_360_RESULTS_PAGE_DATA = "survey/getFeedBackResultPage";
export var GET_FEED_BACK_360_MATRIX_VISUALIZATION_DATA = "/survey/fetchTwoByTwoMatrixData";
export var GET_FEED_BACK_360_CIRCUMPLEX_DATA = "/survey/fetchCircumplexChartData";
export var SET_NORM_BENCHMARK_CONFIGURATION = "survey/modifyNormScoreData";
export var MAIN_ROUTES = {
  LOGIN: "/login",
  USER_HOME: "/home",
  RESET_PASSWORD: "/resetPassword",
  FORGOT_PASSWORD: "/forgot",
  VERIFY_PASSWORD: "/verify",
  DASHBOARD: "/dashboard",
  DETAILS: "/details",
  NARRATIVE_SENSE: "/narrative-sense",
  EMPLOYEE_SURVEY: "/employee-surveys",
  MANAGE_ORG: "/admin/home",
  PROJECT: "/home/:org_id/survey/projects",
  NLP: "/home/:org_id/survey/nlp",
  ATTEND_SURVEY: "/survey/cbs/form/",
  NUMERIC_ANALYTICS: "/home/:org_id/survey/:projectId/engagement/reports",
  NLPV2: "/home/:org_id/survey/nlp",
  NEW_DASHBOARD: "/home/:org_id/newdashboard",
  NO_ACCESS: "/acccessDenied",
  RESULTS_PAGE: "/results",
  FEEDBACK_360_RESULT: "/feedback360Result"
};
export var ROLES = {
  ADMIN: "Admin",
  ORG_ADMIN: "Organization Admin",
  ORG_MANAGER: "Organization Manager",
  VIEWER: "Viewer",
  SUPER_ADMIN: "Super Admin"
};

//benchmark2

export var FETCH_TREND_BENCH_MARK = "/survey/getBenchmarkTwoData";
export var UPDATE_TREND_BENCH_MARK = "/survey/updateBenchmarkTwodata";

//Revoke manager access

export var REVOKE_USER_ACCESS = "/users/revokeManager";

//managerHeatmap

export var GET_MANAGER_HEATMAP = 'survey/getHeatMapByManager';
export var GET_MANAGER_HEATMAP_QUESTION_DATA = 'survey/getManagerHeatMapQuestionData';

//getUserDemographicsForAttendSurvey

export var FETCH_USER_DEMOGRAPHICS = '/users/getUserDemographics';
export var GET_HRIS_DEMOGRAPHIC_DATA = 'survey/getDemographicsData';

//fetchfollowup table data

export var FETCH_FOLLOW_UP_QUESTION_DATA = "survey/surveyFollowUpQuestionReportTableData";

//trend

export var GET_TREND_DATA = "survey/getTrendData";
export var GET_COMPARISON_SUREVEY_DETAILS = "survey/getComparisonSurvey";
export var UPDATE_TREND_DATA = "survey/updateTrendData";
export var GET_TREND_DEMOGRAPHICS_DATA = "survey/getTrendDemographics";

//get manager heatmap generation status

export var GET_MANAGER_HEATMAP_STATUS = "survey/heatMapByManagerStatus";
export var GET_HRIS_STATUS = "/users/getHrisStatus";

//trend Insights

export var GET_TREND_INSIGHTS_DATA = "/survey/trendInsights";

//dashboard ppt generation and status api

export var SURVEY_RESULT_PPT_GENERATOR = '/survey/surveyResultPptGenerator';
export var SURVEY_RESULT_PPT_GENERATOR_STATUS = '/survey/surveyResultPptGeneratorStatusCheck';

//cohort survey children api

export var FETCH_COHORT_CHILD_SURVEYS_URL = "/survey/listCohortSurveys";

//Show tabs in projects page

export var FETCH_SURVEY_LIST_TAB_CONFIGURATION = "/survey/listSurveyTabConfiguration";

//status report api

export var FETCH_STATUS_REPORT = "/survey/fetchCohortStatusReport";

//text summarization

export var TEXT_SUMMARIZATION = nodeBaseUrl + "/textSummerization";