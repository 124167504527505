import { Button, Switch } from 'antd';
import React, { Component } from 'react';
import { createDropDownData, replaceCurrentSurveyWithName } from '../../../../helpers';
import TreeSelectValue from '../../../TreeSelect';
import { SelectElement } from '../../../../../../../../../../FormElements/SelectElement';
import SVGIcons from '../../../../../../../../../../Components/SVGIcons';
import { MultipleGraphChart } from '../../../../../../../../../../Components/MultipleGraphChart';
import { MultipleAreaGraph } from '../../../../../../../../../../Components/MultipleAreaGraph';
import GroupedBarChart from '../../../../../../../../../../Components/GroupBarchart';
import { GRAPH_NAMES_TREND } from '../../constants';

import { TreeSelect } from 'antd';
import { EmptyState } from '../../../../../../../../../../Packages/EmptyState';
import { ButtonElement } from '../../../../../../../../../../FormElements/ButtonElement';

const { TreeNode } = TreeSelect;

class DemographicsTopics extends Component {

    componentDidMount() {

    }
    dropdownRender = () => {
        return (<div>
            <div style={{ marginBottom: 10 }}>
                <TreeNode
                    treeData={this.props.demographicsData}

                >
                    {/* {this.renderTreeNodes(this.state.treeData)} */}
                </TreeNode>
            </div>
            <Button type="primary" onClick={() => { }}>
                Apply
            </Button>
        </div>)

    }

    getTreeSelectData = () => {
        let isTopic = this.props.graphName === GRAPH_NAMES_TREND.DEMOGRAPHICS_TOPICS
        const tProps = {
            treeData: this.props.demographicsData,
            // dropdownRender: () => this.dropdownRender(),
            value: this.props.selectedTree,
            onChange: (e) => this.props.handleChangeTree(e, isTopic),
            treeCheckable: true,
            showCheckedStrategy: 'SHOW_PARENT',
            placeholder: 'Select demographics option',
            style: {
                width: '100%',
            },
             disabled: this.props.loading
        };
        return (
            <div className='trend-graph-chooser-demographics__left'>
                <TreeSelect {...tProps} />

            </div>
        );
    }



    getTopicsToggle = () => {
        let isTopic = this.props.graphName === GRAPH_NAMES_TREND.DEMOGRAPHICS_TOPICS
        return (
            <div className='icon-trendblock'>
                <span className={this.props.graphType === "vertical-bar" ? "active-trendicon" : ""} onClick={(value) => this.props.handleGraphChange("vertical-bar", isTopic)} >  <SVGIcons type={"SVG-graphnew"} /></span>
                {/* <span className={this.props.graphType === "line" ? "active-trendicon" : ""} onClick={(value) => this.props.handleGraphChange("line", isTopic)}>  <SVGIcons type={"SVG-lingraph"} /></span> */}
                <span className={this.props.graphType === "horizontal-bar" ? "active-trendicon" : ""} onClick={(value) => this.props.handleGraphChange("horizontal-bar", isTopic)}>  <SVGIcons type={"SVG-ranking"} /></span>
            </div>
        )
    }

    getTopicSection = () => {
        return (
            <>
                <>
                    <div className='trend-graph-chooser-header'>
                        {this.getTopicsSelectData()}
                        <span className='graph-toggle-button'>{this.getTopicsToggle()}</span>
                    </div>
                    <div className='trend-graph-chooser-demographics'>
                        Choose an option from demographics for filter : {this.getTreeSelectData()}
                    </div>
                    {this.props.graphData.length > 0 ? this.getGraph(true, this.props.graphData) : <EmptyState emptyStateMessage={"No data found"} component="table" />}

                </>
            </>
        )
    }

    getTopicsSelectData = () => {
        return (<div className='trend-graph-chooser-header__left'>

            <>
                <SelectElement
                    disableSearch={true}
                    className="topic-select"
                    onChange={(value) => this.props.onChangeSelection(value, this.props.graphName === GRAPH_NAMES_TREND.DEMOGRAPHICS_TOPICS)}
                    value={this.props.selectedDropDown}
                    optionList={this.props.dropdownData}
                    placeholder={"Select Theme"}
                ></SelectElement>
            </>

        </div>
        )
    }

    getGraph = (topics, graphData) => {
        if (this.props.graphType === "vertical-bar") {
            return <div className='multiplearea-graphblock'>
                <MultipleGraphChart data={graphData} showProjectGroup={this.props.showProjectGroup} />
            </div>
        } else if (this.props.graphType === "line") {
            return <div className='multiplearea-graphblock'>
                <MultipleAreaGraph data={graphData} showProjectGroup={this.props.showProjectGroup} />
            </div>
        } else {
            return <div className='multiplearea-graphblock'>
                <GroupedBarChart data={graphData} showProjectGroup={this.props.showProjectGroup} />
            </div>
        }
    }

    handleToggle = (value) => {
        let toggleState = this.props.graphName === GRAPH_NAMES_TREND.DEMOGRAPHICS_TOPICS ? { topicProjectGroup: value } : { questionProjectGroup: value }
        this.props.handleChangeToggle(toggleState)
    }

    render() {
        return (
            <div className='edit-survey-topicsection'>
                <div className='trend-graph-chooser-header'>
                    <h3>{this.props.graphName}</h3>
                    <div className='question-table-right'>
                        <p><span className='trend-toggle-label'>Switch axis</span> <Switch checked={this.props.showProjectGroup} onChange={(value) => this.handleToggle(value)} /></p>
                        <ButtonElement  className='download-heatmap' onClick={() => this.props.handleDownloadExcel({ key: this.props.graphName, trendData: this.props.graphData, dropdownData: this.props.dropdownData })}><SVGIcons type={"SVG-download"} />Download As Excel</ButtonElement>
                    </div>
                </div>
                {this.getTopicSection()}
            </div>
        );
    }
}

export default DemographicsTopics;




const treeData = [
    {
        title: 'Parent 1',
        value: 'parent1',
        children: [
            {
                title: 'Child 1',
                value: 'child1',
            },
            {
                title: 'Child 2',
                value: 'child2',
            },
        ],
    },
    {
        title: 'Parent 2',
        value: 'parent2',
        children: [
            {
                title: 'Child 3',
                value: 'child3',
            },
            {
                title: 'Child 4',
                value: 'child4',
            },
        ],
    },
];

