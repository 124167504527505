import {
  CHANGE_PASSWORD_PROCESS_FAILED,
  CHANGE_PASSWORD_FORM_CHANGE,
  CHANGE_PASSWORD_PROCESS_SUCCESS,
  CHANGE_PASSWORD_PROCESS_STARTED,
  CHANGE_PASSWORD_PROCESS_CLEAR,
} from "../../constants";
import { CHANGE_PASSWORD_URL } from "../../../../config/constants";
import { ApiBase } from "../../../utils/apiBase";

export const changePasswordFormChange = (event) => {
  return {
    type: CHANGE_PASSWORD_FORM_CHANGE,
    payload: event,
  };
};
export const clearChangePasswordForm = () => {
  return {
    type: CHANGE_PASSWORD_PROCESS_CLEAR,
  };
};
export const changePasswordAction = (params) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD_PROCESS_STARTED,
    });
    apiBaseInstance.instance
      .post(CHANGE_PASSWORD_URL, params)
      .then((res) => changePasswordSuccessAction(dispatch, res))

      .catch((err) => changePasswordFailedAction(dispatch, err));
  };
};

function changePasswordSuccessAction(dispatch, res) {
  return dispatch({
    type: CHANGE_PASSWORD_PROCESS_SUCCESS,
    payload: res.data,
  });
}
function changePasswordFailedAction(dispatch, err) {
  return dispatch({
    type: CHANGE_PASSWORD_PROCESS_FAILED,
    payload: err,
  });
}
