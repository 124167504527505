export default {
  teams_footer: "If you\u2019re a current customer \n    looking for support, please contact ",
  teams_email: 'support@culturebie.com',
  teams_email_link: '#_',
  teams_title: 'Management Team',
  teams_paragraph: "Employee data insights are terrific,but by\n    themselves, surveys don\u2019t tell you the accurate story.\n    Culturebie mines data from varied sources and is focused on \n    helping you connect the dots to give you true talent and \n    culture insights.",
  teams_teams: [{
    label: 'Jasmit Kaur, Founder & CEO',
    text: "Jasmit envisions a world of enlightened leaders,\n        informed by data analysis to guide engaged\n        and productive teams.",
    link: 'https://www.linkedin.com/in/jasmitk/'
  }, {
    label: 'Anran Meng, Data Scientist',
    text: "Anran has a deep passion for statistics and data \n        visualization. She supports client project delivery and\n        product development",
    link: 'https://www.linkedin.com/in/anran-meng/'
  }, {
    label: 'Kiran Sandhu, Business Analyst',
    text: "Kiran challenges the status quo and drives process\n             improvement. She manages operations and customer support \n             processes for Culturebie.",
    link: 'https://www.linkedin.com/in/kiran-sandhu-a1abb318b/'
  }, {
    label: 'Ed Happ, Advisor',
    text: "Leadership positions in the private and non-profit \n         sectors have taught Ed the power of storytelling.\n         story telling. At Culturebie,we work hard to elicit coherent\n         narratives from sdata.",
    link: 'https://www.linkedin.com/in/edward-g-happ/'
  }, {
    label: 'Nancy Benovich Gilby, Advisor',
    text: "A successful serial entrepreneur, Nancy was \n        Culturebie\u2019s first advisor. As an advocate of design thinking,\n         she inspires our human-centered approach to clients.",
    link: 'https://www.linkedin.com/in/nabgilby/'
  }, {
    label: 'Kentaro Toyama, Advisor',
    text: "With backgrounds in AI and human development,\n             Kentaro reminds us that it\u2019s people who use data and \n             insights drawn from it. Culturebie partners with clients\n              to meet human needs.",
    link: 'https://www.linkedin.com/in/kentarotoyama/'
  }],
  learnMore: 'Learn more',
  config: {
    rel: 'noopener noreferrer',
    target: '_blank',
    imgAlt: ''
  }
};