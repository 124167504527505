export function apiStartState(payload = [], clearData) {
  let res = {};
  if (!clearData) res = { ...payload };
  res = {
    ...res,
    loading: true,
    error: false,
  };
  return res;
}

export function apiInitialState(other={}) {

  return {
    loading: true,
    error: null,
    data: null,
    data_count: 0,
    isProcessing:true,
    ...other,
  };
}
export function apiInitialStateAddFn(other={}) {

  return {
    loading: false,
    error: null,
    data: null,
    data_count: 0,
    isProcessing:false,
    ...other,
  };
}

export function apiSuccessState(payload, action) {
  return {
    ...payload,
    loading: false,
    error: false,
    data: action.data,
    data_count: action.count,
    isProcessing:false,
  };
}
export function apiSuccessSortState(payload, action) {
  return {
    ...payload,
    loading: false,
    error: false,
    data: {
      histogramData: action.data.data.histogramData.sort(
        (a, b) => a.count - b.count
      ),
      commentCountInBatch: action.data.data.commentCountInBatch,
    },
    data_count: action.data.data.filteredCommentsCount,
  };
}
export function apiFailureState(payload, action) {
  return {
    loading: false,
    error: action.error || true,
    isProcessing:false,
    message: "Network Error",
  };
}
