import { Default } from "./InitialState";
import {
    FETCH_RESULTS_PAGE_DATA_START,
    FETCH_RESULTS_PAGE_DATA_SUCCCESS,
    FETCH_RESULTS_PAGE_DATA_FAIL
}
    from '../../constants'
import { fetchResultsDataFail, fetchResultsDataStart, fetchResultsDataSuccess } from "./Helper";
const ResultsPageReducer = (state = Default(), action) => {
    switch (action.type) {
        case FETCH_RESULTS_PAGE_DATA_START:
            return fetchResultsDataStart(state, action.payload);
        case FETCH_RESULTS_PAGE_DATA_SUCCCESS:
            return fetchResultsDataSuccess(state, action.payload);
        case FETCH_RESULTS_PAGE_DATA_FAIL:
            return fetchResultsDataFail(state, action.payload);
        default:
            return { ...state }
    }
}

export default ResultsPageReducer