import React from "react";
import { Col, Row } from "antd";
import Container from "../../../../Components/Container";
import RenderBatchIds from "./children/RenderBatchIds";
import { Card } from 'react-bootstrap';
import {
  GetLocalStorage,
} from "../../../../utils/localStorgeOperations";
import RenderForm from "./children/RenderForm";
import { RouteHeader } from "../../../../Components/RouteHeader";
import CustomModal from "../../../../Components/CustomModal";
import constants from "./constants";
import { SelectElement } from "../../../../FormElements/SelectElement/";
import "./index.scss";
import { checkIfAdmin } from '../../../../utils/rolePermissionUtils';
class Admin extends React.Component {
  state = {
    projectId: "",
    modalVisibility: false,
    isAdmin: checkIfAdmin(),
    hrisFile: null,
    hrisFileId: null,
    topic: null,
  };
  componentWillUnmount() {
    this.props.clearFormData();
  }
  onChangeBatchIdField = (event) => {
    // if (
    //   this.props.commentsFileApi.data &&
    //   this.props.commentsFileApi.data.fileName
    // )
    this.props.setBatchIds(event.value);
  };

  onUploadComment = (event) => {
    if (event) {
      this.props.uploadCommentFile(event[0]);
    }
  };

  onUploadTopicLibrary = (_, event) => {
    if (event.target.files.length)
      this.props.uploadTopicLibrary(event.target.files[0]);
    event.target.value = null;
  };
  onUploadHrisFile = (_, event) => {
    if (event.target.files.length)
      this.props.uploadHrisFile(event.target.files[0]);
    event.target.value = null;
  };
  onReprocessUpload = (_, event) => {
    if (event.target.files.length)
      this.props.uploadReprocessFile(event.target.files[0]);
  };
  onSubmitForm = () => {
    const { commentsFileApi, currentBatchId } = this.props;
    if (!currentBatchId.trim()) return;
    const requestBody = {};
    requestBody.projectId = currentBatchId;
    requestBody.projectName = currentBatchId;
    requestBody.filePath = commentsFileApi.data.fileName;
    this.props.getCsvColumns(requestBody);
    // this.setState({ modalVisibility: true });
    this.onColumnSubmit();
  };
  submitReprocessFile = (projectId) => {
    const { reprocessUploadApi } = this.props;
    const requestBody = {};
    requestBody.user = GetLocalStorage("username");
    requestBody.projectId = projectId;
    requestBody.reprocess = true;
    requestBody.topics_library_file = reprocessUploadApi.data
      ? reprocessUploadApi.data.fileName
      : undefined;
    requestBody.topicsLibraryFile = reprocessUploadApi.data
      ? reprocessUploadApi.data.fileName
      : undefined;
    this.props.reProcessFiles(requestBody);
  };
  onSelectHris = (value, event) => {
    if (this.props.fetchHrisFIleApi.data) {
      if (value !== "") {
        this.setState({
          hrisFile: event.children[1].props.children,
          hrisFileId: value,
        });
      } else {
        this.setState({ hrisFile: "", hrisFileId: "" });
      }
    }
  };
  clearHrisFileId = () =>{
    this.setState({hrisFile: null, hrisFileId: null});
  }
  getPropsForForm = () => ({
    ...this.props,
    onChangeBatchIdField: this.onChangeBatchIdField,
    onSubmitForm: this.onSubmitForm,
    onUploadComment: this.onUploadComment,
    commentsFileApi: this.props.commentsFileApi,
    downloadCommentsApi: this.props.downloadCommentsApi,
    downloadSampleComments: this.props.downloadSampleComments,
    downloadDefaultTopics: this.props.downloadDefaultTopics,
    downloadDefaultTopicApi: this.props.downloadDefaultTopicApi,
    processFilesApi: this.props.processFilesApi,
    disableButton: !!!this.props.currentBatchId,
    isAdmin: this.state.isAdmin,
    libraryFileApi: this.props.libraryFileApi,
    onUploadTopic: this.onUploadTopicLibrary,
    onSelectTopic: this.onSelectTopic,
    onUploadHris: this.onUploadHrisFile,
    onSelectHris: this.onSelectHris,
    hrisFileId: this.state.hrisFileId,
    setProjectsCount: this.props.setProjectsCount,
    clearHrisFileId : this.clearHrisFileId,
  });
  getPropsForBatchTable = () => ({
    batchApprove: this.props.batchApproveJobsRequest,
    batchDelete: this.props.batchDeleteJobsRequest,
    isAdmin: this.state.isAdmin,
    approveApi: this.props.jobApprovalApi,
    batchIdsApi: this.props.getBatchIdsApi,
    fetchData: this.props.fetchBatchIds,
    deleteBatchId: this.props.deleteBatchId,
    onApprove: this.props.approveJobRequest,
    downloadComments: this.props.downloadUserComments,
    downloadApi: this.props.downloadUserCommentsApi,
    batchDeleteApi: this.props.batchDeleteApi,
    batchApprovalApi: this.props.batchApprovalApi,
    onReprocessUpload: this.onReprocessUpload,
    reprocessUploadApi: this.props.reprocessUploadApi,
    submitReprocessFile: this.submitReprocessFile,
    reprocessFilesApi: this.props.reprocessFilesApi,
  });
  onCancel = () => {
    this.setState({ modalVisibility: false });
  };
  getHrisRequestBody = (requestBody) => {
    const { hrisFileApi } = this.props;
    if (this.state.hrisFile) {
      requestBody.hrisExisting = true;
      requestBody.hrisFile = this.state.hrisFile;
      requestBody.hrisFileId = this.state.hrisFileId;
    } else {
      requestBody.hrisFile =
        (hrisFileApi.data && hrisFileApi.data.fileName) || undefined;
      requestBody.hrisExisting = false;
    }
  };
  onColumnSubmit = (selectedColumns) => {
    const { commentsFileApi, libraryFileApi, currentBatchId } = this.props;
    if (!currentBatchId.trim()) return;
    const requestBody = {};
    requestBody.user = GetLocalStorage("username");
    requestBody.projectId = currentBatchId;
    requestBody.projectName = currentBatchId;
    requestBody.comments_file = commentsFileApi.data.fileName;
    requestBody.commentsFile = commentsFileApi.data.fileName;
    requestBody.selected_columns = selectedColumns;
    requestBody.selectedColumns = selectedColumns;
    requestBody.topicLibraryExisting = false;
    requestBody.topics_library_file =
      (libraryFileApi.data && libraryFileApi.data.fileName) || undefined;
    requestBody.topicsLibraryFile =
      (libraryFileApi.data && libraryFileApi.data.fileName) || undefined;
    this.getHrisRequestBody(requestBody);
    this.setState({ modalVisibility: false });
    this.props.processFiles(requestBody, () => { });
    this.setState({ hrisFile: null, hrisFileId: null });
  };
  renderModal = () => {
    return (
      <CustomModal
        className="select-column-modal"
        footer={null}
        onCancel={() => this.setState({ modalVisibility: false })}
        visible={this.state.modalVisibility}
      >
        <div>
          <SelectElement
            onColumnSubmit={this.onColumnSubmit}
            columnList={this.props.getCsvColumnsApi.data}
          />
        </div>
      </CustomModal>
    );
  };
  render() {
    return (
      <React.Fragment>
        <RouteHeader
          {...this.props}
          navigationData={constants.navigationList}
          title = {"Text Analytics"}
        />
        <Container>
          <Card className="addreports-wrapper">

            <Row>
              <div className="admin-tab">
                <RenderForm {...this.getPropsForForm()} />
                <RenderBatchIds {...this.getPropsForBatchTable()} />
              </div>
              <Col lg={12} md={12} xs={12}>
                {this.renderModal()}
              </Col>
            </Row>

          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default Admin;
