import {setInitialState} from '../../../../utils/reducerOperations';

export const InitialState=()=>({
    commentsSentimentsApi:setInitialState(),
    isDataAlreadyFetched:false,
    filters : {},
    byScore:[],
    multipleSelect: [],
    selectedUser : "",
    directReporteesOnly: false,
    getBatchCommentQuestionsApi:setInitialState(),
    downloadCommentsBatchApi : setInitialState()

});