import React from 'react';
import { Menu, Dropdown, Tooltip } from "antd";
import './index.scss';
import SVGIcons from '../../Components/SVGIcons';
export const DotMenu = (props) => {
    const commentDownload = (
        <Menu selectedKeys={props.selectedKeys}>
            {props.itemList.map((item, index) => item.haveChildren ?
                <Menu.SubMenu title={<div><a href={false}>{item.label}</a></div>}>
                    {item.childrens.map(menu => <Menu.Item key={menu.value || index}>
                        <div className='dot-menu__dropdown--sub'>
                            <a href={false} disabled={menu.disable} onClick={(e) => menu.onMenuItemClick(e, menu.value, menu.props)}>{menu.label}</a>
                        </div>
                    </Menu.Item>)}
                </Menu.SubMenu>
                : <Menu.Item key={item.value || index} disabled={item.disable}>
                    {item.disable ?
                        <Tooltip title={item.disableReason}>
                            <div>
                                <a href={false} disabled={item.disable} onClick={(e) => item.onMenuItemClick(e, item.value, item.props)}>{item.label}</a>
                            </div>
                        </Tooltip> :
                        <a href={false} disabled={item.disable} onClick={(e) => item.onMenuItemClick(e, item.value, item.props)} className='dot-menu__dropdown'>{item.label}</a>
                    }
                </Menu.Item>)}

        </Menu>
    );
    return (

        <div className="dot-menu">
            <Dropdown
                overlay={commentDownload}
                trigger={["click"]}
                placement="bottomRight"
                getPopupContainer={(trigger) => trigger.parentNode}
            >
                <a
                    href={false}
                    className="ant-dropdown-link"
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >
                    <div className="dot-menu__icon">
                        <span>
                            <SVGIcons type={'SVG-dot-menu'} className="icon-block" />
                        </span>
                    </div>
                </a>
            </Dropdown>
        </div>

    );
};
