
export function fetchExploreRelationDataStart(state, payload) {
  return {
    ...state,
    [payload.questionId]: {
      ...state[payload.questionId],
      exploreRelationDataApi: {
        data: [],
        isProcessing: true,
        error: null,
        message: null,
      },
    }
  };
}

export function fetchExploreRelationDataSuccess(state, payload) {
  return {
    ...state,
    [payload.questionId]: {
      ...state[payload.questionId],
      exploreRelationDataApi: {
        data: payload.data.data,
        isProcessing: false,
        error: false,
        message: payload.message,
      },
      isDataAlreadyFetched: true,
      filters: payload.filters,
      byScore: payload.byScore,
      multipleSelect: payload.multipleSelect,
      selectedUser: payload.selectedUser,
      directReporteesOnly: payload.directReporteesOnly,
    },
  };
}

export function fetchExploreRelationDataFailed(state, payload) {
  return {
    ...state,
    [payload.questionId]: {
      ...state[payload.questionId],
      exploreRelationDataApi: {
        isProcessing: false,
        data: [],
        error: payload.error,
      },
    }
  };
}
