import {
    makeKey,
    update,
    getSuccessResponse
} from '../../../../utils/reducerOperations';
export function getOrganizationProfileSuccess(state, payload) {
    return makeKey(
        'getOrganizationProfileApi',
        update(
            state.getOrganizationProfileApi,
           {...getSuccessResponse(payload),isDataFetched:true}
        )
    );
}
