import * as moment from "moment";
import React from "react";
import { LoaderButton } from "../../FormElements/LoaderButton";
import { compareByAlpha } from "../../utils/compareByAlpha";
const users = {
  header: "Users",
};

export default {
  MODAL_HEADER: "Add new email template",
  NAME: "Name",
  TEMPLATE: "Template",
  TEMPLATES: "Templates",
  NAME_KEY: "name",
  MAIL_TEMPLATE: "mailTemplate",
  ADD_TEMPLATE: "Add Template",
  TEMPLATE_KEY: "template",
  NAME_PLACEHOLDER: "Enter template name",
  TEMPLATE_PLACEHOLDER: "Enter template",
  ERROR_MESSAGE: "Please enter all fields",
  SUGGESTION: "SUGGESTIONS :",
  EMPLOYEE_NAME_VALUE: "{{employeeName}}",
  USERNAME_VALUE: "{{username}}",
  PASSWORD_VALUE: "{{password}}",
  EMPLOYEE_NAME: "employeeName",
  USERNAME: "username",
  PASSWORD: "password",
  CHECKBOX: {
    placeholder: "Is Existing User Email Template?",
  },
  mailTemplateTableColumns: () => [
    {
      title: "Template Name",
      dataIndex: "mailTemplateName",
      key: "templateName",
      width: 200,
    },
    {
      title: "Template",
      dataIndex: "mailTemplate",
      key: "template",
      width: 450,
    },
    {
      title: "Is Existing User Email Template",
      dataIndex: "isExistingUserTemplate",
      key: "isExistingUserTemplate",
      width: 200,
      render: (data) => {
        if (data) return "Yes";
        else return "No";
      },
    },
    {
      title: "Created At",
      key: "uploadDate",
      dataIndex: "projectCreationDate",
      width: 200,
      render: (data) => moment(data).fromNow(),
    },
  ],
};


const organization = {
  header: "Organization Users",
};

export const rolesConstants = {
  users,
  ROLES: "Roles",
  organization,
  userRoute: "/admin/home/manageusers",
  dots: "...",
  Users: " Users",
  CREATE_ROLE: "Create Role",
  roleTableColumns: ({ onDelete }) => [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: 300,
    },
    ...getRoleTableColumnData(),
    {
      title: "",
      key: "delete",
      dataIndex: "delete",
      width: 90,
      render: (_, rec) => (
        <LoaderButton
          onClick={() => onDelete(rec)}
          classList={["btn", "btn-danger", "loader-btn"]}
          loader={false}
          loaderClass={["sm", "white"]}
          text={"Delete"}
        />
      ),
    },
  ],
};

const getRoleTableColumnData = () => {
  return [
    {
      title: "Permissions",
      dataIndex: "tags",
      key: "tags",
      width: 600,
      render: (data) => data.join(" , "),
    },
    {
      title: "",
      key: "edit",
      dataIndex: "edit",
      width: 90,
      render: () => (
        <LoaderButton
          onClick={() => { }}
          classList={["btn", "btn-info", "loader-btn"]}
          loader={false}
          loaderClass={["sm", "white"]}
          text={"Edit"}
        />
      ),
    },
  ];
};

export const Message = {
  error: {
    key: "role_error",
    type: "danger",
    text: "Roles Fetching Error",
  },
  empty: {
    key: "role_empty",
    type: "warning",
    text: "No Roles Avalible",
  },
};

export const userConstants = {
  adminTableColumns: (props) => [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      width: 150,
      // ...getColumnSearchProps("first_name", props),
      sorter: (a, b) => compareByAlpha(a.firstName, b.firstName),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      width: 150,
      // ...getColumnSearchProps("last_name", props),
      sorter: (a, b) => compareByAlpha(a.lastName, b.lastName),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      // ...getColumnSearchProps("email", props),
      sorter: (a, b) => compareByAlpha(a.username, b.username),
    },
    ...getChooseTemplate(props),
  ],
}


const getChooseTemplate = (props) => {
  return [
    {
      title: "Role",
      dataIndex: "userRole",
      key: "role",
      width: 150,
      // ...getColumnSearchProps("role", props),
      sorter: (a, b) => compareByAlpha(a.role, b.role),
      render: (text) => (text ? text.roleName : ""),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      // ...getColumnSearchProps("status", props),
      sorter: (a, b) => compareByAlpha(a.role, b.role),
    },
  ];
};
