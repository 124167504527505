import constants from "./constants";
import { decryptData } from './encryptionUtils'
import { ROLE_PERMISSION_SECRET } from '../../config/userConfigurations'
import { getOrgIdFromUrl } from "./urlProcessor";
import { getDataFromUrlToken } from "./urlDataStore";
import { URL_DATA } from "../../config/urlDataConstants";
export const SetLocalStorage = (inputObject) => {
  for (let item in inputObject) {
    if (item === "organizationId") {
      localStorage.setItem("organizationId", setItemValue(inputObject[item]));
    } else localStorage.setItem(item, setItemValue(inputObject[item]));
  }
};

export const GetLocalStorage = (key) => {
  return getItemValue(localStorage.getItem(key));
};

function setItemValue(value) {
  if (typeof value != "string") return JSON.stringify(value);
  else return value;
}

function getItemValue(item) {
  try {
    return JSON.parse(item);
  } catch (err) {
    return item;
  }
}

export const CheckInLocalStorage = (key) => {
  return localStorage.getItem(key) ? true : false;
};

export const ClearLocalStorage = () => {
  localStorage.clear();
};

export function routeUserToDashboard(userRole, pathname) {
  if (
    window.location.hostname === "app.culturebie.com" ||
    window.location.hostname === "www.app.culturebie.com" ||
    window.location.hostname === "dev.culturebie.com" ||
    window.location.hostname === "www.dev.culturebie.com"||
    window.location.hostname === "qa.culturebie.com" ||
    window.location.hostname === "www.qa.culturebie.com"
  ) {
    window.location.href = pathname || constants.path.adminHome; //constants.path.adminHome;
  } else {
    window.location.href = pathname || constants.path.adminHome;
  }
}

export function getUserRole() {
  const role = JSON.parse(localStorage.getItem("userRole"));
  return (role || {}).roleName;
}

export const getHierarchyJson = () =>
  JSON.parse(localStorage.getItem("heirachyJson"));

export const getOrgId = () => getOrgIdFromUrl() || fetchOrgIdFromToken()
export const getOrgName = () => localStorage.getItem("organizationName");
export const getDefaultHrisValue = () =>
  localStorage.getItem("defaultHrisValue");
export const getNlpHrisId = () => localStorage.getItem("nlpHrisId");
export const getHrisFileId = () => localStorage.getItem("hrisFileId");
export const getNlpHrisExisting = () => localStorage.getItem("nlpHrisExisting");
export const getDefaultHrisName = () => localStorage.getItem("defaultHrisName");
export const getSelectedHrisValue = () => localStorage.getItem("hrisValue");
export const getLoginUserEmail = () => localStorage.getItem("username");
export const getLoginUserId = () => localStorage.getItem("userId");
export const getBatchId = () => localStorage.getItem("projectId");


const fetchOrgIdFromToken = () => {
  return getDataFromUrlToken(URL_DATA.ORGANIZATION) ? getDataFromUrlToken(URL_DATA.ORGANIZATION).organizationId : undefined
}

export const checkRootManager = () => {
  if (GetLocalStorage("managerId") === "root") return true;
  return false;
};

export const getUserPermissions = () => {
  const userRolePermissionsKey = localStorage.getItem('userRolePermissions');
  if (userRolePermissionsKey) {
    const decryptedUserRolePermissions = decryptData(
      userRolePermissionsKey,
      ROLE_PERMISSION_SECRET
    );
    return (
      (decryptedUserRolePermissions &&
        decryptedUserRolePermissions.permission) ||
      []
    );
  } else {
    // ClearLocalStorage();
    // window.location.href = constants.path.login;
  }
};