export const fetchDashBoardFilterStart = (state, payload) => {
  return {
    ...state,
    dashBoardFilterApi: {
      isProcessing: true,
      error: false,
      reason: '',
      facetData: state.dashBoardFilterApi.facetData,
    },
  };
};

export const fetchDashBoardFilterSuccess = (state, payload) => {
  const data = payload.data || {};
  const duplicateFilterData = state.duplicateFilterData.length === 0 ? displayDataForTree(payload.unFormattedData) : state.duplicateFilterData
  return {
    ...state,
    duplicateFilterData: duplicateFilterData,
    dashBoardFilterApi: {
      isProcessing: false,
      error: false,
      reason: '',
      facetData: data,
    },
  };
};

export const fetchDashBoardFilterFail = (state, payload) => {
  return {
    ...state,
    dashBoardFilterApi: {
      isProcessing: false,
      error: true,
      reason: payload.error,
      facetData: [],
    },
  };
};

const displayDataForTree = (data) => {
  let dropDownData = [];
  Object.keys(data).forEach((item) => {
    if (item !== "byScore") {
      dropDownData.push({
        title: item,
        value: item,
        children: Object.keys(data[item]).map((options) => {
          return {
            title: options,
            value: `${item}:${options}`,
          };
        }),
      });
    }
  });

  return dropDownData;
}



