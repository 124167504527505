import {
    update,
    fetchPayload
} from '../../../utils/reducerOperations';
import * as helper from './helper';
import {
    FETCH_EXECUTIVE_OVERVIEW_FAILED,
    FETCH_EXECUTIVE_OVERVIEW_STARTED,
    FETCH_EXECUTIVE_OVERVIEW_SUCCESS,
    UPDATE_CAROUSEL_INDEX,
    UPADTE_CAROUSEL_WIDTH
} from '../../constants';
import { Default } from './InitialState';


const ExecutiveOverviewReducer = (state=Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
    case FETCH_EXECUTIVE_OVERVIEW_STARTED:
        return update(state,
            helper.fetchExecutiveOverviewStarted(state, payload));
    case FETCH_EXECUTIVE_OVERVIEW_SUCCESS:
        return update(state,
            helper.fetchExecutiveOverviewSuccess(state, payload));
    case FETCH_EXECUTIVE_OVERVIEW_FAILED:
        return update(state,
            helper.fetchExecutiveOverviewFailed(state, payload));
    case UPDATE_CAROUSEL_INDEX:
        return helper.updateCarouselIndex(state,payload);
    case UPADTE_CAROUSEL_WIDTH:
        return {...state,carouselWidth:payload};
    default: return {...state};
    }
};

export default ExecutiveOverviewReducer;