import {
  update,
  makeKey,
  handleApiResponseError,
  handleNetworkError,
} from "../../../../utils/reducerOperations";

export function fetchUsersFailure(state, payload) {
  return makeKey(
    "fetchUsersApi",
    update(
      state.fetchUsersApi,
      payload.response && payload.response.data
        ? handleApiResponseError(payload.response.data)
        : handleNetworkError(payload)
    )
  );
}
