import * as actionTypes from '../../constants';
import { Default } from './InitialState';
import helper from './helper';

function SurveyAnsweringPageReducer(state = Default(), action) {
    switch (action.type) {
        case actionTypes.NEW_FETCH_USER_DEMOGRAPHICS_DATA_START:
            return helper.fetchDemographicsDataStart(state);
        case actionTypes.NEW_FETCH_USER_DEMOGRAPHICS_DATA_SUCCESS:
            return helper.fetchDemographicsDataSuccess(state, action);
        case actionTypes.NEW_FETCH_USER_DEMOGRAPHICS_DATA_FAIL:
            return helper.fetchDemographicsDataFailed(state, action);

        case actionTypes.FETCH_ATTENT_SURVEY_DATA_START:
            return helper.fetchProjectStart(state);
        case actionTypes.FETCH_ATTENT_SURVEY_DATA_SUCCESS:
            return helper.fetchProjectSuccess(state, action);
        case actionTypes.FETCH_ATTENT_SURVEY_DATA_FAIL:
            return helper.fetchProjectFail(state, action);

        case actionTypes.UPDATE_ANSWERS_IN_SURVEY_PAGE:
            return helper.updateAnswer(state, action.payload);

        case actionTypes.UPDATE_TEXT_ANSWER_IN_SURVEY:
            return helper.updateTextAnswer(state,action.payload);

        case actionTypes.UPDATE_CURRENT_INDEX_OF_QUESTION:
            return helper.updateCurrentIndex(state, action.payload);

        case actionTypes.SUBMIT_SURVEY_RESPONSE_START:
            return helper.submitSurveyResponseStart(state);
        case actionTypes.SUBMIT_SURVEY_RESPONSE_SUCCESS:
            return helper.submitSurveyResponseSuccess(state, action);
        case actionTypes.SUBMIT_SURVEY_RESPONSE_FAIL:
            return helper.submitSurveyResponseFail(state, action);

        case actionTypes.SURVEY_PAGE_TRANSLATION_START:
            return helper.translateSurveyStart(state, action)
        case actionTypes.SURVEY_PAGE_TRANSLATION_SUCCESS:
            return helper.translateSurveySuccess(state, action)
        case actionTypes.SURVEY_PAGE_TRANSLATION_FAIL:
            return helper.translateSurveyFailed(state, action)

        case actionTypes.ON_CHANGE_TRANSLATION_LANGUAGE:
            return helper.onChangeLanguage(state, action.payload)

        case actionTypes.UPDATE_CURRENT_INDEX_DETAILS:
            return helper.updateIndexDetailsWithData(state,action.payload)

        case actionTypes.BACK_TO_FIRST_PAGE:
            return helper.backToFirstPage(state)

        case actionTypes.GENERATE_ANSWER_SET_FAIL:
           return helper.createAnswerSetFailed(state)
        case actionTypes.GENERATE_ANSWER_SET_SUCCESS:
            return helper.createAnswerSetSuccess(state)

        case actionTypes.HANDLE_CHANGE_RANKING:
            return helper.onChangeRanking(state, action.payload)
        case actionTypes.HANDLE_CHANGE_RATING:
            return helper.onChangeRating(state, action.payload)
        case actionTypes.HANDLE_CHANGE_TABLE_MATRIX:
            return helper.onChangeTableMatrix(state, action.payload)
        case actionTypes.SURVEY_EDITOR_COPY_TO_ANSWERING_PAGE:
            return helper.handleCopyEditSurveyToAnsweringPage(state, action.payload)
        case actionTypes.HANDLE_CHANGE_NETPROMOTER:
            return helper.onChangeNetPromoter(state, action.payload)

        case actionTypes.HANDLE_DRAG_END_PRIORITY:
            return helper.onDragEndPriority(state, action.payload)
        case actionTypes.HANDLE_CHANGE_PRIORITY_TEXT:
            return helper.onChangePriorityText(state, action.payload)
        case actionTypes.BEHAVIOUR_DRAG_STRENGTH_IMPROVEMENT:
            return helper.behaviourDragUpdate(state, action.payload)
        case actionTypes.BEHAVIOUR_DRAG_DELETE:
            return helper.behaviourDragDelete(state,action.payload)

        case actionTypes.HANDLE_CHANGE_CHECKBOX_FEEDBACK_360:
            return helper.handleChangeCheckBoxChange(state, action.payload)

        case actionTypes.GO_TO_PARTICULAR_PAGE:
            return helper.handleChangePageIndex(state, action.payload)

        case actionTypes.PROCEED_TO_NEXT_STEP:
            return helper.handleChangeClickProceed(state, action.payload)


        default: return { ...state };
    }
}
export default SurveyAnsweringPageReducer