import {
  apiStartState,
  apiFailureState,
  apiSuccessState,
  apiInitialState,
} from "../../../../utils/apiStateUtils";

export function getBatchIdsStart(state) {
  return {
    ...state,
    getBatchIdsApi: apiStartState(state.getBatchIdsApi),
  };
}

export function getBatchIdsSuccess(state, action) {
  return {
    ...state,
    getBatchIdsApi: apiSuccessState(state.getBatchIdsApi, action),
    commentsFileApi: { loading: false },//apiInitialState(),
    reprocessUploadApi: apiInitialState(),
    currentBatchId: "",
    libraryFileApi: {
      loading: false,
    },
  };
}

export function getBatchIdsFail(state, action) {
  return {
    ...state,
    getBatchIdsApi: apiFailureState(state.getBatchIdsApi, action),
  };
}

export function setBatchId(state, action) {
  return {
    ...state,
    currentBatchId: action.event,
  };
}
