
export const handleDragEnd = (state, payload) => {
    return {
        ...state,
        trendPairingDataApi: {
            ...state.trendPairingDataApi,
            currentSurvey: payload.currentUpdatedSurvey
        }
    }
}

