import {
    HANDLE_CHANGE_CHECKBOX_FEEDBACK_360,
    HANDLE_CHANGE_PRIORITY_TEXT,
    HANDLE_DRAG_END_PRIORITY,
} from "../../../constants";


export const handleDragEndPriority = (payload, cb) => {
    return {
        type: HANDLE_DRAG_END_PRIORITY,
        payload
    };
}

export const handleChangePriorityText = (payload, cb) => {
    return {
        type: HANDLE_CHANGE_PRIORITY_TEXT,
        payload
    };
}

export const handleChangeNotAnswerNeedCheckBox = (payload, cb) => {
    return {
        type: HANDLE_CHANGE_CHECKBOX_FEEDBACK_360,
        payload
    }

}