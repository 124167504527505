import {
  apiFailureState,
} from "../../../../utils/apiStateUtils";
import { deepCopy } from "../../../../utils/deepCopy";
import { sortHrisFileList } from "../../../../utils/reducerOperations";

export const surveyWizardFetchHrisFileStart = (state, action) => {
  let payload = action.payload && action.payload.hrisFileId ? action.payload : {}
  let dropDownValue = getpresetDatatInDropDownFormat({ ...payload })
  return {
    ...state,
    surveyWizardHrisFIleApi: {
      ...state.surveyWizardHrisFIleApi,
      data: {
        response: {
          hrisFilesList: [
            { ...dropDownValue }
          ]
        }
      },
      isProcessing: true
    }
  };
}
export const surveyWizardFetchHrisFileSuccess = (state, action) => {
  const newPayload = sortHrisFileList(action.payload);
  // let checkAndAddHRIStoExistingList = checkAndGetHRISData(deepCopy(state),deepCopy(newPayload))
  return {
    ...state,
    surveyWizardHrisFIleApi: {
      ...state,
      isProcessing: false,
      error: false,
      data: newPayload.data,
      data_count: newPayload.count,
    }
  };
}

export const surveyWizardFetchHrisFileFail = (state, action) => {
  return {
    ...state,
    surveyWizardHrisFIleApi: apiFailureState(deepCopy(state.surveyWizardHrisFIleApi), action),
  };
}

const getpresetDatatInDropDownFormat = (payload) => {
  if (payload.hrisFileId) {
    return {
      hrisFileId: payload.hrisFileId,
      hrisFileName: payload.hrisFileName,
  }
  } else {
    return {}
  }
}

