import { deepCopy } from "../../../../../../utils/deepCopy"
import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../../utils/goalsUtils";

export const addMultipleChoiceOption = (state, payload) => {
    let optionAddedGoals = addDemographicQuestionToGoals(state.goals, payload)
    return (
        {
            ...state,
            goals: optionAddedGoals
        }
    )
}

const addDemographicQuestionToGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(item => {
        if (item.blockId === payload.blockId) {
            item.questions.forEach(question => {
                const otherOptionIndex = question.options.findIndex((opt) => opt.isOtherOption === true);
                const insertIndex = otherOptionIndex !== -1 ? otherOptionIndex : question.options.length;
                question.options.splice(insertIndex, 0, payload.data)
            })
        }

    })
    return goalsCopy
}

export const addFollowUpQuestionForMultiChoice = (state, payload) => {
    let updatedGoals = getFollowUpAddedGoals(state.goals, payload)
    return (
        {
            ...state,
            goals: updatedGoals
        }
    )
}

const getFollowUpAddedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            let followupQuestion = {}
            if (payload.matrixType) {
                followupQuestion = getMatrixFollowUpQuestion(element.questions[0].options[payload.index], element)
            }
            else if (payload.isRedirection) {
                followupQuestion = getRedirectFollowUpQuestion(element.questions[0].options[payload.index], element)
            }
            else {
                followupQuestion = getTextEntryFollowUpQuestion(element.questions[0].options[payload.index], element)
            }
            let followup = element.questions[0].options[payload.index].followUpQuestions && Array.isArray(element.questions[0].options[payload.index].followUpQuestions)
                ? [...element.questions[0].options[payload.index].followUpQuestions, followupQuestion]
                : [followupQuestion]
            element.questions[0].options[payload.index].followUpQuestions = followup
            element.questions[0].haveFollowUpQuestion = true
        }
    });
    return goalsCopy
}

export const getTextEntryFollowUpQuestion = (matrixData, topicDetails) => {
    return ({
        isMultipleOptionFollowUp: true,
        condition: [],
        dimension: "Comments",
        topicDimension: topicDetails.dimension,
        question: 'Any Comments?',
        options: [],
        type: "Text Entry",
        value: "",
        qid: uuidv4(),
        questionLabel: `${topicDetails.dimension} - Any Comments?`,
        isSelected: true,
        staticQuestionId: generateStaticQuestionId(`Any Comments${topicDetails.dimension}`),
        selectedTopic: topicDetails.dimension,
        selectedQid: topicDetails.questions[0].qid,
        goalId: `${topicDetails.goalId + uuidv4()}`,
        selectedGoalId: topicDetails.goalId,
        selectedBlockId: topicDetails.blockId,
        selectedId: matrixData.value,
    })
}

export const getMatrixFollowUpQuestion = (matrixData, blockData) => {
        return (
        {
            question: "",
            optionsCount: 5,
            options: [
                {
                    label: "Strongly disagree",
                    value: "1"
                },
                {
                    label: "Neither agree nor disagree",
                    value: "2"
                },
                {
                    label: "Strongly agree",
                    value: "3"
                }
            ],
            matrixQuestions: [
                {
                    selectedQuestionStaticId: matrixData.value,
                    selectedTopic: blockData.dimension,
                    staticQuestionId: generateStaticQuestionId(`Enter followup question${uuidv4()}`),
                    isSelected: true,
                    text: "Enter followup question",
                    value: uuidv4()
                },
            ],
            isMultipleOptionFollowUp: true,
            selectedGoalId: blockData.goalId,
            questionDimension: blockData.dimension,
            type: "Matrix",
            selectedId: matrixData.value,
            condition: [],
            qid: uuidv4(),
            goalId: `${blockData.goalId + uuidv4()}`,
            questionLabel: `${blockData.goalId}-follow-up`,
            selectedBlockId: blockData.blockId
        }
    )
}

export const getRedirectFollowUpQuestion = (matrixData, topicDetails) => {
    return ({
        isRedirectionPage: true,
        isMultipleOptionFollowUp: true,
        condition: [],
        dimension: "Redirection Followup",
        topicDimension: topicDetails.dimension,
        options: [],
        type: "redirectionFollowUp",
        value: "",
        qid: uuidv4(),
        staticQuestionId: generateStaticQuestionId(`Any Comments${topicDetails.dimension}`),
        selectedId: matrixData.value,
        selectedTopic: topicDetails.dimension,
        selectedQid: topicDetails.questions[0].qid,
        goalId: `${topicDetails.goalId + uuidv4()}`,
        selectedGoalId: topicDetails.goalId,
        selectedBlockId: topicDetails.blockId
    })
}