import qs from "query-string";
import { getDataFromUrlToken, encryptData } from "./urlDataStore";
import { URL_DATA } from "../../config/urlDataConstants";
import { deepCopy } from "./deepCopy";

export function nlpSetURLParams({ batch, filter, qId, outcome, params }) {
  const ob = qs.parseUrl(window.location.href).query;
  if (batch !== undefined) ob.batch = batch;
  if (qId !== undefined) {
    if (!qId) delete ob.qId;
    else ob.qId = qId;
  }
  if (filter !== undefined) {
    if (!filter) delete ob.filter;
    else ob.filter = filter;
  }
  if (outcome && outcome !== 'undefined') {
    ob.outcome = outcome
  }
  if (outcome === 'undefined') {
    ob.outcome = undefined
  }
  if (params) {
    ob.params = params
  }
  return "?" + qs.stringify(ob);
}

export function nlpGetURLParams() {
  const ob = qs.parseUrl(window.location.href).query;
  if (ob.filter) {
    ob.filter = JSON.parse(decodeURIComponent(ob.filter));
  } else {
    ob.filter = {};
  }
  return ob;
}

export function nlpGetBatchIdURLParams() {
  const ob = qs.parseUrl(window.location.href).query;
  if (ob.batch) {
    ob.batch = JSON.parse(decodeURIComponent(ob.batch));
  } else {
    ob.batch = {};
  }
  return ob;
}

export function setNLPFilterURL({ group, item }, history) {
  const params = nlpGetURLParams();
  params.filter[group] = params.filter[group] || [];
  const index = params.filter[group].indexOf(item);
  if (index > -1) {
    params.filter[group].splice(index, 1);
    if (!params.filter[group].length) {
      delete params.filter[group];
    }
  } else params.filter[group].push(item);
  let filter = encodeURIComponent(JSON.stringify(params.filter));
  if (!Object.keys(params.filter).length) filter = "";
  history.push({ search: nlpSetURLParams({ filter }) });
}

export function removeNlpFilterGroup(groupName, history) {
  const params = nlpGetURLParams();
  if (params.filter[groupName]) {
    delete params.filter[groupName];
    let filter = encodeURIComponent(JSON.stringify(params.filter));
    if (!Object.keys(params.filter).length) {
      filter = "";
    }
    history.push({ search: nlpSetURLParams({ filter }) });
  }
}

export function convertFilterForDisplay() {
  const res = [];
  const { filter } = nlpGetURLParams();
  Object.keys(filter).forEach((label) => {
    (filter[label] || []).forEach((item) => {
      res.push({
        text: label,
        value: item,
        uniqueFilterKey: item,
      });
    });
  });
  return res;
}

export function setDashBoardFilterURLParams(filters, history, showStoryboard) {
  let storyBoardShowData = showStoryboard
  if(showStoryboard===undefined){
    storyBoardShowData = getDataFromUrlToken(URL_DATA.SHOW_STORYBOARD)
  }
  let encryptedUrlParams = encryptData({ dashboardFilter: filters, showStoryboard: storyBoardShowData });
  history.push({ search: nlpSetURLParams({ params: encryptedUrlParams }) });
}

export const onCloseSingleFilter = ({ group, item }, history, showStoryboard) => {
  let urlFilters = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
  let storyBoardShowData = showStoryboard
  let filters = deepCopy(urlFilters) || {};
  if (group) {
    filters[group] = (urlFilters && urlFilters[group]) || [];
    const index = filters[group].indexOf(item);
    if (index > -1) {
      filters[group].splice(index, 1);
      if (!filters[group].length) {
        delete filters[group];
      }
    } else filters[group].push(item);
  }
  if(showStoryboard===undefined){
    storyBoardShowData = getDataFromUrlToken(URL_DATA.SHOW_STORYBOARD)
  }
  let filter = {};
  if (Object.keys(filters).length) filter = filters;
  let encryptedUrlParams = encryptData({ dashboardFilter: filter, showStoryboard: storyBoardShowData });
  history.push({ search: nlpSetURLParams({ params: encryptedUrlParams }) });
}

export function setTreeDataToUrl(data, history) {
  let encryptedUrlParams = encryptData({ [URL_DATA.TREE_DATA]: data });
  history.push({ search: nlpSetURLParams({ params: encryptedUrlParams }) });
}

export function convertDashboardFilterForDisplay(filter) {
  const res = [];
  let urlFilters = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
  if (filter) {
    urlFilters = filter
  }
  if (urlFilters) {
    Object.keys(urlFilters).forEach((label) => {
      (urlFilters[label] || []).forEach((item) => {
        res.push({
          text: label,
          value: item,
          uniqueFilterKey: item,
        });
      });
    });
  }
  return res;
}