import {
    fetchPayload,
    update
} from '../../../utils/reducerOperations';
import {Default} from './InitialState';
import {
    GET_ORGANZATION_PROFILE_FAILED,
    GET_ORGANZATION_PROFILE_STARTED,
    GET_ORGANZATION_PROFILE_SUCCESS
} from '../../constants';

import {
    getOrganizationProfileFailed,
    getOrganizationProfileStarted,
    getOrganizationProfileSuccess
} from './Helpers';
const organizationPageReducer = (state = Default(), action) => {
    let orgPayload = fetchPayload(action);
    switch (action.type) {
    case GET_ORGANZATION_PROFILE_STARTED:
        return update(
            state,
            getOrganizationProfileStarted(state, orgPayload)
        );
    case GET_ORGANZATION_PROFILE_SUCCESS:
        return update(
            state,
            getOrganizationProfileSuccess(state, orgPayload)
        );
    default:
        return organizationOne(state, action);
    }
};
const organizationOne = (state, action) => {
    let orgOnePayload = fetchPayload(action);
    switch (action.type) {
    case GET_ORGANZATION_PROFILE_FAILED:
        return update(
            state,
            getOrganizationProfileFailed(state, orgOnePayload)
        );
    default:
        return {...state};
    }
};
export default organizationPageReducer;
