import { fetchPayload } from "../../../utils/reducerOperations";
import * as helper from "./Helpers";
import {
  FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_FAIL,
  FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_SUCCESS,
  FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_START,
} from "../../constants";
import { InitialState } from "./InitialState";

const NewDashboardExploreTrendInsightsReducer = (state = InitialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_START:
      return helper.fetchExploreTrendInsightsDataStart(state, payload);
    case FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_SUCCESS:
      return helper.fetchExploreTrendInsightsDataSuccess(state, payload);
    case FETCH_NEW_DASHBOARD_EXPLORE_TREND_INSIGHTS_DATA_FAIL:
      return helper.fetchExploreTrendInsightsDataFailed(state, payload);
   
    default:
      return { ...state };
  }
};

export default NewDashboardExploreTrendInsightsReducer;