import { fetchPayload } from "../../../utils/reducerOperations";
import {
  FETCH_EXPLORE_COMMENTS_WORDCLOUD_DATA_START,
  FETCH_EXPLORE_COMMENTS_WORDCLOUD_DATA_SUCCESS,
  FETCH_EXPLORE_COMMENTS_WORDCLOUD_DATA_FAILED,
  FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_START,
  FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_SUCCESS,
  FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_FAILED,
  FETCH_EXPLORE_COMMENTS_DATA_START,
  FETCH_EXPLORE_COMMENTS_DATA_SUCCESS,
  FETCH_EXPLORE_COMMENTS_DATA_FAILED,
  FETCH_EXPLORE_RELATION_DATA_START,
  FETCH_EXPLORE_RELATION_DATA_SUCCESS,
  FETCH_EXPLORE_RELATION_DATA_FAILED,
  FETCH_EXPLORE_TOPIC_COMMENTS_DATA_START,
  FETCH_EXPLORE_TOPIC_COMMENTS_DATA_SUCCESS,
  FETCH_EXPLORE_TOPIC_COMMENTS_DATA_FAILED,
  FETCH_ALLTHEME_DATA_START,
  FETCH_ALLTHEME_DATA_SUCCESS,
  FETCH_ALLTHEME_DATA_FAIL,
  FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_START,
  FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_SUCCESS,
  FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_FAIL,
  UPDATE_THEME_STATE_VALUES,
  SET_NEWDAHSBOARD_COMMENT_QUESTION_INITIAL_STATE,
  FETCH_DASHBOARD_EXPLORE_FILTER_START,
  FETCH_DASHBOARD_EXPLORE_FILTER_SUCCESS,
  FETCH_DASHBOARD_EXPLORE_FILTER_FAILED,
  CHANGE_DASHBOARD_EXPLORE_SEARCH_FILTER,
  SET_SELECTED_QUESTION_TOPIC
} from "../../constants";
import {
  fetchExploreWordcloudDataStart,
  fetchExploreWordcloudDataSuccess,
  fetchExploreWordcloudDataFailed,
  fetchCommentStatisticsStart,
  fetchCommentStatisticsSuccess,
  fetchCommentStatisticsFailed,
  exploreQuestionCommentsStart,
  exploreQuestionCommentsSuccess,
  exploreQuestionCommentsFailed,
  fetchExploreRelationDataStart,
  fetchExploreRelationDataSuccess,
  fetchExploreRelationDataFailed,
  exploreTopicCommentsStart,
  exploreTopicCommentsSuccess,
  exploreTopicCommentsFailed,
  fetchAllThemeStart,
  fetchAllThemeSuccess,
  fetchAllThemeFailed,
  fetchThemeTopicDataStart,
  fetchThemeTopicDataSuccess,
  fetchThemeTopicDataFailed,
  updateThemeState,
  setInitialState,
  fetchExploreDashBoardFilterStart,
  fetchExploreDashBoardFilterSuccess,
  fetchExploreDashBoardFilterFail,
  changeExploreDashboardSearchFilter,
  setSelectedTopicForQuestion
} from "./Helper";
import { InitialState } from "./InitialState";

const FetchExploreCommentQustionReducer = (state = InitialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_EXPLORE_COMMENTS_WORDCLOUD_DATA_START:
      return fetchExploreWordcloudDataStart(state, payload);
    case FETCH_EXPLORE_COMMENTS_WORDCLOUD_DATA_SUCCESS:
      return fetchExploreWordcloudDataSuccess(state, payload);
    case FETCH_EXPLORE_COMMENTS_WORDCLOUD_DATA_FAILED:
      return fetchExploreWordcloudDataFailed(state, payload);
    case FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_START:
      return fetchCommentStatisticsStart(state, payload);
    case FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_SUCCESS:
      return fetchCommentStatisticsSuccess(state, payload);
    case FETCH_EXPLORE_COMMENTS_STATISTICS_DATA_FAILED:
      return fetchCommentStatisticsFailed(state, payload);
    case FETCH_EXPLORE_COMMENTS_DATA_START:
      return exploreQuestionCommentsStart(state, payload);
    case FETCH_EXPLORE_COMMENTS_DATA_SUCCESS:
      return exploreQuestionCommentsSuccess(state, payload);
    case FETCH_EXPLORE_COMMENTS_DATA_FAILED:
      return exploreQuestionCommentsFailed(state, payload);
    case FETCH_EXPLORE_RELATION_DATA_START:
      return fetchExploreRelationDataStart(state, payload);
    case FETCH_EXPLORE_RELATION_DATA_SUCCESS:
      return fetchExploreRelationDataSuccess(state, payload);
    case FETCH_EXPLORE_RELATION_DATA_FAILED:
      return fetchExploreRelationDataFailed(state, payload);
    case FETCH_EXPLORE_TOPIC_COMMENTS_DATA_START:
      return exploreTopicCommentsStart(state, payload);
    case FETCH_EXPLORE_TOPIC_COMMENTS_DATA_SUCCESS:
      return exploreTopicCommentsSuccess(state, payload);
    case FETCH_EXPLORE_TOPIC_COMMENTS_DATA_FAILED:
      return exploreTopicCommentsFailed(state, payload);
    case FETCH_ALLTHEME_DATA_START:
      return fetchAllThemeStart(state, payload);
    case FETCH_ALLTHEME_DATA_SUCCESS:
      return fetchAllThemeSuccess(state, payload);
    case FETCH_ALLTHEME_DATA_FAIL:
      return fetchAllThemeFailed(state, payload);
    case FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_START:
      return fetchThemeTopicDataStart(state, payload);
    case FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_SUCCESS:
      return fetchThemeTopicDataSuccess(state, payload);
    case FETCH_COMMENT_QUESTION_DEMOGRAPHIC_TOPIC_DATA_FAIL:
      return fetchThemeTopicDataFailed(state, payload);
    case UPDATE_THEME_STATE_VALUES:
      return updateThemeState(state, payload);
    case SET_NEWDAHSBOARD_COMMENT_QUESTION_INITIAL_STATE:
      return setInitialState(state, payload);
    case FETCH_DASHBOARD_EXPLORE_FILTER_START:
      return fetchExploreDashBoardFilterStart(state, payload);
    case FETCH_DASHBOARD_EXPLORE_FILTER_SUCCESS:
      return fetchExploreDashBoardFilterSuccess(state, payload);
    case FETCH_DASHBOARD_EXPLORE_FILTER_FAILED:
      return fetchExploreDashBoardFilterFail(state, payload);
    case CHANGE_DASHBOARD_EXPLORE_SEARCH_FILTER:
      return changeExploreDashboardSearchFilter(state, payload);
    case SET_SELECTED_QUESTION_TOPIC:
      return setSelectedTopicForQuestion(state, payload);       
    default:
      return { ...state };
  }
};

export default FetchExploreCommentQustionReducer;
