export const fetchDimensionsStarted = (state) => {
    return {
      ...state,
      driverDimensionsApi: {
        isProcessing: true,
        error: false,
        errorMessage: "",
        isInitialApiCalled: false,
        dimensions: [],
      },
    };
  };
  
  export const fetchDimensionsSuccess = (state, payload) => {
    const data = payload.data;
    return {
      ...state,
      driverDimensionsApi: {
        isProcessing: false,
        error: false,
        errorMessage: "",
        isInitialApiCalled: true,
        dimensions: data,
      },
      topic: payload.topic,
    };
  };
  
  export const fetchDimensionsFailed = (state, payload) => {
    return {
      ...state,
      driverDimensionsApi: {
        isProcessing: false,
        error: true,
        errorMessage: payload.errorMessage,
        isInitialApiCalled: false,
        dimensions: [],
      },
    };
  };