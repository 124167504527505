import { matchPath } from 'react-router'

export const getIndexRoute = (pathName = window.location.pathname) => {
  let routeItems = pathName.split("/");
  return routeItems[routeItems.length - 1];
};

export const getCurrentPath = getIndexRoute;

export const getOrgIdFromUrl = (currentPath) => {
  const defaultPath = '/home/:org_id/'
  let path = matchPath(window.location.pathname, {
    path: currentPath || defaultPath
  })
  if (!path || !path.params) {
    path = matchPath(window.location.pathname, {
      path: '/admin/home/organizations/:org_id/'
    })
  }
  const params = path && path.params
  return params ? params.org_id : undefined
}
export const getTabIdFromUrl = (currentPath) => {
  const defaultPath = '/home/:org_id/'
  let path = matchPath(window.location.pathname, {
    path: currentPath || defaultPath
  })
  if (!path || !path.params) {
    path = matchPath(window.location.pathname, {
      path: '/admin/home/organizations/:org_id/'
    })
  }
  const params = path && path.params
  return params ? params.org_id : undefined
}