import React, { Component } from 'react';
import FeedBackModal from '../../FeedBackModal/FeedBackModal'
import SVGIcons from "../../../Components/SVGIcons";
class MailToLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false
        };
    }
    showSendFeedBackMailModal = () => {
        this.setState({
            isModalVisible: true
        })
    }

    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }

    render() {
        return (
            <>
                <a href={false} onClick={this.showSendFeedBackMailModal}>
                    <SVGIcons type={'SVG-email'} />
                    {this.props.text ? this.props.text : "support@culturebie.com"}
                </a>
                <FeedBackModal
                    isModalVisible={this.state.isModalVisible}
                    handleCancel={this.handleCancel}
                />
            </>
        );
    }
}

export default MailToLink;