import axios from 'axios';
export const UploadFile = uploadParams =>
    axios.put(
        uploadParams.url,
        uploadParams.file,
        uploadParams.options
    );

export const getKeyNameForFileUpload = (fileUrl) => {
    let fileName = fileUrl || "";
    if (window.location.hostname === "app.culturebie.com" ||
        window.location.hostname === "www.app.culturebie.com") {
        return fileName && fileName.split("/culturebie-uploadbucket/")[1];
    } else if (window.location.hostname === "qa.culturebie.com" ||
        window.location.hostname === "www.qa.culturebie.com") {
        return fileName && fileName.split("/culturebieqa-uploadbucket/")[1];
    } else {
        return fileName && fileName.split("/culturebiedev-uploadbucket/")[1];
    }
};
