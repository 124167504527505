import {
    MANAGE_USERS_FETCH_HRIS_FILE_NAME_STARTED,
    MANAGE_USERS_FETCH_HRIS_FILE_NAME_SUCCESS,
    MANAGE_USERS_FETCH_HRIS_FILE_NAME_FAILED,
  } from "../../../constants";
  
  import { ApiBase } from "../../../../utils/apiBase";
import { FETCH_HRIS_DETAILS_URL } from "../../../../../config/constants";
  import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const NewdashboardFetchHRISFileName = (payload) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(withType(MANAGE_USERS_FETCH_HRIS_FILE_NAME_STARTED, {}));
    return apiBaseInstance
      .post(FETCH_HRIS_DETAILS_URL, {
        ...payload,
        ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        dispatch(
          withType(MANAGE_USERS_FETCH_HRIS_FILE_NAME_SUCCESS, {
            data: res.data,
          })
        );
      })
      .catch((err) => {
        dispatch(withType(MANAGE_USERS_FETCH_HRIS_FILE_NAME_FAILED, err));
      });
  };
};