import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const feedbackIdeasComments180 = {
    "blockId": uuidv4(),
    "goalId": "comments",
    "isTemplate": true,
    "questions": [
        {
            "staticQuestionId": generateStaticQuestionId("Please share a couple feedforward ideas or advice for what can specifically be done to improve (i.e., offer some positive suggestions for the future)."),
            "question": "Please share a couple feedforward ideas or advice for what can specifically be done to improve (i.e., offer some positive suggestions for the future).",
            "HTMLQuestion": "<p>Please share a couple feedforward ideas or advice for what can specifically be done to improve (i.e., offer some positive suggestions for the future).</p>",
            "HTMLSelfQuestion": "<p>As you are reflecting on your own leadership, imagine you are giving advice to someone else in your situation. What positive suggestions for the future would you give them to improve the focus areas identified?</p>",
            "selfQuestion": "As you are reflecting on your own leadership, imagine you are giving advice to someone else in your situation. What positive suggestions for the future would you give them to improve the focus areas identified?",
            "options": [],
            "isSelected": true,
            "type": "Text Entry",
            "qid": uuidv4(),
            "questionLabel": ""
        }
    ],
    "isSelected": true,
    "isTextEntryOnly": true,
    "type": "textEntry",
    "dimension": "Comments"
}