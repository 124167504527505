import moment from "moment"
import { DYNAMIC_360_GOAL_IDS, FEEDBACK_360_SURVEY } from "../../../../../config/questionTypeConstants"
import { deepCopy } from "../../../../utils/deepCopy"
import { checkIfJson, isAppliedProjectType } from "../../../../utils/newAttendSurveyUtils"
import { STRENGTH_VALUES } from "../constants"

export const onChangeTableMatrix = (state, payload) => {
    let updatedQuestionSet = getAnsweredGoal(state, payload)
    return {
        ...state,
        questionSet: updatedQuestionSet.questionSetCopy,
        pageBreakAddedQuestionSet: updatedQuestionSet.pageBreakBlockCopy,
        hideErrorBlock: true,
    }
}
const getAnsweredGoal = (state, payload) => {
    let questionSetCopy = deepCopy(state.questionSet)
    let pageBreakBlockCopy = deepCopy(state.pageBreakAddedQuestionSet)
    let answer = []

    questionSetCopy.forEach((item, index) => {
        if (item.blockId === payload.blockId) {
            item.questions.forEach(question => {
                question.answer = JSON.stringify(generateAnswer(question, payload))
                answer = JSON.stringify(generateAnswer(question, payload))
                if (item.goalId === DYNAMIC_360_GOAL_IDS.STRENGTH || item.goalId === DYNAMIC_360_GOAL_IDS.STRENGTH_180) {
                    let questionSetStrengthDragIndex = questionSetCopy.findIndex((item) => item.goalId === DYNAMIC_360_GOAL_IDS.STRENGTH_DRAG || item.goalId === DYNAMIC_360_GOAL_IDS.STRENGTH_DRAG_180)
                    let questionSetImprovementDragIndex = questionSetCopy.findIndex((item) => item.goalId === DYNAMIC_360_GOAL_IDS.IMPROVEMENT_DRAG || item.goalId === DYNAMIC_360_GOAL_IDS.IMPROVEMENT_DRAG_180)


                    let findedDragIndex = pageBreakBlockCopy.findIndex((item) => item.goalId === DYNAMIC_360_GOAL_IDS.STRENGTH_DRAG ||item.goalId === DYNAMIC_360_GOAL_IDS.STRENGTH_DRAG_180)
                    let findedImprovementDragIndex = pageBreakBlockCopy.findIndex((item) => item.goalId === DYNAMIC_360_GOAL_IDS.IMPROVEMENT_DRAG || item.goalId === DYNAMIC_360_GOAL_IDS.IMPROVEMENT_DRAG_180)
                    let questionBlockStrength = pageBreakBlockCopy[findedDragIndex].questions[0] ? pageBreakBlockCopy[findedDragIndex].questions[0] : {}
                    let questionBlockImprovement=pageBreakBlockCopy[findedImprovementDragIndex].questions[0]?pageBreakBlockCopy[findedImprovementDragIndex].questions[0]:{}
                    let questionSetBlockStrength = questionSetCopy[questionSetStrengthDragIndex].questions[0] ? questionSetCopy[questionSetStrengthDragIndex].questions[0] : {}
                    let questionSetBlockImprovement = questionSetCopy[questionSetImprovementDragIndex].questions[0] ? questionSetCopy[questionSetImprovementDragIndex].questions[0] : {}
                    let answeredRadioObject = payload.answeredRadio;
                    //New if condition for applied materials survey -- Type of survey
                    //Change the condition here, if need to apply generic logic instead of based on projectType--- key found in strength scale and improvement scale objects
                    if (state.surveyQuestionSetApi.data.projectType && isAppliedProjectType(state.surveyQuestionSetApi.data.projectType)) {
                        let strengthColoumnIdsList = [ "5", "6"]
                        let improvementColoumnIdsList = ["1", "2",]
                        if (state.surveyQuestionSetApi.data.projectCreationDate && !moment(state.surveyQuestionSetApi.data.projectCreationDate).isBefore('2024-05-29T12:21:02Z')) {
                            improvementColoumnIdsList = ["1", "2", "3", "4"]
                            strengthColoumnIdsList = ["5", "6"]
                        }

                        Object.keys(questionBlockImprovement).map(item => {
                            Array.isArray(questionBlockImprovement[item].questions) && questionBlockImprovement[item].questions.map((question, id) => {
                                if (payload.answeredRadio.rowId === question.id) {
                                    questionBlockImprovement[item].questions.splice(id, 1)
                                    questionSetBlockImprovement[item].questions.splice(id, 1)
                                }
                            })
                            if (questionBlockImprovement[item].questions && questionBlockImprovement[item].questions.length == 0) {
                                delete questionBlockImprovement[item];
                                delete questionSetBlockImprovement[item];
                            }
                        })

                        Object.keys(questionBlockStrength).map(item => {
                            Array.isArray(questionBlockStrength[item].questions) && questionBlockStrength[item].questions.map((question, id) => {
                                if (payload.answeredRadio.rowId === question.id) {
                                    questionBlockStrength[item].questions.splice(id, 1)
                                    questionSetBlockStrength[item].questions.splice(id, 1)
                                }
                            })
                            if (questionBlockStrength[item].questions && questionBlockStrength[item].questions.length == 0) {
                                delete questionBlockStrength[item];
                                delete questionSetBlockStrength[item];
                            }
                        })

                        //rearrange the strength and improvement block so that to make it to uptodate everytime
                        if (questionBlockImprovement[payload.blockId]) {
                            questionBlockImprovement[payload.blockId] = { answerLabel: questionBlockImprovement[payload.blockId].answerLabel ? questionBlockImprovement[payload.blockId].answerLabel : "", questions: questionBlockImprovement[payload.blockId].questions ? questionBlockImprovement[payload.blockId].questions : [], ...generateTopicWiseGrouping(questionBlockImprovement[payload.blockId],), }

                        }
                        if (questionBlockStrength[payload.blockId]) {
                            questionBlockStrength[payload.blockId] = { answerLabel: questionBlockStrength[payload.blockId].answerLabel ? questionBlockStrength[payload.blockId].answerLabel : "", questions: questionBlockStrength[payload.blockId].questions ? questionBlockStrength[payload.blockId].questions : [], ...generateTopicWiseGrouping(questionBlockStrength[payload.blockId],), }

                        }


                        if (pageBreakBlockCopy[findedDragIndex].questions && pageBreakBlockCopy[findedDragIndex].questions[0] && pageBreakBlockCopy[findedDragIndex].questions[0].answer) {
                            if (checkAnswerRearrangementNeeded(pageBreakBlockCopy[findedDragIndex].questions[0], answeredRadioObject.rowId)) {
                                questionBlockStrength.answer = JSON.stringify([])
                                let findedComments = pageBreakBlockCopy.findIndex((item) => item.goalId === FEEDBACK_360_SURVEY.COMMENTS_ON_STRENGTH)
                                //let questionArray = pageBreakBlockCopy[findedDragIndex].questions[0] && pageBreakBlockCopy[findedDragIndex].questions[0].answer ? JSON.parse(pageBreakBlockCopy[findedDragIndex].questions[0].answer) : []

                                let questionArray = questionBlockStrength.answer ? JSON.parse(questionBlockStrength.answer) : []
                                let updatedQuestionsArray = questionArray.map((item) => {
                                    return {
                                        id: item.id,
                                        label: item.label,
                                        question: item.question,
                                        questionDimension: item.questionDimension,
                                        selectedLabelAswer: item.selectedLabelAswer,
                                        staticQuestionId: item.staticQuestionId,
                                        priorityValue: item.answer,
                                        type: "Text Entry",
                                        englishQuestion: item.question,
                                        qid: item.id,
                                        answer: ""
                                    }
                                })
                                pageBreakBlockCopy[findedComments].questions = updatedQuestionsArray
                            }
                        }
                        if (pageBreakBlockCopy[findedImprovementDragIndex].questions && pageBreakBlockCopy[findedImprovementDragIndex].questions[0] && pageBreakBlockCopy[findedImprovementDragIndex].questions[0].answer) {
                            if (checkAnswerRearrangementNeeded(pageBreakBlockCopy[findedImprovementDragIndex].questions[0], answeredRadioObject.rowId)) {
                                questionBlockImprovement.answer = JSON.stringify([])
                                let findedCommentsDragIndex = pageBreakBlockCopy.findIndex((item) => item.goalId === FEEDBACK_360_SURVEY.COMMENTS_ON_IMPROVEMENT)
                                //  let questionArray = pageBreakBlockCopy[findedImprovementDragIndex].questions[0] && pageBreakBlockCopy[findedImprovementDragIndex].questions[0].answer ? JSON.parse(pageBreakBlockCopy[findedImprovementDragIndex].questions[0].answer) : []

                                let questionArray = questionBlockImprovement.answer ? JSON.parse(questionBlockImprovement.answer) : []
                                let updatedQuestionsArray = questionArray.map((item) => {
                                    return {
                                        id: item.id,
                                        label: item.label,
                                        question: item.question,
                                        questionDimension: item.questionDimension,
                                        selectedLabelAswer: item.selectedLabelAswer,
                                        staticQuestionId: item.staticQuestionId,
                                        priorityValue: item.answer,
                                        type: "Text Entry",
                                        englishQuestion: item.question,
                                        qid: item.id,
                                        answer: ""
                                    }
                                })
                                pageBreakBlockCopy[findedCommentsDragIndex].questions = updatedQuestionsArray
                                pageBreakBlockCopy[findedImprovementDragIndex].questions[0] = questionBlockImprovement
                                questionSetCopy[questionSetImprovementDragIndex].questions[0] = questionBlockImprovement
                            }
                        }
                        if (answeredRadioObject.columnId !== "0") {
                            if (strengthColoumnIdsList.includes(answeredRadioObject.columnId)) {
                                if (pageBreakBlockCopy[findedDragIndex].questions) {
                                    let columnId = payload.blockId;
                                    if (!questionBlockStrength[columnId]) {
                                        questionBlockStrength[columnId] = {
                                            answerLabel: answeredRadioObject.questionDimension,
                                            questions: [{
                                                question: answeredRadioObject.question,
                                                label: answeredRadioObject.label,
                                                selectedLabelAswer: payload.answeredRadio.columnId,
                                                id: answeredRadioObject.rowId,
                                                questionDimension: answeredRadioObject.questionDimension,
                                                staticQuestionId: answeredRadioObject.staticQuestionId,
                                                ...answeredRadioObject.extraTranslationKeys
                                            }]
                                        };
                                    }
                                    else {
                                        questionBlockStrength[columnId] = { ...questionBlockStrength[columnId], questions: [...questionBlockStrength[columnId].questions] }
                                        questionBlockStrength[columnId].questions.push({
                                            question: answeredRadioObject.question,
                                            label: answeredRadioObject.label, selectedLabelAswer: payload.answeredRadio.columnId, id: answeredRadioObject.rowId, questionDimension: answeredRadioObject.questionDimension, staticQuestionId: answeredRadioObject.staticQuestionId, ...answeredRadioObject.extraTranslationKeys
                                        })
                                    }
                                    questionBlockStrength[columnId] = { answerLabel: questionBlockStrength[columnId].answerLabel ? questionBlockStrength[columnId].answerLabel : "", questions: questionBlockStrength[columnId].questions ? questionBlockStrength[columnId].questions : [], ...generateTopicWiseGrouping(questionBlockStrength[columnId], columnId), }

                                    pageBreakBlockCopy[findedDragIndex].questions[0] = questionBlockStrength
                                    questionSetCopy[questionSetStrengthDragIndex].questions[0] = questionBlockStrength
                                }
                            }
                            else {
                                if(improvementColoumnIdsList.includes(answeredRadioObject.columnId)){
                                    if (pageBreakBlockCopy[findedImprovementDragIndex].questions) {
                                        let columnId = payload.blockId;
                                        if (!questionBlockImprovement[columnId]) {
                                            questionBlockImprovement[columnId] = {
                                                answerLabel: answeredRadioObject.questionDimension,
                                                questions: [{
                                                    question: answeredRadioObject.question,
                                                    label: answeredRadioObject.label,
                                                    selectedLabelAswer: payload.answeredRadio.columnId,
                                                    id: answeredRadioObject.rowId,
                                                    questionDimension: answeredRadioObject.questionDimension,
                                                    staticQuestionId: answeredRadioObject.staticQuestionId,
                                                    ...answeredRadioObject.extraTranslationKeys
                                                }]
                                            };
                                        }
                                        else {
                                            questionBlockImprovement[columnId] = { ...questionBlockImprovement[columnId], questions: [...questionBlockImprovement[columnId].questions] }
                                            questionBlockImprovement[columnId].questions.push({
                                                question: answeredRadioObject.question,
                                                label: answeredRadioObject.label, selectedLabelAswer: payload.answeredRadio.columnId, id: answeredRadioObject.rowId, questionDimension: answeredRadioObject.questionDimension, staticQuestionId: answeredRadioObject.staticQuestionId, ...answeredRadioObject.extraTranslationKeys
                                            })
                                        }
                                        questionBlockImprovement[columnId] = { answerLabel: questionBlockImprovement[columnId].answerLabel ? questionBlockImprovement[columnId].answerLabel : "", questions: questionBlockImprovement[columnId].questions ? questionBlockImprovement[columnId].questions : [], ...generateTopicWiseGrouping(questionBlockImprovement[columnId], columnId), }
                                        pageBreakBlockCopy[findedImprovementDragIndex].questions[0] = questionBlockImprovement
                                        questionSetCopy[questionSetImprovementDragIndex].questions[0] = questionBlockImprovement
                                        questionSetCopy[questionSetStrengthDragIndex].questions[0] = questionBlockStrength
                                        pageBreakBlockCopy[findedDragIndex].questions[0] = questionBlockStrength
                                    }
                                }
                             
                            }
                        }
                        else {
                            pageBreakBlockCopy[findedDragIndex].questions[0] = questionBlockStrength
                            pageBreakBlockCopy[findedImprovementDragIndex].questions[0] = questionBlockImprovement
                            questionSetCopy[questionSetImprovementDragIndex].questions[0] = questionBlockImprovement
                            questionSetCopy[questionSetStrengthDragIndex].questions[0] = questionBlockStrength
                        }

                    }



                    else {
                        Object.keys(questionBlockImprovement).map(item => {
                            Array.isArray(questionBlockImprovement[item].questions) && questionBlockImprovement[item].questions.map((question, id) => {
                                if (payload.answeredRadio.rowId === question.id) {
                                    questionBlockImprovement[item].questions.splice(id, 1)
                                    questionSetBlockImprovement[item].questions.splice(id, 1)
                                }
                            })
                            if (questionBlockImprovement[item].questions && questionBlockImprovement[item].questions.length == 0) {
                                delete questionBlockImprovement[item];
                                delete questionSetBlockImprovement[item];
                            }
                        })

                        Object.keys(questionBlockStrength).map(item => {
                            Array.isArray(questionBlockStrength[item].questions) && questionBlockStrength[item].questions.map((question, id) => {
                                if (payload.answeredRadio.rowId === question.id) {
                                    questionBlockStrength[item].questions.splice(id, 1)
                                    questionSetBlockStrength[item].questions.splice(id, 1)
                                }
                            })
                            if (questionBlockStrength[item].questions && questionBlockStrength[item].questions.length == 0) {
                                delete questionBlockStrength[item];
                                delete questionSetBlockStrength[item];
                            }
                        })
                        if (pageBreakBlockCopy[findedDragIndex].questions && pageBreakBlockCopy[findedDragIndex].questions[0] && pageBreakBlockCopy[findedDragIndex].questions[0].answer) {
                            if (checkAnswerRearrangementNeeded(pageBreakBlockCopy[findedDragIndex].questions[0], answeredRadioObject.rowId)) {
                                questionBlockStrength.answer = JSON.stringify([])
                                let findedComments = pageBreakBlockCopy.findIndex((item) => item.goalId === FEEDBACK_360_SURVEY.COMMENTS_ON_STRENGTH)
                                let questionArray = pageBreakBlockCopy[findedDragIndex].questions[0] && pageBreakBlockCopy[findedDragIndex].questions[0].answer ? JSON.parse(pageBreakBlockCopy[findedDragIndex].questions[0].answer) : []
                                let updatedQuestionsArray = questionArray.map((item) => {
                                    return {
                                        id: item.id,
                                        label: item.label,
                                        question: item.question,
                                        questionDimension: item.questionDimension,
                                        selectedLabelAswer: item.selectedLabelAswer,
                                        staticQuestionId: item.staticQuestionId,
                                        priorityValue: item.answer,
                                        type: "Text Entry",
                                        englishQuestion: item.question,
                                        qid: item.id,
                                        answer: ""
                                    }
                                })
                                pageBreakBlockCopy[findedComments].questions = updatedQuestionsArray
                            }
                        }
                        if (pageBreakBlockCopy[findedImprovementDragIndex].questions && pageBreakBlockCopy[findedImprovementDragIndex].questions[0] && pageBreakBlockCopy[findedImprovementDragIndex].questions[0].answer) {
                            if (checkAnswerRearrangementNeeded(pageBreakBlockCopy[findedImprovementDragIndex].questions[0], answeredRadioObject.rowId)) {
                                questionBlockImprovement.answer = JSON.stringify([])
                                let findedCommentsDragIndex = pageBreakBlockCopy.findIndex((item) => item.goalId === FEEDBACK_360_SURVEY.COMMENTS_ON_IMPROVEMENT)
                                let questionArray = pageBreakBlockCopy[findedImprovementDragIndex].questions[0] && pageBreakBlockCopy[findedImprovementDragIndex].questions[0].answer ? JSON.parse(pageBreakBlockCopy[findedImprovementDragIndex].questions[0].answer) : []
                                let updatedQuestionsArray = questionArray.map((item) => {
                                    return {
                                        id: item.id,
                                        label: item.label,
                                        question: item.question,
                                        questionDimension: item.questionDimension,
                                        selectedLabelAswer: item.selectedLabelAswer,
                                        staticQuestionId: item.staticQuestionId,
                                        priorityValue: item.answer,
                                        type: "Text Entry",
                                        englishQuestion: item.question,
                                        qid: item.id,
                                        answer: ""
                                    }
                                })
                                pageBreakBlockCopy[findedCommentsDragIndex].questions = updatedQuestionsArray
                                pageBreakBlockCopy[findedImprovementDragIndex].questions[0] = questionBlockImprovement
                                questionSetCopy[questionSetImprovementDragIndex].questions[0] = questionBlockImprovement
                            }
                        }
                        if (answeredRadioObject.columnId !== "0") {
                            if (answeredRadioObject.columnId === "3" || answeredRadioObject.columnId === "4") {
                                if (pageBreakBlockCopy[findedDragIndex].questions) {
                                    let columnId = payload.answeredRadio.columnId;
                                    if (!questionBlockStrength[columnId]) {
                                        questionBlockStrength[columnId] = {
                                            answerLabel: answeredRadioObject.label,
                                            questions: [{
                                                question: answeredRadioObject.question,
                                                label: answeredRadioObject.label,
                                                selectedLabelAswer: columnId,
                                                id: answeredRadioObject.rowId,
                                                questionDimension: answeredRadioObject.questionDimension,
                                                staticQuestionId: answeredRadioObject.staticQuestionId,
                                                ...answeredRadioObject.extraTranslationKeys

                                            }]
                                        };
                                    }
                                    else {
                                        questionBlockStrength[columnId] = { ...questionBlockStrength[columnId], questions: [...questionBlockStrength[columnId].questions] }
                                        questionBlockStrength[columnId].questions.push({
                                            question: answeredRadioObject.question,
                                            label: answeredRadioObject.label, selectedLabelAswer: columnId, id: answeredRadioObject.rowId, questionDimension: answeredRadioObject.questionDimension, staticQuestionId: answeredRadioObject.staticQuestionId, ...answeredRadioObject.extraTranslationKeys
                                        })
                                    }
                                    pageBreakBlockCopy[findedDragIndex].questions[0] = questionBlockStrength
                                    questionSetCopy[questionSetStrengthDragIndex].questions[0] = questionBlockStrength
                                }
                            }
                            else {
                                if (pageBreakBlockCopy[findedImprovementDragIndex].questions) {
                                    let columnId = payload.answeredRadio.columnId;

                                    if (!questionBlockImprovement[columnId]) {
                                        questionBlockImprovement[columnId] = {
                                            answerLabel: answeredRadioObject.label,
                                            questions: [{
                                                question: answeredRadioObject.question,
                                                label: answeredRadioObject.label,
                                                selectedLabelAswer: columnId,
                                                id: answeredRadioObject.rowId,
                                                questionDimension: answeredRadioObject.questionDimension,
                                                staticQuestionId: answeredRadioObject.staticQuestionId,
                                                ...answeredRadioObject.extraTranslationKeys
                                            }]
                                        };
                                    }
                                    else {

                                        questionBlockImprovement[columnId] = { ...questionBlockImprovement[columnId], questions: [...questionBlockImprovement[columnId].questions] }
                                        questionBlockImprovement[columnId].questions.push({
                                            question: answeredRadioObject.question,
                                            label: answeredRadioObject.label, selectedLabelAswer: columnId, id: answeredRadioObject.rowId, questionDimension: answeredRadioObject.questionDimension, staticQuestionId: answeredRadioObject.staticQuestionId, ...answeredRadioObject.extraTranslationKeys
                                        })
                                    }
                                    pageBreakBlockCopy[findedImprovementDragIndex].questions[0] = questionBlockImprovement
                                    questionSetCopy[questionSetImprovementDragIndex].questions[0] = questionBlockImprovement
                                    questionSetCopy[questionSetStrengthDragIndex].questions[0] = questionBlockStrength
                                    pageBreakBlockCopy[findedDragIndex].questions[0] = questionBlockStrength
                                }
                            }
                        }
                        else {
                            pageBreakBlockCopy[findedDragIndex].questions[0] = questionBlockStrength
                            pageBreakBlockCopy[findedImprovementDragIndex].questions[0] = questionBlockImprovement
                            questionSetCopy[questionSetImprovementDragIndex].questions[0] = questionBlockImprovement
                            questionSetCopy[questionSetStrengthDragIndex].questions[0] = questionBlockStrength
                        }
                    }


                }
            });

        }

    });

    return { questionSetCopy, pageBreakBlockCopy };
};

const generateAnswer = (currentQuestion, payload) => {
    let answeredRadio = payload.answeredRadio
    let answer = []
    if (currentQuestion.answer && currentQuestion.answer.length > 0) {
        answer = JSON.parse(currentQuestion.answer)
        let rowAnsweredIndex = answer.findIndex((item) => item.rowId === answeredRadio.rowId)
        if (rowAnsweredIndex !== -1) {
            answer[rowAnsweredIndex] = answeredRadio
        }
        else {
            answer.push(answeredRadio)
        }
    }
    else {
        answer.push(answeredRadio)

    }
    return answer

}

const checkAnswerRearrangementNeeded = (currentContent, rowId) => {
    if (checkIfJson(currentContent.answer)) {
        let parsedAnswer = JSON.parse(currentContent.answer)
        let findAnswer = parsedAnswer.find((item) => item.id === rowId)
        if (findAnswer) {
            return true
        }
        else {
            return false
        }

    }
}

const generateTopicWiseGrouping = (currentQuestionBlock, currentBlockId) => {
    let newObj = {}
    currentQuestionBlock.questions && currentQuestionBlock.questions.forEach((questionItem, index) => {
        if (newObj[questionItem.selectedLabelAswer]) {
            newObj[questionItem.selectedLabelAswer] = { ...newObj[questionItem.selectedLabelAswer], answerLabel: questionItem.label, questions: newObj[questionItem.selectedLabelAswer].questions ? [...newObj[questionItem.selectedLabelAswer].questions] : [] }
            newObj[questionItem.selectedLabelAswer].questions.push({ ...questionItem, index: index })
        }
        else {
            newObj[questionItem.selectedLabelAswer] = { ...newObj[questionItem.selectedLabelAswer], answerLabel: questionItem.label, questions: [{ ...questionItem, index: index }] }
        }

    })

    return newObj
}