import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const winningStrategy = {
    "blockId": uuidv4(),
    isRequired: true,
    allowNAOption: true,
    "description": "<p>Leaders who create winning strategies can balance the short and the long term. Below are the behaviors that help us win in this space. Please share your perspective on how often you feel the following behaviors are demonstrated by this leader.</p>",
    "goalId": "dynamic360Strength",
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Rarely",
                    "value": "1"
                },
                {
                    "label": "Not Often Enough",
                    "value": "2"
                },
                {
                    "label": "Often",
                    "value": "3"
                },
                {
                    "label": "Almost Always",
                    "value": "4"
                },
                { "label": "Can't answer", "value": "0" }

            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "staticQuestionId": generateStaticQuestionId("Deeply knows our customers and consumers."),
                    "HTMLText": "<p>Deeply knows our customers and consumers.</p>",
                    "isSelected": true,
                    "text": "Deeply knows our customers and consumers.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Pushes the organization to adopt new approaches and practices."),
                    "HTMLText": "<p>Pushes the organization to adopt new approaches and practices.</p>",
                    "isSelected": true,
                    "text": "Pushes the organization to adopt new approaches and practices.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Sees around the corner – anticipates what’s coming next and gets prepared for it."),
                    "HTMLText": "<p>Sees around the corner - anticipates what's coming next and gets prepared for it.</p>",
                    "isSelected": true,
                    "text": "Sees around the corner - anticipates what's coming next and gets prepared for it.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Simplifies the complex and zeros in on what’s critical."),
                    "HTMLText": "<p>Simplifies the complex and zeros in on what's critical.</p>",
                    "isSelected": true,
                    "text": "Simplifies the complex and zeros in on what's critical.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Cultivates breakthrough thinking and identifies bold actions."),
                    "HTMLText": "<p>Cultivates breakthrough thinking and identifies bold actions.</p>",
                    "isSelected": true,
                    "text": "Cultivates breakthrough thinking and identifies bold actions.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Takes risks and helps people learn from failures."),
                    "HTMLText": "<p>Takes risks and helps people learn from failures.</p>",
                    "isSelected": true,
                    "text": "Takes risks and helps people learn from failures.",
                    "value": uuidv4()
                }
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Create winning strategies"
}