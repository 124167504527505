import { connect } from 'react-redux';
import { Topics } from './Topics';
import {
  fetchExploreTopicList,
  sortExploreTopicList,
  exploreTopicsFetchSurveyReportData,
  fetchStoryboardFilterStatus,
  applyFilterOnExploreTopicList,
  fetchAdminBarchartData,
} from '../../../../../../redux/actions';
import { withRouter } from 'react-router-dom';
import { deepCopy } from '../../../../../../utils/deepCopy';
const mapStateToProps = (state) => {
  return {
    ...state.ExploreTopicReducer,
    surveyDetails: deepCopy(
      state.DashBoardReducer.surveyDetailsApi.surveyDetails
    ),
    thresholdData: deepCopy(
      state.DashBoardReducer.thresholdApi.data
    ),
    surveyDataApi : deepCopy(state.DashBoardReducer.surveyDetailsApi),
    surveyReport: deepCopy(
      state.ExploreTopicReducer.topicsSurveyReportApi.surveyReport
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchExploreTopicList: (params, cb) =>
      dispatch(fetchExploreTopicList(params, cb)),
    sortExploreTopicList: (params) => dispatch(sortExploreTopicList(params)),
    exploreTopicsFetchSurveyReportData: (params, cb) =>
      dispatch(exploreTopicsFetchSurveyReportData(params, cb)),
    fetchStoryboardFilterStatus: (params, cb) =>
      dispatch(fetchStoryboardFilterStatus(params, cb)),
    applyFilterOnExploreTopicList: (params, cb) =>
      dispatch(applyFilterOnExploreTopicList(params, cb)),
    fetchAdminBarchartData: (params, cb) =>
      dispatch(fetchAdminBarchartData(params, cb)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topics));
