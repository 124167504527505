import {
  FETCH_CHILD_COHORT_SURVEY_DATA_START,
  FETCH_CHILD_COHORT_SURVEY_DATA_SUCCESS,
  FETCH_CHILD_COHORT_SURVEY_DATA_FAIL,
  FETCH_COHORT_SURVEY_DATA_START,
  FETCH_COHORT_SURVEY_DATA_SUCCESS,
  FETCH_COHORT_SURVEY_DATA_FAIL,
  SET_SORT_ORDER_AND_SORT_FIELD_FOR_COHORT,
  FETCH_CHILD_COHORT_SURVEY_WIZARD_DATA_START,
  FETCH_CHILD_COHORT_SURVEY_WIZARD_DATA_SUCCESS,
  FETCH_CHILD_COHORT_SURVEY_WIZARD_DATA_FAIL,
  SET_PAGE_NUMBER_FOR_COHORT_SURVEY_PAGE


} from "../../constants";
import { Default } from "./InitialState/index";
import {
  fetchCohortSurveyListingStart,
  fetchCohortSurveyListingSuccess,
  fetchCohortSurveyListingFail,
  fetchCohortSurveyChildListStart,
  fetchCohortSurveyChildListSuccess,
  fetchCohortSurveyChildListFail,
  handleSortChange,
  fetchCohortSurveyChildListSurveyWizardStart,
  fetchCohortSurveyChildListSurveyWizardSuccess,
  fetchCohortSurveyChildListSurveyWizardFail,
  setPageNumberForCohortPage,
} from "./Helpers";

const CohortSurveyReducer = (state = Default(), action) => {
  switch (action.type) {
    case FETCH_COHORT_SURVEY_DATA_START:
      return fetchCohortSurveyListingStart(state, action.payload);
    case FETCH_COHORT_SURVEY_DATA_SUCCESS:
      return fetchCohortSurveyListingSuccess(state, action.payload);
    case FETCH_COHORT_SURVEY_DATA_FAIL:
      return fetchCohortSurveyListingFail(state, action.payload);

    case FETCH_CHILD_COHORT_SURVEY_DATA_START:
      return fetchCohortSurveyChildListStart(state, action.payload);
    case FETCH_CHILD_COHORT_SURVEY_DATA_SUCCESS:
      return fetchCohortSurveyChildListSuccess(state, action.payload);
    case FETCH_CHILD_COHORT_SURVEY_DATA_FAIL:
      return fetchCohortSurveyChildListFail(state, action.payload);

    case FETCH_CHILD_COHORT_SURVEY_WIZARD_DATA_START:
      return fetchCohortSurveyChildListSurveyWizardStart(state, action.payload);
    case FETCH_CHILD_COHORT_SURVEY_WIZARD_DATA_SUCCESS:
      return fetchCohortSurveyChildListSurveyWizardSuccess(state, action.payload);
    case FETCH_CHILD_COHORT_SURVEY_WIZARD_DATA_FAIL:
      return fetchCohortSurveyChildListSurveyWizardFail(state, action.payload);


    case SET_SORT_ORDER_AND_SORT_FIELD_FOR_COHORT:
      return handleSortChange(state, action.payload)

    case SET_PAGE_NUMBER_FOR_COHORT_SURVEY_PAGE:
      return setPageNumberForCohortPage(state, action.payload)



    default:
      return { ...state };
  }
};

export default CohortSurveyReducer;
