import {
  apiSuccessState,
  apiFailureState,
} from "../../../../utils/apiStateUtils";

export function fetchTemplatesSuccess(state, payload) {
  return {
    ...state,
    fetchTemplatesApi: apiSuccessState(
      state.fetchTemplatesApi,
      payload.payload
    ),
  };
}

export function fetchTemplatesStarted(state, payload) {
  return {
    ...state,
    fetchTemplatesApi: {
      ...state.fetchTemplatesApi,
      loading: true,
      error: false,
    },
  };
}

export function fetchTemplatesFail(state, payload) {
  return {
    ...state,
    fetchTemplatesApi: apiFailureState(state.fetchTemplatesApi, payload),
  };
}
