import { Default } from "./IntialState";
import {
  CHANGE_PASSWORD_FORM_CHANGE,
  CHANGE_PASSWORD_PROCESS_STARTED,
  CHANGE_PASSWORD_PROCESS_SUCCESS,
  CHANGE_PASSWORD_PROCESS_FAILED,
  CHANGE_PASSWORD_PROCESS_CLEAR,
} from "../../constants";
import { fetchPayload, update } from "../../../utils/reducerOperations";
import {
  changePasswordFormChange,
  changePasswordStarted,
  changePasswordSuccess,
  changePasswordFailed,
  clearChangePasswordForm,
} from "./Helpers";
const settingsPageReducer = (state = Default(), action) => {
  let resetPayload = fetchPayload(action);
  switch (action.type) {
    case CHANGE_PASSWORD_PROCESS_CLEAR:
      return clearChangePasswordForm(state, resetPayload);
    case CHANGE_PASSWORD_FORM_CHANGE:
      return update(state, changePasswordFormChange(state, resetPayload));
    case CHANGE_PASSWORD_PROCESS_STARTED:
      return update(state, changePasswordStarted(state, resetPayload));
    default:
      return changeSuccessFail(state, action);
  }
};
const changeSuccessFail = (state, action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case CHANGE_PASSWORD_PROCESS_SUCCESS:
      return update(state, changePasswordSuccess(state, payload));
    case CHANGE_PASSWORD_PROCESS_FAILED:
      return update(state, changePasswordFailed(state, payload));
    default:
      return { ...state };
  }
};
export default settingsPageReducer;
