import {
    FETCH_MULTISELECT_QUESTION_START,
    FETCH_MULTISELECT_QUESTION_SUCCESS,
    FETCH_MULTISELECT_QUESTION_FAIL
} from "../../../constants";
import { GET_MULTI_SELECT_QUESTIONS } from "../../../../../config/constants";
import { withType } from "../../../../utils/actionOperations";
import { ApiBase } from "../../../../utils/apiBase";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const fetchMultiQuestions = (payload, cb) => {
    const apiBaseInstance = new ApiBase().instance;
    return (dispatch) => {
        dispatch(withType(FETCH_MULTISELECT_QUESTION_START, {}));
        return apiBaseInstance
            .post(GET_MULTI_SELECT_QUESTIONS, {
                ...payload, ...getProjectIdAndOrgId(payload)
            })
            .then((res) => {
                dispatch(
                    withType(FETCH_MULTISELECT_QUESTION_SUCCESS, {
                        data: {
                            data: res.data.data,
                        },
                    }),
                );
                cb && setTimeout(() => { cb(); }, 0);
            })
            .catch((err) => {
                dispatch(withType(FETCH_MULTISELECT_QUESTION_FAIL, err));
            });
    };
};