import { connect } from "react-redux";
import CohortSuvey from "./cohortSuvey";
import { NewProjectPageCreateProject, bulkUploadStart, fetchSurveyWizardProjectDetails, getPreSignedURL, handleFileUpload, surveyWizardFetchHRISFiles, surveyWizardModifyProjectData } from "../../redux/actions";
import { addJob, bulkUploadEnd, uploadFile } from "../../redux/actions/UserUploadAction/userUpload";
import { fileUploadStatus } from "../../redux/actions/NewSurveyWizardAction/Helper/fileUploadStatus";


function mapStateToProps(state) {
  return { ...state.AdminReducer,
    ...state.NewSurveyWizardReducer,
    ...state.MyProjectsReducer,
    ...state.SurveyWizardReducer,
    ...state.UserUploadReducer,
 };
}

const mapDispatchToProps = (dispatch)=>{
    return {
      handleFileUpload: (params) => {dispatch(handleFileUpload(params))},
      surveyWizardModifyProjectData: (params, cb, presetData) => dispatch(surveyWizardModifyProjectData(params, cb, presetData)),
      fetchSurveyWizardProjectDetails: (params, cb, presetData) => dispatch(fetchSurveyWizardProjectDetails(params, cb, presetData)),
      surveyWizardFetchHRISFiles: (params, cb,presetData) => dispatch(surveyWizardFetchHRISFiles(params, cb,presetData)),
      bulkUploadStart: (params, next) => { dispatch(bulkUploadStart(params, next)) },
      getPreSignedURL: (params, next) => { dispatch(getPreSignedURL(params, next)) },
      bulkUploadEnd: (params) => { dispatch(bulkUploadEnd(params)) },
      fileUploadStatus: (params,cb) => dispatch(fileUploadStatus(params,cb)),
      addJob: (params, next) => { dispatch(addJob(params, next)) },
      uploadFile: (params, next) => { dispatch(uploadFile(params, next)) },
      NewProjectPageCreateProject: (params, cb) => dispatch(NewProjectPageCreateProject(params, cb)),
      };
}
export default connect(mapStateToProps, mapDispatchToProps)(CohortSuvey);
