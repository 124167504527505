import { connect } from "react-redux";
import RankingQuestion from "./RankingQuestion";
import { handleChangeRanking } from "../../../../../../redux/actions";


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

        handleChangeRanking: (payload, cb) => dispatch((handleChangeRanking(payload, cb)))

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RankingQuestion);