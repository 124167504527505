import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const leadershipTemplate = [
    {
        questionDimension: 'Senior Leaders',
        type: 'Matrix',
        questionLabel: 'Senior Leaders',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: 'Senior leaders are moving the organization in the right direction.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('Senior leaders are moving the organization in the right direction.'),
        },
        {
            text: `Senior leaders demonstrate that people are critical to the organization's success.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`Senior leaders demonstrate that people are critical to the organization's success.`),

        },
        {
            text: `Senior leaders are good role models of our organization's core values.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`Senior leaders are good role models of our organization's core values.`),

        },
        {
            text: 'Senior leaders are making the right decisions to drive business performance.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('Senior leaders are making the right decisions to drive business performance.'),

        }]
    }
]



