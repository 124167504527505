
export function surveyParticipationUpdateSelectedColumn(state, action) {
    if(action)
    {
    let { surveyParticipationSelectedColumns } = state;
    const index = (surveyParticipationSelectedColumns || []).findIndex(
      (i) => i === action.payload
    );
    if (index > -1)
    surveyParticipationSelectedColumns =
    surveyParticipationSelectedColumns.filter((i) => i !== action.payload);
    else surveyParticipationSelectedColumns.push(action.payload);
    return {
      ...state,
      surveyParticipationSelectedColumns: [...surveyParticipationSelectedColumns],
    };
  }
  else
  {
    return {
        ...state,
        surveyParticipationSelectedColumns: [],
      };
  }
}

export function surveyDeselectParticipationColumns(state){
  let { surveyParticipationDataApi } = state;
  const {result} = surveyParticipationDataApi.data.aggregatedDemographicsResponse;
  let selectedColumns = Object.keys(result);
  return {
      ...state,
      surveyParticipationSelectedColumns: [...selectedColumns],
    };
}