import React from "react";
import { getDataFromUrlToken } from "../../../../../../utils/urlDataStore";
import { URL_DATA } from "../../../../../../../config/urlDataConstants";
import { StoryboardParticipationTopicData } from "./children/StoryboardParticipationTopicData.js";
import { StoryboardParticipationOverAllData } from "./children/StoryboardParticipationOverAllData.js";
import AddDemographics from "../../../../../../Components/AddDemographicsModal";
import { deepCopy } from "../../../../../../utils/deepCopy";
import { ParticipationOverallGraph } from "./children/storyboardParticipationGraph";
import GraphLoader from "../../../../../../Components/ContentLoaders/GraphLoader";
import { checkIfObjectsAreEqual } from "../../../../../../utils/customFunctions";
import { getOuterTreeDataChildren } from "../../../../../../utils/treeApiUtils";
import { getSelectedUserData } from "../../helper";
import { getURLFilterAndByScore } from "../../../../../../utils/byScoreFilterUtils";
import { DASHBOARD_TABS } from '../../../../../../../config/tabConstants';
export class Participation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedColumns: [] };
  }
  componentDidMount() {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const selectedUserData = getSelectedUserData();
    if (this.checkIfApiCallRequiredOnMount(urlFilter, selectedUserData)) {
      this.callApi(urlFilter, selectedUserData);
    }
  }

  callApi = (filters, selectedUserData) => {
    const projDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS);
    let filterData = getURLFilterAndByScore(filters)
    const surveyDetails = this.props.surveyDetails && this.props.surveyDetails.surveyDetails
    this.props.fetchExploreParticipationData({
      currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.PARTICIPATION,
      demoGraphicColumns: this.props.exploreParticipationSelectedColumns || [],
      filters: filterData.filters,
      projectId: projDetails.projectId,
      projectOrigin: projDetails.projectOrigin,
      directReporteesOnly: selectedUserData.directReporteesOnly,
      selectedUser: selectedUserData.selectedUser,
      hrisFileId: surveyDetails.hrisFileId,
      isAnonymous: surveyDetails.isAnonymous || false,
      topLevelManagers: this.props.treeDataApi && getOuterTreeDataChildren(this.props.treeDataApi),
    });
    this.props.exploreGetDistributionData({
      currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.PARTICIPATION,
      projectId: projDetails.projectId,
      interval: "day",
      filters: filterData.filters,
      directReporteesOnly: selectedUserData.directReporteesOnly,
      selectedUser: selectedUserData.selectedUser,
    });
  };

  checkIfApiCallRequiredOnMount = (urlFilter, selectedUserData) => {
    let filterData = getURLFilterAndByScore(urlFilter)
    if (
      !this.props.exploreParticipationDataApi.isInitialApiCalled ||
      !checkIfObjectsAreEqual(filterData.filters, this.props.filters) ||
      !checkIfObjectsAreEqual(selectedUserData.directReporteesOnly, this.props.directReporteesOnly) ||
      !checkIfObjectsAreEqual(selectedUserData.selectedUser, this.props.selectedUser)
    ) {
      return true;
    }
    return false;
  };
  componentDidUpdate() {
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const selectedUserData = getSelectedUserData();
    let filterData = getURLFilterAndByScore(urlFilter)
    if (checkIfObjectsAreEqual(filterData.filters, this.props.filters) &&
      checkIfObjectsAreEqual(selectedUserData.directReporteesOnly, this.props.directReporteesOnly) &&
      checkIfObjectsAreEqual(selectedUserData.selectedUser, this.props.selectedUser)) {
    } else {
      if (!this.props.exploreParticipationDataApi.isProcessing) {
        this.callApi(urlFilter, selectedUserData);
      }
    }
  }

  fetchParticipation = (selectedData) => {
    const projDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS);
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(urlFilter)
    const selectedUserData = getSelectedUserData();
    this.props.fetchExploreParticipationData({
      currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.PARTICIPATION,
      demoGraphicColumns: selectedData,
      filters: filterData.filters,
      hrisFileId: projDetails.hrisFileId,
      projectId: projDetails.projectId,
      directReporteesOnly: selectedUserData.directReporteesOnly,
      selectedUser: selectedUserData.selectedUser,
      projectOrigin: projDetails.projectOrigin,
      topLevelManagers: this.props.treeDataApi && getOuterTreeDataChildren(this.props.treeDataApi),
    });
  };
  onCancel = () => this.props.exploreDeselectParticipationcolumns();

  onRemoveItem = (removedItem) => {
    const projDetails = getDataFromUrlToken(URL_DATA.PROJECT_DETAILS);
    const selectedParticipationColumns = deepCopy(
      this.props.exploreParticipationSelectedColumns
    );
    let demoGraphicColumns = selectedParticipationColumns.filter(
      (item) => item !== removedItem
    );
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    let filterData = getURLFilterAndByScore(urlFilter)
    this.props.fetchExploreParticipationData({
      currentTab: DASHBOARD_TABS.EXPLORE_SUB_TABS.PARTICIPATION,
      demoGraphicColumns: demoGraphicColumns,
      filters:filterData.filters,
      projectId: projDetails.projectId,
      hrisFileId: projDetails.hrisFileId,
      projectOrigin: projDetails.projectOrigin,
      topLevelManagers: this.props.treeDataApi && getOuterTreeDataChildren(this.props.treeDataApi),
    });
    this.props.exploreUpdateParticipationSelectedTopicColumns({
      selected: removedItem,
    });
  };

  getHighestPercentage = () => {
    let result =
      this.props.exploreParticipationDataApi.data &&
      this.props.exploreParticipationDataApi.data.aggregatedDemographicsResponse.result
    if (result) {
      let overallPercentage =
        this.props.exploreParticipationDataApi.data
          .aggregatedDemographicsResponse.participationRate;
      let mergedArray = overallPercentage ? [overallPercentage] : []
      for (let key in result) {
        mergedArray = mergedArray.concat(
          result[key].histogramData.filter(item => item.completed >= this.props.thresholdData.filterThreshold)
            .map(item =>
              item.percentage))
      }
      return Math.max(...mergedArray)
    }
    return 100
  };

  render() {
    let getHighestPercentage = this.getHighestPercentage();
    return (
      <>
        {this.props.exploreParticipationDataApi.isProcessing ||
          this.props.exploreDistributionDataApi.isProcessing ? (
          <GraphLoader height={300} width={600} />
        ) : (
          <>
            <ParticipationOverallGraph
              surveyDetails={
                this.props.surveyDetails &&
                this.props.surveyDetails.surveyDetails
              }
              getDistributionDataApi={this.props.exploreDistributionDataApi}
              getParticipationRateApi={this.props.exploreParticipationDataApi}
            />
            <StoryboardParticipationOverAllData
              data={
                this.props.exploreParticipationDataApi.data &&
                this.props.exploreParticipationDataApi.data
                  .aggregatedDemographicsResponse
              }
              getHighestPercentage={getHighestPercentage}
            />
            <StoryboardParticipationTopicData
              data={
                this.props.exploreParticipationDataApi.data &&
                this.props.exploreParticipationDataApi.data
                  .aggregatedDemographicsResponse.result
              }
              onRemoveItem={this.onRemoveItem}
                threshold={this.props.thresholdData.filterThreshold}
              getHighestPercentage={getHighestPercentage}
            />
            <AddDemographics
              columns={
                this.props.exploreParticipationDataApi.data &&
                this.props.exploreParticipationDataApi.data
                  .aggregatedDemographicsResponse.columns
              }
              selectedColumns={this.props.exploreParticipationSelectedColumns}
              onSelect={
                this.props.exploreUpdateParticipationSelectedTopicColumns
              }
              onCancel={this.onCancel}
              onOk={this.fetchParticipation}
            />
          </>
        )}
      </>
    );
  }
}
