import { combineReducers } from "redux";
import loginPageReducer from "./LoginPageReducer";
import verifyPageReducer from "./VerifyPageReducer";
import forgotPasswordPageReducer from "./ForgotPasswordPageReducer";
import resetPasswordPageReducer from "./ResetPasswordPageReducer";
import homePageReducer from "./HomePageReducer";
import usersPageReducer from "./UsersPageReducer";
import getProfilePageReducer from "./GetProfilePageReducer";
import surveyPageReducer from "./SurveyPageReducer";
import organizationPageReducer from "./OrganizationPageReducer";
import WelcomePageReducer from "./WelcomePageReducer";
import driversPageReducer from "./DriversPageReducer";
import executiveOverviewReducer from "./ExecutiveOverviewPageReducer";
import scoresPageReducer from "./ScoresPageReducer";
import comparisonPageReducer from "./ComparisonPageReducer";
import callOutPageReducer from "./CallOutPageReducer";
import spreadAnalysisReducer from "./SpreadAnalysisReducer";
import EditProjectReducer from "./EditProject";
import PreviewProjectReducer from "./PreviewProject";
import { MyProjectsReducer } from "./MyProjects";
import AttendSurveyReducer from "./AttendSurveyReducer";
import emailDistributionReducer from "./Distribution_Email";
import SurveyReportReducer from "./Survey_ReportReducer";
import SentimentReducer from "./NLPSentimentReducer";
import insightsReducer from "./NLPInsightsReducer";
import TopicsReducer from "./NLPTopicsReducer";
import AdminReducer from "./NLPAdminReducer";
import NLPRouteReducer from "./NLPRouteReducer";
import rolesPageReducer from "./RolesPageReducer";
import addAdminPageReducer from "./AddAdminPageReducer";
import UserUploadReducer from "./UserUploadReducer";
import UserCreationLogReducer from "./UserCreationLogReducer";
import NLPComparisonReducer from "./NLPComparisonReducer";
import NLPStoryboardQuestionReducer from "./NLPStoryboardQuestionReducer";
import NLPStoryboardOverviewReducer from "./NLPStoryboardOverviewReducer";
import UserLandingPageReducer from "./UserLandingPageReducer";
import NLPStoryboardTopResultsReducer from "./NLPStoryboardTopResultsReducer";
import AdminSurveyListReducer from "./AdminSurveyListReducer";
import addTemplatesPageReducer from "./AddMailTemplatePageReducer";
import inviteManagerReducer from "./InviteMangerReducer";
import hrisUploadReducer from "./HrisUploadReducer";
import peopleListSearchReducer from "./PeopleListReducer";
import getDistributionDataReducer from "./DistributionSummaryReducer";
import stopResponseCollectionReducer from "./DistributionPageReducer";
import settingsPageReducer from "./SettingsPageReducer";
import changeFirstLastNamePageReducer from "./ChangeFirstLastNameReducer";
import participationsPageReducer from "./ParticipationsPageReducer";
import DimensionListReducer from "./DimensionListReducer";
import ExportFormReducer from "./ExportFormReducer"
import SurveyWizardReducer from "./SurveyWizardReducer";
import OutliersPageReducer from "./OutliersPageReducer";
import SurveyReminderReducer from "./SurveyReminderReducer";
import PreviewMailReducer from "./PreviewMailReducer";
import ResponsesReducer from "./ResponsesReducer";
import CsvDownloadReducer from "./CsvDownloadReducer";
import StoryboardTopicReducer from "./StoryboardTopicReducer";
import PeopleListingReducer from "./peopleListingReducer";
import QuestionsReprocessReducer from "./QuestionsReprocessReducer";
import CustomTopicMappingReducer from "./CustomTopicMappingReducer";
import UnPublishSurveyReducer from "./UnPublishSurveyReducer";
import DashBoardReducer from './DashBoardReducer'
import InviteViewerReducer from "./InviteViewerReducer";
import StoryOutcomeTopicReducer from "./StoryOutcomeTopicReducer";
import storyBoardOutlier from './StoryBoardOutlierReducer';
import fetchStoryboardTopAndBottomTopicsReducer from './StoryboardTopAndBottomTopicReducer';
import StoryboardParticipationReducer from "./StoryboardParticipationReducer";
import StoryboardDriversReducer from './StoryboardDriversReducer';
import StoryboardQuestionsReducer from './StoryboardQuestionsReducer';
import FetchExploreDriversDataReducer from './NewDashBoardExploreDriversReducer'
import FetchExploreOutliersDataReducer from './NewDashboardExploreOutliersReducer'
import ExploreTopicReducer from './ExploreTopicReducer';
import ExploreQuestionsReducer from './ExploreQuestionsReducer';
import ExploreParticipationDataReducer from "./ExploreParticipationReducer";
import ExploreDemographicsReducer from './ExploreDemographicsReducer';
import FetchExploreCommentQustionReducer from './NewDashboardCommentQuestionReducer'
import FetchManageUsersReducer from './NewdashboardManageUsersReducer'
import NewDAshboardGenerateReportMail from './NewdashboardManageExportReducer'
import CommentsFetchSentimentsDataReducer from "./NewDashboardCommentsReducer";
import NewdashboardListViewerReducer from './NewdashboardManageViewersReducer'
import NewdashboardFetchResponsesReducer from './NewdashboardManageResponsesReducer'
import getBatchQuestionsIdReducer from './NewDashbaordFetchBatchQuestions'
import HeatmapReducer from './HeatmapReducer'
import SurveyParticpationReducer from "./surveyParticipationReducer";
import SurveysPageReducer from './ProjectPageReducer'
import NewSurveyWizardReducer from "./NewSurveyWizardReducer";
import NewdashboardBenchMarkReducer from './NewDashboardManageFetchBenchMarkData'
import NewdashboardTrendBenchmarkReducer from './NewDashboardTrendBenchmarkReducer';
import ImageUploadReducer from './ImageUploadReducer';
import NewForgotPasswordReducer from './NewForgetPasswordReducer';
import NewResetPasswordReducer from "./NewResetPasswordReducer";
import MultiSelectQuestionsReducer from "./MultiselectReducer";
import FetchDemographicsDataOfEnteredUserReducer from './FetchDemographicsDataReducer'
import FetchFollowUpReportTableReducer from './NewDahboardFetchFollowUpReportTableReducer'
import SurveyAnsweringPageReducer from './SurveyAnsweringPageReducer'
import NewdashboardTrendPairingReducer from "./NewDashboardTrendPairingReducer";
import TrendVisualisationReducer from "./NewDashboardTrendReducer";
import ResultsPageReducer from "./ResultsPageReducer";
import ManagerHeatmapStatusReducer from "./ManagerHeatmapStatusReducer";
import Feedback360ResultsPageReducer from './Feedback360ResultsPageReducer'
import NewdashboardTrendInsightsReducer from "./NewDashboardTrendInsightsReducer";
import CohortSurveyReducer from "./CohortSurveyReducer";
import NewDashboardExploreTrendInsightsReducer from './NewDashboardExploreTrendInsightsReducer'
import StatusPageReducer from "./StatusPageReducer";
export default combineReducers({
  DimensionListReducer,
  NLPStoryboardTopResultsReducer,
  NLPStoryboardOverviewReducer,
  AdminSurveyListReducer,
  UserLandingPageReducer,
  NLPStoryboardQuestionReducer,
  rolesPageReducer,
  spreadAnalysisReducer,
  loginPageReducer,
  verifyPageReducer,
  forgotPasswordPageReducer,
  resetPasswordPageReducer,
  homePageReducer,
  usersPageReducer,
  getProfilePageReducer,
  surveyPageReducer,
  organizationPageReducer,
  WelcomePageReducer,
  driversPageReducer,
  executiveOverviewReducer,
  scoresPageReducer,
  comparisonPageReducer,
  callOutPageReducer,
  EditProjectReducer,
  PreviewProjectReducer,
  MyProjectsReducer,
  AttendSurveyReducer,
  emailDistributionReducer,
  SurveyReportReducer,
  SentimentReducer,
  insightsReducer,
  TopicsReducer,
  AdminReducer,
  NLPRouteReducer,
  UserUploadReducer,
  UserCreationLogReducer,
  NLPComparisonReducer,
  addAdminPageReducer,
  addTemplatesPageReducer,
  inviteManagerReducer,
  hrisUploadReducer,
  peopleListSearchReducer,
  getDistributionDataReducer,
  settingsPageReducer,
  participationsPageReducer,
  changeFirstLastNamePageReducer,
  stopResponseCollectionReducer,
  ExportFormReducer,
  SurveyWizardReducer,
  OutliersPageReducer,
  SurveyReminderReducer,
  PreviewMailReducer,
  ResponsesReducer,
  CsvDownloadReducer,
  StoryboardTopicReducer,
  PeopleListingReducer,
  QuestionsReprocessReducer,
  CustomTopicMappingReducer,
  UnPublishSurveyReducer,
  DashBoardReducer,
  InviteViewerReducer,
  StoryOutcomeTopicReducer,
  storyBoardOutlier,
  fetchStoryboardTopAndBottomTopicsReducer,
  StoryboardParticipationReducer,
  StoryboardDriversReducer,
  StoryboardQuestionsReducer,
  FetchExploreDriversDataReducer,
  FetchExploreOutliersDataReducer,
  ExploreTopicReducer,
  ExploreQuestionsReducer,
  ExploreParticipationDataReducer,
  ExploreDemographicsReducer,
  FetchExploreCommentQustionReducer,
  FetchManageUsersReducer,
  NewDAshboardGenerateReportMail,
  CommentsFetchSentimentsDataReducer,
  NewdashboardListViewerReducer,
  NewdashboardFetchResponsesReducer,
  getBatchQuestionsIdReducer,
  HeatmapReducer,
  SurveyParticpationReducer,
  SurveysPageReducer,
  NewSurveyWizardReducer,
  NewdashboardBenchMarkReducer,
  NewdashboardTrendBenchmarkReducer,
  ImageUploadReducer,
  NewForgotPasswordReducer,
  NewResetPasswordReducer,
  MultiSelectQuestionsReducer,
  FetchDemographicsDataOfEnteredUserReducer,
  FetchFollowUpReportTableReducer,
  SurveyAnsweringPageReducer,
  NewdashboardTrendPairingReducer,
  TrendVisualisationReducer,
  ResultsPageReducer,
  ManagerHeatmapStatusReducer,
  Feedback360ResultsPageReducer,
  NewdashboardTrendInsightsReducer,
  CohortSurveyReducer,
  NewDashboardExploreTrendInsightsReducer,
  StatusPageReducer
});
