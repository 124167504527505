import {
  MANAGE_USERS_INVITE_MANAGERS_STARTED,
  MANAGE_USERS_INVITE_MANAGERS_SUCCESS,
  MANAGE_USERS_INVITE_MANAGERS_FAILED,
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { INVITE_USER_URL } from "../../../../../config/constants";
import { toast } from "../../../../utils/messageUtils";
import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const NewdashboardInviteManagers = (payload, cb) => {
  const apiBaseInstance = new ApiBase().instance;
  let message = "Invitation to all managers sent successfully"
  if(payload.email!==undefined&&payload.email!==""){
    message = `Invitation to "${payload.email}" sent successfully`
  }
  return (dispatch) => {
    dispatch(withType(MANAGE_USERS_INVITE_MANAGERS_STARTED, {}));
    return apiBaseInstance
      .post(INVITE_USER_URL, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        toast.success(message);
        cb && cb(res.data);
        dispatch(
          withType(MANAGE_USERS_INVITE_MANAGERS_SUCCESS, {
            data: res.data,
          })
        );
      })
      .catch((err) => {
        toast.error("Failed to invite users");
        dispatch(withType(MANAGE_USERS_INVITE_MANAGERS_FAILED, err));
      });
  };
};

