export const newResetPasswordStart = (state, payload) => {

    return {
        ...state,
        newResetPasswordApi: {
            isProcessing: true,
            data: null,
            error: false,
            message: '',
            code: ''
        },
    };
};

export const newResetPasswordSuccess = (state, payload) => {

    return {
        ...state,
        newResetPasswordApi: {
            isProcessing: false,
            data: payload && payload.data,
            error: false,
            message: '',
            code: payload && payload.code
        },
    };
};

export const newResetPasswordFailed = (state, payload) => {
    let errorResponse = payload && payload.response && payload.response.data ? payload.response.data : payload
    let message = errorResponse && errorResponse.message && errorResponse.message === 'Network Error' ? 'Network error' : errorResponse.message
    return {
        ...state,
        newResetPasswordApi: {
            reason: errorResponse && errorResponse.reason,
            isProcessing: false,
            data: null,
            error: true,
            message: message,
            code: errorResponse && errorResponse.code ? errorResponse.code : 'networkError'
        },
    };
};

export const newResetPasswordClearState = (state, payload) => {
    return {
        ...state,
        newResetPasswordApi: {
            reason: '',
            isProcessing: false,
            data: null,
            error: false,
            message: '',
            code: ''
        },
    };
};