import { Default } from "./InitialState";
import { fetchPayload, update } from "../../../utils/reducerOperations";
import {
  FETCH_TEMPLATE_LIST_STARTED,
  FETCH_TEMPLATE_LIST_SUCCESS,
  FETCH_TEMPLATE_LIST_FAILED,
  ADD_TEMPLATE_STARTED,
  ADD_TEMPLATE_SUCCESS,
  ADD_TEMPLATE_FAILED,
  MAIL_TEMPLATE_FORM_CHANGE,
  CLEAR_MAIL_TEMPLATE_FORM,
  EMAIL_TEMPLATE_PAGINATION_CHANGE,
} from "../../constants";
import {
  fetchTemplatesFail,
  fetchTemplatesStarted,
  fetchTemplatesSuccess,
  addTemplateStarted,
  addTemplateSuccess,
  addTemplateFail,
  mailTemplateChange,
  clearTemplateForm,
  onEmailTemplatePagination,
} from "./Helpers";
const addTemplatesPageReducer = (state = Default(), action) => {
  const mailTemplatePayload = fetchPayload(action);
  switch (action.type) {
    case MAIL_TEMPLATE_FORM_CHANGE:
      return update(state, mailTemplateChange(state, mailTemplatePayload));
    case CLEAR_MAIL_TEMPLATE_FORM:
      return clearTemplateForm(state, mailTemplatePayload);
    case FETCH_TEMPLATE_LIST_STARTED:
      return fetchTemplatesStarted(state, action);
    case FETCH_TEMPLATE_LIST_SUCCESS:
      return fetchTemplatesSuccess(state, action);
    case FETCH_TEMPLATE_LIST_FAILED:
      return fetchTemplatesFail(state, action);
    case ADD_TEMPLATE_STARTED:
      return addTemplateStarted(state, action);
    case ADD_TEMPLATE_SUCCESS:
      return addTemplateSuccess(state, action);
    case ADD_TEMPLATE_FAILED:
      return addTemplateFail(state, action);
    case EMAIL_TEMPLATE_PAGINATION_CHANGE:
      return onEmailTemplatePagination(state, mailTemplatePayload);
    default:
      return { ...state };
  }
};

export default addTemplatesPageReducer;
