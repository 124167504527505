import React from 'react';
import { Column } from '@ant-design/plots';
export const MultipleGraphChart = (props) => {
  let trim = props.data.length > 9 ? 20 : 30
  if (props.data.length > 20) {
    trim = 10
  }
  let x = props.showProjectGroup ? 'surveyName' : 'label'
  let seriesField = props.showProjectGroup ? 'label' : 'surveyName'
  const config = {
    data: props.data,
    isGroup: true,
    xField: x,
    yField: 'trendScore',
    maxColumnWidth: 60,
    marginRatio: 0,
    color: ["#4575b4", " #74add1", "#abd9e9", "#c9eff3", "#fdae61", " #658F9E", "#d73027"],
    legend: {
      layout: 'horizontal',
      position: 'top',
      maxRow: 3,
      maxCol: 50,
      offsetX: 10,
      offsetY: 10
    },
    seriesField: seriesField,
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    xAxis: {
      label: {
        formatter: (value) => {
          return value.length > trim ? `${value.slice(0, trim)}...` : value;
        },

      },
    },
  };

  return <Column {...config} />;
};


