import {
  GET_ORGANZATION_PROFILE_STARTED,
  GET_ORGANZATION_PROFILE_SUCCESS,
  GET_ORGANZATION_PROFILE_FAILED,
} from "../../constants";
import { GET_ORGANIZATION_PROFILE_URL } from "../../../../config/constants";
import { ApiBase } from "../../../utils/apiBase";
import { withType } from "../../../utils/actionOperations";
import { setDataToUrlToken } from "../../../utils/urlDataStore";
import { fetchOrgId, getProjectIdAndOrgId } from '../../../utils/apiUtils';
import { ORGANIZATION_PAGE_SECTION } from '../../../../config/tabConstants';

export const getOrganizationProfile = (params) => {
  params.currentTab = ORGANIZATION_PAGE_SECTION.ORGANIZATION
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(GET_ORGANZATION_PROFILE_STARTED, { ...params, ...getProjectIdAndOrgId(params) }));
    apiBaseInstance.instance
      .post(GET_ORGANIZATION_PROFILE_URL, { ...params, organizationId: fetchOrgId(params) })
      .then((res) => getOrganizationProfileSuccessAction(dispatch, res))
      .catch((err) => getOrganizationProfileFailedAction(dispatch, err));
  };
};

function getOrganizationProfileSuccessAction(dispatch, res) {
  if (res.data && res.data.data && res.data.data.isDeleted) {
    window.location.href = "/acccessDenied"
  }
  let projectCount = res.data && res.data.data && res.data.data.projectsCount ?
    res.data.data.projectsCount : undefined;
  if (res.data && res.data.data) {
    let orgDetails = {
      ...res.data.data,
      projectCount: projectCount
    }
    setDataToUrlToken({ orgDetails: orgDetails })
  }
  if (projectCount)
    localStorage.setItem('projectCount', projectCount)
  return dispatch(withType(GET_ORGANZATION_PROFILE_SUCCESS, res.data));
}
function getOrganizationProfileFailedAction(dispatch, err) {
  return dispatch(withType(GET_ORGANZATION_PROFILE_FAILED, err));
}
