import { deepCopy } from "../../../../../utils/deepCopy"
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils"
import { v4 as uuidv4 } from "uuid";

export const addFollowUpQuestion = (state, payload) => {
    let goals = addFollowUpQuestionTopics(state, payload)
    return {
        ...state,
        goals: goals
    }
}

export const deleteFollowUpQuestion = (state, payload) => {
    let goals = deleteFollowUpQuestionTopics(state, payload)
    return {
        ...state,
        goals: goals
    }
}

export const onBlurOpenEndedHeading = (state, payload) => {
    let goals = editOpenEndedFollowUpHeading(state, payload)
    return {
        ...state,
        goals: goals
    }
}

export const onBlurOpenEndedDescription = (state, payload) => {
    let goals = editOpenEndedFollowUpDescription(state, payload)
    return {
        ...state,
        goals: goals
    }
}

//QuestionLevel
export const onBlurFollowUpMatrixQuestion = (state, payload) => {
    let goals = onBlurFollowUpMatrixQuestionInQuestionSet(state, payload)
    return {
        ...state,
        goals: goals
    }
}

export const deleteQuestionLevelFollowUp = (state, payload) => {
    let goals = deleteQuestionLevelFollowUpFromQuestionSet(state, payload)
    return {
        ...state,
        goals: goals
    }
}

export const onChangeMatrixQuestionScale = (state, payload) => {
    let goals = onChangeMatrixQuestionScaleInQuestionSet(state, payload)
    return {
        ...state,
        goals: goals
    }
}

export const onChangeFollowUpQuestionCondition = (state, payload) => {
    let goals = changeConditionInFollowUp(state, payload)
    return {
        ...state,
        goals: goals
    }
}

export const addQuestionLevelMatrixFollowUp = (state, payload) => {
    let goals = addQuestionLevelMatrixFollowUpInGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

export const addQuestionLevelOpenEndedFollowUp = (state, payload) => {
    let goals = addQuestionLevelOpenEndedFollowUpInGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

export const onBlurQuestionLevelOpenEndedHeading = (state, payload) => {
    let goals = onBlurQuestionLevelOpenEndedHeadingOnQuestionSet(state, payload)
    return {
        ...state,
        goals: goals
    }
}

export const onBlurQuestionLevelOpenEndedDescription = (state, payload) => {
    let goals = onBlurQuestionLevelOpenEndedDescriptiopnOnQuestionSet(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const onChangeMatrixQuestionScaleInQuestionSet = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    let autmaticChoices = deepCopy(state.automaticChoices)
    let scaleOptions = autmaticChoices.filter(item => item.value === payload.selectedScale)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.payload.questionDetails.selectedGoalId) {
            item.questions[0].matrixQuestions.forEach(question => {
                if (question.staticQuestionId === payload.payload.matrixquestionDetails.selectedQuestionStaticId) {
                    question.followUpQuestions.forEach(followUp => {
                        if (followUp.qid === payload.payload.questionDetails.qid) {
                            followUp.options = scaleOptions[0].options
                            followUp.scaleOption = payload.selectedScale
                        }
                    })
                }
            })
        }
    })
    return goalsCopy
}

const onBlurFollowUpMatrixQuestionInQuestionSet = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.payload.questionDetails.selectedGoalId) {
            item.questions[0].matrixQuestions.forEach(question => {
                if (question.staticQuestionId === payload.payload.matrixquestionDetails.selectedQuestionStaticId) {
                    question.followUpQuestions.forEach(followUp => {
                        if (followUp.qid === payload.payload.questionDetails.qid) {
                            followUp.matrixQuestions.forEach(matrixFollowUp => {
                                if (matrixFollowUp.staticQuestionId === payload.payload.matrixquestionDetails.staticQuestionId) {
                                    let HTMLRemovedText = payload.data.value.replace(/<[^>]*>?/gm, '')
                                    matrixFollowUp.HTMLText = payload.data.value
                                    matrixFollowUp.text = HTMLRemovedText
                                    matrixFollowUp.staticQuestionId = generateStaticQuestionId(HTMLRemovedText)
                                }
                            })
                        }
                    })
                }
            })
        }
    })
    return goalsCopy
}

const onBlurQuestionLevelOpenEndedDescriptiopnOnQuestionSet = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.topicDetails.goalId) {
            item.questions[0].matrixQuestions.forEach(question => {
                if (question.staticQuestionId === payload.questionDetails.staticQuestionId) {
                    question.followUpQuestions.forEach(followUp => {
                        if (followUp.qid === payload.data.id) {
                            followUp.questionLabel = payload.data.value
                        }
                    })
                }
            })
        }
    })
    return goalsCopy
}

const onBlurQuestionLevelOpenEndedHeadingOnQuestionSet = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.topicDetails.goalId) {
            item.questions[0].matrixQuestions.forEach(question => {
                if (question.staticQuestionId === payload.questionDetails.staticQuestionId) {
                    question.followUpQuestions.forEach(followUp => {
                        if (followUp.qid === payload.data.id) {
                            let HTMLRemovedText = payload.data.value.replace(/<[^>]*>?/gm, '')
                            followUp.staticQuestionId = generateStaticQuestionId(HTMLRemovedText)
                            followUp.question = HTMLRemovedText
                            followUp.HTMLQuestion = payload.data.value
                        }
                    })
                }
            })
        }
    })
    return goalsCopy
}

const deleteQuestionLevelFollowUpFromQuestionSet = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.goalId) {
            item.questions[0].matrixQuestions.forEach(question => {
                if (question.staticQuestionId === payload.staticQuestionId) {
                    let updatedData = question.followUpQuestions.filter(followUp => followUp.qid !== payload.removeId)
                    question.followUpQuestions = updatedData
                    question.haveFollowUpQuestion = updatedData && updatedData.length > 0
                }
            })
        }
    })
    return goalsCopy
}

const changeConditionInFollowUp = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.goalId) {
            item.questions[0].matrixQuestions.forEach(question => {
                if (question.staticQuestionId === payload.staticQuestionId) {
                    question.followUpQuestions.forEach(followUp => {
                        if (payload.isMatrix && followUp.type !== "Text Entry") {
                            followUp.matrixQuestions.forEach(matrixFollowUp => {
                                if (matrixFollowUp.staticQuestionId === payload.selectedStaticId) {
                                    matrixFollowUp.condition = payload.condition
                                }
                            })
                        } else {
                            if (followUp.staticQuestionId === payload.selectedStaticId) {
                                followUp.condition = payload.condition
                            }
                        }
                    })
                }
            })
        }
    })
    return goalsCopy
}

const addQuestionLevelMatrixFollowUpInGoals = (state, payload) => {
    let params = {
        selectedQuestionStaticId: payload.questionDetails.staticQuestionId,
        selectedTopic: payload.topicDetails.dimension,
        questionDimension: payload.topicDetails.dimension,
        selectedQid: payload.topicDetails.questions[0].qid,
        goalId: payload.topicDetails.goalId,
    }
    let goalsCopy = deepCopy(state.goals)
    let followUpQuestion = getMatrixFollowUpQuestion(params)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.topicDetails.goalId) {
            item.questions[0].matrixQuestions.forEach(question => {
                if (question.staticQuestionId === payload.questionDetails.staticQuestionId) {
                    let followUp = question.followUpQuestions && Array.isArray(question.followUpQuestions) ? question.followUpQuestions : []
                    question.followUpQuestions = [...followUp, followUpQuestion]
                    question.haveFollowUpQuestion = true
                }
            })
        }
    })
    return goalsCopy
}

const addQuestionLevelOpenEndedFollowUpInGoals = (state, payload) => {
    let params = {
        selectedQuestionStaticId: payload.questionDetails.staticQuestionId,
        selectedTopic: payload.topicDetails.dimension,
        selectedQID: payload.topicDetails.questions[0].qid,
        selectedGoalId: payload.topicDetails.goalId,
    }
    let goalsCopy = deepCopy(state.goals)
    let followUpQuestion = getFollowUpQuestion(payload.topicDetails)
    let updatedFollowUp = updatesOpenEndedQuestionForQuestinLevel(followUpQuestion, params)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.topicDetails.goalId) {
            item.questions[0].matrixQuestions.forEach(question => {
                if (question.staticQuestionId === payload.questionDetails.staticQuestionId) {
                    let followUp = question.followUpQuestions && Array.isArray(question.followUpQuestions) ? question.followUpQuestions : []
                    question.followUpQuestions = [...followUp, updatedFollowUp]
                    question.haveFollowUpQuestion = true
                }
            })
        }
    })
    return goalsCopy
}


const addFollowUpQuestionTopics = (state, payload) => {
    let followUpQuestion = [getFollowUpQuestion(payload)]
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.goalId) {
            item.haveFollowUpQuestion = true
            item.followUpQuestions = followUpQuestion
        }
    })
    return goalsCopy
}

const deleteFollowUpQuestionTopics = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.goalId) {
            item.haveFollowUpQuestion = false
            item.followUpQuestions = []
        }
    })
    return goalsCopy
}

const editOpenEndedFollowUpHeading = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.topicId) {
            item.followUpQuestions.forEach(followUpQuestion => {
                if (followUpQuestion.qid === payload.id) {
                    followUpQuestion.HTMLQuestion = payload.value
                    let HTMLRemovedText = payload.value.replace(/<[^>]*>?/gm, '')
                    followUpQuestion.staticQuestionId = generateStaticQuestionId(HTMLRemovedText)
                    followUpQuestion.question = HTMLRemovedText
                }
            })
        }
    })
    return goalsCopy
}

const editOpenEndedFollowUpDescription = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === payload.topicId) {
            item.followUpQuestions.forEach(followUpQuestion => {
                if (followUpQuestion.qid === payload.id) {
                    followUpQuestion.questionLabel = payload.value
                }
            })
        }
    })
    return goalsCopy
}

const getFollowUpQuestion = (topicDetails) => {
    return ({
        condition: [],
        dimension: "Comments",
        topicDimension: topicDetails.dimension,
        goalId: topicDetails.goalId,
        isFollowUpQuestion: true,
        question: 'Any Comments?',
        options: [],
        type: "Text Entry",
        value: "",
        qid: uuidv4(),
        questionLabel: `${topicDetails.dimension} - Any Comments?`,
        isSelected: true,
        staticQuestionId: generateStaticQuestionId(`Any Comments${topicDetails.dimension}`),
    })
}

const updatesOpenEndedQuestionForQuestinLevel = (data, payload) => {
    data.selectedQuestionStaticId = payload.selectedQuestionStaticId
    data.selectedTopic = payload.selectedTopic
    data.selectedQid = payload.selectedQID
    data.goalId = `${payload.selectedGoalId + uuidv4()}`
    data.selectedGoalId = payload.selectedGoalId
    return data
}

const getMatrixFollowUpQuestion = (data) => {
    return (
        {
            question: "",
            options: [
                {
                    label: "Strongly disagree",
                    value: "1"
                },
                {
                    label: "Somewhat disagree",
                    value: "2"
                },
                {
                    label: "Neither agree nor disagree",
                    value: "3"
                },
                {
                    label: "Somewhat agree",
                    value: "4"
                },
                {
                    label: "Strongly agree",
                    value: "5"
                }
            ],
            matrixQuestions: [
                {
                    selectedQuestionStaticId: data.selectedQuestionStaticId,
                    selectedTopic: data.selectedTopic,
                    staticQuestionId: generateStaticQuestionId(`Enter question ${uuidv4()}`),
                    isSelected: true,
                    text: "Enter question ",
                    value: uuidv4()
                },
            ],
            isFollowUpQuestion: true,
            selectedGoalId: data.goalId,
            questionDimension: data.selectedTopic,
            type: "Matrix",
            selectedQid: data.selectedQID,
            condition: [],
            qid: uuidv4(),
            goalId: `${data.goalId + uuidv4()}`,
            questionLabel: `${data.goalId}-follow-up`,
            isFollowUpFromQuestionLevel: true
        }
    )
}