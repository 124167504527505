import { notification } from "antd";

export function uploadImageStarted(state) {
  return {
    ...state,
    uploadImageApi: {
      loading: true,
    },
  };
}

export function uploadImageSuccess(state, action) {
  notification.success({ message: "Image upload successful" });
  if (action.payload && action.payload.callbacks)
    action.payload.callbacks.onSuccess("ok");

  return {
    ...state,
    uploadImageApi: {
      loading: false,
      fileUrl: action.payload && action.payload.fileUrl,
      index:
        action.payload &&
        action.payload.callbacks &&
        action.payload.callbacks.index,
      success: true,
    },
  };
}

export function uploadImageFail(state, action) {
  notification.error({ message: "Image upload failed" });
  if (action.payload && action.payload.callbacks)
    action.payload.callbacks.onError(action.payload.error);
  return {
    ...state,
    uploadImageApi: {
      loading: false,
      error: true,
    },
  };
}
