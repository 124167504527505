import { fetchPayload } from "../../../utils/reducerOperations";
import * as helper from "./Helpers";
import {
  FETCH_EXPLORE_PARTICIPATION_FAILED,
  FETCH_EXPLORE_PARTICIPATION_SUCCESS,
  FETCH_EXPLORE_PARTICIPATION_START,
  EXPLORE_UPDATE_PARTICIPATION_COLUMNS_LIST,
  EXPLORE_DESELECT_PARTICIPATION_COLUMNS,
  CLEAR_EXPLORE_PARTICIPATION_REDUCER,
  EXPLORE_GET_DISTIRBUTION_DATA_START,
  EXPLORE_GET_DISTIRBUTION_DATA_SUCCESS,
  EXPLORE_GET_DISTIRBUTION_DATA_FAILED,
} from "../../constants";
import { InitialState } from "./InitialState";

const ExploreParticipationDataReducer = (state = InitialState(), action) => {
  let payload = fetchPayload(action);
  switch (action.type) {
    case FETCH_EXPLORE_PARTICIPATION_START:
      return helper.exploreParticipationDataStart(state, payload);
    case FETCH_EXPLORE_PARTICIPATION_SUCCESS:
      return helper.exploreParticipationDataSuccess(state, payload);
    case FETCH_EXPLORE_PARTICIPATION_FAILED:
      return helper.exploreParticipationDataFailed(state, payload);
    case EXPLORE_UPDATE_PARTICIPATION_COLUMNS_LIST:
      return helper.exploreParticipationUpdateSelectedColumn(state, payload);
    case EXPLORE_DESELECT_PARTICIPATION_COLUMNS:
      return helper.expolreDeselectParticipationColumns(state);
    case CLEAR_EXPLORE_PARTICIPATION_REDUCER:
      return helper.resetParticipationValues(state);
    case EXPLORE_GET_DISTIRBUTION_DATA_START:
      return helper.exploreDistributionDataStart(state,payload);
    case EXPLORE_GET_DISTIRBUTION_DATA_SUCCESS:
      return helper.exploreDistributionDataSuccess(state,payload);
    case EXPLORE_GET_DISTIRBUTION_DATA_FAILED:
      return helper.exploreDistributionDataFailed(state,payload);

    default:
      return { ...state };
  }
};

export default ExploreParticipationDataReducer;
