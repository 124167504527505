import {
    NEW_PROJECT_PAGE_CREATE_PROJECT_START,
    NEW_PROJECT_PAGE_CREATE_PROJECT_SUCCESS,
    NEW_PROJECT_PAGE_CREATE_PROJECT_FAIL,
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import {
    CREATE_SURVEY_URL,
} from "../../../../../config/constants";
import { getDomainName } from "../../../../utils/getDomainName";
import { withType } from "../../../../utils/actionOperations";

export function NewProjectPageCreateProject(payload, callback) {
    payload.sourceDomainName = getDomainName()
    return (dispatch) => {
        dispatch({ type: NEW_PROJECT_PAGE_CREATE_PROJECT_START });
        const axios = new ApiBase().instance;
        axios
            .post(CREATE_SURVEY_URL, { ...payload })
            .then((response) => {
                callback && callback(true,response.data);
                dispatch(
                    withType(NEW_PROJECT_PAGE_CREATE_PROJECT_SUCCESS, { response: response.data })
                );
            })
            .catch((err) => {
                callback && callback(false);
                dispatch(
                    withType(NEW_PROJECT_PAGE_CREATE_PROJECT_FAIL, {
                        error: err,
                    })
                );
            });
    };
}