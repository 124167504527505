import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const wellnessTemplate = [
    {
        questionDimension: 'Wellness',
        type: 'Matrix',
        questionLabel: 'Wellness',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: 'It is easy for me to balance work and personal activities.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('It is easy for me to balance work and personal activities.'),
        },

        {
            text: `I feel comfortable raising concerns about my own or a colleague's wellbeing.`, value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId(`I feel comfortable raising concerns about my own or a colleague's wellbeing.`),

        },
        {
            text: 'I am made aware of important deadlines early enough to include my personal life when planning work.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('I am made aware of important deadlines early enough to include my personal life when planning work.'),

        }]
    }
]




