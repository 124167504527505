import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const listReminderStarted = (state) => {
    return {
        ...state,
        listReminderApi: getApiStartState()
    }

}

export const listReminderSuccess = (state, payload) => {
    let listRemainders = sortRemainders(payload);
    return {
        ...state,
        listReminderApi: getSuccessResponse(listRemainders)
    }

}

export const listReminderFailed = (state, payload) => {
    return {
        ...state,
        listReminderApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}

const sortRemainders = (data) => {
    let sortArray = data && data.data && data.data.data && data.data.data.scheduledRemainders;
    if (sortArray && Array.isArray(sortArray)) {
        sortArray.sort((a, b) => Date.parse(a.scheduledTime) - Date.parse(b.scheduledTime))
        data.data.data.scheduledRemainders = sortArray;
    }
    return data
}