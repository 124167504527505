export const exploreDistributionDataStart = (state) => {
  return {
    ...state,
    exploreDistributionDataApi: {
      data: null,
      isProcessing: true,
      error: null,
      message: null,
    },
  };
};

export const exploreDistributionDataSuccess = (state, payload) => {
  return {
    ...state,
    exploreDistributionDataApi: {
      isProcessing: false,
      data: payload.data.data,
      error: !payload.status,
      message: payload.message,
    },
    filters: payload.filters,
    selectedUser: payload.selectedUser,
    directReporteesOnly: payload.directReporteesOnly,
  };
};

export const exploreDistributionDataFailed = (state, payload) => {
  return {
    ...state,
    exploreDistributionDataApi: {
      isProcessing: false,
      data: null,
      error: payload.error,
    },
  };
};

/* export const resetDistibutionValues = (state) => {
      return {
        ...state,
        exploreDistributionDataApi: {
          isProcessing: false,
          data: null,
          error: null,
        },
      };
    };
  */
