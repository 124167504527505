import { fetchPayload } from "../../../utils/reducerOperations";
import {
    CSV_DOWNLOAD_FAILED,
    CSV_DOWNLOAD_START,
    CSV_DOWNLOAD_SUCCESS
} from "../../constants";
import {
    csvDownloadFailed,
    csvDownloadStarted,
    csvDownloadSuccess
} from "./Helpers";
import { Default } from "./InitialState";
const CsvDownloadReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case CSV_DOWNLOAD_START:
            return csvDownloadStarted(state, payload);
        case CSV_DOWNLOAD_SUCCESS:
            return csvDownloadSuccess(state, payload);
        case CSV_DOWNLOAD_FAILED:
            return csvDownloadFailed(state, payload);
        default:
            return { ...state };
    }
};

export default CsvDownloadReducer;
