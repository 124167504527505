import {
    RESET_PASSWORD_PROCESS_FAILED,
    RESET_PASSWORD_PROCESS_SUCCESS,
    RESET_PASSWORD_PROCESS_STARTED
} from '../../constants';
import {RESET_URL} from '../../../../config/constants';
import {ApiBase} from '../../../utils/apiBase';

export const resetPasswordAction = params => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch({
            type: RESET_PASSWORD_PROCESS_STARTED
        });
        apiBaseInstance.instance
            .post(RESET_URL, params)
            .then(res =>
                resetPasswordSuccessAction(dispatch, res)
            )

            .catch(err =>
                resetPasswordFailedAction(dispatch, err)
            );
    };
};

function resetPasswordSuccessAction(dispatch, res) {
    return dispatch({
        type: RESET_PASSWORD_PROCESS_SUCCESS,
        payload: res.data
    });
}
function resetPasswordFailedAction(dispatch, err) {
    return dispatch({
        type: RESET_PASSWORD_PROCESS_FAILED,
        payload: err
    });
}
