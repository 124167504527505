export const automaticChoices = [
    {
        label: 'Agree-Disagree',
        value: '1',
        options: [
            {
                label: 'Strongly disagree',
                value: '1'
            },
            {
                label: 'Neither agree nor disagree',
                value: '2'
            },
            {
                label: 'Strongly agree',
                value: '3'
            }
        ]
    },
    {
        label: 'Good-Bad',
        value: '2',
        options: [
            {
                label: 'Extremely bad',
                value: '1'
            },
            {
                label: 'Neither good nor bad',
                value: '2'
            },
            {
                label: 'Strongly good',
                value: '3'
            }
        ]
    },
    {
        label: 'Appropriate - Inappropriate',
        value: '3',
        options: [
            {
                label: 'Extremely inappropriate',
                value: '1'
            },
            {
                label: 'Neither appropriate nor inappropriate',
                value: '2'
            },
            {
                label: 'Extremely appropriate',
                value: '3'
            }]
    },
    {
        label: 'True - False',
        value: '4',
        options: [
            {
                label: 'Definitely false',
                value: '1'
            },
            {
                label: 'Neither true nor false',
                value: '2'
            },
            {
                label: 'Definitely true',
                value: '3'
            }]
    },
    {
        label: 'Easy - Difficult',
        value: '5',
        options: [
            {
                label: 'Extremely difficult',
                value: '1'
            },
            {
                label: 'Neither easy nor difficult',
                value: '2'
            },
            {
                label: 'Extremely easy',
                value: '3'
            }]
    },
    {
        label: 'Effective - Improvement',
        value: '6',
        options: [
            {
                label: 'Needs improvement',
                value: '1'
            },
            {
                label: 'Effective',
                value: '2'
            },
            {
                label: 'Highly effective',
                value: '3'
            }]
    }
]