import * as helper from './helper';
import * as actionTypes from '../../constants';
import initialState from './intialState';

const NLPStoryboardTopResultsReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.FETCH_TOP_RESULTS_START:
        return helper.fetchTopResultsStart(state, action);
    case actionTypes.FETCH_TOP_RESULTS_SUCCESS:
        return helper.fetchTopResultsSuccess(state, action);
    case actionTypes.FETCH_TOP_RESULTS_FAIL:
        return helper.fetchTopResultsFail(state, action);
    default: return state;
    }
};

export default NLPStoryboardTopResultsReducer;