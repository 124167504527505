import { deepCopy } from "../../../../../../utils/deepCopy"

export const handleDeleteOption = (state, payload) => {
    let updatedGoals = getDeletedOptionsGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })

}

const getDeletedOptionsGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(item => {
        if (item.blockId === payload.blockId) {
            item.questions.forEach(question => {
                if (question.options.length !== 1) {
                    question.options.splice(payload.deleteIndex, 1)
                }

            })
        }
    })
    return goalsCopy
}