import {
  formChangeSuccess,
  setInitialState,
} from "../../../../utils/reducerOperations";
export function resetPasswordFormChange(state, payload) {
  return formChangeSuccess(state, payload);
}
export const resetState = (state) => {
  return {
    ...state,
    resetPasswordApi: setInitialState({ code: "" }),
  };
};
