export const communicationTemplate = [
    {
        questionDimension: 'Communication',
        type: 'Matrix',
        questionLabel: 'Communication',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [
            {
                text: 'This organization listens to what I have to say.', value: '', isSelected: true

            },
            {
                text: 'There is honest and open two-way communication at my organization.', value: '', isSelected: true

            },
            {
                text: 'My organization is effective communicating about changes.', value: '', isSelected: true

            },
            {
                text: 'My organization freely shares information about topics that affect our business.', value: '', isSelected: false
            }]
    }
]

