import  FeedBackModal  from "./FeedBackModal";
import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';
import {NewdashboarStoryboardSendFeedback} from '../../../redux/actions'

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    NewdashboarStoryboardSendFeedback: (params,cb) => dispatch(NewdashboarStoryboardSendFeedback(params,cb)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeedBackModal));