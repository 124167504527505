

export const NewSurveyPageFetchOrganizationStarted = (state, payload)=> {
    return {
        ...state,
        NewSurveyPageFetchOrganizationApi: {
            isProcessing:true,
            data:null,
            error:false,
            message:''
        }
    }
}

export const NewSurveyPageFilterOrganizations = (data) =>{
    data.Items = data.hits.filter(
        item =>
            item.hasOwnProperty('name') &&
			item.hasOwnProperty('organizationId')
    );
    return data;
}

export const  NewSurveyPageFetchOrganizationSuccess = (state, payload) =>{
    return {
        ...state,
        NewSurveyPageFetchOrganizationApi: {
            isProcessing:false,
            data:NewSurveyPageFilterOrganizations(payload.data),
            error:false,
            message:''
        }
    }
}

export function NewSurveyPageFetchOrganizationFailed(state, payload) {
    return {
        ...state,
        NewSurveyPageFetchOrganizationApi: {
            isProcessing:false,
            data:false,
            error:true,
            message:payload
        }
    }
}