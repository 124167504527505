import TopicLibraryReprocess from "./topicLibraryReprocess";
import { connect } from "react-redux";
import {
    uploadTopicLibrary,
    clearTopicFile,
} from "../../../../../../redux/actions/NLPAdminAction";
import {
    bulkUploadStart,
    getPreSignedURL,
    uploadFile,
    addJob,
    addCommentsJob
} from "../../../../../../redux/actions";


const mapStateToProps = (state) => ({
    ...state.AdminReducer
});

const mapDispatchToProps = (dispatch) => ({
    clearTopicFile: () => dispatch(clearTopicFile()),
    uploadTopicLibrary: (file) => dispatch(uploadTopicLibrary(file)),
    getPreSignedURL: (params, next) => {
        dispatch(getPreSignedURL(params, next));
    },
    uploadFile: (params, next) => {
        dispatch(uploadFile(params, next));
    },
    addJob: (params, next) => {
        dispatch(addJob(params, next));
    },
    bulkUploadStart: (params, next) => {
        dispatch(bulkUploadStart(params, next));
    },
    addCommentsJob: (params, next) => {
        dispatch(addCommentsJob(params, next));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(TopicLibraryReprocess);
