import {
    UN_PUBLISH_SURVEY_START,
    UN_PUBLISH_SURVEY_SUCCESS,
    UN_PUBLISH_SURVEY_FAILED
} from '../../../constants';
import { UN_PUBLISH_SURVEY_URL } from '../../../../../config/constants';
import { ApiBase } from '../../../../utils/apiBase';
import { withType } from '../../../../utils/actionOperations';
import { notification } from 'antd';
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';


export const unPublishSurvey = (payload, callback) => {
    const apiBaseInstance = new ApiBase();
    return dispatch => {
        dispatch(withType(UN_PUBLISH_SURVEY_START, {}));
        apiBaseInstance.instance
            .post(UN_PUBLISH_SURVEY_URL, { ...payload, ...getProjectIdAndOrgId(payload) })
            .then(response => {
                notification.success({ message: response.data.data.successMessage });
                dispatch(withType(UN_PUBLISH_SURVEY_SUCCESS, {
                    data: response.data,
                    message: response.message
                }));
                callback && callback(true);
            })
            .catch(err => {
                callback && callback(false);
                notification.error({ message: err })
                dispatch(withType(UN_PUBLISH_SURVEY_FAILED, {
                    error: err
                }));
            });
    };
};

