export function translateSurveyStart(state, action) {
    return {
        ...state,
        surveyTranslateApi: {
            isProcessing: true,
            error: false
        }
    };

}

export function translateSurveySuccess(state, action) {
    return {
        ...state,
        surveyTranslateApi: {
            isProcessing: false,
            error: false
        },
        translatedTexts: action.payload.data && action.payload.data.translatedData,
    };

}

export function translateSurveyFailed(state, action) {
    return {
        ...state,
        surveyTranslateApi: {
            isProcessing: false,
            error: true
        },
        translatedTexts: state.defaultTexts
    };

}