
import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const executesUrgency = {
    allowNAOption: true,
    "blockId": uuidv4(),
    "goalId": "dynamic360Strength",
    "description": "<p>Leaders who execute with urgency use the Masco Operating System (MOS) to drive results. Below are the behaviors that help us win in this space. Please share your perspective on how often you feel the following behaviors are demonstrated by this leader.</p>",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Rarely",
                    "value": "1"
                },
                {
                    "label": "Not Often Enough",
                    "value": "2"
                },
                {
                    "label": "Often",
                    "value": "3"
                },
                {
                    "label": "Almost Always",
                    "value": "4"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "staticQuestionId": generateStaticQuestionId("Embeds MOS into the way the organization operates."),
                    "HTMLText": "<p>Embeds MOS into the way the organization operates.</p>",
                    "isSelected": true,
                    "text": "Embeds MOS into the way the organization operates.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Constantly pursues gaps - is uncomfortable with the status quo."),
                    "HTMLText": "<p>Constantly pursues gaps - is uncomfortable with the status quo.</p>",
                    "isSelected": true,
                    "text": "Constantly pursues gaps - is uncomfortable with the status quo.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Challenges biases and beliefs when making decisions."),
                    "HTMLText": "<p>Challenges biases and beliefs when making decisions.</p>",
                    "isSelected": true,
                    "text": "Challenges biases and beliefs when making decisions.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Works to define the right problem - gets insights to drive action."),
                    "HTMLText": "<p>Works to define the right problem - gets insights to drive action.</p>",
                    "isSelected": true,
                    "text": "Works to define the right problem - gets insights to drive action.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Creates action - orientation to make progress quickly."),
                    "HTMLText": "<p>Creates action - orientation to make progress quickly.</p>",
                    "isSelected": true,
                    "text": "Creates action - orientation to make progress quickly.",
                    "value": uuidv4()
                },
                {
                    "staticQuestionId": generateStaticQuestionId("Delegates the work and decisions to the right level."),
                    "HTMLText": "<p>Delegates the work and decisions to the right level.</p>",
                    "isSelected": true,
                    "text": "Delegates the work and decisions to the right level.",
                    "value": uuidv4()
                }
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Execute with urgency"
}