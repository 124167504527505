import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const managerTemplate = [
    {
        questionDimension: 'My Manager',
        type: 'Matrix',
        questionLabel: 'My Manager',
        optionsCount: 5,
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Neither agree nor disagree",
                value: "2"
            },
            {
                label: "Strongly agree",
                value: "3"
            }
        ],
        question: '',
        qid: uuidv4(),
        matrixQuestions: [{
            text: 'My manager gives me useful feedback that helps me improve.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My manager gives me useful feedback that helps me improve.'),
        },
        {
            text: 'My manager helps me set clear goals and priorities for my work.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My manager helps me set clear goals and priorities for my work.'),

        },
        {
            text: 'My manager cares about me as a person, not just as an employee.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My manager cares about me as a person, not just as an employee.'),

        },
        {
            text: 'My manager appreciates extra efforts.', value: uuidv4(), isSelected: true, staticQuestionId: generateStaticQuestionId('My manager appreciates extra efforts.'),

        }]
    }
]


