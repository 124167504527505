export * from './fetchWordCloudData';
export * from './fetchCommentStatisticsData';
export * from './fetchCommentsData';
export * from './fetchRelationData';
export * from './fetchTopicComments';
export * from './fetchAllThemeData';
export * from './fetchThemeTopicData';
export * from './setInitialState'
export * from './fetchExploreDashBoardFilter'
export * from './setSelectedTopic'

export const updateThemeState = (state, payload) => {
  return {
    ...state,
    themeTopicDataApi: {
      ...state.themeTopicDataApi,
      ...payload,
    },
  };
};
