import { Radio, Row, Col } from 'antd';
import React, { Component } from 'react';
import { isCurrentCellChecked } from './helper';
import { ButtonElement } from '../../../../../../FormElements/ButtonElement';
import { gatherTranslationKeys, getQuestionTranslation, getTextForTranslation } from '../../../../../../utils/newAttendSurveyUtils';
import { KEY_CONSTANTS } from '../../constants';
import ErrorBlock from '../ErrorBlock';
import { AsterikLabel } from '../../../../../../Components/AsterikLabel';
import parse from "html-react-parser";
import "./index.scss";
import ScrollBar from '../../../../../../Packages/ScrollBar';
import { FEEDBACK_360_FOR_PARTICULAR_ORG_IDS } from '../../../../../../../config/questionTypeConstants';
import { debounce } from 'lodash';
import { checkIfPreviewLink } from '../FinalPage/helper';
import constants from '../../../../constants';


class TableMatrix extends Component {
    constructor(props){
        super(props)
        this.state={
          //  scrolledHeightClass:"",
            scrollExtra: this.props.scrollElementRef.current.scrollTop,
            translatedLanguages: this.props.surveyQuestionSetData.translationLanguages ? this.props.surveyQuestionSetData.translationLanguages : []

        }
       // this.debouncedHandleScroll = debounce(this.handleScroll, 100);
    }

    // componentDidMount() {
    //     if (this.props.scrollElementRef && this.props.scrollElementRef.current) {
    //        // this.props.scrollElementRef.current.addEventListener('scroll', this.debouncedHandleScroll);
    //     }
    // }

    // componentWillUnmount() {
    //     if (this.props.scrollElementRef && this.props.scrollElementRef.current) {
    //       //  this.props.scrollElementRef.current.removeEventListener('scroll', this.debouncedHandleScroll);
    //     }
    // }

    // handleScroll = (scroll) => {    
    //     if (this.props.scrollElementRef.current.scrollTop > 200 && this.state.scrolledHeightClass!=="stickyHeader") {


    //         this.setState({
    //             scrolledHeightClass: "stickyHeader"
    //         })
    //     }
    //     else if(this.state.scrolledHeightClass!=="" && this.props.scrollElementRef.current.scrollTop < 200) {
    //         console.log("top", this.props.scrollElementRef.current.scrollTop)
    //                 console.log("height", this.props.scrollElementRef.current.scrollHeight)

    //         debugger
    //         this.setState({
    //             scrolledHeightClass: ""
    //         })
    //     }
    // }
    
    handleChangeCheckbox = (payload, row) => {
        let extraTranslationKeys = {}
        if (this.state.translatedLanguages.length > 0) {
            extraTranslationKeys = gatherTranslationKeys(row, this.state.translatedLanguages)
            payload.answeredRadio = { ...payload.answeredRadio, ...extraTranslationKeys, extraTranslationKeys }
        }
        this.props.handleChangeTableMatrix(payload)
        const scrollTop = this.props.scrollElementRef.current.scrollTop;
        const scrollHeight = this.props.scrollElementRef.current.scrollHeight;
        const clientHeight = this.props.scrollElementRef.current.clientHeight;
        const maxScrollTop = scrollHeight - clientHeight;
        if(this.props.scrollElementRef.current.clientWidth>600 && checkIfPreviewLink(constants.PREVIEW_LINK)){
            if (scrollTop > 0.1 * maxScrollTop && scrollTop < 0.3 * maxScrollTop && this.state.scrollExtra < 0.67 * maxScrollTop) {
                this.setState({
                    scrollExtra: scrollTop + 0.15 * maxScrollTop + this.state.scrollExtra
                });
            } else {
                this.setState({
                    scrollExtra: 0
                });
                if (this.state.scrollExtra > 0.32 * maxScrollTop) {
                    this.props.manageScroller();
                } else {
                    this.props.manageScroller();
                }
            }
        }
        else{
            this.props.manageScroller(false,140); 
        }
        
    }


    renderTable = (question) => {
        let answer = this.props.currentQuestionSet && this.props.currentQuestionSet.answer ? JSON.parse(this.props.currentQuestionSet.answer) : []
        return (
     
            <div className={this.props.questionDetails.blockImage ? `${this.props.currentClassname}matrixsurvey-img` : FEEDBACK_360_FOR_PARTICULAR_ORG_IDS.includes(this.props.surveyQuestionSetData.organizationId) ? `${this.props.currentClassname}singlesurvey-matrixtable` : `${this.props.currentClassname}matrixsurvey-table`}>


                            <table>

                                <tr className={`metric-row`}>

                                    <th className=''></th>
                                    {question.options.map((item, index) => <th key={index} className=''><div className={ FEEDBACK_360_FOR_PARTICULAR_ORG_IDS.includes(this.props.surveyQuestionSetData.organizationId) ? 'singlesurvey-matrixtable__head':'matrixsurvey-table__head'} >{parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: item, key: this.props.selectedLanguage, isCustomMatrix:true }))}</div></th>)}


                                </tr>

                    {question.matrixQuestions.map((row, index) => {
                                    return <tr key={row.id}>
                                        <th className='fixed'>{parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: row, key: this.props.selectedLanguage, isCustomMatrix:true }))} </th>
                                        {question.options.map(item => <th align='center' scope="row" className={ FEEDBACK_360_FOR_PARTICULAR_ORG_IDS.includes(this.props.surveyQuestionSetData.organizationId) ?`singlemetrix-th ${item.value === "0" ? "metrix-last" : ""}`:`metrix-th ${item.value === "0" ? "metrix-last" : ""}`}>
                                            {/* <Radio onChange={(event) => {
                                this.props.handleChangeTableMatrix(
                                    { answeredRadio: { checked: event.target.checked, rowId: row.value, columnId: item.value, }, blockId: this.props.questionDetails.blockId })
                                }} checked={isCurrentCellChecked({ rowId: row.value, columnId: item.value }, answer)} /> */}
                                        <div className={ FEEDBACK_360_FOR_PARTICULAR_ORG_IDS.includes(this.props.surveyQuestionSetData.organizationId) ? 'surveymetrix-th' : 'metrix-new-th'}>
                                            <label class="radio-button-container" >
                                                <input type="radio" checked={isCurrentCellChecked({ rowId: row.value, columnId: item.value }, answer)} name={row.value} onChange={(event) => {
                                                        this.handleChangeCheckbox(
                                                            { answeredRadio: { checked: event.currentTarget.checked === "on", rowId: row.value, columnId: item.value, label: item.label, question: row.text, questionDimension: this.props.questionDetails.dimension, staticQuestionId: row.staticQuestionId, subCategory: row.subCategory }, blockId: this.props.questionDetails.blockId }, row)
                                                }} />
                                                <span class="checkmark"></span>
                                            </label>
                                            </div>
                                        </th>)}
                                    </tr>
                                })}
                            </table>


                        </div>

        )
    }

    render() {
        return (
            <div>{this.props.questionDetails.questions.map((question) => {
                return (<>
                    <h3 className={this.props.questionDetails.isRequired ? 'heading-asterisk' : ""}>{this.props.questionDetails.dimension === "" ? <p>...</p> : <p>{this.props.questionDetails.dimension}</p>}</h3>
                    <h5> {this.props.questionDetails.description && parse(this.props.questionDetails.description)}</h5>
                    {this.renderTable(question)}
                </>)
            })}
                <div className='multioption-block__button'>
                    {/* <ButtonElement type={'primary'} className='multipleoption-btn' onClick={() => this.props.handleGoPrevAndNext(false, false)}>
                        {getTextForTranslation({
                            language: this.props.selectedLanguage,
                            defaultTexts: this.props.defaultTexts,
                            translatedTexts: this.props.translatedTexts,
                            key: KEY_CONSTANTS.CONTINUE_BUTTON
                        })}
                    </ButtonElement> */}
                </div>
                <ErrorBlock manageScroller={this.props.manageScroller}/>

            </div>
        );
    }
}

export default TableMatrix;
