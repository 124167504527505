import { notification } from "antd";

export const uploadTranslatedQuestionsStart = (state, payload) => {
    return {
        ...state,
        uploadTranslatedQuestionsApi: {
            isProcessing: true,
            error: false
        }
    }

}

export const uploadTranslatedQuestionsSuccess = (state, payload) => {
    notification.success({ message: 'Questions uploaded successfully' });
    return {
        ...state,
        uploadTranslatedQuestionsApi: {
            isProcessing: false,
            error: false
        },
    }

}

export const uploadTranslatedQuestionsFailed = (state, payload) => {
    notification.error({ message: 'Failed to upload' });
    return {
        ...state,
        uploadTranslatedQuestionsApi: {
            isProcessing: false,
            error: true
        }
    }

}


export const checkTranslatedQuestionsStart = (state, payload) => {
    return {
        ...state,
        checkTranslatedQuestionsApi: {
            isProcessing: true,
            error: false
        }
    }

}

export const checkTranslatedQuestionsSuccess = (state, payload) => {
    return {
        ...state,
        checkTranslatedQuestionsApi: {
            isProcessing: false,
            error: false,
            data: payload.data.data
        },
    }

}

export const checkTranslatedQuestionsFailed = (state, payload) => {
    return {
        ...state,
        checkTranslatedQuestionsApi: {
            isProcessing: false,
            error: payload,
        }
    }

}