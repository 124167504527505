import React, { Component } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend, Cell
} from 'recharts';

const barColors = ["#448E8B", "#4B9D9A", "#4C9B97", "#4FA39F", "#80CCC9"]
export default class StackChart extends Component {

  constructor(props) {
    super(props)
    this.state={}
  }

  formatXAxis = (item) => {
    if (this.props.formatXAxis)
      return this.props.formatXAxis(item)
    return item;
  }
  formatCellColor = () => {
    let maxValue = Math.max.apply(Math, this.props.data.map(function (o) { return o.emailResponseCount; }))

    return maxValue;
  }
  render() {
    const maxValue = this.formatCellColor()
    return (
      <ResponsiveContainer className={this.props.className} width={this.props.width || 700} height={this.props.height || 400}>
        <BarChart
          onClick={(e) => this.props.onClick && this.props.onClick()}
          width={this.props.barChartWidth || 700}
          height={this.props.barChartHeight || 400}
          data={this.props.data}
          style={this.props.onClick ? { cursor: 'pointer' } : ""}
        >
          <XAxis dataKey={this.props.xAxisKey || "date"} tickFormatter={this.formatXAxis} ticks={this.props.ticks} tickSize={3} />
          <YAxis tick={this.props.hideYTicks ? false : !this.props.hideYAxis} axisLine={!this.props.hideYAxis} tickLine={!this.props.hideYAxis} />
          {this.props.showTooltip && <Tooltip separator={': '} labelFormatter={this.formatXAxis} offset={0} wrapperStyle={{ left: 0 }} />}
          {this.props.showLegend && <Legend layout="vertical" verticalAlign="middle" align="right" />}
          {!this.props.isAnonymous &&
            <Bar name="Email" dataKey="emailResponseCount" stackId="a" fill="#80CCC9" barSize={this.props.barSize || 20} > {
              this.props.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={barColors[maxValue - entry.emailResponseCount > 4 ? 4 : maxValue - entry.emailResponseCount]} />
              ))
            }</Bar>}
          {this.props.isAnonymous && <Bar name="Anonymous Link" dataKey="anonymousResponseCount" stackId="a" fill="#009933" width={this.props.barWidth || 200} />}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
