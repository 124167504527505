import { fetchPayload } from "../../../utils/reducerOperations";
import {
    FETCH_STATUS_OF_MANAGER_HEATMAP_START,
    FETCH_STATUS_OF_MANAGER_HEATMAP_SUCCESS,
    FETCH_STATUS_OF_MANAGER_HEATMAP_FAIL
} from "../../constants";
import { fetchManagerHeatmapStatusFailed, fetchManagerHeatmapStatusStart, fetchManagerHeatmapStatusSuccess } from "./Helper";

import { initialState } from "./InitialState";

const ManagerHeatmapStatusReducer = (state = initialState(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case FETCH_STATUS_OF_MANAGER_HEATMAP_START:
            return fetchManagerHeatmapStatusStart(state, payload);
        case FETCH_STATUS_OF_MANAGER_HEATMAP_SUCCESS:
            return fetchManagerHeatmapStatusSuccess(state, payload);
        case FETCH_STATUS_OF_MANAGER_HEATMAP_FAIL:
            return fetchManagerHeatmapStatusFailed(state, payload);

        default:
            return { ...state };
    }
};

export default ManagerHeatmapStatusReducer;