import _defineProperty from "/tmp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
export var DASHBOARD_TABS = {
  DASHBOARD: 'dashboard',
  STORYBOARD: 'storyboard',
  EXPLORE: 'explore',
  EXPLORE_SUB_TABS: _defineProperty(_defineProperty({
    TREND: "exploreTrend",
    TOPICS: 'exploreTopics',
    MULTIPLE_QUESTIONS: "multiSelectQuestion",
    QUESTIONS: 'exploreQuestions',
    DRIVERS: 'exploreDrivers',
    OUTLIERS: 'exploreOutliers',
    PARTICIPATION: 'exploreParticipation',
    DEMOGRAPHICS: 'exploreDemographics',
    COMMENT_QUESTIONS: 'exploreCommentQuestions'
  }, "TREND", "exploreTrend"), "TREND_INSIGHTS", "exploreTrendInsights"),
  HEATMAP: 'heatmap',
  HEATMAP_SUB_TABS: {
    BY_MANAGER: 'heatmapByManagerHeatmap'
  },
  TWO_BY_TWO_MATRIX: "twoByTwoMatrix",
  COMMENTS: 'comments',
  INSIGHTS: 'insights',
  MANAGE: 'manage',
  MANAGE_SUB_TABS: {
    USERS: 'manageUsers',
    EXPORT: 'manageExport',
    SAMPLE_COMMENTS: 'manageSampleComments',
    RESPONSES: 'manageResponses',
    THEME_LIBRARY: 'manageThemeLibrary',
    VIEWERS: 'manageViewers',
    BENCHMARK: 'manageBenchmark',
    TREND: 'manageTrend',
    CONFIGURATIONS: 'manageConfigurations'
  }
};
export var SURVEY_WIZARD_TABS = {
  SURVEY_WIZARD: 'surveyWizard',
  WIZARD_SUB_TABS: {
    EDIT_SURVEY: 'wizardEditSurvey',
    EDIT_SURVEY_SUB_TABS: {
      HEADER: 'wizardEditSurveyHeader'
    },
    QUESTIONS: 'wizardQuestions',
    SETUP: 'wizardSetup',
    SETUP_SUB_SECTIONS: {
      PARTICIPANTS: 'wizardSetupParticipants',
      LANGUAGES: 'wizardSetupLanguages',
      SENDING: 'wizardSetupSending',
      REMINDER: 'wizardSetupReminder',
      CLOSING: 'wizardSetupClosing'
    },
    RESPONSES: 'wizardResponses',
    DRAFT_SURVEY: 'wizardDraftSurvey'
  }
};
export var PROJECTS_PAGE_SECTIONS = {
  PROJECTS: 'projects',
  CREATE: 'projectsCreateProject',
  COHORT_SURVEY: "projectsCohortSurvey",
  THREE_DOT_MENU: {
    COPY: 'projectsThreeDotMenuCopy',
    CLOSE: 'projectsThreeDotMenuClose',
    DELETE: 'projectsThreeDotMenuDelete',
    INVITE: 'projectsThreeDotMenuInvite',
    PUBLISH: 'projectsThreeDotMenuPublish',
    RENAME: 'projectsThreeDotMenuRename',
    REOPEN: 'projectsThreeDotMenuReopen',
    UNPUBLISH: 'projectsThreeDotMenuUnpublish'
  }
};
export var ORGANIZATION_PAGE_SECTION = {
  ORGANIZATION: 'organization'
};
export var PEOPLE_PAGE_SECTION = {
  PEOPLE: 'people',
  THREE_DOT_MENU: {
    RENAME: 'peopleThreeDotMenuRename',
    DELETE: 'peopleThreeDotMenuDelete'
  }
};
export var USERS_PAGE_SECTION = {
  USERS: 'users'
};
export var SETTINGS_PAGE_SECTION = {
  SETTINGS: 'settings',
  SETTINGS_SUB_TABS: {
    TOPIC_LIBRARY: 'settingsTopicLibrary',
    NAME_AND_EMAIL: 'settingsName&Email',
    PASSWORD: 'settingsPassword',
    ADMINISTRATOR: 'settingsAdministrator',
    LOGS: 'settingsLogs'
  }
};
export var TEXT_ANALYSIS_PAGE_SECTION = {
  TEXT_ANALYSIS_TABLE: 'textAnalysisListTable'
};
export var RESULTS_PAGE_SECTION = {
  RESULTS_PAGE: 'resultsPage'
};
export var COHORT_SURVEY_SECTION = {
  CREATE_COHORT_SURVEY: 'createCohortSurveyPage'
};
export var LOGIN = 'login';