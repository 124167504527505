export const TEXT_SECTION = {
    "commentsOnStrength": "<p><b>CONTEXT FOR TOP STRENGTHS: </b>Share examples or stories of how this leader demonstrated these leadership strengths to get a better outcome. Consider the impact made on other individuals, the team, or the organization. Help the person better understand how to leverage their strengths further.</p> ",
    "commentsOnImprovement": "<p><b>CONTEXT FOR BACKHANDS / IMPROVEMENT PRIORITIES: </b>Share examples or stories of when the leader didn’t demonstrate these behaviors enough. Help clarify why these might be most important behaviors to focus on. (Note: your input is anonymous but will be shown verbatim in the report.)</p>",
}

export const TEXT_SECTION_180 = {
    "commentsOnStrength": "<p><b>CONTEXT FOR TOP STRENGTHS: </b>Share examples or stories of how these strengths are demonstrated to get better outcomes. Consider the impact made on other individuals, the team, or the organization. Help this person better understand how to further leverage these strengths. (Note: Your input will be shown verbatim in the report.)</p> ",
    "commentsOnImprovement": "<p><b>CONTEXT FOR BACKHANDS / IMPROVEMENT PRIORITIES: </b>Share examples or stories of when this person didn’t demonstrate these behaviors enough. Help clarify why these might be the most important behaviors to focus on. (Note: Your input will be shown verbatim in the report).</p>",
}

export const TEXT_SECTION_180_SELF = {
    "commentsOnStrength": "<p><b>CONTEXT FOR TOP STRENGTHS: </b>Take a moment to reflect and write examples or stories of how you demonstrated these strengths to get better outcomes. Consider the impact made on other individuals, the team, or the organization. Try to highlight the context and behaviors to better understand how to further leverage these strengths.</p> ",
    "commentsOnImprovement": "<p><b>CONTEXT FOR BACKHANDS / IMPROVEMENT PRIORITIES: </b>Take a moment to reflect and write examples or stories of when you didn’t demonstrate these behaviors enough. Try to highlight the context and rationale to help inform how to improve the priority areas.</p>"
}

export const SELF = "self"

export const CHECKBOX_TEXT = "Can't think of examples at this point."