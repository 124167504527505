import { getOrgName, getBatchId } from "../../utils/localStorgeOperations";
import { ROLES } from "../../../config/constants";

export default {
  HOME: "Home",
  HOME_URL: "/admin/home",
  WITH_ACTIVE: "route-header-main-breadcrumb-item active-bc",
  WITHOUT_ACTIVE: "route-header-main-breadcrumb-item",
  ORG_NAME: "/orgName",
  ROUTES: {
    home: () => "/admin/home",
    organization: () => "/admin/home/organizations",
    addOrganization: () => "/admin/home/organizations/addorganization",
    orgName: () => "/orgName",
    manageUsers: (orgId) =>
      "/admin/home/organizations/" + orgId + "/manageusers",
    addReports: (orgId) => "/admin/home/organizations/" + orgId + "/addreports",
    addDataCollectionReports: (orgId) => "/admin/home/organizations/" + orgId + "/Data_Collection/add-data-collection-reports",
    survey: (orgId) => "/home/" + orgId + "/surveys",
    dataCollection: (orgId) => "/home/" + orgId + "/survey/projects/draft",
    numericAnalytics: (orgId) =>
      "/home/" + orgId + "/survey/engagement/reports/executiveoverview",
    textAnalytics: (orgId) =>
      "/home/" + orgId + "/survey/nlp/dashboard/overview?batch=" + getBatchId(),
  },
  LABELS: {
    home: () => "Home",
    organization: () => "Organization",
    addOrganization: () => "Add Organization",
    orgName: () => getOrgName(),
    manageUsers: () => "Manage Users",
    addReports: () => "Add Reports",
    survey: () => "Survey",
    dataCollection: () => "Data collection",
    numericAnalytics: () => "Numeric Analytics",
    textAnalytics: () => "Text Analytics",
  },
  CLASS: {
    home: () => "",
    organization: () => "",
    addOrganization: () => "",
    orgName: () => "orgName",
    manageUsers: () => "",
    addReports: () => "",
    survey: () => "",
    dataCollection: () => "",
    numericAnalytics: () => "",
    textAnalytics: () => "",
  },
  ROLES: {
    home: () => [ROLES.ADMIN],
    organization: () => [ROLES.ADMIN, ROLES.ORG_ADMIN],
    addOrganization: () => [ROLES.ADMIN],
    orgName: () => [],
    manageUsers: () => [ROLES.ADMIN, ROLES.ORG_ADMIN],
    addReports: () => [ROLES.ADMIN, ROLES.ORG_ADMIN],
    survey: () => [],
    dataCollection: () => [ROLES.ADMIN, ROLES.ORG_ADMIN],
    numericAnalytics: () => [],
    textAnalytics: () => [],
  },
};
