import { deepCopy } from "../../../../../utils/deepCopy"

export const topicsDragAndDrop = (state, payload) => {
    let goals = getdragAndDropAppliedGoals(state.goals, payload)
    return {
        ...state,
        goals: goals
    }
}


const getdragAndDropAppliedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    if (payload.destination && payload.source ) {
        let dragTopicId = payload.destination.droppableId
        let destination = payload.destination
        let source = payload.source
        goalsCopy.forEach(item => {
            if (item.goalId === dragTopicId) {
                const dragQuestion = item.questions[0].matrixQuestions[source.index]
                if (dragQuestion) {
                    item.questions[0].matrixQuestions.splice(source.index, 1)
                    item.questions[0].matrixQuestions.splice(destination.index,0,dragQuestion)
                }
            }
        })
        return goalsCopy
    } else {
        return goalsCopy
    }
}   