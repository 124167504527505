import React from 'react';
import { StoryBoardNewBarChart } from '../../../../../../../../Components/StoryBoardNewBarChart';

export const ThemeBarChart = (props) => {
  return (
    <>
      {props.data &&
        !!props.data.length &&
        props.data.map((item) => {
          return (
            !!item.value.length && (
              <div className='div-barchart' key={item.key}>
                <h2>
                  {item.key !== 'Average Score' ? `By ${item.key}` : item.key}
                  {item.key !== 'Average Score' &&
                    props.data.some((i) => i.key === 'Average Score') &&
                    props.data.length > 2 ? (
                    <div
                      onClick={() => props.onRemove && props.onRemove(item.key)}
                    >
                      <p>remove</p>
                    </div>
                  ) : (
                    ''
                  )}
                </h2>
                <div>
                  <StoryBoardNewBarChart
                    defaultTopicIsSelected={props.defaultTopicIsSelected}
                    labelClass={props.defaultTopicIsSelected ? 'barchart-cursor' : ''}
                    graphCursorClass={props.defaultTopicIsSelected ? '' : 'progress-no-cursor'}
                    showPercentage={true}
                    data={item.value}
                    limit={3}
                    threshold={props.threshold}
                    onClick={props.onBarchartClick}
                    hideBackground={true}
                  />
                </div>
              </div>
            )
          );
        })}
    </>
  );
};
