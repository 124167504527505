import { getCurrentQuestionSetDetails } from "../../../../utils/newAttendSurveyUtils"
import constants from "./constants"

export const getType = (questionDetails) => {
    if (!questionDetails) return ""
    if (questionDetails.isFinalPage) return constants.FINAL_PAGE
    if (questionDetails.isTranslationSelection) {
        return constants.TRANSLATION_PAGE
    }
    // return questionDetails.questions[0] && questionDetails.questions[0].type ? questionDetails.questions[0].type : ""
    return questionDetails.type && questionDetails.type ? questionDetails.type : ""
}

export const getComponentProps = (instance) => {
    return ({
        scrollElementRef:instance.props.scrollElementRef,
        currentClassname: instance.props.currentClassname,
        manageScroller: instance.props.manageScroller,
        questionDetails: instance.props.questionDetails,
        handleGoPrevAndNext: (param1, param2, isButtonDisable) => instance.props.handleGoPrevAndNext(param1, param2, isButtonDisable),
        currentQuestionSet: instance.props.questionSet && instance.props.questionDetails && getCurrentQuestionSetDetails(instance),
        submitSurveyLoading: instance.props.submitSurveyResponseApi.isProcessing,
        isSurveyCompleted: instance.props.surveyCompleted,
        selectedLanguage: instance.props.selectedLanguage,
        surveyQuestionSetData: instance.props.surveyQuestionSetApi.data,
        translatedTexts: instance.props.translatedTexts,
        defaultTexts: instance.props.defaultTexts,
        surveyTranslateLoading: instance.props.surveyTranslateApi.isProcessing,
        questionSet: instance.props.questionSet,
        isFromEditorPreview: instance.props.isFromEditorPreview,
        relation:instance.props.relation
    })
}

