export function updatePopupVisibility(state, payload) {
    switch(payload){
    case 'contact':return{
        'showContact':!state.showContact,
        'showResources':false,
        'showTeam':false
    }; 
    case 'team':return {
        'showTeam':!state.showTeam,
        'showResources':false,
        'showContact':false
    };
    case 'resources': return {
        'showResources':!state.showResources,
        'showContact':false,
        'showTeam':false
    };
    default :
    }
}