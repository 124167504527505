import styled from 'styled-components';
const defaultRowHeight = 30;
const TreeNode = styled.div`
    cursor: pointer;
    position: relative;
    line-height: ${({ rowHeight = defaultRowHeight }) => rowHeight - 2}px;
    background: ${props => props.selected ? 'transparent' : 'transparent'};
    color: ${props => props.selected ? '#ed7d31' : '#3f3f3f'};
    padding-left: ${props => props.depth * 18}px;
    .dropdown {
        visibility: hidden;
    }
    a {
        color: #3f3f3f;
    }
    &:hover {
        background: transparent;
        color: #ed7d31;
        .dropdown {
            visibility: inherit;
        }
        a {
            color: #ed7d31;
        }
    }
`;

export default TreeNode;