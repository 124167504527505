export const agilityTemplate = [
    {
        questionDimension: 'Agility',
        type: 'Matrix',
        questionLabel: 'Agility',
        options: [
            {
                label: "Strongly disagree",
                value: "1"
            },
            {
                label: "Somewhat disagree",
                value: "2"
            },
            {
                label: "Neither agree nor disagree",
                value: "3"
            },
            {
                label: "Somewhat agree",
                value: "4"
            },
            {
                label: "Strongly agree",
                value: "5"
            }
        ],
        question: '',
        matrixQuestions: [{
            text: 'We quickly bounce back from failures and setbacks.', value: '', isSelected: true
        },
        {
            text: 'My organization has formal processes in place to learn from failures.', value: '', isSelected: true

        },

        {
            text: `We quickly change tactics if something doesn't work.`, value: '', isSelected: true

        },
        {
            text: 'We remain calm and collected when facing tough situations.', value: '', isSelected: false

        }]
    }
]

