import {
  makeKey,
  update,
  getSuccessResponse,
} from "../../../../utils/reducerOperations";
import { toast } from "../../../../utils/messageUtils";

export function changePasswordSuccess(state, payload) {
  toast.success("Password changed successfully");
  return makeKey(
    "changePasswordApi",
    update(state.changePasswordApi, {
      ...getSuccessResponse(payload),
      code: payload.code,
    })
  );
}
