import { questionSetToBenchmarkFormat } from "../../../../utils/goalsUtils"

export const fetchStatusReportDataStart = (state) => {
    return {
        ...state,
        fetchStatusReportApi: {
            isProcessing: true,
            data: null,
            error: false,
            message: ''
        }
    }

}

export const fetchStatusReportDataSuccess = (state, payload) => {
    return {
        ...state,
        fetchStatusReportApi: {
            isProcessing: false,
            data: payload.data.data.response,
            error: false,
            message: payload.message
        },
        isBenchMarkDataAlreadyFetched: true
    }

}

export const fetchStatusReportDataFailed = (state, payload) => {
    return {
        ...state,
        fetchStatusReportApi: {
            isProcessing: false,
            data: null,
            error: payload.error,
        },
    }

}