import {
    getSuccessResponse, handleApiResponseError, handleNetworkError,
} from '../../../utils/reducerOperations';

export function getParticipationRateStart(state, action) {
    return {
        ...state,
        getParticipationRateApi: {
            data: null,
            isProcessing: true,
            error: false,
            message: null
        }
    };
}

export function getParticipationRateSuccess(state, action) {
    const { columns, result, selectedColumns } = action.payload.data.aggregatedDemographicsResponse;
    let page = ""
    if (action.payload.page) { page = action.payload.page }
    if (page === "compare") {
        return {
            ...state,
            getParticipationRateApi: getSuccessResponse(action.payload),
            participationColumns: columns,
            //selectedParticipationColumns: Object.keys(result || {}),
            selectedParticipationColumns: selectedColumns.length > 0 ? selectedColumns : Object.keys(result || {}),

        };

    }
    else {
        return {
            ...state,
            getParticipationRateApi: getSuccessResponse(action.payload),
            participationColumns: columns,
            selectedParticipationColumns: [],
        };
    }




}

export function getParticipationRateFail(state, action) {
    return {
        ...state,
        getParticipationRateApi:
            (action.response
                && action.response.response
                && action.response.response.data
                ? handleApiResponseError(action.response.response.data)
                : handleNetworkError(action.response))
    };
}

export function updateParticipationSelectedColumns(state, action) {
    if (action.selected) {
        let { selectedParticipationColumns } = state;
        const index = (selectedParticipationColumns || []).findIndex(
            (i) => i === action.selected
        );
        if (index > -1)
            selectedParticipationColumns =
                selectedParticipationColumns.filter((i) => i !== action.selected);
        else selectedParticipationColumns.push(action.selected);
        return {
            ...state,
            selectedParticipationColumns: [...selectedParticipationColumns],
        };
    }
    else {
        return {
            ...state,
            selectedParticipationColumns: [],
        };
    }
}

export const deselectParticipationDemographicColumns = (state) => {
    let { getParticipationRateApi } = state;
    const { result } = getParticipationRateApi.data.aggregatedDemographicsResponse;
    let selectedColumns = Object.keys(result);
    return {
        ...state,
        selectedParticipationColumns: [...selectedColumns],
    };
}