import { imports } from './listOfImports';

const surveyPageReducer = (state = imports.Default(),action) => {
    let surveyPayload = imports.fetchPayload(action);
    switch (action.type) {
    case imports.SELECTED_FACETS_INIT_STATE:
        let res= imports.update(
            state,{selectedFacets:action.filters});
        return res;
    case imports.FETCH_SURVEYS_LIST_SUCCESS:
        return imports.update(state,
            imports.fetchSurveyListSuccess(state, surveyPayload)
        );
    case imports.FETCH_SURVEYS_LIST_STARTED:
        return imports.update(state,
            imports.fetchSurveyListStarted(state, surveyPayload)
        );
    default:
        return surveyListFail(state, action);
    }
};
const surveyListFail = (state, action) => {
    let payload = imports.fetchPayload(action);
    switch (action.type) {
    case imports.FETCH_SURVEYS_LIST_FAILED:
        return imports.update(state,
            imports.fetchSurveyListFailed(state, payload)
        );
    case imports.FILE_SELECTED:
        return imports.update(state,
            imports.fileUploadSelected(state, payload)
        );
    default:
        return uploadStartSuccess(state, action);
    }
};
const uploadStartSuccess = (state, action) => {
    let payload = imports.fetchPayload(action);
    switch (action.type) {
    case imports.FILE_UPLOAD_STARTED:
        return imports.update(state,
            imports.fileUploadStarted(state, payload)
        );
    case imports.FILE_UPLOAD_SUCCESS:
        return imports.update(state,
            imports.fileUploadSuccess(state, payload)
        );
    default:
        return fileUploadFail(state, action);
    }
};
const fileUploadFail = (state, action) => {
    let payload = imports.fetchPayload(action);
    switch (action.type) {
    case imports.FILE_UPLOAD_FAILED:
        return imports.update(state,
            imports.fileUploadFailed(state, payload)
        );
    case imports.DELETE_SURVEY_STARTED:
        return imports.update(state,
            imports.deleteSurveyStarted(state, payload)
        );
    default:
        return deleteSurveySuccessFail(state, action);
    }
};
const deleteSurveySuccessFail = (state, action) => {
    let payload = imports.fetchPayload(action);
    switch (action.type) {
    case imports.DELETE_SURVEY_SUCCESS:
        return imports.update(state,
            imports.deleteSurveySuccess(state, payload)
        );
    case imports.DELETE_SURVEY_FAILED:
        return imports.update(state,
            imports.deleteSurveyFailed(state, payload)
        );
    default:
        return parseCsvStartSuccess(state, action);
    }
};
const parseCsvStartSuccess = (state, action) => {
    let payload = imports.fetchPayload(action);
    switch (action.type) {
    case imports.PARSE_CSV_STARTED:
        return imports.update(state,
            imports.parseCsvStarted(state, payload)
        );
    case imports.PARSE_CSV_SUCCESS:
        return imports.update(state,
            imports.parseCsvSuccess(state, payload)
        );
    default:
        return parseCsvFail(state, action);
    }
};
const parseCsvFail = (state, action) => {
    let payload = imports.fetchPayload(action);
    switch (action.type) {
    case imports.PARSE_CSV_FAILED:
        return imports.update(state,
            imports.parseCsvFailed(state, payload)
        );
    case imports.SELECTED_FACETS_CHANGE:
        return imports.update(state,
            imports.facetChange(state, payload)
        );
    default:
        return changeFacetGroup(state, action);
    }
};
const changeFacetGroup = (state, action) => {
    let payload = imports.fetchPayload(action);
    switch (action.type) {
    case imports.FACET_GROUP_CHANGE:
        return imports.update(state,
            imports.facetGroupChange(state, payload)
        );
    default:
        return getFilterAggregation(state, action);
    }
};
const getFilterAggregation = (state, action) => {
    let payload = imports.fetchPayload(action);
    switch (action.type) {
    case imports.GET_FILTER_AGGREGATION_STARTED:
        return imports.update(state,
            imports.filterAggregationStarted(state, payload)
        );
    case imports.GET_FILTER_AGGREGATION_SUCCESS:
        return imports.update(state,
            imports.filterAggregationSuccess(state, payload)
        );
    default:
        return getFilterAggregationFail(state, action);
    }
};

const getFilterAggregationFail = (state, action) => {
    let payload = imports.fetchPayload(action);
    switch (action.type) {
    case imports.GET_FILTER_AGGREGATION_FAILED:
        return imports.update(state,
            imports.filterAggregationFailed(state, payload)
        );
    case imports.FETCH_SURVEY_DATA_STARTED:
        return imports.update(state,
            imports.fetchSurveyTableStarted(state, payload)
        );
    default:
        return fetchSurveyTable(state, action);
    }
};
const fetchSurveyTable = (state, action) => {
    let payload = imports.fetchPayload(action);
    switch (action.type) {
    case imports.FETCH_SURVEY_DATA_SUCCESS:
        return imports.update(state,
            imports.fetchSurveyTableSuccess(state, payload)
        );
    case imports.FETCH_SURVEY_DATA_FAILED:
        return imports.update(state,
            imports.fetchSurveyTableFailed(state, payload)
        );
    default:
        return togglenavBar(state, action);
    }
};

const togglenavBar=(state,action)=>{
    switch(action.type){
    case imports.TOGGLE_SURVEY_NAVBAR:
        return{
            ...state,
            navVisibility:action.payload
        };
    default:return {...state};
    }
};
export default surveyPageReducer;
