import React from 'react'
import { Bar, G2 } from '@ant-design/plots';

export default function GroupedBarChart(props) {
    const data = props.data
    let trim = props.data.length > 9 ? 20 : 30
    let x = props.showProjectGroup ? 'surveyName' : 'label'
    let seriesField = props.showProjectGroup ? 'label' : 'surveyName'
    const config = {
        data,
        isGroup: true,
        xField: 'trendScore',
        maxColumnWidth: 60,
        yField: x,
        legend: {
            layout: 'horizontal',
            position: 'top',
            maxRow: 10,
            maxCol :10,
            offsetX: 10,
            offsetY: 10
          },
        seriesField: seriesField,
        yAxis: {
            label: {
              formatter: (value) => {
                return value.length > trim ? `${value.slice(0, trim)}...` : value;
              },
      
            },
          },
        color: ["#4575b4", " #74add1", "#abd9e9", "#c9eff3", "#fdae61", " #658F9E", "#d73027"],
        marginRatio: 0.05,
        label: {
            position: 'middle',
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
    };
    return <Bar {...config} />;
};

