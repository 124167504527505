import {
  INVITE_VIEWER_START,
INVITE_VIEWER_SUCCESS,
INVITE_VIEWER_FAILED,
INVITE_VIEWER_FORM,
CLEAR_INVITE_VIEWER_FORM
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { CREATEVIEWER } from "../../../../../config/constants";
import { toast } from "../../../../utils/messageUtils";
import constants from './constants'
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const inviteViewer = (payload, callback) => {
  const apiBaseInstance = new ApiBase();
  return (dispatch) => {
    dispatch(withType(INVITE_VIEWER_START, {}));
    apiBaseInstance.instance
      .post(CREATEVIEWER, { ...payload, ...getProjectIdAndOrgId(payload) })
      .then((response) => {
        toast.success("Viewer invited successfully");
        dispatch(
          withType(INVITE_VIEWER_SUCCESS, {
            data: response.data,
            message: response.message,
          })
        );
        callback && callback();
      })
      .catch((err) => {
        let toastMessage = constants.INVITAION_FAILED_MESSAGE
        if (err.response && err.response.data.reason === "Cannot add this person as a viewer because they are already an administrator of the organization. They can already see all projects.") {
          toastMessage = err.response&&err.response.data.reason
        }
        toast.error(toastMessage);
        dispatch(
          withType(INVITE_VIEWER_FAILED, {
            error: err,
          })
        );
      });
  };
};
export const inviteViewerForm = (event) =>{
  return {
    type: INVITE_VIEWER_FORM,
    payload: event,
  };
}


export const clearInviteViewerForm = () => {
  return {
    type: CLEAR_INVITE_VIEWER_FORM,
  };
};