import { setInitialState } from '../../../utils/reducerOperations';
export const Default = () => ({
    fetchProjectApi: setInitialState(),
    selectedLanguage: 'English',
    defaultTexts: {
        ok: 'Okay',
        continue: 'Continue',
        translatedDescription: undefined,
        back: 'Back to Survey',
        submit: 'Submit',
        languageSelect: 'Select your language',
        placeholder: 'enter answer here',
        recordAnswer: 'Click here to record your answer',
        surveyPreviewEndMessage: 'You have reached the end of the survey',
        surveyUnansweredMessage: 'You have not answered any questions',
        surveySubmitMessage: "Please save your responses by clicking the submit button below",
        surveyCompletedMessage: "We thank you for your time spent taking this survey. Your response has been recorded."
    },
    translatedTexts: {}
});