import {
  FETCH_TREND_VISUALIZATION_DATA_START,
  FETCH_TREND_VISUALIZATION_DATA_SUCCESS,
  FETCH_TREND_VISUALIZATION_DATA_FAIL
} from "../../../constants";
import {
  GET_TREND_VISUALISATION,
} from "../../../../../config/constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';

export const getTrendData = (payload,cb) => {
  const apiBaseInstance = new ApiBase().instance;
  return (dispatch) => {
    dispatch(
      withType(FETCH_TREND_VISUALIZATION_DATA_START, {})
    );
    return apiBaseInstance
      .post(GET_TREND_VISUALISATION, {
        ...payload, ...getProjectIdAndOrgId(payload)
      })
      .then((res) => {
        dispatch(
          withType(FETCH_TREND_VISUALIZATION_DATA_SUCCESS, {
            data: res.data,
            payload: payload
          })
        );
        cb && cb()
      })
      .catch((err) => {
        dispatch(withType(FETCH_TREND_VISUALIZATION_DATA_FAIL, err));
        cb && cb()
      });
  };
};
