import { connect } from 'react-redux';
import AdminBarChart from './AdminBarChart';

const mapStateToProps = (state) => {
  return {
    ...state.ExploreTopicReducer,
    ...state.ExploreQuestionsReducer,
    ...state.NewdashboardTrendBenchmarkReducer,
    projectName:
      state.DashBoardReducer.surveyDetailsApi.surveyDetails.projectName,
    thresholdApi: state.DashBoardReducer.thresholdApi,
    thresholdData: state.DashBoardReducer.thresholdApi.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBarChart);
