import CryptoJS from 'crypto-js'
import qs from 'query-string'
import { createBrowserHistory } from 'history';
import { nlpSetURLParams } from './nlpURLUtils';
import { URL_DATA } from '../../config/urlDataConstants';

const secretKey = 'JhjUHR08AyYJPsh0OzgM';


export const encryptJson = (jsonData) => {
    const stringifiedJson = JSON.stringify(jsonData);
    var urlToken = CryptoJS.AES.encrypt(stringifiedJson, secretKey).toString();
    return urlToken;
}

export const decryptJson = (urlToken) => {
    try {
        const bytes = CryptoJS.AES.decrypt(urlToken, secretKey);
        const stringifiedJson = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(stringifiedJson)
    } catch (e) {
    }
}

export const encryptData = (data) => {
    const urlToken = qs.parseUrl(window.location.href).query;
    let decryptedData;
    let urlData;
    if (urlToken && urlToken.params) {
        decryptedData = decryptJson(urlToken.params);
    }
    if (decryptedData) {
        urlData = {
            ...decryptedData,
            ...data
        }
    } else {
        urlData = {
            ...data
        }
    }
    const encryptedData = encryptJson(urlData)
    return encryptedData;
}

export const setDataToUrlToken = (data) => {
    const encryptedData = encryptData(data)
    createBrowserHistory().push({
        search: nlpSetURLParams({
            params: encryptedData
        })
    })
}

export const getSubTabFromUrlToken = () => {
    const urlToken = qs.parseUrl(window.location.href).query;
    if (urlToken.subTab) return urlToken.subTab
    else return
}

export const getDataFromUrlToken = (key) => {
    const urlToken = qs.parseUrl(window.location.href).query;
    let decryptedData;
    if (urlToken.params)
        decryptedData = decryptJson(urlToken.params);
    if (decryptedData) {
        return decryptedData[key]
    }
}

export const getTabIdFromUrl = (key) => {
    const urlToken = qs.parseUrl(window.location.href).query;
    return urlToken.tabId;
}

export const getAllDataFromUrlToken = () => {
    const urlToken = qs.parseUrl(window.location.href).query;
    let decryptedData;
    if (urlToken.params)
        decryptedData = decryptJson(urlToken.params);
    if (decryptedData) {
        return decryptedData
    }
}

export const getAllParamsFromGivenUrl = (previousLocation) => {
    const urlToken = qs.parse(previousLocation.search);
    let decryptedData;
    if (urlToken.params)
        decryptedData = decryptJson(urlToken.params);
    if (decryptedData) {
        return decryptedData
    }
}

export const checkTreeDataChange = (previousLocation) => {
    let isTreeDataChanged = false;
    const currentTreeData = getDataFromUrlToken(URL_DATA.TREE_DATA);
    const previousTreeData =
        getAllParamsFromGivenUrl(previousLocation)[URL_DATA.TREE_DATA];
    if (currentTreeData && currentTreeData.selectedUser !== previousTreeData.selectedUser)
        isTreeDataChanged = true;
    return { isTreeDataChanged, treeData: currentTreeData };
}

export const checkStoryboardTreeDataChange = (previousLocation) => {
    let isTreeDataChanged = false;
    const currentTreeData = getDataFromUrlToken(URL_DATA.STORYBOARD_TREEDATA);
    const previousTreeData =
        getAllParamsFromGivenUrl(previousLocation)[URL_DATA.STORYBOARD_TREEDATA];
    if (currentTreeData.selectedUser !== previousTreeData.selectedUser)
        isTreeDataChanged = true;
    return { isTreeDataChanged, storyboardTreeData: currentTreeData };
}

export const checkFlterChange = (previousLocation) => {
    let isFilterChanged = false;
    const currentFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
    const previousFilter =
        getAllParamsFromGivenUrl(previousLocation)[URL_DATA.DASHBOARD_FILTER];
    if (currentFilter !== previousFilter) isFilterChanged = true;
    return { isFilterChanged, dashboardFilter: currentFilter };
}