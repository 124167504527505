export const fetchResponsesStart = (state) => {
  return {
    ...state,
    fetchResponsesApi: {
      isProcessing: true,
      data: null,
      error: false,
      message: "",
    },
  };
};

export const fetchResponsesSuccess = (state, payload) => {
  return {
    ...state,
    fetchResponsesApi: {
      isProcessing: false,
      data: payload.payload.data,
      error: false,
      message: payload.message,
    },
  };
};

export const fetchResponsesFailed = (state, payload) => {
  return {
    ...state,
    fetchResponsesApi: {
        isProcessing: false,
        loading: false,
        data: payload,
        message: payload && payload.message,
        error: true,
    },
  };
};
