import {fetchFormConfigurations} from '../../inputFormConfigurations';
import {setInitialState} from '../../../utils/reducerOperations';
export function Default() {
    return {
        isFormValid: false,
        loginProcessApi: setInitialState(),
        inputBoxConfiguration: fetchFormConfigurations([
            'username',
            'password'
        ])
    };
}
