import {
    update,
    fetchPayload
} from '../../../utils/reducerOperations';
import * as helper from './helper';
import {
    FETCH_SURVEY_TABLE_SNAPSHOT_FAILED,
    FETCH_SURVEY_TABLE_SNAPSHOT_STARTED,
    FETCH_SURVEY_TABLE_SNAPSHOT_SUCCESS,
    UPDATE_COMPARISON_PIVOT_STATE_START,
    UPDATE_COMPARISON_PIVOT_STATE_SUCCESS,
    UPDATE_COMPARISON_PIVOT_STATE_FAIL,
} from '../../constants';
import { Default } from './InitialState';

const ComparisonPageReducer = (state=Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
    case FETCH_SURVEY_TABLE_SNAPSHOT_STARTED:
        return update(state,
            helper.fetchSurveySnapshotStarted(state, payload));
    case FETCH_SURVEY_TABLE_SNAPSHOT_SUCCESS:
        return update(state,
            helper.fetchSurveySnapshotSuccess(state, payload));
    case FETCH_SURVEY_TABLE_SNAPSHOT_FAILED:
        return update(state,
            helper.fetchSurveySnapshotFailed(state, payload));
    default:
        return updatePivotState(state,action);
    }
};

const updatePivotState=(state,action)=>{
    let payload = fetchPayload(action);
    switch(action.type){
    case UPDATE_COMPARISON_PIVOT_STATE_START:
        return update(state,helper.updatePivotStarted(state,payload));
    case UPDATE_COMPARISON_PIVOT_STATE_SUCCESS:
        return update(state,helper.updatePivotSuccess(state,payload));
    case UPDATE_COMPARISON_PIVOT_STATE_FAIL:
        return update(state,helper.updatePivotFailed(state,payload)); 
    default :return{...state};
    }
};

export default ComparisonPageReducer;