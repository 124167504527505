
export function setSelectedFacets(facetObject) {
    let returnArray = [];
    facetObject.map(mainObj => {
        let mainArray = mainObj[mainObj.main];
        for (let itm in mainArray) {
            if (mainArray[itm].selected)
                returnArray.push({
                    main: mainObj.main,
                    text:mainObj.value,
                    value: mainArray[itm].value
                });
        }return 0;
    });
    return returnArray;
}