
export const fetchTextSummarizationStart = (state, payload) => {
    return {
        ...state,
        [payload.selectedQuestionId]: {
            ...state[payload.selectedQuestionId],
            textSummarization: {
                ...state[payload.selectedQuestionId].textSummarization,
                data: null,
                isProcessing: true
            }

        },
    };
};

export const fetchTextSummarizationSuccess = (state, payload) => {
    return {
        ...state,
        [payload.selectedQuestionId]: {
            ...state[payload.selectedQuestionId],
            textSummarization: {
                ...state[payload.selectedQuestionId].textSummarization,
                isProcessing: false,
                data: payload.data.data.response,
            }
        },
    };
};

export const fetchTextSummarizationFail = (state, payload) => {
    return {
        ...state,
        [payload.selectedQuestionId]: {
            ...state[payload.selectedQuestionId],
            textSummarization: {
                ...state[payload.selectedQuestionId].textSummarization,
                isProcessing: false,
                data: null,
                error: true
            }


        },
    };
};
