/*eslint-disable*/

export default {
  fileUploadFail: "Uploading failed",
  fileUpoladSuccess: "Successfully uploaded",
  formDataHeader: {
    "Content-Type": "multipart/form-data",
  },
  formProcessSuccess: "Processing started",
  formReprocessSuccess: "Reprocessing started",
  formProcessFail: "Failed",
  defaultFileName: "default_topics_library.csv",
  sampleCommentFileName: "sample_comments_file.csv",
  download: "download",
  downloadingFailed: "Downloading failed",
  a: "a",
  commentsFileApi: "commentsFileApi",
  reprocessUploadApi: "reprocessUploadApi",
  currentBatchId: "currentBatchId",
  libraryFileApi: "libraryFileApi",
  file: "file",
  libraryFilePath:
    "/home/ubuntu/culturebie-nlp/uploaded_files/enhanced topic library parsed _jm.csv",
  userId: "userId",
  projectId: "projectId",
  false: "False",
  processing: "processing",
  uploadTopicLibrary: {
    userRole: "userRole",
    userId: "userId",
    uploadFile: "file",
  },
  userRole: "userRole",
  batchIdDeleteSuccessMsg: "Project deleted successfully",
  batchIdDeleteFailedMsg: "Deleting project failed",
  deleteUserResponseSuccessMsg: "Survey response deleted successfully",
  deleteUserResponseFailedMsg: "Deleting survey response failed",
  username: "username",
  approved: "Approved",
  failed: "Failed",
  deleted: "Deleted",
};
