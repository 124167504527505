import { Tooltip } from 'antd';
import React from 'react';
import { StoryBoardNewBarChart } from '../../../../../../../../Components/StoryBoardNewBarChart';
import { TopicQuestionBarchart } from '../../../Topics/children/NewBarchart';
export class TopicGraph extends React.Component {

  getBarChartData = (histogramData) => {

    const favScore = this.getMultiplierAndHighScore(this.props.highestFavScore);
    const unFavScore = this.getMultiplierAndHighScore(this.props.highestUnfavScore)
    const neutralScore = this.getMultiplierAndHighScore(this.props.highestNeutralScore)
    let graphData = histogramData.map((item) => {
      const favValue = favScore.highScore ? Math.round(item.favourable) / favScore.highScore : 0;
      const unFavValue = unFavScore.highScore ? Math.round(item.unFavourable) / unFavScore.highScore : 0;
      const neutralValue = neutralScore.highScore ? Math.round(item.neutral) / neutralScore.highScore : 0;
      return {
        label: item.node,
        subLabel: `${item.count} Respondents`,
        favourableDetails: { value: `${Math.round(item.favourable)}`, width: Math.round(favValue * favScore.multiplier) },
        unFavourableDetails: { value: `${Math.round(item.unFavourable)}`, width: Math.round(unFavValue * unFavScore.multiplier) },
        neutralDetails: { value: `${Math.round(item.neutral)}`, width: Math.round(neutralValue * neutralScore.multiplier) },
        percentage: item.percentage,
        threshold: item.count,
      };
    });
    if (this.props.scoreType === "all") {
      graphData.sort((first, second) => second.threshold - first.threshold)
    }
    else {
      let key = ""
      if (this.props.scoreType === "favourable") {
        key = "favourableDetails"
      }
      else if (this.props.scoreType === "neutral") {
        key = "neutralDetails"
      }
      else {
        key = "unFavourableDetails"
      }
      graphData.sort((first, second) => this.customSort(first, second, key))

    }
    return graphData;
  };

  customSort = (first, second, scoreType) => {
    if (first.threshold > this.props.threshold.filterThreshold && second.threshold > this.props.threshold.filterThreshold) {
      if (parseInt(first[scoreType].value) < parseInt(second[scoreType].value)) {
        return 1;
      } else if (parseInt(first[scoreType].value) > parseInt(second[scoreType].value)) {
        return -1;
      } else {
        return 0;
      }
    } else if (first.threshold <= this.props.threshold.filterThreshold && second.threshold <= this.props.threshold.filterThreshold) {
      return 0;
    } else if (first.threshold > this.props.threshold.filterThreshold && second.threshold <= this.props.threshold.filterThreshold) {
      return -1;
    } else {
      return 1;
    }
  }

  getSortedGraph = (data) => {
    let threshold = this.props.threshold && this.props.threshold.filterThreshold ? this.props.threshold.filterThreshold : 5
    const managerElement = data.find(item => item.key === 'Manager')
    const averageScoreElement = data.find(item => item.key === 'Average Score')
    const graphData = data.filter(item => item.key !== 'Manager' && item.key !== 'Average Score')
    managerElement && graphData.unshift(managerElement)
    averageScoreElement && graphData.unshift(averageScoreElement)
    graphData.forEach(item => {
      let showData = []
      let insufficentData = []
      item.value.forEach(element => {
        if (element.count >= threshold) {
          showData.push(element)
        } else {
          insufficentData.push(element)
        }
      })
      if (showData.length > 1) {
        showData = showData.sort((a, b) => Math.round(b.favScore) - Math.round(a.favScore))
      }
      item.value = [...showData, ...insufficentData]
    })
    return graphData
  }

  getMultiplierAndHighScore = (highScore) => {
    let multiplier = 1;
    if (highScore && highScore > 80) {
      multiplier = 100;
    } else {
      multiplier = 75;
    }
    return { multiplier, highScore }
  }

  render() {
    return (
      <>
        {this.props.data &&
          !!this.props.data.length &&
          this.getSortedGraph(this.props.data)
            .map((item) => (
              <div className='div-barchart' key={item.key}>
                {item.value.length && (
                  <>
                    <h2>
                      {item.key !== 'Average Score' ? (item.key === "Manager" ?
                        <Tooltip title={this.props.byManagerToolTip} placement='right'><div>By Manager</div></Tooltip>
                        : `By ${item.key}`) : item.key}
                      {item.key !== 'Average Score' &&
                        this.props.data.some((i) => i.key === 'Average Score') &&
                        this.props.data.length > 2 ? (
                        <div
                          onClick={() =>
                            this.props.onRemove && this.props.onRemove(item.key)
                          }
                        >
                          <p>remove</p>
                        </div>
                      ) : (
                        ''
                      )}
                    </h2>
                    <div>
                      <StoryBoardNewBarChart
                        scoreType={this.props.scoreType}
                        data={this.getBarChartData(item.value)}
                        limit={3}
                        graphCursorClass={'progress-no-cursor'}
                        threshold={this.props.threshold && this.props.threshold.filterThreshold}
                        questionIsSelected={this.props.questionIsSelected}
                        outliers={this.props.outliers}
                        topics={this.props.topics}
                        outliersData={item.key}
                        hideBackground={true}
                      />
                    </div>
                  </>
                )}
              </div>
            ))}
      </>
    );
  }
}
