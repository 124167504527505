import { Default } from './InitialState';
import { 
    SURVEY_REPORT_DOWNLOAD_START,
    SURVEY_REPORT_DOWNLOAD_SUCCESS,
    SURVEY_REPORT_DOWNLOAD_FAIL,
    FETCH_PARTICIPATION_DATA_START,
    FETCH_PARTICIPATION_DATA_SUCCESS,
    FETCH_PARTICIPATION_DATA_FAIL
} from '../../constants';
import { 
    downloadReportStart,
    downloadReportSuccess,
    downloadReportFailed,
    getParticipationDataStart,
    getParticipationDataSuccess,
    getParticipationDataFailed
} from './helper';

const SurveyReportReducer = (state = Default(), action) => {
    switch (action.type) {
    case SURVEY_REPORT_DOWNLOAD_START:
        return downloadReportStart(state);
    case SURVEY_REPORT_DOWNLOAD_SUCCESS:
        return downloadReportSuccess(state,action);
    case SURVEY_REPORT_DOWNLOAD_FAIL:
        return downloadReportFailed(state,action);
    case FETCH_PARTICIPATION_DATA_START:
        return getParticipationDataStart(state);
    case FETCH_PARTICIPATION_DATA_SUCCESS:
        return getParticipationDataSuccess(state,action);
    case FETCH_PARTICIPATION_DATA_FAIL:
        return getParticipationDataFailed(state,action);
    default: return { ...state };
    }
};

export default SurveyReportReducer;