import { getApiStartState, getSuccessResponse } from "../../../../utils/reducerOperations"

export const getThemesListStarted = (state) => {
    return {
        ...state,
        getThemesListApi: getApiStartState()
    }

}

export const getThemesListSuccess = (state, payload) => {
    return {
        ...state,
        getThemesListApi: getSuccessResponse(payload)
    }

}

export const getThemesListFailed = (state, payload) => {
    return {
        ...state,
        getThemesListApi: {
            isProcessing: false,
            data: null,
            error: payload.error
        }
    }

}