import { PivotTableFields } from "../../config/constants";
// eslint-disable-next-line
let resultantList = [];

let TableFields = PivotTableFields;

function makeHeatMapData(aggsResult = [], keys = []) {}

export function formatRespondents(filterAgg) {
  let data = [];
  if(filterAgg){
    data = filterAgg;
    return data;
  }
  else
  return [];
}

export function formatDataForPivot(data, keys) {
  if (data.fields) {
    TableFields = data.fields;
    data = data.data;
  } else TableFields = PivotTableFields;

  resultantList = [];
  const doc = {};
  if (Array.isArray(keys) && !keys.length) {
    TableFields.map((key) => (doc[key] = null));
    return [doc];
  }

  if (Array.isArray(data) && Array.isArray(keys)) {
    //makePivotData(data, keys);
    makeHeatMapData(data, keys);
    return data; //resultantList;
  }
  return [];
}
