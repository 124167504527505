import { goToPrevOrNext } from "./updateSurveyAnsweringUtils"

export const updateCurrentIndex = (state, payload) => {
    let updatedCreateAnswerSet = payload.isFromGenerateAnswerSet ? {
        isProcessing: true,
        error: false,
        message: ''
    } : state.createAnswerSetApi
    let { currentIndex, progressIndex, isGoPrev, questionSetCopy, requiredSatisfied, pageBreakAddedQuestionSetCopy, errorText } = goToPrevOrNext(state, payload)
    return { ...state, currentIndex: currentIndex, progressIndex: progressIndex, isPrevClicked: isGoPrev, questionSet: questionSetCopy, createAnswerSetApi: updatedCreateAnswerSet, hideErrorBlock: requiredSatisfied, pageBreakAddedQuestionSet: pageBreakAddedQuestionSetCopy ? pageBreakAddedQuestionSetCopy : state.pageBreakAddedQuestionSet, errorText: errorText }
}
