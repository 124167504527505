export const fetchHRISFileNameStart = (state) => {
  return {
    ...state,
    fetchHRISFileDetailsApi: {
      isProcessing: true,
      data: null,
      error: false,
      message: "",
    },
  };
};

export const fetchHRISFileNameSuccess = (state, payload) => {
  return {
    ...state,
    fetchHRISFileDetailsApi: {
      isProcessing: false,
      data:
        payload.data.data &&
        payload.data.data.response &&
        payload.data.data.response.hrisFilesDetails,
      error: false,
      message: payload.data.message,
    },
  };
};

export const fetchHRISFileNameFailed = (state, payload) => {
  return {
    ...state,
    fetchHRISFileDetailsApi: {
      isProcessing: false,
      data: null,
      error: !payload.status,
      message: payload.message,
    },
  };
};
