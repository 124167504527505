import { Tooltip } from 'antd';
import React from 'react';
import './index.scss';

export class QuestionBarchart extends React.Component {
    render() {
        return (
            <>
                {this.props.data.map((item, index) =>
                    <div className='questions-progress progress-bar'>
                        <Tooltip
                            placement='bottom'
                            title={item.favScore <= 10 ? `${item.favScore} Favorable` : "Favorable"}
                        >
                            <div
                                className='favourable'
                                style={{
                                    width: `${item.favScore}%`,
                                    // minWidth: item.favScore === 0 ? '0%' : this.getMinWidth(item),
                                    padding: item.favScore === 0 && '0',
                                }}
                            >
                                {
                                    item.favScore > 10 &&
                                    <p>{`${item.favScore} `}</p>
                                }
                            </div>
                        </Tooltip>
                        <Tooltip
                            placement='bottom'
                            title={item.neutralScore <= 10 ? `${item.neutralScore} Neutral` : "Neutral"}
                        >
                            <div
                                className='neutral'
                                style={{
                                    width: `${item.neutralScore}%`,
                                    // minWidth: item.neutralScore === 0 ? '0%' : this.getMinWidth(item),
                                    padding: item.neutralScore === 0 && '0',
                                }}
                            >
                                {
                                    item.neutralScore > 10 &&
                                    <p>{`${item.neutralScore} `}</p>
                                }

                            </div>
                        </Tooltip>
                        <Tooltip
                            placement='bottom'
                            title={item.unFavScore <= 10 ? `${item.unFavScore} Unfavorable` : "Unfavorable"}
                        >
                            <div
                                className='unfavourable'
                                style={{
                                    width: `${item.unFavScore}%`,
                                    // minWidth: item.unFavScore === 0 ? '0%' : this.getMinWidth(item),
                                    padding: item.unFavScore === 0 && '0',
                                }}
                            >
                                {
                                    item.unFavScore > 10 &&
                                    <p>{`${item.unFavScore} `}</p>
                                }
                            </div>
                        </Tooltip>
                    </div>
                )}
            </>
        );
    }
}