import React from "react";
import { ListData } from "../../Components/ListRoleandData";
import { Tooltip } from "antd";
import { ReloadButton } from "../../Components/ReloadButton";
import helpers from "./helpers";
import constants from "./constants";
import { SETTINGS_PAGE_SECTION } from '../../../config/tabConstants';

class Logs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisibility: false,
      createRole: {
        selectedTags: [],
        roleName: "",
      },
      pagination: 1,
    };
  }

  componentDidMount = () => {
    this.props.logsPaginationChange(1);
    this.props.fetchUserCreationLogs(this.getPayload());
  };

  createPropsForLogsPagination = () => {
    return {
      fetchApi: this.props.fetchUsersLogApi,
      onChangePagination: this.onChangePagination,
      pagination: this.props.logsPagination,
    };
  };
  onChangePagination = (value) => {
    const fromValue = (value - 1) * 12;
    this.props.logsPaginationChange(value);
    this.setState({ pagination: value });
    this.props.fetchUserCreationLogs(this.getPayload(fromValue));
  };
  getPayload = (fromValue) => {
    // const { org_id } = this.props.match.params;
    return {
      currentTab: SETTINGS_PAGE_SECTION.SETTINGS_SUB_TABS.LOGS,
      from: fromValue ? fromValue : 0,
      size: 12,
      organizationId: localStorage.getItem(constants.ORG_ID),
    };
  };
  makeHRISApiParams = () => {
    return {
      currentTab: SETTINGS_PAGE_SECTION.SETTINGS_SUB_TABS.LOGS,
      searchText: "",
      organizationId: localStorage.getItem(constants.ORG_ID),
    };
  };
  onRefresh = () => {
    const fromValue = (this.props.logsPagination - 1) * 12;
    this.props.fetchUserCreationLogs(this.getPayload(fromValue));
    this.props.fetchHRIS(this.makeHRISApiParams());
  };

  renderLogsTableContent = () => {
    return (
      <>
        <ListData
          listType={"log"}
          onRefresh={this.onRefresh}
          fetchApi={this.props.fetchUsersLogApi}
        />
        {this.props.fetchUsersLogApi.data && this.props.fetchUsersLogApi.data.count > 12 &&
          helpers.getPagination(this.createPropsForLogsPagination())}
      </>
    );
  };

  getReloadButton = () => {
    const { loading } = this.props.fetchUsersLogApi;
    return <ReloadButton loading={loading} onClick={() => this.onRefresh()} />;
  };

  logsCardHeader = () => {
    return (
      <div className="create-header">
        <Tooltip placement="bottom" title="Refresh" mouseLeaveDelay={0.1}>
          {this.getReloadButton()}
        </Tooltip>
      </div>
    );
  };
  render() {
    return (
      <>
        <h2>Logs</h2>
        {/* <TableContentWrapper className="project-table"
        tableContent={this.renderLogsTableContent()}
      /> */}
        {this.renderLogsTableContent()}
      </>
    );
  }
}
export default Logs;
