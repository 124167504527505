import {
    UPDATE_TREND_DATA_SUCCESS,
    UPDATE_TREND_DATA_START,
    UPDATE_TREND_DATA_FAIL
} from "../../../constants";
import { ApiBase } from "../../../../utils/apiBase";
import { withType } from "../../../../utils/actionOperations";
import { UPDATE_TREND_DATA } from "../../../../../config/constants";

export const NewDashboardUpdatePairingData = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
        dispatch(withType(UPDATE_TREND_DATA_START, {
            updatedSurveyDetails: payload.updatedSurveyDetails,
            updatedData: payload.updatedData,
            isFromSurveyTags: payload.isFromSurveyTags
        }));
        apiBaseInstance.instance
            .post(UPDATE_TREND_DATA, { ...payload.updateTrendPayload })
            .then((response) => {
                dispatch(
                    withType(UPDATE_TREND_DATA_SUCCESS, {
                        data: response.data,
                        projectDetails: { questionSet: payload.questionSet, projectType: payload.projectType },
                        message: response.message,
                    })
                );
                cb && cb(true);
            })
            .catch((err) => {
                dispatch(
                    withType(UPDATE_TREND_DATA_FAIL, {
                        error: err,
                    })
                );
                cb && cb(false);
            });
    };
};
