import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.scss";

class ReadMore extends Component {
  constructor(props) {
    super(props);
    let textArray = this.props.textContent.split(".");
    this.state = {
      textArray: textArray,
      shouldTruncate: true,
      textContent: this.props.textContent.split("."),
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.textContent !== this.props.textContent) {
      let textArray = this.props.textContent.split(".");
      this.setState({
        textArray: textArray,
        shouldTruncate: true,
        textContent: this.props.textContent.split("."),
      });
    }
  }

  toggleTruncate = () => {
    this.setState({ shouldTruncate: !this.state.shouldTruncate });
  };

  render() {
    const readMoreEllipsis = (
      <span>
        <span onClick={this.toggleTruncate} className="explore-link">
          {this.props.more}
        </span>
      </span>
    );
    const readLessEllipsis = (
      <span onClick={this.toggleTruncate} className="explore-link">
        {this.props.less}
      </span>
    );
    return (
      <div className="read-more">
        {this.state.shouldTruncate ? (
          <div className="read-more__link" id="content">
            {this.state.textContent[0].trim()}.
            {this.state.textArray.length > 1 ? readMoreEllipsis : ""}
          </div>
        ) : (
          <div>
            {this.props.textContent}
            {readLessEllipsis}
          </div>
        )}
      </div>
    );
  }
}

ReadMore.defaultProps = {
  lines: 2,
  more: "...Read more",
  less: " Read less",
};

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
};

export default ReadMore;
