export default{
    overviewApi: {
        loading:true,
        data:null,
        error:null
    },
    overviewResultApi:{
        loading:true,
        data:null,
        error:null
    }
};