import {
  SURVEY_PATICIPATION_FETCH_SURVEY_DETAILS_STARTED,
  SURVEY_PATICIPATION_FETCH_SURVEY_DETAILS_SUCCESS,
  SURVEY_PATICIPATION_FETCH_SURVEY_DETAILS_FAILED,
  FETCH_SURVEY_PARTICIPATION_FAILED,
  FETCH_SURVEY_PARTICIPATION_SUCCESS,
  FETCH_SURVEY_PARTICIPATION_START,
  SURVEY_UPDATE_PARTICIPATION_COLUMNS_LIST,
  SURVEY_DESELECT_PARTICIPATION_COLUMNS,
  SURVEY_GET_DISTIRBUTION_DATA_START,
  SURVEY_GET_DISTIRBUTION_DATA_SUCCESS,
  SURVEY_GET_DISTIRBUTION_DATA_FAILED,
} from "../../constants";
import { initialState } from "./initialState";
import {
  surveyParticipationFetchSurveyStart,
  surveyParticipationFetchSurveySuccess,
  surveyParticipationFetchSurveyFailed,
  surveyParticipationDataStart,
  surveyParticipationDataSuccess,
  surveyParticipationDataFailed,
  surveyParticipationUpdateSelectedColumn,
  surveyDeselectParticipationColumns,
  surveyDistributionDataStart,
  surveyDistributionDataSuccess,
  surveyDistributionDataFailed,
} from "./helper";

const SurveyParticpationReducer = (state = initialState(), action) => {
  switch (action.type) {
    case SURVEY_PATICIPATION_FETCH_SURVEY_DETAILS_STARTED:
      return surveyParticipationFetchSurveyStart(state, action.payload);
    case SURVEY_PATICIPATION_FETCH_SURVEY_DETAILS_SUCCESS:
      return surveyParticipationFetchSurveySuccess(state, action.payload);
    case SURVEY_PATICIPATION_FETCH_SURVEY_DETAILS_FAILED:
      return surveyParticipationFetchSurveyFailed(state, action.payload);
    case FETCH_SURVEY_PARTICIPATION_START:
      return surveyParticipationDataStart(state, action.payload);
    case FETCH_SURVEY_PARTICIPATION_SUCCESS:
      return surveyParticipationDataSuccess(state, action.payload);
    case FETCH_SURVEY_PARTICIPATION_FAILED:
      return surveyParticipationDataFailed(state, action.payload);
    case SURVEY_UPDATE_PARTICIPATION_COLUMNS_LIST:
      return surveyParticipationUpdateSelectedColumn(state, action);
    case SURVEY_DESELECT_PARTICIPATION_COLUMNS:
      return surveyDeselectParticipationColumns(state);
      case SURVEY_GET_DISTIRBUTION_DATA_START:
        return surveyDistributionDataStart(state, action);
      case SURVEY_GET_DISTIRBUTION_DATA_SUCCESS:
        return surveyDistributionDataSuccess(state, action);
      case SURVEY_GET_DISTIRBUTION_DATA_FAILED:
        return surveyDistributionDataFailed(state);

    default:
      return { ...state };
  }
};

export default SurveyParticpationReducer;
