import { connect } from "react-redux";
import { Outliers } from "./Outliers";
import { fetchNewDashboardOutliersData } from "../../../../../../redux/actions";
import { withRouter } from "react-router-dom";
const mapStateToProps = (state) => {
  return {
    ...state.DashBoardReducer,
    threshold: state.DashBoardReducer.thresholdApi.data,
    ...state.FetchExploreOutliersDataReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNewDashboardOutliersData: (params) =>
      dispatch(fetchNewDashboardOutliersData(params)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Outliers));
