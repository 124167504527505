import React from "react";
import CustomModal from "../CustomModal";
import { EmptyState } from "../../Packages/EmptyState";
import "./index.scss";
import ScrollBars from "../../Packages/ScrollBar";

export function SelectModal({
  items,
  onCancel,
  visible,
  onSelect,
  onOk,
  activeItems,
  okButtonProps
}) {
  return (
    <CustomModal
      title="Add additional filters"
      visible={visible}
      style={{ top: 0 }}
      onOk={onOk}
      okText="Add"
      onCancel={onCancel}
      okButtonProps={{
        disabled: okButtonProps || false,
      }}
    >
      <ScrollBars className="filter-popup-scroller">
        {renderModalContent({ items, activeItems, onSelect })}
      </ScrollBars>
    </CustomModal>
  );
}

const renderModalContent = ({ items, activeItems, onSelect }) => {
  if (items && items.length) {
    return getContent({ items, activeItems, onSelect });
  } else
    return (
      <EmptyState
        component="table"
        emptyStateMessage={"No additional filters to show"}
      />
    );
};
function getContent({ items, activeItems, onSelect }) {
  return (
    <div className="additional-filters">
      <ul className="additional-filters__list">
        {formatItems(items).map((item) => (
          <li key={item.label}
            className={getActiveClass(item.label, activeItems)}
            onClick={() => onSelect(item)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
}

function getActiveClass(label, activeItems) {
  if (formatItems(activeItems).find((item) => item.label === label))
    return "active";
  return "";
}

function formatItems(items) {
  return (items || []).map((item) => {
    if (typeof item === "object") return item;
    else return { label: item };
  });
}
