import {
    NEW_RESET_PASSWORD_PROCESS_FAILED,
    NEW_RESET_PASSWORD_PROCESS_SUCCESS,
    NEW_RESET_PASSWORD_PROCESS_STARTED,
    NEW_RESET_PASSWORD_CLEAR_STATE

} from '../../constants';
import { initialState } from './initialState';
import {
    newResetPasswordStart,
    newResetPasswordSuccess,
    newResetPasswordFailed,
    newResetPasswordClearState

} from './helper';
import { fetchPayload } from '../../../utils/reducerOperations';


const NewResetPasswordReducer = (state = initialState(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        case NEW_RESET_PASSWORD_PROCESS_STARTED:
            return newResetPasswordStart(state, payload);
        case NEW_RESET_PASSWORD_PROCESS_SUCCESS:
            return newResetPasswordSuccess(state, payload);
        case NEW_RESET_PASSWORD_PROCESS_FAILED:
            return newResetPasswordFailed(state, payload);
        case NEW_RESET_PASSWORD_CLEAR_STATE:
            return newResetPasswordClearState(state, payload);
        default:
            return { ...state }
    }
};

export default NewResetPasswordReducer;
