export default{ barData : {
    "loading": false,
    "error": false,
    "data": {
        "histogramData": [
            {
                "node": "Commitment & Retention",
                "count": 1,
                "percentage": 1
            },
            {
                "node": "Organizational Culture",
                "count": 1,
                "percentage": 1
            },
            {
                "node": "Pandemic",
                "count": 1,
                "percentage": 1
            },
            {
                "node": "Performance Management",
                "count": 1,
                "percentage": 1
            },
            {
                "node": "Talent Acquisition",
                "count": 1,
                "percentage": 1
            },
            {
                "node": "Wellbeing",
                "count": 1,
                "percentage": 1
            },
            {
                "node": "Organizational Values",
                "count": 2,
                "percentage": 2
            },
            {
                "node": "Goal Setting",
                "count": 3,
                "percentage": 2
            },
            {
                "node": "Innovation",
                "count": 3,
                "percentage": 2
            },
            {
                "node": "Work-Life Balance",
                "count": 3,
                "percentage": 2
            },
            {
                "node": "Change Management",
                "count": 4,
                "percentage": 3
            },
            {
                "node": "Engagement",
                "count": 4,
                "percentage": 3
            },
            {
                "node": "Production & Delivery",
                "count": 4,
                "percentage": 3
            },
            {
                "node": "Senior Leaders",
                "count": 4,
                "percentage": 3
            },
            {
                "node": "Business Practices",
                "count": 5,
                "percentage": 4
            },
            {
                "node": "Strategy & Direction",
                "count": 7,
                "percentage": 6
            },
            {
                "node": "Talent Management",
                "count": 7,
                "percentage": 6
            },
            {
                "node": "Customer Success",
                "count": 8,
                "percentage": 7
            },
            {
                "node": "Communication",
                "count": 9,
                "percentage": 7
            },
            {
                "node": "Teamwork & Collaboration",
                "count": 9,
                "percentage": 7
            },
            {
                "node": "Policies & Procedures",
                "count": 10,
                "percentage": 8
            },
            {
                "node": "Learning & Development",
                "count": 11,
                "percentage": 9
            },
            {
                "node": "Quality",
                "count": 11,
                "percentage": 9
            },
            {
                "node": "Resources",
                "count": 25,
                "percentage": 21
            },
            {
                "node": "Other",
                "count": 39,
                "percentage": 32
            }
        ]
    },
    "data_count": 121,
    "isProcessing": false,
    
},
"defaultTopic":"All Themes"}