export function Default() {
    return {
        heatmapHeadersApi: {
            isProcessing: false,
            error: false,
            isDataFetched: false
        },
        heatmapTableApi: {
            isProcessing: false,
            error: false,
            isDataFetched: false,
        },
        heatmapQuestionsApi: {
            isProcessing: false,
            error: false
        },
        managerHeatmapHeadersApi: {
            isProcessing: false,
            error: false,
            isDataFetched: false
        },
        managerHeatmapTableApi: {
            isProcessing: false,
            error: false,
            isDataFetched: false,
        },
        managerHeatmapQuestionsApi: {
            isProcessing: false,
            error: false
        },
        managerHeatmapColumns: [],
        managerHeatmapData: [],
        managerHeatmapChildrenList: [],
        managerHeatmapHeaders: [],
        managerSelectedHeaders: [],
        managerHeatmapColumnList: [],
        managerSelectedScoreLevel: 'favourable',
        heatmapColumns: [],
        heatmapData: [],
        heatmapChildrenList: [],
        heatmapHeaders: [],
        selectedHeaders: [],
        heatmapColumnList: [],
        filters: {},
        byScore: [],
        multipleSelect: [],
        selectedScoreLevel: 'favourable',
        selectedComparisonLevel: '',
        managerSelectedComparisonLevel: '',
    };
}