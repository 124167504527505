import {
  makeKey,
  update,
  getSuccessResponse,
} from "../../../../utils/reducerOperations";
import { toast } from "../../../../utils/messageUtils";

export function resetPasswordSuccess(state, payload) {
  toast.success("Password reset successful");
  return makeKey(
    "resetPasswordApi",
    update(state.resetPasswordApi, {
      ...getSuccessResponse(payload),
      code: payload.code,
    })
  );
}
