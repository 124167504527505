import {
    FETCH_EXPLORE_TOPIC_COMMENTS_DATA_START,
    FETCH_EXPLORE_TOPIC_COMMENTS_DATA_SUCCESS,
    FETCH_EXPLORE_TOPIC_COMMENTS_DATA_FAILED,
  } from "../../../constants";
  import { SENTIMENTAL_URL } from "../../../../../config/constants";
  import { ApiBase } from "../../../../utils/apiBase";
  import { withType } from "../../../../utils/actionOperations";
import { getProjectIdAndOrgId } from '../../../../utils/apiUtils';
  
  export const fetchExploreTopicComments = (payload, cb) => {
    const apiBaseInstance = new ApiBase();
    return (dispatch) => {
      dispatch(withType(FETCH_EXPLORE_TOPIC_COMMENTS_DATA_START, { key: payload.key }));
      apiBaseInstance.instance
        .post(SENTIMENTAL_URL, {
          ...payload,
          enableFilter: true,
          ...getProjectIdAndOrgId(payload),
          data:{
          projectId: [payload.projectId],
          sentiments: [],
          text: "",
            topic: payload.topic,
            ...getProjectIdAndOrgId(payload)
          },
        })
        .then((response) => {
          dispatch(
            withType(FETCH_EXPLORE_TOPIC_COMMENTS_DATA_SUCCESS, {
              data: { ...response.data, key: payload.key },
              message: response.message,
            })
          );
          cb && cb(response.data.data.data);
        })
        .catch((err) => {
          dispatch(
            withType(FETCH_EXPLORE_TOPIC_COMMENTS_DATA_FAILED, {
              error: err,
              key: payload.key,
            })
          );
        });
    };
  };
  