import React, { useState } from "react";
import constants from "../constants";
import { LoaderButton } from "../../../FormElements/LoaderButton";
import FileUpload from "../../../Components/FileUpload";
import { toast } from "../../../utils/messageUtils";
import { SETTINGS_PAGE_SECTION } from '../../../../config/tabConstants';
import { checkIfAdmin } from '../../../utils/rolePermissionUtils';

const topicLibrary = (props) => {
  const [state, setState] = useState({
    submitToUpload: false,
  });
  
  const   onUploadTopicLibrary = () => {
    let fileTopic = props.libraryFileApi;
    props.getPreSignedURL(
      {
        currentTab: SETTINGS_PAGE_SECTION.SETTINGS_SUB_TABS.TOPIC_LIBRARY,
      fileName: "defaultTopicLibrary_culturebie_surveys.csv",      //fileTopic.file[0].name,
        fileType: fileTopic.file[0].type,
        isDefaultTopic: true,
        isTopic: true,
      },
      (response) => {
        props.uploadFile(
          {
            url: response ? response.data.data.preSignedUrl : "",
            file: fileTopic.file[0],
            fileType: fileTopic.file[0].type,
          },
          (isSuccess) => {
            if (isSuccess) {
               toast.success("Successfully uploaded topic library");
               setState({ submitToUpload: false });
            } else {
              toast.error("Topic library file upload unsuccessful");
              setState({ submitToUpload: false });
            }
          }
        );
       
      }
    );
  };

  const dragOption = () => (
    
    <>
      <FileUpload
        handleFileUpload={props.uploadTopicLibrary}
        fileUpload={props.libraryFileApi}
      />
      {props.libraryFileApi.file&&props.libraryFileApi.file[0].name}
    </>
    
  );

  const topicFileUpload = () => {
    if(checkIfAdmin()) 
    return (
      <ul>
      <li>{dragOption()}</li>
      <li className="login-btn">{processButton()}</li>
      </ul>)
  };

  const processButton= () => {
    const { processFilesApi } = props;
    return (
      <LoaderButton
        loader={processFilesApi.loading || state.submitToUpload}
        onClick={submitToUpload}
        disabled={props.disableButton || state.submitToUpload}
        classList={constants.loaderButton.classes}
        loaderClass={constants.loaderButton.loaderClass}
        text={constants.buttonText}
      />
    );
  }
  const submitToUpload = (cb, fileName) => {
    setState({
      submitToUpload: true,
    });
    onUploadTopicLibrary();
  }
  return (
    <div div className='settings-contact'>
      <h2>Topic library</h2>
      <ul className='vertical-list '>
        <li>
          <div className='split-block topic-upload'>
           {topicFileUpload()}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default topicLibrary;
