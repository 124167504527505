import { deepCopy } from "../../../../utils/deepCopy";
import { indexBasedColorPicker } from "../../../../utils/heatmapUtils";

export const addComponentButton = (state, payload) => {
  const { selectedItem, colors } = payload;
  const newId = (parseInt(selectedItem.id) + 1).toString();
  const updatedThresholdValue = deepCopy(state.heatmapConfiguration.thresholdValue);
  let valueBefore
  let valueAfter
  let newValue
  let thresholdValueArrayLength = updatedThresholdValue.length
  if(parseInt(selectedItem.id) === thresholdValueArrayLength){
    valueBefore = parseInt(updatedThresholdValue[parseInt(selectedItem.id)-1].value)
    newValue = Math.round((valueBefore + 100) / 2);
  }
  else{
   valueBefore = parseInt(updatedThresholdValue[parseInt(selectedItem.id)-1].value)
   valueAfter  = updatedThresholdValue[parseInt(selectedItem.id)].value
   newValue = Math.round((valueBefore + valueAfter) / 2);
  }

  const newThresholdValue = {
    id: newId,
    value: newValue,
    color: "",
  };

  let heatmapConfigurationIndex = deepCopy(state.heatmapConfiguration.thresholdValue)
  const indexToInsert = heatmapConfigurationIndex.findIndex(
    (item) => item.id === selectedItem.id
  );


  updatedThresholdValue.splice(indexToInsert + 1, 0, newThresholdValue);
  for (let affectedIndex = indexToInsert + 2; affectedIndex < updatedThresholdValue.length; affectedIndex++) {

        updatedThresholdValue[affectedIndex].id = (parseInt(updatedThresholdValue[affectedIndex].id) + 1).toString();
      }
      let updatedColoredArray = indexBasedColorPicker(updatedThresholdValue,colors)

  return {
    ...state,
    heatmapConfiguration: {
      ...state.heatmapConfiguration,
      thresholdValue: updatedColoredArray,
    },
  };
};
