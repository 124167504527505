import { generateStaticQuestionId } from '../../../../../utils/goalsUtils';
import { v4 as uuidv4 } from "uuid";

export const commentsTemplateGoingWell = [{
    qid: uuidv4(),
    type: 'Text Entry',
    questionLabel: 'Going well',
    options: [],
    isSelected: true,
    question: 'All things considered, what is going well?',
    staticQuestionId: generateStaticQuestionId('All things considered, what is going well?'),
}]

export const commentsTemplateImprovement = [
    {
        qid: uuidv4(),
        type: 'Text Entry',
        isSelected: true,
        options: [],
        question: 'What is the number one thing you would improve about your organization?',
        questionLabel: 'Improvement',
        staticQuestionId: generateStaticQuestionId('What is the number one thing you would improve about your organization?'),
    }
]