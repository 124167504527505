import {
  FETCH_STORYBOARD_ELEMNTS_STARTED,
  FETCH_STORYBOARD_ELEMNTS_SUCCESS,
  FETCH_STORYBOARD_ELEMNTS_FAILED,
} from '../../constants';
import { GET_STORYBOARD_ELEMENTS } from '../../../../config/constants';
import {
  NLPCommonReqBodies,
  getSurveyListQueryParams,
} from '../../../utils/actionOperations';
import { ApiBase } from '../../../utils/apiBase';
import { getOrgIdFromUrl } from '../../../utils/urlProcessor';

export const fetchStoryboardElements = (params, cb) => {
  const orgId = getOrgIdFromUrl();
  const fetchProps = getSurveyListQueryParams(params);
  const axios = new ApiBase().instance;
  return (dispatch) => {
    dispatch({ type: FETCH_STORYBOARD_ELEMNTS_STARTED });
    axios
      .post(GET_STORYBOARD_ELEMENTS, {
        ...NLPCommonReqBodies(true, orgId),
        ...fetchProps,
        ...params,
      })
      .then((res) => {
        dispatch({
          type: FETCH_STORYBOARD_ELEMNTS_SUCCESS,
          payload: { data: res && res.data && res.data.data },
        });
        const data = (res && res.data && res.data.data) || {};
        const storyboardElements = Object.values(data) || [];
        cb && cb(storyboardElements);
      })
      .catch((err) =>
        dispatch({
          type: FETCH_STORYBOARD_ELEMNTS_FAILED,
          payload: { error: err && err.message },
        })
      );
  };
};
