export var CREATE_PROJECT_PERMISSIONS = {
  CREATE_PROJECT: 'createProject',
  DATA_COLLECTION: 'createDataCollection',
  TEXT_ANALYSIS: 'uploadTextAnalysis',
  UPLOAD_DATA_COLLECTION: 'uploadDataCollection'
};
export var SETTINGS_PERMISSIONS = {
  UPLOAD_TOPIC: 'uploadDefaultTopic',
  VIEW_ADMINS: 'viewAdmins',
  ADD_ADMIN: 'addAdmins',
  DELETE_ADMIN: 'deleteAdmins',
  VIEW_LOGS: 'viewLogs'
};
export var PEOPLE_PERMISSIONS = {
  VIEW: 'viewPeople',
  ADD: 'addPeople',
  UPLOAD_HRIS: 'uploadHris'
};
export var SURVEY_WIZARD_PERMISSIONS = {
  UPLOAD_THEME: 'uploadThemeLibrary',
  UPLOAD_HRIS: 'uploadHris',
  QUESTIONS: 'wizardQuestions',
  SETUP: 'wizardSetup',
  RESPONSES: 'wizardResponses'
};
export var ORGANIZATION_PERMISSIONS = {
  ADD: 'addOrganization',
  RENAME: 'renameOrganization',
  DELETE: 'deleteOrganization'
};
export var PROJECTS_PERMISSIONS = {
  EDIT: 'editProject',
  RENAME: 'renameProject',
  DELETE: 'deleteProject',
  COPY: 'copyProject',
  INVITE: 'inviteViewers',
  REOPEN: 'reopenProject',
  CLOSE: 'closeProject',
  UNPUBLISH: "unPublishSurvey",
  PUBLISH: "publishSurvey"
};
export var MANAGE_PERMISSIONS = {
  DELETE_VIEWER: "deleteViewer",
  VIEW_MANAGERS: 'viewManagers',
  INVITE_MANAGERS: 'inviteManagers',
  EXPORT: 'exportData',
  VIEW_RESPONSES: 'viewResponses',
  CHANGE_THEME_LIBRARY: 'changeThemeLibrary',
  CHANGE_CUSTOM_TOPICS: 'changeCustomTopics',
  VIEWVERS: 'listViewers',
  DELETE_RESPONSE: 'deleteUserResponse'
};
export var DASHBOARD_TAB_PERMISSION = {
  STORY: 'story',
  EXPLORE: 'explore',
  COMPARE: 'compare',
  COMMENTS: 'comments',
  MANAGE: 'manage',
  INSIGHTS: 'insights',
  HEATMAP: "heatmap"
};
export var PROJECT_CONFIG_PERMISSIONS = {
  UPDATE_PROJECT_CONFIG: 'modifyProjectConfigs'
};