import { URL_DATA } from "../../../../../../../../../config/urlDataConstants";
import { deepCopy } from "../../../../../../../../utils/deepCopy";
import { getDataFromUrlToken } from "../../../../../../../../utils/urlDataStore";

export const filterDemographicsData = (data) => {
    let dataCopy = deepCopy(data)
    let filteredData = dataCopy
    const urlFilter = getDataFromUrlToken(URL_DATA.DASHBOARD_FILTER);
   // filteredData = urlFilter !== undefined ? dataCopy.filter((item => !(Object.keys(urlFilter).includes(item.value)))) : dataCopy
    return filteredData
}

export const getSelectedFilters = (answers, demographicsData) => {
    let demographicsDataCopy = deepCopy(demographicsData)
    let filter = {}
    answers.forEach(item => {
        if (!Object.keys(filter).includes(item)) {
            let splittedItem = item.split(":")
            if (splittedItem[1]) {
                let filteredSplitData = answers.filter((item) => item.startsWith(splittedItem[0]));
                filter[splittedItem[0]] = filteredSplitData.map(data => {
                    return data.split(":")[1]
                })
            }
            else {
                let filteredData = demographicsDataCopy.find(filteredKey => filteredKey.value === item)
                filter[item] = filteredData.children.map(child => {
                    return child.title
                })
            }

        }

    })
    return filter
}