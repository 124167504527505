export const downloadUserCommentStart=(state) =>{
    return {
      ...state,
      downloadUserCommentsApi: {
        isProcessing: true,
        error:false
      },
    };
  }
  
  export const downloadUserCommentSuccess=(state) =>{
    return {
      ...state,
      downloadUserCommentsApi: {
        isProcessing: false,
        error:false
      },
    };
  }
  
  export const downloadUserCommentFailed=(state) =>{
    return {
      ...state,
      downloadUserCommentsApi: {
        isProcessing: false,
        error:true
      },
    };
  }