import {
    handleNetworkError,
    handleApiResponseError,
    getSuccessResponse
} from '../../../../utils/reducerOperations';
import { newJson } from '../../../../utils/newJson';
import { deepCopy } from '../../../../utils/deepCopy';
import { addAdditionalElementsByCondition, divideQuestionSetByPageBreaks, generateQuestionSetWithBlockId, generateUpdatedEditSurveyBlock } from '../../../../utils/newAttendSurveyUtils';
import { generateGoalsForSurveyEditor } from '../../../../utils/newEditSurveyUtils';
import { FEEDBACK_360_TYPE } from '../../../../../config/questionTypeConstants';

export function fetchProjectStart(state) {
    return {
        ...state,
        surveyQuestionSetApi: {
            data: null,
            isProcessing: true,
            message: null,
            error: false
        }

    };
};

export function fetchProjectSuccess(state, action) {
    const { data } = action.response;
    data.data = newJson(data.data.response);
    let isAnswerSet = false;
    if (!data.data.questionSet) {
        data.data.questionSet = data.data.answerSet;
        isAnswerSet = true
    }
    if (data.data.childProjectType) {
        data.data.projectType = data.data.childProjectType
    }
    let getAdditionalElementData = addAdditionalElementsByCondition(data, false, isAnswerSet, action.callbackData)
    if (!FEEDBACK_360_TYPE.includes(data.data.projectType)) {
        data.data.englishQuestionSet = deepCopy(data.data.questionSet);
        let generatedQuestionSet = isAnswerSet || data.data.questionSetUpdatedDate ? generateUpdatedEditSurveyBlock(getAdditionalElementData.data, action.callbackData) : divideQuestionSetByPageBreaks(getAdditionalElementData.data, action.callbackData)
        let finalQuestionSetPageBreaked = isAnswerSet || data.data.questionSetUpdatedDate ? generatedQuestionSet : generateGoalsForSurveyEditor({ questionSet: generatedQuestionSet }, true)
        return {
            ...state,
            surveyQuestionSetApi: getSuccessResponse(getAdditionalElementData),
            selectedLanguage: data.data.selectedLanguage || 'English',
            translatedTexts: data.data.translatedTexts || {},
            questionSet: isAnswerSet || data.data.questionSetUpdatedDate ? data.data.questionSet : generateQuestionSetWithBlockId({ questionSet: generatedQuestionSet }, action.callbackData),
            pageBreakAddedQuestionSet: finalQuestionSetPageBreaked
        };
    }
    else {
        return {
            ...state,
            surveyQuestionSetApi: getSuccessResponse(getAdditionalElementData),
            selectedLanguage: data.data.selectedLanguage || 'English',
            translatedTexts: data.data.translatedTexts || {},
            questionSet: data.data.questionSet,
            pageBreakAddedQuestionSet: generateUpdatedEditSurveyBlock(getAdditionalElementData.data, action.callbackData)

        }
    }

};

// export function changeLanguage(state, action) {
//     return {
//         ...state,
//         selectedLanguage: action.payload.language,
//         surveyQuestionSetApi: changeSurveyLanguage(
//             deepCopy(state.surveyQuestionSetApi),
//             action.payload.language, false,
//             action.payload.translatedDescription,
//             action.payload.descriptiveTexts)
//     }
// }


export function fetchProjectFail(state, action) {
    return {
        ...state,
        surveyQuestionSetApi:
            (action.response
                && action.response.response
                && action.response.response.data
                ? handleApiResponseError(action.response.response.data)
                : handleNetworkError(action.response))
    };
};

// export function translateStart(state, action) {
//     return {
//         ...state,
//         translateApi: {
//             isProcessing: true,
//             error: false
//         }
//     };

// }

// export function translateSuccess(state, action) {
//     return {
//         ...state,
//         translateApi: {
//             isProcessing: false,
//             error: false
//         },
//         translatedTexts: action.payload.data && action.payload.data.translatedData
//     };

// }

// export function translateFailed(state, action) {
//     return {
//         ...state,
//         translateApi: {
//             isProcessing: false,
//             error: true
//         },
//         translatedTexts: state.defaultTexts
//     };

// }


// 2:{
//     label: "Often",
//     questions : [{
//         "question": "Deeply knows our customers and consumers.",
//         "selectedLabelAnswer": "3",
//         "id": "2e859eb52900500465d7bb191a1012a693df2468eef5e3859a26440210a435c2",
//         "label": "Often"
//     },
//     {
//         "question": "Cultivates breakthrough thinking and identifies bold actions.",
//         "selectedLabelAnswer": "3",
//         "id": "3cfe0026a23d644eeaa37590e4fdba24c9e92d1f9c44afb648157e6777b12a8b",
//         "label": "Often"
//     },
//     {
//         "question": "Takes risks and helps people learn from failures.",
//         "selectedLabelAnswer": "3",
//         "id": "7c8fb4e850e62bc64986c5cd31e8d2b8632e64055b0f50ee127288319bb6fadb",
//         "label": "Often"
//     }]
// }
// 3:{
//     label: "not Often",
//     questions : [{
//         "question": "Deeply knows our customers and consumers.",
//         "selectedLabelAnswer": "3",
//         "id": "2e859eb52900500465d7bb191a1012a693df2468eef5e3859a26440210a435c2",
//         "label": "not Often"
//     },
//     {
//         "question": "Cultivates breakthrough thinking and identifies bold actions.",
//         "selectedLabelAnswer": "3",
//         "id": "3cfe0026a23d644eeaa37590e4fdba24c9e92d1f9c44afb648157e6777b12a8b",
//         "label": "not Often"
//     },
//     {
//         "question": "Takes risks and helps people learn from failures.",
//         "selectedLabelAnswer": "3",
//         "id": "7c8fb4e850e62bc64986c5cd31e8d2b8632e64055b0f50ee127288319bb6fadb",
//         "label": "not Often"
//     }]
// }