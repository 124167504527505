import { fetchFormConfigurations } from "../../inputFormConfigurations";
import { setInitialState } from "../../../utils/reducerOperations";
export function Default() {
  return {
    isFormValid: false,
    fetchManagersApi: setInitialState(),
    inviteManagerApi: setInitialState(),
    hirsInputBoxConfiguration: fetchFormConfigurations(["hrisFileName", "hrisFile"]),
  };
}
