export const fetchBatchQuestionStart = (state, payload) => {
  return {
    ...state,
    batchQuestionsApi: {
      isProcessing: true,
      data: [],
      error: false,
      message: "",
    },
  };
};

export const fetchBatchQuestionSuccess = (state, payload) => {
  return {
    ...state,
    batchQuestionsApi: {
      isProcessing: false,
      data:payload.data,
      error: false,
      message: "",
    },
  };
};

export const fetchBatchQuestionFailed = (state, payload) => {
  return {
    ...state,
    batchQuestionsApi: {
      isProcessing: false,
      data: [],
      error: true,
      message: payload.error,
    },
  };
};

export const setExploreInitialstateSuccess = (state,payload)=>{
  return {
    ...state,
    exploreReducerInitialStateSet:payload.key
  };

}

