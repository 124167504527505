import {
  apiStartState,
  apiFailureState,
  apiSuccessState,
  apiInitialState,
} from "../../../../utils/apiStateUtils";

export function uploadTopicFileStart(state, action) {
  return {
    ...state,
    libraryFileApi: apiStartState(state.libraryFileApi),
  };
}

export function uploadTopicFileSuccess(state, action) {
  const api = apiSuccessState(state.libraryFileApi, action);
  return {
    ...state,
    libraryFileApi: {
      ...api,
      name: action.fileName,
      file:action.file
    },
  };
}

export function uploadTopicFileFail(state, action) {
  return {
    ...state,
    libraryFileApi: apiFailureState(state.libraryFileApi, action),
  };
}
export function uploadHrisFileStart(state, action) {
  return {
    ...state,
    hrisFileApi: apiStartState(state.hrisFileApi),
  };
}

export function uploadHrisFileSuccess(state, action) {
  const api = apiSuccessState(state.hrisFileApi, action);
  return {
    ...state,
    hrisFileApi: {
      ...api,
      name: action.fileName,
    },
  };
}

export function uploadHrisFileFail(state, action) {
  return {
    ...state,
    hrisFileApi: apiFailureState(state.hrisFileApi, action),
  };
}

export function processFilesStart(state) {
  return {
    ...state,
    processFilesApi: apiStartState(state.processFilesApi),
  };
}

export function processFilesSuccess(state, action) {
  return {
    ...state,
    processFilesApi: apiSuccessState(state.processFilesApi, action),
  };
}

export function processFilesFail(state, action) {
  return {
    ...state,
    processFilesApi: apiFailureState(state.processFilesApi, action),
  };
}

export function getCsvColumnsStart(state) {
  return {
    ...state,
    getCsvColumnsApi: apiStartState(state.getCsvColumnsApi),
  };
}

export function getCsvColumnsSuccess(state, action) {
  return {
    ...state,
    getCsvColumnsApi: apiSuccessState(state.getCsvColumnsApi, action),
  };
}

export function getCsvColumnsFail(state, action) {
  return {
    ...state,
    getCsvColumnsApi: apiFailureState(state.getCsvColumnsApi, action),
  };
}

export function reprocessFilesStart(state) {
  return {
    ...state,
    reprocessFilesApi: apiStartState(state.reprocessFilesApi),
  };
}

export function reprocessFilesSuccess(state, action) {
  return {
    ...state,
    reprocessFilesApi: apiSuccessState(state.reprocessFilesApi, action),
  };
}

export function reprocessFilesFail(state, action) {
  return {
    ...state,
    reprocessFilesApi: apiFailureState(state.reprocessFilesApi, action),
  };
}

export function updateFields(state, action) {
  const inputBoxConfiguration = Object.assign([], state.inputBoxConfiguration);
  if (action.event.target) {
    inputBoxConfiguration[action.index] = {
      ...inputBoxConfiguration[action.index],
      value: action.event.target.value,
      isValid: action.event.target.value ? true : false,
    };
  }
  return {
    ...state,
    inputBoxConfiguration,
  };
}

export function clearData(state) {
  return {
    ...state,
    commentsFileApi: apiInitialState(),
    libraryFileApi: apiInitialState({name:''}),
  };
}

export function clearTopicFileName(state){
  return{
    ...state,
    libraryFileApi: {
      ...state.libraryFileApi,
      name: '',
      file: false
    }
  };
}

export {
  downloadCommentFail,
  downloadCommentStart,
  downloadCommentSuccess,
  downloadLibraryFail,
  downloadLibraryStart,
  downloadLibrarySuccess,
  downloadUserCommentFail,
  downloadUserCommentStart,
  downloadUserCommentSuccess,
} from "./downloadLibrary";

export {
  getBatchIdsFail,
  getBatchIdsStart,
  getBatchIdsSuccess,
  setBatchId,
} from "./getBatchId";

export {
  jobApprovalRequestFail,
  jobApprovalRequestStart,
  jobApprovalRequestSuccess,
} from "./jobApprovalRequest";

export {
  uploadFileFail,
  uploadFileStart,
  uploadFileSuccess,
} from "./uploadFile";

export {
  reprocessUploadFail,
  reprocessUploadStart,
  reprocessFileUploadSuccess,
} from "./reprocessFileUpload";

export {
  batchApprovalFail,
  batchApprovalStart,
  batchApprovalSuccess,
} from "./batchApprove";

export {
  batchDeleteFail,
  batchDeleteStart,
  batchDeleteSuccess,
} from "./batchDelete";

// export {
//     getCsvColumnsStart,getCsvColumnsSuccess,getCsvColumnsFail
// } from './getCsvColumns';

export * from "./fetchHrisFile";
