import {
  makeKey,
  update,
  handleApiResponseError,
  handleNetworkError,
} from "../../../../utils/reducerOperations";
import { toast } from "../../../../utils/messageUtils";

export function resetPasswordFailed(state, payload) {
  toast.error("Password reset failed");
  let response = makeKey(
    "resetPasswordApi",
    update(
      state.resetPasswordApi,
      payload.response && payload.response.data
        ? handleApiResponseError(payload.response.data)
        : handleNetworkError(payload)
    )
  );
  return response;
}
