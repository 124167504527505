import React from "react";
import NumberFormat from "react-number-format";
import { EmptyState } from "../../../../../../../../Packages/EmptyState";
import { getTopicBadgeColor } from "../../../../helper";
export default class ScoreElement extends React.Component {
  render() {
    return (
      <>
        <h3 className="global-h3">{this.props.title}</h3>
        {this.props.data.length !== 0 ? (
          <>
            {this.props.data &&
              this.props.data.map((item) => (
                <>
                  <li>
                    <span
                      className={`item-count ${getTopicBadgeColor(
                        Math.round(item.favScore)
                      )}`}
                    >
                      <NumberFormat
                        value={Math.round(item.favScore)}
                        decimalScale={0}
                        displayType="text"
                      />
                    </span>
                    <div className="item-info">
                      <span className="item-info__text">{item.item}</span>
                      <span className="item-info__dimension">
                        {item.dimension}
                      </span>
                    </div>
                  </li>
                </>
              ))}
          </>
        ) : (
          <EmptyState emptyStateMessage={"No data found"} />
        )}
      </>
    );
  }
}
