import { createSelector } from "reselect";

const gethomePageReducer = (state) => state.homePageReducer;
const getorganizationPageReducer = (state) => {
  return state.organizationPageReducer;
};
const StateSelector = (homeReducer, orgProfileReducer) => ({
  ...homeReducer,
  ...orgProfileReducer,
});

export const SelectState = createSelector(
  [getorganizationPageReducer, gethomePageReducer],
  StateSelector
);
