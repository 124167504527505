import { deepCopy } from "../../../../../utils/deepCopy"
import { toast } from "../../../../../utils/messageUtils"

export const deleteDemographicQuestionAnswer = (state, payload) => {
    let goals = deleteDemographicQuestionAnswerFromGoals(state, payload)
    return {
        ...state,
        goals: goals
    }
}

const deleteDemographicQuestionAnswerFromGoals = (state, payload) => {
    let goalsCopy = deepCopy(state.goals)
    let goals = deepCopy(goalsCopy)
    let selectedQuestionAnswer = []
    let questionsCount = getQuestionsAnswerCount(state, payload)
    if (questionsCount <= 2) {
        toast.error("Question must  have atleast two answers!")
    } else {
        toast.success("Answer deleted successfully.")
        goals.forEach(item => {
            if (item.goalId === "demographics") {
                item.questions.forEach(
                    question => {
                        if (question.staticQuestionId === payload.goalId) {
                            selectedQuestionAnswer = question.options.filter(data => data.id !== payload.staticQuestionId)
                        }
                    }
                )
            }
        })
        goalsCopy.forEach(goal => {
            if (goal.goalId === "demographics") {
                goal.questions.forEach(value => {
                    if (value.staticQuestionId === payload.goalId) {
                        value.options = selectedQuestionAnswer
                    }
                })
            }
        })
    }
    return goalsCopy
}

const getQuestionsAnswerCount = (state, payload) => {
    let questionsCount = 0
    let goalsCopy = deepCopy(state.goals)
    goalsCopy.forEach(item => {
        if (item.goalId === "demographics") {
            item.questions.forEach(
                question => {
                    if (question.staticQuestionId === payload.goalId) {
                        question.options.forEach(data => questionsCount += 1)
                    }
                }
            )
        }
    })
    return questionsCount
}