import {
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAIL,
  CREATE_PROJECT_START,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAIL,
  SAVE_QUESTIONSET_START,
  SAVE_QUESTIONSET_SUCCESS,
  SAVE_QUESTIONSET_FAIL,
  COPY_SURVEY_START,
  COPY_SURVEY_SUCCESS,
  COPY_SURVEY_FAIL,
  SET_ORGANIZATION_PROJECT_COUNT,
  DELETE_ORGANISATION_PROJECT_START,
  DELETE_ORGANISATION_PROJECT_SUCCESS,
  DELETE_ORGANISATION_PROJECT_FAIL
} from "../../constants";
import { Default } from "./initialState";
import {
  createProjectStart,
  createProjectSuccess,
  createProjectFail,
  saveQuestionSetStart,
  saveQuestionSetSuccess,
  saveQuestionSetFail,
  fetchProjectsStart,
  fetchProjectsSuccess,
  fetchProjectsFail,
  setProjectCount,
  copySurveyStart,
  copySurveySuccess,
  copySurveyFail,
  deleteProjectSuccess,
  deleteProjectStarted,
  deleteProjectFailure
} from "./helper";
import { update } from "../../../utils/reducerOperations";

export function MyProjectsReducer(state = Default(), action) {
  switch (action.type) {
    case FETCH_PROJECTS_START:
      return fetchProjectsStart(state, action);
    case FETCH_PROJECTS_SUCCESS:
      return fetchProjectsSuccess(state, action);
    case FETCH_PROJECTS_FAIL:
      return fetchProjectsFail(state, action);
    case CREATE_PROJECT_START:
      return createProjectStart(state);
    case CREATE_PROJECT_SUCCESS:
      return createProjectSuccess(state, action);
    case CREATE_PROJECT_FAIL:
      return createProjectFail(state, action);
    default:
      return saveQuestionSet(state, action);
  }
}

export function saveQuestionSet(state, action) {
  switch (action.type) {
    case SAVE_QUESTIONSET_START:
      return saveQuestionSetStart(state, action);
    case SAVE_QUESTIONSET_SUCCESS:
      return saveQuestionSetSuccess(state, action);
    case SAVE_QUESTIONSET_FAIL:
      return saveQuestionSetFail(state, action);
    case SET_ORGANIZATION_PROJECT_COUNT:
      return setProjectCount(state, action);
    default:
      return copySuvey(state, action);
  }
}

export function copySuvey(state, action) {
  switch (action.type) {
    case COPY_SURVEY_START:
      return copySurveyStart(state, action);
    case COPY_SURVEY_SUCCESS:
      return copySurveySuccess(state, action);
    case COPY_SURVEY_FAIL:
      return copySurveyFail(state, action);
    default:
      return deleteProject(state, action)
  }
}

export function deleteProject(state, action) {
  switch (action.type) {
    case DELETE_ORGANISATION_PROJECT_START:
      return update(state, deleteProjectStarted(state, action));
    case DELETE_ORGANISATION_PROJECT_SUCCESS:
      return update(state, deleteProjectSuccess(state, action));
    case DELETE_ORGANISATION_PROJECT_FAIL:
      return update(state, deleteProjectFailure(state, action));
    default:
      return { ...state };
  }
};
