import { 
  apiSuccessState, 
  apiFailureState 
} from "../../../../utils/apiStateUtils";

export function fetchWordcloudStart(state, action){
  return {
      ...state,
      questionWordCloudApi:{
          ...state.questionWordCloudApi,
          loading:true
      }
  };
}

export function fetchWordcloudSuccess(state, action){
  return {
      ...state,
      questionWordCloudApi:
      apiSuccessState(state.questionWordCloudApi,action)
  };
}

export function fetchWordcloudFail(state, action) {
  return {
      ...state,
      questionWordCloudApi: 
      apiFailureState(state.questionWordCloudApi,action)
  };
};