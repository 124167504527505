import React from "react";
import { Table } from "antd";
import './index.scss';
import { EmptyState } from "../../Packages/EmptyState";
export const CustomTable = ({ dataSource, columns, pagination, onChange,loading, ...props }) => {

  const getEmptyMessage = () =>{
    return props.emptyStateMessage ? props.emptyStateMessage : "No data found"
  }

  return (
    <div className="custom-table">
      <Table
        showSorterTooltip={false}
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        onChange={onChange}
        className={`${props.className || ' '}`}
        loading={loading}
        scroll={props.scroll?props.scroll:''}
        locale={{ emptyText: (<EmptyState emptyStateMessage={getEmptyMessage()} component="table" />) }}
      />
    </div>
  );
}