import { fetchPayload } from "../../../utils/reducerOperations";
import {
RENAME_PEOPLE_START,
RENAME_PEOPLE_SUCCESS,
RENAME_PEOPLE_FAILED,
DELETE_PEOPLE_START,
DELETE_PEOPLE_SUCCESS,
DELETE_PEOPLE_FAILED
} from "../../constants";
import {
    renamePeopleStarted,
    renamePeopleFailed,
    renamePeopleSuccess,
    deletePeopleStarted,
    deletePeopleFailed,
    deletePeopleSuccess,
    } from "./Helpers";
import { Default } from "./InitialState";
const PeopleListingReducer = (state = Default(), action) => {
    let payload = fetchPayload(action);
    switch (action.type) {
        //rename
        case RENAME_PEOPLE_START:
            return renamePeopleStarted(state, payload);
        case RENAME_PEOPLE_SUCCESS:
            return renamePeopleSuccess(state, payload);
        case RENAME_PEOPLE_FAILED:
            return renamePeopleFailed(state, payload);
            //delete
        case DELETE_PEOPLE_START:
            return deletePeopleStarted(state, payload);
        case DELETE_PEOPLE_SUCCESS:
            return deletePeopleSuccess(state, payload);
        case DELETE_PEOPLE_FAILED:
            return deletePeopleFailed(state, payload);
       
        default:
            return { ...state };
    }
};

export default PeopleListingReducer;
