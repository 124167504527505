import React from "react";
import CustomModal from "../../../../../../../../Components/CustomModal";
import ScrollBar from "../../../../../../../../Packages/ScrollBar";
import "./style.css";
export default class TopicCommentModal extends React.Component {
  render() {
    return (
      <CustomModal
        title={this.props.topic}
        visible={this.props.isModalVisible}
        className="barchart-modal"
        onOk={this.props.handleOkModal}
        onCancel={this.props.handleOkModal}
        width="auto"
        okText="OK"
        commentModal={true}
      >
        <ScrollBar
          onScroll={this.props.handleScroll}
          style={{ height: "400px" }}
        >
          {
          this.props.data
            ? this.props.data.map(
                (item, index) => (
                  <div className="infinit-comment global-text" key={index}>
                    {item.text}
                  </div>
                )
              )
            : ""}
          {this.props.api &&
            this.props.api.isProcessing && (
              <p>Loading ...</p>
            )}
        </ScrollBar>
      </CustomModal>
    );
  }
}
