import { v4 as uuidv4 } from 'uuid'
const answerableQuestions = ["Text Entry", "Multiple Choice", "Matrix", "multipleOption"]

export const divideQuestionSetByPageBreaks = (questionSet, demographicsData) => {
    let blockData = [];
    let questionSetHasPageBreak = false;
    if (questionSet && questionSet.length) {
        questionSet.forEach((block) => {
            let startIndex = 0;
            let pageBreakIndexes = []
            let blockHasPageBreak = false;
            block.questions.forEach((item, index) => {
                if (item.type === 'Page Break') {
                    pageBreakIndexes.push(index);
                    blockHasPageBreak = true;
                    questionSetHasPageBreak = true;
                }
            })
            if (blockHasPageBreak) {
                pageBreakIndexes.forEach((element, index) => {
                    let questionArray = [...block.questions]
                    blockData.push({
                        dimension: block.dimension,
                        questions: questionArray.splice(startIndex, element - startIndex)
                    })
                    questionArray = [...block.questions]
                    if (index === pageBreakIndexes.length - 1) {
                        blockData.push({
                            dimension: block.dimension,
                            questions: questionArray.splice(element + 1, questionArray.length)
                        })
                    }
                    startIndex = element + 1;
                })
            } else {
                blockData.push(block)
            }
        })
    }
    let updatedBlockDataWithFollowUp = updatedBlockData(blockData, questionSet, demographicsData)
    if (questionSetHasPageBreak || (updatedBlockDataWithFollowUp.length > blockData.length)) return updatedBlockDataWithFollowUp
    else return [];
}

export const addAdditionalElementsByCondition = (config, isPreview, isAnswerSet, demographicData) => {
    let removableGoalIds = []
    const questionSetKey = isPreview ? 'questions' : 'questionSet'
    if (config.data && config.data[questionSetKey] && config.data[questionSetKey].length &&
        (config.data.isAnswerSetModified || !isAnswerSet)) {
        if (config.data.aboutSurvey) {
            config.data[questionSetKey].unshift(getDescriptionBlock(
                config.data.aboutSurvey))
        }
        if (config.data.isLocalizationEnabled) {
            config.data[questionSetKey].unshift(getLanguageBlock(config.data))
        }
        config.data[questionSetKey].forEach(block => {
            if (block.isDefault) {
                if (block.isConditionalTopicSection) {
                    let isThisGoalIdHidden = checkIfHrisBasedRuleSatisfied(block, demographicData)
                    if (isThisGoalIdHidden) {
                        removableGoalIds.push(block.goalId)
                    }
                }
                block.questions[0].matrixQuestions.forEach(question => {
                    question.englishQuestion = question.text
                    if (question.haveFollowUpQuestion && question.followUpQuestions) {
                        question.followUpQuestions.forEach(followUpQuestion => {
                            if (followUpQuestion.type === "Matrix") {
                                followUpQuestion.matrixQuestions.forEach(followUpMatrixQuestion => {
                                    followUpMatrixQuestion.englishQuestion = followUpMatrixQuestion.text
                                })
                            }
                            else if (followUpQuestion.type === "Text Entry") {
                                followUpQuestion.englishQuestion = followUpQuestion.question
                            }
                        })
                    }
                })
                block.questions[0].options.forEach(option => {
                    option.englishLabel = option.label
                })
                if (block.haveFollowUpQuestion && block.followUpQuestions && block.followUpQuestions.length) {
                    block.followUpQuestions.forEach((item) => {
                        if (answerableQuestions.includes(item.type)) {
                            item.englishQuestion = item.question
                        }

                    })
                }
            }
            else {
                block.questions.forEach(question => {
                    if (answerableQuestions.includes(question.type)) {
                        question.englishQuestion = question.question
                        question.options.forEach(option => {
                            option.englishLabel = option.label
                        })
                    }
                })
            }
        })
    }
    let filteredConfig = config.data[questionSetKey]
    if (removableGoalIds && removableGoalIds.length > 0) {
        removableGoalIds.forEach((removingGoalId) => {
            filteredConfig = filteredConfig.filter((item) => item.goalId !== removingGoalId)
        })
        config.data[questionSetKey] = filteredConfig
    }


    return config

}

export const changeSurveyLanguage = (config, language, isPreview, translatedDescription, descriptiveTexts) => {
    const matrixKey = language === 'English' ? 'text' : language;
    const optionKey = language === 'English' ? 'label' : language;
    const questionKey = language === 'English' ? 'question' : language
    const questionSetKey = isPreview ? 'questions' : 'questionSet'
    if (config.data && config.data[questionSetKey]
        && config.data[questionSetKey].length) {
        const questions = config.data[questionSetKey];
        const translatedQuestionsSet = config.data.translatedQuestionsSet;
        const englishQuestionSet = config.data.englishQuestionSet;
        questions.forEach(block => {
            let translatedBlock = translatedQuestionsSet.find(item =>
                item.goalId === block.goalId);
            if (config.data.isSurveyEdited && language === 'English') {
                translatedBlock = englishQuestionSet.find(item =>
                    item.goalId === block.goalId);
            }
            if (translatedBlock) {
                if (block.isDefault) {
                    let translatedQuestions = translatedBlock.questions[0].matrixQuestions;
                    let translatedOptions = translatedBlock.questions[0].options;
                    let translatedMatrixDescription = translatedBlock.questions[0][questionKey];
                    block.questions[0].matrixQuestions.forEach(question => {
                        let translatedQuestionElement = translatedQuestions.find(item => item.value === question.value)
                        if (translatedQuestionElement && translatedQuestionElement[matrixKey]) {
                            question.HTMLText ? question.HTMLText = translatedQuestionElement[matrixKey] : question.text = translatedQuestionElement[matrixKey]
                        }
                        //followup-question-level
                        if (question.haveFollowUpQuestion && translatedQuestionElement) {
                            if (question.followUpQuestions && question.followUpQuestions.length > 0) {
                                question.followUpQuestions.forEach((followUpQuestion) => {
                                    let findedTranslatedQuestionElement = translatedQuestionElement.followUpQuestions.find(item => item.qid === followUpQuestion.qid)
                                    if (findedTranslatedQuestionElement) {
                                        if (followUpQuestion.type === "Matrix") {
                                            followUpQuestion.matrixQuestions[0].HTMLText ? followUpQuestion.matrixQuestions[0].HTMLText = findedTranslatedQuestionElement.matrixQuestions[0][matrixKey] :
                                                followUpQuestion.matrixQuestions[0].text = findedTranslatedQuestionElement.matrixQuestions[0][matrixKey]

                                            let translatedFollowUpOptions = findedTranslatedQuestionElement.options
                                            followUpQuestion.options.forEach(option => {
                                                let translatedOptionElementFollowUp = translatedFollowUpOptions.find(item => item.value === option.value)
                                                if (translatedOptionElementFollowUp && translatedOptionElementFollowUp[optionKey]) {
                                                    option.label = translatedOptionElementFollowUp[optionKey]
                                                }

                                            })
                                        }
                                        else {
                                            followUpQuestion.HTMLQuestion ? followUpQuestion.HTMLQuestion = findedTranslatedQuestionElement[questionKey] :
                                            followUpQuestion.question = findedTranslatedQuestionElement[questionKey]

                                        }
                                    }


                                })
                            }
                        }
                    })
                    block.questions[0].options.forEach(option => {
                        let translatedOptionElement = translatedOptions.find(item => item.value === option.value)
                        if (translatedOptionElement && translatedOptionElement[optionKey])
                            option.label = translatedOptionElement[optionKey]
                    })
                    block.questions[0].question = translatedMatrixDescription;
                } else {
                    let translatedQuestions = translatedBlock.questions
                    block.questions.forEach(question => {
                        if (answerableQuestions.includes(question.type)) {
                            let translatedQuestionElement = translatedQuestions.find(
                                item => item.qid === question.qid)
                            if (translatedQuestionElement) {
                                question.HTMLQuestion ? question.HTMLQuestion = translatedQuestionElement[questionKey] :
                                question.question = translatedQuestionElement[questionKey]
                                if (question.options && question.options.length) {
                                    question.options.forEach(option => {
                                        let translatedOption = translatedQuestionElement.options.find(
                                            item => item.value === option.value)
                                        option.label = translatedOption ? translatedOption[optionKey] : option.label
                                    })
                                }
                            }
                        }
                        else if (question.type === 'Descriptive Text') {
                            if (config.data.isSurveyEdited) {
                                let descriptiveTextElement = (descriptiveTexts || []).find(item => item.qid === question.qid)
                                if (descriptiveTextElement)
                                    question.question = descriptiveTextElement.question
                            } else {
                                let translatedQuestionElement = translatedQuestions.find(
                                    item => item.qid === question.qid)
                                if (translatedQuestionElement) {
                                    if (translatedQuestionElement[questionKey]) {
                                        question.question = translatedQuestionElement[questionKey]
                                    }
                                    else {
                                        let descriptiveTextElement = (descriptiveTexts || []).find(item => item.qid === question.qid)
                                        if (descriptiveTextElement)
                                            question.question = descriptiveTextElement.question
                                    }
                                }
                            }
                        }
                    })
                }
                if (block.haveFollowUpQuestion) {
                    if (block.followUpQuestions && block.followUpQuestions.length > 0) {
                        block.followUpQuestions.forEach((topicLevelFollowUp, key) => {
                            let findQuestionToTranslate = translatedBlock.followUpQuestions.find(item => item.qid === topicLevelFollowUp.qid)
                            if (findQuestionToTranslate) {
                                topicLevelFollowUp.HTMLQuestion ? topicLevelFollowUp.HTMLQuestion = findQuestionToTranslate[questionKey] :
                                topicLevelFollowUp.question = findQuestionToTranslate[questionKey]
                            }
                        })
                    }
                }
            }
        })
        if (translatedDescription) {
            questions[1] = getDescriptionBlock(translatedDescription)
        }
        config.data[questionSetKey] = questions;
    }
    return config
}

const getDescriptionBlock = (description) => {
    return {
        dimension: "",
        questions: [
            {
                question: description,
                questionLabel: "",
                isSelected: true,
                isCustom: true,
                questionDimension: "",
                qid: uuidv4(),
                type: "Descriptive Text",
                options: []
            }
        ],
        goalId: uuidv4()
    }
}

const getLanguageBlock = (data) => {
    return {
        dimension: "",
        questions: [
            {
                question: '',
                questionLabel: "",
                isSelected: true,
                isCustom: true,
                questionDimension: "",
                qid: uuidv4(),
                type: "Language Selector",
                options: [],
                translationLanguages: data.translationLanguages,
                translatedQuestionsSet: data.translatedQuestionsSet
            }
        ],
        goalId: uuidv4()
    }

}

const generatePageBreakObjectForNonFollowUp = (block, matrixQuestion, isFromCombinedMatrix) => {
    return {
        ...block,
        questions: [{ ...block.questions[0], isSplittedTopic: true, matrixQuestions: !isFromCombinedMatrix ? [{ ...matrixQuestion, followUpQuestions: [] }] : matrixQuestion }],
        followUpQuestions: [],
    }
}

const makeQuestionLevelFollowUp = (question, submittedAnswer, matrixQuestionDetails) => {
    if (question.type && question.type === "Text Entry") {
        return {
            isOutcomeVariable: true,
            isSelected: true,
            isStatic: true,
            isTemplate: true,
            isQuestionLevelFollowUp: true,
            dimension: question.dimension,
            goalId: question.goalId,
            condition: question.condition,
            questions: [{
                ...question,
                answer: question.answer,
                question: generateCommentQuestionsWithChoice(question.question, submittedAnswer),
                isQuestionLevelFollowUp: true,
                selectedGoalId: question.selectedGoalId,
                selectedMatrixQuestionQuestionId: matrixQuestionDetails.staticQuestionId
            }]
        }
    }
    else {
        return {
            isOutcomeVariable: true,
            isSelected: true,
            isStatic: true,
            isTemplate: true,
            isQuestionLevelFollowUp: true,
            dimension: question.dimension,
            goalId: question.goalId,
            condition: question.condition,
            questions: [{
                answer: question.answer,
                matrixQuestions: generateMatrixQuestionWithChoice(question.matrixQuestions, submittedAnswer),
                options: question.options, type: question.type,
                questionDimension: question.questionDimension,
                qid: question.qid, questionLabel: question.questionLabel,
                isQuestionLevelFollowUp: true,
                selectedGoalId: question.selectedGoalId,
                selectedMatrixQuestionQuestionId: matrixQuestionDetails.staticQuestionId

            }]
        }
    }


}

const checkConditionForDisplay = (questionDetails, questionSet) => {
    let ruleSatisfied = false
    let submittedAnswer
    let conditionToDisplay = questionDetails.type === "Text Entry" ? questionDetails.condition : questionDetails.matrixQuestions[0].condition
    let staticId = questionDetails.type === "Text Entry" ? questionDetails.selectedQuestionStaticId : questionDetails.matrixQuestions[0].selectedQuestionStaticId
    let condition = !Array.isArray(conditionToDisplay) && conditionToDisplay === ""
    if (condition || conditionToDisplay === undefined) {
        ruleSatisfied = true
    }
    else {
        if (conditionToDisplay && Array.isArray(conditionToDisplay)) {
            if (conditionToDisplay.length > 0) {
                questionSet.forEach((block, key) => {
                    if (block.goalId === questionDetails.selectedGoalId) {
                        if (block.questions[0] && block.questions[0].answer) {
                            let parsedAnswers = JSON.parse(block.questions[0].answer);
                            parsedAnswers.forEach((answerGroup) => {
                                if (answerGroup.staticQuestionId === staticId) {
                                    ruleSatisfied = conditionToDisplay.includes(`${answerGroup.answer}`)
                                    let labelForSubmittedAnswer = block.questions[0].options.filter((item) =>
                                        item.value === answerGroup.answer
                                    )
                                    submittedAnswer = labelForSubmittedAnswer[0].label
                                }
                            })
                        }

                    }
                })
            }
            else {
                ruleSatisfied = true
            }

        }
    }


    return { submittedAnswer, ruleSatisfied }
}


const generateMatrixQuestionWithChoice = (matrixQuestions, submittedAnswer) => {
    let matrixQuestionArray
    if (matrixQuestions && matrixQuestions[0] && matrixQuestions[0].text) {
        let indexOfString = matrixQuestions[0].text.search("{choice}")
        if (indexOfString >= 0) {
            let updatedText = matrixQuestions[0].text.replace("{choice}", submittedAnswer)
            matrixQuestionArray = [{ ...matrixQuestions[0], text: updatedText }]
        }
        else {
            matrixQuestionArray = matrixQuestions
        }

    }
    else {
        return matrixQuestions
    }
    return matrixQuestionArray

}


const updatedBlockData = (blockData, questionSet, demographicsData) => {
    let updatedBlockData = []
    let nonFollowUpMatrix = []
    // eslint-disable-next-line
    let isBlockContainsFollowUp = false
    if (blockData && blockData.length) {
        blockData.forEach((block, index) => {
            if (block.isDefault || block.isCustomTopic) {
                block.questions[0].matrixQuestions.forEach((questionDetails, index) => {
                    if (questionDetails.HTMLText) {
                        questionDetails.HTMLText = replaceQuestionIfDemographicsBracesExists(questionDetails.HTMLText, demographicsData)
                    }
                    else {
                        questionDetails.text = replaceQuestionIfDemographicsBracesExists(questionDetails.text, demographicsData)
                    }

                    if (questionDetails.haveFollowUpQuestion) {
                        isBlockContainsFollowUp = true
                        if (nonFollowUpMatrix.length > 0) {
                            let combinedFollowUp = generatePageBreakObjectForNonFollowUp(block, nonFollowUpMatrix, true)
                            updatedBlockData.push(combinedFollowUp)
                            nonFollowUpMatrix = []
                        }
                        let createNewObjectForNonFollowUpPageBreak = generatePageBreakObjectForNonFollowUp(block, questionDetails)
                        updatedBlockData.push(createNewObjectForNonFollowUpPageBreak)
                        questionDetails.followUpQuestions.forEach((question, key) => {
                            let { submittedAnswer, ruleSatisfied } = checkConditionForDisplay(question, questionSet)
                            if (ruleSatisfied) {
                                let makeQuestionLevelFollowUpObject = makeQuestionLevelFollowUp(question, submittedAnswer, questionDetails)
                                updatedBlockData.push(makeQuestionLevelFollowUpObject)
                            }
                            else {
                                if (question.answer) {
                                    question.answer = null
                                }
                            }
                        })
                    }
                    else {
                        nonFollowUpMatrix.push(questionDetails)
                    }

                })
                if (nonFollowUpMatrix.length > 0) {
                    let combinedFollowUp = generatePageBreakObjectForNonFollowUp(block, nonFollowUpMatrix, true)
                    updatedBlockData.push(combinedFollowUp)
                    nonFollowUpMatrix = []
                }
                // if (!isBlockContainsFollowUp && nonFollowUpMatrix.length == 0) {
                //     updatedBlockData.push(block)
                // }
            }

            else {
                block.questions.forEach(question => {
                    if (answerableQuestions.includes(question.type)) {
                        if (question.HTMLQuestion) {
                            question.HTMLQuestion = replaceQuestionIfDemographicsBracesExists(question.HTMLQuestion, demographicsData)
                        }
                        else {
                            question.question = replaceQuestionIfDemographicsBracesExists(question.question, demographicsData)
                        }

                    }
                })
                updatedBlockData.push(block)
            }
            if (block.haveFollowUpQuestion) {
                if (block.followUpQuestions && block.followUpQuestions.length) {
                    block.followUpQuestions.forEach((item) => {
                        updatedBlockData.push({
                            dimension: item.dimension,
                            questions: [item],
                        })
                    })
                }
            }
            isBlockContainsFollowUp = false

        })
    }
    return updatedBlockData

}
const generateCommentQuestionsWithChoice = (commentQuestionText, submittedAnswer) => {
    let updatedText
    if (commentQuestionText) {
        let indexOfString = commentQuestionText.search("{choice}")
        if (indexOfString >= 0) {
            updatedText = commentQuestionText.replace("{choice}", submittedAnswer)
        }
        else {
            updatedText = commentQuestionText
        }
    }
    return updatedText
}

const getReplaceString = (demographicsData, replaceItem) => {
    if (demographicsData && demographicsData[replaceItem]) {
        return demographicsData[replaceItem]
    }
    else return replaceItem
}

const replaceQuestionIfDemographicsBracesExists = (questionText, demographicsData) => {
    let found = [],
        rxp = /{([^}]+)}/g,
        mat;
    let updatedText = questionText
    if (demographicsData) {
        // eslint-disable-next-line
        while (mat = rxp.exec(questionText)) {
            found.push(mat[1]);
        }
        if (found.length > 0) {
            found.forEach((replaceItem) => {
                updatedText = updatedText.replace(`{${replaceItem}}`, getReplaceString(demographicsData, replaceItem))
            })
        }
    }

    return updatedText
}

export const checkIfHrisBasedRuleSatisfied = (block, demographicData) => {
    let isShowTopicBlock = false
    if (block.hrisBasedCondition && block.hrisBasedCondition.length > 0) {
        let condionalDemographics = block.hrisBasedCondition[0] && block.hrisBasedCondition[0].selectedDemographics
        if (condionalDemographics && demographicData && demographicData) {
            if (block.hrisBasedCondition[0][condionalDemographics]) {
                let enteredUserDemographics = demographicData[condionalDemographics]
                isShowTopicBlock = block.hrisBasedCondition[0][condionalDemographics].includes(enteredUserDemographics)
                if (block.hrisBasedCondition[0].showQuestion === "Show" && isShowTopicBlock) {
                    return false
                }
                else if (block.hrisBasedCondition[0].showQuestion === "Hide") {
                    return isShowTopicBlock
                }
                else return true
            }

        }
    }
    return isShowTopicBlock
}