import React from "react";
import {  DESCEND, DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER, NUMERIC_COLUMNS } from "./constants";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import OutliersTableData from "./TableView";

export default class InsightsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      positiveOutliers: [],
      negativeOutliers: [],
      positiveOutliersShowCount: 5,
      negativeOutliersShowCount: 5,
      positiveOutlierSortField: DEFAULT_SORT_FIELD,
      positiveOutlierSortOrder: DEFAULT_SORT_ORDER,
      negativeOutlierSortField: DEFAULT_SORT_FIELD,
      negativeOutlierSortOrder: DEFAULT_SORT_ORDER,
    };
  }

  componentDidUpdate() {
    if (!this.props.apiData.isProcessing) {
      this.getPositiveOutliers(5);
      this.getNegativeOutliers(5);
    }
  }
  getPositiveOutliers = (size) => {
    let positiveOutliers =
      this.props.apiData &&
        this.props.apiData.data &&
        this.props.apiData.data[this.props.positiveFetchKey]
        ? this.props.apiData.data[this.props.positiveFetchKey]
        : [];
    let sortedPositiveOutlier = this.sortOutlierData(positiveOutliers, this.state.positiveOutlierSortField, this.state.positiveOutlierSortOrder)
    return sortedPositiveOutlier.slice(0, size);
  };

  sortOutlierData = (data, sortField, sortOrder) => {
    if (NUMERIC_COLUMNS.includes(sortField)) {
      if (sortOrder === DESCEND) {
        return data.sort((a, b) => { return b[sortField] - a[sortField] })
      } else {
        return data.sort((a, b) => { return a[sortField] - b[sortField] })
      }
    } else {
      if (sortOrder === DESCEND) {
        return data.sort((a, b) => { return b[sortField] > a[sortField] ? 1 : -1 })
      } else {
        return data.sort((a, b) => { return a[sortField] > b[sortField] ? 1 : -1 })
      }
    }
  }

  getNegativeOutliers = (size) => {
    let negativeOutliers =
      this.props.apiData &&
        this.props.apiData.data &&
        this.props.apiData.data[this.props.negativeFetchKey]
        ? this.props.apiData.data[this.props.negativeFetchKey]
        : [];
    let sortedNegativeOutlier = this.sortOutlierData(negativeOutliers, this.state.negativeOutlierSortField, this.state.negativeOutlierSortOrder)
    return sortedNegativeOutlier.slice(0, size);
  };

  showMorePositiveOnClick = (value) => {
    this.setState({
      positiveOutliersShowCount:
        this.props.apiData.data[this.props.positiveFetchKey].length,
    });
    this.getPositiveOutliers(
      this.props.apiData.data[this.props.positiveFetchKey].length
    );
  };

  showMoreNegativeOnClick = (value) => {
    this.setState({
      negativeOutliersShowCount:
        this.props.apiData.data[this.props.negativeFetchKey].length,
    });
    this.getNegativeOutliers(
      this.props.apiData.data[this.props.negativeFetchKey].length
    );
  };
  showLessPositiveOnClick = () => {
    this.setState({ positiveOutliersShowCount: 5 });
    this.getPositiveOutliers(5);
  };
  showLessNegativeOnClick = () => {
    this.setState({ negativeOutliersShowCount: 5 });
    this.getNegativeOutliers(5);
  };

  positiveOutlierTableChange = (pagination, filter, sorter) => {
    if (sorter) {
      if (sorter.columnKey && sorter.order) {
        this.setState({
          ...this.state,
          positiveOutlierSortField: sorter.columnKey,
          positiveOutlierSortOrder: sorter.order
        })
      } else {
        this.setState({
          positiveOutlierSortOrder: DEFAULT_SORT_ORDER,
          positiveOutlierSortField: DEFAULT_SORT_FIELD
        })
      }
    }
  }

  negativeOutlierTableChange = (pagination, filter, sorter) => {
    if (sorter) {
      if (sorter.columnKey && sorter.order) {
        this.setState({
          ...this.state,
          negativeOutlierSortField: sorter.columnKey,
          negativeOutlierSortOrder: sorter.order
        })
      } else {
        this.setState({
          negativeOutlierSortOrder: DEFAULT_SORT_ORDER,
          negativeOutlierSortField: DEFAULT_SORT_FIELD
        })
      }
    }
  }

  getPoitiveOutlierTableProps = () => {
    return ({
      sortField: this.state.positiveOutlierSortField,
      sortOrder: this.state.positiveOutlierSortOrder,
      threshold: this.props.threshold.filterThreshold
    })
  }

  getNegativeOutlierTableProps = () => {
    return ({
      sortField: this.state.negativeOutlierSortField,
      sortOrder: this.state.negativeOutlierSortOrder,
      threshold: this.props.threshold.filterThreshold
    })
  }

  positiveOutliercondition = () => {
    return this.props.apiData &&
      this.props.apiData.data &&
      this.props.apiData.data[this.props.positiveFetchKey].length === 0
  }

  negativeOutlierCondition = () => {
    return this.props.apiData &&
      this.props.apiData.data &&
      this.props.apiData.data[this.props.negativeFetchKey].length === 0
  }

  checkIfBothTableHaveNoData = () => {
    if (this.positiveOutliercondition() && this.negativeOutlierCondition()) {
      return true
    } else {
      return false
    }
  }

  render() {
    return (
      <>
        {this.checkIfBothTableHaveNoData() ? 
        <div className="outliers-area">After analyzing the data, we were unable to find any employee groups with a statistically different score from all other groups.</div> :
          <div className="outliers-block">
            <div className="outliers-block__head">
              <h2>
                {this.props.positiveHeading}
              </h2>
            </div>
            <OutliersTableData
              tableProps={this.getPoitiveOutlierTableProps()}
              onChange={this.positiveOutlierTableChange}
              data={this.getPositiveOutliers(
                this.state.positiveOutliersShowCount
              )
              }
              insightsTrendColomns={this.props.insightsTrendColomns}
              trendData={this.props.trendData}
              loading={this.props.apiData.isProcessing}
            />
            {this.props.apiData &&
              this.props.apiData.data &&
              this.props.apiData.data[this.props.positiveFetchKey].length >
              5 ? (
              this.state.positiveOutliersShowCount > 5 ? (
                <a href="#scrolltop">
                  <div
                    className="show-more"
                    onClick={this.showLessPositiveOnClick}
                  >
                    Show less <UpOutlined />
                  </div>
                </a>
              ) : (
                <div className="show-more" onClick={this.showMorePositiveOnClick}>
                  Show more <DownOutlined />
                </div>
              )
            ) : (
              ""
            )}
            <div className="outliers-block__head">
              <h2>
                {this.props.negativeHeading}

              </h2>
            </div>
            <OutliersTableData
              tableProps={this.getNegativeOutlierTableProps()}
              onChange={this.negativeOutlierTableChange}
              data={this.getNegativeOutliers(
                this.state.negativeOutliersShowCount
              )}
              loading={this.props.apiData.isProcessing}
              trendData={this.props.trendData}
              insightsTrendColomns={this.props.insightsTrendColomns}

            />
            {this.props.apiData &&
              this.props.apiData.data &&
              this.props.apiData.data[this.props.negativeFetchKey].length >
              5 ? (
              this.state.negativeOutliersShowCount > 5 ? (
                <a href="#scrolltop">
                  <div
                    className="show-more"
                    onClick={this.showLessNegativeOnClick}
                  >
                    Show less <UpOutlined />
                  </div>
                </a>
              ) : (
                <div className="show-more" onClick={this.showMoreNegativeOnClick}>
                  Show more <DownOutlined />
                </div>
              )
            ) : (
              ""
            )}
          </div>
        }
      </>
    );
  }
}
