import { 
    GET_DISTRIBUTION_DATA_START,
    GET_DISTRIBUTION_DATA_SUCCESS,
    GET_DISTRIBUTION_DATA_FAIL
} from '../../constants';
import { Default } from './IntialState';
import {  
    getDistributionDataStart,
    getDistributionDataSuccess,
    getDistributionDataFail
} from './helper';


const getDistributionDataReducer = (state=Default(), action) => {
    switch (action.type) {
    case GET_DISTRIBUTION_DATA_START:
        return getDistributionDataStart(state,action);
    case GET_DISTRIBUTION_DATA_SUCCESS:
        return getDistributionDataSuccess(state,action);
    case GET_DISTRIBUTION_DATA_FAIL:
        return getDistributionDataFail(state,action);
    default: return {...state};
    }
};

export default getDistributionDataReducer;