export function downloadLibraryStart(state) {
  return {
    ...state,
    downloadDefaultTopicApi: {
      loading: true,
    },
  };
}

export function downloadLibrarySuccess(state) {
  return {
    ...state,
    downloadDefaultTopicApi: {
      loading: false,
    },
  };
}

export function downloadLibraryFail(state) {
  return {
    ...state,
    downloadDefaultTopicApi: {
      loading: false,
    },
  };
}

export function downloadCommentStart(state) {
  return {
    ...state,
    downloadCommentsApi: {
      loading: true,
    },
  };
}

export function downloadCommentSuccess(state) {
  return {
    ...state,
    downloadCommentsApi: {
      loading: false,
    },
  };
}

export function downloadCommentFail(state) {
  return {
    ...state,
    downloadCommentsApi: {
      loading: false,
    },
  };
}

export function downloadUserCommentStart(state) {
  return {
    ...state,
    downloadUserCommentsApi: {
      loading: true,
    },
  };
}

export function downloadUserCommentSuccess(state) {
  return {
    ...state,
    downloadUserCommentsApi: {
      loading: false,
    },
  };
}

export function downloadUserCommentFail(state) {
  return {
    ...state,
    downloadUserCommentsApi: {
      loading: false,
    },
  };
}
