import { deepCopy } from "../../../../../../utils/deepCopy"
import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../../utils/goalsUtils";
import { getMatrixFollowUpQuestion, getRedirectFollowUpQuestion, getTextEntryFollowUpQuestion } from "./multichoiceAdd"
import { getHTMLRemovedQuestion } from "../helpers";

export const addConditionalFollowUpForMultiChoice = (state, payload) => {
    let updatedGoals = getConditionalFollowUpAddedGoals(state.goals, payload)
    return (
        {
            ...state,
            goals: updatedGoals
        }
    )
}

const getConditionalFollowUpAddedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            let followupQuestion = {}
            if (payload.matrixType) {
                followupQuestion = getMatrixFollowUpQuestion(element.questions[0], element)
            }
            else if (payload.isRedirection) {
                followupQuestion = getRedirectFollowUpQuestion(element.questions[0], element)
            }
            else {
                followupQuestion = getTextEntryFollowUpQuestion(element.questions[0], element)
            }
            let followup = element.questions[0].followUpQuestions && Array.isArray(element.questions[0].followUpQuestions)
                ? [...element.questions[0].followUpQuestions, followupQuestion]
                : [followupQuestion]
            element.questions[0].followUpQuestions = followup
            element.questions[0].haveFollowUpFromBlockLevel = true
        }
    });
    return goalsCopy
}

export const multipleChoiceQuestionChangeFollowUpCondition = (state, payload) => {
    let updatedGoals = getQuestionConditionChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getQuestionConditionChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            element.questions[0].followUpQuestions[payload.followUpIndex].condition = payload.value
        }
    });
    return goalsCopy
}

export const multipleChoiceQuestionDeleteFollowUp = (state, payload) => {
    let updatedGoals = getQuestionFollowUpDeletedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getQuestionFollowUpDeletedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            let followUp = element.questions[0].followUpQuestions
            followUp.splice(payload.followUpIndex, 1)
            element.questions[0].haveFollowUpQuestion = element.questions[0].followUpQuestions && element.questions[0].followUpQuestions.length > 0 ? true : false
        }
    });
    return goalsCopy
}

export const multipleChoiceQuestionChangeFollowUpLabel = (state, payload) => {
    let updatedGoals = getFollowupQuestionLabelChangedGoals(state.goals, payload)
    return ({
        ...state,
        goals: updatedGoals
    })
}


const getFollowupQuestionLabelChangedGoals = (goals, payload) => {
    let goalsCopy = deepCopy(goals)
    goalsCopy.forEach(element => {
        if (element.blockId === payload.blockId) {
            if (payload.matrixSection) {
                element.questions[0].followUpQuestions[payload.followUpIndex].matrixQuestions[0].HTMLText = payload.value
                element.questions[0].followUpQuestions[payload.followUpIndex].matrixQuestions[0].text = getHTMLRemovedQuestion(payload.value)
                element.questions[0].followUpQuestions[payload.followUpIndex].matrixQuestions[0].staticQuestionId = generateStaticQuestionId(payload.value)
            } else if (payload.description) {
                element.questions[0].followUpQuestions[payload.followUpIndex].questionLabel = payload.value
            } else {
                element.questions[0].followUpQuestions[payload.followUpIndex].HTMLQuestion = payload.value
                element.questions[0].followUpQuestions[payload.followUpIndex].question = getHTMLRemovedQuestion(payload.value)
            }

        }
    });
    return goalsCopy
}